import React from 'react';
import PropTypes from 'prop-types';

const FormTitle = ({ title }) => {
  return (
    <div className="section-title">
      <h5 className="fw-600">{title}</h5>
    </div>
  );
};

FormTitle.propTypes = {
  title: PropTypes.object.isRequired,
};

export default FormTitle;
