import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Highlighter from "react-highlight-words";

const QuestionItemAnswerOkr = ({ text, searchKeyword }) => {

  if (text.props && text.props.defaultMessage) {
    text = text.props.defaultMessage;
  }
  
  return (
    <Fragment>
      <p className="medium">
        <Highlighter
          highlightClassName="bg-yellow_pure"
          searchWords={[searchKeyword]}
          autoEscape={true}
          textToHighlight={text}
        />
      </p>
    </Fragment>
  );
};

QuestionItemAnswerOkr.propTypes = {
  index: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired
};

export default QuestionItemAnswerOkr;
