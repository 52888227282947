import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import Moment from 'react-moment';

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: '#bff0fd',
    paddingTop: "5px"
  },
  obj: {
    width: "150px",
    marginRight: "10px",
    fontStyle: "bold",
    fontWeight: "700"
  },
  progress: {
    width: "50px",
    textAlign: "center"
  },
  w50: {
    width: "50px",
  },
  w80: {
    width: "80px",
  },
  kr: {
    width: "200px",
  },
});

const getKeyResults = keyResults => {
  // let results = []
  let keyResult = '';

  for (let y = 0; y < keyResults.length; y++) {
    let kr = keyResults[y];

    let krItem = '';

    if (y !== keyResults.length - 1) {
      krItem = `KR${y+1}: ${kr.title}
        Owner: ${kr.ownerFirstName} ${kr.ownerLastName}
        Value: ${kr.current} / ${kr.goal}, Progress: ${kr.progress}%

      `
    } else {
      // Last KR without new lines
      krItem = `KR${y+1}: ${kr.title}
        Owner: ${kr.ownerFirstName} ${kr.ownerLastName}
        Value: ${kr.current} / ${kr.goal}, Progress: ${kr.progress}%`
    }
    
    keyResult += krItem;
  }

  return keyResult;
};

const TableRow = ({ items }) => {

  const rows = items.map((okr, i) => (
    <View style={styles.row} key={i}>
      <Text style={styles.obj}>{okr.objective}</Text>
      <Text style={styles.w80}>{`${okr.ownerFirstName} ${okr.ownerLastName}`}</Text>
      <Text style={styles.w80}>{okr.teamName}</Text>
      <Text style={styles.w50}>{okr.status === 1 ? "On Track" : okr.status === 2 ? "Behind" : "At Risk"}</Text>
      <Text style={styles.progress}>{Math.round(okr.progress)}%</Text>
      <Text style={styles.kr}>{getKeyResults(okr.keyResults)}</Text>
      <Text style={styles.w80}><Moment format="D MMM YY">{okr.dueIn}</Moment></Text>
      <Text style={styles.w80}><Moment format="D MMM YY">{okr.updatedAt}</Moment></Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default TableRow;