import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';

const UpdatesCampaignType = ({
  campaignType,
  handleCampaignType,
  isForm,
  handleFormClick,
}) => {

  let label = 'Classic';
  if (campaignType === 2) label = 'OKR-based';

  const typeTxt = <FormattedMessage id="type" defaultMessage="Type" />

  return (
    <Fragment>
      {!isForm ? (
        <p 
          className="w-120px small cursor"
          onClick={() => handleFormClick()}
        >
          {typeTxt}{": "}{label}
        </p>
      ) : (
        <div className="campaign-type">
          <select 
            className="w-100px" 
            name='campaignType' 
            value={campaignType} 
            onChange={e => handleCampaignType(e)}
          >
            <option value='' disabled selected>Choose campaign type</option>
            <option value='1'>Classic</option>
            <option value='2'>OKR-based</option>
          </select>
        </div>
      )}

    </Fragment>
  );
};

UpdatesCampaignType.propTypes = {
  title: PropTypes.object,
};

export default UpdatesCampaignType;
