import React, { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { editProfile, getCurrentProfile, editAvatar } from '../../actions/profile';
import { getUserCompany } from '../../actions/company';
import UserNav from '../layout/UserNav';
import Menu from '../layout/Menu';
import { setLocaleLang } from '../../actions/locale';
import Spinner from '../layout/Spinner';
import TimezonePicker from 'react-timezone';
import { FormattedMessage } from 'react-intl';
import AvatarPopup from '../popup/AvatarPopup';

const EditProfile = ({
  getCurrentProfile,
  getUserCompany,
  editAvatar,
  setLocaleLang,
  profile: { profile, loading },
  editProfile,
  company: { company },
  auth: { user },
  history,
  locale
}) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    jobTitle: '',
    department: '',
    timezone: '',
    avatarUpdate: 0,
  });

  const [language, toggleLanguage] = useState(false);

  const handleLang = lang => {
    setLocaleLang(lang);
    toggleLanguage(!language);
  };

  const [preview, setPreview] = useState(null);
  const [avatarPopup, showAvatarPopup] = useState(false);

  const {
    firstName,
    lastName,
    email,
    phone,
    jobTitle,
    department,
    timezone,
    avatarUpdate,
  } = formData;

  useEffect(() => {
    getCurrentProfile();

    getUserCompany();
    
    if (profile) {
      setFormData({
        ...formData,
        firstName: loading || !profile.firstName ? '' : profile.firstName,
        lastName: loading || !profile.lastName ? '' : profile.lastName,
        email: loading || !profile.email ? '' : profile.email,
        phone: loading || !profile.phone ? '' : profile.phone,
        jobTitle: loading || !profile.jobTitle ? '' : profile.jobTitle,
        department: loading || !profile.department ? '' : profile.department,
        timezone: loading || !profile.timezone ? '' : profile.timezone,
      });
    }
  }, [loading, getCurrentProfile, getUserCompany, avatarUpdate]);

  const onChangeAvatar = avatarUpdate => {
    
    avatarUpdate += 1;

    setFormData({ ...formData, avatarUpdate });
  };
  
  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onChangeTimezone = (timezone, name) => {
    setFormData({ ...formData, [name]: timezone });
  };

  const onSubmit = e => {
    e.preventDefault();
    editProfile(formData, history);
  };

  // console.log('==== formData', formData)

  const passwordLink = profile ? `/password/${profile._id}` : null;

  // Select options
  const teamOptions = company
    ? [...company.departments.map(department => {
        return (
          <option value={department._id} key={department._id}>
            {department.name}
          </option>
        );
      })
    , (
      <option value={''} key={'no_team'}>
        {'No team'}
      </option>
    )]
    : [];
 
  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">
          {user && user.department ? (
            <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user && user.isAdmin && true} />
          ) : (
            <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} />
          )}

          <UserNav 
            avatarUpdate={avatarUpdate}
          />
          <Fragment>
            {profile === null || loading ? (
              <Spinner />
            ) : (
              <div className="main main-dashboard user-standups">
                <div className="standups mt-5">
                  <div className="section-title">
                    <h5> 
                    <FormattedMessage id="profile.edit.title" defaultMessage="Edit Profile" />
                    </h5>
                    <Link
                      to="/profile"
                      className="btn-secondary btn-secondary-back"
                    >
                      <FormattedMessage id="profile.edit.back" defaultMessage="Go Back" />
                    </Link>
                  </div>

                  <div className="component component-profile my-2 p-3 light-shadow">
                    <div className="form-full form-profile">
                      <div className="user-avatar">
                        <img
                          src={profile.avatar.indexOf('gravatar') === -1 ? `${profile.avatar}?${avatarUpdate}` : profile.avatar}
                          alt="Avatar"
                          className="round-img d-block avatar"
                          width="140px"
                          height="140px"
                          />
                          
                        <div className="flex flex-column ml-3">
                          <Link
                            to={passwordLink}
                            className="btn-secondary btn-secondary-comment text-center-xs h-0-xs lh-24-xs"
                          >
                            <FormattedMessage id="profile.edit.changepass" defaultMessage="Change Password" />
                          </Link>

                            <div
                              onClick={() => showAvatarPopup(!avatarPopup)}
                              className="medium bold blue mt-3 ml-3 cursor"
                            >
                              <FormattedMessage id="avatar.change" defaultMessage="Change Profile Photo" />
                            </div> 
                        </div>
                      </div>
                        
                        {avatarPopup && (
                          <AvatarPopup
                            showAvatarPopup={showAvatarPopup}
                            avatarPopup={avatarPopup}
                            editAvatar={editAvatar}
                            avatarUpdate={avatarUpdate}
                            onChangeAvatar={onChangeAvatar}
                          />
                        )}

                      <form
                        className="form-app form-app-profile"
                        onSubmit={e => onSubmit(e)}
                      >
                        <div className="form-main form-main-inline">
                          <p className="bold medium block">
                          <FormattedMessage id="profile.edit.firstName" defaultMessage="First Name" />
                          </p>
                          <FormattedMessage id="profile.edit.firstNameInput" defaultMessage="Enter your first name">
                          {msg => <input
                            type="text"
                            placeholder={msg}
                            name="firstName"
                            value={firstName}
                            onChange={e => onChange(e)}
                          />}
                          </FormattedMessage>
                        </div>

                        <div className="form-main form-main-inline">
                          <p className="bold medium block">
                          <FormattedMessage id="profile.edit.lastName" defaultMessage="Last Name" />
                          </p>
                          <FormattedMessage id="profile.edit.lastNameInput" defaultMessage="Enter your last name">
                          {msg => <input
                            type="text"
                            placeholder={msg}
                            name="lastName"
                            value={lastName}
                            onChange={e => onChange(e)}
                          />}
                          </FormattedMessage>
                        </div>

                        <div className="form-main form-main-inline">
                          <p className="bold medium block">
                          <FormattedMessage id="profile.edit.email" defaultMessage="Email" />
                          </p>
                          <FormattedMessage id="profile.edit.emailInput" defaultMessage="Enter your email">
                          {msg => <input
                            type="email"
                            placeholder={msg}
                            name="email"
                            value={email}
                            onChange={e => onChange(e)}
                          />}
                          </FormattedMessage>
                        </div>

                        <div className="form-main form-main-inline">
                          <p className="bold medium block">
                          <FormattedMessage id="profile.edit.phone" defaultMessage="Phone" />
                          </p>
                          <FormattedMessage id="profile.edit.phoneInput" defaultMessage="Enter your phone number">
                          {msg => <input
                            type="text"
                            placeholder={msg}
                            name="phone"
                            value={phone}
                            onChange={e => onChange(e)}
                          />}
                          </FormattedMessage>
                        </div>

                        <div className="form-main form-main-inline">
                          <p className="bold medium block">
                          <FormattedMessage id="profile.edit.position" defaultMessage="Position" />
                          </p>
                          <FormattedMessage id="profile.edit.positionInput" defaultMessage="Add you job position">
                          {msg => <input
                            type="text"
                            placeholder={msg}
                            name="jobTitle"
                            value={jobTitle}
                            onChange={e => onChange(e)}
                          />}
                          </FormattedMessage>
                        </div>

                        <Fragment>
                          {company && company.departments.length > 0 ? (
                            <div className="form-main form-main-inline">
                              <p className="bold medium block">
                              <FormattedMessage id="profile.edit.team" defaultMessage="Team" />
                              </p>
                              <select
                                name="department"
                                value={department}
                                onChange={e => onChange(e)}
                              >
                                {teamOptions}
                              </select>
                            </div>
                          ) : null}
                        </Fragment>

                        <div className="form-main form-main-inline">
                          <p className="bold medium block">
                          <FormattedMessage id="profile.edit.timezone" defaultMessage="Timezone" />
                            </p>
                          <TimezonePicker
                            name="timezone"
                            value={timezone}
                            onChange={timezone =>
                              onChangeTimezone(timezone, 'timezone')
                            }
                            inputProps={{
                              placeholder: 'Select Timezone...',
                              name: 'timezone'
                            }}
                          />
                        </div>

                        <div className="form-main form-main-inline mb-2">
                          <p className="bold medium block pb-4 pt-1">
                          <FormattedMessage id="language" defaultMessage="Language" />
                            </p>
                            <p
                              className="landing_font no_btn pl-2 no-w"
                              onClick={() => toggleLanguage(!language)}
                            >
                              {locale.lang === 'en' ? 'English' : 'Русский'}{' '}
                              <i className="fas fa-caret-down" />
                            </p>
                          {language && (
                            <ul className="sub-menu sub-language sub-user light-shadow px-2">
                              <li className="p-xs">
                                <p
                                  className="landing_font no_btn "
                                  onClick={() => handleLang('en')}
                                >
                                  <FormattedMessage id="nav.english" defaultMessage="English" />
                                </p>
                              </li>
                              <li className="p-xs">
                                <p
                                  className="landing_font no_btn"
                                  onClick={() => handleLang('ru')}
                                >
                                  <FormattedMessage id="nav.russian" defaultMessage="Russian" />
                                </p>
                              </li>
                            </ul>
                          )}
                        </div>


                        <button type="submit" className="btn btn-form">
                        <FormattedMessage id="profile.edit.btn.submit" defaultMessage="Submit" />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

EditProfile.propTypes = {
  setLocaleLang: PropTypes.func.isRequired,
  editProfile: PropTypes.func.isRequired,
  editAvatar: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  company: state.company,
  locale: state.locale,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { editProfile, getCurrentProfile, getUserCompany, setLocaleLang, editAvatar }
)(withRouter(EditProfile));
