import React, { Fragment } from "react";
import PropTypes from "prop-types";
import KRnumber from './krTypes/NumberForm'
import KRbinary from './krTypes/BinaryForm'
import KRmilestone from './krTypes/MilestoneForm'

const KRmetricsForm = ({ 
  keyResult, index, onChangeKeyResults, 
  locale, selectCustomStyles, handleKeyResults,
  krLabels, krName, handleAddMilestone,
 }) => {

  // console.log('====== keyResult in KRmetricsForm', keyResult)

  return (
    <Fragment>
      {!keyResult.krType || keyResult.krType.value === 'number' ? (
        <KRnumber
          keyResult={keyResult}
          index={index}
          onChangeKeyResults={onChangeKeyResults}
          krName={krName}
        />
      ) : (
        keyResult.krType.value === 'binary' ? (
          <KRbinary
            keyResult={keyResult}
            index={index}
            handleKeyResults={handleKeyResults}
            locale={locale}
            krLabels={krLabels}
            selectCustomStyles={selectCustomStyles}
          />
        ) : keyResult.krType.value === 'milestone' ? (
          <KRmilestone
            keyResult={keyResult}
            index={index}
            handleKeyResults={handleKeyResults}
            locale={locale}
            krLabels={krLabels}
            selectCustomStyles={selectCustomStyles}
            handleAdd={handleAddMilestone}
          />
        ) : null 
      )}
    </Fragment>
  );
};

KRmetricsForm.propTypes = {
  handleKeyResults: PropTypes.func.isRequired,
  keyResult: PropTypes.object.isRequired
};

export default KRmetricsForm;
