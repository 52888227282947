import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UserNav from '../layout/UserNav';
import { editCustomAnswerById, getCustomAnswerById } from '../../actions/customAnswer';
import { getCustomSettings } from '../../actions/custom';
import { setAlert } from '../../actions/alert';
import Menu from '../layout/Menu';
import Spinner from '../layout/Spinner';
import { icons } from '../../img/elements/icons';
import { FormattedMessage } from 'react-intl';
import Loader from 'react-loader-spinner';
import classNames from 'classnames';
import { getUserTasks, completeTodo } from '../../actions/todo';
import CustomQuestionItem from './CustomQuestionItem';
import { getAllUserOkrs } from '../../actions/okr';

const adaptAnswersToEdit = (questions, answers, okrs) => {
  // console.log('=== WE ARE IN adaptAnswersToEdit')

  return questions.map((question, index) => {
    if (question.questionType.value === 'single_select') {
      return [question.selectOptions.find((opt) => answers[index] && opt.label === answers[index].text[0])];
    }
    if (question.questionType.value === 'okr_select') {
      // console.log('=== okr_select here')
      // console.log('=== answers[index]', answers[index])
      // console.log('=== FIND', okrs.find((okr) => answers[index] && okr.label === answers[index].text[0]))
      // let obj = answers[index].okr_text
      // console.log('=== obj', obj)

      return [okrs.find((okr) => answers[index] && okr.label === answers[index].text[0])];
      // return [obj];
    }
    return answers[index] ? answers[index].text : [''];
  });
};

const sidMenuItems = [
  { title: 'Tasks', value: 0 }
  // { title: 'Objectives', value: 1 }
];

const CustomForm = ({
  setAlert,
  completeTodo,
  getCustomSettings,
  getCustomAnswerById,
  getUserTasks,
  getAllUserOkrs,
  editCustomAnswerById,
  custom: { settings },
  customAnswer: { customAnswer, loading },
  auth: { user },
  todo: { tasks },
  okr: { okrs },
  match,
  history,
  locale
}) => {
  let noOkrMsg =
    locale && locale.lang !== 'ru' ? `😳 I don't work on OKR today` : `😳 Я не работаю сегодня над OKR`;
  const [formData, setFormData] = useState({
    answers: [],
    selectedOption: [],
    todos: [],
    completedTodos: []
  });

  const { answers, todos } = formData;
  // console.log('=== answers', answers)
  // customAnswer && console.log('=== customAnswer.answers', customAnswer.answers)

  let previousItemAnswersArr = [];

  {
    customAnswer &&
      customAnswer.answers.length > 0 &&
      customAnswer.answers[0].text.forEach((answer) => {
        let obj = {
          text: answer,
          completeOne: false,
          completeTwo: false
        };

        previousItemAnswersArr.push(obj);
      });
  }

  const [complitingTasks, setComplitingTasks] = useState({});
  const [isLoader, toggleLoader] = useState(false);
  const [draggingTodo, setDraggingTodo] = useState(null);
  const [okrOptions, setOkrOptions] = useState(null);
  useEffect(() => {
    getCustomAnswerById(match.params.id);
    getUserTasks({ all: true });
    getAllUserOkrs();
  }, []);

  useEffect(() => {
    if (customAnswer) getCustomSettings(customAnswer.custom);
  }, [customAnswer]);

  // Make answers.length is equal for questions.length
  settings &&
    answers &&
    settings.questions.length !== 0 &&
    settings.questions.length !== answers.length &&
    settings.questions.forEach(() => {
      let text = [''];
      answers.push(text);
    });

  useEffect(() => {
    let okrOptions = [];
    okrs &&
      okrs.length > 0 &&
      okrs.forEach((okr, i) => {
        if (!okr.isArchive) {
          let objective = {
            value: okr._id,
            label: `${okr.objective}`,
            okr: okr._id,
            objective: okr.objective,
            isFocus: true,
            text: okr.objective,
            focusIndex: 99,
            status: okr.status,
            progress: okr.progress,
            goal: 100
          };

          okrOptions.push(objective);

          let krs = okr.keyResults;

          if (krs.length > 0) {
            krs.forEach((kr, index) => {
              let item = {
                value: kr._id,
                label: ` - ${kr.title}`,
                okr: okr._id,
                objective: okr.objective,
                isFocus: true,
                text: kr.title,
                focusIndex: index,
                status: okr.status,
                progress: kr.progress,
                goal: kr.goal,
                current: kr.current,
                krId: kr._id,
                isKr: true
              };

              okrOptions.push(item);
            });
          }
        }

        if (i === okrs.length - 1) {
          let noOkr = { value: `noOkr-${okr._id}`, label: noOkrMsg, isFocus: false };
          okrOptions.push(noOkr);
        }
      });
    setOkrOptions(okrOptions);
  }, [okrs.length]);

  useEffect(() => {
    if (customAnswer && settings && okrOptions) {
      setFormData({
        ...formData,
        answers:
          settings && customAnswer && customAnswer.answers
            ? adaptAnswersToEdit(settings.questions, customAnswer.answers, okrOptions)
            : [],

        todos: !tasks ? [] : tasks
      });
    }
  }, [customAnswer, settings, okrOptions]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //validation
    for (let item of formData.answers) {
      if (item.length === 1 && item[0] === '') {
        return setAlert(
          <FormattedMessage id="fill.form" defaultMessage="Please fill out all fields of the form" />,
          'danger'
        );
      }
    }
    //tranformation to answers to text
    let resultedAnswers = formData.answers.map((el, i) =>
      el.map((el2) => (typeof el2 === 'object' && settings.questions[i].questionType.value !== 'okr_select' ? el2.label : el2)).filter((el2) => el2 !== '')
    );

    for (let tkey in complitingTasks) {
      for (let key in complitingTasks[tkey]) {
        if (complitingTasks[tkey][key].name === resultedAnswers[tkey][key]) {
          const task = tasks.find((tsk) => tsk.name === complitingTasks[tkey][key].name);
          if (task && task.isCompleted !== complitingTasks[tkey][key].value) {
            await completeTodo(task._id, complitingTasks[tkey][key].value);
          }
        }
      }
    }

    //add questions
    resultedAnswers = resultedAnswers.map((ans, i) => ({
      question: settings.questions[i].text,
      questionId: settings.questions[i]._id,
      text: ans
    }));

    // Create new answer or update
    // Check the last weekly custom

    // const res = await editCustomAnswerById(resultedAnswers, settings._id, answerID);

    const res = await editCustomAnswerById({ answerId: match.params.id, answers: resultedAnswers });

    if (res) {
      goBack();
    }
  };

  const handleChange = (answerIndex, answerValue) => {
    setFormData((prev) => {
      const newFormData = { ...prev };
      newFormData.answers[answerIndex] = answerValue;
      return newFormData;
    });
  };

  const goBack = () => {
    history.goBack();
  };

  let btnTxt = <FormattedMessage id="standup.form.submit" defaultMessage="Submit" />;
  let cnclBtnTxt = <FormattedMessage id="cancel" defaultMessage="Cancel" />;

  // Loader for button
  let loadingIcon = (
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Loader type="Circles" color="#ffffff" height="14px" width="15" />
    </span>
  );

  if (isLoader) {
    btnTxt = loadingIcon;
  }

  const [isSideBarOpen, switchSideBar] = useState(true);
  const [currentSideBarTab, switchSideBarTab] = useState(0); //0 - todos 1- objectives

  const onDragStart = (todo) => (e) => {
    setDraggingTodo(todo);
  };
  const onDragEnd = (answerIndex) => {
    if (draggingTodo) {
      setFormData((prev) => {
        const newFormData = { ...prev };
        newFormData.completedTodos.push(draggingTodo);
        // find empty text if not exist push new
        const emptyIndex = newFormData.answers[answerIndex].findIndex((el) => el === '');
        if (emptyIndex !== -1) {
          newFormData.answers[answerIndex][emptyIndex] = draggingTodo.name;
        } else {
          newFormData.answers[answerIndex].push(draggingTodo.name);
        }
        return newFormData;
      });
      setDraggingTodo(null);
    }
  };

  // console.log('=== formData', formData)
  // console.log('=== okrOptions', okrOptions)
  
  const onChangeComplitingTasks = (indx, i, name) => (e) => {
    e.persist();
    setComplitingTasks((prev) => {
      const newTasks = { ...prev };
      if (newTasks[indx]) {
        newTasks[indx][i] = { value: e.target.checked, name };
      } else {
        newTasks[indx] = { [i]: { value: e.target.checked, name } };
      }
      return newTasks;
    });
  };

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">
          <div className="hide-1400">
            {user && user.department ? (
              <Menu
                companyName={user && user.companyName}
                team={user.departmentName}
                teamId={user.department}
                settings={user.isAdmin && true}
                focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true}
                activeIndex={4}
              />
            ) : (
              <Menu
                companyName={user && user.companyName}
                settings={user && user.isAdmin && true}
                focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true}
                activeIndex={4}
              />
            )}
          </div>
          <UserNav />

          {loading ? (
            <Spinner />
          ) : (
            <Fragment>
              <div className="flex w-100">
                <div className="w-300px hide-1400" style={{ flexShrink: 0 }}></div>
                <div className="w-100 main mxa user-standups mr-500 pl-3 pt-0-xs mb-5 pb-5 ">
                  <div className="standups mt-0">
                    <div className="section-title ">
                      <h5 className="fw-600">
                        <FormattedMessage id="standup.form.header" defaultMessage="Complete a Check-in" />
                      </h5>
                      <p onClick={(e) => goBack()} className="">
                        {icons.circle_close}
                      </p>
                    </div>

                    <div className=" standups-daily  ">
                      <div className="form-full">
                        <div>
                          <p className="fs-14">
                            <FormattedMessage
                              id="standup.form.subHeader"
                              defaultMessage="Let's answer some questions to make your day better"
                            />
                          </p>
                          <div className="line mt-2 mb-3" />
                        </div>

                        <form className="form-app form-app-standup" onSubmit={handleSubmit}>
                          {settings ? (
                            <Fragment>
                              <Fragment>
                                {settings.questions.map((question, index) => (
                                  <CustomQuestionItem
                                    question={question}
                                    index={index}
                                    value={answers[index]}
                                    onQuestionChange={handleChange}
                                    okrOptions={okrOptions}
                                    onDragEnd={onDragEnd}
                                    key={index}
                                    complitingTasks={complitingTasks}
                                    onChangeComplitingTasks={onChangeComplitingTasks}
                                    tasks={tasks}
                                  />
                                ))}
                              </Fragment>
                            </Fragment>
                          ) : (
                            <Spinner />
                          )}
                          <div className="flex">
                            <button
                              type="submit"
                              className=" mw-150 fs-18  btn btn-small  btn-submit btn__secondary_green   lh-54   block ml-0-xs  "
                              disabled={!isLoader ? false : true}
                            >
                              {btnTxt}
                            </button>
                            <button
                              type="reset"
                              className="mw-150 ml-2 btn btn-small  fs-18  btn-secondary-back-upd "
                              onClick={goBack}
                              disabled={!isLoader ? false : true}
                            >
                              {cnclBtnTxt}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    'w-450px css-main main_menu right tasks m-0 p-0 h-100 flex-column justify-start ' +
                    classNames({ 'tran-trans-05': true, collapsed: !isSideBarOpen, flex: true })
                  }
                >
                  <div
                    className="flex"
                    onClick={() => {
                      switchSideBar((prev) => !prev);
                    }}
                  >
                    <div className={classNames({ 'tran-trans-1': true, mirrored: !isSideBarOpen })}>
                      {icons.double_angle_left}
                    </div>
                    <span className="ml-1 fs-14 fw-600">
                      <FormattedMessage id="collapse" defaultMessage="Collapse" />
                    </span>
                  </div>

                  <div className={`sub-menu__page mt-1 ml-3`}>
                    {sidMenuItems.map((item, index) => (
                      <div
                        key={`menu-${index}`}
                        className={`fs-22 sub-menu__item inline-block mr-2 cursor ${
                          item.value === currentSideBarTab && ' sub-menu__item_active'
                        }`}
                      >
                        {item.title}
                      </div>
                    ))}
                  </div>
                  <div className="ml-3 mt-2">
                    <p className="fs-12">
                      <FormattedMessage
                        id="standup.tasksdugMessage"
                        defaultMessage="You can drag your tasks to instantly fill in text fields"
                      />
                    </p>
                  </div>
                  <div className="flex ml-3">
                    <div className="line-vertical bg-primary_light1 "></div>
                    <div className="px-2 pl-1 ">
                      <div className="tasks__list">
                        {todos.length > 0 ? (
                          todos
                            .filter(
                              (el) =>
                                !formData.completedTodos.find((el1) => el1._id === el._id) &&
                                el.isCompleted === false
                            )
                            .map((item) => (
                              <div
                                draggable
                                onDragStart={onDragStart(item)}
                                className="mr-2 my-1 flex align-center"
                                key={`todo-${item._id}`}
                              >
                                {icons.drag_cross_small}
                                <p className="medium card-subtitle  cursor ml-1 fs-14">{item.name}</p>
                              </div>
                            ))
                        ) : (
                          <div className="mr-2">
                            <p className="medium card-subtitle  cursor">
                              <FormattedMessage id="standup.tasks.empty" defaultMessage="No action items" />
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

CustomForm.propTypes = {
  setAlert: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  getCustomSettings: PropTypes.func.isRequired,
  getCustomAnswerById: PropTypes.func.isRequired,
  custom: PropTypes.object.isRequired,
  customAnswer: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  custom: state.custom,
  customAnswer: state.customAnswer,
  locale: state.locale,
  auth: state.auth,
  todo: state.todo,
  okr: state.okr
});

export default connect(mapStateToProps, {
  setAlert,
  completeTodo,
  getCustomSettings,
  getCustomAnswerById,
  editCustomAnswerById,
  getUserTasks,
  getAllUserOkrs
})(withRouter(CustomForm));
