import React, { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { editTeam, getTeamById } from '../../actions/company';
import { getCompanyProfiles } from '../../actions/profile';
import Menu from '../layout/Menu';
import Spinner from '../layout/Spinner';
import UserNav from '../layout/UserNav';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';

const EditDepartment = ({
  getCompanyProfiles,
  editTeam,
  getTeamById,
  profile: { profiles },
  company: { team, loading },
  auth: { user },
  match,
  history
}) => {
  const [formData, setFormData] = useState({
    department: '',
    selectedOption: []
  });

  let { department, selectedOption } = formData;
  let options = [];

  profiles.forEach(profile => {
    let user = {
      value: profile._id,
      label: `${profile.firstName} ${profile.lastName}`,
      team: profile.department
    };

    options.push(user);
  });

  const teamProfiles =
    options && team ? options.filter(option => option.team === team._id) : [];

  useEffect(() => {
    getTeamById(match.params.id);
    getCompanyProfiles();

    setFormData({
      department: loading || !team ? '' : team.name,
      selectedOption: loading || !team ? '' : teamProfiles
    });
  }, [getTeamById, getCompanyProfiles, match.params.id, loading, team && team.name]);

  const onSubmit = e => {
    e.preventDefault();
    editTeam(match.params.id, department, selectedOption, history);
  };

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChange = selectedOption => {
    setFormData({ ...formData, selectedOption: selectedOption });
  };

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

          {user && user.department ? (
            <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user.isAdmin && true} activeIndex={9} />
          ) : (
            <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} activeIndex={9} />
          )}

          <UserNav />

          <Fragment>
            {!profiles || !team ? (
              <Spinner />
            ) : (
              <div className="main main-dashboard main-profile-admin">
                <div className="standups-admin mt-5 mt-0-xs">
                  <div className="section-title">
                    <h5 className="pl-0-xs">
                      <FormattedMessage
                        id="admin.team.edit.header"
                        defaultMessage="Edit team"
                      />
                    </h5>
                    <Link
                      to="/teams"
                      className="btn-secondary btn-secondary-back"
                    >
                      <FormattedMessage id="go.back" defaultMessage="Go Back" />
                    </Link>
                  </div>

                  <div className="component edit-team my-2 p-3 light-shadow">
                    <div className="form-full">
                      <form
                        className="form-app form-app-standup"
                        onSubmit={e => onSubmit(e)}
                      >
                        <div className="form-main form-main-inline">
                          <p className="bold medium block question">
                            <FormattedMessage
                              id="admin.team.edit.name"
                              defaultMessage="Name"
                            />
                          </p>
                          <FormattedMessage
                            id="admin.team.edit.nameInput"
                            defaultMessage="Edit team name"
                          >
                            {msg => (
                              <input
                                className="question-input"
                                type="text"
                                placeholder={msg}
                                name="department"
                                value={department}
                                onChange={e => onChange(e)}
                              />
                            )}
                          </FormattedMessage>

                        </div>

                        {teamProfiles && (
                          <Fragment>
                            <p className="bold medium block question">
                              <FormattedMessage
                                id="admin.team.edit.invite"
                                defaultMessage="Invite Your Team"
                              />
                            </p>
                            <FormattedMessage
                              id="admin.team.edit.inviteSelect"
                              defaultMessage="Add employees to the team"
                            >
                              {msg => (
                                <Select
                                  key={profiles._id}
                                  defaultValue={teamProfiles}
                                  onChange={selectedOption =>
                                    handleChange(selectedOption)
                                  }
                                  options={options}
                                  isMulti
                                  isSearchable
                                  placeholder={msg}
                                />
                              )}
                            </FormattedMessage>
                          </Fragment>
                        )}

                        <button type="submit" className="btn btn-form">
                          <FormattedMessage
                            id="admin.team.edit.btn"
                            defaultMessage="Edit team"
                          />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

EditDepartment.propTypes = {
  getCompanyProfiles: PropTypes.func.isRequired,
  getTeamById: PropTypes.func.isRequired,
  editTeam: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  company: state.company,
  profile: state.profile,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { editTeam, getCompanyProfiles, getTeamById }
)(withRouter(EditDepartment));
