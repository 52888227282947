import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../../../img/elements/icons';
import { setAlert } from '../../../actions/alert';
import { connect } from 'react-redux';
import { addUserMainOkr } from '../../../actions/okr';
import TaskItemCard from '../../todos/TaskItemCard';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import OkrProgress from '../elements/OkrProgress';
import Highlighter from 'react-highlight-words';
import KRmetrics from "../elements/KRmetricsUpdate";
import DatePickerComponent from '../..//elems/DatePicker';
import Select from 'react-select';


const selectCustomStyles = {
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isFocused ? '#404ff5' : '#fff',
    color: state.isFocused ? '#fff' : '#000',
    fontSize: '18px'
  }),
  control: (styles, state) => ({
    ...styles,
    // height: '35px',
    borderColor: state.isFocused ? '404ff5' : '#e9eaf5',
    boxShadow: state.isFocused ? '0px 0px 20px #404FF54D' : 'none',
    fontSize: '18px',
    border: state.isFocused ? '2px solid #404ff5 !important' : styles.border,
    margin: state.isFocused ? '0px' : '1px'
  }),
  indicatorsContainer: (styles, state) => ({
    ...styles,
    // height: '35px',
  })
};

const KeyResultItem = ({
  keyResult: {
    _id,
    title,
    owner,
    start,
    current,
    goal,
    dueDate,
    owners,
    krType,
    details,
    krOwners,
    isNew,
  },
  progress,
  okrId,
  isMain,
  index,
  addUserMainOkr,
  okrBoard,
  okrType,
  updateMode,
  formData,
  onChange,
  onChangeOkr,
  indexOkr,
  formType,
  formDataParent,
  setFormDataParent,
  todos,
  usersOptions,
  okrOptions,
  priorityOptions,
  handleSelect,
  handleDueIn,
  handleComplete,
  i,
  user,
  handleDelete,
  handleNameForm,
  todoBoard,
  listView,
  actions,
  showTasks,
  handleDetails = () => {},
  isHideProgress,
  searchKeyword,
  locale,
  handleKeyResults,
  onChangeKRSelect,
  ownerOptions,
  onChangeKRMetricsSelect,
}) => {

  const [showAllMilestones, setShowMilestones] = useState(false);
  let activeMilestoneIndex = krType.activeMilestoneIndex ? krType.activeMilestoneIndex : 0;

  {
    owners &&
      owners.length > 0 &&
      owners.forEach((o) => {
        // let coworker = {
        //   value: o._id,
        //   label: `${o.firstName} ${profile.lastName}`
        // };

        // ownerOptions.push(coworker);
        o.value = o.owner;
        o.label = `${o.ownerFirstName} ${o.ownerLastName}`
      });
  }

  let color;
  // let stroke;

  if (progress < 30) {
    color = 'red';
    // stroke = '#fe0909';
  } else if (progress >= 30 && progress < 60) {
    color = 'yellow';
    // stroke = '#f9d54e';
  } else if (progress >= 60) {
    color = 'green';
    // stroke = '#6ddeb3';
  }

  // ЗДЕСЬ получаем коллбэк и перебираем formData.objectives и обновляем isMainIndex
  const handleMain = async (okrId, index, okrBoard) => {
    try {
      const res = await addUserMainOkr(okrId, index, okrBoard);

      setFormDataParent({
        ...formDataParent,
        objectives: formDataParent.objectives.map((answer) => {
          if (answer._id === res.id) {
            // Update goal
            answer.isMain = res.main.isMain;
            answer.isMainIndex = res.main.isMainIndex;

            return answer;
          } else if (res.exOkrId && answer._id === res.exOkrId) {
            answer.isMain = res.exMain.isMain;
            answer.isMainIndex = res.exMain.isMainIndex;

            return answer;
          } else {
            return answer;
          }
        })
      });
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in update main goal (KR)', err);
    }
  };

  const showAllMlText = <FormattedMessage id="expand.milestones" defaultMessage="Expand milestones"/>
  const collapseAllMlText = <FormattedMessage id="collapse.milestones" defaultMessage="Collapse milestones"/>

  // updateMode && console.log('===== isNew in KR', isNew)
  // updateMode && console.log('===== owners in KR', owners)
  // updateMode && console.log('===== krOwners in KR', krOwners)
  // // updateMode && console.log('===== profiles in KR', profiles)
  // updateMode && console.log('===== ownerOptions in KR', ownerOptions)
  // updateMode && console.log('===== formData.keyResults[index].owners in KR', formData.keyResults[index].owners)

  return (
    <Fragment>
      <div className={`kr flex space-between ${updateMode ? 'align-centerX' : ''} mb-2 relative ml-15-px pr-3`}>
        {!isHideProgress && (
          <div className="pl-3 hide-xs">
            {okrBoard === '1' &&
              (okrType === 1 ? (
                <Fragment>
                  <div className="flex flex-start">
                    <button
                      className={`btn-action no_btn no-minw bg-transparent absolute-xs icon__grey`}
                      type="button"
                      onClick={(e) => handleMain(okrId, index, okrBoard)}
                    >
                      {isMain ? (
                        <span className="star-box__full__yellow">{icons.star_full}</span>
                      ) : (
                        <span className="star-box">
                          <Fragment>
                            <span className="star-box__empty">{icons.star_empty}</span>
                            <span className="star-box__full">{icons.star_full}</span>
                          </Fragment>
                        </span>
                      )}
                    </button>
                    <div className="h-40px line-new__vertical mx-7"></div>
                  </div>
                </Fragment>
              ) : null)}

            {okrBoard === '2' || okrBoard === '3' ? (
              <Fragment>
                <div className="flex flex-start">
                  <button
                    className="btn-action no_btn no-minw bg-transparent absolute-xs icon__grey"
                    type="button"
                    onClick={(e) => handleMain(okrId, index, okrBoard)}
                  >
                    {isMain ? (
                      <span className="star-box__full__yellow">{icons.star_full}</span>
                    ) : (
                      <span className="star-box">
                        <Fragment>
                          <span className="star-box__empty">{icons.star_empty}</span>
                          <span className="star-box__full">{icons.star_full}</span>
                        </Fragment>
                      </span>
                    )}
                  </button>
                  <div className="h-40px line-new__vertical mx-7"></div>
                </div>
              </Fragment>
            ) : null}
          </div>
        )}

        <div className={`kr__card-title card-answer mr-xs ${formType === 2 ? 'w-300px' : ''}`}>
          <div className={`kr__target__${color} mr-1`}>{icons.target}</div>

          <div className="flex justify-start flex-column">
            {!updateMode ? (
              <p className="kr__target__title fs-16 fw-600 lh-20 cursor" onClick={() => handleDetails(_id, okrId, index)}>
                <Highlighter
                  highlightClassName="bg-yellow_pure"
                  searchWords={[searchKeyword]}
                  autoEscape={true}
                  textToHighlight={title}
                />
              </p>
            ) : (
              <Fragment>
                <FormattedMessage
                  id="enter.kr"
                  defaultMessage="Enter key results"
                >
                  {msg => (
                    <input
                      className="kr__target__title h-30 p-xs outline border__input_main fs-14"
                      type="text"
                      placeholder={msg}
                      name="title"
                      value={title}
                      onChange={formType !== 2 ? (
                        (e) => onChange(e, index)) : (
                        (e) => onChangeOkr(e, indexOkr, index)
                        )
                      }
                    />
                  )}
                </FormattedMessage>
              </Fragment>
            )}
            
            <span className="fs-12">
              
              {!updateMode ? (
                <Fragment>
                  <FormattedMessage id="user.okr.due" defaultMessage="Due date" />:{' '}
                  {dueDate ? <Moment format="D MMM">{dueDate}</Moment> : '–'}
                </Fragment>
              ) : (
                <div className="form-app">
                   <div className="h-35 flex align-center">
                      <span className="fs-12 ml-xs w-80px"><FormattedMessage id="user.okr.due" defaultMessage="Due date" /></span>
                       <DatePickerComponent
                          value={dueDate}
                          // onSelect={(day) => onChangeKRSelect(day, 'dueDate', index)}
                          onSelect={(day) => {
                            onChangeKRSelect(day, 'dueDate', index)}}
                          isUpdate={true}
                        />
                    </div>
                </div>
              )}
             
            </span>
          </div>

          {formType !== 2 && (
            <Fragment>
              {!updateMode ? (
                 <div className="kr__card-owner flex flex-start ml-2 mr-xs text-center">
                 {owners &&
                   owners.length > 0 &&
                   owners.map((o, index) => (
                     <Fragment key={index}>
                         <Link
                           to={o.owner ? `/user/${o.owner}` : '/company/people'}
                           className={`relative h-unset flex flex-start user btn-action ${
                             owners.length > 1 && index > 0 ? '-ml-15' : ''
                           }`}
                         >
                           <img
                             src={o.ownerAvatar && o.ownerAvatar}
                             alt={`user avatar`}
                             className="round-img d-block avatar img-30"
                             width="30"
                             height="30"
                           />
                           <div className="dropdown medium p-1 right-30">
                             {`${o.ownerFirstName} ${o.ownerLastName}`}
                           </div>
                       </Link>
                     </Fragment>
                   ))}
               </div>
              ) : (
                // <FormattedMessage
                //   id="user.okr.create.keyResult.ownerSelect"
                //   defaultMessage="Choose the owner of this key result"
                // >
                //   {(msg) => (
                //     <Select
                //       className="w-200px ml-2"
                //       defaultValue={owners}
                //       value={krOwners}
                //       name="owners"
                //       onChange={(selectedOption) => { 
                //         // console.log('===== ownerOptions on onchange', ownerOptions)
                //         onChangeKRMetricsSelect(selectedOption, index)
                //       }}
                //       options={ownerOptions}
                //       isSearchable
                //       placeholder={msg}
                //       required
                //       isMulti
                //       styles={selectCustomStyles}
                //     />
                //   )}
                // </FormattedMessage>
                null
              )}
            </Fragment>
          )}
        </div>

        {formType === 2 && (
          <div className="w-120px kr__card-owner flex flex-start ml-3 mr-xs text-center">
            {owners &&
              owners.length > 0 &&
              owners.map((o, index) => (
                <Fragment key={index}>
                  <img
                    src={o.ownerAvatar && o.ownerAvatar}
                    alt={`user avatar`}
                    className="round-img d-block avatar img-30"
                    width="30"
                    height="30"
                  />
                  <div className="dropdown medium p-1 right-30">
                    {`${o.ownerFirstName} ${o.ownerLastName}`}
                  </div>
                </Fragment>
              ))}
          </div>
        )}

        {!updateMode ? (
          <Fragment>
            <div className={`kr__target__metric fs-16 lh-15 fw-600 relative text-center`}>
              {index === 0 && (
                <span className="small  grey_new absolute fs-10  kr__sub-title-goals w-100 ">
                  <FormattedMessage id="okr.kr.current_goal" defaultMessage="Start / Current / Goal" />
                </span>
              )}
              {krType.value === 'number' ? 
                (`${start} / ${current} / ${goal}`) : 
                (
                  krType.value === 'binary' ? (
                    details[0].label
                  ) : krType.value === 'milestone' ? (
                    <div className="text-left w-">
                      <Fragment>
                        {details.length > 0 && (
                          details.map((ml, idx) => (
                            !showAllMilestones ? (
                              <Fragment>
                                {activeMilestoneIndex === idx || activeMilestoneIndex !== details.length - 1 && activeMilestoneIndex + 1 === idx || activeMilestoneIndex !== 0 && activeMilestoneIndex - 1 === idx ? (
                                  <div className={`flex align-items`}>
                                    <p className={`fs-14 pr-1 ${activeMilestoneIndex + 1 === idx ? 'grey_new' : activeMilestoneIndex - 1 === idx ? 'grey_new old-price' : ''}`}>{ml.label}</p>
                                    <p className={`w-45px fs-14 mla text-right ${activeMilestoneIndex + 1 === idx ? 'grey_new' :  activeMilestoneIndex - 1 === idx ? 'grey_new old-price' : ''}`}>-{" "}{ml.value}%</p>
                                  </div>
                                ): null}
                              </Fragment>
                            ) : (
                              <div className="flex align-items">
                                <p className="fs-14 pr-1">{ml.label}</p>
                                <p className="fs-14 mla">-{""}{ml.value}%</p>
                              </div>
                            )
                          ))
                        )}
                        <p className="fs-10 grey_new cursor" onClick={(e) => setShowMilestones(!showAllMilestones)}>{!showAllMilestones ? showAllMlText : collapseAllMlText}</p>
                      </Fragment>
                    </div>
                  ) : null
                )}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="flex kr__update__metrics">
              {updateMode && (
                <FormattedMessage
                id="user.okr.create.keyResult.ownerSelect"
                defaultMessage="Choose the owner of this key result"
              >
                {(msg) => (
                  <Select
                    className="kr__update__metrics__owner"
                    defaultValue={owners}
                    value={krOwners}
                    name="owners"
                    onChange={(selectedOption) => { 
                      onChangeKRMetricsSelect(selectedOption, index)
                    }}
                    options={ownerOptions}
                    isSearchable
                    placeholder={msg}
                    required
                    isMulti
                    styles={selectCustomStyles}
                  />
                )}
              </FormattedMessage>
              )}
              <KRmetrics
                formType={formType}
                start={start}
                current={current}
                goal={goal}
                onChange={onChange}
                onChangeOkr={onChangeOkr}
                indexOkr={indexOkr}
                index={index}
                krType={krType}
                locale={locale}
                details={details}
                handleKeyResults={handleKeyResults}
              />
            </div>
          </Fragment>
        )}

        {!updateMode && (
           <div className={`${!isHideProgress ? 'kr__target__progress' : ''}`}>
            <OkrProgress
              progress={progress}
              btnClass={color}
              type={2}
              isHideProgress={isHideProgress ? isHideProgress : false}
            />
          </div>
        )}
      </div>

      {todoBoard &&
        todoBoard === 10 &&
        todos.length > 0 &&
        (!actions ? (
          <p onClick={() => showTasks(!actions)} className="left small grey ml-1 pl-1 mb-1 pb-xs cursor">
            {todos.length} <FormattedMessage id="action.items" defaultMessage="action items" />
          </p>
        ) : (
          <Fragment>
            {todos.map((item, index) => (
              <TaskItemCard
                key={`kr-todo-${item._id}`}
                todo={item}
                formData={formData}
                setFormData={setFormDataParent}
                usersOptions={usersOptions}
                okrOptions={okrOptions}
                priorityOptions={priorityOptions}
                handleSelect={handleSelect}
                handleDueIn={handleDueIn}
                handleComplete={handleComplete}
                index={index}
                user={user}
                handleDelete={handleDelete}
                handleNameForm={handleNameForm}
                todoBoard={todoBoard}
                listView={listView}
                okrBoard={true}
              />
            ))}
            <p onClick={() => showTasks(!actions)} className="left small grey ml-3 pl-xs mb-1 pb-xs cursor">
              <FormattedMessage id="action.items.hide" defaultMessage="Hide action items" />
            </p>
          </Fragment>
        ))}
    </Fragment>
  );
};

KeyResultItem.propTypes = {
  keyResult: PropTypes.object.isRequired,
  addUserMainOkr: PropTypes.func,
  updateOneResult: PropTypes.func,
  isMain: PropTypes.bool,
  okrId: PropTypes.string,
  progress: PropTypes.number,
  index: PropTypes.number,
  updateMode: PropTypes.bool
};

export default connect(null, { addUserMainOkr })(KeyResultItem);
