import React from 'react';
import { FormattedMessage } from 'react-intl';

export const feedbackCopy = {
  msgOnboarding: (
    <FormattedMessage
      id="feedback.onboarding.company.notification.msg1"
      defaultMessage="You're using the sample data below. Create the first feedback and you'll see it here. Click the button below to give feedback to your co-worker."
    />
  ), 
  ctaOnboarding: (
    <FormattedMessage
      id="feedback.onboarding.company.notification.cta"
      defaultMessage="Give Feedback"
    />
  ),
  noFeedbackMessage: {
    title: (
      <FormattedMessage
        id="company.nofeedback.user.title"
        defaultMessage="Feedback"
      />
    ),
    msg: (
      <FormattedMessage
        id="company.nofeedback.company.msg"
        defaultMessage="It's the section for people's feedback. Also, you can ask for feedback from your teammates. Just click on the button 'Ask for feedback' above to get it."
      />
    )
  },
  updatePlanMsg: {
    title: (
      <FormattedMessage
        id="company.updateplan.title"
        defaultMessage="Update Plan"
      />
    ),
    msg: (
      <FormattedMessage
        id="company.feedback.updateplan.msg"
        defaultMessage={`Feedback isn't available on your plan. Please upgrade the plan to unlock this feature.`}
      />
    )
  }
}

export default {
  feedbackCopy
}