import axios from 'axios';
import { setAlert } from './alert';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  GET_SLACK_USERS,
  GET_SLACK_CHANNELS,
  GET_SLACK_CHANNELS_MEMBERS,
  SLACK_USERS_ERROR,
  SLACK_CHANNELS_ERROR,
  SLACK_CHANNELS_MEMBERS_ERROR,
  GET_SLACK_TEAM,
  SLACK_TEAM_ERROR,
  SLACK_SETTINGS_ERROR,
  GET_STANDUPS,
} from './types';

// Edit standup
export const editSlackTeam = (formData, history) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(`/api/slackapp/okr/team`, formData, config);

    dispatch({
      type: GET_SLACK_TEAM,
      payload: res.data
    });

    dispatch(
      setAlert(
        <FormattedMessage
          id="alert.slack.edit"
          defaultMessage="Settings were updated"
        />,
        'success'
      )
    );

    history.push('/settings/integrations');
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: SLACK_TEAM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit Slack synchronization settings
export const editSlackSync = (formData, history) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(`/api/slackapp/sync/team`, formData, config);

    dispatch({
      type: GET_SLACK_TEAM,
      payload: res.data
    });

    dispatch(
      setAlert(
        <FormattedMessage
          id="alert.slack.edit"
          defaultMessage="Settings were updated"
        />,
        'success'
      )
    );

    history.push('/settings/integrations');
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: SLACK_TEAM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit slack settings
export const editSlackSettings = (formData, planId, history) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }; 
    
    const body = JSON.stringify(formData);
    const res = await axios.put(`/api/slackapp/settings`, body, config);

    dispatch({
      type: GET_STANDUPS,
      payload: res.data.standup
    });

    dispatch(
      setAlert(
        <FormattedMessage
          id="alert.slack.edit"
          defaultMessage="Settings were updated"
        />,
        'success'
      )
    );

    history.push('/settings/integrations');
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: SLACK_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get Slack channels for standup report
export const getSlackTeam = () => async dispatch => {
  try {

    const res = await axios.get(`/api/slackapp/team`);
   
    dispatch({
      type: GET_SLACK_TEAM,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SLACK_TEAM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get Slack users for standup report
export const getSlackUsers = (companyId, slackId) => async dispatch => {
  try {
    if (!slackId) {
      dispatch(
        setAlert(
          `Something went wrong! We don't see your team in Slack. Please drop us a line on ${process.env.REACT_APP_EMAIL}. We will check it.`,
          'danger',
          10000
        )
      );
    }

    const res = await axios.get(`/api/slackapp/users/${companyId}`);

    dispatch({
      type: GET_SLACK_USERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SLACK_USERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Slack channels for standup report
export const getSlackChannels = (companyId, slackId) => async dispatch => {
  try {

    // Remove this notification because slackId sometimes comes with delay
    if (!slackId) {
      // dispatch(
      //   setAlert(
      //     `Something went wrong! We don't see your team channels in Slack. Please drop us a line on ${process.env.REACT_APP_EMAIL}. We will check it.`,
      //     'danger',
      //     10000
      //   )
      // );
    }

    const res = await axios.get(`/api/slackapp/channels/${companyId}`);

    dispatch({
      type: GET_SLACK_CHANNELS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SLACK_CHANNELS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Slack channels for standup report
export const getSlackChannelsMembers = (companyId, slackId) => async dispatch => {
  try {

    const res = await axios.get(`/api/slackapp/channels/members/${companyId}`);

    dispatch({
      type: GET_SLACK_CHANNELS_MEMBERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SLACK_CHANNELS_MEMBERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};