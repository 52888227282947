import React, { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCustomSettings, editCustom, deleteCustom } from '../../actions/custom';
import { getUserCompany } from '../../actions/company';
import { getSlackUsers, getSlackChannels } from '../../actions/slack';
import { getDiscordChannels, getDiscordFocusUsers } from '../../actions/discord';
import { getCompanyProfiles } from '../../actions/profile';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import Subtitle from './elems/Subtitle';
import QuestionItemNew from './elems/QuestionItemNew';
import QuestionItemArrayNew from './elems/QuestionItemArrayNew';
import AddQuestion from './elems/AddQuestion';
import 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import Menu from '../layout/Menu';
import { icons } from '../../img/elements/icons';
import ErrorBoundary from '../_helpers/ErrorBoundary';
import CheckinSettings from './elems/CheckinSettings';
import Notifications from './elems/Notifications';
import Loader from 'react-loader-spinner';

const weekDays = [
  {index: 1, day: "Monday", isActive: false},
  {index: 2, day: "Tuesday", isActive: false},
  {index: 3, day: "Wednesday", isActive: false},
  {index: 4, day: "Thursday", isActive: false},
  {index: 5, day: "Friday", isActive: false},
  {index: 6, day: "Saturday", isActive: false},
  {index: 0, day: "Sunday", isActive: false},
]

let reportFrequencyOptions = [
  { label: 'On completed check-in', value: 1, name: 'reportFrequency' },
  { label: 'Once a day', value: 2, name: 'reportFrequency' },
  { label: 'Once a week', value: 3, name: 'reportFrequency' },
  { label: 'Biweekly', value: 4, name: 'reportFrequency' },
  { label: 'Once a month', value: 5, name: 'reportFrequency' },
];

const EditCustom = ({
  getCustomSettings,
  editCustom,
  deleteCustom,
  custom: { settings, loading },
  auth: { user },
  history,
  getUserCompany,
  profile: { profiles },
  getCompanyProfiles,
  company: { company },
  slack,
  getSlackUsers,
  getSlackChannels,
  match,
  getDiscordChannels, 
  getDiscordFocusUsers,
  discord,
}) => {
  const [formData, setFormData] = useState({
    name: '',
    questions: [],
    scheduleTime: '',
    scheduleDay: {},
    interval: '',
    timezone: '',
    emailIsActive: !!settings && settings.emailIsActive,
    slackIsActive: !!settings && settings.slackIsActive,
    reportIsActive: !!settings && settings.reportIsActive,
    localReportTime: '',
    localReportHour: '',
    localReportMinute: '',
    isAsync: settings && settings.isAsync,
    slackReportChannels: settings && settings.slackReportChannels,
    selectedOption: [],
    selectedChannelOption: [],
    selectedEmailUsers: [],
    selectedSlackUsers: [],
    okrQuestion: settings && settings.okrQuestion,
    lang: '',
    discordIsActive: settings && settings.discord && settings.discord.isActive,
    selectedDiscordUsers: [],
    discordReportChannels: [],
    discordUserOptions: [],
    discordConnections: [],
    isCheckinReport: '',
    reportFrequency: {},
    visibility: {},
  });

   const {
    name, questions, scheduleTime,
    scheduleDay, interval, timezone,
    slackIsActive, emailIsActive,
    localReportHour,localReportMinute,
    reportIsActive,
    slackReportChannels,
    discordConnections,
    reportFrequency,
  } = formData;

  const [reportChannels, toggleReportChannels] = useState(false);
  const [emailUsersForm, toggleEmailUsers] = useState(false);
  const [slackUsersForm, toggleSlackUsers] = useState(false);
  const [discordUsersForm, toggleDiscordUsers] = useState(false);
  const [discordReportForm, toggleDiscordReportChannels] = useState(false);
  const [showNotifications, toggleNotifications] = useState(true);
  const [isLoader, toggleLoader] = useState(false);

  let options = [];
  let choosedUsers = [];
  let channelOptions = [];
  let choosedChannels = [];
  let choosedEmailUsers = [];
  let emailUsersOptions = [];
  let choosedSlackUsers = [];
  let slackUserAndChannelOptions = [];
  let choosedSlackReportChannels = [];
  let slackReportChannelsOptions = [];
  let discordUserAndChannelOptions = [];
  let discordUserOptionsTemplate = [];

  // Get Email Users to put them in select
  // Choosed users
  settings && settings.emailUsers.forEach(user => {
    let u = {
      value: user.email,
      label: user.team === 1 ? 'Everyone in Focus' : user.name,
      team: user.team,
      name: 'selectedEmailUsers'
    }

    choosedEmailUsers.push(u);
  })

  // Users for options
  {
    profiles &&
      profiles.forEach((profile) => {

        let coworker = {
          value: profile.email,
          label: `${profile.firstName} ${profile.lastName}`,
          team: 3,
          id: profile._id.toString(), 
          name: 'selectedEmailUsers',
        };

        emailUsersOptions.push(coworker);
      });
  }

  {
    company && company.departments.length > 0 &&
      company.departments.forEach((department, index) => {

        if (user && user.department === null) {
          let team = {
            value: department._id,
            label: `${department.name} team`,
            team: 2,
            name: 'selectedEmailUsers',
          };
  
          emailUsersOptions.unshift(team);
        } else if (user && user.department !== null && department._id !== user.department) {
          let team = {
            value: department._id,
            label: `${department.name} team`,
            team: 2,
            name: 'selectedEmailUsers'
          };
  
          emailUsersOptions.unshift(team);
        }

        if (user && index === company.departments.length - 1) {

          // Second option is the team if exists
          if (user.department && department._id === user.department) {
            let team = { value: department._id, label: `${department.name} team`, team: 2, name: 'selectedEmailUsers' };
            emailUsersOptions.unshift(team);
          }

          // Third option is the company
          let name = { value: 'Company', label: `Everyone in Focus`, team: 1, name: 'selectedEmailUsers' };
          emailUsersOptions.unshift(name);
          
        }
      });
  }

  // Get Slack Users to put them in select (for participants select)
  // Choosed users
  settings && settings.slackUsers.forEach(user => {
    let u = {
      value: user.team === 1 ? 'Workspace' : user.value,
      label: user.team === 1 ? 'Everyone in Slack' : user.name,
      email: user.email,
      team: user.team,
      name: 'selectedSlackUsers'
    }

    choosedSlackUsers.push(u);
  })

  // Slack options (users + channels in one arr)
  slack.users &&
    slack.users.forEach((user) => {
      let u = {
        value: user.user_id,
        label: user.name,
        email: user.email,
        team: 3,
        name: 'selectedSlackUsers'
      };

      slackUserAndChannelOptions.push(u);
    });

  slack.channels &&
    slack.channels.forEach((channel, index) => {
      let u = {
        value: channel.channel_id,
        label: `#${channel.name}`,
        team: 2,
        name: 'selectedSlackUsers'
      };

      slackUserAndChannelOptions.unshift(u);

      if (slack && index === slack.channels.length - 1) {
         // First option is the company
          let name = { value: 'Workspace', label: `Everyone in Slack`, team: 1, name: 'selectedSlackUsers' };
          slackUserAndChannelOptions.unshift(name);
       }
    });

  // Users Options for Select format (for delivery report)
  slack.users &&
    slack.users.forEach(user => {
      let u = {
        value: user.user_id,
        label: user.name,
        avatar: user.avatar
      };

      options.push(u);
      slackReportChannelsOptions.push(u);
    });

  // Channels Options for Select format
  slack.channels &&
    slack.channels.forEach(channel => {
      let u = {
        value: channel.channel_id,
        label: channel.name
      };

      channelOptions.push(u);
      slackReportChannelsOptions.push(u);
    });

  // Users & Channels Default values for Select format
  slackReportChannels &&
    slackReportChannels.forEach(item => {
      if (item.user_id) {
        let с = {
          value: item.user_id,
          label: item.name
        };

        choosedUsers.push(с);
        choosedSlackReportChannels.push(с);
      }

      // For channels
      if (item.channel_id) {
        let с = {
          value: item.channel_id,
          label: item.name
        };

        choosedChannels.push(с);
        choosedSlackReportChannels.push(с);
      }
    });

  // Discord options (users + channels in one arr)
  discord.users &&
  discord.users.forEach((user, index) => {

    if (discord && index === discord.users.length - 1) {
      // First option is the company
      let name = { value: 'Company', label: `Everyone`, team: 1 };
      discordUserOptionsTemplate.unshift(name);
    }

    discordUserOptionsTemplate.push(user);
    discordUserAndChannelOptions.push(user);
  });

  discord.channels &&
  discord.channels.forEach((channel, index) => {
    let c = {
      value: channel.value,
      label: `#${channel.label}`,
      type: channel.type,
      team: 2
    };

    discordUserAndChannelOptions.unshift(c);

  }); 

  useEffect(() => {
    getCustomSettings(match.params.id);
    getUserCompany();
    getCompanyProfiles();

    if (company) {
      if (company.isSlack) {
        if (!slack || !slack.users) {
          getSlackUsers(company._id, company.slackTeamId);
        }
        if (!slack || !slack.channels) {
          getSlackChannels(company._id, company.slackTeamId);
        }
      }
      if (company.discord && company.discord.isActive) {
        getDiscordFocusUsers(company._id, company.discord.guildId);
        getDiscordChannels(company._id, company.discord.guildId);
      }
    }

    setFormData({
      ...formData,
      name: !settings ? '' : settings.name,
      questions: !settings ? '' : settings.questions,
      scheduleTime: !settings ? '' : settings.scheduleTime,
      // scheduleTime: !settings ? '' : Moment().hour(settings.scheduleTime.split(":")[0]).minute(settings.scheduleTime.split(":")[1]),
      scheduleDay: !settings ? '' : settings.scheduleDay,
      interval: !settings ? '' : settings.interval,
      timezone: !settings ? '' : settings.timezone,
      emailIsActive: !settings ? false : settings.emailIsActive,
      localReportTime: !settings ? '' : settings.localReportTime,
      localReportHour: !settings ? '' : settings.localReportTime.slice(0, 2),
      localReportMinute: !settings ? '' : settings.localReportTime.slice(3),
      reportIsActive: !settings ? false : settings.reportIsActive,
      slackIsActive: !settings ? false : settings.slackIsActive,
      isAsync: !settings ? false : settings.isAsync,
      slackReportChannels: !settings ? false : settings.slackReportChannels,
      selectedOption: !slack || !settings ? '' : choosedUsers,
      selectedChannelOption: !slack || !settings ? '' : choosedChannels,
      selectedEmailUsers: !settings ? '' : choosedEmailUsers,
      selectedSlackUsers: !slack || !settings ? '' : choosedSlackUsers,
      lang: company === null || !company && !company.language ? 'en': company.language,
      discordIsActive: !settings || !settings.discord ? false : settings.discord.isActive,
      discordReportChannels: !settings || !settings.discord ? '' : settings.discord.reportChannels,
      selectedDiscordUsers: !discord || !settings || !settings.discord ? '' : settings.discord.channels,
      discordUserOptions: !discord ? [] : discord.users,
      isCheckinReport: !settings ? false : settings.isCheckinReport,
      reportFrequency: !settings ? false : settings.reportFrequency,
      visibility: !settings ? false : settings.visibility,
    });

  }, [loading, getCustomSettings, getCompanyProfiles,
    getUserCompany, match.params.id,
    settings && settings.companyName && settings.companyName.length,
    getSlackUsers, getSlackChannels,
    company && company.discord && company.discord.isActive,
  ]);

  useEffect(() => {
    setFormData({
      ...formData,
      discordUserOptions: !discord ? [] : discord.users,
    });
  }, [JSON.stringify(discord)]);

  // Select hour & minute options
  const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  const hourOptions = hours.map(hour => {
    return (
      { label: hour, value: hour, name: 'localReportHour' }
    );
  });

  const minutes = ['00', '15', '30', '45'];
  const minuteOptions = minutes.map(minute => {
    return (
      { label: minute, value: minute, name: 'localReportMinute' }
    );
  });

  // console.log('===== choosedSlackUsers', choosedSlackUsers)

  const handleUsers = selectedOption => {
    // ToDo: save deliver results channel - selectedOption
    // console.log('===== selectedOption in handleSlackUsers', selectedOption)
    // console.log('===== choosedSlackUsers in handleSlackUsers', choosedSlackUsers)
    // setFormData({ ...formData, selectedSlackUsers: selectedOption });

    let reportChannels = [];

    // If null, then no channel
    if (selectedOption !== null) {
      selectedOption.filter(({ name }) => name === 'selectedSlackUsers').forEach(o => {
        // console.log('===== o', o)
        // console.log('===== o.team', o.team)

        let option = o.team !== 1 ? o : options[0];

        if (option) {
          let isNewChannel = choosedSlackUsers.filter(s => s.value === option.value);
          // console.log('===== isNewChannel', isNewChannel)

          // If channel is new
          if (isNewChannel.length === 0) {
            option.isNew = true;
          }

          reportChannels.push(option)
        }
      })
    }
    // console.log('===== reportChannels', reportChannels)

    setFormData({
      ...formData,
      selectedEmailUsers: (selectedOption || []).filter(({ name }) => name === 'selectedEmailUsers'),
      selectedSlackUsers: (selectedOption || []).filter(({ name }) => name === 'selectedSlackUsers'),
      selectedOption: reportChannels
    });
  };

  const handleSlackUsersForm = e => {
    toggleSlackUsers(!slackUsersForm);
  };
  
  const handleChangeReport = selectedOption => {
    setFormData({ ...formData, selectedOption: selectedOption });
  };

  const handleChangeChannelReport = selectedChannelOption => {
    setFormData({ ...formData, selectedChannelOption: selectedChannelOption });
  };

  const handleChangeSlackChannelReport = options => {
    let newSlackReportChannels = [];
    let newSelectedOption = [];
    let newSelectedChannelOption = [];
    if (options) {
      options.forEach((option) => {
        if (slack.users) {
          const user = slack.users.find(({ user_id }) => user_id === option.value);
          if (user) {
            newSlackReportChannels.push(user);
            newSelectedOption.push(option);
          }
        }
        if (slack.channels) {
          const channel = slack.channels.find(({ channel_id }) => channel_id === option.value);
          if (channel) {
            newSlackReportChannels.push(channel);
            newSelectedChannelOption.push(option);
          }
        }
      });
    }
    setFormData({
      ...formData,
      slackReportChannels: newSlackReportChannels,
      selectedOption: newSelectedOption,
      selectedChannelOption: newSelectedChannelOption
    });
  };  

  const handleReport = e => {
    toggleReportChannels(!reportChannels);
  };

  const handleSelectChange = (selectedOption, index, type) => {
    // console.log('===== selectedOption in handleSelectChange', selectedOption)
    // console.log('===== index in handleSelectChange', index)
    // console.log('===== type in handleSelectChange', type)

    if (type === 'isRequired') {
      if (selectedOption === 'false') { selectedOption = true }
      if (selectedOption === 'true') { selectedOption = false }
    } else if (type === 'questionType') {

      if (selectedOption.value !== 'text') {
        if (!questions[index].selectOptions || questions[index].selectOptions && questions[index].selectOptions.length === 0) {
          questions[index].selectOptions = [{ label: '', value: '' }]
        }
      // console.log('=======  questions[index].selectOptions in handleSelectChange',  questions[index].selectOptions)
      }
    }

    questions[index][type] = selectedOption

    setFormData({ ...formData, questions });
  };

  const onChangeOptions = (e, indexQuestion, indexOption) => {
    questions[indexQuestion].selectOptions[indexOption].label = e.target.value;
    // questions[indexQuestion].selectOptions[indexOption].value = e.target.value;
    if (!questions[indexQuestion].selectOptions[indexOption].value) {
      questions[indexQuestion].selectOptions[indexOption].value = indexOption+1
    }

    setFormData({ ...formData, questions: questions });
  };

  const onChangeQuestionTasks = (e, questionIdx, name) => {

    // console.log('======= name in onChangeQuestionTasks', name)

    let value = e.target.value;

    if (value === 'false') { value = true }
    if (value === 'true') { value = false }

    questions[questionIdx][name] = value;

    setFormData({ ...formData, questions: questions });
  };
  // console.log('======= questions in onChangeQuestionTasks', questions)

  const handleKeypressOptions = (e, idxQuestion, idx) => {
    // it triggers by pressing the enter key
    if (e.key === 'Enter') {
      e.preventDefault();

      questions[idxQuestion].selectOptions.push({ label: '', value: '' })

      setFormData({ ...formData, questions: questions });

      // focus on the next element
      const form = e.target.form;
      const index = [...form].indexOf(e.target);

      // console.log('======= form[index] in handleKeypressOptions', form[index])
      // console.log('======= form[index+1] in handleKeypressOptions', form[index+1])
      
      let prev = document.getElementById(`selectOption-${idx}`)
      let next = document.getElementById(`selectOption-${idx+1}`)
      // console.log('======= prev in handleKeypressOptions', prev)
      // console.log('======= next in handleKeypressOptions', next)
      form[index+1].focus();
    }
  };


  const onChangeSelect = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onChangeTimezone = (timezone, name) => {
    setFormData({ ...formData, [name]: timezone });
  };
  
  const onChangeDay = (selectedOption) => {
    scheduleDay.day = selectedOption.label;
    scheduleDay.index = selectedOption.value;

    setFormData({ ...formData, scheduleDay: scheduleDay });
  };

  const onChangeDate = (e) => {
    scheduleDay.index = e.target.value;
    
    setFormData({ ...formData, scheduleDay: scheduleDay });
  };

  const handleDaysChange = (e, index) => {
    scheduleDay.weekDays[index].isActive = !scheduleDay.weekDays[index].isActive;

    setFormData({ ...formData, scheduleDay: scheduleDay });
  };


  const onSubmit = e => {
    e.preventDefault();

    editCustom(formData, match.params.id, history);
  };

  const onChange = (e, index) => {
    // questions[index] = e.target.value;
    questions[index].text = e.target.value;


    setFormData({ ...formData, questions: questions });
  };

  const handleRemove = index => {
    questions.splice(index, 1);

    setFormData({ ...formData, questions: questions });
  };

  const handleAdd = e => {
    e.preventDefault();

    let newQuestion = { text: '', questionType: { label: `Q${questions.length+1}: Text Field`, value: 'text' }, isRequired: true, isTask: false }

    setFormData({ ...formData, questions: [...questions, newQuestion] });
  };

  // Add 12 hours if it's pm time
  const formatTime = (time) => {

    const timeArr = time.split(":");
    
    let hours = +timeArr[0] + 12;
    const result = `${hours}:${timeArr[1]}`

    return result;
  };

  // const handleTime = e => {
  //   setFormData({ ...formData, scheduleTime: e });
  // };

  const handleTime = value => {
    const format = 'h:mm a'; 
    // const format = 'h:mm'; 

    let timeArr = value.format(format).split(' ');
    let timeFormat = timeArr[1];

    let time = timeFormat === 'am' ? timeArr[0] : formatTime(timeArr[0]);

    setFormData({ ...formData, scheduleTime: time });
  };

  const handleSelect = (selectedOption, type) => {
    setFormData({ ...formData, [selectedOption.name]: selectedOption });
  };

  const handleIntervalSelect = (selectedOption) => {

    if (selectedOption.label !== 'Weekly' && selectedOption.label !== 'Biweekly') {
      setFormData({ ...formData, [selectedOption.name]: selectedOption.value });
    } else {
      // Check that index is correct because it might be changes in the monhtly interval
      let choosenItem = weekDays.filter(d => d.day === scheduleDay.day)

      scheduleDay.index = choosenItem.length > 0 ? choosenItem[0].index.toString() : scheduleDay.index;

      setFormData({ ...formData, [selectedOption.name]: selectedOption.value, scheduleDay });
    }
  };

  // Discord
  const handleDiscordUsersForm = e => {
    toggleDiscordUsers(!discordUsersForm);
  };

  const handleDiscordUsers = selectedOption => {
    setFormData({ ...formData, selectedDiscordUsers: selectedOption });
  };

  const handleChangeDiscordReport = selectedOption => {
    setFormData({ ...formData, discordReportChannels: selectedOption });
  };

  const handleDiscordReport = e => {
    toggleDiscordReportChannels(!discordReportForm);
  };

  const onChangeConnectDiscord = (selectedOption, user) => {
    let obj = {
      user,
      selectedOption
    }
    
    discordConnections.push(obj);
    
    setFormData({ ...formData, discordConnections: discordConnections });
  };

  const edit = <FormattedMessage id="edit" defaultMessage="Edit" />;
  const campaignName =<FormattedMessage id="campaign.name" defaultMessage="Campaign name" />;
  const nameTitle =<FormattedMessage id="name" defaultMessage="Name" />;
  const questionSectionTitle =<FormattedMessage id="checkin.question" defaultMessage="Check-in Questions" />;

  const questionTitle =<FormattedMessage id="question" defaultMessage="Question" />;
  const editTitle = <FormattedMessage id="edit" defaultMessage="Edit" />;
  const campaignDescription = <FormattedMessage id="campaign.descriptions.edit" defaultMessage="Edit your custom check-ins campaign here. After creating a campaign, users will get notifications according to the time you set. You will see all answers in the Check-in menu." />;
  
  const checkinsSectionTitle =<FormattedMessage id="checkin.settings" defaultMessage="Check-in Settings" />;
  const checkinsDescription =<FormattedMessage id="checkin.desc" defaultMessage="" />;
  const questionsDescription = <FormattedMessage id="campaign.questions" defaultMessage="Add questions you want to ask your team." />;
  const notificationsTitle =<FormattedMessage id="notifications" defaultMessage="Notifications" />;

  // Selects
  const handleCheckbox = (name, value) => {

    let title = 'slackIsActive';
    if (name === 'isAsync') { title = 'isAsync' }

    setFormData({ ...formData, [title]: value });
  };

  let btnTxt = <FormattedMessage id="create.checkin" defaultMessage="Create Check-in" />;
  let cnclBtnTxt = <FormattedMessage id="cancel" defaultMessage="Cancel" />;
  
  // Loader for button
  let loadingIcon = (
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Loader type="Circles" color="#ffffff" height="14px" width="15" />
    </span>
  );

  if (isLoader) {
    btnTxt = loadingIcon;
  }

  const goBack = () => {
    if (history !== undefined) {
      return history.goBack();
    } else {
      return '/settings';
    }
  };

  // console.log('==== slack', slack)
  // console.log('==== options', options)
  // console.log('==== formData', formData)
  // console.log('==== questions', questions)
  // console.log('==== localReportMinute', localReportMinute)
  
  return (
    <Fragment>
      <div className="css-main bg-primary">
          <div className="wrapper">
            {user && user.department ? (
              <Menu companyName={user && user.companyName} team={user.departmentName} teamId={user.department} settings={user.isAdmin && true} outreach={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={9} />
            ) : (
              <Menu companyName={user && user.companyName} settings={user && user.isAdmin && true} activeIndex={9} />
            )}
            <UserNav />

            <Fragment>
              {!settings || loading ? (
                <Spinner />
              ) : (
                  <div className=" pl-5 pr-5 main-profile-admin pt-0 pb-5">
                    <div className="standups-admin">

                      <div className="mxa checkin__settings__form mt-56">
                        <div className="content">
                          <div className="section-title">
                            <h5 className='lh-35 fw-600'>
                              {editTitle}{" "}{name}
                            </h5>
                            <Link
                              to="/settings"
                              className="lh-15"
                            >
                              {icons.circle_close}
                            </Link>
                          </div>

                          <div className="content__description mt-xs">
                            <p className="fs-12 lh-15 text-left">{campaignDescription}</p>
                          </div>

                          <ErrorBoundary>
                            <div className="content__form edit-updates-weekly my-2">
                              <div className="form-full">
                                <form
                                  className="form-app form-app-update"
                                  onSubmit={e => onSubmit(e)}
                              >
                                
                                <div className="mb-2">
                                  <div className="mb-07">
                                    <Subtitle title={campaignName} />
                                  </div>
                                  <QuestionItemNew
                                    text={nameTitle}
                                    value={name}
                                    name={name}
                                    inputName={'name'}
                                    onChange={onChangeSelect}
                                    stylesInput="w-100 h-44 fs-14"
                                  />
                                </div>

                                <div className="mb-2">
                                  <div className="mb-07">
                                    <Subtitle title={questionSectionTitle} />
                                  </div>
                                  <p className="fs-12 lh-15 text-left">{questionsDescription}</p>
                                </div>

                                {questions.length > 0 && (
                                  questions.map((item, index) => (
                                    <QuestionItemArrayNew
                                      key={`question-${item._id} - ${index}`}
                                      text={questionTitle}
                                      question={item}
                                      index={index}
                                      onChange={onChange}
                                      handleRemove={handleRemove}
                                      handleAdd={handleAdd}
                                      // questionOptions={questionOptions}
                                      handleSelectChange={handleSelectChange}
                                      onChangeOptions={onChangeOptions}
                                      handleKeypressOptions={handleKeypressOptions}
                                      onChangeQuestionTasks={onChangeQuestionTasks}
                                      type={2}
                                    />
                                  ) 
                                  ))}
                                <div className="mt-2">
                                  <AddQuestion handleAdd={handleAdd} />
                                </div> 

                                <div className="sub-menu__page mb-2"></div>

                                <div className="mt-3 mb-2">
                                  <div className="mb-07">
                                    <Subtitle title={checkinsSectionTitle} />
                                  </div>
                                </div>

                                <CheckinSettings 
                                  formData={formData}
                                  setFormData={setFormData}
                                  scheduleTime={scheduleTime}
                                  handleTime={handleTime}
                                  timezone={timezone}
                                  onChangeTimezone={onChangeTimezone}
                                  interval={interval}
                                  onChangeSelect={onChangeSelect}
                                  scheduleDay={scheduleDay}
                                  onChangeDay={onChangeDay}
                                  emailIsActive={emailIsActive}
                                  reportIsActive={reportIsActive}
                                  localReportHour={localReportHour}
                                  hourOptions={hourOptions}
                                  localReportMinute={localReportMinute}
                                  minuteOptions={minuteOptions}
                                  handleDaysChange={handleDaysChange}
                                  onChangeDate={onChangeDate}
                                  profiles={profiles}
                                  handleUsers={handleUsers}
                                  emailUsersOptions={emailUsersOptions}
                                  slackUserAndChannelOptions={slackUserAndChannelOptions}
                                  handleIntervalSelect={handleIntervalSelect}
                                  showNotifications={showNotifications}
                                  toggleNotifications={toggleNotifications}
                                  type={2}
                                />

                                <div className="flex align-start mt-5 mb-2">
                                  <div className="flex align-center" onClick={() => toggleNotifications(!showNotifications)}>
                                    <p className="fs-14 fw-600 mr-1 cursor">{notificationsTitle}</p>
                                    {!showNotifications ? icons.angle_down : icons.angle_up}
                                  </div>
                                  <div className="sub-menu__page ml-4 w-100 pb-xs cursor"></div>
                                </div>

                                {showNotifications && (
                                  <Notifications 
                                    formData={formData}
                                    setFormData={setFormData}
                                    company={company}
                                    toggleEmailUsers={toggleEmailUsers}
                                    emailUsersForm={emailUsersForm}
                                    profiles={profiles}
                                    emailIsActive={emailIsActive}
                                    slackIsActive={slackIsActive}
                                    reportIsActive={reportIsActive}
                                    localReportHour={localReportHour}
                                    hourOptions={hourOptions}
                                    localReportMinute={localReportMinute}
                                    minuteOptions={minuteOptions}
                                    handleTime={handleTime}
                                    handleIntervalSelect={handleIntervalSelect}
                                    reportFrequency={reportFrequency}
                                    reportFrequencyOptions={reportFrequencyOptions}
                                    handleChangeSlackChannelReport={handleChangeSlackChannelReport}
                                    slackReportChannels={choosedSlackReportChannels}
                                    slackReportChannelsOptions={slackReportChannelsOptions}
                                    type={2}
                                    handleSelect={handleSelect}
                                  />
                                )}
                                

                                <div className="flex space-between align-center">
                                  <div className="flex align-center">
                                    <button 
                                      type="submit"
                                      className="mw-150 fs-18 btn btn-small btn-submit btn__secondary_green lh-54 block ml-0-xs"
                                      disabled={!isLoader ? false : true}
                                      onClick={onSubmit}
                                    >
                                      {btnTxt}
                                    </button>
                                    <button
                                      type="reset"
                                      className="mw-150 lh-54 ml-2 btn btn-small fs-18 btn-secondary-back-upd"
                                      onClick={goBack}
                                      disabled={!isLoader ? false : true}
                                    >
                                      {cnclBtnTxt}
                                    </button>
                                  </div>
                                </div>
                                </form>
                              </div>
                            </div>
                          </ErrorBoundary>
                        </div>

                      </div>
                    </div>
                  </div>
                )}
            </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

EditCustom.propTypes = {
  getCustomSettings: PropTypes.func.isRequired,
  editCustom: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getSlackUsers: PropTypes.func,
  getSlackChannels: PropTypes.func,
  getCompanyProfiles: PropTypes.func.isRequired,
  getDiscordChannels: PropTypes.func.isRequired,
  getDiscordFocusUsers: PropTypes.func.isRequired,
  deleteCustom: PropTypes.func.isRequired,
  custom: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  slack: PropTypes.object,
  discord: PropTypes.object,
};

const mapStateToProps = state => ({
  company: state.company,
  custom: state.custom,
  slack: state.slack,
  discord: state.discord,
  profile: state.profile,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { 
    getCustomSettings,
    editCustom,
    deleteCustom,
    getUserCompany,
    getSlackUsers,
    getSlackChannels,
    getCompanyProfiles,
    getDiscordChannels, 
    getDiscordFocusUsers,
  }
)(withRouter(EditCustom));