import React from 'react';
import { FormattedMessage } from 'react-intl';

const mood = <FormattedMessage id="mood" defaultMessage="Mood" />;
const kudos = <FormattedMessage id="kudos" defaultMessage="Kudos" />;

export const menuTitle = <FormattedMessage id="menu.mood.kudos" defaultMessage="Mood & Kudos" />;

export const menuItems = [
  {
      title: mood,
      url: '/mood',
    },
    {
      title: kudos,
      url: '/kudos',
    },
  ]
