import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../../../actions/auth';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../../layout/Spinner';
import { prepareOkrOnboarding } from '../../../../actions/okr';
import { setAlert } from '../../../../actions/alert';
import OkrCreate from './OkrCreate';

const endOfTheYear = new Date();
endOfTheYear.setMonth(11);
endOfTheYear.setDate(31);

const Onboarding2 = ({ 
  user,
  prepareOkrOnboarding,
  setAlert,
  history,
}) => {
  const [formData, setFormData] = useState({
    objective: '',
    dueIn: endOfTheYear,
  });

  const { objective, dueIn, } = formData;
  
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlgeDayChange = (day) => {
    setFormData({ ...formData, dueIn: day });
  };

  const handleStepClick = () => {
    if (objective.length === 0) {
      return setAlert(
        <FormattedMessage
          id="please.enter.objective"
          defaultMessage="Please enter the objective"
        />,
        'danger'
      );
    }

    prepareOkrOnboarding(formData, history);
  };

  // console.log('===== formData', formData)

  return (
    <Fragment>
      {!user ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="css-main onboarding bg__white">
            <div className="wrapper">
              <div className="flex">
                <OkrCreate
                  onChange={onChange}
                  handlgeDayChange={handlgeDayChange}
                  handleStepClick={handleStepClick}
                  objective={objective}
                  dueIn={dueIn}
                />
                
                <div className={`onboarding__image onboarding__image__okr1`}></div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

Onboarding2.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  prepareOkrOnboarding: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  locale: state.locale
});

export default connect(
  mapStateToProps,
  { login, prepareOkrOnboarding, setAlert }
)(Onboarding2);
