import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    paddingBottom: 20,
    marginBottom: 20
  },
});


const Table = ({ data }) => (
  <View style={styles.tableContainer}>
    <TableHeader />
    <TableRow items={data} />
    {/*<TableFooter items={data.items} />*/}
  </View>
);

export default Table;