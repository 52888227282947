import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Menu from '../layout/Menu';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import UpdateItem from '../updates-new/noState/UpdateItem';
import TeamMenu from './TeamMenu';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/1.jpg';
import Notification from '../utils/Notification';
import { getCompanyProfiles } from '../../actions/profile';
import { getTeamUpdateAnswers } from '../../actions/updateAnswer';
import { getUserCompany } from '../../actions/company';
import { getCompanySortedOkrs } from '../../actions/okr';
import { onboardingUpdates } from '../../utils/onboarding/updates';
import { updateCopy } from '../../utils/wording/updates';
import { FormattedMessage } from 'react-intl';
import Moment from 'react-moment';

const TeamUpdates = ({
  getCompanySortedOkrs,
  getUserCompany,
  getCompanyProfiles,
  okr: { okrs },
  auth: { user },
  company: { company },
  profile: { profiles },
  updateAnswer: { updateAnswers, loading },
  getTeamUpdateAnswers,
}) => {
  useEffect(() => {
    getUserCompany();
    getTeamUpdateAnswers();
    getCompanyProfiles();
    getCompanySortedOkrs();

  }, [getUserCompany, getTeamUpdateAnswers, loading, getCompanyProfiles, getCompanySortedOkrs]);

  const noUpdatesMessageTeam = {
    title: <FormattedMessage id="company.noupdates.user.title" defaultMessage="No Updates" />,
    msg: 
    <FormattedMessage id="company.updates.user.msg" defaultMessage={`${company && company.name} has not finished weekly update yet.`} />
  };

  // Removing duplicates in answers array based on the object key 'standup' - to send only unique standup items to StandupTeamItem
  // Берем данные из редакса updateAnswers
  let updateAnswersUnique = updateAnswers ? (updateAnswers.reduce((acc, current) => {
    const x = acc.find(item => item.update === current.update);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, [])) : [];

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {user && user.department ? (
          <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user && user.isAdmin && true} activeIndex={10} />
        ) : (
          <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} activeIndex={10} />
        )}

        <UserNav />
        
          <Fragment>
            {loading || !updateAnswers || !user || !company || !okrs ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="main main-dashboard">
                  <h5>
                   {user.departmentName}{" "}<FormattedMessage id="update.header" defaultMessage={`Updates`} />
                  </h5>
                  <TeamMenu companyIndex={2} />
                  <div className="updates">
                    {company.plan !== 1 ? (
                      <Fragment>
                        {updateAnswers.length === 0 ? (
                          <Fragment>
                            <Notification
                               msg={updateCopy.msgOnboarding}
                               cta={updateCopy.ctaOnboarding}
                               btnColor={'orange'}
                               color={`orange`}
                               url={`/campaigns/updates`}
                            />
                            <Fragment>
                              {onboardingUpdates.map(update => (
                                <UpdateItem
                                  key={`teamUpdates-${update._id}`}
                                  updateAnswers={onboardingUpdates}
                                  updateDate={update.updateDate}
                              />
                              ))}
                            </Fragment>
                          </Fragment>
                        ) : (
                          <Fragment>
                            {updateAnswersUnique.length > 0 ? (
                              updateAnswersUnique.map(
                                (updateAnswerUnique, index) => (
                                  <Fragment key={`fragment-update-${index}`}>
                                    <h3 className="fs-2 pt-2 pl-2 pb-1" key={`date-${index}`}>
                                      <Moment format="D MMM" key={`date-moment-${index}`}>{updateAnswerUnique.updateDate}</Moment>
                                    </h3>

                                    <UpdateItem
                                      key={`updates-${updateAnswerUnique._id}-${index}`}
                                      updateAnswers={updateAnswers.filter(
                                        updateAnswer =>
                                          updateAnswer.update.toString() ===
                                          updateAnswerUnique.update.toString()
                                      )}
                                      updateDate={updateAnswerUnique.updateDate}
                                    />
                                  </Fragment>
                                )
                              )
                            ) : (
                              <div className="mt-5">
                                <NoContent 
                                  message={noUpdatesMessageTeam}
                                  picture={updateImg}
                                  alt={'No updates'}
                                />
                              </div>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      <NoContent 
                        message={updateCopy.updatePlanMsg}
                        picture={updateImg}
                        alt={'Update plan'}
                        admin={user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0}
                      />
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

TeamUpdates.propTypes = {
  getCompanyProfiles: PropTypes.func.isRequired,
  getTeamUpdateAnswers: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getCompanySortedOkrs: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  updateAnswer: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  updateAnswer: state.updateAnswer,
  okr: state.okr
});

export default connect(
  mapStateToProps,
  { getUserCompany, getTeamUpdateAnswers, getCompanyProfiles, getCompanySortedOkrs }
)(TeamUpdates);
