import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import waiting from '../../img/empty/waiting.png';

const StandupEmptyTeam = ({
  message: { title, msg },
  auth: { user, loading }
}) => {
  return (
    <div className="component my-2 p-3 light-shadow gather_data">
      {!loading && user ? (
        <Fragment>
          <p className="sub-title focus-yellow focus">{title}</p>

          <img src={waiting} className="block mxa my-2" alt="Gathering data" />

          <div className="py-2">
            <p className="meduim w-90">{msg}</p>
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};

StandupEmptyTeam.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(StandupEmptyTeam);
