import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { icons } from '../../img/elements/icons';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import chroma from 'chroma-js';
import DatePicker from 'react-date-picker';
import Highlighter from "react-highlight-words";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    backgroundColor: '#ffffff',
    fontSize: '14px',
    padding: '2px 0',
  }),
  control: styles => ({
    ...styles,
    backgroundColor: '#ffffff', 
    padding: '2px 0px',
    borderColor: 'transparent'
  }),
  placeholder: styles => ({
        ...styles,
        fontSize: '14px',
    }),
  menu: styles => ({
    ...styles,
    // width: '150px', 
    textAlign: 'left',
  }),
  clearIndicator: styles => ({
    ...styles,
    padding: '0px', 
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: '0px', 
  }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  },
  valueContainer: styles => ({
    ...styles,
    background: 'transparent', 
    backgroundColor: 'transparent', 
  }),
}


const customStylesPriority = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      fontSize: '14px',
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  input: () => ({
    fontSize: '14px',
  }),
  control: styles => ({
    ...styles,
    padding: '2px 0px',
    borderColor: 'transparent'
  }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  menu: styles => ({
    ...styles,
    width: '150px', 
    textAlign: 'left',
  }),
  clearIndicator: styles => ({
    ...styles,
    padding: '0px', 
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: '0px', 
  }),
  singleValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
      fontSize: '14px',
      padding: '5px',
      borderRadius: '5px',
      color: data.color,
    };
  },
  valueContainer: styles => ({
    ...styles,
    background: 'transparent', 
    backgroundColor: 'transparent', 
  }),
  container: styles => ({
    ...styles,
    background: 'transparent', 
    backgroundColor: 'transparent', 
  }),
}

const indicatorSeparatorStyle = {
  alignSelf: 'stretch',
  backgroundColor: 'transparent',
  marginBottom: 8,
  marginTop: 8,
  width: 0,
};

const IndicatorSeparator = ({ innerProps }) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};


const TaskItemCard = ({
  todo: {
    _id,
    name,
    owner,
    dueIn,
    isCompleted,
    priority,
    OKR,
  },
  formData,
  searchKeyword,
  usersOptions,
  okrOptions,
  priorityOptions,
  handleSelect,
  handleDueIn,
  handleComplete,
  index,
  handleDelete,
  handleNameForm,
  todoBoard,
  okrBoard,
}) => {

  const [isNameUpdate, setNameUpdate] = useState(false);
  const [inHover, setHover] = useState(false);

  if (dueIn === undefined) { dueIn = null };

  // For search
  if (name && name.props && name.props.defaultMessage) {
    name = name.props.defaultMessage;
  }

  const handleMouseLeave = (_id, e, type) => {
    handleSelect(_id, e.target.value, type);

    setNameUpdate(!isNameUpdate);
  };

  const handleKeypress = (e, id, type) => {
    // it triggers by pressing the enter key
    if (e.key === 'Enter') {
      handleSelect(id, e.target.value, type);

      setNameUpdate(!isNameUpdate);
    }
  };

   return (
    <Fragment>
       <div
        //  onMouseEnter={() => setHover(true)}
        //  onMouseLeave={() => setHover(false)}
         className={"relative br-4 brd brd-grey__light bg__white mb-1"}>
       <div
         className="flex-columnn align-center p-2 relative"
         >
         {/* <div className={`${!okrBoard ? 'w-400px' : 'w-50'} mr-2 class flex align-center w-100`}> */}
         <div className={`flex align-center w-100`}>
           <span
             onClick={e => handleComplete(e, _id, index, isCompleted)}
             className="flex align-center"
           >
            <div className="cursor mr-1">
              {!isCompleted ? (icons.checkbox) : (
                <span className="task__completed">
                  {icons.checkbox_full}
                </span>
                )}
            </div>
           </span>
           <Fragment>
              <FormattedMessage
                id="select.priority"
                defaultMessage="Priority..."
              >
                {msg => (
                  <Select
                    className="w-100px small-input cursor"
                    defaultValue={formData.priority}
                    components={{ IndicatorSeparator }}
                    value={priority || ''}
                    name="priority"
                    onChange={selectedOption =>
                      handleSelect(_id, selectedOption, 'priority')
                    }
                    options={priorityOptions}
                    placeholder={msg}
                    styles={customStylesPriority}
                  />
                )}
              </FormattedMessage>

              {!isNameUpdate ? (
                <Fragment>
                  <p
                    onClick={() => setNameUpdate(!isNameUpdate)}
                    className={`${isCompleted && 'grey old-price'} ${!okrBoard ? 'medium' : 'small'} w-70 ml-2 fs-14 lh-25 fw-600 card-subtitle cursor inline-block`}
                  >
                    <Highlighter
                        highlightClassName="bg-yellow_pure"
                        searchWords={[searchKeyword]}
                        autoEscape={true}
                        textToHighlight={name}
                      />
                  </p>
                </Fragment>
              ) : (
                <FormattedMessage
                  id="select.task"
                  defaultMessage="Add new task"
                >
                  {msg => (
                    <textarea
                      className={`w-70 ml-2 fs-14 lh-25 fw-600 card-subtitle cursor inline-block brd-transparent brd_bottom_grey outline`}
                      type="text"
                      rows={name.length < 40 ? "1" : "3"}
                      required
                      value={name || ''}
                      onChange={e =>
                        handleNameForm(e, index, todoBoard, _id)
                      }
                      onKeyPress={e => handleKeypress(e, _id, 'name')}
                      onMouseLeave={e => handleMouseLeave(_id, e, 'name')}
                      placeholder={msg}
                      name="name"
                    />
                  )}
                </FormattedMessage>
              )}

              <div 
                className="w-90px flex justify-end mla"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <div className={`${!inHover ? 'w-35px' : 'w-90px animation'} icon__box`}>
                  <div className={`flex align-center h-34 icon__grey brd__grey brd_new__grey br-4 relative text-center p-1 cursor`} type="button">
                    {icons.trash_small}
                    {inHover && (
                      <p 
                        className="fs-14 fw-600 ml-xs"
                        onClick={() => handleDelete(_id, index) }
                      >
                        <FormattedMessage
                          id="delete"
                          defaultMessage="Delete"
                        />
                      </p>
                    )}
                  </div>
                </div>
              </div>
           </Fragment>
           </div>

        <div className="flex align-center w-100">
          <div className="ml-4 mr-1 w-80px text-center flex-column mt-1">
            <span className="fw-600 fs-10 text-left">Due Date</span>
            <DatePicker
              onChange={selectedOption => handleDueIn(_id, selectedOption)}
              value={dueIn === null ? dueIn : new Date(dueIn)}
            />
          </div>

          <div className="mx-1 w-220px text-center">
            <FormattedMessage
              id="select.assignee"
              defaultMessage="Assignee..."
            >
              {msg => (
                <Select
                  className="small-input select__no-indicator"
                  defaultValue={formData.owner}
                  components={{ IndicatorSeparator }}
                  value={owner}
                  name="owner"
                  onChange={selectedOption =>
                    handleSelect(_id, selectedOption, 'owner')
                  }
                  options={usersOptions}
                  placeholder={msg}
                  styles={customStyles}
                  isSearchable
                  // isMulti
                />
              )}
              </FormattedMessage>
          </div>

          <div className="mx-1 w-50 text-center">
            <FormattedMessage
              id="select.okr"
              defaultMessage="Select OKR..."
            >
              {msg => (
                <Select
                  className="small-input"
                  defaultValue={formData.OKR}
                  components={{ IndicatorSeparator }}
                  value={OKR || ''}
                  onChange={selectedOption =>
                    handleSelect(_id, selectedOption, 'OKR')
                  }
                  options={okrOptions}
                  isSearchable
                  // isClearable
                  placeholder={msg}
                  name="OKR"
                  styles={customStyles}
                />
              )}
              </FormattedMessage>
          </div>
        </div>
      </div>

      </div>
    </Fragment>
  );
};

TaskItemCard.propTypes = {
  todo: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(TaskItemCard);
