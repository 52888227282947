import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Select from 'react-select';
import getLabels from '../../../../translations/okrs';

const BinaryUpdate = ({ 
  index, handleKeyResults, 
  locale, selectCustomStyles, details,
 }) => {

  let krLabels = locale && getLabels(locale.lang);

  let binaryOptions = [
    { value: 0, label: krLabels.binaryLabel.false, name: 'details' },
    { value: 100, label: krLabels.binaryLabel.true, name: 'details' },
  ]

  return (
    <Fragment>
     <div className="kr__update__metrics__binary okr__kr-current labeled-wrapper">
       <FormattedMessage
          id="choose.status"
          defaultMessage="Choose the status"
        >
          {(msg) => (
            <Select
              className="w-100"
              defaultValue={details[0]}
              value={details}
              name="details"
              onChange={(selectedOption) =>
                handleKeyResults(selectedOption, index, 'details', index)
              }
              options={binaryOptions}
              isSearchable
              placeholder={msg}
              required
              styles={selectCustomStyles}
            />
          )}
        </FormattedMessage>
      </div>
    </Fragment>
  );
};

BinaryUpdate.propTypes = {
  details: PropTypes.array.isRequired,
  handleKeyResults: PropTypes.func.isRequired,
};

export default BinaryUpdate;
