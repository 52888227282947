const getLabels = language => { 
  let krTypeLabel = {
    number: 'Number',
    binary: 'Binary',
    milestone: 'Milestone',
  };

  let binaryLabel = {
    true: 'True',
    false: 'False',
  };

  if (language === 'ru') {
    krTypeLabel = {
      number: 'Цифра',
      binary: 'Бинарный',
      milestone: 'Веха'
    };

    binaryLabel = {
      true: 'Выполнено',
      false: 'Не выполнено',
    };
  }

  let obj = { krTypeLabel, binaryLabel }

  return obj;
};

export default getLabels;
