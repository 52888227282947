import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

const Text = ({ text, title, page }) => {
  
  let hi = <FormattedMessage id="hi" defaultMessage="Hi" />
  return (
    <Fragment>
      <p className="bold-pure fs-22">{page !== 1 ? title : (<span> {hi}{" "}{title}!</span>)}</p>
      <p className="fs-16">{text}</p>
    </Fragment>
  );
};

export default Text;
