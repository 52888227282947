import React from 'react';
import PropTypes from 'prop-types';
import ButtonSecondary from './ButtonSecondaryNew';
import { FormattedMessage } from 'react-intl';

const PricingCardPro = ({ 
  user, createSubscription, 
  people, users, price, discountPrice, 
  plan, monthly, updatePlan, 
  id, togglePopup, popup 
}) => {

  const coaching = <FormattedMessage id="admin.pricing.card.coaching" defaultMessage="+ 2 hours coaching per month"/>;
    
  // console.log('====== plan', plan)
  // console.log('====== price', price)

  return (
    <div className="w-40 pricing_item text-center bg-white py-3 px-2 br-6 mb-3">
      <p className="fs2_5 bold">
        <FormattedMessage
          id="pro.plan"
          defaultMessage="Pro Plan"
        />
      </p>

      {monthly ? (
        <p className="fs2 my-1 blue">
          <FormattedMessage
            id="admin.pricing.card.usd"
            defaultMessage="$"
          />{price}{' '}
          <FormattedMessage
            id="admin.pricing.card.price2"
            defaultMessage="user / month"
          />
        </p>
      ) : (
        <p className="fs2 my-1 blue">
          <span className="old-price">
            <FormattedMessage
            id="admin.pricing.card.usd"
            defaultMessage="$"
            />{price}
          </span>{' '}
          <FormattedMessage
            id="admin.pricing.card.usd"
            defaultMessage="$"
          />{discountPrice}{' '}
          <FormattedMessage
            id="admin.pricing.card.price2"
            defaultMessage="user / month"
          />
        </p>
      )}
      <p className="small mb-xs">
        <FormattedMessage
          id="unlimited.users"
          defaultMessage="Unlimited users"
        />
      </p>
      <p className="small mb-xs">
        <FormattedMessage
          id="automated.checkins"
          defaultMessage="Automated Team Check-ins"
        />
      </p>
      <p className="small mb-xs">
        <FormattedMessage
          id="slack.discord"
          defaultMessage="Slack & Discord Integration"
        />
      </p>
      <p className="small mb-xs">
        <FormattedMessage
          id="okrs"
          defaultMessage="OKRs"
        />
      </p>
      <p className="small mb-xs">
        <FormattedMessage
          id="task.management"
          defaultMessage="Task Management"
        />
      </p>
      <p className="small mb-xs">
        <FormattedMessage
          id="one.on.one"
          defaultMessage="1:1 Meetings"
        />
      </p>
      <p className="small mb-2">
        <FormattedMessage
          id="primary.support"
          defaultMessage="Primary Support"
        />
      </p>
      <ButtonSecondary 
        createSubscription={createSubscription} 
        user={user}
        isStripe={true} 
        people={people}
        companyId={id} 
        plan={plan} 
        btn={4} 
        togglePopup={togglePopup} 
        popup={popup} 
        updatePlan={updatePlan} 
        monthly={monthly} 
        link={true} 
        externalLink={99} 
        />
    </div>
  );
};

PricingCardPro.propTypes = {
  users: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  discountPrice: PropTypes.number.isRequired
};

export default PricingCardPro;
