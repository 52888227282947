import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import TimePicker from 'rc-time-picker';
import Moment from 'moment';
import Subtitle from "./Subtitle";
import SelectItem from "../../elems/SelectWithValue";

const format = 'h:mm a';

let intervalOptions = [
  { label: `Monthly`, value: 'Monthly', name: 'interval' },
  { label: `Quarterly`, value: 'Quarterly', name: 'interval' },
  { label: `Annually`, value: 'Annually', name: 'interval' },
];

let daysOptions = [
  { label: '1', value: '1', name: 'day' },
  { label: '2', value: '2', name: 'day' },
  { label: '3', value: '3', name: 'day' },
  { label: '4', value: '4', name: 'day' },
  { label: '5', value: '5', name: 'day' },
  { label: '6', value: '6', name: 'day' },
  { label: '7', value: '7', name: 'day' },
  { label: '8', value: '8', name: 'day' },
  { label: '9', value: '9', name: 'day' },
  { label: '10', value: '10', name: 'day' },
  { label: '11', value: '11', name: 'day' },
  { label: '12', value: '12', name: 'day' },
  { label: '13', value: '13', name: 'day' },
  { label: '14', value: '14', name: 'day' },
  { label: '15', value: '15', name: 'day' },
  { label: '16', value: '16', name: 'day' },
  { label: '17', value: '17', name: 'day' },
  { label: '18', value: '18', name: 'day' },
  { label: '19', value: '19', name: 'day' },
  { label: '20', value: '20', name: 'day' },
  { label: '21', value: '21', name: 'day' },
  { label: '22', value: '22', name: 'day' },
  { label: '23', value: '23', name: 'day' },
  { label: '24', value: '24', name: 'day' },
  { label: '25', value: '25', name: 'day' },
  { label: '26', value: '26', name: 'day' },
  { label: '27', value: '27', name: 'day' },
  { label: '28', value: '28', name: 'day' },
  { label: '29', value: '29', name: 'day' },
  { label: '30', value: '30', name: 'day' },
  { label: '31', value: '31', name: 'day' },
];

let monthsOptions = [
  { label: 'January', value: '1', name: 'month' },
  { label: 'February', value: '2', name: 'month' },
  { label: 'March', value: '3', name: 'month' },
  { label: 'April', value: '4', name: 'month' },
  { label: 'May', value: '5', name: 'month' },
  { label: 'June', value: '6', name: 'month' },
  { label: 'July', value: '7', name: 'month' },
  { label: 'August', value: '8', name: 'month' },
  { label: 'September', value: '9', name: 'month' },
  { label: 'October', value: '10', name: 'month' },
  { label: 'November', value: '11', name: 'month' },
  { label: 'December', value: '12', name: 'month' },
];

let quarterMonthsOptions = [
  { label: 'Jan - Apr - July - Oct', value: '1', name: 'month' },
  { label: 'Feb - May - Aug - Nov', value: '2', name: 'month' },
  { label: 'Mar - June - Sept - Dec', value: '3', name: 'month' },
];

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: state.isFocused ? '#404ff5' : '#e9eaf5',
    boxShadow: state.isFocused ? '0px 0px 20px #404FF54D' : 'none',
    border: state.isFocused ? '1px solid #404ff5' : styles.border,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    fontSize: '14px',
    height: '38px',
  }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize, };
  }
}

// type 1 - create, type 2 - edit
const OkrCallSettings = ({
  formData,
  scheduleTime,
  handleTime,
  timezone,
  onChangeTimezone,
  interval,
  onChangeSelect,
  scheduleDay,
  onChangeDate,
  onChangeMonth,
  profiles,
  handleUsers,
  emailUsersOptions,
  handleIntervalSelect,
  type,
 }) => {

  // Create checkin - type 1, Edit checkin - type 2

  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    setSelectedUsers(formData.selectedEmailUsers);
  }, [formData.selectedEmailUsers]);

  const wordingTeam = {
    placeholderId: "choose.team.member",
    placeholderMsg: "Select Team Members",
  }

  const wordingInterval = {
    placeholderId: "choose.team.interval",
    placeholderMsg: "Choose OKR Call interval",
  }

  const wordingDay = {
    placeholderId: "choose.day",
    placeholderMsg: "Choose day of month",
  }

  const wordingMonth = {
    placeholderId: "choose.month",
    placeholderMsg: "Choose month",
  }

  let scheduleLength = 0;
  if (scheduleDay !== undefined && scheduleDay !== null) {
    scheduleLength = Object.keys(scheduleDay).length;
  }

  let defaultValueTeam = {}
  let defaultValueTime = {}

  if (formData && formData.visibility && formData.visibility.label) {
    defaultValueTeam = {
      users: type === 2 && formData.selectedEmailUsers ? formData.selectedEmailUsers : formData.selectedEmailUsers !== null ? emailUsersOptions[0] : [],
      usersValue: formData.selectedEmailUsers,
    }
  }


  if (formData && formData.interval && formData.interval.length > 0) {
    defaultValueTime = {
      interval: type === 2 ? formData.interval : intervalOptions[1],
    }
  }

  let defaultInterval = type === 2 ? { label: defaultValueTime.interval, value: defaultValueTime.interval, name: "interval" } : defaultValueTime.interval;

  let defaultDay = daysOptions[0];

  let defaultMonth = monthsOptions[0];

  let defaultQuarterMonth = quarterMonthsOptions[0];

  let scheduleTimeArr = scheduleTime.split(":");
  
  let formattedTime = Moment().hour(scheduleTimeArr[0]).minute(scheduleTimeArr[1]);

  return (
    <Fragment>
      <Subtitle title={<FormattedMessage id="title" defaultMessage="Title" />} />
      <div className="flex align-start flex-column justify-start relative form-main mt-05">
        <FormattedMessage id="okrCall.title.placeholder" defaultMessage="ex. OKR Call for Everyone">
          {msg => <input
            required
            className="input-text lh-44 fs-16 brd-grey__new"
            type="text"
            placeholder={msg}
            name="title"
            value={formData.title}
            onChange={onChangeSelect}
          />}
        </FormattedMessage>
      </div>

      <div className="form-main1 form-main-inline1 mt-2">
        <div className="flex flex-column w-24 mr-1">
          <Subtitle title={<FormattedMessage id="team.members" defaultMessage="Team Members" />} />
          <div className="mt-05">
            <SelectItem
              defaultValue={defaultValueTeam.users}
              value={selectedUsers}
              onChange={handleUsers}
              options={emailUsersOptions}
              customStyles={customStyles}
              isClearable={false}
              wording={wordingTeam}
              isMulti={true}
              index={'selectedEmailUsers'}
            />
          </div>
        </div>  
      </div>

      <div className="form-main1 form-main-inline1 mt-2">
        <div className="flex flex-column w-24 mr-1">
          <Subtitle title={<FormattedMessage id="okCall.frequency" defaultMessage="Frequency" />} />
        </div>
        <div className="w-100 flex align-start">
          <div className="flex flex-column w-24 mr-1 time-picker">
            <p className="fs-12 w-100 mb-xs bold">
              <FormattedMessage id="time" defaultMessage="Time" />
            </p>
            <TimePicker
              showSecond={false}
              defaultValue={formattedTime}
              value={formattedTime}
              className="bg__white h-44 brd brd-grey__new pl-1"
              onChange={handleTime}
              format={format}
              // use12Hours
              inputReadOnly
              clearIcon={null}
            />
          </div>

          <div className="flex flex-column w-24 mr-1">
            <p className="fs-12 w-100 mb-xs bold">
              <FormattedMessage id="interval" defaultMessage="Interval" />
            </p>
            <SelectItem 
              defaultValue={defaultInterval}
              value={intervalOptions.filter(item => item.value === formData.interval)[0]}
              onChange={handleIntervalSelect}
              options={intervalOptions}
              customStyles={customStyles}
              isClearable={false}
              wording={wordingInterval}
              isMulti={false}
            />
          </div>

          <div className="flex flex-column w-24 mr-1">
            <Fragment>
              <p className="fs-12 w-100 mb-xs bold">
                <FormattedMessage id="day.month" defaultMessage="Day of the Month" />
              </p>
                <SelectItem 
                  defaultValue={defaultDay}
                  value={daysOptions.find(item => item.value === formData.scheduleDay.index)}
                  onChange={onChangeDate}
                  options={daysOptions}
                  customStyles={customStyles}
                  isClearable={false}
                  wording={wordingDay}
                  isMulti={false}
                />
            </Fragment>
          </div>

          {interval === 'Quarterly'  && (<div className="flex flex-column w-24">
            <Fragment>
              <p className="fs-12 w-100 mb-xs bold">
                <FormattedMessage id="months" defaultMessage="Months" />
              </p>
              <SelectItem 
                defaultValue={defaultQuarterMonth}
                value={quarterMonthsOptions.find(item => item.value == ((formData.scheduleDay.month - 1) % 3) + 1)}
                onChange={onChangeMonth}
                options={quarterMonthsOptions}
                customStyles={customStyles}
                isClearable={false}
                wording={wordingMonth}
                isMulti={false}
              />
            </Fragment>
          </div>)}

          {interval === 'Annually'  && (<div className="flex flex-column w-24">
            <Fragment>
              <p className="fs-12 w-100 mb-xs bold">
                <FormattedMessage id="month" defaultMessage="Month" />
              </p>
              <SelectItem 
                defaultValue={defaultMonth}
                value={monthsOptions.find(item => item.value === formData.scheduleDay.month)}
                onChange={onChangeMonth}
                options={monthsOptions}
                customStyles={customStyles}
                isClearable={false}
                wording={wordingInterval}
                isMulti={false}
              />
            </Fragment>
          </div>)}
        </div>
      </div>
    </Fragment>
  );
};

OkrCallSettings.propTypes = {
  formData: PropTypes.object.isRequired,
  handleTime: PropTypes.func.isRequired,
};

export default OkrCallSettings;
