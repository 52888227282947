import React from 'react';
import { FormattedMessage } from 'react-intl';

const questions = [
  {
    _id: "question1", 
    text: (
      <FormattedMessage
        id="onboarding.updates.q1"
        defaultMessage="How was your experience at work this week?"
      />
    )
  },
  {
    _id: "question2", 
    text: (
      <FormattedMessage
        id="onboarding.updates.q2"
        defaultMessage="What was the focus of your week?"
      />
    )
  },
  {
    _id: "question3", 
    text: (
      <FormattedMessage
        id="onboarding.updates.q3"
        defaultMessage="What was the biggest obstacle of this week?"
      />
    )
  },
  {
    _id: "question4", 
    text: (
      <FormattedMessage
        id="onboarding.updates.q4"
        defaultMessage="What did you accomplish this week?"
      />
    )
  }
]

const answers1 = [
  {
    _id: "answer1", 
    text: [(
      <FormattedMessage
        id="onboarding.updates.a1"
        defaultMessage="4"
      />
    )],
    questionID: "question1"
  },
  {
    _id: "answer2", 
    text: [(
      <FormattedMessage
        id="onboarding.updates.a2"
        defaultMessage="Creating the 'all-in-one' feature"
      />
    )],
    questionID: "question2",
    likesAmount: 2
  },
  {
    _id: "answer3", 
    text: [(
      <FormattedMessage
        id="onboarding.updates.a32"
        defaultMessage="A lot of bugs"
      />
    )],
    questionID: "question3"
  },
  {
    _id: "answer4", 
    text: [(
      <FormattedMessage
        id="onboarding.updates.a10"
        defaultMessage="Completed 'all-in-one' feature"
      />
    )],
    questionID: "question4",
    likesAmount: 5,
    commentAmount: 1
  }
]

const answers2 = [
  {
    _id: "answer1", 
    text: [(
      <FormattedMessage
        id="onboarding.updates.a4"
        defaultMessage="5"
      />
    )],
    questionID: "question1"
  },
  {
    _id: "answer2", 
    text: [(
      <FormattedMessage
        id="onboarding.updates.a5"
        defaultMessage="Building new management processes"
      />
    )],
    questionID: "question2"
  },
  {
    _id: "answer3", 
    text: [(
      <FormattedMessage
        id="onboarding.updates.a6"
        defaultMessage="Identifying all processes of the team"
      />
    )],
    questionID: "question3"
  },
  {
    _id: "answer4", 
    text: [(
      <FormattedMessage
        id="onboarding.updates.a11"
        defaultMessage="Management Playbook"
      />
    )],
    questionID: "question4"
  }
]

const answers3 = [
  {
    _id: "answer1", 
    text: [(
      <FormattedMessage
        id="onboarding.updates.a7"
        defaultMessage="5"
      />
    )],
    questionID: "question1"
  },
  {
    _id: "answer2", 
    text: [(
      <FormattedMessage
        id="onboarding.updates.a8"
        defaultMessage="Creating Sales Playbook"
      />
    )],
    questionID: "question2"
  },
  {
    _id: "answer3", 
    text: [(
      <FormattedMessage
        id="onboarding.updates.a9"
        defaultMessage="A lot of meetings in different locations"
      />
    )],
    questionID: "question3"
  },

  {
    _id: "answer4", 
    text: [(
      <FormattedMessage
        id="onboarding.updates.a12"
        defaultMessage="Sales Playbook"
      />
    )],
    questionID: "question4"
  }
]

const likes1 = [
  {
    _id: "like1",
    user: "onboarding_creator1",
    answerID: "answer4"
  },
  {
    _id: "like1",
    user: "onboarding_creator2",
    answerID: "answer4"
  },
  {
    _id: "like1",
    user: "onboarding_creator3",
    answerID: "answer4"
  },
  {
    _id: "like1",
    user: "onboarding_creator4",
    answerID: "answer4"
  },
  {
    _id: "like1",
    user: "onboarding_creator5",
    answerID: "answer4"
  },
  {
    _id: "like2",
    user: "onboarding_creator2",
    answerID: "answer2"
  },
  {
    _id: "like2",
    user: "onboarding_creator2",
    answerID: "answer2"
  }
]

const comment1 = [
  {
    _id: "comment1",
    user: "onboarding_creator1",
    answerID: "answer4",
    text: (
      <FormattedMessage
        id="onboarding.updates.text"
        defaultMessage="Awesome! When can we deliver it to our users?"
      />
    ),
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName1"
        defaultMessage="Tom"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName1"
        defaultMessage="Ford"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar52.jpeg",
    date: "2020-03-17T09:46:33.402+00:00"
  }
]


export const onboardingUpdates = [
  { 
    _id: "onboarding_update1",
    questions: questions,
    answers: answers1,
    user: "onboarding_owner2",
    team: "onboarding_team1",
    teamName: (
      <FormattedMessage
        id="onboarding.okr.product"
        defaultMessage="Product"
      />
    ),
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName2"
        defaultMessage="Julian"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName2"
        defaultMessage="Tobin"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar44.jpeg",
    company: "onboarding_company1",
    companyName: "onboarding_companyName1",
    department: "onboarding_department1",
    departmentName: (
      <FormattedMessage
        id="onboarding.okr.departmentName1"
        defaultMessage="Marketing"
      />
    ),
    update: "5d36ba95e33e8a061d3b6eda",
    updateID: 1,
    date: "2020-03-11T07:36:33.402+00:00",
    likes: likes1,
    comments: comment1,
    updateDate: "2020-03-29T09:00:00.000+00:00"
  },
  { 
    _id: "onboarding_update2",
    questions: questions,
    answers: answers2,
    user: "onboarding_owner2",
    team: "onboarding_team2",
    teamName: (
      <FormattedMessage
        id="onboarding.okr.teamName2"
        defaultMessage="HR"
      />
    ),
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName1"
        defaultMessage="Tom"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName1"
        defaultMessage="Ford"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar52.jpeg",
    company: "onboarding_company1",
    companyName: "onboarding_companyName1",
    department: "onboarding_department1",
    departmentName: (
      <FormattedMessage
        id="onboarding.okr.departmentName1"
        defaultMessage="Marketing"
      />
    ),
    update: "5d36ba95e33e8a061d3b6eda",
    updateID: 2,
    date: "2020-03-11T07:36:33.402+00:00",
    likes: [],
    comments: [],
    updateDate: "2020-03-22T09:00:00.000+00:00"
  },
  { 
    _id: "onboarding_update3",
    questions: questions,
    answers: answers3,
    user: "onboarding_owner2",
    team: "onboarding_team2",
    teamName: (
      <FormattedMessage
        id="onboarding.okr.teamName2"
        defaultMessage="Marketing"
      />
    ),
    type: "3",
    creator: "onboarding_creator1",
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName3"
        defaultMessage="Patrick"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName3"
        defaultMessage="Brody"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar7.jpeg",
    company: "onboarding_company1",
    companyName: "onboarding_companyName1",
    department: "onboarding_department1",
    departmentName: (
      <FormattedMessage
        id="onboarding.okr.departmentName1"
        defaultMessage="Marketing"
      />
    ),
    update: "5d36ba95e33e8a061d3b6eda",
    updateID: 3,
    date: "2020-03-11T07:36:33.402+00:00",
    likes: [],
    comments: [],
    updateDate: "2020-03-15T09:00:00.000+00:00"
  }
]

export default {
  onboardingUpdates,
}