import React, { useState, Fragment, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Menu from '../layout/Menu';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import { getCompanyUpdateAnswers, clearUpdates } from '../../actions/updateAnswer';
import { getCompanyProfiles } from '../../actions/profile';
import { getAllCustoms } from '../../actions/custom';
import { getUserCompany } from '../../actions/company';
import Chart from '../utils/Chart';
import UpdateItem from './UpdateItem';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/no_content.svg';
import UpdateTitle from '../elems/WeekDateTitle';
import Notification from '../utils/Notification';
import { getUpdates, getUpdateSettings } from '../../actions/update';
import { onboardingUpdates } from '../../utils/onboarding/updates';
import { updateCopy } from '../../utils/wording/updates';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import Moment from 'react-moment';
import { menuTitle, menuItems, getCheckinsMenu } from '../../utils/menu/checkins';
import SubMenu from '../elems/SubMenu';
import { Link } from 'react-router-dom';
import useDateFilter from '../utils/useDateFilter';

const customStyles = {
  option: (provided, state, isSelected) => ({
    ...provided,
    fontSize: '14px'
  }),
  input: () => ({
    fontSize: '14px'
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: '#ffffff',
    padding: '0px 8px',
    lineHeight: '20px',
    height: 36,
    minHeight: 36
  }),
  placeholder: (styles) => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
};

const Update = ({
  clearUpdates,
  getUpdateSettings,
  getUserCompany,
  getCompanyProfiles,
  auth: { user },
  company: { company },
  updateAnswer: { updateAnswers, updateAnswer, loading },
  getCompanyUpdateAnswers,
  profile: { profiles },
  getUpdates,
  update,
  getAllCustoms,
  custom: { campaigns },
  locale
}) => {
  const [formData, setFormData] = useState({
    team: '',
    updates: [],
    notes: ''
  });

  const [search, setSearch] = useState('');
  const [results, setResults] = useState({
    searchResults: false,
    searchKeyword: ''
  });

  const [menuData, setMenu] = useState({
    menu: {}
  });

  let { menu } = menuData;
  let { team, updates, notes } = formData;
  let { searchResults, searchKeyword } = results;
  let teamOptions = [];
  let mentions = [];

  // Перебор profiles & departments и приведение данных к формату Селекта
  {
    profiles &&
      profiles.forEach((profile, index) => {
        let mention = {
          id: profile._id,
          display: `${profile.firstName} ${profile.lastName}`
        };
        mentions.push(mention);

        if (user && profile._id === user._id) return;

        let label =
          (profile && profile.departmentName === null) || (profile && profile.department === undefined)
            ? `${profile.firstName} ${profile.lastName}`
            : `${profile.firstName} ${profile.lastName} - [${profile.departmentName}]`;

        let coworker = {
          value: profile._id,
          label,
          type: 3
        };

        teamOptions.push(coworker);
      });
  }

  {
    company &&
      company.departments.length > 0 &&
      company.departments.forEach((department, index) => {
        if (user && user.department === null) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };

          teamOptions.unshift(team);
        } else if (
          user &&
          user.department !== null &&
          user.department !== undefined &&
          department._id !== user.department
        ) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };

          teamOptions.unshift(team);
        }

        if (user && index === company.departments.length - 1) {
          // You is third in list
          let person = {
            value: user._id,
            label:
              (user && user.departmentName === null) || (user && user.department === undefined)
                ? `${user.firstName} ${user.lastName}`
                : `${user.firstName} ${user.lastName} - [${user.departmentName}]`,
            type: 3
          };

          teamOptions.unshift(person);

          // Second option is the team if exists
          if (user.department !== null && user.department !== undefined) {
            let team = { value: user.department, label: `${user.departmentName}`, type: 2 };
            teamOptions.unshift(team);
          }

          // Sort users alphabetically by first name
          teamOptions.sort((a, b) => a.label.localeCompare(b.label));

          // Third option is the company
          let name = { value: company._id, label: `${company.name}`, type: 1 };
          teamOptions.unshift(name);
        }
      });
  }

  if (company && company.departments.length === 0 && user && profiles && profiles.length === 1) {
    teamOptions = [
      { value: company._id, label: `${company.name}`, type: 1 },
      { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 }
    ];
  } else if (company && company.departments.length === 0 && user && profiles && profiles.length > 1) {
    // if 0 teams and 1+ users
    // add user and company to others members that we found in the profiles loop
    let person = { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 };
    teamOptions.unshift(person);

    let team = { value: company._id, label: `💪 ${company.name}`, type: 1 };
    teamOptions.unshift(team);
  }

  let choosedTeam = user
    ? {
        value: user._id,
        label:
          (user && user.departmentName === null) || (user && user.departmentName === undefined)
            ? `${user.firstName} ${user.lastName}`
            : `${user.firstName} ${user.lastName} - [${user.departmentName}]`,
        type: 3
      }
    : {};

  const getCompanyUpdateAnswersStepped = useCallback(()=>{
    user && 
    getCompanyUpdateAnswers({limit:30, user:user._id}).then(()=>getCompanyUpdateAnswers())
  },[getCompanyUpdateAnswers,user])
  
  useEffect(() => {
    getUserCompany();
    getCompanyUpdateAnswersStepped();
    getCompanyProfiles();
    getUpdates();
    getAllCustoms();
    getUpdateSettings();

   
  }, [
    getUserCompany,
    getCompanyUpdateAnswersStepped,
    getCompanyProfiles,
    getAllCustoms,
    getUpdates,
    getUpdateSettings
  ]);

  useEffect(()=>{
    setFormData({
      team: !user ? '' : choosedTeam,
      updates: !updateAnswers ? [] : updateAnswers.filter((answer) => answer.user === choosedTeam.value),
      notes: !updateAnswers ? '' : updateAnswers.notes
    });
    setMenu({
      menu: campaigns && campaigns.length > 0 ? getCheckinsMenu(campaigns) : menuItems
    });
  },[user,updateAnswers,campaigns,getCheckinsMenu])

  const handleTeam = (selectedOption) => {
    let newUpdates;
    if (selectedOption.type === 1) {
      newUpdates = updateAnswers.filter((answer) => answer.company === selectedOption.value);
    } else if (selectedOption.type === 2) {
      newUpdates = updateAnswers.filter((answer) => answer.department === selectedOption.value);
    } else if (selectedOption.type === 3) {
      newUpdates = updateAnswers.filter((answer) => answer.user === selectedOption.value);
    }

    setFormData({
      ...formData,
      team: selectedOption,
      updates: newUpdates
    });
  };

  const noUpdatesMessageTeam = {
    title: <FormattedMessage id="company.noupdates.user.title" defaultMessage="No Updates" />,
    msg: (
      <FormattedMessage
        id="company.updates.user.msg"
        defaultMessage={`${team.label} has not finished weekly update yet.`}
      />
    )
  };

  const noSearchResults = {
    title: <FormattedMessage id="search.noresults" defaultMessage="No Results Found" />,
    msg: (
      <FormattedMessage
        id="company.checkins.user.msg"
        defaultMessage={`Sorry, we couldn't find any results matching <b>"${searchKeyword}"</b>`}
        values={{ b: (...chunks) => <b>{chunks}</b> }}
      />
    )
  };

  const updateLink = update.updates.length > 0 ? `/updates/${update.updates[0]._id}` : '/campaigns/updates';

  const handleSearch = (e) => {
    setSearch(e.target.value);
    let updatesArr = [];

    if (team.type === 1) {
      // Company
      updatesArr = updateAnswers.filter((answer) => answer.company === team.value);
    } else if (team.type === 2) {
      // Team
      updatesArr = updateAnswers.filter((answer) => answer.department === team.value);
    } else if (team.type === 3) {
      // User
      updatesArr = updateAnswers.filter((answer) => answer.user === team.value);
    }

    let filteredUpdates = [];

    if (e.target.value.length !== 0) {
      // If search input is not empty
      updatesArr.forEach((item) => {
        let answers = item.answers;
        let isUniqueItem = true;

        answers.forEach((answer) => {
          answer.text.forEach((text) => {
            if (!isUniqueItem) {
              return;
            }

            if (text.toLowerCase().includes(e.target.value.toLowerCase()) === true) {
              isUniqueItem = false;
              return filteredUpdates.push(item);
            }
          });
        });
      });

      setFormData({
        ...formData,
        updates: filteredUpdates
      });

      // Check if we have search results or not
      if (filteredUpdates.length !== 0) {
        setResults({
          ...results,
          searchResults: false,
          searchKeyword: e.target.value // change to highlight search results
        });
      } else {
        // If we don't have search results
        setResults({
          ...results,
          searchResults: true,
          searchKeyword: e.target.value
        });
      }
    } else {
      // Empty search input
      setFormData({
        ...formData,
        updates: updatesArr
      });

      setResults({
        ...results,
        searchResults: false,
        searchKeyword: ''
      });
    }
  };

  // Removing duplicates in answers array based on the object key 'update' - to send only unique update items to UpdateTeamItem
  // Берем данные из стейта updates, а не редакса updateAnswers, чтобы делать фильтр на странице
  let updateAnswersUnique = useMemo(
    () =>
      updates
        ? updates.reduce((acc, current) => {
            const x = acc.find((item) => item.update === current.update);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, [])
        : [],
    [updates]
  );

  const [updateAnswersUniqueFiltered, timeFilterOptions, timeFilterSelectedOption, timeFilterChangeOption] =
    useDateFilter(updateAnswersUnique, (item) => item.date, locale && locale.lang);

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">
          {user && user.department ? (
            <Menu
              companyName={user && user.companyName}
              team={user.departmentName}
              teamId={user.department}
              settings={user && user.isAdmin && true}
              focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true}
              activeIndex={2}
            />
          ) : (
            <Menu
              companyName={user && user.companyName}
              settings={user && user.isAdmin && true}
              focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true}
              activeIndex={2}
            />
          )}

          <UserNav />

          <Fragment>
            {loading || !updateAnswers || !user || !company ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="main main_new main-dashboard mt-3">
                  <div className="updates">
                    <div className="page-title">
                      <h5 className="pl-0-xs">{menuTitle}</h5>
                    </div>
                    <div className="insight mt-3">
                      <div className="mt-4 mb-5">
                        <SubMenu items={menu} activeIndex={2} mb={'1'} />
                      </div>

                      <div className="flex flex-column-xs">
                        <div className="flex align-center w-100-xs">
                          {(user && user.isAdmin) || (update.settings && !update.settings.isPrivate) ? (
                            <FormattedMessage
                              id="checkins.sub_menu.teamSelect"
                              defaultMessage="Choose the team or user"
                            >
                              {(msg) => (
                                <Select
                                  className="small-input w-250px w-50-xs mr-1-xs"
                                  defaultValue={choosedTeam}
                                  value={team}
                                  onChange={(selectedOption) => handleTeam(selectedOption)}
                                  options={teamOptions}
                                  isSearchable
                                  placeholder={msg}
                                  required
                                  name="team"
                                  styles={customStyles}
                                />
                              )}
                            </FormattedMessage>
                          ) : (
                            ''
                          )}
                          <FormattedMessage
                            id="checkins.sub_menu.timeSelect"
                            defaultMessage="Choose the time period"
                          >
                            {(msg) => (
                              <Select
                                className="small-input w-250px w-50-xs mr-1-xs ml-2"
                                defaultValue={choosedTeam}
                                value={timeFilterSelectedOption}
                                onChange={timeFilterChangeOption}
                                options={timeFilterOptions}
                                isSearchable
                                placeholder={msg}
                                required
                                name="time"
                                styles={customStyles}
                              />
                            )}
                          </FormattedMessage>

                          <FormattedMessage id="search.bar" defaultMessage="Search...">
                            {(msg) => (
                              <input
                                className="w-200px w-50-xs question-input ml-2 mla-xs pl-2 br-4 brd brd-grey bg__white outline mla lh-34"
                                type="text"
                                placeholder={msg}
                                name="search"
                                value={search}
                                onChange={(e) => handleSearch(e)}
                              />
                            )}
                          </FormattedMessage>
                        </div>

                        {company.plan !== 1 &&
                          (updateAnswers.length === 0 ||
                          (update &&
                            update.updates.length > 1 &&
                            updateAnswer &&
                            update.updates[0]._id.toString() !== updateAnswer.update.toString()) ||
                          (update && update.updates.length > 1 && !updateAnswer) ? (
                            <div className="w-180px h-34 mt-1-xs mla btn__secondary_green brd-transparent cursor text-center flex align-center">
                              <Link to={updateLink} className={'fs-14 white mxa'}>
                                <FormattedMessage id="weekly.update" defaultMessage="Weekly Update" />
                              </Link>
                            </div>
                          ) : null)}
                      </div>
                    </div>

                    {company.plan !== 1 ? (
                      <Fragment>
                        {updateAnswers.length === 0 ? (
                          <Fragment>
                            {update && update.updates.length > 1 && (
                              <Notification
                                msg={updateCopy.updateLive1}
                                msg2={updateCopy.updateLive2}
                                cta={updateCopy.updateLiveCta}
                                btnColor={'green'}
                                color={`green`}
                                url={updateLink}
                              />
                            )}
                            <Fragment>
                              {onboardingUpdates.map((update) => (
                                <UpdateItem
                                  key={`teamUpdates-${update._id}`}
                                  updateAnswers={onboardingUpdates}
                                  updateDate={update.updateDate}
                                  mentions={mentions}
                                />
                              ))}
                            </Fragment>
                          </Fragment>
                        ) : (
                          <Fragment>
                            {/* {update && update.updates.length > 1 && updateAnswer &&
                              update.updates[0]._id.toString() !== updateAnswer.update.toString() || update && update.updates.length > 1 && !updateAnswer && (
                                <Notification
                                  msg={updateCopy.updateLive1}
                                  msg2={updateCopy.updateLive2}
                                  cta={updateCopy.updateLiveCta}
                                  btnColor={'green'}
                                  color={`green`}
                                  url={updateLink}
                                />
                              )} */}

                            {(update.settings && !update.settings.isPrivate) ||
                            (update.settings && update.settings.isPrivate && user && user.isAdmin) ? (
                              <div className="mt-4">
                                <Chart
                                  updateAnswers={updateAnswersUniqueFiltered && updateAnswersUniqueFiltered}
                                />
                              </div>
                            ) : (
                              ''
                            )}

                            {updateAnswers.length > 0 && updateAnswersUniqueFiltered.length > 0 ? (
                              updateAnswersUniqueFiltered.map((updateAnswerUnique, index) => (
                                <Fragment key={`update-${index}`}>
                                  {(update.settings && !update.settings.isPrivate) ||
                                  (update.settings && update.settings.isPrivate && user && user.isAdmin) ||
                                  (update.settings &&
                                    update.settings.isPrivate &&
                                    user &&
                                    !user.isAdmin &&
                                    updateAnswerUnique &&
                                    updateAnswerUnique.user !== null &&
                                    user._id === updateAnswerUnique.user) ? (
                                    <Fragment key={`fragment-update-${index}`}>
                                      <UpdateTitle
                                        key={`date-${index}`}
                                        date={updateAnswerUnique.date}
                                        index={index}
                                        days={7}
                                        action={'-'}
                                      />

                                      <UpdateItem
                                        key={`updates-${updateAnswerUnique._id}-${index}`}
                                        updateAnswers={updates.filter(
                                          (updateAnswer) =>
                                            updateAnswer.update.toString() ===
                                            updateAnswerUnique.update.toString()
                                        )}
                                        updateDate={updateAnswerUnique.updateDate}
                                        formData={formData}
                                        setFormData={setFormData}
                                        searchKeyword={searchKeyword.length > 0 && searchKeyword}
                                        mentions={mentions}
                                        settings={update && update.settings}
                                      />
                                    </Fragment>
                                  ) : (
                                    ''
                                  )}
                                </Fragment>
                              ))
                            ) : (
                              <div className="mt-5">
                                {!searchResults ? (
                                  <NoContent
                                    message={noUpdatesMessageTeam}
                                    picture={updateImg}
                                    imgWidth={'w-300px'}
                                    alt={'No updates'}
                                  />
                                ) : (
                                  <NoContent
                                    message={noSearchResults}
                                    picture={updateImg}
                                    imgWidth={'w-300px'}
                                    alt={'No search results'}
                                  />
                                )}
                              </div>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      <NoContent
                        message={updateCopy.updatePlanMsg}
                        picture={updateImg}
                        alt={'Update plan'}
                        imgWidth={'w-300px'}
                        admin={
                          user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0
                        }
                      />
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

Update.propTypes = {
  clearUpdates: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  getCompanyUpdateAnswers: PropTypes.func.isRequired,
  getUpdates: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getAllCustoms: PropTypes.func.isRequired,
  getUpdateSettings: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  updateAnswer: PropTypes.object.isRequired,
  update: PropTypes.object.isRequired,
  custom: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  updateAnswer: state.updateAnswer,
  update: state.update,
  custom: state.custom,
  locale: state.locale
});

export default connect(mapStateToProps, {
  getCompanyUpdateAnswers,
  getUserCompany,
  getCompanyProfiles,
  getUpdates,
  clearUpdates,
  getAllCustoms,
  getUpdateSettings
})(Update);
