import React from 'react';
import { FormattedMessage } from 'react-intl';

export const updateCopy = {
  msgOnboarding: (
    <FormattedMessage
      id="update.onboarding.company.notification.msg1"
      defaultMessage="You're using the sample data below. Run your first Weekly Update and you'll see your data there. Click the button below to answer your first Weekly Update."
    />
  ),
  ctaOnboarding: (
    <FormattedMessage
      id="update.onboarding.company.notification.cta"
      defaultMessage="Complete the update"
    />
  ),
  updatePlanMsg: {
    title: (
      <FormattedMessage
        id="company.updateplan.title"
        defaultMessage="Update Plan"
      />
    ),
    msg: (
      <FormattedMessage
        id="company.updates.updateplan.msg"
        defaultMessage={`Updates aren't available on the Free plan. Please upgrade the plan to unlock this feature.`}
      />
    )
  }, 
  updateLive1: (
    <FormattedMessage
        id="update.cta.complete1"
        defaultMessage="Weekly Update is going on."
      />
  ),
  updateLive2: (
    <FormattedMessage
        id="update.cta.complete2"
        defaultMessage="Click the button below to participate into your the weekly retrospective."
      />
  ),
  updateLiveCta: (
    <FormattedMessage
        id="update.cta.completeCta"
        defaultMessage="Complete Update"
      />
  ),
  updateLiveNew: (
    <FormattedMessage
        id="update.cta.complete.new"
        defaultMessage="Weekly Updates are Going on ! Show your team what you accomplished."
      />
  ),
  updateLiveCtaNew: (
    <FormattedMessage
        id="update.cta.completeCta.new"
        defaultMessage="Share a Weekly Update"
      />
  ),
}

export default {
  updateCopy
}