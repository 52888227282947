import React, { Fragment } from "react";
import { FormattedMessage } from 'react-intl';

const Fs2Title = ({ title }) => {

  return (
    <Fragment><p className="fs-20 bold">{title}</p></Fragment>
  );
};

export default Fs2Title;
