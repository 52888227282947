import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { icons } from "../../../img/elements/icons";
import { FormattedMessage } from 'react-intl';
import IcebreakerForm from '../../comment/IcebreakerForm';

// Types:
// 1 - daily check-ins
// 2 - weekly updates
// 3 - weekly plans
// 4 - customs

const NotificationIcebreaker = ({ msg, user, addIcebreakerAnswer, icebreakerID }) => {

  const [text, setText] = useState('');
  const [answer, handleAnswer] = useState(false);

  // ЗДЕСЬ получаем коллбэк и перебираем formData.checkins и обновляем комменты
  const onSubmit = async (text) => {
    try {

      const res = await addIcebreakerAnswer(icebreakerID, text);
  
      if (res !== undefined) {

        handleAnswer(!answer);
      }
      
    } catch (err) {
      console.log('Error in icebreaker form', err);
    }
  };

  let title = <FormattedMessage id="active.icebreaker" defaultMessage="Active Icebreaker" />
  let icebreakerTxt = <FormattedMessage id="view.icebreaker" defaultMessage="View Icebreaker Answers" />

  let avatarWidth = '35px';

  return (
    <div className={`notification__weekly notification__weekly__icebreaker px-4 pt-4 pb-3 br-4 shadow text-left`}>
      <div className={`flex align-center mb-24`}>
        <span className="mr-15">{icons.icebreaker_26}</span>
        <p className="fs-22 fw-600">{title}</p>
      </div>
      <p className="medium text-left mb-24">
        {msg}
      </p>
      <div className="icebreaker__form">
        {!answer ? (
          <IcebreakerForm
            avatar={user.avatar}
            avatarWidth={avatarWidth}
            text={text}
            setText={setText}
            onSubmit={onSubmit}
          />
        ) : (
          <Fragment>
            <div className="answer flex align-center">
              <img
                src={user.avatar}
                alt="Avatar"
                className="round-img d-block avatar mr-1"
                width={avatarWidth}
                height={avatarWidth}
              />
              <p className="fs-14">{text}</p>
            </div>
            <Link
              to={'/icebreakers'}
              className="fs-12 blue cursor bold mt-xs ml-45"
            >
              {icebreakerTxt}
            </Link>
          </Fragment>
        )}
      </div>
    </div>
  );
};

NotificationIcebreaker.propTypes = {
  msg: PropTypes.object.isRequired,
};

export default NotificationIcebreaker;
// export default connect(
//   null,
//   { addIcebreakerAnswer }
// )(NotificationIcebreaker);