import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { createSlackInvite } from '../../actions/auth';
import { getSlackUsers, getSlackChannelsMembers } from '../../actions/slack';
import { getCompanyProfiles } from '../../actions/profile';
import { icons } from '../../img/elements/icons';
import ThreeStateCheckbox from '../elems/ThreeStateCheckbox';
import Spinner from '../layout/Spinner';
import useAnalyticsEventTracker from '../../utils/analytics/useAnalyticsEventTracker';

const CreateSlackInvitation = ({
  createSlackInvite,
  locale: { lang },
  auth: { user, loading: loadingAuth },
  company: { company, loading: loadingCompany },
  slack: { users, channelsMembers: channels, loading: loadingSlack },
  profile: { profiles, loading: loadingProfile },
  getSlackUsers,
  getSlackChannelsMembers,
  getCompanyProfiles,
  history,
  toggleModal = () => {},
  onSuccess,
  goBack
}) => {
    const close = () => toggleModal(false);

    const [message, showMessage] = useState(false);

    const [selectedChannels, setSelectedChannels] = useState({});
    const [selectedUsers, setSelectedUsers] = useState({});

    const [allChannesSelected, setAllChannelsSelected] = useState(null);
    const [allUsersSelected, setAllUsersSelected] = useState(null);

    const [loading, setLoading] = useState(false);

    const alreadyInvited = useCallback(slackUser => {
      return profiles && profiles.some(profile => profile.slackId === slackUser.user_id || profile.email === slackUser.email);
    }, [profiles]);

    const invitedUsers = useMemo(() => {
      if (users) {
        return users.filter(alreadyInvited).map(({ user_id }) => user_id);
      } else {
        return [];
      }
    }, [alreadyInvited, users]);

    const isBot = useCallback(slackId => {
      return users && users.every(({ user_id }) => user_id !== slackId);
    }, [users]);

    const hasNewUsers = useCallback(channel => {
      return channel.members.some(member => !invitedUsers.includes(member) && !isBot(member));
    }, [invitedUsers, isBot]);

    const toggleChannel = useCallback((id) => {
      if (channels) {
        const oldValue = selectedChannels[id];

        setSelectedChannels({ ...selectedChannels, [id]: !oldValue });

        const channel = channels.find(({ channel_id }) => id === channel_id);

        if (oldValue) {
          // all members from channel that are not members any other selected channel
          const keepMembers = Object.keys(selectedChannels)
            .filter(channelId => channelId !== channel.channel_id && selectedChannels[channelId])
            .map(channelId => channels.find(({ channel_id }) => channelId === channel_id))
            .filter(Boolean)
            .flatMap(otherSelectedChannel => otherSelectedChannel.members);

          const newUnselectedUsers = channel.members
            .filter(member => !keepMembers.includes(member) && !isBot(member))
            .reduce((unselected, member) => ({ ...unselected, [member]: false }), {});

          if (Object.keys(newUnselectedUsers).length > 0) {
            setSelectedUsers({ ...selectedUsers, ...newUnselectedUsers });
          }
        } else {
          const newSelectedUsers = channel.members
            .filter(member => !selectedUsers[member] && !isBot(member) && !invitedUsers.includes(member))
            .reduce((selected, member) => ({ ...selected, [member]: true }), {});

          if (Object.keys(newSelectedUsers).length > 0) {
            setSelectedUsers({ ...selectedUsers, ...newSelectedUsers });
          }
        }
      }
    }, [selectedChannels, selectedUsers, isBot, channels]);

    const toggleUser = useCallback((id) => {
      if (channels) {
        const oldValue = selectedUsers[id];

        setSelectedUsers({ ...selectedUsers, [id]: !oldValue });

        if (oldValue) {
          const newUnselectedChannels = channels
            .filter(({ channel_id }) => selectedChannels[channel_id])
            .filter(({ members }) => members.includes(id))
            .reduce((selected, channel) => ({ ...selected, [channel.channel_id]: false }), {});

          if (Object.keys(newUnselectedChannels).length > 0) {
            setSelectedChannels({ ...selectedChannels, ...newUnselectedChannels });
          }
        } else {
          const newSelectedChannels = channels
            .filter(hasNewUsers)
            .filter(({ members }) => members.every(member => selectedUsers[member] || member === id || invitedUsers.includes(member) || isBot(member)))
            .reduce((unselected, channel) => ({ ...unselected, [channel.channel_id]: true }), {});

          if (Object.keys(newSelectedChannels).length > 0) {
            setSelectedChannels({ ...selectedChannels, ...newSelectedChannels });
          }
        }
      }
    }, [selectedUsers, selectedChannels, invitedUsers, channels]);

    const onChangeAllChannelsSelected = useCallback((value) => {
      if (channels) {
        if (value) {
          const newSelectedChannels = channels
            .filter(hasNewUsers)
            .filter(({ channel_id }) => !selectedChannels[channel_id])
            .reduce((selected, channel) => ({ ...selected, [channel.channel_id]: true }), {});

          if (Object.keys(newSelectedChannels).length > 0) {
            setSelectedChannels({ ...selectedChannels, ...newSelectedChannels });

            const newSelectedUsers = Object.keys(newSelectedChannels)
              .map(channelId => channels.find(({ channel_id }) => channelId === channel_id))
              .filter(Boolean)
              .flatMap(({ members }) => members)
              .filter(member => !selectedUsers[member] && !isBot(member))
              .reduce((selected, member) => ({ ...selected, [member]: true }), {});

            if (Object.keys(newSelectedUsers).length > 0) {
              setSelectedUsers({ ...selectedUsers, ...newSelectedUsers });
            }
          }
        } else {
          const newUnselectedChannels = channels
            .filter(({ channel_id }) => selectedChannels[channel_id])
            .reduce((selected, channel) => ({ ...selected, [channel.channel_id]: false }), {});

          if (Object.keys(newUnselectedChannels).length > 0) {
            setSelectedChannels({ ...selectedChannels, ...newUnselectedChannels });

            const newUnselectedUsers = Object.keys(newUnselectedChannels)
              .map(channelId => channels.find(({ channel_id }) => channelId === channel_id))
              .filter(Boolean)
              .flatMap(({ members }) => members)
              .filter(member => selectedUsers[member] && !isBot(member))
              .reduce((unselected, member) => ({ ...unselected, [member]: false }), {});

            if (Object.keys(newUnselectedUsers).length > 0) {
              setSelectedUsers({ ...selectedUsers, ...newUnselectedUsers });
            }
          }
        }
      }
    }, [selectedChannels, selectedUsers, isBot, channels]);

    const onChangeAllUsersSelected = useCallback((value) => {
      if (channels && users) {
        if (value) {
          const newSelectedUsers = users
            .filter(user => !selectedUsers[user.user_id] && !isBot(user.user_id))
            .reduce((selected, user) => ({ ...selected, [user.user_id]: true }), {});

          if (Object.keys(newSelectedUsers).length > 0) {
            setSelectedUsers({ ...selectedUsers, ...newSelectedUsers });

            const newSelectedChannels = channels
              .filter(({ channel_id }) => !selectedChannels[channel_id])
              .reduce((selected, channel) => ({ ...selected, [channel.channel_id]: true }), {});

            if (Object.keys(newSelectedChannels).length > 0) {
              setSelectedChannels({ ...selectedChannels, ...newSelectedChannels });
            }
          }
        } else {
          const newUnselectedUsers = users
            .filter(user => selectedUsers[user.user_id] && !isBot(user.user_id))
            .reduce((unselected, user) => ({ ...unselected, [user.user_id]: false }), {});

          if (Object.keys(newUnselectedUsers).length > 0) {
            setSelectedUsers({ ...selectedUsers, ...newUnselectedUsers });

            const newUnselectedChannels = channels
              .filter(({ channel_id }) => selectedChannels[channel_id])
              .reduce((unselected, channel) => ({ ...unselected, [channel.channel_id]: false }), {});

            if (Object.keys(newUnselectedChannels).length > 0) {
              setSelectedChannels({ ...selectedChannels, ...newUnselectedChannels });
            }
          }
        }
      }
    }, [selectedUsers, selectedChannels, isBot, channels, users]);

    useEffect(() => {
      if (channels) {
        const values = channels
          .filter(hasNewUsers)
          .map(({ channel_id }) => selectedChannels[channel_id]);

        if (values.every(Boolean)) {
          setAllChannelsSelected(true);
        } else if (values.some(Boolean)) {
          setAllChannelsSelected(null);
        } else {
          setAllChannelsSelected(false);
        }
      }
    }, [selectedChannels, channels]);

    useEffect(() => {
      if (users) {
        const values = users
          .filter(user => !alreadyInvited(user))
          .map(({ user_id }) => selectedUsers[user_id]);

        if (values.every(Boolean)) {
          setAllUsersSelected(true);
        } else if (values.some(Boolean)) {
          setAllUsersSelected(null);
        } else {
          setAllUsersSelected(false);
        }
      }
    }, [selectedUsers, isBot, users]);

    useEffect(() => {
      if (company && company.isSlack) {
        if (!users) {
          getSlackUsers(company._id, company.slackTeamId);
        }
        if (!channels) {
          getSlackChannelsMembers(company._id, company.slackTeamId);
        }
      }
    }, [company, getSlackUsers, getSlackChannelsMembers]);

    useEffect(() => {
      getCompanyProfiles();
    }, []);

    const slackIds = useMemo(() => {
      return Object.entries(selectedUsers)
        .filter(([ _, value ]) => value)
        .map(([ key, _ ]) => key);
    }, [selectedUsers]);

    const onSubmit = (e) => {
      setLoading(true);

      e.preventDefault();
      useAnalyticsEventTracker('invite', 'invite sent', 'Creating a team Slack invite');
  
      createSlackInvite(slackIds, lang, history)
        .then((res) => {
          if (res) {
            close();
            onSuccess();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <Fragment>
        <div className="modal" style={{ zIndex: 50001 }} onClick={close}>
          <div
            onClick={(e) => e.stopPropagation()}
            className="modal-content br-4"
            style={{
              marginTop: '5%',
              marginBottom: 0
            }}
          >
          <Fragment>
          {loading || loadingAuth || loadingSlack || loadingProfile || loadingCompany || !users || !channels ? 
            <Spinner />
          :
            <div className="p-5">
              <div className="modal-header">
                <h5 className="pl-0-xs fw-600">
                  <FormattedMessage id="admin.createInvite.header" defaultMessage="Invite Your Team" />
                </h5>
              </div>
              <div>
                <p className="medium">
                  <FormattedMessage
                    id="admin.createInvite.slack.text"
                    defaultMessage="Each member will get a link to set up their account."
                  />
                </p>
                <div className='flex space-between mt-2'>
                  <div className='flex-column justify-start w-49'>
                    <h3 className="fs-2"><FormattedMessage id="admin.createInvite.channels" defaultMessage="Channels" /></h3>
                    <p><FormattedMessage id="admin.createInvite.select.channels" defaultMessage="Select channels to invite members:" /></p>
                    <div className='brd brd-grey br-5'>
                      <div className="border-bottom-gray">
                        <div className='ml-1 mt-1 mb-1'>
                          <ThreeStateCheckbox value={allChannesSelected} onChange={onChangeAllChannelsSelected} />
                        </div>
                      </div>
                      <div className='vertical-scroll ma-h250-xs'>
                        {channels && channels.map(channel => ({ ...channel, enabled: hasNewUsers(channel) })).map(({ channel_id, name, enabled }, index) => (
                          <div key={channel_id} className={classNames({ 'flex': true, 'border-top-gray': index > 0, 'h-50px': true, 'align-center': true, 'bg-primary_light2': !enabled, 'opacity-04': !enabled })}>
                            <div className="checkbox">
                              <div className="checkbox-item align-center active-status ml-4 mb-1 mr-0">
                                {enabled && <input
                                  type="checkbox"
                                  id={channel_id}
                                  name="channels"
                                  checked={!!selectedChannels[channel_id]}
                                  value={!!selectedChannels[channel_id]}
                                  onChange={() => toggleChannel(channel_id)}
                                />}
                                <label htmlFor={channel_id} />
                              </div>
                            </div>
                            <p>{`#${name}`}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className='flex-column justify-start w-49'>
                    <h3 className="fs-2"><FormattedMessage id="admin.createInvite.members" defaultMessage="Members" /></h3>
                    <p><FormattedMessage id="admin.createInvite.select.members" defaultMessage="Select individual members to invite:" /></p>
                    <div className='brd brd-grey br-5'>
                      <div className="border-bottom-gray">
                        <div className='ml-1 mt-1 mb-1'>
                          <ThreeStateCheckbox value={allUsersSelected} onChange={onChangeAllUsersSelected} />
                        </div>
                      </div>
                      <div className='vertical-scroll ma-h250-xs'>
                        {users && users.map(user => ({ ...user, enabled: !alreadyInvited(user) })).map(({ user_id, email, name, avatar, enabled }, index) => (
                          <div key={user_id} className={classNames({ 'flex': true, 'border-top-gray': index > 0, 'h-50px': true, 'align-center': true, 'bg-primary_light2': !enabled, 'opacity-04': !enabled })}>
                            <div className="checkbox">
                              <div className="checkbox-item align-center active-status ml-4 mb-1 mr-0">
                                {enabled && <input
                                  type="checkbox"
                                  id={user_id}
                                  name="users"
                                  checked={!!selectedUsers[user_id]}
                                  value={!!selectedUsers[user_id]}
                                  onChange={() => toggleUser(user_id)}
                                />}
                                <label htmlFor={user_id} />
                              </div>
                            </div>
                            <img src={avatar} className='round-img w-40px h-40px'></img>
                            <div className='flex-column ml-2'>
                              <h3>{name}</h3>
                              <p className='small-xs grey_new'>{email}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex mt-2">
                <button
                  type="submit"
                  onClick={onSubmit}
                  className="btn btn-form btn_primary_new mt-0 flex align-center"
                  disabled={slackIds.length === 0}
                >
                  <span className="rounded-text-icon white">{icons.circle_plus}</span>{' '}
                  <FormattedMessage id="admin.createInvite.mainBtn" defaultMessage="Send Invitation" />
                </button>
                <button className="btn-secondary btn-secondary-back" onClick={goBack}>
                  <FormattedMessage id="go.back" defaultMessage="Go Back" />
                </button>
                <button
                  type="button"
                  className={classNames({ 'btn-msg_new': true, active: message, 'ml-3': true })}
                  onClick={(e) => {
                    showMessage(!message);
                  }}
                >
                  <FormattedMessage
                    id="admin.createInvite.secondBtn"
                    defaultMessage="What the invite will say"
                  />
                </button>
              </div>
              <div className="invitation_msg">
                {message && (
                  <div className="card-body mt-25 light br-4">
                    <p className="list-item medium">
                      <FormattedMessage id="admin.createInvite.letter1" defaultMessage="Hello," />
                      <br />
                      {user.firstName}{' '}
                      <FormattedMessage
                        id="admin.createInvite.letter2"
                        defaultMessage="has invited you to Focus."
                      />
                      <br />
                      <FormattedMessage
                        id="admin.createInvite.letter3b"
                        defaultMessage="Please accept this invitation to start using Focus by clicking this link."
                      />
                    </p>
                  </div>
                )}
              </div>
            </div>
          }
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

CreateSlackInvitation.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  locale: state.locale,
  auth: state.auth,
  company: state.company,
  slack: state.slack,
  profile: state.profile,
});

export default connect(
  mapStateToProps,
  {
    createSlackInvite,
    getSlackUsers,
    getSlackChannelsMembers,
    getCompanyProfiles,
  }
)(withRouter(CreateSlackInvitation));