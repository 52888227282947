import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Switch from '../elems/Switch';
import { icons } from "../../img/elements/icons";
import { getOrdinalDay, getMonthName } from '../../utils/dates';

const nextMsg = <FormattedMessage id="next" defaultMessage="next" />
const dontwork = <FormattedMessage id="dont.work" defaultMessage="don't work on this plan" />
const checkinTxt = <FormattedMessage id="checkin" defaultMessage="Check-in" />;
const okrCallTxt = <FormattedMessage id="okrcall" defaultMessage="Call" />;
const teamsTxt = <FormattedMessage id="teams" defaultMessage="Teams" />;
const visibilityTxt = <FormattedMessage id="visibility" defaultMessage="Visibility" />;
const everyoneTxt = <FormattedMessage id="everyone" defaultMessage="Everyone" />;
const active = <FormattedMessage id="admin.dashboard.updates.active" defaultMessage="Active" />;
const inactive = <FormattedMessage id="inactive" defaultMessage="Inactive" />;
const icebreakersTxt = <FormattedMessage id="icebreakers" defaultMessage="Icebreakers" />;

const CampaignItemNew = ({
  formData,
  setFormData,
  disabled,
  campaignTitle,
  name,
  inputName,
  status,
  url,
  toggleStatusFunc,
  settings,
  isArray,
  deleteCustom,
  deleteOkrCall,
  id,
  type,
  history
}) => {
  
  const setData = () => {
    if (!isArray) {
      setFormData({ ...formData, inputName: !status });
    } else {
      setFormData({ ...formData, inputName: !status  });
    }
  };

  const [showIcons, toggleShowIcons] = useState(false);


  /** Hook that alerts clicks outside of the passed ref */
  // Hide Okr Detail if clicks outside
  function useOutsideAlerter(ref) {
    useEffect(() => {

      /** Alert if clicked on outside of element */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          toggleShowIcons(false)
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const getDays = () => {
    let string = '';

    if (settings.interval === "Weekly" || settings.interval === "Biweekly") {
      string = settings.scheduleDay.day;
    } else if (settings.interval === "Monthly") {
      string = `${getOrdinalDay(settings.scheduleDay.index)} of the month`
    } else if (settings.interval === "Daily") {
      const lastActive = (el) => el.isActive || el.isChecked;
      let lastIndex = settings.scheduleDay.weekDays.findLastIndex(lastActive)

      settings.scheduleDay.weekDays.forEach((day, idx) => {
        if (day.isActive || day.isChecked) {
          string += `${day.day}${idx !== lastIndex ? ', ' : ''}`
        }
      });
    } else if (settings.interval === 'Quarterly') {
      string = `from ${getOrdinalDay(settings.scheduleDay.index)} ${getMonthName(settings.scheduleDay.month)}`;
    } else if (settings.interval === 'Annually') {
      string = `${getOrdinalDay(settings.scheduleDay.index)} ${getMonthName(settings.scheduleDay.month)}`;
    }

    return string;
  };

  const handleStatusChange = () => {
    setData();
    toggleStatusFunc(settings._id);
  };

  return (
    <Fragment>
      <div className="relative br-4 brd brd-grey__light bg__white mb-18">
          <div className="flex align-center mx-25 my-2">
            <div className="flex flex-column align-start w-50 card__settings__left">
              {type === 'checkin'
                ? <Link to={url}>
                    <p className="fs-16 fw-600 lh-20 pb-xs">{campaignTitle}</p>
                  </Link>
                : <p className="fs-16 fw-600 lh-20 pb-xs">{campaignTitle}</p>}
              <div className="flex align-center">
                <p className="fs-12 lh-18">
                  <b>{settings.interval}{" "}{type === 'okrcall' ? okrCallTxt : checkinTxt}</b>
                  {" "}
                  ({getDays()})
                  {inputName !== 'icebreakerStatus' ? null : (
                    <span>{" "}-{" "}{settings.icebreakers.length}{" "}{icebreakersTxt}{" "}({nextMsg}:{' '}{`${settings.nextQuestionId + 1}/${settings.icebreakers.length}`})</span>
                  )}
                </p>
                <span className="fs-12 mx-xs">|</span>
                <p className="fs-12 lh-18">
                  {teamsTxt}:{" "}{(settings.emailUsers || []).concat(settings.slackUsers || []).map(({ name }) => name).join(', ')}{" "}
                  {inputName !== 'icebreakerStatus' && inputName !== 'okrcall' ? (
                    <span>
                      ({visibilityTxt}: {settings.visibility ? settings.visibility.label : everyoneTxt})
                    </span>
                    ) : null}
                  </p>
              </div>
            </div>

            {disabled && (
              <p className="small grey">
              {name}{' '}{dontwork}
                </p>
              )}

            <div className="content__right__settings flex align-center minw-270px mla">
              <div className={`w-100px`}>
                <div className="checkbox checkbox-status">
                  <div className="h-44 ml-15 flex align-center">
                    <Switch
                      name={inputName}
                      value={status}
                      onChange={handleStatusChange}
                      index={0}
                    />
                    <p className="fs-12 fw-600 ml-1">{status ? active : inactive}</p>
                  </div>
                </div>
              </div>

              {inputName === 'custom' ? (
                <td ref={wrapperRef} className={`w-35px mla`}>
                  <div className="flex align-center br-4 brd brd-grey__light bg__white relative">
                    <div className={`no_btn relative h-34 px-1 icon__box ${showIcons ? 'icon__box_active' : ''}`} onClick={() => toggleShowIcons(!showIcons)}>
                      <span className="w-50px lh-28">
                        {icons.dots}
                      </span>
                    </div>
                    {showIcons && (
                      <div className="w-100px z-20000 light-shadow absolute brd brd-grey__light br-4 dropdown-40 right bg__white">
                        <button 
                          className="w-100 icon__box fs-14 icon__grey no_btn px-1 lh-30"
                        >
                          <Link 
                            className='fs-14 icon__grey'
                            to={`/settings/${url}`} 
                          >
                            {icons.edit_small}
                            {' '}
                            <FormattedMessage
                              id="edit"
                              defaultMessage="Edit"
                            />
                          </Link>
                        </button>
                        <button 
                          className="w-100 icon__box fs-14 icon__grey no_btn px-1 lh-30"
                        >
                          <Link 
                            className='fs-14 icon__grey'
                            to={`/settings/campaign/new/${id}`} 
                          >
                            {icons.copy_small}
                            {' '}
                            <FormattedMessage
                              id="copy"
                              defaultMessage="Copy"
                            />
                          </Link>
                        </button>
                        <button 
                          type="button"
                          onClick={() => { if (window.confirm('Delete Check-in. All data and answers will be lost. Are you sure you wish to delete this check-in?')) deleteCustom(settings._id, history) } }
                          className="w-100 icon__box fs-14 icon__grey no_btn px-1 lh-30"
                        >
                          {icons.trash_small}
                          {' '}
                          <FormattedMessage
                            id="delete"
                            defaultMessage="Delete"
                          />
                        </button>
                      </div>
                      )}
                  </div>
                </td>
              ) : (
                <td ref={wrapperRef}  className={`w-35px mla`}>
                  <div className="flex align-center br-4 brd brd-grey__light bg__white relative">
                    <div className={`no_btn relative h-34 px-1 icon__box ${showIcons ? 'icon__box_active' : ''}`} onClick={() => toggleShowIcons(!showIcons)}>
                      <span className="w-50px lh-28">
                        {icons.dots}
                      </span>
                    </div>
                    {showIcons && (
                      <div className="w-100px z-20000 light-shadow absolute brd brd-grey__light br-4 dropdown-40 right bg__white">
                        <button 
                          className="w-100 icon__box fs-14 icon__grey no_btn px-1 lh-30"
                        >
                          <Link 
                            className='fs-14 icon__grey'
                            to={`/settings/${url}`} 
                          >
                            {icons.edit_small}
                            {' '}
                            <FormattedMessage
                              id="edit"
                              defaultMessage="Edit"
                            />
                          </Link>
                        </button>
                        {type === 'okrcall' && (
                          <button 
                            type="button"
                            onClick={() => { if (window.confirm('Delete OKR Call. Are you sure you wish to delete this OKR Call?')) deleteOkrCall(settings._id, history) } }
                            className="w-100 icon__box fs-14 icon__grey no_btn px-1 lh-30"
                        >
                          {icons.trash_small}
                          {' '}
                          <FormattedMessage
                            id="delete"
                            defaultMessage="Delete"
                          />
                        </button>
                        )}
                      </div>
                      )}
                  </div>
                </td>
              )}
            </div>

          </div>
        </div>
    </Fragment>
  );
};

CampaignItemNew.propTypes = {
  settings: PropTypes.object,
  updateSettings: PropTypes.object,
  title: PropTypes.object,
  slack: PropTypes.number,
  disabled: PropTypes.bool,
  deleteCustom: PropTypes.func,
  deleteOkrCall: PropTypes.func
};

export default CampaignItemNew;
