import axios from 'axios';
import { setAlert } from './alert';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  GET_UPDATES,
  GET_UPDATE,
  UPDATES_ERROR,
  GET_UPDATES_SETTINGS,
  UPDATES_SETTINGS_ERROR,
  CHANGE_CAMPAIGN_TYPE,
  CLEAR_UPDATES
} from './types';

// Edit weekly update
export const editUpdate = (formData, history) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(`/api/updates/edit`, formData, config);

    dispatch({
      type: GET_UPDATES,
      payload: res.data
    });

    getUpdateSettings();

    dispatch(
      setAlert(
        <FormattedMessage
          id="alert.update.edit"
          defaultMessage="Weekly Updates was edited"
        />,
        'success'
      )
    );

    history.goBack();
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: UPDATES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all companies updates
export const getUpdates = () => async dispatch => {
  try {
    const res = await axios.get('/api/updates');

    dispatch({
      type: GET_UPDATES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: UPDATES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Updates settings
export const getUpdateSettings = () => async dispatch => {
  try {
    const res = await axios.get('/api/updates/settings');
    
    dispatch({
      type: GET_UPDATES_SETTINGS,
      payload: res.data
    });
  } catch (err) {
    console.log('==== err', err);

    dispatch({
      type: UPDATES_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get user team updates
export const getTeamUpdates = () => async dispatch => {
  try {
    const res = await axios.get('/api/updates/team');

    dispatch({
      type: GET_UPDATES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: UPDATES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get update by ID
export const getUpdateById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/updates/${id}`);

    dispatch({
      type: GET_UPDATE,
      payload: res.data
    });
  } catch (err) {
    console.log('==== err', err);
    dispatch({
      type: UPDATES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Switch ON/OFF update settings
export const toggleUpdateSettings = () => async dispatch => {
  try {
    const res = await axios.put('/api/updates');

    dispatch({
      type: GET_UPDATES_SETTINGS,
      payload: res.data
    });
  } catch (err) {
    console.log('==== err', err);

    dispatch({
      type: UPDATES_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Create settings and first update
export const createAdminUpdate = () => async dispatch => {
  try {
    const res = await axios.post('/api/updates/admin/settings');
    dispatch({
      type: GET_UPDATES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: UPDATES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Switch ON/OFF update settings
export const changeCampaignType = (campaignType) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({ campaignType });

    const res = await axios.put(
      `/api/updates/type`,
      body,
      config
    );
    
    dispatch({
      type: CHANGE_CAMPAIGN_TYPE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: UPDATES_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Clear state
export const clearUpdateSettings = () => async dispatch => {
  try {
   
    dispatch({
      type: CLEAR_UPDATES
    });
  } catch (err) {
    dispatch({
      type: UPDATES_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};