import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { updateLike } from '../../../actions/standupAnswer';
import { getCompanyAnswers } from '../../../actions/standupAnswer';
import CommentItem from '../../comment/checkins-noState/CommentItem';
import CommentForm from '../../comment/checkins-noState/CommentForm';
import QuestionItem from '../../comment/checkins/QuestionItem';
import { FormattedMessage } from 'react-intl';

const CheckinItemCardQuestion = ({
  auth: { loading, user },
  standupAnswer: { standupAnswers },
  avatar,
  question,
  answer,
  comments,
  likes,
  standupID,
  userID,
  updateLike,
  index
}) => {
  const findUserLike = likes => {
      if (likes.filter(like => like.user === user._id).length > 0) {
        return true;
      } else {
        return false;
      }
  };

  const findComments = comments => {
    if (comments.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const [discussion, showComments] = useState(false);

  return (
    <Fragment>
      <div className={`${index === 0 ? "card-question-item" : "card-list card-question-item pt-1"}`}>
        <QuestionItem
          key={`teamQuestionItem-${standupID}-${answer._id}`}
          question={question}
          answer={answer}
          avatar={avatar}
          _id={userID}
          index={index}
        />

        <Fragment>
          <div className="btn-feedback">
            <button
              type="button"
              className={classnames('btn-icons btn-like', {
                'btn-active-icon': findUserLike(likes)
              })}
              onClick={e => {
                updateLike(standupID, answer._id)}}
            >
              <i className="fas fa-heart" />
              <span className="small badge">
                {answer.likesAmount > 0 && <span>{answer.likesAmount}</span>}
              </span>
            </button>

            <button
              type="button"
              onClick={() => showComments(!discussion)}
              className={classnames('btn-icons btn-comment', {
                'btn-active-icon': findComments(comments)
              })}
            >
              <i className="fas fa-comment-alt" />
              <span className="small badge">
                {comments.length > 0 && <span>{comments.length}</span>}
              </span>
            </button>
          </div>
        </Fragment>

        {discussion && (
          <Fragment>
            <div className="card-feedback">
              <div className="comment">
                <p className="comment-title medium bold">
                  <strong>
                    <FormattedMessage id="comments" defaultMessage="Comments" />
                  </strong>
                </p>
                <div className="line" />

                {comments.map(comment => (
                  <CommentItem
                    key={`team-${comment._id}`}
                    comment={comment}
                    standupID={standupID}
                    answerID={answer._id}
                  />
                ))}

                <CommentForm
                  standupID={standupID}
                  answerID={answer._id}
                  user={user}
                  avatar={user.avatar}
                />
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

CheckinItemCardQuestion.propTypes = {
  standupAnswer: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  updateLike: PropTypes.func,
  getCompanyAnswers: PropTypes.func
};

const mapStateToProps = state => ({
  auth: state.auth,
  standupAnswer: state.standupAnswer
});

export default connect(
  mapStateToProps,
  { updateLike, getCompanyAnswers }
)(CheckinItemCardQuestion);
