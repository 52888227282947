import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import QuestionItem from '../../comment/QuestionItem';
import DailyFocusCardS from './DailyFocusCardS'

const UsersCardBody = ({ firstName, standupAnswers, _id }) => {

  // Check the object length is not 0
  let objectLength = 0;
  if (standupAnswers.length > 0 && standupAnswers[0].focus !== undefined && standupAnswers[0].focus !== null) {
    objectLength = Object.keys(standupAnswers[0].focus).length;
  }

  const focusOnOkr = <FormattedMessage id="focus.onOkr" defaultMessage="Focus on OKR" />;
  const okrHead = <FormattedMessage id="focus.day.okr" defaultMessage="OKR" />;

  let color = "";
  let label = "";

  if (objectLength > 0 && standupAnswers[0].focus.isFocus) {
    if (standupAnswers[0].focus.status === "1") {
      color = "green";
      label = "On Track";
    } else if (standupAnswers[0].focus.status === "2") {
      color = "yellow";
      label = "Behind";
    } else if (standupAnswers[0].focus.status === "3") {
      color = "red";
      label = "At Risk";
    }
  }

  return (
    <tr>
      <td colspan={6} className="pt-2 border-top-gray">
        <p className="fs-18 pb-2 block">
          <FormattedMessage id="team.people.card.last" defaultMessage="Latest Check-in" />
          {/* Latest Check-in */}
        </p>

        {standupAnswers.length === 0 ? (
          <Fragment>
            <p className="meduim">
              {firstName}{' '}
              <FormattedMessage id="team.people.card.notCompleted" defaultMessage="has not completed standup yet" />
              {/* has not completed standup yet */}
            </p>
          </Fragment>
        ) : (
          <Fragment>
            <div className="card-question-item">
              {standupAnswers[0].questions.map((question, index) => (
                <QuestionItem
                  key={`teamPeopleQuestion-${question._id}-${index}`}
                  question={question}
                  answer={standupAnswers[0].answers[index]}
                  _id={_id}
                />
              ))}


              {objectLength > 0 && standupAnswers[0].focus.isFocus && (
                <DailyFocusCardS dailyFocusAnswers={standupAnswers[0].focus}
                  color={color} label={label} />
              )}

            </div>
          </Fragment>
        )
        }
      </td >
    </tr >
  );
};

UsersCardBody.propTypes = {
  standupAnswers: PropTypes.array.isRequired,
  firstName: PropTypes.string.isRequired
};

export default UsersCardBody;
