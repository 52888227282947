import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { updatePlanLike, addPlanComment, deletePlanComment } from '../../../actions/planAnswer';
import { setAlert } from '../../../actions/alert';
import UniversalCommentItem from '../../comment/UniversalCommentItem';
import UniversalCommentForm from '../../comment/UniversalCommentFormNew';
import QuestionItem from '../../comment/UniQuestionItemNew';
import QuestionItemOkr from '../../comment/UniQuestionItemOkr';
import { FormattedMessage } from 'react-intl';
import { icons } from "../../../img/elements/icons";

// campaignType === 1 => Classic
// campaignType === 2 => OKR-based

const PlanItemCardQuestion = ({
  auth: { loading, user },
  avatar,
  question,
  answer,
  comments,
  likes,
  planID,
  userID,
  updatePlanLike,
  addPlanComment,
  deletePlanComment,
  index,
  formData,
  setFormData,
  searchKeyword,
  mentions,
  campaignType,
  weeklyOkrs,
  isHideLikesAndComments,
}) => {
  const [text, setText] = useState('');
  const [discussion, showComments] = useState(false);

  // передаем индекс, чтобы сразу менять потом стейт items, 
  // а не перебирать каждый items в поисках нашего icebreakers по айди
  const onSubmit = async (answerID, index, data, e) => {
    e.preventDefault();

    const res = await addPlanComment(planID, answerID, data);

    if (res !== undefined) {
      setFormData({ 
        ...formData, 
        lastFiveAnswers: formData.lastFiveAnswers.map(answer => {
          if (answer._id === res.planID) {
            // Update all comments 
            answer.comments = res.comments;
            answer.answers = res.answers;
           
            return answer;
          } else { return answer; }
        })
      });
    }
  };

  const findComments = comments => {
    if (comments.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const findUserLike = likes => {
    if (!loading) {
      if (likes.filter(like => like.user === user._id).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleLike = async (planID, answerID) => {
    try {
      const res = await updatePlanLike(planID, answerID);

      setFormData({ 
        ...formData, 
        lastFiveAnswers: formData.lastFiveAnswers.map(answer => {
          if (answer._id === res.planID) {
            
            // Update all likes 
            answer.likes = res.likes;
            answer.answers = res.answers;
            
            return answer;
          } else {
            return answer;
          }
        })
      });
      
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in likes', err);
    }
  };

  // ЗДЕСЬ получаем коллбэк и перебираем formData.[plans] и обновляем комменты
  const handleDeleteComment = async (answerID, commentID) => {
    try {
      const res = await deletePlanComment(planID, answerID, commentID);

      setFormData({ 
        ...formData, 
        lastFiveAnswers: formData.lastFiveAnswers.map(item => {
          if (item._id === res.planID) {
            // Delete comment 
            item.comments = item.comments.filter(comment => comment._id !== res.commentID);
            item.answers = res.answers;
           
            return item;
          } else {
            return item;
          }
        })
      });
      
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in weekly plan comments', err);
    }
  };

  return (
    <Fragment>
      <div className={"card-question-item pt-1"}>
        {campaignType === 1 || campaignType === undefined ? (
          <QuestionItem
            question={question}
            answer={answer && answer}
            avatar={avatar}
            index={index}
            searchKeyword={searchKeyword}
          />
        ) : (
          <QuestionItemOkr
            question={question}
            answer={answer && answer.okrAnswers && answer.okrAnswers.length > 0 && answer.okrAnswers}
            avatar={avatar}
            index={index}
            searchKeyword={searchKeyword}
            weeklyOkrs={weeklyOkrs}
          />
        )}

        {!isHideLikesAndComments && (
          <Fragment>
            <div className="btn-feedback flex align-center pb-0 pt-14 px-0 ml-38">
              <button
                type="button"
                className={classnames('btn-icons btn-like flex align-center mr-1', {
                  'btn-active-icon btn-like__active': findUserLike(likes)
                })}
                onClick={e => handleLike(planID, answer._id)}
              >
                <span className="mr-xs flex align-center">{icons.heart}{icons.heart_full}</span>
                <span className='fs-10 bold mb-0'>{likes.length > 0 ? likes.length : '0'}</span>
              </button>

              <button
                type="button"
                onClick={() => showComments(!discussion)}
                className={classnames('btn-icons btn-comment flex align-center icon__message__badge', {
                  'btn-active-icon': findComments(comments)
                })}
              >
                <span className="mr-xs flex align-center">{icons.message}</span>
                <span className="fs-10 bold mb-0">{comments.length > 0 ? comments.length : '0'}</span>
              </button>
            </div>
          </Fragment>
        )}
        

        {discussion && (
          <Fragment>
            <div className="card-feedback mt-24">
              <div className="comment">
                <div className="mb-1">
                  {comments.map(comment => (
                    <UniversalCommentItem
                      key={`team-${comment._id}`}
                      mainItemId={answer._id}
                      item={comment}
                      value={comment.text}
                      authUser={user}
                      isEdit={false}
                      searchKeyword={searchKeyword}
                      handleDelete={handleDeleteComment}
                    />
                  ))}
                </div>

                <div className="ml-38">
                  <UniversalCommentForm
                    mainItemId={answer._id}
                    index={index}
                    // text={text}
                    avatar={user.avatar}
                    onSubmit={onSubmit}
                    onChange={e => setText(e.target.value)}
                    mentions={mentions}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

PlanItemCardQuestion.propTypes = {
  auth: PropTypes.object.isRequired,
  updatePlanLike: PropTypes.func.isRequired,
  addPlanComment: PropTypes.func.isRequired,
  deletePlanComment: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { updatePlanLike, addPlanComment, deletePlanComment }
)(PlanItemCardQuestion);
