import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { icons } from "../../../img/elements/icons";
import { copy } from '../../../utils/wording/customs';

// Types:
// 1 - daily check-ins
// 2 - weekly updates
// 3 - weekly plans
// 4 - customs

const NotificationCheckin = ({ type, color, msg, msg2, url, btnColor, cta, externalLink, styles }) => {

  let icon = '';
  let bgColor = '';
  let width = '';
  let hover = '';
  let bgIcon = '';
  let shadow = ''

  if (type === 1) {
    icon = icons.light_bulb;
    bgColor = 'bg-blue';
    width = 'notification__daily';
    hover = 'hover-main';
    bgIcon = 'bg__main';
    shadow = 'shadow_new__blue';
  } else if (type === 2 || type === 5) { 
    icon = icons.clipboard
    bgColor = 'bg-green';
    width = 'notification__weekly';
    bgIcon = 'bg__success';
    shadow = 'shadow_new__green';
  } else if (type === 3) { 
    icon = icons.clipboard
    bgColor = 'bg-yellow';
    width = 'notification__weekly';
    bgIcon = 'bg__warning';
    shadow = 'shadow_new__yellow';
  }

  return (
    <div className={`${width} ${styles} ${bgColor} ${shadow} notification px-4 pt-4 pb-3 br-4 shadow text-left`}>
      <div className={`p-1 w-60px h-60px mb-24 ${bgIcon} br-4 text-center`}>{icon}</div>
      <p className="white notification__title fw-600 text-left mb-24">
        {/* {msg}{' '} */}
        {type !== 5 ? msg : (
          <Fragment>
            {msg}{" "}{copy.checkinLiveNew}
          </Fragment>
        )}{' '}
        {msg2 && (
          <Fragment>
            <br />
            <br /> {msg2}
          </Fragment>
        )}
      </p>
       {cta && (
         externalLink !== 1 ? (
          <Link
            to={url && url}
            className={
              btnColor
                ? `btn-secondary btn-secondary__${btnColor}-hover ${btnColor} py-15 px-25 bold`
                : 'btn-secondary btn-secondary__blue-hover blue py-15 px-25 bold'
            }
          >
            {cta}
          </Link>) : (
            <a 
              href={url && url} 
              className={
                btnColor
                  ? `btn-secondary btn-secondary__${btnColor}-hover ${btnColor} py-15 px-25 bold`
                  : 'btn-secondary btn-secondary__blue-hover blue py-15 px-25 bold'
              }
              rel="noopener noreferrer" 
              target="_blank">
                {cta}
            </a>
          )
      )}
    </div>
  );
};

NotificationCheckin.propTypes = {
  msg: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  cta: PropTypes.object,
  btnColor: PropTypes.string,
  msg2: PropTypes.object,
  url: PropTypes.string,
  externalLink: PropTypes.number
};

export default NotificationCheckin;
