import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { icons } from '../../img/elements/icons';

const CustomFormTextQuestionBody = ({
  index,
  tasks,
  value,
  handleChange,
  complitingTasks,
  onChangeComplitingTasks,
  isHideTasks,
  iconStyles,
  errorIndex
}) => {

  const [schema, setSchema] = useState(value && value.map((item, index) => {
    return {
      id: index,
      text: item
    }
  }));

  useEffect(() => {
    // Track compiling tasks
    if (value && value.length > 0 || value && value.length === 0 && value[0] !== '') {
      setSchema(value && value.map((item, index) => {
        return {
          id: index,
          text: item
        }
      }));
    }
  }, [value && value.length]);

  const onChange = (i) => (e) => {
    const newValue = [...value];
    newValue[i] = e.target.value;
    handleChange(newValue);

    const schemaCopy = schema;
    schemaCopy[i].text = e.target.value;
    setSchema(schemaCopy);
  };

  const handleKeyPress = (e) => {
    e.persist();
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      handleChange([...value, '']);
      setSchema( [...schema, { id: schema.length, text: '' }] );
    }
  };

  const onDragEnd = (result) => {

		// dropped outside the list
		if (!result.destination) {
			return;
		}

		// reorder using index of source and destination.
		const schemaCopy = schema.slice();

		const [removed] = schemaCopy.splice(result.source.index, 1);

		// put the removed one into destination.
		schemaCopy.splice(result.destination.index, 0, removed);

    let sorted = schemaCopy.map((item) => item.text)

    handleChange(sorted);
		setSchema(schemaCopy);
	};

  return value ? (
    <div className="form-app-okr">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="column">
					{(provided, snap) => (
						<div
							ref={provided.innerRef}
							{...provided.droppableProps}
						>
							{schema && schema.map((it, i) => (
								<Draggable
									key={it.id}
									draggableId={it.id.toString()}
									index={i}
								>
									{(provided, snap) => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											className="list-item fs-16 relativ"
										>
                      <div className="flex w-100">
                        <FormattedMessage key={`answer_text-${i}`} id="standup.form.comment" defaultMessage="Enter comment">
                          {(msg) => (
                            <div className="flex w-100">
                              <input
                                 className={`mb-1 input-text ${errorIndex === index ? 'brd-red' : ''}`}
                                 type="text"
                                 tabIndex="1"
                                 placeholder={msg}
                                 name={i}
                                 value={it.text || ''}
                                 onChange={onChange(i)}
                                 onKeyPress={handleKeyPress}
                              />
                              {!isHideTasks && !!tasks.find((tsk) => tsk.name === it.text) && (
                                <div className="ml-2 flex align-center mb-1">
                                  <input
                                    type={'checkbox'}
                                    className="fs-16"
                                    checked={
                                      complitingTasks[index] && complitingTasks[index][i]
                                        ? complitingTasks[index][i].value
                                        : tasks.find((tsk) => tsk.name === it.text).isCompleted
                                    }
                                    onChange={onChangeComplitingTasks(index, i, it.text)}
                                  />
                                  <span className="fs-14 ml-1" style={{ whiteSpace: 'nowrap' }}>
                                    Task Completed
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </FormattedMessage>

                        <div className="relative">
                          <span className={`absolute ${!iconStyles ? 'right-30' : iconStyles} top_plus19 z-50000`}>{icons.drag_cross_small}</span>
                        </div>
                      </div>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
      </DragDropContext>
    </div>
  ) : null;
};

export default CustomFormTextQuestionBody;
