import React from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SubMenu = ({ activeIndex, items, mb }) => {

  return (
    <div className={`sub-menu__page mt-2 mb-${mb ? mb : '3'}`}>
      {items && items.length > 0 && (
        items.map((item, index) => (
          <Link
            key={`menu-${index}`}
            to={item.url}
            className={`sub-menu__item inline-block mr-2 cursor ${index + 1 === activeIndex && ' sub-menu__item_active'}`}
          >
            {item.title}
          </Link>
          )
      ))}
    </div>
  );
};

SubMenu.propTypes = {
  items: PropTypes.array.isRequired,
};

export default SubMenu;
