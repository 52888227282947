import React, { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getIcebreakerSettings, editIcebreakSettings, changeIcebreakersLanguage, getDefaultIcebreakers } from '../../actions/icebreaker';
import { getUserCompany } from '../../actions/company';
import { getSlackUsers, getSlackChannels } from '../../actions/slack';
import { getDiscordChannels } from '../../actions/discord';
import { getCompanyProfiles } from '../../actions/profile';
import Logo from '../elems/Logo';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import ScheduleItem from './elems/ScheduleItem';
import ParticipantItem from './elems/ParticipantItem';
import SlackItem from './elems/SlackItem';
import QuestionItem from './elems/QuestionIcebreakerItem';
import 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';

// type: 1 - checkin, 2 - update, 3 plan, 4 - custom, 5 - icebreaker

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    fontSize: '14px',
  }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
}

const EditIcebreakers = ({
  changeIcebreakersLanguage,
  getDefaultIcebreakers,
  getIcebreakerSettings,
  editIcebreakSettings,
  icebreaker: { settings, loading },
  auth: { user },
  history,
  getUserCompany,
  profile: { profiles },
  getCompanyProfiles,
  company: { company },
  slack,
  getSlackUsers,
  getSlackChannels,
  getDiscordChannels,
  discord,
}) => {
  const [formData, setFormData] = useState({
    questions: [],
    scheduleTime: '',
    scheduleDay: {},
    interval: '',
    timezone: '',
    emailIsActive: settings && settings.emailIsActive,
    slackIsActive: settings && settings.slackIsActive,
    reportIsActive: settings && settings.reportIsActive,
    localReportTime: '',
    localReportHour: '',
    localReportMinute: '',
    isAsync: settings && settings.isAsync,
    slackReportChannels: settings && settings.slackReportChannels,
    selectedOption: [],
    selectedChannelOption: [],
    selectedEmailUsers: [],
    selectedSlackUsers: [],
    lang: '',
    discordIsActive: settings && settings.discord && settings.discord.isActive,
    selectedDiscordChannels: [],
    discordChannelOptions: []
  });

  const { 
    questions, scheduleTime, scheduleDay, interval, 
    timezone, slackIsActive, emailIsActive, isAsync,
    slackReportChannels, 
    selectedOption, selectedChannelOption, 
    localReportHour,
    localReportMinute,
    reportIsActive,
    selectedEmailUsers,
    selectedSlackUsers,
    lang,
    discordIsActive,
    selectedDiscordChannels,
    discordChannelOptions,
  } = formData;

  const [reportChannels, toggleReportChannels] = useState(false);
  const [emailUsersForm, toggleEmailUsers] = useState(false);
  const [slackUsersForm, toggleSlackUsers] = useState(false);
  const [discordUsersForm, toggleDiscordUsers] = useState(false);

  let options = [];
  let choosedUsers = [];
  let channelOptions = [];
  let choosedChannels = [];
  let choosedEmailUsers = [];
  let emailUsersOptions = [];
  let choosedSlackUsers = [];
  let slackUserAndChannelOptions = [];

  // Get Email Users to put them in select
  // Choosed users
  settings && settings.emailUsers.forEach(user => {
    let u = {
      value: user.email,
      label: user.name,
      team: user.team
    }

    choosedEmailUsers.push(u);
  })

  {
    company && company.departments.length > 0 &&
      company.departments.forEach((department, index) => {

        if (user && user.department === null) {
          let team = {
            value: department._id,
            label: `${department.name} team`,
            team: 2
          };
  
          emailUsersOptions.unshift(team);
        } else if (user && user.department !== null && department._id !== user.department) {
          let team = {
            value: department._id,
            label: `${department.name} team`,
            team: 2
          };
  
          emailUsersOptions.unshift(team);
        }

        if (user && index === company.departments.length - 1) {

          // Second option is the team if exists
          if (user.department && department._id === user.department) {
            let team = { value: department._id, label: `😎 ${department.name} team`, team: 2 };
            emailUsersOptions.unshift(team);
          }

        }
      });
  }

  // Users for options
  {
    profiles &&
      profiles.forEach((profile, index) => {

        let coworker = {
          value: profile.email,
          label: `${profile.firstName} ${profile.lastName}`,
          team: 3
        };

        emailUsersOptions.push(coworker);


        if (profiles && index === profiles.length - 1) {
          // Third option is the company
          let name = { value: 'Company', label: `💪 Everyone`, team: 1 };
          emailUsersOptions.unshift(name);
        }
      });
  }


  // Get Slack Users to put them in select (for participants select)
  // Choosed CHANNEL for icebrealer
  settings && settings.slackUsers.forEach(channel => {
    let u = {
      value: channel.channel,
      label: channel.name,
    }

    choosedSlackUsers.push(u);
  })

  slack.channels &&
    slack.channels.forEach((channel, index) => {
      let u = {
        value: channel.channel_id,
        label: `#${channel.name}`,
        team: 2
      };

      slackUserAndChannelOptions.unshift(u);

    });

  // Users Options for Select format (for delivery report)
  slack.users &&
    slack.users.forEach(user => {
      let u = {
        value: user.user_id,
        label: user.name,
        avatar: user.avatar
      };

      options.push(u);
    });

  // Channels Options for Select format
  slack.channels &&
    slack.channels.forEach(channel => {
      let u = {
        value: channel.channel_id,
        label: channel.name
      };

      channelOptions.push(u);
    });

  // Users & Channels Default values for Select format
  settings && settings.slackReportChannels &&
    settings.slackReportChannels.forEach(item => {
      if (item.user_id) {
        let с = {
          value: item.user_id,
          label: item.name
        };

        choosedUsers.push(с);
      }

      // For channels
      if (item.channel_id) {
        let с = {
          value: item.channel_id,
          label: item.name
        };

        choosedChannels.push(с);
      }
    });

  useEffect(() => {
    getIcebreakerSettings();
    getUserCompany();
    getCompanyProfiles();
    company && company.isSlack && getSlackChannels(company._id, company.slackTeamId);
    company && company.discord && company.discord.isActive && getDiscordChannels(company._id, company.discord.guildId);
    
    setFormData({
      ...formData,
      questions: !settings ? '' : settings.icebreakers,
      scheduleTime: !settings ? '' : settings.scheduleTime,
      scheduleDay: !settings ? '' : settings.scheduleDay,
      interval: !settings ? '' : settings.interval,
      timezone: !settings ? '' : settings.timezone,
      emailIsActive: !settings ? false : settings.emailIsActive,
      localReportTime: !settings ? '' : '',
      localReportHour: !settings ? '' : '',
      localReportMinute: !settings ? '' : [],
      reportIsActive: !settings ? false : settings.reportIsActive,
      slackIsActive: !settings ? false : settings.slackIsActive,
      isAsync: !settings ? false : settings.isAsync,
      slackReportChannels: !settings ? false : settings.slackReportChannels,
      selectedOption: !slack || !settings ? '' : choosedUsers,
      selectedChannelOption: !slack || !settings ? '' : choosedChannels,
      selectedEmailUsers: !settings ? '' : choosedEmailUsers,
      selectedSlackUsers: !slack || !settings ? '' : choosedSlackUsers,
      lang: company === null || !company && !company.language ? 'en': company.language,
      discordIsActive: !settings || !settings.discord ? false : settings.discord.isActive,
      discordChannelOptions: !discord ? [] : discord.channels,
      selectedDiscordChannels: !discord || !settings || !settings.discord ? '' : settings.discord.channels,
    });
  }, [loading, getIcebreakerSettings, getCompanyProfiles,
    getUserCompany, changeIcebreakersLanguage, getDefaultIcebreakers,
    settings && settings.icebreakers && settings.icebreakers.length,
    getSlackUsers, getSlackChannels, slack && slack.channels && slack.channels.length,
    company && company.departments && company.departments.length,
    company && company.discord && company.discord.isActive,
  ]);
  // settings && settings.icebreakers && settings.icebreakers.length в useEffect надо, 
  // чтобы отслеживать сброс к default вопросам, НО это не отобразит изменение контента, если
  // количество вопросов будет такое же, как при дефолте
  // ToDo: Для отслеживания изменения языка, надо более правильное решение

  useEffect(() => {
    setFormData({
      ...formData,
      discordChannelOptions: !discord ? [] : discord.channels,
    });
  }, [JSON.stringify(discord)]);

  // Select hour & minute options
  const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  const hourOptions = hours.map(hour => {
        return (
          <option value={hour} key={hour}>
            {hour}
          </option>
        );
      });

  const minutes = ['00', '15', '30', '45'];
  const minuteOptions = minutes.map(minute => {
        return (
          <option value={minute} key={minute}>
            {minute}
          </option>
        );
      });

  
  const handleEmailUsers = selectedOption => {
    setFormData({ ...formData, selectedEmailUsers: selectedOption });
  };

  const handleSlackUsers = selectedOption => {
    setFormData({ ...formData, selectedSlackUsers: selectedOption });
  };

  const handleSlackUsersForm = e => {
    toggleSlackUsers(!slackUsersForm);
  };
  
  const handleChangeReport = selectedOption => {
    setFormData({ ...formData, selectedOption: selectedOption });
  };

  const handleChangeChannelReport = selectedChannelOption => {
    setFormData({ ...formData, selectedChannelOption: selectedChannelOption });
  };

  const handleReport = e => {
    toggleReportChannels(!reportChannels);
  };


  const onChangeSelect = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onChangeTimezone = (timezone, name) => {
    setFormData({ ...formData, [name]: timezone });
  };
  
  const onChangeDay = (e) => {
    let selected = e.target.selectedOptions[0];
    scheduleDay.day = selected.text;
    scheduleDay.index = e.target.value;

    setFormData({ ...formData, scheduleDay: scheduleDay });
  };

  const onSubmit = e => {
    e.preventDefault();
    editIcebreakSettings(formData, history);
  };

  const onChange = (e, index) => {
    questions[index] = e.target.value;

    setFormData({ ...formData, questions: questions });
  };

  const handleRemove = index => {
    questions.splice(index, 1);

    setFormData({ ...formData, questions: questions });
  };

  const handleAdd = e => {
    e.preventDefault();

    setFormData({ ...formData, questions: [...questions, ''] });
  };

  const handleTime = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const title = <FormattedMessage id="admin.dashboard.edit.icebreakers.header" defaultMessage="Edit Icebreakers Campaign" />;
  
  // Discord
  const handleDiscordUsersForm = e => {
    toggleDiscordUsers(!discordUsersForm);
  };

  const handleDiscordUsers = selectedOption => {
    setFormData({ ...formData, selectedDiscordChannels: selectedOption });
  };

  // Selects
  const handleCheckbox = (name, value) => {

    let title = 'slackIsActive';
    if (name === 'isAsync') { title = 'isAsync' }

    setFormData({ ...formData, [title]: value });
  };

  return (
    <Fragment>
      <div className="css-main bg-primary">
          <div className="wrapper">
            <div className="main_menu absolute"><Logo /></div>
            <UserNav />

            <Fragment>
              {!settings || loading ? (
                <Spinner />
              ) : (
                  <div className="main mxa pl-0 main-profile-admin">
                    <div className="standups-admin pt-3 mt-0-xs pt-1-xs">
                      <div className="section-title">
                        <h5>
                          {title}
                        </h5>
                        <Link
                          to="/settings"
                          className="btn-secondary btn-secondary-back"
                        >
                          <FormattedMessage id="go.back" defaultMessage="Go Back" />
                        </Link>
                      </div>

                      <div className="component edit-updates-weekly my-2 p-3 light-shadow">
                        <div className="form-full">
                          <form
                            className="form-app form-app-update"
                            onSubmit={e => onSubmit(e)}
                          >
                            <ScheduleItem 
                              formData={formData}
                              setFormData={setFormData}
                              scheduleTime={scheduleTime}
                              handleTime={handleTime}
                              timezone={timezone}
                              onChangeTimezone={onChangeTimezone}
                              interval={interval}
                              onChangeSelect={onChangeSelect}
                              scheduleDay={scheduleDay}
                              onChangeDay={onChangeDay}
                              emailIsActive={emailIsActive}
                              reportIsActive={reportIsActive}
                              localReportHour={localReportHour}
                              hourOptions={hourOptions}
                              localReportMinute={localReportMinute}
                              minuteOptions={minuteOptions}
                            />
                            <ParticipantItem 
                              choosedEmailUsers={choosedEmailUsers}
                              toggleEmailUsers={toggleEmailUsers}
                              emailUsersForm={emailUsersForm}
                              profiles={profiles}
                              handleEmailUsers={handleEmailUsers}
                              emailUsersOptions={emailUsersOptions}
                            />
                            {company && company.isSlack ? (
                              <SlackItem 
                                formData={formData}
                                setFormData={setFormData}
                                slackIsActive={slackIsActive}
                                isAsync={isAsync}
                                choosedSlackUsers={choosedSlackUsers}
                                handleSlackUsersForm={handleSlackUsersForm}
                                emailUsersForm={emailUsersForm}
                                slackUsersForm={slackUsersForm}
                                profiles={profiles}
                                handleSlackUsers={handleSlackUsers}
                                slackUserAndChannelOptions={slackUserAndChannelOptions}
                                slackReportChannels={slackReportChannels}
                                handleReport={handleReport}
                                reportChannels={reportChannels}
                                slack={slack}
                                choosedUsers={choosedUsers}
                                handleChangeReport={handleChangeReport}
                                options={options}
                                choosedChannels={choosedChannels}
                                handleChangeChannelReport={handleChangeChannelReport}
                                channelOptions={channelOptions}
                                isHideReports={true}
                                isNotMulti={true}
                                type={5}
                                handleCheckbox={handleCheckbox}
                              />
                            ) : null}

                            {company && company.discord && company.discord.isActive ? (
                            <Fragment>
                              <p className="sub-title focus-yellow focus my-2">
                                <FormattedMessage
                                  id="discord"
                                  defaultMessage="Discord"
                                />
                              </p>

                              <div className="form-main form-main-inline standups-time mt-2">
                                <p className="bold medium block question">
                                  <FormattedMessage
                                    id="admin.dashboard.edit.status"
                                    defaultMessage="Status"
                                  />
                                </p>

                                <div className="checkbox status pl-3 w-30">
                                  <div className="checkbox-item active-status">
                                    <input
                                      type="checkbox"
                                      id="status_discord"
                                      name="status_discord"
                                      checked={discordIsActive}
                                      value={discordIsActive}
                                      onChange={e => {
                                        setFormData({
                                          ...formData,
                                          discordIsActive: !discordIsActive
                                        });
                                      }}
                                    />
                                    <label htmlFor="status_discord">
                                      {discordIsActive ? (
                                        <FormattedMessage
                                          id="admin.dashboard.edit.discord.active"
                                          defaultMessage="Active"
                                        />
                                      ) : (
                                        <FormattedMessage
                                          id="admin.dashboard.edit.discord.disable"
                                          defaultMessage="Disabled"
                                        />
                                      )}
                                    </label>
                                  </div>
                                </div>
                              </div>

                              
                              <div className="form-main form-main-inline mt-2">
                                <p className="bold medium block question w-30 w-100-xs">
                                  <FormattedMessage
                                    id="channel"
                                    defaultMessage="Channel"
                                  />
                                </p>

                                 <div className="w-unset pl-3 w-30 pt-">
                                  {selectedDiscordChannels &&
                                  selectedDiscordChannels.length > 0 && (
                                    selectedDiscordChannels.map(item => (
                                      <p
                                        className="small w-100 inline"
                                        key={item.value}
                                      >
                                        {'#'}
                                        {item.label}
                                      </p>
                                    ))
                                  )}
                                </div>

                                <span
                                  className="btn-secondary btn-secondary-back"
                                  onClick={e => handleDiscordUsersForm()}
                                >
                                  {discordUsersForm ? (
                                    <FormattedMessage
                                      id="admin.dashboard.edit.channels.btn-hide"
                                      defaultMessage="Hide channels"
                                    />
                                  ) : (
                                        <FormattedMessage
                                          id="admin.dashboard.edit.channels.btn"
                                          defaultMessage="Choose channels"
                                        />
                                    )}
                                </span>
                              </div>

                              {discordUsersForm && (
                                <Fragment>
                                  <p className="medium block">
                                    <FormattedMessage
                                      id="admin.dashboard.edit.users.discord.icebreakers"
                                      defaultMessage="Choose a public channel for icebreakers. The Focus bot publishes icebreakers in the public channel. <b>NOTE:</b> If you don't see channels in the dropdown menu, please click on the button 'Refresh channels' and choose channel for icebreakers. Also, <b>please add the bot</b> to this channel."
                                      values={{ b: (...chunks) => <b>{chunks}</b> }}
                                    />
                                  </p>
                                  <div className='mb-3'>
                                    <div className="flex align-center pb-1">
                                      <p className="bold medium question">
                                        <FormattedMessage
                                          id="channels"
                                          defaultMessage="Channels"
                                        />
                                      </p>
                                      <span
                                        className="btn-secondary btn-secondary-back mla"
                                        onClick={e => getDiscordChannels(company._id, company.discord.guildId)}
                                      >
                                        <FormattedMessage
                                          id="refresh.channels"
                                          defaultMessage="Refresh channels"
                                        />
                                      </span>
                                    </div>
                                    <FormattedMessage
                                      id="admin.dashboard.edit.users.slack.userPlaceholder"
                                      defaultMessage="Add users"
                                    >
                                      {msg => (
                                        <Select
                                          key={profiles && `${profiles._id}-D`}
                                          defaultValue={selectedDiscordChannels}
                                          onChange={selectedOption =>
                                            handleDiscordUsers(selectedOption)
                                          }
                                          options={discordChannelOptions}
                                          isMulti
                                          isSearchable
                                          placeholder={msg}
                                        />
                                      )}
                                      </FormattedMessage>
                                    </div>
                                </Fragment>
                              )}
                            </Fragment>
                          ) : null}


                            <QuestionItem 
                              formData={formData}
                              setFormData={setFormData}
                              settings={settings}
                              questions={questions}
                              handleRemove={handleRemove}
                              onChange={onChange}
                              handleAdd={handleAdd}
                              changeIcebreakersLanguage={changeIcebreakersLanguage}
                              getDefaultIcebreakers={getDefaultIcebreakers}
                              lang={lang}
                            />

                            <button type="submit" className="btn btn-form">
                              <FormattedMessage id="admin.dashboard.edit.submit" defaultMessage="Submit" />
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

EditIcebreakers.propTypes = {
  getIcebreakerSettings: PropTypes.func.isRequired,
  editIcebreakSettings: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getSlackUsers: PropTypes.func,
  getSlackChannels: PropTypes.func,
  getCompanyProfiles: PropTypes.func.isRequired,
  changeIcebreakersLanguage: PropTypes.func.isRequired,
  getDefaultIcebreakers: PropTypes.func.isRequired,
  getDiscordChannels: PropTypes.func.isRequired,
  icebreaker: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  slack: PropTypes.object,
  discord: PropTypes.object,
};

const mapStateToProps = state => ({
  company: state.company,
  icebreaker: state.icebreaker,
  slack: state.slack,
  discord: state.discord,
  profile: state.profile,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { editIcebreakSettings,
    getIcebreakerSettings,
    getUserCompany,
    getSlackUsers,
    getSlackChannels,
    getCompanyProfiles,
    changeIcebreakersLanguage,
    getDefaultIcebreakers,
    getDiscordChannels,
  }
)(withRouter(EditIcebreakers));