import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addComment } from '../../../actions/okr';
import InputNew from '../../elems/InputNew';

const CommentForm = ({
  okrID,
  addComment,
  user,
  avatar
}) => {
  const [text, setText] = useState('');

  return (
    <form
      className="form-app form-app-comments"
      onSubmit={e => {
        e.preventDefault();
        addComment(okrID, { text });
        setText('');
      }}
    >
      <div className="form-comment">
        <InputNew 
          setText={setText}
          text={text}
        />
      </div>
    </form>
  );
};

CommentForm.propTypes = {
  addComment: PropTypes.func.isRequired
};

export default connect(
  null,
  { addComment }
)(CommentForm);
