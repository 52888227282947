import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MainMenu from '../layout/Sidebar';
import Menu from '../layout/Menu';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import Widgets from '../widgets/Widgets';
import TeamMenu from './TeamMenu';
import RecognitionItem from '../recognitions/noState/RecognitionItem';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/1.jpg';
import {
  getTeamRecognitionsByID,
  getGivedTeamRecognitionsByID,
  clearKudos
} from '../../actions/recognition';
import { getTeamOkrsById } from '../../actions/okr';
import { getCompanyProfiles } from '../../actions/profile';
import { getUserCompany, getTeamById } from '../../actions/company';
import { FormattedMessage } from 'react-intl';
import { kudosCopy } from '../../utils/wording/kudos';

const TeamKudos = ({
  clearKudos,
  getTeamById,
  match,
  getGivedTeamRecognitionsByID,
  getTeamRecognitionsByID,
  getTeamOkrsById,
  getCompanyProfiles,
  getUserCompany,
  company: { company, team },
  okr: { okrs },
  auth: { user, sidebar },
  profile: { profiles },
  recognition: { recognitions, givedRecognitions, loading }
}) => {
  useEffect(() => {
    getUserCompany();
    getCompanyProfiles();
    getGivedTeamRecognitionsByID(match.params.id);
    getTeamRecognitionsByID(match.params.id);
    getTeamById(match.params.id);
    getTeamOkrsById(match.params.id);

    // Clear state
    return () => {
      clearKudos();
    }
  }, [getUserCompany, getCompanyProfiles, getTeamRecognitionsByID, getGivedTeamRecognitionsByID, getTeamOkrsById, getTeamById]);
  
  const [received, handleReceived] = useState(true);
  const [given, handleGiven] = useState(true);

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {user && !loading && (
          <MainMenu activeIndex={7} />
        )}

        <UserNav />
        
          <Fragment>
            {loading || !recognitions || !user || !okrs || !company || !team ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className={`${sidebar === 1 ? 'main main-dashboard ' : 'short_menu__ml__okrs ma-w90 pt-2'} mt-3`}>
                     <h5>
                      <FormattedMessage id="company.header.okrs" defaultMessage={`${team.name} Kudos`} />
                    </h5>

                  <TeamMenu teamId={team._id} companyIndex={4} />
                  <div className="kudos">
                    <div className="mb-4">
                        <Widgets
                          leftColor={'green'}
                          rightColor={'red'}
                          receivedRecognitions={recognitions}
                          givedRecognitions={givedRecognitions}
                          received={received}
                          handleReceived={handleReceived}
                          given={given}
                          handleGiven={handleGiven}
                        />
                    </div>

                      <div className="text-right pb-1">
                        <Link to="/kudos/new" className="btn btn-light">
                          <FormattedMessage
                            id="user.recognition.createBtn"
                            defaultMessage="+ Create Recognition"
                          />
                        </Link>
                      </div>

                      {company.plan !== 1 ? (
                        <Fragment>
                          {recognitions.length === 0 && givedRecognitions ? (
                            <div className="mt-5">
                              <NoContent 
                                message={kudosCopy.noRecognitionMessage}
                                picture={updateImg}
                                alt={'No kudos'}
                              />
                            </div>
                          ) : (
                            <Fragment>
                              {recognitions.map(recognition => (
                                <RecognitionItem
                                  key={recognition._id}
                                  recognition={recognition}
                                />
                              ))}
                            </Fragment>
                          )}
                        </Fragment>
                      ) : (
                        <NoContent 
                          message={kudosCopy.updatePlanMsg}
                          picture={updateImg}
                          alt={'Update plan'}
                          admin={user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0}
                        />
                      )}
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

TeamKudos.propTypes = {
  clearKudos: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getTeamById: PropTypes.func.isRequired,
  getTeamRecognitionsByID: PropTypes.func.isRequired,
  getGivedTeamRecognitionsByID: PropTypes.func.isRequired,
  getTeamOkrsById: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  recognition: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  recognition: state.recognition,
  okr: state.okr
});

export default connect(
  mapStateToProps,
  { getUserCompany, getCompanyProfiles, getTeamRecognitionsByID, getGivedTeamRecognitionsByID, getTeamOkrsById, getTeamById, clearKudos }
)(TeamKudos);
