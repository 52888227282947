import {
  GET_DATA,
  REACH_ERROR,
  ADD_REACH_USER,
  EDIT_REACH_USER,
  CLEAR_REACH,
  DELETE_REACH_USER,
  TOGGLE_REACH_STATUS,
} from '../actions/types';

const initialState = {
  data: [],
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DATA:
      return {
        ...state,
        data: payload,
        loading: false
      };
    case ADD_REACH_USER:
      return {
        ...state,
        data: [payload, ...state.data],
        loading: false
      };
    case EDIT_REACH_USER:
      return {
        ...state,
        data: state.data.map(item =>
          item._id === payload.id ? payload.data : item
        ),
        loading: false
      }
    case TOGGLE_REACH_STATUS:
      return {
        ...state,
        data: state.data.map(item =>
          item._id.toString() === payload.id ? payload.data : item
        ),
        loading: false
      }
    case DELETE_REACH_USER:
      return {
        ...state,
        data: state.data.filter(item => item._id !== payload),
        loading: false
      };
    case REACH_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case CLEAR_REACH:
      return {
        ...state,
        data: [],
        loading: true
      };
    default:
      return state;
  }
}
