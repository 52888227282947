import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MoodRow from './MoodRow';

const MoodMain = ({
  moodAnswers,
  uniqueSurvey,
  moodCopy,
  showAllComments,
  allComments
}) => {

  return (
    <Fragment>
      <div className="component mt-3">
        <div className="content p-3">

          <div className="range flex lh-20">
            <div className="w-80px bg-red mla">
              <p className="white text-center small">{moodCopy.range.bad}</p>
            </div>
            <div className="w-80px bg-orange">
              <p className="white text-center small">{moodCopy.range.hard}</p>
            </div>
            <div className="w-80px bg__grey1">
              <p className="text-center small">{moodCopy.range.neutral}</p>
            </div>
            <div className="w-80px bg-blue">
              <p className="white text-center small">{moodCopy.range.good}</p>
            </div>
            <div className="w-80px bg-green">
              <p className="white text-center small">{moodCopy.range.excellent}</p>
            </div>
        </div>

        <div className="mood__answers mt-2">

          <div className="mood__answers-title flex">
            <p className="medium bold w-200px">{moodCopy.table.date}</p>
            <p className="medium bold">{moodCopy.table.moods}</p>
          </div>
          {uniqueSurvey.map(
            (item, index) => (
              <Fragment key={`fragment-mood-${index}`}>
                  <div className="mood__answers-content">
                    <div className="mood__item flex align-center h-60">
                      <p className="medium w-200px">{item.day}</p>
                    
                      <MoodRow
                          items={moodAnswers.filter(m => m.day === item.day)} 
                          showAlComments={showAllComments}
                          allComments={allComments}
                        />
                    </div>
                  </div>
              </Fragment>
            )
          )}

          </div>
        </div>
      </div>
    </Fragment>
  );
};

MoodRow.propTypes = {
  items: PropTypes.array.isRequired,
};

export default MoodMain;
