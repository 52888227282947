import {
  ADD_CUSTOM,
  DELETE_CUSTOM,
  GET_CUSTOM_SETTINGS,
  CUSTOMS_ERROR,
  CLEAR_CUSTOMS,
  SWITCH_CUSTOM_STATUS,
  GET_ALL_CUSTOMS,
  CUSTOMS_SETTINGS_ERROR,
  CLEAR_CUSTOMS_SETTINGS,
  GET_STANDUP_SETTINGS_IN_CUSTOM,
  GET_PLANS_SETTINGS_IN_CUSTOM,
  GET_ALL_ACTIVE_SETTINGS,
  PREPARE_FIRST_CUSTOM,
  GET_ONBOARDING_CUSTOM,
  REMOVE_ONBOARDING_CUSTOM,
  HIDE_CHECKIN_MODAL,
  CHECKIN_MODAL_ERROR,
} from '../actions/types';

const initialState = {
  campaigns: [],
  campaign: null,
  settings: null,
  loading: true,
  allSettings: [],
  // modalBox: true,
  modalBox: localStorage.getItem('checkin_box'),
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  
  // console.log('==== type', type)
  // console.log('==== payload', payload)
  // console.log('====  localStorage.getItem()', localStorage.getItem('custom_checkin'))

  switch (type) {
    case GET_CUSTOM_SETTINGS:
    case GET_PLANS_SETTINGS_IN_CUSTOM:
    case GET_STANDUP_SETTINGS_IN_CUSTOM:
      return {
        ...state,
        settings: payload,
        loading: false
      };
    case GET_ALL_ACTIVE_SETTINGS:
      return {
        ...state,
        allSettings: payload,
        loading: false
      };
    case PREPARE_FIRST_CUSTOM:
      localStorage.setItem('custom_checkin', payload);
      return {
        ...state,
        campaign: payload,
        // campaign: { ...campaign, payload },
        loading: false
      };
    case HIDE_CHECKIN_MODAL:
      localStorage.setItem('checkin_box', payload);
      return {
        ...state,
        modalBox: payload
      };
    case CHECKIN_MODAL_ERROR:
      return {
        ...state,
        error: payload,
      };
    case GET_ONBOARDING_CUSTOM:
      return {
        ...state,
        campaign: localStorage.getItem('custom_checkin'), 
        loading: false
      };
    case REMOVE_ONBOARDING_CUSTOM:
      localStorage.removeItem('custom_checkin');
      return {
        ...state
      };
    case ADD_CUSTOM:
      return {
        ...state,
        campaigns: [payload, ...state.campaigns],
        loading: false
      };
    case GET_ALL_CUSTOMS:
    // case GET_STANDUP_SETTINGS_IN_CUSTOM:
      return {
        ...state,
        campaigns: payload,
        loading: false
      };
    case SWITCH_CUSTOM_STATUS:
      return {
        ...state,
        campaigns: state.campaigns.map(item =>
          item._id === payload.id
            ? {
                ...item,
                active: payload.active
              }
            : item
        ),
        loading: false
      };
    case DELETE_CUSTOM:
      return {
        ...state,
        campaigns: state.campaigns.filter(custom => custom._id !== payload),
        loading: false
      };
    case CUSTOMS_SETTINGS_ERROR:
    case CUSTOMS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case CLEAR_CUSTOMS:
      return {
        ...state,
        campaigns: [],
        settings: null,
        loading: true
      };
    case CLEAR_CUSTOMS_SETTINGS:
      return {
        ...state,
        settings: null,
      };
    default:
      return state;
  }
}
