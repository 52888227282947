import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { FormattedMessage } from 'react-intl';

const DetailsUpdateItem = ({
  item: {
    text,
    user,
    userFirstName,
    userLastName,
    progress,
    status,
    date
  },
  okrTitle
}) => {
  let btnClass;
  let label = '';

  if (status === 1) {
    btnClass = 'green';
    label = 'On Track';
  } else if (status === 2) {
    btnClass = 'yellow';
    label = 'Behind';
  } else if (status === 3) {
    btnClass = 'red';
    label = 'At Risk';
  }

  // console.log('====== title', title)

  let userLabel = `${userFirstName} ${userLastName}`;
  let updated = <FormattedMessage id="updated" defaultMessage={`updated`} />;

  return (
    <Fragment>
      <Fragment>
        <div className="flex align-center mt-2">
          <div className="comment-head">
            <div className="flex align-center">
              <span className="fs-9 grey mb-xs">
                <Moment format="ddd LLL">{date}</Moment>
              </span>
            </div>
            <p className="fs-12 lh-20">
              <Link to={`/user/${user}`} className="fs-12 lh-20 blue cursor bold">
                {userLabel}
              </Link>{' '}
              {updated} OKR {okrTitle}: <strong>{Math.floor(progress)} / 100%</strong>
            </p>
            {text && <p className="fs-12 lh-20">Comment: {text}</p>}
          </div>
        </div>
      </Fragment>
    </Fragment>
  );
};

DetailsUpdateItem.propTypes = {
  item: PropTypes.object.isRequired
};

export default DetailsUpdateItem;
