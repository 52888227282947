import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProfile } from '../../actions/profile';
import UserNav from '../layout/UserNav';
import Menu from '../layout/Menu';
import Spinner from '../layout/Spinner';
import { FormattedMessage } from 'react-intl';

const Profile = ({
  getCurrentProfile,
  profile: { profile, loading },
  auth: { user }
}) => {
  useEffect(() => {
    getCurrentProfile();
  }, [loading, getCurrentProfile]);

  console.log('====== profile in Profile', profile);

  return (
    <Fragment>
      <div className="css-main">
        <div className="wrapper">
          {user && user.department ? (
            <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user.isAdmin && true} />
          ) : (
            <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} />
          )}
          <UserNav />
          <Fragment>
            {profile === null ? (
              <Spinner />
            ) : (
              <div className="main main-dashboard main-profile">
                <Fragment>
                <div className="mt-5">
                  <div className="section-title">
                    <h5> 
                    <FormattedMessage id="myprofile.title" defaultMessage="Profile" />
                    </h5>
                    <Link
                      to="/"
                      className="btn-secondary btn-secondary-back"
                    >
                      <FormattedMessage id="go.back" defaultMessage="Go Back" />
                    </Link>
                    </div>
                  </div>
                  <div className="profile bg-profile shadow">
                    <div className="profile-left p-4 w-50 ml-3 ml-0-xs w-100-xs">
                        <img
                          src={profile.avatar}
                          alt="user-avatar"
                          className="round-img"
                          width="140px"
                          height="140px"
                        />
                      
                      <div className="profile-name">
                        <h5>
                          {profile.firstName} {profile.lastName}
                        </h5>
                        <p className="large pl-0-xs">
                          {profile.jobTitle ? (
                            profile.jobTitle 
                          ) : (
                            <FormattedMessage
                              id="myprofile.position"
                              defaultMessage="Enter your job positon"
                            />
                          )
                          }
                        </p>
                      </div>
                    </div>

                    <div className="profile-right p-4 w-50 w-100-xs">
                      <div className="profile-team">
                        <p className="large bold">
                          <FormattedMessage
                            id="myprofile.team"
                            defaultMessage="Team"
                          />
                        </p>
                        <p className="medium">
                          {profile.departmentName
                            ? profile.departmentName
                            : '-'}
                        </p>
                      </div>

                      <div className="profile-contacts my-3">
                        <p className="large bold">
                          <FormattedMessage
                            id="myprofile.contacts"
                            defaultMessage="Contacts"
                          />
                        </p>
                        <p className="medium">{profile.email}</p>
                        <p className="medium">
                          {profile.phone ? (
                            profile.phone
                          ) : (
                            <FormattedMessage
                              id="myprofile.phone"
                              defaultMessage="Phone: -"
                            />
                          )
                          }
                        </p>
                      </div>
                      <Link
                        to="/edit-profile"
                        className="btn-secondary btn-secondary-comment ml-0-xs"
                      >
                        <FormattedMessage
                          id="myprofile.edit"
                          defaultMessage="Edit Profile"
                        />
                      </Link>
                    </div>
                  </div>
                </Fragment>
              </div>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

Profile.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getCurrentProfile }
)(Profile);
