import {
  GET_USER_TODOS,
  GET_ALL_USER_TODOS,
  GET_USER_TODO,
  GET_TEAM_TODOS,
  GET_TODO,
  GET_TODO_FOR_EDIT,
  GET_COMPANY_TODOS_FOR_EDIT,
  TODO_ERROR,
  USER_TODO_ERROR,
  USER_TODOS_ERROR,
  TEAM_TODOS_ERROR,
  TODOS_ERROR,
  ADD_TODO,
  EDIT_TODO,
  UPDATE_TODO,
  UPDATE_TODO_ERROR,
  COMPLETE_TODO,
  COMPLETE_TODO_ERROR,
  GET_COMPANY_TODOS,
  COMPANY_TODOS_ERROR,
  CLEAR_TODOS,
  DELETE_TODO,
  GET_TODOS_WITH_OKRS,
  TODOS_WITH_OKRS_ERROR,
  CHANGE_TODO_FILTERS,
} from "../actions/types";

const initialState = {
  tasks: [],
  task: null,
  loading: true,
  filters: localStorage && localStorage.task_filter ? JSON.parse(localStorage.task_filter) : {team:null,type:null},
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_TODOS:
    case GET_TEAM_TODOS:
    case GET_COMPANY_TODOS:
    case GET_ALL_USER_TODOS:
    case GET_TODOS_WITH_OKRS:
      return {
        ...state,
        tasks: payload,
        loading: false
      };
    case ADD_TODO:
      return {
        ...state,
        tasks: [payload, ...state.tasks]
      };
    case EDIT_TODO:
    case UPDATE_TODO:
    case COMPLETE_TODO:
      return {
        ...state,
        tasks: state.tasks.map(item =>
          item._id === payload.id ? payload.data : item
        ),
      }
    case DELETE_TODO:
      return {
        ...state,
        tasks: state.tasks.filter(item => item._id !== payload),
        loading: false
      };
    case TODOS_ERROR:
    case USER_TODOS_ERROR:
    case TEAM_TODOS_ERROR:
    case COMPANY_TODOS_ERROR:
    case COMPLETE_TODO_ERROR:
    case UPDATE_TODO_ERROR:
    case TODOS_WITH_OKRS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case CLEAR_TODOS:
      return {
        ...state,
        tasks: [],
        task: null,
        loading: true,
      };
    case CHANGE_TODO_FILTERS:
      localStorage.setItem('task_filter', JSON.stringify({ team: payload.team, type: payload.type }));
      return {
        ...state,
        filters:{team:payload.team,type:payload.type}
    }
    default:
      return state;
  }
}
