import axios from 'axios';
import { setAlert } from './alert';

import {
  GET_DATA,
  REACH_ERROR,
  ADD_REACH_USER,
  EDIT_REACH_USER,
  CLEAR_REACH,
  DELETE_REACH_USER,
  TOGGLE_REACH_STATUS,
} from './types';

// Get all current outreach users
export const getReachData = () => async dispatch => {
  try {
    const res = await axios.get('/api/outreach/users');

    dispatch({
      type: GET_DATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: REACH_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Add new prospect
export const addReachUser = ({ 
  fullName,
  email,
  company,
  position,
  template,
  comment,
  articleName,
  articleUrl,
  website,
  scheduleTime,
  source,
  jobPostings,
  vcRound,
  vcTime, 
}) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({
      fullName,
      email,
      company,
      position,
      template,
      comment,
      articleName,
      articleUrl,
      website,
      scheduleTime,
      source,
      jobPostings,
      vcRound,
      vcTime
    });

    const res = await axios.post(`/api/outreach/user`, body, config);
   
    dispatch({
      type: ADD_REACH_USER,
      payload: res.data
    });

    dispatch(setAlert('Contact was added', 'success', 1000));

    // Callback for updating in stateData.users in useState to render it
    const data = res.data;
    return data;
  } catch (err) {
    
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: REACH_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Edit user
export const editReachUser = (
  id,
  formData
) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const res = await axios.put(
      `/api/outreach/user/edit/${id}`,
      formData,
      config
    );

    dispatch({
      type: EDIT_REACH_USER,
      payload: { id, data: res.data }
    });
  } catch (err) {
    dispatch({
      type: REACH_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Switch ON/OFF status
export const toggleReachStatus = (id) => async dispatch => {
  try {
    const res = await axios.put(`/api/outreach/user/status/${id}`);

    dispatch({
      type: TOGGLE_REACH_STATUS,
      payload: { id, data: res.data }
    });
  } catch (err) {
    dispatch({
      type: REACH_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete user
export const deleteReachUser = (id) => async dispatch => {
  try {
    await axios.delete(`/api/outreach/user/delete/${id}`);

    dispatch({
      type: DELETE_REACH_USER,
      payload: { id }
    });

  } catch (err) {
    dispatch({
      type: REACH_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Clear state
export const clearReach = () => async dispatch => {
  try {
    
    dispatch({
      type: CLEAR_REACH
    });
  } catch (err) {
    dispatch({
      type: REACH_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};