import React from "react";
import PropTypes from 'prop-types';

const DashboardIcon = ({ activeIndex, shortMenu }) => {
  return (
    <svg className={`w-20px ${!shortMenu ? '' : 'ml-03'}`} id="dashboard" xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
      <path id="Path_25" fill={`${activeIndex === 13 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 25" d="M-368.123,219.712c2.435,0,4.871,0,7.306,0a2.767,2.767,0,0,1,1.906.64,2.123,2.123,0,0,1,.757,1.444c.012.139.02.279.02.418q0,5.492,0,10.983a2.383,2.383,0,0,1-.178,1.046,2.55,2.55,0,0,1-2.464,1.463c-.445.01-.89,0-1.335,0h-13.235a2.921,2.921,0,0,1-1.963-.611,2.146,2.146,0,0,1-.8-1.463,5.284,5.284,0,0,1-.021-.533q0-5.4,0-10.792a3.483,3.483,0,0,1,.1-.905,2.4,2.4,0,0,1,2.226-1.678c.166-.011.334-.011.5-.011Zm-.64,5.732c-.634-.077-7.824-.05-8.144.03-.006.117-.019.241-.019.366q0,3.661,0,7.321a3.144,3.144,0,0,0,.044.531.92.92,0,0,0,.559.718,2.151,2.151,0,0,0,.8.172c2.143.014,4.286.008,6.429.006a3.3,3.3,0,0,0,.332-.035Zm1.273-.014c-.1.913-.054,8.866.052,9.143.091.005.187.015.283.015,2.129,0,4.258,0,6.387,0,.946,0,1.4-.421,1.427-1.289,0-.051,0-.1,0-.153V225.43Zm-9.408-1.174c.732.078,17.21.047,17.545-.036,0-.764.032-1.536-.009-2.307-.037-.7-.512-1.065-1.285-1.083-.084,0-.167,0-.251,0h-14.479a3.955,3.955,0,0,0-.5.017,1.111,1.111,0,0,0-.969.738,1.725,1.725,0,0,0-.077.565c-.009.584-.005,1.168,0,1.753C-376.925,224.013-376.909,224.125-376.9,224.256Z" transform="translate(378.133 -219.709)"/>
    </svg>
  );
};

DashboardIcon.propTypes = {
  activeIndex: PropTypes.number
};

export default DashboardIcon;