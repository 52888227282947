import React from 'react';
import { FormattedMessage } from 'react-intl';

export const planCopy = {
  msgOnboarding: (
    <FormattedMessage
      id="plan.onboarding.company.notification.msg1"
      defaultMessage="You're using the sample data below. Run your first Weekly Plan and you'll see your data there (the sample data will be removed). Click the button below to answer your first Weekly Plan."
    />
  ),
  ctaOnboarding: (
    <FormattedMessage
      id="plan.onboarding.company.notification.cta"
      defaultMessage="Share weekly plan"
    />
  ),
  updatePlanMsg: {
    title: (
      <FormattedMessage
        id="company.updateplan.title"
        defaultMessage="Update Plan"
      />
    ),
    msg: (
      <FormattedMessage
        id="company.plans.planplan.msg"
        defaultMessage={`Weekly Plans aren't available on the Free plan. Please upgrade the plan to unlock this feature.`}
      />
    )
  }, 
  planLive1: (
    <FormattedMessage
        id="plan.cta.complete1"
        defaultMessage="Weekly Plan is going on."
      />
  ),
  planLive2: (
    <FormattedMessage
        id="plan.cta.complete2"
        defaultMessage="Click the button below to share your weekly plan."
      />
  ),
  planLiveCta: (
    <FormattedMessage
        id="plan.cta.completeCta"
        defaultMessage="Create a Plan"
      />
  ),
  planLiveNew: (
    <FormattedMessage
        id="plan.cta.complete.new"
        defaultMessage="Weekly Plans are Going on ! Show your team what you are going to work."
      />
  ),
  planLiveCtaNew: (
    <FormattedMessage
        id="plan.cta.completeCta.new"
        defaultMessage="Share a Weekly Plan"
      />
  )
}

export default {
  planCopy
}