import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import OkrMoreIcons from "../elements/OkrMoreIcons";
import OkrProgress from "../elements/OkrProgress";
import Moment from "react-moment";
import { FormattedMessage } from "react-intl";

const TreeTitle = ({
  label,
  color,
  _id,
  handleArchive,
  handleDelete,
  isArchive,
  archive,
  user,
  owner,
  creator,
}) => {
  
  // type - 1 => Company
  // type - 2 => Team
  // type - 3 => User

  return (
    <Fragment>
      <div className="flex space-between align-center mb-xs">
        <p className={`sub-title focus-${color} focus mb-0`}>
          {label}
        </p>
        <OkrMoreIcons
          _id={_id}
          handleArchive={handleArchive}
          handleDelete={handleDelete}
          isArchive={isArchive}
          archive={archive}
          user={user}
          owner={owner}
          creator={creator}
          isTree={true}
        />
      </div>
    </Fragment>
  );
};

TreeTitle.propTypes = {
  color: PropTypes.string.isRequired,
};

export default TreeTitle;
