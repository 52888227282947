import React, { Fragment} from "react";
import ChartLineOkrDetails from '../../utils/ChartLineOkrDetails';

const DetailsChart = ({ metrics, }) => {
  return (
    <Fragment>
       <div className="chart mb-5">
        <ChartLineOkrDetails 
          items={metrics}  
        />
        </div>
    </Fragment>
  );
};
export default DetailsChart;

