import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import QuestionItemAnswer from "./UniQuestionItemAnswer";
import Focus from '../elems/Focus';

const UniQuestionItemNew = ({ question, answer, index, searchKeyword }) => {

  return (
    <Fragment>
      {question.questionType && (
        question.questionType.value !== 'okr_select' ? (
        <div className="">
          <p className="medium fw-600">{question.text}</p>
          <div className="card-answer">
            <ul className="answer-item mt-1">
                {answer.text.map((text, i) => (
                  <QuestionItemAnswer
                    key={`${text._id}-${i}`}
                    index={index}
                    text={text}
                    searchKeyword={searchKeyword}
                    question={question}
                  />
                ))}
            </ul>
          </div>
        </div>
      ) : (
        <div className="brd br-4 brd-grey__light bg__gray p-2">
          <p className="medium fw-600">{question.text}</p>
          <div className="card-answer">
            <ul className="answer-item text-left">
                {answer.okr_text && answer.okr_text.objective ? (
                <Focus focus={answer.okr_text} />
                ) : (
                  answer.okr_text && answer.okr_text.text && !answer.okr_text.objective ? (
                    <p className="fs-14 text-left">{answer.okr_text.text}</p>
                  ) : null
                )
              }
            </ul>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

UniQuestionItemNew.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.object.isRequired,
  avatar: PropTypes.string.isRequired
};

export default UniQuestionItemNew;
