import React from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const CompanyMenu = ({ companyIndex, type, teamId }) => {

  return (
    <div className="sub-menu__page mt-2 mb-3">
      <Link
        to={'/company/checkins'}
        className={`medium sub-menu__item inline-block mr-2 cursor ${companyIndex === 1 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="company.menu.checkins" defaultMessage="Check-ins" />
      </Link>
      <Link
        to={'/company/updates'}
        className={`medium sub-menu__item inline-block mr-2 cursor ${companyIndex === 2 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="company.menu.updates" defaultMessage="Weekly Updates" />
      </Link>
      
      <Link
        to={'/company/objectives'}
        className={`medium sub-menu__item inline-block mr-2 cursor ${companyIndex === 3 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="company.menu.objectives" defaultMessage="Objectives" />
      </Link>

      <Link
        to={'/company/kudos'}
        className={`medium sub-menu__item inline-block mr-2 cursor ${companyIndex === 4 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="company.menu.kudos" defaultMessage="Kudos" />
      </Link>
    </div>
  );
};

CompanyMenu.propTypes = {
  companyIndex: PropTypes.number.isRequired
};

export default CompanyMenu;
