import React, { useState, Fragment, useEffect, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import Menu from '../layout/Menu';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import { getCompanyAnswers, updateLike, clearStandups } from '../../actions/standupAnswer';
import { getCompanyProfiles } from '../../actions/profile';
import { getUserCompany } from '../../actions/company';
import { getAllCustoms } from '../../actions/custom';
import CheckinItem from './CheckinItem';
import CheckinMenu from './CheckinMenu';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/no_content.svg';
import Notification from '../utils/Notification';
import SubMenu from '../elems/SubMenu';
import { onboardingStandups } from '../../utils/onboarding/standups';
import { checkinCopy } from '../../utils/wording/checkins';
import { getStandups } from '../../actions/standup';
import { menuTitle, menuItems, getCheckinsMenu } from '../../utils/menu/checkins';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import useDateFilter from '../utils/useDateFilter';


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px'
  }),
  input: () => ({
    backgroundColor: '#fcfcfd',
    fontSize: '14px'
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: '#ffffff',
    padding: '0px 8px',
    lineHeight: '20px',
    height: 36,
    minHeight: 36
  }),
  placeholder: (styles) => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
};

const customStylesCampaign = {
  option: (provided, state, isSelected,  isDisabled, isFocused,) => ({
    ...provided,
    fontSize: '14px',
    backgroundColor: state.isSelected ? "#404ff5" : "white",
  }),
  input: () => ({
    fontSize: '18px',
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
    padding: '0px 8px',
    lineHeight: '20px',
    minHeight: 36,
    borderColor: 'transparent',
  }),
  placeholder: (styles) => ({ ...styles, fontSize: '14px' }),
  singleValue: () => ({
    fontSize: '22px',
    fontWeight: '600',
  }),
  dropdownIndicator: base => ({
    ...base,
    color: "#000000" 
  })
};

const Checkin = ({
  clearStandups,
  getUserCompany,
  getCompanyProfiles,
  auth: { user },
  company: { company },
  standupAnswer: { standupAnswers, standupAnswer, loading },
  getCompanyAnswers,
  profile: { profiles },
  getStandups,
  standup: { standups },
  getAllCustoms,
  custom: { campaigns },
  locale,
  match,
  history
}) => {
  const [formData, setFormData] = useState({
    team: '',
    checkins: [],
    notes: ''
  });

  const [search, setSearch] = useState('');
  const [results, setResults] = useState({
    searchResults: false,
    searchKeyword: ''
  });

  const [menuData, setMenu] = useState({
    menu: {},
    menuIndex: []
  });

  let { menu, menuIndex } = menuData;
  let { team, checkins, notes } = formData;
  let { searchResults, searchKeyword } = results;

  let teamOptions = [];
  let mentions = [];

  // Перебор profiles & departments и приведение данных к формату Селекта
  {
    profiles &&
      profiles.forEach((profile, index) => {
        let mention = {
          id: profile._id,
          display: `${profile.firstName} ${profile.lastName}`
        };
        mentions.push(mention);

        if (user && profile._id === user._id) return;

        let label =
          (profile && profile.departmentName === null) || (profile && profile.department === undefined)
            ? `${profile.firstName} ${profile.lastName}`
            : `${profile.firstName} ${profile.lastName} - [${profile.departmentName}]`;

        let coworker = {
          value: profile._id,
          label,
          type: 3
        };

        teamOptions.push(coworker);
      });
  }

  {
    company &&
      company.departments.length > 0 &&
      company.departments.forEach((department, index) => {
        if (user && user.department === null) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };

          teamOptions.unshift(team);
        } else if (
          user &&
          user.department !== null &&
          user.department !== undefined &&
          department._id !== user.department
        ) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };

          teamOptions.unshift(team);
        }

        if (user && index === company.departments.length - 1) {
          // You is third in list
          let person = {
            value: user._id,
            label:
              (user && user.departmentName === null) || (user && user.department === undefined)
                ? `${user.firstName} ${user.lastName}`
                : `${user.firstName} ${user.lastName} - [${user.departmentName}]`,
            type: 3
          };

          teamOptions.unshift(person);

          // Second option is the team if exists
          if (user.department !== null && user.department !== undefined) {
            let team = { value: user.department, label: `${user.departmentName}`, type: 2 };
            teamOptions.unshift(team);
          }

          // Sort users alphabetically by first name
          teamOptions.sort((a, b) => a.label.localeCompare(b.label));

          // Third option is the company
          let name = { value: company._id, label: `${company.name}`, type: 1 };
          teamOptions.unshift(name);
        }
      });
  }

  if (company && company.departments.length === 0 && user && profiles && profiles.length === 1) {
    teamOptions = [
      { value: company._id, label: `💪 ${company.name}`, type: 1 },
      { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 }
    ];
  } else if (company && company.departments.length === 0 && user && profiles && profiles.length > 1) {
    // if 0 teams and 1+ users
    // add user and company to others members that we found in the profiles loop
    let person = { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 };
    teamOptions.unshift(person);

    let team = { value: company._id, label: `💪 ${company.name}`, type: 1 };
    teamOptions.unshift(team);
  }

  let choosedTeam = user
    ? {
        value: user._id,
        label:
          (user && user.departmentName === null) || (user && user.departmentName === undefined)
            ? `${user.firstName} ${user.lastName}`
            : `${user.firstName} ${user.lastName} - [${user.departmentName}]`,
        type: 3
      }
    : {};

  // Сейчас лайки и комменты отслеживаются так:
  // - передает детям formData и setFormData
  // - из actions мы получаем коллбэк
  // - перебираем в мапе массив checkins, находим ответ по id из коллбэка
  // - обновляем в нем ВСЕ лайки / комменты

  // Как можно улучшить?
  // - отслеживать изменение в useReducer и забирать там не весь редюсер, а только изменения => перебирать  checkins также с этими изменениями (возможно useReducer будет быстрей коллбэка - надо проверять)
  // - UPD: сделать отдельный useEffect, в котором отслеживать только изменение лайков

  const getStandupsParams = useRef({ firstLoad: true });

  // useEffect(()=>{console.log(isFirstReq);},[getStandups]);

  const getStandupsStepped = useCallback(() => {
    getStandups(30).then(() => getStandups());
  }, [getStandups]);

  const getCompanyAnswersStepped = useCallback(() => {
    user && getCompanyAnswers({ limit: 30, user: user._id }).then(() => getCompanyAnswers());
  }, [getCompanyAnswers, user]);

  const getMenuIndex = (campaigns) => {
    let arr = campaigns.map((item, index) => {
      if (item._id === match.params.id) {
        return index + 4;
      }
    });

    let index = arr.filter((item) => item !== undefined)[0];

    return index;
  };

  useEffect(() => {
    getUserCompany();
    getCompanyAnswersStepped();
    getCompanyProfiles();
    getStandupsStepped();
    getAllCustoms();
  }, [getUserCompany, getCompanyAnswersStepped, getAllCustoms, getCompanyProfiles, getStandupsStepped]);

  useEffect(() => {
    setFormData({
      team: !user ? '' : choosedTeam,
      checkins: !standupAnswers ? [] : standupAnswers.filter((answer) => answer.user === choosedTeam.value),
      notes: !standupAnswers ? '' : standupAnswers.notes
    });

    setMenu({
      menu: campaigns && campaigns.length > 0 ? getCheckinsMenu(campaigns) : menuItems,
      menuIndex: campaigns && campaigns.length > 0 ? getMenuIndex(campaigns) : 100
    });
  }, [user, standupAnswers, campaigns, getCheckinsMenu]);

  const handleTeam = (selectedOption) => {
    let newCheckins;
    if (selectedOption.type === 1) {
      // Company
      newCheckins = standupAnswers.filter((answer) => answer.company === selectedOption.value);
    } else if (selectedOption.type === 2) {
      // Team
      newCheckins = standupAnswers.filter((answer) => answer.department === selectedOption.value);
    } else if (selectedOption.type === 3) {
      // USer
      newCheckins = standupAnswers.filter((answer) => answer.user === selectedOption.value);
    }

    setFormData({
      ...formData,
      team: selectedOption,
      checkins: newCheckins
    });
  };

  const noStandupsMessageAdmin = {
    title: <FormattedMessage id="company.nostandups.admin.title" defaultMessage="No standups" />,
    msg: (
      <FormattedMessage
        id="company.nostandups.admin.msg"
        defaultMessage="You have not finished standups yet. You could participate in the first standup by clicking the button above. In this section, you will see all standups from your company. It helps you and your team stay in sync without meetings and work together better. You can invite people on the admin menu."
      />
    )
  };
  const noStandupsMessageUserOld = {
    title: <FormattedMessage id="company.nostandups.user.title" defaultMessage="No standups" />,
    msg: (
      <FormattedMessage
        id="company.nostandups.user.msg"
        defaultMessage="You have not finished standups yet. You could participate in the first standup by clicking the button above. In this section, you will see all standups from your company. It helps you and your team stay in sync without meetings and work together better."
      />
    )
  };

  const noCheckinsMessageTeam = {
    title: <FormattedMessage id="company.nocheckins.user.title" defaultMessage="No Check-ins" />,
    msg: (
      <FormattedMessage
        id="company.checkins.user.msg"
        defaultMessage={`${team.label} has not finished check-ins yet.`}
      />
    )
  };

  const noSearchResults = {
    title: <FormattedMessage id="search.noresults" defaultMessage="No Results Found" />,
    msg: (
      <FormattedMessage
        id="company.checkins.user.msg"
        defaultMessage={`Sorry, we couldn't find any results matching <b>"${searchKeyword}"</b>`}
        values={{ b: (...chunks) => <b>{chunks}</b> }}
      />
    )
  };

  const msg = (
    <FormattedMessage id="company.notification.msg1" defaultMessage="You have not filled standups yet." />
  );
  const msg2 = (
    <FormattedMessage
      id="company.notification.msg2"
      defaultMessage="Click the button below to go to personal menu and participate into your first standup."
    />
  );
  const cta = <FormattedMessage id="company.notification.cta" defaultMessage="Go to personal section" />;

  const standupLink = standups.length > 0 ? `/checkins/${standups[0]._id}` : '/checkins';

  // Removing duplicates in answers array based on the object key 'standup' - to send only unique standup items to StandupTeamItem
  // Берем данные из стейта checkins, а не редакса standupAnswers, чтобы делать фильтр на странице
  let standupAnswersUnique = useMemo(
    () =>
      checkins
        ? checkins.reduce((acc, current) => {
            const x = acc.find((item) => item.standup === current.standup);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, [])
        : [],
    [checkins]
  );

  const [standupAnswersUniqueFiltered, timeFilterOptions, timeFilterSelectedOption, timeFilterChangeOption] =
    useDateFilter(standupAnswersUnique, (item) => item.date, locale && locale.lang);

  // КАК СЕЙЧАС РАБОТАЕТ ПОИСК
  // 1. Формируем массив чекинов checkinsArr, который не зависит от checkins
  //   - он также привязан к team, чтобы искать по фильтру команды
  //   - отдельный массив для того, чтобы он не влияел на checkins, который рендерится на странице
  // 2. Делаем циклы, чтобы добраться до text
  //   - Цикл для checkinsArr, потом для checkinsArr.answers, потом для checkinsArr.answers.text
  // 3. Помещаем все в массив filteredCheckins, где хранятся результаты
  //   - используем push
  // ! НЕ ИСПОЛЬЗОВАЛ filter, так как не получается из него достать text
  //   - в будущем для скорости, это можно было бы улучшить
  //   - НО сейчас я не понимаю, как вернуть в фильтре массив, если внутри делается 2 цикла forEach
  //   - нам надо вернуть чекин полностью со всеми данными, при этом поиск ведется по text,
  //     чтобы добраться до которого надо делать 2 цикла!
  // Update переделал на filter c внутренним for т.к. forEach нельзя прервать
  const handleSearch = async (e) => {
    let value = e.target.value;
    let checkinsArr = [];

    setSearch(e.target.value);

    if (team.type === 1) {
      // Company
      checkinsArr = standupAnswers.filter((answer) => answer.company === team.value);
    } else if (team.type === 2) {
      // Team
      checkinsArr = standupAnswers.filter((answer) => answer.department === team.value);
    } else if (team.type === 3) {
      // USer
      checkinsArr = standupAnswers.filter((answer) => answer.user === team.value);
    }

    let filteredCheckins = [];

    if (e.target.value.length !== 0) {
      // If search input is not empty

      filteredCheckins = checkinsArr.filter((item) => {
        for (const answer of item.answers) {
          let result = false;
          result = answer.text.find((text) => text.toLowerCase().includes(e.target.value.toLowerCase()));
          if (result) return result;
        }
      });

      setFormData({
        ...formData,
        checkins: filteredCheckins
      });

      // Check if we have search results or not
      if (filteredCheckins.length !== 0) {
        setResults({
          ...results,
          searchResults: false,
          // searchKeyword: ''
          searchKeyword: e.target.value // change to highlight search results
        });
      } else {
        // If we don't have search results
        setResults({
          ...results,
          searchResults: true,
          searchKeyword: e.target.value
        });
      }
    } else {
      // Empty search input
      setFormData({
        ...formData,
        checkins: checkinsArr
      });

      setResults({
        ...results,
        searchResults: false,
        searchKeyword: ''
      });
    }
  };

  const handleCheckin = (selectedOption) => {
    history.push(selectedOption.url);
  };


  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">
          {user && user.department ? (
            <Menu
              companyName={user && user.companyName}
              team={user.departmentName}
              teamId={user.department}
              settings={user.isAdmin && true}
              focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true}
              activeIndex={2}
            />
          ) : (
            <Menu
              companyName={user && user.companyName}
              settings={user && user.isAdmin && true}
              focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true}
              activeIndex={2}
            />
          )}
          <UserNav />

          <Fragment>
            {loading || !standupAnswers || !user || !company ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="main main_new main-dashboard mt-3">
                  <div className="checkins">
                    <div className="page-title mb-xs">
                      <h5 className="pl-0-xs">{menuTitle}</h5>
                    </div>
                    <div className="pb-3">
                      <div className="sub-menu__page"></div>
                    </div>

                    <div className="checkins__body flex align-start mt-1">
                      <div className="checkins__content mr-5">
                      <div className="insight">
                      {/* <div className="mt-4 mb-5">
                        <SubMenu items={menu} activeIndex={1} mb={'1'} />
                      </div> */}
                      
                      <div className="flex align-center flex-column-xs">
                        <div className="flex align-center w-100-xs">
                            <FormattedMessage
                              id="checkins"
                              defaultMessage="Check-ins"
                            >
                            {(msg) => (
                              <Select
                                className="small-input w-230px w-50-xs mr-1"
                                value={menu[menuIndex-1]}
                                onChange={(selectedOption) => handleCheckin(selectedOption)}
                                options={menu}
                                placeholder={msg}
                                name="checkin"
                                styles={customStylesCampaign}
                                components={{
                                  IndicatorSeparator: () => null
                                }}
                              />
                            )}
                          </FormattedMessage>

                          <FormattedMessage
                            id="checkins.sub_menu.teamSelect"
                            defaultMessage="Choose the team or user"
                          >
                            {(msg) => (
                              <Select
                                className="small-input w-250px w-50-xs mr-1 mla"
                                defaultValue={choosedTeam}
                                value={team}
                                onChange={(selectedOption) => handleTeam(selectedOption)}
                                options={teamOptions}
                                isSearchable
                                placeholder={msg}
                                required
                                name="team"
                                styles={customStyles}
                              />
                            )}
                          </FormattedMessage>
                          <FormattedMessage
                            id="checkins.sub_menu.timeSelect"
                            defaultMessage="Choose the time period"
                          >
                            {(msg) => (
                              <Select
                                className="small-input w-150px w-50-xs mr-1"
                                defaultValue={choosedTeam}
                                value={timeFilterSelectedOption}
                                onChange={timeFilterChangeOption}
                                options={timeFilterOptions}
                                isSearchable
                                placeholder={msg}
                                required
                                name="time"
                                styles={customStyles}
                              />
                            )}
                          </FormattedMessage>

                          <FormattedMessage id="search.bar" defaultMessage="Search...">
                            {(msg) => (
                              <input
                                className="w-200px w-50-xs question-input mla-xs pl-2 br-4 brd brd-grey bg__white outline mla lh-34"
                                type="text"
                                placeholder={msg}
                                name="search"
                                value={search}
                                onChange={(e) => handleSearch(e)}
                              />
                            )}
                          </FormattedMessage>
                        </div>

                        {company.plan !== 1 &&
                          (standupAnswers.length === 0 ||
                          (standups &&
                            standups.length > 0 &&
                            standupAnswer &&
                            standups[0]._id.toString() !== standupAnswer.standup.toString()) ||
                          (standups && standups.length > 1 && !standupAnswer) ? (
                            <div className="w-180px h-34 mt-1-xs mla btn__secondary_green brd-transparent cursor text-center flex align-center">
                              <Link to={standupLink} className={'fs-14 white mxa'}>
                                <FormattedMessage id="daily.checkin" defaultMessage="Daily Check-in" />
                              </Link>
                            </div>
                          ) : null)}
                      </div>
                    </div>

                    {company.plan !== 1 ? (
                      <Fragment>
                        {standupAnswers.length === 0 ? (
                          <Fragment>
                            <Notification
                              msg={checkinCopy.msgOnboarding}
                              cta={checkinCopy.ctaOnboarding}
                              btnColor={'orange'}
                              color={`orange`}
                              url={standupLink}
                            />
                            <Fragment>
                              {onboardingStandups.map((standup) => (
                                <CheckinItem
                                  key={`teamStandups-${standup._id}`}
                                  standupAnswers={onboardingStandups}
                                  standupDate={standup.standupDate}
                                  mentions={mentions}
                                />
                              ))}
                            </Fragment>
                          </Fragment>
                        ) : (
                          <Fragment>
                            {/* {standups && standups.length > 0 && standupAnswer &&
                              standups[0]._id.toString() !== standupAnswer.standup.toString() || standups && standups.length > 1 && !standupAnswer && (
                                <Notification
                                  msg={checkinCopy.standupLive1}
                                  msg2={checkinCopy.standupLive2}
                                  cta={checkinCopy.standupLiveCta}
                                  btnColor={'orange'}
                                  color={`orange`}
                                  url={standupLink}
                                />
                              )} */}

                            {standupAnswersUniqueFiltered.length > 0 ? (
                              standupAnswersUniqueFiltered.map((standupAnswerUnique, index) => (
                                <Fragment key={`fragment-checkin-${index}`}>
                                  <h3 className="fs-2 pt-2 pl-2 pb-1" key={`date-${index}`}>
                                    <Moment format="D MMM" key={`date-moment-${index}`}>
                                      {standupAnswerUnique.date}
                                    </Moment>
                                  </h3>

                                  <CheckinItem
                                    key={`checkins-${standupAnswerUnique._id}-${index}`}
                                    standupAnswers={checkins.filter(
                                      (standupAnswer) =>
                                        standupAnswer.standup.toString() ===
                                        standupAnswerUnique.standup.toString()
                                    )}
                                    standupDate={standupAnswerUnique.standupDate}
                                    formData={formData}
                                    setFormData={setFormData}
                                    searchKeyword={searchKeyword.length > 0 && searchKeyword}
                                    mentions={mentions}
                                  />
                                </Fragment>
                              ))
                            ) : (
                              <div className="mt-5">
                                {!searchResults ? (
                                  <NoContent
                                    message={noCheckinsMessageTeam}
                                    picture={updateImg}
                                    alt={'No checkins'}
                                    imgWidth={'w-300px'}
                                  />
                                ) : (
                                  <NoContent
                                    message={noSearchResults}
                                    picture={updateImg}
                                    imgWidth={'w-300px'}
                                    alt={'No search results'}
                                  />
                                )}
                              </div>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      <NoContent
                        message={checkinCopy.updatePlanMsg}
                        picture={updateImg}
                        alt={'Update plan'}
                        imgWidth={'w-300px'}
                        admin={
                          user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0
                        }
                      />
                    )}
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

Checkin.propTypes = {
  clearStandups: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  getCompanyAnswers: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  updateLike: PropTypes.func.isRequired,
  getStandups: PropTypes.func.isRequired,
  getAllCustoms: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  standupAnswer: PropTypes.object.isRequired,
  standup: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  standupAnswer: state.standupAnswer,
  standup: state.standup,
  custom: state.custom,
  locale: state.locale
});

export default connect(mapStateToProps, {
  getCompanyAnswers,
  getUserCompany,
  getCompanyProfiles,
  updateLike,
  getStandups,
  clearStandups,
  getAllCustoms
})(Checkin);
