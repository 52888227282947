import React, { Fragment, useState } from "react";
import PropTypes from 'prop-types';
import OneHistoryItem from './OneHistoryItem';
// import { FormattedMessage } from 'react-intl';


const OneHistory = ({ docs }) => {

  const [showAll, setShowAll] = useState(false);

  // console.log('====== docs in OneHistory', docs);

  let historyItems = !showAll ? docs.slice(0, 3) : docs;

  // console.log('====== historyItems in OneHistory', historyItems);
  // console.log('====== docs AFTER slice in OneHistory', docs);


  return (
    <div className="timeline">
      <p 
        className="grey cursor small"
        onClick={() => setShowAll(!showAll)}
      >
        {!showAll ? 'Show more' : 'Show less'}
      </p>
			<div className="timeline__row flex wrap flex-column-s mr-1">
        {historyItems && historyItems.map((item, index) => (
          <OneHistoryItem 
            key={`history-column-${index}`}
            item={item} 
          />
        ))}
      </div>
    </div>
  );
};

OneHistory.propTypes = {
  docs: PropTypes.array.isRequired,
};

export default OneHistory;
