import React from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import RoundImg from '../../elems/RoundImg';
import { FormattedMessage } from 'react-intl';
import defaultAvatar from '../../../img/avatar/avatar.png';

const Avatar = ({ user }) => {

  // currentProfile && console.log('====== currentProfile.user', currentProfile.user);
  // user && console.log('====== user._id', user._id);
  // user && console.log('====== user.firstName', user.firstName);
  // user && console.log('====== user.jobTitle', user.jobTitle);

  let withWord = <FormattedMessage id="with" defaultMessage="with"/>;

  let link = user ? `/user/${user._id}` : '#!';
  let src = user ? user.avatar : defaultAvatar;
  let alt = user ? `${user.firstName} avatar` : 'Avatar';
  let userName = user ? `${user.firstName} ${user.lastName}` : '';
  let jobTitle = user ? user.jobTitle : '';

  return (
    <div className="user-title">
      <div className="flex align-center justify-start mr-xs mb-1">
         <Link to={link} className="user mr-1 fs1">
            <RoundImg
              src={src}
              alt={alt}
              w="60"
              h="60"
              styles="brd brd-primary medium-shadow"
            />
        </Link>
        <div className="okr__owner">
          <p className="fs-20 bold-pure">1:1{" "}{withWord}{" "}{userName}</p>
          <span className="medium">{jobTitle}</span>
        </div>
      </div>
    </div>
  );
};

Avatar.propTypes = {
  user: PropTypes.object,
};

export default Avatar;
