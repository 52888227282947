import {
  GET_STANDUPS,
  GET_STANDUP,
  STANDUPS_ERROR,
  CLEAR_STANDUPS,
  GET_SETTINGS,
  SETTINGS_ERROR,
  CLEAR_STANDUP_SETTINGS,
  GET_SETTINGS_DASHBOARD,
} from '../actions/types';

const initialState = {
  standups: [],
  standup: null,
  settings: null,
  loading: true,
  isSettingsLoading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_STANDUPS:
      return {
        ...state,
        standups: payload,
        loading: false
      };
    case GET_STANDUP:
      return {
        ...state,
        standup: payload,
        loading: false
      };
    case GET_SETTINGS:
      return {
        ...state,
        settings: payload,
        loading: false,
        isSettingsLoading: false
      };
    case GET_SETTINGS_DASHBOARD:
      return {
        ...state,
        settings: payload,
        loading: false,
      };
    case SETTINGS_ERROR:
    case STANDUPS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case CLEAR_STANDUPS:
      return {
        ...state,
        standups: [],
        standup: null,
        settings: null,
        loading: true
      };
    case CLEAR_STANDUP_SETTINGS:
      return {
        ...state,
        settings: null,
        isSettingsLoading: true
      };
    default:
      return state;
  }
}
