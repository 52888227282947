import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import MainMenu from '../layout/Sidebar';
import Menu from '../layout/Menu';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import MainWidget from '../utils/MainWidget';
import CompanyMenu from './CompanyMenu';
import RecognitionItem from '../recognitions/noState/RecognitionItem';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/1.jpg';
import Notification from '../utils/Notification';
import {
  getCompanyRecognitions
} from '../../actions/recognition';
import { getCompanyProfiles } from '../../actions/profile';
import { getUserCompany } from '../../actions/company';
import { onboardingRecognitions } from '../../utils/onboarding/recognitions';
import { getCompanySortedOkrs } from '../../actions/okr';
import { FormattedMessage } from 'react-intl';
import { kudosCopy } from '../../utils/wording/kudos';

const CompanyKudos = ({
  getCompanyRecognitions,
  getCompanyProfiles,
  getCompanySortedOkrs,
  getUserCompany,
  company: { company },
  okr: { okrs },
  auth: { user, sidebar },
  profile: { profiles },
  recognition: { recognitions, givedRecognitions, loading }
}) => {
  useEffect(() => {
    getUserCompany();
    getCompanyProfiles();
    getCompanyRecognitions();
    getCompanySortedOkrs();

  }, [getUserCompany, getCompanyProfiles, loading, getCompanyRecognitions, getCompanySortedOkrs]);


  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {user && !loading && (
          <MainMenu activeIndex={6} />
        )}

        <UserNav />
        
          <Fragment>
            {loading || !recognitions || !user || !okrs || !company ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className={`${sidebar === 1 ? 'main main-dashboard ' : 'short_menu__ml__okrs ma-w90 pt-2'} mt-3`}>
                     <h5 className="pl-0-xs">
                      <FormattedMessage id="company.header.okrs" defaultMessage="Company Kudos" />
                    </h5>

                  <CompanyMenu companyIndex={4} />

                  <div className="kudos">

                    <div className="mb-4">
                        {okrs.length > 0 && (
                          okrs.map(okr => (
                            okr.isMain && okr.type === 1 && <MainWidget key={`main-${okr._id}`} okr={okr} />
                            )
                          ))} 
                      </div>

                      <div className="text-right pb-1">
                        <Link to="/kudos/new" className="btn btn-light">
                          <FormattedMessage
                            id="user.recognition.createBtn"
                            defaultMessage="+ Create Recognition"
                          />
                        </Link>
                      </div>

                      {company.plan !== 1 ? (
                        <Fragment>
                          {!loading && recognitions.length === 0 && givedRecognitions ? (
                            <Fragment>
                              <Notification
                                msg={kudosCopy.msgOnboarding}
                                cta={kudosCopy.ctaOnboarding}
                                btnColor={'orange'}
                                color={`orange`}
                                url={`/kudos/new`}
                              />
                              {onboardingRecognitions.map(recognition => (
                                <RecognitionItem
                                  key={recognition._id}
                                  recognition={recognition}
                                />
                              ))}
                            </Fragment>
                          ) : (
                            <Fragment>
                              {recognitions.map(recognition => (
                                <RecognitionItem
                                  key={recognition._id}
                                  recognition={recognition}
                                />
                              ))}
                            </Fragment>
                          )}
                        </Fragment>
                      ) : (
                        <NoContent 
                          message={kudosCopy.updatePlanMsg}
                          picture={updateImg}
                          alt={'Update plan'}
                          admin={user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0}
                        />
                      )}
                    
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

CompanyKudos.propTypes = {
  getCompanyProfiles: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getCompanyRecognitions: PropTypes.func.isRequired,
  getCompanySortedOkrs: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  recognition: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  recognition: state.recognition,
  okr: state.okr
});

export default connect(
  mapStateToProps,
  { getUserCompany, getCompanyProfiles, getCompanyRecognitions, getCompanySortedOkrs }
)(CompanyKudos);
