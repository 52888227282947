import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const CampaignItem = ({
  formData,
  setFormData,
  disabled,
  campaignTitle,
  name,
  inputName,
  status,
  url,
  isActive,
  toggleStatusFunc,
  settings,
  isArray,
}) => {
  
  const nextMsg = <FormattedMessage id="next.icebreaker" defaultMessage="Next Icebreaker" />
  const dontwork = <FormattedMessage id="dont.work" defaultMessage="don't work on this plan" />

  const setData = () => {
    if (!isArray) {
      setFormData({ ...formData, inputName: !status });
    } else {
      setFormData({ ...formData, inputName: !status  });
    }
  };

  return (
    <Fragment>
      <div className="card-item card-team">
          <div className="card-title card-title-blue2 p-2">
          <p className="medium card-subtitle campaign-name">
            {campaignTitle}
            </p>

            {disabled && (
              <p className="small grey">
              {name}{' '}{dontwork}
                </p>
              )}

            {!disabled && inputName === 'icebreakerStatus' && (
              <p className="small grey pr-1">
                {nextMsg}:{' #'}{settings.nextQuestionId + 1}
                </p>
              )}

            <div className={`card-status ${inputName === 'icebreakerStatus' && 'ml-1 mr-5'}`}>
              <div className="checkbox checkbox-status">
                <div className="checkbox-item active-status">
                  <input
                    type="checkbox"
                    id={inputName}
                    name={inputName}
                    checked={status}
                    value={status}
                    onChange={e => {
                      setData();
                      toggleStatusFunc(settings._id);
                    }}
                    disabled={disabled}
                  />
                  <label htmlFor={inputName}>
                    {isActive ? (
                      <FormattedMessage
                        id="admin.dashboard.updates.active"
                        defaultMessage="Active"
                      />
                    ) : (
                      <FormattedMessage
                        id="admin.dashboard.updates.disable"
                        defaultMessage="Disabled"
                      />
                    )}
                  </label>
                </div>
              </div>
            </div>

            <Link
              to={`/settings/${url}`}
              className="btn-secondary btn-secondary-comment btn-secondary-status"
            >
              <FormattedMessage
                id="admin.dashboard.updates.editBtn"
                defaultMessage="Edit"
              />
            </Link>
          </div>
        </div>
    </Fragment>
  );
};

CampaignItem.propTypes = {
  settings: PropTypes.object,
  updateSettings: PropTypes.object,
  title: PropTypes.object,
  slack: PropTypes.number,
  disabled: PropTypes.bool
};

export default CampaignItem;
