import React from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import isEnabled, { ENGAGEMENT } from '../../utils/features';

const SubMenuNew = ({ company: { company }, activeIndex }) => {
  return (
    <div className="w-230px flex flex-column">
      <Link
        to={'/settings'}
        className={`w-content hover__black medium mb-1 grey_new fw-600 cursor ${activeIndex === 1 ? ' sub-menu__item_active__new' : 'pl-16'}`}
      >
        {isEnabled(ENGAGEMENT, { company })
          ? <FormattedMessage id="checkins.and.icebreakers" defaultMessage="Check-ins & Icebreakers" />
          : <FormattedMessage id="checkins" defaultMessage="Check-ins" />
        }
      </Link>
      
      <Link
        to={'/settings/integrations'}
        className={`w-content hover__black medium mb-1 grey_new fw-600 cursor ${activeIndex === 4 ? ' sub-menu__item_active__new' : 'pl-16'}`}
      >
        <FormattedMessage id="admin.settings.integrations" defaultMessage="Integrations" />
      </Link>

      <Link
        to={'/settings/pricing'}
        className={`w-content hover__black medium mb-1 grey_new fw-600 cursor ${activeIndex === 5 ? ' sub-menu__item_active__new' : 'pl-16'}`}
      >
        <FormattedMessage id="admin.settings.plans" defaultMessage={`Plans & Billing`} />
      </Link>

      <Link
        to={'/settings/workspace'}
        className={`w-content hover__black medium mb-1 grey_new fw-600 cursor ${activeIndex === 6 ? ' sub-menu__item_active__new' : 'pl-16'}`}
      >
        <FormattedMessage id="delete.workspace" defaultMessage={`Delete Workspace`} />
      </Link>
    </div>
  );
};

SubMenuNew.propTypes = {
  activeIndex: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
  company: state.company,
});

export default connect(mapStateToProps)(SubMenuNew);