import axios from "axios";
import { setAlert } from "./alert";

import {
  GET_USER_OKRS,
  GET_ALL_USER_OKRS,
  GET_USER_OKR,
  GET_TEAM_OKRS,
  GET_USER_METRICS,
  USER_METRICS_ERROR,
  GET_COMPANY_METRICS,
  COMPANY_METRICS_ERROR,
  GET_OKR_FOR_EDIT,
  GET_COMPANY_OKRS_FOR_EDIT,
  OKR_ERROR,
  ADD_MAIN_OKR,
  REMOVE_MAIN_OKR,
  MAIN_OKR_ERROR,
  USER_OKR_ERROR,
  USER_OKRS_ERROR,
  TEAM_OKRS_ERROR,
  OKRS_ERROR,
  ADD_OKR,
  EDIT_OKR,
  UPDATE_OKR,
  UPDATE_OKRS,
  UPDATE_ONE_KR,
  UPDATE_OKR_LIKES,
  OKR_LIKES_ERROR,
  ADD_OKR_COMMENT,
  UPDATE_OKR_COMMENT,
  OKR_COMMENT_ERROR,
  GET_COMPANY_OKRS,
  COMPANY_OKRS_ERROR,
  DELETE_OKR,
  REMOVE_OKR_COMMENT,
  UPDATE_CHILDS,
  CLEAR_OKRS,
  CLEAR_OKR,
  LOCATION_CHANGE_ERROR,
  CLEAN_DATA_OKR,
  ARCHIVE_OKR,
  UNARCHIVE_OKR,
  GET_TREE_OKRS,
  TREE_OKRS_ERROR,
  GET_ACTIVE_OKRS,
  ACTIVE_OKRS_ERROR,
  REMOVE_OKR_UPDATE,
  OKR_UPDATE_ERROR,
  GET_OKR_FOR_URL,
  CHANGE_OKR_FILTERS,
  PREPARE_FIRST_OKR,
  GET_ONBOARDING_OKR,
  REMOVE_ONBOARDING_OKR,
  CHECK_OKR_FILTERS,
} from "./types";

// Get all current user OKRs
// Now, we get only OKRs with !isArchive (to make frontend faster)
export const getAllUserOkrs = () => async dispatch => {
  try {
    const res = await axios.get("/api/okr/all/user");

    dispatch({
      type: GET_ALL_USER_OKRS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: USER_OKRS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Now, we get only OKRs with !isArchive (to make frontend faster)
export const getAllIndividualUserOkrs = () => async dispatch => {
  try {
    const res = await axios.get("/api/okr/all/user/individual");

    dispatch({
      type: GET_ALL_USER_OKRS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: USER_OKRS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get current user OKRs except the last
export const getUserOkrs = () => async dispatch => {
  try {
    const res = await axios.get("/api/okr/user");

    dispatch({
      type: GET_USER_OKRS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: USER_OKRS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get the last OKR from current user
export const getLastUserOkr = () => async dispatch => {
  try {
    const res = await axios.get("/api/okr/last");

    dispatch({
      type: GET_USER_OKR,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: USER_OKR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// // Get all user team OKRs
export const getTeamOkrs = () => async dispatch => {
  try {
    const res = await axios.get("/api/okr/team");

    dispatch({
      type: GET_TEAM_OKRS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TEAM_OKRS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all company answers
export const getCompanyOkrs = () => async dispatch => {
  try {
    
    const res = await axios.get("/api/okr/company");

    dispatch({
      type: GET_COMPANY_OKRS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: COMPANY_OKRS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all company sorted by parent-child OKRs 
export const getCompanySortedOkrs = () => async dispatch => {
  try {
    
    const res = await axios.get("/api/okr/company/sorting");
    // console.log('res.data in getCompanySortedOkrs', res.data)

    dispatch({
      type: GET_COMPANY_OKRS,
      payload: res.data
    });

    // Callback for updating the state and show OKRs on the page (before we show notification that a team doesn't have OKR in the first 1-2 sec)
    const obj = { ok: true };
    return obj;

  } catch (err) {
    console.log('Error in getCompanySortedOkrs', err)
    dispatch({
      type: COMPANY_OKRS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get all company OKRs for OKR tree (based on branches)
export const getCompanyTreeOkrs = () => async dispatch => {
  try {
    
    const res = await axios.get("/api/okr/company/tree/new");

    dispatch({
      type: GET_TREE_OKRS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TREE_OKRS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get all company answers for Edit
export const getCompanyOkrsEdit = () => async dispatch => {
  try {
    
    const res = await axios.get("/api/okr/company");
    
    dispatch({
      type: GET_COMPANY_OKRS_FOR_EDIT,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: COMPANY_OKRS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
  

// Get user OKRs by user ID
export const getUserOkrsById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/okr/user/${id}`);
   
    dispatch({
      type: GET_USER_OKRS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: USER_OKRS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get company's metrics
export const getAllMetrics = () => async dispatch => {
  try {
    const res = await axios.get(`/api/okr/metrics/company`);
    
    dispatch({
      type: GET_COMPANY_METRICS,
      payload: res.data
    });
  } catch (err) {
    console.log('err', err);
    dispatch({
      type: COMPANY_METRICS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get user MEtrics by user ID
export const getUserMetricsById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/okr/metrics/user/${id}`);
   
    dispatch({
      type: GET_USER_METRICS,
      payload: res.data
    });
  } catch (err) {
    console.log('err', err);
    dispatch({
      type: USER_METRICS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all non-archive OKRs (for dashboard)
export const getActiveOkrs = () => async dispatch => {
  try {
    const res = await axios.get("/api/okr/company/active");

    dispatch({
      type: GET_ACTIVE_OKRS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ACTIVE_OKRS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all team OKRs by team ID
export const getTeamOkrsById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/okr/team/${id}`);

    dispatch({
      type: GET_TEAM_OKRS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TEAM_OKRS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get OKR by ID
export const getOkrById = id => async dispatch => {
  try {
    
    const res = await axios.get(`/api/okr/${id}`);
    
    dispatch({
      type: GET_OKR_FOR_EDIT,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: OKR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get OKR by ID for opening separate page '/objectives/:id'
export const getOkrByIdUrl = id => async dispatch => {
  try {
    
    const res = await axios.get(`/api/okr/url/${id}`);
    
    dispatch({
      type: GET_OKR_FOR_URL,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: OKR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Create OKR
export const addOkr = (data, history, isOnboarding = false) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify(data);

    const res = await axios.post(`/api/okr/new`, body, config);

    dispatch({
      type: ADD_OKR,
      payload: res.data
    });

    dispatch(setAlert('OKR Created', 'success', 2000));

    // console.log('==== isOnboarding', isOnboarding)

    if (!isOnboarding) {
      history.push('/objectives')
    } else {
      dispatch({
        type: REMOVE_ONBOARDING_OKR
      });

      history.push('/welcome/invite')
    }
   
  } catch (err) {
    console.log('err', err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: OKR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });

    return { error: true };
  }
};

// Edit OKR
export const editOkr = (id, data, history) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify(data);
    const res = await axios.put(`/api/okr/${id}`, body, config);
    
    dispatch({
      type: EDIT_OKR,
      payload: { id, data: res.data }
    });

    dispatch(setAlert('OKR updated', 'success', 2000));

    if (history) {
      history.goBack();
    }
  } catch (err) {
    const errors = err.response.data.errors;
    console.log('err.response.data', err.response.data);
    console.log('err.', err);

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: OKR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });

    return { errors }
  }
};

// Update One OKR
export const updateOkr = (data, id) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify(data);

    const res = await axios.put(`/api/okr/update/${id}`, body, config);
    
    dispatch({
      type: UPDATE_OKR,
      payload: { id, data: res.data }
    });

    // Callback for updating in formData.objectives in useState to render it
    const arr = { id, okr: res.data};
    return arr;

  } catch (err) {
    const errors = err.response.data.errors;
    console.log('err.response.data', err.response.data);
    console.log('err', err);

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: OKR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update Many OKR
export const updateManyOkrs = (data) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    let okrs = [];

    data.forEach(okr => {
      if (okr.isNew) {
        okrs.push(okr);
      }
    });

    // Check if we have updated OKRs
    if (okrs.length > 0) {
      const body = JSON.stringify({okrs});
  
      const res = await axios.put(`/api/okr/many/update`, body, config);

      dispatch({
        type: UPDATE_OKRS,
        payload: { data: res.data }
      });
    }

  } catch (err) {
    const errors = err.response.data.errors;
    console.log('err.response.data.', err.response.data);

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: OKRS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Update One KR
export const updateOneResult = (data, index, id) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify(data);
    const res = await axios.put(`/api/okr/update/${id}`, body, config);
    
    dispatch({
      type: UPDATE_ONE_KR,
      payload: { id, data: res.data }
    });
  } catch (err) {
    const errors = err.response.data.errors;
    console.log('err.response.data.', err.response.data);

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: OKR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Export OKRs to CSV file
export const exportToCsv = okrs => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  try {

    let data = [
     { "objective": "okr cool", "status": "On track" }
    ]
    
    const body = JSON.stringify({ okrs: data });
    // console.log('======= body', body);

    const res = await axios.get(`/api/okr/export/csv`);
    // const res = await axios.put(`/api/okr/export/csv`, body, config);
    // console.log('======= res', res);

    // dispatch({
    //   type: GET_OKR_FOR_URL,
    //   payload: res.data
    // });
  } catch (err) {
    dispatch({
      type: OKR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Archive/Unarchive OKR
export const archiveOKR = (id, isArchive, isTree) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({ isArchive: !isArchive });
    const res = await axios.put(`/api/okr/archive/${id}`, body, config);

    if (!isArchive) {
      dispatch({
        type: ARCHIVE_OKR,
        payload: { id, data: res.data, isTree: isTree }
      });

      dispatch(setAlert('OKR was archived', 'success', 2000));
    } else {
      dispatch({
        type: UNARCHIVE_OKR,
        payload: { id, data: res.data, isTree: isTree }
      });

      dispatch(setAlert('OKR was unarchived', 'success', 2000));
    }

    // Callback for updating in formData.objectives in useState to render it
    const arr = { id, okr: res.data };
    return arr;

  } catch (err) {
    console.log('err', err);
    const errors = err.response.data.errors;
    console.log('err.response.data.', err.response.data);

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: OKR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Delete OKR
export const deleteOkr = (okrID, isTree) => async dispatch => {
  try {
    const res = await axios.delete(`/api/okr/${okrID}`);

    // Check if OKR had childs
    if (!res.data.type) {
      // No childs
      dispatch({
        type: DELETE_OKR,
        payload: okrID,
        isTree
      });

      // Callback for updating in formData.objectives in useState to render it
      let data = { okrID }
      return data;

    } else {
      // OKR had childs and we updated it
      dispatch({
        type: DELETE_OKR,
        payload: okrID,
        isTree,
      });

      dispatch({
        type: UPDATE_CHILDS,
        payload: { data: res.data.childs },
        isTree
      });

      // Callback for updating in formData.objectives in useState to render it
      let data = { okrID, childs: res.data.childs }
      return data;

    }
  } catch (err) {
    const errors = err.response.data.errors;
    console.log('err.response.data.', err.response.data);
    
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    
    dispatch({
      type: OKR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set OKR as a Main goal on the widget for user
export const addUserMainOkr = (id, index = 99, team) => async dispatch => {
  try {

    // team: 1 - company, 2 - team, 3 - user
    const res = await axios.put(`/api/okr/main/user/${id}/${index}/${team}`);

    dispatch({
      type: ADD_MAIN_OKR,
      payload: { id, main: res.data.main }
    });

    if (res.data.exOkr !== null) {
      dispatch({
        type: REMOVE_MAIN_OKR,
        payload: { id: res.data.exOkr._id, main: res.data.exOkr }
      });
    }

    // Callback for updating in formData.objectives in useState to render it
    let data = {};

    if (res.data.exOkr !== null) {
      data = { 
        id,
        main: res.data.main,
        exOkrId: res.data.exOkr._id,
        exMain: res.data.exOkr 
      }
    } else {
      // Если кликнули по тому же OKR
      data = { id, main: res.data.main }
    }
    
    return data;

  } catch (err) {
    console.log('Error in add Main OKR', err);

    dispatch({
      type: MAIN_OKR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Add & Remove like
export const updateLike = id => async dispatch => {
  try {
    const res = await axios.put(`/api/okr/like/${id}`);
    

    dispatch({
      type: UPDATE_OKR_LIKES,
      payload: { id, likes: res.data }
    });

    // Callback for updating in formData.objectives in useState to render it
    const data = { id, likes: res.data};
    return data;

  } catch (err) {
    console.log('Error in OKR likes', err);

    dispatch({
      type: OKR_LIKES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add comment
export const addComment = (id, formData) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/okr/comment/${id}`,
      formData,
      config
    );
  
    dispatch({
      type: ADD_OKR_COMMENT,
      payload: { id, comments: res.data }
    });

     // Callback for updating formData.objectives in useState to render it
     const data = { id, comments: res.data }
     return data;

  } catch (err) {
    console.log('Error in add OKR comment', err);
    
    dispatch({
      type: OKR_COMMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit comment
export const editComment = (okrID, commentID, formData) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.put(
      `/api/okr/comment/${okrID}/${commentID}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_OKR_COMMENT,
      payload: {
        id: okrID,
        comments: res.data
      }
    });
  } catch (err) {
    dispatch({
      type: OKR_COMMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Save data for the first okr on onboarding
export const prepareOkrOnboarding = (data, history) => async dispatch => {
  try {

    dispatch({
      type: PREPARE_FIRST_OKR,
      payload: JSON.stringify(data)
    });

    history.push(`/welcome/okrs/create`)

  } catch (err) {
    console.log('===== Error in prepareOkrOnboarding', err)
    dispatch({
      type: OKR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get data from LS for 1st okr
export const getCustomOkr = () => async dispatch => {
  try {
    dispatch({
      type: GET_ONBOARDING_OKR
    });

  } catch (err) {
    dispatch({
      type: OKR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Delete comment
export const deleteComment = (okrID, commentID) => async dispatch => {
  try {
    await axios.delete(`/api/okr/comment/${okrID}/${commentID}`);

    dispatch({
      type: REMOVE_OKR_COMMENT,
      payload: { okrID, commentID }
    });

    // Callback for updating in formData.objectives in useState to render it
    const data = { okrID, commentID }
    return data;
    
  } catch (err) {
    dispatch({
      type: OKR_COMMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete OKR update
export const deleteUpdate = (okrID, updateId) => async dispatch => {
  try {
    await axios.delete(`/api/okr/update/${okrID}/${updateId}`);

    dispatch({
      type: REMOVE_OKR_UPDATE,
      payload: { okrID, updateId }
    });

    // Callback for updating in formData.objectives in useState to render it
    const data = { okrID, updateId }
    return data;
    
  } catch (err) {
    dispatch({
      type: OKR_UPDATE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Clear state
export const clearOkrs = () => async dispatch => {
  try {
   
    dispatch({
      type: CLEAR_OKRS
    });
  } catch (err) {
    dispatch({
      type: LOCATION_CHANGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const clearOkr = () => async dispatch => {
  try {
   
    dispatch({
      type: CLEAR_OKR
    });
  } catch (err) {
    dispatch({
      type: LOCATION_CHANGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Clear state
export const cleanData = () => async dispatch => {
  try {
   
    dispatch({
      type: CLEAN_DATA_OKR
    });
  } catch (err) {
    dispatch({
      type: LOCATION_CHANGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const checkOkrFilters= (user)=>dispatch=>{
  try {
    // console.log('==== user in checkOkrFilters', user)
    dispatch({type:CHECK_OKR_FILTERS,payload:{company: user.user.company}})
  } catch (err) {
    console.log('Error in checkOkrFilters:', err)

    dispatch({
      type: LOCATION_CHANGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
}

export const changeOkrFilters= (team,time,company)=>dispatch=>{
  try {
    // console.log('==== team filter', team)
    // console.log('==== company filter', company)
    dispatch({type:CHANGE_OKR_FILTERS,payload:{team,time,company}})
  } catch (err) {
    console.log('Error in changeOkrFilters:', err)

    dispatch({
      type: LOCATION_CHANGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
}

