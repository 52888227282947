import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BtnLike from "./BtnLike";
import BtnComment from "./BtnComment";

const BtnCommAndLike = ({ comments, showComments, discussion, findComments, likes, findUserLike, handleLike, okrId, }) => {
  return (
    <div className="pt-xs pb-1 flex">
      <BtnLike 
        likes={likes}
        findUserLike={findUserLike}
        handleLike={handleLike}
        okrId={okrId}
      />
      <BtnComment 
        comments={comments}
        showComments={showComments}
        discussion={discussion}
        findComments={findComments}
      />
    </div>
  );
};

BtnCommAndLike.propTypes = {
  comments: PropTypes.array.isRequired,
  showComments: PropTypes.func.isRequired,
  findComments: PropTypes.func.isRequired,
  discussion: PropTypes.bool,
  likes: PropTypes.array.isRequired,
  findUserLike: PropTypes.func.isRequired,
  findUserLike: PropTypes.func.isRequired,
};

export default BtnCommAndLike;
