import axios from 'axios';
import React from 'react';
import { setAlert } from './alert';
import { FormattedMessage } from 'react-intl';
import { getPlanSettings } from './plan';

import {
  GET_COMPANY_MOOD_ANSWERS,
  GET_MOOD_SETTINGS,
  ADD_MOOD_ANSWER,
  MOOD_ANSWERS_ERROR,
  MOOD_SETTINGS_ERROR,
  UPDATE_MOOD_SETTINGS,
  CLEAR_MOOD_ANSWERS,
  GET_USER_MOOD_ANSWER,
  LOCATION_CHANGE_ERROR_MOOD,
  GET_USER_MOOD_ANSWER_AND_SETTINGS,
} from './types';

// Get all current user answers
export const getCompanyMoodAnswers = () => async dispatch => {
  try {
    const res = await axios.get('/api/moodanswers/company');

    dispatch({
      type: GET_COMPANY_MOOD_ANSWERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: MOOD_ANSWERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// // Get all current user answers
export const getLastUserMoodAnswer = () => async dispatch => {
  try {
    const res = await axios.get('/api/moodanswers/last');

    dispatch({
      type: GET_USER_MOOD_ANSWER,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: MOOD_ANSWERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get all current user answers
export const getMoodSettingsAndUserAnswer = () => async dispatch => {
  try {
    const res = await axios.get('/api/moodanswers/lastsettings');

    dispatch({
      type: GET_USER_MOOD_ANSWER_AND_SETTINGS,
      payload: { answer: res.data.answer, settings: res.data.settings }
    });
  } catch (err) {
    dispatch({
      type: MOOD_ANSWERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Add mood answer
export const addMoodAnswer = (
  grade,
  text,
) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({ grade, text, });

    const res = await axios.post(
      `/api/moodanswers/create`,
      body,
      config
    );

    dispatch({
      type: ADD_MOOD_ANSWER,
      payload: res.data
    });

    dispatch(setAlert('Anonymous mood answer was added', 'success', 3000));

    let data = { ok: true };

    return data;

  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: MOOD_ANSWERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get plans settings
export const getMoodSettings = () => async dispatch => {
  try {
    const res = await axios.get('/api/moodSettings/settings');

    dispatch({
      type: GET_MOOD_SETTINGS,
      payload: res.data
    });
  } catch (err) {
    console.log('===== err in MOOD_SETTINGS_ERROR', err);

    dispatch({
      type: MOOD_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit weekly plans campaign
export const editMoodSettings = (formData, history) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(`/api/moodSettings/edit `, formData, config);

    dispatch({
      type: UPDATE_MOOD_SETTINGS,
      payload: res.data
    });

    getPlanSettings();

    let msg = <FormattedMessage id="alert.mood.edit" defaultMessage="Mood survey was edited" />

    dispatch(setAlert(msg, 'success'));

    history.goBack();
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: MOOD_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Switch ON/OFF plans settings
export const toggleMoodSettings = () => async dispatch => {
  try {
    const res = await axios.put('/api/moodSettings/status');

    dispatch({
      type: GET_MOOD_SETTINGS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: MOOD_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Clear state
export const clearMoods = () => async dispatch => {
  try {
   
    dispatch({
      type: CLEAR_MOOD_ANSWERS
    });
  } catch (err) {
    dispatch({
      type: LOCATION_CHANGE_ERROR_MOOD,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};