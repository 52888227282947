import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Logo from '../../../elems/Logo';
import PropTypes from 'prop-types';
import { login } from '../../../../actions/auth';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../../layout/Spinner';
import Text from '../../elems/Text';
import Btn from '../../elems/WhiteBtn';
import Skip from '../../elems/Skip';

const slackUrl = "https://slack.com/oauth/v2/authorize?client_id=783663848244.2478640026919&scope=channels:join,channels:history,channels:read,chat:write,groups:history,im:history,im:read,links:read,mpim:history,team:read,users:read,users:read.email&user_scope=";

const Onboarding2 = ({ user }) => {

  let title = <FormattedMessage id="onboarding.checkin.title1" defaultMessage="Let's Create Your First Check-in!" />
  let message = <FormattedMessage id="onboarding.checkin.msg1" defaultMessage="Check-ins are a simple meeting that helps you align the team around objectives. Where would you like to run the check-ins?" />
  let web = <FormattedMessage id="webapp" defaultMessage="Web App" />
  let slack = <FormattedMessage id="slack" defaultMessage="Slack" />

  return (
    <Fragment>
      {!user ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="css-main onboarding bg__white">
            <div className="wrapper">
              <div className="flex">
                <div className="onboarding__content relative">
                  <div className="">
                    <div className="pb-8">
                      <Logo isNoLink={true} />
                    </div>
                    <Text title={title} text={message} page={4} />

                    <div className="buttons pt-5">
                      <Btn isUrl={true} url="/welcome/checkins/2" text={web} />
                      <div className="mt-2">
                        <Btn isExternalUrl={true} url={slackUrl} text={slack} />
                      </div>
                    </div>
                  </div>
                  
                  <Skip/>
                </div>
                <div className="onboarding__image onboarding__image__checkin"></div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

Onboarding2.propTypes = {
  login: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(
  mapStateToProps,
  { login }
)(Onboarding2);
