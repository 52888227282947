import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Logo from '../../elems/Logo';
import PropTypes from 'prop-types';
import { login } from '../../../actions/auth';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../layout/Spinner';
import Text from '../elems/Text';
import Btn from '../elems/WhiteBtn';
import Skip from '../elems/Skip';
import { createInvite } from '../../../actions/auth';
import { icons } from '../../../img/elements/icons';
import { setAlert } from '../../../actions/alert';

const OnboardingInvite = ({ 
  createInvite,
  locale: { lang },
  user,
  history,
  match,
  setAlert
}) => {
  
  const [formData, setFormData] = useState({
    email: ''
  });

  const { email } = formData;
  const onSubmit = (e) => {
    e.preventDefault();

    if (email === '') {
      return setAlert(
        <FormattedMessage
          id="email.is.required"
          defaultMessage="Email is required. Please enter email(s) and send an invite."
        />,
        'danger'
      );
    } 
    
    createInvite(email, lang, history).then((res) => {
      if (res) {
        if (match.url === '/welcome/invite') {
          // OKR onboarding
          history.push('/objectives')
        } else {
          // Check-ins onboarding
          history.push('/welcome/checkins/1')
        }
      }
    });
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  let title = <FormattedMessage id="welcome.invite.title" defaultMessage="Invite Your Team Members" />
  let textOkr = <FormattedMessage id="welcome.intite.msg.okr" defaultMessage={`Your first OKR has been set up, let's invite some team members to work together! Should you wish to edit the OKR later you can do so on the "Objectives" page`} />
  let textCheckin = <FormattedMessage id="welcome.intite.msg.checkins" defaultMessage="Let's invite some team members to work together !" />
  let text = match.url === '/welcome/invite' ? textOkr : textCheckin
  let separateMsg = <FormattedMessage id="separate.emails" defaultMessage="Separate multiple emails with a comma." />
  let sendMsg = <FormattedMessage id="send.invite" defaultMessage="Send Invite" />
  let continueMsgOkr = <FormattedMessage id="continue.to.app" defaultMessage="Continue to the App" />
  let continueMsgCheckin = <FormattedMessage id="skip.next.step" defaultMessage="Skip to next step" />
  let continueMsg = match.url === '/welcome/invite' ? continueMsgOkr : continueMsgCheckin
  let url = match.url === '/welcome/invite' ? "/objectives" : "/welcome/checkins/1"

  return (
    <Fragment>
      {!user ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="css-main onboarding bg__white">
            <div className="wrapper">
              <div className="flex">
                <div className="onboarding__content relative">
                  <div className="">
                    <div className="pb-8">
                      <Logo isNoLink={true} />
                    </div>
                    <Text title={title} text={text} page={3} />

                    <form className="form-app form-app_new form-app-invite onboarding__invite__form">
                      <div className="form-main mt-3 mb-xs">
                        <FormattedMessage
                          id="enter.email"
                          defaultMessage="Enter Email(s)"
                        >
                          {(msg) => (
                            <textarea
                              rows="5"
                              type="text"
                              placeholder={msg}
                              name="email"
                              value={email}
                              onChange={(e) => onChange(e)}
                            />
                          )}
                        </FormattedMessage>
                      </div>
                      <span className="fs-12">{separateMsg}</span>
                      <div className="flex align-center space-between mt-3">
                        <button
                          type="submit"
                          onClick={onSubmit}
                          className="onboarding__invite__btn text-center btn btn-form btn_primary_new h-54 lh-20 mt-0"
                        >
                         {sendMsg}
                        </button>
                        {/* <span className="fs-18 grey_new">{continueMsg}{" "}{icons.arrow_right_16}</span> */}
                        <Link to={url}>
                          <span className="fs-14 flex align-center cursor icon__grey">
                            <span className="mr-xs">{continueMsg}</span>
                            <span className="flex align-center">{icons.arrow_right}</span>
                          </span>
                        </Link>
                      </div>
                    </form>
                  </div>
                  
                  <Skip/>
                </div>
                <div className="onboarding__image onboarding__image__invite"></div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

OnboardingInvite.propTypes = {
  login: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.user,
  locale: state.locale,
});

export default connect(
  mapStateToProps,
  { login, createInvite, setAlert }
)(OnboardingInvite);
