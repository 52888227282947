import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const NumberUpdate = ({ 
  start,
  current, 
  formType, 
  onChange,
  onChangeOkr,
  indexOkr,
  index,
  goal,
 }) => {

  return (
    <Fragment>
      {/* <div className="w-220px medium-xs mr-xs"> */}
      <div className="kr__update__metrics__inputs medium-xs mr-xs">
        <FormattedMessage
          id="okr.update.kr.input.start"
          defaultMessage="0"
        >
          {msg => (
            <input
              // className="w-45px h-30 p-xs outline border__input_main fs-14"
              className="kr__update__metrics__input h-30 p-xs outline border__input_main fs-14"
              type="text"
              placeholder={msg}
              name="start"
              value={start === 0 ? '0' : start || ''}
              onChange={formType !== 2 ? (
                (e) => onChange(e, index)) : (
                (e) => onChangeOkr(e, indexOkr, index)
                )
              }
            />
          )}
        </FormattedMessage>
        <span className='mx-xs'>/</span>
        <FormattedMessage
          id="okr.update.kr.input.current"
          defaultMessage="0"
        >
          {msg => (
            <input
              // className="w-60px h-30 p-xs outline border__input_main fs-14"
              className="kr__update__metrics__input h-30 p-xs outline border__input_main fs-14"
              type="text"
              placeholder={msg}
              name="current"
              value={current === 0 ? '0' : current || ''}
              onChange={formType !== 2 ? (
                (e) => onChange(e, index)) : (
                (e) => onChangeOkr(e, indexOkr, index)
                )
              }
            />
          )}
        </FormattedMessage>
          <span className='mx-xs'>/</span>
          <FormattedMessage
          id="okr.update.kr.input.goal"
          defaultMessage="The goal"
        >
          {msg => (
            <input
              // className="w-65px h-30 p-xs outline border__input_main fs-14"
              className="kr__update__metrics__input h-30 p-xs outline border__input_main fs-14"
              type="text"
              placeholder={msg}
              name="goal"
              value={goal || ''}
              onChange={formType !== 2 ? (
                (e) => onChange(e, index)) : (
                (e) => onChangeOkr(e, indexOkr, index)
                )
              }
            />
          )}
        </FormattedMessage>
      </div>
    </Fragment>
  );
};

NumberUpdate.propTypes = {
  formType: PropTypes.number,
  onChangeOkr: PropTypes.func,
  onChange: PropTypes.func,
};

export default NumberUpdate;
