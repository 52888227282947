import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MainMenu from '../layout/Sidebar';
import Menu from '../layout/Menu';
import Navbar from '../layout/Navbar';
import UserNav from '../layout/UserNav';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import RecognitionItem from './RecognitionItem';
import Widgets from '../widgets/Widgets';
import Select from 'react-select';
import Notification from '../utils/Notification';
import NoContent from '../utils/NoContent';
import {
  clearKudos,
  getCompanyRecognitions
} from '../../actions/recognition';
import { FormattedMessage } from 'react-intl';
import { getCompanyProfiles } from '../../actions/profile';
import { getUserCompany } from '../../actions/company';
import { onboardingRecognitions } from '../../utils/onboarding/recognitions';
import updateImg from '../../img/feature/1.jpg';
import { kudosCopy } from '../../utils/wording/kudos';
import { menuTitle, menuItems } from '../../utils/menu/culture';
import SubMenu from '../elems/SubMenu';

const customStyles = {
  option: (provided, state, isSelected) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    fontSize: '14px',
  }),
  control: styles => ({ ...styles, backgroundColor: '#fcfcfd', padding: '4px 8px' }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
}

const Kudo = ({
  clearKudos,
  getCompanyRecognitions,
  getCompanyProfiles,
  getUserCompany,
  company: { company },
  auth: { user, sidebar },
  profile: { profiles },
  recognition: { recognitions, givedRecognitions, loading }
}) => {
  const [formData, setFormData] = useState({
    team: '',
    kudos: [],
    givedKudos: []
  });

  let { team, kudos, givedKudos } = formData;
  let teamOptions = [];

  // Перебор profiles & departments и приведение данных к формату Селекта
  {
    profiles &&
      profiles.forEach((profile, index) => {
        
        if (user && profile._id === user._id) return;  

        let coworker = {
          value: profile._id,
          label: `${profile.firstName} ${profile.lastName}`,
          type: 3
        };

        teamOptions.push(coworker);
      });
  }

  {
    company && company.departments.length > 0 &&
      company.departments.forEach((department, index) => {

        if (user && user.department === null) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };
  
          teamOptions.unshift(team);
        } else if (user && user.department !== null && department._id !== user.department) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };
  
          teamOptions.unshift(team);
        }

        if (user && index === company.departments.length - 1) {

          // You is third in list
          let person = { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 }
          teamOptions.unshift(person);

          // Second option is the team if exists
          if (user.department !== null) {
            let team = { value: user.department, label: `😎 ${user.departmentName}`, type: 2 };
            teamOptions.unshift(team);
          }

          // Third option is the company
          let name = { value: company._id, label: `💪 ${company.name}`, type: 1 };
          teamOptions.unshift(name);
        }
      });
  } 

  if (company && company.departments.length === 0 && user) {
    teamOptions = [
      { value: company._id, label: `💪 ${company.name}`, type: 1 },
      { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 }
    ]
  }

  let choosedTeam = user ? { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 } : {};

  useEffect(() => {
    getUserCompany();
    getCompanyRecognitions();
    getCompanyProfiles();

    setFormData({
      team: !user ? '' : choosedTeam,
      kudos: !recognitions ? [] : recognitions.filter(answer => answer.recipient === choosedTeam.value),
      givedKudos: !recognitions ? [] : givedRecognitions.filter(answer => answer.giver === choosedTeam.value)
    });
  }, [getUserCompany, getCompanyRecognitions, getCompanyProfiles, loading, user && choosedTeam.value.length]);

  const handleTeam = selectedOption => {
    let newKudos;
    let newGivedKudos;
    if (selectedOption.type === 1) {
      newKudos = recognitions.filter(answer => answer.company === selectedOption.value)
      newGivedKudos = givedRecognitions.filter(answer => answer.company === selectedOption.value)
    } else if (selectedOption.type === 2) {
      newKudos = recognitions.filter(answer => answer.recipientDepartment === selectedOption.value)
      newGivedKudos = givedRecognitions.filter(answer => answer.giverDepartment === selectedOption.value)
    } else if (selectedOption.type === 3) {
      newKudos = recognitions.filter(answer => answer.recipient === selectedOption.value)
      newGivedKudos = givedRecognitions.filter(answer => answer.company === selectedOption.value)
    }

    setFormData({ 
      ...formData, 
      team: selectedOption, 
      kudos: newKudos,
      givedKudos: newGivedKudos });
  };

  const [received, handleReceived] = useState(true);
  const [given, handleGiven] = useState(true);

  const noRecognitionMessage = {
    title: (
      <FormattedMessage
        id="company.norecognition.user.title"
        defaultMessage="Recognition"
      />
    ),
    msg: (
      <FormattedMessage
        id="company.norecognition.company.msg"
        defaultMessage="It's the section for company recognition. Each appreciation will be shown at this menu."
      />
    )
  };

  let titleLeft = <FormattedMessage id="widget.recognition.left.header" defaultMessage="Kudos received"/>
  let titleRight = <FormattedMessage id="widget.recognition.right.header" defaultMessage="Kudos given"/>

  return (
    <Fragment>
      <Fragment>
        <div className="css-main bg-primary">
          <div className="wrapper">

            {/* {user && user.department ? (
              <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user.isAdmin && true} focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={5} />
            ) : (
              <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={5} />
            )} */}
            {user && !loading && (
              <MainMenu activeIndex={5} />
            )}

            <UserNav />
            
            <Fragment>
              {loading || !company || !user ? (
                <Spinner />
              ) : (
                <Fragment>
                  <div className={`${sidebar === 1 ? 'ml-280 main main-dashboard ' : 'short_menu__ml__okrs ma-w90'} mt-3`}>
                    <div className="recognitions">
                      <h5>
                        {menuTitle}
                      </h5>

                      <SubMenu
                        items={menuItems}
                        activeIndex={2}
                        mb={'1'}
                      />

                    <div className="flex space-between align-center my-3">
                        <FormattedMessage
                          id="updates.sub_menu.teamSelect"
                          defaultMessage="Choose the team or user"
                        >
                          {msg => (
                            <Select
                              className="small-input w-30-l-50-xs"
                              defaultValue={choosedTeam}
                              value={team}
                              onChange={selectedOption =>
                                handleTeam(selectedOption)
                              }
                              options={teamOptions}
                              isSearchable
                              placeholder={msg}
                              required
                              name="team"
                              styles={customStyles}
                            />
                          )}
                        </FormattedMessage>

                        <Link to="/kudos/new" className="btn btn-light ml-2-xs text-center-xs">
                          <FormattedMessage
                            id="user.recognition.createBtn"
                            defaultMessage="+ Create Kudos"
                          />
                        </Link>
                      </div>
                      <Widgets
                        leftColor={'green'}
                        rightColor={'red'}
                        receivedRecognitions={kudos}
                        givedRecognitions={givedKudos}
                        received={received}
                        handleReceived={handleReceived}
                        given={given}
                        handleGiven={handleGiven}
                        titleLeft={titleLeft}
                        titleRight={titleRight}
                      />
                      {company.plan !== 1 ? (
                        <Fragment>
                          {!loading && recognitions.length === 0 && givedRecognitions ? (
                            <Fragment>
                              <Notification
                                msg={kudosCopy.msgOnboarding}
                                cta={kudosCopy.ctaOnboarding}
                                btnColor={'orange'}
                                color={`orange`}
                                url={`/kudos/new`}
                              />
                              {onboardingRecognitions.map(recognition => (
                                <RecognitionItem
                                  key={recognition._id}
                                  recognition={recognition}
                                />
                              ))}
                            </Fragment>
                          ) : (
                            <Fragment>
                              {kudos.map(recognition => (
                                <RecognitionItem
                                  key={recognition._id}
                                  recognition={recognition}
                                  formData={formData}
                                  setFormData={setFormData}
                                />
                              ))}
                            </Fragment>
                          )}
                        </Fragment>
                      ) : (
                        <NoContent 
                          message={kudosCopy.updatePlanMsg}
                          picture={updateImg}
                          alt={'Update plan'}
                          admin={user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0}
                        />
                      )}
                    </div>
                  </div>
                </Fragment>
              )}
            </Fragment>
          </div>
        </div>
      </Fragment>
    </Fragment>
  );
};

Kudo.propTypes = {
  clearKudos: PropTypes.func.isRequired,
  getCompanyRecognitions: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  recognition: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company,
  recognition: state.recognition,
  profile: state.profile
});

export default connect(
  mapStateToProps,
  { getUserCompany, getCompanyRecognitions, getCompanyProfiles, clearKudos }
)(Kudo);
