import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import SelectItem from "../../elems/Select";

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: state.isFocused ? '#404ff5' : '#e9eaf5',
    boxShadow: state.isFocused ? '0px 0px 20px #404FF54D' : 'none',
    border: state.isFocused ? '1px solid #404ff5' : styles.border,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    fontSize: '14px',
    height: '38px',
  }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize, };
  }
}

const NotificationItemReports = ({
  checkboxName,
  checkboxValue,
  setFormData,
  formData,
  defaultValue,
  handleSelect,
  selectOptions,
  isClearable,
  isMulti,
  isSearchable,
  wording,
  key,
  label,
  subTitle,
  inputLabel,
  type,
  section,
  labelStyles,
  localReportTime,
}) => {

  // console.log('====== defaultValue', defaultValue)

  let defaultTime = {}

  // type 1 - create, type 2 - edit
  if (defaultValue && defaultValue.hour) {
    defaultTime = {
      hour: { label: defaultValue.hour, value: defaultValue.hour },
      minute: { label: defaultValue.minute, value: defaultValue.minute },
    }
  }

  return (
    <div className="w-100 flex align-start flex-column checkbox">
      <div className="checkbox-item active-status active-status-new flex flex-column active-status ml-3 mt-2">
        <div className="flex align-center">
          <input
            type="checkbox"
            id={checkboxName}
            name={checkboxName}
            checked={checkboxValue}
            value={checkboxValue}
            onChange={e => {
              setFormData({
                ...formData,
                [checkboxName]: !checkboxValue
              });
            }}
          />
          <label className={`fs-13 ${labelStyles ? labelStyles : ''}`} htmlFor={checkboxName}>{label}</label>
        </div>
        <p className="fs-12 lh-15 mt-xs dark-gray">{subTitle}</p>
      </div>

      {checkboxValue && (
        <div className="flex align-center w-100 mt-xs">
          <p className="fs-13 mr-15">{inputLabel}</p>
          {section === 1 ? (
            <div className={`flex align-center`}>
              
            <div className={`${checkboxName !== 'isCheckinReport' ? 'w-300px' : 'w-220px'}`}>
              <SelectItem 
                // defaultValue={localReportHour}
                defaultValue={defaultValue}
                onChange={handleSelect}
                options={selectOptions}
                customStyles={customStyles}
                isClearable={isClearable}
                key={key}
                wording={wording}
                isMulti={isMulti}
                isSearchable={isSearchable}
                checkboxName={checkboxName}
              />
            </div>
            {checkboxName === 'isCheckinReport' && defaultValue.value !== 1 && (
              <span className="fs-13 mr-15 ml-1">{defaultValue.value === 3 || defaultValue.value === 4 ? `on Sunday ` : defaultValue.value === 5 ? 'the last day of the month ' : ''}at{" "}{localReportTime.hour}:{localReportTime.minute}</span>
            )}
            </div>
          ) : (
            <Fragment>
              <div className="w-80px">
                <SelectItem 
                  // defaultValue={localReportHour}
                  defaultValue={defaultTime.hour}
                  onChange={handleSelect}
                  options={selectOptions.hour}
                  customStyles={customStyles}
                  isClearable={isClearable}
                  key={key}
                  wording={wording.hours}
                  isMulti={isMulti}
                  isSearchable={isSearchable}
                />
              </div>
              <span className="medium mx-1">:</span>
              <div className="w-80px">
                <SelectItem 
                  defaultValue={defaultTime.minute}
                  onChange={handleSelect}
                  options={selectOptions.minute}
                  customStyles={customStyles}
                  isClearable={isClearable}
                  key={key}
                  wording={wording.minutes}
                  isMulti={isMulti}
                  isSearchable={isSearchable}
                />
              </div>
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
};

NotificationItemReports.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.bool]),
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func
};

export default NotificationItemReports;
