import React, { useEffect, useMemo, useState } from "react";
import { renderToString } from 'react-dom/server';
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import TimezonePicker from 'react-timezone';
import { components } from 'react-select';
import SelectItem from "../../elems/SelectWithValue";
import TooltipImg from '../../../img/icons/tooltip.svg'

import focusIcon from '../../../img/logo/logo_square.png';
import slackIcon from '../../../img/admin/slack-logo.png';

let visibilityOptions = [
  { label: `Everyone`, value: 1, name: 'visibility' },
  { label: `Team Members`, value: 2, name: 'visibility' },
  { label: `Private (only admins and user)`, value: 3, name: 'visibility' },
];


const customStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: state.isFocused ? '#404ff5' : '#e9eaf5',
    boxShadow: state.isFocused ? '0px 0px 20px #404FF54D' : 'none',
    border: state.isFocused ? '1px solid #404ff5' : styles.border,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    fontSize: '14px',
    height: '38px',
  }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize, };
  },
  tooltipImage: {
    width: '16px',
    height: '16px',
    marginLeft: '6px',
    marginRight: '6px',
    marginBottom: '-3px'
  }
}

const MultiValueLabel = (props) => {
  return (
    <components.MultiValueLabel {...props}>
      <span style={{ display: 'inline-flex', alignItems: 'center'}}>
        <img src={props.data.name === 'selectedSlackUsers' ? slackIcon : focusIcon} className="img-15" />
        <span className="ml-xs">{props.children}</span>
      </span>
    </components.MultiValueLabel>
  );
};

const CheckinSettingsTeam = ({
  timezone,
  onChangeTimezone,
  profiles,
  handleUsers,
  emailUsersOptions,
  slackUserAndChannelOptions,
  handleSelect,
  defaultValue,
  formData,
}) => {

  const wordingTeam = {
    placeholderId: "choose.team.member",
    placeholderMsg: "Choose team or members",
  }
  const wordingVisibility = {
    placeholderId: "choose.team.visibilty",
    placeholderMsg: "Choose visibility",
  }

  // console.log('===== defaultValue', defaultValue)
  // console.log('===== emailUsersOptions[0]', emailUsersOptions[0])
  // console.log('===== defaultValue.users', defaultValue.users)
  // console.log('===== defaultValue.visibility', defaultValue.visibility)
  // console.log('===== visibilityOptions[0]', visibilityOptions[0])

  const options = useMemo(() => {
    let focusUsersOptions;

    if (emailUsersOptions && slackUserAndChannelOptions) {
      const slackEmails = slackUserAndChannelOptions
        .filter(({ team }) => team === 3)
        .map(({ email }) => email)
        .filter(Boolean);

      focusUsersOptions = emailUsersOptions.filter(({ value }) => !slackEmails.includes(value));
    } else {
      focusUsersOptions = emailUsersOptions;
    }

    return [
      {
        label: 'Slack',
        options: slackUserAndChannelOptions
      },
      {
        label: 'Focus',
        options: focusUsersOptions
      },
    ]
  }, [emailUsersOptions, slackUserAndChannelOptions]);

  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    const unorderedUsers = (formData.selectedSlackUsers || []).concat(formData.selectedEmailUsers || []);

    const oldUsers = selectedUsers.filter(su => unorderedUsers.some(uu => uu.value === su.value));
    const newUsers = unorderedUsers.filter(uu => !oldUsers.some(ou => ou.value === uu.value));

    setSelectedUsers(oldUsers.concat(newUsers));
  }, [formData.selectedEmailUsers, formData.selectedSlackUsers]);

  return (
    <div className="flex align-center w-100">
      <div className="flex flex-column w-33 mr-1">
        <p className="fs-12 w-100 mb-xs">
          <FormattedMessage id="checkin.team" defaultMessage="Check-in Team" />
          <img
            src={TooltipImg}
            style={customStyles.tooltipImage}
            alt="More info"
            data-tooltip-id="info-tooltip" 
            data-tooltip-html={renderToString(<FormattedMessage id="checkin.team.info" defaultMessage="Choose who will receive check-ins" />)} />
        </p>
        <SelectItem 
          // defaultValue={emailUsersOptions[0]}
          defaultValue={defaultValue.users}
          value={selectedUsers}
          // defaultValue={{label: "Product", value: 2}}
          onChange={handleUsers}
          options={options}
          customStyles={customStyles}
          isClearable={false}
          key={`${profiles._id}-U`}
          wording={wordingTeam}
          isMulti={true}
          index={'selectedEmailUsers'}
          components={{ MultiValueLabel }}
        />
      </div>

      <div className="flex flex-column w-33 mr-1">
        <p className="fs-12 w-100 mb-xs">
          <FormattedMessage id="checkin.visibility" defaultMessage="Check-in Visibility" />
          <img
            src={TooltipImg}
            style={customStyles.tooltipImage}
            alt="More info"
            data-tooltip-id="info-tooltip" 
            data-tooltip-html={renderToString(<FormattedMessage id="checkin.visibility.info" defaultMessage="Choose who can view check-in answers" />)} />
        </p>
        <SelectItem 
          // defaultValue={visibilityOptions[0]}
          defaultValue={defaultValue.visibility}
          value={formData.visibility}
          // defaultValue={{label: 'TEam x', value: 2}}
          onChange={handleSelect}
          options={visibilityOptions}
          customStyles={customStyles}
          isClearable={false}
          key={`visibility-U`}
          wording={wordingVisibility}
          isMulti={false}
          index={'visibility'}
        />
      </div>

      <div className="flex flex-column w-33 timezone-picker z-20000">
        <p className="fs-12 w-100 mb-xs">
          <FormattedMessage id="admin.dashboard.edit.timezone" defaultMessage="Timezone" />
        </p>
        <TimezonePicker
          name="timezone"
          value={timezone}
          onChange={timezone =>
            onChangeTimezone(timezone, 'timezone')
          }
          inputProps={{
            placeholder: { timezone },
            name: 'timezone'
          }}
        />
      </div>

    </div>
  );
};

CheckinSettingsTeam.propTypes = {
  onChangeTimezone: PropTypes.func.isRequired,
};

export default CheckinSettingsTeam;
