import React, { useState, Fragment, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Menu from '../layout/Menu';
import { connect } from 'react-redux';
import MainMenu from '../layout/Sidebar';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import { getTeamAnswersById, clearStandups } from '../../actions/standupAnswer';
import { getTeamOkrsById, clearOkrs } from '../../actions/okr';
import { getCompanyProfiles } from '../../actions/profile';
import { getUserCompany, getTeamById } from '../../actions/company';
import MainWidget from '../utils/MainWidget';
import CheckinItem from '../checkins/noState/CheckinItem';
import TeamMenu from './TeamMenu';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/1.jpg';
import { checkinCopy } from '../../utils/wording/checkins';
import { FormattedMessage } from 'react-intl';
import Moment from 'react-moment';

const TeamCheckins = ({
  clearStandups,
  clearOkrs,
  getTeamOkrsById,
  getTeamById,
  getUserCompany,
  getCompanyProfiles,
  okr: { okrs },
  auth: { user, sidebar },
  company: { company, team },
  standupAnswer: { standupAnswers, loading },
  getTeamAnswersById,
  profile: { profiles },
  match,
}) => {
  useEffect(() => {
    getUserCompany();
    getTeamAnswersById(match.params.id);
    getCompanyProfiles();
    getTeamById(match.params.id);
    getTeamOkrsById(match.params.id);

     // Clear state
     return () => {
        clearStandups();
        clearOkrs();
      }
  }, [getUserCompany, getTeamAnswersById, getCompanyProfiles, getTeamOkrsById, match.params.id, getTeamById]);


  const noCheckinsMessageTeam = {
    title: <FormattedMessage id="company.nocheckins.user.title" defaultMessage="No Check-ins" />,
    msg: 
    <FormattedMessage id="company.checkins.user.msg" defaultMessage={`${team && team.name} has not finished check-ins yet.`} />
  };

  // Removing duplicates in answers array based on the object key 'standup' - to send only unique standup items to StandupTeamItem
  // Берем данные из не редакса standupAnswers
  let standupAnswersUnique = standupAnswers ? (standupAnswers.reduce((acc, current) => {
    const x = acc.find(item => item.standup === current.standup);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, [])) : [];

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {/* {user && user.department ? (
          <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user && user.isAdmin && true} activeIndex={7} />
        ) : (
          <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} activeIndex={7} />
        )} */}

        {user && !loading && (
          <MainMenu activeIndex={7} />
        )}

        <UserNav />
        
          <Fragment>
            {loading || !standupAnswers || !user || !company || !team || !okrs ? (
              <Spinner />
            ) : (
              <Fragment>
                {/* <div className="main main-dashboard"> */}
                <div className={`${sidebar === 1 ? 'main main-dashboard ' : 'short_menu__ml__okrs ma-w90 pt-2'} mt-3`}>
                  <h5>
                    <FormattedMessage id="team.header.checkins" defaultMessage={`${team.name} Check-ins`} />
                  </h5>
                  <TeamMenu teamId={team._id} companyIndex={1} />
                  <div className="checkins">
                    <div className="mb-4">
                        {okrs.length > 0 && (
                          okrs.map(okr => (
                            okr.isMain && okr.type === 2 && <MainWidget key={`main-${okr._id}`} okr={okr} />
                            )
                          ))} 
                      </div>

                    {company.plan !== 1 ? (
                      <Fragment>
                        {standupAnswers.length === 0 ? (
                          <div className="mt-5">
                            <NoContent 
                              message={noCheckinsMessageTeam}
                              picture={updateImg}
                              alt={'No checkins'}
                            />
                          </div>
                        ) : (
                          <Fragment>
                            {standupAnswersUnique.length > 0 && (
                              standupAnswersUnique.map(
                                (standupAnswerUnique, index) => (
                                  <Fragment key={`fragment-checkin-${index}`}>
                                    <h3 className="fs-2 pl-2 pt-2 pb-1" key={`date-${index}`}>
                                      <Moment format="D MMM" key={`date-moment-${index}`}>{standupAnswerUnique.standupDate}</Moment>
                                    </h3>

                                    <CheckinItem
                                      key={`checkins-${standupAnswerUnique._id}-${index}`}
                                      standupAnswers={standupAnswers.filter(
                                        standupAnswer =>
                                          standupAnswer.standup.toString() ===
                                          standupAnswerUnique.standup.toString()
                                      )}
                                      standupDate={standupAnswerUnique.standupDate}
                                    />
                                  </Fragment>
                                )
                              )
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      <NoContent 
                        message={checkinCopy.updatePlanMsg}
                        picture={updateImg}
                        alt={'Update plan'}
                        admin={user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0}
                      />
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

TeamCheckins.propTypes = {
  clearStandups: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  getTeamAnswersById: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  clearOkrs: PropTypes.func.isRequired,
  getTeamOkrsById: PropTypes.func.isRequired,
  getTeamById: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  standupAnswer: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  standupAnswer: state.standupAnswer,
  okr: state.okr
});

export default connect(
  mapStateToProps,
  { getTeamAnswersById, getUserCompany, getCompanyProfiles, getTeamOkrsById, getTeamById, clearStandups, clearOkrs }
)(TeamCheckins);
