import React from 'react';
import Fs2Title from '../../elems/Fs2Title';
import { FormattedMessage } from 'react-intl';


let managerTitle = <FormattedMessage id="managerTitle" defaultMessage="About Manager"/>;
let careerTitle = <FormattedMessage id="careerTitle" defaultMessage="Career development"/>;
let startersTitle = <FormattedMessage id="startersTitle" defaultMessage="Conversation starters"/>;
let satisfactionTitle = <FormattedMessage id="satisfactionTitle" defaultMessage="Job satisfaction"/>;
let otherTitle = <FormattedMessage id="otherTitle" defaultMessage="Other"/>;
let teamTitle = <FormattedMessage id="teamTitle" defaultMessage="Team and company"/>;
let balanceTitle = <FormattedMessage id="balanceTitle" defaultMessage="Work-life"/>;

// https://github.com/VGraupera/1on1-questions
let questionsEn = [
  {
    title: managerTitle,
    questions: [
      'Are there any obstacles I can remove for you?',
      'As your manager, what would you like me to stop, start, or continue doing?',
      'At what point in the past week were you most frustrated with or discouraged by your work? What can I do to help you manage that?',
      'Do you feel you’re getting enough feedback? Why/why not?',
      'Do you have any feedback for me?',
      'Do you think that you receive enough feedback? Is feedback helpful for your personal development? What can I do to help you get the feedback you want?',
      'How can I better support you?',
      'How can I make your days more fulfilling?',
      'How could I do a better job communicating with you?',
      'How do you prefer to receive feedback?',
      'How might I make this project more challenging or interesting for you?',
      'I’d like to improve as a manager and I could really use your help. Next week, would you be willing to share some feedback on one to two things you think I could do better as your manager?',        'If you were me, what changes would you make?',
      'I’m trying to make my 1-on-1s better and would appreciate your honest feedback on this one — what did you like about it, and what could be improved?',
      'I’ve noticed that our last several 1-on-1s have stayed pretty surface. What are your honest impressions of this meeting? What could we be doing differently or better?',
      'What additional resources can I provide for you between now and the next time we meet?',
      'What are your impressions of our 1-on-1s? What could we do differently or better?',
      'What are your thoughts on my changes?',
      'What aspect of your job you would like more help or coaching?',
      'What aspects of your work would you like more or less direction from me?',
      'What can I be doing better to help you in your job?',
      'What can I do as a manager to make your work easier?',
      'What can I do better or differently as your manager to support you?',
      `What can I do for you that I'm not?`,
      'What can I do to help remove obstacles?',
      'What can I do to help you achieve you current goals and priorities?',
      'What can I do to help you enjoy your work more or remove roadblocks to progress?',
      'What can I do to help you?',
      'What can I do to make things more manageable?',
      'What can I do to make your job easier?',
      'What could I do as a manager to make your work easier?',
      'What do you like about my management style? What do you dislike?',
      'What do you think I should know about the project, but might not?',
      'What have your past managers done that you’d like me to also do or not do?',
      'What is something I could do better? What feedback do you have for me?',
      'What is something I could have done better? What are the situations that I could have helped more but didn’t?',
      'What is the biggest challenge you are currently facing? How can I help with that?',
      'What is the one thing that you need the most from me?',
      'What is the percentage of my involvement in your daily tasks? Would you prefer more or less?',
      'What would you like to know about me?',
      'What would you like to see change about these discussions? How could we make them more useful for you?',
      'What’s your favorite thing I do as a manager of this team I should keep doing?',
      'Where do you think I can be most helpful?',
      'Which areas would you like more or less direction from me on your work?',
      'Would you like more coaching? What aspect of your job do you like more help and coaching on?',
    ]
  },
  {
    title: careerTitle,
    questions: [
      'Are there any events or training you’d like to attend to help you grow your skills?',
      'Are there any projects you’d really like to work on if you were given the opportunity?',
      'Could you see yourself making progress on more of your goals here? What would need to change to do so?',
      'Do you feel like you are learning at work? What are the new things you learned lately? What are the areas you want to learn about?',
      'Do you feel like you’re making progress on your big goals here? Why or why not?',
      'Do you feel we’re helping you advance your career at a pace you would like?',
      'Do you have the tools you need to reach your goals?',
      'During this meeting you’ve mentioned that you’d like to pursue X. What steps can you take toward that before our next 1-on-1?',
      'Have you ever felt undervalued here?',
      'Have you given any more thought to your long-term goals since our last meeting? What are your latest thoughts?',
      'How do you feel you are progressing in your career?',
      'How do you think about your progress on your big goals? What needs to be done to move towards the goals? What can we do to help?',
      "How do you think you did on your performance review this year? What do you think the results will be?",
      'How is your current work helping or hurting your professional development?',
      'If you were to create your ideal position, how would it differ from what you are currently doing?',
      'Imagine it’s two years from now, and things have gone well: What has been your role in that? What does your role look like?',
      'Is there a different way we can develop your skills outside of traditional training?',
      'Is there an aspect of your job you would like more help or coaching?',
      'Tell me about something new you learned since we met last.',
      'To help identify and clarify the goal: What do you want to achieve? What will you do to achieve it? When will you do it? Who do you need to involve? When should you see results?',
      'What about this goal is important to you and what will be different when you achieve the goal?',
      'What actions will you take before our next 1-on-1 to make progress on X? (Also discuss and agree on actions you will take to help.)',
      'What additional training or education would you like?',
      'What are some of the work projects you’re most proud of, and what do you think you might want to do next?',
      'What are the projects you would be interested in working on next?',
      'What are two to three new skills you’d like to learn on the job? What about those skills interests you?',
      'What are your big dreams in life? Are you making progress on them?',
      'What are your goals (for our group for you personally)',
      'What are your long term goals? Have you thought about them?',
      'What are your super powers? What powers would you like to develop?',
      'What areas of your job do you find difficult that getting better skills at would help?',
      'What big questions do you have about your career opportunities in the future?',
      'What can I do to take action or make progress on what we talked about today?',
      'What can you do to take action or make progress on what we talked about today?',
      'What capabilities do you want to develop?',
      'What development areas do you want to work on in the coming weeks?',
      'What do you see as the next step in your career?',
      'What do you want to be doing in 5 years? 10 years? 3 years?',
      'What do you want to do in your next job?',
      'What else can I be doing to help you grow/advance in your career?',
      'What goals have you set for your career?',
      'What kind of training or experience would be most helpful to you right now?',
      'What opportunities have you had recently to learn something new?',
      'What other roles here could you see yourself in down the line? Or what areas would you like to explore?',
      'What parts of your job would you like to deepen your skills in or get additional training in?',
      'What professional goals would you like to accomplish in the next 6 to 12 months, and what makes you say that?',
      'What skills are required to reach your goals?',
      'What skills do you have that you think are underutilized?',
      'What skills would you like to develop right now?',
      'What sort of progress have you made on the next steps we discussed last time?',
      'What work are you doing here that you feel is most in line with your long term goals?',
      'What would make this your perfect job?',
      'What would you like to learn about in the future?',
      'What’s one thing we could do today to help you with your long term goals?',
      'Where do you see your career in 1 year, 3 years? What can you do to make sure you reach those goals? How can we as HR help you to reach those goals (assuming the goals relate to the company)?',
      'Where do you see your career in the next (2/5/10) years?',
      'Where do you see yourself in three years? Five years?',
      'Which career or development goals do you feel like you’re not able to focus on right now?',
      'Which part of the work here do you feel as most relevant to your long-term goals? What kinds of projects do you want to take part in to move toward your goals?',
      'Who in the company would you like to learn from? What do you want to learn?',
    ]
  },
  {
    title: startersTitle,
    questions: [
      'Are you on track to meet the deadline?',
      'Hey, what’s going on?',
      'How are you? How is life outside of work?',
      'How are your parents/grandparents? Where do they live? Do you visit them?',
      'How is your family?',
      'How was your weekend?',
      'How’s it going?',
      'If around a holiday: Do you celebrate [Holiday]? If so, can I ask what are you plans?',
      'If they have children: How is [name of child] doing? (Ask something related to their age like starting school, playing sports, or other interests.)',
      'I’ve noticed you’re a little quieter than usual. Is there anything you’d like to talk about?',
      'So, what’s on your mind? (or Anything on your mind?)',
      'Tell me a story...',
      'Tell me about anything you stumbled over.',
      'Tell me about last week.',
      'Tell me about what you’ve been working on.',
      'Tell me about your family/weekend/ activities?',
      'Tell me about your week – what’s it been like?',
      'What can I do to make your day better?',
      'What do you like to do in your free time? What are your hobbies?',
      'What have you been doing for fun lately?',
      'What would you like to focus on at this meeting?',
      'What would you like to start with?',
      `What's something I don't know, but should?`,
      'Who do you really admire? Why? (People often admire those they want to become)',
      'Would you like to walk today, or go somewhere else outside the office?',
      'Would you update me on Project X?',
    ]
  },
  {
    title: satisfactionTitle,
    questions: [
      'Are you happy here? What makes you say that?',
      'Are you happy with your recent work? Why or why not?',
      'Can you name three things we can do to help so you can enjoy your job more?What is the best accomplishment you had since you are here? Do you feel appreciated for it?',
      'Do you feel challenged at work? Are you learning new things?',
      `Do you feel like you're growing in your role? What makes you say that?`,
      'Do you feel over-worked, under-worked, or just the right workload?',
      'Do you think our company is loyal to its employees? Why or why not?',
      'Happiness level, 1 to 10?',
      'How are you feeling about the project?',
      'How are you feeling about your role?',
      'How did that affect you?',
      'How did that make you feel?',
      'How do you feel your work/life balance is right now?',
      'How do you think of your current workload and how are you coping with them?',
      'How do you think that least favorite thing affects your overall performance?',
      'How many hours a day do you feel you’re productive? How could we help you be more productive?',
      'If you could change what happened, how would you alter it?',
      'If you could work on anything for the next month, what would it be? What makes you say that?',
      'In our last 1-on-1 you mentioned that you’d like to grow in X — how has that been going?',
      'In our last 1-on-1 you mentioned you were frustrated by X and wanted to try Y as a solution. How has that been going?',
      'In what ways does your current position allow you to use your skills and talents?',
      'I’ve noticed X about the project… Can you help me understand that better? Talk me through your process.',
      'Tell me about what you’ve learned on this project.',
      'What are you most excited about?',
      'What are you most worried about?',
      'What are your biggest concerns about your current project(s)?',
      'What aspect of this project has been particularly interesting for you?',
      'What could I do to make you enjoy your work more?',
      'What did you like most/least about that?',
      'What do you enjoy the most in your current role?',
      'What do you feel is your greatest accomplishment here?',
      `What do you feel like you're learning from this project?`,
      'What do you like most about working on our team?',
      'What do you like most about your job today?',
      'What do you like the least?',
      'What do you need or want to accomplish and by when?',
      'What do you think caused that to happen?',
      `What do you think has gone well? What do you think you could have done better? What, if anything, would you like to do, but haven't been able to?`,
      'What do you think you could be doing differently?',
      'What drives you? What motivates you to come to work each day?',
      'What feedback/praises have you been getting about your current priorities?',
      'What frustrates you about the project?',
      'What have you tried so far to make progress on it?',
      'What interests you about the project(s) you’re currently working on, and why?',
      'What is your favorite/least favorite thing about your work right now?',
      'What talents do you have that you feel we are fully utilizing? Which of your talents are we not fully leveraging?',
      'What was that experience like for you?',
      'What would be the most helpful thing for you to take away from this conversation in order to make progress on it?',
      'What would convince you to leave for a job somewhere else?',
      'What would make you leave this job for another?',
      'What’s a recent situation you wish you handled differently? What would you change?',
      'What’s an area of your work you want to improve?',
      'What’s one thing about your job that, if we fixed, would make you never want to leave?',
      'What’s one thing that could make your work more satisfying, and why?',
      'What’s the best part of your job?',
      'What’s the worst part about your job?',
      'What’s working well for you in your current position?',
      'What’s your No. 1 problem right now? How are you feeling about it?',
      'When was the time you enjoyed working here the most?',
      'Which areas make you feel like your hands are tied or you are unable to reach your full potential?',
      'Which areas would you like to spend more time on and why?',
      'Which parts of your project are unclear or confusing?',
    ]
  },
  {
    title: otherTitle,
    questions: [
      'As a kid, what did you want to be when you grew up?',
      'How are things going for you outside of work?',
      'If you had millions of dollars, what would you do every day?',
      'What did you do for fun in the past that you haven’t had as much time for lately?',
      'What haven’t you tried yet?',
      'What ideas can you bring in from past successes?',
      'What questions do you have about the project?',
      'What would you be doing right now if we weren’t having this meeting? How do you feel about being taken away from that task?',
      'What’s one thing we could change about work for you that would improve your personal life?',
    ]
  },
  {
    title: teamTitle,
    questions: [
      'Are there any aspects of our culture you wish you could change?',
      'Are there any meetings or discussions you feel you should be a part of that you’re not? Are you included in any you don’t want to be a part of?',
      'Are there any problems or issues that I should know about?',
      'Are there any roles in the company you’d like to learn more about?',
      'Are you uncomfortable giving any of your peers constructive criticism? If so, why?',
      'Can you share some of the details around that particular issue? (Who was involved? Where? When? For how long?)',
      'Could you tell me a little more about that?',
      'Do you feel adequately supported by other team members? What makes you say that?',
      'Do you feel like you’re on the same page with the team? How often do you think you need meetings to ensure you stay that way?',
      'Do you have a clear understanding of the new goals and expectations? What makes you say that?',
      'Do you help other members on the team? Do others help you when you need it?',
      'Has anyone on the team ever made you feel uncomfortable? What happened?',
      'How are you feeling about the company’s future overall? What makes you say that?',
      'How are you feeling about the recent news? Why do you say that?',
      'How could we be more creative or innovative as a company?',
      'How could we change our team meetings to be more effective?',
      'How is the new situation/development affecting your work? What could be getting in the way of your being effective?',
      'How well do you feel like you relate to you coworkers? Do you view them as friends, acquaintances, or strangers?',
      'How would you describe the division of work among team members?',
      `How would you describe the personality of the team? What sort of person would be a good fit here? What sort of person would add something we're currently missing?`,
      'How would you rate our communication as a team?',
      `How would you say we're doing at working together as team? What makes you say that?`,
      'If we could improve in any way, how would we do it?',
      'If you were CEO, what’s the first thing you’d change?',
      'Is any part of your project unclear or confusing?',
      'Is everyone pulling their weight on the team?',
      'Is there a situation you’d like my help with?',
      'Is there anybody in the team that you find it difficult to work with? Can you tell me why?',
      'Is there anything blocking you from getting your work done?',
      'Is there anything that’s slowing you down from getting your work done?',
      'Is there anything you’d like to see change about the team, and if so, why?',
      'Is your job what you expected when you accepted it?',
      'What additional resources from me would be helpful for you as you solve this problem?',
      'What are some possible ways to get the solution you need?',
      'What are some ways we could improve at teamwork? What makes you say that?',
      'What are we doing that you think we should stop doing, and why?',
      'What are we not doing that we should be doing? What makes you say that?',
      'What are your favorite parts about our culture?',
      'What are your next steps to make progress on this problem?',
      'What are your top three motivators?',
      'What area of the company would you like to learn more about?',
      'What areas are ahead of schedule?',
      'What characteristics make someone a good fit for our team? How would you look for those characteristics in an interview?',
      `What concerns do you have about the change that haven't been addressed?`,
      'What could you have done differently?',
      'What do you do when you get stuck on something? What is your process of getting unstuck? Who is the team member you turn to for help?',
      'What do you like about working here? What’s not fun about working here?',
      'What do you think about the amount of feedback in our team? When do others give feedback to you? Would you like to hear more feedback from other team member and me?',
      'What do you think about _________?',
      'What do you think are the key skills for your role? How would you rate yourself for each of them?',
      'What do you think would help us work together better?',
      'What do you think you can do to fix that problem?',
      'What excites you about our business? The company? What concerns you?',
      'What is the #1 Problem at our company? Why?',
      'What is the company not doing today that we should do to better compete in the market?',
      'What kind of communication or information do you wish you had more of?',
      'What part of your job do you wish you didn’t have to do?',
      'What sort of resources could you use right now to make things more manageable?',
      'What would you like to learn more about this year?',
      'What would you like to see change here? Why do you say that?',
      'What’s an inexpensive thing we could do to improve our office environment for the team?',
      'What’s going well and not so well with the new situation/development? Why do you think this might be happening?',
      'What’s one thing we should change about how our team works together?',
      'What’s one thing we’d be crazy not to do in the next quarter to improve our product?',
      'What’s something you feel is undervalued that you contribute to the team?',
      'What’s the biggest opportunity we’re missing out on?',
      'What’s the biggest thing you’d like to change about our team?',
      `What’s the No. 1 problem with our organization, and what do you thin,k's causing it?`,
      `What’s the No. 1 problem with our organization? Why?`,
      `What’s the number one problem within our organization? Why?`,
      `Where do you think we can do better?`,
      `Which areas would you would like more feedback on?`,
      `Which company values do you like the most? Which the least? Why?`,
      `Who are you friends with at work? (Shown to be a key to enjoying your job)`,
      `Who do you admire within the company and why?`,
      `Who has really been kicking ass lately?`,
      `Who inspire you in the team? Whose opinions do you respect? What have they done?`,
      `Who is kicking ass on the team? What have they done?`,
      `Who is really kicking ass in the company? Who do you admire?`,
      `Who on the team do you have the most difficulty working with? Why?`,
      `Who would you like to work more often with? Why?`,
      `Whom in the team do you want to learn from? Whom do you get valuable feedback from?`,
      `Why do you think [employee who recently quit] left? What did they tell you?`,
    ]
  },
  {
    title: balanceTitle,
    questions: [
      `How are you feeling about work?`,
      `How are you going to approach this?`,
      `How are you planning to balance work and personal life this year?`,
      `How can I help…? (be more productive/happier at work/enjoy work more/etc)`,
      `How do you feel about your work/life balance?`,
      `How is your workload right now?`,
      `If you could change one thing about your job today, what would you change?`,
      `Is there anything that you need from me?`,
      `Is there anything that you’re struggling with?`,
      `Is there anything you’d like to be doing on your own time to relieve stress that you’re not getting to? How can I help you achieve those personal goals?`,
      `What about work is frustrating?`,
      `What about your job is most satisfying for you?`,
      `What are 3 things could we do to improve your productivity if money was no object?`,
      `What are the biggest time wasters for you each week?`,
      `What are the most important things you will focus on before we meet next?`,
      `What are you doing for yourself outside of work?`,
      `What are your most significant accomplishments since we last met?`,
      `What areas of your work are you confident about?`,
      `What could we change about work that would improve the rest of your life?`,
      `What do you do when you feel low energy or unmotivated?`,
      `What does your ideal weekday look like?`,
      `What is an ideal, productive day at work for you? Walk me through the day…`,
      `What is the most important thing we need to discuss today?`,
      `What makes you excited and motivated to work on a project?`,
      `What obstacles are you encountering right now?`,
      `What part of the day do you have the most energy and focus? When do you have the least? What changes could we make to your work schedule to accommodate this?`,
      `What part of your work routine do you find is working best? What area do you want to improve?`,
      `What questions do you have about this project?`,
      `What stands in your way?`,
      `What suggestions do you have?`,
      `What was difficult this week? what have you learned?`,
      `What were your biggest time wasters or roadblocks last week or the week before?`,
      `What worries you?`,
      `What worries you? What’s on your mind?`,
      `What, if anything, did you used to do that you find you don’t have time for right now?`,
      `What’s an inexpensive thing we could do to improve our office environment?`,
      `When is your next vacation?`,
      `When was your last vacation?`,
      `When you get stuck on something, what is your process for getting unstuck? Who do you turn to for help?`,
      `Which part of the day do you feel most productive? When do you feel that your energy and focus are at the lowest level? What are the changes that can be made so you can take the best out of a work day?`,
    ]
  }
];


let questionsRu = [
  {
    title: managerTitle,
    questions: [
      'Есть ли какие-либо препятствия, которые я могу устранить для вас?',
      'Как ваш руководитель, что бы вы хотели, чтобы я прекратил, начал или продолжил делать?',
      'В какой момент прошлой недели вы были больше всего расстроены или обескуражены своей работой? Что я могу сделать, чтобы помочь вам справиться с этим?',
      'Чувствуете ли вы, что получаете достаточно обратной связи? Почему/почему нет?',
      'Есть ли у вас какие-либо замечания для меня?',
      'Считаете ли вы, что получаете достаточно обратной связи? Полезна ли обратная связь для вашего личного развития? Что я могу сделать, чтобы помочь вам получить обратную связь, которую вы хотите?',
      'Как я могу лучше помочь вам?',
      'Как я могу сделать ваши дни более насыщенными?',
      'Как я могу лучше общаться с вами?',
      'Как вы предпочитаете получать обратную связь?',
      'Как я могу сделать этот проект более сложным или интересным для вас?',
      'Я хотел бы совершенствоваться как менеджер, и мне бы очень пригодилась ваша помощь. На следующей неделе, не могли бы вы поделиться со мной отзывами об одной-двух вещах, которые, по вашему мнению, я мог бы сделать лучше как ваш менеджер?', 
      "Если бы вы были на моем месте, какие изменения вы бы внесли?",
      "Я стараюсь сделать свои встречи 1 на 1 лучше и буду признателен за ваш честный отзыв об этой встрече - что вам в ней понравилось, а что можно улучшить?",
      'Я заметил, что наши последние несколько встреч 1 на 1 были довольно поверхностными. Каковы ваши искренние впечатления от этой встречи? Что мы могли бы сделать по-другому или лучше?',
      "Какие дополнительные ресурсы я могу предоставить вам в период до нашей следующей встречи?",
      'Каковы ваши впечатления от наших встреч 1 на 1? Что мы могли бы сделать по-другому или лучше?',
      'Что вы думаете о моих изменениях?',
      'В каком аспекте своей работы вы хотели бы получить больше помощи или коучинга?',
      'В каких аспектах своей работы вы хотели бы получить от меня больше или меньше указаний?',
      'Что я могу делать лучше, чтобы помочь вам в вашей работе?',
      'Что я могу сделать как менеджер, чтобы облегчить вашу работу?',
      'Что я могу сделать лучше или по-другому как ваш менеджер, чтобы поддержать вас?',
      `Что я могу сделать для вас, чего я не делаю?`,
      'Что я могу сделать, чтобы помочь устранить препятствия?',
      'Что я могу сделать, чтобы помочь вам достичь ваших текущих целей и приоритетов?',
      'Что я могу сделать, чтобы помочь вам получать больше удовольствия от работы или устранить препятствия на пути к прогрессу?',
      'Что я могу сделать, чтобы помочь вам?',
      'Что я могу сделать, чтобы сделать все более управляемым?',
      'Что я могу сделать, чтобы облегчить вашу работу?',
      'Что я как руководитель могу сделать, чтобы облегчить вашу работу?',
      'Что вам нравится в моем стиле управления? Что вам не нравится?',
      'Что, по вашему мнению, я должен знать о проекте, но, возможно, не знаю?',
      "Что бы вы хотели, чтобы я также делал или не делал?",
      'Что я мог бы сделать лучше? Что вы можете мне посоветовать?',
      'Что я мог бы сделать лучше? В каких ситуациях я мог бы помочь больше, но не сделал этого?',
      'С какой самой большой проблемой вы сейчас сталкиваетесь? Как я могу помочь с этим?',
      'Что вам больше всего нужно от меня?',
      'Каков процент моего участия в ваших повседневных делах? Вы бы предпочли больше или меньше?',
      'Что бы вы хотели знать обо мне?',
      'Что бы вы хотели, чтобы изменилось в этих обсуждениях? Как мы могли бы сделать их более полезными для вас?',
      'Что вам больше всего нравится из того, что я делаю как менеджер этой команды, и что я должен продолжать делать?',
      'Как вы думаете, где я могу быть наиболее полезен?',
      'В каких областях вы хотели бы получить от меня больше или меньше указаний по вашей работе?',
      'Хотели бы вы больше наставничества? В каком аспекте своей работы вы хотели бы получить больше помощи и наставничества?',
    ],
  },
  {
    title: careerTitle,
    questions: [
      'Есть ли мероприятия или тренинги, которые вы хотели бы посетить, чтобы повысить свою квалификацию?',
      'Есть ли проекты, над которыми вы хотели бы поработать, если бы у вас была такая возможность?',
      "Могли бы вы увидеть, как вы добиваетесь прогресса в достижении своих целей здесь? Что для этого нужно изменить?",
      'Чувствуете ли вы, что учитесь на работе? Что нового вы узнали за последнее время? Какие области вы хотите изучить?',
      'Чувствуете ли вы, что добиваетесь прогресса в достижении ваших больших целей здесь? Почему да или почему нет?',
      'Чувствуете ли вы, что мы помогаем вам продвигаться по карьерной лестнице в том темпе, в котором вы хотели бы?',
      'Есть ли у вас инструменты, необходимые для достижения ваших целей?',
      'Во время этой встречи вы упомянули, что хотели бы достичь Х. Какие шаги вы можете предпринять для этого до следующей встречи 1 на 1?',
      'Чувствовали ли вы себя здесь недооцененным?',
      'Задумывались ли вы о своих долгосрочных целях после нашей последней встречи? Какие у вас последние мысли?',
      'Как вы считаете, насколько вы продвинулись в своей карьере?',
      'Что вы думаете о своем прогрессе в достижении больших целей? Что нужно сделать, чтобы продвинуться к поставленным целям? Что мы можем сделать, чтобы помочь?',
      "Как, по-вашему, вы справились с аттестацией в этом году? Как вы думаете, каковы будут результаты?",
      "Как ваша нынешняя работа помогает или мешает вашему профессиональному развитию?",
      "Если бы вы создали свою идеальную должность, чем бы она отличалась от того, чем вы занимаетесь сейчас?",
      'Представьте, что прошло два года, и дела пошли хорошо: Какова была ваша роль в этом? На что похожа ваша роль?',
      'Есть ли другой способ развития ваших навыков, помимо традиционного обучения?',
      'Есть ли аспект вашей работы, в котором вы хотели бы получить больше помощи или наставничества?',
      'Расскажите мне о чем-то новом, что вы узнали с момента нашей последней встречи?',
      'Чтобы помочь определить и прояснить цель: чего вы хотите достичь? Что вы будете делать для достижения цели? Когда вы это сделаете? Кого вам нужно привлечь? Когда вы увидите результаты?',
      'Что в этой цели важно для вас и что изменится, когда вы достигнете цели?',
      "Какие действия вы предпримете до нашей следующей встречи 1 на 1, чтобы добиться прогресса в достижении цели X? (Также обсудите и согласуйте действия, которые вы предпримете, чтобы помочь)",
      'Какую дополнительную подготовку или образование вы хотели бы получить?',
      'Какими рабочими проектами вы больше всего гордитесь, и что вы думаете делать дальше?',
      'Над какими проектами вам было бы интересно работать дальше?',
      'Какие два-три новых навыка вы хотели бы освоить на работе? Что в этих навыках вас интересует?',
      'Каковы ваши большие мечты в жизни? Добиваетесь ли вы прогресса в их осуществлении?',
      'Каковы ваши цели (для нашей группы и для вас лично)',
      'Каковы ваши долгосрочные цели? Думали ли вы о них?',
      'Каковы ваши суперспособности? Какие способности вы хотели бы развить?',
      "Какие области вашей работы вызывают у вас затруднения, в которых вам помогло бы совершенствование навыков?",
      'Какие большие вопросы у вас есть по поводу ваших карьерных возможностей в будущем?',
      "Что я могу сделать, чтобы предпринять действия или добиться прогресса в том, о чем мы сегодня говорили?",
      'Что вы можете сделать, чтобы предпринять действия или добиться прогресса в том, о чем мы сегодня говорили?',
      'Какие способности вы хотите развить?',
      'Над какими областями развития вы хотите поработать в ближайшие недели?',
      'Каким вы видите следующий шаг в своей карьере?',
      'Чем вы хотите заниматься через 5 лет? 10 лет? 3 года?',
      'Чем вы хотите заниматься на следующей работе?',
      'Что еще я могу сделать, чтобы помочь вам расти/продвигаться по карьерной лестнице?',
      'Какие цели вы поставили перед собой в карьере?',
      'Какое обучение или опыт были бы наиболее полезны для вас в данный момент?',
      'Какие у вас были возможности научиться чему-то новому в последнее время?',
      'На каких еще должностях вы могли бы себя видеть в будущем? Или какие области вы хотели бы изучить?',
      "В каких областях своей работы вы хотели бы углубить свои навыки или пройти дополнительное обучение?",
      "Какие профессиональные цели вы хотели бы достичь в ближайшие 6-12 месяцев, и что заставляет вас так думать?",
      'Какие навыки необходимы для достижения ваших целей?',
      'Какими навыками вы обладаете, которые, по вашему мнению, недостаточно используются?',
      'Какие навыки вы хотели бы развить прямо сейчас?',
      'Какого прогресса вы достигли в следующих шагах, которые мы обсуждали в прошлый раз?',
      'Какую работу вы выполняете здесь, которая, по вашему мнению, наиболее соответствует вашим долгосрочным целям?',
      'Что сделало бы эту работу вашей идеальной?',
      'Чему бы вы хотели научиться в будущем?',
      "Что мы могли бы сделать сегодня, чтобы помочь вам в достижении ваших долгосрочных целей?",
      'Где вы видите свою карьеру через 1 год, 3 года? Что вы можете сделать, чтобы достичь этих целей? Как мы, HR, можем помочь вам в достижении этих целей (при условии, что цели относятся к компании)?',
      'Где вы видите свою карьеру в ближайшие (2/5/10) лет?',
      'Где вы видите себя через три года? Через пять лет?',
      'На каких карьерных целях или целях развития вы чувствуете, что сейчас не можете сосредоточиться?',
      'Какая часть работы здесь кажется вам наиболее соответствующей вашим долгосрочным целям? В каких проектах вы хотели бы принять участие, чтобы продвинуться к своим целям?',
      'У кого из сотрудников компании вы хотели бы учиться? Чему вы хотите научиться?',
    ]
  },
  {
    title: startersTitle,
    questions: [
      'Вы укладываетесь в сроки?',
      'Привет, что происходит?',
      'Как дела? Как жизнь вне работы?',
      'Как поживают ваши родители/бабушка и дедушка? Где они живут? Навещаешь ли ты их?',
      'Как ваша семья?',
      'Как прошли выходные?',
      'Как дела?',
      'Если вокруг праздник: Отмечаете ли вы [праздник]? Если да, могу я спросить, какие у вас планы?',
      'Если у них есть дети: Как дела у [имя ребенка]? (Спросите что-нибудь, относящееся к их возрасту, например, начало учебы в школе, занятия спортом или другие интересы)',
      'Я заметил, что вы немного тише, чем обычно. Может быть, ты хочешь о чем-то поговорить?',
      'Ну, что у тебя на уме? (или Что тебя сейчас волнует?)',
      'Расскажите мне историю...',
      'Расскажите мне о чем-нибудь, о что вы споткнулись',
      'Расскажи мне о прошлой неделе',
      'Расскажите мне о том, над чем вы работали',
      'Расскажите мне о вашей семье/выходных/занятиях?',
      'Расскажите мне о своей неделе - какой она была?',
      'Что я могу сделать, чтобы ваш день стал лучше?',
      'Что вы любите делать в свободное время? Какие у вас хобби?',
      'Что вы делали для развлечения в последнее время?',
      'На чем бы вы хотели сосредоточиться на этой встрече?',
      'С чего бы вы хотели начать?',
      `Что такое, чего я не знаю, но должен знать?`,
      'Кем вы действительно восхищаетесь? Почему? (Люди часто восхищаются теми, кем они хотят стать)',
      'Хотели бы вы сегодня прогуляться или сходить куда-нибудь за пределы офиса?',
      'Не могли бы вы проинформировать меня о проекте X?',
    ]
  },
  {
    title: satisfactionTitle,
    questions: [
      'Вы счастливы здесь? Почему вы так считаете?',
      'Довольны ли вы своей недавней работой? Почему или почему нет?',
      'Можете ли вы назвать три вещи, которые мы можем сделать, чтобы вам больше нравилась ваша работа? Какое самое лучшее достижение вы сделали за время работы здесь? Чувствуете ли вы благодарность за это?',
      'Чувствуете ли вы вызов на работе? Учитесь ли вы чему-то новому?',
      'Чувствуете ли вы, что растете в своей роли? Почему вы так считаете?',
      'Чувствуете ли вы себя перегруженным работой, недогруженным или просто в меру загруженным?',
      'Считаете ли вы, что наша компания лояльна к своим сотрудникам? Почему или почему нет?',
      'Уровень счастья, от 1 до 10? Почему?',
      'Как вы относитесь к проекту?',
      'Как вы относитесь к своей роли?',
      'Как это повлияло на вас?',
      'Как это заставило вас чувствовать себя?',
      'Как, по вашему мнению, сейчас обстоят дела с балансом между работой и личной жизнью?',
      'Что вы думаете о своей текущей рабочей нагрузке и как вы с ней справляетесь?',
      'Как, по вашему мнению, наименее любимое дело влияет на вашу общую работоспособность?',
      'Сколько часов в день вы считаете продуктивными? Как мы могли бы помочь вам быть более продуктивным?',
      'Если бы вы могли изменить то, что произошло, как бы вы это сделали?',
      'Если бы вы могли работать над чем угодно в течение следующего месяца, что бы это было? Что заставляет вас так говорить?',
      "В нашей последней встрече 1 на 1 вы упомянули, что хотели бы расти в Х - как сейчас статус?",
      "В нашей последней встрече 1 на 1 вы упомянули, что вас расстраивает Х и вы хотите попробовать Y в качестве решения. Как это происходит?",
      'Каким образом ваша нынешняя должность позволяет вам использовать свои навыки и таланты?',
      'Я заметил X в проекте... Можете ли вы помочь мне лучше понять это? Расскажите мне о вашем процессе',
      'Расскажите мне о том, чему вы научились в этом проекте',
      'Что вас больше всего радует?',
      'Что вас больше всего беспокоит?',
      'Что вас больше всего беспокоит в вашем текущем проекте (проектах)?',
      'Какой аспект этого проекта был особенно интересен для вас?',
      'Что я могу сделать, чтобы вы получали больше удовольствия от работы?',
      'Что вам больше/меньше всего понравилось?',
      'Что вам больше всего нравится в вашей нынешней роли?',
      'Что вы считаете своим самым большим достижением здесь?',
      `Чему, по вашему мнению, вы научились в этом проекте?`,
      'Что вам больше всего нравится в работе в нашей команде?',
      'Что вам больше всего нравится в вашей сегодняшней работе?',
      'Что вам нравится меньше всего?',
      'Чего вам нужно или хочется достичь и к какому сроку?',
      'Как вы думаете, что привело к этому?',
      `Как вы думаете, что прошло хорошо? Что, по вашему мнению, вы могли бы сделать лучше? Что, если это возможно, вы хотели бы сделать, но не смогли?`,
      'Что, по вашему мнению, вы могли бы делать по-другому?',
      'Что движет вами? Что мотивирует вас приходить на работу каждый день?',
      'Какие отзывы/похвалы вы получали о ваших текущих приоритетах?',
      'Что вас расстраивает в проекте?',
      'Что вы уже пытались сделать, чтобы добиться прогресса?',
      'Что вас интересует в проекте (проектах), над которым вы сейчас работаете, и почему?',
      'Что вам нравится/не нравится в вашей работе в данный момент?',
      'Какими талантами вы обладаете, которые, по вашему мнению, мы используем в полной мере? Какие из ваших талантов мы используем не полностью?',
      'Каким был этот опыт для вас?',
      'Что бы вы могли вынести из этого разговора, чтобы добиться прогресса?',
      'Что могло бы убедить вас уйти на другую работу?',
      'Что заставило бы вас оставить эту работу ради другой?',
      'Какая недавняя ситуация, с которой вы хотели бы справиться по-другому? Что бы вы изменили?',
      'Какую область своей работы вы хотели бы улучшить?',
      "Что есть такого в вашей работе, исправив что, вы бы никогда не захотели ее покинуть?",
      "Что может сделать вашу работу более приятной и почему?",
      'Что является лучшей частью вашей работы?',
      'Что самое плохое в вашей работе?',
      "Что вас устраивает в вашей нынешней должности?",
      'Какая ваша проблема №1 в данный момент? Что вы чувствуете по этому поводу?',
      'Когда вам больше всего нравилось работать здесь?',
      'В каких областях вы чувствуете, что у вас связаны руки или вы не можете полностью раскрыть свой потенциал?',
      'На какие области вы хотели бы тратить больше времени и почему?',
      'Какие части вашего проекта непонятны или запутаны?',
    ]
  },
  {
    title: otherTitle,
    questions: [
      'В детстве кем вы хотели стать, когда вырастете?',
      'Чем вы занимаетесь вне работы?',
      'Если бы у вас были миллионы долларов, чем бы вы занимались каждый день?',
      'Что вы делали для развлечения в прошлом, на что у вас не хватает времени в последнее время?',
      'Что вы еще не пробовали?',
      'Какие идеи вы можете почерпнуть из прошлых успехов?',
      'Какие вопросы у вас есть по проекту?',
      'Чем бы вы сейчас занимались, если бы у нас не было этой встречи? Как вы относитесь к тому, что вас отрывают от этой задачи?',
      'Что можно изменить в вашей работе, чтобы улучшить вашу личную жизнь?',
    ]
  },
  {
    title: teamTitle,
    questions: [
      'Есть ли какие-то аспекты нашей культуры, которые вы хотели бы изменить?',
      'Есть ли собрания или обсуждения, в которых, по вашему мнению, вы должны участвовать, но не участвуете? Участвуете ли вы в тех, в которых не хотите участвовать?',
      'Есть ли проблемы или вопросы, о которых я должен знать?',
      'Есть ли в компании роли, о которых вы хотели бы узнать больше?',
      'Вам неловко давать конструктивную критику кому-либо из своих коллег? Если да, то почему?',
      'Можете ли вы поделиться некоторыми подробностями, связанными с этим конкретным вопросом? (Кто был вовлечен? Где? Когда? Как долго?)',
      'Не могли бы вы рассказать мне об этом немного подробнее?',
      'Чувствуете ли вы адекватную поддержку со стороны других членов команды? Почему вы так считаете?',
      'Чувствуете ли вы, что находитесь на одной волне с командой? Как часто, по-вашему, нужно проводить совещания, чтобы убедиться в этом?',
      'Есть ли у вас четкое понимание новых целей и ожиданий? Почему вы так считаете?',
      'Помогаете ли вы другим членам команды? Помогают ли вам другие, когда вы в этом нуждаетесь?',
      'Заставлял ли вас кто-нибудь в команде чувствовать себя неловко? Что случилось?',
      'Как вы относитесь к будущему компании в целом? Почему вы так считаете?',
      'Как вы относитесь к последним новостям? Почему вы так думаете?',
      'Как мы могли бы быть более креативными или инновационными как компания?',
      'Как мы могли бы изменить наши командные встречи, чтобы они были более эффективными?',
      'Как новая ситуация/развитие влияет на вашу работу? Что может мешать вам быть эффективным?',
      "Насколько хорошо, по вашему мнению, вы относитесь к своим коллегам? Считаете ли вы их друзьями, знакомыми или незнакомцами?",
      "Как бы вы описали распределение работы между членами команды?",
      `Как бы вы описали личность команды? Какой человек хорошо впишется в коллектив? Какой человек мог бы добавить то, чего нам сейчас не хватает?`,
      'Как бы вы оценили наше общение в команде?',
      `Как вы оцениваете нашу совместную работу в команде? Почему вы так считаете?`,
      'Если бы мы могли что-то улучшить, как бы мы это сделали?',
      'Если бы вы были генеральным директором, что бы вы изменили в первую очередь?',
      'Является ли какая-либо часть вашего проекта неясной или запутанной?',
      'Все ли в команде справляются со своими обязанностями?',
      'Есть ли ситуация, в которой вы хотели бы получить мою помощь?',
      'Есть ли в команде кто-то, с кем вам трудно работать? Можете ли вы сказать мне почему?',
      'Есть ли что-то, что мешает вам выполнять свою работу?',
      'Есть ли что-то, что замедляет вашу работу и достижение результата?',
      'Есть ли что-то, что вы хотели бы изменить в команде, и если да, то почему?',
      'Соответствует ли ваша работа вашим ожиданиям, когда вы соглашались на нее?',
      'Какие дополнительные ресурсы от меня были бы полезны для вас при решении этой проблемы?',
      'Каковы некоторые возможные способы получить нужное вам решение?',
      'Какими способами мы могли бы улучшить работу в команде? Почему вы так считаете?',
      'Что мы делаем, и почему?',
      'Что мы не делаем, но должны делать? Почему вы так считаете?',
      'Что вам больше всего нравится в нашей культуре?',
      'Каковы ваши дальнейшие шаги по решению этой проблемы?',
      'Каковы три ваших главных мотиватора?',
      'О какой сфере деятельности компании вы хотели бы узнать больше?',
      'Какие области опережают график?',
      'Какие характеристики делают человека подходящим для нашей команды? Как бы вы искали эти характеристики на собеседовании?',
      `Какие у вас есть опасения по поводу изменений, которые не были учтены?`,
      'Что вы могли бы сделать по-другому?',
      "Что вы делаете, когда застреваете на чем-то? Каков ваш процесс выхода из затруднительного положения? К кому из членов команды вы обращаетесь за помощью?",
      'Что вам нравится в работе здесь? Что не нравится в работе здесь?',
      'Что вы думаете о количестве обратной связи в нашей команде? Когда другие дают вам обратную связь? Хотели бы вы слышать больше обратной связи от других членов команды и от меня?',
      'Что вы думаете о _________?',
      'Как вы думаете, какие навыки являются ключевыми для вашей роли? Как бы вы оценили себя по каждому из них?',
      'Как вы думаете, что помогло бы нам лучше работать вместе?',
      'Как вы думаете, что вы можете сделать, чтобы решить эту проблему?',
      'Что вас привлекает в нашем бизнесе? Компания? Что вас беспокоит?',
      'Что является проблемой №1 в нашей компании? Почему?',
      'Что компания не делает сегодня, что мы должны сделать, чтобы лучше конкурировать на рынке?',
      'Какой вид общения или информации вы хотели бы иметь больше?',
      'Какую часть своей работы вы хотели бы не делать?',
      'Какие ресурсы вы могли бы использовать прямо сейчас, чтобы сделать работу более управляемой?',
      'О чем бы вы хотели узнать больше в этом году?',
      'Что бы вы хотели, чтобы здесь изменилось? Почему вы так считаете?',
      'Какую недорогую вещь мы могли бы сделать, чтобы улучшить обстановку в офисе для команды?',
      "Что происходит хорошо и не очень хорошо в новой ситуации/развитии? Как вы думаете, почему это происходит?",
      'Что нам следует изменить в работе нашей команды?',
      'Какую одну вещь мы были бы сумасшедшими, если бы не сделали в следующем квартале, чтобы улучшить наш продукт?',
      "Что вы считаете недооцененным вкладом в работу команды?",
      'Какую самую большую возможность мы упускаем?',
      'Что самое главное вы хотели бы изменить в нашей команде?',
      `Какая проблема №1 в нашей организации, и что, по вашему мнению, является ее причиной?`,
      `Какая проблема №1 в нашей организации? Почему?`,
      `Какая проблема номер один в нашей организации? Почему?`,
      `Где, по вашему мнению, мы можем работать лучше?`,
      `В каких областях вы хотели бы получить больше обратной связи?`,
      `Какие ценности компании вам нравятся больше всего? Какие меньше всего? Почему?`,
      `С кем вы дружите на работе? (Показано, что это ключ к получению удовольствия от работы)`,
      `Кем вы восхищаетесь в компании и почему?`,
      `Кто в последнее время действительно ведет себя неприемлемо в команде?`,
      `Кто вдохновляет вас в команде? Чье мнение вы уважаете? Что они сделали?`,
      `Кто сворачивает горы в команде? Что они сделали?`,
      `Кто действительно достиг крутых результатов в компании? Кем вы восхищаетесь?`,
      `С кем из команды вам труднее всего работать? Почему?`,
      `С кем бы вы хотели работать чаще? Почему?`,
      `Кто из команды хочет учиться у вас? От кого вы получаете ценную обратную связь?`,
      `Почему, как вы думаете, ушел [сотрудник, который недавно уволился]? Что они вам сказали?`,
   ]
  },
  {
    title: balanceTitle,
    questions: [
      `Как вы относитесь к работе?`,
      `Как вы собираетесь к этому подходить?`,
      `Как вы планируете сбалансировать работу и личную жизнь в этом году?`,
      `Как я могу помочь...? (быть более продуктивным/счастливым на работе/получать больше удовольствия от работы/т.д.)`,
      `Как вы относитесь к балансу между работой и личной жизнью?`,
      `Как сейчас обстоят дела с вашей рабочей нагрузкой?`,
      `Если бы вы могли изменить что-то одно в вашей работе сегодня, что бы вы изменили?`,
      `Есть ли что-нибудь, что вам нужно от меня?`,
      `Есть ли что-то, с чем вы боретесь?`,
      `Есть ли что-то, что вы хотели бы делать в свободное время для снятия стресса, но не успеваете? Как я могу помочь вам достичь этих личных целей?`,
      `Что в работе вас расстраивает?`,
      `Что в вашей работе приносит вам наибольшее удовлетворение?`,
      `Какие 3 вещи мы могли бы сделать, чтобы повысить вашу производительность, если бы деньги не мешали?`,
      `На что вы тратите больше всего времени каждую неделю?`,
      `На каких самых важных вещах вы сосредоточитесь до нашей следующей встречи?`,
      `Что вы делаете для себя вне работы?`,
      `Каковы ваши самые значительные достижения с момента нашей последней встречи?`,
      `В каких областях вашей работы вы уверены в себе?`,
      `Что мы могли бы изменить в работе, чтобы улучшить остальную часть вашей жизни?`,
      `Что вы делаете, когда чувствуете недостаток энергии или немотивированность?`,
      `Как выглядит ваш идеальный будний день?`,
      `Каким для вас является идеальный, продуктивный рабочий день? Проведите меня через весь день...`,
      `Что самое важное мы должны обсудить сегодня?`,
      `Что вызывает у вас воодушевление и мотивацию для работы над проектом?`,
      `С какими препятствиями вы сталкиваетесь в данный момент?`,
      `В какое время дня вы наиболее энергичны и сосредоточены? Когда у вас их меньше всего? Какие изменения мы могли бы внести в ваш рабочий график, чтобы учесть это?`,
      `Какая часть вашего рабочего распорядка, по вашему мнению, работает лучше всего? Какую область вы хотели бы улучшить?`,
      `Какие вопросы у вас есть по этому проекту?`,
      `Что стоит на вашем пути?`,
      `Какие у вас есть предложения?`,
      `Что было трудным на этой неделе? Чему вы научились?`,
      `Какими были ваши самые большие потери времени или препятствия на прошлой или позапрошлой неделе?`,
      `Что вас беспокоит?`,
      `Что вас беспокоит? Что у вас на уме?`,
      `Что, если что-либо, вы делали раньше, на что у вас сейчас нет времени?`,
      `Какую недорогую вещь мы могли бы сделать, чтобы улучшить обстановку в нашем офисе?`,
      `Когда у вас будет следующий отпуск?`,
      `Когда был ваш последний отпуск?`,
      `Когда вы застреваете на чем-то, каков ваш процесс выхода из затруднительного положения? К кому вы обращаетесь за помощью?`,
      `Какую часть дня вы чувствуете себя наиболее продуктивной? Когда вы чувствуете, что ваша энергия и сосредоточенность находятся на самом низком уровне? Какие изменения можно внести, чтобы вы могли извлечь максимум пользы из рабочего дня?`,
 ]
  }
 ];
 


const OneTopics = ({ lang }) => {

  let content = '';

  // console.log('========= lang in OneTopics', lang);
  
  if (lang !== 'ru') { content = questionsEn };
  if (lang === 'ru') { content = questionsRu };

  return (
    <div className="py-1">
      {content.map((item, index) => (
        <div 
          key={`topics-${index}`}
          className="mt-2"
          >
          <Fs2Title title={item.title} />
          {item.questions.map((question, i) => (
            <ul 
              key={`bullets-${i}`}
              className="card-list"
              >
              <li className="medium">
                {question}
              </li>
            </ul>
          ))}
        </div>
      ))}
    </div>
  );
};

export default OneTopics;
