import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import QuestionField from "./QuestionIcebreakerItemField";
import TimezonePicker from 'react-timezone';

const QuestionIcebreakerItem = ({
  formData,
  setFormData,
  settings,
  questions,
  onChange,
  handleAdd,
  handleRemove,
  changeIcebreakersLanguage,
  getDefaultIcebreakers,
  lang,
}) => {
  
  const [showAll, toggleShowAll] = useState(false);

  // Array for 10 questions
  let tenQuestions = [];

  if (questions.length > 0) {
    if (!showAll) {
      let start = settings.nextQuestionId - 1;
      let end = settings.nextQuestionId + 9;

      // To get proper #week
      for (let index = start; index < end; index++) {
        let data = {
          question: questions[index],
          index: index,
        }

        tenQuestions.push(data);
      }
    }
  }
  
  const youHaveMsg = <FormattedMessage id="icebreaker.youHave" defaultMessage="You have" />
  const addOrRemoveMsg = <FormattedMessage id="icebreaker.addOrRemove" defaultMessage="icebreakers questions in your campaign. You can add, remove, or edit questions. Each week or bi-weekly, Focus creates icebreaker if campaign is active." />
  const questionsLength = <FormattedMessage id="icebreaker.length" defaultMessage="Total icebreakers in campaign: " />
  const nextQuestion = <FormattedMessage id="icebreaker.next" defaultMessage="Next Icebreaker:" />
  const weekMsg = <FormattedMessage id="week.small" defaultMessage="week" />
  const showAllMsg = <FormattedMessage id="icebreaker.showAll" defaultMessage="Show all icebreakers" />
  const showTenMsg = <FormattedMessage id="icebreaker.showTen" defaultMessage="Show 10 icebreakers" />
  const defaultMsg = lang !== 'ru' ?
    "It will rewrite all questions to default icebreakers. The current questions will be lost. Are you sure you wish to rewrite icebreakers?" :
  "Это перезапишет все вопросы на вопросы по умолчанию. Все текущие воросы будут потеряны. Уверены, что хотите вернуть вопросы по умолчанию?"
  
  return (
    <Fragment>
      <div className="settings__questions">
        <Fragment>
          <p className="sub-title focus-yellow focus">
            <FormattedMessage id="admin.dashboard.edit.questions" defaultMessage="Questions" />
          </p>
        
          <div className="py-2">
            <p className="large bold">
              <FormattedMessage id="admin.dashboard.edit.icebreaker" defaultMessage="Icebreaker questions" />
            </p>
            <p className="meduim">
              {youHaveMsg}{' '}<b>{questions.length}</b>{' '}{addOrRemoveMsg}{' '}
              <FormattedMessage id="admin.dashboard.edit.icebreaker.changes" defaultMessage="All changes will implemented in the next icebreaker." />
            </p>
            <p className="meduim my-1">
              <b>{questionsLength}</b>{' '}{questions.length}
            </p>
            <p className="meduim">
              <b>{nextQuestion}</b>{' '}{weekMsg}{' #'}{settings.nextQuestionId + 1}
            </p>
          </div>

          {questions && (
            <Fragment>
              {questions.length > 9 && (
                <p
                  className="cursor medium grey display-inline text-left mb-2 bg-transparent"
                  onClick={e => toggleShowAll(!showAll)}
                >
                  {!showAll ? showAllMsg : showTenMsg}
                </p>
              )}

              {questions.length > 0 && (
                !showAll ? (
                  tenQuestions.map((item) => (
                    <QuestionField
                      key={`${item.question._id}-${item.index}`}
                      question={item.question}
                      index={item.index}
                      nextQuestionId={settings.nextQuestionId}
                      onChange={onChange}
                      handleRemove={handleRemove}
                    />
                  ))
                ): (
                  questions.map((question, index) => (
                  <QuestionField
                    key={`${question._id}-${index}`}
                    question={question}
                    index={index}
                    nextQuestionId={settings.nextQuestionId}
                    onChange={onChange}
                    handleRemove={handleRemove}
                  />
                  ))
                )
              )}
            </Fragment>
          )}
        </Fragment>

        <div className="flex align-center space-between">
          <button
            type="button"
            className="btn-secondary btn-secondary-back my-2 block-wp"
            onClick={e => handleAdd(e)}
          >
            <FormattedMessage id="admin.dashboard.edit.add" defaultMessage="+ Add Question" />
          </button>

           <button
            type="button"
            className="btn-secondary btn-secondary-back my-2 block-wp"
            onClick={() => { if (window.confirm(defaultMsg)) getDefaultIcebreakers(lang) } }
          >
            <FormattedMessage id="default.questions" defaultMessage="Set default questions" />
          </button>
        </div>
      </div>
    </Fragment>
  );
};

QuestionIcebreakerItem.propTypes = {
  questions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

export default QuestionIcebreakerItem;
