import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import OkrProgress from "../okrs/elements/OkrProgressPin";
import Moment from "react-moment";

const MainWidgetNew = ({ okr }) => {
  let objective, 
      progress, 
      status, 
      start, 
      current, 
      goal;

  if (okr.isMainIndex === 99) {
    objective=okr.objective;
    progress=okr.progress;
    status=okr.status;
    start='0';
    current=`${Math.round(okr.progress)}%`;
    goal='100%';
  } else {
    objective=okr.keyResults[okr.isMainIndex].title;
    progress=okr.keyResults[okr.isMainIndex].progress;
    status=okr.status;
    start=okr.keyResults[okr.isMainIndex].start;
    current=okr.keyResults[okr.isMainIndex].current;
    goal=okr.keyResults[okr.isMainIndex].goal;
  }
  
  let btnClass;
  if (status === 1) btnClass = "green";
  if (status === 2) btnClass = "yellow";
  if (status === 3) btnClass = "red";

  let highlight = <FormattedMessage id="highlighted.okr" defaultMessage="Highlighted OKR" />;

  return (
    <div className="flex align-center flex-column-xs align-start-xs main-widget">
      <div className="main-widget__left w-40 w-100-xs flex align-center">
        <div className="line-new__vertical__black mr-38"></div>
        <div className="flex flex-column">
          <div className="grey_new fs-14 lh-20">{okr && okr.teamName}{" "}-{" "}{highlight}</div>
          <div className="fs-22 fw-600 lh-25">{objective}</div>
          <div className="fs-12 lh-20">
            <FormattedMessage id="user.okr.due" defaultMessage="Due date" />:{" "}
            {okr && okr.dueDate ? (<Moment format="D MMM">{okr.dueDate}</Moment>) : '–'}
          </div>
        </div>

      </div>
      <div className="main-widget__right w-60 w-100-xs flex relative">
        <OkrProgress
          progress={progress}
          btnClass={btnClass}
          current={current}
          goal={goal}
        />
      </div>
    </div>
  );
};

MainWidgetNew.propTypes = {
  okr: PropTypes.object.isRequired
};

export default MainWidgetNew;
