import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import UsersRow from './UsersRow';

const UsersTable = ({ 
  users, 
  companies, 
  okrs, 
  standups, 
  updates, 
  plans, 
  tasks, 
  icebreakers, 
  weeklyStandups, 
  weeklyUpdates, 
  weeklyPlans, 
  weeklyCreatedOkrs,
  weeklyUpdatedOkrs,
  handleLogin,
  deleteCompany,
  auth,
  oneOnOnes,
  weeklyOnes,
  moods,
  weeklyMoods,
  weeklyCustoms,
  customs,
 }) => {

  return (
    <Fragment>
      <table className="text-left brd__collapse">
			  <thead>
			    <tr>
			      <th className="bold medium pr-2">#</th>
			      <th className="bold medium pr-2">Name</th>
			      <th className="bold medium pr-2">Email</th>
			      <th className="bold medium pr-2">Company</th>
			      <th className="bold medium pr-2">Users in the company</th>
			      <th className="bold medium pr-2">Role</th>
			      <th className="bold medium pr-2">Signup date</th>
			      <th className="bold medium pr-2">Expired date</th>
			      <th className="bold medium pr-2">Company's Plan</th>
			      <th className="bold medium pr-2"><div className="w-120px">Last login</div></th>
			      <th className="bold medium pr-2 bold blue">Check-ins</th>
			      <th className="bold medium pr-2 ">Standups</th>
			      <th className="bold medium pr-2">Updates</th>
			      <th className="bold medium pr-2">Plans</th>
			      <th className="bold medium pr-2 bold">OKRs</th>
			      <th className="bold medium pr-2">Tasks</th>
			      <th className="bold medium pr-2">1:1s</th>
			      <th className="bold medium pr-2">Icebreakers</th>
			      <th className="bold medium pr-2">Moods</th>
			    </tr>
			  </thead>
			  <tbody>
          {users && users.length > 0 ? (
            users.map((user, index) => (
              <UsersRow 
                key={user && user._id}
                user={user} 
                company={companies && companies.length > 0 && user && user.company ? companies.filter(item => item._id && item._id === user.company)[0] : []}
                customs={customs.filter(item => item.user === user._id)}
                standups={standups.filter(item => item.user === user._id)}
                updates={updates.filter(item => item.user === user._id)}
                plans={plans.filter(item => item.user === user._id)}
                okrs={okrs.filter(item => item.creator === user._id)}
                moods={moods.filter(item => item.pl === user._id)}
                companyUsers={users.filter(item => item.company === user.company)}
                weeklyStandups={weeklyStandups.filter(item => item.user === user._id)}
                weeklyUpdates={weeklyUpdates.filter(item => item.user === user._id)}
                weeklyPlans={weeklyPlans.filter(item => item.user === user._id)}
                weeklyCustoms={weeklyCustoms.filter(item => item.user === user._id)}
                weeklyCreatedOkrs={weeklyCreatedOkrs.filter(item => item.creator === user._id)}
                weeklyUpdatedOkrs={weeklyUpdatedOkrs.filter(item => item.owner === user._id.toString())}
                weeklyOnes={weeklyOnes.filter(item => item.creator.user.toString() === user._id.toString() || item.participant.user.toString() === user._id.toString())}
                weeklyMoods={weeklyMoods.filter(item => item.pl.toString() === user._id.toString())}
                oneOnOnes={oneOnOnes.filter(item => item.creator.user.toString() === user._id.toString() || item.participant.user.toString() === user._id.toString())}
                tasks={tasks.filter(item => item && item.creator && item.creator.toString() === user._id.toString() || item && item.owner && item.owner.length > 0 && item.owner.filter(o => o.user && o.user.toString() === user._id.toString()).length > 0)}
                index={index}
                handleLogin={handleLogin}
                deleteCompany={deleteCompany}
                auth={auth}
              />
            ))
          ) : (
            <Fragment>
              <tr>
                <td className="medium">No users</td>
              </tr>
            </Fragment>
          )}
			  </tbody>
			</table>
      
    </Fragment>
  );
};

UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  okrs: PropTypes.array.isRequired,
  standups: PropTypes.array.isRequired,
  updates: PropTypes.array.isRequired,
  handleLogin: PropTypes.func.isRequired,
};

export default UsersTable;
