import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const FourWidgets = ({
  board,
  objectives,
  weekFocus,
  dayFocus
}) => {
  // Week Focus
  let weekly = [];
  let w = 0;
  let progress;
  let lastScore = 0;

  // ToDo cейчас статистика работает некорректно иногда (не хочу больше тратить на это время, пошел дальше)
  // 1. Неправильно определяется прогресс: 
  // let first = w.progress;    - могут быть разными 2 крайние точки
  // let last = weekly[0].progress;  
  // 2. weekly.length считается не совсем корректно для Days with focus on weekly OKR:
  // - мы берем все апдейты за неделю по окр, если этот окр был фокусом недели
  // - по идеи, надо брать все isDayFocus && isWeekFocus (только с дневным фокусом)
  // - для этого надо делать 2 фильтра (не захотел делать второй цикл) и брать 2 массива: 
  //    - только isDayFocus для окр, у которого на неделе isWeekFocus
  //    - все метрики для окр, у которого на неделе isWeekFocus (надо для показа последнего значения и прогресса, на случай если был апдейт этой метрики не будучи isDayFocus)    // сейчас работает только этот вариант

  if (!weekFocus || weekFocus.length === 0) {
    progress = 0;
  } else {
    weekFocus.length === 1 ? w = weekFocus[0] : w = weekFocus[weekFocus.length - 1];

    // If week focus is KR or Objective
    w.isKr ? weekly = objectives.filter(item => {
     if (item.okr.toString() === w.okr.toString() && item.krId === w.krId) { 
        return item; 
      } else if (item.okr.toString() === w.okr.toString() && item.krId === w.krId) {
        // lastScore = item.current;
      }

      return; 
    }) : (weekly = objectives.filter(item => item.okr.toString() === w.okr.toString() && !item.isKr)); 

    lastScore = weekly[0].progress;
    
    // Progress and last score of weekly focus
    let first = w.progress;
    let last = weekly[0].progress;
    progress = Math.round(((last - first) / first) * 100);
  }

  let progressColor;
  progress < 10 ? progressColor = 'red' : progressColor = 'green';
 
  // OKR updates
  let updates = 0;
  objectives.filter(item => {
    if (!item.isFocusDay && !item.isWeekDay) {
      updates ++;

      if (item.dayUpdates.length > 0) {

        updates = updates + item.dayUpdates.length;
      }
    }
    if (item.isFocusDay && item.dayUpdates.length > 0 || item.isFocusWeek && item.dayUpdates.length > 0) {

      updates = updates + item.dayUpdates.length;
    }
  });

  const generateStatusColor = (status) => {
    if (status === "1") {
      return "green";
    } else if (status === "2") {
      return "yellow";
    } else if (status === "3") {
      return "red";
    }
  }

  const generateStatusLabel = (status) => {
    if (status === "1") {
      return onTrack;
    } else if (status === "2") {
      return behind;
    } else if (status === "3") {
      return atRisk;
    }
  }
  
  let onTrack = <FormattedMessage id="user.okr.status.onTrack" defaultMessage="On Track"/>
  let behind = <FormattedMessage id="user.okr.status.behind" defaultMessage="Behind"/>
  let atRisk = <FormattedMessage id="user.okr.status.risk" defaultMessage="At Risk"/>
  let weekTitle = board === 1 || board === 2 ? (<FormattedMessage id="focus.widgets.week" defaultMessage="Days with  focus on weekly OKR"/>) : (<FormattedMessage id="focus.widgets.weekMonthly" defaultMessage="Weeks with weekly focus"/>);
  let dayTitle = <FormattedMessage id="focus.widgets.day" defaultMessage="Days with focus on OKRs"/>;
  let updatesTitle = <FormattedMessage id="focus.widgets.updates" defaultMessage="Times OKRs were updated"/>;
  let currentTitle = <FormattedMessage id="focus.widgets.current" defaultMessage="Weekly OKR Completeness"/>;
  let progressTitle = <FormattedMessage id="focus.widgets.progress" defaultMessage="progress"/>
  let okrWeekTitle = <FormattedMessage id="focus.widgets.okrWeek" defaultMessage="Weekly focus on the OKR:" />
  const noWeekFocus = <FormattedMessage id="nofocus.okr" defaultMessage="No Focus on OKRs"/>;
  const currentlLabel = <FormattedMessage id="widget.main.current" defaultMessage="Current"/>;
  const goalLabel = <FormattedMessage id="widget.main.goal" defaultMessage="Goal"/>;

  return (
    <Fragment>
			<div className="large"><span className="bold">{okrWeekTitle}</span>{' '}{w ? w.text : noWeekFocus}</div>
      {weekly.length > 0 && (
        <Fragment>
          <p className={`medium text-left inline-block ${weekly.length > 0 && generateStatusColor(weekly[0].status)} fs-14 mr-3`}>{weekly.length > 0 && generateStatusLabel(weekly[0].status)}</p>
          <p className="medium text-left inline-block grey fs-14 mr-1">{currentlLabel}:{' '}{weekly.length > 0 && weekly[0].progress}%</p>
          <p className="medium text-left inline-block grey fs-14">{goalLabel}:{' '}100%</p>
        </Fragment>
      )}
			
      <div className="dashboards flex space-between mt-3 mb-2 flex-column-s">
					<div className="br-12 w-24 left-board bg-primary_white p-3 shadow w-100-s">
						<p className="fs-50 lh-70 xxl">{weekly.length}</p>
						<p className="large">{weekTitle}</p>
					</div>
				
					<div className="br-12 w-24 right-board bg-primary_white p-3 shadow w-100-s mt-1-s">
						<p className="fs-50 lh-70 xxl">{dayFocus.length > 0 ? dayFocus.length : 0}</p>
						<p className="large">{dayTitle}</p>
					</div>

					<div className="br-12 w-24 right-board bg-primary_white p-3 shadow w-100-s mt-1-s">
						<p className="fs-50 lh-70 xxl">{updates}</p>
						<p className="large">{updatesTitle}</p>
					</div>

					<div className="br-12 w-24 right-board bg-primary_white p-3 shadow w-100-s mt-1-s">
						<p className="fs-50 lh-70 xxl">{weekly.length > 0 ? `${lastScore}%` : 0}</p>
						<p className="large">{currentTitle}</p>
						<p className={`small ${progressColor && progressColor}`}>{progress}%{' '}{progressTitle}</p>
					</div>
				</div>
    </Fragment>
  );
};

FourWidgets.propTypes = {
  board: PropTypes.number,
  objectives: PropTypes.array.isRequired,
};


export default FourWidgets;
