import {
  GET_USER_FEEDBACK,
  GET_GIVED_USER_FEEDBACK,
  GET_TEAM_FEEDBACK,
  GET_GIVED_TEAM_FEEDBACK,
  GET_FEEDBACK,
  FEEDBACK_ITEM_ERROR,
  FEEDBACK_ERROR,
  GIVED_USER_FEEDBACK_ERROR,
  GIVED_TEAM_FEEDBACK_ERROR,
  GIVED_COMPANY_FEEDBACK_ERROR,
  ADD_FEEDBACK_ITEM,
  GET_FEEDBACK_ITEM,
  UPDATE_FEEDBACK_ITEM,
  DELETE_FEEDBACK_ITEM,
  UPDATE_LIKES,
  ADD_COMMENT,
  UPDATE_COMMENT,
  CLEAR_FEEDBACK,
  LOCATION_CHANGE_ERROR,
  GET_COMPANY_FEEDBACK,
  GET_GIVED_COMPANY_FEEDBACK,
  COMPANY_FEEDBACK_ERROR,
  TEAM_FEEDBACK_ERROR,
  REMOVE_COMMENT
} from '../actions/types';

const initialState = {
  feedback: [],
  givedFeedback: [],
  feedbackItem: null,
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_FEEDBACK:
    case LOCATION_CHANGE_ERROR: 
      return {
        ...state,
        feedback: [],
        givedFeedback: [],
        feedbackItem: null,
        loading: true
      };
    case GET_FEEDBACK:
    case GET_USER_FEEDBACK:
    case GET_TEAM_FEEDBACK:
      return {
        ...state,
        feedback: payload,
        loading: false
      };
    case GET_COMPANY_FEEDBACK:
      return {
        ...state,
        feedback: payload,
        givedFeedback: payload,
        loading: false
      };
    case GET_GIVED_USER_FEEDBACK:
    case GET_GIVED_TEAM_FEEDBACK:
    case GET_GIVED_COMPANY_FEEDBACK:
      return {
        ...state,
        givedFeedback: payload,
        loading: false
      };
    case GET_FEEDBACK_ITEM:
      return {
        ...state,
        feedbackItem: payload,
        loading: false
      };
    case ADD_FEEDBACK_ITEM:
      return {
        ...state,
        feedbackItem: [payload, ...state.feedback],
        loading: true
      };
    case UPDATE_FEEDBACK_ITEM:
      return {
        ...state,
        feedbackItem: [payload, ...state.feedback],
        loading: false
      };
    case DELETE_FEEDBACK_ITEM:
      return {
        ...state,
        feedback: state.feedback.filter(
          feedbackItem => feedbackItem._id !== payload
        ),
        loading: false
      };
    case UPDATE_LIKES:
      return {
        ...state,
        feedback: state.feedback.map(feedbackItem =>
          feedbackItem._id === payload.id
            ? { ...feedbackItem, likes: payload.likes }
            : feedbackItem
        ),
        loading: false
      };
    case ADD_COMMENT:
      return {
        ...state,
        feedback: state.feedback.map(feedbackItem =>
          feedbackItem._id === payload.id
            ? {
                ...feedbackItem,
                comments: payload.comments
              }
            : feedbackItem
        ),
        loading: false
      };
    case UPDATE_COMMENT:
      return {
        ...state,
        feedback: state.feedback.map(feedbackItem =>
          feedbackItem._id === payload.id
            ? {
                ...feedbackItem,
                comments: payload.comments
              }
            : feedbackItem
        ),
        loading: false
      };
    case REMOVE_COMMENT:
      return {
        ...state,
        feedback: state.feedback.map(feedbackItem =>
          feedbackItem._id === payload.feedbackItemID
            ? {
                ...feedbackItem,
                comments: feedbackItem.comments.filter(
                  comment => comment._id !== payload.commentID
                )
              }
            : feedbackItem
        ),
        loading: false
      };
    case FEEDBACK_ITEM_ERROR:
      return {
        ...state,
        error: payload,
        feedback: [],
        feedbackItem: null,
        loading: false
      };
    case FEEDBACK_ERROR:
    case TEAM_FEEDBACK_ERROR:
    case COMPANY_FEEDBACK_ERROR:
      return {
        ...state,
        error: payload,
        feedback: [],
        loading: false
      };
    case GIVED_USER_FEEDBACK_ERROR:
    case GIVED_TEAM_FEEDBACK_ERROR:
    case GIVED_COMPANY_FEEDBACK_ERROR:
      return {
        ...state,
        error: payload,
        givedFeedback: [],
        loading: false
      };
    case CLEAR_FEEDBACK:
      return {
        ...state,
        feedback: [],
        givedFeedback: [],
        feedbackItem: null,
        loading: true
      };
    default:
      return state;
  }
}
