import React from "react";
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Moment from 'react-moment';

const MeetingDate = ({ docs, onClick, currentDoc, isForm }) => {

  let date = <FormattedMessage id="date" defaultMessage="Date" />

  // console.log('====== docs', docs);
  // currentDoc && console.log('====== currentDoc._id', currentDoc._id);
  // console.log('====== currentDoc in MeetingDate', currentDoc);

  return (
    <div className="weeks py-1">
      <div className="p small grey text-left">{date}</div>
      <div className="weeks__item text-left pt-xs">
        {docs && docs.length > 0 && (
          docs.map((item, index) =>
            <div
              key={`doc-${docs._id}-${index}`}
              className={`medium cursor lh-30 ${currentDoc && item && currentDoc._id.toString() === item._id.toString() && ' bold-pure'}`}
              // onClick={() => onClick(item._id)}
              onClick={!isForm ? () => (onClick(item._id)) : (() => { if (window.confirm('Are you sure you wish to close the form? All data will be lost.')) onClick(item._id) } )}
            >
              <Moment format="MMMM Do">{item.date}</Moment>
            </div>
            )
        )}
      </div>
    </div>
  );
};

MeetingDate.propTypes = {
  docs: PropTypes.array,
  currentProfile: PropTypes.object,
};

export default MeetingDate;
