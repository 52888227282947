import React from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import isEnabled, { ENGAGEMENT } from '../../utils/features';

const SubMenu = ({ 
  company: { company },
  companyIndex,
  userId
}) => {

  return (
    <div className="sub-menu__page mt-2 mb-2">
      <Link
        to={`/user/${userId}`}
        className={`medium sub-menu__item inline-block mr-2 mr-3-xs cursor ${companyIndex === 1 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="company.menu.checkins" defaultMessage="Check-ins" />
      </Link>
      <Link
        to={`/user/${userId}/updates`}
        className={`medium sub-menu__item inline-block mr-2 mr-3-xs cursor ${companyIndex === 2 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="company.menu.updates" defaultMessage="Weekly Updates" />
      </Link>

      <Link
        to={`/user/${userId}/plans`}
        className={`medium sub-menu__item inline-block mr-2 mr-3-xs cursor ${companyIndex === 7 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="company.menu.plans" defaultMessage="Weekly Plans" />
      </Link>
      
      <Link
        to={`/user/${userId}/objectives`}
        className={`medium sub-menu__item inline-block mr-2 mr-3-xs cursor ${companyIndex === 3 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="company.menu.objectives" defaultMessage="Objectives" />
      </Link>

      <Link
        to={`/user/${userId}/focus`}
        className={`medium sub-menu__item inline-block mr-2 mr-3-xs cursor ${companyIndex === 5 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="company.menu.focus" defaultMessage="Focus" />
      </Link>

      {isEnabled(ENGAGEMENT, { company }) && (
      <Link
        to={`/user/${userId}/kudos`}
        className={`medium sub-menu__item inline-block mr-2 mr-3-xs cursor ${companyIndex === 4 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="company.menu.kudos" defaultMessage="Kudos" />
      </Link>
      )}

      <Link
        to={`/user/${userId}/feedback`}
        className={`medium sub-menu__item inline-block mr-2 mr-3-xs cursor ${companyIndex === 6 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="feedback" defaultMessage="Feedback" />
      </Link>
    </div>
  );
};

SubMenu.propTypes = {
  companyIndex: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
  company: state.company,
});

export default connect(mapStateToProps)(SubMenu);
