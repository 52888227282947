import React from 'react';
import PropTypes from 'prop-types';
import ButtonSecondary from './ButtonSecondaryNew';
import { FormattedMessage } from 'react-intl';

const PricingCardFree = ({ 
  user, createSubscription, 
  users, people, price, 
  discountPrice, plan, 
  monthly, updatePlan, 
  id, togglePopup, popup,
  subscriptionId, cancelSubscription,
  planName
}) => {
  
  let limit = process.env.REACT_APP_FREE_PLAN_USERS;
  // console.log('===== limit', limit)

  return (
    <div className="w-40 pricing_item bg-white text-center py-3 px-2 br-6">
      <p className="fs2_5 bold">
        <FormattedMessage
          id="free"
          defaultMessage="Free Forever"
        />
      </p>

      <p className="fs2 my-1 blue">
        <FormattedMessage
          id="admin.pricing.card.usd"
          defaultMessage="$"
        />{price}{' '}
        <FormattedMessage
          id="admin.pricing.card.price2"
          defaultMessage="user / month"
        />
      </p>
      <p className="small mb-xs">
        <FormattedMessage
          id="admin.pricing.card.upto"
          defaultMessage="Up to"
        />{' '}
       <b>{limit}{' '}
        <FormattedMessage
          id="admin.pricing.card.users"
          defaultMessage="users"
        />
        </b>
      </p>
      <p className="small mb-xs">
        <FormattedMessage
          id="automated.checkins"
          defaultMessage="Automated Team Check-ins"
        />
      </p>
      <p className="small mb-2">
        <FormattedMessage
          id="slack.discord"
          defaultMessage="Slack & Discord Integration"
        />
      </p>

      <ButtonSecondary 
        createSubscription={createSubscription} 
        user={user}
        isStripe={true} 
        people={people}
        plan={plan} 
        btn={1} 
        togglePopup={togglePopup} 
        popup={popup} 
        updatePlan={updatePlan} 
        monthly={monthly} 
        companyId={id} 
        link={true} 
        externalLink={99} 
        subscriptionId={subscriptionId}
        cancelSubscription={cancelSubscription}
        planName={planName}
        />
    </div>
  );
};

PricingCardFree.propTypes = {
  users: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  discountPrice: PropTypes.number.isRequired
};

export default PricingCardFree;
