import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addComment } from '../../../actions/standupAnswer';
import InputNew from '../../elems/InputNew';

const CommentFormNew = ({ standupID, answerID, addComment }) => {
  const [text, setText] = useState('');

  return (
    <form
      className="form-app form-app-comments"
      onSubmit={e => {
        e.preventDefault();
        addComment(standupID, answerID, { text });
        setText('');
      }}
    >
      <InputNew 
        setText={setText}
        text={text}
      />
    </form>
  );
};

CommentFormNew.propTypes = {
  addComment: PropTypes.func.isRequired
};

export default connect(
  null,
  { addComment }
)(CommentFormNew);
