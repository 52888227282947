import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
  BsFillEmojiSmileFill, 
  BsFillEmojiHeartEyesFill, 
  BsFillEmojiFrownFill, 
  BsFillEmojiNeutralFill,
  BsFillEmojiDizzyFill, 
} from "react-icons/bs";
import { addMoodAnswer } from '../../actions/moodAnswer';
import { FormattedMessage } from 'react-intl';
import { Badge } from 'antd';
import { Popover } from 'antd';

const MoodIcon = ({
  item,
  allComments,
  showAllComments,
}) => {

  // Сейчас это не работает. Показ идет при ховере. 
  const [comment, showComment] = useState(allComments ? true : false);

  let icon = '';
  let color = '';

  if (item.grade === 1) { icon = <BsFillEmojiFrownFill />; color = 'red' }
  if (item.grade === 2) { icon = <BsFillEmojiDizzyFill />; color = 'orange' }
  if (item.grade === 3) { icon = <BsFillEmojiNeutralFill />; color = 'grey' }
  if (item.grade === 4) { icon = <BsFillEmojiSmileFill />; color = 'blue' }
  if (item.grade === 5) { icon = <BsFillEmojiHeartEyesFill />; color = 'green' }

  return (
    <Fragment>
      <div className="relative parent-dropdown">
        <p 
            className={`fs-40 mr-2 cursor  ${!comment && 'hover__scaled-1_2'} ${color}`}
            onClick={() => showComment(!comment)}
          >
          {icon}

          {item.text && item.text.length > 0 && (
            <span className={`absolute top small ${color}`}>1</span>
          )}
        </p>

        {item.text && item.text.length > 0 && (
          <div className="w-200px z-20000 bg__white dropdown dropdown-mood medium shadow light p-1">{item.text}</div>
        )}
      </div>
    </Fragment>
  );
};

MoodIcon.propTypes = {
  item: PropTypes.object,
};

export default MoodIcon;
