import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Logo from '../../../elems/Logo';
import PropTypes from 'prop-types';
import { login } from '../../../../actions/auth';
import { clearCustomOnboarding, getCustomOnboarding, addCustom } from '../../../../actions/custom';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../../layout/Spinner';
import Text from '../../elems/Text';
import Btn from '../../elems/WhiteBtn';
import Skip from '../../elems/Skip';
import SelectItem from "../../../elems/Select";
import DayItem from '../../../admin/elems/DayItem';
import QuestionItemNew from '../../../admin/elems/QuestionItemNew';
import AddQuestion from '../../../admin/elems/AddQuestion';
import QuestionItemArrayNew from '../../../admin/elems/QuestionItemArrayNew';
import TimePicker from 'react-time-picker';
import BlueBtn from '../../elems/BlueBtn';
import momentTz from 'moment-timezone'
import NotificationItem from '../../../admin/elems/NotificationItem';
import NotificationItemReports from '../../../admin/elems/NotificationItemReports';

const wordingTime = {
  hours: {
    placeholderId: "hours",
    placeholderMsg: "Hours",
  },
  minutes: {
    placeholderId: "minutes",
    placeholderMsg: "Minutes",
  }
}

// Select hour & minute options
const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
const hourOptions = hours.map(hour => {
  return (
    { label: hour, value: hour, name: 'localReportHour' }
  );
});

const minutes = ['00', '15', '30', '45'];
const minuteOptions = minutes.map(minute => {
  return (
    { label: minute, value: minute, name: 'localReportMinute' }
  );
});

let questionsArr = [
  { text: '', questionType: { label: `Q1: Text Field`, value: 'text' }, isRequired: true },
  { text: '', questionType: { label: `Q2: Single Select`, value: 'single_select' }, selectOptions: [{ label: '', value: '' }], isRequired: true },
  { text: '', questionType: { label: `Q3: Multiple Select`, value: 'multiple_select' }, selectOptions: [{ label: '', value: '' }], isRequired: false },
  { text: '', questionType: { label: `Q4: OKR Select`, value: 'okr_select' }, isRequired: true },
]

const Onboarding5 = ({ 
  user, 
  clearCustomOnboarding, 
  getCustomOnboarding,
  history,
  custom: { campaign },
  addCustom
}) => {
  const [formData, setFormData] = useState({
    name: '',
    questions: questionsArr,
    interval: '',
    scheduleDay: {},
    scheduleTime: '',
    emailIsActive: true,
    timezone: momentTz.tz.guess(),
    reportFrequency: { label: 'Once a day', value: 2, name: 'reportFrequency' },
    selectedEmailUsers: [{ value: 'Company', label: `Everyone`, team: 1 }],
    localReportTime: '15:00',
    localReportHour: '15',
    localReportMinute: '00',
    reportIsActive: false,
    isCheckinReport: false,
    isAsync: false,
    discordIsActive: false,
    visibility: { label: `Everyone`, value: 1, name: 'visibility' },
    slackIsActive: false,
    selectedSlackUsers: [],
    selectedOption: [],
  });

  const { emailIsActive, reportIsActive, localReportHour, localReportMinute } = formData;

  // ToDo: забирать из LS интервал
  useEffect(() => {
    
    if (!campaign) getCustomOnboarding()
    // getCustomOnboarding()

    campaign = JSON.parse(campaign);

    setFormData({
      ...formData,
      name: !campaign ? '' : campaign.name,
      questions: !campaign ? '' : campaign.questions,
      interval: !campaign ? '' : campaign.interval,
      scheduleDay: !campaign ? '' : campaign.scheduleDay,
      scheduleTime: !campaign ? '' : campaign.scheduleTime,
      slackIsActive: !campaign ? '' : campaign.slackIsActive,
      selectedSlackUsers: !campaign ? '' : campaign.selectedSlackUsers,
      selectedOption: !campaign ? '' : campaign.selectedOption,
    });
  }, [getCustomOnboarding, campaign && campaign.length]);

  const onSubmit = e => {
    e.preventDefault();

    let isOnboarding = true;
    addCustom(formData, history, isOnboarding);
    // clearCustomOnboarding(formData, history, 4);
  };

  const handleIntervalSelect = (selectedOption) => {
    setFormData({ ...formData, [selectedOption.name]: selectedOption.value });
  };

  let title = <FormattedMessage id="onboarding.checkin.title5" defaultMessage="How would you like to be notified ?" />
  let message = <FormattedMessage id="onboarding.checkin.msg5" defaultMessage="Receive notifications when check-in is completed" />
  let next = <FormattedMessage id="create.checkin" defaultMessage="Create Check-in" />
  const teamTitle =<FormattedMessage id="team.notifications" defaultMessage="Team member notifications" />;
  const emailNotificationsMsg = <FormattedMessage id="notif.email" defaultMessage="Send notifications through Email" />;
  const reportNotifications = <FormattedMessage id="email.reports" defaultMessage="Weekly Email Reports" />;
  const reportNotificationsDesc = <FormattedMessage id="email.reports.desc" defaultMessage="Get a report each Sunday, which will let you know how your check-in went" />;
  const reportNotificationsInput = <FormattedMessage id="email.reports.input" defaultMessage="Report Time" />;
  
  // console.log('======= campaign', campaign)
  // console.log('======= formData', formData)

  return (
    <Fragment>
      {!user ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="css-main onboarding bg__white">
            <div className="wrapper">
              <div className="flex">
                <div className="wr">
                  <div className="onboarding__content relative">
                    <div className="">
                      <div className="pb-8">
                        <Logo isNoLink={true} />
                      </div>
                      <Text title={title} text={message} page={4} />

                      <form className="pt-2 form-app form-app-okr">
                        <div className="mb-2">
                          <NotificationItemReports 
                            checkboxName={"reportIsActive"}
                            checkboxValue={reportIsActive}
                            formData={formData}
                            setFormData={setFormData}
                            defaultValue={{hour:localReportHour, minute: localReportMinute}}
                            handleSelect={handleIntervalSelect}
                            selectOptions={{hour: hourOptions, minute: minuteOptions}}
                            isClearable={false}
                            key={`reports-onboarding`}
                            wording={wordingTime}
                            isMulti={false}
                            isSearchable={true}
                            label={reportNotifications}
                            subTitle={reportNotificationsDesc}
                            inputLabel={reportNotificationsInput}
                            type={1}
                            section={2}
                            labelStyles={'fw-600 fs-14'}
                          />
                        </div>

                        <div className="mb-2">
                          <p className="fs-14 fw-600 dark-gray mb-xs">{teamTitle}</p>
                          <NotificationItem 
                            checkboxName={"emailIsActive"}
                            checkboxValue={emailIsActive}
                            formData={formData}
                            setFormData={setFormData}
                            key={`users-checkin-onboarding`}
                            label={emailNotificationsMsg}
                            labelStyles={'fw-600 fs-14'}
                            divStyles='w-100'
                          />
                        </div>

                        <div className="w-100 buttons pt-3">
                          <BlueBtn onClick={onSubmit} text={next} isLoader={false} />
                        </div>
                      </form>
                    </div>
                    
                    <Skip/>
                  </div>
                </div>

                <div className="onboarding__image onboarding__image__checkin__notifications"></div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

Onboarding5.propTypes = {
  addCustom: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  clearCustomOnboarding: PropTypes.func.isRequired,
  getCustomOnboarding: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  custom: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.user,
  custom: state.custom
});

export default connect(
  mapStateToProps,
  { login, clearCustomOnboarding, getCustomOnboarding, addCustom }
)(Onboarding5);
