import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '../layout/Menu';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import Widgets from '../widgets/Widgets';
import SubMenu from './SubMenu';
import FeedbackItem from '../feedback/FeedbackItem';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/no_content.svg';
import {
  getUserFeedbackById,
  getGivedUserFeedbackById,
  clearFeedback,
  requestFeedback,
} from '../../actions/feedback';
import { getCompanyProfiles, getProfileById } from '../../actions/profile';
import { getUserCompany } from '../../actions/company';
import { FormattedMessage } from 'react-intl';
import { feedbackCopy } from '../../utils/wording/feedback';
// import { kudosCopy } from '../../utils/wording/kudos';

const UserFeedback = ({
  clearFeedback,
  requestFeedback,
  getProfileById,
  getUserFeedbackById,
  getGivedUserFeedbackById,
  getCompanyProfiles,
  getUserCompany,
  company: { company },
  auth: { user },
  profile: { profile, profiles },
  feedback: { feedback, givedFeedback, loading },
  match,
}) => {
  useEffect(() => {
    getUserCompany();
    getCompanyProfiles();
    getUserFeedbackById(match.params.id);
    getGivedUserFeedbackById(match.params.id);
    getProfileById(match.params.id);
  }, [getUserCompany, getCompanyProfiles, getUserFeedbackById, getGivedUserFeedbackById, getProfileById]);
  
  useEffect(() => {
    // Clear state
    return () => {
      clearFeedback();
    }
  }, []);
  
  const [received, handleReceived] = useState(true);
  const [given, handleGiven] = useState(true);

  let titleLeft = <FormattedMessage id="received.feedback" defaultMessage="Feedback received"/>;
  let titleRight = <FormattedMessage id="given.feedback" defaultMessage="Feedback given"/>;
  let askFeedback = <FormattedMessage id="ask.feedback" defaultMessage="Ask for feedback"/>;

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {user && user.department ? (
          <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user && user.isAdmin && true} activeIndex={8} />
        ) : (
          <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} activeIndex={8} />
        )}

        <UserNav />
        
          <Fragment>
            {loading || !feedback || !user || !company || !profile ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="main main-dashboard">
                     <h5>
                      <FormattedMessage id="company.header.kudos" defaultMessage={`Feedback to ${profile && profile.firstName}`} />
                    </h5>
                  <SubMenu userId={profile._id} companyIndex={6} />
                  <div className="feeback">
                    {user._id.toString() !== match.params.id && (
                      <div className="text-right pb-1 flex align-center justify-end">
                      <p
                        onClick={() => requestFeedback(match.params.id, profile.firstName)}
                        className="medium grey pr-2 cursor"
                      >
                          {askFeedback}
                      </p>
                      <Link to={`/feedback/new/${match.params.id}`} className="btn btn-light">
                        <FormattedMessage
                          id="user.feedback.createBtn"
                          defaultMessage="Give Feedback"
                        />
                      </Link>
                      </div>
                    )}

                    <div className="my-4">
                        <Widgets
                          leftColor={'green'}
                          rightColor={'red'}
                          receivedRecognitions={feedback}
                          givedRecognitions={givedFeedback}
                          received={received}
                          handleReceived={handleReceived}
                          given={given}
                          handleGiven={handleGiven}
                          titleLeft={titleLeft}
                          titleRight={titleRight}
                        />
                    </div>

                      {company.plan !== 1 ? (
                        <Fragment>
                          {feedback.length === 0 && givedFeedback ? (
                            <div className="mt-5">
                              <NoContent 
                                message={feedbackCopy.noFeedbackMessage}
                                picture={updateImg}
                                alt={'No feedback'}
                                imgWidth={'w-300px'}
                              />
                            </div>
                          ) : (
                            <Fragment>
                              {feedback.map(feedback => (
                                <FeedbackItem
                                  key={feedback._id}
                                  feedback={feedback}
                                  auth={user}
                                />
                              ))}
                            </Fragment>
                          )}
                        </Fragment>
                      ) : (
                        <NoContent 
                          message={feedbackCopy.updatePlanMsg}
                          picture={updateImg}
                          alt={'Update plan'}
                          imgWidth={'w-300px'}
                          admin={user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0}
                        />
                      )}
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

UserFeedback.propTypes = {
  getCompanyProfiles: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getProfileById: PropTypes.func.isRequired,
  getUserFeedbackById: PropTypes.func.isRequired,
  getGivedUserFeedbackById: PropTypes.func.isRequired,
  clearFeedback: PropTypes.func.isRequired,
  requestFeedback: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  feedback: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  feedback: state.feedback
});

export default connect(
  mapStateToProps,
  { getUserCompany, getCompanyProfiles, 
    getUserFeedbackById, getGivedUserFeedbackById, 
    getProfileById, clearFeedback,
    requestFeedback,
  }
)(UserFeedback);
