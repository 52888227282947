import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '../layout/Menu';
import MainMenu from '../layout/Sidebar';
import UserNav from '../layout/UserNav';
import { connect } from 'react-redux';
import NoContent from '../utils/NoContentNew';
import Moment from 'react-moment';
import Spinner from '../layout/Spinner';
import successImg from '../../img/empty_states/payment.png';
import { FormattedMessage } from 'react-intl';

const PaymentSuccess = ({
  auth: { user, loading } 
}) => {
 
  const message = {
    title: <FormattedMessage id="payment.success" defaultMessage="Payment Success" />,
    msg: <FormattedMessage id="payments.sucess.msg" defaultMessage="Congrats on signing up for Focus premium." />,
    msg2: <FormattedMessage id="payments.sucess.msg2" defaultMessage="Schedule a demo below to get additional tips and tricks on how to get the most out of Focus" />,
    btnUrl: 'https://usefocus.co/demo',
    btnCta: <FormattedMessage id="join.demo" defaultMessage="Join Demo" />,
  };

  return (
    <Fragment>
      <Fragment>
        <div className="css-main bg-primary">
          <div className="wrapper">

            {user && !loading && (
              <MainMenu activeIndex={9} />
            )}

            <UserNav />
            <Fragment>
              {!user ? (
                <Spinner />
              ) : (
                <Fragment>
                  <div className="main main-dashboard mt-0">
                  <h5>
                    <FormattedMessage
                      id="payment"
                      defaultMessage="Payment"
                    />
                  </h5>
                  <NoContent
                    message={message}
                    picture={successImg}
                    alt={'Create first task'}
                    imgWidth={'w-30'}
                    button={1}
                    externalLink={1}
                    btnUrl={message.btnUrl}
                    btnCta={message.btnCta}
                    divStyles="row"
                    contentStyles="w-80 text-left ml-2"
                  />
                  </div>
                </Fragment>
              )}
            </Fragment>
          </div>
        </div>
      </Fragment>
    </Fragment>
  );
};

PaymentSuccess.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  null
)(PaymentSuccess);
