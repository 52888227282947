import React, { useState } from 'react';
import Logo from '../../../elems/Logo';
import { FormattedMessage } from 'react-intl';
import Text from '../../elems/Text';
import BlueBtn from '../../elems/BlueBtn';
import Skip from '../../elems/Skip';
import KRform from '../../../okrs/form/Kr';
import OkrTips from '../../../okrs/elements/OkrTipsNew';

const endOfTheYear = new Date();
endOfTheYear.setMonth(11);
endOfTheYear.setDate(31);

const KrCreate = ({ 
  keyResults, 
  onChangeKeyResults, 
  locale, 
  handleKeyResults, 
  handleAddMilestone,
  handleRemove,
  handleAdd,
  handleStepClick,
  user,
  isLoader,
  krLabels,
 }) => {

  const [displayTips, toggleDisplayTips] = useState(false);
  
  const handleDisplayTips = () => toggleDisplayTips((prev) => !prev);

  let title = <FormattedMessage id="title.onboarding.okr2" defaultMessage="Set up key results for your objective" />
  let message = <FormattedMessage id="message.onboarding.okr2" defaultMessage="Key Results are the measures and milestones that indicate how you will accomplish your Objective." />
  let createOKR = <FormattedMessage id="create.okr" defaultMessage="Create OKR" />
  
  let krTypeOptions = [
    { value: 'number', label: 'Number', name: 'krType' },
    { value: 'binary', label: 'Binary', name: 'krType' },
    { value: 'milestone', label: 'Milestone', name: 'krType' },
  ];

  let ownerOptions = user ? [{ value: user._id, label: `${user.firstName} ${user.lastName}` }] : [];

  return (
    <div className="wr">
      <div className="onboarding__content__kr flex flex-column">
        <div className="h-100">
          <div className="pb-8 relative">
            <Logo isNoLink={true} />
          </div>
          <Text title={title} text={message} page={2} />

          <div className="pt-3 pb-1 flex space-between">
            <p className="bold fs-14 no-w label-pr3 pb-0-xs">
              <FormattedMessage id="user.okr.kr.title" defaultMessage="Key results" />
            </p>

            <div className="green pointer" onClick={handleDisplayTips}>
              <FormattedMessage
                id="okr.tips.title"
                defaultMessage={`Tips on how to set OKRs`}
              />
            </div>
          </div>

          <div className="onboarding__content__kr__child form-app form-app-okr">
            <KRform 
              keyResults={keyResults}
              onChangeKeyResults={onChangeKeyResults}
              locale={locale}
              handleKeyResults={handleKeyResults}
              krLabels={krLabels}
              handleAddMilestone={handleAddMilestone}
              handleRemove={handleRemove}
              ownerOptions={ownerOptions}
              krTypeOptions={krTypeOptions}
              handleAdd={handleAdd}
              // isKrOptionsHide={true}
            />
          </div>

          <OkrTips locale={locale} displayTips={displayTips} />

          <div className="buttons pt-3 onboarding__content__button__260">
            <BlueBtn onClick={handleStepClick} text={createOKR} isLoader={isLoader} />
          </div>

          <Skip page={2}/>
          
        </div>
        
        {/* <Skip/> */}
      </div>
    </div>
  );
};

export default KrCreate;
