import React from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import isEnabled, { ENGAGEMENT } from "../../utils/features";

const TeamMenu = ({
  company: { company },
  companyIndex,
  teamId
}) => {
  return (
    <div className="sub-menu__page mt-2 mb-3">
      <Link
        to={`/team/${teamId}`}
        className={`medium sub-menu__item inline-block mr-2 mr-3-xs cursor ${companyIndex === 1 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="company.menu.checkins" defaultMessage="Check-ins" />
      </Link>
      <Link
        to={`/team/${teamId}/updates`}
        className={`medium sub-menu__item inline-block mr-2 mr-3-xs cursor ${companyIndex === 2 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="company.menu.updates" defaultMessage="Weekly Updates" />
      </Link>
      
      <Link
        to={`/team/${teamId}/objectives`}
        className={`medium sub-menu__item inline-block mr-2 mr-3-xs cursor ${companyIndex === 3 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="company.menu.objectives" defaultMessage="Objectives" />
      </Link>
      {isEnabled(ENGAGEMENT, { company }) && (
      <Link
        to={`/team/${teamId}/kudos`}
        className={`medium sub-menu__item inline-block mr-2 mr-3-xs cursor ${companyIndex === 4 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="company.menu.kudos" defaultMessage="Kudos" />
      </Link>
      )}
    </div>
  );
};

TeamMenu.propTypes = {
  companyIndex: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
  company: state.company
});

export default connect(mapStateToProps)(TeamMenu);