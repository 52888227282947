import React, { Fragment } from "react";
import PropTypes from 'prop-types';

const TableTitleNew = ({ name, items }) => {

  return (
    <Fragment>
      <div className="flex justify-align-baseline w-100 mb-1">
          <p className={`w-220px fs-12 grey_new bold text-left mb-0`}>
            {name.text}
        </p>
        
          <div className={`flex align-center mla`}>
            {items.length > 0 && (
              items.map((item, index) => (
                <p 
                  key={`table-${item._id ? item._id : index}`}
                  className={`fs-12 grey_new bold text-right ${index < 3 ? 'table_new__title' : `table_new__title_${item.width}`}`}
                >
                  {item.text}
                </p>
              ))
            )}
          </div>
      </div>
    </Fragment>
  );
};

TableTitleNew.propTypes = {
  items: PropTypes.array.isRequired,
};


export default TableTitleNew;
