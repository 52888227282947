import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import UnitCardItem from "./UnitCardItem";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { icons } from "../../../img/elements/icons";
import OkrCardItem from '../../okrs/OkrCardItem';

const UnitItem = ({
  unitOkrs,
  user,
  team,
  formDataParent,
  setFormDataParent,
}) => {

  const [displayGoals, toggleGoals] = useState(false);
  const firstElement = unitOkrs.length > 0 && unitOkrs[0];

  let completedOkrs = [];
  let averageProgress = 0;
  let totalProgress = 0;
  let progressColor = '';
  let allOkrs = unitOkrs.length > 0 ? unitOkrs.length : 0;

  // Data for okr unit
  {
    unitOkrs.length > 0 && unitOkrs.forEach(item => {
      if (item.progress >= 100) {
        completedOkrs.push(item);
      }

      totalProgress += item.progress;
    })    
  }

  // Progress
  averageProgress = Math.round(totalProgress / unitOkrs.length);
  if (averageProgress < 30) {
    progressColor = "red";
  } else if (averageProgress >= 30 && averageProgress < 60) {
    progressColor = "yellow";
  } else if (averageProgress >= 60) {
    progressColor = "green";
  }

  if (unitOkrs.length === 0) {
    averageProgress = 0;
  }

  return (

    <Fragment>
      <div className="cards pb-2">
        <div className="card-team">
            <div className="flex space-between align-center">
              <div className="card-team-name w-30">
                <div className="flex align-start">
                  {firstElement && firstElement.ownerAvatar && (
                    <Link to={`/team/${firstElement.team}`} className="user mr-14">
                      <img
                        src={firstElement.ownerAvatar && firstElement.ownerAvatar}
                        alt={`${firstElement.firstName}'s picture`}
                        className="round-img d-block avatar"
                        width="26"
                        height="26"
                      />
                    </Link>
                  )}
                  <div className="flex column">
                    <Link to={`/team/${firstElement.team}`}>
                      <p className="medium card-table card-table-team">
                        {team.type !== 3 ? (firstElement.teamName): (`${team.label}`)}
                      </p>
                    </Link>
                    <p
                      className="fs-14 grey_new pt-xs user-goals cursor"
                      onClick={() => toggleGoals(!displayGoals)}
                    >
                      {allOkrs}{' '}
                      <FormattedMessage
                        id="active.objectives"
                        defaultMessage="active objectives"
                      />{' '}
                    <span className="ml-xs icon__grey">
                      {!displayGoals ? icons.angle_down : icons.angle_up}
                    </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex mla">
                <p className="w-120px fs-14 bold mb-0 text-right">{completedOkrs.length}</p>
                <p className="w-100px fs-14 bold mb-0 text-right">{allOkrs}</p>
                <p className="w-100px fs-14 bold mb-0 text-right">{averageProgress}%</p>
              </div>
            </div>

            {displayGoals && (
              <div className="card-departments-team card-body-hidden p-3 bg__light active mt-1">
                <div className="card-question-item">
                  {unitOkrs.length > 0 && (
                    unitOkrs.map(item => (
                      <div className="mb-1" key={item._id} >
                        <OkrCardItem
                          okr={item}
                          formDataParent={formDataParent}
                          setFormDataParent={setFormDataParent}
                          user={user}
                          formType={2}
                          isHideUpdateBtn={true}
                          showAllKeyResults={false}
                          isHideDetailsModal={true}
                          titleIsLink={true}
                          titleLink={`/objectives/${item._id}`}
                          okrBoard={'0'}
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
          </div>
      </div>
    </Fragment>
  );
};

UnitItem.propTypes = {
  user: PropTypes.object.isRequired,
  team: PropTypes.object,
  unitOkrs: PropTypes.array,
};

export default UnitItem;
