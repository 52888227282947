import React, { Fragment, useEffect, useState, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UsersCardBody from './UsersCardBody';
import { FormattedMessage } from 'react-intl';
import { icons } from '../../../img/elements/icons';
import profile from '../../../reducers/profile';

const roleOptions = [
  { value: 'Member', label: 'Member' },
  { value: 'Head', label: 'Lead' },
  { value: 'Admin', label: 'Admin' },
]

const UsersCardEdit = ({
  user: { firstName, lastName, avatar, departmentName, department, email, jobTitle, _id, status, role },
  standupAnswers,
  value,
  onChange,
  departments = [],
  lang,
  createInvite,
  deleteUser,
  auth,
  searchKeyword
}) => {
  const [displayCardBody, toggleButton] = useState(false);
  const [showIcons, toggleShowIcons] = useState(false);

  const userLink = _id ? `/user/${_id}` : '#!';

  const resend = <FormattedMessage id="resend" defaultMessage="Resend" />;
  const pending = <FormattedMessage id="pending" defaultMessage="pending" />;

  let deleteCopy = `Are you sure you wish to delete ${firstName} ${lastName}? User will be deleted forever.`;

  const teamOptions = useMemo(() => {
    return [
      ...departments.map((item) => ({ value: item._id, label: item.name })),
      { value: '', label: 'No team' }
    ];
  }, [departments]);

  const handleChange = (e) => {
    e.persist();
    onChange(_id, e.target.name, e.target.value);
  };

  return (
    <>
      <tr className="form-app">
        <td className="text-left py-1">
          <Link to={userLink} className="user">
            <div className="flex">
              <img
                src={avatar}
                alt="User's avatar"
                className="round-img d-block avatar mr-1"
                width="26px"
                height="26px"
              />
              <div className="flex column space-around">
                <p className="medium">{`${firstName} ${lastName}`}</p>
              </div>
            </div>
          </Link>
        </td>

        <td className="text-left  medium">
          <input
            type="text"
            name="jobTitle"
            value={value && value.jobTitle}
            onChange={handleChange}
            className="h-30 mr-1"
          />
        </td>

        <td className="text-left medium">
          <select
            name="department"
            value={value && value.department || ''}
            onChange={handleChange}
            className={'h-30 w-150px'}
          >
            {teamOptions.map((item) => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </td>

        <td className="text-left medium pl-1">
          {role !== 'Owner' && role !== 'Superadmin' ? (
            <select
              name="role"
              value={value && value.role}
              onChange={handleChange}
              className={'h-30'}
            >
              {roleOptions.map((item) => (
                <option value={item.value} key={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          ) : (
            <p className="fs-16 pl-1">{role}</p>
          )}
          
        </td>

        <td className="text-right medium">{standupAnswers.length}</td>

        <td className={status === 'pending_invitation' ? 'grey text-right medium' : 'text-right medium'}>
          {status !== 'pending_invitation' ? (
            email
          ) : (
            <Fragment>
              <span className="small grey mr-3">{pending}</span>
              <span className="small grey cursor underline" onClick={() => createInvite(email, lang, null)}>
                {resend}
              </span>
            </Fragment>
          )}
        </td>
        <td className="text-right">
          {status !== 'pending_invitation' && (
            <button
              onClick={() => toggleButton(!displayCardBody)}
              className="btn-action no_btn"
              type="button"
            >
              {displayCardBody ? icons.angle_up : icons.angle_down}
            </button>
          )}
        </td>

        {auth && auth.isAdmin && (
          <td className={`w-30px`}>
            <div className="flex align-center br-4 brd brd-grey__light bg__white relative">
              <div
                className={`no_btn relative h-34 px-1 icon__box ${showIcons ? 'icon__box_active' : ''}`}
                onClick={() => toggleShowIcons(!showIcons)}
              >
                <span className="w-50px lh-28">{icons.dots}</span>
              </div>
              {showIcons && (
                <div className="w-100px z-20000 light-shadow absolute brd brd-grey__light br-4 dropdown-40 right bg__white">
                  <button
                    type="button"
                    onClick={() => {
                      if (window.confirm(deleteCopy)) deleteUser(_id);
                    }}
                    className="w-100 icon__box fs-14 icon__grey no_btn px-1 lh-30"
                  >
                    {icons.trash_small} <FormattedMessage id="delete" defaultMessage="Delete" />
                  </button>
                </div>
              )}
            </div>
          </td>
        )}
      </tr>

      {displayCardBody && <UsersCardBody firstName={firstName} standupAnswers={standupAnswers} _id={_id} />}
    </>
  );
};

UsersCardEdit.propTypes = {
  user: PropTypes.object.isRequired,
  standupAnswers: PropTypes.array.isRequired
};

export default memo(UsersCardEdit);
