import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import UserNav from '../layout/UserNav';
import Logo from '../elems/Logo';
import { createCampaignAndEmail } from '../../actions/outreachEmail';
import { setAlert } from '../../actions/alert';
import Spinner from '../layout/Spinner';
import { FormattedMessage } from 'react-intl';

const OutreachCampaignForm = ({
  createCampaignAndEmail,
  auth: { user, loading },
  setAlert,
  history
}) => {
  const [formData, setFormData] = useState({
    name: '',
    title: '',
    text: '',
  });

  let { name, title, text } = formData;

  const onChange = e => {
    e.preventDefault();

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = e => {
    e.preventDefault();
    
    createCampaignAndEmail(name, title, text, history);
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <Fragment>
      <div className="css-main bg-primary">
          <div className="wrapper">
            <div className="main_menu absolute"><Logo /></div>
            <UserNav />

            {loading || !user && user.role !== 'Superadmin' ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="main mxa user-standups pl-0 pt-0-xs mb-5 pb-5">
                  <div className="standups mt-3">
                    <div className="section-title">
                      <h5>
                        <FormattedMessage
                          id="outreacher.form.header"
                          defaultMessage="Create the new campaign"
                        />
                      </h5>
                      <p
                        onClick={e => goBack()}
                        className="btn-secondary btn-secondary-back"
                      >
                        <FormattedMessage id="go.back" defaultMessage="Go Back" />
                      </p>
                    </div>

                    <div className="component standups-daily my-2 p-3 light-shadow">

                      <div className="form-full">
                        <div className="py-2">
                          <p className="meduim">
                            <FormattedMessage
                              id="outreach.form.subHeader"
                              defaultMessage="Let's make something great!"
                            />
                          </p>
                          <div className="line" />

                          <p className="small bold">Personalisation:</p>
                          <p className="small">{`{name}`}</p>
                          <p className="small">{`{email}`}</p>
                          <p className="small">{`{company}`}</p>
                          <p className="small">{`{position}`}</p>
                          <p className="small">{`{comment}`}</p>
                          <p className="small">{`{website}`}</p>
                          <p className="small">{`{articleName}`}</p>
                          <p className="small">{`{articleUrl}`}</p>
                          <p className="small">{`{source}`}</p>
                          <p className="small">{`{jobPostings}`}</p>
                          <p className="small">{`{vcRound}`}</p>
                          <p className="small">{`{vcTime}`}</p>
                          <p className="small">Links: {`<a href="https://usefocus.co/" target="_blank">Focus</a>`}</p>
                        </div>

                        <form
                          className="form-app form-app-standup"
                          onSubmit={e => onSubmit(e)}
                        >
                          <div>
                                <div>
                                  <p className="bold medium inline mr-1">
                                    <FormattedMessage
                                      id="name"
                                      defaultMessage="Name"
                                    />
                                  </p>
                                </div>
                                <FormattedMessage
                                  id="outreach.form.add.name"
                                  defaultMessage="Type a campaign name"
                                >
                                  {msg => (
                                    <input
                                      className="my-1 w-100"
                                      type="name"
                                      required
                                      placeholder={msg}
                                      name="name"
                                      value={name}
                                      onChange={e => onChange(e)}
                                    />
                                  )}
                              </FormattedMessage>
                              </div>

                              <div>
                                <div>
                                  <p className="bold medium inline mr-1">
                                    <FormattedMessage
                                      id="title"
                                      defaultMessage="Title"
                                    />
                                  </p>
                                </div>
                                <FormattedMessage
                                  id="outreach.form.add.title"
                                  defaultMessage="Type a title"
                                >
                                  {msg => (
                                    <input
                                      className="my-1 w-100"
                                      type="title"
                                      required
                                      placeholder={msg}
                                      name="title"
                                      value={title}
                                      onChange={e => onChange(e)}
                                    />
                                  )}
                              </FormattedMessage>
                              </div>

                              <div>
                                <div>
                                  <p className="bold medium inline mr-1">
                                    <FormattedMessage
                                      id="text"
                                      defaultMessage="Text"
                                    />
                                  </p>
                                </div>
                                <FormattedMessage
                                  id="outreach.form.add.text"
                                  defaultMessage="Add text"
                                >
                                  {msg => (
                                    <textarea
                                      className="my-1 w-100 pre-line"
                                      rows="6"
                                      type="text"
                                      required
                                      placeholder={msg}
                                      name="text"
                                      value={text}
                                      onChange={e => onChange(e)}
                                    />
                                  )}
                              </FormattedMessage>
                              </div>
                          <button type="submit" className="btn btn-submit mt-1">
                            <FormattedMessage
                              id="standup.form.submit"
                              defaultMessage="Submit"
                            />
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
        </div>
      </div>
    </Fragment>
  );
};

OutreachCampaignForm.propTypes = {
  createCampaignAndEmail: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { createCampaignAndEmail, setAlert }
)(withRouter(OutreachCampaignForm));
