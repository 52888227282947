import React, { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUpdateSettings, editUpdate } from '../../actions/update';
import { getUserCompany } from '../../actions/company';
import { getSlackUsers, getSlackChannels } from '../../actions/slack';
import { getDiscordChannels, getDiscordFocusUsers } from '../../actions/discord';
import { getCompanyProfiles } from '../../actions/profile';
import Logo from '../elems/Logo';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import TimezonePicker from 'react-timezone';
import { setAlert } from '../../actions/alert';
import 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    fontSize: '14px',
  }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
}


const EditUpdates = ({
  getUpdateSettings,
  editUpdate,
  update: { settings, loading },
  auth: { user },
  history,
  getUserCompany,
  profile: { profiles },
  getCompanyProfiles,
  company: { company },
  slack,
  getSlackUsers,
  getSlackChannels,
  getDiscordChannels, 
  getDiscordFocusUsers,
  discord
}) => {
  const [formData, setFormData] = useState({
    questions: [],
    scheduleTime: '',
    scheduleDay: {},
    interval: '',
    timezone: '',
    emailIsActive: settings && settings.emailIsActive,
    slackIsActive: settings && settings.slackIsActive,
    reportIsActive: settings && settings.reportIsActive,
    localReportTime: '',
    localReportHour: '',
    localReportMinute: '',
    isAsync: settings && settings.isAsync,
    slackReportChannels: settings && settings.slackReportChannels,
    selectedOption: [],
    selectedChannelOption: [],
    selectedEmailUsers: [],
    selectedSlackUsers: [],
    okrQuestion: {},
    isPrivate: settings && settings.isPrivate,
    discordIsActive: settings && settings.discord && settings.discord.isActive,
    selectedDiscordUsers: [],
    discordReportChannels: [],
    discordUserOptions: [],
    discordConnections: [],
  });

  const { questions, scheduleTime, scheduleDay, interval, timezone,
    slackIsActive, emailIsActive, isAsync, slackReportChannels,
    localReportHour,
    localReportMinute,
    reportIsActive,
    okrQuestion,
    isPrivate,
    discordIsActive,
    selectedDiscordUsers,
    discordReportChannels,
    discordUserOptions,
    discordConnections,
  } = formData;

  const [reportChannels, toggleReportChannels] = useState(false);
  const [emailUsersForm, toggleEmailUsers] = useState(false);
  const [slackUsersForm, toggleSlackUsers] = useState(false);
  const [discordUsersForm, toggleDiscordUsers] = useState(false);
  const [discordReportForm, toggleDiscordReportChannels] = useState(false);

  let options = [];
  let choosedUsers = [];
  let channelOptions = [];
  let choosedChannels = [];
  let choosedEmailUsers = [];
  let emailUsersOptions = [];
  let choosedSlackUsers = [];
  let slackUserAndChannelOptions = [];
  let discordUserAndChannelOptions = [];
  let userOptions = [];
  let discordUserOptionsTemplate = [];

  // Get Email Users to put them in select
  // Choosed users
  settings && settings.emailUsers.forEach(user => {
    let u = {
      value: user.email,
      label: user.name,
      team: user.team
    }

    choosedEmailUsers.push(u);
  })

  {
    company && company.departments.length > 0 &&
      company.departments.forEach((department, index) => {

        if (user && user.department === null) {
          let team = {
            value: department._id,
            label: `${department.name} team`,
            team: 2
          };
  
          emailUsersOptions.unshift(team);
        } else if (user && user.department !== null && department._id !== user.department) {
          let team = {
            value: department._id,
            label: `${department.name} team`,
            team: 2
          };
  
          emailUsersOptions.unshift(team);
        }

        if (user && index === company.departments.length - 1) {

          // Second option is the team if exists
          if (user.department && department._id === user.department) {
            let team = { value: department._id, label: `😎 ${department.name} team`, team: 2 };
            emailUsersOptions.unshift(team);
          }          
        }
      });
  }

  // Users for options
  {
    profiles &&
      profiles.forEach((profile, index) => {

        let coworker = {
          value: profile.email,
          label: `${profile.firstName} ${profile.lastName}`,
          team: 3,
          id: profile._id.toString(), 
        };

        emailUsersOptions.push(coworker);
        userOptions.push(coworker);

        if (profiles && index === profiles.length - 1) {
          // Third option is the company
          let name = { value: 'Company', label: `💪 Everyone`, team: 1 };
          emailUsersOptions.unshift(name);
        }
      });
  }

  // Get Slack Users to put them in select (for participants select)
  // Choosed users
  settings && settings.slackUsers.forEach(user => {
    let u = {
      value: user.value,
      label: user.name,
      team: user.team
    }

    choosedSlackUsers.push(u);
  })

  // Slack options (users + channels in one arr)
  slack.users &&
    slack.users.forEach((user) => {
      let u = {
        value: user.user_id,
        label: user.name,
        team: 3
      };

      slackUserAndChannelOptions.push(u);
    });

  slack.channels &&
    slack.channels.forEach((channel, index) => {
      let u = {
        value: channel.channel_id,
        label: `#${channel.name}`,
        team: 2
      };

      slackUserAndChannelOptions.unshift(u);

      if (slack && index === slack.channels.length - 1) {
         // First option is the company
          let name = { value: 'Company', label: `💪 Everyone`, team: 1 };
          slackUserAndChannelOptions.unshift(name);
       }
    });

  // Users Options for Select format (for delivery report)
  slack.users &&
    slack.users.forEach(user => {
      let u = {
        value: user.user_id,
        label: user.name,
        avatar: user.avatar
      };

      options.push(u);
    });

  // Channels Options for Select format
  slack.channels &&
    slack.channels.forEach(channel => {
      let u = {
        value: channel.channel_id,
        label: channel.name
      };

      channelOptions.push(u);
    });

  // Users & Channels Default values for Select format
  settings && settings.slackReportChannels &&
    settings.slackReportChannels.forEach(item => {
      if (item.user_id) {
        let с = {
          value: item.user_id,
          label: item.name
        };

        choosedUsers.push(с);
      }

      // For channels
      if (item.channel_id) {
        let с = {
          value: item.channel_id,
          label: item.name
        };

        choosedChannels.push(с);
      }
    });

  // Discord options (users + channels in one arr)
  discord.users &&
  discord.users.forEach((user, index) => {

    if (discord && index === discord.users.length - 1) {
      // First option is the company
      let name = { value: 'Company', label: `💪 Everyone`, team: 1 };
      discordUserOptionsTemplate.unshift(name);
    }

    discordUserOptionsTemplate.push(user);
    discordUserAndChannelOptions.push(user);
  });

  discord.channels &&
  discord.channels.forEach((channel, index) => {
    let c = {
      value: channel.value,
      label: `#${channel.label}`,
      type: channel.type,
      team: 2
    };

    discordUserAndChannelOptions.unshift(c);
  }); 

  useEffect(() => {
    getUpdateSettings();
    getUserCompany();
    getCompanyProfiles();
    company && company.isSlack && getSlackUsers(company._id, company.slackTeamId);   
    company && company.isSlack && getSlackChannels(company._id, company.slackTeamId);
    company && company.discord && company.discord.isActive && getDiscordFocusUsers(company._id, company.discord.guildId);
    company && company.discord && company.discord.isActive && getDiscordChannels(company._id, company.discord.guildId);
    
    setFormData({
      ...formData,
      questions: !settings ? '' : settings.questions,
      scheduleTime: !settings ? '' : settings.scheduleTime,
      scheduleDay: !settings ? '' : settings.scheduleDay,
      interval: !settings ? '' : settings.interval,
      timezone: !settings ? '' : settings.timezone,
      emailIsActive: !settings ? false : settings.emailIsActive,
      localReportTime: !settings ? '' : settings.localReportTime,
      localReportHour: !settings ? '' : settings.localReportTime.slice(0, 2),
      localReportMinute: !settings ? '' : settings.localReportTime.slice(3),
      reportIsActive: !settings ? false : settings.reportIsActive,
      slackIsActive: !settings ? false : settings.slackIsActive,
      isAsync: !settings ? false : settings.isAsync,
      slackReportChannels: !settings ? false : settings.slackReportChannels,
      selectedOption: !slack || !settings ? '' : choosedUsers,
      selectedChannelOption: !slack || !settings ? '' : choosedChannels,
      selectedEmailUsers: !settings ? '' : choosedEmailUsers,
      selectedSlackUsers: !slack || !settings ? '' : choosedSlackUsers,
      okrQuestion: !settings ? { isActive: false, text: '' } : settings.okrQuestion,
      isPrivate: !settings ? false : settings.isPrivate,
      discordIsActive: !settings || !settings.discord ? false : settings.discord.isActive,
      discordReportChannels: !settings || !settings.discord ? '' : settings.discord.reportChannels,
      selectedDiscordUsers: !discord || !settings || !settings.discord ? '' : settings.discord.channels,
      discordUserOptions: !discord ? [] : discord.users,
    });
  }, [
    loading, getUpdateSettings, getCompanyProfiles, getUserCompany, 
    getSlackUsers, getSlackChannels, 
    company && company.discord && company.discord.isActive,
    JSON.stringify(settings),
  ]);

  useEffect(() => {
    setFormData({
      ...formData,
      discordUserOptions: !discord ? [] : discord.users,
    });
  }, [JSON.stringify(discord)]);

  // Select hour & minute options
  const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  const hourOptions = hours.map(hour => {
        return (
          <option value={hour} key={hour}>
            {hour}
          </option>
        );
      });

  const minutes = ['00', '15', '30', '45'];
  const minuteOptions = minutes.map(minute => {
        return (
          <option value={minute} key={minute}>
            {minute}
          </option>
        );
      });

  
  const handleEmailUsers = selectedOption => {
    setFormData({ ...formData, selectedEmailUsers: selectedOption });
  };

  const handleSlackUsers = selectedOption => {
    setFormData({ ...formData, selectedSlackUsers: selectedOption });
  };

  const handleSlackUsersForm = e => {
    getSlackUsers(company._id, company.slackTeamId);
    getSlackChannels(company._id, company.slackTeamId);
    toggleSlackUsers(!slackUsersForm);
  };
  
  const handleChangeReport = selectedOption => {
    setFormData({ ...formData, selectedOption: selectedOption });
  };

  const handleChangeChannelReport = selectedChannelOption => {
    setFormData({ ...formData, selectedChannelOption: selectedChannelOption });
  };

  const handleReport = e => {
    toggleReportChannels(!reportChannels);
  };

  const onChangeSelect = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onChangeTimezone = (timezone, name) => {
    setFormData({ ...formData, [name]: timezone });
  };
  
  const onChangeDay = (e) => {
    let selected = e.target.selectedOptions[0];
    scheduleDay.day = selected.text;
    scheduleDay.index = e.target.value;

    setFormData({ ...formData, scheduleDay: scheduleDay });
  };

  const onSubmit = e => {
    if (reportIsActive && !localReportHour || reportIsActive && !localReportMinute) {
      return setAlert(
        <FormattedMessage
          id="admin.standups.alert.reportTime"
          defaultMessage="Please specify the time for report delivery"
        />,
        'danger'
      );
    } 

    e.preventDefault();
    editUpdate(formData, history);
  };

  const onChange = (e, index) => {
    questions[index].text = e.target.value;

    setFormData({ ...formData, questions: questions });
  };

  const handleRemove = index => {
    questions.splice(index, 1);

    setFormData({ ...formData, questions: questions });
  };

  const handleAdd = e => {
    e.preventDefault();

    setFormData({ ...formData, questions: [...questions, {}] });
  };

  const handleTime = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Discord
  const handleDiscordUsersForm = e => {
    toggleDiscordUsers(!discordUsersForm);
  };

  const handleDiscordUsers = selectedOption => {
    setFormData({ ...formData, selectedDiscordUsers: selectedOption });
  };

  const handleChangeDiscordReport = selectedOption => {
    setFormData({ ...formData, discordReportChannels: selectedOption });
  };

  const handleDiscordReport = e => {
    toggleDiscordReportChannels(!discordReportForm);
  };

  const onChangeConnectDiscord = (selectedOption, user) => {
    let obj = {
      user,
      selectedOption
    }

    discordConnections.push(obj);
    
    setFormData({ ...formData, discordConnections: discordConnections });
  };

  return (
    <Fragment>
      <div className="css-main bg-primary">
          <div className="wrapper">
            <div className="main_menu absolute"><Logo /></div>
            <UserNav />

            <Fragment>
              {!settings || loading ? (
                <Spinner />
              ) : (
                  <div className="main mxa pl-0 main-profile-admin">
                    <div className="standups-admin pt-3 mt-0-xs pt-1-xs">
                      <div className="section-title">
                        <h5>
                          <FormattedMessage id="admin.dashboard.edit.updates.header" defaultMessage="Edit Weekly Updates Campaign" />
                        </h5>
                        <Link
                          to="/settings"
                          className="btn-secondary btn-secondary-back"
                        >
                          <FormattedMessage id="go.back" defaultMessage="Go Back" />
                        </Link>
                      </div>

                      <div className="component edit-updates-weekly my-2 p-3 light-shadow">
                        <p className="sub-title focus-yellow focus">
                          <FormattedMessage id="admin.dashboard.edit.questions" defaultMessage="Questions" />
                        </p>

                        <div className="form-full">
                          <div className="py-2">
                            <p className="large bold">
                              <FormattedMessage id="admin.dashboard.edit.updatesQuestions" defaultMessage="Weekly Updates questions" />
                            </p>
                            <p className="meduim">
                              <FormattedMessage id="admin.dashboard.edit.updates.changes" defaultMessage="All changes will implemented at the next weekly update" />
                            </p>
                          </div>

                          <form
                            className="form-app form-app-update"
                            onSubmit={e => onSubmit(e)}
                          >
                            <Fragment>
                              {questions &&
                                questions.map((question, index) => (
                                  <div
                                    key={`${questions.length}-${index}`}
                                    className="form-main form-main-inline"
                                  >
                                    <p className="bold medium block question">
                                      <FormattedMessage id="admin.dashboard.edit.questionItem" defaultMessage="Question" />
                                      {' '}{index + 1}
                                    </p>
                                    <FormattedMessage id="admin.dashboard.edit.questionInput" defaultMessage="Enter your question">
                                      {msg => <input
                                        required
                                        className="question-input"
                                        type="text"
                                        placeholder={msg}
                                        name={index}
                                        value={question.text || ''}
                                        onChange={e => onChange(e, index)}
                                      />}
                                    </FormattedMessage>
                                    <button
                                      type="button"
                                      className="btn-secondary btn-secondary-focus btn-secondary-focus-delete hide-sm"
                                      onClick={() => handleRemove(index)}
                                    >
                                      <FormattedMessage id="admin.dashboard.edit.remove" defaultMessage="Remove" />
                                    </button>
                                    <button
                                      type="button"
                                      className="btn-secondary btn-secondary-focus btn-secondary-focus-delete hide-large btn-mobile-campaign"
                                      onClick={() => handleRemove(index)}
                                    >
                                      x
                                  </button>
                                  </div>
                                ))}
                            </Fragment>

                            <div className="okr-question form-main form-main-inline">
                              <p className="bold medium block question">
                                <FormattedMessage id="okr.question" defaultMessage="OKR Question" />
                              </p>
                              <FormattedMessage id="okr.question.details" defaultMessage="People will be able to choose an OKR in the list of all OKRs">
                                {msg => <input
                                  required
                                  className="question-input"
                                  type="text"
                                  placeholder={msg}
                                  name={okrQuestion.text}
                                  value={okrQuestion.text || ''}
                                  onChange={e => {
                                    e.preventDefault();
                                    setFormData({
                                        ...formData,
                                        okrQuestion: { ...okrQuestion, text: e.target.value }
                                      });
                                  }}
                                />}
                              </FormattedMessage>

                              <div className="checkbox email pl-3 mb-2-xs">
                                  <div className="checkbox-item active-status active-status-new">
                                    <input
                                      type="checkbox"
                                      id="okrQuestion.isActive"
                                      name="okrQuestion.isActive"
                                      checked={okrQuestion.isActive}
                                      value={okrQuestion.isActive}
                                      onChange={e => {
                                        setFormData({
                                          ...formData,
                                          okrQuestion: { ...okrQuestion, isActive: !okrQuestion.isActive }
                                        });
                                      }}
                                    />
                                    <label htmlFor="okrQuestion.isActive">
                                      {okrQuestion.isActive ? (
                                        <FormattedMessage
                                          id="admin.dashboard.edit.email.active"
                                          defaultMessage="Active"
                                        />
                                      ) : (
                                          <FormattedMessage
                                            id="admin.dashboard.edit.email.disable"
                                            defaultMessage="Disabled"
                                          />
                                        )}
                                    </label>
                                  </div>
                                </div>
                            </div>

                            <button
                              type="button"
                              className="btn-secondary btn-secondary-back m-2 block-wp"
                              onClick={e => handleAdd(e)}
                            >
                              <FormattedMessage id="admin.dashboard.edit.add" defaultMessage="+ Add Question" />
                            </button>

                            <Fragment>
                              <p className="sub-title focus-yellow focus my-2">
                                <FormattedMessage id="admin.dashboard.edit.updates.schedule" defaultMessage="Schedule and Notifications" />
                              </p>
                              <div className="form-main form-main-inline mt-2">
                                <p className="bold medium block question">
                                  <FormattedMessage id="admin.dashboard.edit.time" defaultMessage="Time" />
                                </p>
                                <FormattedMessage id="admin.dashboard.edit.timeInput" defaultMessage="Enter the time">
                                  {msg => <input
                                    className="small-input"
                                    type="text"
                                    placeholder={msg}
                                    name="scheduleTime"
                                    value={scheduleTime || ''}
                                    onChange={e => handleTime(e)}
                                  />}
                                </FormattedMessage>

                                <p className="bold medium block question">
                                  <FormattedMessage id="admin.dashboard.edit.timezone" defaultMessage="Timezone" />
                                </p>
                                <TimezonePicker
                                  name="timezone"
                                  value={timezone}
                                  onChange={timezone =>
                                    onChangeTimezone(timezone, 'timezone')
                                  }
                                  inputProps={{
                                    placeholder: { timezone },
                                    name: 'timezone'
                                  }}
                                />
                              </div>

                              <div className="form-main form-main-inline mt-2">
                                <p className="bold medium block question">
                                  <FormattedMessage id="admin.dashboard.edit.update.interval" defaultMessage="Interval" />
                                </p>
                                <select name='interval' value={interval} onChange={e => onChangeSelect(e)}>
                                  <option value='Weekly'>Weekly</option>
                                  <option value='Biweekly'>Biweekly</option>
                                </select>

                              </div>

                              <div className="form-main</div> form-main-inline mt-2">
                                <p className="bold medium block question">
                                  <FormattedMessage id="admin.dashboard.edit.update.day" defaultMessage="Day" />
                                </p>

                              <select name='scheduleDay' value={scheduleDay.index} onChange={e => onChangeDay(e)}>
                                  <option value='1'> Monday</option>
                                  <option value='2'> Tuesday</option>
                                  <option value='3'> Wednesday</option>
                                  <option value='4'> Thursday</option>
                                  <option value='5'> Friday</option>
                                  <option value='6'> Saturday</option>
                                  <option value='0'> Sunday</option>
                              </select>
                              </div>

                              <div className="form-main form-main-inline standups-time mt-2">
                                <p className="bold medium block question w-100-xs">
                                  <FormattedMessage
                                    id="admin.dashboard.edit.email"
                                    defaultMessage="Email notifications"
                                  />
                                </p>
                                <div className="checkbox email pl-3 mb-2-xs">
                                  <div className="checkbox-item active-status active-status-new">
                                    <input
                                      type="checkbox"
                                      id="emailIsActive"
                                      name="emailIsActive"
                                      checked={emailIsActive}
                                      value={emailIsActive}
                                      onChange={e => {
                                        setFormData({
                                          ...formData,
                                          emailIsActive: !emailIsActive
                                        });
                                      }}
                                    />
                                    <label htmlFor="emailIsActive">
                                      {emailIsActive ? (
                                        <FormattedMessage
                                          id="admin.dashboard.edit.email.active"
                                          defaultMessage="Active"
                                        />
                                      ) : (
                                          <FormattedMessage
                                            id="admin.dashboard.edit.email.disable"
                                            defaultMessage="Disabled"
                                          />
                                        )}
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="form-main form-main-inline standups-time mt-2">
                                <p className="bold medium block question w-100-xs">
                                  <FormattedMessage
                                    id="admin.dashboard.edit.report"
                                    defaultMessage="Weekly email reports"
                                  />
                                </p>

                                <div className="checkbox email pl-3">
                                  <div className="checkbox-item active-status active-status-new">
                                    <input
                                      type="checkbox"
                                      id="reportIsActive"
                                      name="reportIsActive"
                                      checked={reportIsActive}
                                      value={reportIsActive}
                                      onChange={e => {
                                        setFormData({
                                          ...formData,
                                          reportIsActive: !reportIsActive
                                        });
                                      }}
                                    />
                                    <label htmlFor="reportIsActive">
                                      {reportIsActive ? (
                                        <FormattedMessage
                                          id="admin.dashboard.edit.report.active"
                                          defaultMessage="Active"
                                        />
                                      ) : (
                                        <FormattedMessage
                                          id="admin.dashboard.edit.report.disable"
                                          defaultMessage="Disabled"
                                        />
                                      )}
                                    </label>
                                  </div>
                                </div>

                                <p className="bold medium block question mla">
                                  <FormattedMessage
                                    id="admin.dashboard.edit.reportTime"
                                    defaultMessage="Report Time"
                                  />
                                </p>

                                {/* ДЛЯ ПРОДА */}
                                <select
                                    className="small-input w-80px mr0 input__report_time mb-0-xs"
                                    name="localReportHour"
                                    value={localReportHour}
                                    onChange={e => handleTime(e)}
                                  >
                                    {hourOptions}
                                </select>
                                
                                <span className="medium mx-1">:</span>

                                <select
                                    className="small-input w-80px mb-0-xs mr-0-xs"
                                    name="localReportMinute"
                                    value={localReportMinute}
                                    onChange={e => handleTime(e)}
                                  >
                                    {minuteOptions}
                                </select>

                                {/* ДЛЯ ТЕСТА */}
                                {/* <FormattedMessage
                                  id="admin.dashboard.edit.reportHourInput"
                                  defaultMessage="Enter the hour"
                                >
                                  {msg => (
                                    <input
                                      className="small-input w-60px mr-1"
                                      type="text"
                                      placeholder={msg}
                                      name="localReportHour"
                                      value={localReportHour || ''}
                                      onChange={e => handleTime(e)}
                                    />
                                  )}
                                </FormattedMessage>

                                <FormattedMessage
                                  id="admin.dashboard.edit.reportMinuteInput"
                                  defaultMessage="Enter the hour"
                                >
                                  {msg => (
                                    <input
                                      className="small-input w-60px"
                                      type="text"
                                      placeholder={msg}
                                      name="localReportMinute"
                                      value={localReportMinute || ''}
                                      onChange={e => handleTime(e)}
                                    />
                                  )}
                                </FormattedMessage> */}

                            </div>
                            </Fragment>

                            <p className="small w-100-xs block">
                              <FormattedMessage
                                id="admin.dashboard.edit.report.delivery"
                                defaultMessage={`Only <b>Admins</b> will get email reports. If you want to add a user, drop us the message via chat or ${process.env.REACT_APP_EMAIL}.`}
                                values={{ b: (...chunks) => <b>{chunks}</b> }}
                              />
                            </p>

                            <Fragment>
                              <p className="sub-title focus-yellow focus my-2">
                                <FormattedMessage
                                  id="privacy"
                                  defaultMessage="Privacy"
                                />
                              </p>

                              <div className="form-main form-main-inline mt-2">
                                <p className="bold medium block question w-100-xs w-30">
                                  <FormattedMessage
                                    id="private.answers"
                                    defaultMessage="Private answers"
                                  />
                                </p>

                                <div className="checkbox status pl-3 w-30">
                                    <div className="checkbox-item active-status active-status-new">
                                      <input
                                        type="checkbox"
                                        id="isPrivate"
                                        name="isPrivate"
                                        checked={isPrivate}
                                        value={isPrivate}
                                        onChange={e => {
                                          setFormData({
                                            ...formData,
                                            isPrivate: !isPrivate
                                          });
                                        }}
                                      />
                                      <label htmlFor="isPrivate">
                                        {isPrivate ? (
                                          <FormattedMessage
                                            id="admin.dashboard.edit.slack.active"
                                            defaultMessage="Active"
                                          />
                                        ) : (
                                            <FormattedMessage
                                              id="admin.dashboard.edit.slack.disable"
                                              defaultMessage="Disabled"
                                            />
                                          )}
                                      </label>
                                    </div>
                                  </div>

                              </div>

                              <p className="small w-100-xs">
                                <FormattedMessage
                                  id="private.campaign.description1"
                                  defaultMessage="<b>Private campaign: </b>Only <b>admins and authors</b> will be able to read answers."
                                  values={{ b: (...chunks) => <b>{chunks}</b> }}
                                />
                              </p>
                              <p className="small w-100-xs">
                                <FormattedMessage
                                  id="private.campaign.description2"
                                  defaultMessage="<b>Public campaign: </b> Everyone can see answers."
                                  values={{ b: (...chunks) => <b>{chunks}</b> }}
                                />
                              </p>
                          </Fragment>

                            <Fragment>
                              <p className="sub-title focus-yellow focus my-2">
                                <FormattedMessage
                                  id="admin.dashboard.edit.participants"
                                  defaultMessage="Participants"
                                />
                              </p>

                              <div className="form-main form-main-inline mt-2">
                                <p className="bold medium block question w-100-xs w-30">
                                  <FormattedMessage
                                    id="admin.dashboard.edit.ipdates.users"
                                    defaultMessage="Updates participants (via email)"
                                  />
                                </p>

                                <div className="w-unset pl-3 w-30 pt-">
                                  {choosedEmailUsers &&
                                  choosedEmailUsers.length > 0 && (
                                    choosedEmailUsers.map(item => (
                                      <p
                                        className="small w-100 inline"
                                        key={item.value}
                                      >
                                        {' '}
                                        {item.label}
                                      </p>
                                    ))
                                  )}
                                </div>

                                <span
                                  className="btn-secondary btn-secondary-back"
                                  onClick={e => toggleEmailUsers(!emailUsersForm)}
                                >
                                  {emailUsersForm ? (
                                    <FormattedMessage
                                      id="admin.dashboard.edit.users.btn-hide"
                                      defaultMessage="Hide participants"
                                    />
                                  ) : (
                                        <FormattedMessage
                                          id="admin.dashboard.edit.users.btn"
                                          defaultMessage="Choose participants"
                                        />
                                    )}
                                </span>
                              </div>
                          </Fragment>

                          {emailUsersForm && (
                            <Fragment>
                              <p className="medium block">
                                <FormattedMessage
                                    id="admin.dashboard.edit.users.updates.description"
                                  defaultMessage="Choose team members who will participate in weekly updates. If you choose a team, then all members of this team will receive notifications."
                                />
                              </p>
                              <div>
                                <p className="bold medium block question">
                                  <FormattedMessage
                                      id="admin.dashboard.edit.users.users"
                                    defaultMessage="Team members"
                                  />
                                </p>
                                <FormattedMessage
                                    id="admin.dashboard.edit.users.userPlaceholder"
                                  defaultMessage="Add users from the company"
                                >
                                  {msg => (
                                    <Select
                                      key={profiles && `${profiles._id}-U`}
                                      defaultValue={choosedEmailUsers}
                                      onChange={selectedOption =>
                                        handleEmailUsers(selectedOption)
                                      }
                                      options={emailUsersOptions}
                                      isMulti
                                      isSearchable
                                      placeholder={msg}
                                    />
                                  )}
                                  </FormattedMessage>
                                </div>
                            </Fragment>
                          )}
                          
                            {company && company.isSlack ? (
                              <Fragment>
                                <p className="sub-title focus-yellow focus my-2">
                                  <FormattedMessage
                                    id="admin.dashboard.edit.slack"
                                    defaultMessage="Slack"
                                  />
                                </p>

                                <div className="form-main form-main-inline standups-time mt-2">
                                  <p className="bold medium block question">
                                    <FormattedMessage
                                      id="admin.dashboard.edit.status"
                                      defaultMessage="Status"
                                    />
                                  </p>

                                  <div className="checkbox status pl-3 w-30">
                                    <div className="checkbox-item active-status active-status-new">
                                      <input
                                        type="checkbox"
                                        id="status"
                                        name="status"
                                        checked={slackIsActive}
                                        value={slackIsActive}
                                        onChange={e => {
                                          setFormData({
                                            ...formData,
                                            slackIsActive: !slackIsActive
                                          });
                                        }}
                                      />
                                      <label htmlFor="status">
                                        {slackIsActive ? (
                                          <FormattedMessage
                                            id="admin.dashboard.edit.slack.active"
                                            defaultMessage="Active"
                                          />
                                        ) : (
                                            <FormattedMessage
                                              id="admin.dashboard.edit.slack.disable"
                                              defaultMessage="Disabled"
                                            />
                                          )}
                                      </label>
                                    </div>
                                  </div>

                                  <p className="bold medium block question mla w-100-xs">
                                    <FormattedMessage
                                      id="admin.dashboard.edit.isasync"
                                      defaultMessage="Asynchronous Standups"
                                    />
                                  </p>

                                  <div className="checkbox status pl-3 mra checkbox__async">
                                    <div className="checkbox-item active-status active-status-new">
                                      <input
                                        type="checkbox"
                                        id="isAsync"
                                        name="isAsync"
                                        checked={isAsync}
                                        value={isAsync}
                                        onChange={e => {
                                          setFormData({
                                            ...formData,
                                            isAsync: !isAsync
                                          });
                                        }}
                                      />
                                      <label htmlFor="isAsync">
                                        {isAsync ? (
                                          <FormattedMessage
                                            id="admin.dashboard.edit.isasync.active"
                                            defaultMessage="Active"
                                          />
                                        ) : (
                                            <FormattedMessage
                                              id="admin.dashboard.edit.isasync.disable"
                                              defaultMessage="Disabled"
                                            />
                                          )}
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div className="form-main form-main-inline mt-2">
                                  <p className="bold medium block question w-30 w-100-xs">
                                    <FormattedMessage
                                      id="admin.dashboard.edit.users.updates.slack"
                                      defaultMessage="Updates participants (via Slack)"
                                    />
                                  </p>

                                  <div className="w-unset pl-3 w-30 pt-">
                                    {choosedSlackUsers &&
                                    choosedSlackUsers.length > 0 && (
                                      choosedSlackUsers.map(item => (
                                        <p
                                          className="small w-100 inline"
                                          key={item.value}
                                        >
                                          {' '}
                                          {item.label}
                                        </p>
                                      ))
                                    )}
                                  </div>

                                  <span
                                    className="btn-secondary btn-secondary-back"
                                    onClick={e => handleSlackUsersForm()}
                                  >
                                    {emailUsersForm ? (
                                      <FormattedMessage
                                        id="admin.dashboard.edit.users.btn-hide"
                                        defaultMessage="Hide participants"
                                      />
                                    ) : (
                                          <FormattedMessage
                                            id="admin.dashboard.edit.users.btn"
                                            defaultMessage="Choose participants"
                                          />
                                      )}
                                  </span>
                                </div>

                              {slackUsersForm && (
                                <Fragment>
                                  <p className="medium block">
                                    <FormattedMessage
                                        id="admin.dashboard.edit.users.slack.updates.description"
                                        defaultMessage="Choose <b>a public channel</b> or team members who will participate in weekly updates. If you choose the public channel, then all members of this channel will receive notifications. <b>NOTE:</b> If you want to run updates in a channel, make sure that you added the Focus bot to this channel."
                                        values={{ b: (...chunks) => <b>{chunks}</b> }}
                                    />
                                  </p>
                                  <div>
                                    <p className="bold medium block question">
                                      <FormattedMessage
                                          id="admin.dashboard.edit.users.users"
                                        defaultMessage="Team members and Channels"
                                      />
                                    </p>
                                    <FormattedMessage
                                        id="admin.dashboard.edit.users.slack.userPlaceholder"
                                      defaultMessage="Add users"
                                    >
                                      {msg => (
                                        <Select
                                          key={profiles && `${profiles._id}-U`}
                                          defaultValue={choosedSlackUsers}
                                          onChange={selectedOption =>
                                            handleSlackUsers(selectedOption)
                                          }
                                          options={slackUserAndChannelOptions}
                                          isMulti
                                          isSearchable
                                          placeholder={msg}
                                        />
                                      )}
                                      </FormattedMessage>
                                    </div>
                                </Fragment>
                              )}
                              
                                <div className="form-main form-main-inline mt-2">
                                  <p className="bold medium block question w-100-xs">
                                    <FormattedMessage
                                      id="admin.dashboard.edit.reports"
                                      defaultMessage="Delivery results"
                                    />
                                  </p>

                                  <div className="w-unset pt-">
                                    {slackReportChannels &&
                                      slackReportChannels.length > 0 ? (
                                        slackReportChannels.map(item => (
                                          <p
                                            className="small mr-2 w-unset"
                                            key={`${item._id}-updates`}
                                          >
                                            {' '}
                                            {item.name}
                                          </p>
                                        ))
                                      ) : (
                                        <p className="small mr-2 w-unset">
                                          <FormattedMessage
                                            id="admin.dashboard.edit.reports.nocontent"
                                            defaultMessage="No reports"
                                          />
                                        </p>
                                      )}
                                  </div>

                                  <span
                                    className="btn-secondary btn-secondary-back"
                                    onClick={e => handleReport(e)}
                                  >
                                    {reportChannels ? (<FormattedMessage
                                      id="admin.dashboard.edit.reports.btn-hide"
                                      defaultMessage="Hide channels"
                                    />) : (
                                        <FormattedMessage
                                          id="admin.dashboard.edit.reports.btn"
                                          defaultMessage="Choose channels"
                                        />
                                      )}
                                  </span>
                                </div>

                                {reportChannels && (
                                  <Fragment>
                                    <p className="medium block">
                                      <FormattedMessage
                                        id="admin.dashboard.edit.reports.description"
                                        defaultMessage="Choose channels or users who will receive reports. If you choose public channel for reports, please add Focus bot to this channel."
                                      />
                                    </p>
                                    <div>
                                      <p className="bold medium block question">
                                        <FormattedMessage
                                          id="admin.dashboard.edit.reports.users"
                                          defaultMessage="Users (via direct messages)"
                                        />
                                      </p>
                                      <FormattedMessage
                                        id="admin.dashboard.edit.reports.userPlaceholder"
                                        defaultMessage="Add users from the workspace"
                                      >
                                        {msg => (
                                          <Select
                                            key={slack.users && `${slack.users._id}_Upd-Us`}
                                            defaultValue={choosedUsers}
                                            onChange={selectedOption =>
                                              handleChangeReport(selectedOption)
                                            }
                                            options={options}
                                            isMulti
                                            isSearchable
                                            placeholder={msg}
                                          />
                                        )}
                                      </FormattedMessage>
                                    </div>

                                    <div className="mt-2">
                                      <p className="bold medium block question">
                                        <FormattedMessage
                                          id="admin.dashboard.edit.reports.channels"
                                          defaultMessage="Channels"
                                        />
                                      </p>
                                      <FormattedMessage
                                        id="admin.dashboard.edit.reports.channelPlaceholder"
                                        defaultMessage="Add channels"
                                      >
                                        {msg => (
                                          <Select
                                            key={slack.channels && `${slack.channels._id}_Upd-Ch`}
                                            defaultValue={choosedChannels}
                                            onChange={selectedChannelOption =>
                                              handleChangeChannelReport(selectedChannelOption)
                                            }
                                            options={channelOptions}
                                            isMulti
                                            isSearchable
                                            placeholder={msg}
                                          />
                                        )}
                                      </FormattedMessage>
                                    </div>
                                  </Fragment>
                                )}
                              </Fragment>
                            ) : null}

                          {company && company.discord && company.discord.isActive ? (
                            <Fragment>
                              <p className="sub-title focus-yellow focus my-2">
                                <FormattedMessage
                                  id="discord"
                                  defaultMessage="Discord"
                                />
                              </p>

                              <div className="form-main form-main-inline standups-time mt-2">
                                <p className="bold medium block question">
                                  <FormattedMessage
                                    id="admin.dashboard.edit.status"
                                    defaultMessage="Status"
                                  />
                                </p>

                                <div className="checkbox status pl-3 w-30">
                                  <div className="checkbox-item active-status active-status-new">
                                    <input
                                      type="checkbox"
                                      id="status_discord"
                                      name="status_discord"
                                      checked={discordIsActive}
                                      value={discordIsActive}
                                      onChange={e => {
                                        setFormData({
                                          ...formData,
                                          discordIsActive: !discordIsActive
                                        });
                                      }}
                                    />
                                    <label htmlFor="status_discord">
                                      {discordIsActive ? (
                                        <FormattedMessage
                                          id="admin.dashboard.edit.discord.active"
                                          defaultMessage="Active"
                                        />
                                      ) : (
                                        <FormattedMessage
                                          id="admin.dashboard.edit.discord.disable"
                                          defaultMessage="Disabled"
                                        />
                                      )}
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="form-main form-main-inline mt-2">
                                <p className="bold medium block question w-30 w-100-xs">
                                  <FormattedMessage
                                    id="admin.dashboard.edit.users.discord"
                                    defaultMessage="Check-in participants (via Discord)"
                                  />
                                </p>

                                 <div className="w-unset pl-3 w-30 pt-">
                                  {selectedDiscordUsers &&
                                  selectedDiscordUsers.length > 0 && (
                                    selectedDiscordUsers.map(item => (
                                      <p
                                        className="small w-100 inline"
                                        key={item.value}
                                      >
                                        {' '}
                                        {item.label}
                                      </p>
                                    ))
                                  )}
                                </div>

                                <span
                                  className="btn-secondary btn-secondary-back"
                                  onClick={e => handleDiscordUsersForm()}
                                >
                                  {discordUsersForm ? (
                                    <FormattedMessage
                                      id="admin.dashboard.edit.users.btn-hide"
                                      defaultMessage="Hide participants"
                                    />
                                  ) : (
                                        <FormattedMessage
                                          id="admin.dashboard.edit.users.btn"
                                          defaultMessage="Choose participants"
                                        />
                                    )}
                                </span>
                              </div>

                              {discordUsersForm && (
                                <Fragment>
                                  <p className="medium bold pb-1"><FormattedMessage id="step" defaultMessage="Step"/>{" "}1:{" "}
                                  <FormattedMessage id="choose.users" defaultMessage="Choose users in Discord"/> </p>
                                  <p className="medium block">
                                    <FormattedMessage
                                      id="admin.dashboard.edit.users.discord.description"
                                      defaultMessage="Choose team members who will participate in daily check-ins. <b>NOTE:</b> If you don't see users in the dropdown menu, please click on the button 'Refresh users' and choose your participants."
                                      values={{ b: (...chunks) => <b>{chunks}</b> }}
                                    />
                                  </p>
                                  <div>

                                    <div className="flex align-center pb-1">
                                      <p className="bold medium question">
                                        <FormattedMessage
                                          id="team.members"
                                          defaultMessage="Team members"
                                        />
                                      </p>
                                      <span
                                        className="btn-secondary btn-secondary-back mla"
                                        onClick={e => getDiscordFocusUsers(company._id, company.discord.guildId)}
                                      >
                                        <FormattedMessage
                                          id="refresh.users"
                                          defaultMessage="Refresh users"
                                        />
                                      </span>
                                    </div>
                                    <FormattedMessage
                                      id="admin.dashboard.edit.users.slack.userPlaceholder"
                                      defaultMessage="Add users"
                                    >
                                      {msg => (
                                        <Select
                                          key={profiles && `${profiles._id}-D`}
                                          defaultValue={selectedDiscordUsers}
                                          onChange={selectedOption =>
                                            handleDiscordUsers(selectedOption)
                                          }
                                          options={discordUserOptionsTemplate}
                                          isMulti
                                          isSearchable
                                          placeholder={msg}
                                        />
                                      )}
                                      </FormattedMessage>
                                    </div>
                                    <p className="medium bold pb-1 pt-2"><FormattedMessage id="step" defaultMessage="Step"/>{" "}2:{" "}
                                    <FormattedMessage id="connect.users" defaultMessage="Connect Discord and Focus users"/>
                                  </p>
                                  <p className="medium block">
                                    <FormattedMessage
                                        id="discord.step2"
                                        defaultMessage="Connect Discord users with Focus users. Please specify who is who in your Discord server and Focus workspace. Please choose a Focus user for each Discord member. <b>NOTE:</b> If you don't connect Discord and Focus users, then Discord answers will be saved as answers from Discord users."
                                        values={{ b: (...chunks) => <b>{chunks}</b> }}
                                    />
                                  </p>

                                  {discordUserOptions && discordUserOptions.length > 0 &&
                                  discordUserOptions.map((user, index) => (
                                    <div className="flex align-center pb-1" key={`discord-users-${index}`}>
                                        <p className="w-200px">{user.label}</p>
                                        <div className="w-250px">
                                          <FormattedMessage
                                            id="choose.user.pl"
                                            defaultMessage="Choose user in Focus"
                                          >
                                          {msg => (
                                            <Select
                                              defaultValue={userOptions && userOptions.filter(p => p.id === user.user_id)}
                                              onChange={selectedOption =>
                                                onChangeConnectDiscord(selectedOption, user)
                                              }
                                              options={userOptions}
                                              isMulti={false}
                                              isSearchable={true}
                                              placeholder={msg}
                                              styles={customStyles}
                                              isClearable
                                            />
                                          )}
                                          </FormattedMessage>
                                        </div>
                                    </div>
                                  ))}
                                </Fragment>
                              )}

                              <div className="form-main form-main-inline mt-3">
                                <p className="bold medium block question w-100-xs">
                                  <FormattedMessage
                                    id="admin.dashboard.edit.reports"
                                    defaultMessage="Delivery results"
                                  />
                                </p>

                                <div className="w-unset pt-">
                                  {discordReportChannels &&
                                  discordReportChannels.length > 0 ? (
                                    discordReportChannels.map((item, index) => (
                                      <p
                                        key={`item-${index}`}
                                        className="small mr-2 w-unset"
                                      >
                                        {' '}
                                        {item.label}
                                      </p>
                                    ))
                                  ) : (
                                    <p className="small mr-2 w-unset">No reports</p>
                                  )}
                                </div>

                                <span
                                  className="btn-secondary btn-secondary-back"
                                  onClick={e => handleDiscordReport(e)}
                                >
                                    {discordReportForm ? (<FormattedMessage
                                      id="admin.dashboard.edit.reports.btn-hide"
                                      defaultMessage="Hide channels"
                                    />) : (
                                        <FormattedMessage
                                          id="admin.dashboard.edit.reports.btn"
                                          defaultMessage="Choose channels"
                                        />
                                    )}
                                </span>
                              </div>

                              {discordReportForm && (
                                <Fragment>
                                  <p className="medium block">
                                    <FormattedMessage
                                        id="admin.dashboard.edit.reports.description"
                                      defaultMessage="Choose channels or users who will receive reports. If you choose public channel for reports, please add Focus bot to this channel."
                                    />
                                  </p>
                                  <div>
                                    <p className="bold medium block question">
                                      <FormattedMessage
                                        id="admin.dashboard.edit.users.users"
                                        defaultMessage="Team members and Channels"
                                      />
                                    </p>
                                    <FormattedMessage
                                      id="admin.dashboard.edit.users.slack.userPlaceholder"
                                      defaultMessage="Add users"
                                    >
                                      {msg => (
                                        <Select
                                          key={slack.users && `${slack.users._id}-Discord-report`}
                                          defaultValue={discordReportChannels}
                                          onChange={selectedOption =>
                                            handleChangeDiscordReport(selectedOption)
                                          }
                                          options={discordUserAndChannelOptions}
                                          isMulti
                                          isSearchable
                                          placeholder={msg}
                                        />
                                      )}
                                      </FormattedMessage>
                                    </div>
                                </Fragment>
                              )}
                            </Fragment>
                          ) : null}

                            <button type="submit" className="btn btn-form">
                              <FormattedMessage id="admin.dashboard.edit.submit" defaultMessage="Submit" />
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

EditUpdates.propTypes = {
  getUpdateSettings: PropTypes.func.isRequired,
  editUpdate: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getSlackUsers: PropTypes.func,
  getSlackChannels: PropTypes.func,
  getCompanyProfiles: PropTypes.func.isRequired,
  getDiscordChannels: PropTypes.func.isRequired,
  getDiscordFocusUsers: PropTypes.func.isRequired,
  update: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  slack: PropTypes.object,
  discord: PropTypes.object,
};

const mapStateToProps = state => ({
  company: state.company,
  update: state.update,
  slack: state.slack,
  discord: state.discord,
  profile: state.profile,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { editUpdate,
    getUpdateSettings,
    getUserCompany,
    getSlackUsers,
    getSlackChannels,
    getCompanyProfiles,
    getDiscordChannels, 
    getDiscordFocusUsers,
   }
)(withRouter(EditUpdates));
