import axios from 'axios';
import { setAlert } from './alert';
import setAuthTokenRef from '../utils/setAuthTokenRef';

import {
  SET_LS,
  ERROR_LS,
  REFERRAL_ERROR,
  GET_REFERRAL,
  REFERRAL_SIGNUP_SUCCESS,
  REFERRAL_SIGNUP_FAIL
} from './types';

// Signup potential referral
export const referralSignup = ({ email, referral }) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ email, referral });

  try {
    const res = await axios.post('/api/earlyreferrals', body, config);

    dispatch({
      type: REFERRAL_SIGNUP_SUCCESS,
      payload: res.data
    });

    dispatch(getCurrentReferral());

    return false;
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger', 2500)));
    }

    dispatch({
      type: REFERRAL_SIGNUP_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status }
    });

    return true;
  }
};

// Get current user
export const getCurrentReferral = () => async dispatch => {
  try {
    if (localStorage.tokenRef) {
      setAuthTokenRef(localStorage.tokenRef);
    }

    const res = await axios.get('/api/earlyreferrals');

    dispatch({
      type: GET_REFERRAL,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: REFERRAL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set local storage for visitor
export const setLocalStorage = id => async dispatch => {
  try {
    const res = await axios.get(`/api/earlyreferrals/${id}`);
    
    dispatch({
      type: SET_LS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ERROR_LS,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
