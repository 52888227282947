import React from "react";
import PropTypes from 'prop-types';

const OkrIcon = ({ activeIndex }) => {
  return (
    <svg id="target" className="w-20px" xmlns="http://www.w3.org/2000/svg" width="20" height="19.996" viewBox="0 0 20 19.996">
        <path fill={`${activeIndex === 4 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} id="Path_11" data-name="Path 11" d="M-1159.749-168.9c-.356.325-.646.624-.974.873-.108.082-.315.046-.476.041a16.008,16.008,0,0,0-1.664-.063,8.588,8.588,0,0,0-7.309,4.63,8.388,8.388,0,0,0-1.043,4.124,8.654,8.654,0,0,0,1.263,4.529,8.51,8.51,0,0,0,5.518,4.013,8.436,8.436,0,0,0,6.511-1.018,8.6,8.6,0,0,0,4.212-6.533,6.767,6.767,0,0,0-.042-2.245.632.632,0,0,1,.193-.639c.248-.237.474-.5.724-.764a9.888,9.888,0,0,1-3.729,10.759,10.01,10.01,0,0,1-12.6-.694,10.01,10.01,0,0,1-1.91-12.44A9.953,9.953,0,0,1-1159.749-168.9Z" transform="translate(1172.434 169.281)"/>
        <path fill={`${activeIndex === 4 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} id="Path_12" data-name="Path 12" d="M-1161.71-165.486l.177,1.2a5.094,5.094,0,0,0-5.958,4.915,5.036,5.036,0,0,0,4.87,5.1,4.872,4.872,0,0,0,3.955-1.73,5.191,5.191,0,0,0,1.212-4.186l1.183.2a6.141,6.141,0,0,1-2.986,6.069,6.252,6.252,0,0,1-7.46-.839,6.315,6.315,0,0,1-1.355-7.22A6.16,6.16,0,0,1-1161.71-165.486Z" transform="translate(1172.434 169.281)"/>
        <path fill={`${activeIndex === 4 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} id="Path_13" data-name="Path 13" d="M-1156.26-161.3l-1.608-.268c-.288-.048-.581-.078-.862-.152a.554.554,0,0,0-.59.183c-.816.819-1.641,1.629-2.465,2.441a2.391,2.391,0,0,1-.346.3.524.524,0,0,1-.717-.086.517.517,0,0,1-.093-.684,1.985,1.985,0,0,1,.293-.35c.806-.81,1.609-1.621,2.425-2.42a.635.635,0,0,0,.216-.657c-.151-.735-.25-1.482-.378-2.222a2.053,2.053,0,0,1,.692-1.992c.547-.5,1.084-1,1.633-1.5a.99.99,0,0,1,1.731.458q.3.935.559,1.881a.513.513,0,0,0,.407.409c.563.154,1.12.331,1.68.5.08.024.16.048.239.075a.953.953,0,0,1,.457,1.641c-.519.6-1.052,1.19-1.591,1.772A2.046,2.046,0,0,1-1156.26-161.3Zm2.119-3.007c-.568-.176-1.056-.318-1.538-.481a.442.442,0,0,0-.517.124c-.525.534-1.065,1.053-1.6,1.58a1.984,1.984,0,0,0-.153.187c.035.031.052.062.075.066.6.1,1.2.211,1.8.308a.557.557,0,0,0,.524-.194C-1155.095-163.222-1154.648-163.732-1154.141-164.306Zm-4.758.5.1.045c.618-.633,1.238-1.266,1.853-1.9a.239.239,0,0,0,.054-.188c-.162-.553-.333-1.1-.524-1.728-.573.509-1.1.955-1.6,1.437a.719.719,0,0,0-.175.531C-1159.118-165.01-1159-164.411-1158.9-163.809Z" transform="translate(1172.434 169.281)"/>
    </svg>
  );
};

OkrIcon.propTypes = {
  activeIndex: PropTypes.number
};

export default OkrIcon;
