import React from 'react';
import { FormattedMessage } from 'react-intl';

export const pricingCopy = {
  msg: (
    <FormattedMessage
      id="okr.notification.msg1"
      defaultMessage="You haven't created any personal Objective yet."
    />
  ),
  msg2: (
    <FormattedMessage
      id="okr.notification.msg2"
      defaultMessage="Click the button below to create your first OKR."
    />
  ),
  cta: (
    <FormattedMessage
      id="okr.notification.cta.user"
      defaultMessage="Create the personal OKR"
    />
  ),
  noOkrMessage: {
    title: (
      <FormattedMessage
        id="user.nookr.user.title"
        defaultMessage="Objectives and Key Results"
      />
    ),
    msg: (
      <FormattedMessage
        id="user.nookr.user.msg"
        defaultMessage="It's the section for your Objectives and Key Results (OKRs). You can create your first inspirational OKR there for yourself or the team. You will be able to see the traction here and get the motivation to reach new heights with your team. OKRs are Silicon Valley’s secret sauce to your career, team, and organization."
      />
    )
  },
  updatePlanMsg: {
    title: (
      <FormattedMessage
        id="company.updateplan.title"
        defaultMessage="Update Plan"
      />
    ),
    msg: (
      <FormattedMessage
        id="updateplan.msg"
        defaultMessage={`The feature isn't available on this plan. Please update the plan to unlock the feature.`}
      />
    )
  }
}

export default {
  pricingCopy
}
