import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Moment from 'react-moment';
import OkrCardItemForUpdate from '../../okrs/OkrCardItemForUpdate';
import Spinner from '../../layout/Spinner';
import Select from 'react-select';
import TextQuestionBody from '../../customAnswers/CustomFormTextQuestionBody';

const UpdateForm1 = ({ 
  update, answers, 
  onChangeSelect, 
  previousAnswers, 
  okrQuestionLength, 
  focus, handleFocus,
  customStyles,
  onChange,
  okrs,
  notes,
  onChangeOkr,
  okrQuestion,
  okrOptions,
  handleAnswer,
  handleChange,
  errorIndex,
 }) => {

  // console.log('===== okrs', okrs)

  return (
    <Fragment>
      <div className="component component-update standups-daily mt-2 p-3 light-shadow">
      {update ? (
        <p className="sub-title focus-blue2 focus">
          <FormattedMessage
            id="updates.week.head"
            defaultMessage="Week:"
          />{' '}
          <Moment format="D MMM">{new Date()}</Moment>
        </p>
      ) : (
        <p className="sub-title focus-blue2 focus">
          <FormattedMessage
            id="update.form.week"
            defaultMessage="Week"
          />
        </p>
      )}

      <div className="py-2">
        <p className="meduim">
          <FormattedMessage
            id="update.form.subHeader"
            defaultMessage="Let's answer some questions to complete your weekly update"
          />
        </p>
        <div className="line" />
      </div>

      {update ? (
        <Fragment>
          {update.questions.map((question, index) => (
            <div className="form-main" key={index}>
              <p className="bold medium block">{question.text}</p>
              {question.isNumber ? (
                <select
                  required
                  name={index}
                  value={answers[index] || ''}
                  onChange={e => onChangeSelect(e, index)}
                  className={`${errorIndex === index ? 'brd-red' : ''}`}
                >
                  <option value="0">Select the number</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              ) : (

                <Fragment>
                  {index === 1 || index === 2 ? (
                    <Fragment>
                      {previousAnswers.map((answer, i) => (
                        <div className="step-item mr-2 mb-2-xs cards__onboarding_body ml-4" key={`${answer.text}-${i}-${index}`}>
                          <p
                            onClick={e => handleAnswer(e, answer, index, i)}
                            className={`inline medium card-subtitle todo__item cursor lh-30 ${index === 1 ? (!answer.completeOne ? '' : "todo__completed") : (index === 2 ? (!answer.completeTwo ? '' : "todo__item-blue") : '')}`}
                          >
                            {answer.text}
                          </p>
                        </div>
                      ))}
                    </Fragment>
                  ) : ("")}

                  <div className="mt-2">
                    <TextQuestionBody
                      value={answers[index]}
                      data={question}
                      handleChange={(newValue) => {
                        handleChange(index, newValue);
                      }}
                      tasks={[]}
                      index={index}
                      isHideTasks={true}
                      iconStyles="right-10"
                      errorIndex={errorIndex}
                    />
                  </div>
                </Fragment>
              )}
            </div>
          ))}

          {okrQuestionLength > 0 && okrQuestion.isActive && (
            <div className="form-main mb-5">
              <p className="bold medium block no-w label-pr3">
                  {okrQuestion.text}
              </p>
              <FormattedMessage
                id="update.form.focus.question"
                defaultMessage="Choose the OKR you are working on next week"
              >
                {msg => (
                  <Select
                    className="small-input w-70-xs mb-2-xs"
                    value={focus}
                    onChange={selectedOption =>
                      handleFocus(selectedOption)
                    }
                    options={okrOptions}
                    isSearchable
                    placeholder={msg}
                    styles={customStyles}
                  />
                )}
              </FormattedMessage>
            </div>
          )}

          <div>
            <div>
              <p className="bold medium inline mr-1">
                <FormattedMessage
                  id="notes"
                  defaultMessage="Notes"
                />
              </p>
              <span className="small grey">
                <FormattedMessage
                  id="optional"
                  defaultMessage="Optional"
                />
              </span>
            </div>
            <FormattedMessage
              id="standup.form.add.notes"
              defaultMessage="Add notes"
            >
              {msg => (
                <textarea
                  className="my-1 w-100"
                  rows="3"
                  type="text"
                  placeholder={msg}
                  name="notes"
                  value={notes}
                  onChange={e => onChange(e)}
                />
              )}
            </FormattedMessage>
          </div>
        </Fragment>
      ) : (
        <Spinner />
      )}
    </div>

    {okrs && okrs.length > 0 && (
      <div className="component standups-daily mt-2 p-3 light-shadow">
        <Fragment>
        <p className="large bold inline">
            <FormattedMessage
              id="you.update.okr.form.header"
              defaultMessage="Objectives and Key Results"
            />
          </p>
          <p className="small inline grey pl-1">
            <FormattedMessage
              id="you.update.recognition.form.sub-header"
              defaultMessage="Optional"
            />
          </p>

          <div className="py-2">
            <p className="meduim">
              <FormattedMessage
                id="you.update.okr.form.subHeader"
                defaultMessage="Did you move your objectives forward this week? Share your progress."
              />
            </p>
            <div className="line" />
          </div>

          <div className="cards py-2">
            {okrs.map((okr, idx) => (
              <OkrCardItemForUpdate
                key={okr._id}
                okr={okr}
                okrBoard={'6'}
                onChangeOkr={onChangeOkr}
                indexOkr={idx}
              />
            ))}
          </div>
        </Fragment>
      </div>
    )}
  </Fragment>
  );
};

UpdateForm1.propTypes = {
  update: PropTypes.object.isRequired
};

export default UpdateForm1;
