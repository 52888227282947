import React from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const SubMenu = ({ activeIndex }) => {

  return (
    <div className="sub-menu__page mt-2 mb-3">
      <Link
        to={'/settings'}
        className={`medium sub-menu__item inline-block mr-2 cursor ${activeIndex === 1 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="admin.settings.campaigns" defaultMessage="Campaigns" />
      </Link>
      <Link
        to={'/settings/integrations'}
        className={`medium sub-menu__item inline-block mr-2 cursor ${activeIndex === 4 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="admin.settings.integrations" defaultMessage="Integrations" />
      </Link>
      
      <Link
        to={'/settings/pricing'}
        className={`medium sub-menu__item inline-block mr-2 cursor ${activeIndex === 5 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="admin.settings.plans" defaultMessage={`Plans & Billing`} />
      </Link>

      <Link
        to={'/settings/workspace'}
        className={`medium sub-menu__item inline-block mr-2 cursor ${activeIndex === 6 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="settings.workspace" defaultMessage={`Workspace Settings`} />
      </Link>
    </div>
  );
};

SubMenu.propTypes = {
  activeIndex: PropTypes.number.isRequired
};

export default SubMenu;
