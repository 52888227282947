import {
  SIGNUP_SUCCESS,
  VALIDATE_EMAIL,
  SIGNUP_FAIL,
  AUTH_ERROR,
  USER_LOADED,
  LOGIN_SUCCESS,
  RESEND_SUCCESS,
  RESEND_ERROR,
  RESEND_NOTOKEN,
  CONFIRM_SUCCESS,
  RESET_SUCCESS,
  RESET_ERROR,
  RESET_NOTOKEN,
  RESET_PASSWORD_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGIN_AS_USER_SUCCESS,
  LOGIN_AS_USER_ERROR,
  LOGOUT_AS_USER_SUCCESS,
  LOGOUT_AS_USER_ERROR,
  UPDATE_AVATAR_AUTH,
  SET_SIDEBAR_VIEW,
} from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  sidebar: null,
  user: null,
  lau: null,
  error: {},
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  
  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        // user: payload
        user: payload.user,
        sidebar: payload.sidebar,
      };
    case UPDATE_AVATAR_AUTH:
      return {
        ...state,
        user: { ...state.user, avatar: payload } 
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        user: payload.user
      };
    case SIGNUP_SUCCESS:
    // case LOGIN_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false
      };
    case LOGIN_AS_USER_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        lau: true
      };
    case LOGOUT_AS_USER_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        lau: null
      };
    case SET_SIDEBAR_VIEW:
      localStorage.setItem('sidebar', payload);
      return {
        ...state,
        sidebar: payload,
      };
    case RESEND_SUCCESS:
    case CONFIRM_SUCCESS:
    case RESET_SUCCESS:
    case VALIDATE_EMAIL:
      return {
        ...state,
        token: payload
      };
    case RESEND_ERROR:
    case RESET_ERROR:
    case RESEND_NOTOKEN:
    case RESET_NOTOKEN:
      return {
        ...state,
        token: null
      };
    case SIGNUP_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
    case LOGOUT_AS_USER_ERROR:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        lau: null
      };
    case LOGIN_AS_USER_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
}
