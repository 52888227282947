import axios from 'axios';
import { setAlert } from './alert';
import { updateAvatar } from './auth';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  GET_PROFILE,
  PROFILE_ERROR,
  USER_LOADED,
  GET_TEAM_PROFILES,
  GET_COMPANY_PROFILES,
  COMPANY_PROFILES_ERROR,
  TEAM_PROFILES_ERROR,
  DELETE_USER,
  DELETE_USER_ERROR,
  UPDATE_NOTIFICATION,
  UPDATE_ALL_NOTIFICATIONS,
  NOTIFICATION_ERROR,
  NOTIFICATIONS_ERROR,
  UPDATE_AVATAR,
  AVATAR_ERROR,
  ASSIGN_TEAM_HEAD,
  ASSIGN_TEAM_HEAD_ERROR,
  UPDATE_COMPANY_MEMBER_PROFILE,
  UPDATE_COMPANY_MEMBER_PROFILE_ERROR
} from './types';

// Get current user profile
export const getCurrentProfile = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/users/me');

    console.log('====== res.data in getCurrentProfile', res.data);

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
  } catch (err) {
    console.log('Error in getCurrentProfile', err);

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit profile for current user
export const editProfile = (formData, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(`/api/users`, formData, config);

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });

    dispatch({
      type: USER_LOADED,
      payload: res.data
    });

    dispatch(
      setAlert(
        <FormattedMessage id="alert.profile.updated" defaultMessage="Profile Updated" />,
        'success',
        3000
      )
    );

    history.push('/profile');
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//edit profile jobTitle and team
export const editCompanyMemberProfile = (id, formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(`/api/users/member/${id}`, formData, config);
    dispatch({
      type: UPDATE_COMPANY_MEMBER_PROFILE,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: UPDATE_COMPANY_MEMBER_PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Change user password
export const changePassword = (password, newPassword, userId, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({
      password,
      newPassword
    });

    await axios.put(`/api/users/password/${userId}`, body, config);

    dispatch(setAlert('Password Updated', 'success'));

    history.push('/profile');
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Change user avatar
export const editAvatar = (avatar) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({
      avatar
    });

    const res = await axios.put(`/api/users/avatar/update`, body, config);

    dispatch({
      type: UPDATE_AVATAR,
      payload: { id: res.data._id, avatar: res.data.avatar }
    });

    dispatch(updateAvatar(res.data.avatar));

    dispatch(setAlert('Avatar was updated', 'success'));

    // Callback for hidden popup and spinner
    const data = { status: 1 };
    return data;
  } catch (err) {
    const errors = err.response.data.errors;
    console.log('== err', err);

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    } else if (err.response.status !== 413) {
      // если ошибка одна, то она будет в err.response.statusText
      dispatch(setAlert(err.response.statusText, 'danger'));
    }

    if (err.response.status === 413) {
      dispatch(setAlert('Crop area is too large. Please make it less or choose another photo.', 'danger'));
    }

    dispatch({
      type: AVATAR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Change user avatar
export const switchTeamHead = (id, role) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({ role });
    const res = await axios.put(`/api/users/role/${id}`, body, config);

    dispatch({
      type: ASSIGN_TEAM_HEAD,
      payload: { id: res.data._id, role: res.data.role }
    });
  } catch (err) {
    const errors = err.response.data.errors;
    console.log('== err', err);

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    } else if (err.response.status !== 413) {
      // если ошибка одна, то она будет в err.response.statusText
      dispatch(setAlert(err.response.statusText, 'danger'));
    }

    if (err.response.status === 413) {
      dispatch(setAlert('Crop area is too large. Please make it less or choose another photo.', 'danger'));
    }

    dispatch({
      type: ASSIGN_TEAM_HEAD_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update notification
export const updateNotification = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify(data);
    const res = await axios.put(`/api/users/notifications/one`, body, config);

    dispatch({
      type: UPDATE_NOTIFICATION,
      payload: { key: res.data.key, data: res.data.data }
    });
  } catch (err) {
    const errors = err.response.data.errors;
    console.log('err.response.data.', err.response.data);

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: NOTIFICATION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update all notifications
export const updateAllNotifications = (data, history) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify(data);
    const res = await axios.put(`/api/users/notifications/all`, body, config);

    dispatch({
      type: UPDATE_ALL_NOTIFICATIONS,
      payload: { data: res.data }
    });

    dispatch(setAlert('Notifications updated', 'success', 3000));

    history.push('/profile');
  } catch (err) {
    const errors = err.response.data.errors;
    console.log('err.response.data.', err.response.data);

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: NOTIFICATIONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all company's profiles
export const getCompanyProfiles = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/company/people');

    dispatch({
      type: GET_COMPANY_PROFILES,
      payload: res.data
    });
  } catch (err) {
    console.log('err', err);

    dispatch({
      type: COMPANY_PROFILES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get current user's team profiles
export const getTeamProfiles = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/company/team/user/people');

    dispatch({
      type: GET_TEAM_PROFILES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TEAM_PROFILES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get user profile by ID
export const getProfileById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/users/${id}`);

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get team's profiles by team ID
export const getTeamProfilesById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/company/team/${id}/users`);

    dispatch({
      type: GET_TEAM_PROFILES,
      payload: res.data
    });
  } catch (err) {
    console.log('err', err);

    dispatch({
      type: TEAM_PROFILES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete user
export const deleteUser = (user_id) => async (dispatch) => {
  try {
    await axios.delete(`/api/users/${user_id}`);

    dispatch({
      type: DELETE_USER,
      payload: user_id
    });

    dispatch(setAlert('User was deleted', 'warning', 3000));
  } catch (err) {
    console.log('== Error', err);

    if (err.response.data.errors) {
      const errors = err.response.data.errors;

      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: DELETE_USER_ERROR,
      payload: { msg: err.response.data.errors, status: err.response.status }
    });
  }
};
