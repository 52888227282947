import axios from 'axios';
import { setAlert } from './alert';

import {
  GET_USER_TODOS,
  TODO_ERROR,
  USER_TODOS_ERROR,
  ADD_TODO,
  EDIT_TODO,
  UPDATE_TODO,
  UPDATE_TODO_ERROR,
  COMPLETE_TODO,
  COMPLETE_TODO_ERROR,
  GET_COMPANY_TODOS,
  COMPANY_TODOS_ERROR,
  DELETE_TODO,
  GET_TODOS_WITH_OKRS,
  TODOS_WITH_OKRS_ERROR,
  CHANGE_TODO_FILTERS,
  LOCATION_CHANGE_ERROR,
} from './types';

// Get all company todos
export const getCompanyTasks = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/todos/company');

    dispatch({
      type: GET_COMPANY_TODOS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: COMPANY_TODOS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all user todos for standups
export const getUserTasks =
  ({ all } = {}) =>
  async (dispatch) => {
    try {
      const params = all ? { all } : {};
      const res = await axios.get('/api/todos/user', { params });

      dispatch({
        type: GET_USER_TODOS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: USER_TODOS_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };

// Get all company todos with OKRs (for dashboard)
export const getTasksWithOkrs = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/todos/okrs');

    dispatch({
      type: GET_TODOS_WITH_OKRS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TODOS_WITH_OKRS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Create Task
export const addTodo = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify(data);
    const res = await axios.post(`/api/todos/new`, body, config);

    dispatch({
      type: ADD_TODO,
      payload: res.data
    });

    // Callback for updating in formData.objectives in useState to render it
    const arr = { data: res.data };
    return arr;
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: TODO_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit Todo
export const updateTodo = (id, data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify(data);
    const res = await axios.put(`/api/todos/update/${id}`, body, config);

    dispatch({
      type: UPDATE_TODO,
      payload: { id, data: res.data }
    });

    // Callback for updating in formData.objectives in useState to render it
    const arr = { id, data: res.data };
    return arr;
  } catch (err) {
    const errors = err.response.data.errors;
    console.log('err.response.data.', err.response.data);

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: UPDATE_TODO_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit Todo
export const completeTodo = (id, isCompleted) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({ isCompleted });
    const res = await axios.put(`/api/todos/complete/${id}`, body, config);

    dispatch({
      type: COMPLETE_TODO,
      payload: { id, data: res.data }
    });

    // Callback for updating in formData.tasks in useState to render it
    const arr = { id, data: res.data };
    return arr;
  } catch (err) {
    const errors = err.response.data.errors;
    console.log('err.response.data.', err.response.data);

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: COMPLETE_TODO_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit Todo // не делал
export const editTodo = (id, data, history) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify(data);
    const res = await axios.put(`/api/okr/${id}`, body, config);

    dispatch({
      type: EDIT_TODO,
      payload: { id, data: res.data }
    });

    dispatch(setAlert('OKR updated', 'success', 2000));

    if (history) {
      history.goBack();
    }
  } catch (err) {
    const errors = err.response.data.errors;
    console.log('err.response.data.', err.response.data);

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: TODO_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// // Delete Todo
export const deleteTodo = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/todos/${id}`);

    dispatch({
      type: DELETE_TODO,
      payload: id
    });

    // Callback for updating in formData.todos in useState to render it
    let data = { id };
    return data;
  } catch (err) {
    const errors = err.response.data.errors;
    console.log('err.response.data.', err.response.data);

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: TODO_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


export const changeTaskFilters= (team,type)=>dispatch=>{
  try {
    // console.log('====== team in changeTaskFilters ', team)
    // console.log('====== type in changeTaskFilters ', type)
    dispatch({type:CHANGE_TODO_FILTERS,payload:{team,type}})
  } catch (err) {
    console.log('Error in changeTaskFilters:', err)

    dispatch({
      type: LOCATION_CHANGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
}