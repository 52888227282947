import React, { useState, useEffect, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import UserNav from '../layout/UserNav';
import Logo from '../elems/Logo';
import { editAnswer, getAnswerById } from '../../actions/updateAnswer';
import { getAllUserOkrs } from '../../actions/okr';
import { setAlert } from '../../actions/alert';
import Select from 'react-select';
import Spinner from '../layout/Spinner';
import { FormattedMessage } from 'react-intl';
import Loader from 'react-loader-spinner';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px'
  }),
  input: () => ({
    fontSize: '14px'
  }),
  placeholder: (styles) => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
};

const UpdateFormEdit = ({
  editAnswer,
  getAnswerById,
  getAllUserOkrs,
  updateAnswer: { updateAnswer, loading },
  okr: { okrs },
  locale,
  match,
  history,
  setAlert
}) => {
  let noOkrMsg =
    locale && locale.lang !== 'ru' ? `😳 I don't work on OKR today` : `😳 Я не работаю сегодня над OKR`;

  const [formData, setFormData] = useState({
    answers: [],
    focus: '',
    notes: ''
  });

  useEffect(() => {
    getAnswerById(match.params.id);
    getAllUserOkrs();
  }, [getAnswerById, getAllUserOkrs]);

  useEffect(() => {
    if (updateAnswer && okrs) setFormData({ answers: updateAnswer.answers, notes: updateAnswer.notes });
  }, [updateAnswer, okrs]);

  const okrOptions = useMemo(() => {
    if (!(okrs && okrs.length)) return [];
    else {
      let options = okrs.reduce((accum, okr, i) => {
        let newAccum = [...accum];
        if (!okr.isArchive) {
          let objective = {
            value: okr._id,
            label: `🎯 ${okr.objective}`,
            okr: okr._id,
            objective: okr.objective,
            isFocus: true,
            text: okr.objective,
            focusIndex: 99,
            status: okr.status,
            progress: okr.progress,
            goal: 100
          };
          newAccum.push(objective);

          const krsOptions = okr.keyResults.map((kr, index) => ({
            value: kr._id,
            label: ` - ${kr.title}`,
            okr: okr._id,
            objective: okr.objective,
            isFocus: true,
            text: kr.title,
            focusIndex: index,
            status: okr.status,
            progress: kr.progress,
            goal: kr.goal,
            current: kr.current,
            krId: kr._id,
            isKr: true
          }));
          newAccum = [...newAccum, ...krsOptions];
        }
        return newAccum;
      }, []);
      options.push({ value: `noOkr-${okrs[okrs.length - 1]._id}`, label: noOkrMsg, isFocus: false });
      return options;
    }
  }, [okrs]);

  useEffect(() => {
    if (okrOptions.length && updateAnswer && updateAnswer.focus) {
      let findOption = updateAnswer.focus.isKr ? 'krId' : 'okr';

      setFormData((prev) => ({
        ...prev,
        focus: okrOptions.find((opt) => opt.value === updateAnswer.focus[findOption]) || ''
      }));
    }
  }, [okrOptions, updateAnswer]);

  const [isLoader, toggleLoader] = useState(false);

  const handleFocus = (selectedOption) => setFormData((prev) => ({ ...prev, focus: selectedOption }));

  const handleNotesChange = (e) => {
    e.persist();
    setFormData((prev) => ({ ...prev, notes: e.target.value }));
  };

  const handleAnswerTextChange = (answerIndex, textIndex) => (e) => {
    e.persist();

    setFormData((prev) => {
      const newState = { ...prev };
      newState.answers[answerIndex].text[textIndex] = e.target.value;
      return newState;
    });
  };

  const goBack = () => {
    history.goBack();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    toggleLoader(true);

    let answersValid = true;
    formData.answers.forEach((element) => {
      element.text.forEach((txt) => {
        if (!txt && element.text.length === 1) answersValid = false;
      });
    });

    if (!answersValid) {
      toggleLoader(false);
      setAlert(
        <FormattedMessage id="form.input.empty" defaultMessage="Please fill out the field" />,
        'danger'
      );
    } else
      editAnswer({ answerId: match.params.id, ...formData }).then((res) => {
        toggleLoader(true);
        if (res) history.push('/campaigns/updates');
      });
  };

  // console.log('=== formData', formData)

  const handleKeyPress = (e, questionIndex) => {
    e.persist();
    if (e.key === 'Enter') {
      const newAnswers = formData.answers;
      newAnswers[questionIndex].text.push('');
      e.preventDefault();
      e.stopPropagation();
      setFormData({ ...formData, answers: newAnswers });
    }
  };

  let btnTxt = <FormattedMessage id="update.form.submit" defaultMessage="Submit" />;

  // Loader for button
  let loadingIcon = (
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Loader type="Circles" color="#ffffff" height="14px" width="15" />
    </span>
  );

  if (isLoader) {
    btnTxt = loadingIcon;
  }

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">
          <div className="main_menu absolute">
            <Logo />
          </div>
          <UserNav />

          {loading ? (
            <Spinner />
          ) : (
            <Fragment>
              <div className="flex">
                <div className="w-100 main mxa user-standups mr-300 pl-0 pt-0-xs mb-5 pb-5">
                  <div className="standups mt-3">
                    <div className="section-title">
                      <h5>
                        <FormattedMessage id="update.form.header" defaultMessage="Complete a Check-in" />
                      </h5>
                      <p onClick={(e) => goBack()} className="btn-secondary btn-secondary-back">
                        <FormattedMessage id="go.back" defaultMessage="Go Back" />
                      </p>
                    </div>

                    <div className="component standups-daily my-2 p-3 light-shadow">
                      {updateAnswer ? (
                        <p className="sub-title focus-green focus">
                          <Moment format="D MMM">{updateAnswer && updateAnswer.date}</Moment>
                        </p>
                      ) : (
                        <p className="sub-title focus-green focus">
                          <FormattedMessage id="update.form.day" defaultMessage="Day" />
                        </p>
                      )}

                      <div className="form-full">
                        <div className="py-2">
                          <p className="meduim">
                            <FormattedMessage
                              id="update.form.subHeader"
                              defaultMessage="Let's answer some questions to make your day better"
                            />
                          </p>
                          <div className="line" />
                        </div>

                        <form className="form-app form-app-standup" onSubmit={(e) => onSubmit(e)}>
                          {updateAnswer ? (
                            <Fragment>
                              <Fragment>
                                {formData.answers.map((answer, index) => (
                                  <div className="form-main" key={index}>
                                    <p className="bold medium block">{answer.question}</p>

                                    {answer.text.map((text, i) => (
                                      <FormattedMessage
                                        key={`answer-${i}`}
                                        id="update.form.comment"
                                        defaultMessage="Add a comment"
                                      >
                                        {(msg) => (
                                          <input
                                            className="my-1"
                                            type="text"
                                            placeholder={msg}
                                            name={i}
                                            value={text || ''}
                                            onChange={handleAnswerTextChange(index, i)}
                                            onKeyPress={(e) => handleKeyPress(e, index)}
                                          />
                                        )}
                                      </FormattedMessage>
                                    ))}
                                  </div>
                                ))}
                              </Fragment>

                              <div className="form-main mb-5">
                                <p className="bold medium block no-w label-pr3">
                                  <FormattedMessage
                                    id="checkin.form.focus.question"
                                    defaultMessage="What is your main focus today?"
                                  />
                                </p>
                                <FormattedMessage
                                  id="checkin.form.focus.question.input"
                                  defaultMessage="Choose the OKR you are working on today"
                                >
                                  {(msg) => (
                                    <Select
                                      className="small-input w-70-xs mb-2-xs"
                                      value={formData.focus}
                                      onChange={(selectedOption) => handleFocus(selectedOption)}
                                      options={okrOptions}
                                      isSearchable
                                      placeholder={msg}
                                      styles={customStyles}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>

                              <div>
                                <div>
                                  <p className="bold medium inline mr-1">
                                    <FormattedMessage id="notes" defaultMessage="Notes" />
                                  </p>
                                  <span className="small grey">
                                    <FormattedMessage id="optional" defaultMessage="Optional" />
                                  </span>
                                </div>
                                <FormattedMessage id="update.form.add.notes" defaultMessage="Add notes">
                                  {(msg) => (
                                    <textarea
                                      className="my-1 w-100"
                                      rows="3"
                                      type="text"
                                      placeholder={msg}
                                      name="notes"
                                      value={formData.notes}
                                      onChange={handleNotesChange}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>
                            </Fragment>
                          ) : (
                            <Spinner />
                          )}
                          <button
                            type="submit"
                            disabled={!isLoader ? false : true}
                            className="mw-150 btn btn-submit mt-1"
                          >
                            {btnTxt}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

UpdateFormEdit.propTypes = {
  editAnswer: PropTypes.func.isRequired,
  getAllUserOkrs: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  getAnswerById: PropTypes.func.isRequired,
  updateAnswer: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired,

  locale: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  okr: state.okr,
  updateAnswer: state.updateAnswer,
  locale: state.locale
});

export default connect(mapStateToProps, {
  editAnswer,
  getAnswerById,
  getAllUserOkrs,
  setAlert
})(withRouter(UpdateFormEdit));
