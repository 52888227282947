import React, { memo } from 'react';
import TextQuestionBody from './CustomFormTextQuestionBody';
import SingleSelectQuestionBody from './CustomFormSingleSelectQuestionBody';
import MultiSelectQuestionBody from './CustomFormMultiSelectQuestionBody';
import OkrSelectQuestionBody from './CustomFormOkrSelectQuestionBody';

const CustomQuestionItem = ({
  question,
  value,
  index,
  onQuestionChange,
  okrOptions,
  onDragEnd,
  tasks,
  complitingTasks,
  onChangeComplitingTasks,
  errorIndex,
}) => {

  // Old check-ins have no questionType field
  if (question && !question.questionType) {
    question.questionType = { value: 'text'}
  }

  const onDragOver = (e) => e.preventDefault();
  const onDrop = () => {
    if (question.questionType.value === 'text') {
      onDragEnd(index);
    }
  };

  const handleChange = (newValue) => {
    onQuestionChange(index, newValue);
  };

  return (
    <div className="form-main mb-1" key={index} onDragOver={onDragOver} onDrop={(e) => onDrop()}>
      <p className="bold medium block pb-0 mb-0">{question.text}</p>
      {question.questionType.value === 'text' && (
        <TextQuestionBody
          value={value}
          data={question}
          handleChange={handleChange}
          tasks={tasks}
          index={index}
          complitingTasks={complitingTasks}
          onChangeComplitingTasks={onChangeComplitingTasks}
          errorIndex={errorIndex}
        />
      )}
      {question.questionType.value === 'single_select' && (
        <SingleSelectQuestionBody 
          value={value} 
          data={question} 
          handleChange={handleChange} 
          errorIndex={errorIndex} 
          index={index} 
        />
      )}
      {question.questionType.value === 'multiple_select' && (
        <MultiSelectQuestionBody 
          value={value} 
          data={question} 
          handleChange={handleChange} 
          errorIndex={errorIndex} 
          index={index} 
        />
      )}
      {question.questionType.value === 'okr_select' && (
        <OkrSelectQuestionBody
          value={value}
          data={question}
          handleChange={handleChange}
          options={okrOptions}
          errorIndex={errorIndex}
          index={index}
        />
      )}
    </div>
  );
};

export default memo(CustomQuestionItem);
