import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const UserTitle = ({ profile, user }) => {
  return (
    <div className="profile bg-profile profile-company-user shadow">
      <div className="flex-up-user flex-column-xs">
        <div className="profile-left w-50 p-4 w-100-xs">
          <img
            src={profile.avatar}
            alt=""
            className="round-img d-block profile__avatar avatar"
            width="140px"
            height="140px"
          />

          <div className="profile-name">
            <h5>
              {profile.firstName} {profile.lastName}
            </h5>
            <p className="large pl-0-xs">
              {profile.jobTitle ? (
                profile.jobTitle
              ) : (
                <FormattedMessage
                  id="profile.position"
                  defaultMessage="Position: -"
                />
              )}
            </p>
          </div>
        </div>

        <div className="profile-right p-4 w-100-xs">
          <div className="profile-team">
            <p className="large bold">
              <FormattedMessage id="profile.team" defaultMessage="Team" />
            </p>
            <p className="medium">
              {profile.departmentName ? profile.departmentName : '-'}
            </p>
          </div>

          <div className="profile-contacts my-3">
            <p className="large bold">
              <FormattedMessage
                id="profile.contacts"
                defaultMessage="Contacts"
              />
            </p>
            <p className="medium">{profile.email}</p>
            <p className="medium">
              {profile.phone ? (
                profile.phone
              ) : (
                <FormattedMessage
                  id="profile.phone"
                  defaultMessage="Phone: -"
                />
              )}
            </p>
          </div>
          {profile && user ? (
            user._id === profile._id ? (
              <Link
                to="/edit-profile"
                className="btn-secondary btn-secondary-comment ml-0-xs"
              >
                <FormattedMessage
                  id="profile.edit"
                  defaultMessage="Edit Profile"
                />
              </Link>
            ) : null
          ) : null}
        </div>
      </div>
    </div>
  );
};

UserTitle.propTypes = {
  profile: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default UserTitle;
