import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import { setAlert } from '../../../actions/alert';
import TreeOkrItem from "./TreeOkrItem";
import TreeTitle from "./TreeTitle";
import Moment from "react-moment";
import { FormattedMessage } from "react-intl";

const TreeGrandChild = ({
  okr: {
    owner,
    ownerAvatar,
    ownerFirstName,
    ownerLastName,
    dueIn,
    _id,
    objective,
    progress,
    status,
    keyResults,
    isChild,
    creator,
    isArchive,
  },
  noParents,
  handleArchive,
  handleDelete,
  archive,
  user,
}) => {
  const [discussion, showComments] = useState(false);
  const [update, toggleUpdateMode] = useState(false);

  let btnClass = '';
  let label = '';

  if (status === 1) {
    btnClass = "green";
    label = "On Track";
  } else if (status === 2) {
    btnClass = "yellow";
    label = "Behind";
  } else if (status === 3) {
    btnClass = "red";
    label = "At Risk";
  }

  return (
    <Fragment>
      <div className={`objective__child ${!isChild && 'objective__no-parent'} relative pl-2 py-1`}>
        <div className="w-450px bg__white br-12 my-2 px-2 py-3 light-shadow">
          <TreeTitle
            label={`${ownerFirstName} ${ownerLastName}`}
            color={btnClass}
            _id={_id}
            handleArchive={handleArchive}
            handleDelete={handleDelete}
            isArchive={isArchive}
            archive={archive}
            user={user}
            owner={owner}
            creator={creator}
          />

          <TreeOkrItem
            objective={objective}
            status={label}
            dueIn={dueIn}
            owner={owner}
            ownerAvatar={ownerAvatar}
            ownerFirstName={ownerFirstName}
            ownerLastName={ownerLastName}
            btnClass={btnClass}
            progress={progress}
            keyResults={keyResults}
          />
        </div>
      </div>
    </Fragment>
  );
};

TreeGrandChild.propTypes = {
  noParents: PropTypes.bool,
  okr: PropTypes.object.isRequired
};

export default TreeGrandChild;
