import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { Link } from 'react-router-dom';
import { sendInvoice } from '../../actions/company';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import { FormattedMessage } from 'react-intl';

const PopupPrice = ({
  setAlert,
  togglePopup,
  popup,
  sendInvoice,
  user,
  price,
  discountPrice,
  monthly,
  people,
  alert
}) => {
  const [formData, setFormData] = useState({
    email: '',
    showForm: true,
    showSuccess: false
  });

  const { email, showForm, showSuccess } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    try {
      sendInvoice(email, finalPrice, people, monthly, user.email, user.company);

      setFormData({ ...formData, showForm: false, showSuccess: true });
    } catch (error) {
      return;
    }
  };

  let finalPrice = monthly ? price * people : discountPrice * people * 12;

  return (
    <Fragment>
      <div className="modal">
        <div className="modal-content">
          {showForm && (
            <Fragment>
              <div className="modal-header pt-3 pb-3 px-4">
                <button
                  className="modal-close no_btn"
                  onClick={() => togglePopup(!popup)}
                >
                  &times;
                </button>
                <h2>
                  <FormattedMessage id="popup.price.header" defaultMessage="Premium Upgrade" />
                  </h2>
              </div>

              <div className="modal-body px-4">
                <p className="lead pb-3">
                  <FormattedMessage id="popup.price.text" defaultMessage="Enter your e-mail address below and we send you the PayPal invoice. Your account will be updated after transfer." />
                  </p>
                <div className="form form-detail">
                  <form onSubmit={e => onSubmit(e)}>
                    <div className="field_email field">
                      <FormattedMessage id="popup.price.email" defaultMessage="Enter Your Email">
                      {msg => <input
                        type="email"
                        className="email"
                        placeholder={msg}
                        name="email"
                        value={email}
                        onChange={e => onChange(e)}
                        required
                      />}
                      </FormattedMessage>
                    </div>

                    <div className="form_button">
                      <button type="submit" className="btn btn-form mt-2">
                        {' '}
                        <FormattedMessage id="popup.price.btn" defaultMessage="Get the PayPal link" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Fragment>
          )}

          {showSuccess && (
            <div>
              {!user ? (
                <Spinner />
              ) : (
                <Fragment>
                  <div className="modal-header pt-3 px-4 text-center">
                    <button
                      className="modal-close no_btn"
                      onClick={() => togglePopup(!popup)}
                    >
                      &times;
                    </button>
                    <h5>
                      <FormattedMessage id="popup.price.success.header" defaultMessage="Quick pay and you'll be on your way! " />
                      </h5>
                    <p className="pt-3 medium text-left">
                      <FormattedMessage id="popup.price.success.text" defaultMessage="We're sending you the invoice today. Please check your email inbox. Your account will be updated after transfer." />
                    </p>
                    <p className="medium text-left pt-1">
                      <FormattedMessage id="popup.price.success.text2" defaultMessage="Your Premium plan details:" />
                    </p>
                  </div>
                  <div className="modal-body px-4 text-center">
                    <p className="py-2">
                      <span className="blue fs2_5 bold">${finalPrice} for {people} active users</span>{' '}
                    </p>
                    <p className="pb-3 fs1_4">
                      <FormattedMessage id="popup.price.success.footer" defaultMessage="Let us know if you have any questions via " /><a className="medium-xs blue" href={`mailto:${process.env.REACT_APP_EMAIL}?subject=Invoice request`}>{process.env.REACT_APP_EMAIL}</a>.
                    </p>
                  </div>
                </Fragment>
              )}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

PopupPrice.propTypes = {
  setAlert: PropTypes.func.isRequired,
  sendInvoice: PropTypes.func.isRequired,
  price: PropTypes.number,
  discountPrice: PropTypes.number,
  people: PropTypes.number
};

const mapStateToProps = state => ({
  alert: state.alert
});

export default connect(
  mapStateToProps,
  { setAlert, sendInvoice }
)(PopupPrice);
