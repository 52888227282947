import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const QuestionIcebreakerItemField = ({
  question,
  index,
  nextQuestionId,
  onChange,
  handleRemove,
}) => {

  return (
    <div
      className="form-main form-main-inline"
    >
      <p className={`bold medium block question ${index === nextQuestionId && 'blue'}`}>
        <FormattedMessage id="week" defaultMessage="Week" />
        {' '}{index + 1}{' '}{index === nextQuestionId && `(next)`}
      </p>
      <FormattedMessage id="admin.dashboard.edit.questionInput" defaultMessage="Enter your question">
        {msg => <input
          required
          className="question-input"
          type="text"
          placeholder={msg}
          name={index}
          value={question || ''}
          onChange={e => onChange(e, index)}
        />}
      </FormattedMessage>
      <button
        type="button"
        className="btn-secondary btn-secondary-focus btn-secondary-focus-delete hide-sm"
        onClick={() => handleRemove(index)}
      >
        <FormattedMessage id="admin.dashboard.edit.remove" defaultMessage="Remove" />
      </button>
      <button
        type="button"
        className="btn-secondary btn-secondary-focus btn-secondary-focus-delete hide-large btn-mobile-campaign"
        onClick={() => handleRemove(index)}
      >
        x
    </button>
    </div>
  );
};

QuestionIcebreakerItemField.propTypes = {
  question: PropTypes.string.isRequired,
};

export default QuestionIcebreakerItemField;
