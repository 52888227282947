import React, { useState, Fragment, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Menu from '../layout/Menu';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import { getCompanyPlanAnswers, clearPlans } from '../../actions/planAnswer';
import { getPlanSettings } from '../../actions/plan';
import { getCompanyProfiles } from '../../actions/profile';
import { getAllCustoms } from '../../actions/custom';
import { getUserCompany } from '../../actions/company';
import PlanItem from './PlanItem';
import PlanTitle from '../elems/WeekDateTitle';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/no_content.svg';
import Notification from '../utils/Notification';
import { onboardingPlans } from '../../utils/onboarding/plans';
import { planCopy } from '../../utils/wording/plans';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import Moment from 'react-moment';
import { menuTitle, menuItems, getCheckinsMenu } from '../../utils/menu/checkins';
import SubMenu from '../elems/SubMenu';
import { Link } from 'react-router-dom';
import useDateFilter from '../utils/useDateFilter';

const customStyles = {
  option: (provided, state, isSelected) => ({
    ...provided,
    fontSize: '14px'
  }),
  input: () => ({
    fontSize: '14px'
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: '#ffffff',
    padding: '0px 8px',
    lineHeight: '20px',
    height: 36,
    minHeight: 36
  }),
  placeholder: (styles) => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
};

const Plan = ({
  clearPlans,
  getUserCompany,
  getCompanyProfiles,
  auth: { user },
  company: { company },
  planAnswer: { planAnswers, planAnswer, loading },
  getCompanyPlanAnswers,
  profile: { profiles },
  getAllCustoms,
  getPlanSettings,
  plan: { settings },
  custom: { campaigns },
  locale
}) => {
  const [formData, setFormData] = useState({
    team: '',
    plans: [],
    notes: ''
  });

  const [search, setSearch] = useState('');
  const [results, setResults] = useState({
    searchResults: false,
    searchKeyword: ''
  });

  const [menuData, setMenu] = useState({
    menu: {}
  });

  let { menu } = menuData;
  let { team, plans, notes } = formData;
  let { searchResults, searchKeyword } = results;
  let teamOptions = [];
  let mentions = [];

  // Перебор profiles & departments и приведение данных к формату Селекта
  {
    profiles &&
      profiles.forEach((profile, index) => {
        let mention = {
          id: profile._id,
          display: `${profile.firstName} ${profile.lastName}`
        };
        mentions.push(mention);

        if (user && profile._id === user._id) return;

        let label =
          (profile && profile.departmentName === null) || (profile && profile.department === undefined)
            ? `${profile.firstName} ${profile.lastName}`
            : `${profile.firstName} ${profile.lastName} - [${profile.departmentName}]`;

        let coworker = {
          value: profile._id,
          label,
          type: 3
        };

        teamOptions.push(coworker);
      });
  }

  {
    company &&
      company.departments.length > 0 &&
      company.departments.forEach((department, index) => {
        if (user && user.department === null) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };

          teamOptions.unshift(team);
        } else if (
          user &&
          user.department !== null &&
          user.department !== undefined &&
          department._id !== user.department
        ) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };

          teamOptions.unshift(team);
        }

        if (user && index === company.departments.length - 1) {
          // You is third in list
          let person = {
            value: user._id,
            label:
              (user && user.departmentName === null) || (user && user.department === undefined)
                ? `${user.firstName} ${user.lastName}`
                : `${user.firstName} ${user.lastName} - [${user.departmentName}]`,
            type: 3
          };

          teamOptions.unshift(person);

          // Second option is the team if exists
          if (user.department !== null && user.department !== undefined) {
            let team = { value: user.department, label: `${user.departmentName}`, type: 2 };
            teamOptions.unshift(team);
          }

          // Sort users alphabetically by first name
          teamOptions.sort((a, b) => a.label.localeCompare(b.label));

          // Third option is the company
          let name = { value: company._id, label: `${company.name}`, type: 1 };
          teamOptions.unshift(name);
        }
      });
  }

  if (company && company.departments.length === 0 && user && profiles && profiles.length === 1) {
    teamOptions = [
      { value: company._id, label: `💪 ${company.name}`, type: 1 },
      { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 }
    ];
  } else if (company && company.departments.length === 0 && user && profiles && profiles.length > 1) {
    // if 0 teams and 1+ users
    // add user and company to others members that we found in the profiles loop
    let person = { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 };
    teamOptions.unshift(person);

    let team = { value: company._id, label: `💪 ${company.name}`, type: 1 };
    teamOptions.unshift(team);
  }

  let choosedTeam = user
    ? {
        value: user._id,
        label:
          (user && user.departmentName === null) || (user && user.departmentName === undefined)
            ? `${user.firstName} ${user.lastName}`
            : `${user.firstName} ${user.lastName} - [${user.departmentName}]`,
        type: 3
      }
    : {};

    const getCompanyPlanAnswersStepped = useCallback(()=>{
      user && 
      getCompanyPlanAnswers({limit:30, user:user._id}).then(()=>getCompanyPlanAnswers())
    },[getCompanyPlanAnswers,user])

  useEffect(() => {
    getUserCompany();
    getCompanyPlanAnswersStepped();
    getCompanyProfiles();
    getAllCustoms();
    getPlanSettings();

  }, [
    getUserCompany,
    getCompanyPlanAnswersStepped,
    getCompanyProfiles,
    getAllCustoms,
    getPlanSettings
  ]);

  useEffect(()=>{
    
    setFormData({
      team: !user ? '' : choosedTeam,
      plans: !planAnswers ? [] : planAnswers.filter((answer) => answer.user === choosedTeam.value),
      notes: !planAnswers ? '' : planAnswers.notes
    });

    setMenu({
      menu: campaigns && campaigns.length > 0 ? getCheckinsMenu(campaigns) : menuItems
    });
  },[user,planAnswers,campaigns,getCheckinsMenu ])

  const handleTeam = (selectedOption) => {
    let newUpdates;
    if (selectedOption.type === 1) {
      newUpdates = planAnswers.filter((answer) => answer.company === selectedOption.value);
    } else if (selectedOption.type === 2) {
      newUpdates = planAnswers.filter((answer) => answer.department === selectedOption.value);
    } else if (selectedOption.type === 3) {
      newUpdates = planAnswers.filter((answer) => answer.user === selectedOption.value);
    }

    setFormData({
      ...formData,
      team: selectedOption,
      plans: newUpdates
    });
  };

  const noPlansMessageTeam = {
    title: <FormattedMessage id="noplans" defaultMessage="No Plans" />,
    msg: (
      <FormattedMessage
        id="noplans.msg"
        defaultMessage={`${team.label} has not finished weekly plans yet.`}
      />
    )
  };

  const noSearchResults = {
    title: <FormattedMessage id="search.noresults" defaultMessage="No Results Found" />,
    msg: (
      <FormattedMessage
        id="company.checkins.user.msg"
        defaultMessage={`Sorry, we couldn't find any results matching <b>"${searchKeyword}"</b>`}
        values={{ b: (...chunks) => <b>{chunks}</b> }}
      />
    )
  };

  const planLink = `/plans/complete`;

  const handleSearch = (e) => {
    setSearch(e.target.value);
    let plansArr = [];

    if (team.type === 1) {
      // Company
      plansArr = planAnswers.filter((answer) => answer.company === team.value);
    } else if (team.type === 2) {
      // Team
      plansArr = planAnswers.filter((answer) => answer.department === team.value);
    } else if (team.type === 3) {
      // User
      plansArr = planAnswers.filter((answer) => answer.user === team.value);
    }

    let filteredPlans = [];

    if (e.target.value.length !== 0) {
      // If search input is not empty

      plansArr.forEach((item) => {
        let answers = item.answers;
        let isUniqueItem = true;

        answers.forEach((answer) => {
          answer.text.forEach((text) => {
            if (!isUniqueItem) {
              return;
            }

            if (text.toLowerCase().includes(e.target.value.toLowerCase()) === true) {
              isUniqueItem = false;
              return filteredPlans.push(item);
            }
          });
        });
      });

      setFormData({
        ...formData,
        plans: filteredPlans
      });

      // Check if we have search results or not
      if (filteredPlans.length !== 0) {
        setResults({
          ...results,
          searchResults: false,
          searchKeyword: e.target.value // change to highlight search results
        });
      } else {
        // If we don't have search results
        setResults({
          ...results,
          searchResults: true,
          searchKeyword: e.target.value
        });
      }
    } else {
      // Empty search input
      setFormData({
        ...formData,
        plans: plansArr
      });

      setResults({
        ...results,
        searchResults: false,
        searchKeyword: ''
      });
    }
  };

  // Removing duplicates in answers array based on the object key 'plan' - to send only unique update items to UpdateTeamItem
  // Берем данные из стейта plans, а не редакса planAnswers, чтобы делать фильтр на странице
  // let planAnswersUnique = plans;  // ToDo: test
  let planAnswersUnique = useMemo(
    () =>
      plans
        ? plans.reduce((acc, current) => {
            const x = acc.find((item) => item.planID === current.planID);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, [])
        : [],
    [plans]
  );

  const [planAnswersUniqueFiltered, timeFilterOptions, timeFilterSelectedOption, timeFilterChangeOption] =
    useDateFilter(planAnswersUnique, (item) => item.date, locale && locale.lang);

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">
          {user && user.department ? (
            <Menu
              companyName={user && user.companyName}
              team={user.departmentName}
              teamId={user.department}
              settings={user && user.isAdmin && true}
              focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true}
              activeIndex={2}
            />
          ) : (
            <Menu
              companyName={user && user.companyName}
              settings={user && user.isAdmin && true}
              focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true}
              activeIndex={2}
            />
          )}
          <UserNav />
          <Fragment>
            {loading || !planAnswers || !user || !company ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="main main_new main-dashboard mt-3">
                  <div className="plans">
                    <div className="page-title">
                      <h5 className="pl-0-xs">{menuTitle}</h5>
                    </div>

                    <div className="insight mt-3">
                      <div className="mt-4 mb-5">
                        <SubMenu items={menu} activeIndex={3} mb={'1'} />
                      </div>

                      <div className="flex flex-column-xs">
                        <div className="flex align-center w-100-xs">
                          {(user && user.isAdmin) || (settings && !settings.isPrivate) ? (
                            <FormattedMessage
                              id="checkins.sub_menu.teamSelect"
                              defaultMessage="Choose the team or user"
                            >
                              {(msg) => (
                                <Select
                                  className="small-input w-250px w-50-xs mr-1-xs"
                                  defaultValue={choosedTeam}
                                  value={team}
                                  onChange={(selectedOption) => handleTeam(selectedOption)}
                                  options={teamOptions}
                                  isSearchable
                                  placeholder={msg}
                                  required
                                  name="team"
                                  styles={customStyles}
                                />
                              )}
                            </FormattedMessage>
                          ) : (
                            ''
                          )}

                          <FormattedMessage
                            id="checkins.sub_menu.timeSelect"
                            defaultMessage="Choose the time period"
                          >
                            {(msg) => (
                              <Select
                                className="small-input w-250px w-50-xs mr-1-xs ml-2"
                                defaultValue={choosedTeam}
                                value={timeFilterSelectedOption}
                                onChange={timeFilterChangeOption}
                                options={timeFilterOptions}
                                isSearchable
                                placeholder={msg}
                                required
                                name="time"
                                styles={customStyles}
                              />
                            )}
                          </FormattedMessage>

                          <FormattedMessage id="search.bar" defaultMessage="Search...">
                            {(msg) => (
                              <input
                                className="w-200px w-50-xs question-input ml-2 mla-xs pl-2 br-4 brd brd-grey bg__white outline mla lh-34"
                                type="text"
                                placeholder={msg}
                                name="search"
                                value={search}
                                onChange={(e) => handleSearch(e)}
                              />
                            )}
                          </FormattedMessage>
                        </div>

                        {company.plan !== 1 || company.plan === 1 && profiles.length > process.env.REACT_APP_FREE_PLAN_USERS ?
                          ((settings && settings.planID > 0) ||
                          (settings && planAnswer && settings.planID !== planAnswer.planID) ||
                          (settings && settings.planID > 0 && !planAnswer) ? (
                            <div className="w-180px h-34 mt-1-xs mla btn__secondary_green brd-transparent cursor text-center flex align-center">
                              <Link to={planLink} className={'fs-14 white mxa'}>
                                <FormattedMessage id="weekly.plan" defaultMessage="Weekly Plan" />
                              </Link>
                            </div>
                          ) : null) : null}
                      </div>
                    </div>

                    {company.plan !== 1 ? (
                      <Fragment>
                        {planAnswers.length === 0 ? (
                          <Fragment>
                            {settings && settings.planID > 0 && (
                              <Notification
                                msg={planCopy.planLive1}
                                msg2={planCopy.planLive2}
                                cta={planCopy.planLiveCta}
                                btnColor={'green'}
                                color={`green`}
                                url={planLink}
                              />
                            )}

                            <Fragment>
                              {onboardingPlans.map((plan) => (
                                <PlanItem
                                  key={`teamPlans-${plan._id}`}
                                  planAnswers={onboardingPlans}
                                  mentions={mentions}
                                />
                              ))}
                            </Fragment>
                          </Fragment>
                        ) : (
                          <Fragment>
                            {/* {settings && planAnswer && settings.planID !== planAnswer.planID || settings && settings.planID > 0 && !planAnswer && (
                                <Notification
                                  msg={planCopy.planLive1}
                                  msg2={planCopy.planLive2}
                                  cta={planCopy.planLiveCta}
                                  btnColor={'green'}
                                  color={`green`}
                                  url={planLink}
                                />
                              )} */}

                            {planAnswers.length > 0 && planAnswersUniqueFiltered.length > 0 ? (
                              planAnswersUniqueFiltered.map((planAnswerUnique, index) => (
                                <Fragment>
                                  {(settings && !settings.isPrivate) ||
                                  (settings && settings.isPrivate && user && user.isAdmin) ||
                                  (settings &&
                                    settings.isPrivate &&
                                    user &&
                                    !user.isAdmin &&
                                    planAnswersUniqueFiltered &&
                                    planAnswersUniqueFiltered.length > 0 &&
                                    planAnswersUniqueFiltered[0].user !== null &&
                                    user._id === planAnswersUniqueFiltered[0].user) ? (
                                    <Fragment key={`fragment-plan-${index}`}>
                                      <PlanTitle
                                        key={`date-${index}`}
                                        date={planAnswerUnique.date}
                                        index={index}
                                        days={7}
                                        action={'+'}
                                      />

                                      <PlanItem
                                        key={`plans-${planAnswerUnique._id}-${index}`}
                                        planAnswers={plans.filter(
                                          (planAnswer) =>
                                            planAnswer.planID.toString() ===
                                            planAnswerUnique.planID.toString()
                                        )}
                                        formData={formData}
                                        setFormData={setFormData}
                                        searchKeyword={searchKeyword.length > 0 && searchKeyword}
                                        settingsID={settings && settings.planID}
                                        campaignType={settings && settings.campaignType}
                                        auth={user && user._id}
                                        mentions={mentions}
                                      />
                                    </Fragment>
                                  ) : (
                                    ''
                                  )}
                                </Fragment>
                              ))
                            ) : (
                              <div className="mt-5">
                                {!searchResults ? (
                                  <NoContent
                                    message={noPlansMessageTeam}
                                    picture={updateImg}
                                    imgWidth={'w-300px'}
                                    alt={'No updates'}
                                  />
                                ) : (
                                  <NoContent
                                    message={noSearchResults}
                                    picture={updateImg}
                                    imgWidth={'w-300px'}
                                    alt={'No search results'}
                                  />
                                )}
                              </div>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      <NoContent
                        message={planCopy.updatePlanMsg}
                        picture={updateImg}
                        alt={'Update plan'}
                        imgWidth={'w-300px'}
                        admin={
                          user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0
                        }
                      />
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

Plan.propTypes = {
  clearPlans: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  getCompanyPlanAnswers: PropTypes.func.isRequired,
  getAllCustoms: PropTypes.func.isRequired,
  getPlanSettings: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  planAnswer: PropTypes.object.isRequired,
  plan: PropTypes.object.isRequired,
  custom: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  planAnswer: state.planAnswer,
  plan: state.plan,
  custom: state.custom,
  locale: state.locale
});

export default connect(mapStateToProps, {
  getCompanyPlanAnswers,
  getUserCompany,
  getCompanyProfiles,
  clearPlans,
  getAllCustoms,
  getPlanSettings
})(Plan);
