import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MainMenu from '../layout/Sidebar';
import Menu from '../layout/Menu';
import UserNav from '../layout/UserNav';
import SubMenu from '../admin/SubMenu';
import Navbar from '../layout/Navbar';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import PricingCardFree from './pricingcards/PricingCardFree';
import PricingCardPro from './pricingcards/PricingCardPro';
// import PricingCard1 from './pricingcards/PricingCard1';
// import PricingCard2 from './pricingcards/PricingCard2';
// import PricingCard3 from './pricingcards/PricingCard3';
// import PricingCard4 from './pricingcards/PricingCard4';
// import PricingCard5 from './pricingcards/PricingCard5';
// import PricingCard6 from './pricingcards/PricingCard6';
import { getUserCompany, updatePlan, checkCode, createSubscription, cancelSubscription } from '../../actions/company';
import { getCompanyProfiles } from '../../actions/profile';
import Moment from 'react-moment';
import { FormattedMessage } from 'react-intl';
import { pricingCopy } from '../../utils/wording/pricing';
import PopupPrice from '../popup/PopupPrice';

const Pricing = ({
  getCompanyProfiles,
  getUserCompany,
  updatePlan,
  checkCode,
  profile: { loading, profiles },
  company: { company },
  auth: { user, sidebar },
  createSubscription,
  cancelSubscription
}) => {
  useEffect(() => {
    getUserCompany();
    getCompanyProfiles();
  }, [getCompanyProfiles, getUserCompany]);

  let planName;
  let newPlanName;
  let price = 0;
  let freePrice = 0;
  let checkinPrice = 3;
  let checkinCulturePrice = 7;
  let proPrice = 7;
  let newProPrice = 3;
  let proCulturePrice = 12;

  // const [stripePromise, setStripePromise] = useState(null);
  // const [clientSecret, setClientSecret] = useState("");

  // console.log('========= clientSecret', clientSecret)
  // console.log('========= stripePromise', stripePromise)

  if (company) {
    if (company.plan === 1) planName = 'Free Forever';
    if (company.plan === 2) { planName = 'Check-ins'; price = checkinPrice };
    if (company.plan === 2.5) { planName = 'Check-ins + Culture'; price = checkinCulturePrice };
    if (company.plan === 3) { planName = 'Pro'; price = proPrice };
    // if (company.plan === 4) { planName = 'Pro + Culture'; price = proCulturePrice };
    if (company.plan === 4) { planName = 'Pro'; price = newProPrice };
    if (company.plan === 33) planName = 'Enterprise';
  }

  let discountPrice = Math.round(price * 0.8 * 100) / 100;
  let discountCheckinCulturePrice = Math.round(proPrice * 0.8);
  let discountProPrice = 5.6;
  let discountNewProPrice = 2.4;
  let discountProCulturePrice = 9.6;
  let discountCheckinPrice = 2.4;

  if (company && company.newPlan && company.newPlan.plan > 0) {
    if (company.newPlan.plan === 1) newPlanName = 'Free Forever';
    if (company.newPlan.plan === 2) newPlanName = 'Check-ins';
    if (company.newPlan.plan === 2.5) newPlanName = 'Check-ins + Culture';
    if (company.newPlan.plan === 3) newPlanName = 'Pro';
    // if (company.newPlan.plan === 4) newPlanName = 'Pro + Culture';
    if (company.newPlan.plan === 4) newPlanName = 'Pro';
    if (company.newPlan.plan === 33) newPlanName = 'Enterprise';
  }

  // if discount price is negative
  let discountPercentMonthly;
  let discountNotPercentMonthly;
  let discountPercentYearly;
  let discountNotPercentYearly;
  
  if (company && company.promocode && company.promocode.value) {

    discountPercentMonthly = profiles.length * price * (1 - company.promocode.value / 100);
    if (discountPercentMonthly < 0) discountPercentMonthly = 0;

    discountNotPercentMonthly = profiles.length * price - company.promocode.value;
    if (discountNotPercentMonthly < 0) discountNotPercentMonthly = 0;
    
    discountPercentYearly = profiles.length * discountPrice * 12 * (1 - company.promocode.value / 100);
    if (discountPercentYearly < 0) discountPercentYearly = 0;

    discountNotPercentYearly = profiles.length * discountPrice * 12 - company.promocode.value;
    if (discountNotPercentYearly < 0) discountNotPercentYearly = 0;
  }
  
  // Set user limit
  let ltdUserLimit = 0;

  if (company && company.promocode && company.promocode.isLTD) {

    if (company.promocode.userLimit) {
      ltdUserLimit = company.promocode.userLimit;
    } else {
      if (company.promocode.value === 59 || company.promocode.value === 69) {
          ltdUserLimit = 10;
        } else if (company.promocode.value === 99) {
          ltdUserLimit = 20;
        }
    }
  }

  const [formData, setFormData] = useState({
    code: ''
  });

  const { code } = formData;
  const [monthly, switchMonthly] = useState(true);
  const [allInOne, switchProduct] = useState(true);
  const [popup, togglePopup] = useState(false);
  const [codeInput, showCodeInput] = useState(false);

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = e => {
    e.preventDefault();

    checkCode(code, company._id, user.email);
  };

  return (
    <Fragment>
      <Fragment>
        <div className="css-main bg-primary">
          <div className="wrapper">
            {/* {user && user.department ? (
              <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user.isAdmin && true} activeIndex={9} />
            ) : (
              <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} activeIndex={9} />
            )} */}
            {user && !loading && (
              <MainMenu activeIndex={9} />
            )}
            <UserNav />
            <Fragment>
              {loading || !user || user && !user.isAdmin ? (
                <Spinner />
              ) : (
                <Fragment>
                  {/* <div className="main main-dashboard"> */}
                <div className={`${sidebar === 1 ? 'main main-dashboard ' : 'short_menu__ml__okrs ma-w90 pt-2'} mt-3`}>

                  <h5>
                    <FormattedMessage
                      id="admin.dashboard.pricing"
                      defaultMessage="Plans and Pricing"
                    />
                  </h5>

                  <SubMenu activeIndex={5} />

                  <div className="section-title flex-column-xs">

                    <div className="toggle flex align-center">
                      <button 
                        onClick={() => switchMonthly(!monthly)}
                        className={classnames('btn-secondary-comment btn__toggle-left', {
                          'btn__toggle-active': monthly
                        })}
                      >
                        <FormattedMessage
                          id="admin.pricing.monthly"
                          defaultMessage="Monthly"
                        />
                      </button>
                      <button 
                        onClick={() => switchMonthly(!monthly)}
                        className={classnames('btn-secondary-comment btn__toggle-right px-0', {
                          'btn__toggle-active': !monthly
                      })}
                        >
                      <FormattedMessage
                          id="admin.pricing.yearly"
                          defaultMessage="Yearly (-20%)"
                        />
                      </button>
                    </div>
                  </div>

                  {company && profiles && (
                    <Fragment>
                      {/* {allInOne ? (
                        <div className="flex widgets__pricing space-around my-2">
                          <PricingCard4 createSubscription={createSubscription} plan={company.plan} users={100} price={checkinPrice} discountPrice={discountCheckinPrice} people={profiles.length} monthly={monthly} updatePlan={updatePlan} id={company._id} togglePopup={togglePopup} popup={popup} user={user} />
                          <PricingCard2 createSubscription={createSubscription} plan={company.plan} users={100} price={proPrice} discountPrice={discountProPrice} people={profiles.length} monthly={monthly} updatePlan={updatePlan} id={company._id} togglePopup={togglePopup} popup={popup} user={user} />
                          <PricingCard5 createSubscription={createSubscription} plan={company.plan} users={100} price={proCulturePrice} discountPrice={discountProCulturePrice} people={profiles.length} monthly={monthly} updatePlan={updatePlan} id={company._id} togglePopup={togglePopup} popup={popup} user={user} />
                          <PricingCard3 plan={company.plan} users={101} />
                        </div>
                      ) : (
                        <div className="flex widgets__pricing space-around my-2">
                          <PricingCard4 plan={company.plan} users={100} price={checkinPrice} discountPrice={discountCheckinPrice} people={profiles.length} monthly={monthly} updatePlan={updatePlan} id={company._id} togglePopup={togglePopup} popup={popup} />
                          <PricingCard6 plan={company.plan} users={100} price={checkinCulturePrice} discountPrice={discountCheckinCulturePrice} people={profiles.length} monthly={monthly} updatePlan={updatePlan} id={company._id} togglePopup={togglePopup} popup={popup} />
                          <PricingCard3 plan={company.plan} users={101} />
                        </div>
                      )} */}

                      <div className="flex widgets__pricing space-around my-2">
                        <PricingCardFree 
                          createSubscription={createSubscription} 
                          plan={company.plan} 
                          users={30} 
                          price={freePrice} 
                          discountPrice={freePrice} 
                          people={profiles.length} 
                          monthly={monthly} 
                          updatePlan={updatePlan} 
                          id={company._id} 
                          togglePopup={togglePopup} 
                          popup={popup} 
                          user={user} 
                          subscriptionId={company.subscriptionId}
                          cancelSubscription={cancelSubscription}
                          planName={planName}
                        />
                        <PricingCardPro 
                          createSubscription={createSubscription} 
                          plan={company.plan} 
                          users={1000} 
                          price={newProPrice} 
                          discountPrice={discountNewProPrice} 
                          people={profiles.length} 
                          monthly={monthly} 
                          updatePlan={updatePlan} 
                          id={company._id} 
                          togglePopup={togglePopup} 
                          popup={popup} 
                          user={user} 
                        />
                      </div>
                      
                      <div className="pricing__current">
                        <p className="large bold">Current Plan</p>
                        {!company.promocode ? (
                          <p className="medium">Your current plan is <b>{planName}</b>. Expiry Date is <b>
                          <Moment format="Do MMMM YYYY">{company.expiredDate}</Moment></b>.</p>
                        ) : (
                          !company.promocode.isLTD ? (
                            <p className="medium">Your current plan is <b>{planName}</b>. Expiry Date is <b>
                            <Moment format="Do MMMM YYYY">{company.expiredDate}</Moment></b>.</p> 
                          ) : (
                            <p className="medium">Your current plan is <b>{planName} (Lifetime access)</b>. No Expired Date. <b>It's Lifetime Plan</b>.</p> 
                          )
                        )}

                        {company.newPlan && company.newPlan.plan > 0 && (
                          <Fragment>
                            {!company.newPlan.isCanceled ? (
                              <p className="medium blue">You have chosen to {company.newPlan.plan < company.plan ? 'downgrade' : 'upgrade'} your plan to <b>{newPlanName}</b>. It will be changed on <b><Moment format="Do MMMM YYYY">{company.expiredDate}</Moment></b>.</p>
                            ) : (
                              <p className="medium blue">You have cancelled your subscription to the {planName} plan. Your account will be moved to the Free Forever plan on <b><Moment format="Do MMMM YYYY">{company.expiredDate}</Moment></b>.</p>
                            )}
                          </Fragment>
                        )}
                      </div>
                     
                      <div className="pricing__details my-2">
                      <p className="large bold">{planName} Plan Details</p>
                       {!company.promocode ? (
                          monthly && company.plan !== 33 ? (
                            <Fragment>
                            {company.plan !== 1 && (
                              <p className="medium">Your workspace will be billed based on the number of active users – <b><Moment format="Do">{company.expiredDate}</Moment> each month.</b></p>
                            )}
                            </Fragment>
                          ) : (
                            <p className="medium">Your workspace will be billed annualy based on the number of active users.</p>
                          )
                        ) : (
                          !company.promocode.isLTD && (
                            monthly && company.plan !== 33 ? (
                              <p className="medium">Your workspace will be billed based on the number of active users – <b><Moment format="Do">{company.expiredDate}</Moment> each month.</b></p>
                            ) : (
                              <p className="medium">Your workspace will be billed annualy based on the number of active users.</p>
                            )
                            )
                        )}
                        
                        <p className="medium">You have <b>{profiles.length} active users</b> today.</p>
                        {company.promocode && company.promocode.isLTD && ltdUserLimit !== 0 && (
                          <Fragment>
                            <p className="medium"><b>{company.promocode.userLimit} users</b> have lifetime access according to your deal.</p>
                            <p className="small grey">If you have more users, you can pay monthly or yearly subscription only for extra users.</p>
                          </Fragment>
                        )}
                      </div>

                      <div className="pricing__details">
                        {monthly && company.plan !== 33 ? (
                          <Fragment>
                            {!company.promocode ? (
                              <p className="medium">Per month: <b>${profiles.length * price} USD</b></p>
                            ) : (
                              <Fragment>
                                <p className="medium">Per month: <b>${!company.promocode.isLTD ? (profiles.length * price) : (`0`)} USD</b></p>
                                {company.promocode.isActive && !company.promocode.isLTD && (
                                  <p className="medium">Discount (via promocode): <b>{company.promocode.isPercent ? (
                                    `${company.promocode.value}%`
                                  ) : (`$${company.promocode.value}`)}</b></p>
                                )}
                              </Fragment>
                            )}

                            {!company.promocode ? (
                              <p className="large bold blue">Total: <b>${profiles.length * price} USD</b></p>
                            ) : (
                              !company.promocode.isLTD ? (
                                !company.promocode.isActive ? (
                                <p className="large bold blue">Total: <b>${profiles.length * price} USD</b></p>
                                ) : (
                                <p className="large bold blue">Total: <b>${company.promocode.isPercent ? (
                                  discountPercentMonthly
                                ) : (discountNotPercentMonthly)} USD</b></p>
                                )
                              ) : (
                                <p className="large bold blue">Total: <b>${`0`} USD</b></p>
                              )
                            )}
                            
                            <p className="small">This is an estimate of your next invoice based of the number of users this month. The amount may change.</p>
                          </Fragment>
                        ) : (
                          <Fragment>
                            {company.plan !== 33 ? (
                              <Fragment>
                                {!company.promocode ? (
                                  <p className="medium">Per month: <b><span className="old-price grey">${profiles.length * price}</span> ${(profiles.length * discountPrice).toFixed(2)} USD</b> (Save 20%)</p>
                                ) : (
                                  <Fragment>
                                    {!company.promocode.isLTD ? (
                                      <p className="medium">Per month: <b><span className="old-price grey">
                                      ${profiles.length * price}</span> ${(profiles.length * discountPrice).toFixed(2)} USD</b> (Save 20%)</p>
                                    ) : (
                                      <p className="medium">Per month: <b>$0</b></p>)}
    
                                    {company.promocode.isActive && !company.promocode.isLTD && (
                                      <p className="medium">Discount (via promocode): <b>{company.promocode.isPercent ? (
                                        `${company.promocode.value}%`
                                      ) : (`$${company.promocode.value}`)}</b></p>
                                    )}
                                  </Fragment>
                                )}
                                
                                {!company.promocode ? (
                                  <p className="large bold blue">Total: <b><span className="old-price grey">${profiles.length * price * 12}</span> ${(profiles.length * discountPrice * 12).toFixed(2)} USD</b> (Save 20%)</p>
                                ) : (
                                  !company.promocode.isLTD ? (
                                    !company.promocode.isActive ? (
                                    <p className="large bold blue">Total: <b><span className="old-price grey">${profiles.length * price * 12}</span> ${(profiles.length * discountPrice * 12).toFixed(2)} USD</b> (Save 20%)</p>
                                    ) : (
                                      <p className="large bold blue">Total: <b><span className="old-price grey">${profiles.length * price * 12}</span> ${company.promocode.isPercent ? (discountPercentYearly) : (discountNotPercentYearly) } USD</b> ({`Save 20% + ${company.promocode.isPercent ? (`${company.promocode.value}%`) : (`$${company.promocode.value}`)}`})</p>
                                    )
                                  ) : (
                                  <p className="large bold blue">Total: <b>${`0`} USD</b></p>
                                  )
                                )}

                                <p className="small">This is an estimate of your next invoice based of the number of users this month. The amount may change.</p>
                              </Fragment>
                                ) : (
                                  <p className="medium">For custom enterprise pricing please contact your manager or use <Link className="medium blue" href="#!">this form</Link>.</p>
                                )}
                         </Fragment>
                        )}
                      </div>
                      {popup && <PopupPrice togglePopup={togglePopup} popup={popup} user={user} price={price} discountPrice={discountPrice} people={profiles.length} monthly={monthly}  />}
                    </Fragment>
                  )}

                  <div className="promocode">
                    <button
                        className="btn-msg mt-2 mb-2"
                        onClick={() => showCodeInput(!codeInput)}
                      >
                        <FormattedMessage
                          id="admin.pricing.promocodeTitle"
                          defaultMessage="Do you have promocode?"
                        />
                      </button>

                      {codeInput && (
                        <form
                          className="form-app form-app-invite mb-2"
                          onSubmit={e => onSubmit(e)}
                        >
                          <div className="field_company field">
                            <FormattedMessage
                              id="settings.pricing.codeInput"
                              defaultMessage="Promocode"
                            >
                              {msg => (
                                <input
                                  type="text"
                                  className="company mr-2"
                                  placeholder={msg}
                                  name="code"
                                  value={code}
                                  onChange={e => onChange(e)}
                                  required
                                />
                              )}
                            </FormattedMessage>

                            <button 
                              type="submit" 
                              className="btn-secondary btn-secondary-comment"
                            >
                              <FormattedMessage
                                id="settings.pricing.codeBtn"
                                defaultMessage="Enter Code"
                              />
                            </button>
                        </div>
                      </form>
                      )}
                  </div>
                  <div className="pricing__footer w-60 mb-2">
                    <p className="medium-xs grey">For payment with ACH or for custom enterprise pricing please contact us via <a href="https://usefocus.co/demo" className="medium-xs" rel="noopener noreferrer">this form</a> or via <a className="medium-xs" href={`mailto:${process.env.REACT_APP_EMAIL}?subject=Enterprise pricing request`}>{process.env.REACT_APP_EMAIL}</a>.</p>
                  </div>
                  <div className="mb-5">
                    <p 
                      className="medium-xs grey pointer" 
                      // onClick={cancelSubscription}
                      onClick={() => { if (window.confirm(`Are you sure you wish to cancel subscription? You will lose all benefits from the ${planName} plan.`)) cancelSubscription() } }
                    >
                      Cancel Subscription
                    </p>
                  </div>
                  </div>
                </Fragment>
              )}
            </Fragment>
          </div>
        </div>
      </Fragment>
    </Fragment>
  );
};

Pricing.propTypes = {
  getCompanyProfiles: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  checkCode: PropTypes.func.isRequired,
  updatePlan: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  createSubscription: PropTypes.object.isRequired,
  cancelSubscription: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  company: state.company
});

export default connect(
  mapStateToProps,
  { getCompanyProfiles, getUserCompany, updatePlan, checkCode, createSubscription, cancelSubscription }
)(Pricing);
