export const isVisible = (campaing, user) => {
  if (!campaing) {
    return true;
  }

  const visibility = campaing.visibility || {};

  if (visibility.value === 1) {
    return true;
  }

  if (!user) {
    return false;
  }

  if (user.isAdmin) {
    return true;
  }

  if (visibility.value === 2) {
    const { department } = user;

    if (!department) {
      return false;
    }

    if (campaing.team && campaing.team.value === department.toString()) {
      return true;
    }

    const emailUsers = campaing.emailUsers || [];

    if (emailUsers.some(({ team, email }) => team === 2 && email === department.toString())) {
      return true;
    }
  }
  
  if (visibility.value === 3) {
    if (campaing.team && (campaing.team.value === user.email || campaing.team.value === user._id.toString())) {
      return true;
    }

    const emailUsers = campaing.emailUsers || [];

    if (emailUsers.some(({ team, email }) => team === 3 && email === user.email)) {
      return true;
    }

    const slackUsers = campaing.slackUsers || [];

    if (slackUsers.some(({ team, value }) => team === 3 && value === user.slackId)) {
      return true;
    }
  }

  return false;
};