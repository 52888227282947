import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SelectLib from 'react-select';

const SelectWithValue = ({
  key, defaultValue,
  onChange, options, isMulti,
  isSearchable, msg, styles,
  selectType, divStyles, hideTitle,
  index, containerStyles, titleStyles,
  wording, customStyles, isClearable,
  value, components
}) => {


  return (
    <div className={`${divStyles}`}>
      <FormattedMessage
        id={wording.placeholderId}
        defaultMessage={wording.placeholderMsg}
      >
      {msg => (
        <SelectLib
          key={key}
          defaultValue={defaultValue}
          value={!value ? defaultValue : value}
          onChange={selectedOption =>
            onChange(selectedOption, index)
          }
          options={options}
          isMulti={isMulti}
          isSearchable={isSearchable}
          isClearable={isClearable}
          placeholder={msg}
          styles={customStyles}
          components={components}
        />
      )}
      </FormattedMessage>
    </div>
  );
};

SelectWithValue.propTypes = {
  user: PropTypes.object,
};

export default SelectWithValue;
