import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DetailsTitle from './DetailsTitle';
import DetailsUpdate from './DetailsUpdateNew';
import DetailsUpdateItem from './DetailsUpdateItem';
import DetailsChanges from './DetailsChanges';
import DetailsChart from './DetailsChart';
import KR from '../kr/KeyResultItem';
import BtnCommAndLike from '../../elems/BtnCommAndLike';
import UpdateOkrBtn from '../../elems/UpdateOkrBtn';
import OkrAvatarItem from '../elements/OkrAvatarItem';
import CommentItem from '../../comment/okrs/CommentItem';
import CommentForm from '../../comment/okrs/CommentForm';
import { FormattedMessage } from 'react-intl';
import { icons } from '../../../img/elements/icons';

const Okrdetails = ({
  okr: {
    owner,
    ownerAvatar,
    ownerFirstName,
    ownerLastName,
    dueIn,
    _id,
    objective,
    teamName,
    progress,
    status,
    updatedAt,
    keyResults,
    likes,
    comments,
    updates,
    changes
  },
  handleDetails,
  detailsId,
  detailsIndex,
  user,
  locale,
  findUserLike,
  handleLike,
  findComments,
  handleStatus,
  onSubmitUpdate,
  okrData,
  setOkrData,
  formData,
  setFormData,
  updateMode,
  toggleUpdateMode,
  deleteUpdate,
  metrics,
  useOutsideAlerter,
  isLoader,
  btnCta,
  ownerOptions,
}) => {
  // console.log('===== btnCta in okrdetails', btnCta)

  const [discussion, showComments] = useState(false);
  const [allActions, showAllActions] = useState(false);

  let statusObj =
    locale && locale.lang !== 'ru'
      ? { onTrack: 'On Track', behind: 'Behind', atRisk: 'At Risk' }
      : { onTrack: 'По Плану', behind: 'Отстаем', atRisk: 'Риск' };
  const statusOptions = locale &&
    locale.lang && [
      { value: 1, label: statusObj.onTrack, color: '#6ddeb3' },
      { value: 2, label: statusObj.behind, color: '#facf34' },
      { value: 3, label: statusObj.atRisk, color: 'red' }
    ];
  let choosedStatus = {};

  status &&
    statusOptions.forEach((item) => {
      if (item.value === status) {
        choosedStatus = {
          value: item.value,
          label: item.label,
          color: item.color
        };
      }
    });

  useEffect(() => {
    setOkrData({
      keyResults: !keyResults ? [] : keyResults,
      status: !status ? '' : choosedStatus
    });
  }, []);

  // Item
  let item = {};
  let kr = {};

  item.status = status;
  item.updatedAt = updatedAt;
  item.goalName = objective;
  item.comments = comments;
  item.likes = likes;
  item.changes = changes;
  item.teamName = teamName;
  item.dueIn = dueIn;

  // Check is it Objective or Key Result
  if (detailsId === _id) {
    // OKR
    item.title = objective;
    item.progress = progress;
    item.updates = updates;
    item.owner = owner;
    item.ownerAvatar = ownerAvatar;
    item.ownerFirstName = ownerFirstName;
    item.ownerLastName = ownerLastName;
    item.isObjective = true; // to show all KRs in update
    item.current = progress;
    item.goal = 100;
  } else {
    // Key Result
    kr = keyResults.filter((el) => el._id === detailsId)[0];

    item.title = kr.title;
    item.progress = kr.progress;
    item.updates = updates.filter((el) => el.updateId === detailsId);
    item.owner = kr.owner;
    item.ownerAvatar = kr.ownerAvatar;
    item.ownerFirstName = kr.ownerFirstName;
    item.ownerLastName = kr.ownerLastName;
    item.isObjective = false;
    item.current = kr.current ? kr.current : kr.start;
    item.goal = kr.goal;
  }

  // Здесь делаем onChange, чтобы забирать все KRs из ОКР, не стейта.
  // Если будем править только 1 КР, то автоматом подтянуться все остальные
  const onChangeKr = (e, index) => {
    keyResults[index][e.target.name] = e.target.value;
    keyResults[index].isNew = true;
    setOkrData({ ...okrData, keyResults: keyResults });
  };

  const onChange = (e) => {
    setOkrData({ ...okrData, [e.target.name]: e.target.value });
  };

  const handleKeyResults = (selectedOption, index, type) => {
    // console.log('==selectedOption in handleKeyResults', selectedOption)

    if (type === 'details') {
      keyResults[index].details = [selectedOption];
      keyResults[index].krCurrent = selectedOption.value;
      keyResults[index].isNew = true; // new for identifying KR that was changed
    } else if (type === 'milestone') {
      keyResults[index].krCurrent = selectedOption.value;
      keyResults[index].krType.activeMilestoneIndex = selectedOption.index;
      keyResults[index].isNew = true; 
    }

    setOkrData({ ...okrData, keyResults: keyResults });
  };
  
  let historyTxt = <FormattedMessage id="more.changes" defaultMessage="View Action History" />;
  
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  // console.log('===== item', item)

  // Change = 1, Update = 2
  item.changes &&
    item.changes.forEach((object) => {
      object.updateOrChange = 1;
    });
  item.updates &&
    item.updates.forEach((object) => {
      object.updateOrChange = 2;
    });

  let history = item.changes;
  history = history.concat(item.updates);

  history.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });

  const getChanges = (item) => {
    let newChanges = formData.changes;
  
    // If we didn't save this update
    if (newChanges && newChanges.indexOf(item) === -1) {
      newChanges.push(item);
    }
  
    return newChanges;
  };

  const onChangeKRSelect = (selectedOption, name, index) => {
    // e.preventDefault();
    let newChanges = getChanges(name);

    keyResults[index][name] = selectedOption;
    setFormData({ ...formData, keyResults: keyResults, changes: newChanges });
  };

  const onChangeKRMetricsSelect = (selectedOption, index) => {

    // console.log('==== selectedOption in onChangeKRMetricsSelect', selectedOption)
    // console.log('==== index in onChangeKRMetricsSelect', index)

    let newChanges = getChanges('owners')
    // let newChanges = getKrChanges('krResults', 'owners', index, 0, '');   // по факту, не работает. Надо менять на более простую схему. 
    // console.log('==== newChanges in onChangeKRMetricsSelect', newChanges)

    keyResults[index].krOwners = selectedOption;
    keyResults[index].isNewOwner = true; // new for identifying KR that was changed
    setFormData({ ...formData, keyResults: keyResults, changes: newChanges });
  };


  // console.log('====== formData in details', formData);
  // console.log('====== ownerOptions in details', ownerOptions);

  return (
    <Fragment>
      <div
        ref={wrapperRef}
        className="okr-details fixed p-5 bg__white right-top h-100 z-50000 shadow vertical-scroll pb-5"
      >
        <div className="pb-3">
          <form className="form__okr-update" onSubmit={(e) => onSubmitUpdate(e, _id, detailsId)}>
            <DetailsTitle
              item={item}
              handleDetails={handleDetails}
              detailsId={detailsId}
              okrId={_id}
              updateMode={updateMode}
              handleStatus={handleStatus}
              choosedStatus={choosedStatus}
              statusOptions={statusOptions}
            />

            <div className="line"></div>

            <div className="my-25">
              <OkrAvatarItem
                ownerAvatar={ownerAvatar}
                ownerFirstName={ownerFirstName}
                ownerLastName={ownerLastName}
              />
            </div>

            <DetailsChart metrics={metrics} />

            <div className="mt-3 mb-2">
              {detailsIndex === 99 ? (
                keyResults.map((el, index) => (
                  <KR
                    key={`${el._id}-${index}`}
                    keyResult={el}
                    okrId={_id}
                    updateMode={updateMode}
                    index={index}
                    onChange={onChangeKr}
                    krStyles="w-30"
                    progress={el.progress}
                    isHideProgress={true}
                    krType={el.krType}
                    details={el.details}
                    handleKeyResults={handleKeyResults}
                    onChangeKRSelect={onChangeKRSelect}
                    onChangeKRMetricsSelect={onChangeKRMetricsSelect}
                    ownerOptions={ownerOptions}
                  />
                ))
              ) : (
                <Fragment>
                  <KR
                    keyResult={keyResults[detailsIndex]}
                    okrId={_id}
                    updateMode={updateMode}
                    index={detailsIndex}
                    onChange={onChangeKr}
                    krStyles="w-30"
                    progress={keyResults[detailsIndex].progress}
                    isHideProgress={true}
                    onChangeKRSelect={onChangeKRSelect}
                    onChangeKRMetricsSelect={onChangeKRMetricsSelect}
                    ownerOptions={ownerOptions}
                  />
                </Fragment>
              )}
            </div>

            <DetailsUpdate
              item={item}
              updateMode={updateMode}
              toggleUpdateMode={toggleUpdateMode}
              user={user}
              okrData={okrData}
              onChange={onChange}
              deleteUpdate={deleteUpdate}
              okrId={_id}
            />

            <div className="flex space-between align-center mt-3 mb-1">
              <BtnCommAndLike
                likes={likes}
                findUserLike={findUserLike}
                handleLike={handleLike}
                comments={comments}
                showComments={showComments}
                discussion={discussion}
                findComments={findComments}
                okrId={_id}
              />

              <UpdateOkrBtn
                update={updateMode}
                toggleUpdateMode={toggleUpdateMode}
                onSubmit={onSubmitUpdate}
                okrId={_id}
                detailsId={detailsId}
                isLoader={isLoader}
                btnCta={btnCta}
              />
            </div>
          </form>

          {discussion && (
            <Fragment>
              <div className="card-feedback mt-2">
                <div className="comment">
                  {comments.map((comment) => (
                    <CommentItem
                      key={comment._id}
                      comment={comment}
                      okrID={_id}
                      formDataParent={formData}
                      setFormDataParent={setFormData}
                    />
                  ))}

                  <CommentForm
                    okrID={_id}
                    user={user}
                    avatar={user && user.avatar}
                    formDataParent={formData}
                    setFormDataParent={setFormData}
                  />
                </div>
              </div>
            </Fragment>
          )}

          <div className="more-changes pt-1">
            <div onClick={() => showAllActions(!allActions)} className="small grey cursor">
              {historyTxt}
              <span className="ml-xs icon__grey">{!allActions ? icons.angle_down : icons.angle_up}</span>
            </div>
          </div>
          {allActions && history && history.length > 0 ? (
            <Fragment>
              <div className="line"></div>
              {history.map((item, i) =>
                item.updateOrChange === 1 ? (
                  <DetailsChanges key={`changes-${i + 1}`} item={item} okrId={_id} okrTitle={objective} />
                ) : (
                  <DetailsUpdateItem
                    key={`updates-${i + 1}`}
                    item={item}
                    deleteUpdate={deleteUpdate}
                    okrId={_id}
                    authUser={user}
                    okrTitle={objective}
                    // lastUpdate={history[i - 1]}
                  />
                )
              )}
            </Fragment>
          ) : (
            allActions && <p className="small">There is no action history in this OKR</p>
          )}
        </div>
      </div>
    </Fragment>
  );
};

Okrdetails.propTypes = {
  okr: PropTypes.object.isRequired,
  handleDetails: PropTypes.func.isRequired
};

export default Okrdetails;
