import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserNav from '../../layout/UserNav';
import Menu from '../../layout/Menu';
import Navbar from '../../layout/Navbar';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';
import SubMenu from '../../elems/SubMenu';
import ThreeStateCheckbox from '../../elems/ThreeStateCheckbox';
import { getCurrentProfile, updateAllNotifications } from '../../../actions/profile';
import { FormattedMessage } from 'react-intl';

const Notifications = ({
  getCurrentProfile,
  updateAllNotifications,
  history,
  profile: { profile },
  auth: { loading, user }
}) => {
  const [formData, setFormData] = useState({
    standupAnswersComment: '',
    updateAnswersComment: '',
    recognitionsComment: '',
    okrsComment: '',
    standupAnswersLike: '',
    updateAnswersLike: '',
    recognitionsLike: '',
    okrsLike: '',
    okrsDeadline: '',
    okrsUpdatedByOthers: '',
    okrsNotUpdated: '',
    tasksDeadline: '',
    tasksCreatedByOther: '',
    recognitionsCreated: '',
    standupAnswersAuthorComment: '',
    updateAnswersAuthorComment: '',
    recognitionsAuthorComment: '',
    okrsAuthorComment: '',
    standupAnswersNotUpdated: '',
    planAnswersComment: '',
    planAnswersLike: '',
    planAnswersAuthorComment: '',
    customAnswersComment: '',
    customAnswersLike: '',
    customAnswersAuthorComment: '',
    oneOnOneCreate: '',
    oneOnOneUpdate: '',
  });

  const {
    standupAnswersComment,
    updateAnswersComment,
    recognitionsComment,
    okrsComment,
    standupAnswersLike,
    updateAnswersLike,
    recognitionsLike,
    okrsLike,
    okrsDeadline,
    okrsUpdatedByOthers,
    okrsNotUpdated,
    tasksDeadline,
    tasksCreatedByOther,
    recognitionsCreated,
    standupAnswersAuthorComment,
    updateAnswersAuthorComment,
    recognitionsAuthorComment,
    okrsAuthorComment,
    standupAnswersNotUpdated,
    planAnswersComment,
    planAnswersLike,
    planAnswersAuthorComment,
    customAnswersComment,
    customAnswersLike,
    customAnswersAuthorComment,
    oneOnOneCreate,
    oneOnOneUpdate,
   } = formData;

  useEffect(() => {
    getCurrentProfile();
    
    if (profile && profile.notifications) {
      setFormData({
        standupAnswersComment: !profile.notifications.standupAnswersComment ? false : profile.notifications.standupAnswersComment,
        updateAnswersComment: !profile.notifications.updateAnswersComment ? false : profile.notifications.updateAnswersComment,
        recognitionsComment: !profile.notifications.recognitionsComment ? false : profile.notifications.recognitionsComment,
        okrsComment: !profile.notifications.okrsComment ? false : profile.notifications.okrsComment,
        standupAnswersLike: !profile.notifications.standupAnswersLike ? false : profile.notifications.standupAnswersLike,
        updateAnswersLike: !profile.notifications.updateAnswersLike ? false : profile.notifications.updateAnswersLike,
        recognitionsLike: !profile.notifications.recognitionsLike ? false : profile.notifications.recognitionsLike,
        okrsLike: !profile.notifications.okrsLike ? false : profile.notifications.okrsLike,
        okrsDeadline: !profile.notifications.okrsDeadline ? false : profile.notifications.okrsDeadline,
        okrsUpdatedByOthers: !profile.notifications.okrsUpdatedByOthers ? false : profile.notifications.okrsUpdatedByOthers,
        okrsNotUpdated: !profile.notifications.okrsNotUpdated ? false : profile.notifications.okrsNotUpdated,
        tasksDeadline: !profile.notifications.tasksDeadline ? false : profile.notifications.tasksDeadline,
        tasksCreatedByOther: !profile.notifications.tasksCreatedByOther ? false : profile.notifications.tasksCreatedByOther,
        recognitionsCreated: !profile.notifications.recognitionsCreated ? false : profile.notifications.recognitionsCreated,
        standupAnswersAuthorComment: !profile.notifications.standupAnswersAuthorComment ? false : profile.notifications.standupAnswersAuthorComment,
        updateAnswersAuthorComment: !profile.notifications.updateAnswersAuthorComment ? false : profile.notifications.updateAnswersAuthorComment,
        recognitionsAuthorComment: !profile.notifications.recognitionsAuthorComment ? false : profile.notifications.recognitionsAuthorComment,
        okrsAuthorComment: !profile.notifications.okrsAuthorComment ? false : profile.notifications.okrsAuthorComment,
        standupAnswersNotUpdated: !profile.notifications.standupAnswersNotUpdated ? false : profile.notifications.standupAnswersNotUpdated,
        planAnswersComment: !profile.notifications.planAnswersComment ? false : profile.notifications.planAnswersComment,
        planAnswersLike: !profile.notifications.planAnswersLike ? false : profile.notifications.planAnswersLike,
        planAnswersAuthorComment: !profile.notifications.planAnswersAuthorComment ? false : profile.notifications.planAnswersAuthorComment,
        customAnswersComment: !profile.notifications.customAnswersComment ? false : profile.notifications.customAnswersComment,
        customAnswersLike: !profile.notifications.customAnswersLike ? false : profile.notifications.customAnswersLike,
        customAnswersAuthorComment: !profile.notifications.customAnswersAuthorComment ? false : profile.notifications.customAnswersAuthorComment,
        oneOnOneCreate: !profile.notifications.oneOnOneCreate ? false : profile.notifications.oneOnOneCreate,
        oneOnOneUpdate: !profile.notifications.oneOnOneUpdate ? false : profile.notifications.oneOnOneUpdate,
      });
    }
  }, [getCurrentProfile, profile && profile.notifications && profile.notifications.length]);


  const [allSelected, setAllSelected] = useState(null);

  useEffect(() => {
    if (Object.values(formData).every(Boolean)) {
      setAllSelected(true);
    } else if (Object.values(formData).some(Boolean)) {
      setAllSelected(null);
    } else {
      setAllSelected(false);
    }
  }, [formData]);

  const onChangeAllSelected = useCallback((value) => {
    setFormData(
      Object.keys(formData)
        .reduce((newFormData, key) => ({ ...newFormData, [key]: value }), {})
    );
  }, [formData]);

  const onSubmit = e => {
    e.preventDefault();

    updateAllNotifications(formData, history);
  };


  const title = <FormattedMessage id="notifications" defaultMessage="Notifications" />;
  const settingsTitle = <FormattedMessage id="settings" defaultMessage="Settings" />;
  const checkinsTitle = <FormattedMessage id="checkins" defaultMessage="Daily Check-ins" />;
  const standupAnswersCommentTitle = <FormattedMessage id="checkins.notify.comment" defaultMessage="Your check-in was commented" />;
  const standupAnswersLikeTitle = <FormattedMessage id="checkins.notify.like" defaultMessage="Your check-in was liked" />;
  const standupAnswersAuthorCommentTitle = <FormattedMessage id="checkins.notify.comment.commentator" defaultMessage="Author of a check-in answered your comment" />;
  const standupAnswersNotUpdatedTitle = <FormattedMessage id="checkins.notify.notUpdated" defaultMessage="Check-in reminder" />;
  const updatesTitle = <FormattedMessage id="updates" defaultMessage="Weekly Updates" />;
  const updateAnswersCommentTitle = <FormattedMessage id="updates.notify.comment" defaultMessage="Your weekly update was commented" />;
  const updateAnswersLikeTitle = <FormattedMessage id="updates.notify.like" defaultMessage="Your update was liked" />;
  const updateAnswersAuthorCommentTitle = <FormattedMessage id="updates.notify.comment.commentator" defaultMessage="Author of an update answered your comment" />;
  const okrsTitle = <FormattedMessage id="okrs" defaultMessage="Objectives and Key Results (OKRs)" />;
  const okrsCommentTitle = <FormattedMessage id="okrs.notify.comment" defaultMessage="OKR was commented" />;
  const okrsLikeTitle = <FormattedMessage id="okrs.notify.like" defaultMessage="OKR was liked" />;
  const okrsDeadlineTitle = <FormattedMessage id="okrs.notify.dueDate" defaultMessage="OKR due date reminder" />;
  const okrsUpdatedByOthersTitle = <FormattedMessage id="okrs.notify.update" defaultMessage="OKR update notification" />;
  const okrsNotUpdatedTitle = <FormattedMessage id="okrs.notify.notUpdated" defaultMessage="OKR update reminder" />;
  const okrsAuthorCommentTitle = <FormattedMessage id="okrs.notify.comment.commentator" defaultMessage="Author of an OKR answered your comment" />;
  const tasksTitle = <FormattedMessage id="tasks" defaultMessage="Tasks" />;
  const tasksDeadlineTitle = <FormattedMessage id="tasks.notify.dueDate" defaultMessage="Tasks due date reminder" />;
  const tasksCreatedByOtherTitle = <FormattedMessage id="tasks.notify.created" defaultMessage="A teammate assigned a task to you" />;
  const kudosTitle = <FormattedMessage id="kudos" defaultMessage="Kudos" />;
  const recognitionsCommentTitle = <FormattedMessage id="kudos.notify.comment" defaultMessage="Your kudo was commented" />;
  const recognitionsLikeTitle = <FormattedMessage id="kudos.notify.like" defaultMessage="Your kudos was liked" />;
  const recognitionsCreatedTitle = <FormattedMessage id="kudos.notify.created" defaultMessage="Receiving kudos" />;
  const recognitionsAuthorCommentTitle = <FormattedMessage id="kudos.notify.comment.commentator" defaultMessage="Author of a kudo answered your comment" />;
  const icebreakersTitle = <FormattedMessage id="icebreakers" defaultMessage="Icebreakers" />;
  const iceberakerCommentTitle = <FormattedMessage id="icebreakers.notify.comment" defaultMessage="Your icebreakers was commented" />;
  const iceberakerLikeTitle = <FormattedMessage id="icebreakers.notify.like" defaultMessage="Your icebreaker was liked" />;
  const iceberakerAuthorCommentTitle = <FormattedMessage id="icebreakers.notify.comment.commentator" defaultMessage="Author of an icebreaker answered your comment" />;
  const planTitle = <FormattedMessage id="plans" defaultMessage="Weekly Plans" />;
  const planAnswersCommentTitle = <FormattedMessage id="plans.notify.comment" defaultMessage="Your weekly plan was commented" />;
  const planAnswersLikeTitle = <FormattedMessage id="plans.notify.like" defaultMessage="Your weekly plan was liked" />;
  const planAnswersAuthorCommentTitle = <FormattedMessage id="plans.notify.comment.commentator" defaultMessage="Author of a weekly plan answered your comment" />;
  const customTitle = <FormattedMessage id="customs" defaultMessage="Custom Check-ins" />;
  const customAnswersCommentTitle = <FormattedMessage id="customs.notify.comment" defaultMessage="Your answer on a custom campaign was commented" />;
  const customAnswersLikeTitle = <FormattedMessage id="customs.notify.like" defaultMessage="Your answer on a custom campaign was liked" />;
  const customAnswersAuthorCommentTitle = <FormattedMessage id="customs.notify.comment.commentator" defaultMessage="Author of an answer on a custom campaign answered your comment" />;
  const oneOnOneTitle = <FormattedMessage id="one_on_one" defaultMessage="1:1 Meetings" />;
  const oneOnOneCreateTitle = <FormattedMessage id="one_on_one.create" defaultMessage="You receive an email when someone creates 1:1 meeting with you" />;
  const oneOnOneUpdateTitle = <FormattedMessage id="one_on_one.update" defaultMessage="You receive an email when someone updated information after 1:1 meeting with you" />;
  const allTitle = <FormattedMessage id="all.notification" defaultMessage="Switch off all notifications" />;
  const activeTitle = <FormattedMessage id="active" defaultMessage="Active" />;
  const disabledTitle = <FormattedMessage id="disabled" defaultMessage="Disabled" />;

  const menuItems = [
    {
      title,
      url: '/profile/notifications',
      isActive: true
    }
  ]

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">
          {user && user.department ? (
            <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user.isAdmin && true} outreach={user && user.isAdmin && user.role === 'Superadmin' && true}  />
          ) : (
            <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} />
          )}
          <UserNav />
          <Fragment>
            {loading || !profile ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="main main-dashboard main-profile-admin">

                  <div className="section-title">
                    <h5> 
                    {settingsTitle}
                    </h5>
                    <Link
                      to="/"
                      className="btn-secondary btn-secondary-back"
                    >
                      <FormattedMessage id="go.back" defaultMessage="Go Back" />
                    </Link>
                  </div>
                  <SubMenu items={menuItems} activeIndex={1} />
                    <div className="component edit-standups-daily my-2 p-3 light-shadow">
                      <div className="form-full">
                      <div className="form-main-inline">
                        <div className="w-100-xs w-300px"/>
                          <ThreeStateCheckbox value={allSelected} onChange={onChangeAllSelected} />
                        </div>
                        <form
                          className="form-app form-app-standup"
                          onSubmit={e => onSubmit(e)}
                        >
                           <Fragment>
                            <p className="sub-title focus-yellow focus my-2 mb-2">
                               {checkinsTitle}
                            </p>
                            <div className="form-main form-main-inline standups-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {standupAnswersCommentTitle}
                              </p>
                              <div className="checkbox email pl-3 mb-2-xs">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="standupAnswersComment"
                                    name="standupAnswersComment"
                                    checked={standupAnswersComment}
                                    value={standupAnswersComment}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        standupAnswersComment: !standupAnswersComment
                                      });
                                    }}
                                  />
                                  <label htmlFor="standupAnswersComment">
                                    {standupAnswersComment ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                             <div className="form-main form-main-inline standups-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {standupAnswersAuthorCommentTitle}
                              </p>
                              <div className="checkbox email pl-3 mb-2-xs">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="standupAnswersAuthorComment"
                                    name="standupAnswersAuthorComment"
                                    checked={standupAnswersAuthorComment}
                                    value={standupAnswersAuthorComment}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        standupAnswersAuthorComment: !standupAnswersAuthorComment
                                      });
                                    }}
                                  />
                                  <label htmlFor="standupAnswersAuthorComment">
                                    {standupAnswersAuthorComment ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-main form-main-inline standups-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {standupAnswersLikeTitle}
                              </p>
                              <div className="checkbox email pl-3">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="standupAnswersLike"
                                    name="standupAnswersLike"
                                    checked={standupAnswersLike}
                                    value={standupAnswersLike}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        standupAnswersLike: !standupAnswersLike
                                      });
                                    }}
                                  />
                                  <label htmlFor="standupAnswersLike">
                                    {standupAnswersLike ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                             <div className="form-main form-main-inline standups-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {standupAnswersNotUpdatedTitle}
                              </p>
                              <div className="checkbox email pl-3">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="standupAnswersNotUpdated"
                                    name="standupAnswersNotUpdated"
                                    checked={standupAnswersNotUpdated}
                                    value={standupAnswersNotUpdated}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        standupAnswersNotUpdated: !standupAnswersNotUpdated
                                      });
                                    }}
                                  />
                                  <label htmlFor="standupAnswersNotUpdated">
                                    {standupAnswersNotUpdated ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Fragment>

                          <Fragment>
                            <p className="sub-title focus-yellow focus my-2 mb-2">
                               {updatesTitle}
                            </p>
                            <div className="form-main form-main-inline standups-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {updateAnswersCommentTitle}
                              </p>
                              <div className="checkbox email pl-3 mb-2-xs">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="updateAnswersComment"
                                    name="updateAnswersComment"
                                    checked={updateAnswersComment}
                                    value={updateAnswersComment}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        updateAnswersComment: !updateAnswersComment
                                      });
                                    }}
                                  />
                                  <label htmlFor="updateAnswersComment">
                                    {updateAnswersComment ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-main form-main-inline standups-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {updateAnswersAuthorCommentTitle}
                              </p>
                              <div className="checkbox email pl-3 mb-2-xs">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="updateAnswersAuthorComment"
                                    name="updateAnswersAuthorComment"
                                    checked={updateAnswersAuthorComment}
                                    value={updateAnswersAuthorComment}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        updateAnswersAuthorComment: !updateAnswersAuthorComment
                                      });
                                    }}
                                  />
                                  <label htmlFor="updateAnswersAuthorComment">
                                    {updateAnswersAuthorComment ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-main form-main-inline updates-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {updateAnswersLikeTitle}
                              </p>
                              <div className="checkbox email pl-3">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="updateAnswersLike"
                                    name="updateAnswersLike"
                                    checked={updateAnswersLike}
                                    value={updateAnswersLike}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        updateAnswersLike: !updateAnswersLike
                                      });
                                    }}
                                  />
                                  <label htmlFor="updateAnswersLike">
                                    {updateAnswersLike ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                          <Fragment>
                            <p className="sub-title focus-yellow focus my-2 mb-2">
                               {planTitle}
                            </p>
                            <div className="form-main form-main-inline standups-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {planAnswersCommentTitle}
                              </p>
                              <div className="checkbox email pl-3 mb-2-xs">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="planAnswersComment"
                                    name="planAnswersComment"
                                    checked={planAnswersComment}
                                    value={planAnswersComment}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        planAnswersComment: !planAnswersComment
                                      });
                                    }}
                                  />
                                  <label htmlFor="planAnswersComment">
                                    {planAnswersComment ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-main form-main-inline standups-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {planAnswersAuthorCommentTitle}
                              </p>
                              <div className="checkbox email pl-3 mb-2-xs">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="planAnswersAuthorComment"
                                    name="planAnswersAuthorComment"
                                    checked={planAnswersAuthorComment}
                                    value={planAnswersAuthorComment}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        planAnswersAuthorComment: !planAnswersAuthorComment
                                      });
                                    }}
                                  />
                                  <label htmlFor="planAnswersAuthorComment">
                                    {planAnswersAuthorComment ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-main form-main-inline plans-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {planAnswersLikeTitle}
                              </p>
                              <div className="checkbox email pl-3">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="planAnswersLike"
                                    name="planAnswersLike"
                                    checked={planAnswersLike}
                                    value={planAnswersLike}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        planAnswersLike: !planAnswersLike
                                      });
                                    }}
                                  />
                                  <label htmlFor="planAnswersLike">
                                    {planAnswersLike ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>

                            </div>
                          </Fragment>
                          <Fragment>
                            <p className="sub-title focus-yellow focus my-2 mb-2">
                               {customTitle}
                            </p>
                            <div className="form-main form-main-inline standups-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {customAnswersCommentTitle}
                              </p>
                              <div className="checkbox email pl-3 mb-2-xs">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="customAnswersComment"
                                    name="customAnswersComment"
                                    checked={customAnswersComment}
                                    value={customAnswersComment}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        customAnswersComment: !customAnswersComment
                                      });
                                    }}
                                  />
                                  <label htmlFor="customAnswersComment">
                                    {customAnswersComment ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-main form-main-inline standups-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {customAnswersAuthorCommentTitle}
                              </p>
                              <div className="checkbox email pl-3 mb-2-xs">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="customAnswersAuthorComment"
                                    name="customAnswersAuthorComment"
                                    checked={customAnswersAuthorComment}
                                    value={customAnswersAuthorComment}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        customAnswersAuthorComment: !customAnswersAuthorComment
                                      });
                                    }}
                                  />
                                  <label htmlFor="customAnswersAuthorComment">
                                    {customAnswersAuthorComment ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-main form-main-inline customs-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {customAnswersLikeTitle}
                              </p>
                              <div className="checkbox email pl-3">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="customAnswersLike"
                                    name="customAnswersLike"
                                    checked={customAnswersLike}
                                    value={customAnswersLike}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        customAnswersLike: !customAnswersLike
                                      });
                                    }}
                                  />
                                  <label htmlFor="customAnswersLike">
                                    {customAnswersLike ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                          <Fragment>
                            <p className="sub-title focus-yellow focus my-2 mb-2">
                               {okrsTitle}
                            </p>
                            <div className="form-main form-main-inline standups-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {okrsCommentTitle}
                              </p>
                              <div className="checkbox email pl-3 mb-2-xs">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="okrsComment"
                                    name="okrsComment"
                                    checked={okrsComment}
                                    value={okrsComment}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        okrsComment: !okrsComment
                                      });
                                    }}
                                  />
                                  <label htmlFor="okrsComment">
                                    {okrsComment ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                             <div className="form-main form-main-inline standups-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {okrsAuthorCommentTitle}
                              </p>
                              <div className="checkbox email pl-3 mb-2-xs">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="okrsAuthorComment"
                                    name="okrsAuthorComment"
                                    checked={okrsAuthorComment}
                                    value={okrsAuthorComment}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        okrsAuthorComment: !okrsAuthorComment
                                      });
                                    }}
                                  />
                                  <label htmlFor="okrsAuthorComment">
                                    {okrsAuthorComment ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-main form-main-inline okrs">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {okrsLikeTitle}
                              </p>
                              <div className="checkbox email pl-3">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="okrsLike"
                                    name="okrsLike"
                                    checked={okrsLike}
                                    value={okrsLike}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        okrsLike: !okrsLike
                                      });
                                    }}
                                  />
                                  <label htmlFor="okrsLike">
                                    {okrsLike ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-main form-main-inline okrs">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {okrsDeadlineTitle}
                              </p>
                              <div className="checkbox email pl-3">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="okrsDeadline"
                                    name="okrsDeadline"
                                    checked={okrsDeadline}
                                    value={okrsDeadline}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        okrsDeadline: !okrsDeadline
                                      });
                                    }}
                                  />
                                  <label htmlFor="okrsDeadline">
                                    {okrsDeadline ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-main form-main-inline okrs">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {okrsUpdatedByOthersTitle}
                              </p>
                              <div className="checkbox email pl-3">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="okrsUpdatedByOthers"
                                    name="okrsUpdatedByOthers"
                                    checked={okrsUpdatedByOthers}
                                    value={okrsUpdatedByOthers}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        okrsUpdatedByOthers: !okrsUpdatedByOthers
                                      });
                                    }}
                                  />
                                  <label htmlFor="okrsUpdatedByOthers">
                                    {okrsUpdatedByOthers ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-main form-main-inline okrs">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {okrsNotUpdatedTitle}
                              </p>
                              <div className="checkbox email pl-3">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="okrsNotUpdated"
                                    name="okrsNotUpdated"
                                    checked={okrsNotUpdated}
                                    value={okrsNotUpdated}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        okrsNotUpdated: !okrsNotUpdated
                                      });
                                    }}
                                  />
                                  <label htmlFor="okrsNotUpdated">
                                    {okrsNotUpdated ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                          <Fragment>
                            <p className="sub-title focus-yellow focus my-2 mb-2">
                               {tasksTitle}
                            </p>
                            <div className="form-main form-main-inline standups-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {tasksDeadlineTitle}
                              </p>
                              <div className="checkbox email pl-3 mb-2-xs">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="tasksDeadline"
                                    name="tasksDeadline"
                                    checked={tasksDeadline}
                                    value={tasksDeadline}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        tasksDeadline: !tasksDeadline
                                      });
                                    }}
                                  />
                                  <label htmlFor="tasksDeadline">
                                    {tasksDeadline ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-main form-main-inline okrs">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {tasksCreatedByOtherTitle}
                              </p>
                              <div className="checkbox email pl-3">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="tasksCreatedByOther"
                                    name="tasksCreatedByOther"
                                    checked={tasksCreatedByOther}
                                    value={tasksCreatedByOther}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        tasksCreatedByOther: !tasksCreatedByOther
                                      });
                                    }}
                                  />
                                  <label htmlFor="tasksCreatedByOther">
                                    {tasksCreatedByOther ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                          <Fragment>
                            <p className="sub-title focus-yellow focus my-2 mb-2">
                               {oneOnOneTitle}
                            </p>
                            <div className="form-main form-main-inline standups-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {oneOnOneCreateTitle}
                              </p>
                              <div className="checkbox email pl-3 mb-2-xs">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="oneOnOneCreate"
                                    name="oneOnOneCreate"
                                    checked={oneOnOneCreate}
                                    value={oneOnOneCreate}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        oneOnOneCreate: !oneOnOneCreate
                                      });
                                    }}
                                  />
                                  <label htmlFor="oneOnOneCreate">
                                    {oneOnOneCreate ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-main form-main-inline standups-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {oneOnOneUpdateTitle}
                              </p>
                              <div className="checkbox email pl-3 mb-2-xs">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="oneOnOneUpdate"
                                    name="oneOnOneUpdate"
                                    checked={oneOnOneUpdate}
                                    value={oneOnOneUpdate}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        oneOnOneUpdate: !oneOnOneUpdate
                                      });
                                    }}
                                  />
                                  <label htmlFor="oneOnOneUpdate">
                                    {oneOnOneUpdate ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                          <Fragment>
                            <p className="sub-title focus-yellow focus my-2 mb-2">
                               {kudosTitle}
                            </p>
                            <div className="form-main form-main-inline standups-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {recognitionsCommentTitle}
                              </p>
                              <div className="checkbox email pl-3 mb-2-xs">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="recognitionsComment"
                                    name="recognitionsComment"
                                    checked={recognitionsComment}
                                    value={recognitionsComment}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        recognitionsComment: !recognitionsComment
                                      });
                                    }}
                                  />
                                  <label htmlFor="recognitionsComment">
                                    {recognitionsComment ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-main form-main-inline standups-time ">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {recognitionsAuthorCommentTitle}
                              </p>
                              <div className="checkbox email pl-3 mb-2-xs">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="recognitionsAuthorComment"
                                    name="recognitionsAuthorComment"
                                    checked={recognitionsAuthorComment}
                                    value={recognitionsAuthorComment}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        recognitionsAuthorComment: !recognitionsAuthorComment
                                      });
                                    }}
                                  />
                                  <label htmlFor="recognitionsAuthorComment">
                                    {recognitionsAuthorComment ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="form-main form-main-inline okrs">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {recognitionsLikeTitle}
                              </p>

                              <div className="checkbox email pl-3">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="recognitionsLike"
                                    name="recognitionsLike"
                                    checked={recognitionsLike}
                                    value={recognitionsLike}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        recognitionsLike: !recognitionsLike
                                      });
                                    }}
                                  />
                                  <label htmlFor="recognitionsLike">
                                    {recognitionsLike ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="form-main form-main-inline okrs">
                              <p className="bold medium block w-100-xs pb-0 w-300px">
                                {recognitionsCreatedTitle}
                              </p>

                              <div className="checkbox email pl-3">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="recognitionsCreated"
                                    name="recognitionsCreated"
                                    checked={recognitionsCreated}
                                    value={recognitionsCreated}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        recognitionsCreated: !recognitionsCreated
                                      });
                                    }}
                                  />
                                  <label htmlFor="recognitionsCreated">
                                    {recognitionsCreated ? activeTitle : disabledTitle}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                          <div className="line"></div>
                          <button type="submit" className="btn btn-form">
                            <FormattedMessage
                              id="admin.dashboard.edit.submit"
                              defaultMessage="Submit"
                            />
                          </button>
                        </form>
                      </div>
                    </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

Notifications.propTypes = {
  updateAllNotifications: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, 
  {
    getCurrentProfile,
    updateAllNotifications,
})(Notifications);
