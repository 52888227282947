import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { setAlert } from '../../../actions/alert';
import Moment from 'react-moment';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  editRecognitionComment,
  deleteRecognitionComment
} from '../../../actions/recognition';

const CommentItem = ({
  comment: { firstName, lastName, text, user, date, avatar, _id },
  auth,
  deleteRecognitionComment,
  editRecognitionComment,
  recognitionID,
  formData,
  setFormData
}) => {
      
  // ЗДЕСЬ получаем коллбэк и перебираем formData.checkins и обновляем комменты
  const handleDelete = async (recognitionID, _id) => {
    try {
      const res = await deleteRecognitionComment(recognitionID, _id);

      setFormData({ 
        ...formData, 
        kudos: formData.kudos.map(answer => {
          if (answer._id === res.kudoID) {
            // Delete comment 
            answer.comments = answer.comments.filter(comment => comment._id !== res.commentID);
           
            return answer;
          } else {
            return answer;
          }
        })
      });
      
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in comments', err);
    }
  };

  return (
    <div className="comment-body">
      <div className="comment-item">
        <Link to={user ? `/user/${user}` : '#!'} className="user">
          <img
            src={avatar}
            alt="Avatar"
            className="round-img d-block avatar"
            width="45px"
            height="45px"
          />
        </Link>

        <div className="comment-head">
          <div className="comment-info">
            <p className="comment-user medium bold">
              <strong>
                {firstName} {lastName}
              </strong>
            </p>

            <time className="js-local-time comment-date">
              <Moment format="ddd LLL">{date}</Moment>
            </time>

            <div className="icons btn-icons-edit">
              {!auth.loading && user === auth.user._id && (
                <button
                  type="button"
                  className="btn-icons btn-delete"
                  onClick={e => handleDelete(recognitionID, _id)}
                >
                  <i className="fas fa-times" />
                </button>
              )}
            </div>
          </div>

          <p className="comment-text">{text}</p>
        </div>
      </div>
    </div>
  );
};

CommentItem.propTypes = {
  auth: PropTypes.object.isRequired,
  recognitionID: PropTypes.string.isRequired,
  comment: PropTypes.object.isRequired,
  editRecognitionComment: PropTypes.func.isRequired,
  deleteRecognitionComment: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { editRecognitionComment, deleteRecognitionComment }
)(CommentItem);
