import React, { useState } from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../../../img/logo/logo.svg';
import Table from './Table';
import PageHeader from './PageHeader';


// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft:60,
    paddingRight:60,
    paddingBotton:60,
    marginBotton:60,
    lineHeight: 1.5,
    flexDirection: 'column',
  }, 
  logo: {
      width: 122,
      height: 35,
      marginLeft: 'auto',
      marginRight: 'auto'
  },  
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    top: 20,
    // left: 0,
    right: 60,
    textAlign: "center",
    color: "grey",
  } 
});

const PDF = ({ data }) => {

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <Image style={styles.logo} src={logo} />
        <PageHeader title={`${data[0].companyName}'s OKRs`} />
        <Table data={data} />
        <Text 
          style={styles.pageNumber}
          render={({pageNumber, totalPages}) => `Page ${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};


export default PDF;
