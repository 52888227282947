import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { FormattedMessage } from 'react-intl';
import OkrUpdate from './OkrUpdate';
import Select from 'react-select';
import DatePickerComponent from '../../elems/DatePicker';

const selectCustomStyles = {
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isFocused ? '#404ff5' : '#fff',
    color: state.isFocused ? '#fff' : '#000',
    fontSize: '18px'
  }),
  control: (styles, state) => ({
    ...styles,
    borderColor: state.isFocused ? '404ff5' : '#e9eaf5',
    boxShadow: state.isFocused ? '0px 0px 20px #404FF54D' : 'none',
    fontSize: '18px',
    border: state.isFocused ? '2px solid #404ff5 !important' : styles.border,
    margin: state.isFocused ? '0px' : '1px'
  })
};

const OkrAvatar = ({
  owner,
  ownerAvatar,
  ownerFirstName,
  ownerLastName,
  dueIn,
  noLink,
  updatedAt,
  isAvatarNoLink,
  updateMode,
  ownerOptions,
  choosedOwner,
  onChangeSelect,
}) => {
  
  return (
    <Fragment>
      {!noLink && !isAvatarNoLink ? (
        <div className="flex align-center justify-start okr__avatar">
          <Link to={`/user/${owner}`} className="user mr-14">
            <img
              src={ownerAvatar && ownerAvatar}
              alt={`User avatar`}
              className="round-img d-block avatar"
              width="40"
              height="40"
            />
          </Link>
          <div className="okr__owner">
            <p className="fs-10 grey_new fw-600 lh-14">
              <FormattedMessage id="user.okr.create.owner" defaultMessage="Owner" />
            </p>
            <Link to={`/user/${owner}`}>
              <p className="fs-16 lh-22">
                {ownerFirstName} {ownerLastName}
              </p>
            </Link>
            <div className="flex align-center">
              <span className="fs-10">
                <FormattedMessage id="user.okr.due" defaultMessage="Due" />:{' '}
                {dueIn ? <Moment format="D MMM">{dueIn}</Moment> : '–'}
              </span>
              <span className="fs-10 px-xs">|</span>
              <OkrUpdate updatedAt={updatedAt} />
            </div>
          </div>
        </div>
      ) : (
        <Fragment>
          {!updateMode ? (
            <div className="flex align-center justify-start mr-xs">
              <span className="user mr-14 flex align-center">
                <img
                  src={ownerAvatar && ownerAvatar}
                  alt={`User avatar`}
                  className="round-img d-block avatar"
                  width="40"
                  height="40"
                />
              </span>
              <div className="okr__owner">
                <p className="fs-10 grey_new fw-600 lh-14">
                  <FormattedMessage id="user.okr.create.owner" defaultMessage="Owner" />
                </p>
                <p className="fs-16 lh-22">
                  {ownerFirstName} {ownerLastName}
                </p>
                <div className="flex align-center">
                  <span className="fs-10">
                    <FormattedMessage id="user.okr.due" defaultMessage="Due" />:{' '}
                    {dueIn ? <Moment format="D MMM">{dueIn}</Moment> : '–'}
                  </span>
                  <span className="fs-10 px-xs">|</span>
                  <OkrUpdate updatedAt={updatedAt} />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-column">
              <div className="okr__avatar__owner_div flex flex- align-center justify-start mr-1">
                <p className="w-50px inline-block fs-10 grey_new fw-600 lh-14">
                    <FormattedMessage id="user.okr.create.owner" defaultMessage="Owner" />
                  </p>
                <FormattedMessage
                  id="user.okr.create.ownerSelect"
                  defaultMessage="Choose the owner of the Objective"
                >
                  {(msg) => (
                    <Select
                      className="okr__avatar__owner"
                      defaultValue={choosedOwner}
                      value={owner}
                      onChange={(selectedOption) => onChangeSelect(selectedOption, selectedOption.name)}
                      options={ownerOptions}
                      isSearchable
                      placeholder={msg}
                      required
                      styles={selectCustomStyles}
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className="flex align-center form-app form-app-ok mt-0">
                <Fragment>
                  <div className="h-35 flex align-center">
                    <span className="w-50px inline-block fs-10 grey_new fw-600 lh-14"><FormattedMessage id="user.okr.due" defaultMessage="Due" /></span>
                    <DatePickerComponent
                      value={dueIn}
                      onSelect={(day) => onChangeSelect(day, 'dueIn')}
                      isUpdate={true}
                    />
                  </div>
                </Fragment>
              </div>
          </div>
          )}  
          
        </Fragment>
      )}
    </Fragment>
  );
};

OkrAvatar.propTypes = {
  dueIn: PropTypes.string
};

export default OkrAvatar;
