import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const WhiteBtn = ({ text, isUrl, url, isExternalUrl }) => {
  
  return (
    <Fragment>
      {isUrl ? (
        <div className="onboarding__content__button h-54 br-4 brd brd-grey__light bg__transparent text-center icon__box flex align-center justify-center">
          <Link to={url} className="w-100 black">
            {text}
          </Link>
        </div>
      ) : isExternalUrl ? (
        <div className="onboarding__content__button h-54 br-4 brd brd-grey__light bg__transparent text-center icon__box flex align-center justify-center">
          <a href={url} className="w-100 black">
            {text}
          </a>
        </div>
      ) : null}
    </Fragment>
    
  );
};

export default WhiteBtn;
