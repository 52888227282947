import React from 'react';

import { FormattedMessage } from 'react-intl';
import Select from 'react-select';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px'
  }),
  input: () => ({
    fontSize: '14px'
  }),
  placeholder: (styles) => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
};

const CustomFormQuestionSingleSelectAnswer = ({ data, value, handleChange, errorIndex, index }) => {
  const onChange = (val) => {
    handleChange([val]);
  };
  return (
    <div className="form-main ">
      <FormattedMessage id="tst" defaultMessage="Select Option">
        {(msg) => (
          <Select
            className={`small-input w-70-xs mb-2-xs ${errorIndex === index ? 'brd-red__select' : ''}`}
            value={value[0]}
            onChange={onChange}
            options={data.selectOptions}
            isSearchable
            placeholder={msg}
            styles={customStyles}
          />
        )}
      </FormattedMessage>
    </div>
  );
};

export default CustomFormQuestionSingleSelectAnswer;
