import React from 'react';
import PropTypes from 'prop-types';
import { icons } from "../../img/elements/icons";

import { FormattedMessage } from 'react-intl';

const InputNew = ({ setText, text }) => {

  return (
    <div className="form-comment flex align-center">
      <input
        type="text"
        className='brd_new__grey p-1 form_new__hover_primary_1px br-4'
        placeholder={''}
        value={text}
        onChange={e => setText(e.target.value)}
        required
      />

      <button
        type="submit"
        className="flex align-center bg-blue p-1 br-4 brd-transparent cursor btn-secondary__blue-hover"
      >
        {icons.paper_plane_white}
      </button>
    </div>
  );
};

InputNew.propTypes = {
  setText: PropTypes.func.isRequired
};

export default InputNew;
