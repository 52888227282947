import React from "react";
import PropTypes from 'prop-types';

const Switch = ({
  name, value, index, onChange
}) => {

  return (
    <label className="switch">
      <input 
        type="checkbox"
        name={name}
        checked={value}
        value={value}
        onChange={e =>
          onChange(e.target.value, index, name)
        }
      />
      <span className="slider-green round"></span>
    </label>
  );
};

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};


export default Switch;
