import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";

const TableTitle = () => {
  return (
    <Fragment>
      <div className="flex justify-align-baseline py-2 w-100">
          <p className="w-150px fs-12 grey_new text-left bold mb-0">
            <FormattedMessage
              id="team"
              defaultMessage="Team"
            />
          </p>
          <div className="flex mla">
            <p className="w-120px fs-12 grey_new text-right bold mb-0">
              <FormattedMessage
                id="okr.achieved"
                defaultMessage="Objectives achieved"
              />
            </p>
            <p className="w-100px fs-12 grey_new text-right bold mb-0">
              <FormattedMessage
                  id="okr.all"
                  defaultMessage="All Objectives"
                />
                </p>
            <p className="w-100px fs-12 grey_new text-right bold mb-0">
              <FormattedMessage
                  id="okr.progress"
                  defaultMessage="OKR Progress"
              />
            </p>
          </div>
      </div>
    </Fragment>
  );
};


export default TableTitle;
