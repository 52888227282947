import React from "react";
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const ExternalSecondBtn = ({ url, cta }) => {

  return (
    <a href={url} className="h-54 lh-25 btn mw-200 btn__secondary_green shadow_new__green__btn text-center br-4" rel="noopener noreferrer">{cta}</a>
  );
};

ExternalSecondBtn.propTypes = {
  cta: PropTypes.object,
};

export default ExternalSecondBtn;
