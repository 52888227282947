import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Select from 'react-select';
import { icons } from '../../../img/elements/icons';
import Switch from '../../elems/Switch'
import Info from '../../elems/Info'

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: state.isFocused ? '#404ff5' : '#e9eaf5',
    boxShadow: state.isFocused ? '0px 0px 20px #404FF54D' : 'none',
    border: state.isFocused ? '1px solid #404ff5' : styles.border,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    fontSize: '14px',
    height: '38px',
  }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize, };
  }
}

const QuestionItemArrayNew = ({
  question,
  index,
  onChange,
  handleRemove,
  handleSelectChange,
  onChangeOptions,
  handleKeypressOptions,
  type, 
  onChangeQuestionTasks,
  isCopy,
  isHideTask
}) => {

  let questionOptions = [
    { label: `Q${index+1}: Text Field`, value: 'text' },
    { label: `Q${index+1}: Single Select`, value: 'single_select' },
    { label: `Q${index+1}: Multiple Select`, value: 'multiple_select' },
    { label: `Q${index+1}: OKR Select`, value: 'okr_select' },
  ];

  let inputId = question && question.questionType && question.questionType.value !== 'okr_select' ? "admin.dashboard.edit.questionInput" : "questionInput.okrquestion"
  let defaultMsg = question && question.questionType && question.questionType.value !== 'okr_select' ? "Enter your question here" : "Enter your question relating to OKR here"
  const infoMsg = <FormattedMessage id="okrquestion.info" defaultMessage="This question type allows the respondent to select one of their OKRs" />;
  const taskTxt = <FormattedMessage id="create.tasks" defaultMessage="Create tasks" />;
  // console.log('====== inputId',inputId)
  // console.log('====== defaultMsg',defaultMsg)

  // type 1 - create, type 2 - edit
  let defaultQ = type === 2 || isCopy ? question.questionType : questionOptions[0];
  
  // console.log('====== question',question)
  // console.log('====== index',index)

  return (
    <Fragment>
        <div
          className="form-main form-main-inline form-app-okr flex align-start"
        >
          <div className="w-300px h-44 mr-1">
            <FormattedMessage
              id="choose.question.type"
              defaultMessage="Choose question type"
            >
              {msg => (
                <Select
                  key={`questionType-${index}`}
                  // defaultValue={questionOptions[0]}
                  defaultValue={defaultQ}
                  onChange={selectedOption =>
                    handleSelectChange(selectedOption, index, 'questionType')
                  }
                  options={questionOptions}
                  isSearchable
                  placeholder={msg}
                  styles={customStyles}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="w-70 flex align-start flex-column justify-start relative">
            <FormattedMessage id={inputId} defaultMessage={defaultMsg}>
              {msg => <input
                required
                className="w-100 input-text h-44 brd-grey__new"
                type="text"
                placeholder={msg}
                name={index}
                value={question.text || ''}
                onChange={e => onChange(e, index)}
              />}
            </FormattedMessage>
            {question && question.questionType && question.questionType.value === 'single_select' || question && question.questionType && question.questionType.value === 'multiple_select' ? (
              question && question.selectOptions && question.selectOptions.length > 0 && (
                question.selectOptions.map((o, idx) => (
                  <div className="mt-1 w-100" key={`options-${idx}`}>
                    <FormattedMessage id="select.single.type" defaultMessage="Type and press Enter to add new answer">
                      {msg => <input
                        id={`selectOption-${idx}`}
                        className={`w-100 input-text h-44 brd-grey__new`}
                        type="text"
                        placeholder={msg}
                        name={index}
                        value={o.label || ''}
                        onChange={e => onChangeOptions(e, index, idx)}
                        onKeyPress={e => handleKeypressOptions(e, index, idx)}
                      />}
                    </FormattedMessage>
                  </div>
                ))
              )
            ) : null}
            {question && question.questionType && question.questionType.value === 'okr_select' && (
              <Info 
                size={16}
                msg={infoMsg}
              />
            )}
          </div>
          <div className="h-44 mx-2 flex flex-column align-center">
            <p className="w-100 fs-10 pr-0 lh-14 mb-xs">Required</p>
            <Switch
              name="isRequired"
              value={question.isRequired}
              onChange={handleSelectChange}
              index={index}
            />
          </div>
          <div className="checkbox w-90px h-44">
            {!isHideTask && question && question.questionType && question.questionType.value === 'text' && (
              <div className="checkbox-item checkbox__task flex flex-column align-center mr-0">
                <input
                  type="checkbox"
                  id={`task-${index}`}
                  name="isTask"
                  checked={question.isTask}
                  value={question.isTask}
                  onChange={e => onChangeQuestionTasks(e, index, "isTask")}
                />
                <label className="fs-10" htmlFor={`task-${index}`}>{taskTxt}</label>
              </div>
            )}
          </div>
          <button
            type="button"
            className="h-44 lh-15 flex align-center no_btn bg-transparent"
            onClick={() => handleRemove(index)}
          >
            {icons.circle_close}
          </button>
          <button
            type="button"
            className="btn-secondary btn-secondary-focus btn-secondary-focus-delete hide-large btn-mobile-campaign"
            onClick={() => handleRemove(index)}
          >
            x
        </button>
      </div>
    </Fragment>
  );
};

QuestionItemArrayNew.propTypes = {
  question: PropTypes.object.isRequired,
  text: PropTypes.object,
};

export default QuestionItemArrayNew;
