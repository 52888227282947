import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { icons } from '../../../img/elements/icons';
import UserNav from '../../layout/UserNav';
import Menu from '../../layout/Menu';
import OkrTips from '../elements/OkrTipsNew';
import { addOkr, getCompanyOkrs, getOkrById } from '../../../actions/okr';
import { getCompanyProfiles } from '../../../actions/profile';
import { getUserCompany } from '../../../actions/company';
import Spinner from '../../layout/Spinner';
import FormTitle from '../../utils/FormTitle_new';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import { setAlert } from '../../../actions/alert';
import chroma from 'chroma-js';
import Loader from 'react-loader-spinner';
import getLabels from '../../../translations/okrs';
import KRmetrics from '../elements/KRmetricsForm';
import useAnalyticsEventTracker from '../../../utils/analytics/useAnalyticsEventTracker';
import DatePickerComponent from '../../elems/DatePicker';


// OKR Privacy:
// 1 - public (company level)
// 2 - team level
// 3 - private level

// Styles for color in Status Select
const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10
  },
  colourStyles
});

const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: state.isFocused ? '#404ff5' : '#e9eaf5',
    boxShadow: state.isFocused ? '0px 0px 20px #404FF54D' : 'none',
    border: state.isFocused ? '2px solid #404ff5' : styles.border,
    margin: state.isFocused ? '0px' : '1px',
    fontSize: '18px'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      fontSize: '18px',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        color: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css())
      }
    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
};
const selectCustomStyles = {
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isFocused ? '#404ff5' : '#fff',
    color: state.isFocused ? '#fff' : '#000',
    fontSize: '18px'
  }),
  control: (styles, state) => ({
    ...styles,
    borderColor: state.isFocused ? '404ff5' : '#e9eaf5',
    boxShadow: state.isFocused ? '0px 0px 20px #404FF54D' : 'none',
    fontSize: '18px',
    border: state.isFocused ? '2px solid #404ff5 !important' : styles.border,
    margin: state.isFocused ? '0px' : '1px'
  })
};

const css = `
  .my-selected:not([disabled]) { 
    font-weight: bold; 
    border: 2px solid currentColor;
  }
  .my-selected:hover:not([disabled]) { 
    border-color: #404ff5;
    color: blue;
  }
  .my-today { 
    font-weight: bold;
    font-size: 140%; 
    color: red;
  },
  .rdp {
    position: absolute;
    box-shadow: 0 0 20px #404FF54D;
    color: red;
  }
`;

const endOfTheYear = new Date();
endOfTheYear.setMonth(11);
endOfTheYear.setDate(31);

// Functions for Copy OKR feature
// User is copying the previous OKR instead of creating the new one from scratch
const getPrivacy = (privacy, privacyOptions) => {
  if (privacy === 1 || privacy === undefined) return privacyOptions[0];
  if (privacy === 2) return privacyOptions[1];
  if (privacy === 3) return privacyOptions[2];
};

const getChoosedTeam = (data) => {
  let choosedTeam = data ? { value: data.team, label: data.teamName, type: data.type } : {};

  return choosedTeam;
};

const getChoosedOwner = (data) => {
  let choosedOwner = data ? { value: data.owner, label: `${data.ownerFirstName} ${data.ownerLastName}` } : {};

  return choosedOwner;
};

const getChoosedStatus = (data, statusOptions) => {
  let choosedStatus = {};

  data &&
    statusOptions.forEach((item) => {
      if (item.value === data.status) {
        choosedStatus = {
          value: item.value,
          label: item.label,
          color: item.color
        };
      }
    });

  return choosedStatus;
};

const getChoosedParent = (data) => {
  let choosedParent = data && data.length !== 0 && data.isChild && data.parentOkr;

  return choosedParent;
};

const transformKRs = (keyResults, ownerOptions) => {
  for (let i = 0; i < keyResults.length; i++) {
    keyResults[i]['krTitle'] = keyResults[i].title;
    keyResults[i]['krStart'] = keyResults[i].start;
    keyResults[i]['krCurrent'] = keyResults[i].current;
    keyResults[i]['krGoal'] = keyResults[i].goal;

    if (keyResults[i].owners.length > 0) {
      // let owner = ownerOptions
      let owners = [];

      keyResults[i].owners.forEach((o) => {
        let owner = ownerOptions.filter(
          (option) => o.owner && option.value.toString() === o.owner.toString()
        );

        if (owner && owner.length > 0) {
          owners.push(owner[0]);
        }
      });

      keyResults[i]['krOwner'] = owners;
    }
  }

  return keyResults;
};

const OkrForm = ({
  addOkr,
  getCompanyProfiles,
  getUserCompany,
  getCompanyOkrs,
  auth: { user },
  profile: { profiles, loading },
  company: { company },
  okr: { okrs, data },
  locale,
  history,
  setAlert,
  match,
  getOkrById
}) => {
  let privacyLabel = {
    public: 'Public',
    team: 'Team',
    private: 'Private'
  };

  let privacyOptions = [];
  let krTypeOptions = [];

  privacyOptions = [
    { value: 1, label: privacyLabel.public, name: 'privacy' },
    { value: 2, label: privacyLabel.team, name: 'privacy' },
    { value: 3, label: privacyLabel.private, name: 'privacy' }
  ];

  // KR Types
  let krLabels = locale && getLabels(locale.lang);

  krTypeOptions = [
    { value: 'number', label: krLabels.krTypeLabel.number, name: 'krType' },
    { value: 'binary', label: krLabels.krTypeLabel.binary, name: 'krType' },
    { value: 'milestone', label: krLabels.krTypeLabel.milestone, name: 'krType' }
  ];

  let personalOkrTitle = 'Personal objective (no team)';

  if (locale && locale.lang === 'ru') {
    privacyLabel = {
      public: 'Публичная',
      team: 'Отдел',
      private: 'Приватная'
    };

    personalOkrTitle = 'Персональный OKR';
  }

  let statusObj =
    locale && locale.lang !== 'ru'
      ? { onTrack: 'On Track', behind: 'Behind', atRisk: 'At Risk' }
      : { onTrack: 'По Плану', behind: 'Отстаем', atRisk: 'Риск' };

  const statusOptions = locale &&
    locale.lang && [
      { value: 1, label: statusObj.onTrack, color: '#6ddeb3' },
      { value: 2, label: statusObj.behind, color: '#facf34' },
      { value: 3, label: statusObj.atRisk, color: 'red' }
    ];

  const [formData, setFormData] = useState({
    objective: '',
    team: company && { value: 3, label: personalOkrTitle, type: 3 },
    owner: user && { value: user._id, label: `${user.firstName} ${user.lastName}` },
    status: locale && locale.lang && { value: 1, label: statusObj.onTrack, color: '#6ddeb3' },
    // keyResults: [{ krStart: '0', krGoal: '100', krCurrent: '0', dueDate: endOfTheYear }],
    keyResults: [
      {
        krStart: '0',
        krGoal: '100',
        krCurrent: '0',
        dueDate: endOfTheYear,
        krType: { label: krLabels.krTypeLabel.number, value: 'number' }
      }
    ],
    dueIn: endOfTheYear,
    parentOkr: '',
    lang: locale && locale.lang,
    privacy: { value: 1, label: privacyLabel.public, name: 'privacy' }
  });

  const { objective, team, owner, status, keyResults, dueIn, parentOkr, privacy } = formData;

  const [displayTips, toggleDisplayTips] = useState(true);
  const [isLoader, toggleLoader] = useState(false);
  const [isCopyOkr, toggleCopyOkr] = useState(false);
  
  let ownerOptions = [];

  {
    profiles &&
      profiles.forEach((profile) => {
        if (profile.status !== 'pending_invitation') {
          let coworker = {
            value: profile._id,
            label: `${profile.firstName} ${profile.lastName}`
          };

          ownerOptions.push(coworker);
        }
      });
  }

  useEffect(() => {
    getCompanyProfiles();
    getUserCompany();
    getCompanyOkrs();
  }, [getCompanyProfiles, getUserCompany, getCompanyOkrs]);

  // If Copy OKR
  useEffect(() => {
    // console.log('======= Copy OKR')
    if (match && match.params && match.params.id && match.params.id.length > 0) {
      getOkrById(match.params.id);

      toggleCopyOkr(true);

      if (data && data.objective) {
        setFormData({
          ...formData,
          objective: !data ? '' : data.objective,
          team: !data ? '' : getChoosedTeam(data),
          owner: !data ? '' : getChoosedOwner(data),
          status: !data ? '' : getChoosedStatus(data, statusOptions),
          keyResults: !data ? [] : transformKRs(data.keyResults, ownerOptions),
          dueIn: data && data.dueIn ? new Date(data.dueIn) : '',
          parentOkr: !data ? '' : getChoosedParent(data),
          privacy: !data ? privacyOptions[0] : getPrivacy(data.privacy, privacyOptions)
        });
      }
    }
  }, [match && match.params && match.params.id, data && data.keyResults && data.keyResults.length]);

  // setup owner and keyOwner default values
  useEffect(() => {
    if (user && company && !isCopyOkr)
      setFormData((prev) => ({
        ...prev,
        owner: {
          value: user._id,
          label: `${user.firstName} ${user.lastName}`
        },
        keyResults: prev.keyResults.map((item) => ({
          ...item,
          krOwner: item.krOwner
            ? item.krOwner
            : [{ value: user._id, label: `${user.firstName} ${user.lastName}` }]
        })),
        team:
          (user && user.isAdmin) || company.okrPrivacyCreation !== 2
            ? { value: company._id, label: `${company.name}`, type: 1 }
            : null
      }));
  }, [user, company]);

  // setup team default value
  useEffect(() => {
    console.log(company);
  }, [company, user]);

  let teamOptions = [];
  let okrOptions = [];

  {
    company &&
      company.departments.length > 0 &&
      company.departments.forEach((department, index) => {
        // Запрет в режиме okrPrivacyCreation === 2,
        // чтобы обычные пользователи могли создавать цели компании и отдела
        if ((user && user.isAdmin) || company.okrPrivacyCreation !== 2) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };

          teamOptions.push(team);
        }

        if (index === company.departments.length - 1) {
          // First option is the company
          if ((user && user.isAdmin) || company.okrPrivacyCreation !== 2) {
            let name = { value: company._id, label: `${company.name}`, type: 1 };
            teamOptions.unshift(name);
          }

          // Personal OKR
          let person = { value: 3, label: 'Personal objective (no team)', type: 3 };
          teamOptions.push(person);
        }
      });
  }

  if (company && company.departments.length === 0) {
    if ((user && user.isAdmin) || company.okrPrivacyCreation !== 2) {
      teamOptions = [
        { value: company._id, label: `${company.name}`, type: 1 },
        { value: 3, label: 'Personal objective (no team)', type: 3 }
      ];
    } else {
      // for Member role
      teamOptions = [{ value: 3, label: 'Personal objective (no team)', type: 3 }];
    }
  }

  {
    okrs &&
      okrs.forEach((okr) => {
        let obj = {
          value: okr._id,
          label: okr.objective
        };

        okrOptions.push(obj);
      });
  }

  const goBack = () => {
    if (history !== undefined) {
      return history.goBack();
    } else {
      return '/objectives';
    }
  };

  const handleKeyResults = (selectedOption, index, type, detailsIdx = 0) => {
    if (type === 'krOwner') {
      keyResults[index].krOwner = selectedOption;
    }

    if (type === 'dueDate') {
      keyResults[index].dueDate = selectedOption;
    }

    if (type === 'krType') {
      keyResults[index].krType = selectedOption;

      if (selectedOption.value === 'binary') {
        keyResults[index].details = { value: 0, label: krLabels.binaryLabel.false };
        keyResults[index].krCurrent = 0;
      } else if (selectedOption.value === 'number') {
        keyResults[index].start = keyResults[index].start;
        keyResults[index].krCurrent = keyResults[index].current;
        keyResults[index].goal = keyResults[index].goal;
      } else if (selectedOption.value === 'milestone') {
        let data = { label: '', value: 0 };

        // Last condition to check if we switch from Binary to Milestone
        if (
          !keyResults[index].details ||
          keyResults[index].details.length === 0 ||
          (keyResults[index].details && keyResults[index].details.value > -1)
        ) {
          keyResults[index].details = [];
          keyResults[index].details.push(data);
          keyResults[index].krType.activeMilestoneIndex = 0;
          keyResults[index].start = 0;
          keyResults[index].krCurrent = 0;
        }
      }
    }

    if (type === 'details' || type === 'binary') {
      keyResults[index].details = selectedOption;
      keyResults[index].krCurrent = selectedOption.value;
    }

    if (type === 'milestone') {
      keyResults[index].details[detailsIdx][selectedOption.target.name] = selectedOption.target.value;

      // Save the goal
      if (selectedOption.target.name === 'value' && detailsIdx === keyResults[index].details.length - 1) {
        keyResults[index].krGoal = parseFloat(selectedOption.target.value);
      }
    }

    setFormData({ ...formData, keyResults: keyResults });
  };

  const handleSelect = (selectedOption, name) => {
    setFormData({ ...formData, parentOkr: selectedOption });
  };

  const handleUniversalSelect = (selectedOption) => {
    setFormData({ ...formData, [selectedOption.name]: selectedOption });
  };

  const handleOwner = (selectedOption) => {
    setFormData({ ...formData, owner: selectedOption });
  };

  const handleTeam = (selectedOption) => {
    setFormData({ ...formData, team: selectedOption });
  };

  const handleStatus = (selectedOption) => {
    setFormData({ ...formData, status: selectedOption });
  };

  const handlgeDayChange = (day) => {
    setFormData({ ...formData, dueIn: day });
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAdd = (e) => {
    e.preventDefault();

    setFormData({
      ...formData,
      keyResults: [
        ...keyResults,
        // { krStart: '0', krCurrent: '0', krGoal: '100', krOwner: [owner], dueDate: dueIn }
        {
          krStart: '0',
          krCurrent: '0',
          krGoal: '100',
          krOwner: [owner],
          dueDate: dueIn,
          krType: { label: krLabels.krTypeLabel.number, value: 'number' }
        }
      ]
    });
  };

  const handleAddMilestone = (e, index) => {
    e.preventDefault();

    // console.log('======= index in handleAddMilestone', index)

    let data = { label: '', value: 0 };
    keyResults[index].details.push(data);

    setFormData({
      ...formData,
      keyResults
    });
  };

  const handleRemove = (index) => {
    const approve = window.confirm('Are you sure you wish to delete this KR? Data will be lost forever.');

    if (approve) {
      keyResults.splice(index, 1);
      setFormData({ ...formData, keyResults: keyResults });
    }
  };
  const handleDisplayTips = () => toggleDisplayTips((prev) => !prev);

  const onChangeKeyResults = (e, index) => {
    if (e.target.name === 'krTitle') {
      keyResults[index].krTitle = e.target.value;
      setFormData({ ...formData, keyResults: keyResults });
    }

    if (e.target.name === 'krStart') {
      keyResults[index].krStart = e.target.value;
      setFormData({ ...formData, keyResults: keyResults });
    }

    if (e.target.name === 'krCurrent') {
      keyResults[index].krCurrent = e.target.value;
      setFormData({ ...formData, keyResults: keyResults });
    }

    if (e.target.name === 'krGoal') {
      keyResults[index].krGoal = e.target.value;
      setFormData({ ...formData, keyResults: keyResults });
    }

    if (e.target.name === 'krOwner') {
      keyResults[index].krOwner = e.target.value;
      setFormData({ ...formData, keyResults: keyResults });
    }
  };

  // console.log('======= keyResults', keyResults)

  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      // Show Loader
      toggleLoader(true);

      if (keyResults.length > 0) {
        for (let i = 0; i < keyResults.length; i++) {
          let kr = keyResults[i];

          if (kr.krStart === null || kr.krGoal === null || kr.krCurrent === null || kr.krStart.length === 0 || kr.krCurrent.length === 0 || kr.krGoal.length === 0) {
            let t = 'Error in the key result: ';
            let t2 = `The key result doesn't have start, current or goal metric. Please add the number to the metric.`;

            if (locale.lang === 'ru') {
              t = 'Ошибка в ключевом результате: ';
              t2 =
                'В данном ключевом результате нет значения Начало или Цель. Пожалуйста, добавьте это значение и сохраните цель.';
            }

            let errMsg = `${t} "${kr.krTitle}". ${t2}`;

            // Hide Loader
            toggleLoader(isLoader);

            return setAlert(errMsg, 'danger', 15000);
          }
          if (
            !Number.isInteger(Number(kr.krStart)) ||
            !Number.isInteger(Number(kr.krCurrent)) ||
            !Number.isInteger(Number(kr.krGoal))
          ) {
            let t = 'Error in the key result: ';
            let t2 = `The key result metric values must be integer. Special characters (%,$,k) aren't allowed.`;

            if (locale.lang === 'ru') {
              t = 'Ошибка в ключевом результате: ';
              t2 =
                'Значения в ключевом результате должны быть целочисленными. Специфичные символы (%,$,к) не допускаются.';
            }

            let errMsg = `${t} ${kr.title || kr.krTitle}. ${t2}`;
            // Hide Loader
            toggleLoader(isLoader);

            return setAlert(errMsg, 'danger', 15000);
          }

          if (!kr.krTitle || kr.krTitle.length === 0) {

            let t = 'Error in the key result: ';
            let t2 =
              'One of key results doesn`t have a title. Please add the title and save the OKR.';

            if (locale.lang === 'ru') {
              t = 'Ошибка в ключевом результате: ';
              t2 =
                'Один из ключевых результатов не имеет названия. Пожалуйста, добавьте название и сохраните OKR.';
            }

            let errMsg = `${t} ${kr.krTitle}. ${t2}`;

            // Hide Loader
            toggleLoader(false);

            return setAlert(errMsg, 'danger', 15000);
          } else if (kr.krType.value === 'milestone') {

            for (let y = 0; y < kr.details.length; y++) {
              let ml = kr.details[y];

              if (!ml.label || ml.label.length === 0) {

                let t = 'Error in the key result: ';
                let t2 =
                  'One of the milestones of the key result doesn`t have a title. Please add the title to the milestone and save the OKR.';
    
                if (locale.lang === 'ru') {
                  t = 'Ошибка в ключевом результате: ';
                  t2 =
                    'Один из майлстоунов ключевого результата не имеет названия. Пожалуйста, добавьте название и значение, и сохраните OKR.';
                }
    
                let errMsg = `${t} ${kr.krTitle}. ${t2}`;
    
                // Hide Loader
                toggleLoader(false);
    
                return setAlert(errMsg, 'danger', 15000);
              } else if (ml.value === '') {

                let t = 'Error in the key result: ';
                let t2 =
                  'One of the milestones of the key result doesn`t have a value. Please add the metric to the milestone and save the OKR.';
    
                if (locale.lang === 'ru') {
                  t = 'Ошибка в ключевом результате: ';
                  t2 =
                    'Один из майлстоунов ключевого результата не имеет значения. Пожалуйста, добавьте значение майлстоуна, и сохраните OKR.';
                }
    
                let errMsg = `${t} ${kr.krTitle}. ${t2}`;
    
                // Hide Loader
                toggleLoader(false);
    
                return setAlert(errMsg, 'danger', 15000);
              }
            }
          }

          if (kr.krStart === kr.krGoal) {
            let t = 'Error in the key result: ';
            let t2 =
              'Your start value equals the goal value. Start value is the initial metric. Goal value is the outcome you want to achieve. The start value should be less than a goal. For example, the start is 0, and the goal is 100. Please make the start value less than the goal and then save OKR.';

            if (locale.lang === 'ru') {
              t = 'Ошибка в ключевом результате: ';
              t2 =
                'Значение Начало не может быть равно значению Цели. Начало - это ваша первоначальная метрика сейчас. Цель - это то, что вы хотите достичь. Начало должно быть меньше Цели. Например, начало = 0 и цель = 100. Пожалуйста, сделайте Начало меньше Цели и снова сохраните OKR.';
            }

            let errMsg = `${t} ${kr.krTitle}. ${t2}`;

            // Hide Loader
            toggleLoader(!isLoader);

            return setAlert(errMsg, 'danger', 15000);
          }
        }
      }

      // If keyResults are empty (no title, but we have krStart and krGoal from state)
      if (keyResults.length === 1 && keyResults[0].krTitle === undefined) {
        // Remove element and send keyResults == []
        keyResults.splice(0, 1);

        setFormData({ ...formData, keyResults: keyResults });
      }

      let res = await addOkr(formData, history, false);

      // Hide Loader
      toggleLoader(false);
      useAnalyticsEventTracker('okr', 'okr created', 'Creating an OKR');
    } catch (err) {
      toggleLoader(false);
    }
  };
  
  // console.log('==== formData', formData)

  const formTitle = <FormattedMessage id="user.okr.create.head" defaultMessage="Create the New Objective" />;

  let publicPrivacyTxt = <FormattedMessage id="publicPrivacyTxt" defaultMessage="Everyone can see an OKR" />;
  let teamPrivacyTxt = (
    <FormattedMessage
      id="teamPrivacyTxt"
      defaultMessage="Only your team, owners of key results, and admin can see an OKR"
    />
  );
  let privatePrivacyTxt = (
    <FormattedMessage
      id="privatePrivacyTxt"
      defaultMessage="Only you, owners of key results, and admin can see an OKR"
    />
  );
  let btnTxt = <FormattedMessage id="standup.form.submit" defaultMessage="Submit" />;
  let cnclBtnTxt = <FormattedMessage id="cancel" defaultMessage="Cancel" />;

  // Loader for button
  let loadingIcon = (
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Loader type="Circles" color="#ffffff" height="14px" width="15" />
    </span>
  );

  if (isLoader) {
    btnTxt = loadingIcon;
  }

  let removeKRCopy = 'Are you sure you wish to delete this Key Result? Data will be lost forever.'

  if (locale && locale.lang === 'ru') {
    removeKRCopy = 'Вы уверены, что хотите удалить этот Ключевой Результат? Все данные будут безвозвратно потеряны.';
  }

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">
          <div className="hide-1600">
            {user && user.department ? (
              <Menu
                companyName={user && user.companyName}
                team={user.departmentName}
                teamId={user.department}
                settings={user.isAdmin && true}
                focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true}
                activeIndex={4}
              />
            ) : (
              <Menu
                companyName={user && user.companyName}
                settings={user && user.isAdmin && true}
                focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true}
                activeIndex={4}
              />
            )}
          </div>

          <UserNav />
          {loading && !company ? (
            <Spinner />
          ) : (
            <Fragment>
              <div className="main mxa pl-0 pt-5 pt-0-xs">
                <div className="okr mt-3">
                  <div className="component my-2 p-3 light-shadow">
                    <FormTitle title={formTitle} />

                    <div className="form-full">
                      <form className="form-app form-app-okr py-2" onSubmit={(e) => onSubmit(e)}>
                        {!profiles ? (
                          <Spinner />
                        ) : (
                          <Fragment>
                            <div className="form-main form-main-inline standups-time flex-column-xs">
                              <div className="w-2-3 mb-3-xs labeled-wrapper">
                                <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                  <FormattedMessage
                                    id="user.okr.create.objective"
                                    defaultMessage="The objective"
                                  />
                                </label>
                                <FormattedMessage
                                  id="user.okr.create.objectiveInput"
                                  defaultMessage="ex Achieve record revenue"
                                >
                                  {(msg) => (
                                    <input
                                      className="w-100 input-text fs-18"
                                      type="text"
                                      placeholder={msg}
                                      name="objective"
                                      value={objective || ''}
                                      onChange={(e) => onChange(e)}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>
                              <div className="w-1-3 pl-2 labeled-wrapper">
                                <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                  <FormattedMessage id="user.okr.create.owner" defaultMessage="Owner" />
                                </label>
                                <FormattedMessage
                                  id="user.okr.create.ownerSelect"
                                  defaultMessage="Choose the owner of the Objective"
                                >
                                  {(msg) => (
                                    <Select
                                      className="w-100"
                                      // value={selectedOption}
                                      value={owner}
                                      onChange={(selectedOption) => handleOwner(selectedOption)}
                                      options={ownerOptions}
                                      isSearchable
                                      placeholder={msg}
                                      required
                                      styles={selectCustomStyles}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>
                            </div>

                            <div className="form-main form-main-inline standups-time mt-2 flex-column-xs">
                              <div className="w-1-3  labeled-wrapper">
                                <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                  <FormattedMessage id="user.okr.create.team" defaultMessage="Team" />
                                </label>
                                <FormattedMessage
                                  id="user.okr.create.teamSelect"
                                  defaultMessage="Choose the team"
                                >
                                  {(msg) => (
                                    <Select
                                      className="w-100"
                                      defaultValue={teamOptions[0]}
                                      value={team}
                                      onChange={(selectedOption) => handleTeam(selectedOption)}
                                      options={teamOptions}
                                      isSearchable
                                      placeholder={msg}
                                      required
                                      name="team"
                                      styles={selectCustomStyles}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>

                              <div className="w-1-3 pl-2 labeled-wrapper">
                                <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                  <FormattedMessage
                                    id="user.okr.create.currentstatus"
                                    defaultMessage="Current Status"
                                  />
                                </label>
                                <FormattedMessage id="user.okr.create.status" defaultMessage="Choose Status">
                                  {(msg) => (
                                    <Select
                                      className="w-100"
                                      value={status}
                                      onChange={(selectedOption) => handleStatus(selectedOption)}
                                      options={statusOptions}
                                      placeholder={msg}
                                      required
                                      name="status"
                                      styles={colourStyles}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>

                              <div className="w-1-3 relative pl-2 labeled-wrapper">
                                <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                  <FormattedMessage id="user.okr.create.dueIn" defaultMessage="Due date" />
                                </label>
                                <DatePickerComponent
                                  value={dueIn}
                                  onSelect={(day) => handlgeDayChange(day)}
                                />
                              </div>
                            </div>

                            <div className="pt-3 pb-1 flex space-between">
                              <p className="bold fs-14 no-w label-pr3 pb-0-xs">
                                <FormattedMessage id="user.okr.kr.title" defaultMessage="Key results" />
                              </p>
                            </div>

                            {keyResults.map((keyResult, index) => (
                              <div
                                key={`keyResult-${index}`}
                                className="card-title-bordered card-title-bordered-green  p-2 column mb-2"
                              >
                                <div className="okr__kr okr__kr-top w-100 flex  pb-2  align-start">
                                  <div className="okr__kr-name  labeled-wrapper w-100 ">
                                    <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                      <FormattedMessage
                                        id="user.okr.create.keyResult.title"
                                        defaultMessage="Measurable key result"
                                      />
                                    </label>
                                    <FormattedMessage
                                      id="user.okr.create.keyResult.titleInput"
                                      defaultMessage="Enter your awesome key result"
                                    >
                                      {(msg) => (
                                        <input
                                          className="w-100 input-text fs-18"
                                          type="text"
                                          placeholder={msg}
                                          name="krTitle"
                                          value={keyResult.krTitle || ''}
                                          onChange={(e) => onChangeKeyResults(e, index)}
                                        />
                                      )}
                                    </FormattedMessage>
                                  </div>

                                  <KRmetrics
                                    keyResult={keyResult}
                                    index={index}
                                    onChangeKeyResults={onChangeKeyResults}
                                    locale={locale && locale.lang}
                                    selectCustomStyles={selectCustomStyles}
                                    handleKeyResults={handleKeyResults}
                                    krLabels={krLabels}
                                    krName="kr"
                                    handleAddMilestone={handleAddMilestone}
                                  />
             

                                  <div 
                                    className="absolute-xs right-10-xs" 
                                    // onClick={() => handleRemove(index)}
                                    onClick={() => { if (window.confirm(removeKRCopy)) handleRemove(index) } }
                                    >
                                    {icons.close__new}
                                  </div>
                                </div>

                                <div className="okr__kr okr__kr-top w-100 flex  pb-2  align-start">
                                  <div className="okr__kr labeled-wrapper w-50">
                                    <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                      <FormattedMessage
                                        id="user.okr.create.keyResult.owner"
                                        defaultMessage="Owner"
                                      />
                                    </label>
                                    <FormattedMessage
                                      id="user.okr.create.keyResult.ownerSelect"
                                      defaultMessage="Choose the owner of this key result"
                                    >
                                      {(msg) => (
                                        <Select
                                          className="w-100"
                                          value={keyResult.krOwner}
                                          name="krOwner"
                                          onChange={(selectedOption) =>
                                            handleKeyResults(selectedOption, index, 'krOwner')
                                          }
                                          options={ownerOptions}
                                          isSearchable
                                          placeholder={msg}
                                          required
                                          isMulti
                                          styles={selectCustomStyles}
                                        />
                                      )}
                                    </FormattedMessage>
                                  </div>
                                  <div className="okr__kr labeled-wrapper w-30 ml-2 relative">
                                    <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                      <FormattedMessage
                                        id="user.okr.create.krDueIn"
                                        defaultMessage="Due date"
                                      />
                                    </label>
                                    <DatePickerComponent
                                      value={keyResult.dueDate}
                                      onSelect={(selectedOption) =>
                                        handleKeyResults(selectedOption, index, 'dueDate')
                                      }
                                    />
                                  </div>

                                  <div className="okr__kr labeled-wrapper w-200px ml-2">
                                    <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                      <FormattedMessage id="kr.type" defaultMessage="Key result type" />
                                    </label>
                                    <FormattedMessage id="choose.type" defaultMessage="Choose the type">
                                      {(msg) => (
                                        <Select
                                          className="w-100"
                                          defaultValue={krTypeOptions[0]}
                                          value={keyResult.krType}
                                          name="krType"
                                          onChange={(selectedOption) =>
                                            handleKeyResults(selectedOption, index, 'krType')
                                          }
                                          options={krTypeOptions}
                                          isSearchable
                                          placeholder={msg}
                                          required
                                          styles={selectCustomStyles}
                                        />
                                      )}
                                    </FormattedMessage>
                                  </div>
                                </div>
                              </div>
                            ))}

                            <button
                              type="button"
                              className="btn-secondary-back-upd fw-600  flex align-center  mb-2 px-1 black"
                              onClick={(e) => handleAdd(e)}
                            >
                              <span className="rounded-text-icon">{icons.circle_plus}</span>{' '}
                              <FormattedMessage
                                id="user.okr.create.keyResult.addBtn"
                                defaultMessage="Add Key Result"
                              />
                            </button>

                            <div className="form-main form-main-inline form-main-inline-end standups-time flex-column-xs">
                              <div className="w-2-3 mb-3-xs labeled-wrapper">
                                <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                  <FormattedMessage
                                    id="user.okr.create.parent"
                                    defaultMessage="Parent Objective"
                                  />{' '}
                                  (
                                  <FormattedMessage id="user.okr.create.optional" defaultMessage="Optional" />
                                  )
                                </label>
                                <FormattedMessage
                                  id="user.okr.create.parentInput"
                                  defaultMessage="Choose the parent objective"
                                >
                                  {(msg) => (
                                    <Select
                                      className="w-100"
                                      onChange={(selectedOption) => handleSelect(selectedOption, parentOkr)}
                                      value={parentOkr}
                                      options={okrOptions}
                                      isSearchable
                                      placeholder={msg}
                                      required
                                      name="parentOkr"
                                      styles={selectCustomStyles}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>
                              <div className="w-1-3 mb-3-xs pl-2 labeled-wrapper">
                                <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                  <FormattedMessage id="privacy" defaultMessage="Privacy" /> (
                                  {privacy.value === 1 && <span>{publicPrivacyTxt}</span>}
                                  {privacy.value === 2 && <span>{teamPrivacyTxt}</span>}
                                  {privacy.value === 3 && <span>{privatePrivacyTxt}</span>})
                                </label>
                                <FormattedMessage id="choose.okr.privacy" defaultMessage="Choose OKR privacy">
                                  {(msg) => (
                                    <Select
                                      className="w-100"
                                      defaultValue={privacyOptions[0]}
                                      value={privacy}
                                      onChange={(selectedOption) => handleUniversalSelect(selectedOption)}
                                      options={privacyOptions}
                                      isSearchable
                                      placeholder={msg}
                                      required
                                      name="privacy"
                                      styles={selectCustomStyles}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>
                            </div>
                          </Fragment>
                        )}
                        <div className="pt-3 pb-1 flex space-between">
                          <div className="green pointer" onClick={handleDisplayTips}>
                            <FormattedMessage
                              id="okr.tips.title"
                              defaultMessage={`Tips on how to set OKRs`}
                            />
                          </div>
                        </div>
                        <OkrTips locale={locale} displayTips={displayTips} />
                        <div className="flex">
                          <button
                            type="submit"
                            className="mw-150 fs-18  btn btn-small  btn-submit btn__secondary_green   lh-54   block ml-0-xs"
                            disabled={!isLoader ? false : true}
                            onClick={onSubmit}
                          >
                            {btnTxt}
                          </button>
                          <button
                            type="reset"
                            className="mw-150 ml-2 btn btn-small  fs-18  btn-secondary-back-upd "
                            onClick={goBack}
                            disabled={!isLoader ? false : true}
                          >
                            {cnclBtnTxt}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

OkrForm.propTypes = {
  setAlert: PropTypes.func.isRequired,
  addOkr: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getCompanyOkrs: PropTypes.func.isRequired,
  getOkrById: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  company: state.company,
  okr: state.okr,
  locale: state.locale
});

export default connect(mapStateToProps, {
  getCompanyProfiles,
  getUserCompany,
  addOkr,
  getCompanyOkrs,
  setAlert,
  getOkrById
})(withRouter(OkrForm));
