import axios from 'axios';
import { setAlert } from './alert';
import store from '../store';
import { updateHomeLikeAnswer, addHomeCommentAnswer, deleteHomeCommentAnswer } from './home';

import {
  GET_USER_CUSTOM_ANSWERS,
  GET_CAMPAIGN_CUSTOM_ANSWERS,
  CUSTOM_ANSWER_ERROR,
  CUSTOM_ANSWERS_ERROR,
  ADD_CUSTOM_ANSWER,
  EDIT_CUSTOM_ANSWER,
  GET_CUSTOM_ANSWER,
  CUSTOM_LIKES,
  ADD_COMMENT,
  UPDATE_COMMENT,
  CLEAR_CUSTOM_ANSWERS,
  GET_COMPANY_CUSTOM_ANSWERS,
  COMPANY_CUSTOM_ANSWER_ERROR,
  REMOVE_COMMENT,
  LOCATION_CHANGE_ERROR_CUSTOM,
  GET_USER_CUSTOM_ANSWERS_ID,
  GET_CUSTOM_ANSWER_BY_ID,
  EDIT_CUSTOM_ANSWER_BY_ID,
  GET_ALL_USER_CUSTOM_ANSWERS,
  GET_COMPANY_STANDUP_ANSWERS_IN_CHECKIN,
  COMPANY_STANDUP_ANSWER_ERROR,
  GET_COMPANY_PLAN_ANSWERS_IN_CUSTOM,
  GET_COMPANY_UPDATE_ANSWERS_IN_CUSTOM,
  GET_COMPANY_ALL_ANSWERS_IN_CUSTOM,
  CHANGE_CHECKIN_FILTERS,
  LOCATION_CHANGE_ERROR,
} from './types';

// Get all current user answers for all campaigns
export const getUserCustomAnswers = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/customanswers/all/user');

    dispatch({
      type: GET_USER_CUSTOM_ANSWERS,
      payload: { answers: res.data.userAnswers, last: res.data.lastAnswer }
    });
  } catch (err) {
    dispatch({
      type: CUSTOM_ANSWERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all current user answers for all campaigns
export const getAllUserCustomAnswersInData = () => async dispatch => {
  try {
    const res = await axios.get('/api/customanswers/all/user');

    dispatch({
      type: GET_ALL_USER_CUSTOM_ANSWERS,
      payload: { answers: res.data.userAnswers }
    });
  } catch (err) {
    dispatch({
      type: CUSTOM_ANSWERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get all current user answers for specific campaign
export const getUserCustomAnswersById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/customanswers/user/${id}`);

    dispatch({
      type: GET_USER_CUSTOM_ANSWERS_ID,
      payload: { answers: res.data.userAnswers, last: res.data.lastAnswer }
    });
  } catch (err) {
    dispatch({
      type: CUSTOM_ANSWERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get the last answer from current user
export const getUserCustomAnswer = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/customanswers/last');

    dispatch({
      type: GET_CUSTOM_ANSWER,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CUSTOM_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all company answers
export const getCompanyCustomAnswers = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/customanswers/company');

    dispatch({
      type: GET_COMPANY_CUSTOM_ANSWERS,
      payload: { company: res.data.companyAnswers, user: res.data.userAnswer }
    });
  } catch (err) {
    dispatch({
      type: COMPANY_CUSTOM_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get company answers for specific campaign
export const getCompanyCustomAnswersById = (id, limit) => async (dispatch) => {
  try {
    const params = {};
    if (limit) params.limit = limit;
    const res = await axios.get(`/api/customanswers/company/${id}`, { params });

    dispatch({
      type: GET_CAMPAIGN_CUSTOM_ANSWERS,
      payload: { company: res.data.companyAnswers, user: res.data.userAnswer }
    });
    return true;
  } catch (err) {
    dispatch({
      type: COMPANY_CUSTOM_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    return false;
  }
};

// Get all company STANDUP answers 
export const getStandupCompanyAnswers = ({limit}={}, offset = 0) => async dispatch => {
  try {
    const params = {};
    params.offset = offset;
    if(limit) params.limit = limit;
    
    const res = await axios.get(`/api/standupanswers/company`,{params});

    // AnswerType = 1 => standup
    let answers = res.data.companyAnswers.map(v => ({...v, answerType: 2 }))

    dispatch({
      type: GET_COMPANY_STANDUP_ANSWERS_IN_CHECKIN,
      payload: { company: answers, user: res.data.userAnswer }
    });
    return true;
  } catch (err) {
    dispatch({
      type: COMPANY_STANDUP_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    return false
  }
};


// Get all current user answers for ALL campaigns
export const getCompanyCustomAndCheckinAnswers = ({limit}={}, offset = 0) => async dispatch => {
  try {
    const params = {};
    params.offset = offset;
    if(limit) params.limit = limit;

    const res = await axios.get(`/api/customanswers/checkins/company`,{params});

    dispatch({
      type: GET_COMPANY_ALL_ANSWERS_IN_CUSTOM,
      payload: { company: res.data.companyAnswers, user: res.data.userAnswer  }
    });
  } catch (err) {
    dispatch({
      type: CUSTOM_ANSWERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all current user answers for all update campaigns
export const getCompanyUpdateAnswers = ({limit}={}, offset = 0) => async dispatch => {
  try {
    const params = {};
    params.offset = offset;
    if(limit) params.limit = limit;

    const res = await axios.get(`/api/updateanswers/company`,{params});

    let answers = res.data.companyAnswers.map(v => ({...v, answerType: 4 }))

    dispatch({
      type: GET_COMPANY_UPDATE_ANSWERS_IN_CUSTOM,
      payload: { company: answers, user: res.data.userAnswer  }
    });

    return { ok: true }
  } catch (err) {
    dispatch({
      type: CUSTOM_ANSWERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all company STANDUP answers 
export const getCompanyPlanAnswers = ({limit}={}, offset = 0) => async dispatch => {
  try {
    const params = {};
    params.offset = offset;
    if(limit) params.limit = limit;
    // if(user)params.user = user;

    const res = await axios.get('/api/plananswers/company',{params});

    let answers = res.data.companyAnswers.map(v => ({...v, answerType: 3 }))

    dispatch({
      type: GET_COMPANY_PLAN_ANSWERS_IN_CUSTOM,
      payload: { company: answers, user: res.data.userAnswer }
    });
    return true;
  } catch (err) {
    dispatch({
      type: COMPANY_CUSTOM_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    return false
  }
};


// Get company answers for specific campaign
export const getCustomAnswerById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/customanswers/${id}`);

    dispatch({
      type: GET_CUSTOM_ANSWER_BY_ID,
      payload: res.data
    });
    return true;
  } catch (err) {
    dispatch({
      type: COMPANY_CUSTOM_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    return false;
  }
};

// Edit ALL answer
export const editAllCustomAnswers = (answerID, customID, answers, focus, history) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({ answers, focus });
    const res = await axios.put(`/api/customanswers/allanswers/${answerID}`, body, config);

    dispatch({
      type: EDIT_CUSTOM_ANSWER,
      payload: {
        customID,
        answers: res.answers,
        updatedAt: res.updatedAt
      }
    });

    dispatch(setAlert('Answer was updated', 'success', 3000));

    if (history) {
      history.goBack();
    } else {
      history.push('/campaigns');
    }
  } catch (err) {
    dispatch({
      type: CUSTOM_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit answer by id
export const editCustomAnswerById =
  ({ answers, focus, answerId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      await axios.put(`/api/customanswers/${answerId}`, { answers }, config);

      dispatch({
        type: EDIT_CUSTOM_ANSWER_BY_ID,
        payload: {}
      });

      dispatch(setAlert('Answer was updated', 'success', 3000));

      return true;
    } catch (err) {
      dispatch({
        type: CUSTOM_ANSWER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
      return false;
    }
  };

// Add answer on campaign
export const addCustomAnswer =
  (answers, customID, answerID, focus, okrQuestion, notes) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      // if user has alread created the custom, then update (PUT)
      if (answerID !== null) {
        dispatch(editAllCustomAnswers(answerID, customID, answers, focus));

        return true;
      }

      const body = JSON.stringify({ answers, customID, focus, notes, okrQuestion });

      const res = await axios.post(`/api/customanswers/${customID}`, body, config);

      dispatch({
        type: ADD_CUSTOM_ANSWER,
        payload: res.data
      });

      dispatch(setAlert('Answer is added', 'success', 3000));

      return true;
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }

      dispatch({
        type: CUSTOM_ANSWER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
      return false;
    }
  };

// Add & Remove like
export const updateCustomLike = (customID, checkinType = 0, answerType = 1) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({ answerType });

      // const res = await axios.post(`/api/customanswers/${customID}`, body, config);
    // const res = await axios.put(`/api/customanswers/like/${customID}/${checkinType}`);
    const res = await axios.put(`/api/customanswers/like/${customID}/${checkinType}`, body, config);

    dispatch({
      type: CUSTOM_LIKES,
      payload: { customID, likes: res.data.likes, answers: res.data.answers }
    });

    const home = store.getState().home;

    // Обновляем редюсер Home, если он не пустой
    // https://stackoverflow.com/questions/35667249/accessing-redux-state-in-an-action-creator
    // https://redux.js.org/api/store#dispatchaction
    if (home.data.length > 0) {
      store.dispatch(
        updateHomeLikeAnswer({ id: customID, likes: res.data.likes, answers: res.data.answers })
      );
    }

    // Callback for updating in formData.CUSTOMs in useState to render it
    const data = { customID, likes: res.data.likes, answers: res.data.answers };

    return data;
  } catch (err) {
    dispatch({
      type: CUSTOM_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add comment 
export const addCustomComment = (customID, answerID, formData, answerType = 1) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({ text: formData, answerType });
    const res = await axios.post(`/api/customanswers/comment/${customID}/${answerID}`, body, config);

    dispatch({
      type: ADD_COMMENT,
      payload: {
        customID,
        answerID,
        comments: res.data.comments,
        answers: res.data.answers
      }
    });

    const home = store.getState().home;

    // Обновляем редюсер Home, если он не пустой
    if (home.data.length > 0) {
      store.dispatch(
        addHomeCommentAnswer({ id: customID, comments: res.data.comments, answers: res.data.answers })
      );
    }

    // Callback for updating in formData.customs in useState to render it
    const data = { customID, comments: res.data.comments, answers: res.data.answers };
    return data;
  } catch (err) {
    console.log('=====err', err);
    dispatch({
      type: CUSTOM_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit comment
export const editCustomComment = (customID, commentID, formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const res = await axios.put(`/api/customanswers/comment/${customID}/${commentID}`, formData, config);

    dispatch({
      type: UPDATE_COMMENT,
      payload: {
        customID,
        comments: res.data
      }
    });
  } catch (err) {
    dispatch({
      type: CUSTOM_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete comment
export const deleteCustomComment = (customID, commentID, answerType = 1) => async (dispatch) => {
  try {

    const res = await axios.delete(`/api/customanswers/comment/${customID}/${commentID}/${answerType}`);

    dispatch({
      type: REMOVE_COMMENT,
      payload: {
        customID,
        commentID,
        answers: res.data.answers
      }
    });

    const home = store.getState().home;

    // Обновляем редюсер Home, если он не пустой
    if (home.data.length > 0) {
      store.dispatch(deleteHomeCommentAnswer({ id: customID, commentID, answers: res.data.answers }));
    }

    // Callback for updating in formData.updates in useState to render it
    const data = { customID, commentID, answers: res.data.answers };
    return data;
  } catch (err) {
    dispatch({
      type: CUSTOM_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Clear state
export const clearCustomsAnswers = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_CUSTOM_ANSWERS
    });
  } catch (err) {
    dispatch({
      type: LOCATION_CHANGE_ERROR_CUSTOM,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const changeCheckinFilters = (team,time,company)=>dispatch=>{
  try {
    // console.log('======= changeCheckinFilters: team', team)
    // console.log('======= changeCheckinFilters: time', time)
    // console.log('======= changeCheckinFilters: company', company)
    dispatch({type:CHANGE_CHECKIN_FILTERS,payload:{team,time,company}})
  } catch (err) {
    console.log('Error in changeCheckinFilters:', err)

    dispatch({
      type: LOCATION_CHANGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
}
