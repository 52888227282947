import { useMemo, useState, useEffect } from 'react';
import moment from 'moment';

const getTeamOptions = (profiles, company, user) => {
  let teamOptions = [];
  
  if (profiles && profiles.length > 0) {

    profiles.forEach((profile, idx) => {

      // Don't add user to the list if there are many profiles and teams
      // Because we add user to the list later in the code 
      if (user && profile._id === user._id && profiles.length > 1 && company && company.departments && company.departments.length > 0) return;

      let label =
        (profile && profile.departmentName === null) || (profile && profile.department === undefined)
          ? `${profile.firstName} ${profile.lastName}`
          : `${profile.firstName} ${profile.lastName} - [${profile.departmentName}]`;

      let coworker = {
        value: profile._id,
        label,
        type: 3
      };

      teamOptions.push(coworker);

      if (idx === profiles.length - 1) {
        // if no departments, then add company as the last option
        if (company && !company.departments || company && company.departments && company.departments.length === 0) {
          // Third option is the company
          let name = { value: company._id, label: `${company.name}`, type: 1 };
          teamOptions.unshift(name);
        }
      }
    });

    if (company && company.departments && company.departments.length > 0) {
      company.departments.forEach((department, index) => {
        if (user && (user.department === null || user.department === undefined)) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };

          teamOptions.unshift(team);
        } else if (
          user &&
          user.department !== null &&
          user.department !== undefined &&
          department._id !== user.department
        ) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };

          teamOptions.unshift(team);
        }

        if (user && index === company.departments.length - 1) {
          // You is third in list
          let person = {
            value: user._id,
            label:
              (user && user.departmentName === null) || (user && user.department === undefined)
                ? `${user.firstName} ${user.lastName}`
                : `${user.firstName} ${user.lastName} - [${user.departmentName}]`,
            type: 3
          };

          // teamOptions.unshift(person);

          // Second option is the team if exists
          if (user.department !== null && user.department !== undefined) {
            let team = { value: user.department, label: `${user.departmentName}`, type: 2 };
            teamOptions.unshift(team);
          }

          // Sort users alphabetically by first name
          teamOptions.sort((a, b) => a.label.localeCompare(b.label));

          // Secons is user
          teamOptions.unshift(person);

          // Third option is the company
          let name = { value: company._id, label: `${company.name}`, type: 1 };
          teamOptions.unshift(name);
        }
      });
    }
  }

  return teamOptions;
};

//period === moment.js .startOf valid string option (isoweek, month, year,)
const timePeriod = (date, period, quarter) => {
  return period !== 'quarter'
    ? { start: moment(date).startOf(period).unix() * 1000, end: moment(date).endOf(period).unix() * 1000 }
    : {
        start: moment(date).quarter(quarter).startOf(period).unix() * 1000,
        end: moment(date).quarter(quarter).endOf(period).unix() * 1000
      };
};

const lastWeekDate = () => new Date(new Date().setDate(new Date().getDate() - 7)); // Get current week day from last week;
const lastMonthDate = () => new Date(new Date().setMonth(new Date().getMonth() - 1)); // Get current day from last month;
const lastYearDate = () => new Date(new Date().setFullYear(new Date().getFullYear() - 1)); // Get current week day from last week;

function useDateFilter(value = [], selectValue = (value) => value, lang = 'eng', profiles = [], company = {}, user = {}, menu = {}, checkinFilters = {}) {

  // console.log('====== value in useDateFilter', value)
  // console.log('====== profiles', profiles)

  const options = useMemo(
    () => [
      { value: 0, label: lang === 'ru' ? 'Все Время' : 'All time', start: 0, end: 4119797860000 },
      { value: 1, label: lang === 'ru' ? 'Неделя' : 'This Week', ...timePeriod(new Date(), 'isoweek') },
      { value: 2, label: lang === 'ru' ? 'Месяц' : 'This Month', ...timePeriod(new Date(), 'month') },
      { value: 3, label: lang === 'ru' ? 'Год' : 'This Year', ...timePeriod(new Date(), 'year') },
      { value: 4, label: lang === 'ru' ? 'Квартал 1' : 'Quarter 1', ...timePeriod(new Date(), 'quarter', 1) },
      { value: 5, label: lang === 'ru' ? 'Квартал 2' : 'Quarter 2', ...timePeriod(new Date(), 'quarter', 2) },
      { value: 6, label: lang === 'ru' ? 'Квартал 3' : 'Quarter 3', ...timePeriod(new Date(), 'quarter', 3) },
      { value: 7, label: lang === 'ru' ? 'Квартал 4' : 'Quarter 4', ...timePeriod(new Date(), 'quarter', 4) },
      {
        value: 8,
        label: lang === 'ru' ? 'Прошлая неделя' : 'Last Week',
        ...timePeriod(lastWeekDate(), 'isoweek')
      },
      {
        value: 9,
        label: lang === 'ru' ? 'Прошлый месяц' : 'Last Month',
        ...timePeriod(lastMonthDate(), 'month')
      },
      { value: 10, label: lang === 'ru' ? 'Прошлый год' : 'Last Year', ...timePeriod(lastYearDate(), 'year') }
    ],
    [lang]
  );

  const teamOptions = useMemo(
    () => getTeamOptions(profiles, company, user),
    [profiles, company, user]
  );

  // console.log('====== teamOptions.length', teamOptions.length)
  // console.log('====== selectValue', selectValue)
  // console.log('====== checkinFilters in useDate', checkinFilters)

  // const [selectedOption, selectOption] = useState(options[0]);
  // const [teamSelectedOption, teamSelectOption] = useState(teamOptions[0]);
  let [selectedOption, selectOption] = useState(checkinFilters && checkinFilters.time !== null ? checkinFilters.time : options[0]);
  let [teamSelectedOption, teamSelectOption] = useState(checkinFilters && checkinFilters.team !== null && checkinFilters.team !== undefined ? checkinFilters.team : teamOptions[0]);
  // console.log('====== selectedOption in useDate', selectedOption)
  // console.log('====== teamSelectedOption in useDate', teamSelectedOption)
  // console.log('====== checkinFilters in useDate', checkinFilters)
  // console.log('====== teamOptions in useDate', teamOptions)

  useEffect(() => {
    // teamSelectOption(teamOptions[0])
    teamSelectOption(checkinFilters && checkinFilters.team !== null && checkinFilters.team !== undefined ? checkinFilters.team : teamOptions[0])
    // teamSelectOption(teamOptions[0])
   
  }, [teamOptions.length]);
  // console.log('====== teamSelectedOption in useDate AFTER', teamSelectedOption)

  const filteredValue = useMemo(
    () =>
      value.filter((item) => {
        const itemValueDate = new Date(selectValue(item));

        // console.log('======== teamSelectedOption', teamSelectedOption)
        // console.log('======== item in useDate', item)
        // Visibility for custom check-ins
        let hiddenCheckins = menu && menu.hiddenCheckins ? menu.hiddenCheckins : [];
        // console.log('======== hiddenCheckins in getCheckins', hiddenCheckins)

        if (hiddenCheckins.length > 0) {
          // console.log('======== we are in hiddenCheckins', hiddenCheckins)
          
          let hiddenAnswers = hiddenCheckins.filter(c => c.value === item.custom)
          // console.log('===== hiddenAnswers', hiddenAnswers)

          if (hiddenAnswers.length > 0) { return }
        }

        // Filter check-ins
        if (teamSelectedOption) {
          if (!selectedOption || selectedOption && !selectedOption.start) { selectedOption = options[0] }

          if (teamSelectedOption.type === 1) {
            return item.company === teamSelectedOption.value && itemValueDate > selectedOption.start && itemValueDate < selectedOption.end;
          } else if (teamSelectedOption.type === 2) {
            return item.department === teamSelectedOption.value && itemValueDate > selectedOption.start && itemValueDate < selectedOption.end;
          } else if (teamSelectedOption.type === 3) {
            
            return item.user === teamSelectedOption.value && itemValueDate > selectedOption.start && itemValueDate < selectedOption.end;
          }
        }
      }),
    [value, selectedOption, teamSelectedOption]
  );

  // console.log('======filteredValue in useFilter', filteredValue)
  // console.log('======selectedOption', selectedOption)
  // console.log('======teamSelectedOption', teamSelectedOption)
  // console.log('====== useDateFilter end')

  // changeCheckinFilters(teamSelectedOption, selectedOption)

  return [filteredValue, options, selectedOption, selectOption, teamOptions, teamSelectedOption, teamSelectOption];
}

export default useDateFilter;
