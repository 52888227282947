import React from "react";
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { FormattedMessage } from 'react-intl';


const OneHistoryItem = ({ 
  item: {
    date,
    talkingPoints,
    focus,
    notes,
    todos,
    followUp,
    numberOneGoal,
  } 
}) => {
  // console.log('====== we are in OneHistoryItem with talkingPoints', talkingPoints)


  let focusObjLength = 0;
  if (focus !== undefined && focus !== null) {
    focusObjLength = Object.keys(focus).length;
  }

  let color;
  let label = "";
  // console.log('====== focusObjLength in OneHistoryItem', focusObjLength)

  if (focusObjLength > 0) {
    // console.log('====== focus.status in OneHistoryItem', focus.status)
    if (focus.status === "1") {
      color = "green";
      label = "On Track";
    } else if (focus.status === "2") {
      color = "yellow";
      label = "Behind";
    } else if (focus.status === "3") {
      color = "red";
      label = "At Risk";
    }
  }

  let oneGoalObjLength = 0;
  if (numberOneGoal !== undefined && numberOneGoal !== null) {
    oneGoalObjLength = Object.keys(numberOneGoal).length;
  }
  // console.log('====== oneGoalObjLength in OneHistoryItem', oneGoalObjLength)


  const focusTitle = <FormattedMessage id="focus" defaultMessage="Focus"/>;
  const currentlLabel = <FormattedMessage id="widget.main.current" defaultMessage="Current"/>;
  const goalLabel = <FormattedMessage id="widget.main.goal" defaultMessage="Goal"/>;
  const todosLabel = <FormattedMessage id="action.items" defaultMessage="Action Items"/>;
  const noTodosLabel = <FormattedMessage id="no.todos" defaultMessage="No items"/>;
  const topicsLabel = <FormattedMessage id="talking.points" defaultMessage="Talking Points"/>;
  const noTopicsLabel = <FormattedMessage id="no.talking.points" defaultMessage="No talking points"/>;
  const notesLabel = <FormattedMessage id="notes" defaultMessage="Notes"/>;
  const oneGoalLabel = <FormattedMessage id="1.weekly.goal" defaultMessage="#1 Weekly Goal"/>;
  const noGoalLabel = <FormattedMessage id="no.1.weekly.goal" defaultMessage="No #1 goal"/>;
  const followupLabel = <FormattedMessage id="follow.up" defaultMessage="Follow Up"/>;
  const planLabel = <FormattedMessage id="plan" defaultMessage="Plan"/>;
  const factLabel = <FormattedMessage id="fact" defaultMessage="Fact"/>;

  
  return (
    <div className="timeline__item history__item p-1 pl-0 mr-3 br-6 w-200px ma-w300 w-100-s">
      <p className="medium blue mb-xs">
      <Moment format="D MMM">{date}</Moment>
      </p>
      <div className="history__weekly-focus my-1">
        <p className="fs-15 lh-22 mb-xs">
          <span className="bold">{focusTitle}</span>:{" "}{focusObjLength > 0 ? focus.text : '-'}
        </p>
        <p className={`small ${color} mr-1`}>{label}</p>
        <p className="small  mr-1">{currentlLabel}:{' '}{focusObjLength > 0 ? focus.progress : '-'}</p>
        <p className="small ">{goalLabel}:{' '}{focusObjLength > 0 ? focus.goal : '-'}</p>
      </div>

      <div className="history__action-items my-1">
        <p className="fs-15 bold lh-22 mb-xs">{todosLabel}</p>
        {todos && todos.length > 0 ? todos.map(item => (
          <ul className='card-list'>
            <li className={`small ${item.isCompleted ? 'grey old-price' : ''}`}>{item.name}</li>
          </ul>
        )) : (
          <li className='small'>{noTodosLabel}</li>
        )}
      </div>

      <div className="history__talking-points my-1">
        <p className="fs-15 bold lh-22 mb-xs">{topicsLabel}</p>
        {talkingPoints && talkingPoints.length > 0 ? talkingPoints.map(item => (
          <ul className='card-list'>
            <li className={`small ${item.isCompleted ? 'grey old-price' : ''}`}>{item.text}</li>
          </ul>
        )) : (
          <li className='small'>{noTopicsLabel}</li>
        )}
      </div>

      <div className="history__one-goal my-1">
      <p className="fs-15 bold lh-22 mb-xs">{oneGoalLabel}</p>
      {oneGoalObjLength > 0 ? (
        <ul className='card-list'>
            <li className={`small ${numberOneGoal.isCompleted ? 'grey old-price' : ''}`}>{numberOneGoal.text}</li>
          </ul>
      ) : (
        // <p className="small ">{noGoalLabel}</p>
        <p className="small ">-</p>
      )}
      </div>

      <div className="history__notes my-1">
        <p className="fs-15 bold lh-22 mb-xs">{notesLabel}</p>
        {notes && notes.length > 0 ? notes.map(item => (
          <div className="mb-xs">
            <p className="grey small">{`${item.firstName} ${item.lastName}`}</p>
            <p className="small">{item.text}</p>
          </div>
        )) : (
          <p className='small'>{noTopicsLabel}</p>
        )}
      </div>

      <div className="history__follow-up my-1">
        <p className="fs-15 bold lh-22 mb-xs">{followupLabel}</p>
        {followUp && followUp.length > 0 ? followUp.map(item => (
          <div className="mb-xs">
            {item.plan && (
              <div className="follow-up__item">
                <p className="fs-10 grey">{planLabel}</p>
                <p className="grey small">{`${item.plan.firstName} ${item.plan.lastName}`}</p>
                <p className="small">{item.plan.text}</p>
              </div>
            )}
            {item.fact && (
              <div className="follow-up__item">
                <p className="fs-10 grey">{factLabel}</p>
                <p className="grey small">{`${item.fact.firstName} ${item.fact.lastName}`}</p>
                <p className="small">{item.fact.text}</p>
              </div>
            )}
          </div>
        )) : (
          <p className='small'>-</p>
        )}
      </div>
    </div>
  );
};

OneHistoryItem.propTypes = {
  docs: PropTypes.array.isRequired,
};

export default OneHistoryItem;
