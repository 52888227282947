import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { icons } from "../../../img/elements/icons";
import Highlighter from "react-highlight-words";

const QuestionItemAnswer = ({ index, text, searchKeyword }) => {

  if (text.props && text.props.defaultMessage) {
    text = text.props.defaultMessage;
  }
  
  return (
    <Fragment>
       <li className="list-item fs-14 my-1 flex align-center">
        <span className="mr-14 flex align-center">
          {icons.circle_small}
        </span>
        {' '}        
        <Highlighter
          highlightClassName="bg-yellow_pure"
          searchWords={[searchKeyword]}
          autoEscape={true}
          textToHighlight={text}
        />
      </li>
    </Fragment>
  );
};

QuestionItemAnswer.propTypes = {
  index: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired
};

export default QuestionItemAnswer;
