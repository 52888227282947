import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import { setAlert } from '../../../actions/alert';
import TreeOkrItem from "./TreeOkrItem";
import TreeGrandChild from "./TreeGrandChild";
import TreeTitle from "./TreeTitle";
import Moment from "react-moment";
import { FormattedMessage } from "react-intl";

const TreeChild = ({
  okr: {
    owner,
    creator,
    ownerAvatar,
    ownerFirstName,
    ownerLastName,
    dueIn,
    _id,
    objective,
    teamName,
    type,
    progress,
    status,
    updatedAt,
    keyResults,
    isParent,
    isChild,
    childOkrs,
    isArchive,
  },
  grandchildsArr,
  isCompanyParent,
  noParents,
  handleArchive,
  handleDelete,
  archive,
  user,
}) => {
  const [discussion, showComments] = useState(false);
  const [update, toggleUpdateMode] = useState(false);
  let btnClass = '';
  let label = '';

  if (status === 1) {
    btnClass = "green";
    label = "On Track";
  } else if (status === 2) {
    btnClass = "yellow";
    label = "Behind";
  } else if (status === 3) {
    btnClass = "red";
    label = "At Risk";
  }

  // АЛГОРИТМ РЕНДЕРА
  // if type === 2 => okr отдела
  // if type === 3 => значит у внука родитель - окр компании (т.е. нет child)

  // Формируем внуков этого ребенка
  let grandChilds = [];
  {
    if (isParent && childOkrs && childOkrs.length > 0) {
      childOkrs.forEach((item) => {

        grandchildsArr.forEach(grand => {
          
          if (grand._id.toString() === item.value) {
            grandChilds.push(grand);
          }
        });
      })
    }
  }

  // если ребенок, на самом деле внук, то запакуем его в объект, чтобы передать в TreeGrandChild
  let okrObj = {};

  if (type === 3) {
    okrObj = {
      owner,
      ownerAvatar,
      ownerFirstName,
      ownerLastName,
      dueIn,
      _id,
      objective,
      teamName,
      type,
      progress,
      status,
      updatedAt,
      keyResults,
      isParent,
      childOkrs,
      isChild,
      isArchive,
      creator,
    }
  }

  return (
    <Fragment>
      <div className={`objective__child ${!isCompanyParent && 'objective__no-parent'} flex align-center relative pl-2 py-1`}>
        
          {type === 2 ? (
            <div className="item">
              <div className="w-450px bg__white br-12 light-shadow my-2 py-3 px-2">
                <TreeTitle
                  label={`${teamName} OKR`}
                  color={btnClass}
                  _id={_id}
                  handleArchive={handleArchive}
                  handleDelete={handleDelete}
                  isArchive={isArchive}
                  archive={archive}
                  user={user}
                  owner={owner}
                  creator={creator}
                />

                <TreeOkrItem
                  objective={objective}
                  status={label}
                  dueIn={dueIn}
                  owner={owner}
                  ownerAvatar={ownerAvatar}
                  ownerFirstName={ownerFirstName}
                  btnClass={btnClass}
                  progress={progress}
                  keyResults={keyResults}
                />
              </div>
            </div>
          ) : (
            <Fragment>
              <div className="item">
                <div className="w-450px my-2 px-2 py-3 no__content"></div>
              </div>
              
              <div className={`objective__grandchilds objective__grandchilds__${isChild ? 'company' : 'no-parent'} pl-5 relative`}>
                <TreeGrandChild
                  okr={okrObj}
                  noParents={noParents}
                  handleArchive={handleArchive}
                  handleDelete={handleDelete}
                  archive={archive}
                  user={user}
                />
              </div>
            </Fragment>
          )}

        {grandChilds.length > 0 && (
          <div className="objective__grandchilds pl-5 relative">
            {grandChilds.map(okr => (
              <TreeGrandChild
                key={`grand-${okr._id}`}
                okr={okr}
                handleArchive={handleArchive}
                handleDelete={handleDelete}
                archive={archive}
                user={user}
              />
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

TreeChild.propTypes = {
  okr: PropTypes.object.isRequired,
  grandchildsArr: PropTypes.array.isRequired,
  isCompanyParent: PropTypes.bool,
  noParents: PropTypes.bool,
};

export default TreeChild;
