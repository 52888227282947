import React from "react";
import PropTypes from 'prop-types';

const TeamIconNew = ({ activeIndex, shortMenu }) => {
  return (
    <svg id="company" className={`w-20px ${!shortMenu ? '' : 'ml-xs'}`} xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20">
      <g id="Group_20" data-name="Group 20" transform="translate(-1264.214 -17.82)">
        <path id="Path_119" fill={`${activeIndex === 7 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 119" d="M1264.215,27.809c0-2.393-.006-4.786.007-7.179a3.407,3.407,0,0,1,.155-1.147,2.39,2.39,0,0,1,2.2-1.65c.152-.013.306-.012.459-.012q4.679,0,9.357,0a4.14,4.14,0,0,1,.951.092,2.428,2.428,0,0,1,1.85,2.166c.016.18.022.361.022.542q0,7.181,0,14.36a3.007,3.007,0,0,1-.2,1.307,2.436,2.436,0,0,1-2.092,1.5c-.18.017-.362.022-.542.022q-4.659,0-9.316,0a4.2,4.2,0,0,1-1.032-.113,2.425,2.425,0,0,1-1.792-2.171c-.015-.194-.019-.389-.019-.584Q1264.214,31.379,1264.215,27.809Zm10.007,8.8c.726,0,1.393,0,2.06,0a4.664,4.664,0,0,0,.748-.051,1.119,1.119,0,0,0,.931-.946,4.211,4.211,0,0,0,.043-.664q0-7.116,0-14.233c0-.111,0-.223,0-.334a1.249,1.249,0,0,0-1.361-1.35c-.07,0-.14,0-.209,0q-4.72,0-9.441,0a3.611,3.611,0,0,0-.54.036,1.143,1.143,0,0,0-.99.985,4.064,4.064,0,0,0-.039.624q0,7.137,0,14.274c0,.139,0,.279.009.417a1.183,1.183,0,0,0,1.216,1.226c.819.037,1.641,0,2.462,0,.022,0,.044-.029.12-.08,0-.126,0-.289,0-.452.007-.738-.023-1.477.03-2.211a2.453,2.453,0,0,1,3.368-2.056,2.426,2.426,0,0,1,1.566,2.349c.016.653,0,1.308.006,1.962C1274.2,36.25,1274.212,36.4,1274.222,36.612Zm-1.258-.026c.011-.169.026-.3.026-.438,0-.611,0-1.221,0-1.831a3.167,3.167,0,0,0-.039-.5,1.169,1.169,0,0,0-1.119-.993,1.2,1.2,0,0,0-1.293.768,2.008,2.008,0,0,0-.1.612c-.011.694-.006,1.387,0,2.081a2.259,2.259,0,0,0,.045.3Z"/>
        <path id="Path_120" fill={`${activeIndex === 7 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 120" d="M1270.433,23.439c0,.306.015.612,0,.916a.876.876,0,0,1-.92.924c-.61.026-1.222.025-1.833,0a.878.878,0,0,1-.945-.941c-.021-.61-.022-1.222,0-1.832a.878.878,0,0,1,.959-.925q.9-.024,1.791,0a.879.879,0,0,1,.946.938c.017.3,0,.61,0,.915Zm-1.233.617V22.817h-1.239v1.239Z"/>
        <path id="Path_121" fill={`${activeIndex === 7 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 121" d="M1274.842,21.585c.32,0,.64-.014.958,0a.845.845,0,0,1,.887.86c.03.65.03,1.3,0,1.956a.837.837,0,0,1-.876.87c-.638.028-1.279.03-1.916,0a.851.851,0,0,1-.893-.9c-.025-.623-.026-1.249,0-1.873a.875.875,0,0,1,.924-.918c.3-.015.611,0,.916,0Zm-.62,2.485a6.658,6.658,0,0,0,1.259-.024,6.129,6.129,0,0,0-.017-1.223h-1.242Z"/>
        <path id="Path_122" fill={`${activeIndex === 7 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 122" d="M1276.7,28.45c0,.306.014.612,0,.916a.878.878,0,0,1-.92.924c-.61.025-1.223.025-1.833,0a.879.879,0,0,1-.945-.941c-.021-.61-.023-1.222,0-1.832a.877.877,0,0,1,.958-.925q.9-.024,1.792,0a.88.88,0,0,1,.946.938c.017.3,0,.61,0,.915Zm-2.465-.628v1.262a7.307,7.307,0,0,0,1.256-.025,8.037,8.037,0,0,0-.03-1.237Z"/>
        <path id="Path_123" fill={`${activeIndex === 7 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 123" d="M1268.6,26.595c.3,0,.612-.013.916,0a.857.857,0,0,1,.9.889c.027.638.027,1.278,0,1.915a.837.837,0,0,1-.866.881c-.651.029-1.306.031-1.957,0a.836.836,0,0,1-.862-.884c-.025-.637-.027-1.277,0-1.915a.861.861,0,0,1,.906-.888c.319-.017.639,0,.959,0Zm.6,1.238a4.449,4.449,0,0,0-1.241,0v1.225h1.241Z"/>
      </g>
     </svg>
  );
};

TeamIconNew.propTypes = {
  activeIndex: PropTypes.number
};

export default TeamIconNew;