import React, { Fragment, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TimezonePicker from 'react-timezone';
import RoundImg from '../elems/RoundImg';
import { FormattedMessage } from 'react-intl';
import Loader from "react-loader-spinner";
import { icons } from '../../img/elements/icons';
import QuestionItem from '../admin/elems/QuestionItem';
import Checkbox from '../elems/Checkbox.js';
import IntervalItem from '../admin/elems/IntervalItem';
import WeekItem from '../admin/elems/WeekItem';


  // Select hour & minute options
  const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  const hourOptions = hours.map(hour => {
        return (
          <option value={hour} key={hour}>
            {hour}
          </option>
        );
      });

  const minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
  const minuteOptions = minutes.map(minute => {
        return (
          <option value={minute} key={minute}>
            {minute}
          </option>
        );
      });

const OneSettings = ({
  // msg,
  formData,
  setFormData,
  isForm,
  handleCheckbox,
  currentDoc,
  handleTime,
  onChangeTimezone,
  onChangeDay,
  onChangeDate,
  onSubmit,
  isLoader,
  isSettings,
  toggleSettings,
  isHideSettings,
}) => {

  const [timeData, setTimeData] = useState({
    scheduleTimeHour: '',
    scheduleTimeMinute: '',
  });

  const { scheduleTimeHour, scheduleTimeMinute } = timeData;

  let timezone = formData.timezone;
  let interval = formData.interval;
  let scheduleDay = formData.scheduleDay;
  let scheduleTime = formData.scheduleTime;

  useEffect(() => {
    setTimeData({
      ...timeData,
      scheduleTimeHour: !scheduleTime ? '09' : scheduleTime.split(":")[0],
      scheduleTimeMinute: !scheduleTime ? '00' : scheduleTime.split(":")[1],
    });
  }, [scheduleTime]);

  const handleTimeSelect = (e) => {
    setTimeData({ ...timeData, [e.target.name]: e.target.value });

    let time = `${scheduleTimeHour}:${scheduleTimeMinute}`

    if (e.target.name === 'scheduleTimeHour') {
      time = `${e.target.value}:${scheduleTimeMinute}`
    } else if (e.target.name === 'scheduleTimeMinute') {
      time = `${scheduleTimeHour}:${e.target.value}`
    }
    setFormData({ ...formData, scheduleTime: time });
  };

  let scheduleLength = 0;
  if (scheduleDay !== undefined && scheduleDay !== null) {
    scheduleLength = Object.keys(scheduleDay).length;
  }

  let notificationsId = "notifications.1_1"
  let notificationsMsg = "Email notifications when a meeting starts";
  let automationId = "automation.1_1"
  let automationMsg = "Automatically create meeting according to schedule";
  let dateTitle = <FormattedMessage id="day.month" defaultMessage="Day of the month" />;
  let settingsMsg = <FormattedMessage id="settings" defaultMessage="Settings"/>;
  settingsMsg = `${settingsMsg} ${icons.dropdown_icon}`
  
  let loadingIcon = (
    <span style={{
      width: "100",
      height: "18px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <Loader type="Circles" color="#DAE3E5" height="18px" width="100" />
    </span>
  );

  let btnText = <FormattedMessage id="submit" defaultMessage="Submit" />;

  if (isLoader) { btnText = loadingIcon; }

  return (
    <Fragment>
      <div 
          className="settings my-4" 
        >
        {isHideSettings && (
          <p 
            onClick={() => toggleSettings(!isSettings)}
            className={`fs-20 bold mb-2 flex align-center cursor`}
          >
            {settingsMsg}
          </p>
          )}

          <Fragment>
            <div className="automation">
              <div className="form-main form-main-inline mb-3">
                <Checkbox
                  titleId={notificationsId}
                  titleMsg={notificationsMsg}
                  inputId={"isStartNotification"}
                  inputName={"isStartNotification"}
                  value={formData.isStartNotification}
                  onChange={handleCheckbox}
                  _id={!isForm && currentDoc ? currentDoc._id : 0}
                  styles="flex mr-3"
                  stylesTitle="w-300px"
                />

                <Checkbox
                  titleId={automationId}
                  titleMsg={automationMsg}
                  inputId={"isAutomation"}
                  inputName={"isAutomation"}
                  value={formData.isAutomation}
                  onChange={handleCheckbox}
                  _id={!isForm && currentDoc ? currentDoc._id : 0}
                  styles="flex"
                  stylesTitle="w-300px"
                />
              </div> 
            </div>

            {formData.isAutomation && (
              <div className="schedule">
                <p className="sub-title focus-yellow focus">
                  <FormattedMessage id="schedule" defaultMessage="Schedule" />
                </p>
                <div className="form-main form-main-inline form-app mt-2">
                  <p className="bold medium block question">
                    <FormattedMessage id="admin.dashboard.edit.time" defaultMessage="Time" />
                  </p>
                  {/* <FormattedMessage id="admin.dashboard.edit.timeInput" defaultMessage="Enter the time">
                    {msg => <input
                      className="small-input"
                      type="text"
                      placeholder={msg}
                      name="scheduleTime"
                      value={scheduleTime || ''}
                      onChange={e => handleTime(e)}
                    />}
                  </FormattedMessage> */}

                   {/* ДЛЯ ПРОДА */}
                   <select
                      className="small-input w-80px mr0 input__report_time mb-0-xs"
                      name="scheduleTimeHour"
                      value={scheduleTimeHour}
                      onChange={e => handleTimeSelect(e)}
                    >
                      {hourOptions}
                  </select>
                  
                  <span className="medium mx-1">:</span>

                  <select
                      className="small-input w-80px mb-0-xs mr-0-xs"
                      name="scheduleTimeMinute"
                      value={scheduleTimeMinute}
                      onChange={e => handleTimeSelect(e)}
                    >
                      {minuteOptions}
                  </select>

                  <p className="bold medium block question">
                    <FormattedMessage id="admin.dashboard.edit.timezone" defaultMessage="Timezone" />
                  </p>
                  <TimezonePicker
                    name="timezone"
                    value={timezone}
                    onChange={timezone =>
                      onChangeTimezone(timezone, 'timezone')
                    }
                    inputProps={{
                      placeholder: { timezone },
                      name: 'timezone'
                    }}
                  />
                </div>

                <div className="form-main form-main-inline form-app mt-2">

                  <IntervalItem
                    interval={interval}
                    isDaily={false}
                    isMonthly={true}
                    onChange={handleTime}
                    styles={'small-input mr-5'}
                  />

                  {interval === 'Weekly' || interval === 'Biweekly' ? (
                      <WeekItem
                        scheduleDay={scheduleLength > 0 && scheduleDay}
                        onChange={onChangeDay}
                        styles={'small-input ml-5'}
                        stylesText={'w-60px'}
                        interval={interval}
                        />
                  ) : (<span></span>)
                  }

                    {interval === 'Monthly' && (
                      <QuestionItem
                        scheduleDays={scheduleLength > 0 && scheduleDay}
                        onChange={onChangeDate}
                        text={dateTitle}
                        stylesDiv={'w-50 mb-0'}
                        stylesText={'w-40'}
                        stylesInput={'w-60px'}
                        inputID={'day.month'}
                        inputText={'Enter day of the month'}
                        inputName={'scheduleDay.index'}
                        value={scheduleDay.index}
                        name={scheduleDay.index}
                        />
                    )}
                  </div>
              </div>
            )}
            </Fragment>
        </div>

        <button
            type="submit"
            className="btn btn-form"
            disabled={!isLoader ? false : true}
            onClick={e => onSubmit(e)}
          >
            {btnText}
          </button>
    </Fragment>
  );
};

OneSettings.propTypes = {
  user: PropTypes.object,
};

export default OneSettings;
