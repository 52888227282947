import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Select from 'react-select';

const ParticipantItem = ({
  choosedEmailUsers,
  toggleEmailUsers,
  emailUsersForm,
  profiles,
  handleEmailUsers,
  emailUsersOptions,
 }) => {
  
  return (
    <Fragment>
      <Fragment>
        <p className="sub-title focus-yellow focus my-2">
          <FormattedMessage
            id="admin.dashboard.edit.participants"
            defaultMessage="Participants"
          />
        </p>

        <div className="form-main form-main-inline mt-2">
          <p className="bold medium block question w-100-xs w-30">
            <FormattedMessage
              id="participants.email"
              defaultMessage="Participants (via email)"
            />
          </p>

          <div className="w-unset pl-3 w-30 pt-">
            {choosedEmailUsers &&
            choosedEmailUsers.length > 0 && (
              choosedEmailUsers.map(item => (
                <p
                  className="small w-100 inline"
                  key={item.value}
                >
                  {' '}
                  {item.label}
                </p>
              ))
            )}
          </div>

          <span
            className="btn-secondary btn-secondary-back"
            onClick={e => toggleEmailUsers(!emailUsersForm)}
          >
            {emailUsersForm ? (
              <FormattedMessage
                id="admin.dashboard.edit.users.btn-hide"
                defaultMessage="Hide participants"
              />
            ) : (
                  <FormattedMessage
                    id="admin.dashboard.edit.users.btn"
                    defaultMessage="Choose participants"
                  />
              )}
          </span>
        </div>
    </Fragment>

    {emailUsersForm && (
      <Fragment>
        <p className="medium block">
          <FormattedMessage
              id="admin.dashboard.edit.users.description"
            defaultMessage="Choose team members who will participate in the campaign. If you choose a team, then all members of this team will receive notifications."
          />
        </p>
        <div>
          <p className="bold medium block question">
            <FormattedMessage
                id="admin.dashboard.edit.users.users"
              defaultMessage="Team members"
            />
          </p>
          <FormattedMessage
              id="admin.dashboard.edit.users.userPlaceholder"
            defaultMessage="Add users from the company"
          >
            {msg => (
              <Select
                key={profiles && `${profiles._id}-U`}
                defaultValue={choosedEmailUsers}
                onChange={selectedOption =>
                  handleEmailUsers(selectedOption)
                }
                options={emailUsersOptions}
                isMulti
                isSearchable
                placeholder={msg}
              />
            )}
            </FormattedMessage>
          </div>
      </Fragment>
    )}
    </Fragment>
  );
};

ParticipantItem.propTypes = {
  choosedEmailUsers: PropTypes.array.isRequired,
  toggleEmailUsers: PropTypes.func.isRequired,
};

export default ParticipantItem;
