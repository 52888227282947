import React from "react";
import PropTypes from 'prop-types';

const SettingsIcon = ({ activeIndex }) => {
  return (
    <svg id="settings-slider" className="w-20px" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path id="Path_38" fill={`${activeIndex === 9 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 38" d="M1723.768,1671.438c0-2.257-.005-4.513.005-6.77a4.576,4.576,0,0,1,.112-1.084,2.8,2.8,0,0,1,2.544-2.1c.143-.01.286-.013.429-.013q6.919,0,13.84,0a2.994,2.994,0,0,1,2.179.8,2.729,2.729,0,0,1,.866,1.805c.016.19.023.381.023.571q0,6.818,0,13.635a4.478,4.478,0,0,1-.079.947,2.888,2.888,0,0,1-2.841,2.241c-.08,0-.159,0-.239,0h-13.649a3.154,3.154,0,0,1-2.246-.764,2.755,2.755,0,0,1-.916-1.829c-.022-.237-.025-.476-.025-.715Q1723.767,1674.8,1723.768,1671.438Zm9.983-8.572h-6.775c-.191,0-.383,0-.572.015a1.323,1.323,0,0,0-1.206,1.105,3.733,3.733,0,0,0-.05.616q0,6.84,0,13.68a3.834,3.834,0,0,0,.043.616,1.285,1.285,0,0,0,1.037,1.11,2.867,2.867,0,0,0,.662.061q6.87,0,13.741,0c.128,0,.255,0,.382-.008a1.374,1.374,0,0,0,1.349-1.291,3.983,3.983,0,0,0,.026-.475q0-6.84,0-13.68a3.687,3.687,0,0,0-.056-.663,1.322,1.322,0,0,0-1.137-1.067,5.144,5.144,0,0,0-.62-.021Z" transform="translate(-1723.767 -1661.468)"/>
      <path id="Path_39" fill={`${activeIndex === 9 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 39" d="M1733.808,1674.349c0-.433-.025-.8.005-1.156a.647.647,0,0,1,.966-.552,1.079,1.079,0,0,1,.351.415.674.674,0,0,1,.04.327q0,1.644,0,3.289a1.57,1.57,0,0,1-.016.237.627.627,0,0,1-.679.592.613.613,0,0,1-.661-.607c-.033-.359-.028-.723-.043-1.15h-.541c-1.367,0-2.735,0-4.1,0a2.365,2.365,0,0,1-.519-.047.625.625,0,0,1-.527-.728.634.634,0,0,1,.62-.607,3.836,3.836,0,0,1,.429-.011h4.677Z" transform="translate(-1723.767 -1661.468)"/>
      <path id="Path_40" fill={`${activeIndex === 9 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 40" d="M1730.93,1668.616c-.211-.011-.365-.024-.519-.025-.477,0-.955.014-1.431-.009a1.656,1.656,0,0,1-.636-.154.623.623,0,0,1-.247-.715.609.609,0,0,1,.61-.505c.508-.022,1.017-.011,1.526-.014h.664c.012-.237.023-.437.033-.638a4.878,4.878,0,0,1,.04-.664.648.648,0,0,1,.68-.486.637.637,0,0,1,.621.5,2.019,2.019,0,0,1,.035.426c0,1.033.005,2.065,0,3.1,0,.647-.222.927-.7.919s-.67-.278-.677-.942C1730.928,1669.153,1730.93,1668.9,1730.93,1668.616Z" transform="translate(-1723.767 -1661.468)"/>
      <path id="Path_41" fill={`${activeIndex === 9 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 41" d="M1736.642,1667.2c.7,0,1.4-.012,2.1,0a.645.645,0,0,1,.717.7.6.6,0,0,1-.481.624,1.846,1.846,0,0,1-.469.057q-1.859.006-3.718,0a2.009,2.009,0,0,1-.424-.041.629.629,0,0,1-.555-.707.62.62,0,0,1,.641-.632c.651-.024,1.3-.011,1.954-.014h.238Z" transform="translate(-1723.767 -1661.468)"/>
      <path id="Path_42" fill={`${activeIndex === 9 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 42" d="M1738.106,1674.357c.238,0,.477-.017.713,0a.622.622,0,0,1,.636.636.592.592,0,0,1-.555.7,10.425,10.425,0,0,1-1.7-.011.578.578,0,0,1-.524-.674.631.631,0,0,1,.624-.649c.268-.022.539,0,.809,0Z" transform="translate(-1723.767 -1661.468)"/>
    </svg>
  );
};

SettingsIcon.propTypes = {
  activeIndex: PropTypes.number
};

export default SettingsIcon;
