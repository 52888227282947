import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { icons } from '../../img/elements/icons';
import { FormattedMessage } from 'react-intl';
import Select, { components } from 'react-select';
import chroma from 'chroma-js';
import DatePicker from 'react-date-picker';
import Highlighter from "react-highlight-words";

const Checkbox = ({
  titleId,
  titleMsg,
  type,
  inputId,
  inputName,
  value,
  onChange,
  styles,
  stylesTitle,
  _id,
}) => {

  return (
    <Fragment>
      <div className={`checkbox ${styles}`}>
          <p className={`bold medium block w-100-xs ${stylesTitle}`}>
            <FormattedMessage
              id={titleId}
              defaultMessage={titleMsg}
            />
          </p>

          <div className="checkbox email pl-3 mb-2-xs">
            <div className="checkbox-item active-status">
              <input
                type="checkbox"
                id={inputId}
                name={inputName}
                checked={value}
                value={value}
                onChange={e => onChange(e, inputName, _id)}
              />
              <label htmlFor={inputName}>
                {value ? (
                  <FormattedMessage
                    id="admin.dashboard.edit.email.active"
                    defaultMessage="Active"
                  />
                ) : (
                    <FormattedMessage
                      id="admin.dashboard.edit.email.disable"
                      defaultMessage="Disabled"
                    />
                  )}
              </label>
            </div>
          </div>
        </div>
    </Fragment>
  );
};

export default Checkbox;
