import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { icons } from '../../img/elements/icons';
import { FormattedMessage } from 'react-intl';
import Select, { components } from 'react-select';
import chroma from 'chroma-js';
import DatePicker from 'react-date-picker'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
  }),
  input: () => ({
    backgroundColor: '#ffffff',
    fontSize: '12px',
    padding: '2px 0',
  }),
  control: styles => ({
    ...styles,
    backgroundColor: '#ffffff', 
    padding: '2px 0px',
    borderColor: 'transparent'
  }),
  placeholder: styles => ({
        ...styles,
        fontSize: '12px',
    }),
  menu: styles => ({
    ...styles,
    width: '150px', 
    textAlign: 'left',
  }),
  clearIndicator: styles => ({
    ...styles,
    padding: '0px', 
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: '0px', 
  }),
  singleValue: (provided, state) => {
    const fontSize = '12px';

    return { ...provided, fontSize };
  }
}

const customStylesPriority = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      fontSize: '12px',
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  input: () => ({
    fontSize: '12px',
  }),
  control: styles => ({
    ...styles,
    padding: '2px 0px',
    borderColor: 'transparent'
  }),
  placeholder: styles => ({ ...styles, fontSize: '12px' }),
  menu: styles => ({
    ...styles,
    width: '150px', 
    textAlign: 'left',
  }),
  clearIndicator: styles => ({
    ...styles,
    padding: '0px', 
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: '0px', 
  }),
  singleValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
      fontSize: '12px',
      padding: '5px',
      borderRadius: '5px',
      color: data.color,
    };
  },
}

const indicatorSeparatorStyle = {
  alignSelf: 'stretch',
  backgroundColor: 'transparent',
  marginBottom: 8,
  marginTop: 8,
  width: 0,
};

const IndicatorSeparator = ({ innerProps }) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};

const TodoItem2CardForm = ({
  item,
  formData,
  usersOptions,
  okrOptions,
  priorityOptions,
  onSubmit,
  handleChangeForm,
  index,
  clearTodo,
  krId,
  addMsg,
  name,
  inputName,
  isAssignee,
  isDueIn,
  isTodo,
  handleChangeFormState,
  oneId,
  width,
  textarea,
}) => {

  let isCompleted = item.isCompleted;
  let owner = item.owner;
  let dueIn = item.dueIn;
  let text = item.text;
  let inputValue = text;
  if (isTodo) { inputValue = item.name };
  let wording = {};
  wording.inputId = `add.${addMsg}`;
  wording.inputMsg = `Add ${addMsg}`;

   return (
    <Fragment>
       <form
         onSubmit={e => onSubmit(e, index, name)}
         className="flex space-between align-center px-1 pt-xs">
          <div className={`${!width ? 'w-50' : width}`}>
            {!textarea ? (
            <FormattedMessage
                id={wording.inputId}
                defaultMessage={wording.inputMsg}
              >
                {msg => (
                  <input
                    className={`w-100 medium card-subtitle brd-transparent brd_bottom_grey outline ${!isCompleted ? ('todo__item') : ('todo__completed')}`}
                    type="text"
                    required
                    autoFocus
                    value={inputValue || ''}
                    onChange={e =>
                      handleChangeFormState(e.target.value, index, inputName, name)
                    }
                    placeholder={msg}
                    name={inputName}
                  />
                )}
              </FormattedMessage>
              ) : (
                <FormattedMessage
                id={wording.inputId}
                defaultMessage={wording.inputMsg}
              >
                {msg => (
                  <textarea
                    className={`w-100 medium card-subtitle brd-primary_light2 p-1 outline`}
                    type="text"
                    required
                    rows='3'
                    autoFocus
                    value={inputValue || ''}
                    onChange={e =>
                      handleChangeFormState(e.target.value, index, inputName, name)
                    }
                    placeholder={msg}
                    name={inputName}
                  />
                )}
              </FormattedMessage>
              )
            }
          </div>

        {isAssignee || isDueIn || isAssignee && isDueIn && (
          <div className="w-250px h-30 flex space-between align-center">

            {isAssignee && (
              <div className="mx-1 w-120px text-center">
              <FormattedMessage
                id="select.assignee"
                defaultMessage="Assignee..."
              >
                {msg => (
                  <Select
                    className="small-input mt-2 mb-2 select__no-indicator"
                    components={{ IndicatorSeparator }}
                    defaultValue={formData.owner}
                    value={owner || ''}
                    onChange={selectedOption =>
                      handleChangeFormState(selectedOption, index, 'owner', name)
                    }
                    options={usersOptions}
                    isSearchable
                    isClearable
                    placeholder={msg}
                    name="owner"
                    styles={customStyles}
                    isMulti
                  />
                )}
                </FormattedMessage>
              </div>
            )}

            {isDueIn && (
              <div className="mx-1 w-120px text-center">
              <DatePicker
                onChange={selectedOption => handleChangeFormState(selectedOption, index, 'dueIn', name)}
                value={dueIn === null ? dueIn : new Date(dueIn)}
              />
              </div>
            )}
          </div>
        )}

        <div className="form-btn flex align-center">
          <button
            onClick={e => onSubmit(e, index, name)}
            className="inline-block bg-lead pointer medium outline brd br-20 cursor px-1 lh-30 mr-2">
            <FormattedMessage
              id="add"
              defaultMessage="Add"
            />
          </button>
           <span 
             onClick={() => clearTodo(index, name)}
             className="no_btn small"
           >
              <i className="fas fa-times cursor grey fs-14"/>
          </span>
        </div>
      </form>
    </Fragment>
  );
};

TodoItem2CardForm.propTypes = {
  item: PropTypes.object.isRequired,
  clearTodo: PropTypes.func,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(TodoItem2CardForm);
