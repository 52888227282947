import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Subtitle from './Subtitle';
import NotificationItem from './NotificationItem';
import NotificationItemReports from './NotificationItemReports';
import SelectItem from "../../elems/SelectWithValue";
import useAnalyticsEventTracker from "../../../utils/analytics/useAnalyticsEventTracker";
import slackBtn from '../../../img/buttons/slack@2x.png';

const slackUrl = "https://slack.com/oauth/v2/authorize?client_id=783663848244.2478640026919&scope=channels:join,channels:history,channels:read,chat:write,groups:history,im:history,im:read,links:read,mpim:history,team:read,users:read,users:read.email&user_scope=";

const teamTitle =<FormattedMessage id="team.notifications" defaultMessage="Team member notifications" />;
const teamDescription =<FormattedMessage id="team.notifications.desc" defaultMessage="Notify your participants through different channels" />;
const emailNotifications = <FormattedMessage id="notif.email" defaultMessage="Send notifications through Email" />;
const slackNotifications = <FormattedMessage id="notif.slack" defaultMessage="Send notifications through Slack" />;
const reportTitle =<FormattedMessage id="admin.notifications" defaultMessage="Admin notifications" />;
const reportDescription =<FormattedMessage id="report.notifications.desc" defaultMessage="Get notifications and reports on completed check-ins" />;
const reportNotifications = <FormattedMessage id="email.reports" defaultMessage="Weekly Email Reports" />;
const reportNotificationsDesc = <FormattedMessage id="email.reports.desc" defaultMessage="Get a report each Sunday, which will let you know how your check-in went" />;
const reportNotificationsInput = <FormattedMessage id="email.reports.input" defaultMessage="Report Time" />;
const frequencyNotifications = <FormattedMessage id="email.notification" defaultMessage="Email Notifications" />;
const frequencyNotificationsDesc = <FormattedMessage id="email.notification.desc" defaultMessage="Get a notification about completed Check-ins" />;
const frequencyNotificationsInput = <FormattedMessage id="email.notification.input" defaultMessage="Report Frequency" />;
const slackReportTitle =<FormattedMessage id="slack.notifications" defaultMessage="Answers notifications" />;
const slackReportDescription =<FormattedMessage id="slack.notifications.desc" defaultMessage="Get Slack notifications on every check-in answer" />;

const wordingFrequency = {
  placeholderId: "select.frequency",
  placeholderMsg: "Select frequency",
}

const wordingTime = {
  hours: {
    placeholderId: "hours",
    placeholderMsg: "Hours",
  },
  minutes: {
    placeholderId: "minutes",
    placeholderMsg: "Minutes",
  }
}

const wordingSlackReport = {
  placeholderId: "select.slack",
  placeholderMsg: "Select users or channels",
}

const gaEventTracker = () => {
  useAnalyticsEventTracker('slack', 'slack installed', 'Slack installation')
};

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: state.isFocused ? '#404ff5' : '#e9eaf5',
    boxShadow: state.isFocused ? '0px 0px 20px #404FF54D' : 'none',
    border: state.isFocused ? '1px solid #404ff5' : styles.border,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    fontSize: '14px',
    height: '38px',
  }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize, };
  },
  tooltipImage: {
    width: '16px',
    height: '16px',
    marginLeft: '6px',
    marginRight: '6px',
    marginBottom: '-3px'
  }
};

const Notifications = ({
  emailIsActive,
  formData,
  setFormData,
  company,
  slackIsActive,
  reportIsActive,
  localReportHour,
  localReportMinute,
  minuteOptions,
  hourOptions,
  handleIntervalSelect,
  reportFrequency,
  reportFrequencyOptions,
  handleChangeSlackChannelReport,
  slackReportChannels,
  slackReportChannelsOptions,
  type,
  handleSelect
}) => {
  return (
    <div className="pb-2">
      <div className="mb-2 mt-2">
        <div className="mb-07">
          <Subtitle title={teamTitle} />
        </div>
        <p className="fs-12 lh-15 text-left">{teamDescription}</p>
      </div>

      <NotificationItem 
        checkboxName={"emailIsActive"}
        checkboxValue={emailIsActive}
        formData={formData}
        setFormData={setFormData}
        key={`users-U`}
        label={emailNotifications}
      />

      {company && company.isSlack && (
        <div className="mt-1">
          <NotificationItem 
            checkboxName={"slackIsActive"}
            checkboxValue={slackIsActive}
            formData={formData}
            setFormData={setFormData}
            key={`slack-U`}
            label={slackNotifications}
          />
        </div>
      )}
      {company && !company.isSlack && (
        <div>
          <div className="w-100 flex align-start flex-column checkbox">
            <div className="flex align-center">
            <div className="checkbox-item active-status active-status-new flex align-center active-status ml-3">
              <input type="checkbox" value={false} checked={false} disabled />
              <label className="fs-13 opacity-04">{slackNotifications}</label>
            </div>
            <a href={slackUrl} target="_blank" onClick={() => gaEventTracker()} rel="noreferrer">
                <img
                  alt="Add to Slack"
                  height="40"
                  width="139"
                  src={slackBtn}
                  className="w-100 bg-transparent"
                />
              </a>
              </div>
          </div>
        </div>
      )}

      {company && company.isSlack && (
        <div>
          <div className="mt-2">
            <div className="mb-07">
              <Subtitle title={slackReportTitle} />
            </div>
            <p className="fs-12 lh-15 text-left">{slackReportDescription}</p>
          </div>
          <div className="w-300px mt-05">
            <SelectItem
              value={slackReportChannels}
              onChange={handleChangeSlackChannelReport}
              options={slackReportChannelsOptions}
              customStyles={customStyles}
              isClearable={false}
              wording={wordingSlackReport}
              isMulti={true}
            />
          </div>
        </div>
      )}

      <div className="mt-2">
        <div className="mb-07">
          <Subtitle title={reportTitle} />
        </div>
        <p className="fs-12 lh-15 text-left">{reportDescription}</p>
      </div>

      <NotificationItemReports 
        checkboxName={"isCheckinReport"}
        checkboxValue={formData.isCheckinReport}
        formData={formData}
        setFormData={setFormData}
        defaultValue={reportFrequency}
        handleSelect={handleSelect}
        selectOptions={reportFrequencyOptions}
        isClearable={false}
        key={`frequency-U`}
        wording={wordingFrequency}
        isMulti={false}
        isSearchable={true}
        label={frequencyNotifications}
        subTitle={frequencyNotificationsDesc}
        inputLabel={frequencyNotificationsInput}
        type={type}
        section={1}
        localReportTime={{hour:localReportHour, minute: localReportMinute}}
      />

      <div className="mb-2">
        <NotificationItemReports 
          checkboxName={"reportIsActive"}
          checkboxValue={reportIsActive}
          formData={formData}
          setFormData={setFormData}
          defaultValue={{hour:localReportHour, minute: localReportMinute}}
          handleSelect={handleIntervalSelect}
          selectOptions={{hour: hourOptions, minute: minuteOptions}}
          isClearable={false}
          key={`reports-U`}
          wording={wordingTime}
          isMulti={false}
          isSearchable={true}
          label={reportNotifications}
          subTitle={reportNotificationsDesc}
          inputLabel={reportNotificationsInput}
          type={type}
          section={2}
        />
      </div>

      <div className="sub-menu__page mb-4"></div>

    </div>
  );
};

Notifications.propTypes = {
  emailIsActive: PropTypes.bool,
  text: PropTypes.object,
};

export default Notifications;
