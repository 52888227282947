import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import OkrProgress from "../elements/OkrProgress";
import TreeKeyResult from "./TreeKeyResult";
import Moment from "react-moment";
import { FormattedMessage } from "react-intl";

const TreeOkrItem = ({
  objective,
  owner,
  ownerAvatar,
  ownerFirstName,
  status,
  dueIn,
  btnClass,
  progress,
  keyResults,
}) => {
  const [krs, showKeyResults] = useState(false);

  // На всякий случай округляем в дереве
  progress = Math.round(progress);

  return (
    <Fragment>
      <div className="cards py-2">
        <div className="card-item card-team flex flex-column">

        <div className="w-100">
          <div className={`card-title card-title-${btnClass} p-2`}>
            <div className="flex align-center column w-70">
              <p 
                className="large w-100 cursor"
                onClick={() => showKeyResults(!krs)
              }>
                {objective}
              </p>
              <div className="okr__status w-100">
                <span className={`${btnClass} medium-xs mr-2`}>{status}</span>
                <span className="small grey">
                  <FormattedMessage id="user.okr.due" defaultMessage="Due" />:{" "}
                  {dueIn ? (<Moment format="D MMM">{dueIn}</Moment>) : '–'}
                </span>

              </div>
            </div>

            <div className="flex align-center justify-start">
              <Link to={`/user/${owner}`} className="user">
                <img
                  src={ownerAvatar && ownerAvatar}
                  alt={`${ownerFirstName}'s picture`}
                  className="w-35px round-img d-block avatar"
                  width="35"
                  height="35"
                />
              </Link>
            </div>

            <OkrProgress
              btnClass={btnClass}
              progress={progress}
            />
          </div>
        </div>

        {krs && (
          <div className="card-body card-standups-team card-body-hidden pt-2 pb-1 px-3 active">
            <div className="card-goal-item relative">
              <span className="small grey absolute fs-10 absolute kr__sub-title-goals">
                <FormattedMessage
                    id="okr.kr.current_goal"
                    defaultMessage="Current / Goal"
                  />
              </span>
              <div className="mb-2">
                {keyResults.length > 0 && (
                  keyResults.map(item => (
                    <TreeKeyResult
                      key={`key-result-${item._id}`}
                      keyResult={item}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        )}
        </div>
      </div>
    </Fragment>
  );
};

TreeOkrItem.propTypes = {
  ownerAvatar: PropTypes.string.isRequired,
  ownerFirstName: PropTypes.string.isRequired,
  objective: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  dueIn: PropTypes.string,
  btnClass: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  keyResults: PropTypes.array,
};

export default TreeOkrItem;
