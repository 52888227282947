import axios from 'axios';
import { setAlert } from './alert';
import {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  CLEAR_PROFILE,
  CLEAR_COMPANY,
  CLEAR_STANDUP_ANSWERS,
  CLEAR_STANDUPS,
  CLEAR_UPDATES,
  CLEAR_UPDATE_ANSWERS,
  CLEAR_PLANS,
  CLEAR_PLAN_ANSWERS,
  CLEAR_RECOGNITIONS,
  RESEND_SUCCESS,
  RESEND_ERROR,
  RESEND_NOTOKEN,
  CONFIRM_SUCCESS,
  RESET_SUCCESS,
  RESET_ERROR,
  RESET_NOTOKEN,
  RESET_PASSWORD_SUCCESS,
  CLEAR_OKRS,
  VALIDATE_EMAIL,
  CLEAR_HOME,
  CLEAR_REACH,
  LOGOUT,
  LOGIN_AS_USER_SUCCESS,
  LOGIN_AS_USER_ERROR,
  LOGOUT_AS_USER_SUCCESS,
  LOGOUT_AS_USER_ERROR,
  UPDATE_AVATAR_AUTH,
  CLEAR_ICEBREAKERS,
  CLEAR_ONES,
  CLEAR_OKR_FILTERS,
  SET_SIDEBAR_VIEW,
  CHECK_OKR_FILTERS,
  CHECK_CHECKIN_FILTERS,
  CLEAR_CHECKIN_FILTERS,
} from './types';
import setAuthToken from '../utils/setAuthToken';
// import store from '../store';
// import { checkOkrFilters } from './okr';


// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({
      type: CLEAR_OKR_FILTERS,
    });
    const res = await axios.get('/api/auth');

    let sidebar = '';
    if (!localStorage.sidebar) {
      // If user doesn't have sidebar in LS
      sidebar = 1;

      dispatch({
        type: SET_SIDEBAR_VIEW,
        payload: sidebar
      });
    } else {
      sidebar = localStorage.sidebar;
    }

    dispatch({
      type: USER_LOADED,
      // payload: res.data
      payload: { user: res.data, sidebar: JSON.parse(localStorage.sidebar) }
    });

    // store.dispatch(checkOkrFilters({ user: res.data }))
    // console.log('==== res.data in loadUser', res.data)
    dispatch({
      type: CHECK_OKR_FILTERS,
      payload: res.data 
    });

    dispatch({
      type: CHECK_CHECKIN_FILTERS,
      payload: res.data 
    });


  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

// Change sidebar
export const changeSidebar = (sidebar) => async (dispatch) => {
  try {

    let newSidebsar = sidebar === 1 ? 2 : 1;

    dispatch({
      type: SET_SIDEBAR_VIEW,
      payload: newSidebsar
    });
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger', 10000)));
    }
  }
};

// Signup User as Owner
export const signup =
  ({ firstName, lastName, email, password, company, jobRole, lang, numberOfEmployees, code = null, cl_timezone }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({
      firstName,
      lastName,
      email,
      password,
      company,
      jobRole,
      lang,
      numberOfEmployees,
      code,
      cl_timezone
    });

    try {
      const res = await axios.post('/api/users', body, config);
  
      dispatch({
        type: VALIDATE_EMAIL,
        payload: res.data.token
      });
  
      return [];
  
    } catch (err) {
      const errors = err.response.data.errors;
  
      if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
      }
  
      dispatch({
        type: SIGNUP_FAIL
      });
  
      return errors;
    }
  };

// Signup User as a Member via Invitation
export const invitation =
  ({ firstName, lastName, password, token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token
      }
    };

    const body = JSON.stringify({
      firstName,
      lastName,
      password
    });

    try {
      const res = await axios.post('/api/auth/invitation/accept', body, config);

      dispatch({
        type: SIGNUP_SUCCESS,
        payload: res.data
      });

      dispatch(loadUser());
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }

      dispatch({
        type: SIGNUP_FAIL
      });
    }
  };

// Login User
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post('/api/auth', body, config);
    // console.log('res.data in login', res.data)
    
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });

    dispatch(loadUser());
  } catch (err) {
    console.log('====err', err);

    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LOGIN_FAIL
    });
  }
};

// Create Invitation for new user(s)
export const createSlackInvite = (slackIds, lang, history) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ slackIds, lang });

  try {
    await axios.post('/api/auth/slack/invitation', body, config);

    dispatch(setAlert(`Congrats! You sent the invitation for your co-worker.`, 'success', 6000));
    return true;
  } catch (err) {
    console.log('===== err', err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger', 10000)));
    }
    return false;
  }
};

// Create Invitation for new user(s)
export const createInvite = (email, lang, history) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ email, lang });

  try {
    await axios.post('/api/auth/invitation', body, config);

    dispatch(setAlert(`Congrats! You sent the invitation for your co-worker.`, 'success', 6000));
    return true;
  } catch (err) {
    console.log('===== err', err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger', 10000)));
    }
    return false;
  }
};

// Resend a confirmation email
export const resend = (email) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ email });

  try {
    const res = await axios.put('/api/auth/resend/confirm', body, config);

    dispatch({
      type: RESEND_SUCCESS,
      payload: res.data.token
    });

    dispatch(setAlert(`Email sent! Please check your inbox.`, 'success', 6000));
  } catch (err) {
    const errors = err.response.data.errors;

    dispatch({
      type: RESEND_ERROR
    });

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger', 6000)));
    }
  }
};

// Remove resend notification
export const toggleResend = () => async (dispatch) => {
  try {
    dispatch({
      type: RESEND_NOTOKEN
    });
  } catch (err) {
    const errors = err.response.data.errors;

    dispatch({
      type: RESEND_ERROR
    });

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger', 10000)));
    }
  }
};

// Reset password email
export const reset = (email) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ email });

  try {
    const res = await axios.put('/api/auth/password/reset', body, config);

    dispatch({
      type: RESET_SUCCESS,
      payload: res.data.token
    });

    dispatch(
      setAlert(
        `If this email address was used to create an account, instructions to reset your password will be sent to you. Please check your email.`,
        'success',
        6000
      )
    );
  } catch (err) {
    const errors = err.response.data.errors;

    dispatch({
      type: RESET_ERROR
    });

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger', 6000)));
    }
  }
};

// Remove resend notification
export const toggleReset = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_NOTOKEN
    });
  } catch (err) {
    const errors = err.response.data.errors;

    dispatch({
      type: RESET_ERROR
    });

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger', 10000)));
    }
  }
};

// Change password (reset password)
export const changePassword =
  ({ password, token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token
      }
    };

    const body = JSON.stringify({
      newPassword: password
    });

    try {
      const res = await axios.put('/api/auth/password/change', body, config);

      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: res.data
      });

      dispatch(loadUser());
    } catch (err) {
      const errors = err.response.data.errors;

      if (err.response.status === 401) {
        errors.forEach(() =>
          dispatch(setAlert('Your link was expired or beaten. Please reset the password again.', 'danger'))
        );
      } else if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }

      dispatch({
        type: RESET_ERROR
      });
    }
  };

// Check code and confirm account
export const checkEmail = (confirmationCode, history) => async (dispatch) => {
  try {
    let res = await axios.put(`/api/auth/confirm/${confirmationCode}`);

    dispatch({
      type: CONFIRM_SUCCESS,
      payload: res.data.token
    });
  } catch (err) {
    const errors = err.response.data.errors;
    console.log('====== errors in checkEmail', errors);

    history.push('/confirmation/error');

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger', 10000)));
    }
  }
};

// Login as a User
export const loginAsUser = (id, history) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ id });

  try {
    const res = await axios.get(`/api/admin/user/login/${id}`, body, config);

    dispatch({
      type: LOGIN_AS_USER_SUCCESS,
      payload: res.data
    });

    dispatch(loadUser());

    history.push('/home');
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LOGIN_AS_USER_ERROR
    });
  }
};

// Login as a User
export const logoutAsUser = () => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const res = await axios.get('/api/admin/user/logout', config);

    dispatch({
      type: LOGOUT_AS_USER_SUCCESS,
      payload: res.data
    });

    dispatch({
      type: CLEAR_CHECKIN_FILTERS,
    });

    // dispatch(loadUser());
    window.location.reload()
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LOGOUT_AS_USER_ERROR
    });
  }
};

// Update Avatar
export const updateAvatar = (avatar) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_AVATAR_AUTH,
      payload: avatar
    });
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger', 10000)));
    }
  }
};

// Logout / Clear Profile
export const logout = () => (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: CLEAR_COMPANY });
  dispatch({ type: CLEAR_STANDUP_ANSWERS });
  dispatch({ type: CLEAR_STANDUPS });
  dispatch({ type: CLEAR_UPDATES });
  dispatch({ type: CLEAR_UPDATE_ANSWERS });
  dispatch({ type: CLEAR_RECOGNITIONS });
  dispatch({ type: CLEAR_OKRS });
  dispatch({ type: CLEAR_HOME });
  dispatch({ type: CLEAR_REACH });
  dispatch({ type: CLEAR_ICEBREAKERS });
  dispatch({ type: CLEAR_PLANS });
  dispatch({ type: CLEAR_PLAN_ANSWERS });
  dispatch({ type: CLEAR_ONES });
  dispatch({ type: LOGOUT });
};
