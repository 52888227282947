import React from 'react';
import { FormattedMessage } from 'react-intl';


const onboardKeyResults1 = [
  { 
    _id: "onboarding_kr1", 
    title: (
      <FormattedMessage
        id="onboarding.okr.kr1"
        defaultMessage="Hit quarterly revenue of over $100000"
      />
    ), 
    krType:{
      "label": "Number",
      "value": "number"
    },
    start: "0",
    current: "100000", 
    goal: "100000", 
    owner: "onboarding_owner1",
    ownerFirstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName1"
        defaultMessage="Tom"
      />
    ),
    ownerLastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName1"
        defaultMessage="Ford"
      />
    ),
    ownerAvatar: "https://app.usefocus.co/img/avatar/users/pravatar52.jpeg",
    progress: 100,
    dueDate: "2020-12-31T09:00:00.000+00:00"
  },
  { 
    _id: "onboarding_kr2", 
    title: (
      <FormattedMessage
        id="onboarding.okr.kr2"
        defaultMessage="Start sales in 2 new countries and achieve first quarter revenues totaling over $10000"
      />
    ), 
    krType:{
      "label": "Number",
      "value": "number"
    },
    start: "0",
    current: "5000", 
    goal: "10000", 
    owner: "onboarding_owner1",
    ownerFirstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName7"
        defaultMessage="Paul"
      />
    ),
    ownerLastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName7"
        defaultMessage="Morton"
      />
    ),
    ownerAvatar: "https://app.usefocus.co/img/avatar/users/pravatar14.jpeg",
    progress: 50,
    dueDate: "2020-12-31T09:00:00.000+00:00"
  },
  { 
    _id: "onboarding_kr3", 
    title: (
      <FormattedMessage
        id="onboarding.okr.kr3"
        defaultMessage="Increase average subscription size to at least $295 per month"
      />
    ), 
    krType:{
      "label": "Number",
      "value": "number"
    },
    start: "100",
    current: "295", 
    goal: "295", 
    owner: "onboarding_owner1",
    ownerFirstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName6"
        defaultMessage="Monica"
      />
    ),
    ownerLastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName6"
        defaultMessage="Ransky"
      />
    ),
    ownerAvatar: "https://app.usefocus.co/img/avatar/users/pravatar25.jpeg",
    progress: 100,
    dueDate: "2020-12-31T09:00:00.000+00:00"
  }
]

const onboardKeyResults2 = [
  { 
    _id: "onboarding_kr4", 
    title: (
      <FormattedMessage
        id="onboarding.okr.kr4"
        defaultMessage="Interview 120 employees on their needs for improving our work culture"
      />
    ), 
    krType:{
      "label": "Number",
      "value": "number"
    },
    start: "0",
    current: "24", 
    goal: "120", 
    owner: "onboarding_owner1",
    ownerFirstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName5"
        defaultMessage="John"
      />
    ),
    ownerLastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName5"
        defaultMessage="Hurt"
      />
    ),
    ownerAvatar: "https://app.usefocus.co/img/avatar/users/pravatar57.jpeg",
    progress: 20,
    dueDate: "2020-12-31T09:00:00.000+00:00"
  },
  { 
    _id: "onboarding_kr5", 
    title: (
      <FormattedMessage
        id="onboarding.okr.kr5"
        defaultMessage="Hire 2 assistants to service employee administrative needs"
      />
    ), 
    krType:{
      "label": "Number",
      "value": "number"
    },
    start: "0",
    current: "1", 
    goal: "2", 
    owner: "onboarding_owner1",
    ownerFirstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName2"
        defaultMessage="Julian"
      />
    ),
    ownerLastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName2"
        defaultMessage="Tobin"
      />
    ),
    ownerAvatar: "https://app.usefocus.co/img/avatar/users/pravatar44.jpeg",
    progress: 50,
    dueDate: "2020-12-31T09:00:00.000+00:00"
  },
  { 
    _id: "onboarding_kr6", 
    title: (
      <FormattedMessage
        id="onboarding.okr.kr6"
        defaultMessage="Increase weekly employee satisfaction score of 8.5 points"
      />
    ), 
    krType:{
      "label": "Number",
      "value": "number"
    },
    start: "0",
    current: "6", 
    goal: "8.5", 
    owner: "onboarding_owner1",
    ownerFirstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName2"
        defaultMessage="Julian"
      />
    ),
    ownerLastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName2"
        defaultMessage="Tobin"
      />
    ),
    ownerAvatar: "https://app.usefocus.co/img/avatar/users/pravatar44.jpeg",
    progress: 70,
    dueDate: "2020-12-31T09:00:00.000+00:00"
  }
]

const onboardKeyResults3 = [
  { 
    _id: "onboarding_kr7", 
    title: (
      <FormattedMessage
        id="onboarding.okr.kr7"
        defaultMessage="Get published product reviews in over 15 publications"
      />
    ), 
    krType:{
      "label": "Number",
      "value": "number"
    },
    start: "0",
    current: "6", 
    goal: "15", 
    owner: "onboarding_owner1",
    ownerFirstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName3"
        defaultMessage="Patrick"
      />
    ),
    ownerLastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName3"
        defaultMessage="Brody"
      />
    ),
    ownerAvatar: "https://app.usefocus.co/img/avatar/users/pravatar7.jpeg",
    progress: 40,
    dueDate: "2020-12-31T09:00:00.000+00:00"
  },
  { 
    _id: "onboarding_kr8", 
    title: (
      <FormattedMessage
        id="onboarding.okr.kr8"
        defaultMessage="Get over 1000 new signups"
      />
    ), 
    krType:{
      "label": "Number",
      "value": "number"
    },
    start: "0",
    current: "600", 
    goal: "1000", 
    owner: "onboarding_owner1",
    ownerFirstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName4"
        defaultMessage="Jeremy"
      />
    ),
    ownerLastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName4"
        defaultMessage="Porter"
      />
    ),
    ownerAvatar: "https://app.usefocus.co/img/avatar/users/pravatar67.jpeg",
    progress: 60,
    dueDate: "2020-12-31T09:00:00.000+00:00"
  },
  { 
    _id: "onboarding_kr9", 
    title: (
      <FormattedMessage
        id="onboarding.okr.kr9"
        defaultMessage="Achieve sign-up to % trial ratio of over 25%"
      />
    ), 
    krType:{
      "label": "Number",
      "value": "number"
    },
    start: "0",
    current: "17", 
    goal: "25", 
    owner: "onboarding_owner1",
    ownerFirstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName3"
        defaultMessage="Patrick"
      />
    ),
    ownerLastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName3"
        defaultMessage="Brody"
      />
    ),
    ownerAvatar: "https://app.usefocus.co/img/avatar/users/pravatar7.jpeg",
    progress: 70,
    dueDate: "2020-12-31T09:00:00.000+00:00"
  }
]

let user1;
let userLastName1;
let user2;
let userLastName2;
let user3;
let userLastName3;

export const onboardingOkrs = [
  { 
    _id: "onboarding_okr1",
    objective: (
      <FormattedMessage
        id="onboarding.okr.objective1"
        defaultMessage="Achieve record revenues while increasing profitability"
      />
    ),
    owner: "onboarding_owner1",
    team: "onboarding_team1",
    teamName: (
      <FormattedMessage
        id="onboarding.okr.teamName1"
        defaultMessage="Company"
      />
    ),
    type: "1",
    creator: "onboarding_creator1",
    ownerFirstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName1"
        defaultMessage="Tom"
      />
    ),
    ownerLastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName1"
        defaultMessage="Ford"
      />
    ),
    updatedAt: "2020-03-23T07:36:33.402+00:00",
    ownerAvatar: "https://app.usefocus.co/img/avatar/users/pravatar52.jpeg",
    company: "onboarding_company1",
    companyName: "onboarding_companyName1",
    department: "onboarding_department1",
    departmentName: (
      <FormattedMessage
        id="onboarding.okr.departmentName1"
        defaultMessage="Marketing"
      />
    ),
    progress: 84,
    status: 1,
    isParent: "false",
    order: "1",
    date: "2020-03-11T07:36:33.402+00:00",
    likes: [],
    comments: [],
    dueIn: "2020-12-31T09:00:00.000+00:00",
    keyResults: onboardKeyResults1
  },
  { 
    _id: "onboarding_okr2",
    objective: (
      <FormattedMessage
        id="onboarding.okr.objective2"
        defaultMessage="Create the best company culture in the world"
      />
    ),
    owner: "onboarding_owner2",
    team: "onboarding_team2",
    teamName: (
      <FormattedMessage
        id="onboarding.okr.teamName2"
        defaultMessage="HR"
      />
    ),
    type: "1",
    creator: "onboarding_creator1",
    ownerFirstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName2"
        defaultMessage="Julian"
      />
    ),
    ownerLastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName2"
        defaultMessage="Tobin"
      />
    ),
    updatedAt: "2020-03-23T07:36:33.402+00:00",
    ownerAvatar: "https://app.usefocus.co/img/avatar/users/pravatar44.jpeg",
    company: "onboarding_company1",
    companyName: "onboarding_companyName1",
    department: "onboarding_department1",
    departmentName: (
      <FormattedMessage
        id="onboarding.okr.departmentName1"
        defaultMessage="Marketing"
      />
    ),
    progress: 46,
    status: 2,
    isParent: "false",
    order: "2",
    date: "2020-03-11T07:36:33.402+00:00",
    likes: [],
    comments: [],
    dueIn: "2020-12-31T09:00:00.000+00:00",
    keyResults: onboardKeyResults2
  },
  { 
    _id: "onboarding_okr3",
    objective: (
      <FormattedMessage
        id="onboarding.okr.objective3"
        defaultMessage="Successfully launch version 3 of our main product"
      />
    ),
    owner: "onboarding_owner2",
    team: "onboarding_team2",
    teamName: (
      <FormattedMessage
        id="onboarding.okr.teamName2"
        defaultMessage="Patrick"
      />
    ),
    type: "3",
    creator: "onboarding_creator1",
    ownerFirstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName3"
        defaultMessage="Patrick"
      />
    ),
    ownerLastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName3"
        defaultMessage="Brody"
      />
    ),
    updatedAt: "2020-03-23T07:36:33.402+00:00",
    ownerAvatar: "https://app.usefocus.co/img/avatar/users/pravatar7.jpeg",
    company: "onboarding_company1",
    companyName: "onboarding_companyName1",
    department: "onboarding_department1",
    departmentName: (
      <FormattedMessage
        id="onboarding.okr.departmentName1"
        defaultMessage="Marketing"
      />
    ),
    progress: 57,
    status: 3,
    isParent: "false",
    order: "3",
    date: "2020-03-11T07:36:33.402+00:00",
    likes: [],
    comments: [],
    dueIn: "2020-12-31T09:00:00.000+00:00",
    keyResults: onboardKeyResults3
  }
]

export default {
  onboardingOkrs,
}