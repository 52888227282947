import { SET_LOCALE } from './types';

// Set locale language
export const setLocaleLang = lang => dispatch => {
  if (lang !== 'ru') {
    lang = 'en';
  }
  
  dispatch({
    type: SET_LOCALE,
    payload: lang
  });
};
