import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import UpdateItemCard from './UpdateItemCard';

const UpdateItem = ({ updateAnswers, updateDate }) => {
  return (
    <Fragment>
          {updateAnswers.map((updateAnswer, index) => (
            <div className="component my-2 px-4 py-2 light-shadow" key={index}>
              <div className="cards">
                <UpdateItemCard
                  updateAnswer={updateAnswer}
                  key={`team-${updateAnswer._id}-${index}`}
                  updateDate={updateDate}
                />
              </div>
            </div>
          ))}
    </Fragment>
  );
};

UpdateItem.propTypes = {
  updateAnswers: PropTypes.array.isRequired
};

export default UpdateItem;
