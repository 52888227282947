import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import OkrTips from '../elements/OkrTipsNew';
import UserNav from '../../layout/UserNav';
import Menu from '../../layout/Menu';
import { icons } from '../../../img/elements/icons';
import {
  editOkr,
  getOkrById,
  getCompanyOkrsEdit,
  clearOkrs,
  getCompanyOkrs,
  getCompanySortedOkrs,
  cleanData
} from '../../../actions/okr';
import { getCompanyProfiles } from '../../../actions/profile';
import { getUserCompany } from '../../../actions/company';
import Spinner from '../../layout/Spinner';
import { setAlert } from '../../../actions/alert';
import FormTitle from '../../utils/FormTitle_new';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import chroma from 'chroma-js';
import Loader from 'react-loader-spinner';
import KRmetrics from '../elements/KRmetricsForm';
import getLabels from '../../../translations/okrs';
import DatePickerComponent from '../../elems/DatePicker';

// КАК РАБОТАЕТ
// 1) Селекты: делаем перебор профайлов, команд и статуса => приводим данные к виду для селекта (value, label) => Записываем в переменные choosedTeam, choosedOwner, choosedStatus => Подставляем в formData в useEffect их при загрузке стейта из okr.data (data - чтобы он не забирал старый okr из okr.okr)
// 2) Проблема с загрузкой контента была решена с помощью добавлению в useEffect data && choosedOwner.value.length (пояснение под useEffect)
// 3) KeyResults: самое сложное отображать при загрузке владельца и менять его. С formdata передается сразу массив keyResults. Чтобы отображать добавляем в forEach item.krOwners = krOwners, в котором правильные данные. В самом селекте отображаем defaultValue={keyResult.krOwners}. Важно, массив мы перебираем из стейта, а не формы data.keyResults.forEach.
// - чтобы не делать неконтрллируемые селект при клике на add btn, устанавливаем форму так в хендлере setFormData({ ...formData, keyResults: [...keyResults, { krNew: true, krOwners: data.keyResults.length > 0 ? data.keyResults[0].krOwners : choosedOwner }] })
// - krNew: true => говорит на беке, что новый, чтобы записались некоторые данные по другому
// - krOwners: data.keyResults.length > 0 ? data.keyResults[0].krOwners : choosedOwner  => подставляем первого владельца из массива или владельца всей цели

// ПРОБЛЕМА
// Сейчас не очищается стейт при уходе со страницы здесь!
// Из-за этого на новых страницах могут возникать баги, так как data там будет не пустой!
// Пробововал добавить в useEffect returh { clearOkrs }, но создается бесконечный цикл!
// КАК РАБОТАЕТ сейчас: в компоненте OkrNew в useEffect мы делаем cleanData, чтобы обнулить data при загрузке.
// Это полурешение, тк если пользователь перейдет на другую страницу, то дата не обнулится


// Styles for color in Status Select
const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10
  }
});

const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: state.isFocused ? '#404ff5' : '#e9eaf5',
    boxShadow: state.isFocused ? '0px 0px 20px #404FF54D' : 'none',
    border: state.isFocused ? '2px solid #404ff5' : styles.border,
    margin: state.isFocused ? '0px' : '1px',
    fontSize: '18px'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      fontSize: '18px',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        color: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css())
      }
    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
};
const selectCustomStyles = {
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isFocused ? '#404ff5' : '#fff',
    color: state.isFocused ? '#fff' : '#000',
    fontSize: '18px'
  }),
  control: (styles, state) => ({
    ...styles,
    // height: '35px',
    borderColor: state.isFocused ? '404ff5' : '#e9eaf5',
    boxShadow: state.isFocused ? '0px 0px 20px #404FF54D' : 'none',
    fontSize: '18px',
    border: state.isFocused ? '2px solid #404ff5 !important' : styles.border,
    margin: state.isFocused ? '0px' : '1px'
  }),
  indicatorContainer: (styles, state) => ({
    ...styles,
    // height: '35px',
  }),
};

const EditOkr = ({
  cleanData,
  editOkr,
  getOkrById,
  getCompanyProfiles,
  getUserCompany,
  getCompanyOkrs,
  auth: { user },
  okr: { okrs, data, isEditLoading },
  profile: { profiles },
  company: { company },
  match,
  locale,
  history,
  setAlert
}) => {
  const [formData, setFormData] = useState({
    objective: '',
    team: '',
    owner: '',
    status: '',
    keyResults: [],
    dueIn: '',
    parentOkr: '',
    privacy: '',
    isUpdatedObjective: false,
    isUpdatedTeam: false,
    isUpdatedOwner: false,
    changes: []
  });

  const [displayTips, toggleDisplayTips] = useState(false);

  const [isLoader, toggleLoader] = useState(false);
  let { objective, team, owner, status, keyResults, dueIn, parentOkr, privacy, changes } = formData;

  let privacyLabel = {
    public: 'Public',
    team: 'Team',
    private: 'Private'
  };

  if (locale && locale.lang === 'ru') {
    privacyLabel = {
      public: 'Публичная',
      team: 'Отдел',
      private: 'Приватная'
    };
  }

  // console.log('===== keyResults', keyResults)

  let ownerOptions = [];
  let teamOptions = [];
  let privacyOptions = [];
  let krTypeOptions = [];
  let krLabels = locale && getLabels(locale.lang);

  privacyOptions = [
    { value: 1, label: privacyLabel.public, name: 'privacy' },
    { value: 2, label: privacyLabel.team, name: 'privacy' },
    { value: 3, label: privacyLabel.private, name: 'privacy' }
  ];

  krTypeOptions = [
    { value: 'number', label: krLabels.krTypeLabel.number, name: 'krType' },
    { value: 'binary', label: krLabels.krTypeLabel.binary, name: 'krType' },
    { value: 'milestone', label: krLabels.krTypeLabel.milestone, name: 'krType' }
  ];

  const personalMsg = (
    <FormattedMessage id="user.okr.create.team.personal" defaultMessage="Personal objective (no team)" />
  );

  const companyMsg = (
    <FormattedMessage id="user.okr.create.team.company" defaultMessage="Сompany objective" />
  );

  useEffect(() => {
    cleanData();
  }, []);

  // Перебор profiles & departments и приведение данных к формату Селекта
  {
    profiles &&
      profiles.length > 0 &&
      profiles.forEach((profile) => {
        let coworker = {
          value: profile._id,
          label: `${profile.firstName} ${profile.lastName}`,
          id: profile._id,
          name: 'owner',
        };

        ownerOptions.push(coworker);
      });
  }

  {
    company &&
      company.departments &&
      company.departments.length > 0 &&
      company.departments.forEach((department, index) => {
        if ((user && user.isAdmin) || company.okrPrivacyCreation !== 2) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };

          teamOptions.push(team);
        }

        if (index === company.departments.length - 1) {
          // First option is the company
          if ((user && user.isAdmin) || company.okrPrivacyCreation !== 2) {
            let name = { value: company._id, label: company.name, type: 1 };
            teamOptions.unshift(name);
          }

          // Personal OKR
          let person = { value: 3, label: 'Personal objective (no team)', type: 3 };
          teamOptions.push(person);
        }
      });
  }

  if (company && company.departments && company.departments.length === 0) {
    if ((user && user.isAdmin) || company.okrPrivacyCreation !== 2) {
      teamOptions = [
        { value: company._id, label: `${company.name} - company objective`, type: 1 },
        { value: 3, label: 'Personal objective (no team)', type: 3 }
      ];
    } else {
      // for Member role
      teamOptions = [{ value: 3, label: 'Personal objective (no team)', type: 3 }];
    }
  }

  let statusObj =
    locale && locale.lang !== 'ru'
      ? { onTrack: 'On Track', behind: 'Behind', atRisk: 'At Risk' }
      : { onTrack: 'По Плану', behind: 'Отстаем', atRisk: 'Риск' };
  const statusOptions = locale &&
    locale.lang && [
      { value: 1, label: statusObj.onTrack, color: '#6ddeb3' },
      { value: 2, label: statusObj.behind, color: '#facf34' },
      { value: 3, label: statusObj.atRisk, color: 'red' }
    ];

  // Team & Owner Default values for Select format
  let choosedTeam = data ? { value: data.team, label: data.teamName, type: data.type } : {};
  let choosedOwner = data ? { value: data.owner, label: `${data.ownerFirstName} ${data.ownerLastName}` } : {};

  // Status default choice
  let choosedStatus = {};

  data &&
    statusOptions.forEach((item) => {
      if (item.value === data.status) {
        choosedStatus = {
          value: item.value,
          label: item.label,
          color: item.color
        };
      }
    });

  // Key Results' owner default choice
  data &&
    data.length !== 0 &&
    data.keyResults &&
    data.keyResults.length > 0 &&
    data.keyResults.forEach((item, index) => {
      let owners = [];

      if (item.dueDate) {
        item.dueDate = new Date(item.dueDate);
      }

      for (let y = 0; y < item.owners.length; y++) {
        let obj = {
          value: item.owners[y].owner,
          label: `${item.owners[y].ownerFirstName} ${item.owners[y].ownerLastName}`
        };

        owners.push(obj);
      }

      // Помещаем новый объект krOwners в KR, который и будем изменять
      item.krOwners = owners;
    });

  // Parent OKR options
  let okrOptions = [];
  let choosedParent = data && data.length !== 0 && data.isChild && data.parentOkr;

  {
    okrs &&
      okrs.length > 0 &&
      okrs.forEach((okr, index) => {
        let obj = {
          value: okr._id,
          label: okr.objective
        };

        okrOptions.push(obj);

        if (index === okrs.length - 1) {
          // First option is the company
          let noParent = { value: 4, label: 'No parent' };
          okrOptions.unshift(noParent);
        }
      });
  }

  const getPrivacy = (privacy) => {
    if (privacy === 1 || privacy === undefined) return privacyOptions[0];
    if (privacy === 2) return privacyOptions[1];
    if (privacy === 3) return privacyOptions[2];
  };

  useEffect(() => {
    getOkrById(match.params.id);
    getCompanyProfiles();
    getUserCompany();

    setFormData({
      ...formData,
      objective: !data ? '' : data.objective,
      team: !data ? '' : choosedTeam,
      owner: !data ? '' : choosedOwner,
      status: !data ? '' : choosedStatus,
      keyResults: !data ? [] : data.keyResults,
      dueIn: data && data.dueIn ? new Date(data.dueIn) : '',
      parentOkr: !data ? '' : choosedParent,
      privacy: !data ? privacyOptions[0] : getPrivacy(data.privacy)
    });
  }, [
    data.length !== 0 && choosedOwner.value && choosedOwner.value.length,
    match.params.id,
    getCompanyProfiles,
    getUserCompany,
    JSON.stringify(okrs)
  ]); // ВАЖНО! Loading должен быть из стейта с data, иначе лоадинг может загрузиться раньше и useEffect больше обновлять страницу не будует, тк обновление привязано к [loading...].
  // Тогда formData будет пустой и страница тоже. НО даже в этом случае loading может быстрей стать false и он не обновит formState с новой data.
  // Поэтому я сделал дополнительное обновление при изменении длины - choosedOwner.value.length.
  // Важно - мы привязываемся к тому, что всегд будет на странице (владелец) и к том, что может измениться только 1 раз.
  // Даже если выберут нового owner, то он не изменит значение choosedOwner.value, тк это значение по дефолту при загрузке из бд.
  // ВАЖНО 2! При добавлении getCompanyOkrsEdit долгое время был баг - бесконечный цикл.
  // ПРОБЛЕМА похожая на предыдущую. ПРИЧИНА была в работе редюсеров: я в GET_COMPANY_OKRS делал data: null, а в getOkrById делал data: res.data - и в результате получался бесконечный цикл, тк они переключали друг друга и хук эффекта постоянно работал

  useEffect(() => {
    getCompanyOkrs();
  }, [getCompanyOkrs]);

  const getChanges = (item) => {
    let newChanges = changes;

    // If we didn't save this update
    if (newChanges.indexOf(item) === -1) {
      newChanges.push(item);
    }

    return newChanges;
  };

  const getKrChanges = (
    item,
    type,
    index,
    milestoneIdx = 100,
    milestoneName = '',
    isMilestoneAdd = false
  ) => {
    let newChanges = changes;
    // console.log('===== type in getKrChanges', type)
    // console.log('===== item in getKrChanges', item)
    // console.log('===== index in getKrChanges', index)
    // console.log('===== 1st condition in getKrChanges', newChanges.indexOf(item) === -1 && type !== 'milestone' && milestoneName !== 'milestone')

    // We change owner to owners because we save many owners in db in the 'owners' array
    if (type === 'owner') {
      type = 'owners';
    }

    // If we didn't save this update
    if (newChanges.indexOf(item) === -1 && type !== 'milestone' && milestoneName !== 'milestone') {
      if (milestoneName === 'binary') {
        type = 'binary';
      } else if (type !== 'milestone') {
        // don't add it if it's binary or milestone
        newChanges.push(item);
      }
      newChanges.push(`kr-${type}-${index}`);
    } else {
      // if we've already had keyResults in the changes
      // check the name of changes then
      // we have 2 elements in changes array for 1 KR:
      // - keyResults - base
      // - kr-${type}-${index} - show index and type (owner, start, etc)
      if (type !== 'milestone') {
        // it's not milestone
        if (newChanges.indexOf(`kr-${type}-${index}`) === -1 || type === 'add' || type === 'remove') {
          // add this type of kr if we don't have it in the arr
          // we always save add and remove actions

          // We add it only if we didn't add the new KR before.
          // If we added KR before, then we don't save new title, start, goals
          if (newChanges.indexOf(`kr-add-${index}`) === -1) {
            // save new change
            newChanges.push(`kr-${type}-${index}`);
          }
        }
      } else {
        // milestone changes

        // We add it only if we didn't add the new Milestone before.
        // If we added Milestone before, then we don't save new title, start, goals
        if (
          newChanges.indexOf(`kr-milestone-${index}-${milestoneIdx}-change-${milestoneName}`) === -1 &&
          newChanges.indexOf(`kr-milestone-${index}-${milestoneIdx}-add`) === -1
        ) {
          let strMl = isMilestoneAdd ? 'add' : `change-${milestoneName}`;
          newChanges.push(`kr-${type}-${index}-${milestoneIdx}-${strMl}`);
        }
      }
    }
    // console.log('===== newChanges in getKrChanges AFTER', newChanges)

    return newChanges;
  };
  
  const goBack = () => {
    if (history !== undefined) {
      return history.goBack();
    } else {
      return '/objectives';
    }
  };

  const handleKeyResults = (selectedOption, index, type, detailsIdx = 0) => {
    let krType = type;
    let secondaryName = '';

    // console.log('====== krType in handleKeyResults', krType)  
    // console.log('====== index in handleKeyResults', index) 
    // console.log('====== detailsIdx in handleKeyResults', detailsIdx)

    if (type === 'krOwners') {
      keyResults[index].krOwners = selectedOption;
      keyResults[index].isNewOwner = true;
      krType = 'owner';
    }

    if (type === 'dueDate') {
      keyResults[index].dueDate = selectedOption;
    }

    if (type === 'krType') {
      keyResults[index].krType = selectedOption;

      if (selectedOption.value === 'binary') {
        keyResults[index].details = [{ value: 0, label: krLabels.binaryLabel.false }];
        keyResults[index].krCurrent = 0;
        secondaryName = 'binary';
      } else if (selectedOption.value === 'number') {
        keyResults[index].start = keyResults[index].start;
        keyResults[index].krCurrent = keyResults[index].current;
        keyResults[index].goal = keyResults[index].goal;
      } else if (selectedOption.value === 'milestone') {
        let data = { label: '', value: 0 };
        if (keyResults[index].details.length === 0) {
          keyResults[index].details.push(data);
        } else if (
          !keyResults[index].details ||
          (keyResults[index].details && keyResults[index].details.value > -1)
        ) {
          // Condition to check if we switch from Binary to Milestone
          keyResults[index].details = [];
          keyResults[index].details.push(data);
        }
        secondaryName = 'milestone';
      }
    }

    if (type === 'details' || type === 'binary') {
      keyResults[index].details = [selectedOption];
      keyResults[index].krCurrent = selectedOption.value;
    }

    if (type === 'milestone') {
      keyResults[index].details[detailsIdx][selectedOption.target.name] = selectedOption.target.value;
      secondaryName = selectedOption.target.name;

      // Save the goal
      if (selectedOption.target.name === 'value' && detailsIdx === keyResults[index].details.length - 1) {
        keyResults[index].goal = parseFloat(selectedOption.target.value);
      }
    }
    // console.log('====== secondaryName', secondaryName)

    let newChanges = getKrChanges('krResults', krType, index, detailsIdx, secondaryName);

    setFormData({ ...formData, keyResults: keyResults, changes: newChanges });
  };
  // console.log('==== keyResults', keyResults)

  const handleUniversalSelect = (selectedOption) => {
    let newChanges = getChanges('privacy');

    setFormData({ ...formData, [selectedOption.name]: selectedOption, changes: newChanges });
  };

  const handleSelect = (selectedOption, name) => {
    let newChanges = getChanges('parentOkr');

    setFormData({ ...formData, parentOkr: selectedOption, changes: newChanges });
  };

  const handleOwner = (selectedOption) => {
    let newChanges = getChanges('owner');

    setFormData({ ...formData, owner: selectedOption, changes: newChanges });
  };

  const handleTeam = (selectedOption) => {
    let newChanges = getChanges('team');

    setFormData({ ...formData, team: selectedOption, changes: newChanges });
  };

  const handleStatus = (selectedOption) => {
    let newChanges = getChanges('status');

    setFormData({ ...formData, status: selectedOption, changes: newChanges });
  };

  const handlgeDayChange = (day) => {
    let newChanges = getChanges('dueIn');

    setFormData({ ...formData, dueIn: day, changes: newChanges });
  };

  const onChange = (e) => {
    let newChanges = getChanges(e.target.name);

    setFormData({ ...formData, [e.target.name]: e.target.value, changes: newChanges });
  };

  const handleAdd = (e) => {
    e.preventDefault();

    let newChanges = getKrChanges('krResults', 'add', keyResults.length);

    setFormData({
      ...formData,
      changes: newChanges,
      keyResults: [
        ...keyResults,
        {
          krNew: true,
          start: '0',
          current: '0',
          goal: '100',
          dueDate: dueIn,
          krOwners: data.keyResults.length > 0 ? [data.keyResults[0].krOwners[0]] : [choosedOwner],
          krType: { label: krLabels.krTypeLabel.number, value: 'number' }
        }
      ]
    });
  };

  const handleAddMilestone = (e, index) => {
    e.preventDefault();

    let newChanges = getKrChanges(
      'krResults',
      'milestone',
      index,
      keyResults[index].details.length,
      '',
      true
    );

    // console.log('======= index', index)
    // console.log('======= keyResults[index]', keyResults[index])
    // console.log('======= keyResults[index].details', keyResults[index].details)
    // console.log('======= newChanges', newChanges)

    let data = { label: '', value: 0 };
    keyResults[index].details.push(data);

    setFormData({
      ...formData,
      changes: newChanges,
      keyResults
    });
  };

  const handleRemove = (index) => {
    const approve = window.confirm('Are you sure you wish to delete this KR? Data will be lost forever.');

    if (approve) {
      keyResults.splice(index, 1);
      let newChanges = getKrChanges('krResults', 'remove', index);
      setFormData({ ...formData, keyResults: keyResults, changes: newChanges });
    }
  };

  const handleDisplayTips = () => toggleDisplayTips((prev) => !prev);

  const onChangeKeyResults = (e, index) => {
    let newChanges = getKrChanges('krResults', e.target.name, index);

    if (e.target.name === 'title') {
      keyResults[index].title = e.target.value;
      setFormData({ ...formData, keyResults: keyResults, changes: newChanges });
    }

    if (e.target.name === 'start') {
      keyResults[index].start = e.target.value;
      keyResults[index].isChanged = true;
      setFormData({ ...formData, keyResults: keyResults, changes: newChanges });
    }

    if (e.target.name === 'goal') {
      keyResults[index].goal = e.target.value;
      keyResults[index].isChanged = true;
      setFormData({ ...formData, keyResults: keyResults, changes: newChanges });
    }

    if (e.target.name === 'current') {
      keyResults[index].current = e.target.value;
      keyResults[index].isChanged = true;
      setFormData({ ...formData, keyResults: keyResults, changes: newChanges });
    }

    if (e.target.name === 'owner') {
      keyResults[index].owner = e.target.value;
      setFormData({ ...formData, keyResults: keyResults, changes: newChanges });
    }
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      // Show Loader
      toggleLoader(true);

      if (parentOkr && match.params.id === parentOkr.value) {
        let errMsg = `OKR can't be a parent for itslef. Please change the parent OKR.`;

        if (locale.lang === 'ru') {
          errMsg = `OKR не может быть родителем для самого себя. Пожалуйста, поменяйте родительский OKR.`;
        }

        toggleLoader(false);
        return setAlert(errMsg, 'danger', 15000);
      }

      if (keyResults.length > 0) {
        for (let i = 0; i < keyResults.length; i++) {
          let kr = keyResults[i];

          if (kr.start === null || kr.goal === null || kr.current === null || kr.start.length === 0 || kr.current.length === 0 || kr.goal.length === 0) {
            let t = 'Error in the key result: ';
            let t2 = `The key result doesn't have start, current or goal metric. Please add the number to the metric.`;

            if (locale.lang === 'ru') {
              t = 'Ошибка в ключевом результате: ';
              t2 =
                'В данном ключевом результате нет значения Начало или Цель. Пожалуйста, добавьте это значение и сохраните цель.';
            }

            let errMsg = `${t} ${kr.title}. ${t2}`;

            // Hide Loader
            toggleLoader(isLoader);

            return setAlert(errMsg, 'danger', 15000);
          }
          if (
            !Number.isInteger(Number(kr.start)) ||
            !Number.isInteger(Number(kr.current)) ||
            !Number.isInteger(Number(kr.goal))
          ) {
            let t = 'Error in the key result: ';
            let t2 = `The key result metric values must be integer. Special characters (%,$,k) aren't allowed.`;

            if (locale.lang === 'ru') {
              t = 'Ошибка в ключевом результате: ';
              t2 =
                'Значения в ключевом результате должны быть целочисленными. Специфичные символы (%,$,к) не допускаются.';
            }

            let errMsg = `${t} ${kr.title || kr.krTitle}. ${t2}`;
            // Hide Loader
            toggleLoader(isLoader);

            return setAlert(errMsg, 'danger', 15000);
          }

          if (kr.start.toString() === kr.goal.toString()) {
            let t = 'Error in the key result: ';
            let t2 =
              'Your start value equals the goal value. Start value is the initial metric. Goal value is the outcome you want to achieve. The start value should be less than a goal. For example, the start is 0, and the goal is 100. Please make the start value less than the goal and then save OKR.';

            if (locale.lang === 'ru') {
              t = 'Ошибка в ключевом результате: ';
              t2 =
                'Значение Начало не может быть равно значению Цели. Начало - это ваша первоначальная метрика сейчас. Цель - это то, что вы хотите достичь. Начало должно быть меньше Цели. Например, начало = 0 и цель = 100. Пожалуйста, сделайте Начало меньше Цели и снова сохраните OKR.';
            }

            let errMsg = `${t} ${kr.title}. ${t2}`;

            // Hide Loader
            toggleLoader(isLoader);

            return setAlert(errMsg, 'danger', 15000);
          }

          if (!kr.title || kr.title.length === 0) {

            let t = 'Error in the key result: ';
            let t2 =
              'One of key results doesn`t have a title. Please add the title and save the OKR.';

            if (locale.lang === 'ru') {
              t = 'Ошибка в ключевом результате: ';
              t2 =
                'Один из ключевых результатов не имеет названия. Пожалуйста, добавьте название и сохраните OKR.';
            }

            let errMsg = `${t}. ${t2}`;

            // Hide Loader
            toggleLoader(false);

            return setAlert(errMsg, 'danger', 10000);
          } else if (kr.krType && kr.krType.value === 'milestone') {

            for (let y = 0; y < kr.details.length; y++) {
              let ml = kr.details[y];

              if (!ml.label || ml.label.length === 0) {

                let t = 'Error in the key result: ';
                let t2 =
                  'One of the milestones of the key result doesn`t have a title. Please add the title to the milestone and save the OKR.';
    
                if (locale.lang === 'ru') {
                  t = 'Ошибка в ключевом результате: ';
                  t2 =
                    'Один из майлстоунов ключевого результата не имеет названия. Пожалуйста, добавьте название и значение, и сохраните OKR.';
                }
    
                let errMsg = `${t} ${kr.title}. ${t2}`;
    
                // Hide Loader
                toggleLoader(false);
    
                return setAlert(errMsg, 'danger', 10000);
              } else if (ml.value === '') {

                let t = 'Error in the key result: ';
                let t2 =
                  'One of the milestones of the key result doesn`t have a value. Please add the metric to the milestone and save the OKR.';
    
                if (locale.lang === 'ru') {
                  t = 'Ошибка в ключевом результате: ';
                  t2 =
                    'Один из майлстоунов ключевого результата не имеет значения. Пожалуйста, добавьте значение майлстоуна, и сохраните OKR.';
                }
    
                let errMsg = `${t} ${kr.title}. ${t2}`;
    
                // Hide Loader
                toggleLoader(false);
    
                return setAlert(errMsg, 'danger', 15000);
              }
            }
          }
        }
      }

      let res = await editOkr(match.params.id, formData, history);

      // If error
      if (res && res.errors && res.errors.length > 0) {
        return toggleLoader(false);
      }

      // Hide Loader
      toggleLoader(false);
    } catch (err) {
      console.log('=== Error in Edit OKR', err);
      // Hide Loader
      toggleLoader(false);
      setAlert(err.message, 'danger', 10000);
    }
  };
  // console.log('==== formData', formData)
  // console.log('==== keyResults', keyResults)

  const formTitle = <FormattedMessage id="user.okr.edit.head" defaultMessage="Edit the Objective" />;

  let publicPrivacyTxt = <FormattedMessage id="publicPrivacyTxt" defaultMessage="Everyone can see an OKR" />;
  let teamPrivacyTxt = (
    <FormattedMessage
      id="teamPrivacyTxt"
      defaultMessage="Only your team, owners of key results, and admin can see an OKR"
    />
  );
  let privatePrivacyTxt = (
    <FormattedMessage
      id="privatePrivacyTxt"
      defaultMessage="Only you, owners of key results, and admin can see an OKR"
    />
  );
  let btnTxt = <FormattedMessage id="standup.form.submit" defaultMessage="Submit" />;
  let cnclBtnTxt = <FormattedMessage id="cancel" defaultMessage="Cancel" />;
  let changesTxt = (
    <FormattedMessage
      id="changes.okr"
      defaultMessage="All changes of the OKR are saved and displayed in the OKR details"
    />
  );

  // Loader for button
  let loadingIcon = (
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Loader type="Circles" color="#ffffff" height="14px" width="15" />
    </span>
  );

  if (isLoader) {
    btnTxt = loadingIcon;
  }

  let removeKRCopy = 'Are you sure you wish to delete this Key Result? Data will be lost forever.'

  if (locale && locale.lang === 'ru') {
    removeKRCopy = 'Вы уверены, что хотите удалить этот Ключевой Результат? Все данные будут безвозвратно потеряны.';
  }


  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">
          <div className="hide-1600">
            {user && user.department ? (
              <Menu
                companyName={user && user.companyName}
                team={user.departmentName}
                teamId={user.department}
                settings={user.isAdmin && true}
                focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true}
                activeIndex={4}
              />
            ) : (
              <Menu
                companyName={user && user.companyName}
                settings={user && user.isAdmin && true}
                focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true}
                activeIndex={4}
              />
            )}
          </div>
          <UserNav />

          {isEditLoading || !data || !company || !profiles || data.length === 0 ? (
            <Spinner />
          ) : (
            <Fragment>
              <div className="main pl-0 mxa pt-5 pt-0-xs">
                <div className="okr mt-3">
                  <div className="component my-2 p-3 light-shadow">
                    <FormTitle title={formTitle} backUrl={'/objectives'} history={history} />

                    <div className="form-full">
                      <form className="form-app form-app-okr py-2" onSubmit={(e) => onSubmit(e)}>
                        {!profiles ? (
                          <Spinner />
                        ) : (
                          <Fragment>
                            <div className="form-main form-main-inline flex-column-xs">
                              <div className="w-2-3 mb-3-xs labeled-wrapper">
                                <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                  <FormattedMessage
                                    id="user.okr.create.objective"
                                    defaultMessage="The objective"
                                  />
                                </label>
                                <FormattedMessage
                                  id="user.okr.create.objectiveInput"
                                  defaultMessage="ex Achieve record revenue"
                                >
                                  {(msg) => (
                                    <input
                                      className="w-100 input-text fs-18"
                                      type="text"
                                      placeholder={msg}
                                      name="objective"
                                      value={objective || ''}
                                      onChange={(e) => onChange(e)}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>
                              <div className="w-1-3 pl-2 labeled-wrapper">
                                <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                  <FormattedMessage id="user.okr.create.owner" defaultMessage="Owner" />
                                </label>
                                <FormattedMessage
                                  id="user.okr.create.ownerSelect"
                                  defaultMessage="Choose the owner of the Objective"
                                >
                                  {(msg) => (
                                    <Select
                                      className="w-100"
                                      defaultValue={choosedOwner}
                                      value={owner}
                                      onChange={(selectedOption) => handleOwner(selectedOption)}
                                      options={ownerOptions}
                                      isSearchable
                                      placeholder={msg}
                                      required
                                      styles={selectCustomStyles}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>
                            </div>

                            <div className="form-main form-main-inline standups-time mt-2 flex-column-xs">
                              <div className="w-1-3  labeled-wrapper">
                                <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                  <FormattedMessage id="user.okr.create.team" defaultMessage="Team" />
                                </label>
                                <FormattedMessage
                                  id="user.okr.create.teamSelect"
                                  defaultMessage="Choose the team"
                                >
                                  {(msg) => (
                                    <Select
                                      className="w-100"
                                      defaultValue={choosedTeam}
                                      value={team}
                                      onChange={(selectedOption) => handleTeam(selectedOption)}
                                      options={teamOptions}
                                      isSearchable
                                      placeholder={msg}
                                      required
                                      name="team"
                                      styles={selectCustomStyles}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>
                              <div className="w-1-3 pl-2 labeled-wrapper">
                                <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                  <FormattedMessage
                                    id="user.okr.create.currentstatus"
                                    defaultMessage="Current Status"
                                  />
                                </label>
                                <FormattedMessage id="user.okr.create.status" defaultMessage="Choose Status">
                                  {(msg) => (
                                    <Select
                                      className="w-100"
                                      value={status}
                                      onChange={(selectedOption) => handleStatus(selectedOption)}
                                      options={statusOptions}
                                      placeholder={msg}
                                      required
                                      name="status"
                                      styles={colourStyles}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>
                              <div className="w-1-3 pl-2 labeled-wrapper">
                                <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                  <FormattedMessage id="user.okr.create.dueIn" defaultMessage="Due date" />
                                </label>
                                <DatePickerComponent
                                  value={dueIn}
                                  onSelect={(day) => handlgeDayChange(day)}
                                />
                              </div>
                            </div>

                            <div className="pt-3 pb-1 flex space-between">
                              <p className="bold fs-14 no-w label-pr3 pb-0-xs">
                                <FormattedMessage id="user.okr.kr.title" defaultMessage="Key results" />
                              </p>
                              <div className="green pointer" onClick={handleDisplayTips}>
                                <FormattedMessage
                                  id="okr.tips.title"
                                  defaultMessage={`Tips on how to set OKRs`}
                                />
                              </div>
                            </div>

                            {keyResults &&
                              keyResults.map((keyResult, index) => (
                                <div
                                  key={`keyResult-${index}`}
                                  className="card-title-bordered card-title-bordered-green  p-2 column mb-2"
                                >
                                  <div className="okr__kr okr__kr-top w-100 flex pb-2 align-start relative">
                                    <div className="okr__kr-name  labeled-wrapper w-100 ">
                                      <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                        <FormattedMessage
                                          id="user.okr.create.keyResult.title"
                                          defaultMessage="Measurable key result"
                                        />
                                      </label>
                                      <FormattedMessage
                                        id="user.okr.create.objectiveInput"
                                        defaultMessage="Enter your awesome key result"
                                      >
                                        {(msg) => (
                                          <input
                                            className="w-100 input-text fs-18"
                                            type="text"
                                            placeholder={msg}
                                            name="title"
                                            value={keyResult.title || ''}
                                            onChange={(e) => onChangeKeyResults(e, index)}
                                          />
                                        )}
                                      </FormattedMessage>
                                    </div>

                                    <KRmetrics
                                      keyResult={keyResult}
                                      index={index}
                                      onChangeKeyResults={onChangeKeyResults}
                                      locale={locale && locale.lang}
                                      selectCustomStyles={selectCustomStyles}
                                      handleKeyResults={handleKeyResults}
                                      krLabels={krLabels}
                                      krName="start"
                                      handleAddMilestone={handleAddMilestone}
                                    />

                                    <div 
                                      className="absolute-xs right-10-xs"
                                      onClick={() => handleRemove(index)}
                                    >
                                      {icons.close__new}
                                    </div>
                                  </div>

                                  <div className="okr__kr okr__kr-top w-100 flex  pb-2  align-start">
                                    <div className="okr__kr labeled-wrapper w-50">
                                      <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                        <FormattedMessage
                                          id="user.okr.create.keyResult.owner"
                                          defaultMessage="Owner"
                                        />
                                      </label>
                                      <FormattedMessage
                                        id="user.okr.create.keyResult.ownerSelect"
                                        defaultMessage="Choose the owner of this key result"
                                      >
                                        {(msg) => (
                                          <Select
                                            className="w-100"
                                            defaultValue={keyResult.krOwners}
                                            value={keyResult.krOwners}
                                            name="krOwners"
                                            onChange={(selectedOption) =>
                                              handleKeyResults(selectedOption, index, 'krOwners')
                                            }
                                            options={ownerOptions}
                                            isSearchable
                                            placeholder={msg}
                                            required
                                            isMulti
                                            styles={selectCustomStyles}
                                          />
                                        )}
                                      </FormattedMessage>
                                    </div>
                                    <div className="okr__kr labeled-wrapper w-30 ml-2">
                                      <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                        <FormattedMessage
                                          id="user.okr.create.krDueIn"
                                          defaultMessage="Due date"
                                        />
                                      </label>
                                       <DatePickerComponent
                                         value={keyResult.dueDate}
                                         onSelect={(selectedOption) =>
                                          handleKeyResults(selectedOption, index, 'dueDate')
                                        }
                                       />
                                    </div>

                                    <div className="okr__kr labeled-wrapper w-200px ml-2">
                                      <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                        <FormattedMessage id="kr.type" defaultMessage="Key result type" />
                                      </label>
                                      <FormattedMessage id="choose.type" defaultMessage="Choose the type">
                                        {(msg) => (
                                          <Select
                                            className="w-100"
                                            defaultValue={krTypeOptions[0]}
                                            value={keyResult.krType}
                                            name="krType"
                                            onChange={(selectedOption) =>
                                              handleKeyResults(selectedOption, index, 'krType')
                                            }
                                            options={krTypeOptions}
                                            isSearchable
                                            placeholder={msg}
                                            required
                                            styles={selectCustomStyles}
                                          />
                                        )}
                                      </FormattedMessage>
                                    </div>
                                  </div>
                                </div>
                              ))}

                            <button
                              type="button"
                              className="btn-secondary-back-upd fw-600  flex align-center mb-2 px-1 black"
                              onClick={(e) => handleAdd(e)}
                            >
                              <span className="rounded-text-icon">{icons.circle_plus}</span>{' '}
                              <FormattedMessage
                                id="user.okr.create.keyResult.addBtn"
                                defaultMessage="Add Key Result"
                              />
                            </button>

                            <div className="form-main form-main-inline form-main-inline-end standups-time flex-column-xs">
                              <div className="w-2-3 mb-3-xs labeled-wrapper">
                                <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                  <FormattedMessage
                                    id="user.okr.create.parent"
                                    defaultMessage="Parent Objective"
                                  />{' '}
                                  (
                                  <FormattedMessage id="user.okr.create.optional" defaultMessage="Optional" />
                                  )
                                </label>
                                <FormattedMessage
                                  id="user.okr.create.parentInput"
                                  defaultMessage="Choose the parent objective"
                                >
                                  {(msg) => (
                                    <Select
                                      className="w-100"
                                      defaultValue={choosedParent}
                                      value={parentOkr}
                                      onChange={(selectedOption) => handleSelect(selectedOption, 'parentOkr')}
                                      options={okrOptions}
                                      isSearchable
                                      placeholder={msg}
                                      required
                                      name="parentOkr"
                                      styles={selectCustomStyles}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>
                              <div className="w-1-3 mb-3-xs pl-2 labeled-wrapper">
                                <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                                  <FormattedMessage id="privacy" defaultMessage="Privacy" /> (
                                  {privacy.value === 1 && <span>{publicPrivacyTxt}</span>}
                                  {privacy.value === 2 && <span>{teamPrivacyTxt}</span>}
                                  {privacy.value === 3 && <span>{privatePrivacyTxt}</span>})
                                </label>
                                <FormattedMessage id="choose.okr.privacy" defaultMessage="Choose OKR privacy">
                                  {(msg) => (
                                    <Select
                                      className="w-100"
                                      defaultValue={privacyOptions[0]}
                                      value={privacy}
                                      onChange={(selectedOption) => handleUniversalSelect(selectedOption)}
                                      options={privacyOptions}
                                      isSearchable
                                      placeholder={msg}
                                      required
                                      name="privacy"
                                      styles={selectCustomStyles}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>
                            </div>
                          </Fragment>
                        )}

                        <OkrTips locale={locale} displayTips={displayTips} />

                        <p className="small grey pb-2">{changesTxt}</p>

                        <div className="flex">
                          <button
                            type="submit"
                            className=" mw-150 fs-18  btn btn-small  btn-submit btn__secondary_green   lh-54   block ml-0-xs  "
                            disabled={!isLoader ? false : true}
                            // onClick={onSubmit}
                          >
                            {btnTxt}
                          </button>
                          <button
                            type="reset"
                            className="mw-150 ml-2 btn btn-small  fs-18  btn-secondary-back-upd "
                            onClick={goBack}
                            disabled={!isLoader ? false : true}
                          >
                            {cnclBtnTxt}
                          </button>
                        </div>

                        {/* <button type="submit" className="mw-150 btn btn-submit block ml-0-xs" disabled={!isLoader ? false : true}>
                          {btnTxt}
                        </button> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

EditOkr.propTypes = {
  setAlert: PropTypes.func.isRequired,
  clearOkrs: PropTypes.func.isRequired,
  editOkr: PropTypes.func.isRequired,
  getOkrById: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getCompanySortedOkrs: PropTypes.func.isRequired,
  getCompanyOkrs: PropTypes.func.isRequired,
  getCompanyOkrsEdit: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  company: state.company,
  locale: state.locale,
  okr: state.okr
});

export default connect(mapStateToProps, {
  cleanData,
  getOkrById,
  getCompanyProfiles,
  getUserCompany,
  editOkr,
  getCompanyOkrsEdit,
  clearOkrs,
  getCompanySortedOkrs,
  setAlert,
  getCompanyOkrs
})(withRouter(EditOkr));
