import {
  ADD_OKR_CALL,
  DELETE_OKR_CALL,
  GET_OKR_CALL,
  OKR_CALLS_ERROR,
  CLEAR_OKR_CALLS,
  SWITCH_OKR_CALL_STATUS,
  GET_ALL_OKR_CALLS,
} from '../actions/types';

const initialState = {
  okrCalls: [],
  okrCall: null,
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  
  switch (type) {
    case GET_OKR_CALL:
      return {
        ...state,
        okrCall: payload,
        loading: false
      };
    case GET_ALL_OKR_CALLS:
      return {
        ...state,
        okrCalls: payload,
        loading: false
      };
    case ADD_OKR_CALL:
      return {
        ...state,
        okrCalls: [payload, ...state.okrCalls],
        loading: false
      };
    case SWITCH_OKR_CALL_STATUS:
      return {
        ...state,
        okrCalls: state.okrCalls.map(item =>
          item._id === payload.id
            ? {
                ...item,
                active: payload.active
              }
            : item
        ),
        loading: false
      };
    case DELETE_OKR_CALL:
      return {
        ...state,
        okrCalls: state.okrCalls.filter(okrCall => okrCall._id !== payload),
        loading: false
      };
    case OKR_CALLS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case CLEAR_OKR_CALLS:
      return {
        ...state,
        okrCalls: [],
        okrCall: null,
        loading: true
      };
    default:
      return state;
  }
}
