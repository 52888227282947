import React, { Fragment } from "react";
import PropTypes from "prop-types";

const Subtitle = ({title}) => {
  
  return (
    <p className="fs-14 lh-20 text-left fw-600">
      {title}
    </p> 
  );
};

Subtitle.propTypes = {
  title: PropTypes.object,
};

export default Subtitle;
