import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Notification = ({ color, msg, msg2, url, btnColor, cta, externalLink, onClick = () => {} }) => {
  const bgClass = color && `notification p-3-l br-12 mt-3 mb-5 shadow bg-${color} text-center`;

  return (
    <div className={bgClass}>
      <p className="white large text-center w-80 mxa py-2">
        {msg}{' '}
        {msg2 && (
          <Fragment>
            <br />
            <br /> {msg2}
          </Fragment>
        )}
      </p>
      {cta &&
        (externalLink !== 1 ? (
          <Link
            to={url && url}
            onClick={onClick}
            className={
              btnColor
                ? `btn-secondary btn-secondary-mix-white btn-secondary-mix-${btnColor} ${btnColor} shadow my-1`
                : 'btn-secondary btn-secondary-mix-white shadow my-1 btn-invite-track'
            }
          >
            {cta}
          </Link>
        ) : (
          <a
            href={url && url}
            className={
              btnColor
                ? `btn-secondary btn-secondary-mix-white btn-secondary-mix-${btnColor} ${btnColor} shadow my-1`
                : 'btn-secondary btn-secondary-mix-white shadow my-1 btn-invite-track'
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            {cta}
          </a>
        ))}
    </div>
  );
};

Notification.propTypes = {
  msg: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  cta: PropTypes.object,
  btnColor: PropTypes.string,
  msg2: PropTypes.object,
  url: PropTypes.string,
  externalLink: PropTypes.number,
  onClick: PropTypes.func
};

export default Notification;
