import React from 'react';
import { FormattedMessage } from 'react-intl';

const TableTitle = () => {
  return (
    <thead>
      <tr>
        <th className="small text-left fs-12 black bold opacity-04 pb-2">
          <FormattedMessage id="company.teams.table.teams" defaultMessage="Team" />
        </th>
        <th className="small text-left fs-12 black bold opacity-04 pb-2">
          <FormattedMessage id="company.teams.table.head" defaultMessage="Team Lead" />
        </th>
        <th className="small text-right fs-12 black bold opacity-04 pb-2">
          <FormattedMessage id="company.teams.table.employees" defaultMessage="Team Members" />
        </th>
      </tr>
    </thead>
  );
};

export default TableTitle;
