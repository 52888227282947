export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const VALIDATE_EMAIL = 'VALIDATE_EMAIL';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const RESEND_SUCCESS = 'RESEND_SUCCESS';
export const RESEND_ERROR = 'RESEND_ERROR';
export const RESEND_NOTOKEN = 'RESEND_NOTOKEN';
export const CONFIRM_SUCCESS = 'CONFIRM_SUCCESS';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_ERROR = 'RESET_ERROR';
export const RESET_NOTOKEN = 'RESET_NOTOKEN';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_COMPANY_ERROR = 'DELETE_COMPANY_ERROR';

export const LOCATION_CHANGE_ERROR = 'LOCATION_CHANGE_ERROR';
export const LOCATION_CHANGE_ERROR_UPDATE = 'LOCATION_CHANGE_ERROR_UPDATE';

// Profiles
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const CLEAR_PROFILES = 'CLEAR_PROFILES';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_TEAM_PROFILES = 'GET_TEAM_PROFILES';
export const TEAM_PROFILES_ERROR = 'TEAM_PROFILES_ERROR';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const GET_COMPANY_PROFILES = 'GET_COMPANY_PROFILES';
export const COMPANY_PROFILES_ERROR = 'COMPANY_PROFILES_ERROR';
export const UPDATE_COMPANY_MEMBER_PROFILE = 'UPDATE_COMPANY_MEMBER_PROFILE';
export const UPDATE_COMPANY_MEMBER_PROFILE_ERROR = 'UPDATE_COMPANY_MEMBER_PROFILE_ERROR';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_ALL_NOTIFICATIONS = 'UPDATE_ALL_NOTIFICATIONS';
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';
export const NOTIFICATIONS_ERROR = 'NOTIFICATIONS_ERROR';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const UPDATE_AVATAR_AUTH = 'UPDATE_AVATAR_AUTH';
export const AVATAR_ERROR = 'AVATAR_ERROR';
export const ASSIGN_TEAM_HEAD = 'ASSIGN_TEAM_HEAD';
export const ASSIGN_TEAM_HEAD_ERROR = 'ASSIGN_TEAM_HEAD_ERROR';

// Companies
export const GET_COMPANY = 'GET_COMPANY';
export const COMPANY_ERROR = 'COMPANY_ERROR';
export const GET_TEAM = 'GET_TEAM';
export const TEAM_ERROR = 'TEAM_ERROR';
export const GET_TEAMS = 'GET_TEAMS';
export const TEAMS_ERROR = 'TEAMS_ERROR';
export const CLEAR_COMPANY = 'CLEAR_COMPANY';
export const CLEAR_TEAM = 'CLEAR_TEAM';
export const UPDATE_PLAN = 'UPDATE_PLAN';
export const UPDATE_PLAN_ERROR = 'UPDATE_PLAN_ERROR';
export const INVOICE_ERROR = 'INVOICE_ERROR';
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const SUBSCRIPTION_ERROR = 'SUBSCRIPTION_ERROR';

// Password & Auth
export const GET_PASSWORD = 'GET_PASSWORD';
export const PASSWORD_ERROR = 'PASSWORD_ERROR';
export const UPDATE_AUTH = 'UPDATE_AUTH';

// Standups
export const GET_STANDUPS = 'GET_STANDUPS';
export const GET_STANDUP = 'GET_STANDUP';
export const UPDATE_STANDUP = 'UPDATE_STANDUP';
export const STANDUPS_ERROR = 'STANDUPS_ERROR';
export const CLEAR_STANDUPS = 'CLEAR_STANDUPS';
export const GET_SETTINGS = 'GET_SETTINGS';
export const SETTINGS_ERROR = 'SETTINGS_ERROR';
export const UPDATE_STANDUP_LIKES = 'UPDATE_STANDUP_LIKES';

// Standup Answers
export const GET_STANDUP_ANSWERS = 'GET_STANDUP_ANSWERS';
export const GET_USER_STANDUP_ANSWERS = 'GET_USER_STANDUP_ANSWERS';
export const GET_PROFILE_STANDUP_ANSWERS = 'GET_PROFILE_STANDUP_ANSWERS';
export const GET_TEAM_STANDUP_ANSWERS = 'GET_TEAM_STANDUP_ANSWERS';
export const GET_COMPANY_STANDUP_ANSWERS = 'GET_COMPANY_STANDUP_ANSWERS';
export const STANDUP_ANSWER_ERROR = 'STANDUP_ANSWER_ERROR';
export const STANDUP_ANSWERS_ERROR = 'STANDUP_ANSWER_ERROR';
export const COMPANY_STANDUP_ANSWER_ERROR = 'COMPANY_STANDUP_ANSWER_ERROR';
export const ADD_STANDUP_ANSWER = 'ADD_STANDUP_ANSWER';
export const EDIT_STANDUP_ANSWER = 'EDIT_STANDUP_ANSWER';
export const GET_STANDUP_ANSWER = 'GET_STANDUP_ANSWER';
export const CLEAR_STANDUP_SETTINGS = 'CLEAR_STANDUP_SETTINGS';
export const GET_SETTINGS_DASHBOARD = 'GET_SETTINGS_DASHBOARD';
export const GET_STANDUP_ANSWER_BY_ID = 'GET_STANDUP_ANSWER_BY_ID';

export const UPDATE_LIKES = 'UPDATE_LIKES';
export const ADD_COMMENT = 'ADD_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const CLEAR_STANDUP_ANSWERS = 'CLEAR_STANDUP_ANSWERS';
export const GET_COMPANY_STANDUP_ANSWERS_SETTINGS = 'GET_COMPANY_STANDUP_ANSWERS_SETTINGS';

// Admin
export const ADD_TEAM = 'ADD_TEAM';
export const ADD_TEAM_ERROR = 'ADD_TEAM_ERROR';
export const DELETE_TEAM = 'DELETE_TEAM';
export const DELETE_TEAM_ERROR = 'DELETE_TEAM_ERROR';
export const EDIT_TEAM = 'EDIT_TEAM';
export const EDIT_TEAM_ERROR = 'EDIT_TEAM_ERROR';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const USERS_ERROR = 'USERS_ERROR';
export const GET_ALL_OKRS = 'GET_ALL_OKRS';
export const GET_ALL_STANDUPS = 'GET_ALL_STANDUPS';
export const GET_ALL_UPDATES = 'GET_ALL_UPDATES';
export const CLEAR_ADATA = 'CLEAR_ADATA';
export const GET_COMPANIES = 'GET_COMPANIES';
export const COMPANIES_ERROR = 'COMPANIES_ERROR';
export const LOGIN_AS_USER_SUCCESS = 'LOGIN_AS_USER_SUCCESS';
export const LOGIN_AS_USER_ERROR = 'LOGIN_AS_USER_ERROR';
export const LOGOUT_AS_USER_SUCCESS = 'LOGOUT_AS_USER_SUCCESS';
export const LOGOUT_AS_USER_ERROR = 'LOGOUT_AS_USER_ERROR';
export const GET_ALL_PLANS = 'GET_ALL_PLANS';
export const GET_ALL_ICEBREAKERS = 'GET_ALL_ICEBREAKERS';
export const GET_ALL_TASKS = 'GET_ALL_TASKS';
export const GET_ALL_ONES = 'GET_ALL_ONES';
export const GET_ALL_LOGS = 'GET_ALL_LOGS';
export const LOGS_ERROR = 'LOGS_ERROR';

// Referral
export const SET_LS = 'SET_LS';
export const REFERRAL_SIGNUP_SUCCESS = 'REFERRAL_SIGNUP_SUCCESS';
export const REFERRAL_SIGNUP_FAIL = 'REFERRAL_SIGNUP_FAIL';
export const GET_REFERRAL = 'GET_REFERRAL';
export const REFERRAL_ERROR = 'REFERRAL_ERROR';
export const ERROR_LS = 'ERROR_LS';

// Language
export const SET_LOCALE = 'SET_LOCALE';

// Weekly Updates
export const GET_UPDATES = 'GET_UPDATES';
export const GET_UPDATE = 'GET_UPDATE';
export const UPDATES_ERROR = 'UPDATES_ERROR';
export const CLEAR_UPDATES = 'CLEAR_UPDATES';
export const GET_UPDATES_SETTINGS = 'GET_UPDATES_SETTINGS';
export const UPDATES_SETTINGS_ERROR = 'UPDATES_SETTINGS_ERROR';
export const CHANGE_CAMPAIGN_TYPE = 'CHANGE_CAMPAIGN_TYPE';

// Weekly Updates Answers
export const GET_UPDATE_ANSWERS = 'GET_UPDATE_ANSWERS';
export const GET_USER_UPDATE_ANSWERS = 'GET_USER_UPDATE_ANSWERS';
export const GET_TEAM_UPDATE_ANSWERS = 'GET_TEAM_UPDATE_ANSWERS';
export const GET_UPDATE_ANSWER = 'GET_UPDATE_ANSWER';
export const GET_COMPANY_UPDATE_ANSWERS = 'GET_COMPANY_UPDATE_ANSWERS';
export const UPDATE_ANSWER_ERROR = 'UPDATE_ANSWER_ERROR';
export const UPDATE_ANSWERS_ERROR = 'UPDATE_ANSWER_ERROR';
export const COMPANY_UPDATE_ANSWER_ERROR = 'COMPANY_UPDATE_ANSWER_ERROR';
export const ADD_UPDATE_ANSWER = 'ADD_UPDATE_ANSWER';
export const EDIT_UPDATE_ANSWER = 'EDIT_UPDATE_ANSWER';
export const CLEAR_UPDATE_ANSWERS = 'CLEAR_UPDATE_ANSWERS';
export const GET_PROFILE_UPDATE_ANSWERS = 'GET_PROFILE_UPDATE_ANSWERS';
export const GET_UPDATE_ANSWER_BY_ID='GET_UPDATE_ANSWER_BY_ID';

// Recognitions
export const GET_USER_RECOGNITIONS = 'GET_USER_RECOGNITIONS';
export const GET_GIVED_USER_RECOGNITIONS = 'GET_GIVED_USER_RECOGNITIONS';
export const GET_TEAM_RECOGNITIONS = 'GET_TEAM_RECOGNITIONS';
export const GET_GIVED_TEAM_RECOGNITIONS = 'GET_GIVED_TEAM_RECOGNITIONS';
export const GET_RECOGNITIONS = 'GET_RECOGNITIONS';
export const RECOGNITION_ERROR = 'RECOGNITION_ERROR';
export const RECOGNITIONS_ERROR = 'RECOGNITIONS_ERROR';
export const GIVED_USER_RECOGNITIONS_ERROR = 'GIVED_USER_RECOGNITIONS_ERROR';
export const GIVED_TEAM_RECOGNITIONS_ERROR = 'GIVED_TEAM_RECOGNITIONS_ERROR';
export const GIVED_COMPANY_RECOGNITIONS_ERROR = 'GIVED_COMPANY_RECOGNITIONS_ERROR';
export const ADD_RECOGNITION = 'ADD_RECOGNITION';
export const GET_RECOGNITION = 'GET_RECOGNITION';
export const UPDATE_RECOGNITION = 'UPDATE_RECOGNITION';
export const DELETE_RECOGNITION = 'DELETE_RECOGNITION';
export const CLEAR_RECOGNITIONS = 'CLEAR_RECOGNITIONS';
export const GET_COMPANY_RECOGNITIONS = 'GET_COMPANY_RECOGNITIONS';
export const GET_GIVED_COMPANY_RECOGNITIONS = 'GET_GIVED_COMPANY_RECOGNITIONS';
export const COMPANY_RECOGNITIONS_ERROR = 'COMPANY_RECOGNITIONS_ERROR';
export const TEAM_RECOGNITIONS_ERROR = 'TEAM_RECOGNITIONS_ERROR';

// Feedback
export const GET_USER_FEEDBACK = 'GET_USER_FEEDBACK';
export const GET_GIVED_USER_FEEDBACK = 'GET_GIVED_USER_FEEDBACK';
export const GET_TEAM_FEEDBACK = 'GET_TEAM_FEEDBACK';
export const GET_GIVED_TEAM_FEEDBACK = 'GET_GIVED_TEAM_FEEDBACK';
export const GET_FEEDBACK = 'GET_FEEDBACK';
export const FEEDBACK_ITEM_ERROR = 'FEEDBACK_ITEM_ERROR';
export const FEEDBACK_ERROR = 'FEEDBACK_ERROR';
export const GIVED_USER_FEEDBACK_ERROR = 'GIVED_USER_FEEDBACK_ERROR';
export const GIVED_TEAM_FEEDBACK_ERROR = 'GIVED_TEAM_FEEDBACK_ERROR';
export const GIVED_COMPANY_FEEDBACK_ERROR = 'GIVED_COMPANY_FEEDBACK_ERROR';
export const ADD_FEEDBACK_ITEM = 'ADD_FEEDBACK_ITEM';
export const GET_FEEDBACK_ITEM = 'GET_FEEDBACK_ITEM';
export const UPDATE_FEEDBACK_ITEM = 'UPDATE_FEEDBACK_ITEM';
export const DELETE_FEEDBACK_ITEM = 'DELETE_FEEDBACK_ITEM';
export const CLEAR_FEEDBACK = 'CLEAR_FEEDBACK';
export const GET_COMPANY_FEEDBACK = 'GET_COMPANY_FEEDBACK';
export const GET_GIVED_COMPANY_FEEDBACK = 'GET_GIVED_COMPANY_FEEDBACK';
export const COMPANY_FEEDBACK_ERROR = 'COMPANY_FEEDBACK_ERROR';
export const TEAM_FEEDBACK_ERROR = 'TEAM_FEEDBACK_ERROR';

// Slack
export const GET_SLACK_USERS = 'GET_SLACK_USERS';
export const GET_SLACK_CHANNELS = 'GET_SLACK_CHANNELS';
export const GET_SLACK_CHANNELS_MEMBERS = 'GET_SLACK_CHANNELS_MEMBERS';
export const SLACK_USERS_ERROR = 'SLACK_USERS_ERROR';
export const SLACK_CHANNELS_ERROR = 'SLACK_CHANNELS_ERROR';
export const SLACK_CHANNELS_MEMBERS_ERROR = 'SLACK_CHANNELS_MEMBERS_ERROR';
export const GET_SLACK_TEAM = 'GET_SLACK_TEAM';
export const SLACK_TEAM_ERROR = 'SLACK_TEAM_ERROR';
export const SLACK_SETTINGS_ERROR = 'SLACK_SETTINGS_ERROR';

// Discord
export const GET_DISCORD_USERS = 'GET_DISCORD_USERS';
export const GET_DISCORD_CHANNELS = 'GET_DISCORD_CHANNELS';
export const DISCORD_USERS_ERROR = 'DISCORD_USERS_ERROR';
export const DISCORD_CHANNELS_ERROR = 'DISCORD_CHANNELS_ERROR';
export const GET_DISCORD_TEAM = 'GET_DISCORD_TEAM';
export const DISCORD_TEAM_ERROR = 'DISCORD_TEAM_ERROR';
export const CLEAR_DISCORD_TEAM = 'CLEAR_DISCORD_TEAM';

// OKRs
export const GET_USER_OKRS = 'GET_USER_OKRS';
export const GET_ALL_USER_OKRS = 'GET_ALL_USER_OKRS';
export const GET_USER_OKR = 'GET_USER_OKR';
export const GET_TEAM_OKRS = 'GET_TEAM_OKRS';
export const GET_OKR = 'GET_OKR';
export const GET_OKR_FOR_EDIT = 'GET_OKR_FOR_EDIT';
export const GET_OKR_FOR_URL = 'GET_OKR_FOR_URL';
export const GET_COMPANY_OKRS_FOR_EDIT = 'GET_COMPANY_OKRS_FOR_EDIT';
export const GET_OKRS = 'GET_OKRS';
export const OKR_ERROR = 'OKR_ERROR';
export const MAIN_OKR_ERROR = 'MAIN_OKR_ERROR';
export const USER_OKR_ERROR = 'USER_OKR_ERROR';
export const USER_OKRS_ERROR = 'USER_OKRS_ERROR';
export const OKRS_ERROR = 'OKRS_ERROR';
export const TEAM_OKRS_ERROR = 'TEAM_OKRS_ERROR';
export const ADD_OKR = 'ADD_OKR';
export const EDIT_OKR = 'EDIT_OKR';
export const UPDATE_OKR = 'UPDATE_OKR';
export const UPDATE_OKRS = 'UPDATE_OKRS';
export const UPDATE_ONE_KR = 'UPDATE_ONE_KR';
export const ADD_MAIN_OKR = 'ADD_MAIN_OKR';
export const REMOVE_MAIN_OKR = 'REMOVE_MAIN_OKR';
export const GET_COMPANY_OKRS = 'GET_COMPANY_OKRS';
export const GET_COMPANY_OKR = 'GET_COMPANY_OKR';
export const COMPANY_OKR_ERROR = 'COMPANY_OKR_ERROR';
export const COMPANY_OKRS_ERROR = 'COMPANY_OKRS_ERROR';
export const DELETE_OKR = 'DELETE_OKR';
export const UPDATE_OKR_LIKES = 'UPDATE_OKR_LIKES';
export const OKR_LIKES_ERROR = 'OKR_LIKES_ERROR';
export const ADD_OKR_COMMENT = 'ADD_OKR_COMMENT';
export const OKR_COMMENT_ERROR = 'OKR_COMMENT_ERROR';
export const REMOVE_OKR_COMMENT = 'REMOVE_OKR_COMMENT';
export const UPDATE_OKR_COMMENT = 'UPDATE_OKR_COMMENT';
export const CLEAR_OKRS = 'CLEAR_OKRS';
export const CLEAR_OKR = 'CLEAR_OKR';
export const UPDATE_CHILDS = 'UPDATE_CHILDS';
export const GET_USER_METRICS = 'GET_USER_METRICS';
export const USER_METRICS_ERROR = 'USER_METRICS_ERROR';
export const GET_COMPANY_METRICS = 'GET_COMPANY_METRICS';
export const COMPANY_METRICS_ERROR = 'COMPANY_METRICS_ERROR';
export const CLEAN_DATA_OKR = 'CLEAN_DATA_OKR';
export const ARCHIVE_OKR = 'ARCHIVE_OKR';
export const UNARCHIVE_OKR = 'UNARCHIVE_OKR';
export const GET_TREE_OKRS = 'GET_TREE_OKRS';
export const TREE_OKRS_ERROR = 'TREE_OKRS_ERROR';
export const GET_ACTIVE_OKRS = 'GET_ACTIVE_OKRS';
export const ACTIVE_OKRS_ERROR = 'ACTIVE_OKRS_ERROR';
export const REMOVE_OKR_UPDATE = 'REMOVE_OKR_UPDATE';
export const OKR_UPDATE_ERROR = 'OKR_UPDATE_ERROR';
export const CHANGE_OKR_FILTERS = 'CHANGE_OKR_FILTERS';
export const CHECK_OKR_FILTERS = 'CHECK_OKR_FILTERS';
export const CLEAR_OKR_FILTERS = 'CLEAR_OKR_FILTERS';
export const PREPARE_FIRST_OKR = 'PREPARE_FIRST_OKR';
export const GET_ONBOARDING_OKR = 'GET_ONBOARDING_OKR';
export const REMOVE_ONBOARDING_OKR = 'REMOVE_ONBOARDING_OKR';

// OKR Calls
export const ADD_OKR_CALL = 'ADD_OKR_CALL';
export const DELETE_OKR_CALL = 'DELETE_OKR_CALL';
export const GET_OKR_CALL = 'GET_OKR_CALL';
export const OKR_CALLS_ERROR = 'OKR_CALLS_ERROR';
export const CLEAR_OKR_CALLS = 'CLEAR_OKR_CALLS';
export const GET_ALL_OKR_CALLS = 'GET_ALL_OKR_CALLS';
export const SWITCH_OKR_CALL_STATUS = 'SWITCH_OKR_CALL_STATUS';

// ToDos
export const GET_USER_TODOS = 'GET_USER_TODOS';
export const GET_ALL_USER_TODOS = 'GET_ALL_USER_TODOS';
export const GET_USER_TODO = 'GET_USER_TODO';
export const GET_TEAM_TODOS = 'GET_TEAM_TODOS';
export const GET_TODO = 'GET_TODO';
export const GET_TODO_FOR_EDIT = 'GET_TODO_FOR_EDIT';
export const GET_COMPANY_TODOS_FOR_EDIT = 'GET_COMPANY_TODOS_FOR_EDIT';
export const TODO_ERROR = 'TODO_ERROR';
export const USER_TODO_ERROR = 'USER_TODO_ERROR';
export const USER_TODOS_ERROR = 'USER_TODOS_ERROR';
export const TEAM_TODOS_ERROR = 'TEAM_TODOS_ERROR';
export const ADD_TODO = 'ADD_TODO';
export const TODOS_ERROR = 'TODOS_ERROR';
export const GET_COMPANY_TODOS = 'GET_COMPANY_TODOS';
export const COMPANY_TODOS_ERROR = 'COMPANY_TODOS_ERROR';
export const EDIT_TODO = 'EDIT_TODO';
export const COMPLETE_TODO = 'COMPLETE_TODO';
export const COMPLETE_TODO_ERROR = 'COMPLETE_TODO_ERROR';
export const UPDATE_TODO = 'UPDATE_TODO';
export const UPDATE_TODO_ERROR = 'UPDATE_TODO_ERROR';
export const DELETE_TODO = 'DELETE_TODO';
export const CLEAR_TODOS = 'CLEAR_TODOS';
export const GET_TODOS_WITH_OKRS = 'GET_TODOS_WITH_OKRS';
export const TODOS_WITH_OKRS_ERROR = 'TODOS_WITH_OKRS_ERROR';
export const CHANGE_TODO_FILTERS = 'CHANGE_TODO_FILTERS';

// Home
export const GET_USER_HOME = 'GET_USER_HOME';
export const HOME_ERROR = 'HOME_ERROR';
export const CLEAR_HOME = 'CLEAR_HOME';
export const UPDATE_HOME_LIKES = 'UPDATE_HOME_LIKES';
export const ADD_HOME_COMMENT = 'ADD_HOME_COMMENT';
export const REMOVE_HOME_COMMENT_KUDOS = 'REMOVE_HOME_COMMENT_KUDOS';
export const UPDATE_HOME_LIKES_ANSWER = 'UPDATE_HOME_LIKES_ANSWER';
export const ADD_HOME_COMMENT_ANSWER = 'ADD_HOME_COMMENT_ANSWER';
export const REMOVE_HOME_COMMENT_ANSWER = 'REMOVE_HOME_COMMENT_ANSWER';

// Icebreakers
export const GET_ICEBREAKERS = 'GET_ICEBREAKERS';
export const ICEBREAKERS_ERROR = 'ICEBREAKERS_ERROR';
export const ICEBREAKER_ERROR = 'ICEBREAKER_ERROR';
export const CLEAR_ICEBREAKERS = 'CLEAR_ICEBREAKERS';
export const GET_ICEBREAKERS_SETTINGS = 'GET_ICEBREAKERS_SETTINGS';
export const ICEBREAKERS_SETTINGS_ERROR = 'ICEBREAKERS_SETTINGS_ERROR';
export const ADD_ICEBREAKER_ANSWER = 'ADD_ICEBREAKER_ANSWER';
export const EDIT_ICEBREAKER_ANSWER = 'EDIT_ICEBREAKER_ANSWER';
export const DELETE_ICEBREAKER_ANSWER = 'DELETE_ICEBREAKER_ANSWER';
export const DELETE_ICEBREAKER = 'DELETE_ICEBREAKER';
export const SWITCH_ICEBREAKERS_STATUS = 'SWITCH_ICEBREAKERS_STATUS';
export const GET_DEFAULT_ICEBREAKERS = 'GET_DEFAULT_ICEBREAKERS';
export const CHANGE_ICEBREAKERS_LANGUAGE = 'CHANGE_ICEBREAKERS_LANGUAGE';

// Weekly Plans
export const GET_USER_PLAN_ANSWERS = 'GET_USER_PLAN_ANSWERS';
export const GET_PROFILE_PLAN_ANSWERS = 'GET_PROFILE_PLAN_ANSWERS';
export const GET_TEAM_PLAN_ANSWERS = 'GET_TEAM_PLAN_ANSWERS';
export const GET_PLAN_ANSWERS = 'GET_PLAN_ANSWERS';
export const PLAN_ANSWER_ERROR = 'PLAN_ANSWER_ERROR';
export const PLAN_ANSWERS_ERROR = 'PLAN_ANSWERS_ERROR';
export const ADD_PLAN_ANSWER = 'ADD_PLAN_ANSWER';
export const EDIT_PLAN_ANSWER = 'EDIT_PLAN_ANSWER';
export const GET_PLAN_ANSWER = 'GET_PLAN_ANSWER';
export const PLAN_LIKES = 'PLAN_LIKES';
export const CLEAR_PLAN_ANSWERS = 'CLEAR_PLAN_ANSWERS';
export const GET_COMPANY_PLAN_ANSWERS = 'GET_COMPANY_PLAN_ANSWERS';
export const COMPANY_PLAN_ANSWER_ERROR = 'COMPANY_PLAN_ANSWER_ERROR';
export const LOCATION_CHANGE_ERROR_PLAN = 'LOCATION_CHANGE_ERROR_PLAN';
export const GET_PLANS = 'GET_PLANS';
export const GET_PLAN = 'GET_PLAN';
export const PLANS_ERROR = 'PLANS_ERROR';
export const CLEAR_PLANS = 'CLEAR_PLANS';
export const GET_PLANS_SETTINGS = 'GET_PLANS_SETTINGS';
export const PLANS_SETTINGS_ERROR = 'PLANS_SETTINGS_ERROR';
export const LOADING_PLANS = 'LOADING_PLANS';

// Custom Campaigns
export const GET_USER_CUSTOM_ANSWERS = 'GET_USER_CUSTOM_ANSWERS';
export const GET_PROFILE_CUSTOM_ANSWERS = 'GET_PROFILE_CUSTOM_ANSWERS';
export const GET_TEAM_CUSTOM_ANSWERS = 'GET_TEAM_CUSTOM_ANSWERS';
export const GET_CUSTOM_ANSWERS = 'GET_CUSTOM_ANSWERS';
export const CUSTOM_ANSWER_ERROR = 'CUSTOM_ANSWER_ERROR';
export const CUSTOM_ANSWERS_ERROR = 'CUSTOM_ANSWERS_ERROR';
export const ADD_CUSTOM_ANSWER = 'ADD_CUSTOM_ANSWER';
export const EDIT_CUSTOM_ANSWER = 'EDIT_CUSTOM_ANSWER';
export const GET_CUSTOM_ANSWER = 'GET_CUSTOM_ANSWER';
export const GET_CUSTOM_ANSWER_BY_ID = 'GET_CUSTOM_ANSWER_BY_ID';
export const GET_CUSTOM_SETTINGS = 'GET_CUSTOM_SETTINGS';
export const CUSTOM_LIKES = 'CUSTOM_LIKES';
export const CLEAR_CUSTOM_ANSWERS = 'CLEAR_CUSTOM_ANSWERS';
export const GET_COMPANY_CUSTOM_ANSWERS = 'GET_COMPANY_CUSTOM_ANSWERS';
export const COMPANY_CUSTOM_ANSWER_ERROR = 'COMPANY_CUSTOM_ANSWER_ERROR';
export const LOCATION_CHANGE_ERROR_CUSTOM = 'LOCATION_CHANGE_ERROR_CUSTOM';
export const GET_CUSTOMS = 'GET_CUSTOMS';
export const GET_ALL_CUSTOMS = 'GET_ALL_CUSTOMS';
export const GET_CUSTOM = 'GET_CUSTOM';
export const CUSTOMS_ERROR = 'CUSTOMS_ERROR';
export const CLEAR_CUSTOMS = 'CLEAR_CUSTOMS';
export const GET_CUSTOMS_SETTINGS = 'GET_CUSTOMS_SETTINGS';
export const CUSTOMS_SETTINGS_ERROR = 'CUSTOMS_SETTINGS_ERROR';
export const ADD_CUSTOM = 'ADD_CUSTOM';
export const EDIT_CUSTOM_ANSWER_BY_ID = 'EDIT_CUSTOM_ANSWER_BY_ID';
export const DELETE_CUSTOM = 'DELETE_CUSTOM';
export const SWITCH_CUSTOM_STATUS = 'SWITCH_CUSTOM_STATUS';
export const GET_CAMPAIGN_CUSTOM_ANSWERS = 'GET_CAMPAIGN_CUSTOM_ANSWERS';
export const GET_USER_CUSTOM_ANSWERS_ID = 'GET_USER_CUSTOM_ANSWERS_ID';
export const CLEAR_CUSTOMS_SETTINGS = 'CLEAR_CUSTOMS_SETTINGS';
export const GET_ALL_USER_CUSTOM_ANSWERS = 'GET_ALL_USER_CUSTOM_ANSWERS';
export const GET_COMPANY_STANDUP_ANSWERS_IN_CHECKIN = 'GET_COMPANY_STANDUP_ANSWERS_IN_CHECKIN';
export const GET_STANDUP_SETTINGS_IN_CUSTOM = 'GET_STANDUP_SETTINGS_IN_CUSTOM';
export const GET_PLANS_SETTINGS_IN_CUSTOM = 'GET_PLANS_SETTINGS_IN_CUSTOM';
export const GET_COMPANY_PLAN_ANSWERS_IN_CUSTOM = 'GET_COMPANY_PLAN_ANSWERS_IN_CUSTOM';
export const GET_ALL_ACTIVE_SETTINGS = 'GET_ALL_ACTIVE_SETTINGS';
export const GET_COMPANY_UPDATE_ANSWERS_IN_CUSTOM = 'GET_COMPANY_UPDATE_ANSWERS_IN_CUSTOM';
export const GET_COMPANY_ALL_ANSWERS_IN_CUSTOM = 'GET_COMPANY_ALL_ANSWERS_IN_CUSTOM';
export const PREPARE_FIRST_CUSTOM = 'PREPARE_FIRST_CUSTOM';
export const GET_ONBOARDING_CUSTOM = 'GET_ONBOARDING_CUSTOM';
export const REMOVE_ONBOARDING_CUSTOM = 'REMOVE_ONBOARDING_CUSTOM';
export const GET_CHECKIN_MODAL_STATUS = 'GET_CHECKIN_MODAL_STATUS';
export const HIDE_CHECKIN_MODAL = 'HIDE_CHECKIN_MODAL';
export const CHECKIN_MODAL_ERROR = 'CHECKIN_MODAL_ERROR';
export const CHANGE_CHECKIN_FILTERS = 'CHANGE_CHECKIN_FILTERS';
export const CHECK_CHECKIN_FILTERS = 'CHECK_CHECKIN_FILTERS';
export const CLEAR_CHECKIN_FILTERS = 'CLEAR_CHECKIN_FILTERS';

// Outreach
export const GET_DATA = 'GET_DATA';
export const REACH_ERROR = 'REACH_ERROR';
export const ADD_REACH_USER = 'ADD_REACH_USER';
export const EDIT_REACH_USER = 'EDIT_REACH_USER';
export const CLEAR_REACH = 'CLEAR_REACH';
export const DELETE_REACH_USER = 'DELETE_REACH_USER';
export const TOGGLE_REACH_STATUS = 'TOGGLE_REACH_STATUS';
export const GET_EMAILS = 'GET_EMAILS';
export const GET_EMAIL = 'GET_EMAIL';
export const GET_CAMPAIGN = 'GET_CAMPAIGN';
export const ADD_CAMPAIGN = 'ADD_CAMPAIGN';
export const ADD_EMAIL = 'ADD_EMAIL';
export const EDIT_EMAIL = 'EDIT_EMAIL';
export const REMOVE_EMAIL = 'REMOVE_EMAIL';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const CLEAR_EMAILS = 'CLEAR_EMAILS';
export const CAMPAIGN_ERROR = 'CAMPAIGN_ERROR';
export const EMAIL_ERROR = 'EMAIL_ERROR';

// Promocode
export const CHECK_CODE = 'CHECK_CODE';
export const CODE_ERROR = 'CODE_ERROR';

// 1:1s
export const GET_COMPANY_ONES = 'GET_COMPANY_ONES';
export const COMPANY_ONES_ERROR = 'COMPANY_ONES_ERROR';
export const GET_USER_ONES = 'GET_USER_ONES';
export const USER_ONES_ERROR = 'USER_ONES_ERROR';
export const GET_USER_ONES_ID = 'GET_USER_ONES_ID';
export const GET_ONES_SETTINGS = 'GET_ONES_SETTINGS';
export const SETTINGS_ONES_ERROR = 'SETTINGS_ONES_ERROR';
export const ADD_ONE_ON_ONE = 'ADD_ONE_ON_ONE';
export const ONES_ERROR = 'ONES_ERROR';
export const EDIT_ONES_SETTING = 'EDIT_ONES_SETTING';
export const UPDATE_ONES = 'UPDATE_ONES';
export const UPDATE_ONES_ERROR = 'UPDATE_ONES_ERROR';
export const COMPLETE_ONES = 'COMPLETE_ONES';
export const COMPLETE_ONES_ERROR = 'COMPLETE_ONES_ERROR';
export const DELETE_ITEM_ONES = 'DELETE_ITEM_ONES';
export const DELETE_ITEM_ONES_ERROR = 'DELETE_ITEM_ONES_ERROR';
export const DELETE_ONES = 'DELETE_ONES';
export const CLEAR_ONES = 'CLEAR_ONES';

// Mood surveys
export const GET_COMPANY_MOOD_ANSWERS = 'GET_COMPANY_MOOD_ANSWERS';
export const GET_MOOD_SETTINGS = 'GET_MOOD_SETTINGS';
export const ADD_MOOD_ANSWER = 'ADD_MOOD_ANSWER';
export const MOOD_ANSWERS_ERROR = 'MOOD_ANSWERS_ERROR';
export const MOOD_SETTINGS_ERROR = 'MOOD_SETTINGS_ERROR';
export const UPDATE_MOOD_SETTINGS = 'UPDATE_MOOD_SETTINGS';
export const CLEAR_MOOD_ANSWERS = 'CLEAR_MOOD_ANSWERS';
export const LOCATION_CHANGE_ERROR_MOOD = 'LOCATION_CHANGE_ERROR_MOOD';
export const GET_USER_MOOD_ANSWER = 'GET_USER_MOOD_ANSWER';
export const GET_USER_MOOD_ANSWER_AND_SETTINGS = 'GET_USER_MOOD_ANSWER_AND_SETTINGS';
export const GET_ALL_MOODS = 'GET_ALL_MOODS';
export const MOODS_ERROR = 'MOODS_ERROR';

// Sidebar
export const SET_SIDEBAR_VIEW = 'SET_SIDEBAR_VIEW';


