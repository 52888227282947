import React from 'react';
import { FormattedMessage } from 'react-intl';

export const icebreakerCopy = {
  msgOnboarding1: (
    <FormattedMessage
      id="example"
      defaultMessage="Example"
    />
  ),
  msgOnboarding2: (
    <FormattedMessage
      id="icebreaker.onboarding.company.notification.msg2"
      defaultMessage="It's sample data below. Add few replies to the active icebreaker above, and this sample data will be disappeared."
    />
  ),
  ctaOnboarding: (
    <FormattedMessage
      id="icebreaker.complete"
      defaultMessage="Complete the icebreaker"
    />
  ),
  updatePlanMsg: {
    title: (
      <FormattedMessage
        id="company.updateplan.title"
        defaultMessage="Update Plan"
      />
    ),
    msg: (
      <FormattedMessage
        id="company.icebreaker.updateplan.msg"
        defaultMessage={`Icebreakers aren't available on this plan. Please update the plan to unlock Icebreakers.`}
      />
    )
  },
}

export default {
  icebreakerCopy
}