import React from 'react';
import Logo from '../../../elems/Logo';
import { FormattedMessage } from 'react-intl';
import Text from '../../elems/Text';
import BlueBtn from '../../elems/BlueBtn';
import Skip from '../../elems/Skip';
// import { DayPicker as DayPickerInput } from 'react-day-picker'
import { format as formatDate } from 'date-fns';
import DatePickerComponent from '../../../elems/DatePicker';

const endOfTheYear = new Date();
endOfTheYear.setMonth(11);
endOfTheYear.setDate(31);

const OkrCreate = ({ 
  onChange, 
  handlgeDayChange,
  handleStepClick,
  objective,
  dueIn,
 }) => {
  
  let title = <FormattedMessage id="title.onboarding.okr1" defaultMessage="Let's Create Your First OKR!" />
  let message = <FormattedMessage id="message.onboarding.okr1" defaultMessage="OKRs are an effective goal-setting and leadership tool for communicating what milestones you'll need to meet in order to accomplish your goal." />
  let nextStep = <FormattedMessage id="next.step" defaultMessage="Next Step" />
  
  return (
    <div className="onboarding__content relative flex flex-column">
      <div className="">
        <div className="pb-8">
          <Logo isNoLink={true} />
        </div>
        <Text title={title} text={message} page={2} />

        <div className="pt-3 form-app form-app-okr">
          <div className="form-main form-main-inline standups-time flex-column-xs">
            <div className="w-100 mb-3-xs labeled-wrapper">
              <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                <FormattedMessage
                  id="user.okr.create.objective"
                  defaultMessage="The objective"
                />
              </label>
              <FormattedMessage
                id="user.okr.create.objectiveInput"
                defaultMessage="Ex. Achieve record revenue"
              >
                {(msg) => (
                  <input
                    className="w-100 input-text fs-18 px-15"
                    type="text"
                    placeholder={msg}
                    name="objective"
                    value={objective || ''}
                    onChange={(e) => onChange(e)}
                  />
                )}
              </FormattedMessage>
            </div>
          </div>

          <div className="form-main form-main-inline standups-time mt-2 flex-column-xs">
            <div className="w-100 labeled-wrapper">
              <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                <FormattedMessage id="due" defaultMessage="Due" />
              </label>
              {/* <DayPickerInput
                formatDate={formatDate}
                // parseDate={parseDate}
                placeholder={`${formatDate(new Date(), 'MM/dd/yyyy')}`}
                name="dueIn"
                value={dueIn}
                onDayChange={(day) => handlgeDayChange(day)}
              /> */}
              <DatePickerComponent
                value={dueIn}
                onSelect={(day) => handlgeDayChange(day)}
              />
            </div>
          </div>
        </div>

        <div className="buttons pt-3 onboarding__content__button__480">
          <BlueBtn onClick={handleStepClick} text={nextStep} isLoader={false} />
        </div>
      </div>
      
      <Skip/>
    </div>
  );
};

export default OkrCreate;
