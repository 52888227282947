import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { icons } from '../../../img/elements/icons';
import { FormattedMessage } from 'react-intl';
import UpdateItemCardQuestion from './UpdateItemCardQuestion';

const UpdateItemCard = ({
  updateAnswer: {
    user,
    firstName,
    lastName,
    avatar,
    questions,
    answers,
    comments,
    likes,
    _id,
    updateDate,
    notes,
    campaignType,
    weeklyOkrs,
    lastWeeklyOkrs,
    date,
  }
}) => {
  const [displayUserAnswer, toggleAnswerButton] = useState(true);

  
  return (
    <Fragment>
      <div className="px-2 card-item card-team">
        <div className="pt-2 flex space-between align-center">
          <div className="flex justify-start align-center w-80">
            <Link to={user ? `/user/${user}` : '#!'} className="user">
              <img
                src={avatar}
                alt=""
                className="round-img img-75 d-block avatar"
                width="75px"
                height="75px"
              />
            </Link>
            <div>
              <p className="medium card-subtitle team-user">
                {firstName} {lastName}
              </p>
              <p className="small grey">
                <FormattedMessage id="updates.published" defaultMessage="Published" />{' '}
                <Moment format="D MMM">{date}</Moment>
              </p>
            </div>
          </div>

          <div className="card-icons">
            <button
              onClick={() => toggleAnswerButton(!displayUserAnswer)}
              className="btn-action no_btn"
              type="button"
            >
              {icons.dropdown_icon}
            </button>
          </div>
        </div>

        <div className="line"></div>

        {displayUserAnswer && (
          <div className="card-updates-team card-body-hidden px-2-l pb-0 pt-1 active">
            {questions.map((question, index) => (
              <UpdateItemCardQuestion
                key={`teamQuestion-${_id}-${index}`}
                updateID={_id}
                question={question}
                answer={answers[index] !== undefined && answers[index]}
                userID={user}
                avatar={avatar}
                comments={comments.filter(
                  comment => comment.answerID === answers[index]._id.toString()
                )}
                likes={likes.filter(
                  like => like.answerID === answers[index]._id.toString()
                )}
                index={index}
                campaignType={campaignType}
                weeklyOkrs={weeklyOkrs}
                lastWeeklyOkrs={lastWeeklyOkrs}
              />
            ))}

            {notes && (
              <div className="brd-transparent brd_left_primary pl-2 my-2">
                <p className="blue small mb-xs">
                  <FormattedMessage id="notes" defaultMessage="Notes" />{' '}
                </p>
                <div className="notes medium">
                  {notes}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

UpdateItemCard.propTypes = {
  updateAnswer: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(UpdateItemCard);
