import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { icons } from '../../img/elements/icons';

const CustomItemTitle = ({
  user,
  firstName,
  lastName,
  avatar,
  toggleAnswerButton,
  displayUserAnswer,
  date,
  customName
}) => {

  return (
    <Fragment>
      <div
        className="w-100 user flex align-center cursor my-1"
        onClick={() => toggleAnswerButton(!displayUserAnswer)}
      >
        <div className="flex align-center nowrap">
          <Link to={user ? `/user/${user}` : '#!'} className="flex align-center">
            <img src={avatar} alt="" className="round-img avatar mr-15" width="40px" height="40px" />
          </Link>
          <p className="large bold mb-0">
            {firstName} {lastName}
          </p>
        </div>
        <div className="flex align-center nowrap">
          <p className="fs-12 ml-15">
            <Moment format="DD MMM">{date}</Moment>
          </p>
          <span className="fs-12 mx-1">|</span>
          <p className="fs-12">{customName}</p>
        </div>
        <div className="sub-menu__page w-60 ml-2 mt__neg-15"></div>
        <div className="card-icons ml-1">
          <button
            onClick={() => toggleAnswerButton(!displayUserAnswer)}
            className="btn-action no_btn bg-transparent"
            type="button"
          >
            {!displayUserAnswer ? icons.angle_up : icons.dropdown_icon}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

CustomItemTitle.propTypes = {
  answer: PropTypes.object.isRequired
};

export default CustomItemTitle;
