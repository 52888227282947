import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import TodoItem2CardForm from '../../elems/TodoItem2CardForm';
import { FormattedMessage } from 'react-intl';
// import { isStreamLike } from 'combined-stream';

const FollowUpForm = ({
  onChange,
  clearTodo,
  onSubmit,
  inputName,
  inputValue,
  name,
  index,
  followPlan,
  wording,
  handleAddFollowUp,
}) => {
  const [isItemForm, setItemForm] = useState(false);

  const handleClear = (index, planFact) => {
    clearTodo(index, name, planFact);
    setItemForm(!isItemForm);
  };

  const handleAddForm = () => {
    handleAddFollowUp(index, followPlan);
    setItemForm(!isItemForm);
  };

  let addText = <FormattedMessage id='add' defaultMessage='Add' />;

  // console.log('==== isItemForm', isItemForm);
  // console.log('==== name in FollowUpForm', name);
  // console.log('==== index in FollowUpForm', index);
  // console.log('==== followPlan in FollowUpForm', followPlan);
  // console.log('==== inputName in FollowUpForm', inputName);
  // console.log('==== inputValue in FollowUpForm', inputValue);
  // console.log('==== item.plan.text in FollowUpForm', item.plan.text);

  return (
    <Fragment>
      {isItemForm ? (
        <form
          onSubmit={(e) => onSubmit(e, index, name)}
          className='flex space-between align-center px-1 pt-xs'
        >
          <div className='w-70'>
            <FormattedMessage
              id={wording.inputId}
              defaultMessage={wording.inputMsg}
            >
              {(msg) => (
                <input
                  className={`w-100 medium card-subtitle brd-transparent brd_bottom_grey outline`}
                  type='text'
                  required
                  autoFocus
                  value={inputValue || ''}
                  // value={item.plan.text || ''}
                  onChange={(e) =>
                    onChange(e.target.value, index, inputName, name, followPlan)
                  }
                  placeholder={msg}
                  name={inputName}
                />
              )}
            </FormattedMessage>
          </div>

          <div className='form-btn flex align-center'>
            <button
              onClick={(e) => onSubmit(e, index, name, followPlan)}
              className='inline-block bg-lead pointer medium outline brd br-20 cursor px-1 lh-30 mr-2'
            >
              <FormattedMessage id='add' defaultMessage='Add' />
            </button>
            <span
              onClick={() => handleClear(index, followPlan)}
              className='no_btn small'
            >
              <i className='fas fa-times cursor grey fs-14' />
            </span>
          </div>
        </form>
      ) : (
        <p
          className='grey medium cursor'
          // onClick={() => setItemForm(!isItemForm)}
          onClick={() => handleAddForm()}
        >
          + {addText}
        </p>
      )}
    </Fragment>
  );
};

// FollowUpForm.propTypes = {
//   inputValue: PropTypes.string.isRequired,
// };

export default FollowUpForm;
