import React from 'react';
import { FormattedMessage } from 'react-intl';

export const oneCopy = {
  chooseUser: {
    title: (
      <FormattedMessage
        id="oneOnOne"
        defaultMessage="1:1 Meetings"
      />
    ),
    msg: (
      <FormattedMessage
        id="one.choose.user"
        defaultMessage="Choose a teammate for a 1:1 meeting or create the new meeting"
      />
    )
  },
  noMeetings: {
    title: (
      <FormattedMessage
        id="no.oneOnOne"
        defaultMessage="No Meetings"
      />
    ),
    msg: (
      <FormattedMessage
        id="one.choose.noMeetings"
        defaultMessage="You don't have 1:1 meetings with your teammates. Create your first 1:1 meeting to keep each other in the loop, give feedback, or resolve issues."
      />
    )
  }
}

export default {
  oneCopy
}
