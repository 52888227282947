import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { changeCheckinFilters } from '../../actions/customAnswer';
import logo from '../../img/logo/logo.svg';
import { icons } from '../../img/elements/icons';
import PeopleIcon from './icons/PeopleIcon';
import SettingsIcon from './icons/SettingsIcon';
import UpdateIcon from './icons/UpdateIcon';
import OkrIcon from './icons/OkrIcon';
import TodoIcon from './icons/TodoIcon';
import KudosIcon from './icons/KudosIcon';
import OneOnOneIcon from './icons/OneOnOneIcon';
import DashboardIcon from './icons/DashboardIcon';
import IcebreakerIcon from './icons/IcebreakerIcon';
import SisterCompaniesIcon from './icons/SisterCompaniesIcon';
import { menuItem } from '../../utils/menu/checkins';
import useInvite from '../invite/useInvite';
import isEnabled, { ENGAGEMENT } from '../../utils/features';

let checkinsTitle = menuItem;

const Menu = ({
  company: { company },
  checkinFilters,
  changeCheckinFilters,
  team,
  teamId,
  settings,
  activeIndex,
  outreach,
  focusUsers,
  companyName,
  isShowCollapseBtn,
  toggleSidebar,
  sidebar,
}) => {
  const [mobileMenu, showMobileMenu] = useState({ active: false });

  const { active } = mobileMenu;

  const handleMobile = () => {
    showMobileMenu({ ...mobileMenu, active: !active });
  };

  const [openInvite, InviteElem] = useInvite();

  return (
    <Fragment>
      <Fragment>
        {InviteElem}
        <div className={active ? 'navigation_toggler activeMenu' : 'navigation_toggler'}>
          <div className={`main_menu menu menu__large ${sidebar === 1 ? 'visible' : 'hidden'} app_menu bg__white h-100 brd_right brd_right_grey_light pt-4 z-50000`}>
            <div className="logo mb-4">
              <Link to="/">
                <img src={logo} alt="Focus logo" className="logo_img" width="122" height="35" />
              </Link>
            </div>

            <div className="main_menu_items mt-3">
              <ul className="menu_list">
                <li className="main_menu_item main_menu__dashboard pb-16">
                  <Link
                    to={'/dashboard/objectives'}
                    className={`flex align-center mm_item ${activeIndex === 13 && ' active'}`}
                  >
                    <DashboardIcon activeIndex={activeIndex} />{' '}
                    <span className="main_menu_item__span">
                      <FormattedMessage id="menu.dashboard" defaultMessage="Dashboard" />
                    </span>
                  </Link>
                </li>
                <li className="main_menu_item main_menu__okrs pb-16">
                  <Link
                    to={'/objectives'}
                    className={`flex align-center mm_item ${activeIndex === 4 && ' active'}`}
                  >
                    <OkrIcon activeIndex={activeIndex} />
                    <span className="main_menu_item__span">
                      <FormattedMessage id="menu.okrs.new" defaultMessage="Objectives" />
                    </span>
                  </Link>
                </li>
                <li className="main_menu_item main_menu__tasks pb-16">
                  <Link
                    to={'/tasks'}
                    className={`flex align-center mm_item ${activeIndex === 12 && ' active'}`}
                  >
                    <TodoIcon activeIndex={activeIndex} />
                    <span className="main_menu_item__span">
                      <FormattedMessage id="menu.tasks.new" defaultMessage="Tasks" />
                    </span>
                  </Link>
                </li>
                <li className="main_menu_item main_menu__updates pb-16">
                  <Link
                    to={'/campaigns/all-checkins'}
                    className={`flex align-center mm_item pr-1 pb- ${activeIndex === 2 && ' active'}`}
                  >
                    <UpdateIcon activeIndex={activeIndex} />{' '}
                    <span className="main_menu_item__span">{checkinsTitle}</span>
                  </Link>
                </li>
                <li className="main_menu_item main_menu__oneOnOne pb-0">
                  <Link
                    to={'/one_on_one'}
                    className={`flex align-center mm_item ${activeIndex === 11 && ' active'}`}
                  >
                    <OneOnOneIcon activeIndex={activeIndex} />{' '}
                    <span className="main_menu_item__span">
                      <FormattedMessage id="oneOnOne" defaultMessage="1:1 Meetings" />
                    </span>
                  </Link>
                </li>
                {isEnabled(ENGAGEMENT, { company }) && (
                <>
                  <div className="line w-100"></div>
                  <p className="fs-12 black uppercase fw-600 pt-2px pb-17px">
                    <FormattedMessage id="menu.engagement" defaultMessage="Engagement" />
                  </p>
                  <li className="main_menu_item main_menu__icebreakers pb-16">
                    <Link
                      to={'/icebreakers'}
                      className={`flex align-center mm_item ${activeIndex === 14 && ' active'}`}
                    >
                      <IcebreakerIcon activeIndex={activeIndex} />{' '}
                      <span className="main_menu_item__span">
                        <FormattedMessage id="icebreakers" defaultMessage="Icebreakers" />
                      </span>
                    </Link>
                  </li>
                  <li className="main_menu_item main_menu__kudos">
                    <Link
                      to={'/mood'}
                      className={`flex align-center mm_item pb-0 ${activeIndex === 5 && ' active'}`}
                    >
                      <KudosIcon activeIndex={activeIndex} />{' '}
                      <span className="main_menu_item__span">
                        <FormattedMessage id="menu.mood.kudos" defaultMessage="Mood & Kudos" />
                      </span>
                    </Link>
                  </li>
                </>)}
                <div className="line w-100"></div>
                <p className="fs-12 black uppercase fw-600 pt-2px pb-17px">
                  <FormattedMessage id="menu.company.teams" defaultMessage="Company & Teams" />
                </p>

                <li className="main_menu_item main_menu__people pb-xs">
                  <Link to={`/teams`} className={`flex align-center mm_item ${activeIndex === 8 && ' active'}`}>
                    <PeopleIcon activeIndex={activeIndex} />{' '}
                    <span className="main_menu_item__span">
                      <FormattedMessage id="menu.users" defaultMessage="Teams & Members" />
                    </span>
                  </Link>
                </li>

                {settings ? (
                  <li className="main_menu_item main_menu__settings pb-xs">
                    <Link
                      to={`/settings`}
                      className={`flex align-center mm_item ${activeIndex === 9 && ' active'}`}
                    >
                      <SettingsIcon activeIndex={activeIndex} />{' '}
                      <span className={`main_menu_item__span`}>
                        <FormattedMessage id="menu.settings" defaultMessage="Company Settings" />
                      </span>
                    </Link>
                  </li>
                ) : null}

                <ul className="menu_list pt-1">
                  <li className={`main_menu_item ${activeIndex === 6 ? ' main_menu__company' : ''}`}>
                    <Link
                      to={'/campaigns/all-checkins'}
                      onClick={() => changeCheckinFilters({ value: company._id, label: company.name, type: 1 }, checkinFilters.time, checkinFilters.company)}
                      className={`flex align-center mm_item pt-2px ${activeIndex === 6 && ' active'}`}
                    >
                      <span className={`main_menu_item__span_team ${activeIndex === 6 ? 'pl-1' : ''}`}>
                        {companyName}
                      </span>
                    </Link>
                  </li>
                  {team ? (
                    <li className="main_menu_item main_menu__team pb-1">
                      <Link
                        to={`/campaigns/all-checkins`}
                        onClick={() => changeCheckinFilters({ value: teamId, label: team, type: 2 }, checkinFilters.time, checkinFilters.company)}
                        className={`mm_item ${activeIndex === 10 && ' active'}`}
                      >
                        <span className={`main_menu_item__span_team ${activeIndex === 10 ? 'pl-1' : ''}`}>
                          {team}
                        </span>
                      </Link>
                    </li>
                  ) : null}
                </ul>

                <div className="line w-100 mt-1 mb-1"></div>

                <ul className="menu_list pt-1">
                  <li className="main_menu_item main_menu__sisterCompanies pb-1">
                    <Link
                      to={'/sister-companies'}
                      className={`flex align-center mm_item ${activeIndex === 15 && ' active'}`}
                    >
                      <SisterCompaniesIcon activeIndex={activeIndex} />{' '}
                      <span className="main_menu_item__span">
                        <FormattedMessage id="menu.sister.companies" defaultMessage="Sister Companies" />
                      </span>
                    </Link>
                  </li>
                  <li className="main_menu_item main_menu__team pb-1">
                    <a
                      href={'https://usefocus.canny.io/feature-requests'}
                      rel="noopener noreferrer" target="_blank"
                      className={`flex align-center mm_item pt-2px`}
                    >
                      <span className="w-20px text-center">
                        {icons.bulb_exclamation}
                      </span>
                      <span className={`main_menu_item__span`}>
                        Feature requests
                      </span>
                    </a>
                  </li>
                </ul>

                {sidebar === 1 && (
                  <Link onClick={openInvite} className={`btn btn-light_new fs-14 mt-1 mr-0 py-1 lh-10 h-34`}>
                  <FormattedMessage id="invite.member" defaultMessage="Invite Team Member" />
                </Link>
                )}

                {outreach ? (
                  <Fragment>
                    <div className="line w-100"></div>
                    <ul className="menu_list">
                      <li className="main_menu_item main_menu__settings">
                        <Link to={`/outreacher`} className={`mm_item ${activeIndex === 100 && ' active'}`}>
                          🚀 <FormattedMessage id="menu.outreach" defaultMessage="Outreach" />
                        </Link>
                      </li>
                    </ul>
                  </Fragment>
                ) : null}

                {focusUsers ? (
                  <Fragment>
                    <div className="line w-80 mt-3"></div>

                    <ul className="menu_list">
                      <li className="main_menu_item main_menu__settings">
                        <Link to={`/a/stats`} className={`mm_item ${activeIndex === 200 && ' active'}`}>
                          🚀 <FormattedMessage id="menu.focus.users" defaultMessage="Analytics" />
                        </Link>
                      </li>
                      {/* <li className="main_menu_item main_menu__settings">
                          <Link
                            to={`/a/logs`}
                            className={`mm_item ${activeIndex === 201 && ' active'}`}
                          >
                            👀
                            {' '}
                            <FormattedMessage id="menu.focus.logs" defaultMessage="Logs" />
                          </Link>
                        </li> */}
                    </ul>
                  </Fragment>
                ) : null}
              </ul>
            </div>


            {/* {isShowCollapseBtn && (
                <div className="absolute right-top grey cursor collapse__btn" onClick={() => setMenuCollapsed(!menuCollapsed)}>
                  X
                </div>
              )} */}
          </div>

          {isShowCollapseBtn && (
            // <div className={`absolute right-top grey cursor collapse__btn collapse__btn__large ${!menuCollapsed ? '' : 'hidden'}`} onClick={() => setMenuCollapsed(!menuCollapsed)}>
            <div className={`absolute right-top grey cursor collapse__btn collapse__btn__large ${sidebar === 1 ? '' : 'hidden'}`} onClick={toggleSidebar}>
              {icons.angle_left}
            </div>
          )}

          <button className="modal-close btn_menu" onClick={() => handleMobile()}>
            x
          </button>
        </div>

        <div className="menu_icon" id="menu_icon">
          <button className="no_btn" onClick={() => handleMobile()}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </Fragment>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  company: state.company,
  checkinFilters: state.customAnswer.filters
});

export default connect(mapStateToProps, { changeCheckinFilters })(Menu);
