import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const DayItem = ({
  scheduleDays,
  onChange,
  styles,
  stylesText,
}) => {
  

  return (
   <Fragment>
      <div className="checkbox days">
        {scheduleDays.map((day, index) => (
          <div key={day.index} className="checkbox-item">
            <input
              type="checkbox"
              // id={day.index}
              id={`${day.day}-${day.index}`}
              name={day.index}
              checked={day.isActive}
              value={day.isActive}
              onChange={e => onChange(e, index)}
            />
            <label className="fs-10" htmlFor={`${day.day}-${day.index}`}>{day.day}</label>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

DayItem.propTypes = {
  scheduleDays: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DayItem;
