import React from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";
import { FormattedMessage } from "react-intl";

const OkrUpdate = ({ updatedAt }) => {
  return (
    <span className="fs-10">
      <FormattedMessage id="user.okr.updatedAt" defaultMessage="Updated at" />{" "}
      <Moment format="D MMM">{updatedAt}</Moment>
    </span>
  );
};

OkrUpdate.propTypes = {
  updatedAt: PropTypes.string.isRequired
};

export default OkrUpdate;
