import React from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SecondBtn = ({ link, cta }) => {

  return (
    <Link to={link} className="btn btn-light">{cta}</Link>
  );
};

SecondBtn.propTypes = {
  cta: PropTypes.object,
};

export default SecondBtn;
