import React from 'react';
import { FormattedMessage } from 'react-intl';

export const taskCopy = {
  msgOnboarding: (
    <FormattedMessage
      id="task.onboarding.company.notification.msg1"
      defaultMessage="You're using the sample data below. Create your first task and you'll see your data there. Click the button below to create your first task."
    />
  ),
  ctaOnboarding: (
    <FormattedMessage
      id="task.onboarding.company.notification.cta"
      defaultMessage="Create a task"
    />
  ),
  updatePlanMsg: {
    title: (
      <FormattedMessage
        id="company.updateplan.title.new"
        defaultMessage="Upgrade your plan to create tasks"
      />
    ),
    msg: (
      <FormattedMessage
        id="company.tasks.updateplan.msg.new"
        defaultMessage="Create & managing tasks helps you accomplish your key objectives."
      />
    ),
    btnTxt: (
      <FormattedMessage
          id="upgrade.plan.cta.customs"
          defaultMessage="Upgrade plan"
        />
    ),
  }
}

export default {
  taskCopy
}