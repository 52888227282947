import React from 'react';
import { FormattedMessage } from 'react-intl';
import { oldCompaniesDate } from '../oldWorkspace';
import { isVisible } from '../checkins';

// Hide default checkins for teams that created after 21.10.2022      
// const oldCompaniesDate = new Date('2022-10-20T00:00:00');
// console.log('oldCompaniesDate', oldCompaniesDate)

export const menuItem = <FormattedMessage id="checkins" defaultMessage="Check-ins" />;
export const menuTitle = <FormattedMessage id="checkins" defaultMessage="Check-ins" />;
const allCheckins = <FormattedMessage id="all.checkins" defaultMessage="All Check-ins" />;
const checkins = <FormattedMessage id="menu.checkins" defaultMessage="Daily Check-ins" />;
const updates = <FormattedMessage id="menu.updates.new" defaultMessage="Weekly Updates" />;
const plans = <FormattedMessage id="weekly.plans" defaultMessage="Weekly Plans" />;
const defaultStandups = <FormattedMessage id="default.standups" defaultMessage="Default Daily Check-ins" />;
const defaultPlans = <FormattedMessage id="default.plans" defaultMessage="Default Weekly Plans" />;
const defaultUpdates = <FormattedMessage id="default.updates" defaultMessage="Default Weekly Updates" />;


export const menuItemsWithoutAllCheckins = [
  {
    title: checkins,
    url: '/campaigns/checkins',
    label: 'Default Daily Check-ins',
    value: 1,
    checkinType: 2,
  },
  {
    title: updates,
    url: '/campaigns/updates',
    label: 'Default Weekly Updates',
    value: 2,
    checkinType: 4,
  },
  {
    title: plans,
    url: '/campaigns/plans',
    label: 'Default Weekly Plans',
    value: 3,
    checkinType: 3,
  }
]

export const menuItems = [
    {
      title: allCheckins,
      url: '/campaigns/all-checkins',
      label: 'All Check-ins',
      value: 0,
      checkinType: 1,
    },
    {
      title: checkins,
      url: '/campaigns/checkins',
      label: 'Default Daily Check-ins',
      value: 1,
      checkinType: 2,
    },
    {
      title: updates,
      url: '/campaigns/updates',
      label: 'Default Weekly Updates',
      value: 2,
      checkinType: 4,
    },
    {
      title: plans,
      url: '/campaigns/plans',
      label: 'Default Weekly Plans',
      value: 3,
      checkinType: 3,
    }
  ]


export const menuItemsRu = [
  {
    title: allCheckins,
    url: '/campaigns/all-checkins',
    label: 'Все чекины',
    value: 0,
    checkinType: 1,
  },
  {
    title: checkins,
    url: '/campaigns/checkins',
    label: 'Статусы Дня',
    value: 1,
    checkinType: 2,
  },
  {
    title: updates,
    url: '/campaigns/updates',
    label: 'Отчеты Недели',
    value: 2,
    checkinType: 4,
  },
  {
    title: plans,
    url: '/campaigns/plans',
    label: 'Планы Недели',
    value: 3,
    checkinType: 3,
  }
]

export const getMenuItems = (customs) => {
    try {
  
      // remove all-checkins if no customs
      let menu = customs.length > 0 ? menuItems : menuItems.splice(0, 1)

      return menu;
  
    } catch (err) {
      console.log('Error in getCheckinsMenu', err);
    }
  };

  

  export const getCheckinsMenuNew = (customs, company={}, user={}, lang = 'en') => {
    try {
  
      // let menuArr = menuItems.slice();
      let menuArr = [menuItems[0]];
      let hiddenCheckins = []

      if (company && new Date(company.date) < oldCompaniesDate) { 
        // Old companies
        menuArr = lang !== 'ru' ? menuItems.slice() : menuItemsRu.slice() 

      }
      
      if (customs.length > 0) {
        for (let i = 0; i < customs.length; i++) {
          let obj = {
            title: customs[i].name,
            url: `/campaigns/${customs[i]._id}`,
            label: customs[i].name,
            value: customs[i]._id.toString(),
            checkinType: 1,
            // _id: customs[i]._id
          }

          // console.log('======= user.lastName', user.lastName)
          // console.log('======= user.isAdmin', user.isAdmin)
          // customs && customs[i].visibility && customs[i].visibility.value !== 1 && console.log('======= customs[i].visibility', customs[i].visibility)

          // Check check-in's visibility and hide it, if necessary
          if (isVisible(customs[i], user)) { 
            menuArr.push(obj);
          } else {
            hiddenCheckins.push(obj)
          }
        }
      }
      // console.log('======= menuArr.length', menuArr.length)
      // console.log('======= hiddenCheckins', hiddenCheckins)

      let data = { menuArr, hiddenCheckins }
      
      // return menuArr;
      return data;
  
    } catch (err) {
      console.log('Error in getCheckinsMenu', err);
    }
  };


export const getCheckinsMenu = (customs, user={}) => {
  try {
    let menuArr = menuItems.slice();
    
    if (customs.length > 0) {
      for (let i = 0; i < customs.length; i++) {
        let obj = {
          title: customs[i].name,
          url: `/campaigns/${customs[i]._id}`,
          label: customs[i].name,
          value: customs[i]._id,
          checkinType: 1,
        }

        // Check check-in's visibility and hide it, if necessary
        if (isVisible(customs[i], user)) {
          menuArr.push(obj);
        }
      }
    }
    
    return menuArr;

  } catch (err) {
    console.log('Error in getCheckinsMenu', err);
  }
};
