import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const TreeKeyResult = ({
  keyResult: {
    owner,
    title,
    ownerFirstName,
    ownerAvatar,
    current,
    goal,
    progress,
  },
}) => {
  let color = '';
  let stroke = '';

  if (progress < 30) {
    color = "red";
    stroke = "#fe0909";
  } else if (progress >= 30 && progress < 60) {
    color = "yellow";
    stroke = "#f9d54e";
  } else if (progress >= 60) {
    color = "green";
    stroke = "#6ddeb3";
  }

  let userLink = owner ? `/user/${owner}` : "/company/people";

  return (
    <Fragment>
      <div className="kr flex space-between align-center mb-xs">
        <div className="kr__card-title card-answer mr-xs">
          <svg
            className="mr-1 mt-xs"
            height="12"
            viewBox="0 0 12 12"
            width="12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="6" cy="6" fill="none" r="5" stroke={stroke} />
          </svg>

          <p className="fs1_4 w-100">{title}</p>
        </div>

        <div className="kr__card-owner flex align-center justify-start mr-xs mx-1">
          <Link to={userLink} className="user mr-1">
            <img
              src={ownerAvatar && ownerAvatar}
              alt={`${ownerFirstName}'s picture`}
              className="round-img d-block avatar img-35"
              width="35"
              height="35"
            />
          </Link>
        </div>

        <div className="kr__card-goal mx-1 fs1_4 mr-xs">
          {current}{' '}/{' '}{goal}
        </div>
        <div className={`kr__card-result fs1_4 ${color}`}>{progress}%</div>
      </div>
    </Fragment>
  );
};

TreeKeyResult.propTypes = {
  keyResult: PropTypes.object,
};

export default TreeKeyResult;
