import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { icons } from '../../../img/elements/icons';

const AddQuestion = ({
  handleAdd
}) => {
  
  return (
   <div className="flex align-center space-between">
      <button
        type="button"
        className="btn-secondary-back-upd fw-600  flex align-center mb-2 px-1 blac"
        onClick={e => handleAdd(e)}
      >
        <span className="rounded-text-icon">{icons.circle_plus}</span>{' '}
        <FormattedMessage id="add.newquestion" defaultMessage="Add new question" />
      </button>
    </div>
  );
};

AddQuestion.propTypes = {
  handleAdd: PropTypes.func.isRequired
};

export default AddQuestion;
