import React from 'react';
import { FormattedMessage } from 'react-intl';

const likes1 = [
  {
    _id: "like1",
    user: "onboarding_creator1"
  },
  {
    _id: "like2",
    user: "onboarding_creator2"
  },
  {
    _id: "like3",
    user: "onboarding_creator3"
  }
]

const comment1 = [
  {
    _id: "comment1",
    user: "onboarding_creator1",
    text: (
      <FormattedMessage
        id="onboarding.recognition.text"
        defaultMessage="It was really great, Tom!"
      />
    ),
    firstName: (
      <FormattedMessage
        id="onboarding.okr.owner.paul"
        defaultMessage="Paul"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.owner.morton"
        defaultMessage="Morton"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar14.jpeg",
    date: "2020-03-17T09:46:33.402+00:00"
  }
]


export const onboardingRecognitions = [
  { 
    _id: "onboarding_recognition1",
    text: (
      <FormattedMessage
        id="onboarding.recognition.t1"
        defaultMessage="Tom, Petrol Motor's contract became real only with your help. Thank you so much for this awesome support during the sales cycle!"
      />
    ),
    giverFirstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName2"
        defaultMessage="Julian"
      />
    ),
    giverLastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName2"
        defaultMessage="Tobin"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar44.jpeg",
    giver: "onboarding_owner2",
    value: (
      <FormattedMessage
        id="onboarding.recognition.performance"
        defaultMessage="Performance"
      />
    ),
    recipientFirstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName1"
        defaultMessage="Tom"
      />
    ),
    recipientLastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName1"
        defaultMessage="Ford"
      />
    ),
    recipient: "onboarding_owner3",
    teamName: (
      <FormattedMessage
        id="onboarding.okr.product"
        defaultMessage="Product"
      />
    ),
    recipientDepartment: "onboarding_department1",
    recipientDepartmentName: (
        <FormattedMessage
        id="onboarding.okr.departmentName1"
        defaultMessage="Marketing"
      />
      ),
    company: "onboarding_company1",
    companyName: "onboarding_companyName1",
    date: "2020-03-11T07:36:33.402+00:00",
    likes: likes1,
    comments: comment1
  },
  { 
    _id: "onboarding_recognition2",
    text: (
      <FormattedMessage
        id="onboarding.recognition.t2"
        defaultMessage="Patrick, new website looks adorable! Really! I think it's your best work!"
      />
    ),
    giverFirstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName1"
        defaultMessage="Tom"
      />
    ),
    giverLastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName1"
        defaultMessage="Ford"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar52.jpeg",
    giver: "onboarding_owner2",
    value: (
      <FormattedMessage
        id="onboarding.recognition.design"
        defaultMessage="Design"
      />
    ),
    recipientFirstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName3"
        defaultMessage="Patrick"
      />
    ),
    recipientLastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName3"
        defaultMessage="Brody"
      />
    ),
    recipient: "onboarding_owner3",
    teamName: (
      <FormattedMessage
        id="onboarding.recogntion.team.design"
        defaultMessage="Design"
      />
    ),
    recipientDepartment: "onboarding_department4",
    recipientDepartmentName: (
        <FormattedMessage
        id="onboarding.okr.departmentName4"
        defaultMessage="Design"
      />
      ),
    company: "onboarding_company1",
    companyName: "onboarding_companyName1",
    date: "2020-03-20T07:36:33.402+00:00",
    likes: [],
    comments: []
  },
  { 
    _id: "onboarding_recognition3",
    text: (
      <FormattedMessage
        id="onboarding.recognition.t3"
        defaultMessage="Thanks you for creating the amazing design for our new website!"
      />
    ),
    giverFirstName: (
      <FormattedMessage
        id="onboarding.okr.owner.paul"
        defaultMessage="Paul"
      />
    ),
    giverLastName: (
      <FormattedMessage
        id="onboarding.okr.owner.morton"
        defaultMessage="Morton"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar14.jpeg",
    giver: "onboarding_owner2",
    value: (
      <FormattedMessage
        id="onboarding.recognition.design"
        defaultMessage="Design"
      />
    ),
    recipientFirstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName3"
        defaultMessage="Patrick"
      />
    ),
    recipientLastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName3"
        defaultMessage="Brody"
      />
    ),
    recipient: "onboarding_owner3",
    teamName: (
      <FormattedMessage
        id="onboarding.recogntion.team.design"
        defaultMessage="Design"
      />
    ),
    recipientDepartment: "onboarding_department4",
    recipientDepartmentName: (
        <FormattedMessage
        id="onboarding.okr.departmentName4"
        defaultMessage="Design"
      />
      ),
    company: "onboarding_company1",
    companyName: "onboarding_companyName1",
    date: "2020-04-04T07:36:33.402+00:00",
    likes: [],
    comments: []
  }
]

export default {
  onboardingRecognitions,
}