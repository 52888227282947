import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const OkrWidget = ({
  board,
  widgetData,
  width,
  bgColor,
  widgetsAmount,
}) => {

  let okrsTxt = <FormattedMessage id="okrs" defaultMessage="Objectives and Key Results"/>;

  return (
    <Fragment>
      <div className="dashboard-okrs__container flex space-between flex-column mb-0">
        {widgetData.map((item, index) => (
          <div
            key={`dwidgets_${index + 1}`}
            className={`dashboard-okrs__item flex align-center bg__white brd-transparent brd_left_${item.bgColor} brd_width__left_10 br-4 shadow`}
          >
            <p className={`dashboard-okrs__item__title xxl ${item.color ? item.color : ''}`}>{item.data}</p>
            <div className="dashboard-okrs__item__text">
              <p className="dashboard-okrs__item__text_title">{okrsTxt}</p>
              <p className="dashboard-okrs__item__text_status">{item.title}</p>
            </div>
          </div>
        ))} 
      </div>
    </Fragment>
  );
};

OkrWidget.propTypes = {
  board: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  bgColor: PropTypes.string,
  widgetData: PropTypes.array.isRequired,
};

export default OkrWidget;
