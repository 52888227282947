import axios from 'axios';
import { setAlert } from './alert';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ADD_OKR_CALL,
  DELETE_OKR_CALL,
  GET_OKR_CALL,
  OKR_CALLS_ERROR,
  CLEAR_OKR_CALLS,
  GET_ALL_OKR_CALLS,
  SWITCH_OKR_CALL_STATUS,
} from './types';


// Add new OKR call
export const addOkrCall = (
  formData,
  history
  ) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {

    const body = JSON.stringify(formData);

    const res = await axios.post(
      `/api/okrcalls/create`,
      body,
      config
    );

    dispatch({
      type: ADD_OKR_CALL,
      payload: res.data
    });

    dispatch(setAlert('The new Call for OKR creation was created', 'success', 3000));
    
    history.push('/settings')

  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: OKR_CALLS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit OKR call
export const editOkrCall = (formData, id, history) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify(formData);
    const res = await axios.put(`/api/okrcalls/edit/${id}`, body, config);

    dispatch({
      type: GET_OKR_CALL,
      payload: res.data
    });

    getOkrCall(id);

    dispatch(
      setAlert(
        <FormattedMessage
          id="alert.okrcall.edit"
          defaultMessage="Call for OKR creation was edited"
        />,
        'success'
      )
    );

    history.push('/settings')
    
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: OKR_CALLS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all companies OKR calls
export const getAllOkrCalls = () => async dispatch => {
  try {
    const res = await axios.get('/api/okrcalls');

    dispatch({
      type: GET_ALL_OKR_CALLS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: OKR_CALLS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get OKR call
export const getOkrCall = (id) => async dispatch => {
  try {
    const res = await axios.get(`/api/okrcalls/${id}`);

    dispatch({
      type: GET_OKR_CALL,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: OKR_CALLS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Switch ON/OFF OKR call status
export const toggleOkrCall = (id) => async dispatch => {
  try {
    const res = await axios.put(`/api/okrcalls/status/${id}`);

    dispatch({
      type: SWITCH_OKR_CALL_STATUS,
      payload: { id: res.data._id, active: res.data.active }
    });
  } catch (err) {
    dispatch({
      type: OKR_CALLS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Delete OKR call
export const deleteOkrCall = (
  id,
  history,
) => async dispatch => {

  try {
    const res = await axios.delete(`/api/okrcalls/${id}`);
    
    dispatch({
      type: DELETE_OKR_CALL,
      payload: res.data
    });

    dispatch(setAlert('Call for OKR creation was successfully deleted', 'success', 3000));
    
    history.push('/settings')
    
  } catch (err) {
    console.log('Error', err);
    const errors = err.response && err.response.data && err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: OKR_CALLS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Clear state
export const clearOkrCalls = () => async dispatch => {
  try {
   
    dispatch({
      type: CLEAR_OKR_CALLS
    });
  } catch (err) {
    dispatch({
      type: OKR_CALLS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
