import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import MainMenu from '../layout/Sidebar';
import { getCompanyTasks } from '../../actions/todo';
import { getCompanyProfiles } from '../../actions/profile';
import { getUserCompany } from '../../actions/company';
import {
  getCompanyMeetings,
  getUserMeetings,
  getSettingsById,
  addMeeting,
  editMeetingSettings,
  updateMeeting,
  completeMeetingItem,
  deleteMeetingItem,
  deleteMeeting,
  updateMeetingFollowup,
  deleteMeetingFollowUp,
} from '../../actions/oneOnOne';
import { getCompanySortedOkrs } from '../../actions/okr';
import Profile from './elems/Profile';
import MeetingDate from './elems/MeetingDate';
import OneAvatar from './elems/Avatar';
import NoContent from '../utils/NoContent';
import OneMain from './OneMain';
import OneSettings from './OneSettings';
import OneTopics from './elems/OneTopics';
import OneDashboard from './elems/OneDashboard';
import PrivateNotes from './elems/PrivateNotes';
import SubMenu from './elems/OneSubMenu';
import updateImg from '../../img/feature/no_content.svg';
import meetingImg from '../../img/feature/team_work3.svg';
import { oneCopy } from '../../utils/wording/oneOnOne';
import { pricingCopy } from '../../utils/wording/pricing';
import { FormattedMessage } from 'react-intl';
import { setAlert } from '../../actions/alert';
import ErrorBoundary from '../_helpers/ErrorBoundary';
import useAnalyticsEventTracker from '../../utils/analytics/useAnalyticsEventTracker';


const OneOnOne = ({
  getUserMeetings,
  addMeeting,
  editMeetingSettings,
  updateMeeting,
  completeMeetingItem,
  deleteMeetingItem,
  getUserCompany,
  getCompanySortedOkrs,
  getCompanyTasks,
  getCompanyProfiles,
  auth: { user, sidebar },
  okr: { okrs },
  company: { company },
  todo: { tasks, task, updated },
  oneOnOne: { meetings, loading },
  profile,
  locale,
  history,
  setAlert,
  updateMeetingFollowup,
  deleteMeetingFollowUp,
  useAnalyticsEventTracker,
}) => {
  const [menu, setMenu] = useState({
    profiles: [],
    currentProfile: '',
    docs: [],
    currentDoc: '',
    meetingID: '',
    settings: '',
    menuItem: 0,
  });

  const [formData, setFormData] = useState({
    creator: {},
    participant: {},
    todos: [],
    talkingPoints: [],
    notes: [],
    followUp: [],
    numberOneGoal: {},
    privateNotes: [],
    focus: {},
    updatedAt: '',
    isActive: false,
    isAutomation: false,
    isStartNotification: false,
    scheduleTime: '',
    scheduleDay: {},
    interval: '',
    timezone: '',
    selectedUser: {},
  });

  const [isForm, toggleForm] = useState(false);
  const [isLoader, toggleLoader] = useState(false);
  const [isSettings, toggleSettings] = useState(false);
  const [isFocusForm, setFocus] = useState(false);

  let {
    todos,
    followUp,
    isActive,
    isAutomation,
    isStartNotification,
    scheduleTime,
    scheduleDay,
    interval,
    timezone,
    selectedUser,
  } = formData;

  let {
    profiles,
    currentProfile,
    docs,
    currentDoc,
    meetingID,
    settings,
    menuItem,
  } = menu;

  let usersOptions = [];
  let okrOptions = [];

  // Перебор profiles и приведение данных к формату Селекта
  {
    profile.profiles &&
      profile.profiles.forEach((profile) => {

        let coworker = {
          value: profile._id,
          label: `${profile.firstName} ${profile.lastName}`,
          user: profile._id,
          firstName: profile.firstName,
          lastName: profile.lastName,
          avatar: profile.avatar,
          department: profile.department,
          departmentName: profile.departmentName,
          name: 'selectedUser',
          type: 3,
        };

        if (user && profile._id === user._id) {
          usersOptions.unshift(coworker);
          return;
        }

        usersOptions.push(coworker);
      });
  }

  // Формируем OKR варианты для селекта
  let noOkrMsg =
    locale && locale.lang !== 'ru' ? `Not connected to OKR` : `Не связан с OKR`;
  {
    okrs &&
      okrs.length > 0 &&
      okrs.forEach((okr, i) => {
        if (!okr.isArchive) {
          let objective = {
            value: okr._id,
            label: `🎯 ${okr.objective}`,
            okrId: okr._id,
            objective: okr.objective,
            text: okr.objective,
            focusIndex: 99,
            status: okr.status,
            progress: okr.progress,
            goal: 100,
            isKr: false,
            name: 'focus',
          };

          okrOptions.push(objective);

          let krs = okr.keyResults;

          if (krs.length > 0) {
            krs.forEach((kr, index) => {
              let item = {
                value: kr._id,
                label: ` - ${kr.title}`,
                okrId: okr._id,
                objective: okr.objective,
                text: kr.title,
                focusIndex: index,
                status: okr.status,
                progress: kr.progress,
                goal: kr.goal,
                current: kr.current,
                krId: kr._id,
                isKr: true,
                name: 'focus',
              };

              okrOptions.push(item);
            });
          }
        }

        if (i === okrs.length - 1) {
          let noOkr = {
            value: `noOkr-${okr._id}`,
            id: 0,
            label: noOkrMsg,
            isFocus: false,
            name: 'OKR',
          };
          okrOptions.unshift(noOkr);
        }
      });
  }

  // Removing duplicates in answers array based the object key 'standup' - to send only unique standup items to StandupTeamItem
  // Берем данные из стейта checkins, а не редакса standupAnswers, чтобы делать фильтр на странице
  let uniqueMeetings =
    meetings && meetings.length > 0
      ? meetings.filter((m) => m.settings === true)
      : [];

  // 1. если есть уники, то отправить их меню
  // - если уников нет, то текст Создай свою 1:1
  // 2. по дефолту загруз экрана текста (1:1 магия)
  // 3. если клик на юзера
  // - то показ последней встречи
  // - список встреч в датах

  const setCurrentData = async (settingsObj) => {
    setFormData({
      ...formData,
      creator: !currentDoc.creator ? {} : currentDoc.creator,
      participant: !currentDoc.participant ? {} : currentDoc.participant,
      todos: !currentDoc.todos ? [] : currentDoc.todos,
      talkingPoints: !currentDoc.talkingPoints ? [] : currentDoc.talkingPoints,
      notes: !currentDoc.notes ? [] : currentDoc.notes,
      numberOneGoal: !currentDoc.numberOneGoal ? {} : currentDoc.numberOneGoal,
      focus: !currentDoc.focus ? {} : currentDoc.focus,
      followUp: !currentDoc.followUp ? [] : currentDoc.followUp,
      privateNotes: !currentDoc.privateNotes ? [] : currentDoc.privateNotes,
      updatedAt: !currentDoc.updatedAt ? '' : currentDoc.updatedAt,
      isActive: settingsObj === undefined ? false : settingsObj.isActive,
      isAutomation:
        settingsObj === undefined ? false : settingsObj.isAutomation,
      isStartNotification:
        settingsObj === undefined ? false : settingsObj.isStartNotification,
      scheduleTime:
        settingsObj === undefined ? '10:00' : settingsObj.scheduleTime,
      scheduleDay:
        settingsObj === undefined
          ? { index: '1', day: 'Monday' }
          : settingsObj.scheduleDay,
      interval: settingsObj === undefined ? 'Weekly' : settingsObj.interval,
      timezone: settingsObj === undefined ? 'Etc/GMT' : settingsObj.timezone,
    });
  };

  useEffect(() => {
    getUserCompany();
    getCompanyProfiles();
    getUserMeetings();
    getCompanyTasks();
    getCompanySortedOkrs();

    let updatedSettings =
      meetings && currentDoc && currentDoc.meetingID
        ? meetings.filter(
            (item) => item._id.toString() === currentDoc.meetingID
          )
        : undefined;

    setMenu({
      ...menu,
      profiles: !uniqueMeetings ? [] : uniqueMeetings,
      docs:
        meetings && currentDoc && currentDoc.meetingID
          ? meetings.filter((item) => item.meetingID === currentDoc.meetingID)
          : docs,
      settings:
        meetings && updatedSettings && updatedSettings.length > 0
          ? updatedSettings[0]
          : settings,
    });

    let settingsObj =
      updatedSettings && updatedSettings.length > 0
        ? updatedSettings[0]
        : undefined;
    setCurrentData(settingsObj);
  }, [
    loading,
    getCompanyTasks,
    getUserCompany,
    getUserMeetings,
    getCompanyProfiles,
    getCompanySortedOkrs,
    uniqueMeetings && uniqueMeetings.length,
  ]);

  useEffect(() => {
    setCurrentData(settings);

    if (currentDoc && currentDoc.meetingID) {
      let profileIndex =
        profiles &&
        profiles.length > 0 &&
        profiles
          .map((item) => item && item._id && item._id.toString())
          .indexOf(currentDoc.meetingID.toString());

      if (profileIndex === -1 || profiles.length === 0) {
        getUserMeetings();
      }
    }
  }, [currentDoc && currentDoc._id]);

  const getFormDefaultData = async (lastMeeting, selectedOption) => {
  
    if (lastMeeting && lastMeeting._id) {
      setFormData({
        ...formData,
        selectedUser: selectedOption,
        todos: !lastMeeting.todos
          ? []
          : lastMeeting.todos.filter((item) => !item.isCompleted),
        talkingPoints: !lastMeeting.talkingPoints
          ? []
          : lastMeeting.talkingPoints.filter((item) => !item.isCompleted),
        focus: !lastMeeting.focus ? {} : lastMeeting.focus,
        numberOneGoal: {},
        notes: [],
      });
    } else {
      // Обнуляем форму
      setFormData({
        ...formData,
        selectedUser: selectedOption,
        todos: [],
        talkingPoints: [],
        focus: {},
      });
    }
  };

  const handleSelect = async (
    itemId,
    selectedOption,
    name,
    index,
    inputName
  ) => {

    let res;
    let obj = {};
    obj[name] = formData[name][index];

    // For objects like numberOneGoal (not arrays)
    if (index === 99) {
      obj[name] = formData[name];
    }

    obj[name]['type'] = inputName; // for changing Todo model

    let id = currentDoc ? currentDoc._id : 0;
    let items = formData[name];

    if (!isForm) {
      // API request
      res = await updateMeeting(id, obj);
    } else {
      // Change only state
      if (index !== 99) {
        // Arrays
        setFormData({
          ...formData,
          [name]: items.map((item) => (item._id === id ? selectedOption : item)),
        });
      } else {
        // Objects
        setFormData({
          ...formData,
          [name]: items,
        });
      }
    }

    // Update docs
    if (!isForm) {
      setMenu({
        ...menu,
        docs: docs.map((item) => (item._id === res.id ? res.data : item)),
      });
    } else {
      setMenu({
        ...menu,
        docs: docs.map((item) => {
          if (item._id === id) { 
            item[name] = items;
            return item;
          } else {
            return item;
          }
        })
      });
    }
  };

  const handleSelectFollowUp = async (
    itemId,
    selectedOption,
    name,
    index,
    inputName,
    followupPlan
  ) => {

    let res;
    let obj = {};
    obj[name] = formData[name][index];
    obj[name]['type'] = followupPlan;
    let items = formData[name];
    let id = currentDoc ? currentDoc._id : 0;

    if (!isForm) {
      // API request
      res = await updateMeetingFollowup(currentDoc._id, obj);
    } else {
      // Change only state
      items[index][followupPlan] = selectedOption;

      setFormData({
        ...formData,
        [name]: items,
      });
    }

    // Update docs
    if (!isForm) {
      setMenu({
        ...menu,
        docs: docs.map((item) => (item._id === res.id ? res.data : item)),
      });
    } else {
      setMenu({
        ...menu,
        docs: docs.map((item) => {
          if (item._id === id) { 
            item[name] = items;
            return item;
          } else {
            return item;
          }
        })
      });
    }
  };

  const handleChangeForm = async (
    itemId,
    selectedOption,
    index,
    type,
    name
  ) => {
    let obj = {};
    let items = formData[name];
    let id = currentDoc ? currentDoc._id : 0;

    if (name !== 'numberOneGoal') {
      // Arrays
      items[index][type] = selectedOption;
      obj[name] = formData[name][index];
      obj[name]['type'] = type;

      const res = await updateMeeting(id, obj);
     
      setFormData({
        ...formData,
        [name]: formData[name].map((item) =>
          item._id === res.id ? res.data[name][index] : item
        ),
      });
    } else {
      // Not array
      items[type] = selectedOption;

      const res = await updateMeeting(id, obj);
   
      setFormData({
        ...formData,
        [name]: res.data[name],
      });
    }
  };

  // For Form (creare 1:1, not update it)
  // Handle select without sending request to server (if they haven't created an item)
  const handleChangeFormState = async (selectedOption, index, type, name) => {
    let items = formData[name];

    if (name !== 'numberOneGoal') {
      // For arrays
      items[index][type] = selectedOption;
    } else {
      // not arrays
      items[type] = selectedOption;
    }
    // Update only state
    setFormData({ ...formData, [name]: items });
  };

  // For inputs without arrays
  const handleChangeFormSingle = async (selectedOption, type, name, id) => {

    let obj = {};
    obj[type] = selectedOption;
    let items = formData[name];
    items[type] = selectedOption;

    if (!isForm) {
      // Send API request
      const res = await updateMeeting(id, obj);
      let testMeeting = meetings.map((item) =>
        item._id === id ? items[type] : item
      );

      setFormData({
        ...formData,
        [name]: items.map((item) => (item._id === res.id ? res.data : item)),
      });
    } else {
      // Change only state (for forms because we send API request when they click on the button)
      setFormData({ ...formData, [name]: items });
    }
  };

  const handleChangeFollowUpState = async (
    selectedOption,
    index,
    type,
    name,
    planFact
  ) => {
    let items = formData[name];
    
    items[index][planFact]['text'] = selectedOption;

    // Update only state
    setFormData({ ...formData, [name]: items });
  };

  // For checkboxes without objects
  const handleCheckbox = async (selectedOption, name, id) => {
    let type = name;
    let obj = {};
    obj[type] = !formData[name];
    let items = !formData[name];
    
    if (!isForm) {
      // Send API request
      // без ожидания от сервера. все делаем в форме.
      setFormData({
        ...formData,
        [name]: items,
      });
    } else {
      // Change only state (for forms because we send API request when they click on the button)
      setFormData({ ...formData, [name]: items });
    }
  };

  const handleNameForm = async (e, index, id, name, inputName) => {
    // if tasks or completed
    // Tasks (List View)
    if (name !== 'numberOneGoal') {
      // Arrays
      formData[name][index][inputName] = e.target.value;
    } else {
      // Not array
      formData[name][inputName] = e.target.value;
    }

    setFormData({
      ...formData,
      [name]: formData[name],
    });
  };

  // No index in state (not for arrays)
  const handleNameFormSingle = async (e, index, name, inputName) => {
    // if tasks or completed
    // Tasks (List View)
    formData[name][inputName] = e.target.value;
    let items = formData[name];

    setFormData({ ...formData, [name]: items });
  };

  const handleDueIn = async (id, selectedOption) => {
    let obj = { dueIn: selectedOption };

    // Update todos state
    setFormData({
      ...formData,
      todos: todos.map((item) => (item._id === id ? selectedOption : item)),
    });
  };

  const clearTodo = (index, name, followupPlan) => {

    let items = formData[name];

    if (index !== 99) {
      // For arrays

      if (name !== 'followUp') {
        // not follow ups
        items.splice(index, 1);
        setFormData({ ...formData, [name]: items });
      } else {
        // For follow ups (another structure with plan/fact objects)
        items[index][followupPlan].text = '';
        setFormData({ ...formData, [name]: items });
      }
    } else {
      // Not array
      if (name === 'numberOneGoal') {
        items = {};
      }

      setFormData({ ...formData, [name]: items });
    }

    if (isFocusForm === true) {
      setFocus(!isFocusForm);
    }
  };

  const handleDelete = async (itemId, index, name) => {
    try {
      let obj = {};
      obj[name] = {};
      obj[name]['_id'] = itemId;

      if (index !== 99) {
        obj[name] = formData[name][index]; // to get taskId for todos
      }

      let id = currentDoc ? currentDoc._id : 0;
      let items = formData[name];
      let res = await deleteMeetingItem(id, obj);

      if (index !== 99) {
        // Arrays
        items = items.filter((item) => item._id !== undefined && item._id.toString() !== itemId.toString());
        setFormData({
          ...formData,
          [name]: items,
        });
      } else {
        // not arrays
        items = {};

        setFormData({
          ...formData,
          [name]: {},
        });
      }

      // Update docs
      if (!isForm) {
        setMenu({
          ...menu,
          docs: docs.map((item) => (item._id === res.id ? res.data : item)),
        });
      } else {
        setMenu({
          ...menu,
          docs: docs.map((item) => {
            if (item._id === id) { 
              item[name] = items;
              return item;
            } else {
              return item;
            }
          })
        });
      }
    } catch (err) {
      console.log('Error in delete task', err);
    }
  };

  const handleDeleteFollowUp = async (itemId, index, name, followupPlan) => {
    try {
      
      let obj = {};
      obj[name] = {};
      obj[name]['type'] = followupPlan;
      obj[name]['_id'] = itemId;

      let id = currentDoc ? currentDoc._id : 0;

      let items = formData[name];

      let res = await deleteMeetingFollowUp(id, obj);

      if (res && res.ok) {

        items[index][followupPlan] = {
          isNew: true,
        };

        setFormData({ ...formData, [name]: items });

        // Update docs
        // Better performance
        if (!isForm) {
          setMenu({
            ...menu,
            docs: docs.map((item) => (item._id === res.id ? res.data : item)),
          });
        } else {
          setMenu({
            ...menu,
            docs: docs.map((item) => {
              if (item._id === id) { 
                item[name] = items;
                return item;
              } else {
                return item;
              }
            })
          });
        }
      }

    } catch (err) {
      console.log('Error in delete task', err);
    }
  };

  const handleCancel = async (e) => {
    e.preventDefault();

    setCurrentData(settings);

    toggleForm(!isForm);
  };

  const handleAdd = (e, name) => {
    e.preventDefault();

    let items = formData[name];

    if (name !== 'numberOneGoal') {
      // For arrays
      let item = { isNew: true, dueIn: null };

      if (name === 'notes') {
        item = {
          isNew: true,
          dueIn: null,
          firstName: user.firstName,
          lastName: user.lastName,
          avatar: user.avatar,
          user: user._id,
          date: new Date(),
        };
      } else if (name === 'followUp') {
        item = {
          isNew: true,
          plan: {
            isNew: true,
          },
          fact: {
            isNew: true,
          },
        };
      } else if (name === 'privateNotes') {
        item = {
          isNew: true,
          firstName: user.firstName,
          lastName: user.lastName,
          avatar: user.avatar,
          user: user._id,
          date: new Date(),
        };
      }
      items.push(item);

      setFormData({ ...formData, [name]: [...items] });
    } else {
      // Not fo arrays
      let obj = { isNew: true, dueIn: false, isCompleted: false };

      setFormData({
        ...formData,
        numberOneGoal: { isNew: true, dueIn: false, isCompleted: false },
      });
    }
  };

  const handleAddFollowUp = (index, planFact) => {
    let items = followUp;

    items[index][planFact] = { isNew: true, index };

    setFormData({ ...formData, followUp: items });
  };

  const handleAddBtn = (e) => {
    e.preventDefault();

    if (!isForm) {
      toggleForm(!isForm);

      setFormData({ ...formData, selectedUser: currentProfile });

      getFormDefaultData(docs[0], currentProfile);

      setMenu({
        ...menu,
        menuItem: 0,
      });
    }
  };

  const handleUserClick = async (selectedProfile, meetingID) => {
    let userDocs = meetings.filter((item) => item.meetingID === meetingID);
    let updatedSettings = meetings.filter(
      (item) => item._id.toString() === meetingID
    );

    // Лучше записывать в currentProfile оригинал юзера, чтобы всегда было актуальное фото и имя
    setMenu({
      ...menu,
      currentProfile: profile.profiles.filter(
        (item) => item._id.toString() === selectedProfile.user.toString()
      )[0],
      docs: userDocs,
      currentDoc: userDocs[0],
      meetingID: userDocs.length > 0 ? userDocs[0].meetingID : null,
      settings: updatedSettings.length > 0 ? updatedSettings[0] : {},
      menuItem: 0,
    });

    if (isForm === true) {
      toggleForm(!isForm);
    }
    if (isFocusForm === true) {
      setFocus(!isFocusForm);
    }
  };

  const handleMeetingClick = async (id) => {

    if (isForm === true) {
      toggleForm(!isForm);

      if (currentDoc && currentDoc._id.toString() === id) {
        setCurrentData(settings);
      }
    }

    if (isFocusForm === true) {
      setFocus(!isFocusForm);
    }

    setMenu({
      ...menu,
      currentDoc: docs.filter((item) => item._id === id)[0],
      menuItem: 0,
    });
  };

  const handleMenuClick = async (e, index) => {
    setMenu({ ...menu, menuItem: index });
  };

  // Return to initial state (click on Main)
  const handleMainClick = async () => {
    setMenu({
      ...menu,
      currentProfile: "",
      docs: [],
      currentDoc: "",
      meetingID: "",
      settings: "",
      menuItem: 0,
    });

    if (isForm === true) {
      toggleForm(!isForm);
    }
    if (isFocusForm === true) {
      setFocus(!isFocusForm);
    }
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({ ...formData, [selectedOption.name]: selectedOption });

    if (selectedOption.name === 'selectedUser') {

      let select = selectedOption.user.toString();
      let authUser = user._id.toString();

      let userSettings = uniqueMeetings.filter(
        (u) =>
          (u.creator.user.toString() === select &&
            u.creator.user !== authUser) ||
          (u.participant.user.toString() === select &&
            u.participant.user !== authUser) ||
          (u.creator.user.toString() === select &&
            u.participant.user.toString() === select)
      );

      let docsState =
        meetings && meetings.length > 0 && userSettings.length > 0
          ? meetings.filter(
              (item) => item.meetingID === userSettings[0]._id.toString()
            )
          : [];

      setMenu({
        ...menu,
        currentProfile: profile.profiles.filter(
          (item) => item._id.toString() === select
        )[0],
        meetingID: userSettings.length > 0 ? userSettings[0]._id : null,
        docs: docsState,
      });

      // Populate form if the user has already had meetings with this participant
      let docStateObj = docsState[0];
      if (docsState.length === 0) {
        docStateObj = undefined;
      }
      getFormDefaultData(docStateObj, selectedOption);
    }
  };

  const handleComplete = async (e, itemId, index, isCompleted, name) => {
    e.preventDefault();

    // Update state
    let res;
    let obj = {};
    obj[name] = formData[name][index];

    // For objects like numberOneGoal (not arrays)
    if (index === 99) {
      obj[name] = formData[name];
    }

    obj[name]['type'] = name; // for changing Todo model
    obj[name]['isCompleted'] = isCompleted;

    let id = currentDoc ? currentDoc._id : 0;

    if (!isForm) {
      // Api call
      res = await completeMeetingItem(id, obj);

    } else {
      // Only state
      let arr = formData[name];
      arr[index].isCompleted = isCompleted;

      if (index !== 99) {
        // Arrays

        setFormData({
          ...formData,
          [formData.name[index]]: obj,
        });
      } else {
        // Objects
        setFormData({ ...formData, [name]: obj });
      }
    }
  };

  const onSubmitTodo = async (e, index, name, followupPlan) => {
    e.preventDefault();

    let items = formData[name];

    let res;
    let obj = {};
    obj[name] = formData[name];
    let id = currentDoc ? currentDoc._id : 0;

    if (index !== 99) {
      // For arrays
      if (!isForm) {
        // Send only changed item
        obj[name] = formData[name][index];

        // specify type for follow ups and send to another route
        if (name !== 'followUp') {
          res = await updateMeeting(currentDoc._id, obj);
        } else {
          obj[name]['type'] = followupPlan;
          res = await updateMeetingFollowup(currentDoc._id, obj);
        }
      }

      items[index].isNew = false;

      // Don't additional item for notes
      if (name !== 'notes') {
        items.push({ isNew: true, dueIn: null });
      }

      if (!isForm && res && res.data) {

        // API
        setFormData({
          ...formData,
          [name]: res.data[name],
        });
      } else {

        // Only state
        setFormData({ ...formData, [name]: [...items] });

      }
    } else {
      // For not arrays
      if (!isForm) {

        res = await updateMeeting(currentDoc._id, obj);
      }

      if (name !== 'focus') {
        items.isNew = false;
      }
    }

    if (isFocusForm) {
      setFocus(!isFocusForm);
    }

    if (!isForm) {
      setMenu({
        ...menu,
        docs: docs.map((item) => (item._id === res.id ? res.data : item)),
      });
    } else {
      setMenu({
        ...menu,
        docs: docs.map((item) => {
          if (item._id === id) { 
            item[name] = items;
            return item;
          } else {
            return item;
          }
        })
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let objectLength = 0;
    if (selectedUser !== undefined && selectedUser !== null) {
      objectLength = Object.keys(selectedUser).length;
    }

    if (objectLength === 0) {
      setAlert(
        <FormattedMessage
          id='alert.specify.teammate'
          defaultMessage='Please specify a teammate for 1:1 meeting'
        />,
        'danger'
      );
    } else {
      // Show Loader
      toggleLoader(!isLoader);

      // Create
      const res = await addMeeting(formData, menu.meetingID, history);

      if (res && res.data) {
        toggleForm(!isForm);

        setMenu({
          ...menu,
          currentDoc: res.data,
          docs: [res.data, ...docs],
          meetingID: res.data.meetingID,
        });

        // Hide Loader
        toggleLoader(isLoader);
        useAnalyticsEventTracker('meeting_created', 'meeting created', 'Meeting Created');
      }
    }
  };

  const onSubmitSettings = async (e) => {
    e.preventDefault();

    let obj = {
      isActive,
      isAutomation,
      isStartNotification,
      scheduleTime,
      scheduleDay,
      interval,
      timezone,
    };

    if (isActive && scheduleDay === undefined) {
      return setAlert(
        <FormattedMessage
          id='alert.specify.scheduleDay'
          defaultMessage='Please choose a day for 1:1 meeting'
        />,
        'danger'
      );
    }

    let res = await editMeetingSettings(meetingID, obj);

    if (res && res.status) {
      setMenu({
        ...menu,
        menuItem: 0,
        settings: res.data,
        profiles: menu.profiles.map(profile => {
          if (profile._id === meetingID) {
            // Update profile's schedule
            profile.scheduleTime = res.data.scheduleTime;
            profile.scheduleDay = res.data.scheduleDay;
            return profile;
          } else {
            return profile;
          }
        })
      });
    }
  };

  // Settings schedule
  const onChangeTimezone = (timezone, name) => {

    setFormData({ ...formData, [name]: timezone });
  };

  const handleTime = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onChangeDay = (e) => {

    let selected = e.target.selectedOptions[0];

    let schedule = scheduleDay;

    if (scheduleDay == undefined) { schedule = {} }
    schedule.day = selected.text;
    schedule.index = e.target.value;

    setFormData({ ...formData, scheduleDay: schedule });
  };
  const onChangeDate = (e) => {
    scheduleDay.index = e.target.value;

    setFormData({ ...formData, scheduleDay: scheduleDay });
  };

  const discussion = {
    title: <FormattedMessage id='meeting' defaultMessage='Meeting' />,
    url: '/one_on_one',
  };
  const privateNotesMsg = {
    title: (
      <FormattedMessage id='private.notes' defaultMessage='Private Notes' />
    ),
    url: '/one_on_one/private',
  };
  const suggestedTopics = {
    title: (
      <FormattedMessage
        id='suggestedTopics'
        defaultMessage='Suggested Topics'
      />
    ),
    url: '/one_on_one/topics',
  };
  const feedbackMsg = {
    title: <FormattedMessage id='feedback' defaultMessage='Feedback' />,
    url: '/one_on_one/feedback',
  };
  const settingsMsg = {
    title: <FormattedMessage id='settings' defaultMessage='Settings' />,
    url: '/one_on_one/settings',
  };
  const addMeetingMsg = (
    <FormattedMessage id='add.meeting' defaultMessage='+ Add Meeting' />
  );

  const menuItems = [discussion, privateNotesMsg, suggestedTopics, settingsMsg];

  // console.log('=== menuCollapsed', menuCollapsed)

  return (
    <Fragment>
      <div className='css-main bg__white'>
        <div className='wrapper'>

          {user && !loading && (
              <MainMenu activeIndex={11} />
          )}
          
          <UserNav background="bg__white" />

          <Fragment>
            {loading || !user || !company || !meetings ? (
              <Spinner />
            ) : (
              <Fragment>
                {/* <div className={`${!menuCollapsed ? 'ml-280' : 'short_menu__ml'} ma-w90 main relative pl-3 pr-3 pb-3`}> */}
                <div className={`${sidebar === 1 ? 'ml-280' : 'short_menu__ml'} ma-w90 main relative pl-3 pr-3 pb-3`}>
                {/* <div className='main-dashboard main relative pl-3 pr-3 pb-3 mxa'> */}
                  <div className='content flex'>
                    <div className='left__menu w-200px pl-1'>
                      <div className='add-meeting pb-2'>
                        <button
                          className='btn btn-title px-2 py-1'
                          onClick={(e) => handleAddBtn(e)}
                        >
                          {addMeetingMsg}
                        </button>
                      </div>

                      <div 
                        className="small grey cursor pb-1"
                        onClick={() => handleMainClick()}
                      >
                        <FormattedMessage
                          id='main'
                          defaultMessage='Main'
                        />
                      </div>

                      {uniqueMeetings &&
                        uniqueMeetings.length > 0 &&
                        uniqueMeetings.map((item) => (
                          <Fragment key={`users-1_1-${item._id}`}>
                            {item.creator.user.toString() !==
                            user._id.toString() ? (
                              <Profile
                                profile={item.creator}
                                onClick={handleUserClick}
                                currentProfile={currentProfile}
                                meetingID={item._id}
                                isForm={isForm}
                              />
                            ) : (
                              <Profile
                                profile={item.participant}
                                onClick={handleUserClick}
                                currentProfile={currentProfile}
                                meetingID={item._id}
                                isForm={isForm}
                              />
                            )}
                          </Fragment>
                        ))}

                      <div className='w-90 line bg-primary_light2'></div>

                      <MeetingDate
                        docs={docs}
                        onClick={handleMeetingClick}
                        currentDoc={currentDoc}
                        isForm={isForm}
                      />
                    </div>

                    <div className='line-vertical bg-primary_light2'></div>

                    <div className='w-80 right pl-4'>
                      <ErrorBoundary>
                        {company.plan > 3 ? (
                          <Fragment>
                            {profiles.length === 0 && !isForm ? (
                              <NoContent
                                message={oneCopy.noMeetings}
                                picture={meetingImg}
                                imgWidth={'w-300px'}
                                alt={'Create your first 1:1'}
                              />
                            ) : !currentProfile && !isForm ? (
                              <OneDashboard
                                profiles={profiles}
                                user={user}
                                handleUserClick={handleUserClick}
                                locale={locale}
                              />
                            ) : (
                              <Fragment>
                                <OneAvatar user={currentProfile} />

                                <SubMenu
                                  items={menuItems}
                                  activeIndex={menuItem}
                                  mb={'1'}
                                  onClick={handleMenuClick}
                                />

                                {menuItem === 0 && (
                                  <OneMain
                                    formData={formData}
                                    currentProfile={currentProfile}
                                    currentDoc={currentDoc}
                                    docs={docs}
                                    userOptions={usersOptions}
                                    profilesState={profile.profiles}
                                    handleSelectChange={handleSelectChange}
                                    isForm={isForm}
                                    toggleForm={toggleForm}
                                    meetingID={meetingID}
                                    okrOptions={okrOptions}
                                    handleAdd={handleAdd}
                                    handleChangeForm={handleChangeForm}
                                    handleChangeFormState={
                                      handleChangeFormState
                                    }
                                    handleChangeFormSingle={
                                      handleChangeFormSingle
                                    }
                                    onSubmitTodo={onSubmitTodo}
                                    clearTodo={clearTodo}
                                    handleComplete={handleComplete}
                                    handleNameForm={handleNameForm}
                                    handleNameFormSingle={handleNameFormSingle}
                                    handleSelect={handleSelect}
                                    handleDelete={handleDelete}
                                    handleDueIn={handleDueIn}
                                    user={user}
                                    onSubmit={onSubmit}
                                    isLoader={isLoader}
                                    toggleSettings={toggleSettings}
                                    isSettings={isSettings}
                                    handleCheckbox={handleCheckbox}
                                    onChangeTimezone={onChangeTimezone}
                                    handleTime={handleTime}
                                    onChangeDay={onChangeDay}
                                    onChangeDate={onChangeDate}
                                    okrs={okrs}
                                    isFocusForm={isFocusForm}
                                    setFocus={setFocus}
                                    onSubmitSettings={onSubmitSettings}
                                    handleCancel={handleCancel}
                                    handleChangeFollowUpState={
                                      handleChangeFollowUpState
                                    }
                                    handleDeleteFollowUp={handleDeleteFollowUp}
                                    handleSelectFollowUp={handleSelectFollowUp}
                                    handleAddFollowUp={handleAddFollowUp}
                                  />
                                )}

                                {menuItem === 1 && (
                                  <PrivateNotes
                                    formData={formData}
                                    handleSelect={handleSelect}
                                    user={user}
                                    handleDelete={handleDelete}
                                    handleNameForm={handleNameForm}
                                    handleChangeForm={handleChangeForm}
                                    handleChangeFormState={handleChangeFormState}
                                    clearTodo={clearTodo}
                                    isForm={isForm}
                                    currentDoc={currentDoc}
                                    onSubmitTodo={onSubmitTodo}
                                    handleAdd={handleAdd}
                                  />
                                )}

                                {menuItem === 2 && (
                                  <OneTopics
                                    lang={locale.lang}
                                  />
                                )}

                                {menuItem === 3 && (
                                  <OneSettings
                                    formData={formData}
                                    setFormData={setFormData}
                                    isForm={formData}
                                    isSettings={isSettings}
                                    isLoader={isLoader}
                                    currentDoc={currentDoc}
                                    handleCheckbox={handleCheckbox}
                                    onChangeTimezone={onChangeTimezone}
                                    handleTime={handleTime}
                                    onChangeDay={onChangeDay}
                                    onChangeDate={onChangeDate}
                                    toggleSettings={toggleSettings}
                                    onSubmit={onSubmitSettings}
                                    isHideSettings={false}
                                  />
                                )}
                              </Fragment>
                            )}
                          </Fragment>
                        ) : (
                          <NoContent
                            message={pricingCopy.updatePlanMsg}
                            picture={updateImg}
                            imgWidth={'w-300px'}
                            alt={'Update plan'}
                            admin={
                              user.role === 'Owner' ||
                              user.role === 'Admin' ||
                              user.role === 'Superadmin'
                                ? 1
                                : 0
                            }
                          />
                        )}
                      </ErrorBoundary>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

OneOnOne.propTypes = {
  getCompanyProfiles: PropTypes.func.isRequired,
  getCompanyTasks: PropTypes.func.isRequired,
  getCompanyMeetings: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getUserMeetings: PropTypes.func.isRequired,
  getSettingsById: PropTypes.func.isRequired,
  addMeeting: PropTypes.func.isRequired,
  editMeetingSettings: PropTypes.func.isRequired,
  updateMeeting: PropTypes.func.isRequired,
  completeMeetingItem: PropTypes.func.isRequired,
  deleteMeetingItem: PropTypes.func.isRequired,
  deleteMeeting: PropTypes.func.isRequired,
  getCompanySortedOkrs: PropTypes.func.isRequired,
  updateMeetingFollowup: PropTypes.func.isRequired,
  deleteMeetingFollowUp: PropTypes.func.isRequired,
  useAnalyticsEventTracker: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  todo: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  oneOnOne: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  todo: state.todo,
  locale: state.locale,
  oneOnOne: state.oneOnOne,
  okr: state.okr,
});

export default connect(mapStateToProps, {
  getUserCompany,
  getCompanyProfiles,
  getCompanyTasks,
  setAlert,
  getCompanyMeetings,
  getUserMeetings,
  getSettingsById,
  addMeeting,
  editMeetingSettings,
  updateMeeting,
  completeMeetingItem,
  deleteMeetingItem,
  deleteMeeting,
  getCompanySortedOkrs,
  updateMeetingFollowup,
  deleteMeetingFollowUp,
  useAnalyticsEventTracker,
})(OneOnOne);
