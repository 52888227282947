const generateCsv = data => {
  let results = []

  for (let i = 0; i < data.length; i++) {
    let okr = data[i];

    let status = okr.status === 1 ? 'On Track' : okr.status === 2 ? "Behind" : "At Risk"
    let keyResults = '';

    for (let y = 0; y < okr.keyResults.length; y++) {
      let kr = okr.keyResults[y];
      
      let krItem = `
        KR${y+1}: ${kr.title}
        Owner: ${kr.ownerFirstName} ${kr.ownerLastName}
        Start: ${kr.start}
        Current value: ${kr.current}
        Goal: ${kr.goal}
        Progress: ${kr.progress}%
        
      `
      keyResults += krItem;
    }

    let obj = {
      "Objective": okr.objective,
      "Company": okr.companyName,
      "Owner": `${okr.ownerFirstName} ${okr.ownerLastName}`,
      "OKR's Team": okr.teamName, 
      "Status": status,
      "Progress": okr.progress,
      "Key Results": keyResults,
      "Due Date": okr.dueIn,
      "Last update": okr.updatedAt,
    }

    results.push(obj)
  }

  return results;
};

export default generateCsv;