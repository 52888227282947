import axios from 'axios';
import { setAlert } from './alert';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ADD_CUSTOM,
  DELETE_CUSTOM,
  GET_CUSTOM_SETTINGS,
  CUSTOMS_ERROR,
  CLEAR_CUSTOMS,
  GET_ALL_CUSTOMS,
  SWITCH_CUSTOM_STATUS,
  CUSTOMS_SETTINGS_ERROR,
  CLEAR_CUSTOMS_SETTINGS,
  GET_STANDUP_SETTINGS_IN_CUSTOM,
  GET_PLANS_SETTINGS_IN_CUSTOM,
  GET_ALL_ACTIVE_SETTINGS,
  PREPARE_FIRST_CUSTOM,
  GET_ONBOARDING_CUSTOM,
  REMOVE_ONBOARDING_CUSTOM,
  CHECKIN_MODAL_ERROR,
  HIDE_CHECKIN_MODAL,
} from './types';


// Get data from LS for 1st custom
export const clearCustomOnboarding = () => async dispatch => {
  try {
    console.log('==== we are in clearCustomOnboarding')

    dispatch({
      type: REMOVE_ONBOARDING_CUSTOM
    });

  } catch (err) {
    dispatch({
      type: CUSTOMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add new custom campaign
export const addCustom = (
  formData,
  history,
  isOnboarding = false
) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {

    const body = JSON.stringify(formData);

    const res = await axios.post(
      `/api/customs/create`,
      body,
      config
    );

    dispatch({
      type: ADD_CUSTOM,
      payload: res.data
    });

    dispatch(setAlert('The new check-in was created', 'success', 3000));
    
    if (!isOnboarding) {
      history.push('/settings')
    } else {
      dispatch({
        type: REMOVE_ONBOARDING_CUSTOM
      });

      history.push('/welcome/checkins/5');
    }

  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: CUSTOMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit custom campaign
export const editCustom = (formData, id, history) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify(formData);
    const res = await axios.put(`/api/customs/edit/${id}`, body, config);

    dispatch({
      type: GET_CUSTOM_SETTINGS,
      payload: res.data
    });

    getCustomSettings(id);

    dispatch(
      setAlert(
        <FormattedMessage
          id="alert.campaign.edit"
          defaultMessage="Campaign was edited"
        />,
        'success'
      )
    );

    history.push('/settings')
    
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: CUSTOMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all companies campaigns
export const getAllCustoms = () => async dispatch => {
  try {
    const res = await axios.get('/api/customs/settings');

    dispatch({
      type: GET_ALL_CUSTOMS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CUSTOMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all ACTIVE companies campaigns
export const getAllActiveCustoms = () => async dispatch => {
  try {
    const res = await axios.get('/api/customs/active/settings');

    dispatch({
      type: GET_ALL_CUSTOMS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CUSTOMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get customs settings
export const getCustomSettings = (id) => async dispatch => {
  try {
    const res = await axios.get(`/api/customs/settings/${id}`);

    dispatch({
      type: GET_CUSTOM_SETTINGS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CUSTOMS_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// // Get customs settings
// export const getStandupsSettings = (limit) => async dispatch => {
//   try {
//     const res = await axios.get(`/api/standups${limit?'?limit='+limit:''}`);

//     dispatch({
//       type: GET_STANDUP_SETTINGS_IN_CUSTOM,
//       payload: res.data
//     });
//   } catch (err) {
//     dispatch({
//       type: CUSTOMS_SETTINGS_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
// };

// Get customs settings
export const getStandupsSettings = () => async dispatch => {
  try {
    // console.log('======= start getStandupsSettings')

    const res = await axios.get(`/api/standups/settings`);
    // console.log('======= res.data in getStandupsSettings', res.data)

    dispatch({
      type: GET_STANDUP_SETTINGS_IN_CUSTOM,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CUSTOMS_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get plans settings
export const getPlanSettings = () => async dispatch => {
  try {
    const res = await axios.get('/api/plans/settings');

    dispatch({
      type: GET_PLANS_SETTINGS_IN_CUSTOM,
      payload: res.data
    });
  } catch (err) {
    console.log('===== err in custom getPlanSettings', err);

    dispatch({
      type: CUSTOMS_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get plans settings
export const getAllSettings = () => async dispatch => {
  try {
    const res = await axios.get('/api/customs/oldcheckins/settings/active');
    // console.log('===== res in custom getAllSettings', res);

    dispatch({
      type: GET_ALL_ACTIVE_SETTINGS,
      payload: res.data
    });
  } catch (err) {
    console.log('===== err in custom getAllSettings', err);

    dispatch({
      type: CUSTOMS_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Switch ON/OFF campaign status
export const toggleCustomSettings = (id) => async dispatch => {
  try {
    const res = await axios.put(`/api/customs/status/${id}`);

    dispatch({
      type: SWITCH_CUSTOM_STATUS,
      payload: { id: res.data._id, active: res.data.active }
    });
  } catch (err) {
    dispatch({
      type: CUSTOMS_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};



// Add new custom campaign
export const deleteCustom = (
  id,
  history,
) => async dispatch => {

  try {
    const res = await axios.delete(`/api/customs/${id}`);
    
    dispatch({
      type: DELETE_CUSTOM,
      payload: res.data
    });

    dispatch(setAlert('The campaign was successfully deleted', 'success', 3000));
    
    // history.push('/settings')
    
  } catch (err) {
    console.log('Error', err);
    const errors = err.response && err.response.data && err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: CUSTOMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Clear state
export const clearCustoms = () => async dispatch => {
  try {
   
    dispatch({
      type: CLEAR_CUSTOMS
    });
  } catch (err) {
    dispatch({
      type: CUSTOMS_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Save data for the first custom on onboarding
export const prepareCustomOnboarding = (data, history, nextPage) => async dispatch => {
  try {

    // console.log('===== data', data)
    dispatch({
      type: PREPARE_FIRST_CUSTOM,
      payload: JSON.stringify(data)
    });

    history.push(`/welcome/checkins/${nextPage}`)

  } catch (err) {
    dispatch({
      type: CUSTOMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get data from LS for 1st custom
export const getCustomOnboarding = () => async dispatch => {
  try {
    dispatch({
      type: GET_ONBOARDING_CUSTOM
    });

  } catch (err) {
    dispatch({
      type: CUSTOMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get data from LS for 1st custom
export const hideCheckinModal = (date) => async dispatch => {
  try {
    dispatch({
      type: HIDE_CHECKIN_MODAL,
      payload: JSON.stringify(date)
    });

  } catch (err) {
    dispatch({
      type: CHECKIN_MODAL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Clear state
export const clearCustomSettings = () => async dispatch => {
  try {
   
    dispatch({
      type: CLEAR_CUSTOMS_SETTINGS
    });
  } catch (err) {
    dispatch({
      type: CUSTOMS_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};