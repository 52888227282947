import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../../../actions/auth';
import Spinner from '../../../layout/Spinner';
import { addOkr, getCustomOkr } from '../../../../actions/okr';
import getLabels from '../../../../translations/okrs';
import { setAlert } from '../../../../actions/alert';
import KrCreate from './KrCreate';

const endOfTheYear = new Date();
endOfTheYear.setMonth(11);
endOfTheYear.setDate(31);

const Onboarding3 = ({ 
  user,
  locale,
  addOkr,
  setAlert,
  history,
  okr: { okr },
  getCustomOkr,
}) => {
  let privacyLabel = {
    public: 'Public',
    team: 'Team',
    private: 'Private'
  };

  let personalOkrTitle = 'Personal objective (no team)';

  if (locale && locale.lang === 'ru') {
    privacyLabel = {
      public: 'Публичная',
      team: 'Отдел',
      private: 'Приватная'
    };

    personalOkrTitle = 'Персональный OKR';
  }

  let krLabels = locale && getLabels(locale.lang);

  let statusObj =
    locale && locale.lang !== 'ru'
      ? { onTrack: 'On Track', behind: 'Behind', atRisk: 'At Risk' }
      : { onTrack: 'По Плану', behind: 'Отстаем', atRisk: 'Риск' };

  const [formData, setFormData] = useState({
    objective: '',
    dueIn: '',
    team: { value: 3, label: personalOkrTitle, type: 3 },
    owner: '',
    status: '',
    keyResults: [],
    parentOkr: '',
    lang: '',
    privacy: { value: 1, label: privacyLabel.public, name: 'privacy' }
  });

  const { owner, keyResults, dueIn } = formData;
  
  const [isLoader, toggleLoader] = useState(false);

  useEffect(() => {
    // console.log('--==== USEEFEECT works')
    if (!okr) getCustomOkr()

    if (user && user._id && okr) {
      okr = JSON.parse(okr);

      setFormData({
        ...formData,
        objective: !okr ? '' : okr.objective,
        dueIn: !okr ? '' : okr.dueIn,
        owner: user && { value: user._id, label: `${user.firstName} ${user.lastName}` },
        status: locale && locale.lang && { value: 1, label: statusObj.onTrack, color: '#6ddeb3' },
        lang: locale && locale.lang,
        keyResults: [
          {
            krStart: '0',
            krGoal: '100',
            krCurrent: '0',
            dueDate: endOfTheYear,
            krOwner: user && [{ value: user._id, label: `${user.firstName} ${user.lastName}` }],
            krType: { label: krLabels.krTypeLabel.number, value: 'number' }
          }
        ],
      });
    }
  }, [user && user.firstName && user.firstName.length, getCustomOkr, okr && okr._id]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      // Show Loader
      toggleLoader(true);

      if (keyResults.length > 0) {
        for (let i = 0; i < keyResults.length; i++) {
          let kr = keyResults[i];

          if (kr.krStart === null || kr.krGoal === null) {
            let t = 'Error in the key result: ';
            let t2 = `The key result doesn't have start or goal metric. It's displayed as grey 0. Please add the number to the metric.`;

            if (locale.lang === 'ru') {
              t = 'Ошибка в ключевом результате: ';
              t2 =
                'В данном ключевом результате нет значения Начало или Цель. В поле ввода стоит серый 0. Пожалуйста, добавьте это значение и сохраните цель.';
            }

            let errMsg = `${t} ${kr.title}. ${t2}`;

            // Hide Loader
            toggleLoader(isLoader);

            return setAlert(errMsg, 'danger', 15000);
          }
          if (
            !Number.isInteger(Number(kr.krStart)) ||
            !Number.isInteger(Number(kr.krCurrent)) ||
            !Number.isInteger(Number(kr.krGoal))
          ) {
            let t = 'Error in the key result: ';
            let t2 = `The key result metric values must be integer. Special characters (%,$,k) aren't allowed.`;

            if (locale.lang === 'ru') {
              t = 'Ошибка в ключевом результате: ';
              t2 =
                'Значения в ключевом результате должны быть целочисленными. Специфичные символы (%,$,к) не допускаются.';
            }

            let errMsg = `${t} ${kr.title || kr.krTitle}. ${t2}`;
            // Hide Loader
            toggleLoader(isLoader);

            return setAlert(errMsg, 'danger', 15000);
          }

          if (kr.krStart === kr.krGoal) {
            let t = 'Error in the key result: ';
            let t2 =
              'Your start value equals the goal value. Start value is the initial metric. Goal value is the outcome you want to achieve. The start value should be less than a goal. For example, the start is 0, and the goal is 100. Please make the start value less than the goal and then save OKR.';

            if (locale.lang === 'ru') {
              t = 'Ошибка в ключевом результате: ';
              t2 =
                'Значение Начало не может быть равно значению Цели. Начало - это ваша первоначальная метрика сейчас. Цель - это то, что вы хотите достичь. Начало должно быть меньше Цели. Например, начало = 0 и цель = 100. Пожалуйста, сделайте Начало меньше Цели и снова сохраните OKR.';
            }

            let errMsg = `${t} ${kr.krTitle}. ${t2}`;

            // Hide Loader
            toggleLoader(!isLoader);

            return setAlert(errMsg, 'danger', 15000);
          }
        }
      }

      // If keyResults are empty (no title, but we have krStart and krGoal from state)
      if (keyResults.length === 1 && keyResults[0].krTitle === undefined) {
        // Remove element and send keyResults == []
        keyResults.splice(0, 1);

        setFormData({ ...formData, keyResults: keyResults });
      }

      let isOnboarding = true;

      let res = await addOkr(formData, history, isOnboarding);

      // Hide Loader
      toggleLoader(false);
    } catch (err) {
      toggleLoader(false);
    }
  };

  const handleKeyResults = (selectedOption, index, type, detailsIdx = 0) => {
    if (type === 'krOwner') {
      keyResults[index].krOwner = selectedOption;
    }

    if (type === 'dueDate') {
      keyResults[index].dueDate = selectedOption;
    }

    if (type === 'krType') {
      keyResults[index].krType = selectedOption;

      if (selectedOption.value === 'binary') {
        keyResults[index].details = { value: 0, label: krLabels.binaryLabel.false };
        keyResults[index].krCurrent = 0;
      } else if (selectedOption.value === 'number') {
        keyResults[index].start = keyResults[index].start;
        keyResults[index].krCurrent = keyResults[index].current;
        keyResults[index].goal = keyResults[index].goal;
      } else if (selectedOption.value === 'milestone') {
        let data = { label: '', value: 0 };

        // Last condition to check if we switch from Binary to Milestone
        if (
          !keyResults[index].details ||
          keyResults[index].details.length === 0 ||
          (keyResults[index].details && keyResults[index].details.value > -1)
        ) {
          keyResults[index].details = [];
          keyResults[index].details.push(data);
          keyResults[index].krType.activeMilestoneIndex = 0;
          keyResults[index].start = 0;
          keyResults[index].krCurrent = 0;
        }
      }
    }

    if (type === 'details' || type === 'binary') {
      keyResults[index].details = selectedOption;
      keyResults[index].krCurrent = selectedOption.value;
    }

    if (type === 'milestone') {
      keyResults[index].details[detailsIdx][selectedOption.target.name] = selectedOption.target.value;

      // Save the goal
      if (selectedOption.target.name === 'value' && detailsIdx === keyResults[index].details.length - 1) {
        keyResults[index].krGoal = parseFloat(selectedOption.target.value);
      }
    }
    
    setFormData({ ...formData, keyResults: keyResults });
  };

  const onChangeKeyResults = (e, index) => {
    if (e.target.name === 'krTitle') {
      keyResults[index].krTitle = e.target.value;
      setFormData({ ...formData, keyResults: keyResults });
    }

    if (e.target.name === 'krStart') {
      keyResults[index].krStart = e.target.value;
      setFormData({ ...formData, keyResults: keyResults });
    }

    if (e.target.name === 'krCurrent') {
      keyResults[index].krCurrent = e.target.value;
      setFormData({ ...formData, keyResults: keyResults });
    }

    if (e.target.name === 'krGoal') {
      keyResults[index].krGoal = e.target.value;
      setFormData({ ...formData, keyResults: keyResults });
    }

    if (e.target.name === 'krOwner') {
      keyResults[index].krOwner = e.target.value;
      setFormData({ ...formData, keyResults: keyResults });
    }
  };

  const handleAddMilestone = (e, index) => {
    e.preventDefault();

    // console.log('======= index in handleAddMilestone', index)

    let data = { label: '', value: 0 };
    keyResults[index].details.push(data);

    setFormData({
      ...formData,
      keyResults
    });
  };

  const handleRemove = (index) => {
    const approve = window.confirm('Are you sure you wish to delete this KR? Data will be lost forever.');

    if (approve) {
      keyResults.splice(index, 1);
      setFormData({ ...formData, keyResults: keyResults });
    }
  };

  const handleAdd = (e) => {
    e.preventDefault();

    setFormData({
      ...formData,
      keyResults: [
        ...keyResults,
        // { krStart: '0', krCurrent: '0', krGoal: '100', krOwner: [owner], dueDate: dueIn }
        {
          krStart: '0',
          krCurrent: '0',
          krGoal: '100',
          krOwner: [owner],
          dueDate: dueIn,
          krType: { label: 'Number', value: 'number' }
        }
      ]
    });
  };

  // console.log('===== okr', okr)
  console.log('===== krLabels', krLabels)

  return (
    <Fragment>
      {!user ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="css-main onboarding bg__white">
            <div className="wrapper">
              <div className="flex">
                <KrCreate
                  keyResults={keyResults}
                  handleStepClick={handleSubmit}
                  locale={locale}
                  onChangeKeyResults={onChangeKeyResults}
                  handleKeyResults={handleKeyResults}
                  handleAddMilestone={handleAddMilestone}
                  handleRemove={handleRemove}
                  handleAdd={handleAdd}
                  user={user}
                  isLoader={isLoader}
                  krLabels={krLabels}
                />
                
                <div className={`onboarding__image onboarding__image__okr2`}></div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

Onboarding3.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  getCustomOkr: PropTypes.func.isRequired,
  addOkr: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  locale: state.locale,
  okr: state.okr
});

export default connect(
  mapStateToProps,
  { login, addOkr, setAlert, getCustomOkr }
)(Onboarding3);
