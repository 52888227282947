import React, { Fragment } from 'react';
import Loader from 'react-loader-spinner';

const BlueBtn = ({ text, onClick, isLoader }) => {

  let btnTxt = text;

  // Loader for button
  let loadingIcon = (
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Loader type="Circles" color="#ffffff" height="14px" width="15" />
    </span>
  );

  if (isLoader) {
    btnTxt = loadingIcon;
  }
  
  return (
    <Fragment>
      <button 
        type="button" 
        className="w-100 h-54 btn white fs-14 mr-0 py-1 px-15 text-center"
        disabled={!isLoader ? false : true}
        onClick={(e) => onClick(e)}
      >
        {btnTxt}
      </button>
    </Fragment>
    
  );
};

export default BlueBtn;
