import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { FormattedMessage } from 'react-intl';
import TaskItemCard from './TaskItemCard';
import TaskItemCardForm from './TaskItemCardForm';
import { icons } from "../../img/elements/icons";

const OkrItemKr = ({
  kr: {
    _id,
    title,
  },
  okrId,
  todos,
  formData,
  setFormData,
  searchKeyword,
  todoBoard,
  usersOptions,
  okrOptions,
  priorityOptions,
  handleSelect,
  handleDueIn,
  onSubmit,
  handleChangeForm,
  clearTodo,
  handleDelete,
  user,
  handleAdd,
  handleAddKr,
  handleComplete,
  handleNameForm,
  listView,
  i,
}) => {

  return (
    <Fragment>
      <div className="key_result my-1">
        <div className="flex align-end">
          <p className="fs-12 grey_new bold mb-0 mr-1">Key Result {i + 1}</p>
          <p className="fs-16">{title}</p>
        </div>

        {todos && todos.length > 0 && (
          <div className="cards pt-xs pb-xs">
          {todos.map((item, index) => (
            <Fragment 
              key={`todo-${item._id}-${index}`} 
            >
              <div>
              {!item.isNew ? (
                <TaskItemCard
                  todo={item}
                  formData={formData}
                  setFormData={setFormData}
                  searchKeyword={searchKeyword}
                  usersOptions={usersOptions}
                  okrOptions={okrOptions}
                  priorityOptions={priorityOptions}
                  handleSelect={handleSelect}
                  handleDueIn={handleDueIn}
                  handleComplete={handleComplete}
                  index={index}
                  user={user}
                  handleDelete={handleDelete}
                  handleNameForm={handleNameForm}
                  todoBoard={todoBoard}
                  listView={listView}
                />
              ) : (
                <TaskItemCardForm
                  todo={item}
                  krId={_id}
                  formData={formData}
                  setFormData={setFormData}
                  searchKeyword={searchKeyword}
                  usersOptions={usersOptions}
                  okrOptions={okrOptions}
                  priorityOptions={priorityOptions}
                  handleChangeForm={handleChangeForm}
                  clearTodo={clearTodo}
                  handleDueIn={handleDueIn}
                  onSubmit={onSubmit}
                  index={index}
                  listView={listView}
                />
              )}
              </div>
            </Fragment>
          ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

OkrItemKr.propTypes = {
  todos: PropTypes.array.isRequired
};

export default OkrItemKr;
  