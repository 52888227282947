import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const FocusMain = ({ 
  title,
  focus, 
  okr, 
  divStyles,
}) => {
  
  let color;
  let label = "";

  if (okr) {
    if (okr.status === 1) {
      color = "green";
      label = "On Track";
    } else if (okr.status === 2) {
      color = "yellow";
      label = "Behind";
    } else if (okr.status === 3) {
      color = "red";
      label = "At Risk";
    }
  }
 
  // Check that object is not empty (object length)
  let objectLength = 0;
  if (okr !== undefined && okr !== null) {
    objectLength = Object.keys(okr).length;
  }
  
  let focusLength = 0;
  if (focus !== undefined && focus !== null) {
    focusLength = Object.keys(focus).length;
  }
  const focusHead = <FormattedMessage id="focus.day.focus" defaultMessage="Focus"/>;
  const okrHead = <FormattedMessage id="focus.day.okr" defaultMessage="OKR"/>;
  const currentlLabel = <FormattedMessage id="widget.main.current" defaultMessage="Current"/>;
  const goalLabel = <FormattedMessage id="widget.main.goal" defaultMessage="Goal"/>;
  const noFocus = <FormattedMessage id="focus.nofocus.okr" defaultMessage="No focus on OKR"/>;
  const focusOnOkr = <FormattedMessage id="focus.onOkr" defaultMessage="Focus on OKR"/>;

  return (
    <div className={`text-left br-12 p-3 ${divStyles}`}>
      <p className="fs-2 text-left bold pb-2">{title}</p>
      {objectLength > 0 && focusLength > 0 ? (
        focus.focusIndex === 99 ? (
          <Fragment>
          <p className="medium text-left"><b>{focusOnOkr}:</b>{' '}{focus.objective}</p>
          <p className={`medium text-left inline-block ${color} fs-14 mr-3`}>{label}</p>
          <p className="medium text-left inline-block grey fs-14 mr-1">{currentlLabel}:{' '}{okr.progress}%</p>
          <p className="medium text-left inline-block grey fs-14">{goalLabel}:{' '}100%</p>
        </Fragment>
        ) : (
          <Fragment>
            <p className="medium text-left"><b>{okrHead}:</b>{' '}{focus.objective}</p>
            <p className="medium text-left"><b>{focusHead}:</b>{' '}{focus.text}</p>
            <p className={`medium text-left inline-block ${color} fs-14 mr-3`}>{label}</p>
            <p className="medium text-left inline-block grey fs-14 mr-1">{currentlLabel}:{' '}{okr.keyResults[focus.focusIndex].current}</p>
            <p className="medium text-left inline-block grey fs-14">{goalLabel}:{' '}{okr.keyResults[focus.focusIndex].goal}</p>
          </Fragment>
        )   
      ) : (
        <p className="medium text-left">{noFocus}</p>
      )}
    </div>
  );
};

FocusMain.propTypes = {
  okr: PropTypes.object,
  focus: PropTypes.object,
  date: PropTypes.string
};

export default FocusMain;
