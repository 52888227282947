import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { FormattedMessage } from 'react-intl';
import TaskItemCard from './TaskItemCard';
import OkrItemKr from './OkrItemKr';
import { icons } from "../../img/elements/icons";
import TaskItemCardForm from './TaskItemCardForm';

const OkrItem = ({
  okr: {
    _id,
    objective,
    keyResults,
  },
  todos,
  formData,
  setFormData,
  searchKeyword,
  todoBoard,
  usersOptions,
  okrOptions,
  priorityOptions,
  handleSelect,
  handleDueIn,
  onSubmit,
  handleChangeForm,
  clearTodo,
  handleDelete,
  user,
  handleAdd,
  handleAddKr,
  handleComplete,
  handleNameForm,
  listView,
  newTodos,
}) => {

  return (
    <Fragment>
      <div className="objective nothing-serious mb-2">
        <div className="flex align-center">
          <span className="fs-16 mr-1">{icons.target}</span>
          <p className="fs-16">{objective}</p>
        </div>

        <div className="key-result">
          {keyResults.map((item, index) => (
            <Fragment  
              key={`todo-${item._id}-${index}`} 
            >
              <div>
                <OkrItemKr
                  kr={item}
                  okrId={_id}
                  todos={todos.filter(todo => todo.OKR && todo.OKR.krId === item._id)}
                  formData={formData}
                  setFormData={setFormData}
                  searchKeyword={searchKeyword}
                  usersOptions={usersOptions}
                  okrOptions={okrOptions}
                  priorityOptions={priorityOptions}
                  handleSelect={handleSelect}
                  handleDueIn={handleDueIn}
                  handleComplete={handleComplete}
                  i={index}
                  user={user}
                  handleDelete={handleDelete}
                  handleNameForm={handleNameForm}
                  todoBoard={todoBoard}
                  listView={listView}
                  handleAdd={handleAdd}
                  handleAddKr={handleAddKr}
                />
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

OkrItem.propTypes = {
  todos: PropTypes.array.isRequired
};

export default OkrItem;
  