import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout, logoutAsUser } from '../../actions/auth';
import { FormattedMessage } from 'react-intl';
import { setLocaleLang } from '../../actions/locale';
import { icons } from "../../img/elements/icons";

const UserNav = ({
  auth: { isAuthenticated, loading, user, lau },
  setLocaleLang,
  locale,
  logout,
  guest,
  logoutAsUser,
  avatarUpdate,
  background,
}) => {
  useEffect(() => {
  }, [avatarUpdate]);

  // так как иногда аватар не обновляется на 2-ом экране (надо раза 3-4 обновлять страницу). Решил все-так оставить, чтобы 
  // он не каждый раз обновлял аватар
  if (avatarUpdate === undefined) { avatarUpdate = 0 }

  const [language, toggleLanguage] = useState(false);

  const onClick = lang => {
    setLocaleLang(lang);
    toggleLanguage(!language);
  };

  const guestLinks = (
    <div className="container navigation">

      <div className="menu-item nav_list">
        <button
          className="landing_font no_btn"
          onClick={() => toggleLanguage(!language)}
        >
          {locale.lang === 'en' ? 'Eng' : 'Ру'}{' '}
          <i className="fas fa-caret-down" />
        </button>
        {language && (
          <ul className="sub-menu sub-language sub-user">
            <li>
              <button
                className="landing_font no_btn"
                onClick={() => onClick('en')}
              >
                <FormattedMessage id="nav.english" defaultMessage="English" />
              </button>
            </li>
            <li>
              <button
                className="landing_font no_btn"
                onClick={() => onClick('ru')}
              >
                <FormattedMessage id="nav.russian" defaultMessage="Russian" />
              </button>
            </li>
          </ul>
        )}
      </div>
    </div>
  );

  const authLinks = (
    <Fragment>
      {lau ? (
        <Fragment>
        <div className="bg-orange w-100 py-1 mb-2 fixed z-50000">
          <button 
            type="button"
            onClick={() => logoutAsUser() }
            className="small secondary-font no_btn mla w-100px block btn btn-light mr-3 text-center"
          >
            Logout
          </button>
        </div>
        <div className="pt-5"></div>
        </Fragment>
      ) : null}
        
      <div className="nav_user nav_list h-60 flex align-center pt-2 mr-4 mla">
          <div className="nav_user__buttons flex align-center mr-4">
            {/* <p className="fs-13 fw-600 px-15 hide-xs"><FormattedMessage id="need.help" defaultMessage="Need Help?" /></p> */}
            <a href="https://usefocus.co/demo/" className="btn fs-14 mr-0 py-1 px-15" rel="noopener noreferrer" target="_blank">
              <FormattedMessage id="schedude.demo" defaultMessage="Schedule a Demo" />
            </a>
          </div>
          <ul className="menu user_menu h-60 flex align-center">
            <li className="user-details menu-item h-60 flex align-center">
              <p className="fw-14 fw-600 text-right flex align-center">
                {isAuthenticated && user && user.avatar !== null ? (
                  <img
                    className="round-img"
                    src={user.avatar.indexOf('gravatar') === -1 ? `${user.avatar}?${avatarUpdate}` : user.avatar}
                    alt="Avatar"
                    width="26px"
                    height="26px"
                    margin-right="5px"
                  />
                ) : null}
                {isAuthenticated && user ? (
                  <Fragment>
                    <span className="mx-xs cursor">{user.firstName}{" "}{user.lastName}</span>
                    </Fragment>
                ) : (
                  <FormattedMessage id="nav.hello" defaultMessage="Hello" />
                )
                }{' '}
                <i className="fas fa-caret-down" />
              </p>
              <ul className="sub-menu sub-user w-200px">
                <li>
                  <Link to="/profile" className="flex align-center icon__box fs-14 fw-600 black">
                    <span className="w-30px flex align-center">{icons.profile}</span>
                    <FormattedMessage id="nav.profile" defaultMessage="Profile" />
                  </Link>
                </li>
                <li>
                  <Link to="/profile/notifications" className="landing_font flex align-center icon__box fs-14 fw-600 black">
                    <span className="w-30px flex align-center">{icons.settings_user}</span>
                    <FormattedMessage id="settings" defaultMessage="Settings" />
                  </Link>
                </li>
                <li>
                  <Link to="#!" className="landing_font flex align-center icon__box fs-14 fw-600 black" onClick={logout}>
                    <span className="w-30px flex align-center">{icons.logout}</span>
                    <FormattedMessage id="nav.logout" defaultMessage="Logout" />
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
    </Fragment>
  );

  return (
    <nav className={`navbar ${background || 'bg-primary'}`}>
      {!loading && <Fragment>{guest ? guestLinks : authLinks}</Fragment>}
    </nav>
  );
};

UserNav.propTypes = {
  logout: PropTypes.func.isRequired,
  logoutAsUser: PropTypes.func.isRequired,
  setLocaleLang: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  avatarUpdate: PropTypes.number,
  background: PropTypes.string,
};

const mapStateToProps = state => ({
  auth: state.auth,
  locale: state.locale
});

export default connect(
  mapStateToProps,
  { logout, setLocaleLang, logoutAsUser }
)(UserNav);