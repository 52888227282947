import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AdminDashboardCardItem from './AdminDashboardCardItem';

const AdminDashboardItem = ({
  title,
  settings,
  updateSettings,
  icebreakerSettings,
  company,
  slack,
  disabled,
  companyPlan,
  toggleIcebreakerSettings,
  planSettings,
  togglePlanSettings,
  customCampaigns,
  toggleCustomSettings,
  handleCampaignType,
  discordObj
}) => {
  return (
    <Fragment>
      <div className="content__checkins">
        <div className="cards py-2">
          <AdminDashboardCardItem
            settings={settings && settings}
            updateSettings={updateSettings && updateSettings}
            icebreakerSettings={icebreakerSettings && icebreakerSettings}
            slackId={company && company.slackTeamId}
            isSlack={company && company.isSlack}
            slack={slack === 1 && slack}
            disabled={disabled}
            toggleIcebreakerSettings={toggleIcebreakerSettings}
            companyPlan={companyPlan}
            planSettings={planSettings}
            togglePlanSettings={togglePlanSettings}
            customCampaigns={customCampaigns}
            toggleCustomSettings={toggleCustomSettings}
            handleCampaignType={handleCampaignType}
            discord={company && company.discord}
            discordObj={discordObj === 1 && discordObj}
          />
        </div>
      </div>
    </Fragment>
  );
};

AdminDashboardItem.propTypes = {
  settings: PropTypes.object,
  updateSettings: PropTypes.object,
  title: PropTypes.object,
  slack: PropTypes.number,
  disabled: PropTypes.bool
};

export default AdminDashboardItem;
