import {
  GET_PROFILE,
  PROFILE_ERROR,
  CLEAR_PROFILE,
  TEAM_PROFILES_ERROR,
  GET_COMPANY_PROFILES,
  COMPANY_PROFILES_ERROR,
  GET_TEAM_PROFILES,
  DELETE_USER,
  DELETE_USER_ERROR,
  UPDATE_NOTIFICATION,
  UPDATE_ALL_NOTIFICATIONS,
  NOTIFICATION_ERROR,
  NOTIFICATIONS_ERROR,
  UPDATE_AVATAR,
  AVATAR_ERROR,
  ASSIGN_TEAM_HEAD,
  ASSIGN_TEAM_HEAD_ERROR,
  UPDATE_COMPANY_MEMBER_PROFILE,
  UPDATE_COMPANY_MEMBER_PROFILE_ERROR
} from '../actions/types';

const initialState = {
  profile: null,
  profiles: [],
  loading: true,
  error: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROFILE:
      return {
        ...state,
        profile: payload,
        loading: false
      };
    case UPDATE_AVATAR:
      return {
        ...state,
        profile: { ...state.profile, avatar: payload.avatar },
        profiles: state.profiles.map((item) =>
          item._id === payload.id ? { ...item, avatar: payload.avatar } : item
        ),
        loading: false
      };
    case ASSIGN_TEAM_HEAD:
      return {
        ...state,
        profiles: state.profiles.map((item) =>
          item._id === payload.id ? { ...item, role: payload.role } : item
        ),
        loading: false
      };
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        profile: (state.profile.notifications[payload.key] = payload.data),
        loading: false
      };
    case UPDATE_ALL_NOTIFICATIONS:
      return {
        ...state,
        profile: (state.profile.notifications = payload.data),
        loading: false
      };
    case GET_TEAM_PROFILES:
    case GET_COMPANY_PROFILES:
      return {
        ...state,
        profiles: payload,
        loading: false
      };
    case UPDATE_COMPANY_MEMBER_PROFILE:
      return {
        ...state,
        profiles: state.profiles.map((item) => (item._id === payload._id ? payload : item)),
        loading: false
      };
    case TEAM_PROFILES_ERROR:
    case DELETE_USER_ERROR:
    case COMPANY_PROFILES_ERROR:
    case PROFILE_ERROR:
    case NOTIFICATION_ERROR:
    case NOTIFICATIONS_ERROR:
    case AVATAR_ERROR:
    case ASSIGN_TEAM_HEAD_ERROR:
    case UPDATE_COMPANY_MEMBER_PROFILE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case DELETE_USER:
      return {
        ...state,
        profiles: state.profiles.filter((profile) => profile._id !== payload),
        loading: false
      };
    case CLEAR_PROFILE:
      return {
        ...state,
        profile: null,
        profiles: null,
        loading: true
      };
    default:
      return state;
  }
}
