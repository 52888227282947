import React from "react";
import PropTypes from "prop-types";
import TableLineNew from "./TableLineNew";
import { FormattedMessage } from 'react-intl';

// types: 1 - checkin, 2 - weekly plan, 3 - weekly update

const NotAnsweredTable = ({
  answers,
  linkName,
  standupAnswers,
  type,
  styles
}) => {

  let noAnswersUsersTitle = <FormattedMessage id="users.noAnswer.checkin" defaultMessage="Haven't Completed Daily Check-in"/>;
  let everyoneAnsweredMsg = <FormattedMessage id="all.answered" defaultMessage={`Everyone answered`} />;
  let teamTitle = <FormattedMessage id="team.member" defaultMessage="Team Member" />;
  let checkinTitle = <FormattedMessage id="last.checkin" defaultMessage="Last Check-in" />;

  let title = '';
  let tabletitle = '';

  if (type === 1) {
    title = <FormattedMessage id="users.noAnswer.checkin" defaultMessage="Haven't Completed Daily Check-ins"/>;
    tabletitle = <FormattedMessage id="last.checkin" defaultMessage="Last Check-in" />;
  } else if (type === 2) {
    title = <FormattedMessage id="users.noAnswer.plan" defaultMessage="Haven't Completed Weekly Plans"/>;
    tabletitle = <FormattedMessage id="last.plan" defaultMessage="Last Plan" />;
  } else if (type === 3) {
    title = <FormattedMessage id="users.noAnswer.update" defaultMessage="Haven't Completed Weekly Updates"/>;
    tabletitle = <FormattedMessage id="last.update" defaultMessage="Last Update" />;
  } else if (type === 4) {
    title = <FormattedMessage id="users.noAnswer.checkin" defaultMessage="Haven't Completed Check-in"/>;
    tabletitle = <FormattedMessage id="last.checkin" defaultMessage="Last Check-in" />;
  }
  
  return (
     <div className={`table__no-answer ${styles ? styles : ''}`}>
        <div className={`br-4 p-35 ${type !== 4 ? 'shadow' : 'brd brd-grey__light bg__white'}`}>
          <h3 className="fs-2 bold text-left mb-25">{title}</h3>
          <div className="line__grey"></div>
          {/* 
          <TableTitle 
              name={tableNameTitle}
              items={!isUnfinishedUsers ? tableTitleItems : [tableTitleItems[1]]}
              arrayWidth={arrayWidth}
            /> */}
          <div className="table__no-answer__title flex space-aroung my-2">
            <div className="fs-14 grey_new text-left">{teamTitle}</div>
            <div className="fs-14 grey_new mla text-right">{tabletitle}</div>
          </div>

          <div className="line__grey mb-2"></div>

          {answers.length > 0 ? (
            answers.map((item, index) => (
              <div key={`table-item-${index}`}>
                <TableLineNew
                    item={item}
                    linkName={linkName}
                    userAnswers={standupAnswers && standupAnswers.filter(s => s.user && s.user.toString() === item._id.toString())}
                  />
              </div>
            ))
          ) : (
            <div className="mt-5">
              <p className="medium">{everyoneAnsweredMsg}</p>
            </div>
          )}
        </div>
    </div>
  );
};

NotAnsweredTable.propTypes = {
  answers: PropTypes.array.isRequired,
  getUserAnswersAmount: PropTypes.func,
  tableNameTitle: PropTypes.object,
};

export default NotAnsweredTable;
