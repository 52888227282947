import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const RoundImg = ({ w, h, src, alt, styles }) => {
  return (
    <img
      src={src}
      alt={alt}
      className={`round-img d-block avatar img-${w} ${styles}`}
      width={w}
      height={h}
    />
  );
};

RoundImg.propTypes = {
  w: PropTypes.string.isRequired,
  h: PropTypes.string.isRequired,
  styles: PropTypes.string,
  alt: PropTypes.string,
};

export default RoundImg;
