import axios from "axios";
import { setAlert } from "./alert";

import {
  GET_ALL_USERS,
  OKRS_ERROR,
  USERS_ERROR,
  GET_ALL_OKRS,
  GET_ALL_STANDUPS,
  GET_ALL_PLANS,
  STANDUPS_ERROR,
  PLANS_ERROR,
  GET_ALL_UPDATES,
  UPDATES_ERROR,
  GET_ALL_ICEBREAKERS,
  GET_ALL_TASKS,
  ICEBREAKERS_ERROR,
  GET_COMPANIES,
  TODOS_ERROR,
  COMPANIES_ERROR,
  DELETE_COMPANY,
  GET_ALL_LOGS,
  LOGS_ERROR,
  GET_ALL_ONES,
  ONES_ERROR,
  GET_ALL_MOODS,
  MOODS_ERROR,
  CUSTOMS_ERROR,
  GET_ALL_CUSTOMS,
} from "./types";

// Get all users at Focus
export const getUsers = () => async dispatch => {
  try {
    const res = await axios.get("/api/admin/users");

    dispatch({
      type: GET_ALL_USERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: USERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all OKRs
export const getAllOkrs = () => async dispatch => {
  try {
    const res = await axios.get("/api/admin/okr/all");

    dispatch({
      type: GET_ALL_OKRS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: OKRS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all standups answers
export const getAllStandups = () => async dispatch => {
  try {
    const res = await axios.get("/api/admin/standupanswers");

    dispatch({
      type: GET_ALL_STANDUPS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: STANDUPS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get all update answers
export const getAllUpdates = () => async dispatch => {
  try {
    const res = await axios.get("/api/admin/updateanswers");

    dispatch({
      type: GET_ALL_UPDATES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: UPDATES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all custom answers
export const getAllCustoms = () => async dispatch => {
  try {
    const res = await axios.get("/api/admin/customanswers");

    dispatch({
      type: GET_ALL_CUSTOMS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CUSTOMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all weekly plan answers
export const getAllPlans = () => async dispatch => {
  try {
    const res = await axios.get("/api/admin/plananswers");

    dispatch({
      type: GET_ALL_PLANS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PLANS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all icebreakers answers
export const getAllIcebreakers = () => async dispatch => {
  try {
    const res = await axios.get("/api/admin/icebreakers");

    dispatch({
      type: GET_ALL_ICEBREAKERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ICEBREAKERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all tasks answers
export const getAllTasks = () => async dispatch => {
  try {
    const res = await axios.get("/api/admin/tasks");

    dispatch({
      type: GET_ALL_TASKS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TODOS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all 1:1 meetings
export const getAllOnes = () => async dispatch => {
  try {
    const res = await axios.get("/api/admin/oneonones");

    dispatch({
      type: GET_ALL_ONES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ONES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all 1:1 meetings
export const getAllMoods = () => async dispatch => {
  try {
    const res = await axios.get("/api/admin/moods");

    dispatch({
      type: GET_ALL_MOODS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: MOODS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all companies
export const getCompanies = () => async dispatch => {
  try {
    const res = await axios.get("/api/admin/companies");

    dispatch({
      type: GET_COMPANIES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: COMPANIES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all logs
export const getLogs = () => async dispatch => {
  try {
    const res = await axios.get("/api/admin/logs");

    console.log('==== res.data in logs', res.data);

    dispatch({
      type: GET_ALL_LOGS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: LOGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Delete company
export const deleteCompany = (id) => async dispatch => {
  try {

    console.log('====id in deleteCompany');

    await axios.delete(`/api/admin/company/delete/total/${id}`);

    dispatch({
      type: DELETE_COMPANY,
      payload: id
    });
  } catch (err) {
    const errors = err.response.data.errors;
    console.log('err.response.data.', err.response.data);
    
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    
    dispatch({
      type: COMPANIES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};