import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { deleteEmail } from "../../actions/outreachEmail";
import { icons } from "../../img/elements/icons";
import { setAlert } from '../../actions/alert';
import { FormattedMessage } from "react-intl";

const EmailCardItem = ({
  user,
  email: {
    title,
    text,
    _id
  },
  campaignId,
  setAlert,
  deleteEmail,
  owner,
  formDataParent,
  setFormDataParent,
  index
}) => {
  
  const [displayText, toggleEmailText] = useState(false);

  // ЗДЕСЬ получаем коллбэк и перебираем formData.objectives и обновляем emails
  const handleDelete = async (campaignId, _id) => {
    try {
      const res = await deleteEmail(campaignId, _id);

      setFormDataParent({ 
        ...formDataParent, 
        state: formDataParent.state.map(item => {
          if (item._id === res.campaignId) {
            item.emails = item.emails.filter(email => email._id !== res.emailId);
            return item;
          } else { return item}
        })
      });
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in handleDelete', err);
    }
  };

  return (
    <Fragment>
      <div className="card-item card-team flex justify-center relative">
        <div className="w-90 okr__card">
          <div className="form__okr-update">
          <div className={`card-title card-title-green p-2 relative`}>
              <div className="flex column w-40-l-70-xs">
                <span className={`fs-10 blue pt-1 pb-xs`}>{`Email #${index}`}</span>
                <p className="large w-100">{title}</p>
              </div>

              <button
                onClick={() => toggleEmailText(!displayText)}
                type="button"
                className="details medium-xs cursor no_btn okr__details"
              >
                <FormattedMessage
                  id="user.okr.details"
                  defaultMessage="Details"
                />{' '}
                <i className="fas fa-caret-down"></i>
              </button>
            </div>

            {displayText && (
              <div className="card-body card-standups-team card-body-hidden pt-2 pb-1 px-3 active">
                <p className="medium pre-line">{text}</p>
              </div>
            )}
          </div>
        </div>

        <div className="card-icons card-icons__okr">
          <div className="btn-action no_btn ml-xs relative" type="button">
            {icons.more}

            <div className="dropdown p-1 light-shadow">
              <Link to={`/outreacher/${campaignId}/${_id}`} className="small secondary-font">
                <i className="fas fa-pencil-alt" />{' '} 
                <FormattedMessage
                  id="user.email.edit"
                  defaultMessage="Edit Email"
                />
                </Link>
                
              {user && owner === user._id || user && user.role === "Admin" || user && user.role === "Owner" || user && user.role === "Superadmin" ? (
                <button 
                  type="button"
                  onClick={() => { if (window.confirm('Are you sure you wish to delete this email?')) handleDelete(campaignId, _id) } }
                  className="small secondary-font no_btn pt-1"
                  >
                  <i className="fas fa-times" />{' '}
                  <FormattedMessage
                    id="outreach.email.delete"
                    defaultMessage="Delete Email"
                  />
                </button>
              ) : null}
            </div>
          </div>
        </div>

      </div>
    </Fragment>
  );
};

EmailCardItem.propTypes = {
  user: PropTypes.object.isRequired,
  email: PropTypes.object.isRequired,
  deleteEmail: PropTypes.func,
  owner: PropTypes.object,
  index: PropTypes.number
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  deleteEmail,
  setAlert
})(EmailCardItem);
