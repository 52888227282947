import React from 'react';
import { FormattedMessage } from 'react-intl';

export const okrsCopy = {
  msg: (
    <FormattedMessage
      id="okr.company.notification.msg1"
      defaultMessage="You're using the sample OKRs below. Create your first OKR and you'll see your objective there. Click the button below to create your first company's OKR."
    />
  ), 
  msg2: (
    <FormattedMessage
      id="okr.company.notification.msg2"
      defaultMessage="Click the button below to create your first company's OKR."
    />
  ),
  cta: (
    <FormattedMessage
      id="okr.notification.cta"
      defaultMessage="Create the OKR"
    />
  ),
  noOkrMessage: {
    title: (
      <FormattedMessage
        id="user.nookr.user.title"
        defaultMessage="Objectives and Key Results"
      />
    ),
    msg: (
      <FormattedMessage
        id="company.nookr.user.msg"
        defaultMessage="It's the section for your company Objectives and Key Results (OKRs). You can create your first company OKR here. You will be able to see the traction of your company's OKRs and get the motivation to reach new heights with your team."
      />
    )
  }
}

export default {
  okrsCopy
}