import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

const LogsRow = ({
  auth,
  log: {
    _id,
    action,
    type,
    subType,
    firstName,
    lastName,
    companyName,
    company,
    role,
    payload,
    date,
  },
  index,
}) => {

let fullName = `${firstName} ${lastName}`;

let object = payload && payload.object ? payload.object : {}
let exObject = payload && payload.exObject ? payload.exObject : {}
let comment = payload && payload.comment ? payload.comment : ''

// Check the object length is not 0
let objectLength = 0;
if (object !== undefined && object !== null) {
  objectLength = Object.keys(object).length;
}

// Check the object length is not 0
let exObjectLength = 0;
if (exObject !== undefined && exObject !== null) {
  objectLength = Object.keys(exObject).length;
}

  return (
    <Fragment>
      <tr>
        <td className="fs1_4 text-center">{index + 1}</td>
        <td className="fs1_4 px-1 text-center">{_id}</td>
        <td className="fs1_4 w-200px text-center"><Moment format="ddd LLL">{date}</Moment></td>
        <td className="fs1_4 px-1 text-center">{fullName}</td>
        <td className="fs1_4 text-center">{companyName ? companyName : ""}</td>
        <td className="fs1_4 px-1 text-center">{action}</td>
        <td className="fs1_4 text-center">{type}</td>
        <td className="fs1_4 px-1 text-center">{subType ? subType : ""}</td>
        <td className="fs1_4 w-500px block">
          {objectLength > 0 && object !== undefined && object !== null && Object.keys(object).map((key) => {
            return <Fragment>
              <span className="small">Key: {key}</span>
              <span className="small">Name: {payload}</span>
            </Fragment>
          })}
        </td>
        <td className="fs1_4 w-500px block">
          {exObjectLength > 0 && exObject !== undefined && Object.keys(exObject).map((key) => {
            return <Fragment>
              <span className="small">Key: {key}</span>
              <span className="small">Name: {exObject[key] !== null && exObject[key].name}</span>
            </Fragment>
          })}
        </td>
        <td className="fs1_4 text-center">{comment ? comment : ""}</td>
      </tr>
    </Fragment>
  );
};

LogsRow.propTypes = {
  log: PropTypes.object.isRequired,
};

export default LogsRow;
