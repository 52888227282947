import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Logo from '../../elems/Logo';
import PropTypes from 'prop-types';
import { login } from '../../../actions/auth';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../layout/Spinner';
import Text from '../elems/Text';
import Btn from '../elems/WhiteBtn';
import Skip from '../elems/Skip';

const Onboarding1 = ({ isAuthenticated, user, loading }) => {
  
  // Redirect if logged in
  // if (isAuthenticated && user.role !== 'Superadmin') {
  //   if (user && user.companyName !== 'shortlist.io') {
  //     return <Redirect to="/dashboard/objectives" />;
  //   }
  // }

  let welcome = <FormattedMessage id="welcome.msg" defaultMessage="Welcome to Focus! Let's start by choosing your primary use of Focus app" />
  let okrmanagement = <FormattedMessage id="okr.management" defaultMessage="OKR Management" />
  let checkinmanagement = <FormattedMessage id="okr.management" defaultMessage="Employee Management" />
  
  
  return (
    <Fragment>
      {!user ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="css-main onboarding bg__white">
            <div className="wrapper">
              <div className="flex">
                <div className="onboarding__content relative">
                  <div className="">
                    <div className="pb-8">
                      <Logo isNoLink={true} />
                    </div>
                    <Text title={user.firstName} text={welcome} page={1} />

                    <div className="buttons pt-5">
                      <Btn isUrl={true} url="/welcome/okrs" text={okrmanagement} />
                      <div className="mt-2">
                        <Btn isUrl={true} url="/welcome/checkins/invite" text={checkinmanagement} />
                      </div>
                    </div>
                  </div>
                  
                  <Skip/>
                </div>
                <div className={`onboarding__image ${loading ? 'onboarding__image__welcome__loading': 'onboarding__image__welcome'}`}></div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

Onboarding1.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  loading: PropTypes.bool,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  loading: state.auth.loading,
});

export default connect(
  mapStateToProps,
  { login }
)(Onboarding1);
