import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CustomItemCard from './CustomItemCard';

const CustomItem = ({ answers, checkinType, formData, setFormData, searchKeyword, mentions, allSettings, lang }) => {

  const getCampaignSettings = (settings, checkinType) => {
    let obj = {};

    if (checkinType === 2) {
      obj = settings.filter(s => s.checkinName === 'standups')
    } else if (checkinType === 3) {
      obj = settings.filter(s => s.checkinName === 'plans')
    } else if (checkinType === 4) {
      obj = settings.filter(s => s.checkinName === 'updates')
    }

    obj = obj[0];

    return obj;
  };

  // console.log('========== CustomItem: answers', answers)

  return (
    <Fragment>
      {answers.map((answer, index) => (
        <Fragment key={index}>
          <CustomItemCard
            answer={answer}
            key={`team-${answer._id}-${index}`}
            formData={formData}
            setFormData={setFormData}
            searchKeyword={searchKeyword}
            mentions={mentions}
            checkinType={checkinType}
            campaignSettings={getCampaignSettings(allSettings, checkinType)}
            lang={lang}
          />
        </Fragment>
      ))}
    </Fragment>
  );
};

CustomItem.propTypes = {
  answers: PropTypes.array.isRequired
};

export default CustomItem;
