import React, { useState, Fragment, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Menu from '../layout/Menu';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import { getTeamAnswers } from '../../actions/standupAnswer';
import { getCompanySortedOkrs } from '../../actions/okr';
import { getCompanyProfiles } from '../../actions/profile';
import { getUserCompany } from '../../actions/company';
import MainWidget from '../utils/MainWidget';
import CheckinItem from '../checkins/noState/CheckinItem';
import TeamMenu from './TeamMenu';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/1.jpg';
import Notification from '../utils/Notification';
import { onboardingStandups } from '../../utils/onboarding/standups';
import { checkinCopy } from '../../utils/wording/checkins';
import { getStandups } from '../../actions/standup';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import Moment from 'react-moment';

const TeamCheckins = ({
  getCompanySortedOkrs,
  getUserCompany,
  getCompanyProfiles,
  okr: { okrs },
  auth: { user },
  company: { company },
  standupAnswer: { standupAnswers, loading },
  getTeamAnswers,
  profile: { profiles },
  getStandups,
  standup: { standups }
}) => {
  useEffect(() => {
    getUserCompany();
    getTeamAnswers();
    getCompanyProfiles();
    getStandups();
    getCompanySortedOkrs();

  }, [getUserCompany, getTeamAnswers, getCompanyProfiles, loading, getCompanySortedOkrs, getStandups]);

  const noStandupsMessageAdmin = {
    title: <FormattedMessage id="company.nostandups.admin.title" defaultMessage="No standups" />,
    msg: 
    <FormattedMessage id="company.nostandups.admin.msg" defaultMessage="You have not finished standups yet. You could participate in the first standup by clicking the button above. In this section, you will see all standups from your company. It helps you and your team stay in sync without meetings and work together better. You can invite people on the admin menu." />
  };
  const noStandupsMessageUserOld = {
    title: <FormattedMessage id="company.nostandups.user.title" defaultMessage="No standups" />,
    msg: 
    <FormattedMessage id="company.nostandups.user.msg" defaultMessage="You have not finished standups yet. You could participate in the first standup by clicking the button above. In this section, you will see all standups from your company. It helps you and your team stay in sync without meetings and work together better." />
  };

  const noCheckinsMessageTeam = {
    title: <FormattedMessage id="company.nocheckins.user.title" defaultMessage="No Check-ins" />,
    msg: 
    <FormattedMessage id="company.checkins.user.msg" defaultMessage={`${user && user.departmentName} has not finished check-ins yet.`} />
  };

  const standupLink =
    standups.length > 0 ? `/checkins/${standups[0]._id}` : '/checkins';

  // Removing duplicates in answers array based on the object key 'standup' - to send only unique standup items to StandupTeamItem
  // Берем данные из не редакса standupAnswers
  let standupAnswersUnique = standupAnswers ? (standupAnswers.reduce((acc, current) => {
    const x = acc.find(item => item.standup === current.standup);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, [])) : [];

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {user && user.department ? (
          <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user && user.isAdmin && true} activeIndex={10} />
        ) : (
          <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} activeIndex={10} />
        )}
        <UserNav />
          <Fragment>
            {loading || !standupAnswers || !user || !company || !okrs ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="main main-dashboard">
                    <h5>
                      {user.departmentName}{" "}<FormattedMessage id="you.header" defaultMessage={`Check-ins`} />
                    </h5>
                  <TeamMenu type={2} companyIndex={1} />
                  <div className="checkins">
                    {company.plan !== 1 ? (
                      <Fragment>
                        {standupAnswers.length === 0 ? (
                          <Fragment>
                            <Notification
                              msg={checkinCopy.msgOnboarding}
                              cta={checkinCopy.ctaOnboarding}
                              btnColor={'orange'}
                              color={`orange`}
                              url={standupLink}
                            />
                            <Fragment>
                              {onboardingStandups.map(standup => (
                                <CheckinItem
                                  key={`teamStandups-${standup._id}`}
                                  standupAnswers={onboardingStandups}
                                  standupDate={standup.standupDate}
                              />
                              ))}
                            </Fragment>
                          </Fragment>
                        ) : (
                          <Fragment>
                            {standupAnswersUnique.length > 0 ? (
                              standupAnswersUnique.map(
                                (standupAnswerUnique, index) => (
                                  <Fragment key={`fragment-checkin-${index}`}>
                                    <h3 className="fs-2 pt-2 pl-2 pb-1" key={`date-${index}`}>
                                      <Moment format="D MMM" key={`date-moment-${index}`}>{standupAnswerUnique.standupDate}</Moment>
                                    </h3>

                                    <CheckinItem
                                      key={`checkins-${standupAnswerUnique._id}-${index}`}
                                      standupAnswers={standupAnswers.filter(
                                        standupAnswer =>
                                          standupAnswer.standup.toString() ===
                                          standupAnswerUnique.standup.toString()
                                      )}
                                      standupDate={standupAnswerUnique.standupDate}
                                    />
                                  </Fragment>
                                )
                              )
                            ) : (
                              <div className="mt-5">
                                <NoContent 
                                  message={noCheckinsMessageTeam}
                                  picture={updateImg}
                                  alt={'No checkins'}
                                />
                              </div>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      <NoContent 
                        message={checkinCopy.updatePlanMsg}
                        picture={updateImg}
                        alt={'Update plan'}
                        admin={user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0}
                      />
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

TeamCheckins.propTypes = {
  getCompanyProfiles: PropTypes.func.isRequired,
  getTeamAnswers: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getStandups: PropTypes.func.isRequired,
  getCompanySortedOkrs: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  standupAnswer: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired,
  standup: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  standupAnswer: state.standupAnswer,
  standup: state.standup,
  okr: state.okr
});

export default connect(
  mapStateToProps,
  { getTeamAnswers, getUserCompany, getCompanyProfiles, getCompanySortedOkrs, getStandups }
)(TeamCheckins);
