import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import OkrAvatar from '../elements/OkrAvatar';
import TreeKeyResult from "./TreeKeyResult";

const TreeOkrItem = ({ keyResults, owner, ownerAvatar, ownerFirstName, ownerLastName, dueIn, progress, updatedAt }) => {

  const [krs, showKeyResults] = useState(false);

  // На всякий случай округляем в дереве
  progress = Math.round(progress);

  return (
    <Fragment>
      <div className="cards px-3 h-120px flex flex-column pointer" onClick={() => showKeyResults(!krs)}>
        <OkrAvatar
          owner={owner}
          ownerAvatar={ownerAvatar}
          ownerFirstName={ownerFirstName}
          ownerLastName={ownerLastName}
          dueIn={dueIn}
          updatedAt={updatedAt}
          isAvatarNoLink={true}
        />
      </div>

      {krs && (
        <div className="card-body card-standups-team card-body-hidden pt-2 pb-1 px-3 active">
          <div className="card-goal-item relative">
            <div className="mb-2">
              {keyResults.length > 0 && (
                keyResults.map(item => (
                  <TreeKeyResult
                    key={`key-result-${item._id}`}
                    keyResult={item}
                  />
                ))
              )}
            </div>
          </div>
        </div>
        )}
    </Fragment>
  );
};

TreeOkrItem.propTypes = {
  ownerAvatar: PropTypes.string.isRequired,
  ownerFirstName: PropTypes.string.isRequired,
  objective: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  dueIn: PropTypes.string,
  btnClass: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  keyResults: PropTypes.array
};

export default TreeOkrItem;
