import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateLike, deleteOkr, addUserMainOkr, updateOkr } from "../../actions/okr";
import OkrStatus from "./elements/OkrStatus";
import OkrAvatar from "./elements/OkrAvatar";
import OkrProgress from "./elements/OkrProgress";
import KeyResultItem from "./kr/KeyResultItem";
import ErrorBoundary from "../_helpers/ErrorBoundary";

const OkrCardItemForUpdate = ({
  okr: {
    owner,
    ownerAvatar,
    ownerFirstName,
    ownerLastName,
    dueIn,
    _id,
    objective,
    isMain,
    isMainIndex,
    type,
    progress,
    status,
    updatedAt,
    keyResults,
    teamName,
  },
  okrBoard,
  indexOkr,
  onChangeOkr
}) => {

  const statusOptions = [{ value: 1, label: 'On Track', color: '#6ddeb3' }, { value: 2, label: 'Behind', color: '#facf34' }, { value: 3, label: 'At Risk', color: 'red' }];

  let choosedStatus = {};

  status && statusOptions.forEach(item => {
    if (item.value === status) {
      choosedStatus = {
        value: item.value,
        label: item.label,
        color: item.color
      };
    }
  });

  // const [displayKeyResults, toggleKeyResultsButton] = useState(keyResults && keyResults.length > 0 ? true : false);
  const [displayKeyResults, toggleKeyResultsButton] = useState(false);
  const [update, toggleUpdateMode] = useState(false);

  let btnClass;
  let label = "";

  if (status === 1) {
    btnClass = "green";
    label = "On Track";
  } else if (status === 2) {
    btnClass = "yellow";
    label = "Behind";
  } else if (status === 3) {
    btnClass = "red";
    label = "At Risk";
  }

  return (
    <Fragment>
      <div className="card-team flex relative">
        <div className="w-100 brd brd-grey__light bg__white okr__card__expanded">

          <div className="okr__card flex align-center">
            <div className="okr__left w-60 flex flex-column flex-start px-3 py-25">
              <span className={`fs-12 dark-grey`}>{teamName}</span>
              <p 
                className="fs-2 w-100 fw-600 cursor"
                onClick={() => toggleKeyResultsButton(!displayKeyResults)}
              >{objective}</p>
              <div className="flex align-center mt-1">
                <div className="mr-1 w-50">
                  <OkrStatus
                    btnClass={btnClass !== undefined && btnClass}
                    label={label}
                    updateMode={true}
                    statusOptions={statusOptions}
                    choosedStatus={choosedStatus}
                    width={'w-100'}
                    onChangeOkr={onChangeOkr}
                    indexOkr={indexOkr}
                    formType={2}
                  />
                </div>
                <OkrProgress 
                  btnClass={btnClass !== undefined && btnClass} 
                  progress={Math.round(progress)}
                  keyResults={keyResults}
                />
              </div>
            </div>

            <div className="line-new__vertical h-180px"></div>
            <div 
              className="okr__right px-3 py-25 flex align-center cursor"
              onClick={() => toggleKeyResultsButton(!displayKeyResults)}
            >
              <OkrAvatar
                owner={owner} 
                ownerAvatar={ownerAvatar}
                ownerFirstName={ownerFirstName}
                ownerLastName={ownerLastName}
                dueIn={dueIn}
                noLink={true}
                updatedAt={updatedAt}
                isAvatarNoLink={true}
              />
            </div>
          </div>
          


          {displayKeyResults && (
            <div className="kr__card bg__white brd brd-grey__light card-standups-team card-body-hidden pt-4 pb-2 pl- active">
              <div className="card-goal-item relative ml-1-xs">
                <div className="mb-2">
                  <ErrorBoundary>
                  {keyResults.map((item, i) => (
                    <KeyResultItem
                      key={item._id}
                      keyResult={item}
                      progress={item.progress}
                      isMain={isMainIndex === i ? isMain : false}
                      okrId={_id}
                      index={i}
                      okrBoard={okrBoard}
                      okrType={type}
                      updateMode={true}
                      onChangeOkr={onChangeOkr}
                      indexOkr={indexOkr}
                      formType={2}
                    />
                  ))}
                  </ErrorBoundary>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

OkrCardItemForUpdate.propTypes = {
  updateOkr: PropTypes.func.isRequired,
  onChangeOkr: PropTypes.func.isRequired,
  okrBoard: PropTypes.string.isRequired,
  indexOkr: PropTypes.number.isRequired
};

export default connect(null, { updateLike, deleteOkr, addUserMainOkr, updateOkr })(OkrCardItemForUpdate);
