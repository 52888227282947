import { SET_LOCALE } from '../actions/types';

const initialState = {
  lang: localStorage.getItem('focusLang')
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOCALE:
      localStorage.setItem('focusLang', payload);
      return {
        ...state,
        lang: payload
      };
    default:
      return state;
  }
}
