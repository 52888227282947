import React from "react";
import PropTypes from 'prop-types';

const KudosIcon = ({ activeIndex }) => {
  return (
    <svg id="circle-heart" className="w-20px" xmlns="http://www.w3.org/2000/svg" width="19.999" height="20.012" viewBox="0 0 19.999 20.012">
      <path id="Path_22" fill={`${activeIndex === 5 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 22" d="M-368.125,138.464a10.027,10.027,0,0,1-10.02-9.973,10.006,10.006,0,0,1,9.98-10.039,10,10,0,0,1,10.019,9.958A10.023,10.023,0,0,1-368.125,138.464Zm8.767-10a8.761,8.761,0,0,0-8.749-8.787,8.7,8.7,0,0,0-8.809,8.754,8.672,8.672,0,0,0,8.734,8.788A8.734,8.734,0,0,0-359.358,128.467Z" transform="translate(378.145 -118.452)"/>
      <path id="Path_23" fill={`${activeIndex === 5 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 23" d="M-368.15,125.969c.266-.279.466-.491.668-.7a2.883,2.883,0,0,1,4.06-.152,2.894,2.894,0,0,1,.281,4.06c-.6.693-1.259,1.336-1.9,2q-.825.856-1.657,1.707a1.92,1.92,0,0,1-2.883.019q-1.683-1.712-3.34-3.448a2.867,2.867,0,0,1-.664-3.337,2.9,2.9,0,0,1,4.725-.9C-368.629,125.442-368.418,125.682-368.15,125.969Zm.01,1.69c-.248-.244-.475-.466-.7-.691-.325-.323-.627-.675-.979-.967a1.684,1.684,0,0,0-2.756.933,1.8,1.8,0,0,0,.563,1.743q1.643,1.663,3.286,3.326c.429.432.737.427,1.172-.011q1.6-1.614,3.2-3.232a3.567,3.567,0,0,0,.327-.379,1.727,1.727,0,0,0-.244-2.37,1.615,1.615,0,0,0-2.29.1c-.433.412-.848.844-1.273,1.265C-367.93,127.47-368.032,127.56-368.14,127.659Z" transform="translate(378.145 -118.452)"/>
    </svg>
  
  );
};

KudosIcon.propTypes = {
  activeIndex: PropTypes.number
};

export default KudosIcon;
