import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';

// types: 1 - checkin, 2 - weekly plan, 3 - weekly update
const UpcomingCheckinItem = ({ checkin, checkinName, answers }) => {

  const completeCheckin = <FormattedMessage id="complete.checkin" defaultMessage="Complete Check-in" />;
  const inProgress = <FormattedMessage id="in.progress" defaultMessage="In Progress" />;
  const teams = <FormattedMessage id="teams" defaultMessage="Teams" />;

  let campaign = checkinName === 'custom' ? checkin : checkin.settings;
  // console.log('===== checkinName in UpcomingCheckinItem', checkinName)
  // console.log('===== checkin in UpcomingCheckinItem', checkin)
  // console.log('===== answers in UpcomingCheckinItem', answers)
  
  return (
    <Fragment>
      <div className="flex align-center">
        <div className="flex flex-column w-50 mr-1">
          {checkinName === 'custom' ? (
            <p className="fs-12">{answers.length === 0 || answers[0].customID !== checkin.customID ? inProgress : checkin.nextDate ? checkin.nextDate : null}</p>
          ) : null}
          <p className="fs-16 fw-600">{checkin.name}</p>
          <p className="fs-12">{teams}:{" "}{(campaign.emailUsers || []).concat(campaign.slackUsers || []).map(({ name }) => name).join(', ')}</p>
        </div>
        {checkinName === 'custom' ? (
          answers.length === 0 || answers[0].customID !== checkin.customID ? (
          <Link to={`/campaign/create/${checkin._id}`} className="fs-14 fs-12-1400 inline-block text-center btn__secondary_green lh-34 nowrap px-25 px-xs-1400px mla ml-2-xs">
            {completeCheckin}
          </Link>
        ) : null) : (
          answers.length === 0 ? (
            <Link to={checkinName === 'standups' ? `/checkins/${campaign._id}` : checkinName === 'plans' ? `/plans/complete` : `/updates/${campaign._id}`} className="fs-14 fs-12-1400 lh-34 nowrap px-25 px-xs-1400px inline-block text-center btn__secondary_green px-25 mla ml-2-xs">
              {completeCheckin}
            </Link>) : null
        )
        }
      </div>
      <div className="line__grey my-2"></div>
    </Fragment>
  );
};

UpcomingCheckinItem.propTypes = {
  answers: PropTypes.array.isRequired,
  getUserAnswersAmount: PropTypes.func,
  tableNameTitle: PropTypes.object,
};

export default UpcomingCheckinItem;
