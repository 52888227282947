import React from "react";
import PropTypes from 'prop-types';

const OneSubMenu = ({ activeIndex, items, mb, onClick }) => {

  return (
    <div className={`sub-menu__page mt-2 mb-${mb ? mb : '3'}`}>
      {items.length > 0 && (
        items.map((item, index) => (
          <span
            key={`menu-${index}`}
            onClick={e => onClick(e, index)}
            className={`medium sub-menu__item inline-block mr-2 cursor ${index === activeIndex && ' sub-menu__item_active'}`}
          >
            {item.title}
          </span>
          )
      ))}
    </div>
  );
};

OneSubMenu.propTypes = {
  items: PropTypes.array.isRequired,
};

export default OneSubMenu;
