import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import CheckinItemCard from './CheckinItemCard';

const CheckinItem = ({ standupAnswers, standupDate }) => {
  return (
    <Fragment>
      {standupAnswers.map((standupAnswer, index) => (
        <div className="component my-2 px-4-l py-2 light-shadow no-shadow-xs" key={index}>
          <div className="cards">
            <CheckinItemCard
              standupAnswer={standupAnswer}
              key={`team-${standupAnswer._id}-${index}`}
              standupDate={standupDate}
            />
          </div>
        </div>
      ))}
    </Fragment>
  );
};

CheckinItem.propTypes = {
  standupAnswers: PropTypes.array.isRequired
};

export default CheckinItem;
