import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UserNav from '../layout/UserNav';
import Menu from '../layout/Menu';
import { addAnswer, getUserAnswer } from '../../actions/standupAnswer';
import { getUserTasks, completeTodo } from '../../actions/todo';
import { getStandupById } from '../../actions/standup';
import { getAllUserOkrs } from '../../actions/okr';
import { setAlert } from '../../actions/alert';
import Select from 'react-select';
import Spinner from '../layout/Spinner';
import { FormattedMessage } from 'react-intl';
import Loader from 'react-loader-spinner';
import { icons } from '../../img/elements/icons';
import classNames from 'classnames';
import TextQuestionBody from '../customAnswers/CustomFormTextQuestionBody';


const sidMenuItems = [
  { title: 'Tasks', value: 0 }
  // { title: 'Objectives', value: 1 }
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px'
  }),
  input: () => ({
    fontSize: '14px'
  }),
  placeholder: (styles) => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
};

const StandupForm = ({
  addAnswer,
  getStandupById,
  getUserAnswer,
  getAllUserOkrs,
  standup: { standup, loading },
  standupAnswer: { standupAnswer },
  okr: { okrs },
  todo: { tasks },
  auth: { user },
  locale,
  match,
  history,
  setAlert,
  getUserTasks,
  completeTodo
}) => {
  const [formData, setFormData] = useState({
    answers: [],
    focus: '',
    notes: '',
    todos: [],
    draggedTodo: {},
    completedTasks: []
  });

  const [isLoader, toggleLoader] = useState(false);
  const [complitingTasks, setComplitingTasks] = useState({});
  const [errorIndex, setErrorIndex] = useState(null); 

  let { answers, focus, notes, todos, draggedTodo, completedTasks } = formData;
  let okrOptions = [];

  // Take answers of the previos standupanswer (standupAnswer.answers[1].text)
  // let previousStandupAnswersArr = standupAnswer && standupAnswer.answers ? standupAnswer.answers[1].text : [];

  let noOkrMsg =
    locale && locale.lang !== 'ru' ? `😳 I don't work on OKR today` : `😳 Я не работаю сегодня над OKR`;
  {
    okrs &&
      okrs.length > 0 &&
      okrs.forEach((okr, i) => {
        if (!okr.isArchive) {
          let objective = {
            value: okr._id,
            label: `🎯 ${okr.objective}`,
            okr: okr._id,
            objective: okr.objective,
            isFocus: true,
            text: okr.objective,
            focusIndex: 99,
            status: okr.status,
            progress: okr.progress,
            goal: 100
          };

          okrOptions.push(objective);

          let krs = okr.keyResults;

          if (krs.length > 0) {
            krs.forEach((kr, index) => {
              let item = {
                value: kr._id,
                label: ` - ${kr.title}`,
                okr: okr._id,
                objective: okr.objective,
                isFocus: true,
                text: kr.title,
                focusIndex: index,
                status: okr.status,
                progress: kr.progress,
                goal: kr.goal,
                current: kr.current,
                krId: kr._id,
                isKr: true
              };

              okrOptions.push(item);
            });
          }
        }

        if (i === okrs.length - 1) {
          let noOkr = { value: `noOkr-${okr._id}`, label: noOkrMsg, isFocus: false };
          okrOptions.push(noOkr);
        }
      });
  }

  // Make answers.length is equal for questions.length
  useEffect(() => {

    // console.log('======= USEEFFECT 1 WORKS')

    if (standup) {
      setFormData((prev) => {
        if (standup.questions.length !== prev.answers.length && prev.answers.length === 0) {
          const newFormData = { ...prev };
          standup.questions.forEach(() => {
            newFormData.answers.push(['']);
          });
          return newFormData;
        }
        return prev;
      });
    }
  }, [standup]);

  useEffect(() => {
    // console.log('======= USEEFFECT 2 WORKS')

    standupAnswer &&
      formData.answers.length !== 0 &&
      standupAnswer.answers.length > 0 &&
      setFormData((prev) => {
        const newFormData = { ...prev };
        newFormData.answers[0] = [...standupAnswer.answers[1].text, ''];
        return newFormData;
      });
  }, [standupAnswer, formData.answers.length]);

  useEffect(() => {
    // console.log('======= USEEFFECT 3 WORKS', standup)

    if (standup === null) {
      getStandupById(match.params.id);
      getAllUserOkrs();
      getUserAnswer();
      getUserTasks({ all: true });
    }

    if (tasks) {
    // console.log('======= tasks here', tasks)

      setFormData({
        ...formData,
        todos: !tasks ? [] : tasks.filter((el) => el.isCompleted === false)
      });
    }


  }, [getStandupById, getAllUserOkrs, getUserAnswer, match.params.id, getUserTasks, tasks.length]);

  const onChange = (e, index, i) => {
    // Add new input
    if (answers[index][i].length === 0 && answers[index].length === i + 1) {
      answers[index].push('');
    }

    answers[index][i] = e.target.value;

    setFormData({ ...formData, answers: answers });
  };

  const handleFocus = (selectedOption) => {
    setFormData({ ...formData, focus: selectedOption });
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
    let arr = [];
    let arr2 = [];

    // Show Loader
    toggleLoader(true);

    for (let i = 0; i < answers.length; i++) {
      // Check if it's empty and only 1 input
      if (answers[i].length === 1 && answers[i][0].length === 0) {
        // Hide Loader
        toggleLoader(false);
        setErrorIndex(i);

        return setAlert(
          <FormattedMessage id="form.input.empty" defaultMessage="Please fill out the field" />,
          'danger'
        );
      } else {
        // Remove empty element from answers
        // Возвращаем в новые массивы arr значения ответы БЕЗ пустых ячеек
        // Специально не меняем answers для этого.
        // Иначе если с бекенда придет ошибка, то эти пустые поля останутся и при повторном вводе в них будет ошибка, тк в стейте answers они будут удалены.
        // Альтернатива - скрыть их на клиенте, но тогда не будет возможности ввести при повторе новое поле.
        // Поэтому добавляем новый arr2, куда сохраняем ответы без пустых строк и отправляем на сервер.
        if (answers[i][answers[i].length - 1].length === 0) {
          arr = answers[i].filter((item) => item.length > 0);

          if (arr.length === 0) {
            // Hide Loader
            toggleLoader(false);
            setErrorIndex(i);

            return setAlert(
              <FormattedMessage id="form.input.empty" defaultMessage="Please fill out the field" />,
              'danger'
            );
          }

          arr2.push(arr);
        } else {
          arr2.push(answers[i]);
        }
      }
    }

    for (let tkey in complitingTasks) {
      for (let key in complitingTasks[tkey]) {
        if (complitingTasks[tkey][key].name === formData.answers[tkey][key]) {
          const task = tasks.find((tsk) => tsk.name === complitingTasks[tkey][key].name);
          if (task && task.isCompleted !== complitingTasks[tkey][key].value) {
            await completeTodo(task._id, complitingTasks[tkey][key].value);
          }
        }
      }
    }

    // console.log('====== arr2 in onsubmit', arr2)

    let res = await addAnswer(arr2, focus, match.params.id, history, notes);

    toggleLoader(false);

    } catch (err) {
      console.log('Error in submit', err)
      toggleLoader(false);
    }
  };

  const handleAnswer = (e, answer, index, i) => {
    e.preventDefault();

    // Toggle - complete or not
    if (index === 0) {
      // Add or remove from the form
      if (answer.completeOne === false) {
        // Add answer to the form
        addAnswerToForm(answer, index, i);
      } else {
        // Remove from answers arr
        let removeIndex = answers[index].indexOf(answer.text);

        if (removeIndex > -1) {
          answers[index].splice(removeIndex, 1);
        }
      }

      // Toggle
      answer.completeOne = !answer.completeOne;
    } else if (index === 1) {
      // Second question

      // Add or remove answer from the form
      if (answer.completeTwo === false) {
        // Add answer to the form
        addAnswerToForm(answer, index, i);
      } else {
        // Remove from answers arr
        let removeIndex = answers[index].indexOf(answer.text);

        if (removeIndex > -1) {
          answers[index].splice(removeIndex, 1);
        }
      }

      // Toggle
      answer.completeTwo = !answer.completeTwo;
    }

    setFormData({ ...formData, answers: answers });
  };

  const addAnswerToForm = (answer, index, i) => {
    // Add new input
    if (answers[index][i] && answers[index][i].length === 0 && answers[index].length === i + 1) {
      answers[index].push('');
    }

    // если первое значение уже записано, то записываем в новый инпут
    if (answers[index][i] && answers[index][i].length === 0) {
      answers[index][i] = answer.text;
    } else {
      // если в текущий i уже записали руками ответ, то помещаем ответ в последний элемент
      // и создаем новый инпут

      answers[index][answers[index].length - 1] = answer.text;

      answers[index].push('');
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDragStart = (e, id, name) => {
    draggedTodo.id = id;
    draggedTodo.name = name;

    setFormData({ ...formData, draggedTodo });
  };

  const onDrop = (e, index) => {
    let newTodos = [];

    answers[index][answers[index].length - 1] = draggedTodo.name;

    newTodos = todos.filter((item) => item._id !== draggedTodo.id);

    answers[index].push('');

    setFormData({
      ...formData,
      answers,
      todos: newTodos,
      completedTasks
    });
  };

  const goBack = () => {
    history.goBack();
  };

  let btnTxt = <FormattedMessage id="standup.form.submit" defaultMessage="Submit" />;
  let cnclBtnTxt = <FormattedMessage id="cancel" defaultMessage="Cancel" />;

  // Loader for button
  let loadingIcon = (
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Loader type="Circles" color="#ffffff" height="14px" width="15" />
    </span>
  );

  if (isLoader) {
    btnTxt = loadingIcon;
  }

  const [isSideBarOpen, switchSideBar] = useState(true);
  const [currentSideBarTab, switchSideBarTab] = useState(0); //0 - todos 1- objectives

  const onChangeComplitingTasks = (indx, i, name) => (e) => {
    e.persist();
    setComplitingTasks((prev) => {
      const newTasks = { ...prev };
      if (newTasks[indx]) {
        newTasks[indx][i] = { value: e.target.checked, name };
      } else {
        newTasks[indx] = { [i]: { value: e.target.checked, name } };
      }
      return newTasks;
    });
  };

  const handleChange = (answerIndex, answerValue) => {
    setFormData((prev) => {
      const newFormData = { ...prev };
      newFormData.answers[answerIndex] = answerValue;
      return newFormData;
    });
  };

  // console.log('==== answers', answers)

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">
          <div className="hide-1600">
            {user && user.department ? (
              <Menu
                companyName={user && user.companyName}
                team={user.departmentName}
                teamId={user.department}
                settings={user.isAdmin && true}
                focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true}
                activeIndex={4}
              />
            ) : (
              <Menu
                companyName={user && user.companyName}
                settings={user && user.isAdmin && true}
                focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true}
                activeIndex={4}
              />
            )}
          </div>
          <UserNav />

          {loading ? (
            <Spinner />
          ) : (
            <Fragment>
              <div className="flex">
                <div className="w-100 main mxa user-standups mr-500 pl-2 pt-0-xs mb-5 pb-5">
                  <div className="standups mt-3">
                    <div className="section-title ">
                      <h5 className="fw-600">
                        <FormattedMessage id="standup.form.header" defaultMessage="Complete a Check-in" />
                      </h5>
                      <p onClick={(e) => goBack()} className="">
                        {icons.circle_close}
                      </p>
                    </div>

                    <div className=" standups-daily  ">
                      <div className="form-full">
                        <div>
                          <p className="fs-14">
                            <FormattedMessage
                              id="standup.form.subHeader"
                              defaultMessage="Let's answer some questions to make your day better"
                            />
                          </p>
                          <div className="line mt-3 mb-3" />
                        </div>

                        <form className="form-app form-app-standup" onSubmit={(e) => onSubmit(e)}>
                          {standup ? (
                            <Fragment>
                              <Fragment>
                                {standup.questions.map((question, index) => (
                                  <div
                                    className="form-main mb-1"
                                    key={index}
                                    onDragOver={(e) => onDragOver(e)}
                                    onDrop={(e) => onDrop(e, index)}
                                  >
                                    <p className="bold medium block pb-0 mb-0">{question.text}</p>
                                      <TextQuestionBody
                                        value={answers[index]}
                                        data={question}
                                        handleChange={(newValue) => {
                                          handleChange(index, newValue);
                                        }}
                                        tasks={tasks}
                                        index={index}
                                        complitingTasks={complitingTasks}
                                        onChangeComplitingTasks={onChangeComplitingTasks}
                                        errorIndex={errorIndex}
                                      />
                                  </div>
                                ))}
                              </Fragment>

                              <div className="form-main mb-5">
                                <p className="bold medium block no-w label-pr3">
                                  <FormattedMessage
                                    id="checkin.form.focus.question"
                                    defaultMessage="What is your main focus today?"
                                  />
                                </p>
                                <FormattedMessage
                                  id="checkin.form.focus.question.input"
                                  defaultMessage="Select OKR"
                                >
                                  {(msg) => (
                                    <Select
                                      className={`small-input w-70-xs mb-2-xs`}
                                      value={focus}
                                      onChange={(selectedOption) => handleFocus(selectedOption)}
                                      options={okrOptions}
                                      isSearchable
                                      placeholder={msg}
                                      styles={customStyles}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>
                            </Fragment>
                          ) : (
                            <Spinner />
                          )}
                          <div className="flex">
                            <button
                              type="submit"
                              className=" mw-150 fs-18 btn btn-small btn-submit btn__secondary_green lh-54 block ml-0-xs  "
                              disabled={!isLoader ? false : true}
                            >
                              {btnTxt}
                            </button>
                            <button
                              type="reset"
                              className="mw-150 ml-2 btn btn-small fs-18 btn-secondary-back-upd "
                              onClick={goBack}
                              disabled={!isLoader ? false : true}
                            >
                              {cnclBtnTxt}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    'w-450px css-main main_menu right tasks m-0 p-0 h-100 flex-column justify-start ' +
                    classNames({ 'tran-trans-05': true, collapsed: !isSideBarOpen, flex: true })
                  }
                >
                  <div
                    className="flex"
                    onClick={() => {
                      switchSideBar((prev) => !prev);
                    }}
                  >
                    <div className={classNames({ 'tran-trans-1': true, mirrored: !isSideBarOpen })}>
                      {icons.double_angle_left}
                    </div>
                    <span className="ml-1 fs-14 fw-600">
                      <FormattedMessage id="collapse" defaultMessage="Collapse" />
                    </span>
                  </div>

                  <div className={`sub-menu__page mt-1 ml-3`}>
                    {sidMenuItems.map((item, index) => (
                      <div
                        key={`menu-${index}`}
                        className={`fs-22 sub-menu__item inline-block mr-2 cursor ${
                          item.value === currentSideBarTab && ' sub-menu__item_active'
                        }`}
                      >
                        {item.title}
                      </div>
                    ))}
                  </div>
                  <div className="ml-3 mt-2">
                    <p className="fs-12">
                      <FormattedMessage
                        id="standup.tasksdugMessage"
                        defaultMessage="You can drag your tasks to instantly fill in text fields"
                      />
                    </p>
                  </div>
                  <div className="flex ml-3">
                    <div className="line-vertical bg-primary_light1 "></div>
                    <div className="px-2 pl-1 ">
                      <div className="tasks__list">
                        {todos.length > 0 ? (
                          todos.map((item) => (
                            <div
                              draggable
                              onDragStart={(e) => onDragStart(e, item._id, item.name)}
                              className="mr-2 my-1 flex align-center"
                              key={`todo-${item._id}`}
                            >
                              {icons.drag_cross_small}
                              <p className="medium card-subtitle  cursor ml-1 fs-14">{item.name}</p>
                            </div>
                          ))
                        ) : (
                          <div className="mr-2">
                            <p className="medium card-subtitle  cursor">
                              <FormattedMessage id="standup.tasks.empty" defaultMessage="No action items" />
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

StandupForm.propTypes = {
  addAnswer: PropTypes.func.isRequired,
  getAllUserOkrs: PropTypes.func.isRequired,
  getStandupById: PropTypes.func.isRequired,
  getUserAnswer: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  getUserTasks: PropTypes.func.isRequired,
  completeTodo: PropTypes.func.isRequired,
  standup: PropTypes.object.isRequired,
  standupAnswer: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired,
  todo: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  standup: state.standup,
  okr: state.okr,
  todo: state.todo,
  standupAnswer: state.standupAnswer,
  locale: state.locale,
  auth: state.auth
});

export default connect(mapStateToProps, {
  addAnswer,
  getStandupById,
  getAllUserOkrs,
  getUserAnswer,
  setAlert,
  getUserTasks,
  completeTodo
})(withRouter(StandupForm));
