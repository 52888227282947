import React from 'react';
import { FormattedMessage } from 'react-intl';

const questions = [
  {
    _id: "question1", 
    text: (
      <FormattedMessage
        id="onboarding.standups.q1"
        defaultMessage="What did you do yesterday?"
      />
    )
  },
  {
    _id: "question2", 
    text: (
      <FormattedMessage
        id="onboarding.standups.q2"
        defaultMessage="What are you going to do today?"
      />
    )
  },
  {
    _id: "question3", 
    text: (
      <FormattedMessage
        id="onboarding.standups.q3"
        defaultMessage="What do you think we can improve?"
      />
    )
  }
]

const answers1 = [
  {
    _id: "answer1", 
    text: [
      (
      <FormattedMessage
        id="onboarding.standups.a1"
        defaultMessage="Creating the new UI for the website"
      />
    ),
    (
      <FormattedMessage
        id="onboarding.standups.a1_2"
        defaultMessage="Market Analysis"
      />
    )
  ],
    questionID: "question1",
    likesAmount: 2,
    commentAmount: 1,
  },
  {
    _id: "answer2", 
    text: [
      (
      <FormattedMessage
        id="onboarding.standups.a2"
        defaultMessage="Update app regarding the new UI"
      />
    )
  ],
    questionID: "question2",
    likesAmount: 1
  },
  {
    _id: "answer3", 
    text: [(
      <FormattedMessage
        id="onboarding.standups.a3"
        defaultMessage="Uncomfortable chair"
      />
    )],
    questionID: "question3",
  }
]

const answers2 = [
  {
    _id: "answer1", 
    text: [
      (
      <FormattedMessage
        id="onboarding.standups.a4"
        defaultMessage="Distribution Channels and Marketing Analysis"
      />
    ),
    (
      <FormattedMessage
        id="onboarding.standups.a4_2"
        defaultMessage="Competitor analysis"
      />
    )
  ],
    questionID: "question1"
  },
  {
    _id: "answer2", 
    text: [(
      <FormattedMessage
        id="onboarding.standups.a5"
        defaultMessage="Create the new marketing campaign"
      />
    )],
    questionID: "question2"
  },
  {
    _id: "answer3", 
    text: [(
      <FormattedMessage
        id="onboarding.standups.a6"
        defaultMessage="Friday beers :)"
      />
    )],
    questionID: "question3"
  }
]

const answers3 = [
  {
    _id: "answer1", 
    text: [(
      <FormattedMessage
        id="onboarding.standups.a7"
        defaultMessage="Signed out the new partnership with ACME"
      />
    )],
    questionID: "question1"
  },
  {
    _id: "answer2", 
    text: [
    (
      <FormattedMessage
        id="onboarding.standups.a8_25x"
        defaultMessage="Create the new management program"
      />
    ),

    (
      <FormattedMessage
        id="onboarding.standups.a8a"
        defaultMessage="Run strategy session"
      />
    )
  ],
    questionID: "question2"
  },
  {
    _id: "answer3", 
    text: [(
      <FormattedMessage
        id="onboarding.standups.a9"
        defaultMessage="More cookies :)"
      />
    )],
    questionID: "question3"
  }
]

const likes1 = [
  {
    _id: "like1",
    user: "onboarding_creator1",
    answerID: "answer1"
  },
  {
    _id: "like2",
    user: "onboarding_creator2",
    answerID: "answer1"
  },
  {
    _id: "like2",
    user: "onboarding_creator2",
    answerID: "answer2"
  }
]

const comment1 = [
  {
    _id: "comment1",
    user: "onboarding_creator1",
    answerID: "answer1",
    text: (
      <FormattedMessage
        id="onboarding.standups.text"
        defaultMessage="Nice work! So excited with our new website!"
      />
    ),
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName1"
        defaultMessage="Tom"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName1"
        defaultMessage="Ford"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar52.jpeg",
    date: "2020-03-12T07:36:33.402+00:00"
  }
]


export const onboardingStandups = [
  { 
    _id: "onboarding_standup1",
    questions: questions,
    answers: answers1,
    user: "onboarding_owner2",
    team: "onboarding_team1",
    teamName: (
      <FormattedMessage
        id="onboarding.okr.product"
        defaultMessage="Product"
      />
    ),
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName2"
        defaultMessage="Julian"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName2"
        defaultMessage="Tobin"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar44.jpeg",
    company: "onboarding_company1",
    companyName: "onboarding_companyName1",
    department: "onboarding_department1",
    departmentName: (
      <FormattedMessage
        id="onboarding.okr.departmentName1"
        defaultMessage="Marketing"
      />
    ),
    standup: "5d36ba95e33e8a061d3b6eda",
    standupID: 1,
    date: "2020-03-11T07:36:33.402+00:00",
    likes: likes1,
    comments: comment1,
    standupDate: "2020-03-31T09:00:00.000+00:00"
  },
  { 
    _id: "onboarding_standup2",
    questions: questions,
    answers: answers2,
    user: "onboarding_owner2",
    team: "onboarding_team2",
    teamName: (
      <FormattedMessage
        id="onboarding.okr.teamName2"
        defaultMessage="HR"
      />
    ),
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName1"
        defaultMessage="Tom"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName1"
        defaultMessage="Ford"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar52.jpeg",
    company: "onboarding_company1",
    companyName: "onboarding_companyName1",
    department: "onboarding_department1",
    departmentName: (
      <FormattedMessage
        id="onboarding.okr.departmentName1"
        defaultMessage="Marketing"
      />
    ),
    standup: "5d36ba95e33e8a061d3b6eda",
    standupID: 2,
    date: "2020-03-11T07:36:33.402+00:00",
    likes: [],
    comments: [],
    standupDate: "2020-03-30T09:00:00.000+00:00"
  },
  { 
    _id: "onboarding_standup3",
    questions: questions,
    answers: answers3,
    user: "onboarding_owner2",
    team: "onboarding_team2",
    teamName: (
      <FormattedMessage
        id="onboarding.okr.teamName2"
        defaultMessage="Marketing"
      />
    ),
    type: "3",
    creator: "onboarding_creator1",
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName3"
        defaultMessage="Patrick"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName3"
        defaultMessage="Brody"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar7.jpeg",
    company: "onboarding_company1",
    companyName: "onboarding_companyName1",
    department: "onboarding_department1",
    departmentName: (
      <FormattedMessage
        id="onboarding.okr.departmentName1"
        defaultMessage="Marketing"
      />
    ),
    standup: "5d36ba95e33e8a061d3b6eda",
    standupID: 3,
    date: "2020-03-11T07:36:33.402+00:00",
    likes: [],
    comments: [],
    standupDate: "2020-03-29T09:00:00.000+00:00"
  }
]

export default {
  onboardingStandups,
}