import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const WidgetsDashboard = ({
  board,
  widgetData,
  width,
  bgColor,
  widgetsAmount,
}) => {

  return (
    <Fragment>
      <div className="dashboards flex space-between mt-3 mb-2 flex-column-s">
        {widgetData.map((item, index) => (
          <div
            key={`dwidgets_${index + 1}`}
            className={`br-12 w-${width} bg-primary_white p-3 w-100-s ${board !== 3 ? 'shadow' : `brd brd-${item.bgColor} ${widgetsAmount === 3 && index === 1 && 'mx-1'}`}`}
          >
            <p className={`fs-50 lh-70 xxl ${item.color && item.color}`}>{item.data}</p>
            <p className="large">{item.title}</p>
            {item.subTitle && (
						  <span className="grey small block">{item.subTitle}</span>
            )}
          </div>
        ))} 
      </div>
    </Fragment>
  );
};

WidgetsDashboard.propTypes = {
  board: PropTypes.number,
  width: PropTypes.number.isRequired,
  bgColor: PropTypes.string,
  widgetData: PropTypes.array.isRequired,
};

export default WidgetsDashboard;
