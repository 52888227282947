import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Logo from '../../../elems/Logo';
import PropTypes from 'prop-types';
import { login } from '../../../../actions/auth';
import { prepareCustomOnboarding, getCustomOnboarding } from '../../../../actions/custom';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../../layout/Spinner';
import Text from '../../elems/Text';
import Btn from '../../elems/WhiteBtn';
import Skip from '../../elems/Skip';
import SelectItem from "../../../elems/Select";
import DayItem from '../../../admin/elems/DayItem';
import QuestionItemNew from '../../../admin/elems/QuestionItemNew';
import AddQuestion from '../../../admin/elems/AddQuestion';
import QuestionItemArrayNew from '../../../admin/elems/QuestionItemArrayNew';
import TimePicker from 'react-time-picker';
import BlueBtn from '../../elems/BlueBtn';
import { setAlert } from '../../../../actions/alert';


const customStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: state.isFocused ? '#404ff5' : '#e9eaf5',
    boxShadow: state.isFocused ? '0px 0px 20px #404FF54D' : 'none',
    border: state.isFocused ? '1px solid #404ff5' : styles.border,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    fontSize: '14px',
    height: '38px',
  }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize, };
  }
}

let questionsArr = [
  { text: '', questionType: { label: `Q1: Text Field`, value: 'text' }, isRequired: true, isTask: false },
  { text: '', questionType: { label: `Q2: Single Select`, value: 'single_select' }, selectOptions: [{ label: '', value: '' }], isRequired: true },
  { text: '', questionType: { label: `Q3: Multiple Select`, value: 'multiple_select' }, selectOptions: [{ label: '', value: '' }], isRequired: false },
  { text: '', questionType: { label: `Q4: OKR Select`, value: 'okr_select' }, isRequired: true },
]

const Onboarding4 = ({ 
  user, 
  prepareCustomOnboarding, 
  getCustomOnboarding,
  history,
  custom: { campaign },
  setAlert
}) => {
  const [formData, setFormData] = useState({
    name: '',
    questions: questionsArr,
    interval: '',
    scheduleDay: {},
    scheduleTime: '',
    slackIsActive: false,
    selectedSlackUsers: [],
    selectedOption: [],
  });

  const { name, questions } = formData;

  useEffect(() => {
    
    if (!campaign) getCustomOnboarding()
    // getCustomOnboarding()

    campaign = JSON.parse(campaign);

    setFormData({
      ...formData,
      interval: !campaign ? '' : campaign.interval,
      scheduleDay: !campaign ? '' : campaign.scheduleDay,
      scheduleTime: !campaign ? '' : campaign.scheduleTime,
      slackIsActive: !campaign ? '' : campaign.slackIsActive,
      selectedSlackUsers: !campaign ? '' : campaign.selectedSlackUsers,
      selectedOption: !campaign ? '' : campaign.selectedOption,
    });
  }, [getCustomOnboarding, campaign && campaign.length]);

  const onSubmit = e => {
    e.preventDefault();

    if (name === '') {
      return setAlert(
        <FormattedMessage
          id="name.is.required"
          defaultMessage="Name is required. Please enter the name of the check-in"
        />,
        'danger'
      );
    } else if (questions.length === 0) {
      return setAlert(
        <FormattedMessage
          id="add.questions.checkin"
          defaultMessage="Please add questions to the check-in"
        />,
        'danger'
      );
    }

    prepareCustomOnboarding(formData, history, 4);
  };

  const onChangeSelect = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onChange = (e, index) => {
    questions[index].text = e.target.value;

    setFormData({ ...formData, questions: questions });
  };

  const handleRemove = index => {
    questions.splice(index, 1);

    setFormData({ ...formData, questions: questions });
  };

  const handleAdd = e => {
    e.preventDefault();

    let newQuestion = { text: '', questionType: { label: `Q${questions.length+1}: Text Field`, value: 'text' }, isRequired: true, isTask: false }

    setFormData({ ...formData, questions: [...questions, newQuestion] });
  };

  const handleSelectChange = (selectedOption, index, type) => {

    if (type === 'isRequired') {
      if (selectedOption === 'false') { selectedOption = true }
      if (selectedOption === 'true') { selectedOption = false }
    } else if (type === 'questionType') {

      if (selectedOption.value !== 'text') {
        if (!questions[index].selectOptions || questions[index].selectOptions && questions[index].selectOptions.length === 0) {
          questions[index].selectOptions = [{ label: '', value: '' }]
        }
      }
    }

    questions[index][type] = selectedOption

    setFormData({ ...formData, questions });
  };

  const onChangeOptions = (e, indexQuestion, indexOption) => {
    questions[indexQuestion].selectOptions[indexOption].label = e.target.value;
    if (!questions[indexQuestion].selectOptions[indexOption].value) {
      questions[indexQuestion].selectOptions[indexOption].value = indexOption+1
    }

    setFormData({ ...formData, questions: questions });
  };


  const handleKeypressOptions = (e, idxQuestion, idx) => {
    // it triggers by pressing the enter key
    if (e.key === 'Enter') {
      e.preventDefault();

      questions[idxQuestion].selectOptions.push({ label: '', value: '' })

      setFormData({ ...formData, questions: questions });

      // focus on the next element
      const form = e.target.form;
      const index = [...form].indexOf(e.target);
      
      let prev = document.getElementById(`selectOption-${idx}`)
      let next = document.getElementById(`selectOption-${idx+1}`)
      form[index+1].focus();
    }
  };

  const onChangeQuestionTasks = (e, questionIdx, name) => {

    let value = e.target.value;

    if (value === 'false') { value = true }
    if (value === 'true') { value = false }

    questions[questionIdx][name] = value;

    setFormData({ ...formData, questions: questions });
  };

  let title = <FormattedMessage id="create.checkin" defaultMessage="Create a Check-in" />
  let message = <FormattedMessage id="onboarding.checkin.msg3" defaultMessage="Give your check-in a name, and add the questions you want to ask your team." />
  let next = <FormattedMessage id="next.step" defaultMessage="Next Step" />
  const nameTitle =<FormattedMessage id="name" defaultMessage="Name" />;
  const questionTitle =<FormattedMessage id="question" defaultMessage="Question" />;
  const questionsDescription =<FormattedMessage id="add.questions.onboarding" defaultMessage="Add questions you want to ask your team." />;
      
  // console.log('======= campaign', campaign)

  return (
    <Fragment>
      {!user ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="css-main onboarding bg__white">
            <div className="wrapper">
              <div className="flex">
                <div className="wr">
                <div className="onboarding__content__kr relative">
                  <div className="">
                    <div className="pb-8">
                      <Logo isNoLink={true} />
                    </div>
                    <Text title={title} text={message} page={4} />

                    <form className="pt-3 form-app form-app-okr">
                      <div className="mb-0">
                        <p className="fs-12 w-100 mb-xs bold-pure">
                          <FormattedMessage id="checkin.name" defaultMessage="Check-in Name" />
                        </p>
                        <QuestionItemNew
                          text={nameTitle}
                          value={name}
                          name={name}
                          inputName={'name'}
                          onChange={onChangeSelect}
                          stylesInput="w-100 h-44 fs-14"
                        />
                      </div>

                      <div className="checkin__questions mt-2">
                        <p className="fs-12 w-100 bold-pure lh-15">
                          <FormattedMessage id="checkin.questions" defaultMessage="Check-in Questions" />
                        </p>
                        <p className="fs-12 dark-gray mb-1">{questionsDescription}</p>
                        {questions.length > 0 && (
                        questions.map((item, index) => (
                          <QuestionItemArrayNew
                            key={`question-${item._id} - ${index}`}
                            text={questionTitle}
                            question={item}
                            index={index}
                            onChange={onChange}
                            handleRemove={handleRemove}
                            handleAdd={handleAdd}
                            handleSelectChange={handleSelectChange}
                            onChangeOptions={onChangeOptions}
                            handleKeypressOptions={handleKeypressOptions}
                            onChangeQuestionTasks={onChangeQuestionTasks}
                            type={2}
                            isHideTask={true}
                          />
                        ) 
                        ))}
                        <div className="mt-2">
                          <AddQuestion handleAdd={handleAdd} />
                        </div> 
                      </div>

                      <div className="buttons pt-3 onboarding__content__button__260">
                        <BlueBtn onClick={onSubmit} text={next} isLoader={false} />
                      </div>
                    </form>
                  </div>
                  
                  <Skip/>
                </div>
                </div>
                
                <div className="onboarding__image onboarding__image__checkin__settings"></div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

Onboarding4.propTypes = {
  login: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  prepareCustomOnboarding: PropTypes.func.isRequired,
  getCustomOnboarding: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  custom: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.user,
  custom: state.custom
});

export default connect(
  mapStateToProps,
  { login, prepareCustomOnboarding, getCustomOnboarding, setAlert }
)(Onboarding4);
