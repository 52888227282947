import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const UniversalBlueBtn = ({ parentId, childId, onSubmit, data, toggleEditForm, displayEdit }) => {
  return (
    <Fragment>
      <button 
        type="submit" 
        className="btn btn-small btn-small-blue ml-1"
      >
        <FormattedMessage
          id="btn.submit"
          defaultMessage="Submit"
        />
      </button>
    </Fragment>
  );
};

UniversalBlueBtn.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  parentId: PropTypes.string,
  childId: PropTypes.string,
};

export default UniversalBlueBtn;
