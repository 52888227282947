import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { updateUpdateLike } from '../../../actions/updateAnswer';
import CommentItem from '../../comment/updates-noState/CommentItem';
import CommentForm from '../../comment/updates-noState/CommentForm';
import QuestionItem from '../../comment/updates/QuestionItem';
import QuestionItemOkr from '../../comment/updates/QuestionItemOkr';
import { FormattedMessage } from 'react-intl';

const UpdateItemCardQuestion = ({
  auth: { loading, user },
  avatar,
  question,
  answer,
  comments,
  likes,
  updateID,
  userID,
  updateUpdateLike,
  index,
  campaignType,
  weeklyOkrs,
  lastWeeklyOkrs,
}) => {
  const findUserLike = likes => {
    if (!loading) {
      if (likes.filter(like => like.user === user._id).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const findComments = comments => {
    if (comments.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const [discussion, showComments] = useState(false);

  return (
    <Fragment>
      <div className={`${index === 0 ? "card-question-item" : "card-list card-question-item pt-1"}`}>
        {index === 0 && campaignType === 2 ? (
          <QuestionItemOkr
            key={`teamQuestionItem-${updateID}-${answer._id}`}
            question={question}
            answer={answer && answer.okrAnswers && answer.okrAnswers.length > 0 && answer.okrAnswers}
            avatar={avatar}
            _id={userID}
            index={index}
            weeklyOkrs={weeklyOkrs}
            lastMetrics={lastWeeklyOkrs}
          />
        ) : (
          <QuestionItem
            key={`teamQuestionItem-${updateID}-${answer._id}`}
            question={question}
            answer={answer}
            avatar={avatar}
            _id={userID}
            index={index}
          />
        )}

        <Fragment>
          <div className="btn-feedback">
            <button
              type="button"
              className={classnames('btn-icons btn-like', {
                'btn-active-icon': findUserLike(likes)
              })}
              onClick={e => updateUpdateLike(updateID, answer._id)}
            >
              <i className="fas fa-heart" />
              <span className="small badge">
                {answer.likesAmount > 0 && <span>{answer.likesAmount}</span>}
              </span>
            </button>

            <button
              type="button"
              onClick={() => showComments(!discussion)}
              className={classnames('btn-icons btn-comment', {
                'btn-active-icon': findComments(comments)
              })}
            >
              <i className="fas fa-comment-alt" />
              <span className="small badge">
                {comments.length > 0 && <span>{comments.length}</span>}
              </span>
            </button>
          </div>
        </Fragment>

        {discussion && (
          <Fragment>
            <div className="card-feedback">
              <div className="comment">
                <p className="comment-title medium bold">
                  <strong>
                    <FormattedMessage id="comments" defaultMessage="Comments" />
                  </strong>
                </p>
                <div className="line" />

                {comments.map(comment => (
                  <CommentItem
                    key={`team-${comment._id}`}
                    comment={comment}
                    updateID={updateID}
                    answerID={answer._id}
                  />
                ))}

                <CommentForm
                  updateID={updateID}
                  answerID={answer._id}
                  user={user}
                  avatar={user.avatar}
                />
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

UpdateItemCardQuestion.propTypes = {
  auth: PropTypes.object.isRequired,
  updateUpdateLike: PropTypes.func
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { updateUpdateLike }
)(UpdateItemCardQuestion);
