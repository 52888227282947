import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Highlighter from "react-highlight-words";
import { icons } from "../../img/elements/icons";


const QuestionItem = ({ searchKeyword, question, answer, _id }) => {
  return (
    <Fragment>
      <p className="question-title medium bold mb-0">
        <strong>{question.text}</strong>
      </p>
      <div className="card-answer border-bottom-gray mb-1">

        <ul className="answer-item">
          {answer.text.map((text, i) => (

            <li key={`answer-${i}`} className="list-item fs-14 my-1 flex align-center">
              <span className="mr-14 flex align-center">
                {icons.arrow_right}
              </span>
              {' '}
              <Highlighter
                highlightClassName="bg-yellow_pure"
                searchWords={[searchKeyword]}
                autoEscape={true}
                textToHighlight={text}
              />
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  );
};

QuestionItem.propTypes = {
  question: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  avatar: PropTypes.string.isRequired
};

export default QuestionItem;
