import {
  GET_UPDATES,
  GET_UPDATE,
  UPDATES_ERROR,
  CLEAR_UPDATES,
  GET_UPDATES_SETTINGS,
  UPDATES_SETTINGS_ERROR,
  CHANGE_CAMPAIGN_TYPE,
} from '../actions/types';

const initialState = {
  updates: [],
  update: null,
  settings: null,
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_UPDATES:
      return {
        ...state,
        updates: payload,
        loading: false
      };
    case GET_UPDATE:
      return {
        ...state,
        update: payload,
        loading: false
      };
    case GET_UPDATES_SETTINGS:
      return {
        ...state,
        settings: payload,
        loading: false
      };
    case UPDATES_SETTINGS_ERROR:
    case UPDATES_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case CHANGE_CAMPAIGN_TYPE:
      return {
        ...state,
        settings: payload,
      };
    case CLEAR_UPDATES:
      return {
        ...state,
        updates: [],
        update: null,
        settings: null,
        loading: true
      };
    default:
      return state;
  }
}
