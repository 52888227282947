import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import Menu from '../layout/Menu';
import UserNav from '../layout/UserNav';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import Notification from '../utils/Notification';
import DayFocus from '../utils/DayFocus';
import WeekFocus from '../utils/WeekFocus';
import CheckListHelloAdmin from '../utils/CheckListHelloAdmin';
import CheckListHelloUser from '../utils/CheckListHelloUser';
import HelloCopy from '../utils/HelloCopy';
import { getCompanySortedOkrs } from '../../actions/okr';
import { getUserAnswer, getUserAnswers, getCompanyAnswers } from '../../actions/standupAnswer';
import { getUserPlanAnswers, editAllPlanAnswers } from '../../actions/planAnswer';
import { getPlanSettings } from '../../actions/plan';
import { getStandups } from '../../actions/standup';
import { getIcebreakers } from '../../actions/icebreaker';
import { getUpdates } from '../../actions/update';
import { getUserUpdateAnswers } from '../../actions/updateAnswer';
import { getUserCustomAnswers } from '../../actions/customAnswer';
import { getAllCustoms } from '../../actions/custom';
import { getHome } from '../../actions/home';
import { getSlackTeam } from '../../actions/slack';
import { getUserMeetings } from '../../actions/oneOnOne';
import { getMoodSettingsAndUserAnswer } from '../../actions/moodAnswer';
import MoodItem from '../mood/MoodItem';
import CheckinItemCard from '../checkins/noState/CheckinItemCard';
import UpdateItemCard from '../updates-new/noState/UpdateItemCard';
import PlanItemCard from '../plans/PlanItemCard';   // no state!
import RecognitionItem from '../recognitions/noState/RecognitionItem';
import Moment from 'react-moment';
import { checkinCopy } from '../../utils/wording/checkins';
import { updateCopy } from '../../utils/wording/updates';
import { planCopy } from '../../utils/wording/plans';
import { copy } from '../../utils/wording/customs';
import { FormattedMessage } from 'react-intl';
import { getUserCompany } from '../../actions/company';
import { getCompanyProfiles } from '../../actions/profile';

const Home = ({
  getIcebreakers,
  getHome,
  home: { data },
  getUserUpdateAnswers,
  updateAnswer: { updateAnswer, updateAnswers, isHomeLoadingUpdate },
  getStandups, // ToDo remove 
  getUpdates, // ToDo remove 
  standup: { standups },   // ToDo remove 
  update: { updates },   // ToDo remove 
  getCompanySortedOkrs,
  getUserCompany,
  getCompanyProfiles,
  getUserAnswers,
  auth: { user, isAuthenticated },
  company: { company },
  standupAnswer: { standupAnswer, standupAnswers, isHomeLoading },
  profile: { profiles, loading },
  okr: { okrs, isHomeLoadingOkr },
  icebreaker: { icebreakers },
  locale,
  getPlanSettings,
  plan,
  getUserPlanAnswers,
  planAnswer: { planAnswer, planAnswers },
  editAllPlanAnswers,
  getUserCustomAnswers,
  getAllCustoms,
  custom: { campaigns },
  customAnswer: { customAnswers },
  getSlackTeam,
  slack,
  getUserMeetings,
  oneOnOne,
  getMoodSettingsAndUserAnswer,
  mood,
}) => {

  // Redirect if signed up
  if (isAuthenticated) {
    return <Redirect to="/dashboard/objectives" />;
  }

  const [planData, setPlanData] = useState({
    plans: []
  });
  const [slackData, setSlackData] = useState({
    slackLink: null
  });

  let { plans, } = planData;
  let { slackLink, } = slackData;


  useEffect(() => {
    getHome();
    getUserCompany();
    getUserAnswers();
    getCompanyProfiles();
    getCompanySortedOkrs();
    getStandups();  // ToDo: remove 
    getUpdates();  // ToDo: remove 
    getUserUpdateAnswers();
    getIcebreakers();
    getUserPlanAnswers();
    getPlanSettings();
    getUserCustomAnswers();
    getAllCustoms();
    getUserMeetings();
    getMoodSettingsAndUserAnswer();

    company && company.isSlack && getSlackTeam();

    setPlanData({
      plans: !planAnswers ? [] : planAnswers,
    });

    // ToDo: определение ссылки не работает. Сделать, чтобы ссылка записывалась в стейт
    setSlackData({
      slackLink: slack && slack.team && slack.team.url ? slack.team.url : null,
    });
  }, [loading, getHome, getUserCompany, getCompanyProfiles,
    getCompanySortedOkrs, getUserAnswer, getUserAnswers,
    getStandups, getUserUpdateAnswers, getCompanyAnswers,
    getUpdates, getIcebreakers, 
    getUserPlanAnswers, getPlanSettings,
    editAllPlanAnswers,
    getUserCustomAnswers,
    getAllCustoms,
    company && company.departments && company.departments.length,
    getSlackTeam,
    getUserMeetings,
    getMoodSettingsAndUserAnswer,
    JSON.stringify(mood),
  ]);

  const msg = (
    <FormattedMessage
      id="home.demo.notification.msg1"
      defaultMessage="Need help?"
    />
  );

  const msg2 = (
    <FormattedMessage
      id="home.demo.notification.msg2"
      defaultMessage="We'll be happy to answer your questions and take you on a product demo."
    />
  );

  const cta = (
    <FormattedMessage
      id="home.demo.notification.cta"
      defaultMessage="Schedule a demo"
    />
  );

  const msgHelloUser = (
    <FormattedMessage
      id="home.demo.user.notification.msg1"
      defaultMessage="Check-in is going on."
    />
  );

  const msgHelloUser2 = (
    <FormattedMessage
      id="home.demo.user.notification.msg2"
      defaultMessage="Click the button below to share your first check-in."
    />
  );

  const ctaHelloUser = (
    <FormattedMessage
      id="home.demo.user.notification.cta"
      defaultMessage="Share a Check-in"
    />
  );

  let focusOkrDay = {};
  let focusOkrWeek = {};

  // Choose what should be in weekly focus - weekly plan or focus
  // Old companies still can use weekly updates
  let weeklyFocus = planAnswer && planAnswer.focus ? planAnswer : updateAnswer;
  focusOkrDay = okrs && okrs.length > 0 && standupAnswer && standupAnswer.focus && standupAnswer.focus.okr && okrs.filter(okr => okr._id.toString() === standupAnswer.focus.okr.toString());
  focusOkrWeek = okrs && okrs.length > 0 && weeklyFocus && weeklyFocus.focus && weeklyFocus.focus.okr && okrs.filter(okr => okr._id.toString() === weeklyFocus.focus.okr.toString());
  
  return (
    <Fragment>
      <Fragment>
        <div className="css-main bg-primary">
          <div className="wrapper">

            {user && user.department ? (
              <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user && user.isAdmin && true} focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={1} />
            ) : (
              <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={1} />
            )}

            <UserNav />
           
            <Fragment>
              {loading || !company || !profiles || !user || !okrs || !data || !standupAnswers || !updateAnswers || isHomeLoading || isHomeLoadingOkr || isHomeLoadingUpdate ? (
                <Spinner />
              ) : (
                <Fragment>
                  <div className="main main-dashboard">
                    <div className="home">
                      <h5 className="pl-0-xs">
                        <FormattedMessage
                          id="Home.header"
                          defaultMessage="Home"
                        />
                      </h5>

                      {company && company.plan > 3 && mood && mood.answer && mood.answer !== null && mood.settings && mood.settings.active && mood.answer.moodID !== mood.settings.moodID ||
                       company && company.plan > 3 && mood && !mood.loading && mood.answer === null ? (
                        <div className="component mt-3">
                          <div className="br-12 light-shadow bg__white w-100">
                            <MoodItem />
                          </div>
                        </div>
                      ) : null}

                      {user.isAdmin ? (
                        profiles.length === 1 || standupAnswers.length === 0 || okrs.length === 0 || planAnswers.length === 0 ? (
                          <Fragment>
                            <Notification
                              msg={msg}
                              msg2={msg2}
                              cta={cta}
                              btnColor={'blue'}
                              color={`blue`}
                              externalLink={1}
                              url={`https://usefocus.co/demo`}
                            />

                            <HelloCopy
                              lang={locale.lang}
                              slack={company && company.isSlack}
                              slackLink={slackLink}
                              styles={''}
                            />

                            <CheckListHelloAdmin 
                              profiles={profiles}
                              okrs={okrs}
                              standupAnswer={standupAnswer && standupAnswer}
                              standups={standups && standups}
                              planAnswers={planAnswers && planAnswers.length > 0 && planAnswers}
                              icebreakers={icebreakers && icebreakers.length > 0 && icebreakers}
                              oneOnOnes={oneOnOne !== undefined && oneOnOne.meetings}
                            />

                            {standups && standupAnswers.length === 0 && standups.length > 0 && (
                                  <Notification
                                    msg={msgHelloUser}
                                    msg2={msgHelloUser2}
                                    cta={ctaHelloUser}
                                    btnColor={'orange'}
                                    color={`orange`}
                                    url={standups && standups.length > 0 ? `/checkins/${standups[0]._id}` : '/checkins'}
                                  />
                                )}

                            {standups && standupAnswers.length > 0 && standups.length > 0 &&
                                standups[0]._id.toString() !== standupAnswers[0].standup.toString() && (
                                  <Notification
                                    msg={checkinCopy.OnboardingstandupLive1}
                                    msg2={checkinCopy.standupLive2}
                                    cta={checkinCopy.standupLiveCta}
                                    btnColor={'orange'}
                                    color={`orange`}
                                    url={standups && standups.length > 0 ? `/checkins/${standups[0]._id}` : '/checkins'}
                                  />
                                )}

                            {plan && planAnswers.length === 0 && plan.settings && plan.settings.active ||
                            plan && planAnswer && plan.settings && plan.settings.active && planAnswer.planID !== plan.settings.planID ? (
                              <Notification
                                msg={planCopy.planLive1}
                                msg2={planCopy.planLive2}
                                cta={planCopy.planLiveCta}
                                btnColor={'yellow'}
                                color={`yellow`}
                                url={`/plans/complete`}
                              />
                            ) : null}

                            {updates && updateAnswers.length === 0 && updates.length > 0 && (
                                  <Notification
                                    msg={updateCopy.updateLive1}
                                    msg2={updateCopy.updateLive2}
                                    cta={updateCopy.updateLiveCta}
                                    btnColor={'green'}
                                    color={`green`}
                                    url={updates.length > 0 ? `/updates/${updates[0]._id}` : '/updates'}
                                  />
                              )}

                            {updates && updateAnswers.length > 0 && updates.length > 0 &&
                                updates[0]._id.toString() !== updateAnswers[0].update.toString() && (
                                  <Notification
                                    msg={updateCopy.updateLive1}
                                    msg2={updateCopy.updateLive2}
                                    cta={updateCopy.updateLiveCta}
                                    btnColor={'green'}
                                    color={`green`}
                                    url={updates.length > 0 ? `/updates/${updates[0]._id}` : '/updates'}
                                  />
                                )}
                          </Fragment>
                        ) : (
                          <Fragment>
                            {standups && standupAnswers &&
                            standupAnswers.length > 0 && standups.length > 0 &&
                                standups[0]._id.toString() !== standupAnswers[0].standup.toString() && (
                                  <Notification
                                    msg={checkinCopy.standupLive1}
                                    msg2={checkinCopy.standupLive2}
                                    cta={checkinCopy.standupLiveCta}
                                    btnColor={'orange'}
                                    color={`orange`}
                                    url={standups && standups.length > 0 ? `/checkins/${standups[0]._id}` : '/checkins'}
                                  />
                                )}

                                {plan && planAnswer && plan.settings && plan.settings.active && planAnswer.planID !== plan.settings.planID && (
                                  <Notification
                                    msg={planCopy.planLive1}
                                    msg2={planCopy.planLive2}
                                    cta={planCopy.planLiveCta}
                                    btnColor={'yellow'}
                                    color={`yellow`}
                                    url={`/plans/complete`}
                                  />
                                )}

                                {updates && updateAnswers && updateAnswers.length > 0 && updates.length > 0 &&
                                    updates[0]._id.toString() !== updateAnswers[0].update.toString() || 
                                    updates && updateAnswers && updateAnswers.length === 0 && updates.length > 0 ? (
                                      <Notification
                                        msg={updateCopy.updateLive1}
                                        msg2={updateCopy.updateLive2}
                                        cta={updateCopy.updateLiveCta}
                                        btnColor={'green'}
                                        color={`green`}
                                        url={updates.length > 0 ? `/updates/${updates[0]._id}` : '/updates'}
                                      />
                                    ) : ("")}

                                
                                {campaigns && customAnswers && customAnswers.length === 0 && campaigns.length > 0 ? (
                                  campaigns.map(campaign => (
                                    <Notification
                                        key={`customs-${campaign._id}`}
                                        msg={copy.liveMsg1}
                                        msg2={copy.liveMsg2}
                                        cta={copy.liveCta}
                                        btnColor={'green'}
                                        color={`green`}
                                        url={`/campaign/create/${campaign._id}`}
                                      />
                                  ))) : (
                                    campaigns && customAnswers && customAnswers.length > 0 && campaigns.length > 0 ? (
                                      campaigns.map(campaign => (
                                        customAnswers.filter(item => item.custom && item.custom.toString() === campaign._id.toString() && item.customID === campaign.customID).length === 0 && (
                                          <Notification
                                            key={`notifications-${campaign._id}`}
                                            msg={copy.liveMsg1}
                                            msg2={copy.liveMsg2}
                                            cta={copy.liveCta}
                                            btnColor={'green'}
                                            color={`green`}
                                            url={`/campaign/create/${campaign._id}`}
                                          />
                                        )
                                      ))
                                    ) : ("")
                                  )}

                                <WeekFocus 
                                  focus={weeklyFocus && weeklyFocus.focus}
                                  okr={focusOkrWeek && focusOkrWeek[0]} 
                                  date={weeklyFocus && weeklyFocus.date}
                                />

                                <DayFocus 
                                  focus={standupAnswer && standupAnswer.focus}
                                  okr={focusOkrDay && focusOkrDay[0]} 
                                  date={standupAnswer && standupAnswer.date}
                                />
                              </Fragment>
                        )
                      ) : (
                        profiles.length === 1 || standupAnswers.length === 0 || okrs.length === 0 || updateAnswers.length === 0 ? (
                          <Fragment>
                            {standups && standupAnswers.length === 0 && standups.length > 0 && (
                                  <Notification
                                    msg={msgHelloUser}
                                    msg2={msgHelloUser2}
                                    cta={ctaHelloUser}
                                    btnColor={'orange'}
                                    color={`orange`}
                                    url={standups && standups.length > 0 ? `/checkins/${standups[0]._id}` : '/checkins'}
                                  />
                                )}

                            {standups && standupAnswers.length > 0 && standups.length > 0 &&
                                standups[0]._id.toString() !== standupAnswers[0].standup.toString() && (
                                  <Notification
                                    msg={checkinCopy.standupLive1}
                                    msg2={checkinCopy.standupLive2}
                                    cta={checkinCopy.standupLiveCta}
                                    btnColor={'orange'}
                                    color={`orange`}
                                    url={standups && standups.length > 0 ? `/checkins/${standups[0]._id}` : '/checkins'}
                                  />
                                )}

                            {plan && planAnswers.length === 0 && plan.settings && plan.settings.active ||
                            plan && planAnswer && plan.settings && plan.settings.active && planAnswer.planID !== plan.settings.planID ? (
                              <Notification
                                msg={planCopy.planLive1}
                                msg2={planCopy.planLive2}
                                cta={planCopy.planLiveCta}
                                btnColor={'yellow'}
                                color={`yellow`}
                                url={`/plans/complete`}
                              />
                            ) : null}

                            {updates && updateAnswers.length === 0 && updates.length > 0 && (
                                  <Notification
                                    msg={updateCopy.updateLive1}
                                    msg2={updateCopy.updateLive2}
                                    cta={updateCopy.updateLiveCta}
                                    btnColor={'green'}
                                    color={`green`}
                                    url={updates.length > 0 ? `/updates/${updates[0]._id}` : '/updates'}
                                  />
                                )}

                            <CheckListHelloUser 
                              profiles={profiles}
                              okrs={okrs}
                              standupAnswer={standupAnswer && standupAnswer}
                              updateAnswers={updateAnswers && updateAnswers}
                              standups={standups && standups}
                              icebreakers={icebreakers && icebreakers}
                              planAnswers={planAnswers && planAnswers}
                              oneOnOnes={oneOnOne !== undefined && oneOnOne.meetings}
                            />
                          </Fragment>
                        ) : (
                          <Fragment>
                            {standups && standupAnswers.length > 0 && standups.length > 0 &&
                                standups[0]._id.toString() !== standupAnswers[0].standup.toString() && (
                                  <Notification
                                    msg={checkinCopy.standupLive1}
                                    msg2={checkinCopy.standupLive2}
                                    cta={checkinCopy.standupLiveCta}
                                    btnColor={'orange'}
                                    color={`orange`}
                                    url={standups && standups.length > 0 ? `/checkins/${standups[0]._id}` : '/checkins'}
                                  />
                                )}

                            {plan && planAnswer && plan.settings && planAnswer.planID !== plan.settings.planID && plan.settings.active ||
                            plan && planAnswers.length === 0 && plan.settings && plan.settings.active ? (
                              <Notification
                                msg={planCopy.planLive1}
                                msg2={planCopy.planLive2}
                                cta={planCopy.planLiveCta}
                                btnColor={'yellow'}
                                color={`yellow`}
                                url={`/plans/complete`}
                              />
                              ) : null}
                                  
                            {updates && updateAnswers.length > 0 && updates.length > 0 &&
                                updates[0]._id.toString() !== updateAnswers[0].update.toString() && (
                                  <Notification
                                    msg={updateCopy.updateLive1}
                                    msg2={updateCopy.updateLive2}
                                    cta={updateCopy.updateLiveCta}
                                    btnColor={'green'}
                                    color={`green`}
                                    url={updates.length > 0 ? `/updates/${updates[0]._id}` : '/updates'}
                                  />
                                )}

                                <WeekFocus 
                                  focus={weeklyFocus && weeklyFocus.focus}
                                  okr={focusOkrWeek && focusOkrWeek[0]} 
                                  date={weeklyFocus && weeklyFocus.date}
                                />

                                <DayFocus 
                                  focus={standupAnswer && standupAnswer.focus}
                                  okr={focusOkrDay && focusOkrDay[0]} 
                                  date={standupAnswer && standupAnswer.date}
                                />
                              </Fragment>
                            )
                      )}
                      
                      <div className="mt-3">
                        {data && data.length > 0 && (
                          data.map((item, index) => (
                            index === 0 || index > 0 &&
                              <h3 className="fs-2 pt-2 pl-2 pb-1" key={`date-${index}`}>
                                <Moment format="D MMM" key={`date-moment-${index}`}>{item.date}</Moment>
                              </h3>,

                            item.standupID ? (
                              <div className="component my-2 px-4-l py-2 light-shadow no-shadow-xs" key={index}>
                                <div className="cards">
                                  <CheckinItemCard
                                    standupAnswer={item}
                                    key={`team-${item._id}-${index}`}
                                    standupDate={item}
                                  />
                                </div>
                              </div>
                            ) : ( 
                              item.updateID ? (
                              <div className="component my-2 px-4-l py-2 light-shadow no-shadow-xs" key={index}>
                                <div className="cards">
                                  <UpdateItemCard
                                    updateAnswer={item}
                                    key={`team-${item._id}-${index}`}
                                    updateDate={item.date}
                                  />
                                </div>
                              </div>
                            ) : (item.planID ? (
                              <div className="component my-2 px-4-l py-2 light-shadow no-shadow-xs" key={index}>
                                <div className="cards">
                                  <PlanItemCard
                                    planAnswer={item}
                                    key={`team-${item._id}-${index}`}
                                    formData={planData}
                                    setFormData={setPlanData}
                                    auth={user._id}
                                    settingsID={plan && plan.settings && plan.settings.planID}
                                  />
                                </div>
                              </div>
                            ) : (item.recipient && (
                                <RecognitionItem
                                  key={item._id}
                                  recognition={item}
                                  home={true}
                                />
                            )))
                          ))
                        ))}
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            </Fragment>
          </div>
        </div>
      </Fragment>
    </Fragment>
  );
};

Home.propTypes = {
  getHome: PropTypes.func.isRequired,
  getStandups: PropTypes.func.isRequired,  // remove
  getUpdates: PropTypes.func.isRequired,  // remove
  getCompanyAnswers: PropTypes.func.isRequired,
  getCompanySortedOkrs: PropTypes.func.isRequired,
  getUserAnswers: PropTypes.func.isRequired,
  getUserUpdateAnswers: PropTypes.func.isRequired,
  getUserAnswer: PropTypes.func.isRequired,
  getIcebreakers: PropTypes.func.isRequired,
  icebreaker: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  home: PropTypes.object.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  getUserPlanAnswers: PropTypes.func.isRequired,
  getPlanSettings: PropTypes.func.isRequired,
  editAllPlanAnswers: PropTypes.func.isRequired,
  getUserCustomAnswers: PropTypes.func.isRequired,
  getAllCustoms: PropTypes.func.isRequired,
  getUserMeetings: PropTypes.func.isRequired,
  getSlackTeam: PropTypes.func.isRequired,
  getMoodSettingsAndUserAnswer: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired,
  standupAnswer: PropTypes.object.isRequired,
  update: PropTypes.object.isRequired,    // ToDo remove
  standup: PropTypes.object.isRequired,    // ToDo remove
  updateAnswer: PropTypes.object.isRequired,   
  planAnswer: PropTypes.object.isRequired,   
  plan: PropTypes.object.isRequired,   
  slack: PropTypes.object.isRequired,   
  oneOnOne: PropTypes.object.isRequired,   
  mood: PropTypes.object.isRequired,   
};

const mapStateToProps = state => ({
  auth: state.auth,
  locale: state.locale,
  home: state.home,
  company: state.company,
  profile: state.profile,
  okr: state.okr,
  standupAnswer: state.standupAnswer,
  updateAnswer: state.updateAnswer,
  planAnswer: state.planAnswer,
  standup: state.standup,   // ToDo remove
  update: state.update,    // ToDo remove
  icebreaker: state.icebreaker,   
  plan: state.plan,   
  custom: state.custom,   
  customAnswer: state.customAnswer,   
  slack: state.slack,   
  oneOnOne: state.oneOnOne,   
  mood: state.ms,   
});

export default connect(
  mapStateToProps,
  {
    getHome, getUserCompany, getCompanyProfiles,
    getCompanySortedOkrs, getUserAnswer, getStandups,
    getUserAnswers, getUserUpdateAnswers, getCompanyAnswers,
    getUpdates, getIcebreakers, getUserPlanAnswers,
    getPlanSettings, editAllPlanAnswers,
    getUserCustomAnswers,
    getAllCustoms,
    getSlackTeam,
    getUserMeetings,
    getMoodSettingsAndUserAnswer,
  }
)(Home);
