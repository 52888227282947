import React from "react";
import PropTypes from 'prop-types';

const TodoIcon = ({ activeIndex }) => {
  return (
    <svg className="w-20px" id="circle-check" xmlns="http://www.w3.org/2000/svg" width="20" height="20.002" viewBox="0 0 20 20.002">
    <path id="Path_14" fill={`${activeIndex === 12 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 14" d="M-358.1,128.279a9.964,9.964,0,0,1-9.985,9.995,9.95,9.95,0,0,1-10.015-10,9.985,9.985,0,0,1,10.023-10A10,10,0,0,1-358.1,128.279Zm-1.21-.017a8.761,8.761,0,0,0-8.824-8.761,8.7,8.7,0,0,0-8.741,8.784,8.664,8.664,0,0,0,8.806,8.762A8.735,8.735,0,0,0-359.306,128.262Z" transform="translate(378.096 -118.272)"/>
    <path id="Path_15" fill={`${activeIndex === 12 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 15" d="M-369.353,129.9c.4-.39.765-.748,1.129-1.11q1.617-1.6,3.231-3.208a2.475,2.475,0,0,1,.311-.277.538.538,0,0,1,.754.052.524.524,0,0,1,.06.75,2.212,2.212,0,0,1-.25.28q-2.3,2.305-4.606,4.608l-.06.059c-.452.439-.683.444-1.126.005-.693-.685-1.38-1.377-2.069-2.066-.1-.1-.2-.192-.291-.3a.555.555,0,0,1-.008-.821.551.551,0,0,1,.819-.017c.274.236.525.5.784.75C-370.247,129.021-369.821,129.44-369.353,129.9Z" transform="translate(378.096 -118.272)"/>
  </svg>
  );
};

TodoIcon.propTypes = {
  activeIndex: PropTypes.number
};

export default TodoIcon;
