import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import OutreachRow from './OutreachRow';

const OutreachTable = ({ users }) => {
  return (
    <Fragment>
      <table className="text-left">
			  <thead>
			    <tr>
			      <th className="bold medium pr-2">Name</th>
			      <th className="bold medium pr-2">Email</th>
			      <th className="bold medium pr-2">Company</th>
			      <th className="bold medium pr-2">Position</th>
			      <th className="bold medium pr-2">Campaign</th>
			      <th className="bold medium pr-2">Sent emails</th>
			      <th className="bold medium pr-2">Schedule (UTC)</th>
			      <th className="bold medium pr-2">Comment</th>
			      <th className="bold medium pr-2">Other Information</th>
			      <th className="bold medium pr-2">Jobs</th>
			      <th className="bold medium pr-2">VC Round</th>
			      <th className="bold medium pr-2">VC Month</th>
			      <th className="bold medium pr-2">Status</th>
			      <th className="bold medium pr-2">Btn</th>
			    </tr>
			  </thead>
			  <tbody>
          {users && users.length > 0 ? (
            users.map(user => (
              <OutreachRow 
                user={user && user._id && user} 
                key={user && user._id}
              />
            ))
          ) : (
            <Fragment>
              <tr>
                <td className="medium">No contacts</td>
              </tr>
            </Fragment>
          )}
			  </tbody>
			</table>
      
    </Fragment>
  );
};

OutreachTable.propTypes = {
  users: PropTypes.array.isRequired
};

export default OutreachTable;
