import axios from 'axios';
import { setAlert } from './alert';

import {
  GET_CAMPAIGN,
  GET_EMAILS,
  GET_EMAIL,
  ADD_CAMPAIGN,
  ADD_EMAIL,
  EDIT_EMAIL,
  REMOVE_EMAIL,
  DELETE_CAMPAIGN,
  CLEAR_EMAILS,
  CAMPAIGN_ERROR,
  EMAIL_ERROR,
} from './types';

// Get all email templates
export const getReachEmailData = () => async dispatch => {
  try {
    const res = await axios.get('/api/outreachemails/all');

    dispatch({
      type: GET_EMAILS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CAMPAIGN_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get campaign by Id
export const getEmailCampaign = id => async dispatch => {
  try {
    const res = await axios.get(`/api/outreachemails/${id}`);

    dispatch({
      type: GET_CAMPAIGN,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CAMPAIGN_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get campaign and email by Id
export const getEmailById = (id, emailId) => async dispatch => {
  try {
    const res = await axios.get(`/api/outreachemails/${id}/${emailId}`);

    dispatch({
      type: GET_EMAIL,
      payload: { campaignId: id, data: res.data }
    });
  } catch (err) {
    dispatch({
      type: EMAIL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Create new template 
export const createCampaignAndEmail = (name, title, text, history) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({
      name,
      title,
      text
    });

    const res = await axios.post(`/api/outreachemails/create`, body, config);

    dispatch({
      type: ADD_CAMPAIGN,
      payload: res.data
    });

    dispatch(setAlert('Campaign was created', 'success', 1000));
   
    history.push('/outreacher/campaigns');

  } catch (err) {
    
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: CAMPAIGN_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Add new email to existing template 
export const addEmail = (name, title, text, id, history) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({
      name,
      title,
      text
    });

    const res = await axios.put(`/api/outreachemails/create/${id}`, body, config);

    dispatch({
      type: ADD_EMAIL,
      payload: res.data
    });

    dispatch(setAlert('Email was added', 'success', 1000));

    history.push('/outreacher/campaigns');
  } catch (err) {
    
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: EMAIL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Edit email 
export const editEmail = (name, title, text, id, emailId, history) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({
      name,
      title,
      text
    });

    const res = await axios.put(`/api/outreachemails/edit/${id}/${emailId}`, body, config);

    dispatch({
      type: EDIT_EMAIL,
      payload: { id, data: res.data }
    });

    dispatch(setAlert('Email was edited', 'success', 1000));

    history.push('/outreacher/campaigns');
  } catch (err) {
    
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: EMAIL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Delete  template and all email
export const deleteCampaign = (id) => async dispatch => {
  try {
    await axios.delete(`/api/outreachemails/campaign/${id}`);

    dispatch({
      type: DELETE_CAMPAIGN,
      payload: { id }
    });

    // Callback for updating in formData.objectives in useState to render it
    const data = { campaignId: id }
    dispatch(setAlert('Campaign was deleted', 'success', 1000));
    return data;

  } catch (err) {
    dispatch({
      type: CAMPAIGN_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete email
export const deleteEmail = (id, emailId) => async dispatch => {
  try {
    await axios.delete(`/api/outreachemails/email/${id}/${emailId}`);

    dispatch({
      type: REMOVE_EMAIL,
      payload: { campaignId: id, emailId }
    });

    // Callback for updating in formData.objectives in useState to render it
    const data = { campaignId: id, emailId }
    dispatch(setAlert('Email was deleted', 'success', 1000));
    return data;
  } catch (err) {
    dispatch({
      type: EMAIL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Clear state
export const clearReachEmail = () => async dispatch => {
  try {
    
    dispatch({
      type: CLEAR_EMAILS
    });
  } catch (err) {
    dispatch({
      type: CAMPAIGN_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};