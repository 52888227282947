import {
  GET_COMPANY,
  COMPANY_ERROR,
  CLEAR_COMPANY,
  GET_TEAM,
  TEAM_ERROR,
  GET_TEAMS,
  TEAMS_ERROR,
  ADD_TEAM,
  ADD_TEAM_ERROR,
  DELETE_TEAM,
  DELETE_TEAM_ERROR,
  EDIT_TEAM,
  EDIT_TEAM_ERROR,
  CLEAR_TEAM,
  UPDATE_PLAN,
  UPDATE_PLAN_ERROR,
  CHECK_CODE,
  CODE_ERROR,
  DELETE_COMPANY,
  DELETE_COMPANY_ERROR,
  CREATE_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  SUBSCRIPTION_ERROR,
} from '../actions/types';

const initialState = {
  company: null,
  team: null,
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_COMPANY:
    case GET_TEAMS:
      return {
        ...state,
        company: payload,
        loading: false
      };
    case GET_TEAM:
      return {
        ...state,
        team: payload,
        loading: false
      };
    case COMPANY_ERROR:
    case TEAM_ERROR:
    case TEAMS_ERROR:
    case ADD_TEAM_ERROR:
    case DELETE_TEAM_ERROR:
    case EDIT_TEAM_ERROR:
    case UPDATE_PLAN_ERROR:
    case DELETE_COMPANY_ERROR:
    case SUBSCRIPTION_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case CLEAR_COMPANY:
      return {
        ...state,
        company: null,
        team: null,
        loading: true
      };
    case CLEAR_TEAM:
      return {
        ...state,
        team: null
      };
    case ADD_TEAM:
    case UPDATE_PLAN:
    case CHECK_CODE:
    case CREATE_SUBSCRIPTION:
    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        company: payload,
        loading: false
      };
    case EDIT_TEAM:
      return {
        ...state,
        company: {
          departments: payload
        },
        loading: false
      };
    case DELETE_TEAM:
      return {
        ...state,
        company: {
          departments: state.company.departments.filter(
            department => department._id !== payload
          )
        },
        loading: false
      };
    case DELETE_COMPANY:
      return {
        company: null,
        team: null,
        loading: true,
        error: {}
      };
    default:
      return state;
  }
}
