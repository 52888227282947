import React, { Fragment, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { invitation } from '../../actions/auth';
import Navbar from '../layout/Navbar';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { FormattedMessage } from 'react-intl';
import { icons } from '../../img/elements/icons';

const Invitation = ({ setAlert, invitation, auth: { isAuthenticated, user }, location, locale: { lang } }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    password: '',
    password2: ''
  });
  const [showPassword, setShowPassword] = useState({ password: false, password2: false })

  const { firstName, lastName, password, password2 } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    if (password !== password2) {
      setAlert(
        <FormattedMessage
          id="auth.invite.alert.pass"
          defaultMessage="Passwords do not match"
        />,
        'danger'
      );
    } else {
      const url = qs.parse(location.search);

      const token = url.token;

      invitation({
        firstName,
        lastName,
        password,
        token,
        lang,
      });
    }
  };

  // Redirect if signed up
  if (isAuthenticated) {
    if (user === null || user && user.numberOfLogins === undefined || user && user.numberOfLogins === 1) {
      return <Redirect to="/welcome/users" />;
    } else {
      return <Redirect to="/dashboard/objectives" />;
    }
  }

  return (
    <Fragment>
      <section id="signup">
        <div className="container pt-5">
          <div className="section_header_new">
            <Navbar guest={true} />
            <h2 className='h2_new'>
              <FormattedMessage
                id="auth.invite.header"
                defaultMessage="Accept invitation"
              />
            </h2>
            <p className="lead lead_new mb-2">
              <FormattedMessage
                id="auth.invite.text"
                defaultMessage="Accept invitation and create your account"
              />
            </p>
          </div>
          <div className="form form_new form-invitation">
            <form onSubmit={e => onSubmit(e)}>
              <div className="field_name field mb-1">
                <FormattedMessage
                  id="auth.invite.nameInput"
                  defaultMessage="First Name"
                >
                  {msg => (
                    <input
                      type="text"
                      className="name name_new"
                      placeholder={msg}
                      name="firstName"
                      value={firstName}
                      onChange={e => onChange(e)}
                      required
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className="field_name field mb-1">
                <FormattedMessage
                  id="auth.invite.lastnameInput"
                  defaultMessage="Last Name"
                >
                  {msg => <input
                    type="text"
                    className="name name_new"
                    placeholder={msg}
                    name="lastName"
                    value={lastName}
                    onChange={e => onChange(e)}
                    required
                  />}
                </FormattedMessage>
              </div>
              <div className="field_password field_password_new field mb-1">
                <FormattedMessage
                  id="auth.invite.passInput"
                  defaultMessage="Password"
                >
                  {msg => (
                    <div className='pass-input_new' >
                      <input
                        type={showPassword.password ? "text" : "password"}
                        className="password password_new"
                        placeholder={msg}
                        name="password"
                        value={password}
                        onChange={e => onChange(e)}
                        minLength="6"
                        required
                        autocomplete="off"
                      />
                      {password && (
                        <button type="button" className='pass-visibility_new' onClick={() => setShowPassword({ ...showPassword, password: !showPassword.password })}>
                          {showPassword.password ? icons.hide_password : icons.view_password}
                        </button>
                      )}

                    </div>
                  )}
                </FormattedMessage>
              </div>
              <div className="field_password password2 field mb-1">
                <FormattedMessage
                  id="auth.invite.pass2Input"
                  defaultMessage="Repeat Password"
                >
                  {msg => (
                    <div className='pass-input_new' >

                      <input
                        type={showPassword.password2 ? "text" : "password"}
                        className="password2 password_new"
                        placeholder={msg}
                        name="password2"
                        value={password2}
                        onChange={e => onChange(e)}
                        minLength="6"
                        required
                        autocomplete="off"
                      />
                      {password2 && (
                        <button type="button" className='pass-visibility_new' onClick={() => setShowPassword({ ...showPassword, password2: !showPassword.password2 })}>
                          {showPassword.password2 ? icons.hide_password : icons.view_password}
                        </button>
                      )}

                    </div>
                  )}
                </FormattedMessage>
              </div>
              <div className="form_button text-center">
                <button type="submit" className="btn btn_new btn-primary my-2">
                  {' '}
                  <FormattedMessage
                    id="auth.invite.btn"
                    defaultMessage="Sign Up"
                  />
                </button>
              </div>
            </form>
          </div>
          <div className='section_footer_new link-page_new mb-1'>
            <p className='link-page_new-message'>
              <FormattedMessage
                id="auth.invite.account"
                defaultMessage="Already have an account??"
              />
            </p>
            <div className='link-page_new-redirect'>
              <Link to="/login" >
                <FormattedMessage id="auth.invite.login" defaultMessage="Login" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

Invitation.propTypes = {
  setAlert: PropTypes.func.isRequired,
  invitation: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired,
  location: PropTypes.object,
  auth: PropTypes.object.isRequired,

};

const mapStateToProps = state => ({
  locale: state.locale,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { setAlert, invitation }
)(Invitation);
