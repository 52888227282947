import React, { 
  useState,
  Fragment,
 } from 'react';
import PropTypes from 'prop-types';
import { setAlert } from '../../../actions/alert';
import { connect } from 'react-redux';
import { addComment } from '../../../actions/okr';
import { FormattedMessage } from 'react-intl';
import { MentionsInput, Mention } from 'react-mentions';
import { icons } from "../../../img/elements/icons";

const CommentForm = ({
  okrID,
  addComment,
  formDataParent,
  setFormDataParent,
  mentions,
}) => {
  const [text, setText] = useState('');

  // ЗДЕСЬ получаем коллбэк и перебираем formData.kudos и обновляем комменты
  const onSubmit = async (okrID, text) => {
    try {
      // console.log('==== onSubmit comment', text)

      const res = await addComment(okrID, text);

      setFormDataParent({ 
        ...formDataParent, 
        objectives: formDataParent.objectives.map(answer => {
          if (answer._id === res.id) {
            // Update all comments 
            answer.comments = res.comments;
            
            return answer;
          } else { return answer; }
        })
      });

    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in comments', err);
    }
  };

  return (
    <Fragment>
      <form
        className="form-app form-app-comments"
      >
        <div className="form-comment flex align-center w-100">
          <MentionsInput 
            value={text} 
            onChange={e => setText(e.target.value)} 
            className={'w-100 p-1 br-4 fs-14'}
            // singleLine={true}
          >
            <Mention
              trigger="@"
              data={mentions}
              markup="@{{__display__:__id__}}"
              className="lh-50"
            />
          </MentionsInput>

          <button
            type="button"
            className="flex align-center bg-blue p-1 br-4 brd-transparent cursor btn-secondary__blue-hover ml-1"
            onClick={e => {
              e.preventDefault();
              onSubmit(okrID, { text });
              setText('');
            }}
          >
            {icons.paper_plane_white}
          </button>
        </div>
      </form>
    </Fragment>
  );
};

CommentForm.propTypes = {
  addComment: PropTypes.func.isRequired
};

export default connect(
  null,
  { addComment }
)(CommentForm);
