import axios from 'axios';
import { setAlert } from './alert';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  GET_DISCORD_USERS,
  GET_DISCORD_CHANNELS,
  DISCORD_USERS_ERROR,
  DISCORD_CHANNELS_ERROR,
  GET_DISCORD_TEAM,
  DISCORD_TEAM_ERROR,
  CLEAR_DISCORD_TEAM,
} from './types';

// Edit standup
export const editDiscordTeam = (formData, history) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(`/api/discord/okr/team`, formData, config);

    dispatch({
      type: GET_DISCORD_TEAM,
      payload: res.data
    });

    dispatch(
      setAlert(
        <FormattedMessage
          id="alert.slack.edit"
          defaultMessage="Settings were updated"
        />,
        'success'
      )
    );

    history.push('/settings/integrations');
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: DISCORD_TEAM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};



// Get Discord channels for standup report
export const getDiscordTeam = () => async dispatch => {
  try {

    const res = await axios.get(`/api/discord/team`);
   
    dispatch({
      type: GET_DISCORD_TEAM,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: DISCORD_TEAM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get Discord users 
export const getDiscordUsers = (companyId, guildId) => async dispatch => {
  try {

    const res = await axios.get(`/api/discord/users/${companyId}/${guildId}`);

    dispatch({
      type: GET_DISCORD_USERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: DISCORD_USERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Discord channels 
export const getDiscordChannels = (companyId, guildId) => async dispatch => {
  try {

    const res = await axios.get(`/api/discord/channels/${companyId}/${guildId}`);
   
    dispatch({
      type: GET_DISCORD_CHANNELS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: DISCORD_CHANNELS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};



// Get Discord users connections
export const getDiscordFocusUsers = (companyId, guildId) => async dispatch => {
  try {

    const res = await axios.get(`/api/discord/users_focus/${companyId}/${guildId}`);

    dispatch({
      type: GET_DISCORD_USERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: DISCORD_USERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


export const clearDiscordTeam = () => async dispatch => {
  try {
   
    dispatch({
      type: CLEAR_DISCORD_TEAM
    });
  } catch (err) {
    dispatch({
      type: DISCORD_TEAM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
