import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import SubTitle from '../elems/SubTitle';
import ButtonWhite200 from '../elems/ButtonWhite200';

const CheckListHelloUser = ({ profiles, okrs, standupAnswer, standups, updateAnswers, planAnswers, icebreakers, oneOnOnes, }) => {
  const setupMsg = (
    <FormattedMessage
      id="home.checklist.setup"
      defaultMessage="Set up Focus"
    />
  );

  let standupLink = '/checkins';
  standupLink = standups.length > 0 ? `/checkins/${standups[0]._id}` : '/checkins';
  
  const data = [
    {
      title: (
        <FormattedMessage
          id="home.user.checklist.1.title"
          defaultMessage="Joined to your company"
        />
      ),
    },
    {
      title: (
        <FormattedMessage
          id="home.user.checklist.2.title"
          defaultMessage="Share your daily check-in"
        />
      ),
      text: (
        <FormattedMessage
          id="home.user.checklist.2.text"
          defaultMessage="Complete your first check-in. Show your team what you are working on."
        />
      ),
      isBtn: true,
      btnColor: 2,
      btnCta: (
        <FormattedMessage
          id="home.user.checklist.2.cta"
          defaultMessage="Complete a Check-in"
        />
      ),
      btnUrl: standupLink
    },
    {
      title: (
        <FormattedMessage
          id="home.user.checklist.3.title"
          defaultMessage="Learn the company's Objectives"
        />
      ),
      title2: (
        <FormattedMessage
          id="home.user.checklist.3.title2"
          defaultMessage="Create company's Objectives"
        />
      ),
      text: (
        <FormattedMessage
          id="home.user.checklist.3.text"
          defaultMessage="Learn the company's objectives to know more what's matter to your company."
        />
      ),
      text2: (
        <FormattedMessage
          id="home.user.checklist.3.text2"
          defaultMessage="Discuss and plan your team's Objectives and Key Results (OKRs)."
        />
      ),
      isBtn: true,
      btnColor: 2,
      btnCta: (
        <FormattedMessage
          id="home.user.checklist.3.cta"
          defaultMessage="See Objectives"
        />
      ),
      btnUrl: "/objectives"
    },
    {
      title: (
        <FormattedMessage
          id="home.user.checklist.4.title"
          defaultMessage="Complete your first Weekly Update"
        />
      ),
      text: (
        <FormattedMessage
          id="home.user.checklist.4.text"
          defaultMessage="Complete your first Weekly Update when it will be started"
        />
      ),
      isBtn: false
    },

    {
      title: (
        <FormattedMessage
          id="home.checklist.6.title"
          defaultMessage="Share a weekly plan"
        />
      ),
      text: (
        <FormattedMessage
          id="home.checklist.6.text"
          defaultMessage="Create your weekly plan to focus on top priorities. Sync the team."
        />
      ),
      isBtn: true,
      btnColor: 2,
      btnCta: (
        <FormattedMessage
          id="home.checklist.6.cta"
          defaultMessage="Share a weekly plan"
        />
      ),
      btnUrl: "/plans/complete"
    },

    {
      title: (
        <FormattedMessage
          id="home.checklist.7.title"
          defaultMessage="Create your 1:1 meeting"
        />
      ),
      text: (
        <FormattedMessage
          id="home.checklist.7.text.user2"
          defaultMessage="Create the 1:1 meeting with your teammates to keep each other in the loop, give feedback, or resolve issues."
        />
      ),
      isBtn: true,
      btnColor: 2,
      btnCta: (
        <FormattedMessage
          id="home.checklist.7.cta"
          defaultMessage="Create 1:1 meeting"
        />
      ),
      btnUrl: "/one_on_one"
    },
  ]

  return (
    <div className="component my-2 p-3 light-shadow cards__onboarding">
      <SubTitle title={setupMsg} />
        <div className="cards pt-2">						
          <div className="card-item card-team">
            <div className="flex space-between align-center p-2 ml-3 flex-column-xs ml-2-xs">
              
              <div className="step-item mr-2 mb-2-xs cards__onboarding_body w-100-xs">
                <p className="medium card-subtitle todo__completed">{data[0].title}</p>
              </div>
            </div>
          </div>

          <div className="card-item card-team">
            <div className="flex space-between align-center p-2 ml-3 flex-column-xs ml-2-xs">
              
              <div className="step-item mr-2 mb-2-xs cards__onboarding_body">
                <Link to={data[1].btnUrl} className="mr-0">
                  <p className={`medium card-subtitle ${standupAnswer === null ? 'bold todo__item' : "todo__completed"}`}>{data[1].title}</p>
                  </Link>
                <p className={`medium`}>{data[1].text}</p>
              </div>

              <div className="mr-2-xs">
                <ButtonWhite200 cta={data[1].btnCta} url={data[1].btnUrl} isComplete={standupAnswer !== null && true} />
              </div>
              
            </div>
          </div>

          <div className="card-item card-team">
            <div className="flex space-between align-center p-2 ml-3 flex-column-xs ml-2-xs">
              
              <div className="step-item mr-2 mb-2-xs cards__onboarding_body">
                <Link to={data[2].btnUrl} className="mr-0">
                  <p className={`medium card-subtitle ${okrs && okrs.length === 0 ? 'bold todo__item' : "todo__completed"}`}>{okrs && okrs.length !== 0 ? data[2].title : data[2].title2}</p>
                  </Link>
                <p className={`medium`}>{okrs.length !== 0 ? data[2].text : data[2].text2}</p>
              </div>

              <div className="mr-2-xs">
                <ButtonWhite200 cta={data[2].btnCta} url={data[2].btnUrl} />
              </div>
            </div>
          </div>

          <div className="card-item card-team">
            <div className="flex space-between align-center p-2 ml-3 flex-column-xs ml-2-xs">
              
              <div className="step-item mr-2 mb-2-xs cards__onboarding_body">
                <Link to={data[3].btnUrl} className="mr-0">
                  <p className={`medium card-subtitle  ${updateAnswers && updateAnswers.length === 0 ? 'bold todo__item' : "todo__completed"}`}>{data[3].title}</p>
                </Link>
                <p className={`medium`}>{data[3].text}</p>
              </div>
            </div>
          </div>

          <div className="card-item card-team">
            <div className="flex space-between align-center p-2 ml-3 flex-column-xs ml-2-xs">
              
              <div className="step-item mr-2 mb-2-xs cards__onboarding_body">
                <Link to={data[4].btnUrl} className="mr-0">
                  <p className={`medium card-subtitle  ${planAnswers && planAnswers.length > 0 && planAnswers[0].answers && planAnswers[0].answers.length === 0 ? 'bold todo__item' : "todo__completed"}`}>{data[4].title}</p>
                </Link>
                <p className={`medium`}>{data[4].text}</p>
              </div>

              <div className="mr-2-xs">
                <ButtonWhite200 cta={data[4].btnCta} url={data[4].btnUrl} isComplete={planAnswers && planAnswers.length > 0 && planAnswers[0].answers && planAnswers[0].answers.length === 0 && true} />
              </div>
            </div>
          </div>

          <div className="card-item card-team">
            <div className="flex space-between align-center p-2 ml-3 flex-column-xs ml-2-xs">
              
              <div className="step-item mr-2 mb-2-xs cards__onboarding_body">
                <Link to={data[5].btnUrl} className="mr-0">
                  <p className={`medium card-subtitle  ${oneOnOnes && oneOnOnes.length === 0 ? 'bold todo__item' : "todo__completed"}`}>{data[5].title}</p>
                </Link>
                <p className={`medium`}>{data[5].text}</p>
              </div>

              <div className="mr-2-xs">
                <ButtonWhite200 cta={data[5].btnCta} url={data[5].btnUrl} isComplete={oneOnOnes && oneOnOnes.length > 0 && true} />
              </div>
            </div>
          </div>

        </div> 
      </div> 
  );
};

CheckListHelloUser.propTypes = {
  profiles: PropTypes.array,
  okrs: PropTypes.array,
  standupAnswer: PropTypes.object,
  updateAnswers: PropTypes.array
};

export default CheckListHelloUser;
