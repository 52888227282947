import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import { setAlert } from '../../../actions/alert';
import TreeOkrItem from "./TreeOkrItem";
import TreeChild from "./TreeChild";
import TreeTitle from "./TreeTitle";
import Moment from "react-moment";
import { FormattedMessage } from "react-intl";

const TreeParent = ({
  parent,
  childs,
  grandchildsArr,
  noParents,
  handleArchive,
  handleDelete,
  archive,
  user
}) => {
  const [discussion, showComments] = useState(false);
  const [update, toggleUpdateMode] = useState(false);

  let btnClass;
  let label = "";
  let isCompanyParent = true;

  if (parent.length > 0) {
    if (parent[0].status === 1) {
      btnClass = "green";
      label = "On Track";
    } else if (parent[0].status === 2) {
      btnClass = "yellow";
      label = "Behind";
    } else if (parent[0].status === 3) {
      btnClass = "red";
      label = "At Risk";
    }
  } else {
    // No company parent
    isCompanyParent = false;
  }

  let companyTitle = <FormattedMessage id="company.okr" defaultMessage="Company OKR" />;

  return (
    <Fragment>
      {parent.length > 0 ? (
        <div className="w-450px bg__white br-12 light-shadow my-2 py-3 px-2">
          {parent.length > 0 && (
            <TreeTitle
              label={companyTitle}
              color={btnClass}
              _id={parent[0]._id}
              handleArchive={handleArchive}
              handleDelete={handleDelete}
              isArchive={parent[0].isArchive}
              archive={archive}
              user={user}
              owner={parent[0].owner}
              creator={parent[0].creator}
            />
          )}
            
            {parent.map(okr => (
                <TreeOkrItem
                  key={`parent-${okr._id}`}
                  objective={okr.objective}
                  status={label}
                  dueIn={okr.dueIn}
                  owner={okr.owner}
                  ownerAvatar={okr.ownerAvatar}
                  ownerFirstName={okr.ownerFirstName}
                  btnClass={btnClass}
                  progress={okr.progress}
                  keyResults={okr.keyResults}
                />
            ))}
          </div>
      ) : (
        <div className="w-450px my-2 py-3 px-2"></div>
      )}

      <div className={`objective__childs ${!isCompanyParent && 'objective__childs__no-parent'} relative pl-5`}>
        {childs.map(okr => (
          <TreeChild
              key={`child-${okr._id}`}
              okr={okr}
              grandchildsArr={grandchildsArr}
              isCompanyParent={isCompanyParent}
              noParents={noParents}
              handleArchive={handleArchive}
              handleDelete={handleDelete}
              archive={archive}
              user={user}
              />
            ))}
      </div>
    </Fragment>
  );
};

TreeParent.propTypes = {
  parent: PropTypes.array.isRequired,
  childs: PropTypes.array.isRequired,
  grandchildsArr: PropTypes.array.isRequired,
  noParents: PropTypes.bool.isRequired,
};

export default TreeParent;