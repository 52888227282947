import React, { Fragment } from "react";
import PropTypes from "prop-types";
import KRnumber from './krTypes/NumberUpdate'
import KRbinary from './krTypes/BinaryUpdate'
import { connect } from 'react-redux';
import KRmilestone from './krTypes/MilestoneUpdate'


const KRmetricsUpdate = ({ 
  start,
  current, 
  goal,
  formType, 
  onChange,
  onChangeOkr,
  indexOkr,
  index,
  krType,
  locale,
  details,
  handleKeyResults,
 }) => {

  return (
    <Fragment>
      {krType.value === 'number' ? (
        <KRnumber
          formType={formType}
          start={start}
          current={current}
          goal={goal}
          onChange={onChange}
          onChangeOkr={onChangeOkr}
          indexOkr={indexOkr}
          index={index}
        />
      ) : (
        krType.value === 'binary' ? (
          <KRbinary
            formType={formType}
            current={current}
            onChange={onChange}
            onChangeOkr={onChangeOkr}
            indexOkr={indexOkr}
            index={index}
            locale={locale}
            details={details}
            handleKeyResults={handleKeyResults}
          />
        ) : krType.value === 'milestone' ? (
          <KRmilestone
            formType={formType}
            current={current}
            onChange={onChange}
            onChangeOkr={onChangeOkr}
            indexOkr={indexOkr}
            index={index}
            locale={locale}
            details={details}
            handleKeyResults={handleKeyResults}
            activeMilestoneIndex={krType.activeMilestoneIndex ? krType.activeMilestoneIndex : 0}
          />
        ) : null
      )}
    </Fragment>
  );
};

KRmetricsUpdate.propTypes = {
  handleKeyResults: PropTypes.func.isRequired,
  krType: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  locale: state.locale,
});

// export default KRmetricsUpdate;
export default connect(mapStateToProps, null)(KRmetricsUpdate);
