import React, { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import momentTz from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { addOkrCall } from '../../actions/okrcall';
import { getUserCompany } from '../../actions/company';
import { getCompanyProfiles } from '../../actions/profile';
import ErrorBoundary from '../_helpers/ErrorBoundary';
import useAnalyticsEventTracker from '../../utils/analytics/useAnalyticsEventTracker';
import { icons } from '../../img/elements/icons';
import Menu from '../layout/Menu';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import OkrCallSettings from './elems/OkrCallSettings';


const CreateOkrCall = ({
  addOkrCall,
  auth: { user },
  history,
  getUserCompany,
  profile: { profiles },
  getCompanyProfiles,
  company: { loading },
}) => {
  const everyone = { value: 'Company', label: 'Everyone', team: 1, name: 'selectedEmailUsers' };
  const leads = { value: 'Lead', label: 'Team Leads', team: 4, name: 'selectedEmailUsers' };

  const [formData, setFormData] = useState({
    title: '',
    scheduleTime: '09:00',
    scheduleDay: { index: '1', month: '1' },
    interval: 'Monthly',
    timezone: momentTz.tz.guess(),
    emailIsActive: true,
    slackIsActive: true,
    isAsync: false,
    selectedEmailUsers: [everyone],
  });

   const {
    scheduleTime,
    scheduleDay,
    interval,
    timezone,
  } = formData;

  let emailUsersOptions = [everyone, leads];

  // Users for options
  if (profiles) {
    profiles.forEach((profile) => {

      let coworker = {
        value: profile.email,
        label: `${profile.firstName} ${profile.lastName}`,
        team: 3,
        name: 'selectedEmailUsers'
      };

      emailUsersOptions.push(coworker);
    });
  }

  useEffect(() => {
    getUserCompany();
    getCompanyProfiles();
  }, [loading, getCompanyProfiles, getUserCompany]);

  const handleUsers = selectedOption => {
    setFormData({
      ...formData,
      selectedEmailUsers: selectedOption || [],
    });
  };

  const handleIntervalSelect = (selectedOption) => {
    setFormData({ ...formData, [selectedOption.name]: selectedOption.value });
  };

  const onChangeTimezone = (timezone, name) => {
    setFormData({ ...formData, [name]: timezone });
  };
  
  const onChangeDate = (selectedOption) => {
    scheduleDay.index = selectedOption.value;
    
    setFormData({ ...formData, scheduleDay: scheduleDay });
  };

  const onChangeMonth = (selectedOption) => {
    scheduleDay.month = selectedOption.value;
    
    setFormData({ ...formData, scheduleDay: scheduleDay });
  };

  const onSubmit = e => {
    e.preventDefault();

    useAnalyticsEventTracker('okrcall', 'okr call created', 'Creating OKR call');
    addOkrCall(formData, history);
  };

  const onChangeSelect = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Add 12 hours if it's pm time
  const formatTime = (time) => {

    const timeArr = time.split(":");
    
    let hours = +timeArr[0] + 12;
    const result = `${hours}:${timeArr[1]}`

    return result;
  };

  const handleTime = value => {
    const format = 'h:mm a'; 

    let timeArr = value.format(format).split(' ');
    let timeFormat = timeArr[1];

    let time = timeFormat === 'am' ? timeArr[0] : formatTime(timeArr[0]);

    setFormData({ ...formData, scheduleTime: time });
  };

  const goBack = () => {
    if (history !== undefined) {
      return history.goBack();
    } else {
      return '/settings';
    }
  };

  const createTitle = <FormattedMessage id="create.okrCall" defaultMessage="Create New OKR Call" />;
  const okrCallDescription1 = <FormattedMessage id="okrCall.description" defaultMessage="Ask your team members to create OKRs for themselves and/or their departments." />;
  const okrCallDescription2 = <FormattedMessage id="okrCall.description" defaultMessage="A request will go out via Slack (if integrated) and Email." />;
  
  let btnTxt = <FormattedMessage id="create.okrCall" defaultMessage="Create OKR Call" />;
  let cnclBtnTxt = <FormattedMessage id="cancel" defaultMessage="Cancel" />;
  
    return (
    <Fragment>
      <div className="css-main bg-primary">
          <div className="wrapper">
            {user && user.department ? (
              <Menu companyName={user && user.companyName} team={user.departmentName} teamId={user.department} settings={user.isAdmin && true} outreach={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={9} />
            ) : (
              <Menu companyName={user && user.companyName} settings={user && user.isAdmin && true} activeIndex={9} />
            )}

            <UserNav />

            <Fragment>
              {loading ? (
                <Spinner />
              ) : (
                  <div className="ml- pl-5 pr-5 main-profile-admin pt-0 pb-5">
                    <div className="standups-admin">
                      <div className="mxa checkin__settings__form mt-56">

                        <div className="content">
                          <div className="section-title">
                            <h5 className='lh-35 fw-600'>
                              {createTitle}
                            </h5>
                            <Link
                              to="/settings"
                              className="lh-15"
                            >
                              {icons.circle_close}
                            </Link>
                          </div>

                          <div className="content__description mt-xs">
                            <p className="fs-12 lh-15 text-left">{okrCallDescription1}</p>
                            <p className="fs-12 lh-15 text-left">{okrCallDescription2}</p>
                          </div>

                          <ErrorBoundary>
                            <div className="content__form edit-updates-weekly my-2">
                              <div className="form-full">
                                <form
                                  className="form-app form-app-update"
                                  onSubmit={e => onSubmit(e)}
                                >
                             
                                 <OkrCallSettings 
                                    formData={formData}
                                    scheduleTime={scheduleTime}
                                    handleTime={handleTime}
                                    timezone={timezone}
                                    onChangeTimezone={onChangeTimezone}
                                    interval={interval}
                                    onChangeSelect={onChangeSelect}
                                    scheduleDay={scheduleDay}
                                    onChangeDate={onChangeDate}
                                    onChangeMonth={onChangeMonth}
                                    profiles={profiles}
                                    handleUsers={handleUsers}
                                    emailUsersOptions={emailUsersOptions}
                                    handleIntervalSelect={handleIntervalSelect}
                                    type={1}
                                  />

                                  <div className="flex align-center mt-2">
                                    <button 
                                      type="submit"
                                      className="mw-150 fs-18 btn btn-small btn-submit btn__secondary_green lh-54 block ml-0-xs"
                                      disabled={false}
                                      onClick={onSubmit}
                                    >
                                      {btnTxt}
                                    </button>
                                    <button
                                      type="reset"
                                      className="mw-150 lh-54 ml-2 btn btn-small fs-18 btn-secondary-back-upd"
                                      onClick={goBack}
                                      disabled={false}
                                    >
                                      {cnclBtnTxt}
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </ErrorBoundary>
                        </div>
                      </div> 
                    </div>
                  </div>
                )}
            </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

CreateOkrCall.propTypes = {
  addOkrCall: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  useAnalyticsEventTracker: PropTypes.func,
  getCompanyProfiles: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  company: state.company,
  profile: state.profile,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { 
    addOkrCall,
    getUserCompany,
    getCompanyProfiles,
    useAnalyticsEventTracker,
  }
)(withRouter(CreateOkrCall));