import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setAlert } from '../../../actions/alert';
import { getCompanyTreeOkrs, archiveOKR, deleteOkr } from '../../../actions/okr';
import TreeBranch from "./TreeBranch";
import UserNav from '../../layout/UserNav';
import { FormattedMessage } from "react-intl";
import Spinner from '../../layout/Spinner';

const OkrTree = ({
  getCompanyTreeOkrs,
  setAlert,
  archiveOKR,
  deleteOkr,
  auth: { user },
  okr: { tree, loading },
}) => {
  const [formData, setFormData] = useState({
    zoom: 0.8,
    okrs: [],
    copyTree: [],
  });

  let { zoom, okrs, copyTree } = formData;

  // Делаем по дефолту false (в OkrTree там стейт)
  let archive = false;

  const zoomTree = (btn) => {
    if (btn === '+' && zoom < 1.2) {
      zoom += 0.1;
    } else if (btn === '-' && zoom > 0.6) {
      zoom -= 0.1;
    }
   
    setFormData({ ...formData, zoom });
  };

  useEffect(() => {
    getCompanyTreeOkrs();

    setFormData({
      ...formData,
      okrs: !tree ? [] : tree,
      copyTree: !tree ? [] : tree,
    });
  }, [loading, archiveOKR, getCompanyTreeOkrs, tree !== undefined && tree && tree.length]);

  // It helps to archive & delete when we do filter in reducer. Tree becomes undefined
  // Помогает избавиться от undefined при архивировании
  // Из-за этого пропадает дерево на несколько секунд. 
  if (tree === undefined) {
    tree = copyTree;
  }

  const handleArchive = async (e, _id, isArchive, isTree) => {
    e.preventDefault();

    const res = await archiveOKR(_id, isArchive, isTree);

    // remove from OKR and add to Archive
     setFormData({ 
        ...formData, 
        okrs: okrs.filter(item => item._id !== res.id),
     });
  };

  // ЗДЕСЬ получаем коллбэк и перебираем formData.objectives и обновляем 
  const handleDeleteOkr = async (_id, isTree) => {
    try {
      const res = await deleteOkr(_id, isTree);

      // Не меняю расположение детей, так как в новом UI они не меняют вид, НО если в будущем будет дерево OKR, то надо будет менять и у детей информацию
      setFormData({ 
        ...formData, 
        okrs: okrs.filter(answer => answer._id !== res.okrID)
      });      
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in delete goal', err);
    }
  };

  return (
    <Fragment>
      <div className={`css-main bg-primary`}>
        <div className={`wrapper`}>

          <UserNav />

          <Fragment>
            {loading || !user ? (
              <Spinner />
            ) : (
              <div className={`main main-dashboard ma-unset pr-5 ml-5`}>
                  <div className="okrs">
                    <Fragment>
                      {okrs !== undefined ? (
                        <div className="objective__tree pt-5">
                          <div className="section-title">
                            <h5><FormattedMessage id="okr.tree" defaultMessage="OKRs Tree" /></h5>
                            <div className="zoom-buttons mra ml-3">
                              <div
                                onClick={() => zoomTree('-')}
                                className="btn-secondary btn-secondary-back zoom__out lh-55 lh-35-xs pl-3-xs fs-16 br-50 mr-1"
                              >
                                <i className="fas fa-search-minus"></i>
                              </div>
                              <div
                                className="btn-secondary btn-secondary-back zoom__in lh-55 lh-35-xs pl-3-xs fs-16 br-50"
                                onClick={() => zoomTree('+')}
                              >
                                <i className="fas fa-search-plus"></i>
                              </div>
                            </div>
                            <Link to="/objectives" className="btn btn-light">
                               <FormattedMessage id="go.back" defaultMessage="Go Back" />
                            </Link>
                          </div>
                          
                          {okrs && okrs.length > 0 && (
                            okrs.map((okr, index) => (
                              <TreeBranch
                                key={`okr-branch-${index}`}
                                okrBranch={okr}
                                handleArchive={handleArchive}
                                handleDelete={handleDeleteOkr}
                                archive={archive}
                                user={user}
                                zoom={zoom}
                              />
                            ))
                          )}
                        </div>
                      ) : (
                          <div className="w-100 h-100 mxa">
                            <Spinner />
                          </div>
                      )}
                    </Fragment>
                </div>
              </div>
            )}
          </Fragment>
          </div>
      </div>
    </Fragment>
  );
};

OkrTree.propTypes = {
  getCompanyTreeOkrs: PropTypes.func.isRequired,
  archiveOKR: PropTypes.func.isRequired,
  deleteOkr: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  okr: state.okr,
});

export default connect(
  mapStateToProps,
  { archiveOKR, deleteOkr,setAlert, getCompanyTreeOkrs }
)(OkrTree);
