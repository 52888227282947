import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const NumberForm = ({ keyResult, index, onChangeKeyResults, krName }) => {

  let start = keyResult.krStart;
  let current = keyResult.krCurrent;
  let goal = keyResult.krGoal;
  let startLabel = 'krStart';
  let currentLabel = 'krCurrent';
  let goalLabel = 'krGoal';

  if (krName === 'start') {
    start = keyResult.start;
    current = keyResult.current;
    goal = keyResult.goal;

    startLabel = 'start';
    currentLabel = 'current';
    goalLabel = 'goal';
  }

  // console.log('===== startLabel', startLabel)

  return (
    <Fragment>
     <div className="okr__kr-start labeled-wrapper w-10 ml-2">
      <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
        <FormattedMessage
          id="user.okr.create.keyResult.start"
          defaultMessage="Start"
        />
      </label>

      <FormattedMessage
        id="dash"
        defaultMessage="-"
      >
        {(msg) => (
          <input
            className="w-100 input-text fs-18"
            type="text"
            placeholder={msg}
            name={startLabel}
            value={start}
            onChange={(e) => onChangeKeyResults(e, index)}
          />
        )}
      </FormattedMessage>
    </div>

    <div className="okr__kr-current labeled-wrapper w-10 ml-1">
      <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
        <FormattedMessage
          id="user.okr.create.keyResult.current"
          defaultMessage="Current"
        />
      </label>
      <FormattedMessage
        id="dash"
        defaultMessage="-"
      >
        {(msg) => (
          <input
            className="w-100 input-text fs-18"
            type="text"
            placeholder={msg}
            // name="krCurrent"
            // value={keyResult.krCurrent}
            name={currentLabel}
            value={current}
            onChange={(e) => onChangeKeyResults(e, index)}
          />
        )}
      </FormattedMessage>
    </div>

    <div className="okr__kr-goal labeled-wrapper w-10 ml-1">
      <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
        <FormattedMessage
          id="user.okr.create.keyResult.goal"
          defaultMessage="Goal"
        />
      </label>
      <FormattedMessage
        id="dash"
        defaultMessage="-"
      >
        {(msg) => (
          <input
            className="w-100 input-text fs-18"
            type="text"
            placeholder={msg}
            // name="krGoal"
            // value={keyResult.krGoal}
            name={goalLabel}
            value={goal}
            onChange={(e) => onChangeKeyResults(e, index)}
          />
        )}
      </FormattedMessage>
    </div>
    </Fragment>
  );
};

NumberForm.propTypes = {
  keyResult: PropTypes.object.isRequired,
  onChangeKeyResults: PropTypes.func.isRequired
};

export default NumberForm;
