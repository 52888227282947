import React from "react";
import PropTypes from 'prop-types';

const OneOnOneIcon = ({ activeIndex }) => {
  return (
    <svg id="calendar" className="w-20px" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path id="Path_26" fill={`${activeIndex === 11 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 26" d="M-373.763,219.625c0-.289,0-.508,0-.728,0-.417-.011-.835.023-1.251a.592.592,0,0,1,.668-.527c.374,0,.641.189.664.53.033.471.019.945.023,1.418,0,.164,0,.326,0,.519h8.615c.01-.159.026-.308.028-.457.006-.472-.011-.946.015-1.418a.621.621,0,0,1,.747-.589.581.581,0,0,1,.605.586c.023.472.009.946.014,1.419,0,.149.018.3.03.493.531,0,1.022-.023,1.51,0a2.73,2.73,0,0,1,2.53,1.567,2.923,2.923,0,0,1,.215,1.1c.031.82.01,1.641.01,2.461q0,4.757,0,9.515a3.792,3.792,0,0,1-.073.871,2.84,2.84,0,0,1-2.872,1.979c-.064,0-.128,0-.191,0H-374.9a3.417,3.417,0,0,1-2.2-.655,2.32,2.32,0,0,1-.935-1.636c-.019-.194-.023-.389-.023-.583q0-5.884,0-11.768a3.669,3.669,0,0,1,.107-.991,2.716,2.716,0,0,1,2.572-1.856C-374.869,219.6-374.36,219.625-373.763,219.625Zm-2.893,5c-.009.165-.023.315-.023.464q0,4.633,0,9.265a2.71,2.71,0,0,0,.046.54,1.2,1.2,0,0,0,1.009.947,2.544,2.544,0,0,0,.565.057q6.992.005,13.981,0c1.081,0,1.6-.46,1.631-1.41,0-.056,0-.111,0-.167v-9.7Zm17.161-1.274a1.474,1.474,0,0,0,.046-.262,10.275,10.275,0,0,0-.028-1.124,1.274,1.274,0,0,0-1.392-1.121c-.111,0-.222,0-.334,0q-6.865,0-13.731,0a4.274,4.274,0,0,0-.571.021,1.224,1.224,0,0,0-1.074.785,5.179,5.179,0,0,0-.094,1.7Z" transform="translate(378.059 -217.119)"/>
      <path id="Path_27" fill={`${activeIndex === 11 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 27" d="M-368.06,228.564h4.626a3.432,3.432,0,0,1,.476.017.62.62,0,0,1,.581.64.619.619,0,0,1-.519.691,2.008,2.008,0,0,1-.471.047q-4.7,0-9.395,0a2.065,2.065,0,0,1-.471-.049.62.62,0,0,1-.517-.692.634.634,0,0,1,.588-.638,3.6,3.6,0,0,1,.476-.015Z" transform="translate(378.059 -218.647)"/>
      <path id="Path_28" fill={`${activeIndex === 11 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 28" d="M-370.174,232.86c.858,0,1.716,0,2.574,0a1.958,1.958,0,0,1,.472.042.607.607,0,0,1,.455.6.607.607,0,0,1-.413.674,1.715,1.715,0,0,1-.514.076q-2.6.008-5.2,0a1.913,1.913,0,0,1-.378-.035.64.64,0,0,1-.573-.741.627.627,0,0,1,.665-.61c.651-.02,1.3-.008,1.954-.01C-370.81,232.859-370.492,232.86-370.174,232.86Z" transform="translate(378.059 -219.22)"/>
    </svg>

  );
};

OneOnOneIcon.propTypes = {
  activeIndex: PropTypes.number
};

export default OneOnOneIcon;
