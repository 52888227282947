import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { setAlert } from '../../../actions/alert';
import { connect } from 'react-redux';
import { addUpdateComment } from '../../../actions/updateAnswer';
import { FormattedMessage } from 'react-intl';

const CommentForm = ({
  updateID,
  answerID,
  addUpdateComment,
  user,
  avatar
}) => {
  const [text, setText] = useState('');

  return (
    <form
      className="form-app form-app-comments"
      onSubmit={e => {
        e.preventDefault();
        addUpdateComment(updateID, answerID, { text });
        setText('');
      }}
    >
      <img
        src={avatar}
        alt="Avatar"
        className="round-img d-block avatar"
        width="45px"
        height="45px"
      />
      <div className="form-comment">
        <FormattedMessage id="add.comment" defaultMessage="Add a comment">
          {msg => (
            <textarea
              type="text"
              rows={3}
              placeholder={msg}
              value={text}
              onChange={e => setText(e.target.value)}
              required
            />
          )}
        </FormattedMessage>
        <button
          type="submit"
          className="btn-secondary btn-secondary-comment hide-sm"
        >
          <FormattedMessage id="btn.submit" defaultMessage="Send" />
        </button>
        <button type="submit" className="btn-mobile-comment hide-large">
          <FormattedMessage id="btn.mobile.send" defaultMessage="Send" />
        </button>
      </div>
    </form>
  );
};

CommentForm.propTypes = {
  addUpdateComment: PropTypes.func.isRequired
};

export default connect(
  null,
  { addUpdateComment }
)(CommentForm);
