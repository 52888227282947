import {
  GET_COMPANY_ONES,
  COMPANY_ONES_ERROR,
  GET_USER_ONES,
  USER_ONES_ERROR,
  GET_USER_ONES_ID,
  GET_ONES_SETTINGS,
  SETTINGS_ONES_ERROR,
  ADD_ONE_ON_ONE,
  ONES_ERROR,
  EDIT_ONES_SETTING,
  UPDATE_ONES,
  UPDATE_ONES_ERROR,
  COMPLETE_ONES,
  COMPLETE_ONES_ERROR,
  DELETE_ITEM_ONES,
  DELETE_ITEM_ONES_ERROR,
  DELETE_ONES,
  CLEAR_ONES,
} from "../actions/types";

const initialState = {
  meetings: [],
  meeting: null,
  settings: null,
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_COMPANY_ONES:
    case GET_USER_ONES:
    case GET_USER_ONES_ID:
      return {
        ...state,
        meetings: payload,
        loading: false
      };
    case GET_ONES_SETTINGS:
      return {
        ...state,
        settings: payload,
        loading: false
      };
    case ADD_ONE_ON_ONE:
      return {
        ...state,
        meetings: [payload, ...state.meetings]
      };
    case UPDATE_ONES:
    case COMPLETE_ONES:
    case DELETE_ITEM_ONES:
      return {
        ...state,
        meetings: state.meetings.map(item =>
          item._id === payload.id ? payload.data : item
        ),
      }
    case EDIT_ONES_SETTING:
      return {
        ...state,
        meetings: state.meetings.map(item =>
          item._id === payload.id ? payload.data : item
        ),
        settings: payload,
      }
    case USER_ONES_ERROR:
    case SETTINGS_ONES_ERROR:
    case ONES_ERROR:
    case UPDATE_ONES_ERROR:
    case COMPLETE_ONES_ERROR:
    case DELETE_ITEM_ONES_ERROR:
    case COMPANY_ONES_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case DELETE_ONES:
      return {
        ...state,
        meetings: state.meetings.filter(item => item._id !== payload),
        loading: false
      };
    case CLEAR_ONES:
      return {
        ...state,
        meetings: [],
        meeting: null,
        settings: null,
        loading: true,
      };
    default:
      return state;
  }
}
