import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const HelloCopy = ({lang, slack, slackUrl, styles}) => {

  let noSlackEn = '';
  let noSlackRu = '';
  let slackEn = '';
  let slackRu = '';
  let content = '';
  let guideLink = 'https://usefocus.co/slack-guide';

  noSlackEn =
    <div className="p-4">
      <p className="medium pb-1">Hey 👋 </p>
      <p className="medium pb-1">Nice to meet you here! </p>
      <p className="medium pb-1">Focus turns your team goals into results. To do that, you can set objectives and automate check-ins to be aligned and sync your team. </p>
      <p className="medium py-1 bold">How does it work?</p>
      <p className="medium ">1. You set big, ambiguous goals (objectives)</p>
      <p className="medium ">2. On Monday, you plan your week with a focus on what matters the most for you</p>
      <p className="medium ">3. Each day, you run daily check-ins to sync the team</p>
      <p className="medium pb-1">4. On Friday, you highlight achievements on a weekly update</p>
      <p className="medium py-1 bold">About Settings</p>
      <p className="medium pb-1">Focus sends your team notifications when it's time for daily check-in or weekly plan according to the schedule in settings. </p>
      <p className="medium pb-1"><span className="bold medium">By default</span>, you have 4 active campaigns at Focus: <span className="bold medium">daily check-ins, weekly plans, weekly updates, and icebreakers</span>.
        If you want to change something (time, questions, participants) or turn off notifications, you can easily manage it in the Settings menu
        (<Link
          to={'/settings/edit_standup'}
          className={"cursor blue medium"}
        >
          daily check-ins
        </Link>,{' '}
        <Link
          to={'/settings/edit_updates'}
          className={"cursor blue medium"}
        >
          weekly updates
        </Link>,{' '}
        <Link
          to={'/settings/edit_plans'}
          className={"cursor blue medium"}
        >
          weekly plans
        </Link>). </p>
      <p className="medium pb-1"><span className="bold medium">Default settings:</span> Focus sends notifications to everyone in your team; you will get an email report with answers according to the time in settings. </p>
      <p className="medium pb-1">Also, you can create your custom campaigns like Sprint Planning, Monthly Recap, or others in{' '}
        <Link
          to={'/settings'}
          className={"cursor blue medium"}
        >
          Settings
        </Link>.</p>
      <p className="medium py-1 bold">Next steps</p>
      <p className="medium">To start using Focus, follow the steps in the checklist below.</p>
      <p className="medium pb-2">If you have any questions or suggestions, don't hesitate to drop us a message.</p>
      <p className="medium">All the best! </p>
      <p className="medium">Dave Schneider</p>
      <p className="medium">Founder at Focus</p>
    </div>
  
  slackEn =
    <div className="p-4">
      <p className="medium pb-1">Hey 👋 </p>
      <p className="medium pb-1">Nice to meet you here! </p>
      <p className="medium pb-1">Focus turns your team goals into results. To do that, you can set objectives and automate check-ins to be aligned and sync your team. The Focus bot <span className="bold medium">was added to your workspace.</span> </p>
      <p className="medium pb-1"><b>[IMPORTANT]</b> By default, the bot sends questions <b>only to you in the workspace</b>. Please read the text below to add participants to your active campaigns.</p>
      <p className="medium py-1 bold">How does it work?</p>
      <p className="medium pb-1">The Focus bot asks questions via DM when it's time for daily check-in or weekly plan according to the schedule in settings.
        It sends you all answers instantly after the teammate's reply. You can set to send all answers to a public channel.
        To do that, specify the channel in settings and add the Focus bot to it.</p>
      <p className="medium py-1 bold">About Settings</p>
      <p className="medium pb-1"><span className="bold medium">By default</span>, you have 4 active campaigns at Focus: <span className="bold medium">daily check-ins, weekly plans, weekly updates, and icebreakers</span>. Also, you can create your custom campaigns like Sprint Planning, Monthly Recap, or others in{' '}
        <Link
          to={'/settings'}
          className={"cursor blue medium"}
        >
          Settings
        </Link>.</p>
      <p className="medium pb-1"><span className="bold medium">Default settings:</span> the bot asks <b>only you</b>.</p>
      <p className="medium pb-1">If you want to change something (edit time, questions, add participants, channels) or turn off a campaign, you can easily manage it in 
        {' '}<Link
          to={'/settings/slack'}
          className={"cursor blue medium"}
        >
          the Bot Settings
        </Link>
        {' '}or in the Campaigns settings
        (<Link
          to={'/settings/edit_standup'}
          className={"cursor blue medium"}
        >
          daily check-ins
        </Link>,{' '}
        <Link
          to={'/settings/edit_updates'}
          className={"cursor blue medium"}
        >
          weekly updates
        </Link>,{' '}
        <Link
          to={'/settings/edit_plans'}
          className={"cursor blue medium"}
        >
          weekly plans
        </Link>).
      </p>
    <p className="medium">If you create Objectives at Focus, the Focus bot will send notifications when someone updates OKRs (Objective and Key Results). It helps to sync the team and focus on the most important things. To use it, please specify a public channel for OKRs notifications in
      {' '}<Link
          to={'/settings/slack/edit_okr'}
          className={"cursor blue medium"}
        >
          the OKR bot settings
        </Link>{' '}
      and add the Focus bot to the channel.</p>
      <p className="medium py-1 bold">Next steps</p>
      <p className="medium pb-1">Edit settings if you want to change something or specify new channels. The bot will start the next daily check-in according to the schedule. </p>
      <p className="medium pb-2">If you have any questions or suggestions, don't hesitate to drop us a message.</p>
      <p className="medium">All the best!</p>
      <p className="medium">Dave Schneider</p>
      <p className="medium">Founder at Focus</p>
      <a href={guideLink} className="cursor blue medium mt-2 block" rel="noopener noreferrer" target="_blank">Focus bot: Guide for Slack</a>
    </div>
  
    // ToDo: сделать русский
    noSlackRu =
    <div className="p-4">
      <p className="medium pb-1">Hey 👋 </p>
      <p className="medium pb-1">Nice to meet you here! </p>
      <p className="medium pb-1">Focus turns your team goals into results. To do that, you can set objectives and automate check-ins to be aligned and sync your team. </p>
      <p className="medium py-1 bold">How does it work?</p>
      <p className="medium ">1. You set big, ambiguous goals (objectives)</p>
      <p className="medium ">2. On Monday, you plan your week with a focus on what matters the most for you</p>
      <p className="medium ">3. Each day, you run daily check-ins to sync the team</p>
      <p className="medium pb-1">4. On Friday, you highlight achievements on a weekly update</p>
      <p className="medium py-1 bold">About Settings</p>
      <p className="medium pb-1">Focus sends your team notifications when it's time for daily check-in or weekly plan according to the schedule in settings. </p>
      <p className="medium pb-1"><span className="bold medium">By default</span>, you have 4 active campaigns at Focus: <span className="bold medium">daily check-ins, weekly plans, weekly updates, and icebreakers</span>.
        If you want to change something (time, questions, participants) or turn off notifications, you can easily manage it in the Settings menu
        (<Link
          to={'/settings/edit_standup'}
          className={"cursor blue medium"}
        >
          daily check-ins
        </Link>,{' '}
        <Link
          to={'/settings/edit_updates'}
          className={"cursor blue medium"}
        >
          weekly updates
        </Link>,{' '}
        <Link
          to={'/settings/edit_plans'}
          className={"cursor blue medium"}
        >
          weekly plans
        </Link>). </p>
      <p className="medium pb-1"><span className="bold medium">Default settings:</span> Focus sends notifications to everyone in your team; you will get an email report with answers according to the time in settings. </p>
      <p className="medium pb-1">Also, you can create your custom campaigns like Sprint Planning, Monthly Recap, or others in{' '}
        <Link
          to={'/settings'}
          className={"cursor blue medium"}
        >
          Settings
        </Link>.</p>
      <p className="medium py-1 bold">Next steps</p>
      <p className="medium">To start using Focus, follow the steps in the checklist below.</p>
      <p className="medium pb-2">If you have any questions or suggestions, don't hesitate to drop us a message.</p>
      <p className="medium">All the best! </p>
      <p className="medium">Dave Schneider</p>
      <p className="medium">Founder at Focus</p>
    </div>

  
  slackRu = (
    <div className="p-4">
      <p className="medium pb-1">Hey 👋 </p>
      <p className="medium pb-1">Nice to meet you here! </p>
      <p className="medium pb-1">Focus turns your team goals into results. To do that, you can set objectives and automate check-ins to be aligned and sync your team. The Focus bot <span className="bold medium">was added to your workspace.</span> </p>
      <p className="medium pb-1"><b>[IMPORTANT]</b> By default, the bot sends questions <b>only to you in the workspace</b>. Please read the text below to add participants to your active campaigns.</p>
      <p className="medium py-1 bold">How does it work?</p>
      <p className="medium pb-1">The Focus bot asks questions via DM when it's time for daily check-in or weekly plan according to the schedule in settings.
        It sends you all answers instantly after the teammate's reply. You can set to send all answers to a public channel.
        To do that, specify the channel in settings and add the Focus bot to it.</p>
      <p className="medium py-1 bold">About Settings</p>
      <p className="medium pb-1"><span className="bold medium">By default</span>, you have 4 active campaigns at Focus: <span className="bold medium">daily check-ins, weekly plans, weekly updates, and icebreakers</span>. Also, you can create your custom campaigns like Sprint Planning, Monthly Recap, or others in{' '}
        <Link
          to={'/settings'}
          className={"cursor blue medium"}
        >
          Settings
        </Link>.</p>
      <p className="medium pb-1"><span className="bold medium">Default settings:</span> the bot asks <b>only you</b>.</p>
      <p className="medium pb-1">If you want to change something (edit time, questions, add participants, channels) or turn off a campaign, you can easily manage it in 
        {' '}<Link
          to={'/settings/slack'}
          className={"cursor blue medium"}
        >
          the Bot Settings
        </Link>
        {' '}or in the Campaigns settings
        (<Link
          to={'/settings/edit_standup'}
          className={"cursor blue medium"}
        >
          daily check-ins
        </Link>,{' '}
        <Link
          to={'/settings/edit_updates'}
          className={"cursor blue medium"}
        >
          weekly updates
        </Link>,{' '}
        <Link
          to={'/settings/edit_plans'}
          className={"cursor blue medium"}
        >
          weekly plans
        </Link>).
      </p>
    <p className="medium">If you create Objectives at Focus, the Focus bot will send notifications when someone updates OKRs (Objective and Key Results). It helps to sync the team and focus on the most important things. To use it, please specify a public channel for OKRs notifications in
      {' '}<Link
          to={'/settings/slack/edit_okr'}
          className={"cursor blue medium"}
        >
          the OKR bot settings
        </Link>{' '}
      and add the Focus bot to the channel.</p>
      <p className="medium py-1 bold">Next steps</p>
      <p className="medium pb-1">Edit settings if you want to change something or specify new channels. The bot will start the next daily check-in according to the schedule. </p>
      <p className="medium pb-2">If you have any questions or suggestions, don't hesitate to drop us a message.</p>
      <p className="medium">All the best!</p>
      <p className="medium">Dave Schneider</p>
      <p className="medium">Founder at Focus</p>
      <a href={guideLink} className="cursor blue medium mt-2 block" rel="noopener noreferrer" target="_blank">Focus bot: Guide for Slack</a>
    </div>
  );
      
    if (lang === 'en' && !slack) { content = noSlackEn };
    if (lang === 'en' && slack) { content = slackEn };
    if (lang === 'ru' && !slack) { content = noSlackRu };
    if (lang === 'ru' && slack) { content = slackRu };

  return (
    <div className={`component light-shadow`}>
      {content}
    </div>
  );
};

export default HelloCopy;
