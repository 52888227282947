import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import TreeChild from './TreeChild';
import TreeTitle from '../okrTree/TreeTitle_new';
import TreeOkrItem from '../okrTree/TreeOkrItem_new';
import { FormattedMessage } from 'react-intl';
import OkrMoreIcons from "../elements/OkrMoreIcons";

const TreeBranch = ({
  okr,
  isParent,
  handleArchive,
  handleDelete,
  archive,
  user,
  zoom,
  allOkrs,
  findChilds
}) => {
  // Находим детей
  let childs = [];
  // И исключаем родителя их списка ОКР сразу, чтобы не было бага, когда ребенок может стать отцом отца или деда

  {
    okr &&
      okr.isParent &&
      okr.childOkrs.length > 0 &&
      allOkrs &&
      allOkrs.length > 0 &&
      okr.childOkrs.forEach((item) => {
        let child = allOkrs.filter(
          (o) => o._id.toString() === item.value && okr._id.toString() !== item.value
        );

        if (child.length > 0) {
          childs.push(child[0]);
        }
      });
  }

  // ToDo: сделать сбор детей в 1ой функции (вместо способа выше): здесь и в TreeChild (сейчас возвращает промис, а await нельзя поставить - нет async)

  let btnClass;
  let label = '';

  if (okr.status === 1) {
    btnClass = 'green';
    label = 'On Track';
  } else if (okr.status === 2) {
    btnClass = 'yellow';
    label = 'Behind';
  } else if (okr.status === 3) {
    btnClass = 'red';
    label = 'At Risk';
  }

  let title =
    okr.type === 1 ? (
      <FormattedMessage id="company.okr" defaultMessage="Company OKR" />
    ) : (
      `${okr.teamName} OKR`
    );
  let isOkrOwner = false;
  let isKrOwner = false;
  let creator = okr.creator;
  let owner = okr.owner;
  let keyResults = okr.keyResults;
  let pr = okr.privacy;

  if (creator === user._id || owner === user._id) {
    isOkrOwner = true;
  }

  let kr = keyResults && keyResults.length > 0 ? keyResults.map((k) => k.owner) : [];
  let krRes = kr.indexOf(user._id.toString());

  if (krRes > -1) {
    isKrOwner = true;
  }

  return (
    <Fragment>
      <div className=" mt-1" style={{ zoom: zoom, zoom: `${zoom * 100}%` }}>
        <div className="objective__branch">
          <div className="objective__parent flex align-center justify-start">
            {user.isAdmin ||
            pr === 1 ||
            pr === undefined ||
            isOkrOwner ||
            isKrOwner ||
            (pr === 2 && user && user.department && user.department.toString() === okr.team) ||
            (pr === 3 && owner === user._id) ||
            (pr === 3 && creator === user._id) ? (
              <div className='relative'>
                <div className="w-450px bg__white br-12 objective_tree wrap" >
                  <TreeTitle
                    _id={okr._id}
                    objective={okr.objective}
                    status={okr.status}
                    btnClass={btnClass}
                    progress={okr.progress}
                    keyResults={okr.keyResults}
                    teamName={okr.teamName}
                    handleArchive={handleArchive}
                    handleDelete={handleDelete}
                    isArchive={okr.isArchive}
                    archive={archive}
                    user={user}
                    owner={okr.owner}
                    creator={okr.creator}
                  />
                  <hr className="divider" />
                  <TreeOkrItem
                    updatedAt={okr.updatedAt}
                    dueIn={okr.dueIn}
                    owner={okr.owner}
                    ownerAvatar={okr.ownerAvatar}
                    ownerFirstName={okr.ownerFirstName}
                    ownerLastName={okr.ownerLastName}
                    progress={okr.progress}
                    keyResults={okr.keyResults}
                  />
                </div>
              </div>
            ) : (
              ''
            )}

            {isParent && (
              <div className={`objective__childs relative pl-5`}>
                {childs.map((okr) => (
                  <TreeChild
                    key={`child-${okr._id}`}
                    okr={okr}
                    allOkrs={allOkrs}
                    findChilds={findChilds}
                    okrId={okr._id}
                    handleArchive={handleArchive}
                    handleDelete={handleDelete}
                    archive={archive}
                    user={user}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

TreeBranch.propTypes = {
  okr: PropTypes.object.isRequired
};

export default TreeBranch;
