import React from 'react';
import { icons } from '../../img/elements/icons';

const CustomFormQuestionMultiSelectBody = ({ value, data, handleChange, errorIndex, index }) => {
  const onClick = (lbl) => (e) => {
    const index = value.findIndex((el) => el === lbl);
    if (index === -1) {
      handleChange([...value, lbl]);
    } else {
      handleChange(value.filter((el) => el !== lbl));
    }
  };
  return (
    <div className="form-main ">
      {data.selectOptions.map((item, ind) => (
        <div className={`flex mt-1 cursor ${errorIndex === index ? 'fill-red__multiselect' : ''}`} onClick={onClick(item.label)} key={ind}>
          {value.find((el) => el === item.label) ? (
            <span className="task__completed">{icons.checkbox_full}</span>
          ) : (
            icons.checkbox_empty
          )}{' '}
          <span className="ml-1 fs-14">{item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default CustomFormQuestionMultiSelectBody;
