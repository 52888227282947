import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { setAlert } from '../../actions/alert';
import { FormattedMessage } from 'react-intl';
import Highlighter from "react-highlight-words";
import UniversalWhiteBtn from '../elems/UniversalWhiteBtn';
import UniversalBlueBtn from '../elems/UniversalBlueBtn';
import { icons } from "../../img/elements/icons";

const UniversalCommentItem = ({
  mainItemId,
  item: { firstName, lastName, text, user, date, avatar, updatedAt, _id },
  authUser,
  handleDelete,
  onSubmitEdit,
  value,
  isEdit,
  searchKeyword,
  isHideSearch,
}) => {

  let time = (updatedAt !== undefined) ? updatedAt : date;

  const [displayEdit, toggleEditForm] = useState(false);
  
  const [data, setText] = useState('');

  useEffect(() => {
    if (value) setText(value);
  }, [value && value.length]);

  // If enter press in the textarea
  const onEnterPress = (e, mainItemId, _id, data) => {
    try {

      if (e.keyCode == 13 && e.shiftKey == false) {
        e.preventDefault();
        onSubmitEdit(mainItemId, _id, data);
        toggleEditForm(!displayEdit);
      }
      
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error', err);
    }
  };

  return (
    <div className="comment-body">
      <div className="comment-item">
        <Link to={user ? `/user/${user}` : '#!'} className="user">
          <img
            src={avatar}
            alt="Avatar"
            className="round-img d-block avatar mr-12"
            width="26px"
            height="26px"
          />
        </Link>

        {!displayEdit ?
          (<div className="comment-head">
            <div className="comment-info align-center">
            <p className="comment-user fs-12 bold mb-0">
              <strong>
                {firstName} {lastName}
              </strong>
            </p>

              <div className="icons btn-icons-edit flex align-center">
                <time className="fs-10 grey_new mr-12">
                  <Moment format="ddd LLL">{time}</Moment>
                </time>
                {user === authUser._id && (
                  <Fragment>
                    {isEdit && (
                      <button
                        type="button"
                        className="btn-icons btn-edit"
                        onClick={() => toggleEditForm(!displayEdit)}
                      >
                        <i className="fas fa-pencil-alt" />
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn-icons btn-delete flex align-center"
                      onClick={e => handleDelete(mainItemId, _id)}
                    >
                       {icons.circle_close}
                    </button>
                  </Fragment>
                )}
              </div>
            </div>

            <p className="comment-text medium">
              {!isHideSearch ? (
                <Highlighter
                highlightClassName="bg-yellow_pure"
                searchWords={[searchKeyword]}
                autoEscape={true}
                textToHighlight={text}
                />
              ) : (
                  text
                )
              }
            </p>
          </div>
          ) : (
            <form
              className="form__okr-update w-100"
              onSubmit={e => {
                e.preventDefault();
                onSubmitEdit(mainItemId, _id, data);
                toggleEditForm(!displayEdit);
              }}
            >
              <div className="form-app my-2">
                 <FormattedMessage
                    id="answer.add"
                    defaultMessage="Add answer"
                  >
                    {msg => (
                      <textarea
                        className="w-100"
                        rows="3"
                        type="text"
                        placeholder={msg}
                        name={'data'}
                        value={data}
                        onChange={e => setText(e.target.value)}
                        onKeyDown={(e) => onEnterPress(e, mainItemId, _id, data)}
                      />
                    )}
                </FormattedMessage>

                <div className="block mla w-content pb-0">
                  <UniversalWhiteBtn
                    toggleUpdateMode={toggleEditForm}
                    update={displayEdit}
                  />

                  <UniversalBlueBtn
                    onSubmit={onSubmitEdit}
                    parentId={mainItemId}
                    childId={_id}
                    data={data}
                  />
                </div>
              </div>
            </form>
            )
        }
      </div>
    </div>
  );
};

UniversalCommentItem.propTypes = {
  authUser: PropTypes.object.isRequired,
  mainItemId: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func
};

export default UniversalCommentItem;

