import React from "react";
import PropTypes from 'prop-types';

const InviteIcon = ({ activeIndex, shortMenu }) => {
  return (
    <svg className="w-20px" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <g id="plus" className="invite__icon" transform="translate(-399 -828)">
        <rect id="Rectangle_112" data-name="Rectangle 112" width="12" height="2" rx="1" transform="translate(399 833)"/>
        <rect id="Rectangle_113" data-name="Rectangle 113" width="12" height="2" rx="1" transform="translate(406 828) rotate(90)"/>
      </g>
    </svg>
  );
};

InviteIcon.propTypes = {
  activeIndex: PropTypes.number
};

export default InviteIcon;