import React from 'react';
import { icons } from "../../img/elements/icons";

const IcebreakerForm = ({  
  avatar, 
  avatarWidth,
  text,
  setText,
  onSubmit,
}) => {
  
  return (
    <form
      className="form-ap form-app-comments"
      onSubmit={e => {
        e.preventDefault();
        onSubmit(text);
        // setText('');
      }}
    >
      <img
        src={avatar}
        alt="Avatar"
        className="round-img d-block avatar mr-1"
        width={avatarWidth}
        height={avatarWidth}
      />
      <div className="form-comment flex align-center">
        <input
          type="text"
          className='brd_new__grey p-1 form_new__hover_primary_1px br-4'
          placeholder={''}
          value={text}
          onChange={e => setText(e.target.value)}
          required
        />

        <button
          type="submit"
          className="flex align-center bg-blue p-1 br-4 brd-transparent cursor btn-secondary__blue-hover"
        >
          {icons.paper_plane_white}
        </button>
      </div>
    </form>
  );
};

export default IcebreakerForm;
