import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import QuestionItemAnswer from "../UniQuestionItemAnswer";
import QuestionItemAnswerOkr from "./QuestionItemAnswerOkr";
import { icons } from '../../../img/elements/icons';
import TodoIcon from '../../elems/icons/TodoIcon';
import Moment from 'react-moment';

const getStatus = status => {
  let label = '';
  if (status === 1 || status === '1') {
    label =  <p className="small inline-block pr-1 green">On Track</p>
  } else if (status === 2 || status === '2') {
    label =  <p className="small inline-block pr-1 yellow">Behind</p>
  } else if (status === 3 || status === '3') {
    label =  <p className="small inline-block pr-1 red">At risk</p>
  }

  return label;
};

const getLabel = status => {
  let label = '';
  if (status === 1 || status === '1') {
    label =  <p className="text-left fs-8 uppercase white br-2 brd bg-green px-xs lh-16">On Track</p>
  } else if (status === 2 || status === '2') {
    label =  <p className="text-left fs-8 uppercase white br-2 brd bg-yellow px-xs lh-16">Behind</p>
  } else if (status === 3 || status === '3') {
    label =  <p className="text-left fs-8 uppercase white br-2 brd bg-red px-xs lh-16">At risk</p>
  }

  return label;
};

const getWeeklyProgress = (status, metric, lastMetric) => {
  let color = '';
  let content = '';

  if (lastMetric === undefined || !lastMetric.current) {
    return '';
  }
 
  let date = <Moment format="D MMM">{metric.date}</Moment>;
  
  if (status === 1 || status === '1') { color = 'green'; } 
  else if (status === 2 || status === '2') { color = 'yellow'; } 
  else if (status === 3 || status === '3') { color = 'red'; }

  let diff = !metric.isKr ? (lastMetric.progress - metric.progress) : (lastMetric.current - metric.current);
  diff = diff > 0 ? `+${diff}` : `${diff}`;

  content = (
    <div className="flex brd-transparent brd_left_grey pl-1 ml-2">
      <p className={`small bold grey mr-1`}>Weekly progress: <span className={`small bold ${color}`}>{diff}{metric.focusIndex === 99 ? '%': ''}</span></p>
      <p className={`small grey`}>Value {date}: {metric.focusIndex === 99 ? `${Math.round(metric.progress)}%` : Math.round(metric.current)}</p>
    </div>
   )

  return content;
};

const QuestionItemOkr = ({ 
  question, 
  answer, 
  index, 
  searchKeyword, 
  weeklyOkrs,
  lastMetrics,
}) => {

  return (
    <Fragment>
      <p className={`fs-16 bold mb-1`}>{question.text}</p>
      <div className="card-answer">
        <ul className="answer-item">
          {answer.map((answer, i) => (
            <Fragment key={i}>
              {index === 0 && (
                <Fragment>
                  <p className={`fw-600 ${i !== 0 && 'mt-2'}`}>{weeklyOkrs[i].okr !== 0 ? (weeklyOkrs[i].focusIndex === 99 ? 'Objective: ' : 'Key Result: ') : ""}{' '}{weeklyOkrs[i].label}</p>
                  {lastMetrics && lastMetrics.length > 0 && lastMetrics[i] && lastMetrics[i].okr && weeklyOkrs[i].okr !== 0 && (
                    <Fragment>
                      <div className="flex align-center">
                        <div className="flex align-center">
                          <p className="text-left fs-16 bold mb-0">{!lastMetrics[i].isKr ? `${Math.round(lastMetrics[i].progress)}%`: Math.round(lastMetrics[i].current)}</p>
                          <span className="fs-16 mx-xs">/</span>
                          <p className="text-left fs-12 mr-1">{lastMetrics[i].goal}{!lastMetrics[i].isKr ? '%': ''}</p>
                          {getLabel(lastMetrics[i].status)}
                        </div>
                        <div className="weekly-traction inline-block">
                          {lastMetrics && lastMetrics.length > 0 && getWeeklyProgress(lastMetrics[i].status, weeklyOkrs[i], lastMetrics[i])}
                        </div>
                      </div>
                    </Fragment>
                  )}
                </Fragment>
              )}
             
              <Fragment>
                {answer.map((item, i) => (
                  <Fragment key={`item-okr-${i}`}>
                    {item.isCompleted ? (
                      <Fragment>
                        <div className="flex align-center my-1">
                          <TodoIcon
                            width={'12'}
                            viewBox={'0 0 30 30'}
                            styles={'icons mr-9px'}
                          />
                          <QuestionItemAnswerOkr
                            key={`${item._id}-${i}`}
                            text={item.text}
                            searchKeyword={searchKeyword}
                          />
                        </div>
                      </Fragment>
                    ) : (
                      <div className="flex align-center">
                        <QuestionItemAnswer
                          key={`${item._id}-${i}`}
                          index={index}
                          text={item.text}
                          searchKeyword={searchKeyword}
                        />
                        <span className="fs-10 grey ml-1">Not completed</span>
                        <div className="brd-transparent brd_left_grey pl-1 ml-2">
                          <p className="small">
                            Comment: {item.comment}
                          </p>
                        </div>
                      </div>
                    )}
                  </Fragment>
                ))}
              </Fragment>
            </Fragment>
          ))}
        </ul>
      </div>
    </Fragment>
  );
};

QuestionItemOkr.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.object.isRequired,
  avatar: PropTypes.string.isRequired
};

export default QuestionItemOkr;
