import React from "react";
import PropTypes from 'prop-types';

const TodoIcon = ({ styles, width, viewBox }) => {
  return (
    <svg width={width} height={width} className={styles} viewBox={viewBox} version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="todo-done" transform="translate(1.000000, 1.000000)">
            <circle id="Oval" stroke="#625D6B" strokeWidth="1.10000002" fill="#625D6B" fillRule="nonzero" cx="14" cy="14" r="14"></circle>
            <polyline id="Shape" stroke="#FFFFFF" strokeWidth="1.00058092" strokeLinecap="square" points="8.959999 15.08 13.105454 18.92 20.159999 9.98"></polyline>
        </g>
    </g>
</svg>
  );
};

TodoIcon.propTypes = {
  activeIndex: PropTypes.number
};

export default TodoIcon;
