import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { setAlert } from '../../../actions/alert';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  editUpdateComment,
  deleteUpdateComment
} from '../../../actions/updateAnswer';
import { icons } from "../../../img/elements/icons";

const CommentItem = ({
  comment: { firstName, lastName, text, user, date, avatar, _id },
  auth,
  deleteUpdateComment,
  editUpdateComment,
  updateID,
  answerID,
  formData,
  setFormData
}) => {

  // ЗДЕСЬ получаем коллбэк и перебираем formData.checkins и обновляем комменты
  const handleDelete = async (updateID, answerID, _id) => {
    try {
      const res = await deleteUpdateComment(updateID, answerID, _id);

      setFormData({ 
        ...formData, 
        updates: formData.updates.map(answer => {
          if (answer._id === res.updateID) {
            // Delete comment 
            answer.comments = answer.comments.filter(comment => comment._id !== res.commentID);
            answer.answers = res.answers;
           
            return answer;
          } else {
            return answer;
          }
        })
      });
      
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in comments', err);
    }
  };

  return (
    <div className="comment-body">
      <div className="comment-item">
        <Link to={user ? `/user/${user}` : '#!'} className="user">
          <img
            src={avatar}
            alt="Avatar"
            className="round-img d-block avatar mr-12"
            width="26px"
            height="26px"
          />
        </Link>

        <div className="comment-head">
          <div className="comment-info align-center">
            <p className="ccomment-user fs-12 bold mb-0">
              <strong>
                {firstName} {lastName}
              </strong>
            </p>

            <div className="icons btn-icons-edit flex align-center">
              <time className="fs-10 grey_new mr-12">
                <Moment format="ddd LLL">{date}</Moment>
              </time>
              {!auth.loading && user === auth.user._id && (
                <button
                  type="button"
                  className="btn-icons btn-delete flex align-center"
                  onClick={e => handleDelete(updateID, answerID, _id)}
                >
                  {icons.circle_close}
                </button>
              )}
            </div>
          </div>

          <p className="comment-text">{text}</p>
        </div>
      </div>
    </div>
  );
};

CommentItem.propTypes = {
  auth: PropTypes.object.isRequired,
  answerID: PropTypes.string.isRequired,
  comment: PropTypes.object.isRequired,
  editUpdateComment: PropTypes.func.isRequired,
  deleteUpdateComment: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { editUpdateComment, deleteUpdateComment }
)(CommentItem);
