import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleSettings } from '../../actions/standup';
import { toggleUpdateSettings, changeCampaignType } from '../../actions/update';
import { FormattedMessage } from 'react-intl';
import CampaignItem from './CampaignItem';
import UpdatesCampaignType from './elems/UpdatesCampaignType';  
import slackBtn from '../../img/buttons/slack.png';
import discordBtn from '../../img/buttons/discord@2x.png';
import useAnalyticsEventTracker from '../../utils/analytics/useAnalyticsEventTracker';

const slackUrl = "https://slack.com/oauth/v2/authorize?client_id=783663848244.2478640026919&scope=channels:join,channels:history,channels:read,chat:write,groups:history,im:history,im:read,links:read,mpim:history,team:read,users:read,users:read.email&user_scope=";

const AdminDashboardCardItem = ({
  settings,
  toggleSettings,
  updateSettings,
  icebreakerSettings,
  toggleUpdateSettings,
  toggleIcebreakerSettings,
  slack,
  slackId,
  isSlack,
  disabled,
  companyPlan,
  planSettings,
  togglePlanSettings,
  toggleCustomSettings,
  customCampaigns,
  changeCampaignType,
  discordObj,
  discord,
}) => {
  const [formData, setFormData] = useState({
    status: settings && settings.active,
    updateStatus: updateSettings && updateSettings.active,
    icebreakerStatus: icebreakerSettings && icebreakerSettings.active,
    planStatus: planSettings && planSettings.active,
    customStatus: [],
  });

  const [checkins, setCheckins] = useState([]);
  
  const [updateData, setUpdateData] = useState({
    campaignType: [],
    isForm: false,
  });


  const { status, updateStatus, icebreakerStatus, planStatus, customStatus } = formData;
  const { campaignType, isForm } = updateData;


  useEffect(() => {
    setFormData({
      ...formData,
      status: !settings ? false : settings.active,
      updateStatus: !updateSettings ? false : updateSettings.active,
      icebreakerStatus: !icebreakerSettings ? false : icebreakerSettings.active,
      planStatus: !planSettings ? false : planSettings.active,
      customStatus: !customCampaigns ? [] : customCampaigns.map(item => item.active),
    });

    console.log('===== useEffect WORKS X')

    setUpdateData({
      ...updateData,
      campaignType: updateSettings && updateSettings.campaignType ? updateSettings.campaignType : 1,
    });

    // Merge all checkins
    if (settings && customCampaigns && planSettings && updateSettings) {
      let arr = customCampaigns.concat(settings);
      arr = arr.concat(planSettings);
      arr = arr.concat(updateSettings);

      setCheckins(arr);
    }

  }, [settings, updateSettings, icebreakerSettings, planSettings, customCampaigns]);

  const handleCampaignType = e => {

    changeCampaignType(e.target.value);
    
    setUpdateData({ 
      ...updateData, 
      [e.target.name]: e.target.value, 
      isForm: !isForm,
    });
  };

  const handleFormClick = () => {
    setUpdateData({ ...updateData, isForm: !isForm });
  };

  const updateCampaignTitle = <FormattedMessage id="admin.dashboard.updates.subtitle" defaultMessage="Weekly Updates" />
  const icebreakerCampaignTitle = <FormattedMessage id="icebreakers" defaultMessage="Icebreakers" />
  const icebreakerName = <FormattedMessage id="icebreakers" defaultMessage="Icebreakers" />
  const planCampaignTitle = <FormattedMessage id="weekly.plans" defaultMessage="Weekly Plans" />
  const planName = <FormattedMessage id="weekly.plans" defaultMessage="Weekly Plans" />


  // console.log('===== checkins', checkins.length)
  // console.log('.=== slack', process.env.REACT_APP_SLACK_URL)

  const gaEventTracker = () => {
    useAnalyticsEventTracker('slack', 'slack installed', 'Slack installation')
  };
  
  return (
    <Fragment>
      {settings && (
        <div className="card-item card-team">
          <div className="card-title card-title-green p-2">
            <p className="medium card-subtitle campaign-name">
              <FormattedMessage
                id="admin.dashboard.subtitle"
                defaultMessage="Daily Standups"
              />
            </p>

            {disabled && (
              <p className="small grey">
                <FormattedMessage
                  id="admin.dashboard.disabled.standups"
                  defaultMessage="Standups don't work for Free plan"
                />
                </p>
              )}

            <div className="card-status">
              <div className="checkbox checkbox-status">
                <div className="checkbox-item active-status">
                  <input
                    type="checkbox"
                    id="status"
                    name="status"
                    checked={status}
                    value={status}
                    onChange={e => {
                      setFormData({ ...formData, status: !status });
                      toggleSettings(settings._id);
                    }}
                    disabled={disabled}
                  />
                  <label htmlFor="status">
                    
                    {settings.active ? (
                      <FormattedMessage
                        id="admin.dashboard.active"
                        defaultMessage="Active"
                      />
                    ) : (
                      <FormattedMessage
                        id="admin.dashboard.disable"
                        defaultMessage="Disabled"
                      />
                    )}
                  </label>
                </div>
              </div>
            </div>

            <Link
              to="/settings/edit_standup"
              className="btn-secondary btn-secondary-comment btn-secondary-status"
            >
              <FormattedMessage
                id="admin.dashboard.editBtn"
                defaultMessage="Edit"
              />
            </Link>
          </div>
        </div>
      )}

      {updateSettings && (
        <div className="card-item card-team">
          <div className="card-title card-title-blue2 p-2">
            <p className="medium card-subtitle campaign-name">
              <FormattedMessage
                id="admin.dashboard.updates.subtitle"
                defaultMessage="Weekly Updates"
              />
            </p>

            <UpdatesCampaignType
              campaignType={campaignType}
              handleCampaignType={handleCampaignType}
              isForm={isForm}
              handleFormClick={handleFormClick}
            />

            {disabled && (
              <p className="small grey">
                <FormattedMessage
                  id="admin.dashboard.disabled.updates"
                  defaultMessage="Updates don't work for Free plan"
                />
                </p>
              )}

            <div className="card-status">
              <div className="checkbox checkbox-status">
                <div className="checkbox-item active-status">
                  <input
                    type="checkbox"
                    id="updateStatus"
                    name="updateStatus"
                    checked={updateStatus}
                    value={updateStatus}
                    onChange={e => {
                      setFormData({ ...formData, updateStatus: !updateStatus });
                      toggleUpdateSettings(updateSettings._id);
                    }}
                    disabled={disabled}
                  />
                  <label htmlFor="updateStatus">
                    {updateSettings.active ? (
                      <FormattedMessage
                        id="admin.dashboard.updates.active"
                        defaultMessage="Active"
                      />
                    ) : (
                      <FormattedMessage
                        id="admin.dashboard.updates.disable"
                        defaultMessage="Disabled"
                      />
                    )}
                  </label>
                </div>
              </div>
            </div>

            <Link
              to="/settings/edit_updates"
              className="btn-secondary btn-secondary-comment btn-secondary-status"
            >
              <FormattedMessage
                id="admin.dashboard.updates.editBtn"
                defaultMessage="Edit"
              />
            </Link>
          </div>
        </div>
      )}

      {planSettings && (
        <CampaignItem
          campaignTitle={planCampaignTitle}
          name={planName}
          inputName={'planStatus'}
          status={planStatus}
          toggleStatusFunc={togglePlanSettings}
          formData={formData}
          setFormData={setFormData}
          disabled={companyPlan < 1 ? true : false}
          isActive={planSettings.active}
          url='edit_plans'
          settings={planSettings}
          type="plan"
        />
      )}

      {icebreakerSettings && (
        <CampaignItem
          campaignTitle={icebreakerCampaignTitle}
          name={icebreakerName}
          inputName={'icebreakerStatus'}
          status={icebreakerStatus}
          toggleStatusFunc={toggleIcebreakerSettings}
          formData={formData}
          setFormData={setFormData}
          disabled={companyPlan < 4 ? true : false}
          isActive={icebreakerSettings.active}
          url='edit_icebreakers'
          settings={icebreakerSettings}
          type="icebreaker"
        />
      )}

      {customCampaigns && customCampaigns.length > 0 && (
        customCampaigns.map((item, index) => (
          <CampaignItem
            key={`campaign-${item._id}`}
            campaignTitle={item.name}
            name={item.name}
            inputName={item._id}
            status={item.active}
            toggleStatusFunc={toggleCustomSettings}
            formData={formData}
            setFormData={setFormData}
            disabled={companyPlan < 3 ? true : false}
            isActive={item.active}
            url={`campaigns/${item._id}`}
            settings={item}
            index={index}
            isArray={true}
            type="checkin"
          />
        ))  
      )}

      {slack === 1 && (
        <div className="card-team">
          <div className="card-title card-title-blue2 p-2">
            <p className="medium card-subtitle campaign-name">
              <FormattedMessage
                id="admin.dashboard.slack.subtitle"
                defaultMessage="Slack"
              />
            </p>

            {!isSlack ? (
              <a href={slackUrl} onClick={() => gaEventTracker()}>
                <img
                  alt="Add to Slack"
                  height="40"
                  width="139"
                  src={slackBtn}
                  className="btn-slack w-100 bg-transparent"
                />
              </a>
            ) : (
              <Fragment>
                <p className="small mr-2 blue">
                  <FormattedMessage
                    id="admin.dashboard.slack.activated"
                    defaultMessage="Activated"
                  />
                </p>
                <Link
                  to="/settings/slack/edit_sync"
                  className="btn-secondary btn-secondary-comment btn-secondary-status mla mr-2"
                >
                  <FormattedMessage
                    id="sync.settings"
                    defaultMessage="Sync Settings"
                  />
                </Link>
                <Link
                  to="/settings/slack/edit_okr"
                  className="btn-secondary btn-secondary-comment btn-secondary-status mr-2"
                >
                  <FormattedMessage
                    id="okr.settings"
                    defaultMessage="OKR Settings"
                  />
                </Link>
                <Link
                  to="/settings/slack/"
                  className="btn-secondary btn-secondary-comment btn-secondary-status"
                >
                  <FormattedMessage
                    id="bot.settings"
                    defaultMessage="Bot Settings"
                  />
                </Link>
              </Fragment>
            )}
          </div>
        </div>
      )}
      
      {discordObj === 1 && (
        <div className="card-team">
          <div className="card-title card-title-blue2 p-2">
            <p className="medium card-subtitle campaign-name">
              <FormattedMessage
                id="discord"
                defaultMessage="Discord"
              />
            </p>
            
            {!discord || discord && !discord.isActive ? (
              <a 
                className='brd brd-grey br-5 flex align-center pt-xs pb-xs cursor'
                href="https://discord.com/api/oauth2/authorize?client_id=945608074230661150&permissions=274877910016&redirect_uri=https%3A%2F%2Fapp.usefocus.co%2Fapi%2Fdiscord%2Foauth2&response_type=code&scope=identify%20email%20guilds%20guilds.members.read%20messages.read%20bot%20applications.commands"
              >
                <img
                  alt="Add to Discord"
                  height="40"
                  width="139"
                  src={discordBtn}
                  className="btn-slack w-100 bg-transparent"
                />
              </a>
            ) : (
              <Fragment>
                <p className="small mr-3 blue">
                  <FormattedMessage
                    id="admin.dashboard.slack.activated"
                    defaultMessage="Activated"
                  />
                </p>
                <Link
                  to="/settings/discord/edit_okr"
                  className="btn-secondary btn-secondary-comment btn-secondary-status"
                >
                  <FormattedMessage
                    id="okr.settings"
                    defaultMessage="OKR Settings"
                  />
                </Link>
              </Fragment>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

AdminDashboardCardItem.propTypes = {
  toggleSettings: PropTypes.func.isRequired,
  toggleUpdateSettings: PropTypes.func.isRequired,
  changeCampaignType: PropTypes.func,
  settings: PropTypes.object,
  updateSettings: PropTypes.object,
  slackId: PropTypes.string,
  isSlack: PropTypes.bool
};

export default connect(null, { 
  toggleSettings, 
  toggleUpdateSettings, 
  changeCampaignType,
})(
  AdminDashboardCardItem
);
