import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import MainMenu from '../layout/Sidebar';
import Menu from '../layout/Menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import { getCompanyProfiles } from '../../actions/profile';
import { getUserCompany } from '../../actions/company';
import { getStandups } from '../../actions/standup';
import { getCompanyAnswers } from '../../actions/standupAnswer';
import { getAllCustoms } from '../../actions/custom';
import SubMenu from '../elems/SubMenu';
import Table from './Table';
import NotAnsweredTable from './NotAnsweredTable';
import { setAlert } from '../../actions/alert';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import { menuTitle, menuItems, tableNameTitle, tableTitleItems, getMenu, getCheckinsMenuNew, } from '../../utils/menu/dashboard';
import NotificationCheckin from '../utils/new/NotificationCheckin';
import WidgetsDashboard from '../widgets/new/Widgets';
import NotificationIcebreaker from '../utils/new/NotificationIcebreaker';
import { getPlanSettings } from '../../actions/plan';
import { getUserPlanAnswers } from '../../actions/planAnswer';
import { getIcebreakers, addIcebreakerAnswer } from '../../actions/icebreaker';
import { getUpdates } from '../../actions/update';
import { getUserUpdateAnswers } from '../../actions/updateAnswer';
import { checkinCopy } from '../../utils/wording/checkins';
import { updateCopy } from '../../utils/wording/updates';
import { planCopy } from '../../utils/wording/plans';
import { icons } from "../../img/elements/icons";
import CheckinItemCard from '../checkins/noState/new/CheckinItemCard';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    backgroundColor: '#fcfcfd',
    fontSize: '14px',
  }),
  control: styles => ({ ...styles, backgroundColor: '#fcfcfd', padding: '4px 8px' }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
}

const DashboardCheckins = ({
  getCompanyAnswers,
  getUserCompany,
  getCompanyProfiles,
  standupAnswer: { standupAnswers, loading },
  auth: { user, sidebar },
  company: { company },
  profile: { profiles },
  locale,
  getStandups,
  standup: { standups },
  getAllCustoms,
  custom, 
  updateAnswer: { updateAnswers },
  getUpdates,
  update: { updates },   
  getUserUpdateAnswers,
  getIcebreakers,
  icebreaker: { icebreakers },
  getPlanSettings,
  plan,
  getUserPlanAnswers,
  planAnswer: { planAnswer, planAnswers },
  addIcebreakerAnswer,
}) => {
const [formData, setFormData] = useState({
    team: '',
    answers: [],
    notCompletedAnswers: [],
    campaigns: [],
    time: '',
    lastFiveAnswers: [],
    userAnswers: [],
  });

  const [usersAmount, setUsersAmount] = useState(0);
  const [menuData, setMenu] = useState({
    menu: {},
    showIcebreaker: false,
  });

  let { menu, showIcebreaker} = menuData;
  let { 
    team, answers, notCompletedAnswers, time, campaigns, 
    lastFiveAnswers, userAnswers,
  } = formData;
  let teamOptions = [];
  let timeOptions = [];

  // Перебор profiles & departments и приведение данных к формату Селекта
   {
    profiles &&
      profiles.forEach((profile, index) => {
        
        if (user && profile._id === user._id) return;

        let coworker = {
          value: profile._id,
          label: `${profile.firstName} ${profile.lastName}`,
          type: 3
        };

        teamOptions.push(coworker);
      });
  }

  {
    company && company.departments.length > 0 &&
      company.departments.forEach((department, index) => {

        if (user && user.department === null) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };
  
          teamOptions.unshift(team);
        } else if (user && user.department !== null && user.department !== undefined && department._id !== user.department) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };
  
          teamOptions.unshift(team);
        }

        if (user && index === company.departments.length - 1) {

          // You is third in list
          let person = { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 }
          teamOptions.unshift(person);

          // Second option is the team if exists
          if (user.department !== null && user.department !== undefined) {
            let team = { value: user.department, label: `😎 ${user.departmentName}`, type: 2 };
            teamOptions.unshift(team);
          }

          // Third option is the company
          let name = { value: company._id, label: `💪 ${company.name}`, type: 1 };
          teamOptions.unshift(name);
        }
      });
  } 

  if (company && company.departments.length === 0 && user && profiles && profiles.length === 1) {
    teamOptions = [
      { value: company._id, label: `💪 ${company.name}`, type: 1 },
      { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 }
    ]
  } else if (company && company.departments.length === 0 && user && profiles && profiles.length > 1) {
    // if 0 teams and 1+ users
    // add user and company to others members that we found in the profiles loop
      let person = { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 }
      teamOptions.unshift(person);

      let team = { value: company._id, label: `💪 ${company.name}`, type: 1 };
      teamOptions.unshift(team);
  }

  let choosedTeam = company ? { value: company._id, label: `💪 ${company.name}`, type: 1 } : {};
  
  // Time Options
  let hours = new Date().getUTCHours();
  let minutes = new Date().getMinutes();
  let today = new Date().getDay();
  let midnight = new Date().setHours(0,0,0,0);
  if (today === 0) { today = 8 }; // For Sundays

  let all = Date.parse(new Date(new Date(2019, 1, 1, 1, 1)));
  let quarter = Date.parse(new Date(new Date() - (90 * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60) * 1000));
  let month = Date.parse(new Date(new Date() - (30 * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60) * 1000));
  let weekAgo = Date.parse(new Date(new Date() - (7 * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60) * 1000));
  let thisWeek = Date.parse(new Date(new Date() - ((today - 1) * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60) * 1000));
 
  let allTime = locale && locale.lang !== 'ru' ? `All Time` : `Все время`; 
  let quarterTime = locale && locale.lang !== 'ru' ? `Quarter` : `Квартал`; 
  let monthTime = locale && locale.lang !== 'ru' ? `Last month` : `Месяц`; 
  let weekTime = locale && locale.lang !== 'ru' ? `Last 7 days` : `7 дней`; 
  let thisWeekTime = locale && locale.lang !== 'ru' ? `This week` : `Эта неделя`; 
  let todayTime = locale && locale.lang !== 'ru' ? `Today` : `Сегодня`; 

  timeOptions = [
      { value: 5, label: allTime, period: all },
      { value: 4, label: quarterTime, period: quarter },
      { value: 3, label: monthTime, period: month },
      { value: 2, label: weekTime, period: weekAgo },
      { value: 1, label: thisWeekTime, period: thisWeek },
      { value: 7, label: todayTime, period: midnight },
    ];

  let choosedTime = { value: 7, label: todayTime, period: midnight };

  // Get users who don't post answers
  const getUnFinishedUsers = (profiles, answers, team) => {
 
    // Company mode by default
    let users = profiles;

    if (profiles.length > 0 && team && team.value) {
      if (team.type === 2) {
        // Team
        users = profiles.filter(item => item.department && item.department.toString() === team.value.toString());
      } else if (team.type === 3) {
        // User
        users = profiles.filter(item => item._id.toString() === team.value.toString());
      }
    }

    setUsersAmount(users.length);

    const unFinishedUsers = users.filter(({ _id: id1 }) => !answers.some(({ user: id2 }) => id2 && id2.toString() === id1.toString()));
    
    return unFinishedUsers;
  };

  useEffect(() => {
    getUserCompany();
    getCompanyProfiles();
    getCompanyAnswers();
    getStandups();
    getAllCustoms();

    setFormData({
      team: !user ? '' : choosedTeam,
      answers: !standupAnswers ? [] : standupAnswers.filter(item => Date.parse(item.date) > midnight),
      notCompletedAnswers: !standupAnswers && !profiles ? [] : getUnFinishedUsers(profiles, standupAnswers.filter(item => Date.parse(item.date) > midnight), choosedTeam),
      time: !user ? '' : choosedTime,
      campaigns: !standups ? [] : standups.filter(item => Date.parse(item.date) > midnight),
      lastFiveAnswers: !standupAnswers ? [] : standupAnswers.slice(0, 5),
      userAnswers: !user || !standupAnswers ? [] : standupAnswers.filter(item => item.user && user._id && item.user.toString() === user._id.toString()),
    });

    setUsersAmount(!profiles ? 0 : profiles.length);

    let settingsArr = custom.campaigns && custom.campaigns.length > 0 && user && company ? getCheckinsMenuNew(custom.campaigns, company, user) : { menuArr: menuItems };

    setMenu({
      ...menuData,
      // menu: custom.campaigns && custom.campaigns.length > 0 ? getMenu(custom.campaigns) : menuItems,
      menu: settingsArr,
    });
  }, [
    getUserCompany, getCompanyAnswers, getCompanyProfiles,
    loading, getStandups, 
    // standupAnswers && standupAnswers.length,
    JSON.stringify(standupAnswers),
    // JSON.stringify(custom),
    // JSON.stringify(updates),
    getAllCustoms,
    user,
  ]);

  // console.log('===== custom', custom)
  // custom && console.log('===== custom.campaigns', custom.campaigns)
  // console.log('===== menuItems', menuItems)
  // console.log('===== updates', updates)
  // console.log('===== updateAnswers', updateAnswers)

  useEffect(() => {
    getUpdates(); 
    getUserUpdateAnswers();
    getIcebreakers();
    getUserPlanAnswers();
    getPlanSettings();

    // console.log('===== USEEFFECT 2 WORKS')
  }, [
    getUserUpdateAnswers,
    getUpdates, 
    getIcebreakers, 
    getUserPlanAnswers, 
    getPlanSettings,
  ]);

  // useEffect(() => {

  //   console.log('===== USEEFFECT 3 WORKS')


  //   if (icebreakers && icebreakers.length > 0 && icebreakers[0].answers.length === 0 ||
  //     icebreakers && user && icebreakers.length > 0 && icebreakers[0].answers.length > 0 && 
  //     icebreakers[0].answers.filter(item => item.user.toString() === user._id.toString()).length === 0) {

  //       // Show icebreakers only if we don't have all weekly notifications at the same time
  //       if (standups && userAnswers.length === 0 && standups.length > 0 || standups && userAnswers && userAnswers.length > 0 && standups.length > 0 && standups[0]._id.toString() !== userAnswers[0].standup.toString()) {
            
  //         if (updates && updateAnswers.length === 0 && updates.length > 0 || updates && updateAnswers.length > 0 && updates.length > 0 && updates[0]._id.toString() !== updateAnswers[0].update.toString()) {

  //               if (plan && planAnswers && planAnswers.length === 0 && plan.settings && plan.settings.active ||
  //                 plan && planAnswer && plan.settings && plan.settings.active && planAnswer.planID !== plan.settings.planID) {
                   
  //                   return;
  //                 }
  //             }
  //         }

  //       setMenu({
  //         ...menuData,
  //         showIcebreaker: true
  //       });
  //     }
  // }, [icebreakers && icebreakers.length]);
  
  // Get users who don't post answers
  const getAnswers = (teamOption, time) => {

    let teamIndex = 'company';
    if (teamOption.type === 1) { teamIndex = 'company'; }
    if (teamOption.type === 2) { teamIndex = 'department'; }
    if (teamOption.type === 3) { teamIndex = 'user'; }

    const answersArr = standupAnswers.filter(answer => answer[teamIndex] === teamOption.value && Date.parse(answer.date) > time.period);
    
    return answersArr;
  };

  const handleTime = async selectedOption => {
    try {
      let newItems = [];
      let newCompletedItems = [];
      let newNotCompletedItems = [];
      let newCampaigns = [];
     
      newItems = await getAnswers(team, selectedOption);
      newNotCompletedItems = await getUnFinishedUsers(profiles, newItems, team);
      newCampaigns = standups.filter(item => Date.parse(item.date) > selectedOption.period);
     
      setFormData({ 
        ...formData, 
        time: selectedOption, 
        answers: newItems,
        notCompletedAnswers: newNotCompletedItems,
        campaigns: newCampaigns,
        lastFiveAnswers: newItems.slice(0, 5),
      });
    } catch (err) {

      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in handleTime', err);
    }
  };

  const handleTeam = async selectedOption => {
    try {
      let newItems = [];
      let newNotCompletedItems = [];
     
      newItems = await getAnswers(selectedOption, time);
      newNotCompletedItems = await getUnFinishedUsers(profiles, newItems, selectedOption);
     
      setFormData({ 
        ...formData, 
        team: selectedOption, 
        answers: newItems,
        notCompletedAnswers: newNotCompletedItems,
        lastFiveAnswers: newItems.slice(0, 5),
      });
    } catch (err) {

      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in handleTeam', err);
    }
  };

  // Get users who don't post answers
  const getUserAnswersAmount = (id) => {
    
    const answersAmount = answers.filter(answer => answer.user && answer.user.toString() === id.toString());
    
    return answersAmount;
  };

  // Removing duplicates in answers array based on the object key 'standup' - to send only unique standup items to StandupTeamItem
  // Берем данные из стейта checkins, а не редакса standupAnswers, чтобы делать фильтр на странице
  let uniqueAnswers = answers ? (answers.reduce((acc, current) => {
    const x = acc.find(item => item.user === current.user);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, [])) : [];

  // Calculate Data
  let campaignsTitle = <FormattedMessage id="total.checkins" defaultMessage="Total Check-ins"/>;
  let completedAnswersTitle = <FormattedMessage id="checkins" defaultMessage="Check-ins"/>;
  let notCompletedAnswersTitle = <FormattedMessage id="not.answered" defaultMessage="Not answered"/>;
  let answersPercentTitle = <FormattedMessage id="response.rate" defaultMessage="Response rate"/>;
  let noAnswersUsersTitle = <FormattedMessage id="users.noAnswer" defaultMessage="Users who didn't answer"/>;
 
  // Data for 4 widgets
  let percent = (answers.length / (usersAmount * campaigns.length)) * 100;
  let answersPercent = Math.round(percent);
  let unfinished = campaigns.length * usersAmount - answers.length;
  
  if (campaigns.length === 0 && answers.length === 0 || unfinished < 0 || isNaN(percent)) {
    unfinished = 0;
    answersPercent = 0;
  }
  
  // Create array with data for widgets
  const firstWidget = {
    data: campaigns.length,
    title: campaignsTitle
  };
  const secondWidget = {
    data: answers.length,
    title: completedAnswersTitle,
  };
  const thirdWidget = {
    data: unfinished,
    title: notCompletedAnswersTitle,
   };
  const forthWidget = {
    data: `${answersPercent}%`,
    title: answersPercentTitle
  };

  const widgetData = [firstWidget, secondWidget, thirdWidget, forthWidget];
  let noAnswersMsg = <FormattedMessage id="no.answers" defaultMessage={`No answers`} />;
  let everyoneAnsweredMsg = <FormattedMessage id="all.answered" defaultMessage={`Everyone answered`} />;
  let subTitle = <FormattedMessage id="dashboard.okrs.subtitle" defaultMessage="Align your team around objectives to achieve better results." />
  let latestCheckins = <FormattedMessage id="latest.checkins" defaultMessage="Latest Check-ins" />
  let viewCheckins = <FormattedMessage id="view.checkins" defaultMessage="View All Check-ins" />;
  
  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {/* {user && user.department ? (
          <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user.isAdmin && true} focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={13} />
        ) : (
          <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={13} />
        )} */}
        {user && !loading && (
          <MainMenu activeIndex={13} />
        )}

        <UserNav />
        
          <Fragment>
            {loading || !user || !company || !standups || !plan || !updates ||
            !standupAnswers || !updateAnswers ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className={`${sidebar === 1 ? 'ml-280-xxl main main-dashboard ' : 'short_menu__ml pl-5 ma-w90'} main_new mt-3`}>
                  <div className="page-title">
                    <h5 className="pl-0-xs">{menuTitle}</h5>
                    <p className="medium">{subTitle}</p>
                  </div>

                  <div className="insights mt-3">

                  <div className="notifications flex align-center pb-3">
                      {standups && standupAnswers.length === 0 && standups.length > 0 ||
                        standups && standupAnswers &&
                        standupAnswers.length > 0 && standups.length > 0 &&
                        standups[0]._id.toString() !== standupAnswers[0].standup.toString() && 
                        (
                          <NotificationCheckin
                            msg={checkinCopy.standupLiveNew}
                            type={1}
                            cta={checkinCopy.standupLiveCtaNew}
                            icon={icons.light_bulb}
                            btnColor={'blue'}
                            color={`blue`}
                            url={standups && standups.length > 0 ? `/checkins/${standups[0]._id}` : '/checkins'}
                            styles={'mr-25'}
                          />
                        )
                      }

                      {plan && planAnswers.length === 0 && plan.settings && plan.settings.active ||
                        plan && planAnswer && plan.settings && plan.settings.active && planAnswer.planID !== plan.settings.planID ? (
                          <NotificationCheckin
                            msg={planCopy.planLiveNew}
                            // msg2={msgHelloUser2}
                            cta={planCopy.planLiveCtaNew}
                            type={3}
                            icon={icons.clipboard}
                            btnColor={'yellow'}
                            color={`yellow`}
                            url={`/plans/complete`}
                            styles={'mr-25'}
                          />
                        ) : null}

                      {updates && updateAnswers.length === 0 && updates.length > 0 ||
                      updates && updateAnswers.length > 0 && updates.length > 0 &&
                      updates[0]._id.toString() !== updateAnswers[0].update.toString() && 
                        (
                          <NotificationCheckin
                            msg={updateCopy.updateLiveNew}
                            type={2}
                            cta={updateCopy.updateLiveCtaNew}
                            icon={icons.clipboard}
                            btnColor={'green'}
                            color={`green`}
                            url={updates.length > 0 ? `/updates/${updates[0]._id}` : '/updates'}
                            styles={'mr-25'}
                          />
                        )}

                    {
                      icebreakers && icebreakers.length > 0 && icebreakers[0].answers.length === 0 ||
                          icebreakers && user && icebreakers.length > 0 && icebreakers[0].answers.length > 0 && 
                          icebreakers[0].answers.filter(item => item.user.toString() === user._id.toString()).length === 0 ?
                        (
                          <NotificationIcebreaker
                            msg={icebreakers[0].question}
                            icebreakerID={icebreakers[0]._id}
                            styles={'mr-25'}
                            user={user}
                            addIcebreakerAnswer={addIcebreakerAnswer}
                          />
                        ) : null}
                    </div>

                    <div className="mt-4 mb-5">
                      <SubMenu
                        items={menu && menu.menuArr ? menu.menuArr : []}
                        activeIndex={2}
                        mb={'5'}
                      />
                    </div>

                    <div className="flex space-between align-center flex-column-xs">
                      <FormattedMessage
                        id="checkins.sub_menu.teamSelect"
                        defaultMessage="Choose the team or user"
                      >
                        {msg => (
                          <Select
                            className="small-input w-350px w-100-xs"
                            defaultValue={choosedTeam}
                            value={team}
                            onChange={selectedOption =>
                              handleTeam(selectedOption)
                            }
                            options={teamOptions}
                            isSearchable
                            placeholder={msg}
                            required
                            name="team"
                            styles={customStyles}
                          />
                        )}
                      </FormattedMessage>

                      <FormattedMessage
                        id="focus.sub_menu.timeSelect"
                        defaultMessage="Choose the time interval"
                      >
                        {msg => (
                          <Select
                            className="small-input w-350px w-100-xs"
                            defaultValue={choosedTime}
                            value={time}
                            onChange={selectedOption =>
                              handleTime(selectedOption)
                            }
                            options={timeOptions}
                            placeholder={msg}
                            required
                            name="time"
                            styles={customStyles}
                          />
                        )}
                      </FormattedMessage>  
                    </div>
                  </div>

                  <div className="dashboard__body flex align-start mt-4">
                    <div className="dashboard__body_left mr-3">
                      <WidgetsDashboard 
                          bgColor={'light'}
                          widgetsAmount={4}
                          width={24}
                          widgetData={widgetData}
                          board={5}
                        />

                      <div className="checkins">
                        <div className="checkins__title flex align-center mt-25 mb-3">
                          <p className="fs-22 bold">{latestCheckins}</p>
                          <Link
                            to={'/checkins'}
                            className="fs-12 blue cursor bold mla mb-0"
                          >
                            {viewCheckins}
                          </Link>
                        </div>
                        {lastFiveAnswers.length > 0 && (
                          lastFiveAnswers.map((item, index) => (
                          <div className="p-2 br-4 mb-14 brd brd-grey__light bg__white" key={index}>
                            <div className="cards">
                              <CheckinItemCard
                                standupAnswer={item}
                                key={`team-${item._id}-${index}`}
                                standupDate={item}
                              />
                            </div>
                          </div>
                          ))
                        )}
                      </div>

                      <div className="answers">
                        <h3 className="fs-2 pt-25 pl-2 pb-1">{completedAnswersTitle}</h3>
                        <Table
                          answers={uniqueAnswers.length > 0 && uniqueAnswers}
                          getUserAnswersAmount={getUserAnswersAmount}
                          tableNameTitle={tableNameTitle}
                          tableTitleItems={tableTitleItems}
                          noContentMsg={noAnswersMsg}
                          isUnfinishedUsers={false}
                          campaigns={campaigns}
                          profiles={profiles}
                          linkName={''}
                        />
                      </div>
                    </div>

                    <div className="dashboard__body_right">
                      <div className="no-answers">
                        <NotAnsweredTable
                          answers={notCompletedAnswers}
                          linkName={''}
                          standupAnswers={standupAnswers}
                          type={1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>

        </div>
      </div>
    </Fragment>
  );
};

DashboardCheckins.propTypes = {
  getCompanyProfiles: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getCompanyAnswers: PropTypes.func.isRequired,
  getStandups: PropTypes.func.isRequired,
  getAllCustoms: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  standupAnswer: PropTypes.object.isRequired,
  standup: PropTypes.object.isRequired,
  icebreaker: PropTypes.object.isRequired,
  update: PropTypes.object.isRequired,
  updateAnswer: PropTypes.object.isRequired,
  planAnswer: PropTypes.object.isRequired,
  plan: PropTypes.object.isRequired,
  custom: PropTypes.object.isRequired,
  getUserUpdateAnswers: PropTypes.func.isRequired,
  getUpdates: PropTypes.func.isRequired,
  getIcebreakers: PropTypes.func.isRequired,
  getUserPlanAnswers: PropTypes.func.isRequired,
  getPlanSettings: PropTypes.func.isRequired,
  addIcebreakerAnswer: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  locale: state.locale,
  company: state.company,
  profile: state.profile,
  standupAnswer: state.standupAnswer,
  standup: state.standup,
  custom: state.custom,
  icebreaker: state.icebreaker,
  planAnswer: state.planAnswer,
  plan: state.plan,
  update: state.update,
  updateAnswer: state.updateAnswer,
});

export default connect(
  mapStateToProps,
  {
    getUserCompany, getCompanyProfiles, getCompanyAnswers,
    getStandups, getAllCustoms, 
    getUserUpdateAnswers,
    getUpdates, getIcebreakers, 
    getUserPlanAnswers, getPlanSettings,
    addIcebreakerAnswer,
  }
)(DashboardCheckins);
