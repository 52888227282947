import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Select from 'react-select';
import getLabels from '../../../../translations/okrs';

const selectCustomStyles = {
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isFocused ? '#404ff5' : '#fff',
    color: state.isFocused ? '#fff' : '#000',
    fontSize: '18px'
  }),
  control: (styles, state) => ({
    ...styles,
    borderColor: state.isFocused ? '404ff5' : '#e9eaf5',
    boxShadow: state.isFocused ? '0px 0px 20px #404FF54D' : 'none',
    fontSize: '18px',
    border: state.isFocused ? '2px solid #404ff5 !important' : styles.border,
    margin: state.isFocused ? '0px' : '1px',
  })
};

const MilestoneUpdate = ({  
  index, 
  handleKeyResults, 
  locale, selectCustomStyles, details,
  activeMilestoneIndex
 }) => {

  details && details.length > 0 && details.forEach((ml, idx) => {
    ml.index = idx
  });


  return (
    <Fragment>
     <div className="kr__update__metrics__binary okr__kr-current labeled-wrapper">
       <FormattedMessage
          id="choose.milestone"
          defaultMessage="Choose the the milestone"
        >
          {(msg) => (
            <Select
              className="w-100"
              // defaultValue={details[activeMilestoneIndex]}
              value={details[activeMilestoneIndex]}
              name="value"
              onChange={(selectedOption) =>
                handleKeyResults(selectedOption, index, 'milestone')
              }
              options={details}
              isSearchable
              placeholder={msg}
              required
              styles={selectCustomStyles}
            />
          )}
        </FormattedMessage>
      </div>
    </Fragment>
  );
};

MilestoneUpdate.propTypes = {
  details: PropTypes.array.isRequired,
  handleKeyResults: PropTypes.func.isRequired,
};

export default MilestoneUpdate;
