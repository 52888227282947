import React, { Fragment } from 'react';
import spinner from './spinner.gif';

const Spinner = () => {

  return (
    <Fragment>
      <img
        src={spinner}
        style={{ width: '200px', margin: 'auto', display: 'block' }}
        alt="Loading..."
      />
  </Fragment>
  );
  // return <img src={spinner} alt="Spinner" />
};

export default Spinner;
