import {
  GET_USER_HOME,
  HOME_ERROR,
  CLEAR_HOME,
  UPDATE_HOME_LIKES,
  ADD_HOME_COMMENT,
  REMOVE_HOME_COMMENT_KUDOS,
  UPDATE_HOME_LIKES_ANSWER,
  ADD_HOME_COMMENT_ANSWER,
  REMOVE_HOME_COMMENT_ANSWER
} from "../actions/types";

const initialState = {
  data: [],
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_HOME:
      return {
        ...state,
        data: payload,
        loading: false
      };
    case UPDATE_HOME_LIKES:
      return {
        ...state,
        data: state.data.map(item =>
          item._id === payload.id
            ? { ...item, likes: payload.likes }
            : item
        ),
        loading: false
      };
    case HOME_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case CLEAR_HOME:
      return {
        ...state,
        data: [],
        loading: true
      };
    case ADD_HOME_COMMENT:
      return {
        ...state,
        data: state.data.map(item =>
          item._id === payload.id
            ? {
                ...item,
                comments: payload.comments
              }
            : item
        ),
        loading: false
      };
    case REMOVE_HOME_COMMENT_KUDOS:
      return {
        ...state,
        data: state.data.map(item =>
          item._id === payload.id
            ? {
                ...item,
                comments: item.comments.filter(
                  comment => comment._id !== payload.commentID
                )
              }
            : item
        ),
        loading: false
      };
    case UPDATE_HOME_LIKES_ANSWER:
      return {
        ...state,
        data: state.data.map(item =>
          item._id === payload.id
            ? {
                ...item,
                likes: payload.likes,
                answers: payload.answers
              }
            : item
        ),
        loading: false
      };
    case ADD_HOME_COMMENT_ANSWER:
        return {
          ...state,
          data: state.data.map(item =>
            item._id === payload.id
              ? {
                  ...item,
                  comments: payload.comments,
                  answers: payload.answers
                }
              : item
          ),
          loading: false
        };
      case REMOVE_HOME_COMMENT_ANSWER:
          return {
            ...state,
            data: state.data.map(item =>
              item._id === payload.id
                ? {
                    ...item,
                    answers: payload.answers,
                    comments: item.comments.filter(
                      comment => comment._id !== payload.commentID
                    )
                  }
                : item
            ),
            loading: false
          };
    default:
      return state;
  }
}
