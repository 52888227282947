import React, { Fragment, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { changePassword } from '../../actions/auth';
import Navbar from '../layout/Navbar';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { FormattedMessage } from 'react-intl';

const ResetPasswordForm = ({ setAlert, changePassword, isAuthenticated, location }) => {
  const [formData, setFormData] = useState({
    password: '',
    password2: ''
  });

  const { password, password2 } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    if (password !== password2) {
      setAlert(
        <FormattedMessage
          id="auth.invite.alert.pass"
          defaultMessage="Passwords do not match"
        />,
        'danger'
      );
    } else {
      const url = qs.parse(location.search);
      const token = url.token;

      changePassword({
        password,
        token
      });
    }
  };

  // Redirect if signed up
  if (isAuthenticated) {
    return <Redirect to="/dashboard/objectives" />;
  }

  return (
    <Fragment>
      <section id="signup">
        <div className="container pt-5">
          <div className="section_header_new mb-2">
            <Navbar guest={true} />
            <h2 className='h2_new'>
              <FormattedMessage
                id="auth.reset.form.header"
                defaultMessage="Change Password"
              />
            </h2>
          </div>
          <div className="form form_new form-invitation">
            <form onSubmit={e => onSubmit(e)}>
              <div className="field_password field mb-1">
                <FormattedMessage
                  id="auth.reset.form.passInput"
                  defaultMessage="New Password"
                >
                  {msg => (
                    <input
                      type="password"
                      className="password password_new"
                      placeholder={msg}
                      name="password"
                      value={password}
                      onChange={e => onChange(e)}
                      minLength="6"
                      required
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className="field_password password2 field mb-1">
                <FormattedMessage
                  id="auth.invite.pass2Input"
                  defaultMessage="Confirm Password"
                >
                  {msg => (
                    <input
                      type="password"
                      className="password2 password_new"
                      placeholder={msg}
                      name="password2"
                      value={password2}
                      onChange={e => onChange(e)}
                      minLength="6"
                      required
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className="form_button text-center">
                <button type="submit" className="btn btn_new btn-primary my-2">
                  {' '}
                  <FormattedMessage
                    id="auth.reset.form.btn"
                    defaultMessage="Change Password"
                  />
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

ResetPasswordForm.propTypes = {
  setAlert: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  location: PropTypes.object,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(
  mapStateToProps,
  { setAlert, changePassword }
)(ResetPasswordForm);
