import React from "react";
import PropTypes from "prop-types";

// Types: 1 or no type - objective, 2 - kr

const OkrProgress = ({ btnClass, progress, type, isHideProgress }) => {
  return (
    <div className={`okr__progress relative text-center flex ${type !== 2 ? 'align-center' : 'flex-start'}`}>
      {!isHideProgress && (
        <div className={`progress ${type !== 2 ? 'progress__okr' : 'progress__kr'} progress__grey mr-1`}>
          <span 
            className={`progress-bar progress-bar__${btnClass}`}
            style={{ width: `${progress <= 100 ? progress : 100}%`}}
            ></span>
        </div>
      )}
      <div className={`okr__progress-number fs-10 lh-14 dark-gray fw-600`}>{Math.round(progress)}%</div>
    </div>
  );
};

OkrProgress.propTypes = {
  btnClass: PropTypes.string.isRequired
};

export default OkrProgress;
