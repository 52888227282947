import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { updateLike } from '../../../../actions/standupAnswer';
import { getCompanyAnswers } from '../../../../actions/standupAnswer';
import CommentItem from '../../../comment/checkins-noState/CommentItem';
import CommentForm from '../../../comment/checkins-noState/CommentFormNew';
import QuestionItem from '../../../comment/checkins/QuestionItem';
import { icons } from "../../../../img/elements/icons";

const CheckinItemCardQuestion = ({
  auth: { loading, user },
  standupAnswer: { standupAnswers },
  avatar,
  question,
  answer,
  comments,
  likes,
  standupID,
  userID,
  updateLike,
  index,
  questionsLength,
}) => {
  const findUserLike = likes => {
      if (likes.filter(like => like.user === user._id).length > 0) {
        return true;
      } else {
        return false;
      }
  };

  const findComments = comments => {
    if (comments.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const [discussion, showComments] = useState(false);

  return (
    <Fragment>
      <div className={`${index === 0 ? "card-question-item" : "card-question-item pt-1"}`}>
        <QuestionItem
          key={`teamQuestionItem-${standupID}-${answer._id}`}
          question={question}
          answer={answer}
          avatar={avatar}
          _id={userID}
          index={index}
        />

        <Fragment>
          <div className="btn-feedback flex align-center pb-0 pt-14 px-0 ml-38">
            <button
              type="button"
              className={classnames('btn-icons btn-like flex align-center mr-14', {
                'btn-active-icon btn-like__active': findUserLike(likes)
              })}
              onClick={e => {
                updateLike(standupID, answer._id)}}
            >
              <span className="mr-xs flex align-center">{icons.heart}{icons.heart_full}</span>
              <span className='fs-10 bold mb-0'>{answer.likesAmount > 0 ? answer.likesAmount : '0'}</span>
            </button>

            <button
              type="button"
              onClick={() => showComments(!discussion)}
              className={classnames('btn-icons btn-comment flex align-center icon__message__badge', {
                'btn-active-icon': findComments(comments)
              })}
            >
              <span className="mr-xs flex align-center">{icons.message}</span>
              <span className="fs-10 bold mb-0">{comments.length > 0 ? comments.length : '0'}</span>
            </button>
          </div>
        </Fragment>

        {discussion && (
          <Fragment>
            <div className="card-feedback mt-24">
              <div className="comment">
                <div className="mb-1">
                  {comments.map(comment => (
                    <CommentItem
                      key={`team-${comment._id}`}
                      comment={comment}
                      standupID={standupID}
                      answerID={answer._id}
                    />
                  ))}
                </div>

                <div className="ml-38">
                  <CommentForm
                    standupID={standupID}
                    answerID={answer._id}
                    user={user}
                    avatar={user.avatar}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        )}

        {index < questionsLength - 1 && (
          <div className="line__grey my-2"></div>
        )}

      </div>
    </Fragment>
  );
};

CheckinItemCardQuestion.propTypes = {
  standupAnswer: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  updateLike: PropTypes.func,
  getCompanyAnswers: PropTypes.func
};

const mapStateToProps = state => ({
  auth: state.auth,
  standupAnswer: state.standupAnswer
});

export default connect(
  mapStateToProps,
  { updateLike, getCompanyAnswers }
)(CheckinItemCardQuestion);
