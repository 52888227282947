import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Navbar from '../layout/Navbar';

const NotFound = () => {
  return (
    <Fragment>
      <Navbar adminMenu={true} />
      <div className="p-5">
        <h1 className="x-large text-primary">
          <i className="fas fa-exclamation-triangle" />{' '}
          <FormattedMessage
            id="notfound.header"
            defaultMessage="Page Not Found"
          />
        </h1>
        <p className="large">
          <FormattedMessage
            id="notfound.error"
            defaultMessage="Sorry, this page does not exist"
          />
        </p>
      </div>
    </Fragment>
  );
};

export default NotFound;
