import React from "react";
import ReactGA from "react-ga4";

const useAnalyticsEventTracker = (category="Test category", action = "test action", label = "test label") => {
  console.log('===== category in useAnalyticsEventTracker', category)
  console.log('===== action in useAnalyticsEventTracker', action)
  console.log('===== label in useAnalyticsEventTracker', label)
  const eventTracker = () => {
    console.log('===== HERE in eventTracker', label)

    // ReactGA.event({category, action, label});
  }
  ReactGA.event({category, action, label});

  // return eventTracker;
}
export default useAnalyticsEventTracker;