import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import TodoItem from '../elems/TodoItem2';
import AddTodoBtn from '../elems/AddTodoBtn';
import TodoItem2Card from '../elems/TodoItem2Card';
import TodoItem2CardForm from '../elems/TodoItem2CardForm.js';
import FocusMain from '../utils/FocusMain.js';
import Fs2Title from '../elems/Fs2Title';
import BtnTodoAdd from '../elems/BtnTodoAdd';
import BtnTodoClose from '../elems/BtnTodoClose';
import OneSettings from './OneSettings';
import OneSelect from './elems/OneSelect';
import OneNotes from './elems/OneNotes';
import OneHistory from './elems/OneHistory';
import FollowUp from './elems/FollowUp';
import { FormattedMessage } from 'react-intl';
import Loader from 'react-loader-spinner';
import { icons } from '../../img/elements/icons';

const OneMain = ({
  currentProfile,
  currentDoc,
  docs,
  userOptions,
  profilesState,
  handleSelectChange,
  isForm,
  toggleForm,
  meetingID,
  okrOptions,
  handleAdd,
  formData,
  handleChangeForm,
  onSubmitTodo,
  clearTodo,
  handleComplete,
  handleNameForm,
  handleSelect,
  handleDelete,
  user,
  handleDueIn,
  handleChangeFormState,
  onSubmit,
  isLoader,
  handleChangeFormSingle,
  handleNameFormSingle,
  isOneGoal,
  toggleOneGoal,
  toggleSettings,
  isSettings,
  handleCheckbox,
  onChangeTimezone,
  handleTime,
  onChangeDay,
  onChangeDate,
  okrs,
  isFocusForm,
  setFocus,
  onSubmitSettings,
  handleCancel,
  handleChangeFollowUpState,
  handleDeleteFollowUp,
  handleSelectFollowUp,
  handleAddFollowUp,
}) => {

  let objectLength = 0;
  if (currentProfile !== undefined && currentProfile !== null) {
    objectLength = Object.keys(currentProfile).length;
  }

  let goalObjLength = 0;
  if (formData.numberOneGoal !== undefined && formData.numberOneGoal !== null) {
    goalObjLength = Object.keys(formData.numberOneGoal).length;
  }

  let currentUser = false;
  let currentFocus = false;
  let currentFocusSelect = false;

  if (objectLength > 0) {
    currentUser = {
      value: currentProfile._id,
      label: `${currentProfile.firstName} ${currentProfile.lastName}`,
      name: 'selectedUser',
    };

    currentFocus = docs.filter((d) => d.meetingID.toString() === meetingID);
    currentFocus = currentFocus.length > 0 ? currentFocus[0].focus : {};

    let focusObjLength = 0;
    if (currentFocus !== undefined && currentFocus !== null) {
      focusObjLength = Object.keys(currentFocus).length;
    }

    if (focusObjLength > 0) {
      currentFocusSelect = {
        value: currentFocus._id,
        label: currentFocus.text,
        name: 'focus',
      };
    }
  }

  let scheduleDay = formData.scheduleDay;

  let scheduleLength = 0;
  if (scheduleDay !== undefined && scheduleDay !== null) {
    scheduleLength = Object.keys(scheduleDay).length;
  }

  let focusTitle = (
    <FormattedMessage id='monthly.focus' defaultMessage='🎯 Monthly Focus' />
  );

  let editMsg = <FormattedMessage id='edit' defaultMessage='Edit' />;
  let historyTitle = <FormattedMessage id='history' defaultMessage='History' />;
  let followupTitle = (
    <FormattedMessage id='follow.up' defaultMessage='Follow Up' />
  );

  // Loader for button
  let loadingIcon = (
    <span
      style={{
        width: '80',
        height: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader type='Circles' color='#DAE3E5' height='18px' width='100' />
    </span>
  );

  let btnText = <FormattedMessage id='create' defaultMessage='Create' />;
  let cancelText = <FormattedMessage id='cancel' defaultMessage='Cancel' />;
  let tpTitle = <FormattedMessage id='talking.points' defaultMessage='Talking points' />;
  let todoTitle = <FormattedMessage id='action.items' defaultMessage='Action items' />;
  let numberOneGoalTitle = <FormattedMessage id='#1thing' defaultMessage='#1 thing to achieve this week' />;
  let notesTitle = <FormattedMessage id='notes' defaultMessage='Notes' />;

  if (isLoader) {
    btnText = loadingIcon;
  }

  let testLoadingBtn = false;

  // OKR for focus
  const [inHoverFocus, setHoverFocus] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  let okr =
    okrs && currentFocus.okrId
      ? okrs.filter((item) => item._id.toString() === currentFocus.okrId)
      : {};
  if (okr.length > 0) {
    okr = okr[0];
  }

  return (
    <Fragment>
      <div className='main-content'>
        {isForm ? (
          <Fragment>
            <OneSelect
              defaultValue={currentUser}
              onChange={handleSelectChange}
              options={userOptions}
              isMulti={false}
              isSearchable={true}
              selectType={1}
              divStyles='w-350px fs1_4'
            />

            <OneSelect
              defaultValue={currentFocusSelect}
              onChange={handleSelectChange}
              options={okrOptions}
              isMulti={false}
              isSearchable={true}
              styles={1}
              selectType={2}
              divStyles='w-350px fs1_4'
            />
          </Fragment>
        ) : !isFocusForm ? (
          <div
            onMouseEnter={() => setHoverFocus(true)}
            onMouseLeave={() => setHoverFocus(false)}
            className='flex align-center mt-3'
          >
            <FocusMain
              okr={okr}
              focus={formData.focus}
              date={currentDoc && currentDoc.date}
              title={focusTitle}
              styles={1}
              focusType={3}
              divStyles={'w-90 bg__white brd brd-primary_light2'}
            />

            <div className='w-30px'>
              {inHoverFocus && (
                <div
                  className='btn-action no_btn ml-xs relative bg-transparent'
                  type='button'
                >
                  {icons.more}

                  <div className='w-100px dropdown p-1 light-shadow'>
                    <button
                      type='button'
                      className='w-100px btn-icons btn-edi flex align-center'
                      onClick={() => setFocus(!isFocusForm)}
                    >
                      <i className='fas fa-pencil-alt' />{' '}
                      <span className='pl-1 small grey'>{editMsg}</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className='flex align-center mt-4'>
            <OneSelect
              defaultValue={currentFocusSelect}
              onChange={handleSelectChange}
              options={okrOptions}
              isMulti={false}
              isSearchable={true}
              styles={1}
              selectType={2}
              divStyles='w-350px fs1_4'
              hideTitle={true}
            />

            <div className='form-btn flex align-center ml-2'>
              <BtnTodoAdd
                msgId={'id'}
                msg={'Add'}
                index={99}
                name={'focus'}
                onSubmit={onSubmitTodo}
              />
              <BtnTodoClose index={99} name={'focus'} onClick={clearTodo} />
            </div>
          </div>
        )}

        <TodoItem
          title={tpTitle}
          addMsg={'topic'}
          name={'talkingPoints'}
          handleAdd={handleAdd}
          formData={formData}
          items={formData.talkingPoints}
          handleChangeForm={handleChangeForm}
          handleChangeFormState={handleChangeFormState}
          inputName={'text'}
          onSubmit={onSubmitTodo}
          clearTodo={clearTodo}
          handleComplete={handleComplete}
          usersOptions={userOptions}
          okrOptions={okrOptions}
          handleNameForm={handleNameForm}
          handleSelect={handleSelect}
          handleDelete={handleDelete}
          user={user}
          handleDueIn={handleDueIn}
          oneId={currentDoc ? currentDoc._id : 0}
          isForm={isForm}
          width={'w-80 mr-2'}
        />

        <TodoItem
          title={todoTitle}
          addMsg={'task'}
          name={'todos'}
          handleAdd={handleAdd}
          formData={formData}
          items={formData.todos}
          handleChangeForm={handleChangeForm}
          handleChangeFormState={handleChangeFormState}
          inputName={'name'}
          onSubmit={onSubmitTodo}
          clearTodo={clearTodo}
          handleComplete={handleComplete}
          usersOptions={userOptions}
          okrOptions={okrOptions}
          handleNameForm={handleNameForm}
          handleSelect={handleSelect}
          handleDelete={handleDelete}
          user={user}
          handleDueIn={handleDueIn}
          isTodo={true}
          isAssignee={true}
          isDueIn={true}
          oneId={currentDoc ? currentDoc._id : 0}
          isForm={isForm}
        />

        <div className='weekly-focus my-3'>
          <Fs2Title title={numberOneGoalTitle} />
          {goalObjLength > 0 ? (
            !formData.numberOneGoal.isNew ? (
              <TodoItem2Card
                item={formData.numberOneGoal}
                formData={formData}
                usersOptions={userOptions}
                okrOptions={okrOptions}
                handleSelect={handleSelect}
                handleDueIn={handleDueIn}
                handleComplete={handleComplete}
                index={99}
                user={user}
                handleDelete={handleDelete}
                handleNameForm={handleNameForm}
                handleChangeForm={handleChangeForm}
                handleChangeFormState={handleChangeFormState}
                addMsg={'goal'}
                name={'numberOneGoal'}
                inputName={'text'}
                isTodo={false}
                clearTodo={clearTodo}
                isAssignee={false}
                isDueIn={false}
                oneId={currentDoc ? currentDoc._id : 0}
                isForm={isForm}
              />
            ) : (
              <TodoItem2CardForm
                item={formData.numberOneGoal}
                formData={formData}
                usersOptions={userOptions}
                okrOptions={okrOptions}
                handleChangeForm={handleChangeForm}
                handleChangeFormState={handleChangeFormState}
                clearTodo={clearTodo}
                handleDueIn={handleDueIn}
                onSubmit={onSubmitTodo}
                index={99}
                addMsg={'goal'}
                name={'numberOneGoal'}
                inputName={'text'}
                isTodo={false}
                isAssignee={false}
                isDueIn={false}
                oneId={currentDoc ? currentDoc._id : 0}
                width={'w-80 mr-2'}
              />
            )
          ) : (
            <div className='step-item mr-2 pt-1 px-1'>
              <p
                onClick={(e) => handleAdd(e, 'numberOneGoal')}
                className='medium grey cursor'
              >
                +Add goal
              </p>
            </div>
          )}
        </div>

        <div className='notes my-3'>
          <div className='mb-1'>
            <Fs2Title title={notesTitle} />
          </div>

          <Fragment>
            {formData.notes &&
              formData.notes.length > 0 &&
              formData.notes.map((item, index) => (
                <div key={`notes_1_${index}-${!item.isNew ? 'note' : 'form'}`}>
                  {!item.isNew ? (
                    <OneNotes
                      item={item}
                      handleSelect={handleSelect}
                      index={index}
                      user={user}
                      handleDelete={handleDelete}
                      handleNameForm={handleNameForm}
                      handleChangeForm={handleChangeForm}
                      handleChangeFormState={handleChangeFormState}
                      name={'notes'}
                      inputName={'text'}
                      isTodo={false}
                      clearTodo={clearTodo}
                      isForm={isForm}
                    />
                  ) : (
                    <TodoItem2CardForm
                      item={item}
                      formData={formData}
                      usersOptions={userOptions}
                      okrOptions={okrOptions}
                      handleChangeForm={handleChangeForm}
                      handleChangeFormState={handleChangeFormState}
                      clearTodo={clearTodo}
                      handleDueIn={handleDueIn}
                      onSubmit={onSubmitTodo}
                      index={index}
                      addMsg={'note'}
                      name={'notes'}
                      inputName={'text'}
                      isTodo={false}
                      isAssignee={false}
                      isDueIn={false}
                      oneId={currentDoc ? currentDoc._id : 0}
                      width={'w-80 mr-2'}
                    />
                  )}
                </div>
              ))}

            <AddTodoBtn handleAdd={handleAdd} name={'notes'} addMsg={'note'} />
          </Fragment>
        </div>

        {!isForm && (
          <div className='follow-up my-3'>
            <Fs2Title title={followupTitle} />

            {formData.followUp.length > 0 && (
              <FollowUp
                items={formData.followUp}
                formData={formData}
                handleChangeForm={handleChangeForm}
                handleChangeFormState={handleChangeFormState}
                clearTodo={clearTodo}
                onSubmit={onSubmitTodo}
                currentDoc={currentDoc}
                handleChangeFollowUpState={handleChangeFollowUpState}
                user={user}
                isForm={isForm}
                handleDeleteFollowUp={handleDeleteFollowUp}
                handleSelectFollowUp={handleSelectFollowUp}
                handleAddFollowUp={handleAddFollowUp}
              />
            )}

            <AddTodoBtn
              handleAdd={handleAdd}
              name={'followUp'}
              addMsg={'follow up'}
            />
          </div>
        )}

        {!isForm && (
          <div className='history my-2'>
            <div
              className='history__title flex align-center cursor'
              onClick={() => setShowHistory(!showHistory)}
            >
              <Fs2Title title={historyTitle} />
              {icons.dropdown_icon}
            </div>

            {showHistory && <OneHistory docs={docs} />}
          </div>
        )}

        {isForm && (
          <Fragment>
            <button
              type='submit'
              className='btn btn-form w-150px h-60'
              disabled={!isLoader ? false : true}
              onClick={(e) => onSubmit(e)}
            >
              {btnText}
            </button>
            <span
              className='grey medium ml-3 cursor'
              onClick={(e) => handleCancel(e)}
            >
              {cancelText}
            </span>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

OneMain.propTypes = {
  currentProfile: PropTypes.object,
};

export default OneMain;
