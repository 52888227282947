import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import UpdateItemCard from './UpdateItemCard';

const UpdateItem = ({ 
  updateAnswers, updateDate, formData, 
  setFormData, searchKeyword, mentions,
  settings,
}) => {
  return (
    <Fragment>
          {updateAnswers.map((updateAnswer, index) => (
            <div className="p-2 br-4 mb-14 brd brd-grey__light bg__white" key={index}>
              <div className="cards">
                <UpdateItemCard
                  updateAnswer={updateAnswer}
                  key={`team-${updateAnswer._id}-${index}`}
                  updateDate={updateDate}
                  formData={formData}
                  setFormData={setFormData}
                  searchKeyword={searchKeyword}
                  mentions={mentions}
                  settings={settings}
                />
              </div>
            </div>
          ))}
    </Fragment>
  );
};

UpdateItem.propTypes = {
  updateAnswers: PropTypes.array.isRequired
};

export default UpdateItem;
