import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import { updateLike, deleteOkr, addUserMainOkr, updateOkr } from "../../../actions/okr";
import { icons } from "../../../img/elements/icons";
import { setAlert } from '../../../actions/alert';
import OkrStatus from "../elements/OkrStatus";
import OkrAvatar from "../elements/OkrAvatar";
import OkrProgress from "../elements/OkrProgress";
import KeyResultItem from "../kr/KeyResultItem";
import CommentItem from "../../comment/okrs-noState/CommentItem";
import CommentForm from "../../comment/okrs-noState/CommentForm";
import ErrorBoundary from "../../_helpers/ErrorBoundary";
import { FormattedMessage } from "react-intl";
import OkrMoreIcons from "../elements/OkrMoreIconsClick";

const OkrCardItem = ({
  auth: { loading, user },
  okr: {
    creator,
    owner,
    ownerAvatar,
    ownerFirstName,
    ownerLastName,
    dueIn,
    _id,
    objective,
    team,
    teamName,
    department,
    isMain,
    isMainIndex,
    type,
    progress,
    status,
    updatedAt,
    keyResults,
    date,
    likes,
    comments,
    isParent,
    isSecondParent,
    isChild,
    order,
    childOrder,
    secondChildOrder,
    isArchive
  },
  updateLike,
  deleteOkr,
  addUserMainOkr,
  okrBoard,
  updateOkr,
  formType
}) => {
  const [formData, setFormData] = useState({
    keyResults: [],
    status: ''
  });

  const statusOptions = [{ value: 1, label: 'On Track', color: '#6ddeb3' }, { value: 2, label: 'Behind', color: '#facf34' }, { value: 3, label: 'At Risk', color: 'red' }];

  let choosedStatus = {};

  status && statusOptions.forEach(item => {
    if (item.value === status) {
      choosedStatus = {
        value: item.value,
        label: item.label,
        color: item.color
      };
    }
  });

  useEffect(() => {
    setFormData({
      keyResults: !keyResults ? [] : keyResults, 
      status: !status ? '' : choosedStatus, 
    });
  }, []); 


  const handleStatus = selectedOption => {
    setFormData({ ...formData, status: selectedOption });
  };

  const onChange = (e, index) => {
    keyResults[index][e.target.name] = e.target.value;
    setFormData({ ...formData, keyResults: keyResults });
  };

  const onSubmit = async e => {
    try {
      e.preventDefault();

      updateOkr(formData, _id);
 
      toggleUpdateMode(!update);
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in likes', err);
    }
  };

  const findUserLike = likes => {
    if (!loading && user) {
      if (likes.filter(like => like.user === user._id).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const findComments = comments => {
    if (comments.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const [displayKeyResults, toggleKeyResultsButton] = useState(keyResults && keyResults.length > 0 ? true : false);
  const [discussion, showComments] = useState(false);
  const [update, toggleUpdateMode] = useState(false);
  const [childs, toggleChilds] = useState(true);

  let btnClass;
  let label = "";

  if (status === 1) {
    btnClass = "green";
    label = "On Track";
  } else if (status === 2) {
    btnClass = "yellow";
    label = "Behind";
  } else if (status === 3) {
    btnClass = "red";
    label = "At Risk";
  }

  return (
    <Fragment>
      <div className="card-team flex relative">
        <div className="card-icons card-icons__okr flex align-start w-40px-l pt-5">

          {okrBoard === '1' && (
             type === 1 ? (
              <button 
              className="btn-action no_btn no-minw bg-transparent absolute-xs icon__grey" 
              type="button"
              onClick={e => addUserMainOkr(_id, 99, okrBoard)}
              >
              {isMain && isMainIndex === 99 ? (
                <span className="star-box__full__yellow">{icons.star_full}</span>
                ) : (
                  <span className="star-box">
                    <Fragment> 
                      <span className="star-box__empty">{icons.star_empty}</span>
                      <span className="star-box__full">{icons.star_full}</span>
                    </Fragment>
                  </span>
                )}
            </button>) : null
          )}

          {okrBoard === '2' || okrBoard === '3' ? (
             <button 
             className="btn-action no_btn no-minw bg-transparent absolute-xs icon__grey" 
             type="button"
             onClick={e => addUserMainOkr(_id, 99, okrBoard)}
             >
             {isMain && isMainIndex === 99 ? (
                <span className="star-box__full__yellow">{icons.star_full}</span>
                ) : (
                  <span className="star-box">
                    <Fragment> 
                      <span className="star-box__empty">{icons.star_empty}</span>
                      <span className="star-box__full">{icons.star_full}</span>
                    </Fragment>
                  </span>
                )}
           </button>
          ) : null}
        </div>

        <div className="w-100 okr__card">
          <form
            className="form__okr-update"
            onSubmit={e => onSubmit(e)}
          >
          <div className={`-${btnClass} relative ${!displayKeyResults ? 'br-4' : 'okr__card__expanded'} brd brd-grey__light bg__white`}>
            <div className="flex w-40-l-70-xs">
              <div className="okr__left flex flex-column flex-start px-3 py-25">
                {type === 2 && okrBoard === '1' ? (<Link to={`/company/team/${team}`} className="-mt-1"><span className={`fs-10`}>{teamName}</span></Link>) :
                (type === 1 && okrBoard === '1' && <span className={`fs-12 dark-grey`}>{teamName}</span>)}
                <p className="fs-2 w-100 fw-600">{objective}</p>

                <div className="flex align-center mt-1">
                  <div className="mr-1">
                    <OkrStatus
                      btnClass={btnClass !== undefined && btnClass}
                      label={label}
                      updateMode={update}
                      handleStatus={handleStatus}
                      statusOptions={statusOptions}
                      choosedStatus={choosedStatus}
                    />
                  </div>
                  {/* <OkrUpdate updatedAt={updatedAt} /> */}
                  <OkrProgress 
                    btnClass={btnClass !== undefined && btnClass} 
                    progress={progress}
                    keyResults={keyResults}
                  />
                </div>
              </div>
              <div className="line-new__vertical"></div>
              <div className="okr__right px-3 py-25 flex align-center">
                <OkrAvatar
                  owner={owner} 
                  ownerAvatar={ownerAvatar}
                  ownerFirstName={ownerFirstName}
                  ownerLastName={ownerLastName}
                  dueIn={dueIn}
                  noLink={formType === 2 && true}
                />
                <div className="mla">
                  {formType !== 2 && (
                    <OkrMoreIcons
                      _id={_id}
                      // handleArchive={handleArchive}
                      handleDelete={() => { if (window.confirm('Are you sure you wish to delete this OKR?')) deleteOkr(_id) } }
                      isArchive={isArchive}
                      // archive={archive}
                      user={user}
                      owner={owner}
                      creator={creator}
                      // locale={locale}
                      // lang={lang}
                      isHideArchive={true}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>

          {displayKeyResults && (
            <div className="kr__card bg__white brd brd-grey__light card-standups-team card-body-hidden pt-4 pb-2 pl- active">
              <div className="card-goal-item relative ml-2-xs">
                <div className="mb-2">
                  <ErrorBoundary>
                  {keyResults.map((item, i) => (
                    <KeyResultItem
                      key={item._id}
                      keyResult={item}
                      progress={item.progress}
                      isMain={isMainIndex === i ? isMain : false}
                      okrId={_id}
                      index={i}
                      okrBoard={okrBoard}
                      okrType={type}
                      updateMode={update}
                      onChange={onChange}
                      formData={formData}
                      formType={formType && formType}
                    />
                  ))}
                  </ErrorBoundary>
                </div>

                <div className="px-3">
                  <div className="line__grey my-3"></div>
                </div>

                <div className="px-3">
                  <div className="1">
                    <div className="flex align-center">
                      {formType !== 2 && (
                        <div className="flex align-center">
                          <button
                            type="button"
                            className={classnames('btn-icons btn-like flex align-center mr-1', {
                              'btn-active-icon btn-like__active': findUserLike(likes)
                            })}
                            onClick={e => updateLike(_id)}
                          >
                            <span className="mr-xs flex align-center">{icons.heart}{icons.heart_full}</span>
                            <span className='fs-10 bold mb-0'>{likes.length > 0 ? likes.length : '0'}</span>
                          </button>

                          <button
                            type="button"
                            onClick={() => showComments(!discussion)}
                            className={classnames('btn-icons btn-comment flex align-center icon__message__badge', {
                              'btn-active-icon': findComments(comments)
                            })}
                          >
                            <span className="mr-xs flex align-center">{icons.message}</span>
                            <span className="fs-10 bold mb-0">{comments.length > 0 ? comments.length : '0'}</span>
                          </button>
                        </div>
                      )}

                    {!update ? (
                      <Fragment>
                        <div className="block mla w-content pb-0">
                          <button 
                            type="button" 
                            className="btn_new__grey br-4 cursor"
                            onClick={() => toggleUpdateMode(!update)}
                          >
                            <FormattedMessage
                              id="okr.form.update"
                              defaultMessage="Update OKR"
                            />
                          </button>
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="flex align-center block mla w-content pb-0">
                          <button 
                            type="button" 
                            className="btn_new__grey br-4 cursor"
                            onClick={() => toggleUpdateMode(!update)}
                          >
                            <FormattedMessage
                              id="okr.form.cancel"
                              defaultMessage="Cancel"
                            />
                          </button>

                          <button 
                            type="submit" 
                            className="btn btn-small btn-small-blue ml-1 br-4 mr-0"
                          >
                            <FormattedMessage
                              id="standup.form.submit"
                              defaultMessage="Submit"
                            />
                          </button>
                        </div>
                      </Fragment>
                    )}
                    </div>
                  </div>
                </div>
              </div>
              {discussion && (
              <Fragment>
                <div className="card-feedback mt-2 px-3">
                  <div className="comment">

                    {comments.map(comment => (
                      <CommentItem
                        key={comment._id}
                        comment={comment}
                        okrID={_id}
                      />
                    ))}

                    <CommentForm
                      okrID={_id}
                      user={user}
                      avatar={user && user.avatar}
                    />
                  </div>
                </div>
              </Fragment>
            )}
            </div>
          )}
          </form>
        </div>
      </div>
    </Fragment>
  );
};

OkrCardItem.propTypes = {
  auth: PropTypes.object.isRequired,
  updateLike: PropTypes.func,
  deleteOkr: PropTypes.func,
  addUserMainOkr: PropTypes.func,
  updateOkr: PropTypes.func.isRequired,
  okrBoard: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { updateLike, deleteOkr, addUserMainOkr, updateOkr })(OkrCardItem);
