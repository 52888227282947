import axios from "axios";
import { setAlert } from "./alert";

import {
  GET_COMPANY_ONES,
  COMPANY_ONES_ERROR,
  GET_USER_ONES,
  USER_ONES_ERROR,
  GET_USER_ONES_ID,
  GET_ONES_SETTINGS,
  SETTINGS_ONES_ERROR,
  ADD_ONE_ON_ONE,
  ONES_ERROR,
  EDIT_ONES_SETTING,
  UPDATE_ONES,
  UPDATE_ONES_ERROR,
  COMPLETE_ONES,
  COMPLETE_ONES_ERROR,
  DELETE_ITEM_ONES,
  DELETE_ITEM_ONES_ERROR,
  DELETE_ONES,
  CLEAR_ONES,
} from "./types";


// Get all company 1:1s
export const getCompanyMeetings = () => async dispatch => {
  try {
    
    const res = await axios.get("/api/oneonones/company");

    dispatch({
      type: GET_COMPANY_ONES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: COMPANY_ONES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get all user 1:1s
export const getUserMeetings = () => async dispatch => {
  try {
    
    const res = await axios.get("/api/oneonones/user");

    dispatch({
      type: GET_USER_ONES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: USER_ONES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all user 1:1s by id
export const getUserMeetingsById = (id) => async dispatch => {
  try {
    
    const res = await axios.get(`/api/oneonones/user/${id}`);

    dispatch({
      type: GET_USER_ONES_ID,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: USER_ONES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get all 1:1s settings
export const getSettingsById = (id) => async dispatch => {
  try {
    
    const res = await axios.get(`/api/oneonones/settings/${id}`);

    dispatch({
      type: GET_ONES_SETTINGS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SETTINGS_ONES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Create 1:1
export const addMeeting = (data, meetingID) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({ data, meetingID });
    const res = await axios.post(`/api/oneonones/new`, body, config);

    dispatch({
      type: ADD_ONE_ON_ONE,
      payload: res.data
    });

    dispatch(setAlert('The meeting was created', 'success', 2000));

    // Callback for updating in formData.objectives in useState to render it
    const arr = { data: res.data };
    return arr;
   

  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ONES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};



// Edit 1:1 settings
export const editMeetingSettings = (id, data) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify(data);

    const res = await axios.put(`/api/oneonones/${id}`, body, config);
    
    dispatch({
      type: EDIT_ONES_SETTING,
      payload: { id, data: res.data }
    });

    dispatch(setAlert('The settings was updated', 'success', 2000));

    const obj = { status: true, id, data: res.data };
    return obj;

  } catch (err) {
    const errors = err.response.data.errors;
    console.log('err.response.data.', err.response.data);

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ONES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Update Todo
export const updateMeeting = (id, data) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify(data);
    const res = await axios.put(`/api/oneonones/update/${id}`, body, config);
    
    dispatch({
      type: UPDATE_ONES,
      payload: { id, data: res.data }
    });

    // Callback for updating in formData.objectives in useState to render it
    const arr = { id, data: res.data};
    return arr;

  } catch (err) {
    const errors = err.response.data.errors;
    console.log('err.response.data.', err.response.data);

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: UPDATE_ONES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Update Todo for follow ups
export const updateMeetingFollowup = (id, data) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify(data);

    const res = await axios.put(`/api/oneonones/update/followup/${id}`, body, config);
    
    dispatch({
      type: UPDATE_ONES,
      payload: { id, data: res.data }
    });
    
    // Callback for updating in formData.objectives in useState to render it
    const arr = { id, data: res.data};
    return arr;

  } catch (err) {
    const errors = err.response.data.errors;
    console.log('err.response.data.', err.response.data);

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: UPDATE_ONES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Complete something in 1:1
export const completeMeetingItem = (id, data) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify(data);

    const res = await axios.put(`/api/oneonones/complete/${id}`, body, config);
    
    dispatch({
      type: COMPLETE_ONES,
      payload: { id, data: res.data }
    });

    // Callback for updating in formData.objectives in useState to render it
    const arr = { id, data: res.data};
    return arr;

  } catch (err) {
    const errors = err.response.data.errors;
    console.log('err.response.data.', err.response.data);

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: COMPLETE_ONES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};





// Delete Todo
export const deleteMeetingItem = (id, data) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify(data);

    const res = await axios.put(`/api/oneonones/delete/item/${id}`, body, config);
    
    dispatch({
      type: DELETE_ITEM_ONES,
      payload: { id, data: res.data }
    });

    // Callback for updating in formData.objectives in useState to render it
    const arr = { id, data: res.data};
    return arr;

  } catch (err) {
    const errors = err.response.data.errors;
    console.log('err.response.data.', err.response.data);

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: DELETE_ITEM_ONES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete Follow Up
export const deleteMeetingFollowUp = (id, data) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify(data);

    const res = await axios.put(`/api/oneonones/delete/followup/${id}`, body, config);
    
    dispatch({
      type: DELETE_ITEM_ONES,
      payload: { id, data: res.data }
    });

    // Callback for updating in formData.objectives in useState to render it
    const arr = { id, data: res.data, ok: true };
    return arr;

  } catch (err) {
    const errors = err.response.data.errors;
    console.log('err.response.data.', err.response.data);

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: DELETE_ITEM_ONES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete Todo
export const deleteMeeting = (id) => async dispatch => {
  try {
    await axios.delete(`/api/oneonones/${id}`);

    dispatch({
      type: DELETE_ONES,
      payload: id
    });

    // Callback for updating in formData.todos in useState to render it
    let data = { id }
    return data;

  } catch (err) {
    const errors = err.response.data.errors;
    console.log('err.response.data.', err.response.data);
    
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    
    dispatch({
      type: ONES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Clear state
export const clearOneOnOnes = () => async dispatch => {
  try {
   
    dispatch({
      type: CLEAR_ONES
    });
  } catch (err) {
    dispatch({
      type: ONES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
