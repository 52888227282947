import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { icons } from '../../img/elements/icons';
import { FormattedMessage } from 'react-intl';
import UniversalCommentItem from '../comment/UniversalCommentItem';
import UniversalCommentForm from '../comment/UniversalCommentForm';

const IceItem = ({
  icebreaker: {
    _id,
    question,
    date,
    answers,
  },
  formData,
  setFormData,
  searchKeyword,
  authUser,
  handleDeleteAnswer,
  onSubmitEdit,
  index,
  handleEditButton,
  displayEdit,
  toggleEditForm,
  onChange,
  onSubmit,
}) => {
  const [displayUserAnswer, toggleAnswerButton] = useState(true);

  return (
    <Fragment>
      <div className="px-2 card-team">
        <div className="pt-2 flex space-between align-center">
          <div className="flex justify-start align-center w-80">
            <div>
              <p className="fs-20 lh-30 bold card-subtitle team-user">
                {question}
              </p>
              <p className="small grey">
                <FormattedMessage id="checkins.published" defaultMessage="Published" />{' '}
                <Moment format="D MMM">{date}</Moment>
              </p>
            </div>
          </div>

          <div className="card-icons">
            <button
              onClick={() => toggleAnswerButton(!displayUserAnswer)}
              className="btn-action no_btn"
              type="button"
            >
              {icons.dropdown_icon}
            </button>
          </div>
        </div>

        <div className="line"></div>

        {displayUserAnswer && (
          <div className="card-standups-team card-body-hidden px-2-l pb-0 pt-1 active">
            {answers.map((answer) => (
              <UniversalCommentItem
                key={`icebreakerItemAnswer-${_id}-${answer._id}`}
                mainItemId={_id}
                item={answer}
                authUser={authUser}
                searchKeyword={searchKeyword}
                handleDelete={handleDeleteAnswer}
                onSubmitEdit={onSubmitEdit}
                isEdit={true}
                value={answer.text}
                handleEditButton={handleEditButton}
                displayEdit={displayEdit}
                toggleEditForm={toggleEditForm}
                index={index}
                onChange={onChange}
              />
            ))}

            <UniversalCommentForm
              mainItemId={_id}
              index={index}
              value={formData.text}
              avatar={authUser.avatar}
              onSubmit={onSubmit}
              onChange={onChange}
            />

          </div>
        )}
      </div>
    </Fragment>
  );
};

IceItem.propTypes = {
  icebreaker: PropTypes.object.isRequired,
};

export default IceItem;
