import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { icons } from '../../img/elements/icons';
import { FormattedMessage } from 'react-intl';
import Select, { components } from 'react-select';
import chroma from 'chroma-js';
import DatePicker from 'react-date-picker';
import Highlighter from "react-highlight-words";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
  }),
  input: () => ({
    backgroundColor: '#ffffff',
    fontSize: '12px',
    padding: '2px 0',
  }),
  control: styles => ({
    ...styles,
    backgroundColor: '#ffffff', 
    padding: '2px 0px',
    borderColor: 'transparent'
  }),
  placeholder: styles => ({
        ...styles,
        fontSize: '12px',
    }),
  menu: styles => ({
    ...styles,
    width: '150px', 
    textAlign: 'left',
  }),
  clearIndicator: styles => ({
    ...styles,
    padding: '0px', 
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: '0px', 
  }),
  singleValue: (provided, state) => {
    const fontSize = '12px';

    return { ...provided, fontSize };
  },
  valueContainer: styles => ({
    ...styles,
    background: 'transparent', 
    backgroundColor: 'transparent', 
  }),
}

const customStylesPriority = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      fontSize: '12px',
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  input: () => ({
    fontSize: '12px',
  }),
  control: styles => ({
    ...styles,
    padding: '2px 0px',
    borderColor: 'transparent'
  }),
  placeholder: styles => ({ ...styles, fontSize: '12px' }),
  menu: styles => ({
    ...styles,
    width: '150px', 
    textAlign: 'left',
  }),
  clearIndicator: styles => ({
    ...styles,
    padding: '0px', 
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: '0px', 
  }),
  singleValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
      fontSize: '12px',
      padding: '5px',
      borderRadius: '5px',
      color: data.color,
    };
  },
  valueContainer: styles => ({
    ...styles,
    background: 'transparent', 
    backgroundColor: 'transparent', 
  }),
  container: styles => ({
    ...styles,
    background: 'transparent', 
    backgroundColor: 'transparent', 
  }),
}

const indicatorSeparatorStyle = {
  alignSelf: 'stretch',
  backgroundColor: 'transparent',
  marginBottom: 8,
  marginTop: 8,
  width: 0,
};

const IndicatorSeparator = ({ innerProps }) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};

const TodoItem2Card = ({
  item,
  formData,
  usersOptions,
  handleSelect,
  handleComplete,
  index,
  user,
  handleDelete,
  handleNameForm,
  okrBoard,
  name,
  inputName,
  isTodo,
  isAssignee,
  isDueIn,
  clearTodo,
  handleChangeForm,
  handleChangeFormState,
  isForm,
}) => {

  let isCompleted = item.isCompleted;
  let dueIn = item.dueIn;
  let text = item.text;
  let _id = item._id;
  let isNew = item.isNew;
  let owner = item.owner;
  let creator = formData.creator.user;
  let participant = formData.participant.user;

  let inputValue = text;
  if (isTodo) { inputValue = item.name };

  const [isNameUpdate, setNameUpdate] = useState(false);
  const [inHover, setHover] = useState(false);

  if (dueIn === undefined) { dueIn = null };

  const handleMouseLeave = (e, _id) => {
    handleSelect(_id, e.target.value, name, index, inputName);

    setNameUpdate(!isNameUpdate);
  };

  const handleKeypress = (e, id) => {
    // it triggers by pressing the enter key
    if (e.key === 'Enter') {
      handleSelect(id, e.target.value, name, index, inputName);

      setNameUpdate(!isNameUpdate);
    }

    if (e.key === 27) {
      setNameUpdate(!isNameUpdate);
    }
  };

   return (
    <Fragment>
       <div
         onMouseEnter={() => setHover(true)}
         onMouseLeave={() => setHover(false)}
         className={`card-item card-team pb-xs flex align-center ${okrBoard && 'ml-1'}`}>
       <div
         className="flex space-between align-center pl-1 pr-2 relative w-95"
         >
         <div className={`${!okrBoard ? 'w-90' : 'w-50'} mr-2 class flex align-center w-100`}>
           <span
             onClick={e => handleComplete(e, _id, index, !isCompleted, name)}
             className="flex align-center"
           >
             {!isCompleted ? (icons.todo) : (icons.todo_done)}
           </span>
              {!isNameUpdate ? (
               <p
                 onClick={() => setNameUpdate(!isNameUpdate)}
                 className={`${isCompleted && 'grey old-price'} ${!okrBoard ? 'medium' : 'small'} card-subtitle cursor inline-block lh-30`}
               >
                 {inputValue}
               </p>
             ) : (
                 <FormattedMessage
                  id="select.task"
                  defaultMessage="Add task"
                >
                  {msg => (
                    <textarea
                      className={`w-100 medium card-subtitle brd-transparent brd_bottom_grey outline ${!isCompleted ? ('todo__item') : ('todo__completed')}`}
                      type="text"
                      rows={inputValue.length < 40 ? "1" : "2"}
                      required
                      value={inputValue || ''}
                      onChange={e =>
                        handleNameForm(e, index, _id, name, inputName)
                      }
                      onKeyPress={e => handleKeypress(e, _id)}
                      onMouseLeave={e => handleMouseLeave(e, _id)}
                      placeholder={msg}
                      name={inputValue}
                    />
                  )}
                </FormattedMessage>
            )}
             
          </div>

        <div className={`${!isTodo ? '0' : 'w-400px'} h-40 flex space-between align-center`}>

          {isAssignee && (
            <div className="mx-1 w-250px w-150px-l text-center">
            <FormattedMessage
              id="select.assignee"
              defaultMessage="Assignee..."
            >
              {msg => (
                <Select
                  className="small-input select__no-indicator"
                  components={{ IndicatorSeparator }}
                  defaultValue={formData.owner}
                  value={owner || ''}
                  onChange={isNew !== false ? (selectedOption => handleChangeForm(_id, selectedOption, index, 'owner', name)) : (selectedOption => handleChangeFormState(selectedOption, index, 'owner', name)) }
                  options={usersOptions}
                  isSearchable
                  isClearable
                  placeholder={msg}
                  name="owner"
                  styles={customStyles}
                  isMulti
                />
              )}
              </FormattedMessage>
          </div>)}


        {isDueIn && (
          <div className="mx-1 w-120px text-center">
            <DatePicker
              onChange={isNew !== false ? (selectedOption => handleChangeForm(_id, selectedOption, index, 'dueIn', name)) : (selectedOption => handleChangeFormState(selectedOption, index, 'dueIn', name)) }
              value={dueIn === null ? dueIn : new Date(dueIn)}
            />
          </div>)}
        </div>
      </div>

        <div className="w-30px">
          {inHover && (
            user && owner === user._id.toString() || user && creator === user._id.toString() || user && participant === user._id.toString() || user && user.role === "Admin" || user && user.role === "Owner" || user && user.role === "Superadmin" ? (
              <div className="btn-action no_btn ml-xs relative bg-transparent" type="button">
                {icons.more}
                <div className="dropdown p-1 light-shadow">
                  <button 
                      type="button"
                      onClick={isForm && !_id ? (() => clearTodo(index, name)) : (() => handleDelete(_id, index, name))}
                      // onClick={isForm ? (() => clearTodo(index, name)) : (() => handleDelete(_id, index, name))}
                      className="small secondary-font no_btn"
                    >
                      <i className="fas fa-times" />{' '}
                      <FormattedMessage
                        id="delete"
                        defaultMessage="Delete"
                      />
                    </button>
                </div>
            </div>
            ) : null
              )}
         </div>
      </div>
    </Fragment>
  );
};

TodoItem2Card.propTypes = {
  item: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(TodoItem2Card);
