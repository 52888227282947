import React, { useState, Fragment, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import MainMenu from '../layout/Sidebar';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import { getCompanyCustomAnswersById, clearCustomsAnswers, getAllUserCustomAnswersInData, 
  getStandupCompanyAnswers, getCompanyPlanAnswers, getCompanyUpdateAnswers,
  getCompanyCustomAndCheckinAnswers, changeCheckinFilters
 } from '../../actions/customAnswer';
import { getAllCustoms, clearCustoms, getCustomSettings, clearCustomSettings, 
  getStandupsSettings, getPlanSettings, getAllSettings } from '../../actions/custom';
import { getCompanyProfiles } from '../../actions/profile';
import { getUserCompany } from '../../actions/company';
import CustomItem from './CustomItem';
import NoContentNew from '../utils/NoContentNew';
import bellImg from '../../img/empty_states/bell@2x.png';
import searchImg from '../../img/empty_states/search@2x.png';
import checkinAdminImg from '../../img/empty_states/checkins1.png';
import checkinUserImg from '../../img/empty_states/checkins2.png';
import { copy } from '../../utils/wording/customs';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import Moment from 'react-moment';
import { menuTitle, menuItems, getCheckinsMenuNew } from '../../utils/menu/checkins';
import { Link } from 'react-router-dom';
import useDateFilter from '../utils/useDateFilter';
import { icons } from '../../img/elements/icons'
import NotAnsweredTable from '../dashboard/NotAnsweredTable';
import Upcoming from '../elems/UpcomingCheckins';
import { getCompanyAnswers } from '../../actions/standupAnswer';
import CheckinModal from '../utils/BottomModal';
import { modal } from '../../utils/wording/customs';
import { isVisible } from '../../utils/checkins';
import Loader from 'react-loader-spinner';

// const apiLimit = 50;

const customStyles = {
  option: (provided, state, isSelected) => ({
    ...provided,
    fontSize: '14px',
    backgroundColor: state.isSelected ? "#404ff5" : "white",
  }),
  input: () => ({
    fontSize: '14px',
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: '#ffffff',
    padding: '0px 8px',
    lineHeight: '20px',
    height: 36,
    minHeight: 36,
  }),
  placeholder: (styles) => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
};

const customStylesCampaign = {
  option: (provided, state, isSelected,  isDisabled, isFocused,) => ({
    ...provided,
    fontSize: '14px',
    backgroundColor: state.isSelected ? "#404ff5" : "white",
  }),
  input: () => ({
    fontSize: '18px',
    // height: '54px'
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
    padding: '0px 8px',
    lineHeight: '20px',
    // height: 36,
    minHeight: 36,
    borderColor: 'transparent',
    paddingLeft: '0'
  }),
  placeholder: (styles) => ({ ...styles, fontSize: '14px' }),
  singleValue: () => ({
    fontSize: '22px',
    fontWeight: '600',
    width: '95%',
  }),
  dropdownIndicator: base => ({
    ...base,
    color: "#000000" 
  })
};

let midnight = new Date().setHours(0,0,0,0);


// CheckinType: custom - 1, default daily checkin - 2, weekly plan - 3, weekly update - 4
 
const CustomNew = ({
  getUserCompany,
  getCustomSettings,
  getCompanyCustomAnswersById,
  getAllUserCustomAnswersInData,
  clearCustoms,
  clearCustomSettings,
  clearCustomsAnswers,
  getAllCustoms,
  getCompanyProfiles,
  auth: { user, sidebar },
  company: { company },
  customAnswer: { customAnswers, customAnswer, data, loading, isAnswersLoaded, isAllAnswersLoaded },
  profile: { profiles },
  custom: { campaigns, settings, allSettings, modalBox },
  match,
  locale,
  history,
  getStandupCompanyAnswers,
  getStandupsSettings,
  getPlanSettings,
  getCompanyPlanAnswers,
  getAllSettings,
  getCompanyUpdateAnswers,
  getCompanyCustomAndCheckinAnswers,
  checkinFilters,
  changeCheckinFilters,
}) => {
  const [formData, setFormData] = useState({
    team: '',
    answers: [],
    notes: '',
    notCompletedAnswers: [],
    firstDate: '',
    lastDate: '',
  });

  const [search, setSearch] = useState('');
  const [results, setResults] = useState({
    searchResults: false,
    searchKeyword: ''
  });

  const [menuData, setMenu] = useState({
    menu: {},
    menuIndex: [],
  });

  const [checkinType, setCheckinType] = useState(null);
  const [upcomingStatus, switchUpcoming] = useState(1);
  const [visibleCampaigns, setVisibleCampaigns] = useState([]);
  const [apiLimit, setApiLimit] = useState(50);
  const [localLoading, setLocalLoading] = useState(false);
  const [allCheckinLoaded, setAllCheckinLoading] = useState(true);

  const [usersAmount, setUsersAmount] = useState(0);

  let { menu, menuIndex } = menuData;
  let { team, answers, notes, notCompletedAnswers, firstDate, lastDate } = formData;
  let { searchResults, searchKeyword } = results;
  let teamOptions = [];
  let mentions = [];

  const [page, setPage] = useState({
    // currentPageCheckins: [],
    elementsPerPage: apiLimit,
    pagesCount: 1,
    offset: 0,
  });

  const { elementsPerPage, pagesCount, offset } = page;


  // Перебор profiles & departments и приведение данных к формату Селекта
  {
    profiles &&
      profiles.forEach((profile, index) => {
        let mention = {
          id: profile._id,
          display: `${profile.firstName} ${profile.lastName}`
        };
        mentions.push(mention);

        if (user && profile._id === user._id) return;

        let label =
          (profile && profile.departmentName === null) || (profile && profile.department === undefined)
            ? `${profile.firstName} ${profile.lastName}`
            : `${profile.firstName} ${profile.lastName} - [${profile.departmentName}]`;

        let coworker = {
          value: profile._id,
          label,
          type: 3
        };

        teamOptions.push(coworker);
      });
  }

  {
    company &&
      company.departments.length > 0 &&
      company.departments.forEach((department, index) => {
        if (user && user.department === null) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };

          teamOptions.unshift(team);
        } else if (
          user &&
          user.department !== null &&
          user.department !== undefined &&
          department._id !== user.department
        ) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };

          teamOptions.unshift(team);
        }

        // List loop
        if (user && index === company.departments.length - 1) {
          // You is third in list
          let person = {
            value: user._id,
            label:
              (user && user.departmentName === null) || (user && user.department === undefined)
                ? `${user.firstName} ${user.lastName}`
                : `${user.firstName} ${user.lastName} - [${user.departmentName}]`,
            type: 3
          };

          // teamOptions.unshift(person);

          // Second option is the team if exists
          if (user.department !== null && user.department !== undefined) {
            let team = { value: user.department, label: `${user.departmentName}`, type: 2 };
            teamOptions.unshift(team);
          }

          // Sort users alphabetically by first name
          teamOptions.sort((a, b) => a.label.localeCompare(b.label));

          // user
          teamOptions.unshift(person);

          // First option is the company
          let name = { value: company._id, label: `${company.name}`, type: 1 };
          teamOptions.unshift(name);
        }
      });
  }

  if (company && company.departments.length === 0 && user && profiles && profiles.length === 1) {
    teamOptions = [
      { value: company._id, label: `💪 ${company.name}`, type: 1 },
      { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 }
    ];
  } else if (company && company.departments.length === 0 && user && profiles && profiles.length > 1) {
    // if 0 teams and 1+ users
    // add user and company to others members that we found in the profiles loop
    let person = { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 };
    teamOptions.unshift(person);

    let team = { value: company._id, label: `💪 ${company.name}`, type: 1 };
    teamOptions.unshift(team);
  }

  // let choosedTeam = user ? { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 } : {};
  let choosedTeam = company ? { value: company._id, label: company.name, type: 1 } : {};

  const getMenuIndex = (menuArr) => {
    const index = menuArr.findIndex(({ url }) => url === `/campaigns/${match.params.id}`) + 1;
    return index > 0 ? index : 1;
  };

  const getCompanyCustomAnswersByIdStepped = useCallback((id)=>{
    getCompanyCustomAnswersById(id,30).then(()=>getCompanyCustomAnswersById(id))
  },[getCompanyCustomAnswersById])



  // Get users who don't post answers
  const getUnFinishedUsers = (profiles, answers, team) => {
 
    // Company mode by default
    let users = profiles;

    if (profiles && profiles.length > 0 && team && team.value) {
      if (team.type === 2) {
        // Team
        users = profiles.filter(item => item.department && item.department.toString() === team.value.toString());
      } else if (team.type === 3) {
        // User
        users = profiles.filter(item => item._id.toString() === team.value.toString());
      }
    }

    setUsersAmount(users.length);

    const unFinishedUsers = users.filter(({ _id: id1 }) => !answers.some(({ user: id2 }) => id2 && id2.toString() === id1.toString()));
    
    return unFinishedUsers;
  };

  const getVisibleCampaigns = (campaigns) => {
    return campaigns.filter(campaign => isVisible(campaign, user));
  };

  // console.log('========== NEW RENDER')
  // console.log('========== loading', loading)
  // console.log('========== campaigns', campaigns)
  // console.log('========== allSettings', allSettings)
  // console.log('========== settings', settings)

  const getCompanyAnswersStepped = useCallback((offset)=>{
    // getStandupCompanyAnswers({limit:30}).then(()=>getStandupCompanyAnswers())
    getStandupCompanyAnswers({limit: apiLimit}, offset)
  },[getStandupCompanyAnswers])

  const getCompanyPlanAnswersStepped = useCallback((offset)=>{ 
    // getCompanyPlanAnswers({limit:30, user:null}).then(()=>getCompanyPlanAnswers())
    getCompanyPlanAnswers({limit: apiLimit, user:null}, offset)
  },[getCompanyPlanAnswers])

  const getCompanyUpdateAnswersStepped = useCallback((offset)=>{ 
    // getCompanyUpdateAnswers({limit:30, user:null}).then(()=>getCompanyUpdateAnswers())
    getCompanyUpdateAnswers({limit: apiLimit, user:null}, offset)
  },[getCompanyUpdateAnswers])

  const getCompanyAllAnswersStepped = useCallback(async (offset)=>{ 
    getCompanyCustomAndCheckinAnswers({limit: apiLimit, user:null}, offset)

    // console.log('====== company in LOADER', company)
    // console.log('====== company && new Date(company.date) < oldCompaniesDate in LOADER', company && new Date(company.date) < oldCompaniesDate)

    getStandupCompanyAnswers({limit: apiLimit}, offset)
    getCompanyPlanAnswers({limit: apiLimit, user:null}, offset)
    let res = await getCompanyUpdateAnswers({limit: apiLimit, user:null}, offset)
    
    // console.log('====== res in LOADER', res)

    if (res && res.ok) {
      setAllCheckinLoading(true)
    }

  },[getCompanyUpdateAnswers])

  // console.log('====== customAnswers', customAnswers)
  // console.log('====== checkinType', checkinType)

  useEffect(() => {
    // console.log('==== USEEFFECT 1 WORKS')

    getUserCompany();
    getCompanyProfiles();
    getAllCustoms();
    getAllUserCustomAnswersInData();
    getAllSettings();
   
  }, [
    getUserCompany,
    getCompanyProfiles,
  ]);

  useEffect(() => {
    // console.log('==== USEEFFECT 9 WORKS: match')
    if (customAnswers && customAnswers.length > 0) {
      setPage({ 
        ...page, 
        offset: 0,
        pagesCount: 1
      });

      clearCustomsAnswers();
    }
    
    // getAllCustoms();
    // getAllUserCustomAnswersInData();
    // getAllSettings();

    if (match.params.id !== 'checkins' && match.params.id !== 'plans' && match.params.id !== 'updates' && match.params.id !== 'all-checkins') { 
      
      getCompanyCustomAnswersByIdStepped(match.params.id);
      getCustomSettings(match.params.id);
      setCheckinType(1)

    } else if (match.params.id === 'all-checkins') {
      setAllCheckinLoading(false) // turn on loader to get all data

      getCompanyAllAnswersStepped(0);
      setCheckinType(1)
    } else if (match.params.id === 'checkins') {
      getCompanyAnswersStepped(0);
      setCheckinType(2)
      getStandupsSettings();
      // console.log('====== calling getStandupsSettings')

    } else if (match.params.id === 'plans') {
      getCompanyPlanAnswersStepped(0);
      setCheckinType(3)
      getPlanSettings()

    } else if (match.params.id === 'updates') {
      getCompanyUpdateAnswersStepped(0);
      setCheckinType(4)
    } 

  }, [match && match.params && match.params.id]);

  // console.log('==== checkinType on top', checkinType)

  useEffect(()=>{
    // console.log('==== USEEFFECT 2 WORKS')

    // if (!customAnswers || customAnswers.length === 0 || customAnswers.length !== answers.length) {
    if (!customAnswers || customAnswers.length === 0 || customAnswers.length > 0) {
      // console.log('==== USEEFFECT saving new date was: answers.length', answers.length)

      let sorted = customAnswers && customAnswers.length > 0 ? customAnswers.sort((a,b) => new Date(b.date) - new Date(a.date)) : [];
      
      setFormData({
        ...formData,
        team: !checkinFilters.team ? choosedTeam : checkinFilters.team,
        answers: !customAnswers || customAnswers === undefined ? [] : customAnswers.slice(0, elementsPerPage*pagesCount+1),
        answers: !customAnswers || customAnswers === undefined ? [] : sorted.slice(0, elementsPerPage*pagesCount+1),
        notCompletedAnswers: !customAnswers && !profiles || customAnswers === undefined ? [] : getUnFinishedUsers(profiles, customAnswers.filter(item => Date.parse(item.date) > midnight), choosedTeam),
        lastDate: customAnswers && customAnswers.length > 0 ? customAnswers[0].date : null,
        firstDate: customAnswers && customAnswers.length > 0 ? customAnswers[customAnswers.length - 1].date : null,
      });

    }

    const newMenu = user && campaigns && company ? getCheckinsMenuNew(campaigns, company, user, locale.lang) : { menuArr: [menuItems[0]], hiddenCheckins: [] };
    
    setMenu({
      menu: newMenu,
      menuIndex: getMenuIndex(newMenu.menuArr),
    });

    setVisibleCampaigns(user && campaigns && campaigns.length > 0 ? getVisibleCampaigns(campaigns) : []);
  },[
    user, 
    customAnswers, 
    campaigns,
  ]);

  // ПОСЛЕДНИЙ АПДЕЙТ: я убрал  JSON.stringify(customAnswers), так как при комментировании другого юзера происходит возврат автоматически к данным auth.user
  // так как здесь мы загружаем team: !user ? '' : choosedTeam
  // ПОЭТОМУ вернул все назад - скрыл JSON.stringify(customAnswers) и включил getCompanyCustomAnswersById и customAnswers && customAnswers.length
  // ИДЕАЛЬНОЕ РЕШЕНИЕ: здесь скрыть getCompanyCustomAnswersById и customAnswers && customAnswers.length И
  // сделать отдельный useEffect, где отслеживать JSON.stringify(customAnswers) и менять только answers и notes

  const handleTeam = async (selectedOption) => {
    let newUpdates;
    let newNotCompletedItems = [];

    if (selectedOption.type === 1) {
      newUpdates = customAnswers.filter((answer) => answer.company === selectedOption.value);
    } else if (selectedOption.type === 2) {
      newUpdates = customAnswers.filter((answer) => answer.department === selectedOption.value);
    } else if (selectedOption.type === 3) {
      newUpdates = customAnswers.filter((answer) => answer.user === selectedOption.value);
    }

    newNotCompletedItems = await getUnFinishedUsers(profiles, newUpdates, selectedOption);

    setFormData({
      ...formData,
      team: selectedOption,
      answers: newUpdates,
      notCompletedAnswers: newNotCompletedItems
    });
  };

  const handleCheckin = (selectedOption) => {
    setCheckinType(selectedOption.checkinType)
    history.push(selectedOption.url);

  };

  const noCustomsMessageTeam = {
    title: <FormattedMessage id="noanswers" defaultMessage="No Answers" />,
    // msg: <FormattedMessage id="noanswerws.msg" defaultMessage={team && team.label !== undefined ? `${team.label} hasn't checked in yet.` : 'No answers'} />
    msg: <FormattedMessage id="noanswerws.msg" defaultMessage={team && team.label !== undefined ? `doesn't have check-ins` : 'No answers'} />,
    msgYou: <FormattedMessage id="noanswerws.msg.you" defaultMessage={`You don't have check-ins`} />,
  };
  

  const noCustomsMsgNoContentAdmin = {
    title: <FormattedMessage id="checkins.empty.title" defaultMessage="Check-ins can help you run effective meetings." />,
    msg: <FormattedMessage id="checkins.empty.msg" defaultMessage="Replace lengthy meetings with efficient online check-ins. Start by creating your first check-in." />,
    btnUrl: '/settings/campaign/new',
    btnCta: <FormattedMessage id="create.new.checkin" defaultMessage="Create New Check-in" />,
  };

  let fromTxt = <FormattedMessage id="from" defaultMessage="from" />
  let toTxt = <FormattedMessage id="to" defaultMessage="to" />
  let loadTxt = <FormattedMessage id="load.more" defaultMessage="Load more" />
  let settingsTxt = <FormattedMessage id="checkin.settings" defaultMessage="Check-in Settings" />

  const noCustomsMsgNoContentMember = {
    title: <FormattedMessage id="no.checkins.user.title" defaultMessage="You have no new check-ins ..." />,
    msg: <FormattedMessage id="no.checkins.user.msg" defaultMessage="Remote or distributed teams may benefit from more frequent check-ins to keep everyone on the same page. Once your team members answer the check-in, their answers will be displayed here." />,
    btnUrl: '/settings/campaign/new',
    btnCta: <FormattedMessage id="create.new.checkin" defaultMessage="Create New Check-in" />,
  };

  const noSearchResults = {
    title: <FormattedMessage id="search.noresults" defaultMessage="No Results Found" />,
    msg: (
      <FormattedMessage
        id="company.checkins.user.msg"
        // defaultMessage={`Sorry, we couldn't find any results matching <b>"${searchKeyword}"</b>`}
        defaultMessage={`No search results for <b>"${searchKeyword}"</b>`}
        values={{ b: (...chunks) => <b>{chunks}</b> }}
      />
    ),
    msg2: (
      <FormattedMessage
        id="try.diff.search"
        defaultMessage="Try searching a different keyword."
      />
    ),
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    let itemArr = [];

    if (team.type === 1) {
      // Company
      itemArr = customAnswers.filter((answer) => answer.company === team.value);
    } else if (team.type === 2) {
      // Team
      itemArr = customAnswers.filter((answer) => answer.department === team.value);
    } else if (team.type === 3) {
      // USer
      itemArr = customAnswers.filter((answer) => answer.user === team.value);
    }

    let filteredItems = [];

    if (e.target.value.length !== 0) {
      // If search input is not empty

      itemArr.forEach((item) => {
        let answers = item.answers;
        let isUniqueItem = true;

        answers.forEach((answer) => {
          answer.text.forEach((text) => {
            if (!isUniqueItem) {
              return;
            }

            if (text.toLowerCase().includes(e.target.value.toLowerCase()) === true) {
              isUniqueItem = false;
              return filteredItems.push(item);
            }
          });
        });
      });

      setFormData({
        ...formData,
        answers: filteredItems
      });

      // Check if we have search results or not
      if (filteredItems.length !== 0) {
        setResults({
          ...results,
          searchResults: false,
          searchKeyword: e.target.value // change to highlight search results
        });
      } else {
        // If we don't have search results
        setResults({
          ...results,
          searchResults: true,
          searchKeyword: e.target.value
        });
      }
    } else {
      // Empty search input
      setFormData({
        ...formData,
        answers: itemArr
      });

      setResults({
        ...results,
        searchResults: false,
        searchKeyword: ''
      });
    }
  };

  // Removing duplicates in answers array based on the object key 'plan' - to send only unique update items to UpdateTeamItem
  // Берем данные из стейта answers, а не редакса planAnswers, чтобы делать фильтр на странице
  let customAnswersUnique = useMemo(
    () =>
      answers
        ? answers.reduce((acc, current) => {
            // const x = acc.find((item) => item.customID === current.customID);

            // let hiddenCheckinIds = menu && menu.hiddenCheckins && menu.hiddenCheckins.length > 0 ? menu.hiddenCheckins.map(a => a.value) : [];
            // console.log('====== hiddenCheckinIds', hiddenCheckinIds);
            // console.log('====== acc in customAnswersUnique', acc);
            // console.log('====== current', current);

            const x = checkinType === 1 || checkinType === 5 ? acc.find((item) => item.customID === current.customID) 
            // const x = checkinType === 1 || checkinType === 5 ? acc.find((item) => item.customID === current.customID && hiddenCheckinIds.indexOf(item.custom) === -1) 
            : checkinType === 2 ? acc.find((item) => item.standupID === current.standupID) 
            : checkinType === 3 ? acc.find((item) => item.planID === current.planID) 
            : checkinType === 4 && acc.find((item) => item.updateID === current.updateID);
            
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, [])
        : [],
    [answers]
  );

  // customAnswersUnique = answers;

  // console.log('====== checkinType', checkinType);
  // console.log('====== campaigns', campaigns);

  // Send answers not customAnswersUnique. Answers array from State are unique answers atm.
  const [customAnswersUniqueFiltered, timeFilterOptions, timeFilterSelectedOption, timeFilterChangeOption, teamFilterOptions, teamFilterSelectedOption, teamFilterChangeOption] =
    // useDateFilter(customAnswersUnique, (item) => item.date, locale && locale.lang, profiles, company, user, menu);
    useDateFilter(answers, (item) => item.date, locale && locale.lang, profiles, company, user, menu, checkinFilters);  
  
  // console.log('====== answers arr', answers);
  // console.log('====== customAnswers', customAnswers);
  // console.log('====== customAnswersUniqueFiltered', customAnswersUniqueFiltered);
  // console.log('====== customAnswersUniqueFiltered.length', customAnswersUniqueFiltered.length);
  // console.log('====== teamFilterSelectedOption', teamFilterSelectedOption);
  // console.log('====== timeFilterSelectedOption', timeFilterSelectedOption);

  useEffect(() => {
    // console.log('==== USEEFFECT 4 WORKS: clear')

    // Clear checkinType
    return () => {
      clearCustomsAnswers();
    };
  }, []);

  useEffect(() => {
    // console.log('==== USEEFFECT 77 WORKS: filters')

    // console.log('==== USEEFFECT 77 WORKS: teamFilterSelectedOption', teamFilterSelectedOption)
    // user ? console.log('==== USEEFFECT 77 WORKS: user.company', user.company) : console.log('==== USEEFFECT 77 WORKS: user', user)
    changeCheckinFilters(teamFilterSelectedOption, timeFilterSelectedOption, user ? user.company : user);
    
  }, [teamFilterSelectedOption, timeFilterSelectedOption]);

  useEffect(() => {
    if (checkinFilters.team && teamFilterSelectedOption && checkinFilters.team.value != teamFilterSelectedOption.value) {
      teamFilterChangeOption(checkinFilters.team);
    }
  }, [checkinFilters.team]);

  // console.log('===== teamFilterSelectedOption', teamFilterSelectedOption)
  // console.log('===== timeFilterSelectedOption', timeFilterSelectedOption)
  // console.log('===== checkinFilters', checkinFilters)

  const handleMoreBtn = async (limitType = 0) => {
    // console.log('====== offset in handleMoreBtn', offset)
    // console.log('====== pagesCount in handleMoreBtn', pagesCount)
    setLocalLoading(true)

    let currentPage = pagesCount + 1;
    let newOffset = offset + elementsPerPage;

    let multiplier = 0;

    if (limitType === 100 && apiLimit !== 150) {
      multiplier = 100;
      setApiLimit(apiLimit+limitType)
    }

    // Set data from state
    setPage({ 
      ...page, 
      offset: newOffset,
      pagesCount: currentPage,
      elementsPerPage: apiLimit+multiplier
    });
    // console.log('====== newOffset', newOffset);

    if (checkinType === 1) { 
      getCompanyAllAnswersStepped(newOffset)
    } else if (checkinType === 2) {
      getCompanyAnswersStepped(newOffset) 
    } else if (checkinType === 3) {
      getCompanyPlanAnswersStepped(newOffset) 
      
    } else if (checkinType === 4) {
      getCompanyUpdateAnswersStepped(newOffset) 
    }

      setLocalLoading(false)
  }
  // console.log('====== pagesCount', pagesCount);
  // console.log('====== isAnswersLoaded', isAnswersLoaded);

  const createCheckin = <FormattedMessage id="create.checkin" defaultMessage="Create New Check-in" />;
  const checkinStatusTxt = <FormattedMessage id="checkin.status" defaultMessage="Check-in Status" />;
  const upcomingCheckingTxt = <FormattedMessage id="Upcoming.checkin" defaultMessage="Upcoming Check-ins" />;
  const chooseCheckinTxt = <FormattedMessage id="choose.checkin.cta" defaultMessage="Please choose check-in" />;
  const checkinsSettings = <FormattedMessage id="checkin.settings" defaultMessage="Check-in Settings" />;
  const noAccess = <FormattedMessage id="checkin.noaccess" defaultMessage="You don't have access to this check-in" />;

  useEffect(() => {
    // console.log('==== USEEFFECT 3 WORKS')
    setFormData({
      ...formData,
      notCompletedAnswers: getUnFinishedUsers(profiles, customAnswersUniqueFiltered, team),
      team: teamFilterSelectedOption,
    });
   
  }, [timeFilterSelectedOption, teamFilterSelectedOption]);

  // console.log('====== notCompletedAnswers', notCompletedAnswers);
  // menu && console.log('====== menu && menu.hiddenCheckins', menu.hiddenCheckins);

  const getCheckins = (answers, customAnswerUnique) => {
    let arr = [];
  // console.log('====== getCheckins is starting for answers.length', answers.length);
  // console.log('====== getCheckins is starting for customAnswerUnique', customAnswerUnique);
  // console.log('====== getCheckins checkinTypeh', checkinType);

    if (checkinType === 1) {
      // Custom
      arr = answers.filter((customAnswer) => {
        
        // Visibility: if the answers from hiddenCheckins, then don't save it
        let hiddenCheckins = menu && menu.hiddenCheckins ? menu.hiddenCheckins : [];
        // console.log('======== hiddenCheckins in getCheckins', hiddenCheckins)

        if (hiddenCheckins.length > 0) {
          // console.log('======== customAnswer in getCheckins', customAnswer)
          
          let hiddenAnswers = hiddenCheckins.filter(c => c.value === customAnswer.custom)
          if (hiddenAnswers.length > 0) { return }
        }

       if (customAnswer && customAnswer.customID && customAnswerUnique && customAnswerUnique.customID && customAnswer.customID.toString() === customAnswerUnique.customID.toString()) {
        // console.log('======== returning customAnswer in arr', customAnswer)

        return customAnswer;
       }
      //  console.log('======== WE DO NOT add this customAnswer to arr', customAnswer)
      })
    } else if (checkinType === 2) {
    // Standups
      arr = answers.length > 0 && answers.filter((customAnswer) =>
      customAnswer && customAnswer.standupID && customAnswerUnique && customAnswerUnique.standupID && customAnswer.standupID.toString() === customAnswerUnique.standupID.toString()
      )
    } else if (checkinType === 3) {
      // Plan
      arr = answers.filter((customAnswer) =>
      customAnswer && customAnswer.planID && customAnswerUnique && customAnswerUnique.planID && customAnswer.planID.toString() === customAnswerUnique.planID.toString()
      )
    } else if (checkinType === 4) {
      // Update
      arr = answers.filter((customAnswer) =>
      customAnswer && customAnswer.updateID && customAnswerUnique && customAnswerUnique.updateID && customAnswer.updateID.toString() === customAnswerUnique.updateID.toString()
      )
    }
    // console.log('====== getCheckins END for arr.length', arr.length);

    return arr;
  };

  // console.log('==== allCheckinLoaded', allCheckinLoaded)
  // console.log('==== isAnswersLoaded', isAnswersLoaded)
  // console.log('==== isAllAnswersLoaded', isAllAnswersLoaded)
  // console.log('===== teamFilterSelectedOption', teamFilterSelectedOption)
  // console.log('===== timeFilterSelectedOption', timeFilterSelectedOption)

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className={`wrapper ${sidebar === 2 ? 'short__sidebar' : ''}`}>

          {user && !loading && (
            <MainMenu activeIndex={2} />
          )}

          <UserNav />

          <Fragment>
            {loading || !customAnswers || !user || !company ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className={`${sidebar === 1 ? 'ml-280 main main-dashboard ' : 'short_menu__ml__okrs ma-w90'} mt-3`}>
                  {user && user.isAdmin && campaigns.length === 0 && allSettings.length === 0 && (
                    <CheckinModal 
                      title={modal.title} 
                      text={modal.text} 
                      btnTxt={modal.btnTxt} 
                      btnLink={modal.btnLink}
                      cancelTxt={modal.cancelTxt}
                      modalBox={modalBox}
                    />
                  )}

                  <div className="plans">
                    <div className="page-title mb-xs">
                      <h5 className="pl-0-xs fw-600">{menuTitle}</h5>
                    </div>
                    <div className="pb-3">
                      <div className="sub-menu__page"></div>
                    </div>

                    <div className="checkins__body flex align-start mt-1">
                      <div className={`${company.plan !== 0 || company.plan === 1 && profiles.length <= process.env.REACT_APP_FREE_PLAN_USERS ? 'checkins__content' : 'w-100 mxa'}`}>
                        <div className="insight">
                          <div className="flex align-center flex-column-xs">
                            {company.plan !== 0 || company.plan === 1 && profiles.length <= process.env.REACT_APP_FREE_PLAN_USERS ? (
                              <div className="checkins__filters flex align-center w-100-xs">
                                {menu && menu.menuArr && menu.menuArr.length > 0 && (
                                  <FormattedMessage
                                    id="choose.checkin"
                                    defaultMessage="Choose check-in"
                                  >
                                  {(msg) => (
                                    <Select
                                      className="checkins__filters__name small-input w-50-xs mr-1"
                                      defaultValue={menu.menuArr[0]}
                                      value={menu.menuArr[menuIndex-1]}
                                      onChange={(selectedOption) => handleCheckin(selectedOption)}
                                      options={menu.menuArr}
                                      placeholder={msg}
                                      name="checkin"
                                      styles={customStylesCampaign}
                                      components={{
                                        IndicatorSeparator: () => null
                                      }}
                                    />
                                  )}
                                </FormattedMessage>
                                )}

                                <FormattedMessage
                                  id="checkins.sub_menu.teamSelect"
                                  defaultMessage="Choose the team or user"
                                >
                                  {(msg) => (
                                    <Select
                                      className="checkins__filters__items small-input w-50-xs mr-1 mla"
                                      defaultValue={choosedTeam}
                                      value={teamFilterSelectedOption}
                                      onChange={teamFilterChangeOption}
                                      options={teamFilterOptions}
                                      isSearchable
                                      placeholder={msg}
                                      required
                                      name="team"
                                      styles={customStyles}
                                    />
                                  )}
                                </FormattedMessage>

                                <FormattedMessage
                                  id="checkins.sub_menu.timeSelect"
                                  defaultMessage="Choose the time period"
                                >
                                  {(msg) => (
                                    <Select
                                      className="checkins__filters__time small-input w-50-xs mr-1"
                                      defaultValue={choosedTeam}
                                      value={timeFilterSelectedOption}
                                      onChange={timeFilterChangeOption}
                                      options={timeFilterOptions}
                                      isSearchable
                                      placeholder={msg}
                                      required
                                      name="time"
                                      styles={customStyles}
                                    />
                                  )}
                                </FormattedMessage>

                                <FormattedMessage id="search.bar" defaultMessage="Search...">
                                  {(msg) => (
                                    <input
                                      className="checkins__filters__search w-100px-xxl w-50-xs question-input mla-xs pl-2 br-4 brd brd-grey bg__white outline mla lh-34"
                                      type="text"
                                      placeholder={msg}
                                      name="search"
                                      value={search}
                                      onChange={(e) => handleSearch(e)}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>
                            ) : 
                            null
                            }
                          </div>
                        </div>

                        {!isAnswersLoaded && checkinType !== 1 || checkinType === 1 && !allCheckinLoaded && !isAnswersLoaded && !isAllAnswersLoaded ? (
                          <div className="my-2 text-center">
                            <Loader type="Circles" color="#99a2ff" height="20" width="20" />
                          </div>
                        ) : null}

                        {company.plan !== 0 || company.plan === 1 && profiles.length <= process.env.REACT_APP_FREE_PLAN_USERS ? (
                          <Fragment>
                            {customAnswers.length === 0 ? (
                              <Fragment>
                                {campaigns.length > 0 ? (
                                  <Fragment>
                                    {team.value !== user._id.toString() ? (
                                      <Fragment>
                                        {isAnswersLoaded && (
                                          <div className='flex mt-2 align-center'>
                                            <img src={bellImg} className="mr-1" width="47" alt={"icon"} />
                                            <p className="fs-25 fw-600 mt-2">{team.label}{" "}{noCustomsMessageTeam.msg}{" "}{lastDate && lastDate !== '' ? (
                                              <Fragment>
                                                {fromTxt}{" "}{<Moment format="D MMM">{firstDate}</Moment>}{" "}{toTxt}{" "}{<Moment format="D MMM">{lastDate}</Moment>}
                                              </Fragment>
                                              ) : null}
                                              </p>
                                          </div>
                                        )}
                                      </Fragment>
                                    ) : (
                                      <div className="mt-2">
                                          {isAnswersLoaded && allCheckinLoaded && (
                                            <NoContentNew
                                              message={noCustomsMsgNoContentMember}
                                              picture={checkinUserImg}
                                              alt={'Create Check-in'}
                                              imgWidth={'w-100 mb-0 pb-0'}
                                              divStyles="flex-column"
                                              contentStyles="text-left"
                                            />
                                          )}
                                      </div>
                                    )}
                                  </Fragment>
                                ) : (
                                  // <p className="fs-14 mt-2">{chooseCheckinTxt}</p>
                                  <Fragment>
                                    <div className="mt-2">
                                      {user && user.isAdmin ? (
                                        <Fragment>
                                          {menu && menu.menuArr && menu.menuArr.length > 1 ? (
                                            // isAnswersLoaded && <p className="fs-14 mt-2">{chooseCheckinTxt}</p>
                                            isAnswersLoaded && allCheckinLoaded && <p className="fs-14 mt-2">{chooseCheckinTxt}</p>
                                          ) : (
                                            <NoContentNew
                                              message={noCustomsMsgNoContentAdmin}
                                              picture={checkinAdminImg}
                                              alt={'Create first Check-in'}
                                              imgWidth={'w-100 -mb-30px'}
                                              button={1}
                                              btnUrl={noCustomsMsgNoContentAdmin.btnUrl}
                                              btnCta={noCustomsMsgNoContentAdmin.btnCta}
                                              divStyles="flex-column"
                                            />
                                          )}
                                        </Fragment>
                                      ) : (
                                        <Fragment>
                                          {menu && menu.menuArr && menu.menuArr.length > 1 ? (
                                            <p className="fs-14 mt-2">{chooseCheckinTxt}</p>
                                          ) : (
                                            <Fragment>
                                              <NoContentNew
                                                message={noCustomsMsgNoContentMember}
                                                picture={checkinUserImg}
                                                alt={'Create Check-in'}
                                                imgWidth={'w-100 mb-0 pb-0'}
                                                divStyles="flex-column"
                                                contentStyles="text-left"
                                              />
                                            </Fragment>
                                          )}
                                        </Fragment>
                                      )}
                                    </div>
                                  </Fragment>
                                )}
                              </Fragment>
                            ) : (
                              <Fragment>
                                {isVisible(settings, user) ? (
                                  <div className="mt-2 pt-xs">
                                    {customAnswers.length > 0 && customAnswersUniqueFiltered.length > 0 ? (
                                      <Fragment>
                                        {customAnswersUniqueFiltered.map((customAnswerUnique, index) => (
                                          <Fragment key={`fragment-custom-${index}`}>
                                            <CustomItem
                                              key={`customs-${customAnswerUnique._id}-${index}`}
                                              // answers={getCheckins(answers,customAnswerUnique)}
                                              answers={[customAnswerUnique]}
                                              formData={formData}
                                              setFormData={setFormData}
                                              searchKeyword={searchKeyword.length > 0 && searchKeyword}
                                              mentions={mentions}
                                              checkinType={checkinType}
                                              allSettings={allSettings}
                                              lang={locale ? locale.lang : false}
                                            />
                                          </Fragment>
                                        ))}
                                        {isAnswersLoaded && !isAllAnswersLoaded && allCheckinLoaded && (
                                          <div className="w-150px h-34 br-4 brd brd-grey__light bg__white relative text-center mxa">
                                            <p className='flex align-center w-100 icon__box text-center lh-34 h-34 justify-center cursor' onClick={() => handleMoreBtn()}>{loadTxt}</p>
                                          </div>
                                        )}
                                      </Fragment>
                                    ) : (
                                      <div className="mt-2">
                                        {!searchResults ? (
                                          team.value !== user._id.toString() ? (
                                            <Fragment>
                                              {isAnswersLoaded && allCheckinLoaded && (
                                                <Fragment>
                                                  <div className='flex mt-2 align-center'>
                                                    <img src={bellImg} className="mr-1" width="47" alt={"icon"} />
                                                    <p className="fs-25 fw-600 mt-2">{team.label}{" "}{noCustomsMessageTeam.msg}{" "}{lastDate && lastDate !== '' && !isAllAnswersLoaded ? (
                                                      <Fragment>
                                                        {fromTxt}{" "}{<Moment format="D MMM">{firstDate}</Moment>}{" "}{toTxt}{" "}{<Moment format="D MMM">{lastDate}</Moment>}
                                                      </Fragment>
                                                      ) : null}
                                                    </p>
                                                  </div>
                                                  {!isAllAnswersLoaded && (
                                                    <div className="w-150px h-34 br-4 brd brd-grey__light bg__white relative text-center mt-2 mxa">
                                                      <p className='flex align-center w-100 icon__box text-center lh-34 h-34 justify-center cursor' onClick={() => handleMoreBtn()}>{loadTxt}</p>
                                                    </div>
                                                  )}
                                                </Fragment>
                                              )}
                                            </Fragment>
                                          ) : (
                                            <Fragment>
                                              <div className="mt-3">
                                                {isAnswersLoaded && !isAllAnswersLoaded && allCheckinLoaded ? (
                                                  <Fragment>
                                                    <div className='flex mt- align-center'>
                                                      <img src={bellImg} className="mr-1" width="47" alt={"icon"} />
                                                      <p className="fs-25 fw-600">{noCustomsMessageTeam.msgYou}{" "}{lastDate && lastDate !== '' && !isAllAnswersLoaded ? (
                                                        <Fragment>
                                                          {fromTxt}{" "}{<Moment format="D MMM">{firstDate}</Moment>}{" "}{toTxt}{" "}{<Moment format="D MMM">{lastDate}</Moment>}
                                                        </Fragment>
                                                        ) : null}
                                                      </p>
                                                    </div>
                                                    <div className="w-150px h-34 br-4 brd brd-grey__light bg__white relative text-center mt-2 mxa">
                                                      <p className='flex align-center w-100 icon__box text-center lh-34 h-34 justify-center cursor' onClick={() => handleMoreBtn(100)}>{loadTxt}</p>
                                                    </div>

                                                    {localLoading && (
                                                      <div className="my-2 text-center">
                                                        <Loader type="Circles" color="#99a2ff" height="20" width="20" />
                                                      </div>
                                                    )}
                                                </Fragment>
                                                ) : (
                                                  <Fragment>
                                                    {isAnswersLoaded && isAllAnswersLoaded && allCheckinLoaded && (
                                                      <NoContentNew
                                                        message={noCustomsMsgNoContentMember}
                                                        picture={checkinUserImg}
                                                        alt={'Create Check-in'}
                                                        imgWidth={'w-100 mb-0 pb-0'}
                                                        divStyles="flex-column"
                                                        contentStyles="text-left"
                                                      />
                                                    )}
                                                  </Fragment>
                                                )}
                                              </div>
                                            </Fragment>
                                          )
                                        ) : (
                                          <Fragment>
                                            <div className="flex align-center">
                                              <img src={searchImg} className="mr-2 ml-2 transform-50" width="47" alt={"icon"} />
                                              <p className="fs-25 fw-600">{noSearchResults.msg}</p>
                                            </div>
                                            <p className="fs-16 ml-5 pl-4">{noSearchResults.msg2}</p>
                                          </Fragment>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div className="mt-2 pt-xs">
                                    <p className="fs-16">{noAccess}</p>
                                  </div>
                                )
                                }
                              </Fragment>
                            )}
                          </Fragment>
                        ) : (
                          <NoContentNew
                            message={copy.updatePlanMsg}
                            picture={checkinAdminImg}
                            alt={'Update plan'}
                            imgWidth={'w-100 pb-0 mb-0'}
                            admin={
                              user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0
                            }
                            divStyles="flex-column"
                          />
                        )}
                      </div>

                      {company.plan !== 0 || company.plan === 1 && profiles.length <= process.env.REACT_APP_FREE_PLAN_USERS ? (
                        <div className="checkins__status">
                          <div className="flex flex-column">
                            {user && user.isAdmin && (
                              <div className="flex align-center mb-3">
                                <div className="btn btn__create__checkin fs-14 px-15 text-center mr-2 py-0 h-34">
                                  <Link to={'/settings/campaign/new'} className={'fs-14 white mxa lh-34'}>
                                    {createCheckin}
                                  </Link>
                                </div>
                                <div 
                                  className="btn__checkin px-1 fs-14 py-0 flex align-center"
                                >
                                  <Link to={'/settings'} className="btn__checkin__settings flex align-center">
                                    {icons.settings_slider}
                                    <span className="btn__checkin__settings__text pl-1 fs-14 fw-600">{settingsTxt}</span>
                                  </Link>
                                </div>
                              </div>
                            )}
                            <div className="checkins__status__body flex flex-column">
                              <div className="flex align-center mb-3">
                                <div 
                                  className={`sub-menu__item inline-block mr-2 cursor ${upcomingStatus === 1 ? 'sub-menu__item_black' : ''}`}
                                  onClick={() => switchUpcoming(1)}
                                >
                                  {upcomingCheckingTxt}
                                  </div>
                                <div 
                                  className={`sub-menu__item inline-block mr-2 cursor ${upcomingStatus === 2 ? 'sub-menu__item_black' : ''}`}
                                  onClick={() => switchUpcoming(2)}
                                >
                                  {checkinStatusTxt}
                                  </div>
                              </div>
                              
                              <div className="flex">
                                {upcomingStatus === 1 ? (
                                  <Upcoming
                                    checkins={visibleCampaigns}
                                    oldCheckins={allSettings}
                                    answers={data}
                                    type={4}
                                    styles='w-450px'
                                    checkinType={checkinType}
                                  />
                                ) : (
                                  <div className="no-answers">
                                    <NotAnsweredTable
                                      answers={notCompletedAnswers}
                                      linkName={''}
                                      standupAnswers={customAnswers}
                                      type={4}
                                      styles='w-450px'
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

CustomNew.propTypes = {
  clearCustoms: PropTypes.func.isRequired,
  clearCustomsAnswers: PropTypes.func.isRequired,
  getAllCustoms: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  getCompanyCustomAnswersById: PropTypes.func.isRequired,
  clearCustomSettings: PropTypes.func.isRequired,
  getAllUserCustomAnswersInData: PropTypes.func.isRequired,
  getStandupCompanyAnswers: PropTypes.func.isRequired,
  getCompanyAnswers: PropTypes.func.isRequired,
  getCompanyPlanAnswers: PropTypes.func.isRequired,
  getAllSettings: PropTypes.func.isRequired,
  getPlanSettings: PropTypes.func.isRequired,
  getCustomSettings: PropTypes.func.isRequired,
  getStandupsSettings: PropTypes.func.isRequired,
  getCompanyUpdateAnswers: PropTypes.func.isRequired,
  getCompanyCustomAndCheckinAnswers: PropTypes.func.isRequired,
  changeCheckinFilters: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  customAnswer: PropTypes.object.isRequired,
  custom: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  customAnswer: state.customAnswer,
  checkinFilters: state.customAnswer.filters,
  custom: state.custom,
  locale: state.locale
});

export default connect(mapStateToProps, {
  getUserCompany,
  getCompanyProfiles,
  getCompanyCustomAnswersById,
  clearCustoms,
  getAllCustoms,
  clearCustomsAnswers,
  getCustomSettings,
  clearCustomSettings,
  getAllUserCustomAnswersInData,
  getCompanyAnswers,
  getStandupCompanyAnswers,
  getStandupsSettings,
  getCompanyPlanAnswers,
  getPlanSettings,
  getAllSettings,
  getCompanyUpdateAnswers,
  getCompanyCustomAndCheckinAnswers,
  changeCheckinFilters,
})(CustomNew);
