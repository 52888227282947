import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Menu from '../layout/Menu';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import UpdateItem from '../updates-new/noState/UpdateItem';
import Chart from '../utils/Chart';
import SubMenu from './SubMenu';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/1.jpg';
import { getCompanyProfiles, getProfileById } from '../../actions/profile';
import { getUpdateUserAnswersById, clearUpdates } from '../../actions/updateAnswer';
import { getUserCompany } from '../../actions/company';
import { updateCopy } from '../../utils/wording/updates';
import { FormattedMessage } from 'react-intl';
import Moment from 'react-moment';

const UserUpdates = ({
  clearUpdates,
  getUpdateUserAnswersById,
  getProfileById,
  getUserCompany,
  getCompanyProfiles,
  auth: { user },
  company: { company, team },
  profile: { profile },
  updateAnswer: { updateAnswers, loading },
  match,
}) => {
  useEffect(() => {
    getUserCompany();
    getUpdateUserAnswersById(match.params.id);
    getCompanyProfiles();
    getProfileById(match.params.id);

    // Clear state
    return () => {
      clearUpdates();
    }

  }, [getUserCompany, getUpdateUserAnswersById, getCompanyProfiles, getProfileById, match.params.id]); 

  const noUpdatesMessageTeam = {
    title: <FormattedMessage id="company.noupdates.user.title" defaultMessage="No Updates" />,
    msg: 
    <FormattedMessage id="company.updates.user.msg" defaultMessage={`${profile && profile.firstName} has not finished weekly update yet.`} />
  };

  // Removing duplicates in answers array based on the object key 'standup' - to send only unique standup items to StandupTeamItem
  // Берем данные из редакса updateAnswers
  let updateAnswersUnique = updateAnswers ? (updateAnswers.reduce((acc, current) => {
    const x = acc.find(item => item.update === current.update);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, [])) : [];

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {user && user.department ? (
          <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user && user.isAdmin && true} activeIndex={8} />
        ) : (
          <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} activeIndex={8} />
        )}

        <UserNav />
        
          <Fragment>
            {loading || !updateAnswers || !user || !company || !profile ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="main main-dashboard">
                <h5>
                  <FormattedMessage id="team.header.updates" defaultMessage={`${profile && profile.firstName}'s Updates`} />
                </h5>
                  <SubMenu userId={profile._id} companyIndex={2} />
                  <div className="updates">
                  <div className="mt-4">
                    <Chart updateAnswers={updateAnswersUnique} />
                  </div>

                    {company.plan !== 1 ? (
                      <Fragment>
                        {updateAnswers.length === 0 ? (
                          <div className="mt-5">
                            <NoContent 
                              message={noUpdatesMessageTeam}
                              picture={updateImg}
                              alt={'No updates'}
                            />
                          </div>
                        ) : (
                          <Fragment>
                            {updateAnswersUnique.length > 0 && (
                              updateAnswersUnique.map(
                                (updateAnswerUnique, index) => (
                                  <Fragment key={`fragment-update-${index}`}>
                                    <h3 className="fs-2 pt-2 pl-2 pb-1" key={`date-${index}`}>
                                      <Moment format="D MMM" key={`date-moment-${index}`}>{updateAnswerUnique.updateDate}</Moment>
                                    </h3>

                                    <UpdateItem
                                      key={`updates-${updateAnswerUnique._id}-${index}`}
                                      updateAnswers={updateAnswers.filter(
                                        updateAnswer =>
                                          updateAnswer.update.toString() ===
                                          updateAnswerUnique.update.toString()
                                      )}
                                      updateDate={updateAnswerUnique.updateDate}
                                    />
                                  </Fragment>
                                )
                              )
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      <NoContent 
                        message={updateCopy.updatePlanMsg}
                        picture={updateImg}
                        alt={'Update plan'}
                        admin={user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0}
                      />
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

UserUpdates.propTypes = {
  clearUpdates: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  getUpdateUserAnswersById: PropTypes.func.isRequired,
  getProfileById: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  updateAnswer: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  updateAnswer: state.updateAnswer
});

export default connect(
  mapStateToProps,
  { getUserCompany, getUpdateUserAnswersById, getCompanyProfiles, getProfileById, clearUpdates }
)(UserUpdates);
