import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { FormattedMessage } from "react-intl";

const DetailsChanges = ({
  item: {
    user,
    userFirstName,
    userLastName,
    type,
    subType,
    krName,
    currentValue,
    oldValue,
    date,
    milestoneType,
    milestoneAction,
    milestoneName,
  },
  okrId,
  okrTitle,
}) => {

  let btnClass;
  let status = 0;

  if (type === 'status') { status = currentValue; }

  if (status === 'On Track') {
    btnClass = "green";
  } else if (status === 'Behind') {
    btnClass = "yellow";
  } else if (status === 'At Risk') {
    btnClass = "red";
  }

  let userLabel = `${userFirstName} ${userLastName}`
  let fromValue = oldValue;
  let toValue = currentValue;
  let typeLabel = type;

  let from = <FormattedMessage id="from" defaultMessage={`from`} />;
  let to = <FormattedMessage id="to" defaultMessage={`to`} />;
  let changed = <FormattedMessage id="changed" defaultMessage={`changed`} />;
  let added = <FormattedMessage id="added" defaultMessage={`added`} />;
  let value = <FormattedMessage id="value" defaultMessage="value" />;
  let name = <FormattedMessage id="value" defaultMessage="name" />;

  if (type === 'dueIn') {
    typeLabel = <FormattedMessage id="due.date" defaultMessage="due date" />;
    fromValue =  <Moment format="ddd LL">{fromValue}</Moment>
    toValue =  <Moment format="ddd LL">{toValue}</Moment>
  } else if (type === 'owner') {
    typeLabel = <FormattedMessage id="okr.owner" defaultMessage="owner of the OKR" />;
  }  else if (type === 'parentOkr') {
    typeLabel = <FormattedMessage id="parent.okr" defaultMessage="parent OKR" />;

    if (toValue === '' || toValue === undefined) {
      toValue = 'no parent OKR';
    } else if (fromValue === '' || fromValue === undefined) {
      fromValue = 'no parent OKR';
    }
  } else if (type === 'keyResult') {
    typeLabel = 'the key result';

    if (subType === 'add') {
      changed = <FormattedMessage id="added" defaultMessage={`added`} />;
      from = '';
      to = '';
      toValue = krName;
    } else if (subType === 'remove') {
      changed = <FormattedMessage id="removed" defaultMessage={`removed`} />;
      from = '';
      to = '';
      fromValue = '';
      toValue = krName;
    } else if (subType === 'title') {
      typeLabel = 'title in the key result';
    } else if (subType === 'start') {
      typeLabel = `start metric in the key result '${krName}'`;
    } else if (subType === 'goal') {
      typeLabel = `goal in the key result '${krName}'`;
    } else if (subType === 'dueDate') {
      typeLabel = `due date in the key result '${krName}'`;
      fromValue =  <Moment format="ddd LL">{fromValue}</Moment>
      toValue =  <Moment format="ddd LL">{toValue}</Moment>
    } else if (subType === 'owners') {
      typeLabel = `owners in the key result '${krName}': `;
      fromValue = '';
      from = '';
      to = '';
    } else if (subType === 'milestone') {
      let label = milestoneAction !== 'add' ? (milestoneType === 'value' ? 'value of ' : 'name of') : ''
      typeLabel = `${label} the milestone '${milestoneName}' in the key result '${krName}': `;
      if (milestoneAction === 'add') changed = added;
    } else if (subType === 'binary') {
      typeLabel = `value in the key result '${krName}': `;
    }
  }
  
  return (
    <Fragment>
      <div className="flex align-center mt-2">
        <div className="comment-head">
            <div className="flex align-center">
              <span className="fs-9 grey mb-xs">
                <Moment format="ddd LLL">{date}</Moment>
              </span>
            </div>
            <p className="fs-12 lh-20">
              <Link to={`/user/${user}`} className="fs-12 lh-20 blue cursor bold">{userLabel}</Link>
            {" "}{changed}{" "}{typeLabel}{" "}{from}{" "}<strong>{fromValue}</strong>{" "}{to}{" "}<strong>{toValue}</strong></p>
        </div>
      </div>
    </Fragment>
  )
};

DetailsChanges.propTypes = {
  item: PropTypes.object.isRequired,
};

export default DetailsChanges;

