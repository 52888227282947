import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import logo from '../../img/logo/logo.svg';
import { FormattedMessage } from 'react-intl';
import { setLocaleLang } from '../../actions/locale';
import { icons } from "../../img/elements/icons";

const Navbar = ({
  auth: { isAuthenticated, loading, user },
  setLocaleLang,
  locale,
  logout,
  userMenu,
  teamMenu,
  companyMenu,
  adminMenu,
  guest
}) => {
  const [language, toggleLanguage] = useState(false);
  const [mobileMenu, showMobileMenu] = useState({
    active: false
  });

  const { active } = mobileMenu;

  const handleMobile = () => {
    showMobileMenu({ ...mobileMenu, active: !active });
  };

  const onClick = lang => {
    setLocaleLang(lang);
    toggleLanguage(!language);
  };

  const guestLinks = (
    <div className="container navigation navigation_new mb-4_5">
      <div className="logo">
        <Link to="/home">
          <img
            src={logo}
            alt="Focus logo"
            className="logo_img"
            width="122"
            height="35"
          />
        </Link>
      </div>
    </div>
  );

  const authLinks = (
    <div className="container navigation">
      <div className="logo">
        <Link to="/home">
          <img
            src={logo}
            alt="Focus logo"
            className="logo_img"
            width="122"
            height="35"
          />
        </Link>
      </div>

      <div
        className={
          active ? 'navigation_toggler activeMenu' : 'navigation_toggler'
        }
      >
      </div>

      <div className="menu_icon" id="menu_icon">
        <button className="no_btn" onClick={() => handleMobile()}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>

      <div className="nav_user nav_list">
        <ul className="menu user_menu">
          <li className="user-details menu-item">
            <p className="landing_font">
              {isAuthenticated && user ? (
                <img
                  className="round-img"
                  src={user.avatar}
                  alt="Avatar"
                  width="35px"
                  height="35px"
                  margin-right="5px"
                />
              ) : null}
              {isAuthenticated && user ? (
                user.firstName
              ) : (
                <FormattedMessage id="nav.hello" defaultMessage="Hello" />
              )
              }{' '}
              <i className="fas fa-caret-down" />
            </p>
            <ul className="sub-menu sub-user">
              <li>
                <Link to="/profile" className="landing_font">
                  {icons.profile}{' '}
                  <FormattedMessage id="nav.profile" defaultMessage="Profile" />
                </Link>
              </li>
              <li>
                <Link to="/profile/notifications" className="landing_font">
                  {icons.settings_user}{' '}
                  <FormattedMessage id="user.settings" defaultMessage="User Settings" />
                </Link>
              </li>
              <li>
                <Link to="#!" className="landing_font" onClick={logout}>
                  {icons.logout}{' '}
                  <FormattedMessage id="nav.logout" defaultMessage="Logout" />
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <nav className="navbar bg-primary">
      {!loading && <Fragment>{guest ? guestLinks : authLinks}</Fragment>}
    </nav>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  setLocaleLang: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  locale: state.locale
});

export default connect(
  mapStateToProps,
  { logout, setLocaleLang }
)(Navbar);
