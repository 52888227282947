import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import SubTitle from '../elems/SubTitle';
import ButtonBlue from '../elems/ButtonBlue';
import ButtonWhite200 from '../elems/ButtonWhite200';
import useInvite from '../invite/useInvite';

const CheckListHelloAdmin = ({
  profiles,
  okrs,
  standupAnswer,
  standups,
  planAnswers,
  oneOnOnes
}) => {
  const setupMsg = <FormattedMessage id="home.checklist.setup" defaultMessage="Set up Focus" />;

  const inviteCta = <FormattedMessage id="home.checklist.invite.cta" defaultMessage="Invite Your Team" />;

  let standupLink = '/checkins';
  standupLink = standups.length > 0 ? `/checkins/${standups[0]._id}` : '/checkins';
  const [openInvite, InviteElem] = useInvite();

  const data = [
    {
      title: <FormattedMessage id="home.checklist.1.title" defaultMessage="Create a new company" />
    },
    {
      title: (
        <FormattedMessage id="home.checklist.2.title" defaultMessage="Invite a couple of team members" />
      ),
      text: (
        <FormattedMessage
          id="home.checklist.2.text"
          defaultMessage="Team work goes better together. You are alone in your team. To start using Focus invite people."
        />
      ),
      isBtn: true,
      btnColor: 1,
      btnCta: inviteCta,
      onClick: openInvite
    },
    {
      title: <FormattedMessage id="home.checklist.3.title" defaultMessage="Share your daily check-in" />,
      text: (
        <FormattedMessage
          id="home.checklist.3.text"
          defaultMessage="Complete your first check-in. Show your team what you are working on."
        />
      ),
      isBtn: true,
      btnColor: 2,
      btnCta: <FormattedMessage id="home.checklist.3.cta" defaultMessage="Complete a Check-in" />,
      btnUrl: standupLink
    },
    {
      title: <FormattedMessage id="home.checklist.4.title" defaultMessage="Create the first Objective" />,
      text: <FormattedMessage id="home.checklist.4.text" defaultMessage="Create the first company's OKR" />,
      isBtn: true,
      btnColor: 2,
      btnCta: <FormattedMessage id="home.checklist.4.cta" defaultMessage="Create OKR" />,
      btnUrl: '/objective/new'
    },
    {
      title: <FormattedMessage id="home.checklist.6.title" defaultMessage="Share a weekly plan" />,
      text: (
        <FormattedMessage
          id="home.checklist.6.text"
          defaultMessage="Create your weekly plan to focus on top priorities. Sync the team."
        />
      ),
      isBtn: true,
      btnColor: 2,
      btnCta: <FormattedMessage id="home.checklist.6.cta" defaultMessage="Share a weekly plan" />,
      btnUrl: '/plans/complete'
    },
    {
      title: <FormattedMessage id="home.checklist.7.title" defaultMessage="Create your 1:1 meeting" />,
      text: (
        <FormattedMessage
          id="home.checklist.7.text"
          defaultMessage="Create the 1:1 meeting with your teammates to keep each other in the loop, give feedback, resolve issues, or help the participants grow in their roles."
        />
      ),
      isBtn: true,
      btnColor: 2,
      btnCta: <FormattedMessage id="home.checklist.7.cta" defaultMessage="Create 1:1 meeting" />,
      btnUrl: '/one_on_one'
    }
  ];

  
  return (
    <div className="component my-2 p-3 light-shadow cards__onboarding">
      <SubTitle title={setupMsg} />
      <div className="cards pt-2">
        <div className="card-item card-team">
          <div className="flex space-between align-center p-2 ml-3 flex-column-xs ml-2-xs">
            <div className="step-item mr-2 mb-2-xs cards__onboarding_body w-100-xs">
              <p className="medium card-subtitle todo__completed">{data[0].title}</p>
            </div>
          </div>
        </div>

        <div className="card-item card-team">
          <div className="flex space-between align-center p-2 ml-3 flex-column-xs ml-2-xs">
            <div className="step-item mr-2 mb-2-xs cards__onboarding_body">
              <Link onClick={data[1].onClick} className="mr-0 ">
                <p
                  className={`medium card-subtitle ${
                    profiles && profiles.length === 1 ? 'bold todo__item' : 'todo__completed'
                  }`}
                >
                  {data[1].title}
                </p>
              </Link>
              <p className={`medium`}>{data[1].text}</p>
            </div>
            <ButtonBlue cta={data[1].btnCta} url={data[1].btnUrl} />
          </div>
        </div>

        <div className="card-item card-team">
          <div className="flex space-between align-center p-2 ml-3 flex-column-xs ml-2-xs">
            <div className="step-item mr-2 mb-2-xs cards__onboarding_body">
              <Link to={data[2].btnUrl} className="mr-0">
                <p
                  className={`medium card-subtitle ${
                    standupAnswer === null ? 'bold todo__item' : 'todo__completed'
                  }`}
                >
                  {data[2].title}
                </p>
              </Link>
              <p className={`medium`}>{data[2].text}</p>
            </div>

            <div className="mr-2-xs">
              <ButtonWhite200
                cta={data[2].btnCta}
                url={data[2].btnUrl}
                isComplete={standupAnswer !== null && true}
              />
            </div>
          </div>
        </div>

        <div className="card-item card-team">
          <div className="flex space-between align-center p-2 ml-3 flex-column-xs ml-2-xs">
            <div className="step-item mr-2 mb-2-xs cards__onboarding_body">
              <Link to={data[3].btnUrl} className="mr-0">
                <p
                  className={`medium card-subtitle  ${
                    okrs && okrs.length === 0 ? 'bold todo__item' : 'todo__completed'
                  }`}
                >
                  {data[3].title}
                </p>
              </Link>
              <p className={`medium`}>{data[3].text}</p>
            </div>

            <div className="mr-2-xs">
              <ButtonWhite200
                cta={data[3].btnCta}
                url={data[3].btnUrl}
                isComplete={okrs && okrs.length !== 0 && true}
              />
            </div>
          </div>
        </div>

        <div className="card-item card-team">
          <div className="flex space-between align-center p-2 ml-3 flex-column-xs ml-2-xs">
            <div className="step-item mr-2 mb-2-xs cards__onboarding_body">
              <Link to={data[4].btnUrl} className="mr-0">
                <p
                  className={`medium card-subtitle  ${
                    planAnswers === false ||
                    (planAnswers && planAnswers.length === 0) ||
                    (planAnswers &&
                      planAnswers.length > 0 &&
                      planAnswers[0].answers &&
                      planAnswers[0].answers.length === 0)
                      ? 'bold todo__item'
                      : 'todo__completed'
                  }`}
                >
                  {data[4].title}
                </p>
              </Link>
              <p className={`medium`}>{data[4].text}</p>
            </div>

            <div className="mr-2-xs">
              <ButtonWhite200
                cta={data[4].btnCta}
                url={data[4].btnUrl}
                isComplete={
                  planAnswers && planAnswers.length > 0 && planAnswers[0].answers.length === 0 && true
                }
              />
            </div>
          </div>
        </div>

        <div className="card-item card-team">
          <div className="flex space-between align-center p-2 ml-3 flex-column-xs ml-2-xs">
            <div className="step-item mr-2 mb-2-xs cards__onboarding_body">
              <Link to={data[5].btnUrl} className="mr-0">
                <p
                  className={`medium card-subtitle  ${
                    oneOnOnes && oneOnOnes.length === 0 ? 'bold todo__item' : 'todo__completed'
                  }`}
                >
                  {data[5].title}
                </p>
              </Link>
              <p className={`medium`}>{data[5].text}</p>
            </div>

            <div className="mr-2-xs">
              <ButtonWhite200
                cta={data[5].btnCta}
                url={data[5].btnUrl}
                isComplete={oneOnOnes && oneOnOnes.length > 0 && true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CheckListHelloAdmin.propTypes = {
  profiles: PropTypes.array,
  okrs: PropTypes.array,
  standupAnswer: PropTypes.object
};

export default CheckListHelloAdmin;
