import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const DayFocus = ({ 
  focus, 
  okr, 
  date 
}) => {
  
  let color;
  let label = "";

  if (okr) {
    if (okr.status === 1) {
      color = "green";
      label = "On Track";
    } else if (okr.status === 2) {
      color = "yellow";
      label = "Behind";
    } else if (okr.status === 3) {
      color = "red";
      label = "At Risk";
    }
  }
 
  // Check that object is not empty (object length)
  let objectLength = 0;
  if (okr !== undefined && okr !== null) {
    objectLength = Object.keys(okr).length;
  }

  // Determine that it's todays day
  const isToday = (someDate) => {
    const today = new Date();
    return someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
  }

  const checkinDate = date && new Date(date);
  const today = checkinDate && isToday(checkinDate);
  const title = <FormattedMessage id="focus.day.title" defaultMessage="Daily Focus"/>;
  const focusHead = <FormattedMessage id="focus.day.focus" defaultMessage="Focus"/>;
  const okrHead = <FormattedMessage id="focus.day.okr" defaultMessage="OKR"/>;
  const currentlLabel = <FormattedMessage id="widget.main.current" defaultMessage="Current"/>;
  const goalLabel = <FormattedMessage id="widget.main.goal" defaultMessage="Goal"/>;
  const noFocus = <FormattedMessage id="focus.nofocus" defaultMessage="No focus on OKR today"/>;
  const focusOnOkr = <FormattedMessage id="focus.onOkr" defaultMessage="Focus on OKR"/>;

  return (
    <div className="bg__white bg__focus_day light-shadow text-left br-12 px-4 py-5 my-4">
      <p className="fs-2 text-left bold pb-2">🙌{' '}{title}</p>
      {today && okr !== {} && objectLength > 0 && focus && focus.isFocus ? (
        focus.focusIndex === 99 ? (
          <Fragment>
          <p className="medium text-left"><b>{focusOnOkr}:</b>{' '}{focus.objective}</p>
          <p className={`medium text-left inline-block ${color} fs-14 mr-3`}>{label}</p>
          <p className="medium text-left inline-block grey fs-14 mr-1">{currentlLabel}:{' '}{okr.progress}%</p>
          <p className="medium text-left inline-block grey fs-14">{goalLabel}:{' '}100%</p>
        </Fragment>
        ) : (
          <Fragment>
            <p className="medium text-left"><b>{okrHead}:</b>{' '}{focus.objective}</p>
            <p className="medium text-left"><b>{focusHead}:</b>{' '}{focus.text}</p>
            <p className={`medium text-left inline-block ${color} fs-14 mr-3`}>{label}</p>
            <p className="medium text-left inline-block grey fs-14 mr-1">{currentlLabel}:{' '}{okr.keyResults[focus.focusIndex].current}</p>
            <p className="medium text-left inline-block grey fs-14">{goalLabel}:{' '}{okr.keyResults[focus.focusIndex].goal}</p>
          </Fragment>
        )   
      ) : (
        <p className="medium text-left">{noFocus}</p>
      )}
    </div>
  );
};

DayFocus.propTypes = {
  okr: PropTypes.object,
  focus: PropTypes.object,
  date: PropTypes.string
};

export default DayFocus;
