import React from 'react';
import { FormattedMessage } from 'react-intl';

const q1 = <FormattedMessage id="onboarding.plans.1" defaultMessage="What are you going to do this week?" />
const q2 = <FormattedMessage id="onboarding.plans.q2" defaultMessage="What is one thing you can do this week to get the best outcome?" />

const questions = [q1, q2];

let reply1 = <FormattedMessage id="onboarding.plans.a2" defaultMessage="Creating the 'all-in-one' feature" />;
let reply2 = <FormattedMessage id="onboarding.plans.a2" defaultMessage="Building new management processes" />;
let reply3 = <FormattedMessage id="onboarding.plans.a6" defaultMessage="Identifying all processes of the team" />;
let reply4 = <FormattedMessage id="onboarding.plans.a11" defaultMessage="Management Playbook" />;
let reply5 = <FormattedMessage id="onboarding.plans.a8" defaultMessage="Creating the Sales Playbook" />;
let reply6 = <FormattedMessage id="onboarding.plans.a13" defaultMessage="Q2 clients follow ups" />;
let reply7 = <FormattedMessage id="onboarding.plans.a15" defaultMessage="Create the new website design" />;
let reply8 = <FormattedMessage id="onboarding.plans.a16" defaultMessage="Improve onboarding workflow" />;


const answers1 = [
  {
    _id: "answer1",
    text: [reply1, reply2, reply3],
    question: questions[0]
  },
  {
    _id: "answer2", 
    text: [reply2],
    question: questions[1]
  },
]

const answers2 = [
  {
    _id: "answer1", 
    text: [reply4, reply5, reply6],
    question: questions[0]
  },
  {
    _id: "answer2", 
    text: [reply5],
    question: questions[1]
  },
]


const answers3 = [
  {
    _id: "answer1", 
    text: [reply7, reply8],
    question: questions[0]
  },
  {
    _id: "answer2", 
    text: [reply7],
    question: questions[1]
  },
]

const likes1 = [
  {
    _id: "like1",
    user: "onboarding_creator1",
    answerID: "answer1"
  },
  {
    _id: "like1",
    user: "onboarding_creator2",
    answerID: "answer1"
  },
  {
    _id: "like1",
    user: "onboarding_creator3",
    answerID: "answer1"
  },
  {
    _id: "like1",
    user: "onboarding_creator4",
    answerID: "answer1"
  },
  {
    _id: "like1",
    user: "onboarding_creator5",
    answerID: "answer1"
  },
  {
    _id: "like2",
    user: "onboarding_creator2",
    answerID: "answer2"
  },
  {
    _id: "like2",
    user: "onboarding_creator2",
    answerID: "answer2"
  }
]

const comment1 = [
  {
    _id: "comment1",
    user: "onboarding_creator1",
    answerID: "answer1",
    text: (
      <FormattedMessage
        id="onboarding.updates.text"
        defaultMessage="Awesome! When can we deliver it to our users?"
      />
    ),
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName1"
        defaultMessage="Tom"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName1"
        defaultMessage="Ford"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar52.jpeg",
    date: "2020-03-17T09:46:33.402+00:00"
  }
]

export const onboardingPlans = [
  { 
    _id: "onboarding_update1",
    questions: questions,
    answers: answers1,
    user: "onboarding_owner2",
    team: "onboarding_team1",
    teamName: (
      <FormattedMessage
        id="onboarding.okr.product"
        defaultMessage="Product"
      />
    ),
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName2"
        defaultMessage="Julian"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName2"
        defaultMessage="Tobin"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar44.jpeg",
    company: "onboarding_company1",
    companyName: "onboarding_companyName1",
    department: "onboarding_department1",
    departmentName: (
      <FormattedMessage
        id="onboarding.okr.departmentName1"
        defaultMessage="Marketing"
      />
    ),
    update: "5d36ba95e33e8a061d3b6eda",
    updateID: 1,
    date: "2020-03-11T07:36:33.402+00:00",
    likes: likes1,
    comments: comment1,
    updateDate: "2020-03-29T09:00:00.000+00:00"
  },
  { 
    _id: "onboarding_update2",
    questions: questions,
    answers: answers2,
    user: "onboarding_owner2",
    team: "onboarding_team2",
    teamName: (
      <FormattedMessage
        id="onboarding.okr.teamName2"
        defaultMessage="HR"
      />
    ),
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName1"
        defaultMessage="Tom"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName1"
        defaultMessage="Ford"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar52.jpeg",
    company: "onboarding_company1",
    companyName: "onboarding_companyName1",
    department: "onboarding_department1",
    departmentName: (
      <FormattedMessage
        id="onboarding.okr.departmentName1"
        defaultMessage="Marketing"
      />
    ),
    update: "5d36ba95e33e8a061d3b6eda",
    updateID: 2,
    date: "2020-03-11T07:36:33.402+00:00",
    likes: [],
    comments: [],
    updateDate: "2020-03-22T09:00:00.000+00:00"
  },
  { 
    _id: "onboarding_update3",
    questions: questions,
    answers: answers3,
    user: "onboarding_owner2",
    team: "onboarding_team2",
    teamName: (
      <FormattedMessage
        id="onboarding.okr.teamName2"
        defaultMessage="Marketing"
      />
    ),
    type: "3",
    creator: "onboarding_creator1",
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName3"
        defaultMessage="Patrick"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName3"
        defaultMessage="Brody"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar7.jpeg",
    company: "onboarding_company1",
    companyName: "onboarding_companyName1",
    department: "onboarding_department1",
    departmentName: (
      <FormattedMessage
        id="onboarding.okr.departmentName1"
        defaultMessage="Marketing"
      />
    ),
    update: "5d36ba95e33e8a061d3b6eda",
    updateID: 3,
    date: "2020-03-11T07:36:33.402+00:00",
    likes: [],
    comments: [],
    updateDate: "2020-03-15T09:00:00.000+00:00"
  }
]

export default {
  onboardingPlans,
}