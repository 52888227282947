import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import {
  editRecognitionComment,
  deleteRecognitionComment
} from '../../../actions/recognition';

const CommentItem = ({
  comment: { firstName, lastName, text, user, date, avatar, _id },
  auth,
  deleteRecognitionComment,
  editRecognitionComment,
  recognitionID
}) => {

  return (
    <div className="comment-body">
      <div className="comment-item">
        <Link to={user ? `/user/${user}` : '#!'} className="user">
          <img
            src={avatar}
            alt="Avatar"
            className="round-img d-block avatar"
            width="45px"
            height="45px"
          />
        </Link>

        <div className="comment-head">
          <div className="comment-info">
            <p className="comment-user medium bold">
              <strong>
                {firstName} {lastName}
              </strong>
            </p>

            <time className="js-local-time comment-date">
              <Moment format="ddd LLL">{date}</Moment>
            </time>

            <div className="icons btn-icons-edit">
              {!auth.loading && user === auth.user._id && (
                <button
                  type="button"
                  className="btn-icons btn-delete"
                  onClick={e => deleteRecognitionComment(recognitionID, _id)}
                >
                  <i className="fas fa-times" />
                </button>
              )}
            </div>
          </div>

          <p className="comment-text">{text}</p>
        </div>
      </div>
    </div>
  );
};

CommentItem.propTypes = {
  auth: PropTypes.object.isRequired,
  recognitionID: PropTypes.string.isRequired,
  comment: PropTypes.object.isRequired,
  editRecognitionComment: PropTypes.func.isRequired,
  deleteRecognitionComment: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { editRecognitionComment, deleteRecognitionComment }
)(CommentItem);
