import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import PropTypes from 'prop-types';
import SpinnerButton from '../layout/SpinnerButton';
import { FormattedMessage } from 'react-intl';
import Avatar from 'react-avatar-edit';

const AvatarPopup = ({
  setAlert,
  avatarPopup,
  showAvatarPopup,
  editAvatar,
  avatarUpdate,
  onChangeAvatar,
}) => {

  const [preview, setPreview] = useState(null);
  const [spinner, setSpinner] = useState(false);

  // Avatar
  function onClose() {
    setPreview(null);
  }

  function onCrop(pv) {
    // console.log('======== pv in onCrop', pv);

    setPreview(pv);
  }
 
  function onBeforeFileLoad(elem) {
    if (elem.target.files[0].size > 10000000) {
      setAlert(
        <FormattedMessage
          id="avatar.bigSize"
          defaultMessage="File is too big. Max size: 10 MB."
        />,
        'danger'
      );
      elem.target.value = "";
    }
  }

  function onFileLoad(elem) {
    // console.log('======== elem in onFileLoad', elem);
  }

  // ЗДЕСЬ получаем коллбэк и скрываем попап 
  const saveAvatar = async (preview) => {
    try {
      setSpinner(!spinner);
      const res = await editAvatar(preview);

      if (res && res.status === 1) {
        setSpinner(!spinner);

        onChangeAvatar(avatarUpdate);

        showAvatarPopup(!avatarPopup);
      }

    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in uploading avatar', err);
    }
  };
 
  return (
    <Fragment>
      <div className="modal z-30000">
        <div className="w-50 modal-content pb-3">
          <Fragment>
              <div className="modal-header pt-3 pb-2 px-4">
                <button
                  className="modal-close no_btn"
                  onClick={() => showAvatarPopup(!avatarPopup)}
                >
                  &times;
                </button>
                <h2>
                  <FormattedMessage id="avatar.upload" defaultMessage="Upload Image" />
                  </h2>
              </div>

              <div className="modal-body px-4">
                <p className="medium mb-1">
                  <FormattedMessage id="avatar.size" defaultMessage="Max size: 10 MB" />
                </p>
                <div className="flex align-start">
                  <Avatar
                    width={400}
                    height={400}
                    cropRadius={75}
                    minCropRadius={50}
                    onCrop={onCrop}
                    onClose={onClose}
                    onBeforeFileLoad={onBeforeFileLoad}
                    onFileLoad={onFileLoad}
                    src={null}
                  />
                {preview && (
                  <Fragment>
                    <div className="flex flex-column ml-3 text-center">
                      <p className="medium bold block">
                        <FormattedMessage id="preview" defaultMessage="Preview" />
                      </p>
                      <img src={preview} className="mra ml-3" alt="Preview" />
                    </div>
                  </Fragment>
                )}
                </div>

              {preview && (
                <button
                  onClick={() => saveAvatar(preview)}
                  type="submit"
                  className="btn btn-form mt-2"
                >
                  {!spinner ? (
                    <FormattedMessage id="admin.dashboard.edit.submit" defaultMessage="Submit" />
                  ) : (
                      <SpinnerButton />
                      // null
                  )}
                </button>
                )}
              </div>
            </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

AvatarPopup.propTypes = {
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  alert: state.alert
});

export default connect(
  mapStateToProps,
  { setAlert }
)(AvatarPopup);
