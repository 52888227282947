import React, { Fragment, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import UpcomingCheckinItem from "./UpcomingCheckinItem";
import { FormattedMessage } from "react-intl";

const nocheckins = <FormattedMessage id="no.upcoming.checkins" defaultMessage="No upcoming check-ins" />;

const getAnswers = (answers, settings) => {
  let arr = []

  if (!settings.checkinName) {
    // Custom
    arr = answers.filter(a => a.custom && a.custom.toString() === settings._id.toString())
  } else if (settings.checkinName === 'standups') {
    // Plan
    arr = answers.filter(a => a.standupID && a.standupID.toString() === settings.settings.companyID.toString())
  } else if (settings.checkinName === 'plans') {
    // Plan
    arr = answers.filter(a => a.planID && a.planID.toString() === settings.settings.planID.toString())
  } else if (settings.checkinName === 'updates') {
    // Plan
    arr = answers.filter(a => a.updateID && a.updateID.toString() === settings.settings.companyID.toString())
  }

  return arr;
};

const UpcomingCheckins = ({
  checkins,
  answers,
  styles,
  checkinType,
  oldCheckins,
}) => {

  let campaigns = oldCheckins && oldCheckins.length === 0 ? checkins : checkins.concat(oldCheckins);
  // console.log('===== checkins in settings', checkins)
  // console.log('===== campaigns in settings', campaigns)
  // console.log('===== answers in settings', answers)

  return (
     <div className={`table__upcoming ${styles ? styles : ''}`}>
        <div className={`br-4 p-3 brd brd-grey__light bg__white`}>
          {campaigns && campaigns.length > 0 ? (
            campaigns.map(item => (
              item.active ? 
              <UpcomingCheckinItem 
                checkinName={!item.checkinName ? 'custom' : item.checkinName}
                checkin={item}
                // answers={answers.length > 0 ? answers.filter(a => a.custom.toString() === item._id.toString()) : []}
                answers={answers.length > 0 ? getAnswers(answers, item) : []}
              /> : null
            ))
          ) : (
            <p className="fs-14">{nocheckins}</p>
          )}
        </div>
    </div>
  );
};

UpcomingCheckins.propTypes = {
  answers: PropTypes.array.isRequired,
  getUserAnswersAmount: PropTypes.func,
  tableNameTitle: PropTypes.object,
};

export default UpcomingCheckins;
