import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const OkrAvatarItem = ({ ownerAvatar, ownerFirstName, ownerLastName }) => {
  return (
    <div className="flex align-center justify-start mr-xs">
      <span className="user mr-14 flex align-center">
        <img
          src={ownerAvatar && ownerAvatar}
          alt={`User avatar`}
          className="round-img d-block avatar"
          width="40"
          height="40"
        />
      </span>
      <div className="okr__owner">
        <p className="fs-10 grey_new fw-600 lh-14">
          <FormattedMessage id="user.okr.create.owner" defaultMessage="Owner" />
        </p>
        <p className="fs-16 lh-22">
            {ownerFirstName} {ownerLastName}
        </p>
      </div>
    </div>
  );
};

OkrAvatarItem.propTypes = {
  ownerAvatar: PropTypes.string.isRequired,
  ownerFirstName: PropTypes.string.isRequired,
  ownerLastName: PropTypes.string.isRequired
};

export default OkrAvatarItem;
