import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '../layout/Menu';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import OutreachTable from './OutreachTable';
import Select from 'react-select';
import { setAlert } from '../../actions/alert';
import { getReachData, clearReach, addReachUser } from '../../actions/reach';
import { getReachEmailData } from '../../actions/outreachEmail';
import { FormattedMessage } from 'react-intl';


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '16px',
  }),
  input: () => ({
    backgroundColor: '#fcfcfd',
    fontSize: '14px',
  }),
  control: styles => ({ ...styles, backgroundColor: '#fcfcfd', padding: '4px 8px' }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
}

const Outreach = ({
  setAlert,
  clearReach,
  getReachData,
  addReachUser,
  auth: { user },
  outreachEmail: { campaigns },
  reach: { data, loading },
  getReachEmailData,
}) => {
  const [stateData, setStateData] = useState({
    campaign: '',
    users: []
  });

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    company: '',
    position: '',
    comment: '',
    source: '',
    website: '',
    articleName: '',
    articleUrl: '',
    article: {},
    template: [],
    scheduleTime: { value: 4, label: '10:07 SF / 13:07 NY / 17:07 UTC', utc: '17:07', id: 'scheduleTime' }, 
    jobPostings: '',
    vcRound: '',
    vcTime: '',
  });
  
  const [addLead, toggleAddLead] = useState(false);
  const { fullName, email, company, position, comment, source, website, template, scheduleTime, articleName, articleUrl, jobPostings, vcRound, vcTime } = formData;
  let { campaign, users } = stateData;
  
  let campaignOptions = [];
  let timeOptions = [];

  timeOptions = [
      { value: 1, label: '6:07 SF / 9:07 NY / 13:07 UTC', utc: '13:07', id: 'scheduleTime' },
      { value: 2, label: '8:07 SF / 11:07 NY / 15:07 UTC', utc: '15:07', id: 'scheduleTime' },
      { value: 4, label: '10:07 SF / 13:07 NY / 17:07 UTC', utc: '17:07', id: 'scheduleTime' },
      { value: 3, label: '11:07 SF / 14:07 NY / 18:07 UTC', utc: '18:07', id: 'scheduleTime' },
    ];

  let campaignFilterOptions = [];

  // Перебор campaigns и приведение данных к формату Селекта
  {
    campaigns && campaigns.length > 0 &&
      campaigns.forEach((campaign, index) => {
      
        let obj = {
          value: index,
          label: `${campaign.name}`,
          name: `${campaign.name}`,
          id: campaign._id
        };

        campaignFilterOptions.push(obj);
        campaignOptions.push(obj);

        if (index === campaigns.length - 1) {

          // First option is all campaigns
          let all = { value: 99, label: '🎯 All Campaigns', name: 'All campaigns', id: 'template' };
          campaignFilterOptions.unshift(all);
        }
      });
  }

  let choosedCampaign = campaigns.length > 0 ? campaignFilterOptions[0] : [];

  useEffect(() => {
    getReachData();
    getReachEmailData();

    setStateData({
      campaign: campaigns.length === 0 ? '' : choosedCampaign,
      users: !data ? [] : data,
    });

    setFormData({
      ...formData,
      template: campaigns.length === 0 ? [] : campaignOptions[0],
    });
  }, [
    getReachData,
    getReachEmailData,
    JSON.stringify(campaigns),
    JSON.stringify(data),
  ]);
  
  const handleFilterChange = selectedOption => {
    let newItems;
    
    if (selectedOption.value === 99) {
      // for All campaigns
      newItems = data;
    } else {
      newItems = data.filter(item => item.template.toString() === selectedOption.id.toString());
    }

    setStateData({ 
      ...stateData, 
      campaign: selectedOption, 
      users: newItems });
  };

  const handleSelectChange = selectedOption => {
    if (selectedOption.id !== 'scheduleTime') {
      setFormData({ ...formData, template: selectedOption });
    } else {
      setFormData({ ...formData, [selectedOption.id]: selectedOption });
    }
  };

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async e => {
    try {
      
      e.preventDefault();

      // Update state
      const res = await addReachUser(formData);
      console.log('====== res', res);

      // Stop if error
      if (!res.data) { return; }

      setStateData({ 
        ...stateData, 
        users: [res, ...stateData.users]
      });

      setFormData({ 
        ...formData, 
        fullName: '',
        email: '',
        company: '',
        position: '',
        comment: '',
        source: '',
        website: '',
        articleName: '',
        articleUrl: '',
        article: {},
        template: campaignFilterOptions[1],
        scheduleTime: { value: 4, label: '10:07 SF / 13:07 NY / 17:07 UTC', utc: '17:07', id: 'scheduleTime' }, 
        jobPostings: '',
        vcRound: '',
        vcTime: '',
      });
    } catch (err) {
      console.log('Error in Outreach', err);
    }
  };

  const start = new Date().setHours(0,0,0,0);
  const end = new Date().setHours(23,59,59,999);

  let meetings = [];
  let answers = [];
  let emailsToday = [];
  let totalEmails = 0;

  data && users.length > 0 && users.forEach(user => {

    if (user && user.schedule) {
      let date = Date.parse(user.schedule.date);
    
      if (user.meeting && user.meeting > 0) { meetings.push(user) };
      if (user.answer && user.answer > 0) { answers.push(user) };
      if (user.isActive && date > start && date < end) { emailsToday.push(user) };
      if (user.nextEmail > 0) { totalEmails = totalEmails + (user.nextEmail - 1) };
    }
  });

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {user && user.department ? (
          <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user && user.isAdmin && true} outreach={user && user.isAdmin && user.role === 'Superadmin' && true} focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={100} />
        ) : (
          <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} outreach={user && user.isAdmin && user.role === 'Superadmin' && true} focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={100} />
        )}

        <UserNav />
        
          <Fragment>
            {loading || !user || !data || user.role !== 'Superadmin'  ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="pt-2 main-dashboard">
                     <h5>
                      <FormattedMessage id="company.header.outreach" defaultMessage={`Focus Outreach`} />
                    </h5>

                  <div className="mb-5 pb-5">

                    <div className="flex space-between align-center my-3">

                      <FormattedMessage
                        id="focus.sub_menu.campaignSelect"
                        defaultMessage="Choose the campaign"
                      >
                        {msg => (
                          <Select
                            className="small-input mt-2 w-30-l-50-xs"
                            defaultValue={choosedCampaign}
                            value={campaign}
                            onChange={selectedOption =>
                              handleFilterChange(selectedOption)
                            }
                            options={campaignFilterOptions}
                            isSearchable
                            placeholder={msg}
                            required
                            name="campaign"
                            styles={customStyles}
                          />
                        )}
                      </FormattedMessage>

                      <Link to="/outreacher/campaigns/" className="small mr-2 cursor mla">
                        <FormattedMessage
                          id="email.campaigns"
                          defaultMessage="Email Campaigns"
                        />
                      </Link>
                    </div>

                    <div className="mt-3">
                        <p className="medium bold">Total contacts:{" "}{users.length}</p>
                        <p className="medium bold">Total emails sent:{" "}{totalEmails}</p>
                        <p className="medium bold">Emails will be sent today:{" "}{emailsToday.length}</p>
                        <p className="medium bold">Answers:{" "}{answers.length}</p>
                        <p className="medium bold">Meetings:{" "}{meetings.length}</p>
                    </div>

                    <div className="section-title justify-end pr-2">
                      <button 
                        onClick={() => toggleAddLead(!addLead)}
                        className="btn btn-light w-150px text-center"
                      >
                        <FormattedMessage
                          id="admin.people.addContact"
                          defaultMessage="+ Add contact"
                        />
                      </button>
                    </div>

                    {addLead && (
                      <Fragment>
                        <div className="form-full pr-2">
                          <form
                            className="form-app form-app-okr py-3"
                            onSubmit={e => onSubmit(e)}
                          >
                              <div className="form-main form-main-inline flex-column-xs">
                                <p className="bold medium block no-w label-pr3 pb-0-xs">Full name</p>
                                <input
                                  className="question-input input-transparent input-transparent__title w-40 mr-1 mb-3-xs w-100-xs"
                                  type="text"
                                  required
                                  placeholder="Name"
                                  name="fullName"
                                  value={fullName || ''}
                                  onChange={e => onChange(e)}
                                />

                                <p className="bold medium block no-w label-pr3 pb-0-xs">Email</p>
                                <input
                                  className="question-input input-transparent input-transparent__title w-40 mr-1 mb-3-xs w-100-xs"
                                  type="email"
                                  required
                                  placeholder="Email"
                                  name="email"
                                  value={email || ''}
                                  onChange={e => onChange(e)}
                                />
                              </div>

                              <div className="form-main form-main-inline standups-time mt-2 flex-column-xs">
                                <p className="bold medium block no-w label-pr3">Campaign</p>
                                <Select
                                  className="small-input w-70-xs mb-2-xs"
                                  defaultValue={campaignOptions[0]}
                                  value={template}
                                  onChange={selectedOption =>
                                    handleSelectChange(selectedOption)
                                  }
                                  options={campaignOptions}
                                  isSearchable
                                  placeholder="Campaign"
                                  required
                                  name="template"
                                />

                                <p className="bold medium block no-w label-pr3 ml-1 ml-0-xs">Schedule</p>
                                <Select
                                  className="small-input w-70-xs mb-2-xs"
                                  value={scheduleTime}
                                  onChange={selectedOption =>
                                    handleSelectChange(selectedOption)
                                  }
                                  options={timeOptions}
                                  isSearchable
                                  placeholder="Schedule time"
                                  name="scheduleTime"
                                  required
                                />

                                <p className="bold medium block no-w label-pr3 ml-1 ml-0-xs">Company</p>
                                <input
                                  className="question-input input-transparent input-transparent__title w-40 mr-1 mb-3-xs w-100-xs"
                                  type="text"
                                  placeholder="Company"
                                  name="company"
                                  value={company || ''}
                                  onChange={e => onChange(e)}
                                />
                              </div>

                              <div className="form-main form-main-inline flex-column-xs">
                                <p className="bold medium block no-w label-pr3 pb-0-xs">Position</p>
                                <input
                                  className="question-input input-transparent input-transparent__title mr-1 mb-3-xs w-100-xs"
                                  type="text"
                                  placeholder="Position"
                                  name="position"
                                  value={position || ''}
                                  onChange={e => onChange(e)}
                                />

                                <p className="bold medium block no-w label-pr3 pb-0-xs">Source</p>
                                <input
                                  className="question-input input-transparent input-transparent__title mr-1 mb-3-xs w-100-xs"
                                  type="text"
                                  placeholder="Source"
                                  name="source"
                                  value={source || ''}
                                  onChange={e => onChange(e)}
                                />

                                <p className="bold medium block no-w label-pr3 pb-0-xs">Website</p>
                                <input
                                  className="question-input input-transparent input-transparent__title mr-1 mb-3-xs w-100-xs"
                                  type="text"
                                  placeholder="Website"
                                  name="website"
                                  value={website || ''}
                                  onChange={e => onChange(e)}
                                />
                              </div>


                              <div className="form-main form-main-inline flex-column-xs">
                                <p className="bold medium block no-w label-pr3 pb-0-xs">Article Title</p>
                                <input
                                  className="question-input input-transparent input-transparent__title mr-1 mb-3-xs w-100-xs"
                                  type="text"
                                  placeholder="Title"
                                  name="articleName"
                                  value={articleName || ''}
                                  onChange={e => onChange(e)}
                                />

                                <p className="bold medium block no-w label-pr3 pb-0-xs">Article URL</p>
                                <input
                                  className="question-input input-transparent input-transparent__title mr-1 mb-3-xs w-100-xs"
                                  type="text"
                                  placeholder="Article URL"
                                  name="articleUrl"
                                  value={articleUrl || ''}
                                  onChange={e => onChange(e)}
                                />

                                <p className="bold medium block no-w label-pr3 pb-0-xs">Comment</p>
                                <input
                                  className="question-input input-transparent input-transparent__title mr-1 mb-3-xs w-100-xs"
                                  type="text"
                                  placeholder="Comment"
                                  name="comment"
                                  value={comment || ''}
                                  onChange={e => onChange(e)}
                                />
                              </div>


                              <div className="form-main form-main-inline flex-column-xs">
                                <p className="bold medium block no-w label-pr3 pb-0-xs">Job Postings</p>
                                <input
                                  className="question-input input-transparent input-transparent__title mr-1 mb-3-xs w-100-xs"
                                  type="text"
                                  placeholder="Jobs"
                                  name="jobPostings"
                                  value={jobPostings || ''}
                                  onChange={e => onChange(e)}
                                />

                                <p className="bold medium block no-w label-pr3 pb-0-xs">VC Round</p>
                                <input
                                  className="question-input input-transparent input-transparent__title mr-1 mb-3-xs w-100-xs"
                                  type="text"
                                  placeholder="VC Round"
                                  name="vcRound"
                                  value={vcRound || ''}
                                  onChange={e => onChange(e)}
                                />

                                <p className="bold medium block no-w label-pr3 pb-0-xs">VC Month</p>
                                <input
                                  className="question-input input-transparent input-transparent__title mr-1 mb-3-xs w-100-xs"
                                  type="text"
                                  placeholder="VC Month"
                                  name="vcTime"
                                  value={vcTime || ''}
                                  onChange={e => onChange(e)}
                                />
                              </div>

                              <div className="block mla w-content pb-0">
                                <p 
                                  className="btn btn-small btn-small-grey mr"
                                  onClick={() => toggleAddLead(!addLead)}
                                >
                                  <FormattedMessage
                                    id="okr.form.cancel"
                                    defaultMessage="Cancel"
                                  />
                                </p>

                                <button 
                                  type="submit" 
                                  className="btn btn-small btn-small-blue ml-1"
                                >
                                  <FormattedMessage
                                    id="standup.form.submit"
                                    defaultMessage="Submit"
                                  />
                                </button>
                              </div>
                          </form>
                        </div>
                      </Fragment>
                    )}

                    <div className="my-3 pb-5 pr-2">
                        <OutreachTable users={users} />
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

Outreach.propTypes = {
  setAlert: PropTypes.func.isRequired,
  addReachUser: PropTypes.func.isRequired,
  getReachData: PropTypes.func.isRequired,
  clearReach: PropTypes.func.isRequired,
  getReachEmailData: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  reach: PropTypes.object.isRequired,
  outreachEmail: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  reach: state.reach,
  outreachEmail: state.outreachEmail,
});

export default connect(
  mapStateToProps,
  { clearReach, getReachData, addReachUser, setAlert, getReachEmailData }
)(Outreach);