import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { updateUpdateLike } from '../../../actions/updateAnswer';
import CommentItem from '../../comment/updates-noState/CommentItem';
import CommentForm from '../../comment/updates-noState/CommentFormNew';
// import CommentForm from '../../comment/UniversalCommentFormNew';
import QuestionItem from '../../comment/updates/QuestionItem';
import QuestionItemOkr from '../../comment/updates/QuestionItemOkr';
import { icons } from "../../../img/elements/icons";

const UpdateItemCardQuestion = ({
  auth: { loading, user },
  avatar,
  question,
  answer,
  comments,
  likes,
  updateID,
  userID,
  updateUpdateLike,
  index,
  campaignType,
  weeklyOkrs,
  lastWeeklyOkrs,
}) => {
  const findUserLike = likes => {
    if (!loading) {
      if (likes.filter(like => like.user === user._id).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const findComments = comments => {
    if (comments.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const [discussion, showComments] = useState(false);

  return (
    <Fragment>
      <div className={`${index === 0 ? "card-question-item" : "card-question-item pt-1"}`}>
        {index === 0 && campaignType === 2 ? (
          <QuestionItemOkr
            key={`teamQuestionItem-${updateID}-${answer._id}`}
            question={question}
            answer={answer && answer.okrAnswers && answer.okrAnswers.length > 0 && answer.okrAnswers}
            avatar={avatar}
            _id={userID}
            index={index}
            weeklyOkrs={weeklyOkrs}
            lastMetrics={lastWeeklyOkrs}
          />
        ) : (
          <QuestionItem
            key={`teamQuestionItem-${updateID}-${answer._id}`}
            question={question}
            answer={answer}
            avatar={avatar}
            _id={userID}
            index={index}
          />
        )}

        <Fragment>
          <div className="btn-feedback  flex align-center pb-0 pt-14 px-0 ml-38">
            <button
              type="button"
              className={classnames('btn-icons btn-like flex align-center mr-1', {
                'btn-active-icon btn-like__active': findUserLike(likes)
              })}
              onClick={e => updateUpdateLike(updateID, answer._id)}
            >
              <span className="mr-xs flex align-center">{icons.heart}{icons.heart_full}</span>
              <span className='fs-10 bold mb-0'>{answer.likesAmount > 0 ? answer.likesAmount : '0'}</span>
            </button>

            <button
              type="button"
              onClick={() => showComments(!discussion)}
              className={classnames('btn-icons btn-comment flex align-center icon__message__badge', {
                'btn-active-icon': findComments(comments)
              })}
            >
              <span className="mr-xs flex align-center">{icons.message}</span>
              <span className="fs-10 bold mb-0">{comments.length > 0 ? comments.length : '0'}</span>
            </button>
          </div>
        </Fragment>

        {discussion && (
          <Fragment>
            <div className="card-feedback mt-24">
              <div className="comment">
                <div className="mb-1">
                  {comments.map(comment => (
                    <CommentItem
                      key={`team-${comment._id}`}
                      comment={comment}
                      updateID={updateID}
                      answerID={answer._id}
                    />
                  ))}
                </div>

                <div className="ml-38">
                  <CommentForm
                    updateID={updateID}
                    answerID={answer._id}
                    user={user}
                    avatar={user.avatar}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

UpdateItemCardQuestion.propTypes = {
  auth: PropTypes.object.isRequired,
  updateUpdateLike: PropTypes.func
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { updateUpdateLike }
)(UpdateItemCardQuestion);
