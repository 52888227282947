import React, { Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import Menu from "./Menu";
import ShortMenu from "./ShortMenu";
import { changeSidebar } from '../../actions/auth';

const Sidebar = ({ 
  auth: { user, sidebar },
  activeIndex, 
  changeSidebar,
  windowDimensions,
 }) => {
  const [menuCollapsed, setMenuCollapsed] = useState(false);

  const toggleSidebar = () => {
    setMenuCollapsed(!menuCollapsed);

    changeSidebar(sidebar);
  };

  // console.log('======== activeIndex', activeIndex)

  return (
    <Fragment>
      {activeIndex !== 4 ? (
        <Fragment>
          {sidebar === 1 ? (
            <Menu
              team={user && user.department ? user.departmentName : null}
              teamId={user && user.department ? user.department : null}
              settings={user && user.isAdmin && true}
              focusUsers={
                user && user.isAdmin && user.role === 'Superadmin' && true
              }
              companyName={user && user.companyName}
              activeIndex={activeIndex}
              isShowCollapseBtn={true}
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
              toggleSidebar={toggleSidebar}
              sidebar={sidebar}
            />
          ) : (
            <ShortMenu
              team={user && user.department ? user.departmentName : null}
              teamId={user && user.department ? user.department : null}
              settings={user && user.isAdmin && true}
              focusUsers={
                user && user.isAdmin && user.role === 'Superadmin' && true
              }
              companyName={user && user.companyName}
              activeIndex={activeIndex}
              isShowCollapseBtn={true}
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
              toggleSidebar={toggleSidebar}
              sidebar={sidebar}
            />
          )}
      </Fragment>
      ) : (
        <Fragment>
          {windowDimensions && windowDimensions.width > 1100 ? (
            <Fragment>
              {sidebar === 1 ? (
                <Menu
                  team={user && user.department ? user.departmentName : null}
                  teamId={user && user.department ? user.department : null}
                  settings={user && user.isAdmin && true}
                  focusUsers={
                    user && user.isAdmin && user.role === 'Superadmin' && true
                  }
                  companyName={user && user.companyName}
                  activeIndex={activeIndex}
                  isShowCollapseBtn={true}
                  menuCollapsed={menuCollapsed}
                  setMenuCollapsed={setMenuCollapsed}
                  toggleSidebar={toggleSidebar}
                  sidebar={sidebar}
                />
              ) : (
                <ShortMenu
                  team={user && user.department ? user.departmentName : null}
                  teamId={user && user.department ? user.department : null}
                  settings={user && user.isAdmin && true}
                  focusUsers={
                    user && user.isAdmin && user.role === 'Superadmin' && true
                  }
                  companyName={user && user.companyName}
                  activeIndex={activeIndex}
                  isShowCollapseBtn={true}
                  menuCollapsed={menuCollapsed}
                  setMenuCollapsed={setMenuCollapsed}
                  toggleSidebar={toggleSidebar}
                  sidebar={sidebar}
                />
              )}
            </Fragment>
          ) : (
            <ShortMenu
                team={user && user.department ? user.departmentName : null}
                teamId={user && user.department ? user.department : null}
                settings={user && user.isAdmin && true}
                focusUsers={
                  user && user.isAdmin && user.role === 'Superadmin' && true
                }
                companyName={user && user.companyName}
                activeIndex={activeIndex}
                isShowCollapseBtn={false}
                menuCollapsed={menuCollapsed}
                setMenuCollapsed={setMenuCollapsed}
                toggleSidebar={toggleSidebar}
                sidebar={2}
              />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

Sidebar.propTypes = {
  changeSidebar: PropTypes.func,
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

// export default Sidebar;

export default connect(mapStateToProps, {
  changeSidebar
})(Sidebar);
