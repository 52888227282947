import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import MainMenu from '../layout/Sidebar';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import { getCompanyTasks, updateTodo, addTodo, deleteTodo, completeTodo, changeTaskFilters } from '../../actions/todo';
import { getCompanyProfiles } from '../../actions/profile';
import { getUserCompany } from '../../actions/company';
import { getCompanySortedOkrs, getAllIndividualUserOkrs } from '../../actions/okr';
import ListItem from './ListItem';
import OkrItem from './OkrItem';
import searchImg from '../../img/empty_states/search@2x.png';
import noTaskImg from '../../img/empty_states/no_tasks.svg';
import NoContentNew from '../utils/NoContentNew';
import taskImg from '../../img/empty_states/task1.png';
import okrImg from '../../img/empty_states/task2@2x.png';
import { taskCopy } from '../../utils/wording/tasks';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import { setAlert } from '../../actions/alert';
import { icons } from '../../img/elements/icons';
import TaskItemCardForm from './TaskItemCardForm';
import useAnalyticsEventTracker from '../../utils/analytics/useAnalyticsEventTracker';


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px'
  }),
  input: () => ({
    backgroundColor: '#fcfcfd',
    fontSize: '14px'
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: '#ffffff',
    padding: '0px 8px',
    lineHeight: '20px',
    height: 35,
    minHeight: 35
  }),
  placeholder: (styles) => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
};
const endOfTheYear = new Date();
endOfTheYear.setMonth(11);
endOfTheYear.setDate(31);

const Task = ({
  updateTodo,
  addTodo,
  getUserCompany,
  getCompanyTasks,
  getCompanyProfiles,
  getCompanySortedOkrs,
  auth: { user, sidebar },
  company: { company },
  todo: { tasks, task, loading, updated },
  okr: { okrs },
  profile: { profiles },
  locale,
  history,
  deleteTodo,
  completeTodo,
  setAlert,
  taskFilters,
  changeTaskFilters,
  getAllIndividualUserOkrs,
  // useAnalyticsEventTracker,
}) => {
  const [formData, setFormData] = useState({
    team: '',
    todos: [],
    completedTodos: [],
    name: '',
    owner: [],
    isCompleted: false,
    isOKR: false,
    OKR: {},
    isObjective: false,
    KR: {},
    dueIn: '',
    priority: {},
    updatedAt: '',
    isPrivate: '',
    objectives: [],
    taskView: 1,
    isNewTask: false
  });

  const [search, setSearch] = useState('');
  const [results, setResults] = useState({
    searchResults: false,
    searchKeyword: ''
  });
  const [completedTasks, setCompletedTasks] = useState(false);
  const [listView, switchView] = useState(true);

  let {
    team,
    todos,
    completedTodos,
    objectives,
    // name,
    // assignee,
    // isCompleted,
    // isOKR,
    // OKR,
    // isObjective,
    // KR,
    // dueIn,
    // priority,
    // updatedAt,
    // isPrivate,
    taskView,
    isNewTask
  } = formData;
  let { searchResults, searchKeyword } = results;
  let teamOptions = [];
  let usersOptions = [];
  let okrOptions = [];

  // Перебор profiles & departments и приведение данных к формату Селекта
  {
    profiles &&
      profiles.forEach((profile, index) => {
        let coworker = {
          value: profile._id,
          label: `${profile.firstName} ${profile.lastName}`,
          user: profile._id,
          firstName: profile.firstName,
          lastName: profile.lastName,
          avatar: profile.avatar,
          department: profile.department,
          departmentName: profile.departmentName,
          name: 'owner',
          type: 3
        };

        usersOptions.push(coworker);

        if (user && profile._id === user._id) return;
        teamOptions.push(coworker);
      });
  }

  {
    company &&
      company.departments.length > 0 &&
      company.departments.forEach((department, index) => {
        if (user && user.department === null) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };

          teamOptions.unshift(team);
        } else if (user && user.department !== null && department._id !== user.department) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };

          teamOptions.unshift(team);
        }

        if (user && index === company.departments.length - 1) {
          // You is third in list
          let person = {
            value: user._id,
            label: `${user.firstName} ${user.lastName}`,
            user: user._id,
            firstName: user.firstName,
            lastName: user.lastName,
            avatar: user.avatar,
            department: user.department,
            departmentName: user.departmentName,
            name: 'owner',
            type: 3
          };
          teamOptions.unshift(person);

          // Second option is the team if exists
          if (user.department !== null) {
            let team = { value: user.department, label: `${user.departmentName}`, type: 2 };
            teamOptions.unshift(team);
          }

          // Third option is the company
          let name = { value: company._id, label: `${company.name}`, type: 1 };
          teamOptions.unshift(name);
        }
      });
  }

  if (company && company.departments.length === 0 && user && profiles && profiles.length === 1) {
    teamOptions = [
      { value: company._id, label: `${company.name}`, type: 1 },
      { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 }
    ];
  } else if (company && company.departments.length === 0 && user && profiles && profiles.length > 1) {
    // if 0 teams and 1+ users
    // add user and company to others members that we found in the profiles loop
    let person = { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 };
    teamOptions.unshift(person);

    let team = { value: company._id, label: `${company.name}`, type: 1 };
    teamOptions.unshift(team);
  }

  let choosedTeam = user ? { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 } : {};

  // Формируем OKR варианты для селекта
  let noOkrMsg = locale && locale.lang !== 'ru' ? `Not connected to OKR` : `Не связан с OKR`;
  {
    okrs &&
      okrs.length > 0 &&
      okrs.forEach((okr, i) => {
        if (!okr.isArchive) {
          let objective = {
            value: okr._id,
            label: `🎯 ${okr.objective}`,
            okrId: okr._id,
            objective: okr.objective,
            text: okr.objective,
            focusIndex: 99,
            status: okr.status,
            progress: okr.progress,
            goal: 100,
            isKr: false,
            name: 'OKR'
          };

          okrOptions.push(objective);

          let krs = okr.keyResults;

          if (krs.length > 0) {
            krs.forEach((kr, index) => {
              let item = {
                value: kr._id,
                label: ` - ${kr.title}`,
                okrId: okr._id,
                objective: okr.objective,
                text: kr.title,
                focusIndex: index,
                status: okr.status,
                progress: kr.progress,
                goal: kr.goal,
                current: kr.current,
                krId: kr._id,
                isKr: true,
                name: 'OKR'
              };

              okrOptions.push(item);
            });
          }
        }
      });
  }

  // Формируем приоритет
  const priorityOptions = [
    { value: 1, label: 'Normal', color: '#6ddeb3', name: 'priority' },
    { value: 2, label: 'Average', color: '#facf34', name: 'priority' },
    { value: 3, label: 'High', color: 'red', name: 'priority' }
  ];

  // Priority default choice
  let choosedPriority = {};

  task &&
    priorityOptions.forEach((item) => {
      if (item.value === task.status) {
        choosedPriority = {
          value: item.value,
          label: item.label,
          color: item.color
        };
      }
    });

  let taskTxt = locale && locale.lang !== 'ru' ? `All Tasks` : `Все Задачи`;
  let taskCompletedTxt = locale && locale.lang !== 'ru' ? `Completed Tasks` : `Выполненные Задачи`;
  let taskOKRTxt = locale && locale.lang !== 'ru' ? `OKR Related` : `OKR Задачи`;
  let assignedTaskTxt = locale && locale.lang !== 'ru' ? `Tasks you've assigned` : `Ваши задачи`;

  let choosedView = locale && locale.lang !== 'ru' ? { value: 1, label: taskTxt } : {};

  let taskOptions = [
    { value: 1, label: taskTxt },
    { value: 4, label: assignedTaskTxt },
    { value: 2, label: taskOKRTxt },
    { value: 3, label: taskCompletedTxt }
  ];


  useEffect(() => {
    getUserCompany();
    getCompanyProfiles();
    getCompanyTasks();
    // getCompanySortedOkrs();
    getAllIndividualUserOkrs();

    let teamFilter = !taskFilters || taskFilters && !taskFilters.team ? choosedTeam : taskFilters.team;
    let typeFilter = !taskFilters || taskFilters && !taskFilters.type ? choosedView : taskFilters.type;

    // If completed tasks
    if (typeFilter.value === 3) { setCompletedTasks(true) };

    setFormData({
      ...formData,
      team: teamFilter,
      taskView: typeFilter,
      todos: !tasks ? [] : handleTeam(teamFilter, true),
      completedTodos: !tasks
        ? []
        : tasks.filter((item) => {
            if (item.creator === teamFilter.value && item.isCompleted) {
              return item;
            } else if (
              item.owner &&
              item.creator !== teamFilter.value &&
              item.owner.user === teamFilter.value &&
              item.isCompleted
            ) {
              return item;
            }
          }),
      objectives: !okrs
        ? []
        : okrs.filter((item) => {
            if (item.owner === teamFilter.value && !item.isArchive) {
              // if option is a owner (don't check team or company level)
              return item;
            } else if (!item.isArchive && item.keyResults.findIndex((i) => i.owner === teamFilter.value) > -1) {
              // if option is owner of key result
              if (!item.isArchive) {
                return item;
              }
            } else {
              // в идеале, надо добавить OKR, где юзер не ответственный, но у него есть таски
              // для этого надо 3-им условием проверять оставшиеся Objective and KRs на совпадение с todos.OKR.okrId || krId
              return false;
            }
          })
    });
  }, [
    updated,
    getUserCompany,
    // getCompanySortedOkrs,
    getAllIndividualUserOkrs,
    getCompanyProfiles,
    loading,
    user && choosedTeam.value.length,
    updateTodo,
    okrs.length,
    // getCompanyTasks,
    tasks && tasks.length,
  ]);

  const getAssignedTasks = async () => {

    let todos = [];
    let completedTodos = [];
 
    tasks.forEach((item) => {
      // Don't check creator field, only owner
      if (item.owner && item.owner.length > 0) {
        item.owner.forEach((t) => {
          if (t.user === user._id.toString() && !item.isCompleted) {
            // not completed
            todos.push(item);
          } else if (t.user === user._id.toString() && item.isCompleted) {
            // completed
            completedTodos.push(item);
          }
        });
      } else if (item.creator.toString() === user._id.toString() && item.owner && item.owner.length === 0 && item.isCompleted) {
        // Also add completed tasks that user created but didn't set assignee
        completedTodos.push(item);
      }
    });

    return { todos, completedTodos };
  };


  const handleTeam = (selectedOption, isPageLoading = false) => {
    let newTodos = [];
    let newCompletedTodos = [];
    let newOkrs = [];

    if (listView) {
      // List View

      if (selectedOption.type === 1) {
        // Company
        newTodos = tasks.filter((item) => item.company === selectedOption.value && !item.isCompleted);
        newCompletedTodos = tasks.filter((item) => item.company === selectedOption.value && item.isCompleted);

        // OKRs (all OKRs - not only company level)
        newOkrs = okrs.filter((item) => item.company === selectedOption.value && !item.isArchive);
      } else if (selectedOption.type === 2) {
        // Team
        newTodos = tasks.filter((item) => item.department === selectedOption.value && !item.isCompleted);
        newCompletedTodos = tasks.filter(
          (item) => item.department === selectedOption.value && item.isCompleted
        );

        // OKRs
        newOkrs = okrs.filter((item) => item.team === selectedOption.value && !item.isArchive);
      } else if (selectedOption.type === 3) {
        // User

        // Array of owners
        tasks.forEach((item) => {
          if (item.creator === selectedOption.value && !item.isCompleted) {
            newTodos.push(item);
          } else if (
            item.owner &&
            item.owner.length > 0 &&
            item.creator !== selectedOption.value &&
            !item.isCompleted
          ) {
            item.owner.forEach((t) => {
              if (t.user === selectedOption.value) {
                newTodos.push(item);
              }
            });
          }
        });
        tasks.forEach((item) => {
          if (item.creator === selectedOption.value && item.isCompleted) {
            newCompletedTodos.push(item);
          } else if (
            item.owner &&
            item.owner.length > 0 &&
            item.creator !== selectedOption.value &&
            item.isCompleted
          ) {
            item.owner.forEach((t) => {
              if (t.user === selectedOption.value) {
                newCompletedTodos.push(item);
              }
            });
          }
        });

        // OKRs
        newOkrs = okrs.filter((item) => {
          if (item.owner === selectedOption.value && !item.isArchive) {
            // If user is owner
            return item;
          } else if (item.keyResults.findIndex((i) => i.owner === selectedOption.value) > -1) {
            // if user is owner of key result
            if (!item.isArchive) {
              return item;
            }
          } else {
            return false;
          }
        });
      }
    } else {
      // OKR View

      if (selectedOption.type === 1) {
        // Company
        okrs.forEach((okr) => {
          tasks.forEach((item) => {
            if (item.company === selectedOption.value) {
              if (item.OKR && item.OKR.okrId === okr._id) {
                if (!item.isCompleted) {
                  return newTodos.push(item);
                } else {
                  return newCompletedTodos.push(item);
                }
              }
            }
          });
        });

        newOkrs = okrs.filter((item) => item.company === selectedOption.value && !item.isArchive);
      } else if (selectedOption.type === 2) {
        // Team
        // здесь надо вначале отобрать правильные OKR и потом уже по ним выбрать таски
        newOkrs = okrs.filter((item) => item.team === selectedOption.value && !item.isArchive);

        newOkrs.forEach((okr) => {
          tasks.forEach((item) => {
            if (item.department === selectedOption.value) {
              if (item.OKR && item.OKR.okrId === okr._id) {
                if (!item.isCompleted) {
                  return newTodos.push(item);
                } else {
                  return newCompletedTodos.push(item);
                }
              }
            }
          });
        });
      } else if (selectedOption.type === 3) {
        // User

        // OKRs
        newOkrs = okrs.filter((item) => {
          if (item.owner === selectedOption.value && !item.isArchive) {
            // If user is owner
            return item;
          } else if (item.keyResults.findIndex((i) => i.owner === selectedOption.value) > -1) {
            // if user is owner of key result
            if (!item.isArchive) {
              return item;
            }
          } else {
            return false;
          }
        });

        newOkrs.forEach((okr) => {
          tasks.forEach((item) => {
            if (item.creator === selectedOption.value) {
              if (item.OKR && item.OKR.okrId === okr._id) {
                if (!item.isCompleted) {
                  return newTodos.push(item);
                } else {
                  return newCompletedTodos.push(item);
                }
              }
            } else if (
              item.owner &&
              item.creator !== selectedOption.value &&
              item.owner.user === selectedOption.value &&
              item.OKR &&
              item.OKR.okrId === okr._id
            ) {
              if (!item.isCompleted) {
                return newTodos.push(item);
              } else {
                return newCompletedTodos.push(item);
              }
            }
          });
        });
      }
    }

    if (isPageLoading) return newTodos;

    setFormData({
      ...formData,
      team: selectedOption,
      todos: newTodos,
      completedTodos: newCompletedTodos,
      objectives: newOkrs
    });

    changeTaskFilters(selectedOption, taskView)
  };

  const handleSwitchView = async (newView) => {
    let newTodos = [];
    let newCompletedTodos = [];

    if (newView.value !== 4) {
      // Tasks not from "Assigned to you" field

      if (team.type === 1) {
        // Company
        if (newView.value === 1) {
          // List view
          newTodos = tasks.filter((item) => item.company === team.value && !item.isCompleted);
          newCompletedTodos = tasks.filter((item) => item.company === team.value && item.isCompleted);
        } else {
          // OKR view
          objectives.forEach((okr) => {
            todos.forEach((item) => {
              if (item.company === team.value && !item.isCompleted) {
                if (item.OKR && item.OKR.okrId === okr._id) {
                  return newTodos.push(item);
                }
              }
            });
          });
        }
      } else if (team.type === 2) {
        // Team
        if (newView.value === 1) {
          newTodos = tasks.filter((item) => item.department === team.value && !item.isCompleted);
          newCompletedTodos = tasks.filter((item) => item.department === team.value && item.isCompleted);
        } else {
          // OKR view
          objectives.forEach((okr) => {
            todos.forEach((item) => {
              if (item.department === team.value && !item.isCompleted) {
                if (item.OKR && item.OKR.okrId === okr._id) {
                  return newTodos.push(item);
                }
              }
            });
          });
        } 
      } else if (team.type === 3) {
        // User
        if (newView.value === 1) {
          tasks.forEach((item) => {
            if (item.creator === team.value && !item.isCompleted) {
              newTodos.push(item);
            } else if (
              item.owner &&
              item.owner.length > 0 &&
              item.creator !== team.value &&
              !item.isCompleted
            ) {
              item.owner.forEach((t) => {
                if (t.user === team.value) {
                  newTodos.push(item);
                }
              });
            }
          });
          tasks.forEach((item) => {
            if (item.creator === team.value && item.isCompleted) {
              newCompletedTodos.push(item);
            } else if (item.owner && item.owner.length > 0 && item.creator !== team.value && item.isCompleted) {
              item.owner.forEach((t) => {
                if (t.user === team.value) {
                  newCompletedTodos.push(item);
                }
              });
            }
          });
        } else {
          // OKR view
          objectives.forEach((okr) => {
            todos.forEach((item) => {
              if (item.creator === team.value && !item.isCompleted) {
                if (item.OKR && item.OKR.okrId === okr._id) {
                  return newTodos.push(item);
                }
              } else if (
                item.owner &&
                item.owner.length > 0 &&
                item.creator !== team.value &&
                !item.isCompleted &&
                item.OKR &&
                item.OKR.okrId === okr._id
              ) {
                item.owner.forEach((t) => {
                  if (t.user === team.value) {
                    return newTodos.push(item);
                  }
                });
              }
            });
          });
        }
      }
    } else {
      // List view: assigned to user
      let data = await getAssignedTasks();
        
      newTodos = data.todos;
      newCompletedTodos = data.completedTodos;
    }

    setFormData({
      ...formData,
      todos: newTodos,
      completedTodos: newCompletedTodos.length ? newCompletedTodos : formData.completedTodos,
      taskView: newView,
      isNewTask: false
    });

    // switchView(!listView);
  };

  const handleView = (selectedOption) => {
    // 1 - all tasks, 2 - okr related tasks, 3 - completed tasks,
    
    if (selectedOption.value === 1) {
      setCompletedTasks(false);
      switchView(false);
      handleSwitchView(selectedOption);
    } else if (selectedOption.value === 2) {
      setCompletedTasks(false);
      switchView(true);
      handleSwitchView(selectedOption);
    } else if (selectedOption.value === 3) {
      setCompletedTasks(!completedTasks);
      setFormData({
        ...formData,
        taskView: selectedOption
      });
    } else if (selectedOption.value === 4) {
      setCompletedTasks(false);
      switchView(false);
      handleSwitchView(selectedOption);
    }

    changeTaskFilters(team, selectedOption)
  };

  const noTasksMessageTeam = {
    title: <FormattedMessage id="company.notasks.user.title" defaultMessage="No Tasks" />,
    // msg: <FormattedMessage id="company.tasks.user.msg" defaultMessage={`${team.label} has not created tasks yet.`} />
    msg: <FormattedMessage id="company.tasks.user.msg.new" defaultMessage="There is no tasks created that match your filters." />
  };

  const noCompletedTasksMessageTeam = {
    title: <FormattedMessage id="company.notasks.completed.title" defaultMessage="No Completed Tasks" />,
    msg: (
      <FormattedMessage
        id="company.tasks.completed.msg.new"
        defaultMessage="Once you complete your tasks, they will show here."
      />
    )
  };

  const noSearchResults = {
    title: <FormattedMessage id="search.noresults" defaultMessage="No Results Found" />,
    msg: (
      <FormattedMessage
        id="company.checkins.user.msg"
        defaultMessage={`No search results for <b>"${search}"</b>`}
        // defaultMessage={`Sorry, we couldn't find any results matching <b>"${searchKeyword}"</b>`}
        values={{ b: (...chunks) => <b>{chunks}</b> }}
      />
    ),
    msg2: (
      <FormattedMessage
        id="try.diff.search"
        defaultMessage="Try searching a different keyword."
      />
    ),
  };

  // КАК СЕЙЧС РАБОТАЕТ
  // 1. Формируем массив чекинов checkinsArr, который не зависит от checkins
  //   - он также привязан к team, чтобы искать по фильтру команды
  //   - отдельный массив для того, чтобы он не влияел на checkins, который рендерится на странице
  // 2. Делаем циклы, чтобы добраться до text
  //   - Цикл для checkinsArr, потом для checkinsArr.answers, потом для checkinsArr.answers.text
  // 3. Помещаем все в массив filteredCheckins, где хранятся результаты
  //   - используем push
  const handleSearch = (e) => {
    setSearch(e.target.value);
    let answersArr = [];

    // New solution with state
    if (team.type === 1) {
      // Company
      answersArr = tasks.filter((answer) => answer.company === team.value);
    } else if (team.type === 2) {
      // Team
      answersArr = tasks.filter((answer) => answer.department === team.value);
    } else if (team.type === 3) {
      // USer
      answersArr = tasks.filter((item) => {
        if (item.creator === team.value) {
          return item;
        } else if (item.owner && item.creator !== team.value && item.owner.user === team.value) {
          return item;
        }
      });
    }

    let filteredAnswers = [];

    if (e.target.value.length !== 0) {
      // If search input is not empty
      let isUniqueItem = true;

      answersArr.forEach((item, i) => {
        if (item.name.toLowerCase().includes(e.target.value.toLowerCase()) === true) {
          return filteredAnswers.push(item);
        }
      });

      setFormData({
        ...formData,
        todos: filteredAnswers.filter((item) => !item.isCompleted),
        completedTodos: filteredAnswers.filter((item) => item.isCompleted)
      });

      // Check if we have search results or not
      if (filteredAnswers.length !== 0) {
        searchResults = false;

        if (todos.length === 0) {
          searchResults = true;
        }
      } else {
        searchResults = true;
      }

      setResults({
        ...results,
        searchResults,
        searchKeyword: e.target.value
      });
    } else {
      // Empty search input
      setFormData({
        ...formData,
        todos: answersArr.filter((item) => !item.isCompleted),
        completedTodos: answersArr.filter((item) => item.isCompleted)
      });

      setResults({
        ...results,
        searchResults: false,
        searchKeyword: ''
      });
    }
  };

  const handleSelect = async (id, selectedOption, type) => {
    let obj = {};
    obj[type] = selectedOption;

    const res = await updateTodo(id, obj);

    // Update todos state
    setFormData({
      ...formData,
      todos: todos.map((item) => (item._id === res.id ? res.data : item)),
      completedTodos: completedTodos.map((item) => (item._id === res.id ? res.data : item))
    });
  };

  const handleChangeForm = async (selectedOption, index, type) => {
    todos[index][type] = selectedOption;

    setFormData({
      ...formData,
      todos: todos
    });
  };

  const handleNameForm = async (e, index, board, id) => {
    // if tasks or completed
    if (board === 1) {
      // Tasks (List View)
      todos[index].name = e.target.value;

      setFormData({
        ...formData,
        todos: todos
      });
    } else if (board === 99) {
      // Completed
      completedTodos[index].name = e.target.value;

      setFormData({
        ...formData,
        completedTodos: completedTodos
      });
    } else if (board === 10) {
      // OKR View
      let itemIndex = todos.map((item) => item._id).indexOf(id);

      todos[itemIndex].name = e.target.value;

      setFormData({
        ...formData,
        todos: todos
      });
    }
  };

  const handleDueIn = async (id, selectedOption) => {
    let obj = { dueIn: selectedOption };

    const res = await updateTodo(id, obj);

    // Update todos state
    setFormData({
      ...formData,
      todos: todos.map((item) => (item._id === res.id ? res.data : item)),
      completedTodos: completedTodos.map((item) => (item._id === res.id ? res.data : item))
    });
  };

  const clearTodo = (e, index) => {
    todos.splice(index, 1);

    setFormData({ ...formData, todos, isNewTask: false });
  };

  const handleDelete = async (_id, index) => {
    try {
      const res = await deleteTodo(_id);

      setFormData({
        ...formData,
        todos: todos.filter((item) => item._id !== _id),
        completedTodos: completedTodos.filter((item) => item._id !== _id)
      });
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in delete task', err);
    }
  };

  const handleAdd = (e) => {
    e.preventDefault();

    setFormData({
      ...formData,
      todos: [
        {
          isNew: true,
          dueIn: endOfTheYear,
          priority: priorityOptions[priorityOptions.length - 1],
          owner: {
            avatar: user.avatar,
            firstName: user.firstName,
            label: `${user.firstName} ${user.lastName}`,
            lastName: user.lastName,
            user: user._id,
            value: user._id
          }
        },
        ...todos
      ],
      isNewTask: true
    });
  };

  // Это показывает новый инпут в OKR, но дальше надо менять сам onChange, тк он привязан к index,
  // который в OKR view НЕ равен отображению
  // Например, in todos newItem будет иметь индекс 5, а в OkrItem -> OkrITemKr он будет 2-ым (там только 1 таск есть)...
  const handleAddKr = (e, okrId, krId) => {
    e.preventDefault();

    setFormData({ ...formData, todos: [...todos, { isNew: true, dueIn: null, OKR: { okrId, krId } }] });
  };

  const handleComplete = async (e, id, index, isCompleted) => {
    e.preventDefault();

    // actions
    // update state
    completeTodo(id, isCompleted);

    // if it was not completed => mean that use completes it now
    if (!isCompleted) {
      // Complete
      if (!listView) {
        index = todos.map((item) => item._id).indexOf(id);
      }
      todos[index].isCompleted = !todos[index].isCompleted;

      setFormData({
        ...formData,
        todos: todos.filter((item) => item._id !== id),
        completedTodos: [...completedTodos, todos[index]]
      });
    } else {
      // Makes active again
      if (!listView) {
        index = completedTodos.map((item) => item._id).indexOf(id);
      }

      // it was completed and now it becomes active
      completedTodos[index].isCompleted = !completedTodos[index].isCompleted;

      setFormData({
        ...formData,
        todos: [...todos, completedTodos[index]],
        completedTodos: completedTodos.filter((item) => item._id !== id)
      });
    }
  };

  const onSubmit = async (e, index, krId) => {
    e.preventDefault();

    // if task created in OKR view
    if (krId !== 0) {
      // Add OKR to this todo
      let okr = okrOptions.filter((item) => item.value === krId);
      todos[index].OKR = okr;
    }

    const res = await addTodo(todos[index], history);

    // console.log('===== res in task submit', res.data)

    if (res && res.data) {
      // Update todos state (добавляем id нового таска и OKR если создавали в виде okr)
      todos[index] = res.data;

      setFormData({
        ...formData,
        todos: [{ isNew: true, dueIn: null }, ...todos]
      });

      useAnalyticsEventTracker('task_created', 'task created', 'Task Created');
    }
  };

  let noTaskMsg = {
    title: <FormattedMessage id="notasks.title" defaultMessage="You have no tasks ..." />,
    msg: <FormattedMessage id="nookrs.msg" defaultMessage="Create & manage your tasks to accomplish your key objectives"/>,
    onClick: handleAdd,
    btnTxt: <FormattedMessage id="create.new.task" defaultMessage="Create New Task" />,
    secondImg: { src: okrImg, styles: 'w-inherit', alt: 'OKR tasks'},
    handleClick: true,
    url: '',
    btnColor: 1,
  };

  let updatePlanMsg = {
    title: <FormattedMessage id="company.tasks.updateplan.msg.new" defaultMessage="Upgrade your plan to create tasks" />,
    msg: <FormattedMessage id="nookrs.msg" defaultMessage="Create & managing tasks helps you accomplish your key objectives."/>,
    onClick: null,
    btnTxt: <FormattedMessage id="upgrade.plan.cta.customs" defaultMessage="Upgrade plan" />,
    secondImg: { src: okrImg, styles: 'w-inherit', alt: 'OKR tasks'},
    handleClick: false,
    url: '/settings/pricing',
    btnColor: 2,
  };

  // console.log('==== search', search) 
  // console.log('==== taskFilters', taskFilters)
  // console.log('==== choosedTeam', choosedTeam)
  // console.log('==== completedTasks', completedTasks)
  // console.log('==== taskView', taskView)
  // console.log('==== tasks', tasks)
  // console.log('==== todos', todos) 
  // console.log('==== completedTodos', completedTodos)
  // console.log('==== objectives', objectives)


  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

          {user && !loading && (
              <MainMenu activeIndex={12} />
          )}
          <UserNav />
          <Fragment>
            {loading || !tasks || !user || !company || !okrs || !profiles ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className={`${sidebar === 1 ? 'ml-280 main main-dashboard' : 'short_menu__ml__okrs ma-w90'} mt-3 wrapper__okrs`}>
                  <div className="checkins">
                    <div className="title">
                      <h5 className="pl-0-xs fw-600">
                        <FormattedMessage id="tasks.header" defaultMessage="Tasks" />
                      </h5>
                      <p className="medim">
                        <FormattedMessage
                          id="tasks.subheader"
                          defaultMessage="Manage your tasks and connect them to objectives"
                        />
                      </p>
                    </div>
                    <div className="flex space-between align-center">
                      <FormattedMessage id="checkins.tasks" defaultMessage="Choose tasks">
                        {(msg) => (
                          <Select
                            className="small-input w-200px filter-180px w-150px-xs mr-2 z-10000"
                            defaultValue={choosedView}
                            value={taskView}
                            onChange={(selectedOption) => handleView(selectedOption)}
                            options={taskOptions}
                            isSearchable
                            placeholder={msg}
                            required
                            name="taskView"
                            styles={customStyles}
                          />
                        )}
                      </FormattedMessage>

                      <FormattedMessage
                        id="checkins.sub_menu.teamSelect"
                        defaultMessage="Choose the team or user"
                      >
                        {(msg) => (
                          <Select
                            className="small-input w-200px filter-180px w-150px-xs mra z-10000"
                            defaultValue={choosedTeam}
                            value={team}
                            onChange={(selectedOption) => handleTeam(selectedOption)}
                            options={teamOptions}
                            isSearchable
                            placeholder={msg}
                            required
                            name="team"
                            styles={customStyles}
                          />
                        )}
                      </FormattedMessage>

                      <FormattedMessage id="search.bar" defaultMessage="Search...">
                        {(msg) => (
                          <input
                            className="question-input ml-2 pl-2 br-4 brd brd-grey bg__white outline w-20-l-50-xs mla lh-34"
                            type="text"
                            placeholder={msg}
                            name="search"
                            value={search}
                            onChange={(e) => handleSearch(e)}
                          />
                        )}
                      </FormattedMessage>
                    </div>

                    {/* {tasks.length === 0 && company.plan !== 1 && (
                      <Notification msg={noTasksMessageTeam.msg} btnColor={'orange'} color={`orange`} />
                    )} */}

                   {company.plan !== 1 && todos.length > 0 ? (
                    <div className="mt-3">
                      <div
                        className="btn__secondary_green brd-transparent cursor flex align-center justify-center w-180px text-center"
                        onClick={(e) => handleAdd(e)}
                      >
                        <span className="flex align-center mr-xs lh-35">{icons.circle_plus_small_white}</span>{' '}
                        <span className="fs-16">
                          <FormattedMessage id="tasks.subtitle2.addTask" defaultMessage="Add New Task" />
                        </span>
                      </div>
                    </div>
                    ) : (company.plan !== 1 && todos.length === 0 ? (
                      <Fragment>
                        {search.length === 0 ? (
                          <div className="mt-2">
                            {team.value === user._id.toString() ? (
                              <NoContentNew
                                message={noTaskMsg}
                                picture={taskImg}
                                alt={'Create first task'}
                                imgWidth={'w-100'}
                                divStyles="row-reverse"
                                isTask={true}
                                contentStyles="w-80 text-left mr-2"
                              />
                            ) : (
                              <Fragment>
                                {!completedTasks && (
                                  <div className='mt-3'>
                                    <p className="fs-35 fw-600">{noTasksMessageTeam.title}</p>
                                    <p className="fs-16 mt-1">{noTasksMessageTeam.msg}</p>
                                    <div className="mt-2">
                                    <div
                                      className="btn__secondary_green cursor flex align-center shadow_new__green__btn justify-center w-200px text-center"
                                      onClick={(e) => handleAdd(e)}
                                    >
                                      <span className="fs-16">
                                        <FormattedMessage id="create.new.task" defaultMessage="Create New Task" />
                                      </span>
                                    </div>
                                  </div>
                                    <img src={noTaskImg} className="mt-2" alt={"icon"} />
                                  </div>
                                )}
                              </Fragment>
                            )}
                          </div>
                        ) : (
                          <Fragment>
                            <div className="flex align-center mt-3">
                              <img src={searchImg} className="mr-2 ml-2 transform-50" width="47" alt={"icon"} />
                              <p className="fs-25 fw-600">{noSearchResults.msg}</p>
                            </div>
                            <p className="fs-16 ml-5 pl-4">{noSearchResults.msg2}</p>
                          </Fragment>
                        )}
                      </Fragment>
                    ) : null)
                    }

                    {company.plan !== 1 ? (
                      <Fragment>
                        {!completedTasks ? (
                          taskView.value === 1 || taskView.value === 4 ? (
                            todos.length >= 0 && (
                              <>
                                <ListItem
                                  todos={todos}
                                  user={user}
                                  formData={formData}
                                  setFormData={setFormData}
                                  searchKeyword={searchKeyword.length > 0 && searchKeyword}
                                  todoBoard={1}
                                  usersOptions={usersOptions}
                                  okrOptions={okrOptions}
                                  priorityOptions={priorityOptions}
                                  handleSelect={handleSelect}
                                  handleChangeForm={handleChangeForm}
                                  handleDueIn={handleDueIn}
                                  onSubmit={onSubmit}
                                  clearTodo={clearTodo}
                                  handleDelete={handleDelete}
                                  handleAdd={handleAdd}
                                  handleComplete={handleComplete}
                                  handleNameForm={handleNameForm}
                                />
                                {completedTodos.length > 0 && completedTasks && (
                                  <ListItem
                                    todos={completedTodos}
                                    user={user}
                                    formData={formData}
                                    setFormData={setFormData}
                                    searchKeyword={searchKeyword.length > 0 && searchKeyword}
                                    todoBoard={99}
                                    usersOptions={usersOptions}
                                    okrOptions={okrOptions}
                                    priorityOptions={priorityOptions}
                                    handleSelect={handleSelect}
                                    handleChangeForm={handleChangeForm}
                                    handleDueIn={handleDueIn}
                                    onSubmit={onSubmit}
                                    clearTodo={clearTodo}
                                    handleDelete={handleDelete}
                                    handleComplete={handleComplete}
                                    handleNameForm={handleNameForm}
                                  />
                                )}
                              </>
                            )
                          ) : objectives.length > 0 ? (
                            <Fragment>
                              {isNewTask && (
                                <div className="my-2">
                                  <TaskItemCardForm
                                    todo={todos && todos[0]}
                                    formData={formData}
                                    setFormData={setFormData}
                                    searchKeyword={searchKeyword}
                                    usersOptions={usersOptions}
                                    okrOptions={okrOptions}
                                    priorityOptions={priorityOptions}
                                    handleChangeForm={handleChangeForm}
                                    clearTodo={clearTodo}
                                    handleDueIn={handleDueIn}
                                    onSubmit={onSubmit}
                                    index={0}
                                    krId={0}
                                  />
                                </div>
                              )}
                              <div className="my-2">
                                {objectives.map((item) => (
                                  <OkrItem
                                    key={`okr-${item._id}`}
                                    okr={item}
                                    todos={
                                      todos &&
                                      todos.filter(
                                        (todo) =>
                                          todo && todo.OKR && todo.OKR.okrId === item._id && !todo.isNew
                                      )
                                    }
                                    newTodos={todos && todos.filter((todo) => todo && todo.isNew)}
                                    user={user}
                                    formData={formData}
                                    setFormData={setFormData}
                                    searchKeyword={searchKeyword.length > 0 && searchKeyword}
                                    todoBoard={10}
                                    usersOptions={usersOptions}
                                    okrOptions={okrOptions}
                                    priorityOptions={priorityOptions}
                                    handleSelect={handleSelect}
                                    handleChangeForm={handleChangeForm}
                                    handleDueIn={handleDueIn}
                                    onSubmit={onSubmit}
                                    clearTodo={clearTodo}
                                    handleDelete={handleDelete}
                                    handleAdd={handleAdd}
                                    handleComplete={handleComplete}
                                    handleNameForm={handleNameForm}
                                    listView={listView}
                                    handleAddKr={handleAddKr}
                                  />
                                ))}
                              </div>
                            </Fragment>
                          ) : (
                            <Fragment>
                              {/* <NoContent
                                message={noTasksMessageTeam}
                                picture={updateImg}
                                alt={'Notifications: no OKRs - create OKR'}
                              /> */}

                              {todos.length > 0 ? (
                                <ListItem
                                  todos={todos}
                                  user={user}
                                  formData={formData}
                                  setFormData={setFormData}
                                  searchKeyword={searchKeyword.length > 0 && searchKeyword}
                                  todoBoard={1}
                                  usersOptions={usersOptions}
                                  okrOptions={okrOptions}
                                  priorityOptions={priorityOptions}
                                  handleSelect={handleSelect}
                                  handleChangeForm={handleChangeForm}
                                  handleDueIn={handleDueIn}
                                  onSubmit={onSubmit}
                                  clearTodo={clearTodo}
                                  handleDelete={handleDelete}
                                  handleAdd={handleAdd}
                                  handleComplete={handleComplete}
                                  handleNameForm={handleNameForm}
                                />
                              ) : (
                                <Fragment>
                                  {search.length > 0 && (
                                    <div className="flex align-center">
                                      <img src={searchImg} className="mr-2 ml-2 transform-50" width="47" alt={"icon"} />
                                      <p className="fs-25 fw-600">{noSearchResults.msg}</p>
                                    </div>
                                  )}
                              </Fragment>
                              )}
                            </Fragment>
                          )
                        ) : (
                          <Fragment>
                            {isNewTask && (
                              <div className="my-2">
                                <TaskItemCardForm
                                  todo={todos && todos.length > 0 && todos[0].isNew ? todos[0] : false}
                                  formData={formData}
                                  setFormData={setFormData}
                                  searchKeyword={searchKeyword}
                                  usersOptions={usersOptions}
                                  okrOptions={okrOptions}
                                  priorityOptions={priorityOptions}
                                  handleChangeForm={handleChangeForm}
                                  clearTodo={clearTodo}
                                  handleDueIn={handleDueIn}
                                  onSubmit={onSubmit}
                                  index={0}
                                  krId={0}
                                />
                              </div>
                            )}
                            {completedTodos.length > 0 ? (
                              <ListItem
                                todos={completedTodos}
                                user={user}
                                formData={formData}
                                setFormData={setFormData}
                                searchKeyword={searchKeyword.length > 0 && searchKeyword}
                                todoBoard={99}
                                usersOptions={usersOptions}
                                okrOptions={okrOptions}
                                priorityOptions={priorityOptions}
                                handleSelect={handleSelect}
                                handleChangeForm={handleChangeForm}
                                handleDueIn={handleDueIn}
                                onSubmit={onSubmit}
                                clearTodo={clearTodo}
                                handleDelete={handleDelete}
                                handleComplete={handleComplete}
                                handleNameForm={handleNameForm}
                              />
                            ) : (
                              <div className='mt-3'>
                                <p className="fs-35 fw-600">{noCompletedTasksMessageTeam.title}</p>
                                <p className="fs-16 mt-1">{noCompletedTasksMessageTeam.msg}</p>
                                <div className="mt-2">
                                <div
                                  className="btn__secondary_green cursor flex align-center shadow_new__green__btn justify-center w-200px text-center"
                                  onClick={(e) => handleAdd(e)}
                                >
                                  <span className="fs-16">
                                    <FormattedMessage id="create.new.task" defaultMessage="Create New Task" />
                                  </span>
                                </div>
                              </div>
                                <img src={noTaskImg} className="mt-2" alt={"icon"} />
                              </div>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      <div className="mt-2">
                        <NoContentNew
                          message={updatePlanMsg}
                          picture={taskImg}
                          alt={'Upgrade plan'}
                          imgWidth={'w-100'}
                          btnCta={taskCopy.updatePlanMsg.btnTxt}
                          divStyles="row-reverse"
                          isTask={true}
                          contentStyles="w-80 text-left mr-2"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

Task.propTypes = {
  getCompanyProfiles: PropTypes.func.isRequired,
  getCompanyTasks: PropTypes.func.isRequired,
  getCompanySortedOkrs: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  addTodo: PropTypes.func.isRequired,
  completeTodo: PropTypes.func.isRequired,
  deleteTodo: PropTypes.func.isRequired,
  getAllIndividualUserOkrs: PropTypes.func.isRequired,
  updateTodo: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  changeTaskFilters: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  todo: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  todo: state.todo,
  locale: state.locale,
  taskFilters: state.todo.filters,
  okr: state.okr
});

export default connect(mapStateToProps, {
  getUserCompany,
  getCompanyProfiles,
  getCompanyTasks,
  getCompanySortedOkrs,
  updateTodo,
  addTodo,
  deleteTodo,
  completeTodo,
  setAlert,
  changeTaskFilters,
  getAllIndividualUserOkrs
})(Task);
