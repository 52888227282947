import React from "react";
import { FormattedMessage } from 'react-intl';

function compareDay(a, b) {
  if (a.scheduleDay === undefined || !a.scheduleDay.index) { return -1 }
  if (b.scheduleDay === undefined || !b.scheduleDay.index) { return -1 }

  if (a.scheduleDay.index < b.scheduleDay.index){
    return -1;
  }
  if (a.scheduleDay.index > b.scheduleDay.index){
    return 1;
  }
  return 0;
}

function compareTime(a, b) {
  if (a.scheduleDay === undefined  || !a.scheduleDay.index || !a.scheduleTime) { return -1 }
  if (b.scheduleDay === undefined  || !b.scheduleDay.index || !b.scheduleTime) { return -1 }
  if (a.scheduleDay.index === b.scheduleDay.index){
      if (a.scheduleTime < b.scheduleTime){ 
        return -1;
      }
      if (a.scheduleTime > b.scheduleTime){ 
        return 1;
      }
    return 0;
  }
}

const OneDashboard = ({ profiles, user, handleUserClick, locale }) => {
  
  function getProfileName(nextMeeting) {
    let nextMeetingUser = {};

    if (nextMeeting) {
      if (user._id === nextMeeting.creator.user) {
        nextMeetingUser = nextMeeting.participant;
        nextMeetingUser.meetingId = nextMeeting._id;
        nextMeetingUser.label = `${nextMeeting.participant.firstName} ${nextMeeting.participant.lastName}`;
      } else if (user._id === nextMeeting.participant.user) {
        nextMeetingUser = nextMeeting.creator;
        nextMeetingUser.meetingId = nextMeeting._id;
        nextMeetingUser.label = `${nextMeeting.creator.firstName} ${nextMeeting.creator.lastName}`
      }
    }

    return nextMeetingUser;
  }

  let profilesSort = profiles.sort(compareDay);
  profilesSort.sort(compareTime);

  let nextMeeting = profilesSort.length > 0 && profilesSort[0];
  let nextMeetingUser = getProfileName(nextMeeting);

  let msg1 = <FormattedMessage id="one.msg1" defaultMessage="You have meetings with" />
  let msg2 = <FormattedMessage id="one.msg2" defaultMessage="teammates" />
  let nextMsg = <FormattedMessage id="next.meetings" defaultMessage="Next meeting" />
  let scheduleMsg = <FormattedMessage id="schedule" defaultMessage="Schedule" />

  let atMsg = "at"
  let withMsg = "with"
  let meetingWithoutScheduleMsg = "Meeting without schedule"

  if (locale.lang === 'ru') {
    atMsg = "в"
    withMsg = "с"
    meetingWithoutScheduleMsg = "Встреча без расписания"
  }

  return (
     <div className="one__dashboard my-2 p-3">
       <p className="medium pb-2">{msg1}{" "}<span className="medium bold">{profiles && profiles.length}</span>{" "}{msg2}.</p>
       <p className="medium bold mb-0">{nextMsg}:<span className="medium">{" "}{nextMeeting && nextMeeting.scheduleDay && nextMeeting.scheduleDay.day}{" "}{atMsg}{" "}{nextMeeting && nextMeeting.scheduleTime}{" "}{withMsg}{" "}<span className="cursor bold" onClick={() => handleUserClick(nextMeetingUser, nextMeeting._id)}>{nextMeetingUser.label}</span></span></p>
       <div className="one__dashboard_schedule py-2">
          <p className="bold medium">{scheduleMsg}</p>
          {profilesSort && profilesSort.length > 0 && profilesSort.map(profile => (
            <p className="medium" key={profile._id}>-{" "}{profile && profile.scheduleDay !== undefined && profile.isAutomation ? (`${profile.scheduleDay.day} ${atMsg} ${profile.scheduleTime}`) : (meetingWithoutScheduleMsg)}{" "}{withMsg}{" "}
                <span 
                  className="medium bold mb-0 cursor"
                  onClick={() => handleUserClick(getProfileName(profile), profile._id)}
                >
                  {getProfileName(profile).label}
                  </span>
            </p>
         ))}
       </div>
     </div>
  );
};

export default OneDashboard;
