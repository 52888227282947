import {
  GET_PLANS,
  PLANS_ERROR,
  CLEAR_PLANS,
  GET_PLANS_SETTINGS,
  PLANS_SETTINGS_ERROR
} from '../actions/types';

const initialState = {
  plans: [],
  plan: null,
  settings: null,
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PLANS:
      return {
        ...state,
        plans: payload,
        loading: false
      };
    case GET_PLANS_SETTINGS:
      return {
        ...state,
        settings: payload,
        loading: false
      };
    case PLANS_SETTINGS_ERROR:
    case PLANS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case CLEAR_PLANS:
      return {
        ...state,
        plans: [],
        plan: null,
        settings: null,
        loading: true
      };
    default:
      return state;
  }
}
