import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import PlanItemCard from './PlanItemCard';

const PlanItem = ({ 
  planAnswers, planDate, 
  formData, setFormData, searchKeyword, 
  settingsID, auth, mentions,
  campaignType,
 }) => {
  return (
    <Fragment>
          {planAnswers.map((planAnswer, index) => (
            <div className="p-2 br-4 mb-14 brd brd-grey__light bg__white" key={index}>
              <div className="cards">
                <PlanItemCard
                  planAnswer={planAnswer}
                  key={`team-${planAnswer._id}-${index}`}
                  formData={formData}
                  setFormData={setFormData}
                  searchKeyword={searchKeyword}
                  settingsID={settingsID}
                  auth={auth}
                  mentions={mentions}
                  campaignType={campaignType}
                />
              </div>
            </div>
          ))}
    </Fragment>
  );
};

PlanItem.propTypes = {
  planAnswers: PropTypes.array.isRequired
};

export default PlanItem;
