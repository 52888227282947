import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../img/logo/logo.svg';

const Logo = ({ isNoLink }) => {
  return (
    <Fragment>
      {!isNoLink || isNoLink === undefined ? (
        <div className="logo">
          <Link to="/dashboard/objectives">
            <img
              src={logo}
              alt="Focus logo"
              className="logo_img"
              width="122"
              height="35"
            />
          </Link>
        </div>
      ) : (
        <div className="logo">
          <img
            src={logo}
            alt="Focus logo"
            className="logo_img"
            width="122"
            height="35"
          />
        </div>
      )}
    </Fragment>
  );
};

export default Logo;
