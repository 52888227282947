import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import OneNotes from './OneNotes';
import TodoItem2CardForm from '../../elems/TodoItem2CardForm.js';
import AddTodoBtn from '../../elems/AddTodoBtn';
import Fs2Title from '../../elems/Fs2Title';
import { FormattedMessage } from 'react-intl';

const PrivateNotes = ({
  formData,
  handleSelect,
  user,
  handleDelete,
  handleNameForm,
  handleChangeForm,
  handleChangeFormState,
  clearTodo,
  isForm,
  currentDoc,
  handleAdd,
  onSubmitTodo,
}) => {

  let privateNotesTitle = <FormattedMessage id='private.notes' defaultMessage='Private Notes' />;
  let privateNotesMsg = <FormattedMessage id='private.notes.desc' defaultMessage='It is private notes. Only you see these notes.' />;

   return (
    <div className='private-notes my-3'>
      <div className='mb-1'>
        <Fs2Title title={privateNotesTitle} />
      </div>

      <div className="bg-dark px-2 py-1 br-8 mb-2">
        <p className="small white text-left">{privateNotesMsg}</p>
      </div>

      <Fragment>
        {formData.privateNotes &&
          formData.privateNotes.length > 0 &&
          formData.privateNotes.map((item, index) => (
            <div key={`private-notes_1_${index}-${!item.isNew ? 'note' : 'form'}`}>
              {!item.isNew && item.user === user._id ? (
                <OneNotes
                  item={item}
                  handleSelect={handleSelect}
                  index={index}
                  user={user}
                  handleDelete={handleDelete}
                  handleNameForm={handleNameForm}
                  handleChangeForm={handleChangeForm}
                  handleChangeFormState={handleChangeFormState}
                  clearTodo={clearTodo}
                  isForm={isForm}
                  name={'privateNotes'}
                  inputName={'text'}
                  isTodo={false}
                />
              ) : (
                item.user === user._id &&
                  <TodoItem2CardForm
                    item={item}
                    formData={formData}
                    handleChangeForm={handleChangeForm}
                    handleChangeFormState={handleChangeFormState}
                    clearTodo={clearTodo}
                    onSubmit={onSubmitTodo}
                    oneId={currentDoc ? currentDoc._id : 0}
                    index={index}
                    addMsg={'private note'}
                    name={'privateNotes'}
                    inputName={'text'}
                    isTodo={false}
                    isAssignee={false}
                    isDueIn={false}
                    width={'w-90 mr-2'}
                    textarea={true}
                  />
              )}
            </div>
          ))}
        <AddTodoBtn handleAdd={handleAdd} name={'privateNotes'} addMsg={'private note'} />
      </Fragment>
    </div>
  );
};

PrivateNotes.propTypes = {
  formData: PropTypes.object.isRequired
};


export default PrivateNotes;
