import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Select from 'react-select';

const BinaryForm = ({ 
  keyResult, index, handleKeyResults, 
  selectCustomStyles,
  krLabels
 }) => {
  
  let binaryOptions = [
    { value: 0, label: krLabels.binaryLabel.false, name: 'details' },
    { value: 100, label: krLabels.binaryLabel.true, name: 'details' },
  ]

  return (
    <Fragment>

    <div className="okr__kr-current labeled-wrapper w-200px ml-1">
      <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
        <FormattedMessage
          id="user.okr.create.keyResult.current"
          defaultMessage="Current"
        />
      </label>
       <FormattedMessage
          id="choose.status"
          defaultMessage="Choose the status"
        >
          {(msg) => (
            <Select
              className="w-100"
              defaultValue={binaryOptions[0]}
              value={keyResult.details}
              name="details"
              onChange={(selectedOption) =>
                handleKeyResults(selectedOption, index, 'binary')
              }
              options={binaryOptions}
              isSearchable
              placeholder={msg}
              required
              styles={selectCustomStyles}
            />
          )}
        </FormattedMessage>
      </div>
    </Fragment>
  );
};

BinaryForm.propTypes = {
  keyResult: PropTypes.object.isRequired,
  handleKeyResults: PropTypes.func.isRequired,
};

export default BinaryForm;
