import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Menu from '../layout/Menu';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import { getCompanyProfiles, getProfileById } from '../../actions/profile';
import { getUserCompany } from '../../actions/company';
import MainWidget from '../utils/MainWidgetNew';
import OkrCardItem from '../okrs/noState/OkrCardItem';
import UserTitle from './UserTitle';
import SubMenu from './SubMenu';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/1.jpg';
import { getUserOkrsById, clearOkrs } from '../../actions/okr';
import { FormattedMessage } from 'react-intl';

const UserOkrs = ({
  clearOkrs,
  getUserOkrsById,
  getProfileById,
  getUserCompany,
  getCompanyProfiles,
  okr: { okrs, loading },
  auth: { user },
  company: { company, team },
  profile: { profiles, profile },
  match,
}) => {
  useEffect(() => {
    getUserCompany();
    getCompanyProfiles();
    getProfileById(match.params.id);
    getUserOkrsById(match.params.id);

    // Clear state
    return () => {
      clearOkrs();
    }
  }, [getUserCompany, getCompanyProfiles, getUserOkrsById, getProfileById]);

  const updatePlanMsg = {
    title: (
      <FormattedMessage
        id="company.updateplan.title"
        defaultMessage="Update Plan"
      />
    ),
    msg: (
      <FormattedMessage
        id="company.okrs.updateplan.msg"
        defaultMessage={`The Free plan is limited by 4 users in the company. You have ${profiles && profiles.length} active users today. Please upgrade the plan to use Focus for the team with 5 and more users.`}
      />
    )
  };

  const noOkrMessageTeam = {
    title: <FormattedMessage id="nookrs.title" defaultMessage="No Objectives" />,
    msg: 
    <FormattedMessage id="nookrs.msg" defaultMessage={`${profile && profile.firstName} has not created any objective yet.`} />
  };

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {user && user.department ? (
          <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user && user.isAdmin && true} activeIndex={8} />
        ) : (
          <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} activeIndex={8} />
        )}

        <UserNav />
          <Fragment>
            {loading || !okrs || !user || !company || !profile  ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="main main-dashboard">
                     <h5>
                      <FormattedMessage id="team.header.okrs" defaultMessage={`${profile.firstName}'s Objectives`} />
                    </h5>
                  <SubMenu userId={profile._id} companyIndex={3} />
                  <div className="okrs">
                    <div className="my-4">
                      {okrs.length > 0 && (
                        okrs.map(okr => (
                          okr.isMain && okr.type === 3 && <MainWidget key={`main-${okr._id}`} okr={okr} />
                          )
                        ))} 
                    </div>

                      {company.plan === 1 ? (
                        <NoContent 
                          message={updatePlanMsg}
                          picture={updateImg}
                          alt={'Update plan'}
                          admin={user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0}
                        />
                      ) : (
                        <Fragment>
                          {okrs.length === 0 ? (
                            <div className="mt-5">
                              <NoContent 
                                message={noOkrMessageTeam}
                                picture={updateImg}
                                alt={'No OKRs'}
                              />
                            </div>
                          ) : (
                            <Fragment> 
                                  {okrs.length > 0 && (
                                    okrs.map(okr => (
                                    <div className="" key={`okr-item-${okr._id}`}>
                                      <div className="cards py-2">
                                        <OkrCardItem
                                        key={okr._id}
                                        okr={okr}
                                        okrBoard={'1'}
                                      />
                                    </div>
                                  </div>
                                  )))}
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

UserOkrs.propTypes = {
  getCompanyProfiles: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getUserOkrsById: PropTypes.func.isRequired,
  getProfileById: PropTypes.func.isRequired,
  clearOkrs: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  okr: state.okr
});

export default connect(
  mapStateToProps,
  { getUserCompany, getCompanyProfiles, getUserOkrsById, getProfileById, clearOkrs }
)(UserOkrs);
