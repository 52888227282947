import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import QuestionItemAnswer from "./QuestionItemAnswer";

const QuestionItem = ({ question, answer, _id, index, searchKeyword }) => {

  return (
    <Fragment>
      <p className="medium bold">
        <strong>{question.text}</strong>
      </p>
      <div className="card-answer">
        <ul className="answer-item">
            {answer.text.map((text, i) => (
              <QuestionItemAnswer
                key={`${text._id}-${i}`}
                index={index}
                text={text}
                searchKeyword={searchKeyword}
                isNumber={answer.isNumber}
              />
            ))}
        </ul>
      </div>
    </Fragment>
  );
};

QuestionItem.propTypes = {
  question: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  avatar: PropTypes.string.isRequired
};

export default QuestionItem;
