import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const QuestionItem = ({
  onChange,
  text,
  name,
  value,
  isNotQuestion,
  inputID,
  inputText,
  stylesDiv,
  stylesText,
  stylesInput,
  inputName,
}) => {

  return (
    <div
      className={`form-main form-main-inline ${stylesDiv}`}
    >
      <p className={`bold medium block question ${stylesText}`}>
        {text}
      </p>
      <FormattedMessage id="add.text" defaultMessage="Add text">
        {msg => <input
          required
          className={`question-input ${stylesInput}`}
          type="text"
          placeholder={msg}
          name={inputName}
          value={value || ''}
          onChange={e => onChange(e, name)}
        />}
      </FormattedMessage>
    </div>
  );
};

QuestionItem.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.object,
};

export default QuestionItem;
