import React from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import isEnabled, { ENGAGEMENT } from '../../utils/features';

const TeamMenu = ({
  company: { company },
  companyIndex
}) => {
  return (
    <div className="sub-menu__page mt-2 mb-3">
      <Link
        to={'/team/checkins'}
        className={`medium sub-menu__item inline-block mr-2 mr-3-xs cursor ${companyIndex === 1 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="menu.checkins" defaultMessage="Check-ins" />
      </Link>
      <Link
        to={'/team/updates'}
        className={`medium sub-menu__item inline-block mr-2 mr-3-xs cursor ${companyIndex === 2 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="menu.updates.new" defaultMessage="Weekly Updates" />
      </Link>
      
      <Link
        to={'/team/objectives'}
        className={`medium sub-menu__item inline-block mr-2 mr-3-xs cursor ${companyIndex === 3 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="menu.okrs.new" defaultMessage="Objectives" />
      </Link>
      {isEnabled(ENGAGEMENT, { company }) && (
      <Link
        to={'/team/kudos'}
        className={`medium sub-menu__item inline-block mr-2 mr-3-xs cursor ${companyIndex === 4 && ' sub-menu__item_active'}`}
      >
        <FormattedMessage id="menu.kudos" defaultMessage="Kudos" />
      </Link>
      )}
    </div>
  );
};

TeamMenu.propTypes = {
  companyIndex: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
  company: state.company
});

export default connect(mapStateToProps)(TeamMenu);
