import axios from 'axios';
import { setAlert } from './alert';
import store from '../store';
import { updateHomeLikeAnswer, addHomeCommentAnswer, deleteHomeCommentAnswer } from './home';

import {
  GET_USER_STANDUP_ANSWERS,
  GET_PROFILE_STANDUP_ANSWERS,
  GET_TEAM_STANDUP_ANSWERS,
  GET_STANDUP_ANSWER,
  STANDUP_ANSWER_ERROR,
  STANDUP_ANSWERS_ERROR,
  ADD_STANDUP_ANSWER,
  EDIT_STANDUP_ANSWER,
  UPDATE_STANDUP_LIKES,
  ADD_COMMENT,
  UPDATE_COMMENT,
  GET_COMPANY_STANDUP_ANSWERS,
  COMPANY_STANDUP_ANSWER_ERROR,
  REMOVE_COMMENT,
  CLEAR_STANDUP_ANSWERS,
  LOCATION_CHANGE_ERROR,
  GET_COMPANY_STANDUP_ANSWERS_SETTINGS,
  GET_STANDUP_ANSWER_BY_ID
} from './types';

// Get all current user answers
export const getUserAnswers = () => async dispatch => {
  try {
    const res = await axios.get('/api/standupanswers/user');

    dispatch({
      type: GET_USER_STANDUP_ANSWERS,
      payload: { answers: res.data.userAnswers, last: res.data.lastAnswer }
    });
  } catch (err) {
    dispatch({
      type: STANDUP_ANSWERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get the last answer from current user 
export const getUserAnswer = () => async dispatch => {
  try {
    const res = await axios.get('/api/standupanswers/last');

    dispatch({
      type: GET_STANDUP_ANSWER,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: STANDUP_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all user team answers
export const getTeamAnswers = () => async dispatch => {
  try {
    const res = await axios.get('/api/standupanswers/team');

    dispatch({
      type: GET_TEAM_STANDUP_ANSWERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: STANDUP_ANSWERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all company answers
export const getCompanyAnswers = ({limit,user}={}) => async dispatch => {
  try {
    const params = {};
    if(limit) params.limit = limit;
    if(user)params.user = user;
    const res = await axios.get(`/api/standupanswers/company`,{params});

    dispatch({
      type: GET_COMPANY_STANDUP_ANSWERS,
      payload: { company: res.data.companyAnswers, user: res.data.userAnswer }
    });
    return true;
  } catch (err) {
    dispatch({
      type: COMPANY_STANDUP_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    return false
  }
};

// Get answer by id
export const getAnswerById = (id) => async dispatch => {
  try {
    const res = await axios.get(`/api/standupanswers/${id}`);

    dispatch({
      type: GET_STANDUP_ANSWER_BY_ID,
      payload: res.data
    });
    return true;
  } catch (err) {
    dispatch({
      type: STANDUP_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    return false
  }
};

// Get all company answers in Settings
export const getCompanyAnswersSettings = () => async dispatch => {
  try {
    const res = await axios.get('/api/standupanswers/company');

    dispatch({
      type: GET_COMPANY_STANDUP_ANSWERS_SETTINGS,
      payload: { company: res.data.companyAnswers, user: res.data.userAnswer }
    });
  } catch (err) {
    dispatch({
      type: COMPANY_STANDUP_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get user answers by user ID
export const getUserAnswersById = id => async dispatch => {
  try {
    
    const res = await axios.get(`/api/standupanswers/user/${id}`);

    dispatch({
      type: GET_PROFILE_STANDUP_ANSWERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: STANDUP_ANSWERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all team answers by team ID
export const getTeamAnswersById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/standupanswers/team/${id}`);

    dispatch({
      type: GET_TEAM_STANDUP_ANSWERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: STANDUP_ANSWERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add answer on standup
export const addAnswer = (answers, focus, standupID, history, notes) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({
      answers, 
      focus,
      notes
    });

    const res = await axios.post(
      `/api/standupanswers/${standupID}`,
      body,
      config
    );

    dispatch({
      type: ADD_STANDUP_ANSWER,
      payload: res.data
    });

    dispatch(setAlert('Check-in Completed', 'success', 3000));

    // if (history) {
    //   history.goBack();
    // } else {
    //   history.push('/campaigns/checkins')
    // }
    history.push('/campaigns/checkins')

    let data = { ok: true };

    return data;
  } catch (err) {
    const errors = err.response.data.errors;
  
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    
    dispatch({
      type: STANDUP_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const editAnswer = ({answers, focus, answerId, notes}) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {

    const res = await axios.put(
      `/api/standupanswers/${answerId}`,
      {
        answers, 
        focus,
        notes
      },
      config
    );

    dispatch({
      type: EDIT_STANDUP_ANSWER,
      payload: res.data
    });

    dispatch(setAlert('Updated', 'success', 3000));

   return true;
  } catch (err) {
    dispatch({
      type: STANDUP_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    return false;
  }
};

// Add & Remove like
export const updateLike = (standupID, answerID) => async dispatch => {
  try {
    const res = await axios.put(
      `/api/standupanswers/like/${standupID}/${answerID}`
    );

    dispatch({
      type: UPDATE_STANDUP_LIKES,
      payload: { standupID, likes: res.data.likes, answers: res.data.answers }
    });

    const home = store.getState().home;

    // Обновляем редюсер Home, если он не пустой
    // https://stackoverflow.com/questions/35667249/accessing-redux-state-in-an-action-creator
    // https://redux.js.org/api/store#dispatchaction
    if (home.data.length > 0) {
      
      store.dispatch(updateHomeLikeAnswer({ id: standupID, likes: res.data.likes, answers: res.data.answers }))

      // Можно вызывать диспатч home сразу, но так мы не отследим ошибку. Выигрыша в скорости на 1-2 лайках не заметил. 
      // dispatch({
      //   type: UPDATE_HOME_LIKES_ANSWER,
      //   payload: { id: standupID, likes: res.data.likes, answers: res.data.answers }
      // });
    }

    // Callback for updating in formData.checkins in useState to render it
    const data = { standupID, likes: res.data.likes, answers: res.data.answers }
       
    return data;
  } catch (err) {
    dispatch({
      type: STANDUP_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add comment
export const addComment = (standupID, answerID, formData) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const res = await axios.post(
      `/api/standupanswers/comment/${standupID}/${answerID}`,
      formData,
      config
    );

    dispatch({
      type: ADD_COMMENT,
      payload: {
        standupID,
        comments: res.data.comments,
        answers: res.data.answers
      }
    });

    const home = store.getState().home;

    // Обновляем редюсер Home, если он не пустой
    if (home.data.length > 0) {
      store.dispatch(addHomeCommentAnswer({ id: standupID, comments: res.data.comments, answers: res.data.answers }))
    }

    // Callback for updating formData.checkins in useState to render it
    const data = { standupID, comments: res.data.comments, answers: res.data.answers }
    return data;

  } catch (err) {
    dispatch({
      type: STANDUP_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit comment
export const editComment = (
  standupID,
  commentID,
  formData
) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const res = await axios.put(
      `/api/standupanswers/comment/${standupID}/${commentID}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_COMMENT,
      payload: {
        standupID,
        comments: res.data
      }
    });
  } catch (err) {
    dispatch({
      type: STANDUP_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete comment
export const deleteComment = (
  standupID,
  answerID,
  commentID
) => async dispatch => {
  try {
    const res = await axios.delete(
      `/api/standupanswers/comment/${standupID}/${answerID}/${commentID}`
    );

    dispatch({
      type: REMOVE_COMMENT,
      payload: {
        standupID,
        commentID,
        answers: res.data.answers
      }
    });

    const home = store.getState().home;

    // Обновляем редюсер Home, если он не пустой
    if (home.data.length > 0) {
      store.dispatch(deleteHomeCommentAnswer({ id: standupID, commentID, answers: res.data.answers }))
    }

    // Callback for updating in formData.checkins in useState to render it
    const data = { standupID, commentID, answers: res.data.answers }
    return data;

  } catch (err) {
    dispatch({
      type: STANDUP_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Clear state
export const clearStandups = () => async dispatch => {
  try {
    
    dispatch({
      type: CLEAR_STANDUP_ANSWERS
    });
  } catch (err) {
    dispatch({
      type: LOCATION_CHANGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};