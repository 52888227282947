import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { setAlert } from '../../../actions/alert';
import { connect } from 'react-redux';
import { addUpdateComment } from '../../../actions/updateAnswer';
import { FormattedMessage } from 'react-intl';
import InputNew from '../../elems/InputNew';

const CommentFormNew = ({
  updateID,
  answerID,
  addUpdateComment,
  user,
  avatar
}) => {
  const [text, setText] = useState('');

  return (
    <form
      className="form-app form-app-comments"
      onSubmit={e => {
        e.preventDefault();
        addUpdateComment(updateID, answerID, { text });
        setText('');
      }}
    >
      <InputNew 
        setText={setText}
        text={text}
      />
    </form>
  );
};

CommentFormNew.propTypes = {
  addUpdateComment: PropTypes.func.isRequired
};

export default connect(
  null,
  { addUpdateComment }
)(CommentFormNew);
