import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addComment } from '../../../actions/standupAnswer';

import { FormattedMessage } from 'react-intl';

const CommentForm = ({ standupID, answerID, addComment, avatar }) => {
  const [text, setText] = useState('');

  return (
    <form
      className="form-app form-app-comments"
      onSubmit={e => {
        e.preventDefault();
        addComment(standupID, answerID, { text });
        setText('');
      }}
    >
      <img
        src={avatar}
        alt="Avatar"
        className="round-img d-block avatar"
        width="45px"
        height="45px"
      />
      <div className="form-comment">
        <FormattedMessage id="add.comment" defaultMessage="Add a comment">
          {msg => (
            <textarea
              type="text"
              rows={3}
              placeholder={msg}
              value={text}
              onChange={e => setText(e.target.value)}
              required
            />
          )}
        </FormattedMessage>
        <button
          type="submit"
          className="btn-secondary btn-secondary-comment hide-sm"
        >
          <FormattedMessage id="btn.submit" defaultMessage="Send" />
        </button>
        <button type="submit" className="btn-mobile-comment hide-large">
          <FormattedMessage id="btn.mobile.send" defaultMessage="Send" />
        </button>
      </div>
    </form>
  );
};

CommentForm.propTypes = {
  addComment: PropTypes.func.isRequired
};

export default connect(
  null,
  { addComment }
)(CommentForm);
