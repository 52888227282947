import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import UserNav from '../layout/UserNav';
import MainMenu from '../layout/Sidebar';
import SubMenu from './SubMenu';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { getUserCompany, deleteCompany } from '../../actions/company';
import { FormattedMessage } from 'react-intl';
import { getCompanyProfiles } from '../../actions/profile';

const AdminSettings = ({
  getUserCompany,
  getCompanyProfiles,
  deleteCompany,
  profile: { profiles },
  company: { company },
  auth: { loading, user, sidebar },
}) => {
  useEffect(() => {
    getUserCompany();
    getCompanyProfiles();
  }, [
    getUserCompany,
    getCompanyProfiles
  ]);

  const deleteCopy = `Please prove that you want to lost all your company's data. We will not be able to restore data if you delete it now. Are you sure that you want to delete company's workspace forever?`;


  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">
          
          {user && !loading && (
            <MainMenu activeIndex={9} />
          )}

          <UserNav />

          <Fragment>
            {loading || !company || !profiles || user && !user.isAdmin ? (
              <Spinner />
            ) : (
              <Fragment>
                {/* <div className="main main-dashboard main-profile-admin"> */}
                <div className={`${sidebar === 1 ? 'main main-dashboard main-profile-admin ' : 'short_menu__ml__okrs ma-w90 pt-2'} mt-3`}>

                  <h5 className="pl-0-xs">
                    <FormattedMessage
                      id="workspace.settings"
                      defaultMessage="Workspace Settings"
                    />
                  </h5>

                  <SubMenu activeIndex={6} />

                  <div className="flex align-center">
                    <p className="bold-pure medium">
                      <FormattedMessage
                        id="workspace.delete"
                        defaultMessage="Delete company's workspace"
                      />
                    </p>
                    <button
                      type="button"
                      className="mla btn-secondary btn-secondary-focus btn-secondary-focus-delete hide-sm"
                      onClick={() => { if (window.confirm(deleteCopy)) deleteCompany() } }
                    >
                      <FormattedMessage
                        id="delete"
                        defaultMessage="Delete"
                      />
                    </button>
                  </div>

                  <p className="small w-80 text-centers">
                    <FormattedMessage
                        id="delete.attention"
                        defaultMessage="<b>Important!</b> All workspace data will be lost. All users' accounts will be deleted. All OKRs, check-ins, plans, and other data will be lost. All <b>data will be lost forever</b>."
                        values={{ b: (...chunks) => <b>{chunks}</b> }}
                      />
                  </p>
                  <p className="small w-80 text-centers">
                    <FormattedMessage
                        id="delete.attentio.msg2"
                        defaultMessage={`If you want to delete only <b>your account</b> (not company's workspace), please drop us an email on ${process.env.REACT_APP_EMAIL}. We will delete your personal account.`}
                        values={{ b: (...chunks) => <b>{chunks}</b> }}
                      />
                  </p>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

AdminSettings.propTypes = {
  getUserCompany: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  deleteCompany: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  company: state.company
});

export default connect(mapStateToProps, {
  getUserCompany,
  getCompanyProfiles,
  deleteCompany
})(AdminSettings);
