import React from "react";
import PropTypes from 'prop-types';

const SubTitle = ({ title, color }) => {

  return (
    <p className={`sub-title ${color ? `focus-${color}` : "focus-yellow"} focus`}>{title}</p>
  );
};

SubTitle.propTypes = {
  title: PropTypes.object,
  color: PropTypes.string,
};

export default SubTitle;
