import React, { Fragment, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { reset, toggleReset } from '../../actions/auth';
import Navbar from '../layout/Navbar';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ResetPassword = ({ setAlert, reset, isAuthenticated, token, toggleReset, locale: { lang } }) => {
  const [formData, setFormData] = useState({
    email: ''
  });

  const [resetForm, toggleForm] = useState(true);
  const { email } = formData;

  useEffect(() => {
    if (token === 'passwordSent') {
      toggleForm(!resetForm);
    }
  }, [token]);

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();

    reset(email);
  };

  const handleToggle = e => {
    e.preventDefault();

    toggleForm(!resetForm);

    toggleReset();
  };

  // Redirect if signed up
  if (isAuthenticated) {
    return <Redirect to="/dashboard/objectives" />;
  }

  return (
    <Fragment>
      <section id="signup">
          <div className="container pt-5">
            <div className="section_header_new mb-2">
              <Navbar guest={true} />
              <h2 className="h2_new">
                <FormattedMessage
                  id="auth.reset.header"
                  defaultMessage="Forgot password ?"
                />
              </h2>
              <p className="lead lead_new lh-22">
                <FormattedMessage
                  id="auth.reset.text"
                  defaultMessage="Please enter your email address in the field below, and we'll email you instructions on how to reset your password"
                />
              </p>
            </div>

            <div className="form form-detail form_new mb-2">
              <form onSubmit={e => onSubmit(e)}>

                <div className="field_email field mb-2_5">
                  <FormattedMessage
                    id="auth.signup.yourEmailInput"
                    defaultMessage="Your Email"
                  >
                    {msg => (
                      <input
                        type="email"
                        className="email email_new"
                        placeholder={msg}
                        name="email"
                        value={email}
                        onChange={e => onChange(e)}
                        required
                      />
                    )}
                  </FormattedMessage>
                </div>
                <div className="form_button">
                  <button type="submit" className="btn btn_new btn-primary">
                    {' '}
                    <FormattedMessage
                      id="auth.reset.btn.main"
                      defaultMessage="Reset Password"
                    />
                  </button>
                </div>
              </form>
            </div>
            <div className='section_footer_new'>
              <Link to="/login" className="medium cursor dark-gray medium_new link-auth_new">
                <FormattedMessage id="auth.goback.login" defaultMessage="Back to Login" />
              </Link>
            </div>
          </div>
      </section>
    </Fragment>
  );
};

ResetPassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  toggleReset: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  token: PropTypes.string
};

const mapStateToProps = state => ({
  locale: state.locale,
  isAuthenticated: state.auth.isAuthenticated,
  token: state.auth.token
});

export default connect(
  mapStateToProps,
  { setAlert, reset, toggleReset }
)(ResetPassword);
