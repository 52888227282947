import React, { Fragment, useMemo} from 'react';
import PropTypes from 'prop-types';
import TreeOkrItem from '../okrTree/TreeOkrItem_new';
import TreeTitle from '../okrTree/TreeTitle_new';

const TreeChild = ({
  okr: {
    _id,
    owner,
    creator,
    ownerAvatar,
    ownerFirstName,
    ownerLastName,
    dueIn,
    objective,
    team,
    teamName,
    progress,
    status,
    keyResults,
    isParent,
    childOkrs,
    isArchive,
    privacy,
    updatedAt,
  },
  handleArchive,
  handleDelete,
  archive,
  user,
  allOkrs,
  findChilds,
  parentId,
  okrs
}) => {
  let btnClass = '';
  let label = '';

  if (status === 1) {
    btnClass = 'green';
    label = 'On Track';
  } else if (status === 2) {
    btnClass = 'yellow';
    label = 'Behind';
  } else if (status === 3) {
    btnClass = 'red';
    label = 'At Risk';
  }


    // И исключаем родителя их списка ОКР сразу, чтобы не было бага, когда ребенок может стать отцом отца или деда
  // okrs = okrs && okrs.length > 0 && okrs.filter(o => o._id !== _id);

 let items= useMemo(()=>{
  console.log(_id);
 },[]) 

  let childs = useMemo(()=>{
    const childs = [];

    isParent &&
    childOkrs.length > 0 &&
    allOkrs &&
    allOkrs.length > 0 &&
    childOkrs.forEach((item) => {
      // Исключаем, чтобы ребенок не мог быть отцом своего деда или отца
      let child = allOkrs.filter((o) => o._id.toString() === item.value);

      if (child.length > 0) {
        childs.push(child[0]);
      }
    });
    
  return childs.filter(v=>(v._id!==_id));
  },[isParent,childOkrs,allOkrs,_id]);


  // ToDo: сделать сбор детей в 1ой функции (вместо способа выше): здесь и в TreeBranch (сейчас возвращает промис, а await нельзя поставить - нет async)

  const nestedItems = useMemo(()=> (childs || []).map((okr) => {
    return (
      <TreeChild
        key={`child-${okr._id}`}
        okr={okr}
        handleArchive={handleArchive}
        handleDelete={handleDelete}
        archive={archive}
        user={user}
        allOkrs={allOkrs}
        findChilds={findChilds}
      />
    );
  }),[childs]);

  let isOkrOwner = false;
  let isKrOwner = false;

  // Shorten
  let pr = privacy;

  if (creator === user._id || owner === user._id) {
    isOkrOwner = true;
  }

  let kr = keyResults && keyResults.length > 0 && keyResults.map((k) => k.owner);
  let krRes = kr && kr.length > 0 && kr.indexOf(user._id.toString());

  if (krRes > -1) {
    isKrOwner = true;
  }

  // ToDo: здесь иногда отображаются приватные ОКР (на тесте в аккаунте Тома Форда)

  return (
    <Fragment>
      <div className={`objective__child flex align-center relative pl-2 py-1`}>
        <div className="item">
          {user.isAdmin ||
          pr === 1 ||
          pr === undefined ||
          isOkrOwner ||
          isKrOwner ||
          (pr === 2 && user && user.department && user.department.toString() === team) ||
          (pr === 3 && owner === user._id) ||
          (pr === 3 && creator === user._id) ? (
            <div className="w-450px bg__white br-12 objective_tree wrap">
              <TreeTitle
                _id={_id}
                objective={objective}
                status={status}
                btnClass={btnClass}
                progress={progress}
                keyResults={keyResults}
                teamName={teamName}
                handleArchive={handleArchive}
                handleDelete={handleDelete}
                isArchive={isArchive}
                archive={archive}
                user={user}
                owner={owner}
                creator={creator}
              />
              <hr className="divider" />
              <TreeOkrItem
                updatedAt={updatedAt}
                dueIn={dueIn}
                owner={owner}
                ownerAvatar={ownerAvatar}
                ownerFirstName={ownerFirstName}
                ownerLastName={ownerLastName}
                progress={progress}
                keyResults={keyResults}
              />
            </div>
          ) : (
            ''
          )}
        </div>
        {nestedItems.length > 0 && <div className={`objective__childs relative pl-5`}>{nestedItems}</div>}
      </div>
    </Fragment>
  );
};

TreeChild.propTypes = {
  okr: PropTypes.object.isRequired,
  isCompanyParent: PropTypes.bool,
  noParents: PropTypes.bool
};

export default TreeChild;
