import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { icons } from "../../img/elements/icons";

const BtnLike = ({ likes, findUserLike, handleLike, okrId }) => {
  return (
    <Fragment>
     <button
        type="button"
        className={classnames('btn-icons btn-like flex align-center mr-1', {
          'btn-active-icon btn-like__active': findUserLike(likes)
        })}
        onClick={e => handleLike(okrId)}
      >
          <span className="mr-xs flex align-center">{icons.heart}{icons.heart_full}</span>
          <span className='fs-10 bold mb-0'>{likes.length > 0 ? likes.length : '0'}</span>
      </button>
    </Fragment>
  );
};

BtnLike.propTypes = {
  likes: PropTypes.array.isRequired,
  findUserLike: PropTypes.func.isRequired,
  findUserLike: PropTypes.func.isRequired,
};

export default BtnLike;
