import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Button = ({ cta, url, handleClick, onClick, color, }) => {

  let colorClass = 'btn__secondary_green shadow_new__green__btn';

  // color - 2: yellow
  if (color === 2) {
    colorClass = 'btn__secondary_yellow shadow_new__yellow__btn';
  }

  // console.log('========== Button: colorClass', colorClass)
  // console.log('========== Button: color', color)
  // console.log('========== Button: handleClick', handleClick)

  
  return (
    <Fragment>
      {!handleClick ? (
        <Link to={url} className={`h-54 lh-25 btn mw-200 text-center br-4 ${colorClass}`}>{cta}</Link>
      ) : (
        <div
          className={`h-54 lh-25 btn mw-200 text-center br-4 ${colorClass}`}
          onClick={(e) => onClick(e)}
        >
          <span className="fs-16">{cta}</span>
        </div>
      )}
    </Fragment>
  );
};

Button.propTypes = {
  cta: PropTypes.object,
  url: PropTypes.string
};

export default Button;
