import React from 'react';
import { FormattedMessage } from 'react-intl';
import { oldCompaniesDate } from '../oldWorkspace';
import { isVisible } from '../checkins';

export const menuTitle = <FormattedMessage id="dashboard" defaultMessage="Dashboard" />;
const okrs = <FormattedMessage id="company.menu.objectives" defaultMessage="Objectives" />;
const checkins = <FormattedMessage id="menu.checkins" defaultMessage="Daily Check-ins" />;
const updates = <FormattedMessage id="menu.updates.new" defaultMessage="Weekly Updates" />;
const plans = <FormattedMessage id="weekly.plans" defaultMessage="Weekly Plans" />;

export const menuItems = [
  {
      title: okrs,
      url: '/dashboard/objectives',
    },
    {
      title: checkins,
      url: '/dashboard/checkins',
    },
    {
      title: plans,
      url: '/dashboard/plans',
    },
    {
      title: updates,
      url: '/dashboard/updates',
    }
  ]

const name = <FormattedMessage id="team.member" defaultMessage="Team Member" />;
const checkinsTitle = <FormattedMessage id="checkins" defaultMessage="Check-ins" />;
const answers = <FormattedMessage id="completed" defaultMessage="Completed" />;
const notAnswers = <FormattedMessage id="failed" defaultMessage="Failed" />;
const responseRate = <FormattedMessage id="average.response" defaultMessage="Average Response" />;
const timeDistribution = <FormattedMessage id="average.time.distribution" defaultMessage="Answer Time Distribution" />;

export const arrayWidth = '60';

export const tableNameTitle = {
  text: name,
  width: '40',
};

export const tableTitleItems = [
  {
    text: checkinsTitle,
    width: '150px',
  },
  {
    text: answers,
    width: '150px',
  },
  {
    text: notAnswers,
    width: '150px',
  },
  {
    text: responseRate,
    width: '130px',
  },
  {
    text: timeDistribution,
    width: '170px',
  },
]


export const getMenu = customs => {
  try {

    let menuArr = menuItems.slice();

    if (customs.length > 0) {
      for (let i = 0; i < customs.length; i++) {
        let obj = {
          title: customs[i].name,
          url: `/dashboard/campaigns/${customs[i]._id}`
        }
        
        menuArr.push(obj);
      }
    }
    
    return menuArr;

  } catch (err) {
    console.log('Error in getMenu', err);
  }
};


export const getCheckinsMenuNew = (customs, company={}, user={}) => {
  try {

    let menuArr = [menuItems[0]];
    let hiddenCheckins = [];

    // console.log('===== customs in getCheckinsMenuNew', customs)
    // console.log('===== company', company)
    // console.log('===== company && new Date(company.date) < oldCompaniesDate', company && new Date(company.date) < oldCompaniesDate)

    if (company && new Date(company.date) < oldCompaniesDate) { menuArr = menuItems.slice() }
    
    if (customs.length > 0) {
      for (let i = 0; i < customs.length; i++) {
        let obj = {
          title: customs[i].name,
          url: `/dashboard/campaigns/${customs[i]._id}`,
          // label: customs[i].name,
          value: customs[i]._id.toString(),
          _id: customs[i]._id.toString(),
          // checkinType: 1,
        }

        // console.log('======= user.lastName in getCheckinsMenuNew', user.lastName)
        // console.log('======= user.isAdmin', user.isAdmin)
        // customs && customs[i].visibility && customs[i].visibility.value !== 1 && console.log('======= customs[i].visibility', customs[i].visibility)

        // Check check-in's visibility and hide it, if necessary
        if (isVisible(customs[i], user)) {
          menuArr.push(obj);
        } else {
          hiddenCheckins.push(obj);
        }
      }
    }
    // console.log('======= hiddenCheckins', hiddenCheckins)

    let data = { menuArr, hiddenCheckins }
      
    // return menuArr;
    return data;

  } catch (err) {
    console.log('Error in getCheckinsMenuNew in dashboard', err);
  }
};
