import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Drift from 'react-driftjs';
import { Tooltip } from 'react-tooltip';
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-E5JY0VY291";
ReactGA.initialize(TRACKING_ID);

import Signup from './components/auth/Signup';
import Login from './components/auth/Login';
import Invitation from './components/auth/Invitation';
import Alert from './components/layout/Alert';
import NotFound from './components/layout/NotFound';
import Appsumo from './components/auth/Appsumo';
import Resend from './components/auth/Resend';
import Welcome from './components/auth/Welcome';
import Confirmation from './components/auth/Confirmation';
import ConfirmationError from './components/auth/ConfirmationError';
import ResetPassword from './components/auth/ResetPassword';
import ResetPasswordForm from './components/auth/ResetPasswordForm';
import AdminAnalytics from './components/superadmin/Users';
import AdminLogs from './components/superadmin/Logs';

// New UI
import Home from './components/home/Home';
import Checkin from './components/checkins/Checkin';
import Update from './components/updates-new/Update';
import Kudo from './components/recognitions/Kudo';
import OkrNew from './components/okrs/OkrNew';
import OkrTree from './components/okrs/newTree/OkrTree';
import CompanyCheckins from './components/company/CompanyCheckins';
import CompanyUpdates from './components/company/CompanyUpdates';
import CompanyOkrs from './components/company/CompanyOkrs';
import CompanyKudos from './components/company/CompanyKudos';
import TeamCheckins from './components/your-team/TeamCheckins';
import TeamUpdates from './components/your-team/TeamUpdates';
import TeamOkrs from './components/your-team/TeamOkrs';
import TeamKudos from './components/your-team/TeamKudos';
import TeamItemCheckins from './components/teams/TeamCheckins';
import TeamItemUpdates from './components/teams/TeamUpdates';
import TeamItemOkrs from './components/teams/TeamOkrs';
import TeamItemKudos from './components/teams/TeamKudos';
import UserCheckins from './components/profiles-new/UserCheckins';
import UserUpdates from './components/profiles-new/UserUpdates';
import UserPlans from './components/profiles-new/UserPlans';
import UserOkrs from './components/profiles-new/UserOkrs';
import UserKudos from './components/profiles-new/UserKudos';
import UserFeedback from './components/profiles-new/UserFeedback';
import UserFocus from './components/profiles-new/UserFocus';
import Teams from './components/teams/main/Teams';
import Users from './components/profiles-new/main/Users';
import AdminIntegrations from './components/admin/AdminIntegrations';
import AdminAccount from './components/admin/AdminWorkspace';
import OneOnOne from './components/oneOnOne/OneOnOne';
import Icebreaker from './components/icebreakers/Icebreaker';
import PaymentSuccess from './components/pricing/PaymentSuccess';
import Plan from './components/plans/Plan';
import PlanForm from './components/plans/PlanForm';
import Custom from './components/customAnswers/CustomNew';
import CustomForm from './components/customAnswers/CustomFormNew';
import CustomFormEdit from './components/customAnswers/CustomFormEditNew';
import EditSyncSlack from './components/slack/EditSyncSettings';
import EditOkrSlack from './components/slack/EditOkrSettings';
import EditOkrDiscord from './components/discord/EditOkrSettings';
import Mood from './components/mood/Mood';
import SisterCompanies from './components/sister-companies/SisterCompanies';

// User
import Profile from './components/profile/Profile';
import EditProfile from './components/profile/EditProfile';
import ChangePassword from './components/profile/ChangePassword';
import StandupForm from './components/standups/StandupForm';
import StandupFormEdit from './components/standups/StandupFormEdit';
import UpdateForm from './components/updates/UpdateForm';
import UpdateFormEdit from './components/updates/UpdateFormEdit';
import RecognitionForm from './components/recognitions/RecognitionForm';
import FeedbackForm from './components/feedback/FeedbackForm';
import Notifications from './components/profile/settings/Notifications';
import DashboardOkrs from './components/dashboard/DashboardOkrs';
import DashboardCheckins from './components/dashboard/DashboardCheckins';
import DashboardUpdates from './components/dashboard/DashboardUpdates';
import DashboardPlans from './components/dashboard/DashboardPlans';
import DashboardCustoms from './components/dashboard/DashboardCustoms';

// Company
import Pricing from './components/pricing/Pricing';

// OKR
import OkrForm from './components/okrs/form/OkrForm';
import EditOkr from './components/okrs/form/EditOkr';

// Task
import Task from './components/todos/Task';

// Admin
import AdminDashboard from './components/admin/AdminDashboard';
import AdminDashboardNew from './components/admin/AdminDashboardNew';
import EditStandups from './components/admin/EditStandups';
import EditUpdates from './components/admin/EditUpdates';
import EditIcebreakers from './components/admin/EditIcebreakers';
import EditPlans from './components/admin/EditPlans';
import EditCustom from './components/admin/EditCustom';
import EditSlack from './components/admin/EditSlack';
import CreateCustom from './components/admin/CreateCustom';
import CreateDepartment from './components/admin-department/CreateDepartment';
import EditDepartment from './components/admin-department/EditDepartment';
import SlackAuth from './components/slack/Auth';
import Outreach from './components/outreach/Outreach';
import OutreachEmail from './components/outreach/OutreachEmail';
import OutreachCampaignForm from './components/outreach/OutreachCampaignForm';
import OutreachEmailForm from './components/outreach/OutreachEmailForm';
import EditOutreachEmail from './components/outreach/EditOutreachEmail';

// Onboarding - draft
// import Onboarding1Draft from './components/onboarding/draft/Onboarding1';
// import Onboarding2Draft from './components/onboarding/draft/Onboarding2';
// import Onboarding3Draft from './components/onboarding/draft/Onboarding3';
// import Onboarding4Draft from './components/onboarding/draft/Onboarding4';

// Onboarding - new
import Onboarding1 from './components/onboarding/new/Onboarding1';
import Onboarding2 from './components/onboarding/new/okrs/Onboarding2';
import Onboarding3 from './components/onboarding/new/okrs/Onboarding3';
import OnboardingInvite from './components/onboarding/new/OnboardingInvite';
import OnboardingCheckin2 from './components/onboarding/new/checkins/Onboarding2';
import OnboardingCheckin3 from './components/onboarding/new/checkins/Onboarding3';
import OnboardingCheckin4 from './components/onboarding/new/checkins/Onboarding4';
import OnboardingCheckin5 from './components/onboarding/new/checkins/Onboarding5';
import OnboardingCheckin6 from './components/onboarding/new/checkins/Onboarding6';
import OnboardingCheckinSlack from './components/onboarding/new/checkins/SlackSetup';
import MemberOnboarding from './components/onboarding/new/checkins/MemberOnboarding';

// Referral
import RegisterReferral from './components/early-referrals/RegisterReferral';

import PrivateRoute from './components/routing/PrivateRoute';


// Redux
// import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import { setLocaleLang } from './actions/locale';
import setAuthToken from './utils/setAuthToken';
import setLangStorage from './utils/setLangStorage';

// Translation
import { IntlProvider } from 'react-intl';
import messages_en from './translations/en.json';
import messages_ru from './translations/ru.json';

import 'react-tooltip/dist/react-tooltip.css'
import './App.css';
import CreateOkrCall from './components/admin/CreateOkrCall';
import EditOkrCall from './components/admin/EditOkrCall';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

// Get locale language
const language = navigator.language.split(/[-_]/)[0];

const messages = {
  en: messages_en,
  ru: messages_ru
};

if (localStorage.focusLang) {
  store.dispatch(setLocaleLang(localStorage.focusLang));
} else {
  setLangStorage(language);
  store.dispatch(setLocaleLang(language));
}

const App = ({ locale: { lang }, auth: { user } }) => {
  useEffect(() => {
    // console.log('App.js in useEffect', user)

    if (!user) {
      store.dispatch(loadUser());
    } else {
      // Bugpilot identify
      // window.Bugpilot.identify({
      //   id: user._id, 
      //   email: user.email,
      //   firstName: user.firstName,
      //   lastName: user.lastName,
      //   company: user.companyName,
      // });
    }

    // Track pageview in GA with react-router-dom
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // Take user to identify id and send userId to Drift

  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      <Router>
        <Fragment>
          <section>
            <Alert />
            <Tooltip id="info-tooltip" className='info_tooltip'/>
            {user && (
              <Fragment>
                <Drift
                  appId="vbkxykzdk869"
                  userId={user && user._id}
                  attributes={{
                    email: user && user.email,
                    company: user && user.companyName,
                    name: user && `${user.firstName} ${user.lastName}`
                  }}
                />
              </Fragment>
            )}
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/appsumo" component={Appsumo} />
              <Route exact path="/resend" component={Resend} />
              <Route exact path="/welcome" component={Welcome} />
              <Route exact path="/confirmation" component={Confirmation} />
              <Route exact path="/confirmation/error" component={ConfirmationError} />
              <Route exact path="/password/reset" component={ResetPassword} />
              <Route exact path="/password/change" component={ResetPasswordForm} />
              <PrivateRoute exact path="/home" component={Home} />
              <PrivateRoute exact path="/checkins" component={Checkin} />
              <PrivateRoute exact path="/updates" component={Update} />
              <PrivateRoute exact path="/kudos" component={Kudo} />
              <PrivateRoute exact path="/objectives" component={OkrNew} />
              <PrivateRoute exact path="/objectives/:id" component={OkrNew} />
              <PrivateRoute exact path="/objectives/view/tree" component={OkrTree} />
              <PrivateRoute exact path="/tasks" component={Task} />
              <PrivateRoute exact path="/company/checkins" component={CompanyCheckins} />
              <PrivateRoute exact path="/company/updates" component={CompanyUpdates} />
              <PrivateRoute exact path="/company/objectives" component={CompanyOkrs} />
              <PrivateRoute exact path="/company/kudos" component={CompanyKudos} />
              <PrivateRoute exact path="/team/checkins" component={TeamCheckins} />
              <PrivateRoute exact path="/team/updates" component={TeamUpdates} />
              <PrivateRoute exact path="/team/objectives" component={TeamOkrs} />
              <PrivateRoute exact path="/team/kudos" component={TeamKudos} />
              <PrivateRoute exact path="/team/:id" component={TeamItemCheckins} />
              <PrivateRoute exact path="/team/:id/updates" component={TeamItemUpdates} />
              <PrivateRoute exact path="/team/:id/objectives" component={TeamItemOkrs} />
              <PrivateRoute exact path="/team/:id/kudos" component={TeamItemKudos} />
              <PrivateRoute exact path="/user/:id" component={UserCheckins} />
              <PrivateRoute exact path="/user/:id/updates" component={UserUpdates} />
              <PrivateRoute exact path="/user/:id/plans" component={UserPlans} />
              <PrivateRoute exact path="/user/:id/objectives" component={UserOkrs} />
              <PrivateRoute exact path="/user/:id/kudos" component={UserKudos} />
              <PrivateRoute exact path="/user/:id/focus" component={UserFocus} />
              <PrivateRoute exact path="/user/:id/feedback" component={UserFeedback} />
              <PrivateRoute exact path="/teams" component={Teams} />
              <PrivateRoute exact path="/people" component={Users} />
              <PrivateRoute exact path="/settings/integrations" component={AdminIntegrations} />
              <PrivateRoute exact path="/settings/workspace" component={AdminAccount} />
              <PrivateRoute exact path="/mood" component={Mood} />
              <Route exact path="/invitation/accept" component={Invitation} />
              <Route exact path="/referral" component={RegisterReferral} />
              <PrivateRoute exact path="/profile" component={Profile} />
              <PrivateRoute exact path="/edit-profile" component={EditProfile} />
              <PrivateRoute exact path="/password/:id" component={ChangePassword} />
              <PrivateRoute exact path="/checkins/:id" component={StandupForm} />
              <PrivateRoute exact path="/checkins/edit/:id" component={StandupFormEdit} />
              <PrivateRoute exact path="/updates/:id" component={UpdateForm} />
              <PrivateRoute exact path="/updates/edit/:id" component={UpdateFormEdit} />
              <PrivateRoute exact path="/kudos/new" component={RecognitionForm} />
              <PrivateRoute exact path="/feedback/new/:id" component={FeedbackForm} />
              <PrivateRoute exact path="/objective/new" component={OkrForm} />
              <PrivateRoute exact path="/objective/new/:id" component={OkrForm} />
              <PrivateRoute exact path="/okr/:id" component={EditOkr} />
              <PrivateRoute exact path="/settings" component={AdminDashboard} />
              <PrivateRoute exact path="/newsettings" component={AdminDashboardNew} />
              <PrivateRoute exact path="/settings/edit_standup" component={EditStandups} />
              <PrivateRoute exact path="/settings/edit_icebreakers" component={EditIcebreakers} />
              <PrivateRoute exact path="/settings/edit_updates" component={EditUpdates} />
              <PrivateRoute exact path="/settings/edit_plans" component={EditPlans} />
              <PrivateRoute exact path="/settings/campaigns/:id" component={EditCustom} />
              <PrivateRoute exact path="/settings/slack" component={EditSlack} />
              <PrivateRoute exact path="/settings/campaign/new" component={CreateCustom} />
              <PrivateRoute exact path="/settings/campaign/new/:id" component={CreateCustom} />
              <PrivateRoute exact path="/settings/create-okr-call" component={CreateOkrCall} />
              <PrivateRoute exact path="/settings/edit-okr-call/:id" component={EditOkrCall} />
              {/* <PrivateRoute exact path="/settings/users" component={AdminPeople} /> */}
              {/* <PrivateRoute exact path="/settings/teams" component={AdminDepartments} /> */}
              <PrivateRoute exact path="/settings/create-team" component={CreateDepartment} />
              <PrivateRoute exact path="/settings/edit-team/:id" component={EditDepartment} />
              <PrivateRoute exact path="/settings/slack/edit_sync" component={EditSyncSlack} />
              <PrivateRoute exact path="/settings/slack/edit_okr" component={EditOkrSlack} />
              <PrivateRoute exact path="/settings/discord/edit_okr" component={EditOkrDiscord} />
              <PrivateRoute exact path="/dashboard/objectives" component={DashboardOkrs} />
              <PrivateRoute exact path="/dashboard/checkins" component={DashboardCheckins} />
              <PrivateRoute exact path="/dashboard/updates" component={DashboardUpdates} />
              <PrivateRoute exact path="/dashboard/plans" component={DashboardPlans} />
              <PrivateRoute exact path="/dashboard/campaigns/:id" component={DashboardCustoms} />
              <PrivateRoute exact path="/slack/auth" component={SlackAuth} />
              <PrivateRoute exact path="/settings/pricing" component={Pricing} />
              <PrivateRoute exact path="/outreacher" component={Outreach} />
              <PrivateRoute exact path="/outreacher/campaigns/" component={OutreachEmail} />
              <PrivateRoute exact path="/outreacher/campaigns/new" component={OutreachCampaignForm} />
              <PrivateRoute exact path="/outreacher/add/:id" component={OutreachEmailForm} />
              <PrivateRoute exact path="/outreacher/:campaignId/:emailId" component={EditOutreachEmail} />
              <PrivateRoute exact path="/icebreakers" component={Icebreaker} />
              <PrivateRoute exact path="/one_on_one" component={OneOnOne} />
              <PrivateRoute exact path="/a/stats" component={AdminAnalytics} />
              <PrivateRoute exact path="/a/logs" component={AdminLogs} />
              <PrivateRoute exact path="/profile/notifications" component={Notifications} />
              <PrivateRoute exact path="/payments/success" component={PaymentSuccess} />
              <PrivateRoute exact path="/plans" component={Plan} />
              <PrivateRoute exact path="/plans/complete" component={PlanForm} />
              <PrivateRoute exact path="/campaigns/:id" component={Custom} />
              <PrivateRoute exact path="/campaign/create/:id" component={CustomForm} />
              <PrivateRoute exact path="/campaign/edit/:id" component={CustomFormEdit} />
              {/* <PrivateRoute exact path="/onboarding/1" component={Onboarding1Draft} /> 
              <PrivateRoute exact path="/onboarding/2" component={Onboarding2Draft} />
              <PrivateRoute exact path="/onboarding/3" component={Onboarding3Draft} />
              <PrivateRoute exact path="/onboarding/4" component={Onboarding4Draft} /> */}
              <PrivateRoute exact path="/welcome/hello" component={Onboarding1} />
              <PrivateRoute exact path="/welcome/okrs" component={Onboarding2} />
              <PrivateRoute exact path="/welcome/okrs/create" component={Onboarding3} />
              <PrivateRoute exact path="/welcome/checkins/1" component={OnboardingCheckin2} />
              <PrivateRoute exact path="/welcome/checkins/2" component={OnboardingCheckin3} />
              <PrivateRoute exact path="/welcome/checkins/3" component={OnboardingCheckin4} />
              <PrivateRoute exact path="/welcome/checkins/4" component={OnboardingCheckin5} />
              <PrivateRoute exact path="/welcome/checkins/5" component={OnboardingCheckin6} />
              <PrivateRoute exact path="/welcome/checkins/slack" component={OnboardingCheckinSlack} />
              <PrivateRoute exact path="/welcome/invite" component={OnboardingInvite} />
              <PrivateRoute exact path="/welcome/checkins/invite" component={OnboardingInvite} />
              <PrivateRoute exact path="/welcome/users" component={MemberOnboarding} />
              <PrivateRoute exact path="/sister-companies" component={SisterCompanies}/>
              <Route component={NotFound} />
            </Switch>
          </section>
        </Fragment>
      </Router>
    </IntlProvider>
  );
};

App.propTypes = {
  locale: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  locale: state.locale,
  auth: state.auth
});

export default connect(mapStateToProps, null)(App);
