import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Menu from '../layout/Menu';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import { getUserAnswersById, clearStandups } from '../../actions/standupAnswer';
import { getCompanyProfiles, getProfileById } from '../../actions/profile';
import { getUserCompany } from '../../actions/company';
import { clearOkrs } from '../../actions/okr';
import UserTitle from './UserTitle';
import MainWidget from '../utils/MainWidget';
import CheckinItem from '../checkins/noState/CheckinItem';
import SubMenu from './SubMenu';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/1.jpg';
import { checkinCopy } from '../../utils/wording/checkins';
import { FormattedMessage } from 'react-intl';
import Moment from 'react-moment';

const UserCheckins = ({
  clearStandups,
  clearOkrs,
  getProfileById,
  getUserAnswersById,
  getUserCompany,
  getCompanyProfiles,
  profile: { profile, profiles },
  standupAnswer: { standupAnswers, loading },
  company: { company },
  auth: { user },
  match,
}) => {
  useEffect(() => {
    getUserCompany();
    getUserAnswersById(match.params.id);
    getCompanyProfiles();
    getProfileById(match.params.id);

    // Clear state
    return () => {
      clearStandups();
      clearOkrs();
    }
  }, [getUserCompany, getProfileById, getUserAnswersById, getCompanyProfiles, match.params.id]); 

  const noCheckinsMessageTeam = {
    title: <FormattedMessage id="company.nocheckins.user.title" defaultMessage="No Check-ins" />,
    msg: 
    <FormattedMessage id="company.checkins.user.msg" defaultMessage={`${profile && profile.firstName} has not finished check-ins yet.`} />
  };

  // Removing duplicates in answers array based on the object key 'standup' - to send only unique standup items to StandupTeamItem
  // Берем данные из не редакса standupAnswers
  let standupAnswersUnique = standupAnswers ? (standupAnswers.reduce((acc, current) => {
    const x = acc.find(item => item.standup === current.standup);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, [])) : [];

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {user && user.department ? (
          <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user && user.isAdmin && true} activeIndex={8} />
        ) : (
          <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} activeIndex={8} />
        )}
        <UserNav />
          <Fragment>
            {loading || !standupAnswers || !user || !company || !profile ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="main main-dashboard">
                    <h5>
                      <FormattedMessage id="team.header.checkins" defaultMessage={`${profile && profile.firstName}'s Check-ins`} />
                    </h5>

                  <SubMenu userId={profile._id} companyIndex={1} />

                  <div className="checkins">

                    <UserTitle
                      profile={profile}
                      user={user}
                    />

                    {company.plan !== 1 || company.plan === 1 && profiles.length <= process.env.REACT_APP_FREE_PLAN_USERS ? (
                      <Fragment>
                        {standupAnswers.length === 0 ? (
                          <div className="mt-5">
                            <NoContent 
                              message={noCheckinsMessageTeam}
                              picture={updateImg}
                              alt={'No checkins'}
                            />
                          </div>
                        ) : (
                          <Fragment>
                            {standupAnswersUnique.length > 0 && (
                              standupAnswersUnique.map(
                                (standupAnswerUnique, index) => (
                                  <Fragment key={`fragment-checkin-${index}`}>
                                    <h3 className="fs-2 pl-2 pt-2 pb-1" key={`date-${index}`}>
                                      <Moment format="D MMM" key={`date-moment-${index}`}>{standupAnswerUnique.standupDate}</Moment>
                                    </h3>

                                    <CheckinItem
                                      key={`checkins-${standupAnswerUnique._id}-${index}`}
                                      standupAnswers={standupAnswers.filter(
                                        standupAnswer =>
                                          standupAnswer.standup.toString() ===
                                          standupAnswerUnique.standup.toString()
                                      )}
                                      standupDate={standupAnswerUnique.standupDate}
                                    />
                                  </Fragment>
                                )
                              )
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      <NoContent 
                        message={checkinCopy.updatePlanMsg}
                        picture={updateImg}
                        alt={'Update plan'}
                        admin={user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0}
                      />
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

UserCheckins.propTypes = {
  clearStandups: PropTypes.func.isRequired,
  clearOkrs: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  getUserAnswersById: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getProfileById: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  standupAnswer: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  standupAnswer: state.standupAnswer
});

export default connect(
  mapStateToProps,
  { getUserAnswersById, getUserCompany, getCompanyProfiles, getProfileById, clearStandups, clearOkrs }
)(UserCheckins);
