import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { icons } from '../../../img/elements/icons';

const DailyFocusCardS = ({ dailyFocusAnswers, color, label }) => {

    const okrHead = <FormattedMessage id="focus.day.okr" defaultMessage="OKR" />;
    const focusOnOkr = <FormattedMessage id="focus.onOkr" defaultMessage="Focus on OKR" />;

    return (
        <div className={`my-2`}>
            <div className="flex align-center">
                <span className="mr-14 flex align-center">
                    {icons.arrow_right}
                </span>
                {' '}
                <p className={`fs-12 uppercase bold mb-0`}>
                    <FormattedMessage id="daily.focus" defaultMessage="Daily focus" />{' '}
                </p>
            </div>
            <div className="notes ml-38">
                <Fragment>
                    {dailyFocusAnswers.focusIndex === 99 ? (
                        <p className="fs-16 text-left"><b>{focusOnOkr}:</b>{' '}{dailyFocusAnswers.objective}</p>
                    ) : (
                        <Fragment>
                            <div className="flex align-center">
                                <p className="fs-16 text-left"><b>{okrHead}:</b>{' '}{dailyFocusAnswers.objective}</p>
                                <span className="mx-8 flex align-center">{icons.arrow_right}</span>
                                <p className="fs-14 text-left">{dailyFocusAnswers.text}</p>
                            </div>
                        </Fragment>
                    )}
                    <div className="flex align-center">
                        <p className="text-left fs-16 bold mb-0">{dailyFocusAnswers.progress}%</p>
                        <span className="fs-16 mx-xs">/</span>
                        <p className="text-left fs-12 mr-1">{dailyFocusAnswers.goal}%</p>
                        <p className={`text-left fs-8 uppercase white br-2 brd bg-${color} px-xs lh-16`}>{label}</p>
                    </div>
                </Fragment>
            </div>
        </div>
    )
};

DailyFocusCardS.propTypes = {
    dailyFocusAnswers: PropTypes.object.isRequired,
};

export default DailyFocusCardS;
