import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import MainMenu from '../layout/Sidebar';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import { getCompanyProfiles } from '../../actions/profile';
import { getUserCompany } from '../../actions/company';
import { getActiveOkrs, getCompanySortedOkrs, getAllMetrics, clearOkr } from '../../actions/okr';
import { getTasksWithOkrs } from '../../actions/todo';
import { getAllCustoms } from '../../actions/custom';
import TableTitle from '../okrs/dashboard/TableTitle';
import OkrCardItem from '../okrs/OkrCardItem';
import UnitItem from '../okrs/dashboard/UnitItem';
import OkrWidgets from '../widgets/new/OkrWidget';
import WidgetsDashboard from '../widgets/new/Widgets';
import NotificationCheckin from '../utils/new/NotificationCheckin';
import NotificationOkr from '../utils/new/Notification';
import NotificationIcebreaker from '../utils/new/NotificationIcebreaker';
import classnames from "classnames";
import { setAlert } from '../../actions/alert';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import SubMenu from '../elems/SubMenu';
import { menuTitle, menuItems, getCheckinsMenuNew } from '../../utils/menu/dashboard';
import { icons } from "../../img/elements/icons";
import { getUserAnswers } from '../../actions/standupAnswer';
import { getUserPlanAnswers } from '../../actions/planAnswer';
import { getPlanSettings } from '../../actions/plan';
import { getStandups } from '../../actions/standup';
import { getIcebreakers, addIcebreakerAnswer } from '../../actions/icebreaker';
import { getUpdates } from '../../actions/update';
import { getUserUpdateAnswers } from '../../actions/updateAnswer';
import { checkinCopy } from '../../utils/wording/checkins';
import { updateCopy } from '../../utils/wording/updates';
import { planCopy } from '../../utils/wording/plans';
import { oldCompaniesDate } from '../../utils/oldWorkspace'


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    backgroundColor: '#fcfcfd',
    color: "#000000",
    fontSize: '14px',
  }),
  control: styles => ({ ...styles, backgroundColor: '#fcfcfd', padding: '4px 8px', borderColor: "#E9EAF5", }),
  placeholder: styles => ({ ...styles, fontSize: '14px', color: "#000000" }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
}

let iteration = 0;

const DashboardOkrs = ({
  getCompanySortedOkrs,
  getActiveOkrs,
  getAllMetrics,
  getTasksWithOkrs,
  getUserCompany,
  getCompanyProfiles,
  okr: { okrs, loading, data },
  todo: { tasks },
  auth: { user, sidebar },
  company: { company },
  profile: { profiles },
  locale,
  getAllCustoms,
  custom: { campaigns },
  clearOkr,
  getUserUpdateAnswers,
  getUserAnswers,
  updateAnswer: { updateAnswers },
  standupAnswer: { standupAnswers },
  getStandups, 
  getUpdates, 
  standup: { standups },   
  update: { updates },   
  getIcebreakers,
  icebreaker: { icebreakers },
  getPlanSettings,
  plan,
  getUserPlanAnswers,
  planAnswer: { planAnswer, planAnswers },
  addIcebreakerAnswer,
}) => {
const [formData, setFormData] = useState({
    team: '',
    objectives: [],
    archivedObjectives: [],
    todos: [],
    completedTodos: [],
    time: '',
    objectivesForUnit: [],
    metrics: [],
    notUpdatedOkrs: [],
  });

  const [menuData, setMenu] = useState({
    menu: {},
    showIcebreaker: false,
  });

  let { menu, showIcebreaker } = menuData;

  // OKR по командам или сотрудникам в команде (юниты) vs просто цели списком
  const [okrUnits, toggleOkrUnits] = useState(true);

  let { team, objectives, archivedObjectives, todos, completedTodos, notUpdatedOkrs, time, objectivesForUnit, metrics, } = formData;

  let teamOptions = [];
  let usersOptions = [];
  let timeOptions = [];

  // Перебор profiles & departments и приведение данных к формату Селекта
  {
    profiles &&
      profiles.forEach((profile, index) => {
        
        if (user && profile._id === user._id) return; 

        let coworker = {
          value: profile._id,
          label: `${profile.firstName} ${profile.lastName}`,
          user: profile._id,
          firstName: profile.firstName,
          lastName: profile.lastName,
          avatar: profile.avatar,
          department: profile.department,
          departmentName: profile.departmentName,
          name: "owner",
          type: 3
        };

        teamOptions.push(coworker);
        usersOptions.push(coworker);
      });
  }

  {
    company && company.departments.length > 0 &&
      company.departments.forEach((department, index) => {

        if (user && user.department === null) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };
  
          teamOptions.unshift(team);
        } else if (user && user.department !== null && user.department !== undefined && department._id !== user.department) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };
  
          teamOptions.unshift(team);
        }

        if (user && index === company.departments.length - 1) {
          // You is third in list
          let person = {
            value: user._id,
            label: `${user.firstName} ${user.lastName}`,
            user: user._id,
            firstName: user.firstName,
            lastName: user.lastName,
            avatar: user.avatar,
            department: user.department,
            departmentName: user.departmentName,
            name: "owner",
            type: 3
          }
          teamOptions.unshift(person);
          usersOptions.unshift(person);

          // Second option is the team if exists
          if (user.department !== null && user.department !== undefined) {
            let team = { value: user.department, label: `${user.departmentName}`, type: 2 };
            teamOptions.unshift(team);
          }

          let companyOkrs = locale && locale.lang !== 'ru' ? `- Organization level` : `- Уровень компании`; 

          // Third option is the company
          let name = { value: company._id, label: `${company.name} ${companyOkrs}`, type: 1 };
          teamOptions.unshift(name);

          let allOkrs = locale && locale.lang !== 'ru' ? `All OKRs` : `Все OKR`; 

          // Fourth option is all OKRs
          let all = { value: `Company`, label: allOkrs, type: 4, company: company._id };
          teamOptions.unshift(all);
        }
      });
  } 

  if (company && company.departments.length === 0 && user) {
    teamOptions = [
      { value: company._id, label: `All OKRs`, type: 4 },
      { value: company._id, label: `${company.name}`, type: 1 },
      { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 }
    ]
  }

  let choosedTeam = company ? { value: company._id, label: `All OKRs`, type: 4, company: company._id } : {};
  
  // Time Options
  let hours = new Date().getUTCHours();
  let minutes = new Date().getMinutes();
  let today = new Date().getDay();
  let midnight = new Date().setHours(0,0,0,0);
  if (today === 0) { today = 8 }; // For Sundays

  let all = Date.parse(new Date(new Date(2019, 1, 1, 1, 1)));
  let quarter = Date.parse(new Date(new Date() - (90 * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60) * 1000));
  let month = Date.parse(new Date(new Date() - (30 * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60) * 1000));
  let weekAgo = Date.parse(new Date(new Date() - (7 * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60) * 1000));
  let thisWeek = Date.parse(new Date(new Date() - ((today - 1) * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60) * 1000));
  let allTime = locale && locale.lang !== 'ru' ? `All Time` : `Все время`; 
  let quarterTime = locale && locale.lang !== 'ru' ? `Quarter` : `Квартал`; 
  let monthTime = locale && locale.lang !== 'ru' ? `Last month` : `Месяц`; 
  let weekTime = locale && locale.lang !== 'ru' ? `Last 7 days` : `7 дней`; 
  let thisWeekTime = locale && locale.lang !== 'ru' ? `This week` : `Эта неделя`; 

  timeOptions = [
      { value: 5, label: allTime, period: all },
      { value: 4, label: quarterTime, period: quarter },
      { value: 3, label: monthTime, period: month },
      { value: 2, label: weekTime, period: weekAgo },
      { value: 1, label: thisWeekTime, period: thisWeek }
    ];

    let choosedTime = { value: 5, label: weekTime, period: weekAgo };

  // Create OKRs arrays for list view based on teams (for default setup)
  let choosedUnits = [];

  {
    okrs &&
      okrs.forEach((okr) => {
        if (okr.type === 2) {

          // ToDo: как проверить, что команда еще не занесена в units?
          // Проверяем, чтобы в choosedUnits уже не было массива с этой командой
          // Для этого перебераем каждый массив и меняем флаг isUnique, если такая есть команда
          let isUnique = true;
          let unit = [];

          choosedUnits.forEach(unitsItem => {
            let unitIndex = unitsItem.map(item => item.team).indexOf(okr.team);

            if (unitIndex !== -1) {
              isUnique = false;
            }
          })

          if (isUnique) {
            unit = okrs.filter(item => item.type === 2 && item.team === okr.team);
          }

          if (unit.length > 0) {
            choosedUnits.push(unit);
          }
        }
      });
  }

  useEffect(() => {
    // console.log('======== USEEFFECT 1 WORKS')

    if (iteration < 2) {
      // console.log('======== USEEFFECT: calling api')
      getUserCompany();
      getCompanyProfiles();
      getCompanySortedOkrs();
      getTasksWithOkrs();
      getAllMetrics();
      getAllCustoms();
      clearOkr();
    }

    setFormData({
      ...formData,
      team: !user ? '' : choosedTeam,
      objectives: !okrs ? [] : okrs,
      archivedObjectives: !okrs ? [] : okrs.filter(item => item.isArchive),
      todos: !tasks ? [] : tasks,
      completedTodos: !tasks ? [] : tasks.filter(item => item.isCompelted),
      time: !user ? '' : choosedTime,
      objectivesForUnit: !okrs ? [] : choosedUnits,
      metrics: !data ? [] : data.filter(item => Date.parse(item.date) > choosedTime.period),
      notUpdatedOkrs: !okrs && !profiles ? [] : okrs.filter(item => Date.parse(item.updatedAt) < choosedTime.period && !item.isArchive),
    });

    // console.log('===== useEffect: company', company)
    let settingsArr = campaigns && campaigns.length > 0 && user && company ? getCheckinsMenuNew(campaigns, company, user) : { menuArr: menuItems };

    setMenu({
      ...menuData,
      menu: settingsArr,
    });

  }, [
    // getUserCompany, 
    company && company.plan,
    campaigns && campaigns.length,
    user && user._id,
    getCompanySortedOkrs, 
    getCompanyProfiles,
    getActiveOkrs, 
    getTasksWithOkrs,
    getAllMetrics, 
    data && data.length,
    getAllCustoms,
  ]);


  useEffect(() => {
    return () => {
      
      // Make iteration = 0, when user leaves the page
      iteration = 0
    };
  }, [
    getUserAnswers,
    getStandups, getUserUpdateAnswers,
    getUpdates, 
    getIcebreakers, 
    getUserPlanAnswers, getPlanSettings,
  ]);

  useEffect(() => {
    getUserAnswers();
    getStandups(); 
    getUpdates(); 
    getUserUpdateAnswers();
    getIcebreakers();
    getUserPlanAnswers();
    getPlanSettings();

    // console.log('======== USEEFFECT 2 WORKS')

  }, []);


  // okrs ? console.log('=== okrs.length', okrs.length) : console.log('=== okrs', okrs)
  // tasks ? console.log('=== tasks.length', tasks.length) : console.log('=== tasks', tasks)
  // console.log('=== loading', loading)
  // console.log('=== iteration', iteration)
  
  iteration += 1;

  const sortOkrForUnits = (okrs, teamType) => {
    
    choosedUnits = [];
    let teamVar;
    
    // АЛГОРИТМ
    // Проверяем, по чему мы должны сортировать 
    // Записываем переменную, которая будет отбирать ОКР по type
    // 2 - ОКР отдела, 3 - персональный ОКР
    if (teamType === 1 || teamType === 4) {
      // Company OKRs

      // BUG: To-Do почему-то не показываются цели в Organization view (teamType === 1)
      teamVar = 2;
    } else if (teamType === 2) {
      // Team OKRs
      // Сортируем по членам команды

      teamVar = 3;
    } else if (teamType === 3) {
      // User's OKRs
      // Записываем просто все ОКР этого юзера

      teamVar = 0;
    }

    // If it's company or team OKRs
    // Значит мы запускаем цикл и отбираем ОКР по type === 2 (если окр компании, то все отделы)
    // или type === 3 (если окр отдела, то показываем всех сотрудников в нем)
    if (teamVar !== 0) {
      okrs.forEach((okr) => {
       
        if (okr.type === teamVar) {

          let isUnique = true;
          let unit = [];

          // Check that we don't have user/team OKR in choosedUnits
          choosedUnits.forEach(unitsItem => {
            let unitIndex = unitsItem.map(item => item.team).indexOf(okr.team);

            if (unitIndex !== -1) {
              isUnique = false;
            }
          })
          
          if (isUnique) {
            unit = okrs.filter(item => item.type === teamVar && item.team === okr.team);
          }

          if (unit.length > 0) {
            choosedUnits.push(unit);
          }
        }
      });
    } else {
      // If it's personal OKRs
      // Then push all user's OKRs
      choosedUnits.push(okrs);
    }

    return choosedUnits;
  };
  
  const handleTime = async selectedOption => {
    try {
      let newItems = [];
      let newArchivedItems = [];
      let newTodos = [];
      let newItemsForUnit = [];
      let newMetrics = [];
      newItems = okrs.filter(item => Date.parse(item.date) > selectedOption.period && !item.isArchive);
      newArchivedItems = okrs.filter(item => Date.parse(item.date) > selectedOption.period && item.isArchive);
      newTodos = tasks.filter(item => Date.parse(item.date) > selectedOption.period);
      newItemsForUnit = newItems;
      
      if (team.type !== 4) {
        newMetrics = data.filter(item => item.team === team.value && Date.parse(item.date) > selectedOption.period);
      } else {
        newMetrics = data.filter(item => item.company === team.company && Date.parse(item.date) > selectedOption.period);
      }

      let res = await sortOkrForUnits(newItemsForUnit, team.type);
      let notUpdatedItems = okrs.filter(item => Date.parse(item.updatedAt) < selectedOption.period && !item.isArchive);
      
      setFormData({ 
        ...formData, 
        time: selectedOption, 
        objectives: newItems,
        archivedObjectives: newArchivedItems,
        todos: newTodos,
        objectivesForUnit: res,
        metrics: newMetrics,
        notUpdatedOkrs: notUpdatedItems,
      });

    } catch (err) {

      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in likes', err);
    }
  };

  const handleTeam = async selectedOption => {
    let newItems = [];
    let newArchivedItems;
    let newTodos = [];
    let newCompletedTodos = [];
    let teamUsers = [];
    let itemsForUnit = [];
    let newMetrics = [];

    if (selectedOption.type === 1) {

      // Company
      newItems = okrs.filter(item => item.team === selectedOption.value && Date.parse(item.date) > time.period);
      newArchivedItems = okrs.filter(item => item.team === selectedOption.value && item.isArchive && Date.parse(item.date) > time.period);
      
      // Tasks
      newTodos = tasks.filter(item => item.company === selectedOption.value && Date.parse(item.date) > time.period);

      itemsForUnit = newItems;

      newMetrics = data.filter(item => item.team === selectedOption.value && Date.parse(item.date) > time.period);

    } else if (selectedOption.type === 2) {

      // Team
      newItems = okrs.filter(item => item.team === selectedOption.value && Date.parse(item.date) > time.period);
      newArchivedItems = okrs.filter(item => item.team === selectedOption.value && item.isArchive && Date.parse(item.date) > time.period);
      newMetrics = data.filter(item => item.team === selectedOption.value && Date.parse(item.date) > time.period);

      // Tasks
      newTodos = tasks.filter(item => item.department === selectedOption.value && Date.parse(item.date) > time.period);

      // АЛГОРИТМ Для itemsForUnit:
      // 1. Находим всех членов этой команды
      // 2. И находим все их ОКР для каждого

      // ToDo: хотел вывести все в findTeamUsersOkrs, чтобы отсюда делать 
      // вызов функции и из handleTime. Но перестало работать, решил пропустить отрезок.
      // Сейчас, если стоит команда и начинают менять время, то цели в Teams будут неправильные.
  
      let arr = [];
      teamUsers = profiles.filter(item => item.department && item.department.toString() === selectedOption.value);

      teamUsers.forEach(user => {

        okrs.forEach(item => {
          if (item.type === 3) {
            if (item.owner === user._id) {
              
              // If user is owner
              arr.push(item);
            }

            // ToDo: убрал поиск по KR (код ниже). Сейчас мы выводим в teams виде только OKR,
            // где юзер собственник Objective.
            // Иначе получается, что если мы находим OKR, где он только ответственный KR,
            // то при формировании массива для рендера в sortOkrsForUnits мы сделаем
            // 2 разных массива и отрисуем дополнительно цели другого собственника
            // ТО ЕСТЬ: в отделе А есть юзер1, а мы отрисуем цели юзера1 и юзера2,
            // который является собственником ОКР, где юзер1 отвечает только за КР. 
            // При этом юзер 2 может быть из другого отдела
            // Поэтому скрыл это. 
            // ToDo: подумать в будущем, как включать OKR где юзер только хозяин KR в 
            // массив этого юзера, не нового юзера. 
          }
        })
      })

      if (arr.length > 0) {
        itemsForUnit = arr;
      }

    } else if (selectedOption.type === 3) {

      newItems = okrs.filter(item => {
        if (item.owner === selectedOption.value && Date.parse(item.date) > time.period) {
          
          // If user is owner
          return item;
        } else if (item.keyResults.findIndex(i => i.owner === selectedOption.value) > -1 && Date.parse(item.date) > time.period) {

           // if user is owner of key result
          return item; 
        } else {
          return false;
        }
      })

      newArchivedItems = okrs.filter(item => {
        if (item.owner === selectedOption.value && item.isArchive && Date.parse(item.date) > time.period) {
          
          // If user is owner
          return item;
        } else if (item.keyResults.findIndex(i => i.owner === selectedOption.value) > -1 && Date.parse(item.date) > time.period) {

           // if user is owner of key result
          if (item.isArchive) { return item; }
        } else {
          return false;
        }
      })

      // Tasks
      // Здесь мы возвращаем только цели с указанием ответственных (не считаем в статистике создателей таска)
      newTodos = tasks.filter(item => item.owner && item.owner.user === selectedOption.value && Date.parse(item.date) > time.period);

      itemsForUnit = newItems;

      newMetrics = data.filter(item => item.team === selectedOption.value && Date.parse(item.date) > time.period);
    } else if (selectedOption.type === 4) {

      // All OKRs
      newItems = okrs.filter(item => item.company === selectedOption.company && Date.parse(item.date) > time.period);
      newArchivedItems = okrs.filter(item => item.company === selectedOption.company && item.isArchive && Date.parse(item.date) > time.period);
      newMetrics = data.filter(item => item.company === selectedOption.company && Date.parse(item.date) > time.period);

      // Tasks
      newTodos = tasks.filter(item => item.company === selectedOption.company && Date.parse(item.date) > time.period);
    
       itemsForUnit = newItems;
    }

    let res = await sortOkrForUnits(itemsForUnit, selectedOption.type);
    let notUpdatedItems = newItems.filter(item => Date.parse(item.updatedAt) < time.period && !item.isArchive);

    setFormData({ 
      ...formData, 
      team: selectedOption, 
      objectives: newItems,
      archivedObjectives: newArchivedItems,
      todos: newTodos,
      objectivesForUnit: res,
      metrics: newMetrics,
      notUpdatedOkrs: notUpdatedItems
    });
  };

  // Calculate Data
  let onTrack = <FormattedMessage id="user.okr.status.onTrack" defaultMessage="On Track"/>
  let behind = <FormattedMessage id="user.okr.status.behind" defaultMessage="Behind"/>
  let atRisk = <FormattedMessage id="user.okr.status.risk" defaultMessage="At Risk" />
  let completedOkrsTitle = <FormattedMessage id="completed.okrs" defaultMessage="OKRs Achieved"/>;
  let totalOkrsTitle = <FormattedMessage id="okrs" defaultMessage="Objectives and Key Results"/>;
  let archivedOkrsTitle = <FormattedMessage id="archived.okrs" defaultMessage="Archived OKRs"/>;
  let OkrsProgressTitle = <FormattedMessage id="progress.okrs" defaultMessage="OKR progress"/>;
  let TasksTitle = <FormattedMessage id="tasks.okrs" defaultMessage="Action items"/>;
  let archivedTitle = <FormattedMessage 
      id="okrs.archived.title" 
      defaultMessage={`<b>Including ${archivedObjectives.length} archived OKRs</b>`}
      values={{ b: (...chunks) => <b>{chunks}</b>}} />

  // Data for 3 widgets
  const objTrack = {
    data: objectives.filter(item => item.status === 1 && !item.isArchive).length,
    title: onTrack,
    bgColor: 'green',
  }
  const objBehind = {
    data: objectives.filter(item => item.status === 2 && !item.isArchive).length,
    title: behind,
    bgColor: 'yellow'
  }
  const objRisk = {
    data: objectives.filter(item => item.status === 3 && !item.isArchive).length,
    title: atRisk,
    bgColor: 'red'
  }
  const OKRdata = [ objTrack, objBehind, objRisk ];

  // Data for 4 widgets
  let completedOkrs = [];
  let totalProgress = 0;
  let averageProgress = 0;
  let progressColor = '';
  let completedTasks = [];
  let initiatives;

  // Completed okrs && progress
  objectives && objectives.forEach(item => {

    if (item.progress >= 100) {
      completedOkrs.push(item);
    }

    totalProgress += item.progress;
  });

  // Progress
  averageProgress = Math.round(totalProgress / objectives.length);
  if (averageProgress < 30) {
    progressColor = "red";
  } else if (averageProgress >= 30 && averageProgress < 60) {
    progressColor = "yellow";
  } else if (averageProgress >= 60) {
    progressColor = "green";
  }

  if (objectives.length === 0) {
    averageProgress = 0;
  }
  
  averageProgress = `${averageProgress}%`

  // Tasks
  completedTasks = tasks && todos.filter(item => item.isCompleted);
  initiatives = `${completedTasks.length}/${todos.length}`;

  // Create array with data for widgets
  const objOkrs = {
    data: objectives.length,
    title: totalOkrsTitle,
    subTitle: archivedTitle,
    width: '230px',
    height: '241px',
  };
  const objOkrsAchieved = {
    data: completedOkrs.length > 0 ? completedOkrs.length : 0,
    title: completedOkrsTitle,
    width: '230px',
    height: '186px',
  };
  const objProgress = {
    data: averageProgress,
    title: OkrsProgressTitle,
    width: '230px',
    height: '186px',
  };
  const objTasks = {
    data: initiatives,
    title: TasksTitle,
    width: '230px',
    height: '186px',
  };

  const widgetData = [objOkrs, objOkrsAchieved, objProgress, objTasks];
    
  const noOkrMessageTeam = {
    title: <FormattedMessage id="nookrs.title" defaultMessage="No Objectives" />,
    msg: 
    <FormattedMessage id="nookrs.msg" defaultMessage={`${team.label} has not created any objective yet.`} />
  };
  
  let updatedOkrsMsg = <FormattedMessage id="updated.okrs" defaultMessage='All OKRs were updated' />
  let noOksMsg = '';
  
  if (team) {
    if (team.type === 1 || 4) {
        noOksMsg = <FormattedMessage id="nookrs.msg.co" defaultMessage={`There are no objectives in the team view. You can check all company's objectives in the List view by clicking on the button List.`} />;
      } else if (team.type === 2) {
        noOksMsg = <FormattedMessage id="nookrs.msg.team" defaultMessage={`There are no teammates with personal objectives in a ${team.label} team. You can check all team's objectives in the List view by clicking on the button List.`} />;
      } else if (team.type === 3) {
        noOksMsg = <FormattedMessage id="nookrs.msg.user" defaultMessage={`${team.label} doesn't have an objective as an owner.`} />;
      }
  }

  let okrTxt = <FormattedMessage id="set.okr.title" defaultMessage="Set Objectives. Track. Measure." />
  let okrCta = <FormattedMessage id="set.okr.cta" defaultMessage="Create New Objective" />
  let subTitle = <FormattedMessage id="dashboard.okrs.subtitle" defaultMessage="Align your team around objectives to achieve better results." />

  // console.log('===== standups', standups)


  return (
    <Fragment>
      <div className="css-main bg-primary css-main__dashboard overflow-x-auto">
        <div className="wrapper">

        {/* {user && user.department ? (
          <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user.isAdmin && true} focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={13} />
        ) : (
          <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={13} />
        )} */}

        {user && !loading && (
          <MainMenu activeIndex={13} />
        )}

        <UserNav />
        
          <Fragment>
            {loading || !okrs || !user || !company || !tasks ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className={`${sidebar === 1 ? 'ml-280-xxl main main-dashboard ' : 'short_menu__ml pl-5 ma-w90'} main_new mt-3`}>
                  <div className="page-title">
                    <h5 className="pl-0-xs">{menuTitle}</h5>
                    <p className="medium">{subTitle}</p>
                  </div>

                  <div className="insights mt-3">
                    <div className="notifications flex align-center pb-3">
                      <Fragment> 
                        {company && new Date(company.date) < oldCompaniesDate && (
                          <Fragment>
                            {standups && standupAnswers.length === 0 && standups.length > 0 ||
                              standups && standupAnswers &&
                              standupAnswers.length > 0 && standups.length > 0 &&
                              standups[0]._id.toString() !== standupAnswers[0].standup.toString() ? 
                              (
                                <NotificationCheckin
                                  msg={checkinCopy.standupLiveNew}
                                  type={1}
                                  cta={checkinCopy.standupLiveCtaNew}
                                  icon={icons.light_bulb}
                                  btnColor={'blue'}
                                  color={`blue`}
                                  url={standups && standups.length > 0 ? `/checkins/${standups[0]._id}` : '/checkins'}
                                  styles={'mr-25'}
                                />
                              ) : (null)
                            }

                            {plan && planAnswers.length === 0 && plan.settings && plan.settings.active ||
                              plan && planAnswer && plan.settings && plan.settings.active && planAnswer.planID !== plan.settings.planID ? (
                                <NotificationCheckin
                                  msg={planCopy.planLiveNew}
                                  // msg2={msgHelloUser2}
                                  cta={planCopy.planLiveCtaNew}
                                  type={3}
                                  icon={icons.clipboard}
                                  btnColor={'yellow'}
                                  color={`yellow`}
                                  url={`/plans/complete`}
                                  styles={'mr-25'}
                                />
                              ) : null}

                            {updates && updateAnswers.length === 0 && updates.length > 0 ||
                            updates && updateAnswers.length > 0 && updates.length > 0 &&
                            updates[0]._id.toString() !== updateAnswers[0].update.toString() && 
                              (
                                <NotificationCheckin
                                  msg={updateCopy.updateLiveNew}
                                  // msg2={msgHelloUser2}
                                  type={2}
                                  cta={updateCopy.updateLiveCtaNew}
                                  icon={icons.clipboard}
                                  btnColor={'green'}
                                  color={`green`}
                                  url={updates.length > 0 ? `/updates/${updates[0]._id}` : '/updates'}
                                  styles={'mr-25'}
                                />
                              )}
                          </Fragment>
                        )}
                      </Fragment>
                      

                    {
                      icebreakers && icebreakers.length > 0 && icebreakers[0].answers.length === 0 ||
                          icebreakers && user && icebreakers.length > 0 && icebreakers[0].answers.length > 0 && 
                          icebreakers[0].answers.filter(item => item.user.toString() === user._id.toString()).length === 0 ?
                        (
                          <NotificationIcebreaker
                            msg={icebreakers[0].question}
                            icebreakerID={icebreakers[0]._id}
                            styles={'mr-25'}
                            user={user}
                            addIcebreakerAnswer={addIcebreakerAnswer}
                          />
                        ) : null}
                    </div>

                    <div className="mt-4 mb-5">
                      <SubMenu
                        items={menu && menu.menuArr ? menu.menuArr : []}
                        activeIndex={1}
                        mb={'5'}
                      />
                    </div>

                    <div className="flex space-between align-center flex-column-xs">
                      <FormattedMessage
                        id="checkins.sub_menu.teamSelect"
                        defaultMessage="Choose the team or user"
                      >
                        {msg => (
                          <Select
                            className="small-input w-350px w-100-xs"
                            defaultValue={choosedTeam}
                            value={team}
                            onChange={selectedOption =>
                              handleTeam(selectedOption)
                            }
                            options={teamOptions}
                            isSearchable
                            placeholder={msg}
                            required
                            name="team"
                            styles={customStyles}
                          />
                        )}
                      </FormattedMessage>

                      <FormattedMessage
                        id="focus.sub_menu.timeSelect"
                        defaultMessage="Choose the time interval"
                      >
                        {msg => (
                          <Select
                            className="small-input w-350px w-100-xs"
                            defaultValue={choosedTime}
                            value={time}
                            onChange={selectedOption =>
                              handleTime(selectedOption)
                            }
                            options={timeOptions}
                            placeholder={msg}
                            required
                            name="time"
                            styles={customStyles}
                          />
                        )}
                      </FormattedMessage>  
                    </div>

                    <div className="mt-4 flex flex-start flex-column-xs">
                      <div className="dashboard__left__okrs">
                        <OkrWidgets
                          width={'500px'}
                          widgetData={OKRdata}
                        />
                      </div>
                      <div className="dashboard__right__okrs">
                        <WidgetsDashboard 
                          bgColor={'light'}
                          widgetsAmount={4}
                          width={24}
                          widgetData={widgetData}
                          board={5}
                        />
                        <div className="mt-3">
                          <NotificationOkr
                            msg={okrTxt}
                            cta={okrCta}
                            btnColor={'blue'}
                            color={`blue`}
                            url={'/objective/new'}
                            width={'500px'}
                            height={'140px'}
                            styles={'w-500px h-140px text-left'}
                          />
                        </div>
                      </div>
                    </div> 
                  </div>

                  <div className="mt-2">
                    <p className="fs-22 lh-30 bold mb-1">
                      <FormattedMessage
                          id="objectives"
                          defaultMessage="Objectives"
                        />
                    </p>
                    <div className="toggle mla">
                      <button 
                        onClick={() => toggleOkrUnits(!okrUnits)}
                        className={classnames('btn__toggle-left', {
                          'btn__toggle-active': okrUnits
                        })}
                      >
                        <FormattedMessage
                          id="tasks.view.unit"
                          defaultMessage="Teams"
                        />
                      </button>
                      <button 
                        onClick={() => toggleOkrUnits(!okrUnits)}
                        className={classnames('btn__toggle-right', {
                          'btn__toggle-active': !okrUnits
                      })}
                        >
                      <FormattedMessage
                          id="tasks.view.list"
                          defaultMessage="List"
                        />
                      </button>
                    </div>
                  </div>

                  <div className="units">
                    {okrUnits ? (
                      <div className="br-4 brd brd-grey__light bg__white my-2 p-4">
                          <TableTitle />
                          
                          {objectivesForUnit.length > 0 ? (
                            objectivesForUnit.map((item, index) => (
                              <div key={`unit-item-${index}`}>
                                <UnitItem
                                    unitOkrs={item}
                                    user={user}
                                    team={team}
                                    formDataParent={formData}
                                    setFormDataParent={setFormData}
                                  />
                              </div>
                            ))
                          ) : (
                            <div className="mt-5">
                              <p className="medium">{noOksMsg}</p>
                            </div>
                          )}
                        </div>
                    ) : (
                      <div className="">
                            {objectives && objectives.length > 0 ? (
                              objectives.map(okr => (
                              <div className={`cards py-1 ${okr.isChild && 'ml-3'}`} key={`okr-item-${okr._id}`}>
                                <OkrCardItem
                                  key={okr._id}
                                  okr={okr}
                                  formDataParent={formData}
                                  setFormDataParent={setFormData}
                                  user={user}
                                  formType={2}
                                  okrBoard={'0'}
                                  isHideUpdateBtn={true}
                                  showAllKeyResults={false}
                                  isHideDetailsModal={true}
                                  titleIsLink={true}
                                  titleLink={`/objectives/${okr._id}`}
                                />
                              </div>
                              ))) : (
                                 <p className="medium p-4">{noOkrMessageTeam.msg}</p>
                            )
                            }
                        </div>
                    )}
                </div>

                <div className="not-updated-okrs">
                  <h3 className="fs-22 pt-2 pl-2 pb-1">{'Not updated OKRs'}</h3>
                  {notUpdatedOkrs.length > 0 ? (
                      <div className="my-2 py-3">
                        {notUpdatedOkrs.map((okr, index) => (
                            <div className={`cards py-1 ${okr.isChild && 'ml-3'}`} key={`okr-item-${okr._id}`}>
                              <OkrCardItem
                                key={okr._id}
                                okr={okr}
                                okrBoard={'0'}
                                listView={false}
                                user={user}
                                formType={2}
                                isHideUpdateBtn={true}
                                showAllKeyResults={false}
                                isHideDetailsModal={true}
                                titleIsLink={true}
                                titleLink={`/objectives/${okr._id}`}
                              />
                            </div>
                        ))}
                      </div>
                      ) : (
                        <div className="component my-2 p-4 light-shadow">
                        <p className="fs-16">{updatedOkrsMsg}</p>
                      </div>
                    )}
                </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

DashboardOkrs.propTypes = {
  getCompanyProfiles: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getActiveOkrs: PropTypes.func.isRequired,
  getCompanySortedOkrs: PropTypes.func.isRequired,
  getTasksWithOkrs: PropTypes.func.isRequired,
  getAllMetrics: PropTypes.func.isRequired,
  getAllCustoms: PropTypes.func.isRequired,
  clearOkr: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired,
  todo: PropTypes.object.isRequired,
  custom: PropTypes.object.isRequired,
  getUserAnswers: PropTypes.func.isRequired,
  getStandups: PropTypes.func.isRequired,
  getUserUpdateAnswers: PropTypes.func.isRequired,
  getUpdates: PropTypes.func.isRequired,
  getIcebreakers: PropTypes.func.isRequired,
  getUserPlanAnswers: PropTypes.func.isRequired,
  getPlanSettings: PropTypes.func.isRequired,
  addIcebreakerAnswer: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  locale: state.locale,
  company: state.company,
  profile: state.profile,
  okr: state.okr,
  todo: state.todo,
  custom: state.custom,
  standup: state.standup,
  update: state.update,
  updateAnswer: state.updateAnswer,
  standupAnswer: state.standupAnswer,
  icebreaker: state.icebreaker,
  planAnswer: state.planAnswer,
  plan: state.plan,
});

export default connect(
  mapStateToProps,
  {
    getUserCompany, getCompanyProfiles, getCompanySortedOkrs,
    getActiveOkrs, getTasksWithOkrs, getAllMetrics,
    getAllCustoms, clearOkr, 
    getUserAnswers,
    getStandups, getUserUpdateAnswers,
    getUpdates, getIcebreakers, 
    getUserPlanAnswers, getPlanSettings,
    addIcebreakerAnswer,
  }
)(DashboardOkrs);
