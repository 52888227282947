import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import CheckinSettingsTeam from './CheckinSettingsTeam';
import CheckinSettingsTime from './CheckinSettingsTime';

let visibilityOptions = [
  { label: `Everyone`, value: 1, name: 'visibility' },
  { label: `Team Members`, value: 2, name: 'visibility' },
  { label: `Private (only admins and user)`, value: 3, name: 'visibility' },
];

let intervalOptions = [
  { label: `Daily`, value: 'Daily', name: 'interval' },
  { label: `Weekly`, value: 'Weekly', name: 'interval' },
  { label: `Monthly`, value: 'Monthly', name: 'interval' },
];

let daysOptions = [
  { label: `Monday`, value: '1', name: 'weekday' },
  { label: `Tuesday`, value: '2', name: 'weekday' },
  { label: `Wednesday`, value: '3', name: 'weekday' },
  { label: `Thursday`, value: '4', name: 'weekday' },
  { label: `Friday`, value: '5', name: 'weekday' },
  { label: `Saturday`, value: '6', name: 'weekday' },
  { label: `Sunday`, value: '7', name: 'weekday' },
];


// type 1 - create, type 2 - edit
const CheckinSettings = ({
  formData,
  scheduleTime,
  handleTime,
  timezone,
  onChangeTimezone,
  interval,
  onChangeSelect,
  scheduleDay,
  onChangeDay,
  handleDaysChange,
  onChangeDate,
  profiles,
  handleUsers,
  emailUsersOptions,
  slackUserAndChannelOptions,
  handleSelect,
  handleIntervalSelect,
  type,
  isCopy,
 }) => {

  // Create checkin - type 1, Edit checkin - type 2

  let scheduleLength = 0;
  if (scheduleDay !== undefined && scheduleDay !== null) {
    scheduleLength = Object.keys(scheduleDay).length;
  }

  let defaultValueTeam = {}
  let defaultValueTime = {}

  if (formData && formData.visibility && formData.visibility.label) {
    defaultValueTeam = {
      users: type === 2 && formData.selectedEmailUsers ? formData.selectedEmailUsers : formData.selectedEmailUsers !== null ? emailUsersOptions[0] : [],
      usersValue: formData.selectedEmailUsers,
      // users: type === 2 && formData.selectedEmailUsers ? formData.selectedEmailUsers : emailUsersOptions[0],
      visibility: type === 2 && formData.visibility && formData.visibility.label ? formData.visibility : visibilityOptions[0],
    }
    if (isCopy) {
      defaultValueTeam = {
        users: formData.selectedEmailUsers ? formData.selectedEmailUsers : emailUsersOptions[0],
        visibility: formData.visibility && formData.visibility.label ? formData.visibility : visibilityOptions[0],
      }
    }
  }
  // console.log('==== isCopy', isCopy)
  // console.log('==== type', type)
  // console.log('==== formData.selectedEmailUsers', formData.selectedEmailUsers)


  if (formData && formData.interval && formData.interval.length > 0) {
    defaultValueTime = {
      interval: type === 2 ? formData.interval : intervalOptions[1],
      // interval: type === 2 || isCopy ? formData.interval : intervalOptions[1],
      days: formData.scheduleDay && formData.scheduleDay.index ? formData.scheduleDay : daysOptions[0],
    }
    // console.log('==== HERE iscopy', isCopy)
    // console.log('==== HERE formData.interval', formData.interval)

    if (isCopy) {
      defaultValueTime.interval = intervalOptions.filter(item => item.value === formData.interval)[0]
    }
  }

  // console.log('==== emailUsersOptions', emailUsersOptions)
  // // console.log('==== defaultValueTime', defaultValueTime)
  // console.log('==== defaultValueTime', defaultValueTime)
  // console.log('==== formData', formData)

  return (
    <Fragment>
      <Fragment>
        <div className="form-main form-main-inline mt-2">
          {defaultValueTeam.users && (
            <CheckinSettingsTeam 
              timezone={timezone}
              onChangeTimezone={onChangeTimezone}
              profiles={profiles}
              handleUsers={handleUsers}
              emailUsersOptions={emailUsersOptions}
              slackUserAndChannelOptions={slackUserAndChannelOptions}
              handleSelect={handleSelect}
              defaultValue={defaultValueTeam}
              formData={formData}
            />
          )}
          
        </div>

        <div className="form-main1 form-main-inline1 mt-2">
          {defaultValueTime.interval && (
            <CheckinSettingsTime 
              formData={formData}
              scheduleTime={scheduleTime}
              handleTime={handleTime}
              interval={interval}
              scheduleDay={scheduleDay}
              handleDaysChange={handleDaysChange}
              handleIntervalSelect={handleIntervalSelect}
              onChangeDay={onChangeDay}
              onChangeDate={onChangeDate}
              defaultValue={defaultValueTime}
              type={type}
            />
          )}
        </div>
      </Fragment>
    </Fragment>
  );
};

CheckinSettings.propTypes = {
  formData: PropTypes.object.isRequired,
  handleTime: PropTypes.func.isRequired,
};

export default CheckinSettings;
