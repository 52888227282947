import React, { Fragment, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Navbar from '../layout/Navbar';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import { FormattedMessage } from 'react-intl';
import { icons } from '../../img/elements/icons';


const Login = ({ login, auth: { isAuthenticated, user } }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [showPassword, setShowPassword] = useState(false)

  const [scriptLoaded, setScriptLoaded] = useState(false);

  // Load script after page load
  useEffect(() => {
    (function (w, d, i, s) {
      function l() {
        if (!d.getElementById(i)) {
          var f = d.getElementsByTagName(s)[0],
            e = d.createElement(s);
          (e.type = "text/javascript"),
            (e.async = !0),
            (e.src = "https://canny.io/sdk.js"),
            f.parentNode.insertBefore(e, f);
        }
      }
      if ("function" != typeof w.Canny) {
        var c = function () {
          c.q.push(arguments);
        };
        (c.q = []),
          (w.Canny = c),
          "complete" === d.readyState
            ? l()
            : w.attachEvent
            ? w.attachEvent("onload", l)
            : w.addEventListener("load", l, !1);
      }
    })(window, document, "canny-jssdk", "script");
    setScriptLoaded(true);
  }, []);

  useEffect(() => {
    if (!scriptLoaded || !user) {
      return;
    }

    // console.log('==== user in useEffect', user) 

    // Only run if user has changed
    Canny("identify", {
      appID: process.env.REACT_APP_CANNY_ID,
      user: {
        email: user.email,
        name: `${user.firstName} ${user.lastName}}`,
        id: user._id,
        avatarURL: user.avatar,
        created: user.date
      }
    });
    // console.log('==== Canny in useEffect', Canny)

  }, [scriptLoaded, JSON.stringify(user)]);

  const { email, password } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    login(email, password);
  };

  // Redirect if logged in
  if (isAuthenticated) {
    // console.log('==== user in isAuthenticated', user)

    if (user && user.numberOfLogins === undefined || user && user.numberOfLogins === 1) {
      if (user.role === 'Owner' || user.role === 'Superadmin') {
        // Admins
        return <Redirect to="/welcome/hello" />;
      } else {
        // Members
        return <Redirect to="/welcome/users" />;
      }
    }
    return <Redirect to="/dashboard/objectives" />;
  }

  return (
    <Fragment>
      <section id="login">
        <div className="container pt-5">
          <div className="section_header section_header_new pb-0 mb-2_5">
            <Navbar guest={true} />
            <h2 className="h2_new">
              <FormattedMessage id="auth.login.header" defaultMessage="Login" />
            </h2>
            <p className="lead lead_new">
              <FormattedMessage
                id="auth.login.pass"
                defaultMessage="Enter your email and password to login."
              />
            </p>
          </div>
          <div className="form form-detail form_new mb-2_5">
            <form onSubmit={e => onSubmit(e)}>
              <div className="field_email field_email_new field mb-2">
                <FormattedMessage
                  id="auth.login.emailInput"
                  defaultMessage="Email"
                >
                  {msg => (
                    <input
                      type="email"
                      className="email email_new"
                      placeholder={msg}
                      name="email"
                      value={email}
                      onChange={e => onChange(e)}
                      required
                      autoFocus
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className="field_password field_password_new field mb-3">
                <FormattedMessage
                  id="auth.login.passInput"
                  defaultMessage="Password"
                >
                  {msg => (
                    <div className='pass-input_new' >
                      <input
                        type={showPassword ? "text" : "password"}
                        className="password password_new"
                        placeholder={msg}
                        name="password"
                        value={password}
                        onChange={e => onChange(e)}
                        minLength="6"
                        required
                        autoComplete="off"
                      />
                      {password && (
                        <button type="button" className='pass-visibility_new' onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? icons.hide_password : icons.view_password}
                        </button>
                      )}

                    </div>
                  )}
                </FormattedMessage>
              </div>
              <div className="form_button">
                <button type="submit" className="btn btn_new btn-primary">
                  {' '}
                  <FormattedMessage
                    id="auth.login.btn"
                    defaultMessage="Login"
                  />
                </button>
              </div>
            </form>
          </div>
          <div className='link-auth'>
            <p className="text-center block block_new mb-4">
              <Link to="/password/reset" className="medium cursor dark-gray medium_new link-auth_new">
                <FormattedMessage id="auth.signup.reset.btn" defaultMessage="Forgot your password ?" />
              </Link>
            </p>
            <p className="text-center block block_new bold mb-1_5">
              <FormattedMessage
                id="auth.login.haveAccount"
                defaultMessage="Don't have Focus account yet ?"
              />
              {' '}
            </p>
            <Link to="/signup" className="btn btn-light_new">
              <FormattedMessage
                id="auth.login.signup"
                defaultMessage="Create new account"
              />
            </Link>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { login }
)(Login);
