import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

const updateInput = (ref, value) => {
  const input = ref.current;
  if (input) {
    input.checked = value;
    input.indeterminate = value == null;
  }
};

const ThreeStateCheckbox = ({ value, onChange }) => {
  const inputRef = React.useRef(null);
  const valueRef = React.useRef(value);

  React.useEffect(() => {
    valueRef.current = value;
    updateInput(inputRef, value);
  }, [value]);

  const handleClick = () => {
    valueRef.current = !valueRef.current;
    updateInput(inputRef, valueRef.current);
    if (onChange) {
      onChange(valueRef.current);
    }
  };

  return (
    <Fragment>
      <div className="checkbox pl-3 mb-2-xs">
        <div className="checkbox-item active-status">
          <input ref={inputRef} type="checkbox" onClick={handleClick} />
          <label onClick={handleClick}>{value ? (
              <FormattedMessage id="checkbox.unselectAll" defaultMessage="Unselect all" />
            ) : (
              <FormattedMessage id="checkbox.selectAll" defaultMessage="Select all" />
            )}
          </label>
        </div>
      </div>
  </Fragment>
  );
};

export default ThreeStateCheckbox;