import React, { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPlanSettings, editPlan, clearPlanSettings } from '../../actions/plan';
import { getUserCompany } from '../../actions/company';
import { getSlackUsers, getSlackChannels, editSlackSettings } from '../../actions/slack';
import { getDiscordChannels, getDiscordFocusUsers } from '../../actions/discord';
import { getStandupSettings, clearStandupSettings } from '../../actions/standup';
import { getUpdateSettings, clearUpdateSettings } from '../../actions/update';
import { getIcebreakerSettings, clearIcebreakers } from '../../actions/icebreaker';
import { getCompanyProfiles } from '../../actions/profile';
import Logo from '../elems/Logo';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import SlackItem from './elems/SlackItem';
import SlackSettingsCopy from './elems/SlackSettingsCopy';
import { FormattedMessage } from 'react-intl';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    fontSize: '14px',
  }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
}

const EditSlack = ({
  getPlanSettings,
  editPlan,
  plan,
  auth: { user },
  history,
  getUserCompany,
  profile: { profiles },
  getCompanyProfiles,
  company: { company },
  slack,
  getSlackUsers,
  getSlackChannels,
  getDiscordChannels, 
  getDiscordFocusUsers,
  discord,
  getStandupSettings,
  standup: { settings, loading },
  getUpdateSettings,
  update,
  getIcebreakerSettings,
  icebreaker,
  editSlackSettings,
  clearStandupSettings,
  clearUpdateSettings,
  clearPlanSettings,
  clearIcebreakers,
}) => {
  const [formData, setFormData] = useState({
    slackIsActive: settings && settings.slackIsActive,
    isAsync: settings && settings.isAsync,
    slackReportChannels: settings && settings.slackReportChannels,
    selectedOption: [],
    selectedChannelOption: [],
    selectedStandupUsers: [],
    planIsActive: plan.settings && plan.settings.slackIsActive,
    planIsAsync: plan.settings && plan.settings.isAsync,
    planReportChannels: plan.settings && plan.settings.slackReportChannels,
    selectedPlanUsers: [],
    selPlanOption: [],
    selPlanChannelOption: [],
    updateIsActive: update.settings && update.settings.slackIsActive,
    updateIsAsync: update.settings && update.settings.isAsync,
    updateReportChannels: update.settings && update.settings.slackReportChannels,
    selectedUpdateUsers: [],
    selUpdateOption: [],
    selUpdateChannelOption: [],
    icebreakerIsActive: icebreaker.settings && icebreaker.settings.slackIsActive,
    icebreakerIsAsync: icebreaker.settings && icebreaker.settings.isAsync,
    icebreakerReportChannels: icebreaker.settings && icebreaker.settings.slackReportChannels,
    selectedIcebreakerUsers: [],
    selIcebreakerOption: [],
    selIcebreakerChannelOption: [],
  });

  const {
    slackIsActive, 
    isAsync, slackReportChannels,
    selectedStandupUsers,
    selectedPlanUsers,
    planIsActive,
    planIsAsync,
    updateIsActive,
    updateIsAsync,
    icebreakerIsActive,
    icebreakerIsAsync,
    planReportChannels,
    updateReportChannels,
    icebreakerReportChannels,
  } = formData;

  const [reportChannels, toggleReportChannels] = useState(false);
  const [slackStandupForm, toggleStandupForm] = useState(false);
  const [slackPlanForm, togglePlanForm] = useState(false);
  const [reportPlanChannels, togglePlanReportChannels] = useState(false);
  const [slackUpdateForm, toggleUpdateForm] = useState(false);
  const [reportUpdateChannels, toggleUpdateReportChannels] = useState(false);
  const [slackIcebreakerForm, toggleIcebreakerForm] = useState(false);
  const [reportIcebreakerChannels, toggleIcebreakerReportChannels] = useState(false);

  let options = [];
  let choosedUsers = [];
  let channelOptions = [];
  let choosedChannels = [];
  let choosedStandupUsers = [];
  let slackUserAndChannelOptions = [];
  let userOptions = [];
  let chPlanUsers = [];
  let chPlanChannels = [];
  let choosedPlanUsers = [];
  // Updates
  let chUpdateUsers = [];
  let chUpdateChannels = [];
  let choosedUpdateUsers = [];
  // Icebreakers
  let chIcebreakerUsers = [];
  let chIcebreakerChannels = [];
  let choosedIcebreakerUsers = [];
  
  // Users for options
  {
    profiles &&
      profiles.forEach((profile, index) => {

        let coworker = {
          value: profile.email,
          label: `${profile.firstName} ${profile.lastName}`,
          team: 3,
          id: profile._id.toString(), 
        };

        // emailUsersOptions.push(coworker);
        userOptions.push(coworker);

        if (profiles && index === profiles.length - 1) {
          // Third option is the company
          let name = { value: 'Company', label: `💪 Everyone`, team: 1 };
        }
      });
  }

  // Get Slack Users to put them in select (for participants select)
  // Choosed users
  settings && settings.slackUsers && settings.slackUsers.forEach(user => {
    let u = {
      value: user.value,
      label: user.name,
      team: user.team
    }

    choosedStandupUsers.push(u);
  })

  plan && plan.settings && plan.settings.slackUsers.forEach(user => {
    let u = {
      value: user.value,
      label: user.name,
      team: user.team
    }

    choosedPlanUsers.push(u);
  })

  // Slack options (users + channels in one arr)
  slack.users &&
    slack.users.forEach((user) => {
      let u = {
        value: user.user_id,
        label: user.name,
        team: 3
      };

      slackUserAndChannelOptions.push(u);
    });

  slack.channels &&
    slack.channels.forEach((channel, index) => {
      let u = {
        value: channel.channel_id,
        label: `#${channel.name}`,
        team: 2
      };

      slackUserAndChannelOptions.unshift(u);

      if (slack && index === slack.channels.length - 1) {
         // First option is the company
          let name = { value: 'Company', label: `💪 Everyone`, team: 1 };
          slackUserAndChannelOptions.unshift(name);
       }
    });

  // Users Options for Select format (for delivery report)
  slack.users &&
    slack.users.forEach(user => {
      let u = {
        value: user.user_id,
        label: user.name,
        avatar: user.avatar
      };

      options.push(u);
    });

  // Channels Options for Select format
  slack.channels &&
    slack.channels.forEach(channel => {
      let u = {
        value: channel.channel_id,
        label: channel.name
      };

      channelOptions.push(u);
    });

  // Users & Channels Default values for Select format
    settings && settings.slackReportChannels &&
    settings.slackReportChannels.forEach(item => {
      if (item.user_id) {
        let с = {
          value: item.user_id,
          label: item.name
        };

        choosedUsers.push(с);
      }

      // For channels
      if (item.channel_id) {
        let с = {
          value: item.channel_id,
          label: item.name
        };

        choosedChannels.push(с);
      }
    });

    plan && plan.settings && plan.settings.slackReportChannels &&
    plan.settings.slackReportChannels.forEach(item => {
      if (item.user_id) {
        let с = {
          value: item.user_id,
          label: item.name
        };

        chPlanUsers.push(с);
      }

      // For channels
      if (item.channel_id) {
        let с = {
          value: item.channel_id,
          label: item.name
        };

        chPlanChannels.push(с);
      }
    });

    // Updates
    update && update.settings && update.settings.slackUsers.forEach(user => {
      let u = {
        value: user.value,
        label: user.name,
        team: user.team
      }
  
      choosedUpdateUsers.push(u);
    })

    update && update.settings && update.settings.slackReportChannels &&
    update.settings.slackReportChannels.forEach(item => {
      if (item.user_id) {
        let с = {
          value: item.user_id,
          label: item.name
        };

        chUpdateUsers.push(с);
      }

      // For channels
      if (item.channel_id) {
        let с = {
          value: item.channel_id,
          label: item.name
        };

        chUpdateChannels.push(с);
      }
    });

    // Icebreakers
    icebreaker && icebreaker.settings && icebreaker.settings.slackUsers.forEach(user => {
      let u = {
        value: user.value,
        label: user.name,
        team: user.team
      }
  
      choosedIcebreakerUsers.push(u);
    })

    icebreaker && icebreaker.settings && icebreaker.settings.slackReportChannels &&
    icebreaker.settings.slackReportChannels.forEach(item => {
      if (item.user_id) {
        let с = {
          value: item.user_id,
          label: item.name
        };

        chIcebreakerUsers.push(с);
      }

      // For channels
      if (item.channel_id) {
        let с = {
          value: item.channel_id,
          label: item.name
        };

        chIcebreakerChannels.push(с);
      }
    });


  useEffect(() => {
    getPlanSettings();
    getStandupSettings();
    getUpdateSettings();
    getIcebreakerSettings();
    getUserCompany();
    getCompanyProfiles();
    company && company.isSlack && getSlackUsers(company._id, company.slackTeamId);   
    company && company.isSlack && getSlackChannels(company._id, company.slackTeamId);
  }, [
    loading, 
    company && company.isSlack,
  ]);

  useEffect(() => {
    
    setFormData({
      ...formData,
      slackIsActive: !settings ? false : settings.slackIsActive,
      isAsync: !settings ? false : settings.isAsync,
      slackReportChannels: !settings ? false : settings.slackReportChannels,
      selectedOption: !slack || !settings ? '' : choosedUsers,
      selectedChannelOption: !slack || !settings ? '' : choosedChannels,
      selectedStandupUsers: !slack || !settings ? '' : choosedStandupUsers,
      planIsActive: !plan || plan && !plan.settings ? false : plan.settings.slackIsActive,
      planIsAsync: !plan || plan && !plan.settings ? false : plan.settings.isAsync,
      planReportChannels: !plan || plan && !plan.settings ? false : plan.settings.slackReportChannels,
      selPlanOption: !slack || !plan || plan && !plan.settings ? '' : chPlanUsers,
      selPlanChannelOption: !slack || !settings ? '' : chPlanChannels,
      selectedPlanUsers: !slack || !plan || plan && !plan.settings ? '' : choosedPlanUsers,
      updateIsActive: !update || update && !update.settings ? false : update.settings.slackIsActive,
      updateIsAsync: !update || update && !update.settings ? false : update.settings.isAsync,
      updateReportChannels: !update || update && !update.settings ? false : update.settings.slackReportChannels,
      selUpdateOption: !slack || !update || update && !update.settings ? '' : chUpdateUsers,
      selUpdateChannelOption: !slack || !settings ? '' : chUpdateChannels,
      selectedUpdateUsers: !slack || !update || update && !update.settings ? '' : choosedUpdateUsers,
      icebreakerIsActive: !icebreaker || icebreaker && !icebreaker.settings ? false : icebreaker.settings.slackIsActive,
      icebreakerIsAsync: !icebreaker || icebreaker && !icebreaker.settings ? false : icebreaker.settings.isAsync,
      icebreakerReportChannels: !icebreaker || icebreaker && !icebreaker.settings ? false : icebreaker.settings.slackReportChannels,
      selIcebreakerOption: !slack || !icebreaker || icebreaker && !icebreaker.settings ? '' : chIcebreakerUsers,
      selIcebreakerChannelOption: !slack || !settings ? '' : chIcebreakerChannels,
      selectedIcebreakerUsers: !slack || !icebreaker || icebreaker && !icebreaker.settings ? '' : choosedIcebreakerUsers,
    });
  }, [
    getCompanyProfiles, 
    getUserCompany, 
    JSON.stringify(settings),
    JSON.stringify(slack),
    JSON.stringify(plan),
    JSON.stringify(update),
    JSON.stringify(icebreaker),
  ]);

  useEffect(() => {
    return () => {
      clearStandupSettings();
      clearUpdateSettings();
      clearPlanSettings();
      clearIcebreakers();
    }
  }, []);

  // Select hour & minute options
  const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  const hourOptions = hours.map(hour => {
        return (
          <option value={hour} key={hour}>
            {hour}
          </option>
        );
      });

  const minutes = ['00', '15', '30', '45'];
  const minuteOptions = minutes.map(minute => {
        return (
          <option value={minute} key={minute}>
            {minute}
          </option>
        );
      });

  const handleStandupUsers = selectedOption => {
    setFormData({ ...formData, selectedStandupUsers: selectedOption });
  };

  const handleSlackUsersForm = e => {
    toggleStandupForm(!slackStandupForm);
  };
  
  const handleChangeReport = selectedOption => {
    setFormData({ ...formData, selectedOption: selectedOption });
  };

  const handleChangeChannelReport = selectedChannelOption => {
    setFormData({ ...formData, selectedChannelOption: selectedChannelOption });
  };

  const handleReport = e => {
    toggleReportChannels(!reportChannels);
  };

  // Selects
  const handleStandupCheckbox = (name, value) => {
    let title = 'slackIsActive';
    if (name === 'isAsync') { title = 'isAsync' }

    setFormData({ ...formData, [title]: value });
  };

  const handlePlanCheckbox = (name, value) => {

    let title = 'planIsActive';
    if (name === 'isAsync') { title = 'planIsAsync' }

    setFormData({ ...formData, [title]: value });
  };

  const handleUpdateCheckbox = (name, value) => {

    let title = 'updateIsActive';
    if (name === 'isAsync') { title = 'updateIsAsync' }

    setFormData({ ...formData, [title]: value });
  };

  const handleIcebreakerCheckbox = (name, value) => {

    let title = 'icebreakerIsActive';
    if (name === 'isAsync') { title = 'icebreakerIsAsync' }

    setFormData({ ...formData, [title]: value });
  };

  // Plans
  const handlePlanUsers = selectedOption => {
    setFormData({ ...formData, selectedPlanUsers: selectedOption });
  };

  const handlePlanUsersForm = e => {
    togglePlanForm(!slackPlanForm);
  };

  const handlePlanChangeReport = selectedOption => {
    setFormData({ ...formData, selPlanOption: selectedOption });
  };

  const handlePlanChangeChannelReport = selectedChannelOption => {
    setFormData({ ...formData, selPlanChannelOption: selectedChannelOption });
  };

  const handlePlanReport = e => {
    togglePlanReportChannels(!reportPlanChannels);
  };

  // Updates
  const handleUpdateUsers = selectedOption => {
    setFormData({ ...formData, selectedUpdateUsers: selectedOption });
  };

  const handleUpdateUsersForm = e => {
    toggleUpdateForm(!slackUpdateForm);
  };

  const handleUpdateChangeReport = selectedOption => {
    setFormData({ ...formData, selUpdateOption: selectedOption });
  };

  const handleUpdateChangeChannelReport = selectedChannelOption => {
    setFormData({ ...formData, selUpdateChannelOption: selectedChannelOption });
  };

  const handleUpdateReport = e => {
    toggleUpdateReportChannels(!reportUpdateChannels);
  };


  const refreshSlackUsers = (e) => {
    e.preventDefault();

    getSlackUsers(company._id, company.slackTeamId);  
    getSlackChannels(company._id, company.slackTeamId);
  };

  // Updates
  const handleIcebreakerUsers = selectedOption => {
    setFormData({ ...formData, selectedIcebreakerUsers: selectedOption });
  };

  const handleIcebreakerUsersForm = e => {
    toggleIcebreakerForm(!slackIcebreakerForm);
  };

  const handleIcebreakerChangeReport = selectedOption => {
    setFormData({ ...formData, selIcebreakerOption: selectedOption });
  };

  const handleIcebreakerChangeChannelReport = selectedChannelOption => {
    setFormData({ ...formData, selIcebreakerChannelOption: selectedChannelOption });
  };

  const handleIcebreakerReport = e => {
    toggleIcebreakerReportChannels(!reportIcebreakerChannels);
  };

  const onSubmit = e => {

    e.preventDefault();
    editSlackSettings(formData, plan.settings._id, history);
  };

  let checkinTitle = <FormattedMessage id="daily.checkins" defaultMessage="Daily Check-ins" />
  let planTitle = <FormattedMessage id="weekly.plans" defaultMessage="Weekly Plans" />
  let updateTitle = <FormattedMessage id="weekly.updates" defaultMessage="Weekly Update" />
  let icebreakerTitle = <FormattedMessage id="icebreakers" defaultMessage="Icebreakers" />
  
  return (
    <Fragment>
      <div className="css-main bg-primary">
          <div className="wrapper">
            <div className="main_menu absolute"><Logo /></div>
            <UserNav />

            <Fragment>
              {!settings || loading ? (
                <Spinner />
              ) : (
                  <div className="main mxa pl-0 main-profile-admin">
                    <div className="standups-admin pt-3 mt-0-xs pt-1-xs">
                      <div className="section-title">
                        <h5>
                          <FormattedMessage id="edit.bot.settings.header" defaultMessage="Edit the Focus bot settings" />
                        </h5>
                        <Link
                          to="/settings/integrations"
                          className="btn-secondary btn-secondary-back"
                        >
                          <FormattedMessage id="go.back" defaultMessage="Go Back" />
                        </Link>
                      </div>
                      <div className="component edit-updates-weekly my-2 p-3 light-shadow">
                        <div className="form-full">
                          <div className="py-0">
                            <SlackSettingsCopy />
                          </div>

                          <form
                            className="form-app form-app-update"
                            onSubmit={e => onSubmit(e)}
                          >
                          
                              <div className="my-3">
                                <SlackItem 
                                  formData={formData && formData}
                                  setFormData={setFormData && setFormData}
                                  slackIsActive={slackIsActive}
                                  isAsync={isAsync}
                                  choosedSlackUsers={choosedStandupUsers}
                                  handleSlackUsersForm={handleSlackUsersForm}
                                  slackUsersForm={slackStandupForm}
                                  profiles={profiles}
                                  handleSlackUsers={handleStandupUsers}
                                  slackUserAndChannelOptions={slackUserAndChannelOptions}
                                  slackReportChannels={slackReportChannels}
                                  handleReport={handleReport}
                                  reportChannels={reportChannels}
                                  slack={slack}
                                  choosedUsers={choosedUsers}
                                  handleChangeReport={handleChangeReport}
                                  options={options}
                                  choosedChannels={choosedChannels}
                                  handleChangeChannelReport={handleChangeChannelReport}
                                  channelOptions={channelOptions}
                                  sectionTitle={checkinTitle}
                                  handleCheckbox={handleStandupCheckbox}
                                  type={10}
                                  refreshSlackUsers={refreshSlackUsers}
                                />
                              </div>
                              
                              <div className="my-3">
                                <SlackItem 
                                  formData={formData && formData}
                                  setFormData={setFormData && setFormData}
                                  slackIsActive={planIsActive}
                                  isAsync={planIsAsync}
                                  choosedSlackUsers={choosedPlanUsers}
                                  handleSlackUsersForm={handlePlanUsersForm}
                                  slackUsersForm={slackPlanForm}
                                  profiles={profiles}
                                  handleSlackUsers={handlePlanUsers}
                                  slackUserAndChannelOptions={slackUserAndChannelOptions}
                                  slackReportChannels={planReportChannels}
                                  handleReport={handlePlanReport}
                                  reportChannels={reportPlanChannels}
                                  slack={slack}
                                  choosedUsers={chPlanUsers}
                                  handleChangeReport={handlePlanChangeReport}
                                  options={options}
                                  choosedChannels={chPlanChannels}
                                  handleChangeChannelReport={handlePlanChangeChannelReport}
                                  channelOptions={channelOptions}
                                  sectionTitle={planTitle}
                                  handleCheckbox={handlePlanCheckbox}
                                  type={11}
                                  refreshSlackUsers={refreshSlackUsers}
                                />
                              </div>
                              
                              <div className="my-3">
                                <SlackItem 
                                  formData={formData && formData}
                                  setFormData={setFormData && setFormData}
                                  slackIsActive={updateIsActive}
                                  isAsync={updateIsAsync}
                                  choosedSlackUsers={choosedUpdateUsers}
                                  handleSlackUsersForm={handleUpdateUsersForm}
                                  slackUsersForm={slackUpdateForm}
                                  profiles={profiles}
                                  handleSlackUsers={handleUpdateUsers}
                                  slackUserAndChannelOptions={slackUserAndChannelOptions}
                                  slackReportChannels={updateReportChannels}
                                  handleReport={handleUpdateReport}
                                  reportChannels={reportUpdateChannels}
                                  slack={slack}
                                  choosedUsers={chUpdateUsers}
                                  handleChangeReport={handleUpdateChangeReport}
                                  options={options}
                                  choosedChannels={chUpdateChannels}
                                  handleChangeChannelReport={handleUpdateChangeChannelReport}
                                  channelOptions={channelOptions}
                                  sectionTitle={updateTitle}
                                  handleCheckbox={handleUpdateCheckbox}
                                  type={12}
                                  refreshSlackUsers={refreshSlackUsers}
                                />
                              </div>
                              <div className="my-3">
                                <SlackItem 
                                  formData={formData && formData}
                                  setFormData={setFormData && setFormData}
                                  slackIsActive={icebreakerIsActive}
                                  isAsync={icebreakerIsAsync}
                                  choosedSlackUsers={choosedIcebreakerUsers}
                                  handleSlackUsersForm={handleIcebreakerUsersForm}
                                  slackUsersForm={slackIcebreakerForm}
                                  profiles={profiles}
                                  handleSlackUsers={handleIcebreakerUsers}
                                  slackUserAndChannelOptions={slackUserAndChannelOptions}
                                  slackReportChannels={icebreakerReportChannels}
                                  handleReport={handleIcebreakerReport}
                                  reportChannels={reportIcebreakerChannels}
                                  slack={slack}
                                  choosedUsers={chIcebreakerUsers}
                                  handleChangeReport={handleIcebreakerChangeReport}
                                  options={options}
                                  choosedChannels={chIcebreakerChannels}
                                  handleChangeChannelReport={handleIcebreakerChangeChannelReport}
                                  channelOptions={channelOptions}
                                  sectionTitle={icebreakerTitle}
                                  handleCheckbox={handleIcebreakerCheckbox}
                                  type={14}
                                  isHideReports={true}
                                  refreshSlackUsers={refreshSlackUsers}
                                />
                              </div>
                            <button type="submit" className="btn btn-form">
                              <FormattedMessage id="admin.dashboard.edit.submit" defaultMessage="Submit" />
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

EditSlack.propTypes = {
  clearStandupSettings: PropTypes.func.isRequired,
  getStandupSettings: PropTypes.func.isRequired,
  getPlanSettings: PropTypes.func.isRequired,
  getUpdateSettings: PropTypes.func.isRequired,
  getIcebreakerSettings: PropTypes.func.isRequired,
  editSlackSettings: PropTypes.func.isRequired,
  editPlan: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getSlackUsers: PropTypes.func,
  getSlackChannels: PropTypes.func,
  getCompanyProfiles: PropTypes.func.isRequired,
  getDiscordFocusUsers: PropTypes.func.isRequired,
  getDiscordChannels: PropTypes.func.isRequired,
  clearUpdateSettings: PropTypes.func.isRequired,
  clearPlanSettings: PropTypes.func.isRequired,
  clearIcebreakers: PropTypes.func.isRequired,
  plan: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  slack: PropTypes.object,
  discord: PropTypes.object,
  standup: PropTypes.object,
  update: PropTypes.object,
  icebreaker: PropTypes.object,
};

const mapStateToProps = state => ({
  company: state.company,
  plan: state.plan,
  slack: state.slack,
  discord: state.discord,
  profile: state.profile,
  standup: state.standup,
  update: state.update,
  icebreaker: state.icebreaker,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { editPlan,
    getPlanSettings,
    getUserCompany,
    getSlackUsers,
    getSlackChannels,
    getCompanyProfiles,
    getDiscordChannels, 
    getDiscordFocusUsers,
    getStandupSettings,
    getUpdateSettings,
    getIcebreakerSettings,
    editSlackSettings,
    clearStandupSettings,
    clearUpdateSettings,
    clearPlanSettings,
    clearIcebreakers,
   }
)(withRouter(EditSlack));
