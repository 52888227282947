import {
  GET_USER_UPDATE_ANSWERS,
  GET_PROFILE_UPDATE_ANSWERS,
  GET_TEAM_UPDATE_ANSWERS,
  GET_UPDATE_ANSWERS,
  UPDATE_ANSWER_ERROR,
  UPDATE_ANSWERS_ERROR,
  ADD_UPDATE_ANSWER,
  GET_UPDATE_ANSWER,
  UPDATE_LIKES,
  ADD_COMMENT,
  UPDATE_COMMENT,
  CLEAR_UPDATE_ANSWERS,
  GET_COMPANY_UPDATE_ANSWERS,
  COMPANY_UPDATE_ANSWER_ERROR,
  REMOVE_COMMENT,
  LOCATION_CHANGE_ERROR_UPDATE,
  GET_UPDATE_ANSWER_BY_ID
} from '../actions/types';

const initialState = {
  updateAnswers: [],
  updateAnswer: null,
  loading: true,
  isHomeLoadingUpdate: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_UPDATE_ANSWERS:
    case LOCATION_CHANGE_ERROR_UPDATE: 
     return {
       ...state,
       updateAnswers: [],
       updateAnswer: null,
       loading: true
     };
    case GET_UPDATE_ANSWERS:
    case GET_PROFILE_UPDATE_ANSWERS:
    case GET_TEAM_UPDATE_ANSWERS:
      return {
        ...state,
        updateAnswers: payload,
        loading: false,
        isHomeLoadingUpdate: false
      };
    case GET_COMPANY_UPDATE_ANSWERS:
      return {
        ...state,
        updateAnswers: payload.company,
        updateAnswer: payload.user,
        loading: false
      };
    case GET_USER_UPDATE_ANSWERS:
      return {
        ...state,
        updateAnswers: payload.answers,
        updateAnswer: payload.last,
        loading: false,
        isHomeLoadingUpdate: false
       };
    case UPDATE_ANSWER_ERROR:
    case GET_UPDATE_ANSWER:
    case GET_UPDATE_ANSWER_BY_ID:
      return {
        ...state,
        updateAnswer: payload,
        loading: false
      };
    case ADD_UPDATE_ANSWER:
      return {
        ...state,
        updateAnswers: [payload, ...state.updateAnswers],
        loading: false
      };
    case UPDATE_LIKES:
      return {
        ...state,
        updateAnswers: state.updateAnswers.map(updateAnswer =>
          updateAnswer._id === payload.updateID
            ? {
                ...updateAnswer,
                likes: payload.likes,
                answers: payload.answers
              }
            : updateAnswer
        ),
        loading: false
      };
    case ADD_COMMENT:
      return {
        ...state,
        updateAnswers: state.updateAnswers.map(updateAnswer =>
          updateAnswer._id === payload.updateID
            ? {
                ...updateAnswer,
                comments: payload.comments,
                answers: payload.answers
              }
            : updateAnswer
        ),
        loading: false
      };
    case UPDATE_COMMENT:
      return {
        ...state,
        updateAnswers: state.updateAnswers.map(updateAnswer =>
          updateAnswer._id === payload.updateID
            ? {
                ...updateAnswer,
                comments: payload
              }
            : updateAnswer
        ),
        loading: false
      };
    case REMOVE_COMMENT:
      return {
        ...state,
        updateAnswers: state.updateAnswers.map(updateAnswer =>
          updateAnswer._id === payload.updateID
            ? {
                ...updateAnswer,
                answers: payload.answers,
                comments: updateAnswer.comments.filter(
                  comment => comment._id !== payload.commentID
                )
              }
            : updateAnswer
        ),
        loading: false
      };
    case UPDATE_ANSWER_ERROR:
    case COMPANY_UPDATE_ANSWER_ERROR:
      return {
        ...state,
        error: payload,
        updateAnswers: [],
        updateAnswer: null,
        loading: false
      };
    case UPDATE_ANSWERS_ERROR:
      return {
        ...state,
        error: payload,
        updateAnswers: [],
        loading: false
      };
    default:
      return state;
  }
}
