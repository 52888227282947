import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const WeekItem = ({
  scheduleDay,
  onChange,
  styles,
  stylesText,
}) => {

  return (
   <Fragment>
    <p className={`bold medium block question ${stylesText}`}>
      <FormattedMessage id="admin.dashboard.edit.update.day" defaultMessage="Day" />
    </p>

    <select className={styles} name='scheduleDay' value={scheduleDay.index} onChange={e => onChange(e)}>
        <option value="" selected disabled hidden>Choose day</option>
        <option value='1'> Monday</option>
        <option value='2'> Tuesday</option>
        <option value='3'> Wednesday</option>
        <option value='4'> Thursday</option>
        <option value='5'> Friday</option>
        <option value='6'> Saturday</option>
        <option value='0'> Sunday</option>
    </select>
  </Fragment>
  );
};

WeekItem.propTypes = {
  interval: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default WeekItem;
