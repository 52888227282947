import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { setAlert } from '../../../actions/alert';
import { connect } from 'react-redux';
import { addRecognitionComment } from '../../../actions/recognition';
import { FormattedMessage } from 'react-intl';

const CommentForm = ({
  recognitionID,
  addRecognitionComment,
  user,
  avatar
}) => {
  const [text, setText] = useState('');

  return (
    <form
      className="form-app form-app-comments"
      onSubmit={e => {
        e.preventDefault();
        addRecognitionComment(recognitionID, { text });
        setText('');
      }}
    >
      <img
        src={avatar}
        alt="Avatar"
        className="round-img d-block avatar"
        width="45px"
        height="45px"
      />
      <div className="form-comment">
        <FormattedMessage id="add.comment" defaultMessage="Add a comment">
          {msg => (
            <input
              type="text"
              placeholder={msg}
              value={text}
              onChange={e => setText(e.target.value)}
              required
            />
          )}
        </FormattedMessage>
        <button
          type="submit"
          className="btn-secondary btn-secondary-comment hide-sm"
        >
          <FormattedMessage id="btn.submit" defaultMessage="Send" />
        </button>
        <button type="submit" className="btn-mobile-comment hide-large">
          <FormattedMessage id="btn.mobile.send" defaultMessage="Send" />
        </button>
      </div>
    </form>
  );
};

CommentForm.propTypes = {
  addRecognitionComment: PropTypes.func.isRequired
};

export default connect(
  null,
  { addRecognitionComment }
)(CommentForm);
