import React from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ButtonBlue = ({ cta, url }) => {

  return (
    <Link to={url} className="btn mw-200 mr-0 text-center">{cta}</Link>
  );
};

ButtonBlue.propTypes = {
  cta: PropTypes.object,
  url: PropTypes.string
};

export default ButtonBlue;
