import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const Widgets = ({
  board,
  widgetData,
  width,
  bgColor,
  widgetsAmount,
}) => {

  return (
    <Fragment>
      <div className="dashboards-square flex space-between flex-column-s">
        {widgetData.map((item, index) => (
          <div
            key={`dwidgets_square_${index + 1}`}
            className={`dashboards-square__item br-4 h-${item.height} bg__white brd brd-grey__light w-100-s text-center ${widgetData && index === widgetData.length - 1 ? "mr-0" : ''}`}
          >
            <p className={`dashboards-square__item__metric xxl ${item.color && item.color} ${item.data.length > 5 ? 'fs-40' : ''}`}>{item.data}</p>
            <p className="dashboards-square__item__title">{item.title}</p>
            {item.subTitle && (
						  <span className="grey fs-12 block">{item.subTitle}</span>
            )}
          </div>
        ))} 
      </div>
    </Fragment>
  );
};

Widgets.propTypes = {
  board: PropTypes.number,
  width: PropTypes.number.isRequired,
  bgColor: PropTypes.string,
  widgetData: PropTypes.array.isRequired,
};

export default Widgets;
