import {
  GET_SLACK_USERS,
  GET_SLACK_CHANNELS,
  GET_SLACK_CHANNELS_MEMBERS,
  SLACK_USERS_ERROR,
  SLACK_CHANNELS_ERROR,
  SLACK_CHANNELS_MEMBERS_ERROR,
  GET_SLACK_TEAM,
  SLACK_TEAM_ERROR,
  SLACK_SETTINGS_ERROR,
} from '../actions/types';

const initialState = {
  users: null,
  channels: null,
  team: null,
  loading: true,
  error: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SLACK_USERS:
      return {
        ...state,
        users: payload,
        loading: false
      };
    case GET_SLACK_CHANNELS:
      return {
        ...state,
        channels: payload,
        loading: false
      };
    case GET_SLACK_CHANNELS_MEMBERS:
      return {
        ...state,
        channelsMembers: payload,
        loading: false
      };
    case GET_SLACK_TEAM:
      return {
        ...state,
        team: payload,
        loading: false
      };
    case SLACK_USERS_ERROR:
    case SLACK_CHANNELS_ERROR:
    case SLACK_CHANNELS_MEMBERS_ERROR:
    case SLACK_TEAM_ERROR:
    case SLACK_SETTINGS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
}
