import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MainMenu from '../../layout/Sidebar';
import Menu from '../../layout/Menu';
import { connect } from 'react-redux';
import UserNav from '../../layout/UserNav';
import Spinner from '../../layout/Spinner';
import { getCompanyTeams, deleteTeam } from '../../../actions/company';
import { getCompanyProfiles } from '../../../actions/profile';
import { getCompanyAnswers, clearStandups } from '../../../actions/standupAnswer';
import { getCompanyUpdateAnswers, clearUpdates } from '../../../actions/updateAnswer';
import { clearOkrs } from '../../../actions/okr';
import { getCompanyRecognitions, clearKudos } from '../../../actions/recognition';
import TableTitle from './TableTitle';
import TeamsCard from './TeamsCard';
import StandupEmptyTeam from '../../standups/StandupEmptyTeam';
import { FormattedMessage } from 'react-intl';
import useInvite from '../../invite/useInvite';
import NoContent from '../../utils/NoContentNew';
import updateImg from '../../../img/empty_states/invite.png';

const menuTitle = <FormattedMessage id="menu.users" defaultMessage="Teams & Members" />;
const subTitle = (
  <FormattedMessage id="company.teamsmembers.subtitle" defaultMessage="Manage your teams & team members." />
);

const Teams = ({
  clearStandups,
  clearUpdates,
  clearOkrs,
  clearKudos,
  getCompanyProfiles,
  standupAnswer: { standupAnswers },
  getCompanyTeams,
  getCompanyAnswers,
  getCompanyUpdateAnswers,
  getCompanyRecognitions,
  company: { company },
  profile: { loading, profiles },
  auth: { user, sidebar },
  deleteTeam
}) => {
  useEffect(() => {
    getCompanyProfiles();
    getCompanyTeams();
    getCompanyAnswers();
    getCompanyUpdateAnswers();
    getCompanyRecognitions();

    // Clear states
    return () => {
      clearStandups();
      clearUpdates();
      clearOkrs();
      clearKudos();
    };
  }, [
    getCompanyProfiles,
    getCompanyTeams,
    getCompanyAnswers,
    getCompanyUpdateAnswers,
    getCompanyRecognitions
  ]);

  const noTeams = {
    title: <FormattedMessage id="company.teams.noteams.title" defaultMessage="Set up Your Teams" />,
    msg: <FormattedMessage id="company.teams.noteams.msg" defaultMessage="You currently don't have any teams set up."/>,
    msg2: <FormattedMessage id="company.teams.noteams.msg2" defaultMessage="Create teams to better organize projects and track achieved targets."/>,
    btnUrl: '/settings/create-team',
    btnCta: <FormattedMessage id="create.new.team" defaultMessage="Create New Team" />,
    // btnColor: 2
  };

  const noTeamsMember = {
    title: <FormattedMessage id="company.teams.noteams.member.title" defaultMessage="No Teams Set up" />,
    msg: <FormattedMessage id="company.teams.noteams.member.msg" defaultMessage="Your admin didn't set any teams yet."/>,
    msg2: <FormattedMessage id="company.teams.noteams.member.msg2" defaultMessage="Create teams to better organize projects and track achieved targets."/>,
  };

  const [openInvite, InviteElem] = useInvite();

  return (
    <Fragment>
      {InviteElem}
      <div className="css-main bg-primary">
        <div className="wrapper">
          {user && !loading && (
            <MainMenu activeIndex={8} />
          )}
          <UserNav />
          <Fragment>
            {loading || !company || !profiles ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className={`${sidebar === 1 ? 'ml-280 main main-dashboard main_new' : 'short_menu__ml__okrs ma-w90'} mt-3`}>
                  <div className="dashboard-admin">
                    <h5 className="pl-0-xs">{menuTitle}</h5>
                    <div className="flex space-between align-center mb-4">
                      <p className="medium block pb-0">{subTitle}</p>

                      <div className="section-title justify-end">
                        <Link onClick={openInvite} className="btn fs-14 mr-0 py-1 px-15">
                          <FormattedMessage id="admin.people.invite" defaultMessage="Invite Team Member" />
                        </Link>
                      </div>
                    </div>

                    <div className="insights mb-3_5">
                      <div className="sub-menu__page mt-2">
                        <Link
                          to={'/teams'}
                          className={`medium sub-menu__item inline-block mr-2 cursor sub-menu__item_active`}
                        >
                          <FormattedMessage id="settings.workspace" defaultMessage={`Teams`} />
                        </Link>

                        <Link to={'/people'} className={`medium sub-menu__item inline-block mr-2 cursor`}>
                          <FormattedMessage id="settings.workspace" defaultMessage={`Members`} />
                        </Link>
                      </div>
                    </div>

                    {user && user.isAdmin && company.departments && company.departments.length > 0 && (
                      <div className="section-title justify-end">
                        <Link to="/settings/create-team" className="btn__secondary_blue px-2 lh-34 fw-600 fs-14">
                          <FormattedMessage
                            id="admin.team.create"
                            defaultMessage="Create team"
                          />
                        </Link>
                      </div>
                    )}

                    <div className="departments">
                      {company.departments.length === 0 ? (
                        <div className="mt-2">
                          {user && user.isAdmin ? (
                            <NoContent
                              message={noTeams}
                              picture={updateImg}
                              alt={'Create first team'}
                              imgWidth={'w-100 z-1000 -mt-okr__empty'}
                              button={1}
                              btnUrl={noTeams.btnUrl}
                              btnCta={noTeams.btnCta}
                              divStyles="flex-column-reverse pt-3"
                              containerStyles="p-0"
                              externalLink={2}
                            />
                          ): (
                            <NoContent
                              message={noTeamsMember}
                              picture={updateImg}
                              alt={'Create first team'}
                              imgWidth={'w-100 z-1000 -mt-okr__empty'}
                              divStyles="flex-column-reverse pt-3"
                              containerStyles="p-0"
                              contentStyles="pb-5 mb-5 text-center"
                            />
                          )}
                        </div>
                      ) : (
                        <Fragment>
                          <div className="component border-gray br-4 my-3 p-4">
                            <table>
                              <TableTitle />
                              {company.departments
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map((department, index) => (
                                  <tbody key={`Departments-${department._id}-${index}`}>
                                    <TeamsCard
                                      department={department}
                                      users={profiles.filter(
                                        (profile) => department._id === profile.department
                                      )}
                                      auth={user}
                                      deleteTeam={deleteTeam}
                                    />
                                  </tbody>
                                ))}
                            </table>
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

Teams.propTypes = {
  clearStandups: PropTypes.func.isRequired,
  clearUpdates: PropTypes.func.isRequired,
  clearOkrs: PropTypes.func.isRequired,
  clearKudos: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  getCompanyTeams: PropTypes.func.isRequired,
  getCompanyAnswers: PropTypes.func.isRequired,
  getCompanyUpdateAnswers: PropTypes.func.isRequired,
  getCompanyRecognitions: PropTypes.func.isRequired,
  deleteTeam: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  recognition: PropTypes.object.isRequired,
  updateAnswer: PropTypes.object.isRequired,
  standupAnswer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  company: state.company,
  recognition: state.recognition,
  updateAnswer: state.updateAnswer,
  standupAnswer: state.standupAnswer
});

export default connect(mapStateToProps, {
  getCompanyTeams,
  getCompanyProfiles,
  getCompanyUpdateAnswers,
  getCompanyAnswers,
  getCompanyRecognitions,
  clearStandups,
  clearUpdates,
  clearOkrs,
  clearKudos,
  deleteTeam
})(Teams);
