import React from 'react';
import { FormattedMessage } from 'react-intl';

export const adminCopy = {
  msg: (
    <FormattedMessage
      id="admin.dashboard.msg1"
      defaultMessage="Daily Standups and Weekly Updates are the best ways to stay align for your team. It takes several minutes for an employee to answer three main questions about their daily and weekly results."
    />
  ),
  msg2: (
    <FormattedMessage
      id="admin.dashboard.msg2"
      defaultMessage="You can turn off standups and updates by clicking on button 'Active' below. Also, you can change the time of events and questions by clicking the Edit button."
    />
  ),
  updatePlan: (
    <FormattedMessage
      id="admin.updatePlan.msg"
      defaultMessage={`You've exceeded the Free plan limit`}
    />
  ),
  ctaPlan: (
    <FormattedMessage
      id="admin.updatePlan.ctaPlan"
      defaultMessage="Upgrade Plan"
    />
  ),
  freePlan: (
    <FormattedMessage
      id="admin.freePlan.msg"
      defaultMessage={`Take the full power of Focus!`}
    />
  ),
  freePlan2: (
    <FormattedMessage
      id="admin.freePlan.msg2"
      defaultMessage={`You're using a Free plan without daily standups, weekly updates, and recognition. Update the plan to take the full power of Focus.`}
    />
  ),
}

export default {
  adminCopy
}
