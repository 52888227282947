import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import { icons } from '../../img/elements/icons';

// Type: 1 - new checkins, 2 - old checkins
const Focus = ({ focus, type }) => {
  
  // Check the object length is not 0
  let objectLength = 0;
  if (focus !== undefined && focus !== null) {
    objectLength = Object.keys(focus).length;
  }

  let color;
  let label = '';

  if (objectLength > 0 && focus.status) {
    if (focus.status === '1') {
      color = 'green';
      label = 'On Track';
    } else if (focus.status === '2') {
      color = 'yellow';
      label = 'Behind';
    } else if (focus.status === '3') {
      color = 'red';
      label = 'At Risk';
    }
  }

  const okrHead = <FormattedMessage id="focus.day.okr" defaultMessage="OKR" />;

  return (
    <div className={`my-1`}>
      {type === 2 && (
        <div className="flex align-center">
          <span className="mr-14 flex align-center">{icons.arrow_right}</span>{' '}
          <p className={`fs-12 uppercase bold mb-0`}>
            <FormattedMessage id="daily.focus" defaultMessage="Daily focus" />{' '}
          </p>
        </div>
      )}
      <div className={`notes ${type === 2 ? 'ml-38' : ''}`}>
        <Fragment>
          {focus.focusIndex === 99 ? (
            <p className="fs-16 text-left">
              <b>{okrHead}:</b> {focus.objective}
            </p>
          ) : (
            <Fragment>
              <div className="flex align-center">
                <p className="fs-16 text-left">
                  <b>{okrHead}:</b> {focus.objective}
                </p>
                <span className="mx-8 flex align-center">{icons.arrow_right}</span>
                <p className="fs-14 text-left">{focus.text}</p>
              </div>
            </Fragment>
          )}
          <div className="flex align-center">
            <p className="text-left fs-16 bold mb-0">{focus.progress}%</p>
            <span className="fs-16 mx-xs">/</span>
            <p className="text-left fs-12 mr-1">{focus.goal}%</p>
            <p className={`text-left fs-8 uppercase white br-2 brd bg-${color} px-xs lh-16`}>
              {label}
            </p>
          </div>
        </Fragment>
      </div>
    </div>
  );
};

Focus.propTypes = {
  focus: PropTypes.object.isRequired,
};

export default Focus;
