import React from "react";
import PropTypes from 'prop-types';

const IcebreakerIcon = ({ activeIndex }) => {
  return (
    <svg id="bolt" className="w-20px" xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
      <path id="Path_24" fill={`${activeIndex === 14 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 24" d="M596.683,76.6h-.546c-1.249,0-2.5,0-3.747,0a2.761,2.761,0,0,1-.573-.053.94.94,0,0,1-.554-1.595,2.882,2.882,0,0,1,.288-.261q5.326-4.447,10.655-8.891c.1-.083.2-.17.3-.243a1.077,1.077,0,0,1,1.208-.025.871.871,0,0,1,.393,1.059c-.238.688-.5,1.366-.762,2.048q-.97,2.555-1.943,5.109c-.039.1-.061.21-.1.358.176.008.318.019.46.019q1.873,0,3.747,0a3.837,3.837,0,0,1,.446.013,1.037,1.037,0,0,1,.967.688.948.948,0,0,1-.3,1.105q-.931.78-1.869,1.552l-8.991,7.438c-.089.073-.176.151-.27.217a1.074,1.074,0,0,1-1.244.037.864.864,0,0,1-.379-1.064c.324-.909.675-1.811,1.018-2.716q.842-2.222,1.687-4.443C596.607,76.866,596.631,76.773,596.683,76.6ZM592.8,75.269l.05.11h.478q2.074,0,4.149,0a.827.827,0,0,1,.65.208.66.66,0,0,1,.075.779q-1.262,3.322-2.514,6.647c-.046.123-.077.25-.115.375l.071.038,9.622-7.976-.039-.1h-.47q-2.03,0-4.06,0a2.257,2.257,0,0,1-.529-.046.583.583,0,0,1-.447-.744c.018-.067.043-.133.068-.2q1.244-3.284,2.49-6.567c.052-.139.094-.28.142-.42l-.072-.044Z" transform="translate(-590.989 -65.358)"/>
    </svg>
  
  );
};

IcebreakerIcon.propTypes = {
  activeIndex: PropTypes.number
};

export default IcebreakerIcon;
