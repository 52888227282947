import React, { Fragment, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { signup } from '../../actions/auth';
import Navbar from '../layout/Navbar';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { icons } from '../../img/elements/icons';
import Loader from 'react-loader-spinner';
import momentTz from 'moment-timezone';
import useAnalyticsEventTracker from '../../utils/analytics/useAnalyticsEventTracker';

const Signup = ({ setAlert, signup, isAuthenticated, token, locale: { lang }, alert }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    password2: '',
    company: '',
    lang: ''
  });

  const [signupForm, toggleForm] = useState(true);
  const [showPassword, setShowPassword] = useState({ password: false, password2: false })
  const [isLoader, toggleLoader] = useState(false);


  useEffect(() => {
    if (token === 'validate') {
      toggleForm(!signupForm);
    }
  }, [token]);

  const {
    firstName,
    lastName,
    email,
    password,
    password2,
    company
  } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();

    if (password !== password2) {
      setAlert(
        <FormattedMessage
          id="auth.signup.alert.pass"
          defaultMessage="Passwords do not match"
        />,
        'danger'
      );
    } else {
      toggleLoader(true);

      let res = await signup({
        firstName,
        lastName,
        email,
        password,
        company,
        lang,
        cl_timezone : momentTz.tz.guess()
      });

      if (res.length === 0) {
        toggleForm(!signupForm);
      }

      useAnalyticsEventTracker('signup', 'Signup created', 'Creating a new workspace');

      // Hide Loader
      toggleLoader(false);
    }
  };

  // Redirect if signed up
  if (isAuthenticated) {
    return <Redirect to="/dashboard/objectives" />;
  }

  let btnTxt = <FormattedMessage id="auth.signup.btn" defaultMessage="Sign Up" />

  // Loader for button
  let loadingIcon = (
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader type="Circles" color="#ffffff" height="14px" width="15" />
    </span>
  );

  if (isLoader) {
    btnTxt = loadingIcon;
  }

  return (
    <Fragment>
      <section id="signup">
        {signupForm ? (
          <div className="container pt-5">
            <div className="section_header section_header_new mb-2_5">
              <Navbar guest={true} />
              <h2 className='h2_new'>
                <FormattedMessage
                  id="auth.signup.header"
                  defaultMessage="Hey you !"
                />
              </h2>
              <p className="lead lead_new">
                <FormattedMessage
                  id="auth.signup.text"
                  defaultMessage="Let's create your company account."
                />
              </p>
            </div>
            <div className="form form-detail form_new mb-1">
              <form onSubmit={e => onSubmit(e)}>
                <div className="field_name field_name_new field mb-1">
                  <FormattedMessage
                    id="auth.signup.nameInput"
                    defaultMessage="First Name"
                  >
                    {msg => (
                      <input
                        type="text"
                        className="name name_new"
                        placeholder={msg}
                        name="firstName"
                        value={firstName}
                        onChange={e => onChange(e)}
                        required
                        autoFocus
                      />
                    )}
                  </FormattedMessage>
                  <FormattedMessage
                    id="auth.signup.lastnameInput"
                    defaultMessage="Last Name"
                  >
                    {msg => (
                      <input
                        type="text"
                        className="name name_new"
                        placeholder={msg}
                        name="lastName"
                        value={lastName}
                        onChange={e => onChange(e)}
                        required
                      />
                    )}
                  </FormattedMessage>
                </div>

                <div className="field_email field mb-1">
                  <FormattedMessage
                    id="auth.signup.emailInput"
                    defaultMessage="Email"
                  >
                    {msg => (
                      <input
                        type="email"
                        className="email email_new"
                        placeholder={msg}
                        name="email"
                        value={email}
                        onChange={e => onChange(e)}
                        required
                      />
                    )}
                  </FormattedMessage>
                </div>
                <div className="field_password field_password_new field mb-1">
                  <FormattedMessage
                    id="auth.signup.passInput"
                    defaultMessage="Password"
                  >
                    {msg => (
                      <div className='pass-input_new' >
                        <input
                          type={showPassword.password ? "text" : "password"}
                          className="password password_new"
                          placeholder={msg}
                          name="password"
                          value={password}
                          onChange={e => onChange(e)}
                          minLength="6"
                          required
                          autocomplete="off"
                        />
                        {password && (
                          <button type="button" className='pass-visibility_new' onClick={() => setShowPassword({ ...showPassword, password: !showPassword.password })}>
                            {showPassword.password ? icons.hide_password : icons.view_password}
                          </button>
                        )}

                      </div>
                    )}
                  </FormattedMessage>
                </div>
                <div className="field_password password2 field mb-1">
                  <FormattedMessage
                    id="auth.signup.pass2Input"
                    defaultMessage="Repeat Password"
                  >
                    {msg => (
                      <div className='pass-input_new' >

                        <input
                          type={showPassword.password2 ? "text" : "password"}
                          className="password2 password_new"
                          placeholder={msg}
                          name="password2"
                          value={password2}
                          onChange={e => onChange(e)}
                          minLength="6"
                          required
                          autocomplete="off"
                        />
                        {password2 && (
                          <button type="button" className='pass-visibility_new' onClick={() => setShowPassword({ ...showPassword, password2: !showPassword.password2 })}>
                            {showPassword.password2 ? icons.hide_password : icons.view_password}
                          </button>
                        )}

                      </div>
                    )}
                  </FormattedMessage>
                </div>
                <div className="field_company field mb-1">
                  <FormattedMessage
                    id="auth.signup.companyInput"
                    defaultMessage="Company"
                  >
                    {msg => (
                      <input
                        type="text"
                        className="company company_new"
                        placeholder={msg}
                        name="company"
                        value={company}
                        onChange={e => onChange(e)}
                        required
                      />
                    )}
                  </FormattedMessage>
                </div>
                <div className='link-page_new mb-2'>
                  <p className='link-page_new-message'>
                    <FormattedMessage
                      id="auth.signup.companyAccount"
                      defaultMessage="Already have company account ?"
                    />
                  </p>
                  <div className='link-page_new-redirect'>
                    <Link to="/dashboard/objectives" >
                      <FormattedMessage id="auth.signup.resendToDashboard" defaultMessage="Find out how to join" />
                    </Link>
                  </div>
                </div>
                <div className="form_button">
                  <button 
                    type="submit" 
                    className="btn btn_new btn-primary"
                    disabled={!isLoader ? false : true}
                    onClick={onSubmit}
                  >
                    {' '}
                    {/* <FormattedMessage
                      id="auth.signup.btn"
                      defaultMessage="Sign Up"
                    /> */}
                   {btnTxt}
                  </button>
                </div>
              </form>
            </div>
            <div className="section_footer_new">
              <Link to="/resend" className="medium block grey cursor dark-gray medium_new link-auth_new mb-2">
                <FormattedMessage id="auth.signup.resend" defaultMessage="Didn't Get Confirmation Email ?" />
              </Link>
              <p className='text-center block block_new bold mb-1_5'>
                <FormattedMessage
                  id="auth.signup.account"
                  defaultMessage="Already have Focus account ?"
                />{' '}
              </p>
              <Link to="/login" className="btn btn-light_new">
                <FormattedMessage id="auth.signup.login" defaultMessage="Login" />
              </Link>
            </div>

          </div>
        ) : (
          <div className="container pt-5">
            <div className="section_header_new mb-1_5">
              <Navbar guest={true} />
              <h2 className="h2_new mb-2">
                <FormattedMessage
                  id="auth.signup.verify.title"
                  defaultMessage="Please verify your email"
                />
              </h2>
              <p className="lead lead_new lh-25 mb-2">
                <FormattedMessage
                  id="auth.signup.verify.text1"
                  defaultMessage="Registration is almost done."
                />
              </p>
              <p className="lead lead_new lh-25">
                <FormattedMessage
                  id="auth.signup.verify.text2"
                  defaultMessage="Once you verify your email address, you and your team can get started in Focus."
                />
              </p>
            </div>
            <div className='section_footer_new '>
              <div className='link-page_new fs-14 mb-2'>

                <p className="link-page_new-message">
                  <FormattedMessage
                    id="auth.signup.resend.text"
                    defaultMessage="Didn't Get Confirmation Email ?"
                  />
                </p>
                {' '}
                <div className='link-page_new-redirect'>

                  <Link to="/resend">
                    <FormattedMessage id="auth.signup.resend.btn" defaultMessage="Resend" />
                  </Link>
                </div>
              </div>

              <Link to="/login" className="btn btn-light_new">
                <FormattedMessage
                  id="auth.signup.resend.login"
                  defaultMessage="Login"
                />
              </Link>
            </div>

          </div>
        )}
      </section>
    </Fragment>
  );
};

Signup.propTypes = {
  setAlert: PropTypes.func.isRequired,
  signup: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  token: PropTypes.string
};

const mapStateToProps = state => ({
  locale: state.locale,
  isAuthenticated: state.auth.isAuthenticated,
  token: state.auth.token,
  alert: state.alert
});

export default connect(
  mapStateToProps,
  { setAlert, signup }
)(Signup);
