import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Moment from 'react-moment';
import OkrCardItemForUpdate from '../../okrs/OkrCardItemForUpdate';
import Spinner from '../../layout/Spinner';
import Select from 'react-select';

const UpdateForm2 = ({ 
  update, 
  answers, 
  onChangeSelect, 
  previousAnswers, 
  onChangeAnswer, 
  okrQuestionLength, 
  focus, handleFocus,
  customStyles,
  onChange,
  okrs,
  notes,
  onChangeOkr,
  okrQuestion,
  okrOptions,
  handleAnswer,
  handleAddOkrAnswer,
  onChangeOkrAnswer,
  onChangeOkrSelect,
  onChange0Answer,
 }) => {

  let addAnswer = <FormattedMessage id="add.answer.plus" defaultMessage="+ Add an answer" />;
  let noPlanMsg = <FormattedMessage 
    id="update2.noPlan.msg" 
    defaultMessage="In OKR-based weekly updates, you will be able to see items and OKRs you mentioned on the last weekly plan here. <b>Please complete a Weekly Plan</b> before a weekly update. Having completed a weekly plan in the new 'OKR-based' approach, you can return to this form and answer the questions." 
    values={{ b: (...chunks) => <b>{chunks}</b> }}
  />;
  let noPlanMsgDanger = <FormattedMessage id="update2.noPlan.danger" defaultMessage="It's not possible to complete the weekly update now." />;

  console.log('===== okrs in UpdateForm2', okrs);

  return (
    <Fragment>
      <div className="component component-update standups-daily mt-2 p-3 light-shadow">
      {update ? (
        <p className="sub-title focus-blue2 focus">
          <FormattedMessage
            id="updates.week.head"
            defaultMessage="Week:"
          />{' '}
          <Moment format="D MMM">{update.date}</Moment>
        </p>
      ) : (
        <p className="sub-title focus-blue2 focus">
          <FormattedMessage
            id="update.form.week"
            defaultMessage="Week"
          />
        </p>
      )}

      <div className="py-2">
        <p className="meduim">
          <FormattedMessage
            id="update.form.subHeader"
            defaultMessage="Let's answer some questions to complete your weekly update"
          />
        </p>
        <div className="line" />
      </div>

      {update ? (
        <Fragment>
          {update && update.questions && update.questions.map((question, index) => (
            <div className="form-main" key={index}>
              <p className="bold medium block">{question.text}</p>
              {question.isNumber ? (
                <select
                  required
                  name={index}
                  value={answers[index] || ''}
                  onChange={e => onChangeSelect(e, index)}
                >
                  <option value="0">Select the number</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              ) : (
                <Fragment>
                  {index === 0 ? (
                    <Fragment>
                      {okrs && okrs.length > 0 ? (
                        <div className="objectives pt-1">
                          <Fragment>
                            <div className="cards py-2">
                              {okrs && okrs.length > 0 &&
                                okrs.map((okr, i) => (
                                <Fragment>
                                  {okr.value === undefined ? (
                                    <OkrCardItemForUpdate
                                      key={okr._id}
                                      okr={okr}
                                      okrBoard={'6'}
                                      onChangeOkr={onChangeOkr}
                                      indexOkr={i}
                                    />
                                  ) : (
                                    <p className="bold">{okr.label}</p>
                                  )}
                                  
                                  <div className="plan-answers">
                                    {answers && answers.length > 0 && answers[0].length > 0 && answers[0][i] && answers[0][i].length > 0 && answers[0][i].map((answer, y) => (
                                      <Fragment>
                                        <Fragment>
                                          {!answers[0][i][y].isNew ? (
                                              <Fragment>
                                              <div className="flex align-center">
                                                  <FormattedMessage
                                                      key={`answer-${y}`}
                                                      id="add.answer"
                                                      defaultMessage="Add an answer"
                                                    >
                                                      {msg => (
                                                        <input
                                                          className="my-1 w-75 bg__grey1"
                                                          type="text"
                                                          disabled
                                                          placeholder={msg}
                                                          name={y}
                                                          value={answers[0][i][y].text || ''}
                                                          onChange={e => onChangeAnswer(e, 0, i, y)}
                                                        />
                                                      )}
                                                    </FormattedMessage>
                                                  
                                                  <select 
                                                    className="w-25 ml-2" 
                                                    name='isCompleted' 
                                                    required
                                                    value={answers[0][i][y].isCompleted || ''} 
                                                    onChange={e => onChangeOkrSelect(e, 0, i, y)}
                                                  >
                                                    <option value='' disabled selected>Choose status</option>
                                                    <option value={true}>Completed</option>
                                                    <option value={false}>Not completed</option>
                                                  </select>
                                                </div>
                                                {answers[0][i][y].isCompleted === "false" && (
                                                  <FormattedMessage
                                                    key={`answer-${y}`}
                                                    id="details.about.it"
                                                    defaultMessage="Please add more details about it"
                                                  >
                                                    {msg => (
                                                      <input
                                                        className="my-1 w-100 brd-grey"
                                                        type="text"
                                                        placeholder={msg}
                                                        required
                                                        name={'comment'}
                                                        value={answers[0][i][y].comment || ''}
                                                        onChange={e => onChangeOkrAnswer(e, 0, i, y)}
                                                      />
                                                    )}
                                                  </FormattedMessage>
                                                )}
                                              </Fragment>
                                          ) : (
                                            <FormattedMessage
                                              key={`answer-${y}`}
                                              id="add.answer"
                                              defaultMessage="Add an answer"
                                            >
                                              {msg => (
                                                <input
                                                  className="my-1"
                                                  type="text"
                                                  placeholder={msg}
                                                  name={'text'}
                                                  value={answers[0][i][y].text || ''}
                                                  onChange={e => onChangeOkrAnswer(e, 0, i, y)}
                                                />
                                              )}
                                            </FormattedMessage>
                                          )}
                                        </Fragment>
                                      </Fragment>
                                    ))}
                                    <p 
                                      className="grey medium mb-3 cursor"
                                      onClick={() => handleAddOkrAnswer(i)}
                                      >
                                        {addAnswer}
                                      </p>
                                  </div>
                                </Fragment>
                              ))}
                            </div>
                          </Fragment>
                        </div>
                      )
                       : (
                        <Fragment>
                            <p className="medium orange mb-1">{noPlanMsgDanger}</p>
                            <p className="medium">{noPlanMsg}</p>
                          </Fragment>
                      )
                      }
                    </Fragment>
                  ) : (
                    <Fragment>
                      {index === 1 ? (
                        <Fragment>
                          {previousAnswers !== undefined && previousAnswers.length > 0 && previousAnswers.map((answer, i) => (
                            <div className="step-item mr-2 mb-2-xs cards__onboarding_body ml-4" key={`${answer.text}-${i}-${index}`}>
                              <p
                                onClick={e => handleAnswer(e, answer, index, i)}
                                className={`inline medium card-subtitle todo__item cursor lh-30 ${index === 1 ? (!answer.completeOne ? '' : "todo__completed") : (index === 2 ? (!answer.completeTwo ? '' : "todo__item-blue") : '')}`}
                              >
                                {answer.text}
                              </p>
                            </div>
                          ))}
                        </Fragment>
                      ) : ("")}
                      {answers && answers.length > 0 && answers[index].map((answer, i) => (
                        <Fragment>
                          <FormattedMessage
                            key={`answer-${i}`}
                            id="add.answer"
                            defaultMessage="Add an answer"
                          >
                            {msg => (
                              <input
                                className="my-1"
                                type="text"
                                placeholder={msg}
                                name={i}
                                value={answers[index][i] || ''}
                                onChange={e => onChangeAnswer(e, index, i)}
                              />
                            )}
                        </FormattedMessage>
                      </Fragment>
                      ))}
                    </Fragment>
                  )}
                </Fragment>
              )}
            </div>
          ))}

          {okrQuestionLength > 0 && okrQuestion.isActive && (
            <div className="form-main mb-5">
              <p className="bold medium block no-w label-pr3">
                  {okrQuestion.text}
              </p>
              <FormattedMessage
                id="update.form.focus.question"
                defaultMessage="Choose the OKR you are working on next week"
              >
                {msg => (
                  <Select
                    className="small-input w-70-xs mb-2-xs"
                    value={focus}
                    onChange={selectedOption =>
                      handleFocus(selectedOption)
                    }
                    options={okrOptions}
                    isSearchable
                    placeholder={msg}
                    styles={customStyles}
                  />
                )}
              </FormattedMessage>
            </div>
          )}
          
          <div>
            <div>
              <p className="bold medium inline mr-1">
                <FormattedMessage
                  id="notes"
                  defaultMessage="Notes"
                />
              </p>
              <span className="small grey">
                <FormattedMessage
                  id="optional"
                  defaultMessage="Optional"
                />
              </span>
            </div>
            <FormattedMessage
              id="standup.form.add.notes"
              defaultMessage="Add notes"
            >
              {msg => (
                <textarea
                  className="my-1 w-100"
                  rows="3"
                  type="text"
                  placeholder={msg}
                  name="notes"
                  value={notes}
                  onChange={e => onChange(e)}
                />
              )}
            </FormattedMessage>
          </div>
        </Fragment>
      ) : (
        <Spinner />
      )}
    </div>
  </Fragment>
  );
};

UpdateForm2.propTypes = {
  update: PropTypes.object.isRequired
};

export default UpdateForm2;
