import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';

const MainWidget = ({ okr }) => {
  let objective, 
      progress, 
      status, 
      start, 
      current, 
      goal;

  if (okr.isMainIndex === 99) {
    objective=okr.objective;
    progress=okr.progress;
    status=okr.status;
    start='0';
    current=`${okr.progress}%`;
    goal='100%';
  } else {
    objective=okr.keyResults[okr.isMainIndex].title;
    progress=okr.keyResults[okr.isMainIndex].progress;
    status=okr.status;
    start=okr.keyResults[okr.isMainIndex].start;
    current=okr.keyResults[okr.isMainIndex].current;
    goal=okr.keyResults[okr.isMainIndex].goal;
  }
  
  let btnClass;
  if (status === 1) btnClass = "green";
  if (status === 2) btnClass = "yellow";
  if (status === 3) btnClass = "red";

  return (
    <div className={`main_goal bg-${btnClass} p-3 light-shadow flex column space-around`}> 
      <p className="large white title fs-3"><strong>{objective}</strong></p>
        <div className="main_progress">
          <p className="large white status-up">
          <FormattedMessage
            id="widget.main.current"
            defaultMessage="Current"
          />:{' '}
            <span className="large white">{current}</span>
          </p>

          <div className="progress-bar-main my-1">
            <span 
              className="status-bar-main"
              style={{ width: `${progress <= 100 ? progress : '100'}%`}}
            ></span>
          </div>

          <div className="goal-number">
            <p className="large white status-down">
            <FormattedMessage
              id="widget.main.start"
              defaultMessage="Start"
            />:{' '}
              <span className="large white">{start}</span>
            </p>
            <p className="large white status-down">
            <FormattedMessage
              id="widget.main.goal"
              defaultMessage="Goal"
            />:{' '}
              <span className="large white">{goal}</span>
            </p>
          </div>
        </div>
      </div>
  );
};

MainWidget.propTypes = {
  okr: PropTypes.object.isRequired
};

export default MainWidget;
