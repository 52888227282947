import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Highlighter from "react-highlight-words";
import { icons } from "../../img/elements/icons";

const QuestionItemAnswer = ({ index, text, searchKeyword, question }) => {
  if (text.props && text.props.defaultMessage) {
    text = text.props.defaultMessage;
  }
  // console.log('===== QuestionItemAnswer: text', text)
  // question && console.log('===== QuestionItemAnswer: question.questionType', question.questionType)
  
  return (
    <Fragment>
      <li className="list-item fs-14 mb-xs flex align-center"> 
        {question && question.questionType && question.questionType.value === 'single_select' || question && question.questionType && question.questionType.value === 'multiple_select' ? (
          <span className="mr-1 flex align-center">
            {icons.check}
          </span>
        ) : (
          <Fragment>
            {text && text.length > 0 && (
              <span className="mr-1 flex align-center">
                {icons.check}
              </span>
            )}
          </Fragment>
        )}
        <Highlighter
          highlightClassName="bg-yellow_pure"
          searchWords={[searchKeyword]}
          autoEscape={true}
          textToHighlight={text}
        />
      </li>
    </Fragment>
  );
};

QuestionItemAnswer.propTypes = {
  index: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired
};

export default QuestionItemAnswer;
