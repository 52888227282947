import axios from 'axios';
import { setAlert } from './alert';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  GET_PLANS,
  PLANS_ERROR,
  CLEAR_PLANS,
  GET_PLANS_SETTINGS,
  PLANS_SETTINGS_ERROR
} from './types';

// Edit weekly plans campaign
export const editPlan = (formData, id, history) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(`/api/plans/edit/${id}`, formData, config);

    dispatch({
      type: GET_PLANS,
      payload: res.data
    });

    getPlanSettings();

    dispatch(
      setAlert(
        <FormattedMessage
          id="alert.plan.edit"
          defaultMessage="Weekly Plan was edited"
        />,
        'success'
      )
    );

    history.goBack();
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PLANS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get plans settings
export const getPlanSettings = () => async dispatch => {
  try {
    const res = await axios.get('/api/plans/settings');

    dispatch({
      type: GET_PLANS_SETTINGS,
      payload: res.data
    });
  } catch (err) {
    console.log('===== err in getPlanSettings', err);

    dispatch({
      type: PLANS_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Switch ON/OFF plans settings
export const togglePlanSettings = () => async dispatch => {
  try {
    const res = await axios.put('/api/plans/status');

    dispatch({
      type: GET_PLANS_SETTINGS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PLANS_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Clear state
export const clearPlanSettings = () => async dispatch => {
  try {
   
    dispatch({
      type: CLEAR_PLANS
    });
  } catch (err) {
    dispatch({
      type: PLANS_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};