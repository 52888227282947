import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import TodoItem2CardForm from '../../elems/TodoItem2CardForm';
import FollowUpForm from './FollowUpForm';
import { FormattedMessage } from 'react-intl';
// import { isStreamLike } from 'combined-stream';
import FollowUpCard from './FollowUpCard';

const FollowUp = ({
  items,
  formData,
  handleChangeForm,
  handleChangeFormState,
  handleChangeFollowUpState,
  clearTodo,
  onSubmit,
  currentDoc,
  user,
  isForm,
  handleDeleteFollowUp,
  handleSelectFollowUp,
  handleAddFollowUp,
}) => {

  let planText = <FormattedMessage id='plan' defaultMessage='Plan' />;
  let factText = <FormattedMessage id='fact' defaultMessage='Fact' />;
  let addMsg = 'follow-up';

  let wording = {};
  wording.inputId = `add.${addMsg}`;
  wording.inputMsg = `Add ${addMsg}`;

  return (
    <table className='w-100 follow-up__table brd__collapse'>
      <tbody className='medium'>
        <tr>
          <th className='grey small text-left pl-2'>{planText}</th>
          <th className='grey small text-left pl-2'>{factText}</th>
        </tr>
        {items &&
          items.map((item, index) => (
            <Fragment key={index}>
              <tr>
                <td className='w-50 brd brd-black p-2'>
                  {item && item.plan && !item.plan.isNew ? (
                    <FollowUpCard
                      _id={item._id}
                      item={item.plan}
                      formData={formData}
                      handleSelect={handleSelectFollowUp}
                      user={user}
                      handleDelete={handleDeleteFollowUp}
                      handleNameForm={handleChangeFollowUpState}
                      clearTodo={clearTodo}
                      isForm={isForm}
                      index={index}
                      addMsg={addMsg}
                      name={'followUp'}
                      inputName={'text'}
                      inputValue={
                        item.plan && item.plan.text ? item.plan.text : ''
                      }
                      followPlan={'plan'}
                      wording={wording}
                    />
                  ) : (
                    <FollowUpForm
                      onChange={handleChangeFollowUpState}
                      clearTodo={clearTodo}
                      onSubmit={onSubmit}
                      index={index}
                      addMsg={addMsg}
                      name={'followUp'}
                      inputName={'text'}
                      inputValue={item.plan && item.plan.text}
                      followPlan={'plan'}
                      wording={wording}
                      handleAddFollowUp={handleAddFollowUp}
                    />
                  )}
                </td>
                <td className='w-50 brd brd-black p-2'>
                  {item && item.fact && !item.fact.isNew ? (
                    <FollowUpCard
                      _id={item._id}
                      item={item.fact}
                      formData={formData}
                      handleSelect={handleSelectFollowUp}
                      user={user}
                      handleDelete={handleDeleteFollowUp}
                      handleNameForm={handleChangeFollowUpState}
                      clearTodo={clearTodo}
                      isForm={isForm}
                      index={index}
                      addMsg={addMsg}
                      name={'followUp'}
                      inputName={'text'}
                      inputValue={
                        item.fact && item.fact.text ? item.fact.text : ''
                      }
                      followPlan={'fact'}
                      wording={wording}
                    />
                  ) : (
                    <FollowUpForm
                      onChange={handleChangeFollowUpState}
                      clearTodo={clearTodo}
                      onSubmit={onSubmit}
                      index={index}
                      addMsg={addMsg}
                      name={'followUp'}
                      inputName={'text'}
                      inputValue={item.fact && item.fact.text}
                      followPlan={'fact'}
                      wording={wording}
                      handleAddFollowUp={handleAddFollowUp}
                    />
                  )}
                </td>
              </tr>
            </Fragment>
          ))}
      </tbody>
    </table>
  );
};

export default FollowUp;
