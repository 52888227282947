import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import chroma from 'chroma-js';

// Styles for color in Status Select
const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        color: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

const OkrStatus = ({ 
  btnClass, label, updateMode, handleStatus, 
  statusOptions, choosedStatus, width, onChangeOkr, indexOkr, formType 
}) => {
  
  return (
    <Fragment>
      {!updateMode ? (
        <p className={`text-left fs-8 bold mb-0 uppercase white br-2 brd bg-${btnClass} px-7 lh-14`}>{label}</p>
      ) : (
        <FormattedMessage
          id="user.okr.create.status"
          defaultMessage="Choose Status"
        >
          {msg => (
            <Select
              className={`small-input mt-xs mb-xs ${!width ? 'w-80px' : width}`}
              defaultValue={choosedStatus}
              onChange={formType !== 2 ? (
                (selectedOption) => handleStatus(selectedOption)) : (
                (selectedOption) => onChangeOkr(selectedOption, indexOkr)
                )
              }
              options={statusOptions}
              placeholder={msg}
              required
              name="status"
              styles={colourStyles}
            />
          )}
        </FormattedMessage>
      )}
   </Fragment>
  );
};

OkrStatus.propTypes = {
  btnClass: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  updateMode: PropTypes.bool,
  width: PropTypes.string,
  choosedStatus: PropTypes.object,
  statusOptions: PropTypes.array,
  handleStatus: PropTypes.func
};

export default OkrStatus;
