import axios from 'axios';
import { setAlert } from './alert';
import store from '../store';
import { updateHomeLike, addHomeComment, deleteHomeComment } from './home';

import {
  GET_USER_FEEDBACK,
  GET_GIVED_USER_FEEDBACK,
  GET_TEAM_FEEDBACK,
  GET_GIVED_TEAM_FEEDBACK,
  GET_FEEDBACK,
  FEEDBACK_ITEM_ERROR,
  FEEDBACK_ERROR,
  GIVED_USER_FEEDBACK_ERROR,
  GIVED_TEAM_FEEDBACK_ERROR,
  GIVED_COMPANY_FEEDBACK_ERROR,
  ADD_FEEDBACK_ITEM,
  GET_FEEDBACK_ITEM,
  UPDATE_FEEDBACK_ITEM,
  DELETE_FEEDBACK_ITEM,
  UPDATE_LIKES,
  ADD_COMMENT,
  UPDATE_COMMENT,
  CLEAR_FEEDBACK,
  LOCATION_CHANGE_ERROR,
  GET_COMPANY_FEEDBACK,
  GET_GIVED_COMPANY_FEEDBACK,
  COMPANY_FEEDBACK_ERROR,
  TEAM_FEEDBACK_ERROR,
  REMOVE_COMMENT
} from './types';

// Get one feedback by id
export const getFeedbackById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/feedback/item/${id}`);

    dispatch({
      type: GET_FEEDBACK_ITEM,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: FEEDBACK_ITEM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all user feedbacks by user id (as recipient)
export const getUserFeedbackById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/feedback/user/${id}`);

    dispatch({
      type: GET_USER_FEEDBACK,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: FEEDBACK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all user gived feedbacks by user id (as giver)
export const getGivedUserFeedbackById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/feedback/giver/user/${id}`);

    dispatch({
      type: GET_GIVED_USER_FEEDBACK,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GIVED_USER_FEEDBACK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all current user feedbacks (as recipient)
export const getUserFeedback = () => async dispatch => {
  try {
    const res = await axios.get('/api/feedback/user');

    dispatch({
      type: GET_USER_FEEDBACK,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: FEEDBACK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all current user gived feedbacks (as giver)
export const getGivedUserFeedback = () => async dispatch => {
  try {
    const res = await axios.get('/api/feedback/giver/user');

    dispatch({
      type: GET_GIVED_USER_FEEDBACK,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GIVED_USER_FEEDBACK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all current user team feedbacks
export const getTeamFeedback = () => async dispatch => {
  try {
    const res = await axios.get('/api/feedback/team');

    dispatch({
      type: GET_TEAM_FEEDBACK,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TEAM_FEEDBACK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all current user team feedbacks (as giver)
export const getGivedTeamFeedback = () => async dispatch => {
  try {
    const res = await axios.get('/api/feedback/giver/team');

    dispatch({
      type: GET_GIVED_TEAM_FEEDBACK,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GIVED_TEAM_FEEDBACK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all team feedbacks by ID
export const getTeamFeedbackByID = id => async dispatch => {
  try {
  
    const res = await axios.get(`/api/feedback/team/${id}`);
    
    dispatch({
      type: GET_TEAM_FEEDBACK,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TEAM_FEEDBACK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all team feedbacks by ID (as giver)
export const getGivedTeamFeedbackByID = id => async dispatch => {
  try {
    const res = await axios.get(`/api/feedback/giver/team/${id}`);

    dispatch({
      type: GET_GIVED_TEAM_FEEDBACK,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GIVED_TEAM_FEEDBACK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all company feedbacks
export const getCompanyFeedback = () => async dispatch => {
  try {
    const res = await axios.get('/api/feedback/company');

    dispatch({
      type: GET_COMPANY_FEEDBACK,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: COMPANY_FEEDBACK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Request feedback
export const requestFeedback = (id, firstName) => async dispatch => {
  try {

    const res = await axios.get(`/api/feedback/request/${id}`);

    dispatch(setAlert(`We sent ${firstName} an email. We will notify you when ${firstName} gives you feedback.`, 'success', 7000));

  } catch (err) {
    dispatch({
      type: FEEDBACK_ITEM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Create feedback
export const addFeedback = (
  text,
  recipient,
  isPrivate,
  history = false
) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({
      text,
      recipient,
      isPrivate,
    });

    console.log('=== body', body);
    const res = await axios.post(`/api/feedback/new`, body, config);

    dispatch({
      type: ADD_FEEDBACK_ITEM,
      payload: res.data
    });

    dispatch(setAlert('Feedback Completed', 'success', 2000));
    
    if (history) {
      history.goBack();
    }
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: FEEDBACK_ITEM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit feedback
export const editFeedback = (id, text) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({ text });

    const res = await axios.put(`/api/feedback/${id}`, body, config);

    dispatch({
      type: UPDATE_FEEDBACK_ITEM,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: FEEDBACK_ITEM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete feedback
export const deleteFeedback = id => async dispatch => {
  try {
    await axios.delete(`/api/feedback/comment/${id}`);

    dispatch({
      type: DELETE_FEEDBACK_ITEM,
      payload: id
    });

    dispatch(setAlert('Feedback Removed', 'success', 2000));
  } catch (err) {
    dispatch({
      type: FEEDBACK_ITEM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add & Remove like
export const updateFeedbackLike = id => async dispatch => {
  try {
    const res = await axios.put(`/api/feedback/like/${id}`);
   
    dispatch({
      type: UPDATE_LIKES,
      payload: { id, likes: res.data }
    });

     const home = store.getState().home;
 
     // Обновляем редюсер Home, если он не пустой
     // https://stackoverflow.com/questions/35667249/accessing-redux-state-in-an-action-creator
     // https://redux.js.org/api/store#dispatchaction
     if (home.data.length > 0) {
       store.dispatch(updateHomeLike({ id, likes: res.data }))
     }

    // Callback for updating in formData.kudos in useState to render it
    const data = { kudoID: id, likes: res.data}
       
    return data;
  } catch (err) {
    dispatch({
      type: FEEDBACK_ITEM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add comment
export const addFeedbackComment = (id, formData) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const res = await axios.post(
      `/api/feedback/comment/${id}`,
      formData,
      config
    );

    dispatch({
      type: ADD_COMMENT,
      payload: {
        id,
        comments: res.data
      }
    });

    const home = store.getState().home;
 
    // Обновляем редюсер Home, если он не пустой
    // https://stackoverflow.com/questions/35667249/accessing-redux-state-in-an-action-creator
    // https://redux.js.org/api/store#dispatchaction
    if (home.data.length > 0) {
      store.dispatch(addHomeComment({ id, comments: res.data }))
    }

    // Callback for updating formData.kudos in useState to render it
    const data = { kudoID: id, comments: res.data }
    return data;

  } catch (err) {
    dispatch({
      type: FEEDBACK_ITEM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit comment
export const editFeedbackComment = (
  feedbackID,
  commentID,
  formData
) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const res = await axios.put(
      `/api/feedback/comment/${feedbackID}/${commentID}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_COMMENT,
      payload: {
        id: feedbackID,
        comments: res.data
      }
    });
  } catch (err) {
    dispatch({
      type: FEEDBACK_ITEM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete comment
export const deleteFeedbackComment = (
  feedbackID,
  commentID
) => async dispatch => {
  try {
    await axios.delete(
      `/api/feedback/comment/${feedbackID}/${commentID}`
    );

    dispatch({
      type: REMOVE_COMMENT,
      payload: {
        feedbackID,
        commentID
      }
    });

    const home = store.getState().home;
 
    // Обновляем редюсер Home, если он не пустой
    if (home.data.length > 0) {
      store.dispatch(deleteHomeComment({ feedbackID, commentID }))
    }

    // Callback for updating in formData.kudos in useState to render it
    const data = { kudoID: feedbackID, commentID }
    return data;
    
  } catch (err) {
    dispatch({
      type: FEEDBACK_ITEM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Clear state
export const clearFeedback = () => async dispatch => {
  try {
   
    dispatch({
      type: CLEAR_FEEDBACK
    });
  } catch (err) {
    dispatch({
      type: LOCATION_CHANGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
