import 'rc-time-picker/assets/index.css';
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import SelectItem from "../../elems/SelectWithValue";
import DayItem from './DayItem';
import QuestionItemNew from './QuestionItemNew';
import Moment from 'moment';
import TimePicker from 'rc-time-picker';
const format = 'h:mm a';

let intervalOptions = [
  { label: `Daily`, value: 'Daily', name: 'interval' },
  { label: `Weekly`, value: 'Weekly', name: 'interval' },
  { label: `Monthly`, value: 'Monthly', name: 'interval' },
];

let daysOptions = [
  { label: `Monday`, value: '1', name: 'weekday' },
  { label: `Tuesday`, value: '2', name: 'weekday' },
  { label: `Wednesday`, value: '3', name: 'weekday' },
  { label: `Thursday`, value: '4', name: 'weekday' },
  { label: `Friday`, value: '5', name: 'weekday' },
  { label: `Saturday`, value: '6', name: 'weekday' },
  { label: `Sunday`, value: '7', name: 'weekday' },
];

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: state.isFocused ? '#404ff5' : '#e9eaf5',
    boxShadow: state.isFocused ? '0px 0px 20px #404FF54D' : 'none',
    border: state.isFocused ? '1px solid #404ff5' : styles.border,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    fontSize: '14px',
    height: '38px',
  }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize, };
  }
}

const CheckinSettingsTime = ({
  scheduleTime,
  handleTime,
  interval,
  scheduleDay,
  handleDaysChange,
  handleIntervalSelect,
  onChangeDay,
  onChangeDate,
  defaultValue,
  type,
  formData,
}) => {

  const wordingInterval = {
    placeholderId: "choose.team.interval",
    placeholderMsg: "Choose check-in interval",
  }

  let scheduleLength = 0;
  if (scheduleDay !== undefined && scheduleDay !== null) {
    scheduleLength = Object.keys(scheduleDay).length;
  }

  // console.log('===== intervalOptions[1]', intervalOptions[1])

  let defaultInterval = type === 2 ? { label: defaultValue.interval, value: defaultValue.interval, name: "interval" } : defaultValue.interval
  // let defaultDay = type === 2 ? daysOptions.filter(d => d.label === defaultValue.days.day)[0] : defaultValue.days
  let defaultDay = type === 2 ? daysOptions.filter(d => d.label === defaultValue.days.day)[0] : defaultValue.days.day ? daysOptions.filter(d => d.label === defaultValue.days.day)[0] : defaultValue.days
  
  let scheduleTimeArr = scheduleTime.split(":")
  // console.log('====== scheduleTimeArr', scheduleTimeArr)

  let formattedTime = Moment().hour(scheduleTimeArr[0]).minute(scheduleTimeArr[1]);

  return (
    <div className="w-100 flex align-start">
      <div className="flex flex-column w-33 mr-1 time-picker">
      <p className="fs-12 w-100 mb-xs">
        <FormattedMessage id="time" defaultMessage="Time" />
        </p>
        {/* <TimePickerDefault
          onChange={handleTime}
          value={scheduleTime}
          disableClock={true}
          clearIcon={null}
          locale="sv-sv"
          className="bg__white"
        /> */}

        <TimePicker
          showSecond={false}
          defaultValue={formattedTime}
          value={formattedTime}
          className="bg__white h-44 brd brd-grey__new pl-1"
          onChange={handleTime}
          format={format}
          // use12Hours
          inputReadOnly
          clearIcon={null}
        />
        
      </div>

      <div className="flex flex-column w-33 mr-1">
        <p className="fs-12 w-100 mb-xs">
          <FormattedMessage id="interval" defaultMessage="Interval" />
        </p>
        <SelectItem 
          defaultValue={defaultInterval}
          value={intervalOptions.filter(item => item.value === formData.interval)[0]}
          onChange={handleIntervalSelect}
          options={intervalOptions}
          customStyles={customStyles}
          isClearable={false}
          key={`interval-U`}
          wording={wordingInterval}
          isMulti={false}
        />
      </div>

      <div className="flex flex-column w-33">
        {interval === 'Weekly' || interval === 'Biweekly' ? (
            <Fragment>
              <p className="fs-12 w-100 mb-xs">
                <FormattedMessage id="day" defaultMessage="Day" />
              </p>
              <SelectItem 
                defaultValue={defaultDay}
                onChange={onChangeDay}
                options={daysOptions}
                customStyles={customStyles}
                isClearable={false}
                key={`days-U`}
                wording={wordingInterval}
                isMulti={false}
              />
            </Fragment>
        ) : (<span></span>)}
      
        {interval === 'Daily' && (
          <Fragment>
            <p className="fs-12 w-100 mb-xs">
              <FormattedMessage id="days.week" defaultMessage="Days of the Week" />
            </p>
            <DayItem
              scheduleDays={scheduleLength > 0 && scheduleDay.weekDays}
              onChange={handleDaysChange}
              styles={'small-input ml-5'}
              stylesText={'w-60px'}
            />
          </Fragment>
        )}

        {interval === 'Monthly' && (
          <Fragment>
            <p className="fs-12 w-100 mb-xs">
              <FormattedMessage id="day.month" defaultMessage="Day of the Month" />
            </p>
              <QuestionItemNew
                wording={{ id: "date.month", text: "Enter a day" }}
                value={scheduleDay.index}
                name={scheduleDay.index}
                inputName={'name'}
                onChange={onChangeDate}
                stylesInput="w-100 h-44"
              />
          </Fragment>
        )}
      </div>

    </div>
  );
};

CheckinSettingsTime.propTypes = {
  handleTime: PropTypes.func.isRequired,
};

export default CheckinSettingsTime;
