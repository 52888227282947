import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logo30 from '../../img/logo/logo_square.png';
import { icons } from "../../img/elements/icons";
import PeopleIcon from "./icons/PeopleIcon";
import SettingsIcon from "./icons/SettingsIcon";
import CompanyIcon from "./icons/CompanyIcon";
import InviteIcon from "./icons/InviteIcon";
import TeamIconNew from "./icons/TeamIconNew";
import UpdateIcon from "./icons/UpdateIcon";
import OkrIcon from "./icons/OkrIcon";
import TodoIcon from "./icons/TodoIcon";
import KudosIcon from "./icons/KudosIcon";
import OneOnOneIcon from "./icons/OneOnOneIcon";
import DashboardIcon from "./icons/DashboardIcon";
import IcebreakerIcon from "./icons/IcebreakerIcon";
import SisterCompaniesIcon from './icons/SisterCompaniesIcon';
import useInvite from '../invite/useInvite';
import { changeCheckinFilters } from '../../actions/customAnswer';
import isEnabled, { ENGAGEMENT } from '../../utils/features';

const ShortMenu = ({
  company: { company },
  checkinFilters,
  changeCheckinFilters,
  team,
  teamId,
  settings,
  companyName,
  activeIndex,
  outreach,
  focusUsers,
  isShowCollapseBtn,
  toggleSidebar,
  sidebar
}) => {
  const [mobileMenu, showMobileMenu] = useState({ active: false });

  const { active } = mobileMenu;

  const handleMobile = () => {
    showMobileMenu({ ...mobileMenu, active: !active });
  };

  const [openInvite, InviteElem] = useInvite();

  return (
    <Fragment>
      {InviteElem}
      <div 
        className={`short_menu ${sidebar === 2 ? 'visible' : ''} menu app_menu brd_right brd_right_grey_light pl-1 pt-4 z-50000`}
      >
        <div className="w-100">
          <div className="logo_30 flex justify-center">
            <Link to="/" className="mxa pb-0">
              <img
                src={logo30}
                alt="Focus logo"
                className="w-30px h-30"
                width="30"
                height="30"
              />
            </Link>
          </div>
          
          <div className="">
            <div className="main_menu_items mt-4 text-center">
              <ul className="menu_list">
                
                <div className="tooltip">
                  <li className="main_menu_item main_menu__dashboard pb-1 tooltip">
                    <Link
                      to={'/dashboard/objectives'}
                      className={`mm_item pl-1xs tooltip1 ${activeIndex === 13 && ' active'}`}
                    >
                      <DashboardIcon activeIndex={activeIndex} shortMenu={true} />
                      <span className="tooltip__text">Dashboard</span>
                    </Link>
                  </li>
                </div>

                <li className="main_menu_item main_menu__okrs pb-1">
                  <Link
                    to={'/objectives'}
                    data-tip="Objectives" data-for="tooltip-okr"
                    className={`mm_item pl-1xs tooltip ${activeIndex === 4 && ' active'}`}
                  >
                    <OkrIcon activeIndex={activeIndex} />
                    <span className="tooltip__text">OKRs</span>
                  </Link>
                </li>
                <li className="main_menu_item main_menu__tasks pb-1">
                  <Link
                    to={'/tasks'}
                    data-tip="Tasks" data-for="tooltip-tasks"
                    className={`mm_item pl-1xs tooltip ${activeIndex === 12 && ' active'}`}
                  >
                    <TodoIcon activeIndex={activeIndex} />
                    <span className="tooltip__text">Tasks</span>
                  </Link>
                </li>
                <li className="main_menu_item main_menu__updates pb-1">
                  <Link
                    to={'/campaigns/all-checkins'}
                    className={`mm_item pl-1xs pr-1 tooltip fs-15 ${activeIndex === 2 && ' active'}`}
                  >
                    <UpdateIcon activeIndex={activeIndex} />
                    <span className="tooltip__text">Check-ins</span>
                  </Link>
                </li>

                <li className="main_menu_item main_menu__oneOnOne pb-0">
                  <Link
                    to={'/one_on_one'}
                    className={`mm_item pl-1xs tooltip pb-0 ${activeIndex === 11 && ' active'}`}

                  >
                    <OneOnOneIcon activeIndex={activeIndex} />
                    <span className="tooltip__text">1:1 meetings</span>
                  </Link>
                </li>
                <div className="line w-50 mxa"></div>

                {isEnabled(ENGAGEMENT, { company }) && (
                <>
                  <li className="main_menu_item main_menu__icebreakers pb-1 mt-1">
                    <Link
                      to={'/icebreakers'}
                      className={`mm_item tooltip ${activeIndex === 14 && ' active'}`}
                    >
                      <IcebreakerIcon activeIndex={activeIndex} />
                      <span className="tooltip__text">Icebreakers</span>
                    </Link>
                  </li>
                  <li className="main_menu_item main_menu__kudos pb-0">
                    <Link
                      to={'/mood'}
                      className={`mm_item pb-xs tooltip ${activeIndex === 5 && ' active'}`}
                    >
                      <KudosIcon activeIndex={activeIndex} />
                      <span className="tooltip__text">Mood & Kudos</span>
                    </Link>
                  </li>
                </>)}

                <div className="line w-50 mxa"></div>

                <ul className="menu_list mt-1">
                    <li className="main_menu_item main_menu__people">
                      <Link
                        to={`/people`}
                        className={`mm_item pl-1xs tooltip ${activeIndex === 8 && ' active'}`}
                      >
                        <PeopleIcon activeIndex={activeIndex} />
                        <span className="tooltip__text">Members & Teams</span>
                      </Link>
                    </li>

                  {settings ? (
                    <li className="main_menu_item main_menu__settings">
                      <Link
                        to={`/settings`}
                        className={`mm_item pl-1xs tooltip ${activeIndex === 9 && ' active'}`}
                      >
                        <SettingsIcon activeIndex={activeIndex} />
                        <span className="tooltip__text">Company Settings</span>

                      </Link>
                    </li>
                  ) : null}

                  <li className="main_menu_item main_menu__team pb-1">
                      <Link
                        to={`/campaigns/all-checkins`}
                        onClick={() => changeCheckinFilters({ value: company._id, label: company.name, type: 1 }, checkinFilters.time, checkinFilters.company)}
                        className={`mm_item tooltip ${activeIndex === 6 && ' active'}`}
                      >
                        <CompanyIcon activeIndex={activeIndex} shortMenu={true} />
                        <span className="tooltip__text">{companyName}</span>
                      </Link>
                    </li>

                  {team ? (
                    <li className="main_menu_item main_menu__team pb-1">
                      <Link
                       to={`/campaigns/all-checkins`}
                       onClick={() => changeCheckinFilters({ value: teamId, label: team, type: 2 }, checkinFilters.time, checkinFilters.company)}
                       className={`mm_item pb-0 tooltip ${activeIndex === 10 && ' active'}`}>
                        <TeamIconNew activeIndex={activeIndex} shortMenu={true} />
                        <span className="tooltip__text">{team}</span>
                      </Link>
                    </li>
                  ) : null}
                  </ul>

                  <div className="line w-50 mxa mt-1 mb-1"></div>

                  <ul className="menu_list py-1">
                    <li className="main_menu_item main_menu__sisterCompanies pb-1 mt-1">
                      <Link
                        to={'/sister-companies'}
                        className={`mm_item tooltip ${activeIndex === 15 && ' active'}`}
                      >
                        <SisterCompaniesIcon activeIndex={activeIndex} />
                        <span className="tooltip__text">Sister Companies</span>
                      </Link>
                    </li>
                    <li className={`mm_item w-28px pb-0 tooltip text-center `}>
                      <a
                        href={'https://usefocus.canny.io/feature-requests'}
                        rel="noopener noreferrer" target="_blank"
                        className={`flex align-center mm_item pt-2px`}
                      >
                        <span className="w-20px text-center opacity-04">
                          {icons.bulb_exclamation}
                        </span>

                        <span className="tooltip__text">Feature requests</span>
                      </a>
                    </li>
                  </ul>

                  {sidebar === 2 && (
                    <div className="w-30px mxa icon__invite__div">
                      <Link onClick={openInvite} className="btn btn-light_new ml-03 tooltip fs-14 mr-0 py-1 lh-10 h-34 flex align-center justify-center flow-column">
                        <InviteIcon activeIndex={activeIndex} />
                        <span className="tooltip__text tooltip__invite">Invite Team Members</span>
                      </Link>
                    </div>
                  )}

                  {outreach ? (
                    <Fragment>
                      <div className="line w-80"></div>

                      <ul className="menu_list">
                        <li className="main_menu_item main_menu__settings">
                          <Link
                            to={`/outreacher`}
                            className={`mm_item pl-1xs ${activeIndex === 100 && ' active'}`}
                          >
                            🚀
                          </Link>
                        </li>
                      </ul>
                    </Fragment>
                  ) : null}

                  {focusUsers ? (
                    <Fragment>
                      <div className="line w-80"></div>

                      <ul className="menu_list">
                        <li className="main_menu_item main_menu__settings">
                          <Link
                            to={`/a/stats`}
                            className={`mm_item pl-1xs ${activeIndex === 200 && ' active'}`}
                          >
                            🚀
                          </Link>
                        </li>
                      </ul>
                    </Fragment>
                  ) : null}
                  
              </ul>
            </div>
          </div>
          
          <button 
            className="modal-close btn_menu"
            onClick={() => handleMobile()}
          >
            x
          </button>
        </div>
        
        {isShowCollapseBtn && (
          <div className="fixed right-top grey cursor collapse__btn collapse__btn__small" onClick={toggleSidebar}>
            {icons.angle_right}
          </div>
        )}

      </div>

      <div className="menu_icon" id="menu_icon">
        <button className="no_btn" onClick={() => handleMobile()}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  company: state.company,
  checkinFilters: state.customAnswer.filters
});

export default connect(mapStateToProps, { changeCheckinFilters })(ShortMenu);
