import React, { Fragment, useState } from "react";
import PropTypes from 'prop-types';
import { icons } from '../../img/elements/icons'

const Info = ({
  size, msg
}) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <Fragment>
      <span 
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        className={`absolute right-10 top-13`}
      >
        {size === 16 ? icons.info_small : icons.info}
      </span>
      {isShown && (
        <div className={`w-200px absolute right-35 top-13 bg__white shadow br-4 z-20000 px-15 py-1`}>
          <p className="w-100 fs-10 lh-14">{msg}</p> 
        </div>
      )}
    </Fragment>
  );
};

Info.propTypes = {
  msg: PropTypes.object.isRequired,
  size: PropTypes.number
};


export default Info;
