import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Loader from 'react-loader-spinner';

const UpdateOkrBtn = ({
  update, toggleUpdateMode, 
  onSubmit, okrId, detailsId,
  isLoader,
  btnCta
 }) => {
  return (
    <Fragment>
      {!update ? (
          <Fragment>
            <div className="block mla w-content pb-0">
              <button 
                type="button" 
                className="btn_new__grey br-4 cursor"
                onClick={() => toggleUpdateMode(!update)}
              >
                <FormattedMessage
                  id="okr.form.update"
                  defaultMessage="Update OKR"
                />
              </button>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="flex align-center block mla w-content pb-0">
              <button 
                type="button" 
                className="btn_new__grey br-4 cursor"
                onClick={() => toggleUpdateMode(!update)}
              >
                <FormattedMessage
                  id="okr.form.cancel"
                  defaultMessage="Cancel"
                />
              </button>

              <button 
                type="submit" 
                className="btn btn-small btn-small-blue ml-1 br-4 mr-0"
                onClick={(e) => onSubmit(e, okrId, detailsId)}
                disabled={!isLoader ? false : true}
              >
                {/* <FormattedMessage
                  id="save"
                  defaultMessage="Save"
                /> */}
                {btnCta ? btnCta : (<FormattedMessage id="save" defaultMessage="Save" />)}
              </button>
            </div>
          </Fragment>
        )}
    </Fragment>
  );
};

UpdateOkrBtn.propTypes = {
  toggleUpdateMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  update: PropTypes.bool.isRequired,
};

export default UpdateOkrBtn;
