import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserNav from '../layout/UserNav';
import Menu from '../layout/Menu';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { getIcebreakerSettings, toggleIcebreakerSettings, clearIcebreakers } from '../../actions/icebreaker';
import { getPlanSettings, togglePlanSettings, clearPlanSettings } from '../../actions/plan';
import { getStandupSettings, clearStandupSettings } from '../../actions/standup';
import { getAllCustoms, toggleCustomSettings, clearCustoms } from '../../actions/custom';
import { getAllOkrCalls, toggleOkrCall, clearOkrCalls } from '../../actions/okrcall';
import { getUserCompany } from '../../actions/company';
import { getUpdateSettings, createAdminUpdate, clearUpdateSettings } from '../../actions/update';
import { getCompanyAnswersSettings } from '../../actions/standupAnswer';
import AdminDashboardCardItemNew from './AdminDashboardCardItemNew';
import Notification from '../utils/Notification';
import { adminCopy } from '../../utils/wording/admin';
import { FormattedMessage } from 'react-intl';
import SettingsTitle from './elems/SettingsTitle';
import SubMenu from './SubMenuNew';

// For plans check-ins
import { getCompanyProfiles } from '../../actions/profile';

const AdminDashboard = ({
  clearIcebreakers,
  createAdminUpdate,
  clearCustoms,
  getAllCustoms,
  toggleCustomSettings,
  clearOkrCalls,
  getAllOkrCalls,
  toggleOkrCall,
  getStandupSettings,
  getUserCompany,
  getCompanyAnswersSettings,
  toggleIcebreakerSettings,
  getIcebreakerSettings,
  getCompanyProfiles,
  profile: { profiles },
  icebreaker,
  standup: { settings },
  update,
  standupAnswer: { standupAnswers, isSettingsLoading },
  company: { company },
  auth: { loading, user },
  custom: { campaigns },
  okrCall: { okrCalls },
  getPlanSettings,
  togglePlanSettings,
  clearPlanSettings,
  plan,
  getUpdateSettings,
  clearStandupSettings,
  clearUpdateSettings
}) => {
  useEffect(() => {
    getStandupSettings(true);
    getCompanyAnswersSettings();
    getUpdateSettings();
    getUserCompany();
    getCompanyProfiles();
    getIcebreakerSettings();
    getPlanSettings();
    getAllCustoms();
    clearCustoms();
    getAllOkrCalls();
    clearOkrCalls();

    // Clear state
    return () => {
      clearStandupSettings();
      clearIcebreakers();
    };
  }, [
    getStandupSettings,
    getCompanyAnswersSettings,
    getUpdateSettings,
    getUserCompany,
    getCompanyProfiles,
    getIcebreakerSettings,
    getPlanSettings,
    getAllCustoms,
    clearCustoms,
    clearCustoms,
    getAllOkrCalls,
    clearOkrCalls
  ]);

  useEffect(() => {
    // Clear state
    return () => {
      clearPlanSettings();
    };
  }, []);

  useEffect(() => {
    // Clear state
    return () => {
      clearUpdateSettings();
    };
  }, []);

  const updatePlan2 = (
    <FormattedMessage
      id="admin.updatePlan.msg2"
      defaultMessage={`The Free plan is limited by 4 users in the company. You have ${
        profiles && profiles.length
      } active users today. Please upgrade the plan to use Focus for the team with 5 and more users.`}
    />
  );

  const pageTitle = <FormattedMessage id="checkins" defaultMessage="Check-ins" />;
  const pagesubTitle = <FormattedMessage id="checkins.subtitle" defaultMessage="Create Custom Check-ins to cover a wide range of topics, from progress, goals to personal development." />;
  const createCheckinBtn = <FormattedMessage id="create.checkin" defaultMessage="Create New Check-in" />;

      // console.log('== profiles', profiles)

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">
          {user && user.department ? (
            <Menu
              companyName={user && user.companyName}
              team={user.departmentName}
              teamId={user.department}
              settings={user.isAdmin && true}
              outreach={user && user.isAdmin && user.role === 'Superadmin' && true}
              activeIndex={9}
            />
          ) : (
            <Menu
              companyName={user && user.companyName}
              settings={user && user.isAdmin && true}
              activeIndex={9}
            />
          )}

          <UserNav />

          <Fragment>
            {loading || !settings || !company || !profiles || !update || !icebreaker ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="ml-290 pl-5 pr-5 main-profile-admin pt-0 pb-5">
                  <div className="settings-admin">
                    <div className="mt-56 pt-xs mb-3">
                      <SettingsTitle />
                    </div>
                    <div className="flex align-start">
                      <div className="w-230px">
                        <SubMenu activeIndex={1} />
                      </div>


                    <div className="content">
                      <div className="section-title mb-14">
                        <div className="flex flex-column align-start">
                          <h5 className='lh-35 fw-600'>
                            {pageTitle}
                          </h5>
                          <p className="fs-12 lh-18 pr-1">{pagesubTitle}</p>
                        </div>
                        <div className="w-300px text-right">
                          <Link to="/settings/campaign/new" className="fs-14 lh-34 inline-block text-center btn__secondary_green px-25 mla ml-2-xs">
                            {createCheckinBtn}
                          </Link>
                        </div>
                      </div>

                      <div className="dashboard-admin">
                        {company.plan === 1 && profiles.length > process.env.REACT_APP_FREE_PLAN_USERS && (
                          <Notification
                            msg={adminCopy.updatePlan}
                            msg2={updatePlan2}
                            cta={adminCopy.ctaPlan}
                            btnColor={'red'}
                            color={`red`}
                            url={`/settings/pricing`}
                          />
                        )}

                        {company.plan === 1 && profiles.length >= 1 && profiles.length <= process.env.REACT_APP_FREE_PLAN_USERS && (
                          <Notification
                            msg={adminCopy.freePlan}
                            msg2={adminCopy.freePlan2}
                            cta={adminCopy.ctaPlan}
                            btnColor={'orange'}
                            color={`orange`}
                            url={`/settings/pricing`}
                          />
                        )}

                        <Fragment>
                          {standupAnswers && company.plan !== 1 && standupAnswers.length < 3 && (
                            <Notification
                              msg={adminCopy.msg}
                              msg2={adminCopy.msg2}
                              color={`orange`}
                            />
                          )}

                          <Fragment>
                            <AdminDashboardCardItemNew
                              settings={settings && settings}
                              updateSettings={update && update.settings}
                              disabled={company.plan === 1 && profiles.length <= process.env.REACT_APP_FREE_PLAN_USERS ? true : false}
                              companyPlan={company.plan}
                              icebreakerSettings={icebreaker && icebreaker.settings}
                              toggleIcebreakerSettings={toggleIcebreakerSettings}
                              planSettings={plan && plan.settings}
                              togglePlanSettings={togglePlanSettings}
                              customCampaigns={campaigns}
                              toggleCustomSettings={toggleCustomSettings}
                              okrCalls={okrCalls}
                              toggleOkrCall={toggleOkrCall}
                              slackId={company && company.slackTeamId}
                              isSlack={company && company.isSlack}
                              discord={company && company.discord}
                              profiles={profiles ? profiles : []}
                            />
                          </Fragment> 
                        </Fragment>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

AdminDashboard.propTypes = {
  createAdminUpdate: PropTypes.func.isRequired,
  getStandupSettings: PropTypes.func.isRequired,
  getUpdateSettings: PropTypes.func.isRequired,
  getCompanyAnswersSettings: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getIcebreakerSettings: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  toggleIcebreakerSettings: PropTypes.func.isRequired,
  clearIcebreakers: PropTypes.func.isRequired,
  getPlanSettings: PropTypes.func.isRequired,
  togglePlanSettings: PropTypes.func.isRequired,
  getAllCustoms: PropTypes.func.isRequired,
  toggleCustomSettings: PropTypes.func.isRequired,
  clearCustoms: PropTypes.func.isRequired,
  getAllOkrCalls: PropTypes.func.isRequired,
  toggleOkrCall: PropTypes.func.isRequired,
  clearOkrCalls: PropTypes.func.isRequired,
  clearPlanSettings: PropTypes.func.isRequired,
  clearStandupSettings: PropTypes.func.isRequired,
  clearUpdateSettings: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  standup: PropTypes.object.isRequired,
  update: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  standupAnswer: PropTypes.object.isRequired,
  icebreaker: PropTypes.object.isRequired,
  custom: PropTypes.object.isRequired,
  okrCall: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  standup: state.standup,
  update: state.update,
  auth: state.auth,
  profile: state.profile,
  standupAnswer: state.standupAnswer,
  company: state.company,
  icebreaker: state.icebreaker,
  plan: state.plan,
  custom: state.custom,
  okrCall: state.okrCall
});

export default connect(mapStateToProps, {
  createAdminUpdate,
  getStandupSettings,
  getCompanyAnswersSettings,
  getUserCompany,
  getUpdateSettings,
  getCompanyProfiles,
  getIcebreakerSettings,
  toggleIcebreakerSettings,
  clearIcebreakers,
  getPlanSettings,
  togglePlanSettings,
  clearPlanSettings,
  getAllCustoms,
  toggleCustomSettings,
  clearCustoms,
  getAllOkrCalls,
  toggleOkrCall,
  clearOkrCalls,
  clearStandupSettings,
  clearUpdateSettings
})(AdminDashboard);