import React, { useState, Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MainMenu from '../layout/Sidebar';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import OkrCardItem from './OkrCardItem';
import OkrTree from './okrTree/OkrTree';
import MainWidget from '../utils/MainWidgetNew';
import moment from 'moment';
import chroma from 'chroma-js';
import {
  getCompanyOkrs,
  getCompanySortedOkrs,
  clearOkrs,
  cleanData,
  archiveOKR,
  getCompanyTreeOkrs,
  deleteOkr,
  updateLike,
  updateOkr,
  deleteUpdate,
  getAllMetrics,
  getOkrByIdUrl,
  clearOkr,
  changeOkrFilters
} from '../../actions/okr';
import { FormattedMessage } from 'react-intl';
import { setAlert } from '../../actions/alert';
import { modal } from '../../utils/wording/customs';
import { icons } from '../../img/elements/icons';
import Select from 'react-select';
import OkrDetails from './okr-details/Okrdetails';
import { CSVLink } from 'react-csv';
import generateCsv from '../../utils/export/csv';
import PDF from '../utils/pdf/PDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { getAllCustoms, getAllSettings } from '../../actions/custom';
import CheckinModal from '../utils/BottomModal';
// For plans check-ins
import { getUserCompany } from '../../actions/company';
import { getCompanyTasks, updateTodo, completeTodo, deleteTodo } from '../../actions/todo';
import { getCompanyProfiles } from '../../actions/profile';
import NoContent from '../utils/NoContentNew';
import updateImg from '../../img/empty_states/okr.png';
import searchImg from '../../img/empty_states/search@2x.png';
import Loader from 'react-loader-spinner';


// OKR Privacy:
// 1 - public (company level)
// 2 - team level
// 3 - private level

let iteration = 0;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10
  },
  fontSize: '14px'
});

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: '#ffffff',
    padding: '0px 8px',
    lineHeight: '20px',
    height: 35,
    minHeight: 35
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      fontSize: '14px',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        color: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css())
      }
    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px'
  }),
  input: () => ({
    backgroundColor: '#ffffff',
    fontSize: '14px'
    // padding: "2px 8px 9px 8px",
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: '#ffffff',
    padding: '0px 8px',
    lineHeight: '20px',
    height: 35,
    minHeight: 35
  }),
  placeholder: (styles) => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
};

//period === moment.js .startOf valid string option (isoweek, month, year,)
const timePeriod = (date, period) => {
  return { start: moment(date).startOf(period).unix(), period: moment(date).endOf(period).unix() };
};


const OkrCompany = ({
  cleanData,
  updateOkr,
  clearOkrs,
  getCompanyOkrs,
  getCompanySortedOkrs,
  updateLike,
  getAllMetrics,
  getUserCompany,
  getCompanyProfiles,
  auth: { user, sidebar },
  company: { company },
  profile: { profiles },
  okr: { okrs, tree, loading, data, okr },
  locale,
  archiveOKR,
  deleteOkr,
  getCompanyTasks,
  todo: { tasks, task },
  updateTodo,
  completeTodo,
  deleteTodo,
  setAlert,
  getCompanyTreeOkrs,
  deleteUpdate,
  match,
  getOkrByIdUrl,
  okrFilters,
  changeOkrFilters,
  getAllCustoms,
  custom: { campaigns, allSettings, modalBox },
  getAllSettings,
}) => {
  let statusObj =
    locale && locale.lang !== 'ru'
      ? { onTrack: 'On Track', behind: 'Behind', atRisk: 'At Risk', all: 'All Active', archive: 'Archive', achieved: "Achieved", notAchieved: "Not Achieved" }
      : { onTrack: 'По Плану', behind: 'Отстаем', atRisk: 'Риск', all: 'Все активные', archive: 'Архив', achieved: "Выполненные", notAchieved: "Не выполненные" };

  const statusOptions = locale &&
    locale.lang && [
      { value: 0, label: statusObj.all, color: '#000000' },
      { value: 1, label: statusObj.onTrack, color: '#6ddeb3' },
      { value: 2, label: statusObj.behind, color: '#facf34' },
      { value: 3, label: statusObj.atRisk, color: 'red' },
      { value: 4, label: statusObj.archive, color: '#898989' },
      { value: 5, label: statusObj.achieved, color: '#404ff5' },
      { value: 6, label: statusObj.notAchieved, color: 'grey' }
    ];

  const [formData, setFormData] = useState({
    team: '',
    objectives: [],
    archivedObjectives: [],
    todos: [],
    copyTree: [],
    time: '',
    status: statusOptions[0]
  });

  const [isLoader, toggleLoader] = useState(false);

  const formDataRef = useRef(formData);

  // Window size
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    // console.log('========== USEEFFECT resize WORKS')

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    // console.log('========== USEEFFECT 0 WORKS')

    formDataRef.current = formData;

  }, [formData]);

  useEffect(() => {
    // console.log('========== USEEFFECT 99 WORKS', formDataRef)

    return () => {
      // changeOkrFilters(formDataRef.current.team, formDataRef.current.time);
      // console.log('========== USEEFFECT 99 EXIT')
      
      // Make iteration = 0, when user leaves the page
      iteration = 0
    };
  }, [formDataRef, setFormData]);

  const [detailsData, setDetailsData] = useState({
    isOkrDetails: false,
    detailsId: '',
    okrDetails: '',
    detailsIndex: '',
    metrics: [],
    okrId: ''
  });

  const [okrData, setOkrData] = useState({
    keyResults: [],
    status: '',
    title: '',
    text: ''
  });

  const [updateMode, toggleUpdateMode] = useState(false);
  const [search, setSearch] = useState('');
  const [results, setResults] = useState({
    searchResults: false,
    searchKeyword: ''
  });

  const [archive, toggleArchive] = useState(false);
  // Сделал OKR Tree по отдельной ссылке, но здесь оставил этот стейт, чтобы не менять все.
  // Просто выключил место, где изменяется okrTree. Он всегда будет false
  const [okrTree, toggleOkrTree] = useState(false);
  const [showAllKeyResults, toggleAllKeyResults] = useState(false);
  const [exportBtn, toggleExportBtn] = useState(false);
  const [teamOptionsWithOkrs, setTeamOptionsWithOkrs] = useState([]);
  const [localLoading, setLocalLoading] = useState(true);

  let { searchKeyword } = results;
  let { team, objectives, archivedObjectives, todos, copyTree, time } = formData;
  let { isOkrDetails, detailsId, okrDetails, detailsIndex, metrics, okrId } = detailsData;
  let { status } = okrData;

  let teamOptions = [];
  let timeOptions = [];
  let usersOptions = [];
  let okrOptions = [];
  let mentions = [];
  // let ownerOptions = []

  // Перебор profiles & departments и приведение данных к формату Селекта
  {
    profiles &&
      profiles.forEach((profile, index) => {
        let mention = {
          id: profile._id,
          display: `${profile.firstName} ${profile.lastName}`,
          value: profile._id,
          label: `${profile.firstName} ${profile.lastName}`,
          name: 'owner'
        };
        mentions.push(mention);
        // ownerOptions.push(mention);

        if (user && profile._id === user._id) return;

        let label =
          (profile && profile.departmentName === null) || profile.department === undefined
            ? `${profile.firstName} ${profile.lastName}`
            : `${profile.firstName} ${profile.lastName} - [${profile.departmentName}]`;

        let coworker = {
          value: profile._id,
          label,
          user: profile._id,
          firstName: profile.firstName,
          lastName: profile.lastName,
          avatar: profile.avatar,
          department: profile.department,
          departmentName: profile.departmentName,
          name: 'owner',
          type: 3
        };

        teamOptions.push(coworker);
        usersOptions.push(coworker);
      });
  }

  // console.log('===== okrs in KR', okrs)
  // // console.log('===== mentions in OKR', mentions)
  // console.log('===== team in OKR', team)
  // console.log('===== time in OKR', time)
  // console.log('===== teamOptionsWithOkrs in OKR', teamOptionsWithOkrs)

  {
    user &&
      company &&
      company.departments.length > 0 &&
      company.departments.forEach((department, index) => {
        if (user && (user.department === null || user.department === undefined)) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };

          teamOptions.unshift(team);
        } else if (
          user &&
          user.department !== null &&
          user.department !== undefined &&
          department._id !== user.department
        ) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };

          teamOptions.unshift(team);
        }

        if (user && index === company.departments.length - 1) {
          // You is third in list
          let person = {
            value: user._id,
            label:
              user && (user.departmentName === null || user.department === undefined)
                ? `${user.firstName} ${user.lastName}`
                : `${user.firstName} ${user.lastName} - [${user.departmentName}]`,
            user: user._id,
            firstName: user.firstName,
            lastName: user.lastName,
            avatar: user.avatar,
            department: user.department,
            departmentName: user.departmentName,
            name: 'owner',
            type: 3
          };
          // teamOptions.unshift(person);
          usersOptions.unshift(person);

          // Second option is the team if exists
          if (user.department !== null && user.department !== undefined) {
            let team = { value: user.department, label: `${user.departmentName}`, type: 2 };
            teamOptions.unshift(team);
          }

          // Sort users alphabetically by first name
          teamOptions.sort((a, b) => a.label.localeCompare(b.label));

          let companyOkrs = locale && locale.lang !== 'ru' ? `- Organization level` : `- Уровень компании`;

          // Third option is user
          teamOptions.unshift(person);

          // Second option is the company
          // let name = { value: company._id, label: `${company.name} ${companyOkrs}`, type: 1 };
          let name = { value: company._id, label: `${company.name}`, type: 1 };
          teamOptions.unshift(name);

          let allOkrs = locale && locale.lang !== 'ru' ? `All OKRs` : `Все OKR`;

          // First option is all OKRs
          let all = { value: company._id, label: allOkrs, type: 4, company: company._id, name: company.name };
          teamOptions.unshift(all);
        }
      });
  }

  if (company && company.departments.length === 0 && user && profiles && profiles.length === 1) {
    teamOptions = [
      { value: company._id, label: `All OKRs`, type: 4, name: company.name },
      { value: company._id, label: `${company.name}`, type: 1 },
      { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 }
    ];
  } else if (company && company.departments.length === 0 && user && profiles && profiles.length > 1) {
    // if 0 teams and 1+ users
    // add user and company to others members that we found in the profiles loop
    let person = { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 };
    teamOptions.unshift(person);

    let companyOkrs = locale && locale.lang !== 'ru' ? `- Organization level` : `- Уровень компании`;

    // Third option is the company
    // let name = { value: company._id, label: `${company.name} ${companyOkrs}`, type: 1 };
    let name = { value: company._id, label: `${company.name}`, type: 1 };
    teamOptions.unshift(name);

    let allOkrs = locale && locale.lang !== 'ru' ? `All OKRs` : `Все OKR`;

    // Fourth option is all OKRs
    let all = { value: company._id, label: allOkrs, type: 4, company: company._id, name: company.name };
    teamOptions.unshift(all);
  }

  // All OKRs by default
  let allOkrs = locale && locale.lang !== 'ru' ? `All OKRs` : `Все OKR`;
  let choosedTeam =
    okrFilters.team ||
    (locale && user
      ? { value: user.company, label: allOkrs, type: 4, company: user.company, name: user.companyName }
      : {});


  // Time Options
  let todayDate = new Date();
  let hours = new Date().getUTCHours();
  let minutes = new Date().getMinutes();
  let today = new Date().getDay();
  let midnight = new Date().setHours(0, 0, 0, 0);
  let currentYear = new Date().getFullYear();
  let yearEnd = new Date(`${currentYear}, 12, 31 23:59:59`);
  let firstDayOfMonth = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1).getTime();
  let firstDay = todayDate.getDate() - todayDate.getDay(); // First day is the day of the month - the day of the week
  let lastDay = firstDay + 7; // lastDayOfWeek day is the firstDayOfWeek day + 6
  let lastDayOfMonth = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0).setHours(23, 59, 59, 0);
  let firstDayOfWeek = new Date(todayDate.setDate(firstDay)).getTime();
  let lastDayOfWeek = new Date(todayDate.setDate(lastDay)).getTime();
  if (today === 0) {
    today = 8;
  } // For Sundays

  let all = Date.parse(new Date(new Date(2019, 1, 1, 1, 1)));
  let allEnd = Date.parse(new Date(new Date(2219, 1, 1, 1, 1)));
  // let yearStart2 = Date.parse(new Date(new Date(currentYear, 1, 1, 1, 1)));
  let yearStart = Date.parse(currentYear, 0, 1);
  let year = yearEnd.getTime();

  let allTime = locale && locale.lang !== 'ru' ? `All Time` : `Все время`;
  let thisYearTime = locale && locale.lang !== 'ru' ? `This Year` : `Год`;
  let quarter1Time = locale && locale.lang !== 'ru' ? `Quarter 1` : `Квартал 1`;
  let quarter2Time = locale && locale.lang !== 'ru' ? `Quarter 2` : `Квартал 2`;
  let quarter3Time = locale && locale.lang !== 'ru' ? `Quarter 3` : `Квартал 3`;
  let quarter4Time = locale && locale.lang !== 'ru' ? `Quarter 4` : `Квартал 4`;
  let thisMonthTime = locale && locale.lang !== 'ru' ? `This month` : `Месяц`;
  let thisWeekTime = locale && locale.lang !== 'ru' ? `This Week` : `Неделя`;
  let thisQuarterTime = '';
  let lastMonthTime = locale && locale.lang !== 'ru' ? `Last Month` : `Прошлый месяц`;
  let lastWeekTime = locale && locale.lang !== 'ru' ? `Last Week` : `Прошлая  неделя`;
  let lastYearTime = locale && locale.lang !== 'ru' ? `Last Year` : `Прошлый год`;

  let currentMonth = new Date().getMonth();

  // Quarters
  let quarter1Start = new Date(`${currentYear}, 1, 1`);
  let quarter2Start = new Date(`${currentYear}, 4, 1`);
  let quarter3Start = new Date(`${currentYear}, 7, 1`);
  let quarter4Start = new Date(`${currentYear}, 10, 1`);

  let quarter1 = new Date(`${currentYear}, 3, 31, 23:59:59`);
  let quarter2 = new Date(`${currentYear}, 6, 30 23:59:59`);
  let quarter3 = new Date(`${currentYear}, 9, 30 23:59:59`);
  let quarter4 = yearEnd;

  let quarterNow = quarter1; // by default: Q1 end
  let quarterNowStart = quarter1Start; // by default: Q1 start
  let thisQuarterValue = 11; // by default: Q1 value

  if (currentMonth < 3) {
    thisQuarterValue = 11;
    thisQuarterTime = quarter1Time;
    quarterNowStart = quarter1Start;
    quarterNow = quarter1;
  }
  if (currentMonth > 2 && currentMonth < 6) {
    thisQuarterValue = 12;
    thisQuarterTime = quarter2Time;
    quarterNowStart = quarter2Start;
    quarterNow = quarter2;
  }
  if (currentMonth > 5 && currentMonth < 9) {
    thisQuarterValue = 13;
    thisQuarterTime = quarter3Time;
    quarterNowStart = quarter3Start;
    quarterNow = quarter3;
  }
  if (currentMonth > 8) {
    thisQuarterValue = 14;
    thisQuarterTime = quarter4Time;
    quarterNowStart = quarter4Start;
    quarterNow = quarter4;
  }

  let thisQuarter = quarterNow.getTime();
  let quarterStart = quarterNowStart.getTime();

  timeOptions = [
    { value: 5, label: allTime, start: all, period: allEnd },
    { value: 1, label: thisWeekTime, start: firstDayOfWeek, period: lastDayOfWeek },
    { value: 3, label: thisMonthTime, start: firstDayOfMonth, period: lastDayOfMonth },
    { value: 6, label: thisYearTime, start: yearStart, period: year },
    { value: 11, label: quarter1Time, start: quarter1Start, period: quarter1 },
    { value: 12, label: quarter2Time, start: quarter2Start, period: quarter2 },
    { value: 13, label: quarter3Time, start: quarter3Start, period: quarter3 },
    { value: 14, label: quarter4Time, start: quarter4Start, period: quarter4 },
    {
      value: 15,
      label: lastWeekTime,
      ...timePeriod(new Date().setDate(new Date().getDate() - 7), 'isoweek')
    },
    {
      value: 16,
      label: lastMonthTime,
      ...timePeriod(new Date().setMonth(new Date().getMonth() - 1), 'month')
    },
    {
      value: 17,
      label: lastYearTime,
      ...timePeriod(new Date().setFullYear(new Date().getFullYear() - 1), 'year')
    }
  ];

  let choosedTime = okrFilters.time || { value: 6, label: thisYearTime, start: yearStart, period: year };

  // OKR options and priority for Tasks
  {
    okrs &&
      okrs.length > 0 &&
      okrs.forEach((okr, i) => {
        if (!okr.isArchive) {
          let objective = {
            value: okr._id,
            label: `🎯 ${okr.objective}`,
            okrId: okr._id,
            objective: okr.objective,
            text: okr.objective,
            focusIndex: 99,
            status: okr.status,
            progress: okr.progress,
            goal: 100,
            isKr: false,
            name: 'OKR'
          };

          okrOptions.push(objective);

          let krs = okr.keyResults;

          if (krs.length > 0) {
            krs.forEach((kr, index) => {
              let item = {
                value: kr._id,
                label: ` - ${kr.title}`,
                okrId: okr._id,
                objective: okr.objective,
                text: kr.title,
                focusIndex: index,
                status: okr.status,
                progress: kr.progress,
                goal: kr.goal,
                current: kr.current,
                krId: kr._id,
                isKr: true,
                name: 'OKR'
              };

              okrOptions.push(item);
            });
          }
        }
      });
  }

  // Формируем приоритет
  let priorityObj =
    locale && locale.lang !== 'ru'
      ? { normal: 'Normal', average: 'Average', high: 'High' }
      : { normal: 'Обычный', average: 'Средний', high: 'Высокий' };
  const priorityOptions = locale &&
    locale.lang && [
      { value: 1, label: priorityObj.normal, color: '#6ddeb3', name: 'priority' },
      { value: 2, label: priorityObj.average, color: '#facf34', name: 'priority' },
      { value: 3, label: priorityObj.high, color: 'red', name: 'priority' }
    ];

  // Priority default choice
  let choosedPriority = {};

  task &&
    priorityOptions.forEach((item) => {
      if (item.value === task.status) {
        choosedPriority = {
          value: item.value,
          label: item.label,
          color: item.color
        };
      }
    });

  
    const sortOkrs = (objectives) => {
      try {
        // console.log('======== we are in SORTOKRS', objectives.length)
  
        // Only not Childs
        let parents = objectives.filter(o => !o.isChild);
        // let sortedOkrs = objectives.filter(o => !o.isChild); // double to about recursive loop when we remove child inside the loop
        
        // console.log('======== parents.length', parents.length)
        // console.log('======== childs.length', childs.length)

        let newSorted = [];
        // let childsCount = 0

        // Loop and if it's parent, then find childs
        for (let i = 0; i < parents.length; i ++) {
          let parent = parents[i];
          newSorted.push(parent);
          // console.log('======== i', i)
          // console.log('======== parent.objective', parent.objective)

          if (parent.isParent && parent.childOkrs && parent.childOkrs.length > 0) {

            function recurse(parent) {
              // console.log('=========== RECURSE START: parent', parent)

              for(let y = 0, count = parent.childOkrs.length; y < count; y++) {
                  // childsCount += 1;

                  let child = parent.childOkrs[y];
                  // console.log('=========== y', y)
                  // console.log('======== child.label', child.label)

                  let index = objectives.findIndex(o => { return o._id.toString() === child.value })
                  // console.log('======== objectives[index]', objectives[index])

                  if (index > -1 && objectives[index]._id.toString() !== parent._id.toString()) {
                    // insert child, if we found index
                    newSorted.push(objectives[index])
                    let childOkr = objectives[index];

                    if (childOkr.isParent && childOkr.childOkrs && childOkr.childOkrs.length > 0) {
                      // console.log('======== ЗАПУСКАЕМ рекурсию для ', childOkr.objective)

                      recurse(childOkr)
                    }
                  }
              }
            } 

            recurse(parent)
            
          }
        }
        // console.log('======== SORTOKRS end', objectives.length)

        // Get unique okrs that we don't have in newSorted. Sometimes we don't have it because they make parent its own child.
        let uniqueOkrs = objectives.filter((o) => newSorted.map(item => item._id).indexOf(o._id) === -1);

        if (uniqueOkrs.length > 0) {
          // console.log('======== parents.length', parents.length)
          // console.log('======== childsCount after', childsCount)
          // console.log('======== uniqueOkrs.length after', uniqueOkrs.length)
          // console.log('======== uniqueOkrs after', uniqueOkrs)
          newSorted = newSorted.concat(uniqueOkrs)
          // console.log('======== newSorted.length after', newSorted.length)
        }

        return newSorted;
  
      } catch (err) {
        setAlert(
          <FormattedMessage
            id="like.alert.error"
            defaultMessage="Something went wrong. We are working on it..."
          />,
          'danger'
        );
        console.log('Error in sortOkrs', err);
      }
    };  

  // Get okrs for team and time filters
  const getOkrs = (teamOption, time, status) => {
    // console.log('===== we are in getOkrs: time', time)
    // console.log('===== we are in getOkrs: teamOption', teamOption)
    
    let newItems = [];
    let newArchivedItems = [];
    let newTodos = [];
    let teamIndex = 'value';
    let itemIndex = 'company';
    let taskIndex = 'company';
    if (teamOption.type === 1) {
      itemIndex = 'team';
      taskIndex = 'company';
    } // Company level
    if (teamOption.type === 2) {
      itemIndex = 'team';
      taskIndex = 'department';
    }
    if (teamOption.type === 3) {
      itemIndex = 'user';
    }
    if (teamOption.type === 4) {
      itemIndex = 'company';
      taskIndex = 'company';
      teamIndex = 'value';
    } // All OKRs

    // Debug on mobile
    // if (isNaN(time.start) || isNaN(time.period)) {
    //   // time = timeOptions[6];
    //   time = okrFilters.time ? okrFilters.time : choosedTime;
    // }

    // сделать проще функцию: объединить 1,2 и 4
    // проверить как мы тут определяем, что teamOption
    if (teamOption.type !== 3) {
      // For team = 1, 2, or 4 (everything, but not individual OKRs)
      // Company level, team level, and All OKRs,
      newItems =
        time.value !== 5
          ? okrs.filter((item) => {
              return (
                item[itemIndex] === teamOption[teamIndex] &&
                !item.isArchive &&
                Date.parse(item.dueIn || item.updatedAt) > time.start &&
                Date.parse(item.dueIn || item.updatedAt) < time.period
              );
            })
          : okrs.filter((item) => item[itemIndex] === teamOption[teamIndex] && !item.isArchive);
      newArchivedItems =
        time.value !== 5
          ? okrs.filter(
              (item) =>
                item[itemIndex] === teamOption[teamIndex] &&
                item.isArchive &&
                Date.parse(item.dueIn || item.updatedAt) > time.start &&
                Date.parse(item.dueIn || item.updatedAt) < time.period
            )
          : okrs.filter((item) => item[itemIndex] === teamOption[teamIndex] && item.isArchive);
      
      // Tasks
      newItems.forEach((okr) => {
        tasks.forEach((item) => {
          if (item[taskIndex] === teamOption[teamIndex]) {
            // не делаю поиск по времени в тасках, чтобы показывать все задачи к ОКРам в конкретном периоде
            if (item.OKR && item.OKR.okrId === okr._id && !item.isCompleted) {
              return newTodos.push(item);
            }
          }
        });
      });
    } else {
      // console.log('=========== WE ARE here in user okr loop')
      // console.log('===== time in loop', time)
      // console.log('===== time.value in loop', time.value)
      // console.log('===== time.start in loop', time.start)
      // console.log('===== time.period in loop', time.period)

      // teampOption === 3
      // Individual OKRs
      okrs.forEach((item) => {
        let timeCondition =
          time.value !== 5
            ? Date.parse(item.dueIn || item.updatedAt) > time.start &&
              Date.parse(item.dueIn || item.updatedAt) < time.period
            : item.objective; // item.objective - для того, чтобы взять все ОКР, даже если нет item.DueIn
          
         // ToDo
         // Разный формат у time.start и time.period = в кварталах передается неправильный формат и из-за этого окр не выводятся при первой загрузке
        // console.log('===== timeCondition in loop', timeCondition)
        
        if (item.owner === teamOption.value && !item.isArchive && timeCondition) {
          // If user is owner
          return newItems.push(item);

          // Old solution when no arrays for owners
        } else if (item.keyResults.length > 0 && !item.isArchive) {
          let uniqueOKR = false;

          item.keyResults.forEach((kr) => {
            if (
              !uniqueOKR &&
              kr.owners.findIndex((i) => i.owner === teamOption.value) > -1 &&
              timeCondition
            ) {
              uniqueOKR = true;
              // if user is owner of key result
              return newItems.push(item);
            }
          });
        } else {
          return false;
        }
      });

      newArchivedItems = okrs.filter((item) => {
        if (
          item.owner === teamOption.value &&
          item.isArchive &&
          Date.parse(item.dueIn || item.updatedAt) > time.start &&
          Date.parse(item.dueIn || item.updatedAt) < time.period
        ) {
          // If user is owner
          return item;
        } else if (
          item.keyResults.findIndex((i) => i.owner === teamOption.value) > -1 &&
          Date.parse(item.dueIn || item.updatedAt) > time.start &&
          Date.parse(item.dueIn || item.updatedAt) < time.period
        ) {
          // if user is owner of key result
          if (item.isArchive) {
            return item;
          }
        } else {
          return false;
        }
      });

      // Tasks
      newItems.forEach((okr) => {
        tasks.forEach((item) => {
          if (item.creator === teamOption.value) {
            if (item.OKR && item.OKR.okrId === okr._id && !item.isCompleted) {
              return newTodos.push(item);
            }
          } else if (
            item.owner &&
            item.creator !== teamOption.value &&
            !item.isCompleted &&
            item.owner.user === teamOption.value &&
            item.OKR &&
            item.OKR.okrId === okr._id
          ) {
            return newTodos.push(item);
          }
        });
      });
    }
    if (status && status.value !== 0) {
      if (status.value < 5) {
        newItems = newItems.filter((item) => item.status === status.value);
      } else if (status.value === 5) {
        newItems = newItems.filter((item) => item.progress === 100);
      } else if (status.value === 6) {
        newItems = newItems.filter((item) => item.progress < 100);
      }
      
      // newArchivedItems = newArchivedItems.filter((item) => item.status === status.value);
    }

    // if (teamOption.type === 4){
      // console.log('====== [FINISH almost] newItems.length in getOKRs', newItems.length)
    // }
    // console.log('====== [FINISH almost] newItems.length in getOKRs', newItems.length)
    // sort OKRs to put childs below parents
    let sortedOkrs = sortOkrs(newItems);
    // let sortedOkrs = newItems;
    // console.log('====== [FINISH] sortedOkrs.length in getOKRs', sortedOkrs.length)

    let data = { newItems: sortedOkrs, newArchivedItems, newTodos };
    // let data = { newItems, newArchivedItems, newTodos };

    return data;
  };


  const getTeamOptionsWithOkrs = (users) => {
    try {

      if (users.length > 0) {
        for (let i = 0; i < users.length; i++) {
          let user = users[i];

          // console.log('============ CALLING getOkrs from getTeamOptionsWithOkrs')
          let okrsArr = getOkrs(user, time, status)
          let userOkrs = okrsArr.newItems;

          user.okrs = userOkrs.length;
          user.label = `${user.label} - ${userOkrs.length} OKRs`
        }
      }

      return users;

    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in getTeamOptionsWithOkrs', err);
    }
  };

  // let teamForEffect = team === '' || team === {} ? choosedTeam : team;
  let teamForEffect = !okrFilters.team ? choosedTeam : okrFilters.team
  // let timeForEffect = time === '' ? choosedTime : time;
  // let timeForEffect = !okrFilters.time ? choosedTime : okrFilters.time
  let timeForEffect = !okrFilters.time ? choosedTime : {
    value: okrFilters.time.value,
    label: okrFilters.time.label,
    start: new Date(okrFilters.time.start),
    period: new Date(okrFilters.time.period),
  }
  // console.log('======= choosedTime', choosedTime)
  // console.log('======= okrFilters', okrFilters)


  useEffect(() => {
    cleanData();

    const fetchData = async () => {
      let res = await getCompanySortedOkrs();
    
      if (res && res.ok) {
        setLocalLoading(false);
      }
      // console.log('======= useffect: res', res)
    }
  
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);

    // console.log('===== USEEFFECT 0.5 WORKS: clean data');    
  }, []);

  useEffect(() => {
    // console.log('======= USEEFFECT 1 WORKS: main')

    // let res = false;

    // Calling functions only if it's first two renders
    if (iteration < 2) {
      // console.log('======= useffect: calling functions...')

      // await getCompanySortedOkrs();
      // console.log('======= useffect: res', res)
      const fetchOkrs = async () => {
        // get the data from the api
        const res = await getCompanySortedOkrs;
        // console.log('======res in fetchOkrs', res)

        // set state with the result
        if (res && res.ok) {
          setLocalLoading(false);
        }
      }
    
      // get OKRs
      fetchOkrs()

      getUserCompany();
      getCompanyProfiles();
      getCompanyTasks();
      getCompanyTreeOkrs();
      getAllMetrics();
      getAllCustoms();
      getAllSettings();

    }

    if (match && match.url !== '/objectives') {
      getOkrByIdUrl(match.params.id);
    }

    let data = null;
    let newItems = null;
    let newArchivedItems = null;
    let newTodos = null;

    // console.log('=== okrs in useeffect', okrs)
    // console.log('=== user in useeffect', user)

    if (okrs && user) {
      data = getOkrs(teamForEffect, timeForEffect, formData.status);
      // console.log('============ CALLING getOkrs from useeffect 1')
      // console.log('============ here teamForEffect', teamForEffect)
    
      newItems = data.newItems;
      newArchivedItems = data.newArchivedItems;
      newTodos = data.newTodos;
    }
    // console.log('=== = newItems => obj in useEffect', newItems)

    if (newItems && user && okrs) {
      setFormData({
        ...formData,
        team: !okrFilters.team ? choosedTeam : okrFilters.team,
        time: !okrFilters.time ? choosedTime : okrFilters.time,
        objectives: okrs && newItems ? newItems : [],
        archivedObjectives: okrs && newArchivedItems ? newArchivedItems : [],
        todos: tasks && newTodos ? newTodos : [],
        copyTree: !tree ? [] : tree
      });
    }

    // Update comments in the OKR Details
    if (objectives && objectives.length > 0 && okrDetails && okrDetails.comments) {
      let objective = objectives.filter((o) => o._id.toString() === okrDetails._id.toString());

      if (
        objective.length > 0 &&
        okrDetails.comments &&
        objective[0].comments.length !== okrDetails.comments.length
      ) {
        setDetailsData({
          ...detailsData,
          okrDetails: objective[0]
        });
      }
    }
  }, [
    JSON.stringify(okrs),
    JSON.stringify(company),
    getCompanyProfiles,
    choosedTeam && choosedTeam.value && choosedTeam.value.length,
    tasks && tasks.length,
    tree !== undefined && tree && tree.length,
    locale,
    user,
    getAllCustoms,
  ]);

  // console.log('=== = okrFilters', okrFilters)
  // console.log('=== = okrFilters.team', okrFilters.team)
  // console.log('=== = team', team)
  // console.log('=== = time', time)
  // console.log('=== = okrs', okrs)
  // console.log('=== = objectives', objectives)

  useEffect(() => {
    let index = '';
    let detailsIdVar = '';
    // console.log('===== USEEFFECT 2 WORKS')

    if (okrs && okrs.length > 0 && okr && okr._id && match.params.id) {
      if (okr._id === match.params.id) {
        index = 99;
        detailsIdVar = okr._id;
      } else {
        // Get update index
        index = okr.keyResults.map((item) => item._id.toString()).indexOf(match.params.id);

        detailsIdVar = okr.keyResults[index]._id;
      }

      handleDetails(detailsIdVar, okr._id, index);
    }
  }, [objectives && objectives.length > 0 && JSON.stringify(okr)]);

  useEffect(() => {
    let metrics = '';
    // console.log('===== USEEFFECT 3 WORKS')

    if (
      (data && data.length > 0 && detailsIndex && detailsId && okrId) ||
      (data && data.length > 0 && detailsIndex === 0 && detailsId && okrId)
    ) {
      let id = detailsId;

      metrics =
        detailsIndex === 99
          ? data.filter((item) => !item.isKr && item.okr === okrId)
          : data.filter((item) => item.isKr && item.krId === id);

      setDetailsData({
        ...detailsData,
        metrics
      });
    }

    // ВЫКЛЮЧИЛ ТУТ
    // if (objectives.length > 0 && iteration > 9 && localLoading) {
    //   setLocalLoading(false)
    // } else if (okrs.length === 0 && objectives.length === 0 && iteration > 5) {
    //   setLocalLoading(false);
    // } else if (!loading && okrs.length === 0 && objectives.length === 0 && iteration === 1 && localLoading) {
    //   setLocalLoading(false);
    // }
  }, [
    JSON.stringify(data),
    // data && data.length
  ]);

  // data ? console.log('==== data.length', data.length) : console.log('==== data', data)
  // okrs ? console.log('==== okrs.length', okrs.length) : console.log('==== okrs', okrs)
  // objectives ? console.log('==== objectives.length', objectives.length) : console.log('==== objectives', objectives)
  // console.log('==== iteration', iteration)
  // console.log('==== loading', loading)

  iteration = iteration + 1;

  useEffect(() => {
    // console.log('===== USEEFFECT 4 WORKS')
    // console.log('===== USEEFFECT teamOptions', teamOptions)
    // console.log('===== USEEFFECT objectives', objectives)

    // if (teamOptions.length > 0 && objectives.length > 0) {
    if (teamOptions.length > 0) {
      let userWithOkrs = getTeamOptionsWithOkrs(teamOptions);

      setTeamOptionsWithOkrs(userWithOkrs)
    }

    // Turn off loading
    // if (objectives.length > 0 && iteration > 9) {
    //   setLocalLoading(false)
    // } else if (okrs.length === 0 && objectives.length === 0 && iteration > 5) {
    //   setLocalLoading(false);
    // } else if (!loading && okrs.length === 0 && objectives.length === 0 && iteration === 1 && localLoading) {
    //   setLocalLoading(false);
    // }

  }, [objectives]);

  // console.log('teamOptions', teamOptions);
  // console.log('======= teamOptionsWithOkrs', teamOptionsWithOkrs)

  // Helps in archive & delete when we do filter in reducer. Tree becomes undefined
  // Помогает избавиться от undefined при архивировании
  // Из-за этого пропадает дерево на несколько секунд.
  if (tree === undefined) {
    tree = copyTree;
  }

  const handleDetails = async (id, okrId, detailsIndex) => {
    if (isOkrDetails) {
      // if we already show details, check - is it the same details or for new one

      if (id === detailsId) {
        // the same details, then close
        isOkrDetails = false;
        detailsId = '';
        okrDetails = '';
        detailsIndex = '';
        metrics = [];
      } else {
        // New one - then show new details
        isOkrDetails = true;
        detailsId = id;
        okrDetails = okrs.filter((item) => item._id.toString() === okrId)[0];
        metrics =
          detailsIndex === 99
            ? data.filter((item) => !item.isKr && item.okr === okrId)
            : data.filter((item) => item.isKr && item.krId === id);
      }
    } else {
      // if no details, then show them
      isOkrDetails = true;
      detailsId = id;
      okrDetails = okrs.filter((item) => item._id.toString() === okrId)[0];
      metrics =
        detailsIndex === 99
          ? data.filter((item) => !item.isKr && item.okr === okrId)
          : data.filter((item) => item.isKr && item.krId === id);
    }

    setDetailsData({
      ...detailsData,
      isOkrDetails,
      detailsId,
      okrDetails,
      detailsIndex,
      metrics,
      okrId
    });
  };

  const handleArchive = async (e, _id, isArchive, isTree) => {
    e.preventDefault();

    const res = await archiveOKR(_id, isArchive, isTree);

    if (res.okr.isArchive) {
      // remove from OKR and add to Archive
      setFormData({
        ...formData,
        objectives: objectives.filter((item) => item._id !== res.id),
        archivedObjectives: [res.okr, ...archivedObjectives]
      });
    } else {
      // remove from archive and add to OKRs
      setFormData({
        ...formData,
        objectives: [res.okr, ...objectives],
        archivedObjectives: archivedObjectives.filter((item) => item._id !== res.id)
      });
    }
  };
  // console.log('=== objectives', objectives);
  // console.log('=== time', time);
  // console.log('=== team', team);

  const handleTime = async (selectedOption) => {
    try {
      // console.log('=== we are in handleTime - time', selectedOption);
      // console.log('=== we are in handleTime - team', team);
      let data = await getOkrs(team, selectedOption, formData.status);

      let { newItems, newArchivedItems, newTodos } = data;
      // console.log('=== handleTime - newItems', newItems);
      // console.log('=== handleTime - team', team);

      setFormData({
        ...formData,
        time: selectedOption,
        objectives: newItems,
        archivedObjectives: newArchivedItems,
        todos: newTodos
      });
      // console.log('=== handleTime END', formData);

      changeOkrFilters(team, selectedOption, user.company)

    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in handleTime', err);
    }
  };

  const handleStatusFilterChange = async (selectedOption) => {
    try {

      // Archive or not
      if (selectedOption.value !== 4) {
        // If no archive

        // If user switches from archive to active OKRs
        if (archive) {
          toggleArchive(!archive)
        } 

        // if user switches between at risk/behind/on trach
        let data = await getOkrs(team, time, selectedOption);

        let { newItems, newArchivedItems, newTodos } = data;

        setFormData({
          ...formData,
          status: selectedOption,
          objectives: newItems,
          archivedObjectives: newArchivedItems,
          todos: newTodos
        });
       
      } else {
        // Achive
        toggleArchive(!archive)
      }
      
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="status.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in handleStatusFilterChange', err);
    }
  };


  const handleTeam = async (selectedOption) => {
    try {
      // console.log('=== we are in handleTeam', selectedOption);

      let data = await getOkrs(selectedOption, time, formData.status);

      let { newItems, newArchivedItems, newTodos } = data;

      setFormData({
        ...formData,
        team: selectedOption,
        objectives: newItems,
        archivedObjectives: newArchivedItems,
        todos: newTodos
      });
      // console.log('=== handleTeam END', formData);

      changeOkrFilters(selectedOption, time, user.company)

    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in handleTeam', err);
    }
  };

  // Tasks functions
  const handleSelect = async (id, selectedOption, type) => {
    let obj = {};
    obj[type] = selectedOption;

    const res = await updateTodo(id, obj);

    // Update todos state
    setFormData({
      ...formData,
      todos: todos.map((item) => (item._id === res.id ? res.data : item))
    });
  };

  const handleNameForm = async (e, index, board, id) => {
    // if tasks or completed
    if (board === 1) {
      // Tasks (List View)
      todos[index].name = e.target.value;

      setFormData({
        ...formData,
        todos: todos
      });
    } else if (board === 10) {
      // OKR View
      let itemIndex = todos.map((item) => item._id).indexOf(id);
      todos[itemIndex].name = e.target.value;

      setFormData({
        ...formData,
        todos: todos
      });
    }
  };

  const handleDueIn = async (id, selectedOption) => {
    let obj = { dueIn: selectedOption };

    const res = await updateTodo(id, obj);

    // Update todos state
    setFormData({
      ...formData,
      todos: todos.map((item) => (item._id === res.id ? res.data : item))
    });
  };

  const clearTodo = (e, index) => {
    todos.splice(index, 1);

    setFormData({ ...formData, todos });
  };

  // ЗДЕСЬ получаем коллбэк и перебираем formData.objectives и обновляем
  const handleDeleteOkr = async (_id, isTree) => {
    try {
      const res = await deleteOkr(_id, isTree);

      // Не меняю расположение детей, так как в новом UI они не меняют вид, НО если в будущем будет дерево OKR, то надо будет менять и у детей информацию
      setFormData({
        ...formData,
        objectives: formData.objectives.filter((answer) => answer._id !== res.okrID)
      });
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in delete goal', err);
    }
  };

  // console.log('===== objective', objectives)

  const handleDeleteTask = async (_id, index) => {
    try {
      const res = await deleteTodo(_id);

      setFormData({
        ...formData,
        todos: todos.filter((item) => item._id !== _id)
      });
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in delete task', err);
    }
  };

  const handleComplete = async (e, id, index, isCompleted) => {
    e.preventDefault();

    // actions
    // update state
    const res = await completeTodo(id, isCompleted);

    // if it was not completed => mean that use completes it now
    if (!isCompleted) {
      // Complete
      index = todos.map((item) => item._id).indexOf(id);
      todos[index].isCompleted = !todos[index].isCompleted;

      setFormData({
        ...formData,
        todos: todos.filter((item) => item._id !== id)
      });
    }
  };

  const updatePlanMsg = {
    title: <FormattedMessage id="company.updateplan.title" defaultMessage="Update Plan" />,
    msg: (
      <FormattedMessage
        id="company.okrs.updateplan.msg"
        defaultMessage={`The Free plan is available only for individuals. You have ${
          profiles && profiles.length
        } active users today. Please upgrade the plan to use Focus for the team with 2 and more users.`}
      />
    )
  };

  const updateCheckinPlanMsg = {
    title: <FormattedMessage id="company.updateplan.title" defaultMessage="Update Plan" />,
    msg: (
      <FormattedMessage
        id="company.okrs.updatecheckinplan.msg"
        defaultMessage={`Objectives and Key Results are not available in the Check-ins plan. Please upgrade your plan to use OKRs.`}
      />
    )
  };

  // To show the right message if objectives === 0 in specific period
  let teamOkrs = okrs ? okrs.filter((item) => item.company === choosedTeam.company && !item.isArchive) : [];

  let noOkrMessageTeam = {
    title: <FormattedMessage id="nookrs.title" defaultMessage="Create Your First OKR" />,
    msg: <FormattedMessage id="nookrs.msg" defaultMessage="OKRs are an effective goal-setting and leadership tool for communicating what milestones you'll need to meet in order to accomplish your goal."/>,
    btnUrl: '/objective/new',
    btnCta: <FormattedMessage id="create.new.okr" defaultMessage="Create New Objective" />,
  };

  let noOkrMessageInFilters = {
    title: <FormattedMessage id="nookrs.filter.empty" defaultMessage="There are no OKRs that match your filter." />,
    msg: <FormattedMessage id="nookrs.filter.msg" defaultMessage="Try different parameters to find the OKR you are looking for."/>,
    // btnUrl: '/objective/new',
    // btnCta: <FormattedMessage id="create.new.okr" defaultMessage="Create New Objective" />,
  };

  const noArchivedOkrMessageTeam = {
    title: <FormattedMessage id="nookrs.archive.title.new" defaultMessage="Your OKR Archive is empty" />,
    // msg: (
    //   <FormattedMessage
    //     id="nookrs.archive.msg"
    //     defaultMessage={`${team.type !== 4 ? team.label : team.name} has not archived any objective yet.`}
    //   />
    // )
    msg: <FormattedMessage id="nookrs.archive.msg" defaultMessage="Meet your milestones and accomplish your goal. Once your Objective is completed and archived it will be stored here."/>,
    
  };

  // ===== OKR DETAILS functions  ======== /
  const findUserLike = (likes) => {
    if (!loading && user) {
      if (likes.filter((like) => like.user === user._id).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  // ЗДЕСЬ получаем коллбэк и перебираем formData.objectives и обновляем лайки
  const handleLike = async (id) => {
    try {
      const res = await updateLike(id);

      setFormData({
        ...formData,
        objectives: formData.objectives.map((answer) => {
          if (answer._id === res.id) {
            // Update all likes
            answer.likes = res.likes;

            return answer;
          } else {
            return answer;
          }
        })
      });
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in likes', err);
    }
  };

  const findComments = (comments) => {
    if (comments.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleStatus = (selectedOption) => {
    setOkrData({ ...okrData, status: selectedOption });
  };

  const onSubmitUpdate = async (e, okrId, detailsId) => {
    try {
      e.preventDefault(); 
      // Show Loader
      toggleLoader(true);
      // console.log('===== onSubmitUpdate start')

      okrData['detailsId'] = detailsId;

      const res = await updateOkr(okrData, okrId);
      // console.log('===== okrData in onsubmit', okrData)
      // console.log('===== res in onsubmit', okrData)

      if (res && res.id) {
        setFormData({
          ...formData,
          objectives: formData.objectives.map((item) => (item._id === res.id ? res.okr : item))
        });

        setDetailsData({
          ...detailsData,
          okrDetails: res.okr
        });

        toggleUpdateMode(!updateMode);

        setOkrData({
          ...okrData,
          title: '',
          text: ''
        });
      }

       // Hide Loader
       toggleLoader(false);

    } catch (err) {
      // Hide Loader
      toggleLoader(false);

      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in onSubmit', err);
    }
  };

  // console.log('===== formData', formData)

  // ЗДЕСЬ получаем коллбэк и перебираем formData.objectives и обновляем комменты
  const handleDelete = async (okrID, _id) => {
    try {
      const res = await deleteUpdate(okrID, _id);

      setFormData({
        ...formData,
        objectives: formData.objectives.map((answer) => {
          if (answer._id === res.okrID) {
            // Delete update
            answer.updates = answer.updates.filter((update) => update._id !== res.updateId);
            return answer;
          } else {
            return answer;
          }
        })
      });
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in comments', err);
    }
  };

  /** Hook that alerts clicks outside of the passed ref */
  // Hide Okr Detail if clicks outside
  function useOutsideAlerter(ref) {
    useEffect(() => {
    // console.log('========== USEEFFECT 5 WORKS')

      /** Alert if clicked on outside of element */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDetailsData({
            ...detailsData,
            isOkrDetails: false,
            detailsId: '',
            okrDetails: '',
            detailsIndex: '',
            metrics: []
          });
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const handleSearch = async (e) => {
    let value = e.target.value;
    let okrsArr = await getOkrs(team, time, formData.status);
    okrsArr = okrsArr.newItems;

    setSearch(value);

    let filteredResults = [];

    if (value.length !== 0) {
      // If search input is not empty

      okrsArr.forEach((item, i) => {
        let keyResults = item.keyResults;
        let isUniqueItem = true;

        if (item && item.objective && item.objective.toLowerCase().includes(value.toLowerCase()) === true) {
          isUniqueItem = false;

          return filteredResults.push(item);
        }

        keyResults.forEach((kr, index) => {
          if (kr && kr.title && kr.title.toLowerCase().includes(value.toLowerCase()) === true) {
            isUniqueItem = false;

            return filteredResults.push(item);
          }
        });
      });

      setFormData({
        ...formData,
        objectives: filteredResults
      });

      // Check if we have search results or not
      if (filteredResults.length !== 0) {
        setResults({
          ...results,
          searchResults: false,
          searchKeyword: value // change to highlight search results
        });
      } else {
        // If we don't have search results
        setResults({
          ...results,
          searchResults: true,
          searchKeyword: value
        });
      }
    } else {
      // Empty search input
      setFormData({
        ...formData,
        objectives: okrsArr
      });

      setResults({
        ...results,
        searchResults: false,
        searchKeyword: ''
      });
    }
  };

  const noSearchResults = {
    title: <FormattedMessage id="search.noresults" defaultMessage="No Results Found" />,
    msg: (
      <FormattedMessage
        id="company.checkins.user.msg"
        defaultMessage={`No search results for <b>"${search}"</b>`}
        values={{ b: (...chunks) => <b>{chunks}</b> }}
      />
    ),
    msg2: (
      <FormattedMessage
        id="try.diff.search"
        defaultMessage="Try searching a different keyword."
      />
    ),
  };

  let btnTxt = <FormattedMessage id="save" defaultMessage="Save" />;

  // Loader for button
  let loadingIcon = (
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Loader type="Circles" color="#ffffff" height="14px" width="15" />
    </span>
  );

  if (isLoader) {
    btnTxt = loadingIcon;
  }

  // console.log('===== objectives', objectives)
  // console.log('===== okrs', okrs)
  // console.log('===== localLoading', localLoading)
  // console.log('===== isLoader', isLoader)
  // console.log('===== iteration', iteration)

  return (
    <Fragment>
      <Fragment>
        <div className={`css-main bg-primary`}>
          <div className={`wrapper`}>
            {!okrTree && user && !loading && (
              <MainMenu activeIndex={4} windowDimensions={windowDimensions} />
            )}

            <UserNav />

            <Fragment>
              {loading || !company || !profiles || !user || !okrs ? (
                <Spinner />
              ) : (
                <Fragment>
                  <div
                    className={`main-dashboard mt-3 ${
                      !okrTree ? (sidebar === 1 && windowDimensions && windowDimensions.width > 1100 ? 'wrapper__okrs' : 'short_menu__ml__okrs') : 'ma-unset pr-5 ml-5'
                    }`}
                  >
                    {isOkrDetails && (
                      <OkrDetails
                        okr={okrDetails}
                        handleDetails={handleDetails}
                        detailsId={detailsId}
                        detailsIndex={detailsIndex}
                        user={user}
                        locale={locale}
                        findUserLike={findUserLike}
                        handleLike={handleLike}
                        findComments={findComments}
                        handleStatus={handleStatus}
                        onSubmitUpdate={onSubmitUpdate}
                        okrData={okrData}
                        setOkrData={setOkrData}
                        formData={formData}
                        setFormData={setFormData}
                        updateMode={updateMode}
                        toggleUpdateMode={toggleUpdateMode}
                        deleteUpdate={handleDelete}
                        metrics={metrics}
                        useOutsideAlerter={useOutsideAlerter}
                        isLoader={isLoader}
                        btnCta={btnTxt}
                        ownerOptions={mentions}
                      />
                    )}

                    {user && user.isAdmin && campaigns.length === 0 && allSettings.length === 0 && (
                      <CheckinModal 
                        title={modal.title} 
                        text={modal.text} 
                        btnTxt={modal.btnTxt} 
                        btnLink={modal.btnLink}
                        cancelTxt={modal.cancelTxt}
                        modalBox={modalBox}
                      />
                    )}

                    <div className="okrs">
                      {!okrTree && (
                        <Fragment>
                          <div className="flex align-center">
                            <div className="w-30-xs title pb-0-xs">
                              <h5 className="pl-0-xs fw-600">
                                <FormattedMessage id="you.header.okr" defaultMessage="Objectives" />
                              </h5>
                              <p className="fs-16">
                                <FormattedMessage
                                  id="you.subheader.okr"
                                  defaultMessage="Manage OKRs (Objectives and Key Results)"
                                />
                              </p>
                            </div>
                            <Link to="/objective/new" id='gtag-okr-click' className="btn__secondary_green mla ml-2-xs">
                              <FormattedMessage id="user.okr.sub_btn" defaultMessage="New Objective" />
                            </Link>
                          </div>
                        </Fragment>
                      )}

                      {!okrTree && (
                        <Fragment>
                          <div className="ma-w350-xs flex space-between align-center align-start-xs wrap mt-1 mb-3">
                            <div className="flex space-between align-center w-100-xs">
                              <FormattedMessage
                                id="checkins.sub_menu.teamSelect"
                                defaultMessage="Choose the team or user"
                              >
                                {(msg) => (
                                  <Select
                                    className="w-200px w-150px-1400 small-input filter-180px w-150px-xs z-20000"
                                    defaultValue={choosedTeam}
                                    value={team}
                                    onChange={(selectedOption) => handleTeam(selectedOption)}
                                    options={teamOptionsWithOkrs}
                                    isSearchable
                                    placeholder={msg}
                                    required
                                    name="team"
                                    styles={customStyles}
                                    getOptionValue={(opt) => {
                                      return opt.value + opt.type;
                                    }}
                                  />
                                )}
                              </FormattedMessage>

                              <FormattedMessage
                                id="focus.sub_menu.timeSelect"
                                defaultMessage="Choose the time interval"
                              >
                                {(msg) => (
                                  <Select
                                    className="small-input w-150px ml-1 z-20000"
                                    defaultValue={choosedTime}
                                    value={time}
                                    onChange={(selectedOption) => handleTime(selectedOption)}
                                    options={timeOptions}
                                    placeholder={msg}
                                    required
                                    name="time"
                                    styles={customStyles}
                                  />
                                )}
                              </FormattedMessage>

                              <FormattedMessage
                                id="focus.sub_menu.status"
                                defaultMessage="Choose the OKR status"
                              >
                                {(msg) => (
                                  <Select
                                    className="small-input w-150px ml-1 z-20000"
                                    // defaultValue={formData.status}
                                    value={formData.status}
                                    onChange={handleStatusFilterChange}
                                    options={statusOptions}
                                    placeholder={msg}
                                    required
                                    name="time"
                                    styles={colourStyles}
                                  />
                                )}
                              </FormattedMessage>
                            </div>

                            <div className="flex space-between align-center w-100-xs my-1-xs">
                              {/* <p
                                className="medium mr-1 cursor ml-1 ml-0-xs btn__secondary_blue px-2 lh-34 fw-600 fs-14"
                                onClick={() => toggleArchive(!archive)}
                              >
                                {!archive ? (
                                  <FormattedMessage id="okr.archive" defaultMessage="Archive" />
                                ) : (
                                  <FormattedMessage id="okr.live" defaultMessage="OKRs" />
                                )}
                              </p> */}

                              <Link
                                to="/objectives/view/tree"
                                className="btn__secondary_blue px-2 lh-34 fw-600 mra mr-0-xs fs-14"
                              >
                                <FormattedMessage id="okr.view.tree" defaultMessage="OKR Tree" />
                              </Link>
                            </div>

                            <div className="flex space-between align-center w-100-xs">
                              <FormattedMessage id="search.bar" defaultMessage="Search...">
                                {(msg) => (
                                  <input
                                    // className="w-20-l-50-xs lh-34 question-input pl-2 br-4 brd brd-grey bg__white outline"
                                    className="w-130px-1400 lh-34 question-input pl-2 br-4 brd brd-grey bg__white outline"
                                    type="text"
                                    placeholder={msg}
                                    name="search"
                                    value={search}
                                    onChange={(e) => handleSearch(e)}
                                  />
                                )}
                              </FormattedMessage>

                              {objectives && objectives.length > 0 && (
                                <div className="export relative">
                                  <div className="ml-1">
                                    <span
                                      onClick={() => toggleExportBtn(!exportBtn)}
                                      className="cursor icon__grey_hover_blue fs-22 tooltip"
                                    >
                                      {icons.share}
                                      <span className="tooltiptext">Export</span>
                                    </span>
                                  </div>
                                  {exportBtn && (
                                    <div className="w-100px z-10000 light-shadow absolute brd brd-grey__light br-4 dropdown-25 right bg__white">
                                      <CSVLink
                                        data={generateCsv(objectives)}
                                        target="_blank"
                                        filename={`Focus_OKRs_${team.label}_${time.label}.csv`}
                                        className="w-100 flex align-center justify-center icon__box fs-14 icon__grey no_btn px-1 lh-30"
                                        onClick={() => toggleExportBtn(!exportBtn)}
                                      >
                                        CSV
                                      </CSVLink>
                                      <div className="w-100 icon__box fs-14 icon__grey no_btn px-1 lh-30 cursor">
                                        <PDFDownloadLink
                                          document={<PDF data={objectives} />}
                                          fileName={`Focus_OKRs_${team.label}_${time.label}`}
                                        >
                                          <button className="w-100 icon__box fs-14 icon__grey no_btn px-1 lh-30">
                                            PDF
                                          </button>
                                        </PDFDownloadLink>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}

                              <div className="relative">
                                <div className="ml-1">
                                  <span
                                    onClick={() => toggleAllKeyResults(!showAllKeyResults)}
                                    className="cursor icon__grey_hover_blue fs-22 tooltip"
                                  >
                                    {!showAllKeyResults ? icons.expand : icons.collapse}
                                    <span className="tooltiptext">
                                      {!showAllKeyResults ? <FormattedMessage id="show.all.krs" defaultMessage="Expand Objectives" /> : <FormattedMessage
                                        id="hide.all.krs"
                                        defaultMessage="Collapse Objectives"
                                      />}
                                    </span>
                                  </span>
                                </div>
                              </div>
                              
                            </div>
                          </div>

                          {!archive && (
                            <div className="my-4 ma-w350-xs">
                              {objectives.length > 0 &&
                                objectives.map((okr) =>
                                  (okr && team.type === 1 && okr.isMain && okr.type === 1) ||
                                  (okr && team.type === 4 && okr.isMain && okr.type === 1) ? (
                                    <MainWidget key={`main-${okr._id}`} okr={okr} />
                                  ) : okr && team.type === 2 && okr.isMain && okr.type === 2 ? (
                                    <MainWidget key={`main-${okr._id}`} okr={okr} />
                                  ) : (
                                    okr &&
                                    team.type === 3 &&
                                    okr.isMain &&
                                    okr.type === 3 && <MainWidget key={`main-${okr._id}`} okr={okr} />
                                  )
                                )}
                            </div>
                          )}
                        </Fragment>
                      )}

                      {archive && !okrTree && (
                        <div className="bg-dark px-2 py-1 br-8">
                          <p className="small white text-left">
                            <FormattedMessage id="archive" defaultMessage="It is the archive of Objectives" />
                          </p>
                        </div>
                      )}

                      {/* {(company.plan === 1 && profiles.length > 1) || company.plan === 2 ? ( */}
                      {(company.plan === 1) ? (
                        <NoContent
                          message={updatePlanMsg}
                          picture={updateImg}
                          alt={'Upgrade Plan'}
                          imgWidth={'w-100'}
                          divStyles='flex-column flex-'
                          admin={
                            user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin'
                              ? 1
                              : 0
                          }
                        />
                      ) : (
                        <Fragment>
                          {okrs.length === 0 ? (
                            <Fragment>
                              <div className="w-90 mxa">
                                {/* <Notification
                                  msg={okrsCopy.msg}
                                  cta={okrsCopy.cta}
                                  btnColor={'orange'}
                                  color={`orange`}
                                  url={`/objective/new`}
                                /> */}

                                <Fragment>
                                  {localLoading ? (
                                    <div className="my-2 text-center">
                                      <Loader type="Circles" color="#99a2ff" height="20" width="20" />
                                    </div>
                                  ) : (
                                    <NoContent
                                      message={noOkrMessageTeam}
                                      picture={updateImg}
                                      alt={'Create first OKR'}
                                      imgWidth={'w-100 z-1000 -mt-okr__empty'}
                                      button={1}
                                      btnUrl={noOkrMessageTeam.btnUrl}
                                      btnCta={noOkrMessageTeam.btnCta}
                                      divStyles="flex-column-reverse"
                                    />
                                  )}
                                </Fragment>
                              </div>
                            </Fragment>
                          ) : (
                            <Fragment>
                              {!archive ? (
                                !okrTree ? (
                                  objectives.length > 0 ? (
                                    <div className="">
                                      {localLoading ? (
                                        <div className="my-2 text-center">
                                          <Loader type="Circles" color="#99a2ff" height="20" width="20" />
                                        </div>
                                      ) : (
                                        <Fragment>
                                          {objectives.map((okr, index) => (
                                            <Fragment key={`okr-${okr._id}-${index}`}>
                                              {okr.isChild && (
                                                <div className="fs-10 grey_new mt-1 ml-5 pl-5">
                                                  <FormattedMessage id="parent.okr" defaultMessage="Parent OKR" />
                                                  : <span className="bold mb-0">{okr.parentOkr.label}</span>
                                                </div>
                                              )}
                                              <div
                                                className={`cards py-1 pt-5-xs ${okr.isChild ? 'ml-3' : ''}`}
                                                key={`okr-item-${okr._id}`}
                                              >
                                                <OkrCardItem
                                                  okr={okr}
                                                  okrBoard={
                                                    (team.type === 1 && okr.type === 1) ||
                                                    (team.type === 4 && okr.type === 1)
                                                      ? '1'
                                                      : team.type === 2 && okr.type === 2
                                                      ? '2'
                                                      : team.type === 3 && okr.type === 3 && '3'
                                                  }
                                                  formDataParent={formData}
                                                  setFormDataParent={setFormData}
                                                  archive={archive}
                                                  archiveOKR={archiveOKR}
                                                  handleArchive={handleArchive}
                                                  todos={
                                                    todos &&
                                                    todos.length > 0 &&
                                                    todos.filter((todo) => todo.OKR && todo.OKR.okrId === okr._id)
                                                  }
                                                  usersOptions={usersOptions}
                                                  okrOptions={okrOptions}
                                                  priorityOptions={priorityOptions}
                                                  handleSelect={handleSelect}
                                                  handleDueIn={handleDueIn}
                                                  clearTodo={clearTodo}
                                                  handleDeleteOkr={handleDeleteOkr}
                                                  handleDeleteTask={handleDeleteTask}
                                                  handleComplete={handleComplete}
                                                  handleNameForm={handleNameForm}
                                                  listView={false}
                                                  todoBoard={10}
                                                  user={user}
                                                  handleDetails={handleDetails}
                                                  showAllKeyResults={showAllKeyResults}
                                                  toggleAllKeyResults={toggleAllKeyResults}
                                                  profiles={mentions}
                                                  ownerOptions={mentions}
                                                  locale={locale}
                                                  lang={locale && locale.lang}
                                                  isAvatarNoLink={true}
                                                  searchKeyword={searchKeyword}
                                                  okrIndex={index}
                                                />
                                              </div>
                                            </Fragment>
                                          ))}
                                        </Fragment>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="mt-5">
                                      {search.length === 0 ? (
                                          <NoContent
                                            message={noOkrMessageInFilters}
                                            picture={updateImg}
                                            alt={'Create first OKR'}
                                            imgWidth={'w-100 z-1000 -mt-okr__empty_50 pt-4'}
                                            divStyles="flex-column-reverse"
                                          />
                                      ): (
                                        <Fragment>
                                          <div className="flex align-center">
                                            <img src={searchImg} className="mr-2 ml-2 transform-50" width="47" alt={"icon"} />
                                            <p className="fs-25 fw-600">{noSearchResults.msg}</p>
                                          </div>
                                          <p className="fs-16 ml-5 pl-4">{noSearchResults.msg2}</p>
                                        </Fragment>
                                      )}
                                    </div>
                                  )
                                ) : (
                                  <OkrTree
                                    okrs={tree && tree}
                                    okrTree={okrTree}
                                    toggleOkrTree={toggleOkrTree}
                                    handleArchive={handleArchive}
                                    handleDelete={handleDeleteOkr}
                                    archive={archive}
                                    user={user}
                                  />
                                )
                              ) : !okrTree ? (
                                archivedObjectives.length > 0 ? (
                                  archivedObjectives.map((okr) => (
                                    <div
                                      className="component my-2 py-3 px-1 light-shadow"
                                      key={`okr-item-${okr._id}`}
                                    >
                                      <div className="cards py-2">
                                        <OkrCardItem
                                          key={okr._id}
                                          okr={okr}
                                          okrBoard={
                                            (team.type === 1 && okr.type === 1) ||
                                            (team.type === 4 && okr.type === 1)
                                              ? '1'
                                              : team.type === 2 && okr.type === 2
                                              ? '2'
                                              : team.type === 3 && okr.type === 3 && '3'
                                          }
                                          formDataParent={formData}
                                          setFormDataParent={setFormData}
                                          archive={archive}
                                          toggleArchive={toggleArchive}
                                          archiveOKR={archiveOKR}
                                          handleArchive={handleArchive}
                                          todos={
                                            todos &&
                                            todos.length > 0 &&
                                            todos.filter((todo) => todo.OKR && todo.OKR.okrId === okr._id)
                                          }
                                          usersOptions={usersOptions}
                                          okrOptions={okrOptions}
                                          priorityOptions={priorityOptions}
                                          handleSelect={handleSelect}
                                          handleDueIn={handleDueIn}
                                          clearTodo={clearTodo}
                                          handleDeleteOkr={handleDeleteOkr}
                                          handleDeleteTask={handleDeleteTask}
                                          handleComplete={handleComplete}
                                          handleNameForm={handleNameForm}
                                          listView={false}
                                          todoBoard={10}
                                          user={user}
                                          handleDetails={handleDetails}
                                          showAllKeyResults={showAllKeyResults}
                                          toggleAllKeyResults={toggleAllKeyResults}
                                          profiles={mentions}
                                          ownerOptions={mentions}
                                          locale={locale}
                                          lang={locale && locale.lang}
                                          searchKeyword={searchKeyword}
                                        />
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div className="mt-5">
                                    <NoContent
                                      message={noArchivedOkrMessageTeam}
                                      picture={updateImg}
                                      alt={'No OKRs'}
                                      imgWidth={'w-100'}
                                      divStyles="flex-column-reverse"
                                    />
                                  </div>
                                )
                              ) : (
                                <OkrTree
                                  okrs={tree && tree}
                                  okrTree={okrTree}
                                  toggleOkrTree={toggleOkrTree}
                                  handleArchive={handleArchive}
                                  handleDelete={handleDeleteOkr}
                                  archive={archive}
                                  user={user}
                                />
                              )}
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </Fragment>
              )}
            </Fragment>
          </div>
        </div>
      </Fragment>
    </Fragment>
  );
};

OkrCompany.propTypes = {
  cleanData: PropTypes.func.isRequired,
  clearOkrs: PropTypes.func.isRequired,
  getCompanySortedOkrs: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  archiveOKR: PropTypes.func,
  getCompanyProfiles: PropTypes.func.isRequired,
  getCompanyTasks: PropTypes.func.isRequired,
  getCompanyTreeOkrs: PropTypes.func.isRequired,
  updateTodo: PropTypes.func.isRequired,
  completeTodo: PropTypes.func.isRequired,
  deleteTodo: PropTypes.func.isRequired,
  deleteOkr: PropTypes.func.isRequired,
  updateLike: PropTypes.func.isRequired,
  updateOkr: PropTypes.func.isRequired,
  getOkrByIdUrl: PropTypes.func.isRequired,
  getAllMetrics: PropTypes.func.isRequired,
  getAllCustoms: PropTypes.func.isRequired,
  getAllSettings: PropTypes.func.isRequired,
  changeOkrFilters: PropTypes.func.isRequired,
  clearOkr: PropTypes.func.isRequired,
  exportToCsv: PropTypes.func,
  deleteUpdate: PropTypes.func,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  todo: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired,
  custom: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  locale: state.locale,
  company: state.company,
  profile: state.profile,
  todo: state.todo,
  okr: state.okr,
  okrFilters: state.okr.filters,
  custom: state.custom,
});

export default connect(mapStateToProps, {
  getUserCompany,
  getCompanyProfiles,
  getCompanyOkrs,
  archiveOKR,
  deleteOkr,
  getCompanySortedOkrs,
  clearOkrs,
  cleanData,
  getCompanyTasks,
  updateTodo,
  completeTodo,
  deleteTodo,
  setAlert,
  getCompanyTreeOkrs,
  updateLike,
  updateOkr,
  deleteUpdate,
  getAllMetrics,
  getOkrByIdUrl,
  clearOkr,
  changeOkrFilters,
  getAllCustoms,
  getAllSettings,
})(OkrCompany);
