import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import QuestionItemAnswer from "./QuestionItemAnswerNew";

const QuestionItem = ({ question, answer, _id, index, searchKeyword }) => {
  
  return (
    <Fragment>
      <p className="medium fw-600">{question.text}</p>
      <div className="card-answer">
        <ul className="answer-item">
            {answer.text.map((text, i) => (
              <QuestionItemAnswer
                key={`${text._id}-${i}`}
                index={index}
                text={text}
                searchKeyword={searchKeyword}
              />
            ))}
        </ul>
      </div>
    </Fragment>
  );
};

QuestionItem.propTypes = {
  question: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired
};

export default QuestionItem;
