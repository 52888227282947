import React, { Fragment, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import Navbar from '../layout/Navbar';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ConfirmationError = ({ isAuthenticated, locale: { lang } }) => {

  // Redirect if signed up
  if (isAuthenticated) {
    return <Redirect to="/dashboard/objectives" />;
  }

  return (
    <Fragment>
      <section id="signup">
        <div className="container pt-5">
          <div className="section_header_new mb-2">
            <Navbar guest={true} />
            <h2 className="h2_new">
              <FormattedMessage
                id="auth.confirmation.error.title"
                defaultMessage="Something went wrong"
              />
            </h2>
            <p className="lead lead_new lh-22 mb-2">
              <FormattedMessage
                id="auth.confirmation.error.text1"
                defaultMessage="Your account wasn't verified."
              />
            </p>
            <p className="lead lead_new lh-22 mb-2">
              <FormattedMessage
                id="auth.confirmation.error.text2"
                defaultMessage="Please request a new confirmation email to confirm your account."
              />
            </p>
            <p className="lead lead_new lh-22 mb-2">
              <FormattedMessage
                id="auth.confirmation.error.text3"
                defaultMessage={`If it will not work, contact us via live chat or email us at.`}
              />{" "}{process.env.REACT_APP_EMAIL}
            </p>

            <div className="block text-center">
              <Link to="/resend" className="btn btn_new btn-primary my-2">
                <FormattedMessage id="auth.resend.btn.main" defaultMessage="Resend email" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

ConfirmationError.propTypes = {
  setAlert: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  locale: state.locale,
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(
  mapStateToProps,
  { setAlert }
)(ConfirmationError);
