import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import QuestionItemAnswer from "./UniQuestionItemAnswer";

const UniQuestionItem = ({ question, answer, index, searchKeyword }) => {
  // console.log('===== UniQuestionItem: answer', answer)
  let value = answer.text && answer.text.length > 0 ? answer.text : answer.okrAnswers[0].text
 
  // console.log('===== UniQuestionItem: value', value)
  // console.log('===== UniQuestionItem: question', question)

  let q = question && question.text ? question.text : question;

  return (
    <Fragment>
      <p className="medium fw-600">{q}</p>
      <div className="card-answer">
        <ul className="answer-item">
            {value && value.length > 0 && value.map((text, i) => (
              <QuestionItemAnswer
                key={`${text._id}-${i}`}
                index={index}
                text={text}
                searchKeyword={searchKeyword}
                question={q}
              />
            ))}
        </ul>
      </div>
    </Fragment>
  );
};

UniQuestionItem.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.object.isRequired,
  avatar: PropTypes.string.isRequired
};

export default UniQuestionItem;