import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { icons } from '../../../img/elements/icons';
import TeamsCardBody from './TeamsCardBody';

const TeamsCard = ({ department, users, auth, deleteTeam }) => {
  const [displayCardBody, toggleButton] = useState(false);
  const [showIcons, toggleShowIcons] = useState(false);

  let deleteCopy = `Are you sure you wish to delete ${department.name}? Team will be lost forever.`

  return (
    <>
      <tr>
        <td className={`text-left medium py-1`}>
          <Link to={department && `/team/${department._id}`}>
            <p className="hover-black">
              {department.name}
            </p>
          </Link>
        </td>

        <td className={`text-left py-1`}>
          <div className='flex'>

            {department.headAvatar ? (
              <img
                src={
                  department.headAvatar !== null ? department.headAvatar : null
                }
                alt="head"
                className="round-img d-block avatar mr-1"
                width="26px"
                height="26px"
              />
            ) : null}

            <div className="flex column">
              <Link to={'/'} className="user">
                <p className={`medium ${department.headFirstName && department.headLastName ? 'hover-underline' : 'cursor-default'}`}>
                  {department.headFirstName && department.headLastName
                    ? `${department.headFirstName} ${department.headLastName}`
                    : (
                      <FormattedMessage
                        id="no.head"
                        defaultMessage="No Lead"
                      />
                    )}
                </p>
              </Link>
            </div>
          </div>
        </td>

        <td className={`text-right py-1`}>
          <div className='flex justify-end align-center'>
            <div className='medium'>
              {users.length > 0 ? users.length : '0'}
            </div>
            <div className="card-icons">
              <button
                onClick={() => toggleButton(!displayCardBody)}
                className="btn-action no_btn"
                type="button"
              >
                {displayCardBody ? icons.angle_up : icons.angle_down}
              </button>
            </div>
          </div>
        </td>
        
        {auth && auth.isAdmin && (
          <td className={`w-30px`}>
            <div className="flex align-center br-4 brd brd-grey__light bg__white relative">
              <div className={`no_btn relative h-34 px-1 icon__box ${showIcons ? 'icon__box_active' : ''}`} onClick={() => toggleShowIcons(!showIcons)}>
                <span className="w-50px lh-28">
                  {icons.dots}
                </span>
              </div>
              {showIcons && (
                <div className="w-100px z-20000 light-shadow absolute brd brd-grey__light br-4 dropdown-40 right bg__white">
                  <button 
                    className="w-100 icon__box fs-14 icon__grey no_btn px-1 lh-30"
                  >
                    <Link 
                      className='fs-14 icon__grey'
                      to={`/settings/edit-team/${department._id}`}
                    >
                      {icons.edit_small}
                      {' '}
                      <FormattedMessage
                        id="edit"
                        defaultMessage="Edit"
                      />
                    </Link>
                  </button>
                  <button 
                    type="button"
                    // onClick={() => { if (window.confirm(deleteCopy)) handleDelete(_id, isTree) } }
                    onClick={() => { if (window.confirm(deleteCopy)) deleteTeam(department._id) } }
                    className="w-100 icon__box fs-14 icon__grey no_btn px-1 lh-30"
                  >
                    {icons.trash_small}
                    {' '}
                    <FormattedMessage
                      id="delete"
                      defaultMessage="Delete"
                    />
                  </button>
                </div>
                )}
            </div>
          </td>
        )}
      </tr>
      {displayCardBody && (
        <TeamsCardBody
          department={department}
          employees={users}
          _id={department._id}
        />
      )}
    </>
  );
};

TeamsCard.propTypes = {
  users: PropTypes.array.isRequired,
  department: PropTypes.object.isRequired
};

export default TeamsCard;
