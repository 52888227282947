import axios from 'axios';
import { setAlert } from './alert';
import store from '../store';
import { updateHomeLikeAnswer, addHomeCommentAnswer, deleteHomeCommentAnswer } from './home';

import {
  GET_USER_PLAN_ANSWERS,
  GET_PROFILE_PLAN_ANSWERS,
  PLAN_ANSWER_ERROR,
  PLAN_ANSWERS_ERROR,
  ADD_PLAN_ANSWER,
  EDIT_PLAN_ANSWER,
  GET_PLAN_ANSWER,
  PLAN_LIKES,
  ADD_COMMENT,
  UPDATE_COMMENT,
  CLEAR_PLAN_ANSWERS,
  GET_COMPANY_PLAN_ANSWERS,
  COMPANY_PLAN_ANSWER_ERROR,
  REMOVE_COMMENT,
  LOCATION_CHANGE_ERROR_PLAN,
  LOADING_PLANS,
} from './types';

// // Get all current user answers
export const getUserPlanAnswers = () => async dispatch => {
  try {
    const res = await axios.get('/api/plananswers/user');
    
    dispatch({
      type: GET_USER_PLAN_ANSWERS,
      payload: { answers: res.data.userAnswers, last: res.data.lastAnswer }
    });
  } catch (err) {
    dispatch({
      type: PLAN_ANSWERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get the last answer from current user 
export const getUserPlanAnswer = () => async dispatch => {
  try {
    const res = await axios.get('/api/plananswers/last');

    dispatch({
      type: GET_PLAN_ANSWER,
      payload: res.data
    });
  } catch (err) {
     console.log('===== err in last', err);

    dispatch({
      type: PLAN_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all company answers
export const getCompanyPlanAnswers = ({limit,user}={}) => async dispatch => {
  try {
    const params = {};
    if(limit) params.limit = limit;
    if(user)params.user = user;
    const res = await axios.get('/api/plananswers/company',{params});

    dispatch({
      type: GET_COMPANY_PLAN_ANSWERS,
      payload: { company: res.data.companyAnswers, user: res.data.userAnswer }
    });
    return true;
  } catch (err) {
    dispatch({
      type: COMPANY_PLAN_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    return false;
  }
};

// // Get user answers by user ID
export const getPlanUserAnswersById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/plananswers/user/${id}`);

    dispatch({
      type: GET_PROFILE_PLAN_ANSWERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PLAN_ANSWERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit ALL answer
export const editAllPlanAnswers = (
  planAnswer,
  planID,
  answers,
  focus,
  weeklyOkrs,
  history
) => async dispatch => {
  
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({ answers, focus, weeklyOkrs });

    const res = await axios.put(
      `/api/plananswers/allanswers/${planAnswer._id}`,
      body,
      config
    );
    
    dispatch({
      type: EDIT_PLAN_ANSWER,
      payload: {
        planID,
        answers: res.answers,
        updatedAt: res.updatedAt,
      }
    });

    dispatch(setAlert('Weekly Plan was updated', 'success', 3000));

    if (history) {
      history.goBack();
    } else {
      history.push('/plans')
    }

  } catch (err) {
    dispatch({
      type: PLAN_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add answer on plan
export const addPlanAnswer = (
  answers,
  focus,
  planID,
  okrQuestion,
  notes,
  planAnswer,
  weeklyOkrs,
  history,
) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    
    // if user has alread created the plan, then update (PUT)
    if (planID !== null) {
      dispatch(editAllPlanAnswers(planAnswer, planID, answers, focus, weeklyOkrs, history));
    
      return;
    }

    const body = JSON.stringify({ answers, focus, notes, planID, okrQuestion, weeklyOkrs, });
    
    const res = await axios.post(
      `/api/plananswers/create`,
      body,
      config
    );

    dispatch({
      type: ADD_PLAN_ANSWER,
      payload: res.data
    });

    dispatch(setAlert('Weekly Plan was completed', 'success', 3000));
    
    if (history) {
      history.goBack();
    } else {
      history.push('/campaigns/plans')
    }

  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PLAN_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};



// Edit 1 answer
export const editOnePlanAnswer = (
  planID,
  answerID,
  text
) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({ text });
    const res = await axios.put(
      `/api/plananswers/answer/${planID}/${answerID}`,
      body,
      config
    );

    dispatch({
      type: EDIT_PLAN_ANSWER,
      payload: {
        planID,
        answerID,
        answers: res.answers,
        updatedAt: res.updatedAt,
      }
    });

     // Callback for updating in formData.plans in useState to render it
    const data = { planID, answerID, answers: res.data }
    return data;

  } catch (err) {
    dispatch({
      type: PLAN_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
  };


// Add & Remove like
export const updatePlanLike = (planID, answerID) => async dispatch => {
  try {
    const res = await axios.put(
      `/api/plananswers/like/${planID}/${answerID}`
    );
    
    dispatch({
      type: PLAN_LIKES,
      payload: { planID, answerID, likes: res.data.likes, answers: res.data.answers }
    });

    const home = store.getState().home;

    // Обновляем редюсер Home, если он не пустой
    // https://stackoverflow.com/questions/35667249/accessing-redux-state-in-an-action-creator
    // https://redux.js.org/api/store#dispatchaction
    if (home.data.length > 0) {
      store.dispatch(updateHomeLikeAnswer({ id: planID, likes: res.data.likes, answers: res.data.answers }))
    }

    // Callback for updating in formData.plans in useState to render it
    const data = { planID, likes: res.data.likes, answers: res.data.answers }
    // console.log('===== data in acitons', data)   
    return data;
  } catch (err) {
    dispatch({
      type: PLAN_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add comment
export const addPlanComment = (
  planID,
  answerID,
  formData
) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {

    const body = JSON.stringify({ text: formData });
    const res = await axios.post(
      `/api/plananswers/comment/${planID}/${answerID}`,
      body,
      config
    );

    dispatch({
      type: ADD_COMMENT,
      payload: {
        planID,
        answerID,
        comments: res.data.comments,
        answers: res.data.answers
      }
    });

    const home = store.getState().home;

    // Обновляем редюсер Home, если он не пустой
    if (home.data.length > 0) {
      store.dispatch(addHomeCommentAnswer({ id: planID, comments: res.data.comments, answers: res.data.answers }))
    }
  
    // Callback for updating in formData.plans in useState to render it
    const data = { planID, comments: res.data.comments, answers: res.data.answers }
    return data;
  } catch (err) {
    console.log('=====err', err);
    dispatch({
      type: PLAN_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit comment
export const editPlanComment = (
  planID,
  commentID,
  formData
) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const res = await axios.put(
      `/api/plananswers/comment/${planID}/${commentID}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_COMMENT,
      payload: {
        planID,
        comments: res.data
      }
    });
  } catch (err) {
    dispatch({
      type: PLAN_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete comment
export const deletePlanComment = (
  planID,
  answerID,
  commentID
) => async dispatch => {
  try {
    const res = await axios.delete(
      `/api/plananswers/comment/${planID}/${answerID}/${commentID}`
    );

    dispatch({
      type: REMOVE_COMMENT,
      payload: {
        planID,
        commentID,
        answers: res.data.answers
      }
    });

    const home = store.getState().home;

    // Обновляем редюсер Home, если он не пустой
    if (home.data.length > 0) {
      store.dispatch(deleteHomeCommentAnswer({ id: planID, commentID, answers: res.data.answers }))
    }

    // Callback for updating in formData.updates in useState to render it
    const data = { planID, commentID, answers: res.data.answers }
    return data;
  } catch (err) {
    dispatch({
      type: PLAN_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Clear state
export const clearPlans = () => async dispatch => {
  try {
   
    dispatch({
      type: CLEAR_PLAN_ANSWERS
    });
  } catch (err) {
    dispatch({
      type: LOCATION_CHANGE_ERROR_PLAN,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Clear state
export const loadingPlans = () => async dispatch => {
  try {
   
    dispatch({
      type: LOADING_PLANS
    });
  } catch (err) {
    dispatch({
      type: LOCATION_CHANGE_ERROR_PLAN,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};