import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Logo from '../../../elems/Logo';
import PropTypes from 'prop-types';
import { login } from '../../../../actions/auth';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../../layout/Spinner';
import Text from '../../elems/Text';
import Skip from '../../elems/Skip';
import { createInvite } from '../../../../actions/auth';
import { icons } from '../../../../img/elements/icons';
import { getAllCustoms } from '../../../../actions/custom';

const Onboarding6 = ({ 
  user, 
  getAllCustoms,
  custom: { campaigns },
}) => {
  
  useEffect(() => {
    getAllCustoms();
  }, [getAllCustoms]);

  let title = <FormattedMessage id="welcome.checkin.title6" defaultMessage="You've created your first Check-in" />
  let text = <FormattedMessage id="welcome.checkin.msg6" defaultMessage={`Your first check-in has been set up, you can try it out yourself! Should you wish to edit the check-in later you can do so on "Company Settings" page.`} />
  let completeMsg = <FormattedMessage id="complete.checkin" defaultMessage="Complete Check-in" />
  let continueMsg = <FormattedMessage id="continue.to.app" defaultMessage="Continue to the App" />
  
  let checkinUrl = campaigns && campaigns.length > 0 ? `/campaign/create/${campaigns[campaigns.length-1]._id}` : '/campaigns/all-checkins'
  
  // console.log('===== campaigns', campaigns)

  return (
    <Fragment>
      {!user ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="css-main onboarding bg__white">
            <div className="wrapper">
              <div className="flex">
                <div className="onboarding__content relative">
                  <div className="">
                    <div className="pb-8">
                      <Logo isNoLink={true} />
                    </div>
                    <Text title={title} text={text} page={6} />

                    <div className="flex align-center space-between mt-3 onboarding__invite__form">
                      <Link to={checkinUrl} className="onboarding__invite__btn text-center btn btn-form btn_primary_new h-54 lh-20 mt-0">
                        {completeMsg}
                      </Link>
                      <Link to="/campaigns/all-checkins">
                        <span className="fs-14 flex align-center cursor icon__grey">
                          <span className="mr-xs">{continueMsg}</span>
                          <span className="flex align-center">{icons.arrow_right}</span>
                        </span>
                      </Link>
                    </div>
                  </div>
                  
                  <Skip/>
                </div>
                <div className="onboarding__image onboarding__image__checkin__complete"></div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

Onboarding6.propTypes = {
  getAllCustoms: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.user,
  locale: state.locale,
  custom: state.custom,
});

export default connect(
  mapStateToProps,
  { login, createInvite, getAllCustoms }
)(Onboarding6);
