import React from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";
import { FormattedMessage } from "react-intl";

const OkrDueIn = ({ dueIn }) => {
  return (
    <span className="fs-10">
      <FormattedMessage id="user.okr.due" defaultMessage="Due" />:{" "}
      {dueIn ? (<Moment format="D MMM">{dueIn}</Moment>) : '–'}
    </span>
  );
};

OkrDueIn.propTypes = {
  dueIn: PropTypes.string.isRequired
};

export default OkrDueIn;
