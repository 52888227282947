import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Notification = ({ 
  color, msg, msg2, url, 
  btnColor, cta, externalLink, 
  width, height, styles 
}) => {

  return (
    <div className={`${styles} px-4 py-2 notification br-4 shadow_new__blue bg-${color}`}>
      <p className={`white fs-22 fw-600 text-left mxa`}>
        {msg}{' '}
        {msg2 && (
          <Fragment>
            <br />
            <br /> {msg2}
          </Fragment>
        )}
      </p>
       {cta && (
         externalLink !== 1 ? (
            <Link
              to={url && url}
              className={
                btnColor
                  ? `btn-secondary btn-secondary-mix-white btn-secondary__blue-hover ${btnColor} mt-2`
                  : 'btn-secondary btn-secondary-mix-white btn-secondary__blue-hover mt-2 btn-invite-track'
              }
            >
              {cta}
            </Link>) : (
              <a 
                href={url && url} 
                className={
                  btnColor
                    ? `btn-secondary btn-secondary-mix-white btn-secondary__blue-hover ${btnColor} mt-2`
                    : 'btn-secondary btn-secondary-mix-white btn-secondary__blue-hover mt-2 btn-invite-track'
                }
                rel="noopener noreferrer" 
                target="_blank">
                  {cta}
              </a>
            )
      )}
    </div>
  );
};

Notification.propTypes = {
  msg: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  cta: PropTypes.object,
  btnColor: PropTypes.string,
  msg2: PropTypes.object,
  url: PropTypes.string,
  externalLink: PropTypes.number
};

export default Notification;
