const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getOrdinalDay = (index) => {
    let suffix;
    if (index == 1) {
      suffix = 'st';
    } else if (index == 2) {
      suffix = 'nd';
    } else if (index == 3) {
      suffix = 'rd';
    } else {
      suffix = 'th';
    }

    return `${index}${suffix}`;
  };

  export const getMonthName = (index) => {
    return months[index  - 1] || '';
  };