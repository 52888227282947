import axios from 'axios';
import { setAlert } from './alert';
import store from '../store';
import { updateHomeLike, addHomeComment, deleteHomeComment } from './home';


import {
  GET_USER_RECOGNITIONS,
  GET_GIVED_USER_RECOGNITIONS,
  GET_TEAM_RECOGNITIONS,
  GET_GIVED_TEAM_RECOGNITIONS,
  RECOGNITION_ERROR,
  RECOGNITIONS_ERROR,
  GIVED_USER_RECOGNITIONS_ERROR,
  GIVED_TEAM_RECOGNITIONS_ERROR,
  ADD_RECOGNITION,
  GET_RECOGNITION,
  UPDATE_RECOGNITION,
  DELETE_RECOGNITION,
  UPDATE_LIKES,
  ADD_COMMENT,
  UPDATE_COMMENT,
  GET_COMPANY_RECOGNITIONS,
  COMPANY_RECOGNITIONS_ERROR,
  TEAM_RECOGNITIONS_ERROR,
  REMOVE_COMMENT,
  CLEAR_RECOGNITIONS,
  LOCATION_CHANGE_ERROR
} from './types';

// Get one recognition by id
export const getRecognitionById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/recognition/item/${id}`);

    dispatch({
      type: GET_RECOGNITION,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: RECOGNITION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all user recognitions by user id (as recipient)
export const getUserRecognitionsById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/recognition/user/${id}`);

    dispatch({
      type: GET_USER_RECOGNITIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: RECOGNITIONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all user gived recognitions by user id (as giver)
export const getGivedUserRecognitionsById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/recognition/giver/user/${id}`);

    dispatch({
      type: GET_GIVED_USER_RECOGNITIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GIVED_USER_RECOGNITIONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all current user recognitions (as recipient)
export const getUserRecognitions = () => async dispatch => {
  try {
    const res = await axios.get('/api/recognition/user');

    dispatch({
      type: GET_USER_RECOGNITIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: RECOGNITIONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all current user gived recognitions (as giver)
export const getGivedUserRecognitions = () => async dispatch => {
  try {
    const res = await axios.get('/api/recognition/giver/user');

    dispatch({
      type: GET_GIVED_USER_RECOGNITIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GIVED_USER_RECOGNITIONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all current user team recognitions
export const getTeamRecognitions = () => async dispatch => {
  try {
    const res = await axios.get('/api/recognition/team');

    dispatch({
      type: GET_TEAM_RECOGNITIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TEAM_RECOGNITIONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all current user team recognitions (as giver)
export const getGivedTeamRecognitions = () => async dispatch => {
  try {
    const res = await axios.get('/api/recognition/giver/team');

    dispatch({
      type: GET_GIVED_TEAM_RECOGNITIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GIVED_TEAM_RECOGNITIONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all team recognitions by ID
export const getTeamRecognitionsByID = id => async dispatch => {
  try {
  
    const res = await axios.get(`/api/recognition/team/${id}`);
    
    dispatch({
      type: GET_TEAM_RECOGNITIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TEAM_RECOGNITIONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all team recognitions by ID (as giver)
export const getGivedTeamRecognitionsByID = id => async dispatch => {
  try {
    const res = await axios.get(`/api/recognition/giver/team/${id}`);

    dispatch({
      type: GET_GIVED_TEAM_RECOGNITIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GIVED_TEAM_RECOGNITIONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all company recognitions
export const getCompanyRecognitions = () => async dispatch => {
  try {
    const res = await axios.get('/api/recognition/company');

    dispatch({
      type: GET_COMPANY_RECOGNITIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: COMPANY_RECOGNITIONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Create recognition
export const addRecognition = (
  text,
  value,
  recipient,
  history = false
) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({
      text,
      value,
      recipient
    });

    const res = await axios.post(`/api/recognition/new`, body, config);

    dispatch({
      type: ADD_RECOGNITION,
      payload: res.data
    });

    dispatch(setAlert('Recognition Completed', 'success', 2000));
    
    if (history) {
      history.goBack();
    }
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: RECOGNITION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit recognition
export const editRecognition = (id, text) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({ text });

    const res = await axios.put(`/api/recognition/${id}`, body, config);

    dispatch({
      type: UPDATE_RECOGNITION,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: RECOGNITION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete recognition
export const deleteRecognition = id => async dispatch => {
  try {
    await axios.delete(`/api/recognition/comment/${id}`);

    dispatch({
      type: DELETE_RECOGNITION,
      payload: id
    });

    dispatch(setAlert('Recognition Removed', 'success', 2000));
  } catch (err) {
    dispatch({
      type: RECOGNITION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add & Remove like
export const updateRecognitionLike = id => async dispatch => {
  try {
    const res = await axios.put(`/api/recognition/like/${id}`);
   
    dispatch({
      type: UPDATE_LIKES,
      payload: { id, likes: res.data }
    });

    const home = store.getState().home;
    // Обновляем редюсер Home, если он не пустой
    // https://stackoverflow.com/questions/35667249/accessing-redux-state-in-an-action-creator
    // https://redux.js.org/api/store#dispatchaction
    if (home.data.length > 0) {
      store.dispatch(updateHomeLike({ id, likes: res.data }))
    }

    // Callback for updating in formData.kudos in useState to render it
    const data = { kudoID: id, likes: res.data}
       
    return data;
  } catch (err) {
    dispatch({
      type: RECOGNITION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add comment
export const addRecognitionComment = (id, formData) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const res = await axios.post(
      `/api/recognition/comment/${id}`,
      formData,
      config
    );

    dispatch({
      type: ADD_COMMENT,
      payload: {
        id,
        comments: res.data
      }
    });

    const home = store.getState().home;
 
    // Обновляем редюсер Home, если он не пустой
    // https://stackoverflow.com/questions/35667249/accessing-redux-state-in-an-action-creator
    // https://redux.js.org/api/store#dispatchaction
    if (home.data.length > 0) {
      store.dispatch(addHomeComment({ id, comments: res.data }))
    }

    // Callback for updating formData.kudos in useState to render it
    const data = { kudoID: id, comments: res.data }
    return data;

  } catch (err) {
    dispatch({
      type: RECOGNITION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit comment
export const editRecognitionComment = (
  recognitionID,
  commentID,
  formData
) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const res = await axios.put(
      `/api/recognition/comment/${recognitionID}/${commentID}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_COMMENT,
      payload: {
        id: recognitionID,
        comments: res.data
      }
    });
  } catch (err) {
    dispatch({
      type: RECOGNITION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete comment
export const deleteRecognitionComment = (
  recognitionID,
  commentID
) => async dispatch => {
  try {
    await axios.delete(
      `/api/recognition/comment/${recognitionID}/${commentID}`
    );

    dispatch({
      type: REMOVE_COMMENT,
      payload: {
        recognitionID,
        commentID
      }
    });

    const home = store.getState().home;
 
    // Обновляем редюсер Home, если он не пустой
    if (home.data.length > 0) {
      store.dispatch(deleteHomeComment({ recognitionID, commentID }))
    }

    // Callback for updating in formData.kudos in useState to render it
    const data = { kudoID: recognitionID, commentID }
    return data;
    
  } catch (err) {
    dispatch({
      type: RECOGNITION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Clear state
export const clearKudos = () => async dispatch => {
  try {
   
    dispatch({
      type: CLEAR_RECOGNITIONS
    });
  } catch (err) {
    dispatch({
      type: LOCATION_CHANGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
