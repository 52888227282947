import {
    GET_ALL_USERS,
    OKRS_ERROR,
    USERS_ERROR,
    GET_ALL_OKRS,
    GET_ALL_STANDUPS,
    GET_ALL_TASKS,
    STANDUPS_ERROR,
    GET_ALL_UPDATES,
    UPDATES_ERROR,
    CLEAR_ADATA,
    GET_COMPANIES,
    COMPANIES_ERROR,
    CLEAR_OKRS,
    LOCATION_CHANGE_ERROR,
    GET_ALL_PLANS,
    PLANS_ERROR,
    TODOS_ERROR,
    GET_ALL_ICEBREAKERS,
    ICEBREAKERS_ERROR,
    DELETE_COMPANY,
    GET_ALL_LOGS,
    LOGS_ERROR,
    GET_ALL_ONES,
    ONES_ERROR,
    GET_ALL_MOODS,
    MOODS_ERROR,
    GET_ALL_CUSTOMS,
    CUSTOMS_ERROR,
} from "../actions/types";

const initialState = {
  u: [],
  ca: [],
  s: [],
  w: [],
  p: [],
  o: [],
  c: [],
  t: [],
  i: [],
  l: [],
  oo: [],
  ms: [],
  loading: true,
  error: {}
};


export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_OKRS:
    case LOCATION_CHANGE_ERROR: 
      return {
        ...state,
        u: [],
        s: [],
        w: [],
        o: [],
        c: [],
        loading: true,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        u: payload,
        loading: false,
      };
    case GET_ALL_OKRS:
      return {
        ...state,
        o: payload,
        loading: false,
      };
    case GET_ALL_STANDUPS:
      return {
        ...state,
        s: payload,
        loading: false,
      };
    case GET_ALL_UPDATES:
      return {
        ...state,
        w: payload,
        loading: false,
      };
    case GET_ALL_CUSTOMS:
      return {
        ...state,
        ca: payload,
        loading: false,
      };
    case GET_ALL_PLANS:
      return {
        ...state,
        p: payload,
        loading: false,
      };
    case GET_ALL_TASKS:
      return {
        ...state,
        t: payload,
        loading: false,
      };
    case GET_ALL_ICEBREAKERS:
      return {
        ...state,
        i: payload,
        loading: false,
      };
    case GET_ALL_ONES:
      return {
        ...state,
        oo: payload,
        loading: false,
      };
    case GET_COMPANIES:
      return {
        ...state,
        c: payload,
        loading: false,
      };
    case GET_ALL_LOGS:
      return {
        ...state,
        l: payload,
        loading: false,
      };
    case GET_ALL_MOODS:
      return {
        ...state,
        ms: payload,
        loading: false,
      };
    case DELETE_COMPANY:
      return {
        ...state,
        c: state.c.filter(item => item._id !== payload),
        u: state.u.filter(item => item.company.toString() !== payload),
        s: state.s.filter(item => item.company.toString() !== payload),
        w: state.w.filter(item => item.company.toString() !== payload),
        o: state.o.filter(item => item.company.toString() !== payload),
        p: state.p.filter(item => item.company.toString() !== payload),
        i: state.i.filter(item => item.company.toString() !== payload),
        t: state.t.filter(item => item.company.toString() !== payload),
        oo: state.oo.filter(item => item.company.toString() !== payload),
        ms: state.ms.filter(item => item.company.toString() !== payload),
        loading: false
      };
    case OKRS_ERROR:
    case USERS_ERROR:
    case STANDUPS_ERROR:
    case UPDATES_ERROR:
    case COMPANIES_ERROR:
    case PLANS_ERROR:
      return {
         ...state,
        error: payload,
        u: [],
        s: [],
        w: [],
        o: [],
        c: [],
        loading: false
      };
    case CUSTOMS_ERROR:
      return {
        ...state,
       error: payload,
       ca: [],
       loading: false
     };
    case CLEAR_ADATA:
      return {
        ...state,
        error: payload,
        u: [],
        s: [],
        w: [],
        o: [],
        p: [],
        i: [],
        c: [],
        t: [],
        l: [],
        oo: [],
        ms: [],
        loading: false
      };
    case ICEBREAKERS_ERROR:
      return {
        ...state,
        error: payload,
        i: [],
        loading: false
      };
    case TODOS_ERROR:
      return {
        ...state,
        error: payload,
        t: [],
        loading: false
      };
    case ONES_ERROR:
      return {
        ...state,
        error: payload,
        oo: [],
        loading: false
      };
    case LOGS_ERROR:
      return {
        ...state,
        error: payload,
        l: [],
        loading: false
      };
    case MOODS_ERROR:
      return {
        ...state,
        error: payload,
        ms: [],
        loading: false
      };
    default:
      return state;
  }
}
