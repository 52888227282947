import React from "react";
import PropTypes from 'prop-types';

const CompanyIcon = ({ activeIndex, shortMenu }) => {
  return (
    <svg id="company" className={`w-20px ${!shortMenu ? '' : 'ml-xs'}`} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="company-g" transform="translate(-2048.883 103.379)">
        <path id="Path_124" fill={`${activeIndex === 6 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 124" d="M2270.433-93.357q0,3.527,0,7.054c0,.195,0,.39-.016.584a2.382,2.382,0,0,1-2.271,2.3q-3.334.078-6.672,0a2.385,2.385,0,0,1-2.242-2.244c-.014-.166-.019-.333-.019-.5q0-7.221,0-14.443a3.993,3.993,0,0,1,.057-.789,2.479,2.479,0,0,1,2.289-1.972c.083-.005.167-.011.25-.011,2.016,0,4.032,0,6.049,0a2.457,2.457,0,0,1,2.3,1.379,2.827,2.827,0,0,1,.271,1.376Q2270.431-96.989,2270.433-93.357Zm-10.016-.056q0,3.588,0,7.177a3.608,3.608,0,0,0,.046.664,1.112,1.112,0,0,0,.9.928,2.562,2.562,0,0,0,.579.056q2.877.006,5.754,0a3.057,3.057,0,0,0,.538-.047,1.14,1.14,0,0,0,.951-.974,3.089,3.089,0,0,0,.039-.5q0-7.26,0-14.52a3.3,3.3,0,0,0-.035-.5,1.15,1.15,0,0,0-1.012-1.01,4.24,4.24,0,0,0-.582-.033c-1.849,0-3.7.022-5.546-.01-1.18-.021-1.654.534-1.642,1.63C2260.436-98.17,2260.417-95.792,2260.417-93.414Z" transform="translate(-201.551)"/>
        <path id="Path_125" fill={`${activeIndex === 6 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 125" d="M2048.9,23.657c0-1.545.043-3.091-.011-4.634a2.617,2.617,0,0,1,2.848-2.841c1.221.058,2.448.011,3.672.012a3.519,3.519,0,0,1,.417.014.591.591,0,0,1,.009,1.174,3.041,3.041,0,0,1-.416.019q-1.774,0-3.547,0c-.1,0-.195,0-.292,0-1.026.015-1.471.457-1.472,1.491q-.006,4.781,0,9.562a2.728,2.728,0,0,0,.046.539,1.114,1.114,0,0,0,.907.922,3.133,3.133,0,0,0,.663.055c1.433,0,2.865,0,4.3,0a3.947,3.947,0,0,1,.417.012.566.566,0,0,1,.553.568.579.579,0,0,1-.553.612,1.526,1.526,0,0,1-.208.013c-1.586,0-3.171,0-4.757,0a2.5,2.5,0,0,1-1.753-.657,2.407,2.407,0,0,1-.8-1.6c-.017-.194-.021-.389-.021-.584Q2048.895,26,2048.9,23.657Z" transform="translate(0 -114.56)"/>
        <path id="Path_126" fill={`${activeIndex === 6 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 126" d="M2112.768,78.205c0,.305.014.611,0,.916a.874.874,0,0,1-.909.932c-.623.027-1.249.028-1.872,0a.865.865,0,0,1-.911-.928c-.022-.61-.023-1.222,0-1.832a.877.877,0,0,1,.948-.935q.894-.025,1.789,0a.88.88,0,0,1,.954.929c.018.3,0,.611,0,.916Zm-1.234.628V77.594H2110.3v1.239Z" transform="translate(-57.661 -172.216)"/>
        <path id="Path_127" fill={`${activeIndex === 6 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 127" d="M2110.926,196.363c.319,0,.639-.014.957,0a.845.845,0,0,1,.886.859c.029.651.03,1.3,0,1.956a.837.837,0,0,1-.875.87c-.636.028-1.276.03-1.913,0a.851.851,0,0,1-.892-.9c-.025-.624-.025-1.25,0-1.873a.874.874,0,0,1,.922-.918c.3-.015.61,0,.916,0Zm-.609,1.227v1.239h1.231v-1.239Z" transform="translate(-57.677 -287.207)"/>
        <path id="Path_128" fill={`${activeIndex === 6 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 128" d="M2351,76.482c.305,0,.611-.012.916,0a.856.856,0,0,1,.915.876c.028.651.028,1.305,0,1.956a.823.823,0,0,1-.846.852c-.65.031-1.3.032-1.955.005a.835.835,0,0,1-.874-.871c-.027-.651-.028-1.305,0-1.956a.848.848,0,0,1,.887-.86c.318-.018.638,0,.957,0Zm.611,1.233h-1.245a5.133,5.133,0,0,0,.019,1.239h1.226Z" transform="translate(-287.722 -172.34)"/>
        <path id="Path_129" fill={`${activeIndex === 6 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 129" d="M2350.936,196.358c.305,0,.611-.013.915,0a.857.857,0,0,1,.906.886c.027.637.027,1.277,0,1.915a.852.852,0,0,1-.9.89c-.623.026-1.249.028-1.872,0a.865.865,0,0,1-.911-.928c-.022-.61-.023-1.222,0-1.832a.883.883,0,0,1,.948-.936c.3-.013.61,0,.916,0Zm-.629,2.472h1.228v-1.244a5.183,5.183,0,0,0-1.266.018c0,.346,0,.651,0,.956A2.465,2.465,0,0,0,2350.307,198.831Z" transform="translate(-287.646 -287.204)"/>
        <path id="Path_130" fill={`${activeIndex === 6 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 130" d="M2350.927-43.437c.319,0,.639-.015.957,0a.845.845,0,0,1,.884.862c.029.651.029,1.3,0,1.956a.837.837,0,0,1-.877.868c-.637.028-1.277.03-1.913,0a.851.851,0,0,1-.89-.9c-.024-.624-.025-1.25,0-1.873a.874.874,0,0,1,.924-.916c.3-.015.61,0,.916,0Zm.627,1.213a8.254,8.254,0,0,0-1.267.02,7.828,7.828,0,0,0,.033,1.257,8.47,8.47,0,0,0,1.234-.02Z" transform="translate(-287.66 -57.429)"/>
      </g>
     </svg>
  );
};

CompanyIcon.propTypes = {
  activeIndex: PropTypes.number
};

export default CompanyIcon;