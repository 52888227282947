import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '../layout/Menu';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import Widgets from '../widgets/Widgets';
import TeamMenu from './TeamMenu';
import RecognitionItem from '../recognitions/noState/RecognitionItem';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/1.jpg';
import Notification from '../utils/Notification';
import {
  getTeamRecognitions,
  getGivedTeamRecognitions
} from '../../actions/recognition';
import { getCompanyProfiles } from '../../actions/profile';
import { getUserCompany } from '../../actions/company';
import { onboardingRecognitions } from '../../utils/onboarding/recognitions';
import { getCompanySortedOkrs } from '../../actions/okr';
import { FormattedMessage } from 'react-intl';
import { kudosCopy } from '../../utils/wording/kudos';

const TeamKudos = ({
  getTeamRecognitions,
  getCompanyProfiles,
  getCompanySortedOkrs,
  getUserCompany,
  company: { company },
  okr: { okrs },
  auth: { user },
  profile: { profiles },
  getGivedTeamRecognitions,
  recognition: { recognitions, givedRecognitions, loading }
}) => {
  useEffect(() => {
    getUserCompany();
    getCompanyProfiles();
    getGivedTeamRecognitions();
    getTeamRecognitions();
    getCompanySortedOkrs();

  }, [getUserCompany, getCompanyProfiles, loading, getTeamRecognitions, getGivedTeamRecognitions, getCompanySortedOkrs]);

  const [received, handleReceived] = useState(true);
  const [given, handleGiven] = useState(true);

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {user && user.department ? (
          <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user && user.isAdmin && true} activeIndex={10} />
        ) : (
          <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} activeIndex={10} />
        )}

        <UserNav />
        
          <Fragment>
            {loading || !recognitions || !user || !okrs || !company ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="main main-dashboard">
                     <h5>
                      {user.departmentName}{" "}<FormattedMessage id="menu.kudos" defaultMessage={`Kudos`} />
                    </h5>
                  <TeamMenu companyIndex={4} />
                  <div className="kudos">
                    <div className="mb-4">
                        <Widgets
                          leftColor={'green'}
                          rightColor={'red'}
                          receivedRecognitions={recognitions}
                          givedRecognitions={givedRecognitions}
                          received={received}
                          handleReceived={handleReceived}
                          given={given}
                          handleGiven={handleGiven}
                        />
                    </div>

                      <div className="text-right pb-1">
                        <Link to="/kudos/new" className="btn btn-light">
                          <FormattedMessage
                            id="user.recognition.createBtn"
                            defaultMessage="+ Create Recognition"
                          />
                        </Link>
                      </div>

                      {company.plan !== 1 ? (
                        <Fragment>
                          {!loading && recognitions.length === 0 && givedRecognitions ? (
                            <Fragment>
                              <Notification
                                msg={kudosCopy.msgOnboarding}
                                cta={kudosCopy.ctaOnboarding}
                                btnColor={'orange'}
                                color={`orange`}
                                url={`/kudos/new`}
                              />
                              {onboardingRecognitions.map(recognition => (
                                <RecognitionItem
                                  key={recognition._id}
                                  recognition={recognition}
                                />
                              ))}
                            </Fragment>
                          ) : (
                            <Fragment>
                              {recognitions.map(recognition => (
                                <RecognitionItem
                                  key={recognition._id}
                                  recognition={recognition}
                                />
                              ))}
                            </Fragment>
                          )}
                        </Fragment>
                      ) : (
                        <NoContent 
                          message={kudosCopy.updatePlanMsg}
                          picture={updateImg}
                          alt={'Update plan'}
                          admin={user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0}
                        />
                      )}
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

TeamKudos.propTypes = {
  getCompanyProfiles: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getTeamRecognitions: PropTypes.func.isRequired,
  getGivedTeamRecognitions: PropTypes.func.isRequired,
  getCompanySortedOkrs: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  recognition: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  recognition: state.recognition,
  okr: state.okr
});

export default connect(
  mapStateToProps,
  { getUserCompany, getCompanyProfiles, getTeamRecognitions, getGivedTeamRecognitions, getCompanySortedOkrs }
)(TeamKudos);
