import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import MainMenu from '../layout/Sidebar';
import Menu from '../layout/Menu';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import { getIcebreakers, editIcebreakerAnswer, clearIcebreakers, addIcebreakerAnswer, deleteIcebreakerAnswer } from '../../actions/icebreaker';
import { getCompanyProfiles } from '../../actions/profile';
import { getUserCompany } from '../../actions/company';
import IceItem from './IceItem';
import NoContent from '../utils/NoContent';
import NoContentNew from '../utils/NoContentNew';
import updateImg from '../../img/feature/no_content.svg';
import kudosImg from '../../img/feature/team_work4.svg';
import icebreakerImg from '../../img/empty_states/flash.png';
import Notification from '../utils/Notification';
import { onboardingIcebreakerEn, onboardingIcebreakerRu } from '../../utils/onboarding/icebreakers';
import { setAlert } from '../../actions/alert';
import { icebreakerCopy } from '../../utils/wording/icebreaker';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import Moment from 'react-moment';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    backgroundColor: '#fcfcfd',
    fontSize: '14px',
  }),
  control: styles => ({ ...styles, backgroundColor: '#fcfcfd', padding: '4px 8px' }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
}

const Icebreaker = ({
  clearIcebreakers,
  deleteIcebreakerAnswer,
  getUserCompany,
  getCompanyProfiles,
  auth: { user, sidebar },
  company: { company },
  icebreaker: { icebreakers, loading },
  getIcebreakers,
  editIcebreakerAnswer,
  addIcebreakerAnswer,
  profile: { profiles },
  locale,
}) => {
  const [formData, setFormData] = useState({
    team: '',
    items: [],
  });

  const [search, setSearch] = useState('');
  const [results, setResults] = useState({
    searchResults: false,
    searchKeyword: ''
  });

  let { team, items, } = formData;
  let { searchResults, searchKeyword } = results;
  let teamOptions = [];

  // Перебор profiles & departments и приведение данных к формату Селекта
  {
    profiles &&
      profiles.forEach((profile, index) => {
        
        if (user && profile._id === user._id) return; 

        let coworker = {
          value: profile._id,
          label: `${profile.firstName} ${profile.lastName}`,
          type: 3
        };

        teamOptions.push(coworker);
      });
  }

  {
    company && company.departments.length > 0 &&
      company.departments.forEach((department, index) => {

        if (user && user.department === null) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };
  
          teamOptions.unshift(team);
        } else if (user && user.department !== null && department._id !== user.department) {
          let team = {
            value: department._id,
            label: department.name,
            type: 2
          };
  
          teamOptions.unshift(team);
        }

        if (user && index === company.departments.length - 1) {

          // You is third in list
          let person = { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 }
          teamOptions.unshift(person);

          // Second option is the team if exists
          if (user.department !== null) {
            let team = { value: user.department, label: `😎 ${user.departmentName}`, type: 2 };
            teamOptions.unshift(team);
          }

          // Third option is the company
          let name = { value: company._id, label: `💪 ${company.name}`, type: 1 };
          teamOptions.unshift(name);
        }
      });
  } 

  if (company && company.departments.length === 0 && user && profiles && profiles.length === 1) {
    teamOptions = [
      { value: company._id, label: `💪 ${company.name}`, type: 1 },
      { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 }
    ]
  } else if (company && company.departments.length === 0 && user && profiles && profiles.length > 1) {
    // if 0 teams and 1+ users
    // add user and company to others members that we found in the profiles loop
      let person = { value: user._id, label: `${user.firstName} ${user.lastName}`, type: 3 }
      teamOptions.unshift(person);

      let team = { value: company._id, label: `💪 ${company.name}`, type: 1 };
      teamOptions.unshift(team);
  }
  
  let choosedTeam = company && locale && user ? { value: company._id, label: `💪 ${company.name}`, type: 1, } : {};

  useEffect(() => {
    getUserCompany();
    getIcebreakers();
    getCompanyProfiles();

    setFormData({
      ...formData,
      team: !user ? '' : choosedTeam,
      items: !icebreakers ? [] : icebreakers,
    });
  }, [getUserCompany, getIcebreakers, getCompanyProfiles, loading, user && choosedTeam && choosedTeam.value && choosedTeam.value.length]);

  const handleTeam = selectedOption => {
    let newIcebreakers = [];

    if (selectedOption.type === 1) {
      // Company
      newIcebreakers = icebreakers;
    } else if (selectedOption.type === 2) {
      // Team
      icebreakers.map(item => {
        // Use .every() to stop the loop
        // Используем цикл every, чтобы остановить цикл, тк break and continue не останавливают forEach and map
        // для остановки делаем return false, для продолжения цикла return true
        item.answers.every(answer => {
          if (answer.department === selectedOption.value) {

            newIcebreakers.push(item);
            return false;
          }
          return true;
        })
      })
    } else if (selectedOption.type === 3) {
      // User 
      icebreakers.map(item => {

        item.answers.every(answer => {
          if (answer.user === selectedOption.value) {
            newIcebreakers.push(item);
            return false;
          }
          return true;
        })
      })
    }
  
    setFormData({ 
      ...formData, 
      team: selectedOption, 
      items: newIcebreakers
    });
  };
  
  // КАК СЕЙЧAС РАБОТАЕТ
  // 1. Формируем массив чекинов itemsArr, который не зависит от checkins 
  //   - он также привязан к team, чтобы искать по фильтру команды
  //   - отдельный массив для того, чтобы он не влияел на checkins, который рендерится на странице
  // 2. Делаем циклы, чтобы добраться до text
  //   - Цикл для itemsArr, потом для itemsArr.answers, потом для itemsArr.answers.text
  // 3. Помещаем все в массив filteredItems, где хранятся результаты
  //   - используем push
  const handleSearch = e => {

    let value = e.target.value;
    let itemsArr = [];
    setSearch(e.target.value);

    // handle Team
    if (team.type === 1) {
      // Company
      itemsArr = icebreakers;
    } else if (team.type === 2) {
      // Team
      icebreakers.map(item => {
        item.answers.every(answer => {
          if (answer.department === team.value) {

            itemsArr.push(item);
            return false;
          }
          return true;
        })
      })
    } else if (team.type === 3) {
      // User 
      icebreakers.map(item => {

        item.answers.every(answer => {
          if (answer.user === team.value) {
            itemsArr.push(item);
            return false;
          }
          return true;
        })
      })
    }

    let filteredItems = [];

    if (e.target.value.length !== 0) {
      // If search input is not empty
      
      itemsArr.forEach((item, i) => {
        let answers = item.answers;
        let isUniqueItem = true;
  
        answers.every((answer, index) => {
          if (answer.text.toLowerCase().includes( e.target.value.toLowerCase() ) === true) {
            isUniqueItem = false;
            
            filteredItems.push(item);
            return false;
          }

          return true;
          })  
        })

        setFormData({ 
          ...formData, 
          items: filteredItems });

        // Check if we have search results or not
        if (filteredItems.length !== 0) {
          setResults({ 
            ...results, 
            searchResults: false,
            searchKeyword: e.target.value 
           });
        } else {
          // If we don't have search results
          setResults({ 
            ...results, 
            searchResults: true,
            searchKeyword: e.target.value
           });
        }

    } else {
      // Empty search input
      setFormData({ 
        ...formData, 
        items: itemsArr });

      setResults({ 
        ...results, 
        searchResults: false,
        searchKeyword: ''
        });
    }
  };
  
  // передаем индекс, чтобы сразу менять потом стейт items, 
  // а не перебирать каждый items в поисках нашего icebreakers по айди
  const onSubmit = async (icebreakerID, index, data, e) => {
    e.preventDefault();

    const res = await addIcebreakerAnswer(icebreakerID, data);

    if (res !== undefined) {

      items[index].answers.push(res.data.answer);

      setFormData({
        ...formData,
        items: items,
        text: ''
      });
    }
  };

  const onChange = (e, index) => {
    setFormData({ ...formData, items });
  };

  // ЗДЕСЬ получаем коллбэк и перебираем formData.checkins и обновляем комменты
  const handleDeleteAnswer = async (icebreakerID, answerID) => {
    try {
      const res = await deleteIcebreakerAnswer(icebreakerID, answerID);
    
      setFormData({ 
        ...formData, 
        items: formData.items.map(item => {
          if (item._id === res.icebreakerID) {
            // Delete answer 
            item.answers = item.answers.filter(answer => answer._id !== res.answerID);
       
            return item;
          } else {
            return item;
          }
        })
      });
      
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in icebreaker answers', err);
    }
  };

  // ЗДЕСЬ получаем коллбэк и перебираем formData.items и обновляем state
  const onSubmitEdit = async (icebreakerID, answerID, data) => {
    try {
      const res = await editIcebreakerAnswer(icebreakerID, answerID, data);
     
      if (res !== undefined) {

        items.map(item => {
          if (item._id === res.icebreakerID) {
            item.answers.map(answer => {
              if (answer._id === res.answerID) {
                answer.text = res.answer.text;
                answer.date = res.answer.date;
              }
            })
          }
        })

        setFormData({ ...formData, items });
      }
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in likes', err);
    }
  };

  const handleEditButton = async (answerID, index, update) => {
    try {
      let editText = items[index].answers.filter(answer => answer._id === answerID).text;

      // Put answer to textarea field
      if (!update) {
        setFormData({ ...formData, editText })
      } 
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in likes', err);
    }
  };

  // Get users answer to the last icebreaker
  let lastIcebreakerAnswer = icebreakers.length > 0 && user ? icebreakers[0].answers.filter(item => item.user === user._id) : [];
  
  const aboutIcebreakers = {
    title: <FormattedMessage id="icebreaker.about" defaultMessage="About Icebreakers" />,
    msg: <FormattedMessage id="icebreaker.about.msg" defaultMessage='Icebreaker questions are designed as simple team-building activities to help teammates into a little team bonding. It allows people in your team to connect on a personal level. Focus generates simple and fun icebreaker questions each week. Just add your reply and have fun with your teammates.' />
    
  };

   const noIcebreakersMessageTeam = {
    title: <FormattedMessage id="noicebreaker.title" defaultMessage="No Icebreakers" />,
    msg: `${team.label} ${<FormattedMessage id="noicebreaker.msg" defaultMessage={`has not finished icebreaker yet.`} />}`
    
  };

  const noSearchResults = {
    title: <FormattedMessage id="search.noresults" defaultMessage="No Results Found" />,
    msg: 
    <FormattedMessage 
      id="company.checkins.user.msg" 
      defaultMessage={`Sorry, we couldn't find any results matching <b>"${searchKeyword}"</b>`}
      values={{ b: (...chunks) => <b>{chunks}</b>}} />
  };

  const noReplyMsgOne = <FormattedMessage id="icebreaker.notification.msg1" defaultMessage="You have not answered to icebreaker yet." />;
  let noReplyMsgTwo = <FormattedMessage id="icebreaker.notification.msg2.5" defaultMessage="Just add your answer to the last icebreaker" />;
  const currentItemMsg = <FormattedMessage id="icebreaker.active" defaultMessage="Active Icebreaker" />;
  
  if (icebreakers && icebreakers.length === 0) { 
    noReplyMsgTwo = <FormattedMessage id="icebreaker.notification.msg3" defaultMessage="Add your answer when the first icebreaker is created" />;
  }

  const upgradePlan = {
    title: <FormattedMessage id="upgrade.icebreakers" defaultMessage="Upgrade your plan to start Icebreakers" />,
    msg: <FormattedMessage id="payments.sucess.msg" defaultMessage="Icebreakers are fun way to get your team to know one another. You can use our icebreaker campaign with 100+ ready icebreakers or set up your own !" />,
    btnUrl: '/settings/pricing',
    btnCta: <FormattedMessage id="upgrade.plan" defaultMessage="Upgrade plan" />,
    btnColor: 2
  };

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {/* {user && user.department ? (
          <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user.isAdmin && true} focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={14} />
        ) : (
          <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={14} />
        )} */}
        {user && !loading && (
          <MainMenu activeIndex={14} />
        )}

        <UserNav />
          <Fragment>
            {loading || !icebreakers || !user || !company ? (
              <Spinner />
            ) : (
              <Fragment>
                 <div className={`${sidebar === 1 ? 'ml-280 main main-dashboard ' : 'short_menu__ml__okrs ma-w90'} mt-3`}>
                  <div className="checkins">
                    <div className="title">
                      <h5 className="pl-0-xs">
                        <FormattedMessage id="icebreakers.header" defaultMessage="Icebreakers" />
                      </h5>
                      <p className="medim">
                        <FormattedMessage
                          id="icebreaker.subheader"
                          defaultMessage="Brake the ice and start conversation between the team"
                        />
                      </p>
                    </div>
                    
                    <div className="flex space-between align-baseline">
                      <FormattedMessage
                        id="checkins.sub_menu.teamSelect"
                        defaultMessage="Choose the team or user"
                      >
                        {msg => (
                          <Select
                            className="small-input w-30-l-50-xs mt-2 mb-3"
                            defaultValue={choosedTeam}
                            value={team}
                            onChange={selectedOption =>
                              handleTeam(selectedOption)
                            }
                            options={teamOptions}
                            isSearchable
                            placeholder={msg}
                            required
                            name="team"
                            styles={customStyles}
                          />
                        )}
                      </FormattedMessage>

                      <FormattedMessage
                        id="search.bar"
                        defaultMessage="Search..."
                      >
                        {msg => (
                          <input
                            className="question-input ml-2 p-1 pl-2 br-20 brd brd-grey bg__white outline w-20-l-50-xs"
                            type="text"
                            placeholder={msg}
                            name="search"
                            value={search}
                            onChange={e => handleSearch(e)}
                          />
                        )}
                      </FormattedMessage>
                    </div>

                    {company.plan > 3 ? (
                      <Fragment>
                        {icebreakers.length === 0 ? (
                          <Fragment>
                            <NoContent 
                              message={aboutIcebreakers}
                              picture={kudosImg}
                              alt={'Icebreakers'}
                              imgWidth={'w-300px'}
                            />
                            <Notification
                              msg={noReplyMsgOne}
                              msg2={noReplyMsgTwo}
                              color={`orange`}
                              />
                          </Fragment>
                        ) : (
                          <Fragment>
                                {lastIcebreakerAnswer.length === 0 && icebreakers.length === 1 && (
                                  <Fragment>
                                    <NoContent 
                                        message={aboutIcebreakers}
                                        picture={kudosImg}
                                        alt={'Icebreakers'}
                                        imgWidth={'w-300px'}
                                      />
                                    <Notification
                                      msg={noReplyMsgOne}
                                      msg2={noReplyMsgTwo}
                                      color={`orange`}
                                      />
                                  </Fragment>
                                )}

                            {items.length > 0 ? (
                              items.map(
                                (item, index) => (
                                  <Fragment key={`fragment-icebreaker-${index}`}>
                                    <h3 className="fs-2 pt-2 pl-2 pb-1" key={`icebreaker-date-${index}`}>
                                      <Moment format="D MMM" key={`date-moment-${index}`}>{item.date}</Moment>
                                    </h3>
                                    {index === 0 && (
                                      <p className="fs-2 pl-2 blue">
                                        {currentItemMsg}
                                      </p>
                                    )}

                                    <div
                                      className={`component my-2 px-4-l py-2 light-shadow no-shadow-xs ${index === 0 && 'brd brd-primary'}`}
                                      key={index}
                                    >
                                      <div className="cards">
                                        <IceItem
                                          key={`icebreaker-${item._id}-${index}`}
                                          icebreaker={item}
                                          index={index}
                                          formData={formData}
                                          authUser={user}
                                          setFormData={setFormData}
                                          searchKeyword={searchKeyword.length > 0 && searchKeyword}
                                          handleDeleteAnswer={handleDeleteAnswer}
                                          onSubmitEdit={onSubmitEdit}
                                          handleEditButton={handleEditButton}
                                          onChange={onChange}
                                          onSubmit={onSubmit}
                                        />
                                      </div>
                                    </div>

                                    {icebreakers.length === 1 && lastIcebreakerAnswer.length < 2 && (
                                      <Fragment>
                                        <p className="fs-2 pl-2 bold">
                                          {icebreakerCopy.msgOnboarding1}
                                        </p>
                                        <p className="medium pl-2">
                                          {icebreakerCopy.msgOnboarding2}
                                        </p>

                                        <div
                                          className={`component my-2 px-4-l py-2 light-shadow no-shadow-xs`}
                                        >
                                          <div className="cards">
                                            <IceItem
                                              key={`icebreaker-onboarding-2`}
                                              icebreaker={locale.lang !== 'ru' ? onboardingIcebreakerEn : onboardingIcebreakerRu}
                                              index={1}
                                              formData={formData}
                                              authUser={user}
                                              setFormData={setFormData}
                                              searchKeyword={searchKeyword.length > 0 && searchKeyword}
                                              handleDeleteAnswer={handleDeleteAnswer}
                                              onSubmitEdit={onSubmitEdit}
                                              handleEditButton={handleEditButton}
                                              onChange={onChange}
                                              onSubmit={onSubmit}
                                            />
                                          </div>
                                          </div>
                                        </Fragment>
                                      )}
                                  </Fragment>
                                )
                              )
                            ) : (
                              <div className="mt-5">
                                {!searchResults ? (
                                   <NoContent 
                                      message={noIcebreakersMessageTeam}
                                      picture={updateImg}
                                      alt={'No results'}
                                      imgWidth={'w-300px'}
                                    />
                                ) : (
                                  <NoContent 
                                    message={noSearchResults}
                                    picture={updateImg}
                                    alt={'No search results'}
                                    imgWidth={'w-300px'}
                                  />
                                )}
                              </div>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      // <NoContent 
                      //   message={icebreakerCopy.updatePlanMsg}
                      //   btnCta={icebreakerCopy.updatePlanMsg.title}
                      //   picture={updateImg}
                      //   alt={'Update plan'}
                      //   imgWidth={'w-300px'}
                      //   admin={user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0}
                      // />
                      <NoContentNew
                        message={upgradePlan}
                        picture={icebreakerImg}
                        alt={'Upgrade your plan'}
                        imgWidth={'w-200px h-150px'}
                        button={1}
                        btnUrl={upgradePlan.btnUrl}
                        btnCta={upgradePlan.btnCta}
                        divStyles="row"
                        contentStyles="w-80 text-left ml-2"
                      />
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

Icebreaker.propTypes = {
  clearIcebreakers: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  getIcebreakers: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  addIcebreakerAnswer: PropTypes.func.isRequired,
  editIcebreakerAnswer: PropTypes.func.isRequired,
  deleteIcebreakerAnswer: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  icebreaker: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  icebreaker: state.icebreaker,
  locale: state.locale,
});

export default connect(
  mapStateToProps,
  {
    getIcebreakers, editIcebreakerAnswer, clearIcebreakers,
    addIcebreakerAnswer, getUserCompany, getCompanyProfiles,
    deleteIcebreakerAnswer,
  }
)(Icebreaker);
