import axios from 'axios';

const setAuthTokenRef = token => {
  if (token) {
    axios.defaults.headers.common['x-auth-token-ref'] = token;
  } else {
    delete axios.defaults.headers.common['x-auth-token-ref'];
  }
};

export default setAuthTokenRef;
