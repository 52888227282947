import React from 'react';
import { FormattedMessage } from 'react-intl';

export const moodCopy = {
  range: {
    bad: (
      <FormattedMessage id="bad" defaultMessage="Bad"/>
    ),
    hard: (
      <FormattedMessage id="hard" defaultMessage="Hard"/>
    ),
    neutral: (
      <FormattedMessage id="neutral" defaultMessage="Neutral"/>
    ),
    good: (
      <FormattedMessage id="good" defaultMessage="Good"/>
    ),
    excellent: (
      <FormattedMessage id="excellent" defaultMessage="Excellent"/>
    ),
  },
  table: {
    date: (
      <FormattedMessage id="date" defaultMessage="Date"/>
    ),
    moods: (
      <FormattedMessage id="moods" defaultMessage="Moods"/>
    ),
  },
  msgOnboarding: (
    <FormattedMessage
      id="mood.onboarding.company.notification.msg1"
      defaultMessage="You're using the sample data below. Share your mood, and you'll see your data there (the sample data will be removed). Click the icon and submit the mood."
    />
  ),
  ctaOnboarding: (
    <FormattedMessage
      id="mood.onboarding.company.notification.cta"
      defaultMessage="Share weekly plan"
    />
  ),
  updatePlanMsg: {
    title: (
      <FormattedMessage
        id="company.updateplan.title"
        defaultMessage="Update Plan"
      />
    ),
    msg: (
      <FormattedMessage
        id="company.mood.upgrade.msg"
        defaultMessage={`Mood survey is available on Pro + Culture plan. Please upgrade the plan to unlock this feature.`}
      />
    )
  }, 
}

export default {
  moodCopy
}