import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import KRmetrics from '../elements/KRmetricsForm';
import { icons } from '../../../img/elements/icons';
import Select from 'react-select';
import DatePickerComponent from '../../elems/DatePicker';


const selectCustomStyles = {
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isFocused ? '#404ff5' : '#fff',
    color: state.isFocused ? '#fff' : '#000',
    fontSize: '18px'
  }),
  control: (styles, state) => ({
    ...styles,
    borderColor: state.isFocused ? '404ff5' : '#e9eaf5',
    boxShadow: state.isFocused ? '0px 0px 20px #404FF54D' : 'none',
    fontSize: '18px',
    border: state.isFocused ? '2px solid #404ff5 !important' : styles.border,
    margin: state.isFocused ? '0px' : '1px'
  })
};


const Kr = ({ 
  keyResults, 
  onChangeKeyResults, 
  locale, 
  handleKeyResults, 
  krLabels,
  handleAddMilestone,
  handleRemove,
  ownerOptions,
  krTypeOptions,
  handleAdd,
  isKrOptionsHide,
 }) => {

  let removeKRCopy = 'Are you sure you wish to delete this Key Result? Data will be lost forever.'

  if (locale && locale.lang === 'ru') {
    removeKRCopy = 'Вы уверены, что хотите удалить этот Ключевой Результат? Все данные будут безвозвратно потеряны.';
  }
  
  return (
    <Fragment>
      {keyResults.map((keyResult, index) => (
        <div
          key={`keyResult-${index}`}
          className="card-title-bordered card-title-bordered-green  p-2 column mb-2"
        >
          <div className="okr__kr okr__kr-top w-100 flex  pb-2  align-start">
            <div className="okr__kr-name  labeled-wrapper w-100 ">
              <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                <FormattedMessage
                  id="user.okr.create.keyResult.title"
                  defaultMessage="Measurable key result"
                />
              </label>
              <FormattedMessage
                id="user.okr.create.keyResult.titleInput"
                defaultMessage="Enter your awesome key result"
              >
                {(msg) => (
                  <input
                    className="w-100 input-text fs-18"
                    type="text"
                    placeholder={msg}
                    name="krTitle"
                    value={keyResult.krTitle || ''}
                    onChange={(e) => onChangeKeyResults(e, index)}
                  />
                )}
              </FormattedMessage>
            </div>

            <KRmetrics
              keyResult={keyResult}
              index={index}
              onChangeKeyResults={onChangeKeyResults}
              locale={locale && locale.lang}
              selectCustomStyles={selectCustomStyles}
              handleKeyResults={handleKeyResults}
              krLabels={krLabels}
              krName="kr"
              handleAddMilestone={handleAddMilestone}
            />


            <div 
              className="absolute-xs right-10-xs" 
              onClick={() => { if (window.confirm(removeKRCopy)) handleRemove(index) } }
              >
              {icons.close__new}
            </div>
          </div>

          <div className="okr__kr okr__kr-top w-100 flex  pb-2  align-start">
            <div className="okr__kr labeled-wrapper w-50">
              <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                <FormattedMessage
                  id="user.okr.create.keyResult.owner"
                  defaultMessage="Owner"
                />
              </label>
              <FormattedMessage
                id="user.okr.create.keyResult.ownerSelect"
                defaultMessage="Choose the owner of this key result"
              >
                {(msg) => (
                  <Select
                    className="w-100"
                    value={keyResult.krOwner}
                    name="krOwner"
                    onChange={(selectedOption) =>
                      handleKeyResults(selectedOption, index, 'krOwner')
                    }
                    options={ownerOptions}
                    isSearchable
                    placeholder={msg}
                    required
                    isMulti
                    styles={selectCustomStyles}
                  />
                )}
              </FormattedMessage>
            </div>
            <div className="okr__kr labeled-wrapper w-30 ml-2">
              <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                <FormattedMessage
                  id="user.okr.create.krDueIn"
                  defaultMessage="Due date"
                />
              </label>
              <DatePickerComponent
                value={keyResult.dueDate}
                onSelect={(selectedOption) =>
                  handleKeyResults(selectedOption, index, 'dueDate')
                }
                isUpdate={true}
              />
            </div>

            {!isKrOptionsHide || isKrOptionsHide === undefined ? (
              <div className="okr__kr labeled-wrapper w-200px ml-2">
                <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                  <FormattedMessage id="kr.type" defaultMessage="Key result type" />
                </label>
                <FormattedMessage id="choose.type" defaultMessage="Choose the type">
                  {(msg) => (
                    <Select
                      className="w-100"
                      defaultValue={krTypeOptions[0]}
                      value={keyResult.krType}
                      name="krType"
                      onChange={(selectedOption) =>
                        handleKeyResults(selectedOption, index, 'krType')
                      }
                      options={krTypeOptions}
                      isSearchable
                      placeholder={msg}
                      required
                      styles={selectCustomStyles}
                    />
                  )}
                </FormattedMessage>
              </div>
            ) : null}
          </div>
        </div>
      ))}

      <button
        type="button"
        className="btn-secondary-back-upd fw-600  flex align-center  mb-2 px-1 black"
        onClick={(e) => handleAdd(e)}
      >
        <span className="rounded-text-icon">{icons.circle_plus}</span>{' '}
        <FormattedMessage
          id="user.okr.create.keyResult.addBtn"
          defaultMessage="Add Key Result"
        />
      </button>
    </Fragment>
  );
};

export default Kr;
