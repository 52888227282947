import React, { Fragment, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { signup } from '../../actions/auth';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import appsumoImg from '../../img/logo/companies/appsumo_focus.png';


const Appsumo = ({ setAlert, signup, isAuthenticated, locale: { lang } }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    password2: '',
    company: '',
    lang: '',
    code: ''
  });

  const {
    firstName,
    lastName,
    email,
    password,
    password2,
    company,
    code
  } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();

    if (password !== password2) {
      setAlert(
        <FormattedMessage
          id="auth.signup.alert.pass"
          defaultMessage="Passwords do not match"
        />,
        'danger'
      );
    } else {
      signup({
        firstName,
        lastName,
        email,
        password,
        company,
        lang,
        code
      });
    }
  };

  // Redirect if signed up
  if (isAuthenticated) {
    return <Redirect to="/dashboard/objectives" />;
  }

  return (
    <Fragment>
      {/* <Navbar guest={true} /> */}
      <section>
        <div className="container py-4 my-0">
          <div className="flex space-around flex-column-xs">
            <div className="left-part w-40 w-100-xs">
              <div className="hero_image text-center py-2">
                <img
                    src={appsumoImg}
                    alt="AppSumo + Focus"
                    className="d-block w-100-xs"
                    width="421px"
                    height="45px"
                  />
              </div>

              <div className="section_header mxa w-100-xs text-left">
                <h2 className="pl-1">
                  <FormattedMessage
                    id="auth.signup.appsumo.header"
                    defaultMessage="Hey Sumo-lings!"
                  />
                </h2>
                <p className="medium p-1">
                  <FormattedMessage
                    id="auth.signup.appsumo.text1"
                    defaultMessage={`Please share you full name, email, company name, and AppSumo code to signup in Focus with AppSumo <b>lifetime access.</b>`}
                    values={{ b: (...chunks) => <b>{chunks}</b>}}
                  />
                </p>
                <p className="medium p-1">
                  <FormattedMessage
                    id="auth.signup.appsumo.text2"
                    defaultMessage="After your registration, you’ll be able to use Focus."
                  />
                </p>
                <p className="medium p-1">
                  <FormattedMessage
                    id="auth.signup.appsumo.text3"
                    defaultMessage="Thanks so much and happy to have you!"
                  />
                </p>
                <p className="medium pl-1">
                  <FormattedMessage
                    id="auth.signup.appsumo.text4"
                    defaultMessage="xo,"
                  />
                </p>
                <p className="medium pl-1">
                  <FormattedMessage
                    id="auth.signup.appsumo.text5"
                    defaultMessage="Dave Schneider + Team"
                  />
                </p>
              </div>
            </div>

            <div className="right-part">
              <div className="form form-detail">
                <form onSubmit={e => onSubmit(e)}>
                  <div className="field_name field">
                    <FormattedMessage
                      id="auth.signup.nameInput"
                      defaultMessage="First Name"
                    >
                      {msg => (
                        <input
                          type="text"
                          className="name"
                          placeholder={msg}
                          name="firstName"
                          value={firstName}
                          onChange={e => onChange(e)}
                          required
                        />
                      )}
                    </FormattedMessage>
                  </div>
                  <div className="field_name field">
                    <FormattedMessage
                      id="auth.signup.lastnameInput"
                      defaultMessage="Last Name"
                    >
                      {msg => (
                        <input
                          type="text"
                          className="name"
                          placeholder={msg}
                          name="lastName"
                          value={lastName}
                          onChange={e => onChange(e)}
                          required
                        />
                      )}
                    </FormattedMessage>
                  </div>
                  <div className="field_email field">
                    <FormattedMessage
                      id="auth.signup.sumoEmailInput"
                      defaultMessage="Your Email Address"
                    >
                      {msg => (
                        <input
                          type="email"
                          className="email"
                          placeholder={msg}
                          name="email"
                          value={email}
                          onChange={e => onChange(e)}
                          required
                        />
                      )}
                    </FormattedMessage>
                  </div>
                  <div className="field_password field">
                    <FormattedMessage
                      id="auth.signup.passInput"
                      defaultMessage="Password"
                    >
                      {msg => (
                        <input
                          type="password"
                          className="password"
                          placeholder={msg}
                          name="password"
                          value={password}
                          onChange={e => onChange(e)}
                          minLength="6"
                          required
                        />
                      )}
                    </FormattedMessage>
                  </div>
                  <div className="field_password password2 field">
                    <FormattedMessage
                      id="auth.signup.pass2Input"
                      defaultMessage="Confirm Password"
                    >
                      {msg => (
                        <input
                          type="password"
                          className="password2"
                          placeholder={msg}
                          name="password2"
                          value={password2}
                          onChange={e => onChange(e)}
                          minLength="6"
                          required
                        />
                      )}
                    </FormattedMessage>
                  </div>
                  <div className="field_company field">
                    <FormattedMessage
                      id="auth.signup.companyInput"
                      defaultMessage="Company Name"
                    >
                      {msg => (
                        <input
                          type="text"
                          className="company"
                          placeholder={msg}
                          name="company"
                          value={company}
                          onChange={e => onChange(e)}
                          required
                        />
                      )}
                    </FormattedMessage>
                  </div>
                  <div className="field_company field">
                    <FormattedMessage
                      id="auth.signup.appsumoCodeInput"
                      defaultMessage="AppSumo Code"
                    >
                      {msg => (
                        <input
                          type="text"
                          className="company"
                          placeholder={msg}
                          name="code"
                          value={code}
                          onChange={e => onChange(e)}
                          required
                        />
                      )}
                    </FormattedMessage>
                  </div>
                  <div className="form_button">
                    <button type="submit" className="btn btn-primary my-2">
                      {' '}
                      <FormattedMessage
                        id="auth.signup.btn"
                        defaultMessage="Sign Up"
                      />
                    </button>
                  </div>
                </form>
              </div>
              <p className="text-center block">
                <FormattedMessage
                  id="auth.signup.account"
                  defaultMessage="Already have an account?"
                />{' '}
                <Link to="/login" className="link">
                  <FormattedMessage id="auth.signup.login" defaultMessage="Login" />
                </Link>
              </p>
            </div>

          </div>
        </div>
      </section>
    </Fragment>
  );
};

Appsumo.propTypes = {
  setAlert: PropTypes.func.isRequired,
  signup: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  locale: state.locale,
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(
  mapStateToProps,
  { setAlert, signup }
)(Appsumo);
