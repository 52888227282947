import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ExternalSecondBtn from '../elems/ExternalSecondBtn';
import okrImg from '../../img/empty_states/okr.png';
import ButtonGreen from '../elems/ButtonGreen';

const NoContentNew = ({
  message: { title, msg, msg2, onClick, btnTxt, secondImg, handleClick, url, btnColor },
  auth: { user, loading },
  picture,
  alt,
  admin,
  button,
  btnUrl,
  btnCta,
  externalLink,
  imgWidth,
  divStyles,
  contentStyles,
  isTask,
  containerStyles,
  isOnClick,
}) => {

  let image;
  picture ? image = picture : image = okrImg;

  let desc;
  alt ? desc = alt : desc = 'Gathering data';

  const updatePlan = <FormattedMessage id="plan.upgrade.cta" defaultMessage="Upgrade Plan" />;

  // console.log('---- externalLink', externalLink)
  // console.log('---- button', button)
  // console.log('---- btnUrl', btnUrl) 
  // console.log('---- btnColor', btnColor)

  return (
    <div className={`mh-300 br-4 brd brd-grey__light bg__white ${containerStyles ? containerStyles: 'p-3'}`}>
      {!loading && user ? (
        <Fragment>
          <div className={`flex ${divStyles ? divStyles : ''}`}>
            {!isTask ? (
              <img src={image} className={`block mxa my-2 ${imgWidth ? imgWidth : ''}`} alt={desc} />
            ) : (
              <div className="w-30">
                <img src={image} className={`block mxa my-2 ${imgWidth ? imgWidth : ''}`} alt={desc} />
              </div>
            )}

            <div className={`py-2 z ${contentStyles ? contentStyles : 'text-center'}`}>
              <p className="fs-35 lh-45 fw-600 pb-1">{title}</p>
              <p className="fs-16">{msg}</p>
              {msg2 && (<p className="meduim">{msg2}</p>)}
              {admin === 1 && (
                <div className="text-center py-2">
                  <ButtonGreen 
                    url={'/settings/pricing'}
                    cta={updatePlan}
                    color={2}
                  />
                </div>
              )}
              {button === 1 && (
                <div className="py-2 relative z-50000">
                  {externalLink !== 1 ? (
                    <ButtonGreen url={btnUrl} cta={btnCta} color={btnColor} />
                  ) : (
                    <ExternalSecondBtn url={btnUrl} cta={btnCta} />
                  )}
                </div>
              )}
              {isTask && (
                <div className='flex flex-column mt-2'>
                  <div className="w-300px">
                    <ButtonGreen cta={btnTxt} handleClick={handleClick} onClick={onClick} url={url} color={btnColor} />
                  </div>
                  <div className="mt-3 w-100">
                    <img src={secondImg.src} className={secondImg.styles} alt={secondImg.alt} />
                  </div>
                </div>
              )}
              {isOnClick && (
                <div className="py-2 relative z-50000">
                  <ButtonGreen cta={btnTxt} handleClick={true} onClick={onClick} />
                </div>
              )}
            </div>
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};

NoContentNew.propTypes = {
  auth: PropTypes.object.isRequired,
  alt: PropTypes.string
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(NoContentNew);
