import React from 'react';
import { FormattedMessage } from 'react-intl';

export const kudosCopy = {
  msgOnboarding: (
    <FormattedMessage
      id="recognition.onboarding.company.notification.msg1"
      defaultMessage="You're using the sample data below. Create the first recognition and you'll see your public praise there. Click the button below to thank you note for your co-worker."
    />
  ), 
  ctaOnboarding: (
    <FormattedMessage
      id="recognition.onboarding.company.notification.cta"
      defaultMessage="Create Kudos"
    />
  ),
  noRecognitionMessage: {
    title: (
      <FormattedMessage
        id="company.norecognition.user.title"
        defaultMessage="Kudos"
      />
    ),
    msg: (
      <FormattedMessage
        id="company.norecognition.company.msg"
        defaultMessage="It's the section for company recognition. Each appreciation will be shown at this menu."
      />
    )
  },
  updatePlanMsg: {
    title: (
      <FormattedMessage
        id="company.updateplan.title"
        defaultMessage="Update Plan"
      />
    ),
    msg: (
      <FormattedMessage
        id="company.recognition.updateplan.msg"
        defaultMessage={`Recognitions aren't available on the Free plan. Please upgrade the plan to unlock this feature.`}
      />
    )
  }
}

export default {
  kudosCopy
}