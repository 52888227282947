import {
  GET_EMAIL,
  GET_EMAILS,
  GET_CAMPAIGN,
  ADD_CAMPAIGN,
  ADD_EMAIL,
  EDIT_EMAIL,
  REMOVE_EMAIL,
  DELETE_CAMPAIGN,
  CLEAR_EMAILS,
  CAMPAIGN_ERROR,
  EMAIL_ERROR
} from "../actions/types";

const initialState = {
  campaigns: [],
  campaign: null,
  email: null,
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_EMAILS:
      return {
        ...state,
         campaigns: [],
         campaign: null,
         email: null,
         loading: true,
         error: {}
      };
    case GET_EMAILS:
      return {
        ...state,
        campaigns: payload,
        campaign: null,
        loading: false
      };
    case GET_EMAIL:
      return {
        ...state,
        email: payload.data,
        loading: false
      };
    case GET_CAMPAIGN:
      return {
        ...state,
        campaign: payload,
        loading: false
      };
    case ADD_CAMPAIGN:
    case ADD_EMAIL:
      return {
        ...state,
        campaigns: [payload, ...state.campaigns],
        loading: true
      };
    case EDIT_EMAIL:
      return {
        ...state,
        campaigns: state.campaigns.map(item =>
          item._id === payload.id ? payload.data : item
        ),
        loading: true 
      }
    case DELETE_CAMPAIGN:
      return {
        ...state,
        campaigns: state.campaigns.filter(item => item._id !== payload.id),
        loading: false
      };
    case REMOVE_EMAIL:
      return {
        ...state,
        campaigns: state.campaigns.map(email =>
          email._id === payload.campaignId
            ? {
                ...email,
                emails: email.emails.filter(
                  item => item._id !== payload.emailId
                )
              }
            : email
        ),
        loading: false
      };
    case CAMPAIGN_ERROR:
    case EMAIL_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
}
