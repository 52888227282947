import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { icons } from '../../img/elements/icons';
import { FormattedMessage } from 'react-intl';
import CustomItemCardQuestion from './CustomItemCardQuestion';
import CustomItemTitle from './CustomItemTitle';
import UniversalCommentItem from '../comment/UniversalCommentItem';
import UniversalCommentForm from '../comment/UniversalCommentForm';
import { updateCustomLike, addCustomComment, deleteCustomComment } from '../../actions/customAnswer';
import classnames from 'classnames';
import { setAlert } from '../../actions/alert';

const CustomItemCard = ({
  answer: {
    user,
    firstName,
    lastName,
    avatar,
    questions,
    answers,
    comments,
    likes,
    _id,
    updatedAt,
    notes,
    date,
    customName,
    focus,
    answerType
  },
  formData,
  setFormData,
  searchKeyword,
  mentions,
  updateCustomLike,
  addCustomComment,
  deleteCustomComment,
  auth,
  setAlert,
  checkinType,
  campaignSettings,
  lang,
}) => {
  const [displayUserAnswer, toggleAnswerButton] = useState(true);

  const [text, setText] = useState('');
  const [discussion, showComments] = useState(false);


  // Check the object length is not 0
  let objectLength = 0;
  if (focus !== undefined && focus !== null) {
    objectLength = Object.keys(focus).length;
  }
  
  const okrHead = <FormattedMessage id="focus.day.okr" defaultMessage="OKR"/>;
  const focusOnOkr = <FormattedMessage id="focus.onOkr" defaultMessage="Focus on OKR" />;

  let color;
  let label = "";

  if (objectLength > 0 && focus.isFocus) {
    if (focus.status === "1") {
      color = "green";
      label = "On Track";
    } else if (focus.status === "2") {
      color = "yellow";
      label = "Behind";
    } else if (focus.status === "3") {
      color = "red";
      label = "At Risk";
    }
  }

  const onSubmit = async (answerID, index, data, e) => {
    e.preventDefault();

    await addCustomComment(_id, answerID, data, answerType);
  };

  const findComments = comments => {
    if (comments.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const findUserLike = likes => {
    if (!auth.loading) {
      if (likes.filter(like => like.user === auth.user._id).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleLike = async (customID) => {
    try {
      await updateCustomLike(customID, checkinType, answerType);
    
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in likes', err);
    }
  };

  // ЗДЕСЬ получаем коллбэк и перебираем formData.[customs] и обновляем комменты
  const handleDeleteComment = async (answerID, commentID) => {
    try {
      await deleteCustomComment(_id, commentID, answerType);  
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in custom campigns comments', err);
    }
  };

  let standupName = lang !== 'ru' ? 'Default Daily Check-in' : 'Статус Дня'
  let planName = lang !== 'ru' ? 'Default Weekly Plan' : 'Отчет Недели'
  let updateName = lang !== 'ru' ? 'Default Weekly Update' : 'План Недели'

  if (checkinType === 2) { 
    customName = standupName
  } else if (checkinType === 3) {
    customName = planName
  } else if (checkinType === 4) {
    customName = updateName
  } else if (checkinType === 1 && customName === undefined) {
    customName = answerType === 2 ? standupName: answerType === 3 ? planName : answerType === 4 ? updateName : undefined;
  }

  // console.log('==== checkinType in ItemCard', checkinType)
  // console.log('==== customName in ItemCard', customName)
  // console.log('==== answerType in ItemCard', answerType)

  // let editLink = checkinType === 1 ? `/campaign/edit/${_id}` : checkinType === 2 ? `/checkins/edit/${_id}` : checkinType === 3 ? `/plans/complete` : `/updates/edit/${_id}`;
  let editLink = answerType === 1 ? `/campaign/edit/${_id}` : answerType === 2 ? `/checkins/edit/${_id}` : answerType === 3 ? `/plans/complete` : `/updates/edit/${_id}`;
  
  if (!answerType) {
    editLink = checkinType === 1 ? `/campaign/edit/${_id}` : checkinType === 2 ? `/checkins/edit/${_id}` : checkinType === 3 ? `/plans/complete` : `/updates/edit/${_id}`;
  }
  
  // console.log('==== editLink in ItemCard', editLink)
  
  return (
    <Fragment>
      <div className="flex">
        <CustomItemTitle 
          user={user}
          avatar={avatar}
          toggleAnswerButton={toggleAnswerButton}
          displayUserAnswer={displayUserAnswer}
          firstName={firstName}
          lastName={lastName}
          date={date}
          customName={customName}
        />
      </div>
      {displayUserAnswer && (
        <Fragment>
          <div className="p-2 br-4 mb-14 brd brd-grey__light bg__white">
            <div className="cards">
              <div className="card-team relative">
                {user && auth && user.toString() === auth.user._id.toString() && (
                   <span className="br-4 brd brd-grey__light bg__white no_btn icon__box h-34 lh-34 px-15 text-center absolute right-top">
                    {/* <Link to={`/campaign/edit/${_id}`} className="fs-14 icon__grey fw-600 flex align-center text-center"> */}
                    <Link to={editLink} className="fs-14 icon__grey fw-600 flex align-center text-center">
                      {icons.edit_small}{' '}<FormattedMessage id="edit" defaultMessage="Edit" />
                    </Link>
                  </span>
                )}
                <div className="card-standups-team card-body-hidden pb-0 active">
                  {answers.map((answer, index) => (
                    <CustomItemCardQuestion
                      key={`teamAnswer-${_id}-${index}`}
                      customID={_id}
                      question={answer.question}
                      answer={answer}
                      userID={user}
                      avatar={avatar}
                      comments={comments.filter((comment) => comment.answerID === answer._id.toString())}
                      likes={likes.filter((like) => like.answerID === answer._id.toString())}
                      index={index}
                      formData={formData}
                      setFormData={setFormData}
                      searchKeyword={searchKeyword}
                      mentions={mentions}
                      checkinType={checkinType}
                      campaignSettings={campaignSettings}
                      answerType={answerType}
                    />
                  ))}

                  {notes && (
                    <div className="notes">
                      <div className="line__grey my-2"></div>
                      <div className="flex align-center">
                        <span className="mr-14 flex align-center">{icons.arrow_right}</span>{' '}
                        <p className={`fs-12 uppercase bold mb-0`}>
                          <FormattedMessage id="notes" defaultMessage="Notes" />
                        </p>
                      </div>
                      <div className="fs-16 ml-38 mt-1">{notes}</div>
                    </div>
                  )}

                  {objectLength > 0 && focus.isFocus && (
                    <div className={`my-2`}>
                      <div className="flex align-center">
                        <span className="mr-14 flex align-center">
                          {icons.arrow_right}
                        </span>
                        {' '}  
                        <p className={`fs-12 uppercase bold mb-0`}>
                          <FormattedMessage id="focus" defaultMessage="Focus" />{' '}
                        </p>
                      </div>
                      <div className="notes ml-38">
                        <Fragment>
                          {focus.focusIndex === 99 ? (
                            <p className="fs-16 text-left"><b>{focusOnOkr}:</b>{' '}{focus.objective}</p>
                          ) : (
                            <Fragment>
                              <div className="flex align-center">
                                <p className="fs-16 text-left"><b>{okrHead}:</b>{' '}{focus.objective}</p>
                                <span className="mx-8 flex align-center">{icons.arrow_right}</span>
                                <p className="fs-14 text-left">{focus.text}</p>
                              </div>
                            </Fragment>
                          )}
                          <div className="flex align-center">
                            <p className="text-left fs-16 bold mb-0">{focus.progress}%</p>
                            <span className="fs-16 mx-xs">/</span>
                            <p className="text-left fs-12 mr-1">{focus.goal}%</p>
                            <p className={`text-left fs-8 uppercase white br-2 brd bg-${color} px-xs lh-16`}>{label}</p>
                          </div>
                        </Fragment>
                      </div>
                    </div>
                  )}

                    <Fragment>
                      <div className="btn-feedback flex align-center pb-0 pt-14 px-0 ml-38">
                        <button
                          type="button"
                          className={classnames('btn-icons btn-like flex align-center mr-14', {
                            'btn-active-icon btn-like__active': findUserLike(likes)
                          })}
                          onClick={e => handleLike(_id)}
                        >
                          <span className="mr-xs flex align-center">{icons.heart}{icons.heart_full}</span>
                          <span className='fs-10 bold mb-0'>{likes.length > 0 ? likes.length : '0'}</span>
                        </button>

                        <button
                          type="button"
                          onClick={() => showComments(!discussion)}
                          className={classnames('btn-icons btn-comment flex align-center icon__message__badge', {
                            'btn-active-icon': findComments(comments)
                          })}
                        >
                          <span className="mr-xs flex align-center">{icons.message}</span>
                          <span className="fs-10 bold mb-0">{comments.length > 0 ? comments.length : '0'}</span>
                        </button>
                      </div>
                    </Fragment>

                    {discussion && (
                      <Fragment>
                        <div className="card-feedback mt-24">
                          <div className="comment">
                            <div className="mb-1">
                              {comments.map(comment => (
                                <UniversalCommentItem
                                  key={`team-${comment._id}`}
                                  mainItemId={0}
                                  item={comment}
                                  value={comment.text}
                                  authUser={auth.user}
                                  isEdit={false}
                                  searchKeyword={searchKeyword}
                                  handleDelete={handleDeleteComment}
                                  isHideSearch={false}
                                />
                              ))}
                            </div>

                            <div className="ml-38">
                              <UniversalCommentForm
                                mainItemId={0}
                                index={0}
                                value={text}
                                avatar={auth.user.avatar}
                                onSubmit={onSubmit}
                                onChange={e => setText(e.target.value)}
                                mentions={mentions}
                              />
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

CustomItemCard.propTypes = {
  answer: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  updateCustomLike: PropTypes.func.isRequired,
  addCustomComment: PropTypes.func.isRequired,
  deleteCustomComment: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { updateCustomLike, addCustomComment, deleteCustomComment, setAlert }
)(CustomItemCard);
