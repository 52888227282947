import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import Avatar from "../elems/Avatar";
import Moment from 'react-moment';

const TableLineNew = ({
  item: {
    _id: user,
    firstName,
    lastName,
    avatar,
  },
  linkName,
  userAnswers,
}) => {

  let lastAnswer = userAnswers && userAnswers.length > 0 ? <Moment local format="D MMM YYYY">{userAnswers[0].date}</Moment> : '-'

  return (

    <Fragment>
      <div className="pb-2">
        <div className="flex align-center">
            <div className="flex align-center w-80">
              <Link to={`/user/${user}/${linkName}`} className={`flex align-center`}>
                <Avatar
                  id={user}
                  name={firstName}
                  lastName={lastName}
                  avatar={avatar}
                  noLink={true}
                  width={"26"}
                  styles={'mr-xs flex align-center'}
                />

                <p className="fs-14">{firstName}{' '}{lastName}</p>
              </Link>
            </div>
            <p className="w-100px fs-14 mla text-right">{lastAnswer}</p>
          </div>
      </div>
    </Fragment>
  );
};

TableLineNew.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TableLineNew;
