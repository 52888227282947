import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { icons } from '../../img/elements/icons';
import { FormattedMessage } from 'react-intl';
import PlanItemCardQuestion from './PlanItemCardQuestion';

const PlanItemCard = ({
  planAnswer: {
    user,
    firstName,
    lastName,
    avatar,
    questions,
    answers,
    comments,
    likes,
    _id,
    updatedAt,
    notes,
    focus,
    planID,
    campaignType,
    okrAnswers,
    weeklyOkrs,
    date,
  },
  formData,
  setFormData,
  searchKeyword,
  settingsID,
  auth,
  mentions,
}) => {
  const [displayUserAnswer, toggleAnswerButton] = useState(true);

  // Check the object length is not 0
  let objectLength = 0;
  if (focus !== undefined && focus !== null) {
    objectLength = Object.keys(focus).length;
  }
  
  const focusHead = <FormattedMessage id="focus.day.focus" defaultMessage="Focus"/>;
  const okrHead = <FormattedMessage id="focus.day.okr" defaultMessage="OKR"/>;
  const currentlLabel = <FormattedMessage id="widget.main.current" defaultMessage="Current"/>;
  const goalLabel = <FormattedMessage id="widget.main.goal" defaultMessage="Goal"/>;
  const focusOnOkr = <FormattedMessage id="focus.onOkr" defaultMessage="Focus on OKR" />;

  let color;
  let label = "";

  if (objectLength > 0 && focus.isFocus) {
    if (focus.status === "1") {
      color = "green";
      label = "On Track";
    } else if (focus.status === "2") {
      color = "yellow";
      label = "Behind";
    } else if (focus.status === "3") {
      color = "red";
      label = "At Risk";
    }
  }

  // console.log('===== objectLength', objectLength)

  return (
    <Fragment>
      <div className="card-team">
        <div className="flex space-between align-center">
          <div 
            className="user flex align-center cursor" 
            onClick={() => toggleAnswerButton(!displayUserAnswer)}
            >
            <Link to={user ? `/user/${user}` : '#!'} className="flex align-center">
            <img
              src={avatar}
              alt=""
              className="round-img avatar mr-24"
              width="40px"
              height="40px"
            />
            </Link>
            <p className="large bold">
              {firstName} {lastName}
            </p>
          </div>

          <p className="fs-14 grey_new mla">
            <Moment format="D MMM">{date}</Moment>
          </p>

          <div className="card-icons">
            <button
              onClick={() => toggleAnswerButton(!displayUserAnswer)}
              className="btn-action no_btn"
              type="button"
            >
              {icons.dropdown_icon}
            </button>
          </div>

          {planID !== null && planID !== false && auth === user && planID === settingsID && (
            <div className="btn-action no_btn ml-1 relative" type="button">
              {icons.more}

              <div className="dropdown p-1 light-shadow">
                <Link to={`/plans/complete`} className="small secondary-font">
                  <i className="fas fa-pencil-alt" />{' '} 
                  <FormattedMessage
                    id="edit.answer"
                    defaultMessage="Edit Answer"
                  />
                </Link>
              </div>
            </div>
          )}
        </div>


        {displayUserAnswer && (
          <div className="card-standups-team card-body-hidden pb-0 mt-24 active">
            {campaignType === 1 || okrAnswers === undefined ? (
              <Fragment>
                {answers.map((answer, index) => (
                  <PlanItemCardQuestion
                    key={`teamAnswer-${_id}-${index}`}
                    planID={_id}
                    question={answer.question}
                    answer={answer}
                    userID={user}
                    avatar={avatar}
                    comments={comments.filter(
                      comment => comment.answerID === answer._id.toString()
                    )}
                    likes={likes.filter(
                      like => like.answerID === answer._id.toString()
                    )}
                    index={index}
                    formData={formData}
                    setFormData={setFormData}
                    searchKeyword={searchKeyword}
                    mentions={mentions}
                    campaignType={campaignType}
                  />
                ))}
              </Fragment>
            ) : (
              <Fragment>
                {answers.map((answer, i) => (
                  answer.okrAnswers.length > 0 && answer.okrAnswers.map((item, index) => (
                    <PlanItemCardQuestion
                      key={`teamAnswer-${_id}-${index}`}
                      planID={_id}
                      question={item.question}
                      answer={item}
                      userID={user}
                      avatar={avatar}
                      comments={comments.filter(
                        comment => comment.answerID === item._id.toString()
                      )}
                      likes={likes.filter(
                        like => like.answerID === item._id.toString()
                      )}
                      index={index}
                      formData={formData}
                      setFormData={setFormData}
                      searchKeyword={searchKeyword}
                      mentions={mentions}
                      campaignType={campaignType}
                      weeklyOkrs={weeklyOkrs}
                    />
                  ))
                ))
                }
              </Fragment>
            )}

            {notes && (
              <div className="notes">
                <div className="line__grey my-2"></div>
                <div className="flex align-center">
                  <span className="mr-14 flex align-center">
                    {icons.arrow_right}
                  </span>
                  {' '}  
                  <p className={`fs-12 uppercase bold mb-0`}>
                    <FormattedMessage id="notes" defaultMessage="Notes" />
                  </p>
                </div>
                <div className="fs-16 ml-38 mt-1">
                  {notes}
                </div>
              </div>
            )}

            {objectLength > 0 && focus.isFocus && (
              <div className={`my-2`}>
                <div className="flex align-center">
                  <span className="mr-14 flex align-center">
                    {icons.arrow_right}
                  </span>
                  {' '}  
                  <p className={`fs-12 uppercase bold mb-0`}>
                    <FormattedMessage id="daily.focus" defaultMessage="Daily focus" />{' '}
                  </p>
                </div>
                <div className="notes ml-38">
                  <Fragment>
                    {focus.focusIndex === 99 ? (
                      <p className="fs-16 text-left"><b>{focusOnOkr}:</b>{' '}{focus.objective}</p>
                    ) : (
                      <Fragment>
                        <div className="flex align-center">
                          <p className="fs-16 text-left"><b>{okrHead}:</b>{' '}{focus.objective}</p>
                          <span className="mx-8 flex align-center">{icons.arrow_right}</span>
                          <p className="fs-14 text-left">{focus.text}</p>
                        </div>
                      </Fragment>
                    )}
                    <div className="flex align-center">
                      <p className="text-left fs-16 bold mb-0">{focus.progress}%</p>
                      <span className="fs-16 mx-xs">/</span>
                      <p className="text-left fs-12 mr-1">{focus.goal}%</p>
                      <p className={`text-left fs-8 uppercase white br-2 brd bg-${color} px-xs lh-16`}>{label}</p>
                    </div>
                  </Fragment>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

PlanItemCard.propTypes = {
  planAnswer: PropTypes.object.isRequired,
};

export default PlanItemCard;
