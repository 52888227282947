import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { setAlert } from '../../../actions/alert';
import OkrStatus from '../elements/OkrStatus';
import OkrUpdate from '../elements/OkrUpdate';
import OkrDueIn from '../elements/OkrDueIn';
import OkrProgress from '../elements/OkrProgress';
import ShareIcon from '../../elems/icons/ShareIcon';
import CopyIcon from '../../elems/icons/CopyIcon';
import Moment from 'react-moment';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../layout/Spinner';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { icons } from '../../../img/elements/icons';

const DetailsTitle = ({
  item: { title, status, progress, updatedAt, isObjective, goal, current, teamName, dueIn },
  handleDetails,
  detailsId,
  okrId,
  updateMode,
  statusOptions,
  choosedStatus,
  handleStatus,
  history,
  isGoBackLink,
  goBackLink
}) => {
  const domain = 'https://app.usefocus.co/objectives';

  const [shareLink, setShareLink] = useState({
    url: '',
    copied: false
  });

  const [link, showLink] = useState(false);
  let { url, copied } = shareLink;

  const goBack = () => {
    if (history !== undefined) {
      return history.goBack();
    } else {
      return goBackLink;
    }
  };

  useEffect(() => {
    setShareLink({
      ...shareLink,
      url: detailsId ? `${domain}/${detailsId}` : domain
    });
  }, [detailsId]);

  let btnClass;
  let label = '';

  if (status === 1) {
    btnClass = 'green';
    label = 'On Track';
  } else if (status === 2) {
    btnClass = 'yellow';
    label = 'Behind';
  } else if (status === 3) {
    btnClass = 'red';
    label = 'At Risk';
  }

  return (
    <Fragment>
      <div className="flex align-start space-between">
        <div className="flex align-center column w-50">
          <p className="fs-12 w-100">{teamName}</p>
          <p className="fs-2 lh-25 w-100 fw-600">{title}</p>
          <div className="okr__status flex w-100 mt-2 text-left">
            <div className="mr-1">
              <OkrStatus
                btnClass={btnClass !== undefined && btnClass}
                label={label}
                updateMode={updateMode}
                handleStatus={handleStatus}
                statusOptions={statusOptions}
                choosedStatus={choosedStatus}
              />
            </div>
            <OkrProgress btnClass={btnClass !== undefined && btnClass} progress={Math.round(progress)} />
          </div>
          <div className="w-100 flex text-left mt-15">
            <OkrDueIn dueIn={dueIn} />
            <span className="fs-10 px-xs">|</span>
            <OkrUpdate updatedAt={updatedAt} />
          </div>
        </div>

        {!isObjective && (
          <p className="mla mr-2 medium bold">
            {current} / {goal}
          </p>
        )}

        <div className="mla relative mr-2">
          <span onClick={() => showLink(!link)} className="cursor icon__grey_hover_blue fs-22">
            {icons.share}
          </span>

          <div
            className={`${
              !link ? 'display-none' : 'display-block'
            } w-400px p-1 bg-white absolute right top-15 light-shadow flex align-center`}
          >
            <input value={url} disabled className="w-90 medium px-1 bg-white br-6" />

            <CopyToClipboard text={url} onCopy={() => setShareLink({ ...shareLink, copied: true })}>
              <span className="ml-1">
                <CopyIcon styles={'w-23px'} />
              </span>
            </CopyToClipboard>

            {copied ? <span style={{ color: 'green' }}>Copied.</span> : null}

            <span onClick={() => showLink(!link)} className="cursor icon__grey">
              {icons.circle_close}
            </span>
          </div>
        </div>

        {!isGoBackLink ? (
          <span onClick={() => handleDetails(detailsId, okrId)} className="cursor icon__grey">
            {icons.circle_close}
          </span>
        ) : (
          <span onClick={() => goBack()} className="cursor icon__grey">
            {icons.circle_close}
          </span>
        )}
      </div>
    </Fragment>
  );
};

DetailsTitle.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  detailsId: PropTypes.string.isRequired,
  okrId: PropTypes.string.isRequired,
  updateMode: PropTypes.bool.isRequired,
  handleDetails: PropTypes.func.isRequired,
  handleStatus: PropTypes.func.isRequired,
  deleteOkr: PropTypes.func,
  statusOptions: PropTypes.array.isRequired,
  locale: PropTypes.object,
  choosedStatus: PropTypes.object.isRequired
};

export default DetailsTitle;
