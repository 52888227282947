import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Avatar = ({ id, avatar, name, lastName, noLink, width, styles }) => {
  return (
    <Fragment>
    {!noLink ? (
      <div className="avatar mr-1">
        <Link to={`/user/${id}`} className={`user ${styles} flex align-center`}>
          <img
            src={avatar && avatar}
            alt={`${name}'s picture`}
            className="round-img d-block avatar"
            width={width}
            height={width}
          />
        </Link>
      </div>
    ) : (
      <div className="avatar mr-1">
        <span className={`user ${styles} flex align-center`}>
          <img
            src={avatar && avatar}
            alt={`${name}'s picture`}
            className="round-img d-block avatar"
            width={width}
            height={width}
          />
        </span>
      </div>
    )}
    </Fragment>
  );
};

Avatar.propTypes = {
  avatar: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  lastName: PropTypes.string,
  noLink: PropTypes.bool,
  id: PropTypes.string,
};

export default Avatar;
