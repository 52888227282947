import React, { Fragment, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { resend, toggleResend } from '../../actions/auth';
import Navbar from '../layout/Navbar';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const Resend = ({ setAlert, resend, isAuthenticated, token, toggleResend, locale: { lang } }) => {
  const [formData, setFormData] = useState({
    email: ''
  });

  const [resendForm, toggleForm] = useState(true);

  const { email } = formData;

  useEffect(() => {
    if (token === 'resent') {
      toggleForm(!resendForm);
    }
  }, [token]);

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();

    resend(email);
  };

  const handleToggle = e => {
    e.preventDefault();

    toggleForm(!resendForm);

    toggleResend();
  };

  // Redirect if signed up
  if (isAuthenticated) {
    return <Redirect to="/dashboard/objectives" />;
  }

  return (
    <Fragment>
      <section id="signup">
        {resendForm ? (
          <div className="container pt-5">
            <div className="section_header_new mb-2">
              <Navbar guest={true} />
              <h2 className="h2_new">
                <FormattedMessage
                  id="auth.resend.text"
                  defaultMessage="Didn't receive an email ?"
                />
              </h2>
              <p className="lead lead_new lh-20">
                <FormattedMessage
                  id="auth.resend.cta"
                  defaultMessage="Please enter your email address and we resend you an email."
                />
              </p>
            </div>

            <div className="form form_new form-detail mb-2">
              <form onSubmit={e => onSubmit(e)}>

                <div className="field_email field mb-2_5">
                  <FormattedMessage
                    id="auth.signup.yourEmailInput"
                    defaultMessage="Email"
                  >
                    {msg => (
                      <input
                        type="email"
                        className="email email_new"
                        placeholder={msg}
                        name="email"
                        value={email}
                        onChange={e => onChange(e)}
                        required
                      />
                    )}
                  </FormattedMessage>
                </div>
                <div className="form_button">
                  <button type="submit" className="btn btn_new btn-primary">
                    {' '}
                    <FormattedMessage
                      id="auth.resend.btn.main"
                      defaultMessage="Resend Email"
                    />
                  </button>
                </div>
              </form>
            </div>
            <div className='section_footer_new'>
              <Link to="/signup" className="medium cursor dark-gray medium_new link-auth_new">
                <FormattedMessage id="auth.goback.signup" defaultMessage="Back to Sign Up" />
              </Link>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="text-center p-3 p-3-xs">
              <p className="fs-40 lh-50 mb-1 mb-3-xs green bold">
                <FormattedMessage
                  id="auth.resend.success.title"
                  defaultMessage="Email sent"
                />
              </p>
              <p className="fs-2 lh-30 w-80 w-100-xs mxa">
                <FormattedMessage
                  id="auth.resend.success.text"
                  defaultMessage="If you do not receive the confirmation message within a few minutes of signing up, please check your Spam folder just in case the confirmation email."
                />
              </p>
            </div>

            <p
              className="text-center block grey cursor"
              onClick={(e) => handleToggle(e)}>
              <FormattedMessage id="auth.resend.again" defaultMessage="Resend an email again" />
            </p>
          </div>
        )}

      </section>
    </Fragment>
  );
};

Resend.propTypes = {
  setAlert: PropTypes.func.isRequired,
  resend: PropTypes.func.isRequired,
  toggleResend: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  token: PropTypes.string
};

const mapStateToProps = state => ({
  locale: state.locale,
  isAuthenticated: state.auth.isAuthenticated,
  token: state.auth.token
});

export default connect(
  mapStateToProps,
  { setAlert, resend, toggleResend }
)(Resend);
