import React, { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserCompany } from '../../actions/company';
import { getSlackTeam, getSlackChannels, editSlackTeam } from '../../actions/slack';
import { getCompanyProfiles } from '../../actions/profile';
import Logo from '../elems/Logo';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import IntervalItem from '../admin/elems/IntervalItem';
import { setAlert } from '../../actions/alert';
import 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';

const EditOkrSettings = ({
  setAlert,
  editSlackTeam,
  getSlackTeam,
  auth: { user },
  history,
  getUserCompany,
  getCompanyProfiles,
  company: { company },
  slack: { loading, channels, team },
  getSlackChannels,
}) => {
  const [formData, setFormData] = useState({
    okrIsActive: team && team.okrIsActive,
    okrPushActive: team && team.okrPushActive,
    selectedChannelOption: [],
    okrPushInterval: '',
    okrReportChannels: team && team.okrReportChannels,
  });

   const {
    okrIsActive, okrPushActive, selectedChannelOption,
    okrPushInterval, okrReportChannels,
  } = formData;

  const [reportChannels, toggleReportChannels] = useState(false);
  let channelOptions = [];
  let choosedChannels = [];

  // Channels Options for Select format
  channels &&
    channels.forEach(channel => {
      let u = {
        value: channel.channel_id,
        label: channel.name
      };

      channelOptions.push(u);
    });

  // Users & Channels Default values for Select format
  team && team.okrReportChannels &&
    team.okrReportChannels.forEach(item => {

      // For channels
      if (item.channel_id) {
        let с = {
          value: item.channel_id,
          label: item.name
        };

        choosedChannels.push(с);
      }
    });

  let interval = team && team.okrPushInterval !== undefined ? team.okrPushInterval : 'Biweekly';

  useEffect(() => {
    getUserCompany();
    user && getSlackTeam();
    company && company.name && getSlackChannels(company._id, company.slackTeamId);

    setFormData({
      ...formData,
      okrIsActive: !team ? false : team.okrIsActive,
      okrPushActive: !team ? false : team.okrPushActive,
      okrPushInterval: !team ? '' : interval,
      selectedChannelOption: !team ? '' : choosedChannels,
      okrReportChannels: !team ? false : team.okrReportChannels,
    });
  }, [loading, getSlackTeam, getCompanyProfiles, getUserCompany, getSlackChannels,
    team && team.scope && team.scope.length,
    company && company.departments && company.departments.length,
  ]);

  const handleChangeChannelReport = selectedChannelOption => {
    setFormData({ ...formData, selectedChannelOption: selectedChannelOption });
  };

  const onChangeSelect = (e) => {
    setFormData({ ...formData, okrPushInterval: e.target.value });
  };

  const onSubmit = e => {
    e.preventDefault();

    if (okrIsActive && selectedChannelOption.length === 0) {

        setAlert(
          <FormattedMessage
            id="channel.updates.okr"
            defaultMessage="Please specify channel for OKR updates"
          />,
          'danger'
        );
    } else {
      editSlackTeam(formData, history);
    }
  };

  const title = <FormattedMessage id="edit.slack.okr" defaultMessage="Slack bot: OKR settings" />;
  const subTitle = <FormattedMessage id="edit.slack.okr.subtitle" defaultMessage="Edit OKR settings in the Slack bot" />;
  const okrUpdates =<FormattedMessage id="okr.updates" defaultMessage="OKR Updates" />;
  const okrUpdatesTxt =<FormattedMessage id="okr.updates.txt" defaultMessage="The Focus bot will send notification in public channel when OKR is updated" />;
  const statusField =<FormattedMessage id="status" defaultMessage="Status" />;
  const okrUpdatesInput =<FormattedMessage id="channel" defaultMessage="Public Channel" />;
  const okrReminder =<FormattedMessage id="reminder" defaultMessage="Reminder" />;
  const okrReminderTxt =<FormattedMessage id="reminder.text" defaultMessage="The Focus bot will send notification for users if they didn't update OKR for some time" />;
  const publicChannel = <FormattedMessage
      id="public.channel.details"
      defaultMessage="Choose <b>a public channel</b> to get notifications about OKRs. All members of this channel will receive notifications. <b>NOTE:</b> Please add the Focus bot to <b>this channel</b>. The bot can publish notifications <b>only</b> if it's a member of the channel."
      values={{ b: (...chunks) => <b>{chunks}</b> }}
  />

  return (
    <Fragment>
      <div className="css-main bg-primary">
          <div className="wrapper">
            <div className="main_menu absolute"><Logo /></div>
            <UserNav />

            <Fragment>
              {!team || loading ? (
                <Spinner />
              ) : (
                  <div className="main mxa pl-0 main-profile-admin">
                    <div className="standups-admin pt-3 mt-0-xs pt-1-xs">
                      <div className="section-title">
                        <h5>
                          {title}
                        </h5>
                        <Link
                          to="/settings/integrations"
                          className="btn-secondary btn-secondary-back"
                        >
                          <FormattedMessage id="go.back" defaultMessage="Go Back" />
                        </Link>
                      </div>

                      <div className="component edit-updates-weekly my-2 p-3 light-shadow">
                        <div className="form-full">
                          <form
                            className="form-app form-app-update"
                            onSubmit={e => onSubmit(e)}
                          >
                          <p className="sub-title focus-yellow focus my-2">{okrUpdates}</p>
                          <p className="meduim">{okrUpdatesTxt}</p>

                          <Fragment>
                            
                            <div className="form-main form-main-inline standups-time mt-2">
                              <p className="bold medium block question">
                                {statusField}
                              </p>

                              <div className="checkbox status pl-3 w-30">
                                <div className="checkbox-item active-status">
                                  <input
                                    type="checkbox"
                                    id="okrIsActive"
                                    name="okrIsActive"
                                    checked={okrIsActive}
                                    value={okrIsActive}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        okrIsActive: !okrIsActive
                                      });
                                    }}
                                  />
                                  <label htmlFor="okrIsActive">
                                    {okrIsActive ? (<FormattedMessage id="admin.dashboard.edit.slack.active" defaultMessage="Active" />) : (<FormattedMessage id="admin.dashboard.edit.slack.disable" defaultMessage="Disabled" />)}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                          
                          <Fragment>
                            <Fragment>
                              <div className="form-main form-main-inline mt-2">
                                <p className="bold medium block question w-100-xs w-30">{okrUpdatesInput}</p>
                                <div className="w-unset pl-3 w-30 pt-">
                                  {choosedChannels &&
                                  choosedChannels.length > 0 && (
                                    choosedChannels.map(item => (
                                      <p
                                        className="small w-100 inline"
                                        key={item.value}
                                      >
                                        {' '}
                                        {item.label}
                                      </p>
                                    ))
                                  )}
                                </div>

                                <span
                                  className="btn-secondary btn-secondary-back"
                                  onClick={e => toggleReportChannels(!reportChannels)}
                                >
                                  {reportChannels ? (<FormattedMessage id="hide.channels" defaultMessage="Hide Channels" />) : (<FormattedMessage id="choose.channels" defaultMessage="Choose Channel" />)}
                                </span>
                              </div>
                          </Fragment>

                          {reportChannels && (
                            <Fragment>
                              <p className="medium block">{publicChannel}</p>
                              <div>
                                <p className="bold medium block question">{okrUpdatesInput}</p>
                                <FormattedMessage
                                  id="admin.dashboard.edit.reports.channelPlaceholder"
                                  defaultMessage="Add channels"
                                >
                                  {msg => (
                                    <Select
                                      key={channels && `${channels._id}_sl_ch`}
                                      defaultValue={choosedChannels}
                                      onChange={selectedChannelOption =>
                                        handleChangeChannelReport(selectedChannelOption)
                                      }
                                      options={channelOptions}
                                      isSearchable
                                      placeholder={msg}
                                    />
                                  )}
                                  </FormattedMessage>
                                </div>
                            </Fragment>
                          )}
                          </Fragment>

                          <Fragment>
                            <p className="sub-title focus-yellow focus my-2">{okrReminder}</p>
                            <p className="meduim">{okrReminderTxt}</p>
                            <div className="form-main form-main-inline standups-time mt-2">
                              <p className="bold medium block question w-100-xs">{statusField}</p>
                              <div className="checkbox email pl-3">
                                <div className="checkbox-item active-status mr-5">
                                  <input
                                    type="checkbox"
                                    id="okrPushActive"
                                    name="okrPushActive"
                                    checked={okrPushActive}
                                    value={okrPushActive}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        okrPushActive: !okrPushActive
                                      });
                                    }}
                                  />
                                  <label htmlFor="okrPushActive">
                                    {okrPushActive ? (<FormattedMessage id="admin.dashboard.edit.slack.active" defaultMessage="Active" />) : (<FormattedMessage id="admin.dashboard.edit.slack.disable" defaultMessage="Disabled" />)}
                                  </label>
                                </div>
                              </div>

                              <IntervalItem
                                interval={okrPushInterval}
                                isCustom={false}
                                onChange={onChangeSelect}
                                styles={'small-input mr-5'}
                              />
                            </div>
                          </Fragment>

                            <button type="submit" className="btn btn-form">
                              <FormattedMessage id="admin.dashboard.edit.submit" defaultMessage="Submit" />
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

EditOkrSettings.propTypes = {
  editSlackTeam: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getSlackUsers: PropTypes.func,
  getSlackChannels: PropTypes.func,
  getCompanyProfiles: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  getSlackTeam: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  slack: PropTypes.object,
};

const mapStateToProps = state => ({
  company: state.company,
  slack: state.slack,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { 
    getUserCompany,
    getSlackChannels,
    getCompanyProfiles,
    getSlackTeam,
    editSlackTeam,
    setAlert,
  }
)(withRouter(EditOkrSettings));