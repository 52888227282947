import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import OkrProgress from '../elements/OkrProgress';
import OkrStatus from '../elements/OkrStatus';
import OkrMoreIcons from "../elements/OkrMoreIcons";

const TreeTitle = ({ 
  _id, 
  objective,
  status, 
  btnClass, 
  progress, 
  keyResults, 
  teamName, 
  handleArchive,
  handleDelete,
  isArchive,
  archive,
  user,
  owner,
  creator,
}) => {

  // type - 1 => Company
  // type - 2 => Team
  // type - 3 => User

  const statusOptions = [
    { value: 1, label: 'On Track', color: '#6ddeb3' },
    { value: 2, label: 'Behind', color: '#facf34' },
    { value: 3, label: 'At Risk', color: 'red' }
  ];
  let choosedStatus = {};

  status &&
    statusOptions.forEach((item) => {
      if (item.value === status) {
        choosedStatus = {
          value: item.value,
          label: item.label,
          color: item.color
        };
      }
    });

  return (
    <Fragment>
      <div className="relative flex flex-column px-3 h-120px">
        <span className={`fs-12 dark-grey`}>{teamName}</span>

        <p className="fs-2 fw-600 w-100">{objective}</p>

        <div className="flex align-center mt-1">
          <div className="mr-1">
            <OkrStatus
              btnClass={btnClass !== undefined && btnClass}
              label={choosedStatus.label}
              updateMode={false}
              handleStatus={() => {}}
              statusOptions={statusOptions}
              choosedStatus={choosedStatus}
            />
          </div>
          <OkrProgress
            btnClass={btnClass !== undefined && btnClass}
            progress={progress}
            keyResults={keyResults}
          />
        </div>

        <div className="absolute right-10">
          <OkrMoreIcons
            _id={_id}
            handleArchive={handleArchive}
            handleDelete={handleDelete}
            isArchive={isArchive}
            archive={archive}
            user={user}
            owner={owner}
            creator={creator}
          />
        </div>
      </div>
    </Fragment>
  );
};

TreeTitle.propTypes = {
  color: PropTypes.string.isRequired
};

export default TreeTitle;
