import axios from 'axios';
import { setAlert } from './alert';
import store from '../store';
import { updateHomeLikeAnswer, addHomeCommentAnswer, deleteHomeCommentAnswer } from './home';

import {
  GET_USER_UPDATE_ANSWERS,
  GET_PROFILE_UPDATE_ANSWERS,
  GET_TEAM_UPDATE_ANSWERS,
  GET_UPDATE_ANSWER,
  UPDATE_ANSWER_ERROR,
  UPDATE_ANSWERS_ERROR,
  ADD_UPDATE_ANSWER,
  UPDATE_LIKES,
  ADD_COMMENT,
  UPDATE_COMMENT,
  GET_COMPANY_UPDATE_ANSWERS,
  COMPANY_UPDATE_ANSWER_ERROR,
  REMOVE_COMMENT,
  CLEAR_UPDATE_ANSWERS,
  LOCATION_CHANGE_ERROR_UPDATE,
  GET_UPDATE_ANSWER_BY_ID,
  EDIT_UPDATE_ANSWER,
} from './types';

// Get all current user answers
export const getUserUpdateAnswers = () => async dispatch => {
  try {
    const res = await axios.get('/api/updateanswers/user');
    
    dispatch({
      type: GET_USER_UPDATE_ANSWERS,
      payload: { answers: res.data.userAnswers, last: res.data.lastAnswer }
    });
  } catch (err) {
    dispatch({
      type: UPDATE_ANSWERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get the last answer from current user 
export const getUserUpdateAnswer = () => async dispatch => {
  try {
    const res = await axios.get('/api/updateanswers/last');

    dispatch({
      type: GET_UPDATE_ANSWER,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: UPDATE_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all user team answers
export const getTeamUpdateAnswers = () => async dispatch => {
  try {
    const res = await axios.get('/api/updateanswers/team');

    dispatch({
      type: GET_TEAM_UPDATE_ANSWERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: UPDATE_ANSWERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all company answers
export const getCompanyUpdateAnswers = ({limit,user}={}) => async dispatch => {
  try {
    const params = {};
    if(limit) params.limit = limit;
    if(user)params.user = user;
    const res = await axios.get('/api/updateanswers/company',{params});

    dispatch({
      type: GET_COMPANY_UPDATE_ANSWERS,
      payload: { company: res.data.companyAnswers, user: res.data.userAnswer }
    });
    return true;
  } catch (err) {
    dispatch({
      type: COMPANY_UPDATE_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    return false;
  }
};

export const getAnswerById = (id) => async dispatch => {
  try {
    const res = await axios.get(`/api/updateanswers/${id}`);

    dispatch({
      type: GET_UPDATE_ANSWER_BY_ID,
      payload: res.data
    });
    return true;
  } catch (err) {
    dispatch({
      type: UPDATE_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    return false
  }
};

// Get user answers by user ID
export const getUpdateUserAnswersById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/updateanswers/user/${id}`);

    dispatch({
      type: GET_PROFILE_UPDATE_ANSWERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: UPDATE_ANSWERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all team answers by team ID
export const getTeamUpdateAnswersById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/updateanswers/team/${id}`);
   
    dispatch({
      type: GET_TEAM_UPDATE_ANSWERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: UPDATE_ANSWERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add answer on update
export const addUpdateAnswer = (
  answers,
  focus,
  updateID,
  weeklyOkrs,
  history,
  notes
) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({ answers, focus, notes, weeklyOkrs });

    const res = await axios.post(
      `/api/updateanswers/${updateID}`,
      body,
      config
    );

    dispatch({
      type: ADD_UPDATE_ANSWER,
      payload: res.data
    });

    dispatch(setAlert('Update was completed', 'success', 3000));

    if (history) {
      history.goBack();
    } else {
      history.push('/campaigns/updates')
    }

  } catch (err) {
    const errors = err.response.data.errors;
    console.log('Errors', errors);

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: UPDATE_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//editAnswer
export const editAnswer = ({answers, focus, answerId, notes}) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {

    const res = await axios.put(
      `/api/updateanswers/${answerId}`,
      {
        answers, 
        focus,
        notes
      },
      config
    );

    dispatch({
      type: EDIT_UPDATE_ANSWER,
      payload: res.data
    });

    dispatch(setAlert('Updated', 'success', 3000));

   return true;
  } catch (err) {
    dispatch({
      type: UPDATE_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    return false;
  }
};

// Add & Remove like
export const updateUpdateLike = (updateID, answerID) => async dispatch => {
  try {
    const res = await axios.put(
      `/api/updateanswers/like/${updateID}/${answerID}`
    );
    
    dispatch({
      type: UPDATE_LIKES,
      payload: { updateID, likes: res.data.likes, answers: res.data.answers }
    });

    const home = store.getState().home;

    // Обновляем редюсер Home, если он не пустой
    // https://stackoverflow.com/questions/35667249/accessing-redux-state-in-an-action-creator
    // https://redux.js.org/api/store#dispatchaction
    if (home.data.length > 0) {
      store.dispatch(updateHomeLikeAnswer({ id: updateID, likes: res.data.likes, answers: res.data.answers }))
    }

    // Callback for updating in formData.updates in useState to render it
    const data = { updateID, likes: res.data.likes, answers: res.data.answers }
    // console.log('===== data in acitons', data)   
    return data;
  } catch (err) {
    dispatch({
      type: UPDATE_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add comment
export const addUpdateComment = (
  updateID,
  answerID,
  formData
) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const res = await axios.post(
      `/api/updateanswers/comment/${updateID}/${answerID}`,
      formData,
      config
    );

    dispatch({
      type: ADD_COMMENT,
      payload: {
        updateID,
        comments: res.data.comments,
        answers: res.data.answers
      }
    });

    const home = store.getState().home;

    // Обновляем редюсер Home, если он не пустой
    if (home.data.length > 0) {
      store.dispatch(addHomeCommentAnswer({ id: updateID, comments: res.data.comments, answers: res.data.answers }))
    }
    
    // Callback for updating in formData.updates in useState to render it
    const data = { updateID, comments: res.data.comments, answers: res.data.answers }
    return data;
  } catch (err) {
    dispatch({
      type: UPDATE_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit comment
export const editUpdateComment = (
  updateID,
  commentID,
  formData
) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const res = await axios.put(
      `/api/updateanswers/comment/${updateID}/${commentID}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_COMMENT,
      payload: {
        updateID,
        comments: res.data
      }
    });
  } catch (err) {
    dispatch({
      type: UPDATE_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete comment
export const deleteUpdateComment = (
  updateID,
  answerID,
  commentID
) => async dispatch => {
  try {
    const res = await axios.delete(
      `/api/updateanswers/comment/${updateID}/${answerID}/${commentID}`
    );

    dispatch({
      type: REMOVE_COMMENT,
      payload: {
        updateID,
        commentID,
        answers: res.data.answers
      }
    });

    const home = store.getState().home;

    // Обновляем редюсер Home, если он не пустой
    if (home.data.length > 0) {
      store.dispatch(deleteHomeCommentAnswer({ id: updateID, commentID, answers: res.data.answers }))
    }

    // Callback for updating in formData.updates in useState to render it
    const data = { updateID, commentID, answers: res.data.answers }
    return data;
  } catch (err) {
    dispatch({
      type: UPDATE_ANSWER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Clear state
export const clearUpdates = () => async dispatch => {
  try {
   
    dispatch({
      type: CLEAR_UPDATE_ANSWERS
    });
  } catch (err) {
    dispatch({
      type: LOCATION_CHANGE_ERROR_UPDATE,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};