import React from "react";
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';

const customStyles1 = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    fontSize: '14px',
  }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
}

const OneSelect = ({
  key, defaultValue,
  onChange, options, isMulti,
  isSearchable, msg, styles,
  selectType, divStyles, hideTitle,
  index, containerStyles, titleStyles,
}) => {

  let customStyles = false;
  if (styles === 1) { customStyles = customStyles1 };

  let wording = {};
  let isHideTxt = false;

  if (selectType === 1) {
    wording.titleId = "1_1.choose.member";
    wording.titleMsg = "Choose a team member for 1:1 meeting";
    wording.descriptionId = "user";
    wording.descriptionMsg = "User";
    wording.placeholderId = "choose.1_1.user";
    wording.placeholderMsg = "Choose a teammate for a 1:1 meeting";
  } else if (selectType === 2) {
    wording.titleId = "1_1.choose.focus";
    wording.titleMsg = "Choose an OKR for the monthly focus";
    wording.descriptionId = "monhtly.focus";
    wording.descriptionMsg = "Monthly Focus";
    wording.placeholderId = "choose.monthly.focus";
    wording.placeholderMsg = "Choose monthly focus";
  } else if (selectType === 3) {
    wording.titleId = "1_1.choose.okr.week";
    wording.titleMsg = "Choose an OKR for the next week";
    wording.descriptionId = "okr";
    wording.descriptionMsg = "OKR";
    wording.placeholderId = "choose.okr";
    wording.placeholderMsg = "Choose an OKR";
    isHideTxt = true;
  }

  let withWord = <FormattedMessage id="with" defaultMessage="with"/>;

  return (
    <div className={`mt-2 ${containerStyles}`}>
      {!hideTitle && (
        <p className={`medium mb-1 ${titleStyles}`}>
          <FormattedMessage
            id={wording.titleId}
            defaultMessage={wording.titleMsg}
          />
        </p>
      )}
      <div className="flex align-center">
        {!isHideTxt && <p className="w-100px bold-pure medium">
          <FormattedMessage
            id={wording.descriptionId}
            defaultMessage={wording.descriptionMsg}
          />
        </p>}
        <div className={`${divStyles}`}>
          <FormattedMessage
            id={wording.placeholderId}
            defaultMessage={wording.placeholderMsg}
          >
            {msg => (
              <Select
                key={key}
                defaultValue={defaultValue}
                onChange={selectedOption =>
                  onChange(selectedOption, index)
                }
                options={options}
                isMulti={isMulti}
                isSearchable={isSearchable}
                placeholder={msg}
                styles={customStyles1}
              />
            )}
            </FormattedMessage>
          </div>
        </div>
    </div>
  );
};

OneSelect.propTypes = {
  user: PropTypes.object,
};

export default OneSelect;
