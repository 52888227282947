import React, { Fragment, useState, useEffect, useCallback  } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAlert } from '../../../actions/alert';
import { getCompanyTreeOkrs, archiveOKR, deleteOkr, getCompanyOkrs, editOkr } from '../../../actions/okr';
import TreeBranch from './TreeBranch';
import UserNav from '../../layout/UserNav';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../layout/Spinner';
import Logo from '../../elems/Logo';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';



const OkrTree = ({
  getCompanyTreeOkrs,
  getCompanyOkrs,
  setAlert,
  archiveOKR,
  deleteOkr,
  auth: { user },
  okr: { tree, okrs, loading },
  editOkr,
  locale
}) => {
  const [formData, setFormData] = useState({
    zoom: 0.8,
    okrsTree: [],
    copyTree: [],
    allOkrs: []
  });

  let { zoom, okrsTree, copyTree, allOkrs } = formData;

  // Делаем по дефолту false (в OkrTree там стейт)
  let archive = false;

  const zoomTree = (btn) => {
    if (btn === '+' && zoom < 1.2) {
      zoom += 0.1;
    } else if (btn === '-' && zoom > 0.6) {
      zoom -= 0.1;
    }

    setFormData({ ...formData, zoom });
  };

  useEffect(() => {
    getCompanyTreeOkrs();
    getCompanyOkrs();

    setFormData({
      ...formData,
      okrsTree: !tree ? [] : tree,
      copyTree: !tree ? [] : tree,
      allOkrs: !okrs ? [] : okrs
    });
  }, [
    loading,
    archiveOKR,
    getCompanyOkrs,
    getCompanyTreeOkrs,
    tree !== undefined && tree && tree.length,
    setAlert,
    editOkr,
    JSON.stringify(okrs)
  ]);

  // It helps to archive & delete when we do filter in reducer. Tree becomes undefined
  // Помогает избавиться от undefined при архивировании
  // Из-за этого пропадает дерево на несколько секунд.
  if (tree === undefined) {
    tree = copyTree;
  }

  const findChilds =  useCallback(async (arr) => {
    let childs = [];

    arr.forEach((item) => {
      let child = okrs.filter((o) => o._id.toString() === item.value);

      // If we found a child
      if (child.length > 0) {
        childs.push(child[0]);
      }
    });

    return childs;
  },[])

  const handleArchive = async (e, _id, isArchive, isTree) => {
    e.preventDefault();

    const res = await archiveOKR(_id, isArchive, isTree);

    // remove from OKR and add to Archive
    setFormData({
      ...formData,
      okrsTree: okrsTree.filter((item) => item._id !== res.id),
      allOkrs: allOkrs.filter((item) => item._id !== res.id)
    });
  };

  // ЗДЕСЬ получаем коллбэк и перебираем formData.objectives и обновляем
  const handleDeleteOkr = async (_id, isTree) => {
    try {
      const res = await deleteOkr(_id, isTree);

      // Не меняю расположение детей, так как в новом UI они не меняют вид, НО если в будущем будет дерево OKR, то надо будет менять и у детей информацию
      setFormData({
        ...formData,
        okrsTree: okrsTree.filter((answer) => answer._id !== res.okrID),
        allOkrs: allOkrs.filter((answer) => answer._id !== res.okrID)
      });
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in delete goal', err);
    }
  };

  // Rewrite render to make horisontal scroll work
  
  
  return (
    <Fragment>
      <div className={`css-main bg-primary`}>
        <div className={`wrapper__tree`}>
          <div className="main_menu absolute">
            <Logo />
          </div>
          <UserNav />

          <Fragment>
            {loading || !user || !tree ? (
              <Spinner />
            ) : (
              // <div className={`main main-dashboard ma-unset ml-0 pl-3 pr-6 horizontal-scroll__wrap`}>
              <div className={`px-3 horizontal-scroll__wrap`}>
                {/* <div className="horizontal-scroll__wrap"></div> */}
                <div className="okrs">
                  <Fragment>
                    {okrsTree !== undefined ? (
                      <div className="objective__tree pt-5">
                        <div className="flex justify-start align-center">
                          <h5 className="fw-600">
                            <FormattedMessage id="okr.tree" defaultMessage="OKRs Tree" />
                          </h5>

                          <div className="zoom-buttons mr-15 ml-2 mt-05">
                            <MinusCircleOutlined
                              className="fs-30 btn-zoom-back btn-icons pl-0 pr-0"
                              onClick={() => zoomTree('-')}
                            />

                            <PlusCircleOutlined
                              className="fs-30  btn-zoom-back btn-icons ml-1 pl-0 pr-0"
                              onClick={() => zoomTree('+')}
                            />
                          </div>

                          <Link to="/objectives" className="btn btn_new__grey s">
                            <FormattedMessage id="go.back" defaultMessage="Go Back" />
                          </Link>
                        </div>

                        {okrsTree &&
                          okrsTree.length > 0 &&
                          okrsTree.map((okr, index) => (
                            <TreeBranch
                              isParent={okr.isParent}
                              key={`okr-branch-${index}`}
                              okr={okr}
                              handleArchive={handleArchive}
                              handleDelete={handleDeleteOkr}
                              archive={archive}
                              user={user}
                              zoom={zoom}
                              allOkrs={allOkrs}
                              findChilds={findChilds}
                              locale={locale}
                            />
                          ))}
                      </div>
                    ) : (
                      <div className="w-100 h-100 mxa">
                        <Spinner />
                      </div>
                    )}
                  </Fragment>
                </div>
              </div>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

OkrTree.propTypes = {
  getCompanyOkrs: PropTypes.func.isRequired,
  getCompanyTreeOkrs: PropTypes.func.isRequired,
  archiveOKR: PropTypes.func.isRequired,
  deleteOkr: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  editOkr: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  okr: state.okr,
  locale: state.locale
});

export default connect(mapStateToProps, {
  archiveOKR,
  deleteOkr,
  setAlert,
  getCompanyTreeOkrs,
  getCompanyOkrs,
  editOkr,
  setAlert
})(OkrTree);
