import React from "react";
import PropTypes from 'prop-types';

const UpdateIcon = ({ activeIndex }) => {
  return (
  <svg id="clipboard" className="w-20px" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path id="Path_16" fill={`${activeIndex === 2 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 16" d="M-355.6,229.717c0,1.988,0,3.976,0,5.964a2.263,2.263,0,0,1-.678,1.725,4.073,4.073,0,0,1-2.97,1.016c-1.8,0-3.6,0-5.4,0q-3.592,0-7.186,0a8.494,8.494,0,0,1-1.106-.072,2.925,2.925,0,0,1-2.614-2.123,4.651,4.651,0,0,1-.04-.624q0-5.922,0-11.845a2.594,2.594,0,0,1,.236-1.229,3.46,3.46,0,0,1,3.005-1.589c.661,0,1.062.417.787.842a1.064,1.064,0,0,1-.425.3,1.434,1.434,0,0,1-.43.076c-.97.107-1.436.464-1.54,1.194a3.1,3.1,0,0,0-.023.458q0,5.86,0,11.72a3.371,3.371,0,0,0,.052.623,1.344,1.344,0,0,0,1.238,1,4.512,4.512,0,0,0,.719.05q6.377,0,12.757,0a3.967,3.967,0,0,0,.825-.07,1.293,1.293,0,0,0,1.123-.934,2.877,2.877,0,0,0,.06-.623q0-5.9,0-11.8c0-.1,0-.194,0-.291-.067-.828-.512-1.19-1.612-1.324a1.1,1.1,0,0,1-.729-.261.478.478,0,0,1-.068-.632.814.814,0,0,1,.724-.322,3.908,3.908,0,0,1,1.831.43,2.393,2.393,0,0,1,1.438,1.843,5.013,5.013,0,0,1,.029.583Q-355.6,226.756-355.6,229.717Z" transform="translate(375.602 -218.424)"/>
    <path id="Path_17" fill={`${activeIndex === 2 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 17" d="M-370.28,222.194a2.506,2.506,0,0,1,.869-3.377,2.369,2.369,0,0,1,2.8.143c.941.755,1.159,1.742.681,3.189.122.012.237.028.353.033a6.914,6.914,0,0,1,.707.025.535.535,0,0,1,.467.553.531.531,0,0,1-.415.59,1.757,1.757,0,0,1-.452.052q-2.853,0-5.706,0a1.715,1.715,0,0,1-.411-.045.537.537,0,0,1-.449-.606.537.537,0,0,1,.515-.551C-370.989,222.175-370.658,222.194-370.28,222.194Zm2.167-2.541a1.209,1.209,0,0,0-1.263,1.253,1.234,1.234,0,0,0,1.258,1.272,1.231,1.231,0,0,0,1.262-1.267A1.205,1.205,0,0,0-368.113,219.653Z" transform="translate(378.115 -218.424)"/>
    <path id="Path_18" fill={`${activeIndex === 2 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 18" d="M-366.269,232.777c-.736,0-1.471,0-2.206,0a2.045,2.045,0,0,1-.415-.027.538.538,0,0,1-.438-.534.528.528,0,0,1,.4-.6,1.706,1.706,0,0,1,.451-.057q2.247-.006,4.5,0a1.531,1.531,0,0,1,.41.051.544.544,0,0,1,.42.626.547.547,0,0,1-.5.527,2.587,2.587,0,0,1-.374.014C-364.771,232.778-365.52,232.777-366.269,232.777Z" transform="translate(379.188 -218.424)"/>
    <path id="Path_19" fill={`${activeIndex === 2 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 19" d="M-366.272,229.022c-.735,0-1.471,0-2.206,0a2.217,2.217,0,0,1-.374-.019.544.544,0,0,1-.476-.545.53.53,0,0,1,.4-.6,1.531,1.531,0,0,1,.41-.056q2.289,0,4.579,0a1.4,1.4,0,0,1,.369.051.545.545,0,0,1,.416.629.546.546,0,0,1-.5.524,2.742,2.742,0,0,1-.374.015Z" transform="translate(379.188 -218.424)"/>
    <path id="Path_20" fill={`${activeIndex === 2 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 20" d="M-370.96,232.186a.932.932,0,0,1-.913.9.929.929,0,0,1-.905-.949.891.891,0,0,1,.931-.867A.893.893,0,0,1-370.96,232.186Z" transform="translate(376.681 -218.424)"/>
    <path id="Path_21" fill={`${activeIndex === 2 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 21" d="M-371.878,227.517a.9.9,0,0,1,.921.884.935.935,0,0,1-.927.932.931.931,0,0,1-.9-.917A.9.9,0,0,1-371.878,227.517Z" transform="translate(376.681 -218.424)"/>
  </svg>

  );
};

UpdateIcon.propTypes = {
  activeIndex: PropTypes.number
};

export default UpdateIcon;
