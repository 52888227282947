import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import TimezonePicker from 'react-timezone';
import IntervalItem from './IntervalItem';
import WeekItem from './WeekItem';
import DayItem from './DayItem';
import QuestionItem from './QuestionItem';

const ScheduleItem = ({
  formData,
  setFormData,
  scheduleTime,
  handleTime,
  timezone,
  onChangeTimezone,
  interval,
  onChangeSelect,
  scheduleDay,
  onChangeDay,
  emailIsActive,
  reportIsActive,
  localReportHour,
  hourOptions,
  localReportMinute,
  minuteOptions,
  handleDaysChange,
  onChangeDate,
 }) => {

  let scheduleLength = 0;
  if (scheduleDay !== undefined && scheduleDay !== null) {
    scheduleLength = Object.keys(scheduleDay).length;
  }

  const dateTitle =<FormattedMessage id="day.month" defaultMessage="Day of the month" />;
  
  return (
    <Fragment>
      <Fragment>
        <p className="sub-title focus-yellow focus my-2">
          <FormattedMessage id="admin.dashboard.edit.updates.schedule" defaultMessage="Schedule and Notifications" />
        </p>
        <div className="form-main form-main-inline mt-2">
          <p className="bold medium block question">
            <FormattedMessage id="admin.dashboard.edit.time" defaultMessage="Time" />
          </p>
          <FormattedMessage id="admin.dashboard.edit.timeInput" defaultMessage="Enter the time">
            {msg => <input
              className="small-input"
              type="text"
              placeholder={msg}
              name="scheduleTime"
              value={scheduleTime || ''}
              onChange={e => handleTime(e)}
            />}
          </FormattedMessage>

          <p className="bold medium block question">
            <FormattedMessage id="admin.dashboard.edit.timezone" defaultMessage="Timezone" />
          </p>
          <TimezonePicker
            name="timezone"
            value={timezone}
            onChange={timezone =>
              onChangeTimezone(timezone, 'timezone')
            }
            inputProps={{
              placeholder: { timezone },
              name: 'timezone'
            }}
          />
        </div>

        <div className="form-main form-main-inline mt-2">

          <IntervalItem
            interval={interval}
            isCustom={true}
            onChange={onChangeSelect}
            styles={'small-input mr-5'}
          />

          {interval === 'Weekly' || interval === 'Biweekly' ? (
              <WeekItem
                scheduleDay={scheduleLength > 0 && scheduleDay}
                onChange={onChangeDay}
                styles={'small-input ml-5'}
                stylesText={'w-60px'}
                />
          ) : (<span></span>)}
          
            {interval === 'Daily' && (
              <DayItem
                scheduleDays={scheduleLength > 0 && scheduleDay.weekDays}
                onChange={handleDaysChange}
                styles={'small-input ml-5'}
                stylesText={'w-60px'}
                />
            )}

            {interval === 'Monthly' && (
              <QuestionItem
                scheduleDays={scheduleLength > 0 && scheduleDay}
                onChange={onChangeDate}
                text={dateTitle}
                stylesDiv={'w-50 mb-0'}
                stylesText={'w-40'}
                stylesInput={'w-60px'}
                inputID={'day.month'}
                inputText={'Enter day of the month'}
                inputName={'scheduleDay.index'}
                value={scheduleDay.index}
                name={scheduleDay.index}
                />
            )}
          </div>
        <div className="form-main form-main-inline standups-time mt-2">
          <p className="bold medium block question w-100-xs">
            <FormattedMessage
              id="admin.dashboard.edit.email"
              defaultMessage="Email notifications"
            />
          </p>
          <div className="checkbox email pl-3 mb-2-xs">
            <div className="checkbox-item active-status">
              <input
                type="checkbox"
                id="emailIsActive"
                name="emailIsActive"
                checked={emailIsActive}
                value={emailIsActive}
                onChange={e => {
                  setFormData({
                    ...formData,
                    emailIsActive: !emailIsActive
                  });
                }}
              />
              <label htmlFor="emailIsActive">
                {emailIsActive ? (
                  <FormattedMessage
                    id="admin.dashboard.edit.email.active"
                    defaultMessage="Active"
                  />
                ) : (
                    <FormattedMessage
                      id="admin.dashboard.edit.email.disable"
                      defaultMessage="Disabled"
                    />
                  )}
              </label>
            </div>
          </div>
        </div>
        <div className="form-main form-main-inline standups-time mt-2">
          <p className="bold medium block question w-100-xs">
            <FormattedMessage
              id="admin.dashboard.edit.report"
              defaultMessage="Weekly email reports"
            />
          </p>

          <div className="checkbox email pl-3">
            <div className="checkbox-item active-status">
              <input
                type="checkbox"
                id="reportIsActive"
                name="reportIsActive"
                checked={reportIsActive}
                value={reportIsActive}
                onChange={e => {
                  setFormData({
                    ...formData,
                    reportIsActive: !reportIsActive
                  });
                }}
              />
              <label htmlFor="reportIsActive">
                {reportIsActive ? (
                  <FormattedMessage
                    id="admin.dashboard.edit.report.active"
                    defaultMessage="Active"
                  />
                ) : (
                  <FormattedMessage
                    id="admin.dashboard.edit.report.disable"
                    defaultMessage="Disabled"
                  />
                )}
              </label>
            </div>
          </div>
          <p className="bold medium block question mla">
            <FormattedMessage
              id="admin.dashboard.edit.reportTime"
              defaultMessage="Report Time"
            />
          </p>
          <select
              className="small-input w-80px mr0 input__report_time mb-0-xs"
              name="localReportHour"
              value={localReportHour}
              onChange={e => handleTime(e)}
            >
              {hourOptions}
          </select>
          <span className="medium mx-1">:</span>
          <select
              className="small-input w-80px mb-0-xs mr-0-xs"
              name="localReportMinute"
              value={localReportMinute}
              onChange={e => handleTime(e)}
            >
              {minuteOptions}
          </select>
        </div>
      </Fragment>

      <p className="small w-100-xs block">
        <FormattedMessage
          id="admin.dashboard.edit.report.delivery"
          defaultMessage={`Only <b>Admins</b> will get email reports. If you want to add a user, drop us the message via chat or ${process.env.REACT_APP_EMAIL}.`}
          values={{ b: (...chunks) => <b>{chunks}</b> }}
        />
      </p>
    </Fragment>
  );
};

ScheduleItem.propTypes = {
  formdata: PropTypes.object.isRequired,
  setFormdata: PropTypes.func.isRequired,
};

export default ScheduleItem;
