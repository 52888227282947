import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { updateRecognitionLike } from '../../actions/recognition';
import CommentItem from '../comment/recognitions/CommentItem';
import CommentForm from '../comment/recognitions/CommentForm';
import { FormattedMessage } from 'react-intl';

const RecognitionCardItem = ({
  auth: { loading, user },
  text,
  value,
  date,
  likes,
  comments,
  recognitionID,
  giver,
  giverAvatar,
  giverFirstName,
  giverLastName,
  recipientFirstName,
  recipientLastName,
  updateRecognitionLike,
  formData,
  setFormData
}) => {
  const findUserLike = likes => {
    if (!loading) {
      if (likes.filter(like => like.user === user._id).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const findComments = comments => {
    if (comments.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const [discussion, showComments] = useState(false);

  // ЗДЕСЬ получаем коллбэк и перебираем formData.checkins и обновляем лайки
  const handleLike = async (recognitionID) => {
    try {
      const res = await updateRecognitionLike(recognitionID);

      setFormData({ 
        ...formData, 
        kudos: formData.kudos.map(answer => {
          if (answer._id === res.kudoID) {
            // Update all likes 
            answer.likes = res.likes;
            return answer;
          } else {
            return answer;
          }
        })
      });
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in likes', err);
    }
  };

  return (
    <Fragment>
      <div className="card-item">
        <div className="card-recognition p-2">
          <div className="comment-body">
            <div className="comment-item">
              <Link
                to={giver ? `/user/${giver}` : '/company/people'}
                className="user"
              >
                <img
                  src={giverAvatar}
                  alt="Avatar"
                  className="round-img d-block avatar"
                  width="45px"
                  height="45px"
                />
              </Link>

              <div className="comment-head recognition-item">
                <div className="comment-info">
                  <p className="comment-user recognition-user medium bold">
                    {giverFirstName} {giverLastName}{' '}
                    <span className="regular">
                      <FormattedMessage
                        id="recognition.card.appreciate"
                        defaultMessage="appreciates "
                      />
                    </span>{' '}
                    {recipientFirstName} {recipientLastName}
                  </p>
                </div>

                {value && (
                  <p className="blue small pb-1">
                    <FormattedMessage
                      id="recognition.card.value"
                      defaultMessage="Value: "
                    />
                    {value}
                  </p>
                )}

                <p className="comment-text">{text}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="p-2 px-2-l">
          <div className="btn-feedback">
            <button
              type="button"
              className={classnames('btn-icons btn-like', {
                'btn-active-icon': findUserLike(likes)
              })}
              onClick={e => handleLike(recognitionID)}
            >
              <i className="fas fa-heart" />
              <span className="small badge">
                {likes.length > 0 && <span>{likes.length}</span>}
              </span>
            </button>

            <button
              type="button"
              onClick={() => showComments(!discussion)}
              className={classnames('btn-icons btn-comment', {
                'btn-active-icon': findComments(comments)
              })}
            >
              <i className="fas fa-comment-alt" />
              <span className="small badge">
                {comments.length > 0 && <span>{comments.length}</span>}
              </span>
            </button>
          </div>

          {discussion && (
            <Fragment>
              <div className="card-feedback">
                <div className="comment">
                  <p className="comment-title medium bold">
                    <strong>
                      <FormattedMessage
                        id="comments"
                        defaultMessage="Comments"
                      />
                    </strong>
                  </p>
                  <div className="line" />

                  {comments.map(comment => (
                    <CommentItem
                      key={comment._id}
                      comment={comment}
                      recognitionID={recognitionID}
                      formData={formData}
                      setFormData={setFormData}
                    />
                  ))}

                  <CommentForm
                    recognitionID={recognitionID}
                    user={user}
                    avatar={user && user.avatar}
                    formData={formData}
                    setFormData={setFormData}
                  />
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

RecognitionCardItem.propTypes = {
  auth: PropTypes.object.isRequired,
  updateRecognitionLike: PropTypes.func
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { updateRecognitionLike }
)(RecognitionCardItem);
