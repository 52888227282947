import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { icons } from '../../img/elements/icons';
import { FormattedMessage } from 'react-intl';

const UsersRow = ({
  auth,
  user,
  okrs,
  standups,
  updates,
  plans,
  tasks,
  icebreakers,
  companyUsers,
  customs,
  // weeklyCustoms, 
  // weeklyStandups, 
  // weeklyUpdates, 
  // weeklyPlans, 
  // weeklyTasks, 
  // weeklyIcebreakers, 
  // weeklyCreatedOkrs,
  // weeklyUpdatedOkrs,
  index,
  company,
  handleLogin,
  deleteCompany,
  // weeklyOnes,
  oneOnOnes,
  // weeklyMoods,
  moods,
}) => {

  let _id = user._id; 
  let firstName = user.firstName; 
  let lastName = user.lastName; 
  let email = user.email; 
  let companyName = user.companyName; 
  let role = user.role; 
  let status = user.status; 
  let date = user.date; 
  let userCompany = user.company;

  let expiredDate = company ? company.expiredDate : '';
  let plan = company ? company.plan : '';
  let promocode = company ? company.promocode : '';
  let isSlack = company ? company.isSlack : '';
  let utm = company ? company.utm : '';

  let fullName = `${firstName} ${lastName}`;
  let companyPlan;

  if (plan === 1) { companyPlan = 'Free' };
  if (plan === 2) { companyPlan = 'Check-ins' };
  if (plan === 2.5) { companyPlan = 'Check-ins + Culture' };
  if (plan === 3) { companyPlan = 'Pro' };
  if (plan === 4) { companyPlan = 'Pro + Culture' };
  if (plan === 33) { companyPlan = 'Enterprise' };
  
  let utmObj = utm && utm !== undefined ? `
  ref: ${utm.referrer}, 
  source: ${utm.utm_source}, 
  medium: ${utm.utm_medium}, 
  campaign: ${utm.utm_campaign}, 
  content: ${utm.utm_content}, 
  ` : `no UTM!`;

  let lastLogin = user && user.lastLogin ? user.lastLogin : (user ? user.date : '-');

  // console.log('=== tasks', tasks)

  return (
    <Fragment>
      <tr>
        <td className="brd brd-black fs1_4">{index + 1}</td>
        <td className="brd brd-black fs1_4">{fullName}</td>
        <td className="brd brd-black fs1_4 px-1">{email}</td>
        <td className="brd brd-black fs1_4">{companyName ? companyName : ""}</td>
        <td className="brd brd-black fs1_4">{companyUsers.length}</td>
        <td className="brd brd-black fs1_4">{role ? role : ""}</td>
        <td className="brd brd-black fs1_4"><Moment format="D MMM YY">{date}</Moment></td>
        <td className="brd brd-black fs1_4"><Moment format="D MMM YY">{expiredDate}</Moment></td>
        <td className="brd brd-black fs1_4">{companyPlan}</td>
        <td className="brd brd-black fs1_4"><Moment format="D MMM YY LT">{lastLogin}</Moment></td>
        <td className="brd brd-black fs1_4 bold blue">{customs.length > 0 ? customs.length : 0}</td>
        <td className="brd brd-black fs1_4">{standups.length > 0 ? standups.length : 0}</td>
        <td className="brd brd-black fs1_4">{updates.length > 0 ? updates.length : 0}</td>
        <td className="brd brd-black fs1_4">{plans !== undefined && plans.length > 0 ? plans.length : 0}</td>
        <td className="brd brd-black fs1_4 bold">{okrs.length > 0 ? okrs.length : 0}</td>
        <td className="brd brd-black fs1_4">{tasks !== undefined && tasks.length > 0 ? tasks.length : 0}</td>
        <td className="brd brd-black fs1_4">{oneOnOnes !== undefined && oneOnOnes.length > 0 ? oneOnOnes.length : 0}</td>
        <td className="brd brd-black fs1_4">{icebreakers !== undefined && icebreakers.length > 0 ? icebreakers.length : 0}</td>
        <td className="brd brd-black fs1_4">{moods !== undefined && moods.length > 0 ? moods.length : 0}</td>
        <td className="brd brd-black fs1_4">
          <div className="card-icons card-icons__okr pt-0 pb-0">
          <div className="btn-action no_btn ml-xs relative" type="button">
              {icons.more}

              <div className="dropdown p-1 light-shadow">
                <button 
                  type="button"
                  onClick={() => handleLogin(_id) }
                  className="small secondary-font no_btn mb-1"
                >
                  <i className="fas fa-user" />{' '}
                  <FormattedMessage
                    id="login.asUser"
                    defaultMessage="Login as User"
                  />
                </button>
                {auth && auth.role === "Superadmin" ? (
                  <button 
                    type="button"
                    onClick={() => { if (window.confirm(`Are you sure you want to delete company ${companyName} (id: ${userCompany})?`)) deleteCompany(userCompany) } }
                    className="small secondary-font no_btn mb-1"
                  >
                    <i className="fas fa-times" />{' '}
                    <FormattedMessage
                      id="user.okr.deleteContact"
                      defaultMessage="Delete Company"
                    />
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </td>
      </tr>
    </Fragment>
  );
};

UsersRow.propTypes = {
  user: PropTypes.object.isRequired,
  okrs: PropTypes.array.isRequired,
  companyUsers: PropTypes.array.isRequired,
  standups: PropTypes.array.isRequired,
  updates: PropTypes.array.isRequired,
  handleLogin: PropTypes.func.isRequired,
};

export default UsersRow;
