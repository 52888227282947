import React, { Fragment } from 'react';
import QuestionItem from '../comment/UniQuestionItemNew';
import QuestionItemOld from '../comment/UniQuestionItem';
import QuestionItemOkr from '../comment/UniQuestionItemOkr';

const CustomItemCardQuestion = ({
  avatar,
  question,
  answer,
  index,
  searchKeyword,
  checkinType,
  campaignSettings,
  answerType,
}) => {

  // console.log('===== CustomItemCardQuestion: checkinType', checkinType)
  // console.log('===== CustomItemCardQuestion: answerType', answerType)
  // console.log('===== CustomItemCardQuestion: campaignSettings', campaignSettings)

  return (
    <Fragment>
      <div className={"card-question-item pb-1 mb-xs"}>
        {checkinType === 1 ? (
          // <QuestionItem
          //   question={question}
          //   answer={answer && answer}
          //   avatar={avatar}
          //   index={index}
          //   searchKeyword={searchKeyword}
          // />
          <Fragment>
            {!answerType || answerType === 1 ? (
              <QuestionItem
                question={question}
                answer={answer && answer}
                avatar={avatar}
                index={index}
                searchKeyword={searchKeyword}
              />
            ) : (
              <QuestionItemOld
                question={question}
                answer={answer && answer}
                avatar={avatar}
                index={index}
                searchKeyword={searchKeyword}
              />
            )}
          </Fragment>
        ) : (
          !campaignSettings || campaignSettings && campaignSettings.settings && !campaignSettings.settings.campaignType || campaignSettings && campaignSettings.settings && campaignSettings.settings.campaignType !== 2 ? (
            <QuestionItemOld
              question={question}
              answer={answer && answer}
              avatar={avatar}
              index={index}
              searchKeyword={searchKeyword}
            />
          ) : (
            null
            // <QuestionItemOkr
            //   question={question}
            //   answer={answer && answer.okrAnswers && answer.okrAnswers.length > 0 && answer.okrAnswers}
            //   avatar={avatar}
            //   index={index}
            //   searchKeyword={searchKeyword}
            //   // weeklyOkrs={weeklyOkrs}
            // />
          )
        )}
      </div>
    </Fragment>
  );
};

export default CustomItemCardQuestion;