import axios from 'axios';
import { setAlert } from './alert';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  GET_ICEBREAKERS,
  ICEBREAKERS_ERROR,
  ICEBREAKER_ERROR,
  CLEAR_ICEBREAKERS,
  GET_ICEBREAKERS_SETTINGS,
  ICEBREAKERS_SETTINGS_ERROR,
  ADD_ICEBREAKER_ANSWER,
  EDIT_ICEBREAKER_ANSWER,
  DELETE_ICEBREAKER_ANSWER,
  DELETE_ICEBREAKER,
  SWITCH_ICEBREAKERS_STATUS,
  GET_DEFAULT_ICEBREAKERS,
  CHANGE_ICEBREAKERS_LANGUAGE,
} from './types';

// Get all companies icebreakers
export const getIcebreakers = () => async dispatch => {
  try {
    const res = await axios.get('/api/icebreakers/company');

    dispatch({
      type: GET_ICEBREAKERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ICEBREAKERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Icebreaker settings
export const getIcebreakerSettings = () => async dispatch => {
  try {
    const res = await axios.get('/api/icebreakers/settings');

    dispatch({
      type: GET_ICEBREAKERS_SETTINGS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ICEBREAKERS_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Switch ON/OFF icebreaker settings
export const toggleIcebreakerSettings = () => async dispatch => {
  try {
    const res = await axios.put('/api/icebreakers/status');

    dispatch({
      type: SWITCH_ICEBREAKERS_STATUS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ICEBREAKERS_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Edit Icebreak Settings
export const editIcebreakSettings = (formData, history) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(`/api/icebreakers/edit`, formData, config);

    dispatch({
      type: GET_ICEBREAKERS,
      payload: res.data
    });

    getIcebreakerSettings();

    dispatch(
      setAlert(
        <FormattedMessage
          id="alert.icebreaker.edit"
          defaultMessage="Icebreakers were updated"
        />,
        'success'
      )
    );

    history.goBack();
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ICEBREAKERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Add answer on icebreaker
export const addIcebreakerAnswer = (
  icebreakerID,
  text
) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({ text });

    const res = await axios.post(
      `/api/icebreakers/answer/${icebreakerID}`,
      body,
      config
    );

    dispatch({
      type: ADD_ICEBREAKER_ANSWER,
      payload: {
        icebreakerID,
        answer: res.data
      }
    });

    // Callback for updating in formData.icebreakers in useState to render it
    const arr = { data: { icebreakerID, answer: res.data } }
    return arr;
  } catch (err) {
    console.log('====err', err);
    dispatch({
      type: ICEBREAKER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
  };


// Edit answer
export const editIcebreakerAnswer = (
  icebreakerID,
  answerID,
  text
) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({ text });

    console.log('===== body', body);

    const res = await axios.put(
      `/api/icebreakers/answer/${icebreakerID}/${answerID}`,
      body,
      config
    );

    dispatch({
      type: EDIT_ICEBREAKER_ANSWER,
      payload: {
        icebreakerID,
        answerID,
        answer: res.data
      }
    });

     // Callback for updating in formData.icebreakers in useState to render it
    const data = { icebreakerID, answerID, answer: res.data }
    return data;

  } catch (err) {
    dispatch({
      type: ICEBREAKER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Rewrite questions to default icebreakers
export const getDefaultIcebreakers = (language) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({ language });
    const res = await axios.put(
      `/api/icebreakers/default`,
      body,
      config
    );

    dispatch({
      type: GET_DEFAULT_ICEBREAKERS,
      payload: res.data
    });

  } catch (err) {
    dispatch({
      type: ICEBREAKERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Change language of icebreakers
export const changeIcebreakersLanguage = (language) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({ language });
    const res = await axios.put(
      `/api/icebreakers/default`,
      body,
      config
    );

    dispatch({
      type: CHANGE_ICEBREAKERS_LANGUAGE,
      payload: res.data
    });

  } catch (err) {
    dispatch({
      type: ICEBREAKERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete answer
export const deleteIcebreakerAnswer = (
  icebreakerID,
  answerID,
) => async dispatch => {
  try {
    await axios.delete(
      `/api/icebreakers/answer/${icebreakerID}/${answerID}`
    );

    dispatch({
      type: DELETE_ICEBREAKER_ANSWER,
      payload: {
        icebreakerID,
        answerID,
      }
    });

    // Callback for updating in formData.updates in useState to render it
    const data = { icebreakerID, answerID }
    return data;

  } catch (err) {
    dispatch({
      type: ICEBREAKER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete answer
export const deleteIcebreaker = (id) => async dispatch => {
  try {
    await axios.delete(
      `/api/icebreakers/${id}`
    );

    dispatch({
      type: DELETE_ICEBREAKER,
      payload: id
    });

    // Callback for updating in formData.updates in useState to render it
    const data = { icebreakerID: id }
    return data;

  } catch (err) {
    dispatch({
      type: ICEBREAKER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Clear state
export const clearIcebreakers = () => async dispatch => {
  try {
   
    dispatch({
      type: CLEAR_ICEBREAKERS
    });
  } catch (err) {
    dispatch({
      type: ICEBREAKERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};