import React from 'react';
import { FormattedMessage } from 'react-intl';

const questionEn = <FormattedMessage id="onboarding.icebreakers.q1" defaultMessage="What is your favourite movie?" />;
const answer1 = <FormattedMessage id="onboarding.icebreakers.a1" defaultMessage="Inception" />;


const answersEn = [
  {
    _id: "answer1",
    user: "onboarding_owner1",
    date: "2021-03-11T07:36:33.402+00:00",
    updatedAt: "2021-03-11T07:36:33.402+00:00",
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName2"
        defaultMessage="Julian"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName2"
        defaultMessage="Tobin"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar44.jpeg",
    text: 'Inception',
  },
  {
    _id: "answer2",
    user: "onboarding_owner2",
    date: "2021-03-11T07:36:33.402+00:00",
    updatedAt: "2021-03-11T07:44:33.402+00:00",
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName1"
        defaultMessage="Tom"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName1"
        defaultMessage="Ford"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar52.jpeg",
    text: 'Avengers: Endgame'
  },
  {
    _id: "answer3",
    user: "onboarding_owner3",
    date: "2021-03-11T07:36:33.402+00:00",
    updatedAt: "2021-03-11T08:15:33.402+00:00",
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName3"
        defaultMessage="Patrick"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName3"
        defaultMessage="Brody"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar7.jpeg",
    text: 'Whaat?! We are enemies now, how could you like Avengers 😂',
  },
  {
    _id: "answer4",
    user: "onboarding_owner4",
    date: "2021-03-11T07:36:33.402+00:00",
    updatedAt: "2021-03-12T07:30:33.402+00:00",
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName7"
        defaultMessage="Paul"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName7"
        defaultMessage="Morton"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar14.jpeg",
    text: 'Haha love Avengers too. The infinity war is one of my favorite movies :)'
  }
]

const answersRu = [
  {
    _id: "answer1",
    user: "onboarding_owner1",
    date: "2021-03-11T07:36:33.402+00:00",
    updatedAt: "2021-03-11T07:36:33.402+00:00",
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName2"
        defaultMessage="Julian"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName2"
        defaultMessage="Tobin"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar44.jpeg",
    text: 'Начало',
  },
  {
    _id: "answer2",
    user: "onboarding_owner2",
    date: "2021-03-11T07:36:33.402+00:00",
    updatedAt: "2021-03-11T07:44:33.402+00:00",
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName1"
        defaultMessage="Tom"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName1"
        defaultMessage="Ford"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar52.jpeg",
    text: 'Мстители: Финал'
  },
  {
    _id: "answer3",
    user: "onboarding_owner3",
    date: "2021-03-11T07:36:33.402+00:00",
    updatedAt: "2021-03-11T08:15:33.402+00:00",
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName3"
        defaultMessage="Patrick"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName3"
        defaultMessage="Brody"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar7.jpeg",
    text: 'Чтооооо?! Всё, мы теперь враги - как тебе могут нравиться Мстители? 😂',
  },
  {
    _id: "answer4",
    user: "onboarding_owner4",
    date: "2021-03-11T07:36:33.402+00:00",
    updatedAt: "2021-03-12T07:30:33.402+00:00",
    firstName: (
      <FormattedMessage
        id="onboarding.okr.ownerFirstName7"
        defaultMessage="Paul"
      />
    ),
    lastName: (
      <FormattedMessage
        id="onboarding.okr.ownerLastName7"
        defaultMessage="Morton"
      />
    ),
    avatar: "https://app.usefocus.co/img/avatar/users/pravatar14.jpeg",
    text: 'Ахаха а мне тоже они нравятся. Война бесконечности - один из моих любимых фильмов :)'
  }
]

export const onboardingIcebreakerEn = { 
    _id: "onboarding_icebreaker1",
    question: questionEn,
    answers: answersEn,
    teamName: (
      <FormattedMessage
        id="onboarding.okr.product"
        defaultMessage="Product"
      />
    ),
    company: "onboarding_company1",
    companyName: "onboarding_companyName1",
    department: "onboarding_department1",
    departmentName: (
      <FormattedMessage
        id="onboarding.okr.departmentName1"
        defaultMessage="Marketing"
      />
    ),
    ID: "20",
    companyID: 1,
    date: "2021-03-11T07:36:33.402+00:00"
  }
  
  export const onboardingIcebreakerRu = { 
    _id: "onboarding_icebreaker1",
    question: questionEn,
    answers: answersRu,
    teamName: (
      <FormattedMessage
        id="onboarding.okr.product"
        defaultMessage="Product"
      />
    ),
    company: "onboarding_company1",
    companyName: "onboarding_companyName1",
    department: "onboarding_department1",
    departmentName: (
      <FormattedMessage
        id="onboarding.okr.departmentName1"
        defaultMessage="Marketing"
      />
    ),
    ID: "20",
    companyID: 1,
    date: "2021-03-11T07:36:33.402+00:00"
  }


export default {
  onboardingIcebreakerEn,
  onboardingIcebreakerRu,
}