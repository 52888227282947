import {
  GET_COMPANY_MOOD_ANSWERS,
  GET_USER_MOOD_ANSWER,
  GET_MOOD_SETTINGS,
  ADD_MOOD_ANSWER,
  MOOD_ANSWERS_ERROR,
  MOOD_SETTINGS_ERROR,
  UPDATE_MOOD_SETTINGS,
  CLEAR_MOOD_ANSWERS,
  LOCATION_CHANGE_ERROR_MOOD,
  GET_USER_MOOD_ANSWER_AND_SETTINGS,
} from '../actions/types';

const initialState = {
  answers: [],
  answer: null,
  settings: null,
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_MOOD_ANSWERS:
    case LOCATION_CHANGE_ERROR_MOOD: 
     return {
       ...state,
       answers: [],
       answer: null,
       settings: null,
       loading: true
     };
    case GET_COMPANY_MOOD_ANSWERS:
      return {
        ...state,
        answers: payload,
        loading: false,
      };
    case GET_USER_MOOD_ANSWER_AND_SETTINGS:
      return {
        ...state,
        answer: payload.answer,
        settings: payload.settings,
        loading: false,
      };
    case GET_USER_MOOD_ANSWER:
      return {
        ...state,
        answer: payload,
        loading: false,
      };
    case GET_MOOD_SETTINGS:
    case UPDATE_MOOD_SETTINGS:
      return {
        ...state,
        settings: payload,
        loading: false,
      };
    case ADD_MOOD_ANSWER:
      return {
        ...state,
        answers: [payload, ...state.answers],
        answer: payload,
        loading: false
      };
    case MOOD_ANSWERS_ERROR:
      return {
        ...state,
        error: payload,
        answers: [],
        loading: false
      };
    case MOOD_SETTINGS_ERROR:
      return {
        ...state,
        error: payload,
        answer: null,
        settings: null,
        loading: false
      };
    default:
      return state;
  }
}
