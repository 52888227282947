import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import FeedbackCardItem from './FeedbackCardItem';
import { FormattedMessage } from 'react-intl';

const FeedbackItem = ({
  feedback: {
    text,
    value,
    date,
    likes,
    comments,
    giver,
    giverFirstName,
    giverLastName,
    giverAvatar,
    recipient,
    recipientFirstName,
    recipientLastName,
    recipientAvatar,
    _id,
    isPrivate
  },
  auth,
}) => {

  let privateFeedbackTxt = <FormattedMessage id="private.feedback" defaultMessage="Private feedback"/>;

  return (
    <Fragment>
      {!isPrivate || isPrivate && auth._id === giver || isPrivate && auth._id === recipient || isPrivate && auth.isAdmin ? (
        <div className={`component brd brd-${!isPrivate ? 'green' : 'grey'} my-2 p-3 light-shadow`}>
          <div className="component-title flex align-center mb-1">
            <p className={`sub-title mb-0 focus-${!isPrivate ? 'green' : 'yellow'} focus`}>
              <Moment format="D MMM">{date}</Moment>
            </p>

            {isPrivate && ( 
              <div className="small grey ml-3">{privateFeedbackTxt}</div>
            )}
          </div>

          <div className="cards py-2">
            <FeedbackCardItem
              key={_id}
              feedbackID={_id}
              text={text}
              value={value}
              giver={giver}
              giverAvatar={giverAvatar}
              giverFirstName={giverFirstName}
              giverLastName={giverLastName}
              recipientFirstName={recipientFirstName}
              recipientLastName={recipientLastName}
              comments={comments}
              likes={likes}
            />
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

FeedbackItem.propTypes = {
  feedback: PropTypes.object.isRequired
};

export default FeedbackItem;
