import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import LogsRow from './LogsRow';

const UsersTable = ({ 
  logs,
  auth
 }) => {

  return (
    <Fragment>
      <table className="text-left">
			  <thead>
			    <tr>
			      <th className="bold medium pr-2">#</th>
			      <th className="bold medium pr-2">Log id</th>
			      <th className="bold medium pr-2">Date</th>
			      <th className="bold medium pr-2">User</th>
			      <th className="bold medium pr-2">Company</th>
			      <th className="bold medium pr-2">Action</th>
			      <th className="bold medium pr-2">Type</th>
			      <th className="bold medium pr-2">SubType</th>
			      <th className="bold medium pr-2">Object</th>
			      <th className="bold medium pr-2">Ex-Object</th>
			      <th className="bold medium pr-2">Comment</th>
			    </tr>
			  </thead>
			  <tbody>
          {logs && logs.length > 0 ? (
            logs.map((item, index) => (
              <LogsRow 
                key={item && item._id}
                log={item} 
                index={index}
                auth={auth}
              />
            ))
          ) : (
            <Fragment>
              <tr>
                <td className="medium">No logs</td>
              </tr>
            </Fragment>
          )}
			  </tbody>
			</table>
    </Fragment>
  );
};

UsersTable.propTypes = {
  logs: PropTypes.array.isRequired,
};

export default UsersTable;
