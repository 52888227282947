import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import Fs2Title from './Fs2Title';
import TodoItem2Card from './TodoItem2Card';
import TodoItem2CardForm from './TodoItem2CardForm.js';
import AddTodoBtn from './AddTodoBtn.js';

const TodoItem2 = ({
  title, addMsg, handleAdd,
  formData, items, name,
  handleChangeForm, inputName,
  onSubmit, clearTodo, handleComplete,
  usersOptions, okrOptions, handleNameForm,
  handleSelect, isTodo, handleDelete, handleDueIn,
  user, isAssignee, isDueIn, handleChangeFormState,
  oneId, isForm, width,
}) => {

  return (
    <div className="">

      <div className="my-3">
        <Fs2Title title={title} />

        {items.map((item, index) => (
            <Fragment 
              key={`${name}-${item._id}-${index}`} 
            >
              <div 
              >
              {!item.isNew ? (
                <TodoItem2Card
                  item={item}
                  formData={formData}
                  usersOptions={usersOptions}
                  okrOptions={okrOptions}
                  handleSelect={handleSelect}
                  handleDueIn={handleDueIn}
                  handleComplete={handleComplete}
                  index={index}
                  user={user}
                  handleDelete={handleDelete}
                  handleNameForm={handleNameForm}
                  handleChangeForm={handleChangeForm}
                  handleChangeFormState={handleChangeFormState}
                  addMsg={addMsg}
                  name={name}
                  inputName={inputName}
                  isTodo={isTodo}
                  clearTodo={clearTodo}
                  isAssignee={isAssignee}
                  isDueIn={isDueIn}
                  oneId={oneId}
                  isForm={isForm}
                />
              ) : (
                <TodoItem2CardForm
                  item={item}
                  formData={formData}
                  usersOptions={usersOptions}
                  okrOptions={okrOptions}
                  handleChangeForm={handleChangeForm}
                  handleChangeFormState={handleChangeFormState}
                  clearTodo={clearTodo}
                  handleDueIn={handleDueIn}
                  onSubmit={onSubmit}
                  index={index}
                  addMsg={addMsg}
                  name={name}
                  inputName={inputName}
                  isTodo={isTodo}
                  isAssignee={isAssignee}
                  isDueIn={isDueIn}
                  oneId={oneId}
                  width={width}
                />
              )}
              </div>
            </Fragment>
          ))}

        <AddTodoBtn
          handleAdd={handleAdd}
          name={name}
          addMsg={addMsg}
        />
      </div>

    </div>
  );
};

TodoItem2.propTypes = {
  currentProfile: PropTypes.object,
};

export default TodoItem2;
