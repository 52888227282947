import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Logo from '../../../elems/Logo';
import PropTypes from 'prop-types';
import { login } from '../../../../actions/auth';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../../layout/Spinner';
import Text from '../../elems/Text';
import Skip from '../../elems/Skip';
import { createInvite } from '../../../../actions/auth';
import { icons } from '../../../../img/elements/icons';
import { getAllActiveCustoms } from '../../../../actions/custom';

const MemberOnboarding = ({ 
  user, 
  getAllActiveCustoms,
  custom: { campaigns },
}) => {
  
  useEffect(() => {
    getAllActiveCustoms();
  }, [getAllActiveCustoms]);

  let title = <FormattedMessage id="welcome.checkin.member.title" defaultMessage="Welcome to Focus!" />
  let text = <FormattedMessage id="welcome.checkin.member.msg" defaultMessage={`You've been invited to join "${user && user.companyName ? user.companyName : 'your team'}" on Focus app. There is an ongoing check-in waiting for you, would you like to complete it now?`} />
  let completeMsg = <FormattedMessage id="complete.checkin" defaultMessage="Complete Check-in" />
  let continueMsg = <FormattedMessage id="continue.to.app" defaultMessage="Continue to the App" />
  
  let checkinUrl = campaigns && campaigns.length > 0 ? `/campaign/create/${campaigns[campaigns.length-1]._id}` : '/campaigns/all-checkins'
  let continueUrl = "/campaigns/all-checkins";

  // If no campaigns
  if (campaigns && campaigns.length === 0 ) {
    text = <FormattedMessage id="welcome.checkin.member.msg" defaultMessage={`You've been invited to join "${user && user.companyName ? user.companyName : 'your team'}" on Focus app. You can see company's OKRs (Objectives and Key Results) and check-ins here.`} />
    completeMsg = <FormattedMessage id="go.to.okrs" defaultMessage="Go to OKRs" />
    checkinUrl = '/objectives';
    continueMsg = <FormattedMessage id="see.checkins" defaultMessage="See Check-ins" />
    continueUrl = '/campaigns/all-checkins';
  }

  return (
    <Fragment>
      {!user ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="css-main onboarding bg__white">
            <div className="wrapper">
              <div className="flex">
                <div className="onboarding__content relative">
                  <div className="">
                    <div className="pb-8">
                      <Logo isNoLink={true} />
                    </div>
                    <Text title={title} text={text} page={6} />

                    <div className="flex align-center space-between mt-3 onboarding__invite__form">
                      <Link to={checkinUrl} className="onboarding__invite__btn text-center btn btn-form btn_primary_new h-54 lh-20 mt-0">
                        {completeMsg}
                      </Link>
                      <Link to={continueUrl}>
                        <span className="fs-14 flex align-center cursor icon__grey">
                          <span className="mr-xs">{continueMsg}</span>
                          <span className="flex align-center">{icons.arrow_right}</span>
                        </span>
                      </Link>
                    </div>
                  </div>
                  
                  <Skip/>
                </div>
                <div className="onboarding__image onboarding__image__checkin"></div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

MemberOnboarding.propTypes = {
  getAllActiveCustoms: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.user,
  locale: state.locale,
  custom: state.custom,
});

export default connect(
  mapStateToProps,
  { login, createInvite, getAllActiveCustoms }
)(MemberOnboarding);
