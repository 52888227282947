import React from "react";
import { FormattedMessage } from "react-intl";

const SettingsTitle = ({
  title,
  text,
}) => {

  return (
    <div className="">
      <p className="fs-28 fw-600 text-left">
        <FormattedMessage
          id="company.settings"
          defaultMessage="Company Settings"
        />
      </p>
      {/* <div className="sub-menu__page mt-15 mb-5"></div> */}
    </div>
  );
};

export default SettingsTitle;
