import {
  GET_DISCORD_USERS,
  GET_DISCORD_CHANNELS,
  DISCORD_USERS_ERROR,
  DISCORD_CHANNELS_ERROR,
  GET_DISCORD_TEAM,
  DISCORD_TEAM_ERROR,
  CLEAR_DISCORD_TEAM,
} from '../actions/types';

const initialState = {
  users: null,
  channels: null,
  team: null,
  loading: true,
  error: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DISCORD_USERS:
      return {
        ...state,
        users: payload,
        loading: false
      };
    case GET_DISCORD_CHANNELS:
      return {
        ...state,
        channels: payload,
        loading: false
      };
    case GET_DISCORD_TEAM:
      return {
        ...state,
        team: payload,
        loading: false
      };
    case DISCORD_USERS_ERROR:
    case DISCORD_CHANNELS_ERROR:
    case DISCORD_TEAM_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case CLEAR_DISCORD_TEAM:
      return {
        ...state,
        users: null,
        channels: null,
        team: null,
        loading: true
      };
    default:
      return state;
  }
}
