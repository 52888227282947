import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ErrorBoundary from "../_helpers/ErrorBoundary";
import Moment from 'react-moment';

const Timeline = ({
  item: { date, text, current, goal, status, isDayFocus, isWeekFocus },
  standupAnswer,
}) => {

  let onTrack = <FormattedMessage id="user.okr.status.onTrack" defaultMessage="On Track"/>
  let behind = <FormattedMessage id="user.okr.status.behind" defaultMessage="Behind"/>
  let atRisk = <FormattedMessage id="user.okr.status.risk" defaultMessage="At Risk"/>
  let label = "";
  let color;

  if (status === "1") {
    color = "green";
    label = onTrack;
  } else if (status === "2") {
    color = "yellow";
    label = behind;
  } else if (status === "3") {
    color = "red";
    label = atRisk;
  }

  const checkinlLabel = <FormattedMessage id="checkin" defaultMessage="Daily Check-in"/>;
  const noCheckin = <FormattedMessage id="nocheckin" defaultMessage="No check-in"/>;
  const noFocus = <FormattedMessage id="nofocus" defaultMessage="Without Focus"/>;
  const otherUpdated = <FormattedMessage id="nocheckin" defaultMessage="Other OKRs were updated"/>;
  const focuslLabel = <FormattedMessage id="focus" defaultMessage="Focus"/>;
  const currentlLabel = <FormattedMessage id="widget.main.current" defaultMessage="Current"/>;
  const goalLabel = <FormattedMessage id="widget.main.goal" defaultMessage="Goal"/>;

  return (
    <Fragment>
      <ErrorBoundary>
          <div className="timeline__item p-1 brd brd-primary_light br-6 w-170px ma-w170 w-100-s">
							<p className="small grey mb-xs"><Moment format="D MMM">{date}</Moment></p>
              {isDayFocus ? (
                <Fragment>
                	<p className="fs-15 lh-22 mb-xs"><span className="bold">{focuslLabel}</span>:{' '}{text}</p>
                  <p className={`small ${color}  mr-1`}>{label}</p>
                  <p className="small  mr-1">{currentlLabel}:{' '}{current}</p>
                  <p className="small ">{goalLabel}:{' '}{goal}</p>
                </Fragment>
              ) : (
                <p className="fs-15 lh-22 grey mb-xs">{noFocus}</p>
              )}

              {standupAnswer && standupAnswer.length > 0 && standupAnswer[0].answers.length > 0 ? (
                <div className="small underline bold cursor checkin-focus relative btn-action mb-1">{checkinlLabel}
                  <div className="dropdown dropdown-left p-2 cursor-text light-shadow">
                    {standupAnswer[0].answers.map((item, index) => (
                      <Fragment key={item._id}>
                        <p className="medium bold">{item.question}</p>
                        <p className={`medium ${index < standupAnswer[0].answers.length - 1 && "mb-1"}`}>{item.text}</p>
                      </Fragment>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="small grey underline bold checkin-focus relative mb-1">{noCheckin}</div>
              )}
						
              {!isDayFocus && (
                <div className="cursor checkin-focus relative btn-action">
                  <span className="small-xs">{otherUpdated}</span>
                  <div className="dropdown dropdown-left p-2 cursor-text light-shadow ma-500 ma-320-xs">
                    <div>
                      <p className="medium bold">{text}</p>
                      <p className={`fs-14 ${color} inline-block mr-1`}>{label}</p>
                      <p className="fs-14 inline-block mr-1">{currentlLabel}:{' '}{current}</p>
                      <p className="fs-14 inline-block">{goalLabel}:{' '}{goal}</p>
                    </div>
                  </div>
                </div>
              )}
						</div>
      </ErrorBoundary>
    </Fragment>
  );
};

Timeline.propTypes = {
  item: PropTypes.object.isRequired,
  standupAnswer: PropTypes.array,
};

export default Timeline;
