import {
  SET_LS,
  ERROR_LS,
  REFERRAL_ERROR,
  GET_REFERRAL,
  REFERRAL_SIGNUP_SUCCESS,
  REFERRAL_SIGNUP_FAIL
} from '../actions/types';

const initialState = {
  tokenRef: localStorage.getItem('tokenRef'),
  faffRef: localStorage.getItem('faff-ref'),
  isAuthenticatedRef: null,
  loading: true,
  user: null,
  error: false
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_REFERRAL:
      localStorage.removeItem('faff-ref');
      return {
        ...state,
        isAuthenticatedRef: true,
        loading: false,
        user: payload,
        error: false
      };
    case REFERRAL_SIGNUP_SUCCESS:
      localStorage.setItem('tokenRef', payload.tokenRef);
      localStorage.removeItem('faff-ref');
      return {
        ...state,
        ...payload,
        isAuthenticatedRef: true,
        loading: false,
        error: false
      };
    case REFERRAL_SIGNUP_FAIL:
    case REFERRAL_ERROR:
      localStorage.removeItem('tokenRef');
      return {
        ...state,
        tokenRef: null,
        isAuthenticatedRef: false,
        loading: false,
        error: payload
      };
    case ERROR_LS:
      localStorage.removeItem('faff-ref');
      return {
        ...state,
        tokenRef: null,
        isAuthenticatedRef: false,
        loading: false,
        error: payload
      };
    case SET_LS:
      localStorage.setItem('faff-ref', payload);
      return {
        ...state,
        tokenRef: null,
        isAuthenticatedRef: false,
        loading: false
      };
    default:
      return state;
  }
}
