import axios from 'axios';
import { setAlert } from './alert';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  GET_STANDUPS,
  GET_STANDUP,
  STANDUPS_ERROR,
  GET_SETTINGS,
  SETTINGS_ERROR,
  CLEAR_STANDUP_SETTINGS,
  GET_SETTINGS_DASHBOARD,
} from './types';

// Edit standup
export const editStandup = (formData, history) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(`/api/standups/edit`, formData, config);

    dispatch({
      type: GET_STANDUPS,
      payload: res.data
    });

    getStandupSettings();

    dispatch(
      setAlert(
        <FormattedMessage
          id="alert.standup.edit"
          defaultMessage="Standups Updated"
        />,
        'success'
      )
    );

    history.goBack();
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: STANDUPS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all companies standups
export const getStandups = (limit) => async dispatch => {
  try {
    const res = await axios.get(`/api/standups${limit?'?limit='+limit:''}`);

    dispatch({
      type: GET_STANDUPS,
      payload: res.data
    });
    return true;
  } catch (err) {
    dispatch({
      type: STANDUPS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    return false;
  }
};


// Get standup settings
export const getStandupSettings = (isDashboard = false) => async dispatch => {
  try {
    const res = await axios.get('/api/standups/settings');

    // Загрузка на странице настроек
    if (!isDashboard) {
      dispatch({
        type: GET_SETTINGS,
        payload: res.data
      });
    } else {
      // загрузка на странице /settings
      dispatch({
        type: GET_SETTINGS_DASHBOARD,
        payload: res.data
      });
    }
  } catch (err) {
    dispatch({
      type: SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get user team standups
export const getTeamStandups = () => async dispatch => {
  try {
    const res = await axios.get('/api/standups/team');

    dispatch({
      type: GET_STANDUPS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: STANDUPS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
}; 

// Get standup by ID
export const getStandupById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/standups/${id}`);

    dispatch({
      type: GET_STANDUP,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: STANDUPS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Switch ON/OFF settings
export const toggleSettings = () => async dispatch => {
  try {
    const res = await axios.put('/api/standups');

    dispatch({
      type: GET_SETTINGS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


export const clearStandupSettings = () => async dispatch => {
  try {
   
    dispatch({
      type: CLEAR_STANDUP_SETTINGS
    });
  } catch (err) {
    dispatch({
      type: SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

