import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const Widgets = ({
  receivedRecognitions,
  givedRecognitions,
  titleLeft,
  titleRight,
  received,
  handleReceived,
  given,
  handleGiven
}) => {
  
  return (
    <Fragment>
      <div className="widgets widgets-recognition">
        <div
          className="board left-board bg-green p-3 shadow"
        >
          <p className="number left-number xxl white">
            {receivedRecognitions.length}
          </p>
          <p className="large white">
            {titleLeft}
          </p>
        </div>

        <div
          className="board right-board bg-red p-3 shadow"
        >
          <p className="number right-number xxl white">
            {givedRecognitions.length}
          </p>
          <p className="large white">
            {titleRight}
          </p>
        </div>
      </div>
    </Fragment>
  );
};

Widgets.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  null
)(Widgets);
