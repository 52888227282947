import axios from 'axios';
import { setAlert } from './alert';
import store from '../store';
import { logoutAsUser, logout } from './auth';

import {
  GET_COMPANY,
  COMPANY_ERROR,
  GET_TEAMS,
  TEAMS_ERROR,
  GET_TEAM,
  TEAM_ERROR,
  ADD_TEAM,
  ADD_TEAM_ERROR,
  DELETE_TEAM,
  DELETE_TEAM_ERROR,
  EDIT_TEAM,
  CLEAR_TEAM,
  CHECK_CODE,
  CODE_ERROR,
  EDIT_TEAM_ERROR,
  UPDATE_PLAN,
  UPDATE_PLAN_ERROR,
  INVOICE_ERROR,
  DELETE_COMPANY,
  DELETE_COMPANY_ERROR,
  CREATE_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  SUBSCRIPTION_ERROR,
} from './types';

// Get current user's company
export const getUserCompany = () => async dispatch => {
  try {
    const res = await axios.get('/api/company');

    dispatch({
      type: GET_COMPANY,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: COMPANY_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all teams
export const getCompanyTeams = () => async dispatch => {
  try {
    const res = await axios.get('/api/company/teams');

    dispatch({
      type: GET_TEAMS,
      payload: res.data
    });

    setTimeout(() => dispatch({ type: CLEAR_TEAM }), 1000);
    
  } catch (err) {
    dispatch({
      type: TEAMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get team by team ID
export const getTeamById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/company/team/${id}`);

    dispatch({
      type: GET_TEAM,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TEAM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Create new team
export const createTeam = (department, users, history) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({ department, users });

    const res = await axios.post(`/api/company/team`, body, config);

    dispatch({
      type: ADD_TEAM,
      payload: res.data
    });

    dispatch(setAlert('Team created', 'success', 2000));

    history.push('/teams');
  } catch (err) {
    console.log(err);

    if (err.response.data.errors) {
      const errors = err.response.data.errors;

      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ADD_TEAM_ERROR,
      payload: { msg: err.response.data.errors, status: err.response.status }
    });
  }
};

// Edit team
export const editTeam = (id, department, users, history) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const body = JSON.stringify({ department, users });
    
    const res = await axios.put(`/api/company/team/${id}`, body, config);

    dispatch({
      type: EDIT_TEAM,
      payload: res.data
    });

    dispatch(setAlert('Team updated', 'success', 2000));

    history.push('/teams');
  } catch (err) {
    
    if (err.response.data.errors) {
      const errors = err.response.data.errors;

      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: EDIT_TEAM_ERROR,
      payload: { msg: err.response.data.errors, status: err.response.status }
    });
  }
};

// Delete team
export const deleteTeam = team_id => async dispatch => {
  try {
    await axios.delete(`/api/company/team/${team_id}`);

    dispatch({
      type: DELETE_TEAM,
      payload: team_id
    });

    dispatch(setAlert('Team was deleted', 'warning', 2000));
  } catch (err) {
    if (err.response.data.errors) {
      const errors = err.response.data.errors;

      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: DELETE_TEAM_ERROR,
      payload: { msg: err.response.data.errors, status: err.response.status }
    });
  }
};

// Update plan
export const updatePlan = (plan, time, id, btn = false, priceId, seats = 1) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {

    let newPlan = false;

    if (btn) {
      newPlan = {
        plan: btn,
        time
      }
    }

    const body = JSON.stringify({ plan, time, newPlan, priceId, seats });
    const res = await axios.put(`/api/company/plan/update/${id}`, body, config);

    dispatch({
      type: UPDATE_PLAN,
      payload: res.data
    });

    dispatch(setAlert('Plan was updated', 'success', 2000));

  } catch (err) {
    console.log(err);

    if (err.response.data.errors) {
      const errors = err.response.data.errors;

      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: UPDATE_PLAN_ERROR,
      payload: { msg: err.response.data.errors, status: err.response.status }
    });
  }
};


// Invoice request
export const sendInvoice = (email, price, users, monthly = false, authEmail, authCompanyId) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {

    const body = JSON.stringify({ email, price, users, monthly, authEmail, authCompanyId });

    await axios.put(`/api/company/plan/invoice`, body, config);

  } catch (err) {
    console.log(err);

    if (err.response.data.errors) {
      const errors = err.response.data.errors;

      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: INVOICE_ERROR,
      payload: { msg: err.response.data.errors, status: err.response.status }
    });
  }
};


// Check promocode
export const checkCode = (code, id, email) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {

    const body = JSON.stringify({ code, email });
    const res = await axios.post(`/api/company/promocode/${id}`, body, config);

    dispatch({
      type: CHECK_CODE,
      payload: res.data
    });
    
  } catch (err) {
    console.log(err);

    if (err.response.data.errors) {
      const errors = err.response.data.errors;

      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: CODE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};



// Create Subscription
export const createSubscription = (companyId, seats, plan, priceId) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {

    const body = JSON.stringify({ companyId, seats, plan, priceId});
    const res = await axios.post(`/api/company/payment/subscription/${companyId}/${seats}`, body, config);
    // console.log('====== res.data in createSubscription', res.data)

    dispatch({
      type: CREATE_SUBSCRIPTION,
      payload: res.data
    });

    return res.data;
    
  } catch (err) {
    console.log(err);

    if (err.response.data.errors) {
      const errors = err.response.data.errors;

      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: SUBSCRIPTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};



// Cancel Subscription
export const cancelSubscription = () => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {

    // const body = JSON.stringify({ companyId, seats, plan, priceId});
    const res = await axios.put(`/api/company/payment/subscription/cancel`, config);
    // console.log('====== res.data in createSubscription', res.data)

    dispatch({
      type: CANCEL_SUBSCRIPTION,
      payload: res.data
    });

    // return res.data;
    
  } catch (err) {
    console.log(err);

    if (err.response.data.errors) {
      const errors = err.response.data.errors;

      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: SUBSCRIPTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Delete team
export const deleteCompany = () => async dispatch => {
  try {
    let res = await axios.delete(`/api/company/delete`);

    console.log('===== res.data', res.data)

    dispatch({
      type: DELETE_COMPANY,
    });

    store.dispatch(logout())

    dispatch(setAlert('Company was updated', 'success', 3000));
  } catch (err) {
    console.log('===== error', err)

    if (err.response.data.errors) {
      const errors = err.response.data.errors;

      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: DELETE_COMPANY_ERROR,
      payload: { msg: err.response.data.errors, status: err.response.status }
    });
  }
};