import React, { Fragment } from "react";
import PropTypes from "prop-types";

const BtnTodoClose = ({ onClick, index, name }) => {

  return (
    <Fragment>
      <span 
        onClick={() => onClick(index, name)}
        className="no_btn small"
      >
        <i className="fas fa-times cursor grey fs-14"/>
    </span>
    </Fragment>
  );
};

BtnTodoClose.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

export default BtnTodoClose;
