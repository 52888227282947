import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UserNav from '../layout/UserNav';
import Logo from '../elems/Logo';
import UpdateForm1 from './elems/UpdateForm1';
import UpdateForm2 from './elems/UpdateForm2';
import { getUserPlanAnswer } from '../../actions/planAnswer';
import { addUpdateAnswer, getUserUpdateAnswer } from '../../actions/updateAnswer';
import { getUpdateById, getUpdateSettings } from '../../actions/update';
import { getCompanyProfiles } from '../../actions/profile';
import { addRecognition } from '../../actions/recognition';
import { getAllUserOkrs, getAllIndividualUserOkrs, updateManyOkrs, getActiveOkrs } from '../../actions/okr';
import { setAlert } from '../../actions/alert';
import Spinner from '../layout/Spinner';
import Select from 'react-select';
import Loader from 'react-loader-spinner';
import { FormattedMessage } from 'react-intl';

let iteration = 0;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    fontSize: '14px',
  }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
}

const UpdateForm = ({
  setAlert,
  getUpdateSettings,
  getCompanyProfiles,
  addRecognition,
  profile: { profiles },
  addUpdateAnswer,
  getUpdateById,
  update: { update, loading, settings },
  updateAnswer: { updateAnswer },
  okr: { okrs },
  match,
  history,
  locale,
  getUserUpdateAnswer,
  updateManyOkrs,
  getUserPlanAnswer,
  planAnswer: { planAnswer },
  getActiveOkrs,
  auth: {user},
  getAllIndividualUserOkrs
}) => {
  const [formData, setFormData] = useState({
    answers: [],
    text: '',
    value: '',
    selectedOption: [],
    focus: '',
    previousUpdateAnswers: [],
    previousPlanAnswers: [],
    previousAnswers: [],
    notes: '', 
    okrQuestion: {},
    weeklyOkrs: [],
    objectives: [],
  });

  const [isLoader, toggleLoader] = useState(false);
  const [errorIndex, setErrorIndex] = useState(null); 

  let { answers, text, value, selectedOption, focus, 
    previousUpdateAnswers, previousAnswers, 
    notes, okrQuestion, weeklyOkrs, objectives
  } = formData;

  let previousUpdateAnswersArr = [];
  let previousPlanAnswersArr = [];

  let questionIndex = 1;
  let questionPlanIndex = 0;

  // Инспектрум 
  // Inspectrum id === 60dd2dedb3bee50109e45fa2
  if (planAnswer && planAnswer.answers.length > 0 && planAnswer.company === "60dd2dedb3bee50109e45fa2") {
    questionPlanIndex = 2;
  } 

  // Get right answers for different types of campaign: answers.text or answers.okrAnswers
  let answersArrRaw = settings && updateAnswer && updateAnswer.answers && updateAnswer.answers.length > 0 ? (settings.campaignType === 1 ? (updateAnswer.answers[questionIndex].text) : (updateAnswer.answers[questionIndex].okrAnswers)) : [];
  let planAnswersArrRaw = settings && planAnswer && planAnswer.answers && planAnswer.answers.length > 0 ? (settings.campaignType === 1 ? (planAnswer.answers[questionPlanIndex].text) : (planAnswer.answers[questionPlanIndex].okrAnswers)) : [];
 
  {
    answersArrRaw.forEach(answer => {
      let obj = {};

      if (settings.campaignType === 1) {
        // Classic type
        obj = {
          text: answer,
          completeOne: false,
          completeTwo: false,
          date: updateAnswer.date
        }
        
        previousUpdateAnswersArr.push(obj);

      } else {
        // OKR-based type
        for (let i = 0; i < answer.text.length; i ++) {
          obj = {
            text: answer.text[i],
            isCompleted: answer.isCompleted,
            okrId: answer.okrValue,
            completeOne: false,
            completeTwo: false
          }

          previousUpdateAnswersArr.push(obj);
        }
      }
    })
  }

  {
    planAnswersArrRaw.forEach(answer => {
      let obj = {};

      if (settings.campaignType === 1) {
        // Classic type
        obj = {
          text: answer,
          completeOne: false,
          completeTwo: false,
          date: planAnswer.date,
        }
        
        previousPlanAnswersArr.push(obj);

      } else {
        // OKR-based type
        for (let i = 0; i < answer.text.length; i ++) {
          obj = {
            text: answer.text[i],
            isCompleted: answer.isCompleted,
            okrId: answer.okrValue,
            completeOne: false,
            completeTwo: false,
            date: planAnswer.date
          }

          previousPlanAnswersArr.push(obj);
        }
      }
    })
  }

  const chooseAnswer = () => {
    let item = [];
    
    if (previousPlanAnswersArr.length > 0 && previousUpdateAnswersArr.length === 0) { item = previousPlanAnswersArr };
    if (previousPlanAnswersArr.length === 0 && previousUpdateAnswersArr.length > 0) { item = previousUpdateAnswersArr };
    if (previousPlanAnswersArr.length === 0 && previousUpdateAnswersArr.length === 0) { item = [] };
    if (previousPlanAnswersArr.length > 0 && previousUpdateAnswersArr.length > 0) { 
      // Check what is the latest answer
      if (Date.parse(previousPlanAnswersArr[0].date) > Date.parse(previousUpdateAnswersArr[0].date)) {
        item = previousPlanAnswersArr;
      } else {
        item = previousUpdateAnswersArr;
      }
     };

    return item;
  };

  const getWeeklyOkrs = (okrs) => {
    let objectivesArr = []; 

    // console.log('===== getWeeklyOkrs okrs.length', okrs.length)
    // console.log('===== getWeeklyOkrs: settings && settings.campaignType === 2', settings && settings.campaignType === 2)

    // CampaignType === 2
    if (settings && settings.campaignType === 2) {
    // console.log('===== getWeeklyOkrs planAnswer', planAnswer)
    // planAnswer && console.log('===== getWeeklyOkrs planAnswer.weeklyOkrs', planAnswer.weeklyOkrs)

      {
        okrs && okrs.length > 0 && planAnswer && planAnswer.weeklyOkrs.length > 0 && planAnswer.weeklyOkrs.forEach((weekly, index) => {
          for (let i = 0; i < okrs.length; i ++) {
            let okr = okrs[i];
            
            if (okr._id.toString() === weekly.okr) {
              objectivesArr.push(okr);
            } 
  
            if (weekly.okr === 0 && i === index) {
              objectivesArr.push(weekly);
            }
          }
        })
      }
    } else {
      // For campaignType === 1
      objectivesArr = okrs;

      // let newItems = [];

      // okrs.forEach(okr => {
      //   if (okr.owner === user._id.toString() && !okr.isArchive) {
      //     newItems.push(okr);
      //   } else if (okr.keyResults.length > 0 && !okr.isArchive) {
      //     let uniqueOKR = false;

      //     okr.keyResults.forEach((kr) => {
      //       if (
      //         !uniqueOKR &&
      //         kr.owners.findIndex((i) => i.owner === user._id.toString()) > -1
      //       ) {
      //         uniqueOKR = true;
      //         // if user is owner of key result
      //         return newItems.push(okr);
      //       }
      //     });
      //   } else {
      //     return null;
      //   }
      // })

      // // console.log('===== getWeeklyOkrs: newItems', newItems)

      // objectivesArr = newItems;
    }

    // console.log('===== getWeeklyOkrs: objectivesArr', objectivesArr)

    
    return objectivesArr;
  };

  let answerArr = answers;
  // planAnswer && console.log('==== planAnswer.answers', planAnswer.answers)
  // Formatting answers if it's edit form
  if (settings && planAnswer && settings.campaignType === 2 && planAnswer.answers.length > 0) {
    planAnswer.answers.forEach((answer, index) => {

      let arr = [];

      // Мы сюда не заходим же в условии выше
      // Почему стоит это условие?
      if (settings.campaignType === 1) {
        // Classic campaign
        answerArr.push(answer.text);

      } else {
        // OKR-based
        if (index === 0) {
          for (let i = 0; i < answer.okrAnswers.length; i ++) {
            let item = answer.okrAnswers[i];
            let newArr = [];

            for (let y = 0; y < item.text.length; y ++) {

              let obj = {
                text: item.text[y],
              }

              newArr.push(obj);
            }
            
            arr.push(newArr);
          }
          
          answerArr.push(arr);
        }
      }
    })
  }
    // console.log('=====  user', user)

  useEffect(() => {
    // console.log('===== USEEFFECT 1 WORKS: okrs', okrs)
    // console.log('==== settings here', settings)
    // console.log('==== okrs here', okrs)
    
    if (settings && okrs.length === 0) {
      console.log('==== settings.campaignType', settings.campaignType)
      if (settings.campaignType === 1 || settings.campaignType === undefined) {

        // getAllUserOkrs();
        getAllIndividualUserOkrs();
      } else {

        getActiveOkrs();
      }
    // } else if (settings === null) {
    } else if (iteration === 0) {
      // First loading
      getUpdateById(match.params.id);
      getUserUpdateAnswer();
      getCompanyProfiles();
      getUserPlanAnswer();
      getUpdateSettings();
    }

    setFormData({
      ...formData,
      // previousUpdateAnswers: previousUpdateAnswersArr.length === 0 ? [] : previousUpdateAnswersArr,
      previousUpdateAnswers: previousUpdateAnswers.length === 0 ? previousUpdateAnswersArr : previousUpdateAnswers,
      previousPlanAnswers: previousPlanAnswersArr.length === 0 ? [] : previousPlanAnswersArr,
      // previousAnswers: chooseAnswer(),
      previousAnswers: previousAnswers.length === 0 ? chooseAnswer() : previousAnswers,
      // answers: answerArr,
      answers: settings && settings.campaignType === 2 ? answerArr : answers,
      okrQuestion: !update ? { isActive: false } : update.okrQuestion,     
      weeklyOkrs: settings && planAnswer && settings.campaignType === 2 && planAnswer.weeklyOkrs && planAnswer.weeklyOkrs.length > 0 ? planAnswer.weeklyOkrs : [],
      objectives: okrs && okrs.length > 0 ? getWeeklyOkrs(okrs) : [],
    });

    iteration += 1;
  }, [
    getCompanyProfiles, 
    match.params.id, 
    JSON.stringify(planAnswer),
    JSON.stringify(okrs),
    settings && settings.campaignType,
  ]);

  // console.log('==== formData', formData)
  // console.log('==== answers', answers)

  let options = [];

  {
    profiles &&
      profiles.forEach(profile => {
        let coworker = {
          value: profile._id,
          label: `${profile.firstName} ${profile.lastName}`
        };

        options.push(coworker);
      });
  }

  let okrOptions = [];

  let noOkrMsg = locale && locale.lang !== 'ru' ? `😳 I won't work on OKR next week` : `😳 Я не буду работать над OKR на следующей неделе`; 
  {
    okrs && okrs.length > 0 &&
      okrs.forEach((okr, i) => {

        if (!okr.isArchive) {
          let objective = { value: okr._id, label: `🎯 ${okr.objective}`, okr: okr._id, objective: okr.objective, isFocus: true, text: okr.objective, focusIndex: 99, status: okr.status, progress: okr.progress, goal: 100 };
          
          okrOptions.push(objective);

          let krs = okr.keyResults;

          if (krs.length > 0) {
            krs.forEach((kr, index) => {
              let item = { value: kr._id, label: ` - ${kr.title}`, okr: okr._id, objective: okr.objective, isFocus: true, text: kr.title, focusIndex: index, status: okr.status, progress: kr.progress, goal: kr.goal, current: kr.current, krId: kr._id, isKr: true };
          
              okrOptions.push(item);
            })
          }
        }

      if (i === okrs.length - 1) {
          let noOkr = { value: `noOkr-${okr._id}`, label: noOkrMsg, isFocus: false }
          okrOptions.push(noOkr);
        }
      });
  } 

  // Make answers.length is equal for questions.length
  update && settings && answers && update.questions.length !== 0 && update.questions.length !== answers.length && update.questions.forEach((q, i) => {

    let text = [""];

    if (settings.campaignType === 1) {
      // Classic
      answers.push(text);
    } else {
      // OKR-based
      if (i === 0 && answers.length === 0 && answerArr.length === 0) { 
        answers.push([text]);
      } else if (i === 0 && answers.length === 0 && answerArr.length > 0) {
        answers = answerArr;
      }  

      if (i >= 1) { 
        answers.push(text)
      };
    }

  });

  const handleAddOkrAnswer = (i) => {
    let obj = { text: '', isNew: true, isCompleted: true };
    answers[0][i].push(obj);
    setFormData({ ...formData, answers: answers });
  }

  const handleChange = selectedOption => {
    setFormData({ ...formData, selectedOption: selectedOption });
  };

  const onChangeSelect = (e, index) => {
    answers[index] = [e.target.value];
    setFormData({ ...formData, answers: answers });
  }

  const onChangeOkrSelect = (e, index, i, y) => {
    answers[index][i][y]["isCompleted"] = e.target.value;
    setFormData({ ...formData, answers: answers });
  }

  const onChangeAnswer = (e, index, i) => {
    // Add new input
    if (index > 0 && answers[index][i].length === 0 && answers[index].length === i + 1) {

      answers[index].push("");
    }

    answers[index][i] = e.target.value;

    setFormData({ ...formData, answers: answers });
  };

  const onChange0Answer = (e, index, i) => {
    // Add new input
    if (index > 0 && answers[index][i].length === 0 && answers[index].length === i + 1) {

      answers[index].push("");
    }

    answers[0] = e.target.value;

    setFormData({ ...formData, answers: answers });
  };

  const onChangeOkrAnswer = (e, index, i, y) => {
    // Add new input
    if (answers[index][i][y].text.length === index && answers[index][i].length === y + 1) {
      let obj = { text: '', isNew: true };
      answers[index][i].push(obj);
    }

    answers[index][i][y][e.target.name] = e.target.value;

    setFormData({ ...formData, answers: answers });
  };

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onChangeOkr = (e, indexOkr, indexKr) => {
    if (e.label) {
      // If it's status select
      objectives[indexOkr].status = e.value;
      objectives[indexOkr].isNewStatus = true;
    } else {
      // If it's goals
      objectives[indexOkr].keyResults[indexKr][e.target.name] = e.target.value;
      objectives[indexOkr].isNewResults = true;
      objectives[indexOkr].keyResults[indexKr].isNew = true;  
    }
    
    objectives[indexOkr].isNew = true;

    setFormData({ ...formData, objectives: objectives });
  };

  const handleFocus = selectedOption => {

    setFormData({ ...formData, focus: selectedOption });
  };

  const onSubmit = e => {
    e.preventDefault();
    let arr = [];
    let arr2 = [];

    console.log('======= answers in submit', answers)

    // Show Loader
    toggleLoader(true);

    for (let i = 0; i < answers.length; i ++) {
      
      // Check if it's empty and only 1 input
      if (answers[i].length === 1 && answers[i][0].length === 0) {
        // Hide Loader
        toggleLoader(false);
        setErrorIndex(i);
        
        return setAlert(
          <FormattedMessage
            id="form.input.empty"
            defaultMessage="Please fill out the field of Weekly Update"
          />,
          'danger'
        );
      } else {

        // Remove empty element from answers
        // Возвращаем в новые массивы arr значения ответы БЕЗ пустых ячеек
        // Специально не меняем answers для этого. Иначе если с бекенда придет ошибка, то эти пустые поля останутся и при повторном вводе в них будет ошибка, тк в стейте answers они будут удалены. Альтернатива - скрыть их на клиенте, но тогда не будет возможности ввести при повторе новое поле. Поэтому добавляем новый arr2, куда сохраняем ответы без пустых строк и отправляем на сервер.
        // answers[0] - добавил, чтобы оценка для графика заходила сюда и записывалась в arr2 (у нее длина всегда 1)
        // console.log('======= answers[0]', answers[0])
       
        if (answers[0] || answers[i][answers[i].length - 1].length === 0) {
          arr = answers[i].filter(item => item.length > 0);

          if (arr.length === 0) {
            // Hide Loader
            toggleLoader(false);
            setErrorIndex(i);

            return setAlert(
              <FormattedMessage
                id="form.input.empty"
                defaultMessage="Please fill out the field"
              />,
              'danger'
            );
          }

          arr2.push(arr);
        }
      }
    }

    if (text !== '' && selectedOption.value !== undefined) {
      addRecognition(text, value, selectedOption.value);
    } else if (
      (text !== '' && selectedOption.value === undefined) ||
      (text === '' && selectedOption.value !== undefined)
    ) {
      // Hide Loader
      toggleLoader(false);

      return setAlert(
        <FormattedMessage
          id="auth.update.alert.recognition"
          defaultMessage="Please fill all fields, if you want to create recognition"
        />,
        'danger'
      );
    }

    if (objectives && objectives.length > 0) {
      updateManyOkrs(objectives)
    }

    addUpdateAnswer(arr2, focus, match.params.id, weeklyOkrs, history, notes);
    
    // Hide Loader
    toggleLoader(!isLoader);
  };
  // console.log('===== answers', answers)

  const handleAnswer = (e, answer, index, i) => {
    e.preventDefault();

    // console.log('====== answer in handleAnswer', answer)

    // Toggle - complete or not 
    if (index === 1) {

      // Add or remove from the form
      if (answer.completeOne === false) {
        // Add answer to the form
        addAnswerToForm(answer, index, i);
      } else {

        // Remove from answers arr
        let removeIndex = answers[index].indexOf(answer.text);

        if (removeIndex > -1) {
          answers[index].splice(removeIndex, 1); 
        }
      }

      // Toggle
      answer.completeOne = !answer.completeOne;
    } else if (index === 2) {
      // Second question

      // Add or remove answer from the form
      if (answer.completeTwo === false) {
        // Add answer to the form
        addAnswerToForm(answer, index, i);
      } else {

        // Remove from answers arr
        let removeIndex = answers[index].indexOf(answer.text);

        if (removeIndex > -1) {
          answers[index].splice(removeIndex, 1); 
        }
      }

      // Toggle
      answer.completeTwo = !answer.completeTwo;
    }

    setFormData({ ...formData, answers: answers });
  };


  const addAnswerToForm = (answer, index, i) => {
    // Add new input
    if (answers[index][i] && answers[index][i].length === 0 && answers[index].length === i + 1) {

      answers[index].push("");
    }

    // если первое значение уже записано, то записываем в новый инпут
    if (answers[index][i] && answers[index][i].length === 0) {

      answers[index][i] = answer.text;
    } else {
      // если в текущий i уже записали руками ответ, то помещаем ответ в последний элемент
      // и создаем новый инпут
      answers[index][answers[index].length - 1] = answer.text;

      answers[index].push("");
    } 
  };

  // Check the object length is not 0
  let okrQuestionLength = 0;
  if (okrQuestion !== undefined && okrQuestion !== null) {
    okrQuestionLength = Object.keys(okrQuestion).length;
  }

  // Loader for button
  let loadingIcon = (
    <span
      style={{
        width: '60px',
        height: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader type='Circles' color='#DAE3E5' height='18px' width='100' />
    </span>
  );

  let btnText = <FormattedMessage id='standup.form.submit' defaultMessage='Submit' />;

  if (isLoader) {
    btnText = loadingIcon;
  }

  const goBack = () => {
    history.goBack();
  };


  const handleAnswerChange = (answerIndex, answerValue) => {
    setFormData((prev) => {
      const newFormData = { ...prev };
      newFormData.answers[answerIndex] = answerValue;
      return newFormData;
    });
  };

  // console.log('==== formData', formData)
  // console.log('==== errorIndex', errorIndex)
  // console.log('==== isLoader', isLoader)
  // console.log('==== objectives', objectives)
  // settings && console.log('==== settings.campaignType', settings.campaignType)

  return (
    <Fragment>
      <div className="css-main bg-primary">
          <div className="wrapper">
            <div className="main_menu absolute"><Logo /></div>
            <UserNav />

            {loading ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="main mxa user-standups pl-0 pt-0-xs">
                  <div className="standups mt-3">
                    <div className="section-title">
                      <h5>
                        <FormattedMessage
                          id="update.form.header"
                          defaultMessage="Complete Weekly Update"
                        />
                      </h5>
                      <p
                        onClick={e => goBack()}
                        className="btn-secondary btn-secondary-back"
                      >
                        <FormattedMessage id="go.back" defaultMessage="Go Back" />
                      </p>
                    </div>

                    <div className="form_okr-update form-updates mb-3">
                      <form
                        className="form-app form-app-updates"
                        onSubmit={e => onSubmit(e)}
                      >
                        {settings && settings.campaignType === 1 ? (
                          <UpdateForm1 
                            update={update}
                            answers={answers}
                            onChangeSelect={onChangeSelect}
                            previousAnswers={previousAnswers}
                            onChangeAnswer={onChangeAnswer}
                            okrQuestionLength={okrQuestionLength}
                            focus={focus}
                            handleFocus={handleFocus}
                            customStyles={customStyles}
                            onChange={onChange}
                            // okrs={objectives}
                            okrs={objectives}
                            notes={notes}
                            onChangeOkr={onChangeOkr}
                            okrQuestion={okrQuestion}
                            okrOptions={okrOptions}
                            handleAnswer={handleAnswer}
                            handleChange={handleAnswerChange}
                            errorIndex={errorIndex}
                          />
                        ) : (
                          <UpdateForm2 
                            update={update}
                            answers={answers}
                            onChangeSelect={onChangeSelect}
                            previousAnswers={previousAnswers && previousAnswers}
                            onChangeAnswer={onChangeAnswer}
                            okrQuestionLength={okrQuestionLength}
                            focus={focus}
                            handleFocus={handleFocus}
                            customStyles={customStyles}
                            onChange={onChange}
                            okrs={objectives}
                            notes={notes}
                            onChangeOkr={onChangeOkr}
                            okrQuestion={okrQuestion}
                            okrOptions={okrOptions}
                            handleAnswer={handleAnswer}
                            handleAddOkrAnswer={handleAddOkrAnswer}
                            onChangeOkrAnswer={onChangeOkrAnswer}
                            onChangeOkrSelect={onChangeOkrSelect}
                            onChange0Answer={onChange0Answer}
                          />
                        )}
                        
                        <div className="component standups-daily mt-2 p-3 light-shadow">
                          {!profiles ? (
                            <Spinner />
                          ) : (
                            <Fragment>
                              <p className="large bold inline">
                                <FormattedMessage
                                  id="you.update.recognition.form.header"
                                  defaultMessage="Recognition"
                                />
                              </p>
                              <p className="small inline grey pl-1">
                                <FormattedMessage
                                  id="you.update.recognition.form.sub-header"
                                  defaultMessage="Optional"
                                />
                              </p>

                              <div className="py-2">
                                <p className="meduim">
                                  <FormattedMessage
                                    id="recognition.form.subHeader"
                                    defaultMessage="Did someone do a great job this week? Let them know by sending appreciation. This recognition will be seen in recipient's feed. "
                                  />
                                </p>
                                <div className="line" />
                              </div>

                              <div className="form-main">
                                <p className="bold medium block">
                                  <FormattedMessage
                                    id="user.recognition.create.coworker"
                                    defaultMessage="Co-worker"
                                  />
                                </p>
                                <FormattedMessage
                                  id="user.recognition.create.coworkerSelect"
                                  defaultMessage="Choose the co-worker"
                                >
                                  {msg => (
                                    <Select
                                      value={selectedOption}
                                      onChange={selectedOption =>
                                        handleChange(selectedOption)
                                      }
                                      options={options}
                                      isSearchable
                                      placeholder={msg}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>

                              <div className="form-main mb-1">
                                <p className="bold medium block">
                                  <FormattedMessage
                                    id="user.recognition.create.text"
                                    defaultMessage="Apprecition"
                                  />
                                </p>

                                <FormattedMessage
                                  id="user.recognition.create.textInput"
                                  defaultMessage="Be specific and start your 'Thank you'"
                                >
                                  {msg => (
                                    <textarea
                                      rows="3"
                                      type="text"
                                      placeholder={msg}
                                      name="text"
                                      value={text}
                                      onChange={e => onChange(e)}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>

                              <div className="form-main">
                                <p className="bold medium block">
                                  <FormattedMessage
                                    id="user.recognition.create.value"
                                    defaultMessage="Value"
                                  />
                                </p>

                                <FormattedMessage
                                  id="user.recognition.create.valueInput"
                                  defaultMessage="Add a value"
                                >
                                  {msg => (
                                    <input
                                      type="value"
                                      placeholder={msg}
                                      name="value"
                                      value={value || ''}
                                      onChange={e => onChange(e)}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>
                            </Fragment>
                          )}
                        </div>

                        <button 
                          type="submit" 
                          disabled={!isLoader ? false : true}
                          className="btn btn-submit"
                        >
                          {btnText}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
        </div>
      </div>
    </Fragment>
  );
};

UpdateForm.propTypes = {
  setAlert: PropTypes.func.isRequired,
  addRecognition: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  addUpdateAnswer: PropTypes.func.isRequired,
  getUpdateById: PropTypes.func.isRequired,
  getAllUserOkrs: PropTypes.func.isRequired,
  updateManyOkrs: PropTypes.func.isRequired,
  getUserUpdateAnswer: PropTypes.func.isRequired,
  getUserPlanAnswer: PropTypes.func.isRequired,
  getUpdateSettings: PropTypes.func.isRequired,
  getAllIndividualUserOkrs: PropTypes.func.isRequired,
  getActiveOkrs: PropTypes.func.isRequired,
  update: PropTypes.object.isRequired,
  updateAnswer: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  okr: PropTypes.object,
  planAnswer: PropTypes.object,
  auth: PropTypes.object,
};

const mapStateToProps = state => ({
  update: state.update,
  updateAnswer: state.updateAnswer,
  profile: state.profile,
  locale: state.locale,
  okr: state.okr,
  planAnswer: state.planAnswer,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  {
    addUpdateAnswer,
    getUpdateById,
    getCompanyProfiles,
    addRecognition,
    setAlert,
    getAllUserOkrs,
    updateManyOkrs,
    getUserUpdateAnswer,
    getUserPlanAnswer,
    getUpdateSettings,
    getActiveOkrs,
    getAllIndividualUserOkrs,
  }
)(withRouter(UpdateForm));
