import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { setAlert } from '../../../actions/alert';
import { connect } from 'react-redux';
import { addUpdateComment } from '../../../actions/updateAnswer';
import { FormattedMessage } from 'react-intl';
import { MentionsInput, Mention } from 'react-mentions';
import { icons } from "../../../img/elements/icons";

const CommentForm = ({
  updateID,
  answerID,
  addUpdateComment,
  user,
  avatar,
  formData,
  setFormData,
  mentions,
}) => {
  const [text, setText] = useState('');

  // ЗДЕСЬ получаем коллбэк и перебираем formData.updates и обновляем комменты
  const onSubmit = async (updateID, answerID, text) => {
    try {

      const res = await addUpdateComment(updateID, answerID, text);

      setFormData({ 
        ...formData, 
        updates: formData.updates.map(answer => {
          if (answer._id === res.updateID) {
            // Update all comments 
            answer.comments = res.comments;
            answer.answers = res.answers;
           
            return answer;
          } else { return answer; }
        })
      });
      
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in comments', err);
    }
  };

  return (
    <form
      className="form-app form-app-comments"
      onSubmit={e => {
        e.preventDefault();
        onSubmit(updateID, answerID, { text })
        setText('');
      }}
    >
      <div className="form-comment flex align-center w-100">
        <MentionsInput 
            value={text} 
            onChange={e => setText(e.target.value)} 
            className={'w-100 p-1 br-4 fs-14'}
          >
            <Mention
              trigger="@"
              data={mentions}
              markup="@{{__display__:__id__}}"
              className="lh-50"
            />
          </MentionsInput>
          <button
            type="submit"
            className="flex align-center bg-blue p-1 br-4 brd-transparent cursor btn-secondary__blue-hover ml-1"
          >
            {icons.paper_plane_white}
          </button>
      </div>
    </form>
  );
};

CommentForm.propTypes = {
  addUpdateComment: PropTypes.func.isRequired
};

export default connect(
  null,
  { addUpdateComment }
)(CommentForm);
