import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DayPicker as DayPickerInput } from 'react-day-picker'
import style from 'react-day-picker/dist/style.css';
import { format as formatDate, parseISO } from 'date-fns';
// import parseISO from 'date-fns/parseISO'

const DatePicker = ({ 
  value, onSelect, isUpdate
 }) => {

  const [isDateOpen, setIsDateOpen] = useState(false);
  const formDataRef = useRef(isDateOpen);

  function useOutsideAlerter(ref) {
    useEffect(() => {
    // console.log('========== USEEFFECT 5 WORKS')

      /** Alert if clicked on outside of element */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsDateOpen(false)
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(formDataRef)

  // console.log('====== value in date', value)
  // console.log('====== parseISO(value) in date', parseISO(value))
  // value && console.log('====== value.length in date', value.length)
  // console.log('====== formDataRef in date', formDataRef)

  // object or string
  // let formattedDate = value ? value instanceof Date ? formatDate(value, 'MM/dd/yyyy') : typeof value === 'string' ? parseISO(value) : value : '';
  if (typeof value === 'string' && value.length > 10) {
    value = parseISO(value);
  }

  return (
    <div ref={formDataRef} className={`${isUpdate ? 'DayPickerInput' : 'x'}`}>
      <input
        type="text"
        placeholder={"Choose due date"}
        value={value ? formatDate(value, 'MM/dd/yyyy') : ''}
        // onChange={() => setIsDateOpen(true)}
        onClick={() => setIsDateOpen(true)}
        className="w-100 input-text fs-18"
      />
      {isDateOpen && (
        <>
        <DayPickerInput
          mode="single"
          selected={value}
          classNames={{
            ...style,
            months: `absolute ${!isUpdate ? 'top-95' : ''} shadow p-2 z-50000 bg__white br-6`
          }}
          defaultMonth={value}
          // onSelect={onSelect}
          onSelect={(selectedOption) => {
            onSelect(selectedOption);
            setIsDateOpen(false);
          }}
        />
        </>
      )}
    </div>
  );
};

DatePicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default DatePicker;
