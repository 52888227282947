import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { addRecognition, getCompanyRecognitions } from '../../actions/recognition';
import { getCompanyProfiles } from '../../actions/profile';
import Spinner from '../layout/Spinner';
import UserNav from '../layout/UserNav';
import Logo from '../elems/Logo';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';

const RecognitionForm = ({
  addRecognition,
  getCompanyRecognitions,
  getCompanyProfiles,
  auth: { user },
  profile: { profiles, loading },
  recognition: { recognitions, givedRecognitions },
  history
}) => {
  const [formData, setFormData] = useState({
    text: '',
    value: '',
    selectedOption: [],
    givedKudos: []
  });

  useEffect(() => {
    getCompanyProfiles();
    getCompanyRecognitions();

    setFormData({
      givedKudos: !recognitions ? [] : givedRecognitions.filter(answer => answer.giver === user._id)
    });
  }, [getCompanyRecognitions, getCompanyProfiles]);

  let options = [];

  {
    profiles &&
      profiles.forEach(profile => {
        let coworker = {
          value: profile._id,
          label: `${profile.firstName} ${profile.lastName}`
        };

        options.push(coworker);
      });
  }

  const { text, value, selectedOption, givedKudos } = formData;

  const handleChange = selectedOption => {
    setFormData({ ...formData, selectedOption: selectedOption });
  };

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = e => {
    e.preventDefault();

    addRecognition(text, value, selectedOption.value, history);
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <Fragment>
      <div className="css-main bg-primary">
          <div className="wrapper">
            <div className="main_menu"><Logo /></div>
            <UserNav />

            {loading ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="main main-dashboard mxa user-standups pt-5 pt-0-xs">
                  <div className="standups mt-3">
                    <div className="section-title">
                      <h5>
                        <FormattedMessage
                          id="recognition.form.header"
                          defaultMessage="Create Recognition"
                        />
                      </h5>
                      <p
                        onClick={e => goBack()}
                        className="btn-secondary btn-secondary-back"
                      >
                        <FormattedMessage id="go.back" defaultMessage="Go Back" />
                      </p>
                    </div>

                    <div className="form-full form-recognition mb-3">
                      <form
                        className="form-app form-app-recognition"
                        onSubmit={e => onSubmit(e)}
                      >
                        <div className="component standups-daily mt-2 p-3 light-shadow">
                          {!profiles ? (
                            <Spinner />
                          ) : (
                            <Fragment>
                              <div className="py-2">
                                <p className="meduim">
                                  <FormattedMessage
                                    id="recognition.form.subHeader"
                                    defaultMessage="Did someone do a great job this week? Let them know by sending appreciation. This recognition will be seen in recipient's feed. "
                                  />
                                </p>
                                <div className="line" />
                              </div>

                              <div className="form-main">
                                <p className="bold medium block">
                                  <FormattedMessage
                                    id="user.recognition.create.coworker"
                                    defaultMessage="Co-worker"
                                  />
                                </p>
                                <FormattedMessage
                                  id="user.recognition.create.coworkerSelect"
                                  defaultMessage="Choose the co-worker"
                                >
                                  {msg => (
                                    <Select
                                      value={selectedOption}
                                      onChange={selectedOption =>
                                        handleChange(selectedOption)
                                      }
                                      options={options}
                                      isSearchable
                                      placeholder={msg}
                                      required
                                    />
                                  )}
                                </FormattedMessage>
                              </div>

                              <div className="form-main mb-1">
                                <p className="bold medium block">
                                  <FormattedMessage
                                    id="user.recognition.create.text"
                                    defaultMessage="Apprecition"
                                  />
                                </p>

                                <FormattedMessage
                                  id="user.recognition.create.textInput"
                                  defaultMessage="Be specific and start your 'Thank you'"
                                >
                                  {msg => (
                                    <textarea
                                      rows="3"
                                      type="text"
                                      required
                                      placeholder={msg}
                                      name="text"
                                      value={text}
                                      onChange={e => onChange(e)}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>

                              <div className="form-main">
                                <p className="bold medium block">
                                  <FormattedMessage
                                    id="user.recognition.create.value"
                                    defaultMessage="Value"
                                  />
                                </p>

                                <FormattedMessage
                                  id="user.recognition.create.valueInput"
                                  defaultMessage="Add a value"
                                >
                                  {msg => (
                                    <input
                                      type="value"
                                      placeholder={msg}
                                      name="value"
                                      value={value || ''}
                                      onChange={e => onChange(e)}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>
                            </Fragment>
                          )}
                        </div>

                        <button type="submit" className="btn btn-submit">
                          <FormattedMessage
                            id="standup.form.submit"
                            defaultMessage="Submit"
                          />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
        </div>
      </div>
    </Fragment>
  );
};

RecognitionForm.propTypes = {
  getCompanyRecognitions: PropTypes.func.isRequired,
  addRecognition: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  recognition: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  recognition: state.recognition,
  profile: state.profile
});

export default connect(
  mapStateToProps,
  { getCompanyProfiles, addRecognition, getCompanyRecognitions }
)(withRouter(RecognitionForm));
