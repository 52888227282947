import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UsersCardBody from './UsersCardBody';
import { FormattedMessage } from 'react-intl';
import { icons } from '../../../img/elements/icons';
import Highlighter from 'react-highlight-words';

const UsersCard = ({
  user: { firstName, lastName, avatar, departmentName, department, email, jobTitle, _id, status, role },
  standupAnswers,
  lang,
  createInvite,
  deleteUser,
  auth,
  switchTeamHead,
  searchKeyword,
  checkinsLoaded,
  // customAnswers,
}) => {
  const [displayCardBody, toggleButton] = useState(false);
  const [showIcons, toggleShowIcons] = useState(false);

  const userLink = _id ? `/user/${_id}` : '#!';

  const resend = <FormattedMessage id="resend" defaultMessage="Resend" />;
  const pending = <FormattedMessage id="pending" defaultMessage="pending" />;

  let deleteCopy = `Are you sure you wish to delete ${firstName} ${lastName}? User will be deleted forever.`;

  return (
    <>
      <tr>
        <td className="text-left py-1">
          <Link to={userLink} className="user">
            <div className="flex">
              <img
                src={avatar}
                alt="User's avatar"
                className="round-img d-block avatar mr-1"
                width="26px"
                height="26px"
              />
              <div className="flex column space-around">
                <p className="medium">
                  <Highlighter
                    highlightClassName="bg-yellow_pure"
                    searchWords={[searchKeyword]}
                    autoEscape={true}
                    textToHighlight={`${firstName} ${lastName}`}
                  />
                </p>
              </div>
            </div>
          </Link>
        </td>

        <td className="text-left py-1 medium">
          <Highlighter
            highlightClassName="bg-yellow_pure"
            searchWords={[searchKeyword]}
            autoEscape={true}
            textToHighlight={jobTitle ? jobTitle : '-'}
          />
        </td>

        <td className="text-left py-1 medium">
          <Highlighter
            highlightClassName="bg-yellow_pure"
            searchWords={[searchKeyword]}
            autoEscape={true}
            textToHighlight={departmentName ? departmentName : '-'}
          />
        </td>

        <td className='text-left py-1 medium'>
          {/* {role ? role : '-'} */}
          <Highlighter
            highlightClassName="bg-yellow_pure"
            searchWords={[searchKeyword]}
            autoEscape={true}
            textToHighlight={role === 'Head' ? 'Lead' : role}
          />
        </td>

        <td className="text-right medium">{checkinsLoaded ? standupAnswers.length : '...'}</td>

        <td className={status === 'pending_invitation' ? 'grey text-right medium' : 'text-right medium'}>
          {status !== 'pending_invitation' ? (
            <Highlighter
              highlightClassName="bg-yellow_pure"
              searchWords={[searchKeyword]}
              autoEscape={true}
              textToHighlight={email}
            />
          ) : (
            <Fragment>
              <span className="small grey mr-3">{pending}</span>
              <span className="small grey cursor underline" onClick={() => createInvite(email, lang, null)}>
                {resend}
              </span>
            </Fragment>
          )}
        </td>
        <td className="text-right">
          {status !== 'pending_invitation' && (
            <button
              onClick={() => toggleButton(!displayCardBody)}
              className="btn-action no_btn"
              type="button"
            >
              {displayCardBody ? icons.angle_up : icons.angle_down}
            </button>
          )}
        </td>

        {auth && auth.isAdmin && (
          <td className={`w-30px`}>
            <div className="flex align-center br-4 brd brd-grey__light bg__white relative">
              <div className={`no_btn relative h-34 px-1 icon__box ${showIcons ? 'icon__box_active' : ''}`} onClick={() => toggleShowIcons(!showIcons)}>
                <span className="w-50px lh-28">
                  {icons.dots}
                </span>
              </div>
              {showIcons && (
                <div className="w-150px z-20000 light-shadow absolute brd brd-grey__light br-4 dropdown-40 right bg__white">
                  <button
                    onClick={() => switchTeamHead(_id, 'Head')}
                    className="w-100 icon__box fs-14 icon__grey no_btn px-1 lh-30"
                  >
                    <i className="fas fa-flag" />{" "}
                    {role !== 'Head' ? (
                      <FormattedMessage
                        id="admin.people.head"
                        defaultMessage="Make Lead"
                      />
                    ) : (
                        <FormattedMessage
                          id="remove.head"
                          defaultMessage="Remove Lead"
                        />
                    )}
                  </button>
                  <button 
                    type="button"
                    onClick={() => { if (window.confirm(deleteCopy)) deleteUser(_id) } }
                    className="w-100 icon__box fs-14 icon__grey no_btn px-1 lh-30"
                  >
                    {icons.trash_small}
                    {' '}
                    <FormattedMessage
                      id="delete.member"
                      defaultMessage="Delete Member"
                    />
                  </button>
                </div>
                )}
            </div>
          </td>
        )}
      </tr>

      {displayCardBody && <UsersCardBody firstName={firstName} standupAnswers={standupAnswers} _id={_id} />}
    </>
  );
};

UsersCard.propTypes = {
  user: PropTypes.object.isRequired,
  standupAnswers: PropTypes.array.isRequired
};

export default UsersCard;
