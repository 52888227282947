import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import useAnalyticsEventTracker from '../../../utils/analytics/useAnalyticsEventTracker';

const ButtonSecondaryNew = ({ 
  user,
  createSubscription, people,
  isStripe, 
  plan, btn, link, url, 
  updatePlan, monthly, companyId, 
  togglePopup, popup, externalLink,
  subscriptionId, cancelSubscription,
  planName, 
}) => {

  const [isProcessing, setIsProcessing] = useState(false);


  let upgrade = <FormattedMessage id="admin.pricing.card.btn2" defaultMessage="Upgrade Plan" />;
  let downgrade = <FormattedMessage id="admin.pricing.card.btn1.downgrade" defaultMessage="Downgrade plan" />;
  let contactUs = <FormattedMessage id="admin.pricing.card.btn3" defaultMessage="Contact us" />;
  // let current = <FormattedMessage id="admin.pricing.card.btn1" defaultMessage="Extend plan" />;
  let current = <FormattedMessage id="admin.pricing.current.btn1" defaultMessage="Current plan" />;
  
  let upgradeWord = <FormattedMessage id="upgrade" defaultMessage="Upgrade" />;
  let downgradeWord = <FormattedMessage id="downgrade" defaultMessage="Downgrade" />;
  let cancelTxt = <FormattedMessage id="cancel.subscription" defaultMessage="Cancel Subscription" />;

  let cta = btn !== 33 ? upgrade : contactUs;

  if (plan === btn) {
    cta = current;
  } else if (plan < btn && btn !== 33) {
    cta = upgrade;
  } else if (plan > btn) {
    cta = subscriptionId && subscriptionId.length > 0 ? cancelTxt : downgrade;
  } else if (plan < btn && btn === 33) {
    cta = contactUs;
  }

  let time = monthly ? 1 : 12;
  let label = '';
  let priceId = '';

  // Create payment link
  let paymentLink = '';

  if (monthly) {
    // Monthly plans
    if (btn === 3) {
      // Pro
      paymentLink = 'https://usefocus.lemonsqueezy.com/checkout/buy/7e531665-cffb-4fb2-8f4c-31c046fbd4cd';
      label = 'Pro Plan Monthly';
      priceId = process.env.REACT_APP_PRO_PLAN_MO;
    } else if (btn === 4) {
      // Pro + Culture
      paymentLink = 'https://usefocus.lemonsqueezy.com/checkout/buy/a2066c20-411d-453b-a40e-503bbcbd3d15';
      // label = 'Pro+Culture Plan Monthly';
      label = 'Pro Plan Monthly';
      priceId = process.env.REACT_APP_PRO_CULTURE_PLAN_MO;
    } else if (btn === 2) {
      // Checkins
      paymentLink = 'https://usefocus.lemonsqueezy.com/checkout/buy/a9739c21-99e8-4710-9e74-0b462a7b0530';
      label = 'Check-ins Plan Monthly';
      priceId = process.env.REACT_APP_CHECKINS_PLAN_MO;
    } else if (btn === 1) {
      // Free
      label = 'Free Forever';
      // priceId = process.env.REACT_APP_PRO_PLAN_MO;
    }
  } else {

    // Yearly plans
    if (btn === 3) {
      // Pro
      paymentLink = 'https://usefocus.lemonsqueezy.com/checkout/buy/91d6d766-502b-4667-8e06-e1e306cd3b20';
      label = 'Pro Plan Yearly';
      priceId = process.env.REACT_APP_PRO_PLAN_YEAR;
    } else if (btn === 4) {
      // Pro + Culture
      paymentLink = 'https://usefocus.lemonsqueezy.com/checkout/buy/05a58b3d-27c2-4acf-9bb9-2d57c32e49f0';
      // label = 'Pro + Culture Plan Yearly';
      label = 'Pro Yearly';
      priceId = process.env.REACT_APP_PRO_CULTURE_PLAN_YEAR;
    } else if (btn === 2) {
      // Checkins
      paymentLink = 'https://usefocus.lemonsqueezy.com/checkout/buy/2559735e-18d8-40d9-9807-a4217005cdaf';
      label = 'Check-ins Plan Yearly';
      priceId = process.env.REACT_APP_CHECKINS_PLAN_YEAR;
    } else if (btn === 1) {
      // Free
      label = 'Free Forever';
      // priceId = process.env.REACT_APP_PRO_PLAN_MO;
    }
  }

  const handleClick = async (e) => {
    try {
      e.preventDefault();

      setIsProcessing(true);
  
      // let plan = 3;

      if (user && !user.subscriptionId && btn !== 1 || plan === btn) {
        // Create session for user who doesn't have subscription
        let res = await createSubscription(companyId, people, btn, priceId);
  
        // console.log('========= res handleClick finish', res) 
  
        if (res.url) {
          window.location = res.url
        }
      } else if (subscriptionId && subscriptionId.length > 0 || btn === 1 && plan !== 1) {
        // Update subscription (upgrade or downgrade)

        // let action = upgradeWord;
        let action = 'upgrade';
        if (plan < btn && btn !== 33) {
          // Upgrade
          // action = upgradeWord;
        } else if (plan > btn && btn !== 33) {
          // Downgrade
          // action = upgradeWord;
          action = subscriptionId && subscriptionId.length > 0 ? 'cancel' : 'downgrade';
        }

        if (subscriptionId && subscriptionId.length > 0) {
          // cancel
          if (window.confirm(`Are you sure you wish to cancel subscription? You will lose all benefits from the ${planName} plan.`)) cancelSubscription()

        } else {
          // upgrade / downgrade
          if (window.confirm(`Are you sure you wish to ${action} your plan to ${label}? ${label} will be active from the next subscription period.`)) updatePlan(plan, time, companyId, btn, priceId, people)
        }

        setIsProcessing(false);

      }
  
      // setIsProcessing(false);
    } catch (err) {
      setIsProcessing(false);
      console.log('========= err in handleClick', err) 
    }
  };


  const gaEventTracker = () => {
    useAnalyticsEventTracker('subscription', 'subscription payment', `Subscription payment: ${label}`)
  };

  // console.log('===== isProcessing', isProcessing)
  // console.log('===== isStripe', isStripe)
  // console.log('===== isStripe', isStripe)
  // console.log('===== link', link)
  // console.log('===== plan', plan)
  // console.log('===== btn', btn)
  // console.log('===== subscriptionId', subscriptionId)

  return (
    <Fragment>
      {isStripe ? (
        <button 
          className="btn-secondary btn-secondary-comment lh-unset h-unset py-2"
          disabled={isProcessing}
          onClick={e => handleClick(e)}
        >
          {isProcessing ? "Processing ... " : cta}
        </button>
      ) : (
        <Fragment>
          {!link ? (
            <Fragment>
              {plan === btn ? (
                <button className="btn-secondary btn-secondary-comment lh-unset h-unset py-2">{cta}</button>
              ) : (
                plan > btn ? (
                  <button 
                     className="btn-secondary btn-secondary-comment lh-unset h-unset py-2"
                     onClick={() => { if (window.confirm('Are you sure you wish to downgrade your plan? You will lose all benefits from your plan.')) updatePlan(btn, time, companyId, btn) } }
                   >
                   {cta}
                 </button>
                ) : 
                (
                    <button 
                      className="btn-secondary btn-secondary-comment lh-unset h-unset py-2"
                      onClick={() => togglePopup(!popup)}
                    >
                      {cta}</button>
                )
              )}
            </Fragment>
          ) : (
              externalLink !== 99 ? (
                externalLink !== 1 ? (
                <Link to={url} className="btn-secondary btn-secondary-comment lh-unset h-unset py-2">{cta}</Link>) : 
                (<a href={url} className="btn-secondary btn-secondary-comment lh-unset h-unset py-2" rel="noopener noreferrer">{cta}</a>)
                ) : (
                  <a href={paymentLink} className="btn-secondary btn-secondary-comment lh-unset h-unset py-2 lemonsqueezy-button" rel="noopener noreferrer" onClick={() => gaEventTracker()}>{cta}</a>
                )
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

ButtonSecondaryNew.propTypes = {
  plan: PropTypes.number.isRequired,
  btn: PropTypes.number.isRequired,
  link: PropTypes.bool,
  url: PropTypes.string,
  externalLinkl: PropTypes.number
};

export default ButtonSecondaryNew;
