import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { icons } from "../../../img/elements/icons";
import { FormattedMessage } from "react-intl";

const OkrMoreIcons = ({
  _id,
  handleArchive,
  handleDelete,
  isArchive,
  archive,
  user,
  owner,
  creator,
  isTree,
  locale,
  lang,
}) => {

  let deleteCopy = 'Are you sure you wish to delete this OKR? Data will be lost forever.'

  if (lang === 'ru') {
    deleteCopy = 'Вы уверены, что хотите удалить этот OKR? Все данные будут безвозвратно потеряны.';
  }

  return (
    <Fragment>
      {user && owner === user._id || user && creator === user._id || user && user.role === "Admin" || user && user.role === "Owner" || user && user.role === "Superadmin" ? (
      <div className="btn-action no_btn ml-xs relative" type="button">
        {icons.more}

        <div className="dropdown p-1 light-shadow">
            <Link to={`/okr/${_id}`} className="small secondary-font">
              <i className="fas fa-pencil-alt" />{' '} 
              <FormattedMessage
                id="user.okr.edit"
                defaultMessage="Edit OKR"
              />
            </Link>

            <button 
              type="button"
              onClick={(e) => handleArchive(e, _id, isArchive, isTree)}
              className="small secondary-font no_btn"
              >
              <i className="fa fa-archive" />{' '}
              {!archive ? (
                <FormattedMessage
                  id="user.okr.archive"
                  defaultMessage="Archive OKR"
                />
              ) : (
                <FormattedMessage
                  id="user.okr.unarchive"
                  defaultMessage="Unarchive OKR"
                />
              )}
            </button>
            <button 
              type="button"
              onClick={() => { if (window.confirm(deleteCopy)) handleDelete(_id, isTree) } }
              className="small secondary-font no_btn"
            >
              <i className="fas fa-times" />{' '}
              <FormattedMessage
                id="user.okr.delete"
                defaultMessage="Delete OKR"
              />
            </button>
          </div>
        </div>
        ) : null}
    </Fragment>
  );
};

OkrMoreIcons.propTypes = {
  _id: PropTypes.string.isRequired,
  handleArchive: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isArchive: PropTypes.bool.isRequired,
  archive: PropTypes.bool.isRequired,
  isTree: PropTypes.bool,
  user: PropTypes.object.isRequired,
  owner: PropTypes.string.isRequired,
  creator: PropTypes.string.isRequired,
};

export default OkrMoreIcons;