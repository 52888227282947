import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { icons } from "../../img/elements/icons";

const BtnComment = ({ comments, showComments, discussion, findComments, }) => {
  return (
    <button
      type="button"
      onClick={() => showComments(!discussion)}
      className={classnames('btn-icons btn-comment flex align-center icon__message__badge', {
        'btn-active-icon': findComments(comments)
      })}
    >
        <span className="mr-xs flex align-center">{icons.message}</span>
        <span className="fs-10 bold mb-0">{comments.length > 0 ? comments.length : '0'}</span>
    </button>
  );
};

BtnComment.propTypes = {
  comments: PropTypes.array.isRequired,
  showComments: PropTypes.func.isRequired,
  findComments: PropTypes.func.isRequired,
  discussion: PropTypes.bool,
};

export default BtnComment;
