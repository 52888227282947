import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { icons } from '../../img/elements/icons';
import { FormattedMessage } from 'react-intl';
import Select, { components } from 'react-select';
import chroma from 'chroma-js';
import DatePicker from 'react-date-picker'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
  }),
  input: () => ({
    backgroundColor: '#ffffff',
    fontSize: '12px',
    padding: '2px 0',
  }),
  control: styles => ({
    ...styles,
    backgroundColor: '#ffffff', 
    padding: '2px 0px',
    borderColor: 'transparent'
  }),
  placeholder: styles => ({
        ...styles,
        fontSize: '12px',
    }),
  menu: styles => ({
    ...styles,
    width: '150px', 
    textAlign: 'left',
  }),
  clearIndicator: styles => ({
    ...styles,
    padding: '0px', 
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: '0px', 
  }),
  singleValue: (provided, state) => {
    const fontSize = '12px';

    return { ...provided, fontSize };
  }
}

const customStylesPriority = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      fontSize: '12px',
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  input: () => ({
    fontSize: '12px',
  }),
  control: styles => ({
    ...styles,
    padding: '2px 0px',
    borderColor: 'transparent'
  }),
  placeholder: styles => ({ ...styles, fontSize: '12px' }),
  menu: styles => ({
    ...styles,
    width: '150px', 
    textAlign: 'left',
  }),
  clearIndicator: styles => ({
    ...styles,
    padding: '0px', 
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: '0px', 
  }),
  singleValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
      fontSize: '12px',
      padding: '5px',
      borderRadius: '5px',
      color: data.color,
    };
  },
}

const indicatorSeparatorStyle = {
  alignSelf: 'stretch',
  backgroundColor: 'transparent',
  marginBottom: 8,
  marginTop: 8,
  width: 0,
};

const IndicatorSeparator = ({ innerProps }) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};

const TaskItemCardForm = ({
  todo: {
    name,
    owner,
    dueIn,
    isCompleted,
    priority,
    OKR,
  },
  formData,
  usersOptions,
  okrOptions,
  priorityOptions,
  onSubmit,
  handleChangeForm,
  index,
  clearTodo,
  krId,
}) => {

   return (
    <Fragment>
       <form
         onSubmit={e => onSubmit(e, index, krId)}
         className="relative br-4 brd brd-grey__light bg__white mb-1 p-2 flex">
          <div className="flex-column w-90 mr-1">
            <div className="flex align-center w-100">
              <div className="ml-3 w-100px text-center">
                <FormattedMessage
                  id="select.priority"
                  defaultMessage="Priority..."
                >
                  {msg => (
                    <Select
                      className="w-100px small-input cursor"
                      defaultValue={formData.priority}
                      components={{ IndicatorSeparator }}
                      value={priority || ''}
                      onChange={selectedOption =>
                        handleChangeForm(selectedOption, index, 'priority')
                      }
                      options={priorityOptions}
                      isSearchable
                      isClearable
                      placeholder={msg}
                      name="priority"
                      styles={customStylesPriority}
                    />
                  )}
                  </FormattedMessage>
              </div>

              <FormattedMessage
                id="select.task"
                defaultMessage="Add new task"
              >
                {msg => (
                  <input
                    className={`w-80 ml-2 fs-14 fw-600 lh-25 card-subtitle cursor inline-block brd-transparent brd_bottom_grey outline`}
                    type="text"
                    required
                    autoFocus
                    value={name || ''}
                    onChange={e =>
                      handleChangeForm(e.target.value, index, 'name')
                    }
                    placeholder={msg}
                    name="name"
                  />
                )}
              </FormattedMessage>
            </div>

            <div className="flex align-center w-100">
              <div className="ml-4 mr-1 w-80px text-center flex-column mt-1">
                <span className="fw-600 fs-10 text-left">Due Date</span>
                <DatePicker
                  onChange={selectedOption => handleChangeForm(selectedOption, index, 'dueIn')}
                  value={dueIn === null || dueIn === undefined ? dueIn : new Date(dueIn)}
                />
              </div>

              <div className="mx-1 w-220px text-center">
                <FormattedMessage
                  id="select.assignee"
                  defaultMessage="Assignee..."
                >
                  {msg => (
                    <Select
                      className="small-input select__no-indicator"
                      components={{ IndicatorSeparator }}
                      defaultValue={formData.owner}
                      value={owner || ''}
                      onChange={selectedOption =>
                        handleChangeForm(selectedOption, index, 'owner')
                      }
                      options={usersOptions}
                      isSearchable
                      placeholder={msg}
                      name="owner"
                      styles={customStyles}
                      // isMulti
                    />
                  )}
                  </FormattedMessage>
              </div>

              <div className="mx-1 w-50 text-center">
                <FormattedMessage
                  id="select.priority"
                  defaultMessage="Select OKR..."
                >
                  {msg => (
                    <Select
                      className="small-input"
                      defaultValue={formData.OKR}
                      components={{ IndicatorSeparator }}
                      value={OKR || ''}
                      onChange={selectedOption =>
                        handleChangeForm(selectedOption, index, 'OKR')
                      }
                      options={okrOptions}
                      isSearchable
                      // isClearable
                      placeholder={msg}
                      name="OKR"
                      styles={customStyles}
                    />
                  )}
                  </FormattedMessage>
              </div>
            </div>
          </div>

          <div className="form-btn flex align-center w-100px mla">
              <button
                onClick={e => onSubmit(e, index, krId)}
                className="btn fs-14 mr-1 lh-34 px-1 py-0">
                <FormattedMessage
                  id="add"
                  defaultMessage="Add"
                />
              </button>
              <span 
                onClick={(e) => clearTodo(e, index)}
                className="no_btn small flex align-center"
              >
                  {icons.circle_close}
              </span>
            </div>
      </form>
    </Fragment>
  );
};

TaskItemCardForm.propTypes = {
  todo: PropTypes.object.isRequired,
  clearTodo: PropTypes.func,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(TaskItemCardForm);
