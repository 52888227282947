import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Logo from '../../../elems/Logo';
import PropTypes from 'prop-types';
import { login } from '../../../../actions/auth';
import { prepareCustomOnboarding, getCustomOnboarding } from '../../../../actions/custom';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../../layout/Spinner';
import Text from '../../elems/Text';
import Btn from '../../elems/WhiteBtn';
import Skip from '../../elems/Skip';
import SelectItem from "../../../elems/Select";
import DayItem from '../../../admin/elems/DayItem';
import QuestionItemNew from '../../../admin/elems/QuestionItemNew';
// import TimePicker from 'react-time-picker';
import BlueBtn from '../../elems/BlueBtn';
import Moment from 'moment';
import TimePicker from 'rc-time-picker';
const format = 'h:mm a';

const scheduleObj = {
  index: '1',
  day: 'Monday',
  weekDays: [
    {index: 1, day: "Mo", isActive: false},
    {index: 2, day: "Tu", isActive: false},
    {index: 3, day: "We", isActive: false},
    {index: 4, day: "Th", isActive: false},
    {index: 5, day: "Fr", isActive: false},
    {index: 6, day: "Sa", isActive: false},
    {index: 0, day: "Su", isActive: false},
  ]
}
let intervalOptions = [
  { label: `Daily`, value: 'Daily', name: 'interval' },
  { label: `Weekly`, value: 'Weekly', name: 'interval' },
  { label: `Bi-weekly`, value: 'Biweekly', name: 'interval' },
  { label: `Monthly`, value: 'Monthly', name: 'interval' },
];

let daysOptions = [
  { label: `Monday`, value: '1', name: 'weekday' },
  { label: `Tuesday`, value: '2', name: 'weekday' },
  { label: `Wednesday`, value: '3', name: 'weekday' },
  { label: `Thursday`, value: '4', name: 'weekday' },
  { label: `Friday`, value: '5', name: 'weekday' },
  { label: `Saturday`, value: '6', name: 'weekday' },
  { label: `Sunday`, value: '7', name: 'weekday' },
];

const wordingInterval = {
  placeholderId: "choose.team.interval",
  placeholderMsg: "Choose check-in interval",
}

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: state.isFocused ? '#404ff5' : '#e9eaf5',
    boxShadow: state.isFocused ? '0px 0px 20px #404FF54D' : 'none',
    border: state.isFocused ? '1px solid #404ff5' : styles.border,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    fontSize: '14px',
    height: '38px',
  }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize, };
  }
}

const Onboarding3 = ({ 
  user,
  prepareCustomOnboarding, 
  history,
  getCustomOnboarding,
  custom: { campaign }
}) => {
  const [formData, setFormData] = useState({
    interval: 'Weekly',
    scheduleDay: scheduleObj,
    scheduleTime: '09:00',
    slackIsActive: false,
    selectedSlackUsers: [],
    selectedOption: [],
  });

  const { interval, scheduleDay, scheduleTime } = formData;

  useEffect(() => {
    
    if (!campaign) getCustomOnboarding()
    // getCustomOnboarding()

    campaign = JSON.parse(campaign);

    setFormData({
      ...formData,
      slackIsActive: !campaign ? '' : campaign.slackIsActive,
      selectedSlackUsers: !campaign ? '' : campaign.selectedSlackUsers,
      selectedOption: !campaign ? '' : campaign.selectedOption,
    });
  }, [getCustomOnboarding, campaign && campaign.length]);

  const onSubmit = e => {
    e.preventDefault();

    prepareCustomOnboarding(formData, history, 3);
  };

  const handleIntervalSelect = (selectedOption, type) => {
    setFormData({ ...formData, [selectedOption.name]: selectedOption.value });
  };

  const onChangeDate = (e) => {
    scheduleDay.index = e.target.value;
    
    setFormData({ ...formData, scheduleDay: scheduleDay });
  };

  const onChangeDay = (selectedOption) => {
    scheduleDay.day = selectedOption.label;
    scheduleDay.index = selectedOption.value;

    setFormData({ ...formData, scheduleDay: scheduleDay });
  };

  
  // Add 12 hours if it's pm time
  const formatTime = (time) => {

    const timeArr = time.split(":");
    
    let hours = +timeArr[0] + 12;
    const result = `${hours}:${timeArr[1]}`

    return result;
  };

  // const handleTime = e => {
  //   setFormData({ ...formData, scheduleTime: e });
  // };

  const handleTime = value => {
    const format = 'h:mm a'; 
    // const format = 'h:mm'; 

    let timeArr = value.format(format).split(' ');
    let timeFormat = timeArr[1];

    let time = timeFormat === 'am' ? timeArr[0] : formatTime(timeArr[0]);

    setFormData({ ...formData, scheduleTime: time });
  };

  const handleDaysChange = (e, index) => {
    scheduleDay.weekDays[index].isActive = !scheduleDay.weekDays[index].isActive;

    setFormData({ ...formData, scheduleDay: scheduleDay });
  };

  let title = <FormattedMessage id="create.checkin" defaultMessage="Create a Check-in" />
  let message = <FormattedMessage id="onboarding.checkin.msg2" defaultMessage="Check-ins are best run at a regular interval. Choose a day of the week and frequency." />
  let next = <FormattedMessage id="next.step" defaultMessage="Next Step" />

  let defaultValue = {}
  let type = 1;

  if (formData && formData.interval && formData.interval.length > 0) {
    defaultValue = {
      interval: type === 2 ? formData.interval : intervalOptions[1],
      days: type === 2 && formData.scheduleDay ? formData.scheduleDay : daysOptions[0],
    }
  }

  let defaultInterval = type === 2 ? { label: defaultValue.interval, value: defaultValue.interval, name: "interval" } : defaultValue.interval
  let defaultDay = type === 2 ? daysOptions.filter(d => d.label === defaultValue.days.day)[0] : defaultValue.days

  let scheduleLength = 0;
  if (scheduleDay !== undefined && scheduleDay !== null) {
    scheduleLength = Object.keys(scheduleDay).length;
  }

  let scheduleTimeArr = scheduleTime.split(":")
  let formattedTime = Moment().hour(scheduleTimeArr[0]).minute(scheduleTimeArr[1]);
  // console.log('===== scheduleTime', scheduleTime)

  return (
    <Fragment>
      {!user ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="css-main onboarding bg__white">
            <div className="wrapper">
              <div className="flex">
                <div className="onboarding__content relative">
                  <div className="onboarding__content__button__480">
                    <div className="pb-8">
                      <Logo isNoLink={true} />
                    </div>
                    <Text title={title} text={message} page={4} />

                    <div className="pt-3 form-app form-app-okr">
                      <div className="w-100 flex flex-column time-picker">
                        <p className="fs-12 w-100 mb-xs bold-pure">
                          <FormattedMessage id="time" defaultMessage="Time" />
                        </p>
                        {/* <TimePicker
                          onChange={handleTime}
                          value={scheduleTime}
                          disableClock={true}
                          clearIcon={null}
                          locale="sv-sv"
                          className="bg__white"
                        /> */}
                         <TimePicker
                            showSecond={false}
                            defaultValue={formattedTime}
                            className="bg__white h-44 brd brd-grey__new pl-1"
                            onChange={handleTime}
                            format={format}
                            // use12Hours
                            inputReadOnly
                            clearIcon={null}
                          />
                      </div>
                      <div className="form-main form-main-inline standups-time flex-column-xs mb-0">
                        <div className="w-100 mb-3-xs labeled-wrapper pt-1">
                          <p className="fs-12 w-100 mb-xs bold-pure">
                            <FormattedMessage id="interval" defaultMessage="Interval" />
                          </p>
                          <SelectItem 
                            defaultValue={defaultInterval}
                            onChange={handleIntervalSelect}
                            options={intervalOptions}
                            customStyles={customStyles}
                            isClearable={false}
                            key={`interval-onboarding`}
                            wording={wordingInterval}
                            isMulti={false}
                          />
                        </div>
                      </div>

                      
                      <div className="w-100 flex flex-column pt-1">
                        {interval === 'Weekly' || interval === 'Biweekly' ? (
                            <Fragment>
                              <p className="fs-12 w-100 mb-xs bold-pure">
                                <FormattedMessage id="day" defaultMessage="Day" />
                              </p>
                              <SelectItem 
                                defaultValue={defaultDay}
                                onChange={onChangeDay}
                                options={daysOptions}
                                customStyles={customStyles}
                                isClearable={false}
                                key={`days-U`}
                                wording={wordingInterval}
                                isMulti={false}
                              />
                            </Fragment>
                        ) : (<span></span>)}
                      
                        {interval === 'Daily' && (
                          <Fragment>
                            <p className="fs-12 w-100 mb-xs bold-pure">
                              <FormattedMessage id="days.week" defaultMessage="Days of the Week" />
                            </p>
                            <div className="pb-2">
                            <DayItem
                              scheduleDays={scheduleLength > 0 && scheduleDay.weekDays}
                              onChange={handleDaysChange}
                              styles={'small-input ml-5'}
                              stylesText={'w-60px'}
                            />
                            </div>
                            
                          </Fragment>
                        )}

                        {interval === 'Monthly' && (
                          <Fragment>
                            <p className="fs-12 w-100 mb-xs bold-pure">
                              <FormattedMessage id="day.month" defaultMessage="Day of the Month" />
                            </p>
                              <QuestionItemNew
                                wording={{ id: "date.month", text: "Enter a day" }}
                                value={scheduleDay.index}
                                name={scheduleDay.index}
                                inputName={'name'}
                                onChange={onChangeDate}
                                stylesInput="w-100 h-44"
                              />
                          </Fragment>
                        )}
                      </div>

                      <div className="buttons pt-3 onboarding__content__button__260">
                        <BlueBtn onClick={onSubmit} text={next} isLoader={false} />
                      </div>
                    </div>
                  </div>
                  
                  <Skip/>
                </div>
                <div className="onboarding__image onboarding__image__checkin_interval"></div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

Onboarding3.propTypes = {
  login: PropTypes.func.isRequired,
  prepareCustomOnboarding: PropTypes.func.isRequired,
  getCustomOnboarding: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  custom: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.user,
  custom: state.custom,
});

export default connect(
  mapStateToProps,
  { login, prepareCustomOnboarding, getCustomOnboarding }
)(Onboarding3);
