import {
  GET_ICEBREAKERS,
  ICEBREAKERS_ERROR,
  CLEAR_ICEBREAKERS,
  GET_ICEBREAKERS_SETTINGS,
  ICEBREAKERS_SETTINGS_ERROR,
  ADD_ICEBREAKER_ANSWER,
  EDIT_ICEBREAKER_ANSWER,
  DELETE_ICEBREAKER_ANSWER,
  DELETE_ICEBREAKER,
  SWITCH_ICEBREAKERS_STATUS,
  ICEBREAKER_ERROR,
  GET_DEFAULT_ICEBREAKERS,
  CHANGE_ICEBREAKERS_LANGUAGE,
} from '../actions/types';

const initialState = {
  icebreakers: [],
  settings: null,
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ICEBREAKERS:
      return {
        ...state,
        icebreakers: payload,
        loading: false
      };
    case GET_ICEBREAKERS_SETTINGS:
    case SWITCH_ICEBREAKERS_STATUS:
    case GET_DEFAULT_ICEBREAKERS:
    case CHANGE_ICEBREAKERS_LANGUAGE:
      return {
        ...state,
        settings: payload,
        loading: false
      };
    case ADD_ICEBREAKER_ANSWER:
      return {
        ...state,
        icebreakers: state.icebreakers.map(icebreaker =>
          icebreaker._id === payload.icebreakerID
            ? {
                ...icebreaker,
                answers: [...icebreaker.answers, payload.answer]
              }
            : icebreaker
        ),
        loading: false
      };
    case EDIT_ICEBREAKER_ANSWER:
      return {
        ...state,
        icebreakers: state.icebreakers.map(icebreaker =>
          icebreaker._id === payload.icebreakerID
            ? {
                ...icebreaker,
                answers: icebreaker.answers.map(
                  answer => answer._id === payload.answerID
                    ? {
                    answer: payload.answer
                  } : answer
                )
              }
            : icebreaker
        ),
        loading: false
      };
    case ICEBREAKERS_SETTINGS_ERROR:
    case ICEBREAKERS_ERROR:
    case ICEBREAKER_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case DELETE_ICEBREAKER:
      return {
        ...state,
        icebreakers: state.icebreakers.filter(icebreaker => icebreaker._id !== payload),
        loading: false
      };
    case DELETE_ICEBREAKER_ANSWER:
      return {
        ...state,
        icebreakers: state.icebreakers.map(icebreaker =>
          icebreaker._id === payload.icebreakerID
            ? {
                ...icebreaker,
                answers: icebreaker.answers.filter(
                  answer => answer._id !== payload.answerID
                )
              }
            : icebreaker
        ),
        loading: false
      };
    case CLEAR_ICEBREAKERS:
      return {
        ...state,
        icebreakers: [],
        settings: null,
        loading: true
      };
    default:
      return state;
  }
}
