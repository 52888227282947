import {
  GET_USER_PLAN_ANSWERS,
  GET_PROFILE_PLAN_ANSWERS,
  GET_TEAM_PLAN_ANSWERS,
  GET_PLAN_ANSWERS,
  PLAN_ANSWER_ERROR,
  PLAN_ANSWERS_ERROR,
  ADD_PLAN_ANSWER,
  EDIT_PLAN_ANSWER,
  GET_PLAN_ANSWER,
  PLAN_LIKES,
  ADD_COMMENT,
  UPDATE_COMMENT,
  CLEAR_PLAN_ANSWERS,
  GET_COMPANY_PLAN_ANSWERS,
  COMPANY_PLAN_ANSWER_ERROR,
  REMOVE_COMMENT,
  LOCATION_CHANGE_ERROR_PLAN,
  LOADING_PLANS,
} from '../actions/types';

const initialState = {
  planAnswers: [],
  planAnswer: null,
  loading: true,
  isHomeLoadingPlan: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_PLAN_ANSWERS:
    case LOCATION_CHANGE_ERROR_PLAN: 
     return {
       ...state,
       planAnswers: [],
       planAnswer: null,
       loading: true
     };
    case GET_PLAN_ANSWERS:
    case GET_PROFILE_PLAN_ANSWERS:
    case GET_TEAM_PLAN_ANSWERS:
      return {
        ...state,
        planAnswers: payload,
        loading: false,
        isHomeLoadingPlan: false
      };
    case GET_COMPANY_PLAN_ANSWERS:
      return {
        ...state,
        planAnswers: payload.company,
        planAnswer: payload.user,
        loading: false
      };
    case GET_USER_PLAN_ANSWERS:
      return {
        ...state,
        planAnswers: payload.answers,
        planAnswer: payload.last,
        loading: false,
        isHomeLoadingPlan: false
       };
    case GET_PLAN_ANSWER:
      return {
        ...state,
        planAnswer: payload,
        loading: false
      };
    case ADD_PLAN_ANSWER:
      return {
        ...state,
        planAnswers: [payload, ...state.planAnswers],
        loading: false
      };
    case EDIT_PLAN_ANSWER:
      return {
        ...state,
        planAnswers: state.planAnswers.map(planAnswer =>
          planAnswer._id === payload.planID
            ? {
                ...planAnswer,
                answers: payload.answers,
                updatedAt: payload.updatedAt
              }
            : planAnswer
        ),
        loading: false
      };
    case PLAN_LIKES:
      return {
        ...state,
        planAnswers: state.planAnswers.map(planAnswer =>
          planAnswer._id === payload.planID
            ? {
                ...planAnswer,
                likes: payload.likes,
                answers: payload.answers
              }
            : planAnswer
        ),
        loading: false
      };
    case ADD_COMMENT:
      return {
        ...state,
        planAnswers: state.planAnswers.map(planAnswer =>
          planAnswer._id === payload.planID
            ? {
                ...planAnswer,
                comments: payload.comments,
                answers: payload.answers
              }
            : planAnswer
        ),
        loading: false
      };
    case UPDATE_COMMENT:
      return {
        ...state,
        planAnswers: state.planAnswers.map(planAnswer =>
          planAnswer._id === payload.planID
            ? {
                ...planAnswer,
                comments: payload
              }
            : planAnswer
        ),
        loading: false
      };
    case REMOVE_COMMENT:
      return {
        ...state,
        planAnswers: state.planAnswers.map(planAnswer =>
          planAnswer._id === payload.planID
            ? {
                ...planAnswer,
                answers: payload.answers,
                comments: planAnswer.comments.filter(
                  comment => comment._id !== payload.commentID
                )
              }
            : planAnswer
        ),
        loading: false
      };
    case PLAN_ANSWER_ERROR:
    case COMPANY_PLAN_ANSWER_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case PLAN_ANSWERS_ERROR:
      return {
        ...state,
        error: payload,
        planAnswers: [],
        loading: false
      };
    case LOADING_PLANS:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
