import React from "react";
import PropTypes from 'prop-types';
import Moment from 'react-moment';

const WeekDateTitle = ({
  date,
  index,
  action,
  days
}) => {
  
  let rawDate = new Date(date);
  let firstDate;
  let secondDate;

  if (action === '+') {
    rawDate.setDate(rawDate.getDate() + days);
    firstDate = date;
    secondDate = rawDate;
  } else if (action === '-') {
    rawDate.setDate(rawDate.getDate() - days);
    firstDate = rawDate;
    secondDate = date;
  }
  
  return (
    <h3 className="fs-2 pt-2 pl-2 pb-1">
      <Moment format="D MMM" key={`date-moment-${index}`}>{firstDate}</Moment>{' '}-{' '}
      <Moment format="D MMM" key={`date-end_of_the_week-${index}`}>{secondDate}</Moment>
    </h3>
  );
};

export default WeekDateTitle;
