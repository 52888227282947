import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { icons } from '../../img/elements/icons';
import { FormattedMessage } from 'react-intl';
import CheckinItemCardQuestion from './CheckinItemCardQuestion';
import Focus from '../elems/Focus';

const CheckinItemCard = ({
  standupAnswer: {
    user,
    firstName,
    lastName,
    avatar,
    questions,
    answers,
    comments,
    likes,
    _id,
    standupDate,
    notes,
    focus,
    date,
    standup
  },
  formData,
  setFormData,
  searchKeyword,
  mentions,
  standupTypeId
}) => {
  const [displayUserAnswer, toggleAnswerButton] = useState(true);

  // Check the object length is not 0
  let objectLength = 0;
  if (focus !== undefined && focus !== null) {
    objectLength = Object.keys(focus).length;
  }

  return (
    <Fragment>
      <div className="card-team">
        <div className="flex space-between align-center">
          <div
            className="user flex align-center cursor"
            onClick={() => toggleAnswerButton(!displayUserAnswer)}
          >
            <Link to={user ? `/user/${user}` : '#!'} className="flex align-center">
              <img src={avatar} alt="" className="round-img avatar mr-24" width="40px" height="40px" />
            </Link>
            <p className="large bold">
              {firstName} {lastName}
            </p>
          </div>

          <p className="fs-14 grey_new mla">
            <Moment format="D MMM">{date}</Moment>
          </p>

          <Link to={`/checkins/edit/${_id}`} className="fs-14 icon__grey fw-600 flex align-center ml-1">
            {icons.edit_small} <FormattedMessage id="edit" defaultMessage="Edit" />
          </Link>

          <div className="card-icons">
            <button
              onClick={() => toggleAnswerButton(!displayUserAnswer)}
              className="btn-action no_btn"
              type="button"
            >
              {icons.dropdown_icon}
            </button>
          </div>
        </div>

        {displayUserAnswer && (
          <div className="card-standups-team card-body-hidden pb-0 mt-24 active">
            {questions.map((question, index) => (
              <CheckinItemCardQuestion
                key={`teamQuestion-${_id}-${index}`}
                standupID={_id}
                question={question}
                answer={answers[index] !== undefined && answers[index]}
                userID={user}
                avatar={avatar}
                comments={comments.filter((comment) => comment.answerID === answers[index]._id.toString())}
                likes={likes.filter((like) => like.answerID === answers[index]._id.toString())}
                index={index}
                formData={formData}
                setFormData={setFormData}
                searchKeyword={searchKeyword}
                notes={notes}
                mentions={mentions}
              />
            ))}

            {notes && (
              <div className="notes">
                <div className="line__grey my-2"></div>
                <div className="flex align-center">
                  <span className="mr-14 flex align-center">{icons.arrow_right}</span>{' '}
                  <p className={`fs-12 uppercase bold mb-0`}>
                    <FormattedMessage id="notes" defaultMessage="Notes" />
                  </p>
                </div>
                <div className="fs-16 ml-38 mt-1">{notes}</div>
              </div>
            )}

            {objectLength > 0 && focus.isFocus && (
              <Focus focus={focus} type={2} />
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

CheckinItemCard.propTypes = {
  standupAnswer: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(CheckinItemCard);
