import React, { Fragment, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import MainMenu from '../layout/Sidebar';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import { getCompanyProfiles } from '../../actions/profile';
import { getUserCompany } from '../../actions/company';
import MainWidget from '../utils/MainWidget';
import OkrCardItem from '../okrs/noState/OkrCardItem';
import CompanyMenu from './CompanyMenu';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/1.jpg';
import Notification from '../utils/Notification';
import { onboardingOkrs } from '../../utils/onboarding/OKRs';
import { getCompanySortedOkrs } from '../../actions/okr';
import { okrsCopy } from '../../utils/wording/okrs';
import { FormattedMessage } from 'react-intl';

const CompanyOkrs = ({
  getCompanySortedOkrs,
  getUserCompany,
  getCompanyProfiles,
  okr: { okrs, okr, loading },
  auth: { user, sidebar },
  company: { company },
  profile: { profiles },
}) => {
  useEffect(() => {
    getUserCompany();
    getCompanyProfiles();
    getCompanySortedOkrs();

  }, [getUserCompany, getCompanyProfiles, loading, getCompanySortedOkrs]);


  const updatePlanMsg = {
    title: (
      <FormattedMessage
        id="company.updateplan.title"
        defaultMessage="Update Plan"
      />
    ),
    msg: (
      <FormattedMessage
        id="company.okrs.updateplan.msg"
        defaultMessage={`The Free plan is limited by 4 users in the company. You have ${profiles && profiles.length} active users today. Please upgrade the plan to use Focus for the team with 5 and more users.`}
      />
    )
  };

  const noOkrMessageTeam = {
    title: <FormattedMessage id="nookrs.title" defaultMessage="No Objectives" />,
    msg: 
    <FormattedMessage id="nookrs.msg" defaultMessage={`${company && company.name} has not created any objective yet.`} />
  };

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {user && !loading && (
          <MainMenu activeIndex={6} />
        )}

        <UserNav />
        
          <Fragment>
            {loading || !okrs || !user || !company ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className={`${sidebar === 1 ? 'main main-dashboard ' : 'short_menu__ml__okrs ma-w90 pt-2'} mt-3`}>
                     <h5 className="pl-0-xs">
                      <FormattedMessage id="company.header.okrs" defaultMessage="Company Objectives" />
                    </h5>
                  <CompanyMenu companyIndex={3} />
                  <div className="okrs">
                    <div className="mb-4">
                        {okrs.length > 0 && (
                          okrs.map(okr => (
                            okr.isMain && okr.type === 1 && <MainWidget key={`main-${okr._id}`} okr={okr} />
                            )
                          ))} 
                      </div>
                      <div className="text-right pb-1">
                        <Link to="/objective/new" className="btn btn-light">
                          <FormattedMessage
                            id="user.okr.sub_btn"
                            defaultMessage="Create Objective"
                          />
                        </Link>
                      </div>
                      {company.plan === 1 && profiles.length > 4 ? (
                        <NoContent 
                          message={updatePlanMsg}
                          picture={updateImg}
                          alt={'Update plan'}
                          admin={user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0}
                        />
                      ) : (
                        <Fragment>
                          {okrs.length === 0 ? (
                            <Fragment>
                              <div className="w-90 mxa">
                              <Notification
                                msg={okrsCopy.msg}
                                cta={okrsCopy.cta}
                                btnColor={'orange'}
                                color={`orange`}
                                url={`/okr/new`}
                              />
                              
                              <Fragment> 
                              <div className="component my-2 py-3 px-1 light-shadow">

                                <div className="cards py-2">
                                  {onboardingOkrs.map(okr => (
                                    <OkrCardItem
                                      key={okr._id}
                                      okr={okr}
                                      okrBoard={'1'}
                                      formType={2}
                                    />
                                  ))}
                                </div>
                              </div>
                            </Fragment>
                              </div>
                            </Fragment>
                          ) : (
                            <Fragment> 

                                  {okrs.length > 0 ? (
                                    okrs.map(okr => (
                                    <div className="component my-2 py-3 px-1 light-shadow" key={`okr-item-${okr._id}`}>
                                      <div className="cards py-2">
                                        <OkrCardItem
                                        key={okr._id}
                                        okr={okr}
                                        okrBoard={'1'}
                                      />
                                    </div>
                                  </div>
                                  ))) : (
                                    <div className="mt-5">
                                      <NoContent 
                                        message={noOkrMessageTeam}
                                        picture={updateImg}
                                        alt={'No OKRs'}
                                      />
                                    </div>
                                  )}
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>

        </div>
      </div>
    </Fragment>
  );
};

CompanyOkrs.propTypes = {
  getCompanyProfiles: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getCompanySortedOkrs: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  okr: state.okr
});

export default connect(
  mapStateToProps,
  { getUserCompany, getCompanyProfiles, getCompanySortedOkrs }
)(CompanyOkrs);
