import React, { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { getUserCompany } from '../../actions/company';
import { getSlackTeam, getSlackChannels, editSlackSync } from '../../actions/slack';
import { setAlert } from '../../actions/alert';
import Logo from '../elems/Logo';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';

const EditSyncSettings = ({
  setAlert,
  editSlackSync,
  getSlackTeam,
  auth: { user },
  history,
  getUserCompany,
  getSlackChannels,
  company: { company },
  slack: { loading, channels, team },
}) => {
  const [formData, setFormData] = useState({
    syncIsActive: team && team.syncIsActive,
    syncChannels: team && team.syncChannels,
    selectedChannelOption: [],
  });

  const {
    syncIsActive, syncChannels, selectedChannelOption
  } = formData;

  let channelOptions = [];
  let choosedChannels = [];

  // Channels Options for Select format
  channels &&
    channels.forEach(channel => {
      let u = {
        value: channel.channel_id,
        label: channel.name
      };

      channelOptions.push(u);
    });

  // Users & Channels Default values for Select format
  team && team.syncChannels &&
    team.syncChannels.forEach(item => {

      // For channels
      if (item.channel_id) {
        let с = {
          value: item.channel_id,
          label: item.name
        };

        choosedChannels.push(с);
      }
    });

  useEffect(() => {
    getUserCompany();
    user && getSlackTeam();
    company && company.name && getSlackChannels(company._id, company.slackTeamId);

    setFormData({
      ...formData,
      syncIsActive: !team ? false : team.syncIsActive,
      syncChannels: !team ? false : team.syncChannels,
      selectedChannelOption: !team ? '' : choosedChannels,
    });
  }, [loading, getSlackTeam, getUserCompany, getSlackChannels,
    team && team.scope && team.scope.length,
    company && company.departments && company.departments.length,
  ]);

  const handleChangeSyncChannel = selectedChannelOption => {
    setFormData({ ...formData, selectedChannelOption: selectedChannelOption });
  };

  const onSubmit = e => {
    e.preventDefault();

    if (syncIsActive && selectedChannelOption.length === 0) {
        setAlert(
          <FormattedMessage
            id="channel.updates.sync"
            defaultMessage="Please specify channel for synchronization"
          />,
          'danger'
        );
    } else if (syncIsActive && team && team.scope && !team.scope.includes('channels:join')) {
      window.location = 'https://app.usefocus.co/api/slackapp/install'
    } else {
      editSlackSync(formData, history);
    }
  };

  const title = <FormattedMessage id="edit.slack.sync" defaultMessage="Slack: Synchronization settings" />;
  const automateMembers =<FormattedMessage id="slack.sync.updates" defaultMessage="Automate Members" />;
  const automateMembersTxt = <FormattedMessage id="slack.sync.updates.txt" defaultMessage="Automate members allows you to sync your organization to a Slack channel, such as a <b>headquarters</b>. As members are added or removed from that channel, they will be automatically updated in Focus." values={{ b: (...chunks) => <b>{chunks}</b> }} />;
  const automateMembersInput = <FormattedMessage id="channel" defaultMessage="Public Channel" />;
  const statusField = <FormattedMessage id="status" defaultMessage="Status" />;

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">
          <div className="main_menu absolute"><Logo /></div>
          <UserNav />

          <Fragment>
            {!team || loading ? (
              <Spinner />
            ) : (
              <div className="main mxa pl-0 main-profile-admin">
                <div className="standups-admin pt-3 mt-0-xs pt-1-xs">
                  <div className="section-title">
                    <h5>{title}</h5>
                    <Link
                      to="/settings/integrations"
                      className="btn-secondary btn-secondary-back"
                    >
                      <FormattedMessage id="go.back" defaultMessage="Go Back" />
                    </Link>
                  </div>

                  <div className="component edit-updates-weekly my-2 p-3 light-shadow">
                    <div className="form-full">
                      <form
                        className="form-app form-app-update"
                        onSubmit={e => onSubmit(e)}
                      >
                        <p className="sub-title focus-yellow focus my-2">{automateMembers}</p>
                        <p className="meduim">{automateMembersTxt}</p>

                        <Fragment>
                          <div className="form-main form-main-inline mt-2">
                            <p className="bold medium block question">{statusField}</p>

                            <div className="checkbox status pl-3 w-30">
                              <div className="checkbox-item active-status">
                                <input
                                  type="checkbox"
                                  id="syncIsActive"
                                  name="syncIsActive"
                                  checked={syncIsActive}
                                  value={syncIsActive}
                                  onChange={e => {
                                    setFormData({
                                      ...formData,
                                      syncIsActive: !syncIsActive
                                    });
                                  }}
                                />
                                <label htmlFor="syncIsActive">
                                  {syncIsActive ? (<FormattedMessage id="admin.dashboard.edit.slack.active" defaultMessage="Active" />) : (<FormattedMessage id="admin.dashboard.edit.slack.disable" defaultMessage="Disabled" />)}
                                </label>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                          
                        <Fragment>
                          <div className="form-main form-main-inline mt-2">
                            <p className="bold medium block question">{automateMembersInput}</p>
                            <div className='w-40 fs-15'>
                              <FormattedMessage
                                id="admin.dashboard.edit.sync.channelPlaceholder"
                                defaultMessage="Select channel"
                              >
                                {msg => (
                                  <Select
                                    key={channels && `${channels._id}_sl_ch`}
                                    defaultValue={choosedChannels}
                                    onChange={selectedChannelOption =>
                                      handleChangeSyncChannel(selectedChannelOption)
                                    }
                                    options={channelOptions}
                                    isSearchable
                                    placeholder={msg}
                                  />
                                )}
                              </FormattedMessage>
                            </div>
                          </div>
                        </Fragment>

                        <button type="submit" className="btn btn-form">
                          <FormattedMessage id="admin.dashboard.edit.submit" defaultMessage="Submit" />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

EditSyncSettings.propTypes = {
  editSlackSync: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getSlackUsers: PropTypes.func,
  getSlackChannels: PropTypes.func,
  setAlert: PropTypes.func.isRequired,
  getSlackTeam: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  slack: PropTypes.object,
};

const mapStateToProps = state => ({
  company: state.company,
  slack: state.slack,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { 
    getUserCompany,
    getSlackChannels,
    getSlackTeam,
    editSlackSync,
    setAlert,
  }
)(withRouter(EditSyncSettings));