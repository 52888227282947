import React from "react";
import PropTypes from "prop-types";

// Types: 1 or no type - objective, 2 - kr

const OkrProgressPin = ({ btnClass, progress, current, goal }) => {
  return (
    <div className={`okr__progress text-center flex align-center`}>
      <div className="relative">
        <p className="fs-14 fw-600 absolute top-30 left">{current}{" "}/{" "}{goal}</p>
        <div className={`progress__main progress__grey mr-1`}>
          <span 
            className={`progress-bar progress-bar__main progress-bar__${btnClass}`}
            style={{ width: `${progress <= 100 ? progress : 100}%`}}
            ></span>
        </div>
      </div>
      <div className={`okr__progress-number fs-22 lh-30 dark-gray fw-600`}>{Math.round(progress)}%</div>
    </div>
  );
};

OkrProgressPin.propTypes = {
  btnClass: PropTypes.string.isRequired
};

export default OkrProgressPin;
