import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { setAlert } from '../../../actions/alert';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { editComment, deleteComment } from '../../../actions/okr';
import { icons } from "../../../img/elements/icons";

const CommentItem = ({
  comment: { firstName, lastName, text, user, date, avatar, _id, isEdit },
  auth,
  deleteComment,
  editComment,
  okrID,
  formDataParent,
  setFormDataParent
}) => {
  const [displayEdit, toggleEditForm] = useState(false);

  // ЗДЕСЬ получаем коллбэк и перебираем formData.objectives и обновляем комменты
  const handleDelete = async (okrID, _id) => {
    try {
      const res = await deleteComment(okrID, _id);

      setFormDataParent({ 
        ...formDataParent, 
        objectives: formDataParent.objectives.map(answer => {
          if (answer._id === res.okrID) {
            // Delete comment 
            answer.comments = answer.comments.filter(comment => comment._id !== res.commentID);
            return answer;

          } else {
            return answer;
          }
        })
      });
      
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in comments', err);
    }
  };

  return (
    <div className="comment-body">
      <div className="comment-item">
      <Link to={user ? `/user/${user}` : '#!'} className="user">
        <img
          src={avatar}
          alt="Avatar"
          className="round-img d-block avatar mr-12"
          width="26px"
          height="26px"
        />
      </Link>

        <div className="comment-head">
          <div className="comment-info align-center">
            <p className="comment-user fs-12 bold mb-0">
              <strong>
                {firstName} {lastName}
              </strong>
            </p>

            <div className="icons btn-icons-edit flex align-center">
              <time className="fs-10 grey_new mr-12">
                <Moment format="ddd LLL">{date}</Moment>
              </time>

              {user === auth.user._id && (
                <Fragment>
                  {isEdit && (
                    <button
                      type="button"
                      className="btn-icons btn-edit"
                      onClick={() => toggleEditForm(!displayEdit)}
                    >
                      <i className="fas fa-pencil-alt" />
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn-icons btn-delete flex align-center"
                    onClick={e => handleDelete(okrID, _id)}
                  >
                      {icons.circle_close}
                  </button>
                </Fragment>
              )}
            </div>
          </div>

          <p className="comment-text">{text}</p>
        </div>
      </div>
    </div>
  );
};

CommentItem.propTypes = {
  auth: PropTypes.object.isRequired,
  okrID: PropTypes.string.isRequired,
  comment: PropTypes.object.isRequired,
  editComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { editComment, deleteComment }
)(CommentItem);
