import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const TeamsCardBody = ({ department, employees }) => {
    return (
        <tr className=''>
            <td colspan={6} className="pt-2 border-top-gray border-bottom-gray">

                <div className='mb-2'>
                    <p className="fs-12 bold pb-2 inline opacity-04">
                        <FormattedMessage id="team.people" defaultMessage="Team Members" />
                    </p>
                    <p className="fs-12 bold pb-2 inline opacity-04">
                        &nbsp;-&nbsp;{department.name}
                    </p>
                </div>

                {employees.map((empl) => (
                    <div className='flex mb-'>
                        <img
                            src={empl.avatar}
                            alt="User's avatar"
                            className="round-img d-block avatar mr-1"
                            width="26px"
                            height="26px"
                        />
                        <div className="flex column space-around">
                            <Link to={`/user/${empl._id}`} className="user">
                                <p className="medium block hover-underline">
                                    {`${empl.firstName} ${empl.lastName}`}
                                </p>
                            </Link>
                        </div>
                    </div>
                ))}
            </td>
        </tr>
    );
};

TeamsCardBody.propTypes = {
    employees: PropTypes.array.isRequired,
    department: PropTypes.object.isRequired
};

export default TeamsCardBody;
