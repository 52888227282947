import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { icons } from "../../../img/elements/icons";
import Highlighter from "react-highlight-words";

const getIcon = status => {
  let icon = '';
  if (status === 1 || status === '1') {
    icon =  icons.smiley_sad;
  } else if (status === 2 || status === '2') {
    icon =  icons.smiley_notgood;
  } else if (status === 3 || status === '3') {
    icon =  icons.smiley_neutral;
  } else if (status === 4 || status === '4') {
    icon =  icons.smiley_good;
  } else if (status === 5 || status === '5') {
    icon =  icons.smiley_happy;
  }

  return icon;
};

const QuestionItemAnswer = ({ index, text, searchKeyword, isNumber }) => {

  if (text.props && text.props.defaultMessage) {
    text = text.props.defaultMessage;
  }
  
  return (
    <Fragment>
      {index === 0 ? (
        <li className={`list-item medium my-1 flex align-center ${isNumber ? 'bold mb-0' : null}`}>
        {!isNumber ? (
            icons.todo_done
            ) : (
              <div className="mr-1 flex align-center">{getIcon(text)}</div>
            )}
        <Highlighter
          highlightClassName="bg-yellow_pure"
          searchWords={[searchKeyword]}
          autoEscape={true}
          textToHighlight={text}
        />
        </li>
      ) : (
        <li className={`list-item medium my-1 flex align-center ${isNumber ? 'bold mb-0' : null}`}>
          {isNumber ? (
            <div className="mr-1 flex align-center">{getIcon(text)}</div>
            ) : (
              <span className="mr-14 flex align-center">
                {icons.circle_small}
              </span>
            )}
          <Highlighter
            highlightClassName="bg-yellow_pure"
            searchWords={[searchKeyword]}
            autoEscape={true}
            textToHighlight={text}
          />
        </li>
      )}
    </Fragment>
  );
};

QuestionItemAnswer.propTypes = {
  index: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired
};

export default QuestionItemAnswer;
