import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import MainMenu from '../layout/Sidebar';
import Menu from '../layout/Menu';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import Select from 'react-select';
import { getUserCompany } from '../../actions/company';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/no_content.svg';
import Notification from '../utils/Notification';
import { onboardingMood, onboardingMoodTitle } from '../../utils/onboarding/mood';  
import { planCopy } from '../../utils/wording/plans';
import { moodCopy } from '../../utils/wording/mood';
import { menuTitle, menuItems } from '../../utils/menu/culture';
import { getMoodSettingsAndUserAnswer, getCompanyMoodAnswers, } from '../../actions/moodAnswer';
import MoodItem from './MoodItem';
import MoodMain from './MoodMain';
import SubMenu from '../elems/SubMenu';
import { FormattedMessage } from 'react-intl';
import WidgetsDashboard from '../widgets/WidgetsDashboard';
import { setAlert } from "../../actions/alert";


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    backgroundColor: '#fcfcfd',
    fontSize: '14px',
  }),
  control: styles => ({ ...styles, backgroundColor: '#fcfcfd', padding: '4px 8px' }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
}

const Mood = ({
  getUserCompany,
  auth: { user, sidebar },
  company: { company },
  mood: { answers, answer, settings, loading },
  getMoodSettingsAndUserAnswer, 
  getCompanyMoodAnswers,
  locale,
}) => {
  const [state, setState] = useState({
    moodAnswers: [],
    uniqueAnswers: [],
    time: '',
  });

  const [allComments, showAllComments] = useState(false);
  let { moodAnswers, uniqueAnswers, time } = state;
  let timeOptions = [];

  // Time Options
  let hours = new Date().getUTCHours();
  let minutes = new Date().getMinutes();
  let today = new Date().getDay();
  let midnight = new Date().setHours(0,0,0,0);
  if (today === 0) { today = 8 }; // For Sundays

  let all = Date.parse(new Date(new Date(2019, 1, 1, 1, 1)));
  let quarter = Date.parse(new Date(new Date() - (90 * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60) * 1000));
  let month = Date.parse(new Date(new Date() - (30 * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60) * 1000));
  let weekAgo = Date.parse(new Date(new Date() - (7 * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60) * 1000));
  let thisWeek = Date.parse(new Date(new Date() - ((today - 1) * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60) * 1000));
 
  let allTime = locale && locale.lang !== 'ru' ? `All Time` : `Все время`; 
  let quarterTime = locale && locale.lang !== 'ru' ? `Quarter` : `Квартал`; 
  let monthTime = locale && locale.lang !== 'ru' ? `Last month` : `Месяц`; 
  let weekTime = locale && locale.lang !== 'ru' ? `Last 7 days` : `7 дней`; 
  let thisWeekTime = locale && locale.lang !== 'ru' ? `This week` : `Эта неделя`; 
  let todayTime = locale && locale.lang !== 'ru' ? `Today` : `Сегодня`; 
  
  timeOptions = [
      { value: 5, label: allTime, period: all },
      { value: 4, label: quarterTime, period: quarter },
      { value: 3, label: monthTime, period: month },
      { value: 2, label: weekTime, period: weekAgo },
      { value: 1, label: thisWeekTime, period: thisWeek },
      { value: 7, label: todayTime, period: midnight },
    ];

  let choosedTime = { value: 1, label: thisWeekTime, period: thisWeek };

  const getUnique = data => {
    let array = data.reduce((acc, current) => {
      const x = acc.find(item => item.day === current.day);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, [])

    return array;
  };

  useEffect(() => {
    getUserCompany();

    getMoodSettingsAndUserAnswer();
    getCompanyMoodAnswers();

    setState({
      ...state,
      time: !user ? '' : choosedTime,
      moodAnswers: !answers ? [] : answers.filter(item => Date.parse(item.date) > choosedTime.period),
      uniqueAnswers: !answers ? [] : getUnique(answers.filter(item => Date.parse(item.date) > choosedTime.period)),
    });

    
  }, [
    getUserCompany, 
    JSON.stringify(answers),
  ]);

  const handleTime = async selectedOption => {
    try {
      let newItems = [];
      let newUniqueItems= [];

      newItems = answers.filter(item => Date.parse(item.date) > selectedOption.period);
      newUniqueItems = getUnique(newItems)

      setState({ 
        ...state, 
        time: selectedOption, 
        moodAnswers: newItems,
        uniqueAnswers: newUniqueItems
      });
    } catch (err) {

      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in handleTime', err);
    }
  };

  let uniqueSurvey = moodAnswers && moodAnswers.length > 0 ? (answers.reduce((acc, current) => {
    const x = acc.find(item => item.day === current.day);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, [])) : [];

  let lastDay = moodAnswers && uniqueSurvey && uniqueSurvey.length > 0 && moodAnswers.length > 0 ? moodAnswers.filter(i => i.day === uniqueSurvey[0].day) : []
  let commentsArr = moodAnswers && moodAnswers.length > 0 ? moodAnswers.filter(i => i.text && i.text.length > 0) : []

  const getSum = arr =>
  arr.reduce((sum, { grade }) => sum + grade, 0)

  const getAverage = (sum, length) => {
    let number = (sum / length).toFixed(2)
    if (isNaN(number)) { number = 0 }

    return number
  }

  let sumToday = getSum(lastDay);
  let averageToday = getAverage(sumToday, lastDay.length);
  let sumTotal = getSum(answers);
  let averageTotal = getAverage(sumTotal, answers.length);
  let averageTodayTitle = <FormattedMessage id="average.today" defaultMessage="Average mood today"/>;
  let averageTotalTitle = <FormattedMessage id="average.total" defaultMessage="Average mood all time"/>;
  let allAnswersTitle = <FormattedMessage id="all.answers" defaultMessage="All answers"/>;
  let allCommentsTitle = <FormattedMessage id="all.comments" defaultMessage="All comments"/>;

  // Create array with data for widgets
  const firstWidget = {
    data: moodAnswers.length,
    title: allAnswersTitle
  };
  const secondWidget = {
    data: commentsArr.length,
    title: allCommentsTitle
  };
  const thirdWidget = {
    data: averageToday,
    title: averageTodayTitle,
  };
  const forthWidget = {
    data: averageTotal,
    title: averageTotalTitle,
  };

  const widgetData = [firstWidget, secondWidget, thirdWidget, forthWidget];

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {/* {user && user.department ? (
          <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user && user.isAdmin && true} focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={5} />
        ) : (
          <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={5} />
        )} */}
        {user && !loading && (
            <MainMenu activeIndex={5} />
          )}

        <UserNav />

          <Fragment>
            {loading  || !user || !company ? (
              <Spinner />
            ) : (
              <Fragment>
                 <div className={`${sidebar === 1 ? 'ml-280 main main-dashboard' : 'short_menu__ml__okrs ma-w90'} mt-3`}>

                  <div className="plans">

                    <h5 className="pl-0-xs">{menuTitle}</h5>
                    <SubMenu
                      items={menuItems}
                      activeIndex={1}
                      mb={'1'}
                    />

                      <FormattedMessage
                        id="focus.sub_menu.timeSelect"
                        defaultMessage="Choose the time interval"
                      >
                        {msg => (
                          <Select
                            className="w-30-l-50-xs mt-3 mla small-input"
                            defaultValue={choosedTime}
                            value={time}
                            onChange={selectedOption =>
                              handleTime(selectedOption)
                            }
                            options={timeOptions}
                            // isSearchable
                            placeholder={msg}
                            required
                            name="time"
                            styles={customStyles}
                          />
                        )}
                      </FormattedMessage>  

                    {company.plan > 3 || company.name === 'Sesterce' ? (
                      <Fragment>

                        <div className="m2-4">
                          <WidgetsDashboard 
                            bgColor={'light'}
                            widgetsAmount={4}
                            width={24}
                            widgetData={widgetData}
                            board={5}
                          />
                        </div> 

                        {answer && answer !== null && settings && settings.active && answer.moodID !== settings.moodID ||
                          !loading && answer === null ? (
                            <div className="component mt-3">
                              <div className="br-12 light-shadow bg__white w-100">
                                <MoodItem />
                              </div>
                            </div>
                          ) : null}

                        {answers.length === 0 && answer === null ? (
                          <Fragment>
                            <Notification
                              msg={moodCopy.msgOnboarding}
                              color={'green'}
                            />

                            <Fragment>
                               <MoodMain 
                                  moodAnswers={onboardingMood}
                                  uniqueSurvey={onboardingMoodTitle}
                                  moodCopy={moodCopy}
                                  allComments={allComments}
                                  showAllComments={showAllComments}
                                />
                             </Fragment>
                          </Fragment>
                        ) : (
                          <Fragment>

                            {moodAnswers.length > 0 && (

                              <MoodMain 
                                moodAnswers={moodAnswers}
                                uniqueSurvey={uniqueAnswers}
                                moodCopy={moodCopy}
                                allComments={allComments}
                                showAllComments={showAllComments}
                              />
                              )
                            }
                          </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      <NoContent 
                        message={moodCopy.updatePlanMsg}
                        picture={updateImg}
                        alt={'Update plan'}
                        imgWidth={'w-300px'}
                        admin={user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0}
                      />
                    )}
                    
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

Mood.propTypes = {
  getUserCompany: PropTypes.func.isRequired,
  getMoodSettingsAndUserAnswer: PropTypes.func.isRequired,
  getCompanyMoodAnswers: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  mood: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company,
  locale: state.company,
  mood: state.ms,
});

export default connect(
  mapStateToProps,
  {
    getUserCompany,
    getMoodSettingsAndUserAnswer, 
    getCompanyMoodAnswers,
  }
)(Mood);
