import {
  GET_USER_CUSTOM_ANSWERS,
  GET_USER_CUSTOM_ANSWERS_ID,
  GET_PROFILE_CUSTOM_ANSWERS,
  GET_TEAM_CUSTOM_ANSWERS,
  GET_CUSTOM_ANSWERS,
  CUSTOM_ANSWER_ERROR,
  CUSTOM_ANSWERS_ERROR,
  ADD_CUSTOM_ANSWER,
  EDIT_CUSTOM_ANSWER,
  GET_CUSTOM_ANSWER,
  GET_CAMPAIGN_CUSTOM_ANSWERS,
  CUSTOM_LIKES,
  ADD_COMMENT,
  UPDATE_COMMENT,
  CLEAR_CUSTOM_ANSWERS,
  GET_COMPANY_CUSTOM_ANSWERS,
  COMPANY_CUSTOM_ANSWER_ERROR,
  REMOVE_COMMENT,
  LOCATION_CHANGE_ERROR_CUSTOM,
  GET_CUSTOM_ANSWER_BY_ID,
  GET_ALL_USER_CUSTOM_ANSWERS,
  GET_COMPANY_STANDUP_ANSWERS_IN_CHECKIN,
  GET_COMPANY_PLAN_ANSWERS_IN_CUSTOM,
  GET_COMPANY_UPDATE_ANSWERS_IN_CUSTOM,
  GET_COMPANY_ALL_ANSWERS_IN_CUSTOM,
  CHANGE_CHECKIN_FILTERS,
  CHECK_CHECKIN_FILTERS,
  CLEAR_CHECKIN_FILTERS,
} from '../actions/types';

const initialState = {
  customAnswers: [],
  customAnswer: null,
  loading: true,
  data: [],
  isHomeLoadingCustom: true,
  isAnswersLoaded: false,
  isAllAnswersLoaded: false,  // 50 answers - our limit in api
  filters: localStorage && localStorage.checkin_filter ? JSON.parse(localStorage.checkin_filter) : {team:null,time:null,company:null},
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_CUSTOM_ANSWERS:
    case LOCATION_CHANGE_ERROR_CUSTOM: 
     return {
       ...state,
       customAnswers: [],
       isAnswersLoaded: false,
     };
    case GET_CUSTOM_ANSWERS:
    case GET_PROFILE_CUSTOM_ANSWERS:
    case GET_TEAM_CUSTOM_ANSWERS:
      return {
        ...state,
        customAnswers: payload,
        loading: false,
        isHomeLoadingCustom: false
      };
    case GET_COMPANY_CUSTOM_ANSWERS:
    case GET_CAMPAIGN_CUSTOM_ANSWERS:
    // case GET_COMPANY_STANDUP_ANSWERS_IN_CHECKIN:
    // case GET_COMPANY_PLAN_ANSWERS_IN_CUSTOM:
    // case GET_COMPANY_UPDATE_ANSWERS_IN_CUSTOM:
    // case GET_COMPANY_ALL_ANSWERS_IN_CUSTOM:
      return {
        ...state,
        customAnswers: payload.company,
        customAnswer: payload.user,
        loading: false,
        isAnswersLoaded: true,
      };
    case GET_COMPANY_STANDUP_ANSWERS_IN_CHECKIN:
    case GET_COMPANY_PLAN_ANSWERS_IN_CUSTOM:
    case GET_COMPANY_UPDATE_ANSWERS_IN_CUSTOM:
    case GET_COMPANY_ALL_ANSWERS_IN_CUSTOM:
      const newCustomAnswers = payload.company.filter(({ _id: newId }) => !state.customAnswers.some(({ _id: oldId }) => newId === oldId));
      return {
        ...state,
        customAnswers: state.customAnswers.length === 0 ? payload.company : state.customAnswers.concat(newCustomAnswers),
        customAnswer: payload.user,
        loading: false,
        isAnswersLoaded: true,
        isAllAnswersLoaded: payload.company.length < 50 ? true : false,
      };
    case GET_USER_CUSTOM_ANSWERS:
    case GET_USER_CUSTOM_ANSWERS_ID:
      return {
        ...state,
        customAnswers: payload.answers,
        customAnswer: payload.last,
        loading: false,
        isHomeLoadingCustom: false,
       };
    case GET_ALL_USER_CUSTOM_ANSWERS:
      return {
        ...state,
        data: payload.answers
        };
    case GET_CUSTOM_ANSWER_BY_ID:
    case GET_CUSTOM_ANSWER:
      return {
        ...state,
        customAnswer: payload,
        loading: false
      };
    case ADD_CUSTOM_ANSWER:
      return {
        ...state,
        customAnswers: [payload, ...state.customAnswers],
        loading: false
      };
    case EDIT_CUSTOM_ANSWER:
      return {
        ...state,
        customAnswers: state.customAnswers.map(customAnswer =>
          customAnswer._id === payload.customID
            ? {
                ...customAnswer,
                answers: payload.answers,
                updatedAt: payload.updatedAt
              }
            : customAnswer
        ),
        loading: false
      };
    case CUSTOM_LIKES:
      return {
        ...state,
        customAnswers: state.customAnswers.map(customAnswer =>
          customAnswer._id === payload.customID
            ? {
                ...customAnswer,
                likes: payload.likes,
                answers: payload.answers
              }
            : customAnswer
        ),
        loading: false
      };
    case ADD_COMMENT:
      return {
        ...state,
        customAnswers: state.customAnswers.map(customAnswer =>
          customAnswer._id === payload.customID
            ? {
                ...customAnswer,
                comments: payload.comments,
                answers: payload.answers
              }
            : customAnswer
        ),
        loading: false
      };
    case UPDATE_COMMENT:
      return {
        ...state,
        customAnswers: state.customAnswers.map(customAnswer =>
          customAnswer._id === payload.customID
            ? {
                ...customAnswer,
                comments: payload
              }
            : customAnswer
        ),
        loading: false
      };
    case REMOVE_COMMENT:
      return {
        ...state,
        customAnswers: state.customAnswers.map(customAnswer =>
          customAnswer._id === payload.customID
            ? {
                ...customAnswer,
                answers: payload.answers,
                comments: customAnswer.comments.filter(
                  comment => comment._id !== payload.commentID
                )
              }
            : customAnswer
        ),
        loading: false
      };
    case CUSTOM_ANSWER_ERROR:
    case COMPANY_CUSTOM_ANSWER_ERROR:
      return {
        ...state,
        error: payload,
        customAnswers: [],
        customAnswer: null,
        loading: false,
      };
    case CHANGE_CHECKIN_FILTERS:
      localStorage.setItem('checkin_filter', JSON.stringify({ team: payload.team, time: payload.time, company: payload.company ? payload.company : state.filters.company  }));
      return {
        ...state,
        filters:{team:payload.team,time:payload.time,company: payload.company ? payload.company : state.filters.company}
    }
    case CHECK_CHECKIN_FILTERS:
      // console.log('======= CHECK_CHECKIN_FILTERS ========')
      // console.log('======= state.filters', state.filters)
      // console.log('======= state.filters.company', state.filters.company)
      // console.log('======= payload.company', payload.company)
      if (state.filters && state.filters.company && payload.company && state.filters && state.filters.company !== payload.company || !state.filters.company) {
        localStorage.setItem('checkin_filter', JSON.stringify({ team: null, time: null, company: null }));
      }
      return {
        ...state,
        filters: state.filters && state.filters.company && state.filters.company === payload.company ? state.filters : {team:null,time:null,company:null}
      }
    case CLEAR_CHECKIN_FILTERS:
      // console.log('======= CLEAR_CHECKIN_FILTERS ========')
      localStorage.setItem('checkin_filter', JSON.stringify({ team: null, time: null, company: null }));
      return {
        ...state,
        filters: {team:null,time:null,company:null}
      }
    case CUSTOM_ANSWERS_ERROR:
      return {
        ...state,
        error: payload,
        customAnswers: [],
        loading: false
      };
    default:
      return state;
  }
}
