import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '../layout/Menu';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import Widgets from '../widgets/Widgets';
import FourWidgets from '../widgets/FourWidgets';
import UserTitle from './UserTitle';
import SubMenu from './SubMenu';
import ChartLine from '../utils/ChartLine';
import Timeline from '../utils/Timeline';
import Select from 'react-select';

import { getUserOkrsById, getUserMetricsById, clearOkrs } from '../../actions/okr';
import { getCompanyProfiles, getProfileById } from '../../actions/profile';
import { getUserAnswersById, clearStandups } from '../../actions/standupAnswer';
import { getUserCompany } from '../../actions/company';
import { FormattedMessage } from 'react-intl';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    backgroundColor: '#fcfcfd',
    fontSize: '14px',
  }),
  control: styles => ({ ...styles, backgroundColor: '#fcfcfd', padding: '4px 8px' }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
}

const UserFocus = ({
  clearOkrs,
  clearStandups,
  getUserAnswersById,
  getUserMetricsById,
  getUserOkrsById,
  getProfileById,
  getCompanyProfiles,
  getUserCompany,
  company: { company },
  auth: { user },
  profile: { profile },
  standupAnswer: { standupAnswers },
  okr: { data, isMetricsLoading },
  match,
  locale,
}) => {
  const [formData, setFormData] = useState({
    time: '',
    objectives: []
  });

  let { time, objectives } = formData;
  
  let timeOptions = [];

  // Time
  let hours = new Date().getUTCHours();
  let minutes = new Date().getMinutes();
  let today = new Date().getDay();
  if (today === 0) { today = 8 }; // For Sundays
  let all = Date.parse(new Date(new Date(2019, 1, 1, 1, 1)));
  let month = Date.parse(new Date(new Date() - (30 * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60) * 1000));
  let weekAgo = Date.parse(new Date(new Date() - (7 * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60) * 1000));
  let thisWeek = Date.parse(new Date(new Date() - ((today - 1) * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60) * 1000));

  let allTime = locale && locale.lang !== 'ru' ? `All Time` : `Все время`; 
  let monthTime = locale && locale.lang !== 'ru' ? `Last month` : `Месяц`; 
  let weekTime = locale && locale.lang !== 'ru' ? `Last 7 days` : `7 дней`; 
  let thisWeekTime = locale && locale.lang !== 'ru' ? `This week` : `Эта неделя`; 
  
  timeOptions = [
      { value: 4, label: allTime, period: all },
      { value: 3, label: monthTime, period: month },
      { value: 2, label: weekTime, period: weekAgo },
      { value: 1, label: thisWeekTime, period: thisWeek }
    ];

  let choosedTime = { value: 1, label: `This week`, period: thisWeek };


  useEffect(() => {
    getUserCompany();
    getCompanyProfiles();
    getProfileById(match.params.id);
    getUserMetricsById(match.params.id);
    getUserAnswersById(match.params.id);

    setFormData({
      time: !data ? '' : choosedTime,
      objectives: !data ? [] : data.filter(item => Date.parse(item.date) > choosedTime.period)
    });

    // Clear state
    return () => {
      clearStandups();
     }

  }, [getUserCompany, getCompanyProfiles, getProfileById, getUserMetricsById, getUserAnswersById, isMetricsLoading, match.params.id]);
  
  const handleTime = selectedOption => {
    let newItems;

    newItems = data.filter(item => Date.parse(item.date) > selectedOption.period);

    setFormData({ 
      ...formData, 
      time: selectedOption, 
      objectives: newItems });
  };

  let x = data.length > 0 && data.filter(item => {
    if (Date.parse(item.date) > choosedTime.period) {
      return item;
    }

    return
  })

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {user && user.department ? (
          <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user && user.isAdmin && true} activeIndex={8} />
        ) : (
          <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} activeIndex={8} />
        )}
        <UserNav />
          <Fragment>
            {isMetricsLoading || !user || !company || !profile || !data  ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="main main-dashboard">
                     <h5>
                      <FormattedMessage id="company.header.focus" defaultMessage={`${profile && profile.firstName} Focus`} />
                    </h5>

                  <SubMenu userId={profile._id} companyIndex={5} />

                  <div className="focus mb-5 pb-5">
                    <FormattedMessage
                      id="focus.sub_menu.timeSelect"
                      defaultMessage="Choose the time interval"
                    >
                      {msg => (
                        <Select
                          className="small-input w-30-l-50-xs"
                          defaultValue={choosedTime}
                          value={time}
                          onChange={selectedOption =>
                            handleTime(selectedOption)
                          }
                          options={timeOptions}
                          placeholder={msg}
                          required
                          name="time"
                          styles={customStyles}
                        />
                      )}
                    </FormattedMessage>
                    <div className="my-4">
                        <FourWidgets 
                          color={'light'}
                          amount={4}
                          objectives={objectives}
                          weekFocus={objectives.length > 0 && objectives.filter(i => i.isWeekFocus)}
                          dayFocus={objectives.length > 0 && objectives.filter(i => i.isDayFocus)}
                          board={time.value}
                        />
                    </div>
                    <ChartLine 
                      items={objectives}  
                    />
                    <div className="my-3 pb-5">
                      <div className="timeline my-5">
                        <div className="timeline__row flex wrap flex-column-s">
                          {objectives.length > 0 && (
                            objectives.map(item => (
                              <Timeline 
                                item={item} 
                                key={item._id}
                                standupAnswer={standupAnswers && standupAnswers.filter(s => s._id === item.standupAnswer)}  
                              />
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

UserFocus.propTypes = {
  getCompanyProfiles: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getProfileById: PropTypes.func.isRequired,
  getUserOkrsById: PropTypes.func.isRequired,
  getUserMetricsById: PropTypes.func.isRequired,
  getUserAnswersById: PropTypes.func.isRequired,
  clearOkrs: PropTypes.func.isRequired,
  clearStandups: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  standupAnswer: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  locale: state.locale,
  standupAnswer: state.standupAnswer,
  okr: state.okr
});

export default connect(
  mapStateToProps,
  { getUserCompany, getCompanyProfiles,  getUserOkrsById, getProfileById, clearOkrs, getUserMetricsById, getUserAnswersById, clearStandups }
)(UserFocus);
