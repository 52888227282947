import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const QuestionItemNew = ({
  onChange,
  name,
  value,
  stylesDiv,
  stylesInput,
  inputName,
  wording,
}) => {

  let wordingDefault = { id: "add.text", text: "Add text" }

  if (!wording || wording.id && wording.id.length === 0) { wording = wordingDefault }

  return (
    <div
      className={`form-main form-app-okr form-main-inline ${stylesDiv}`}
    >
      <FormattedMessage id={wording.id} defaultMessage={wording.text}>
        {msg => <input
          required
          className={`input-text lh-44 fs-16 brd-grey__new ${stylesInput}`}
          type="text"
          placeholder={msg}
          name={inputName}
          value={value || ''}
          onChange={e => onChange(e, name)}
        />}
      </FormattedMessage>
    </div>
  );
};

QuestionItemNew.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.object,
};

export default QuestionItemNew;
