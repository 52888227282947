import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { setAlert } from '../../actions/alert';
import { MentionsInput, Mention } from 'react-mentions';
import { icons } from "../../img/elements/icons";

import { FormattedMessage } from 'react-intl';

const UniversalCommentForm = ({
  mainItemId,
  index,
  value,
  onSubmit,
  onChange,
  avatar,
  mentions,
}) => {

  const [data, setText] = useState('');

  return (
    <form
      className="form-app form-app-comments"
      onSubmit={e => {
        onSubmit(mainItemId, index, data, e);
        setText('');
      }}
    >
      <div className="form-comment flex align-center w-100">
          <MentionsInput 
            value={data} 
            onChange={e => setText(e.target.value)} 
            className={'w-100 p-1 br-4 fs-14'}
          >
            <Mention
              trigger="@"
              data={mentions}
              markup="@{{__display__:__id__}}"
              className="lh-50"
            />
          </MentionsInput>
        
          <button
            type="submit"
            className="flex align-center bg-blue p-1 br-4 brd-transparent cursor btn-secondary__blue-hover ml-1"
          >
            {icons.paper_plane_white}
          </button>
      </div>
    </form>
  );
};

UniversalCommentForm.propTypes = {
  addComment: PropTypes.func
};

export default UniversalCommentForm;
