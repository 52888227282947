import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
  BsFillEmojiSmileFill, 
  BsFillEmojiHeartEyesFill, 
  BsFillEmojiFrownFill, 
  BsFillEmojiNeutralFill,
  BsFillEmojiDizzyFill, 
} from "react-icons/bs";
import { addMoodAnswer } from '../../actions/moodAnswer';
import { FormattedMessage } from 'react-intl';
import Loader from 'react-loader-spinner';

const MoodItem = ({
  addMoodAnswer,
}) => {

  const [formData, setFormData] = useState({
    text: '',
    active: 0,
  });

  const [isLoader, toggleLoader] = useState(false);
  let { active, text } = formData;

  const handleClick = (icon) => {

    if (active === 0 || active > 0 && active !== icon) {
      setFormData({
        ...formData,
        active: icon,
      })
    } else if (active > 0 && active === icon) {
      setFormData({
        ...formData,
        active: 0,
      })
    }
  }

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Show Loader
    toggleLoader(!isLoader);

    let res = await addMoodAnswer(active, text);

    if (res && res.ok) {
      setFormData({
        ...formData,
        active: 100,
      })
    }

  };

  let questionTxt = <FormattedMessage id="mood.question" defaultMessage="How do you feel today?" />;
  let descriptionTxt = <FormattedMessage id="mood.desctiption" defaultMessage="Please answer with your current mood. All answers are anonymous." />;
  let btnTxt = <FormattedMessage id="submit.mood" defaultMessage="Submit mood" />;

  // Loader for button
  let loadingIcon = (
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader type='Circles' color='#404ff5' height='14px' width='15' />
    </span>
  );
  
  if (isLoader) {
    btnTxt = loadingIcon;
  }

  return (
    <Fragment>
      <div className="p-4">
        <div className="mood__title">
          <p className="fs-2 bold">{questionTxt}</p>
        </div>

        <div className="small grey">{descriptionTxt}</div>
        <div className="h-50px mood__options flex align-center my-2">
          <p 
            className={`fs-50 mr-1 cursor hover__scaled-1_2 red ${active === 1 ? 'scaled-1_2' : ''}`}
            onClick={() => handleClick(1)}
          >
            <BsFillEmojiFrownFill />
          </p>
          <p 
            className={`fs-50 mr-1 cursor hover__scaled-1_2 orange ${active === 2 ? 'scaled-1_2' : ''}`}
            onClick={() => handleClick(2)}
          >
            <BsFillEmojiDizzyFill />
          </p>
          <p 
            className={`fs-50 mr-1 cursor hover__scaled-1_2 grey ${active === 3 ? 'scaled-1_2' : ''}`}
            onClick={() => handleClick(3)}
            >
           <BsFillEmojiNeutralFill />
          </p>
          <p 
            className={`fs-50 mr-1 cursor hover__scaled-1_2 blue ${active === 4 ? 'scaled-1_2' : ''}`}
            onClick={() => handleClick(4)}
            >
            <BsFillEmojiSmileFill />
          </p>
          <p 
            className={`fs-50 mr-1 cursor hover__scaled-1_2 green ${active === 5 ? 'scaled-1_2' : ''}`}
            onClick={() => handleClick(5)}
            >
            <BsFillEmojiHeartEyesFill />
          </p>
        </div>

        {active > 0 && (
          <form 
            className="form-app-comments form-app form-comment"
            onSubmit={e => onSubmit(e)}
            >
            <FormattedMessage
                id="add.comment.optional"
                defaultMessage="Add a comment (optional)"
              >
                {msg => (
                  <input
                    type="text"
                    className="text w-60"
                    placeholder={msg}
                    name="text"
                    value={text || ''}
                    onChange={e => onChange(e)}
                  />
                )}
              </FormattedMessage>
            <button 
              type="submit" 
              disabled={!isLoader ? false : true}
              className="mw-200 btn-secondary btn-secondary-comment"
              onSubmit={e => onSubmit(e)}
              >
               {btnTxt}
            </button>
          </form>
        )}
      </div>
    </Fragment>
  );
};

MoodItem.propTypes = {
  addMoodAnswer: PropTypes.func.isRequired,
};

export default connect(
  null,
  {
    addMoodAnswer,
  }
)(MoodItem);