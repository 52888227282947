import React, { Fragment, useState } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { changePassword } from '../../actions/profile';
import UserNav from '../layout/UserNav';
import Menu from '../layout/Menu';
import Spinner from '../layout/Spinner';
import { FormattedMessage } from 'react-intl';

const ChangePassword = ({
  profile,
  auth: { isAuthenticated, loading, user },
  changePassword,
  setAlert,
  match,
  history
}) => {
  const [formData, setFormData] = useState({
    password: '',
    newPassword: '',
    newPassword2: ''
  });

  const { password, newPassword, newPassword2 } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    if (!password)
      return setAlert(
        <FormattedMessage
          id="profile.change.alert.enterOld"
          defaultMessage="Please enter the old password"
        />,
        'danger'
      );

    if (newPassword !== newPassword2) {
      setAlert(
        <FormattedMessage
          id="profile.change.alert.noMatch"
          defaultMessage="New passwords do not match"
        />,
        'danger'
      );
    } else {
      changePassword(password, newPassword, match.params.id, history);
    }
  };

  if (!loading && !isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <div className="css-main">
        <div className="wrapper">

          {user && user.department ? (
            <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user && user.isAdmin && true} />
          ) : (
            <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} />
          )}

          <UserNav />
          <Fragment>
            {profile === null || loading ? (
              <Spinner />
            ) : (
              <div className="main main-dashboard user-standups">
                <div className="standups mt-5">
                  <div className="section-title">
                    <h5>
                      <FormattedMessage
                        id="profile.change.pass.header"
                        defaultMessage="Change Password"
                      />
                    </h5>
                    <Link
                      to="/edit-profile"
                      className="btn-secondary btn-secondary-back"
                    >
                      <FormattedMessage id="go.back" defaultMessage="Go Back" />
                    </Link>
                  </div>

                  <div className="component standups-daily my-2 p-3 light-shadow">
                    <div className="form-full mt-2">
                      <form
                        className="form-app form-app-standup"
                        onSubmit={e => onSubmit(e)}
                      >
                        <div className="form-main form-main-inline">
                          <p className="bold medium block">
                            <FormattedMessage
                              id="profile.change.pass.old"
                              defaultMessage="Old password"
                            />
                          </p>
                          <FormattedMessage
                            id="profile.change.pass.oldInput"
                            defaultMessage="Enter your old password"
                          >
                            {msg => (
                              <input
                                type="password"
                                placeholder={msg}
                                name="password"
                                value={password}
                                onChange={e => onChange(e)}
                              />
                            )}
                          </FormattedMessage>
                        </div>

                        <div className="form-main form-main-inline">
                          <p className="bold medium block">
                            <FormattedMessage
                              id="profile.change.pass.new"
                              defaultMessage="New password"
                            />
                          </p>
                          <FormattedMessage
                            id="profile.change.pass.newInput"
                            defaultMessage="Enter your new password"
                          >
                            {msg => (
                              <input
                                type="password"
                                placeholder={msg}
                                name="newPassword"
                                value={newPassword}
                                onChange={e => onChange(e)}
                              />
                            )}
                          </FormattedMessage>
                        </div>

                        <div className="form-main form-main-inline">
                          <p className="bold medium block">
                            <FormattedMessage
                              id="profile.change.pass.new2"
                              defaultMessage="Repeat new password"
                            />
                          </p>
                          <FormattedMessage
                            id="profile.change.pass.newInput2"
                            defaultMessage="Repeat your new password"
                          >
                            {msg => (
                              <input
                                type="password"
                                placeholder={msg}
                                name="newPassword2"
                                value={newPassword2}
                                onChange={e => onChange(e)}
                              />
                            )}
                          </FormattedMessage>
                        </div>

                        <button type="submit" className="btn btn-form">
                          <FormattedMessage
                            id="profile.change.pass.btn"
                            defaultMessage="Change Password"
                          />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

ChangePassword.propTypes = {
  setAlert: PropTypes.func,
  changePassword: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { setAlert, changePassword }
)(withRouter(ChangePassword));
