import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Menu from '../layout/Menu';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import UsersTable from './UsersTable';
import { setAlert } from '../../actions/alert';
import { loginAsUser, logoutAsUser } from '../../actions/auth';
import { getUsers, getAllOkrs, getAllStandups, getAllUpdates, getAllCustoms, getCompanies, getAllPlans, getAllIcebreakers, getAllTasks, deleteCompany, getAllOnes, getAllMoods } from '../../actions/admin';
import { FormattedMessage } from 'react-intl';

const Users = ({
  setAlert,
  getUsers,
  getAllOkrs,
  getAllStandups,
  getAllUpdates,
  getAllPlans,
  getAllIcebreakers,
  getAllTasks,
  getCompanies,
  auth: { user },
  data: { u, ca, s, w, o, c, p, i, t, oo, ms, loading, },
  loginAsUser,
  logoutAsUser,
  history,
  deleteCompany,
  getAllOnes,
  getAllMoods, 
  getAllCustoms,
}) => {

  useEffect(() => {
    getUsers();
    getAllOkrs();
    getAllStandups();
    getAllUpdates();
    getCompanies();
    getAllPlans();
    getAllIcebreakers();
    getAllTasks();
    getAllOnes();
    getAllMoods();
    getAllCustoms();

  }, [getUsers, getAllOkrs, getAllStandups, 
    getAllUpdates, getCompanies, getAllPlans, 
    getAllIcebreakers, getAllTasks, loading, getAllOnes, 
    getAllMoods,getAllCustoms,
  ]);
  
  let icebreakersAnswers = 0;
  
  {
    i && i.length > 0 && i.forEach(item => {
      if (item.answers.length > 0) {
        icebreakersAnswers += item.answers.length;
      }
    })
  }

  const activeUsers = u && u.filter(item => item.status === 'active');
  const activeCompanies = c && c.filter(item => item.status === 'active');
  const num = activeUsers.length / activeCompanies.length;
  const averageTeam = num.toFixed(2);

  let hours = new Date().getUTCHours();
  let minutes = new Date().getMinutes();
  let weekAgo = Date.parse(new Date(new Date() - (7 * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60) * 1000));

  const weeklyCreatedOkrs = o.filter(item => new Date(item.date) > weekAgo);
  const weeklyUpdatedOkrs = o.filter(item => new Date(item.updatedAt) > weekAgo);
  const weeklyStandups = s.filter(item => new Date(item.date) > weekAgo);
  const weeklyUpdates = w.filter(item => new Date(item.date) > weekAgo);
  const weeklyPlans = p.filter(item => new Date(item.date) > weekAgo);
  const weeklyCustoms = ca.filter(item => new Date(item.date) > weekAgo);
  const weeklyIcebreakers = i.filter(item => item.answers.length > 0 && new Date(item.date) > weekAgo);
  const weeklyTasks = t.filter(item => new Date(item.date) > weekAgo);
  const weeklyOnes = oo.filter(item => new Date(item.date) > weekAgo);
  const weeklyMoods = ms.filter(item => new Date(item.date) > weekAgo);
  const newUsers = u.filter(item => new Date(item.date) > weekAgo && item.status === 'active');
  const newCompanies = c.filter(item => new Date(item.date) > weekAgo && item.status === 'active');

   const handleLogin = id => {
     loginAsUser(id, history);
   };

  //  console.log('===== ca', ca)

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {user && user.department ? (
          <Menu companyName={user && user.companyName} team={user.departmentName} teamId={user.department} settings={user && user.isAdmin && true} focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={200} />
        ) : (
          <Menu companyName={user && user.companyName} settings={user && user.isAdmin && true} focusUsers={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={200} />
        )}

        <UserNav />
        
          <Fragment>
            {loading || !user || !u || !s || user.role !== 'Superadmin'  ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="pt-2 main-dashboard dashboard__admin_analytics">
                  <h5>
                    <FormattedMessage id="company.header.outreach" defaultMessage={`Focus Analytics`} />
                  </h5>
                  <div className="mb-5 pb-5">
                    <div className="mt-3">
                        <p className="medium bold">Total users:{" "}{u.length}</p>
                        <p className="medium bold">Active users:{" "}{activeUsers.length}</p>
                        <p className="medium bold">Total companies:{" "}{c.length}</p>
                        <p className="medium bold">Active companies:{" "}{activeCompanies.length}</p>
                        <p className="medium bold">Average users in a company:{" "}{averageTeam}</p>
                        <p className="medium bold">Total Check-in answers:{" "}{ca.length}{" "}(wkly answers:{" "}{weeklyCustoms.length})</p>
                        <p className="medium bold">Total Standup answers:{" "}{s.length}{" "}(wkly answers:{" "}{weeklyStandups.length})</p>
                        <p className="medium bold">Total Weekly Update answers:{" "}{w.length}{" "}(wkly answers:{" "}{weeklyUpdates.length})</p>
                        <p className="medium bold">Total Weekly Plan answers:{" "}{p.length}{" "}(wkly answers:{" "}{weeklyPlans.length})</p>
                        <p className="medium bold">Total Icebreakers:{" "}{icebreakersAnswers}{" "}(wkly icebreakers:{" "}{weeklyIcebreakers.length})</p>
                        <p className="medium bold">Total OKRs:{" "}{o.length}{" "}(wkly created:{" "}{weeklyCreatedOkrs.length}; wkly updated: {" "}{weeklyUpdatedOkrs.length})</p>
                        <p className="medium bold">Total Tasks:{" "}{t.length}{" "}(wkly tasks:{" "}{weeklyTasks.length})</p>
                        <p className="medium bold">Total 1:1s:{" "}{oo.length}{" "}(wkly 1:1s:{" "}{weeklyOnes.length})</p>
                        <p className="medium bold">Total Moods:{" "}{ms.length}{" "}(wkly moods:{" "}{weeklyMoods.length})</p>
                        <div className="line w-80"></div>
                        <p className="medium bold">New active companies:{" "}{newCompanies.length}</p>
                        <p className="medium bold">New active users:{" "}{newUsers.length}</p>
                    </div>

                    <div className="my-3 pb-5 pr-2">
                        <UsersTable
                          users={u}
                          companies={c}
                          customs={ca}
                          okrs={o}
                          standups={s}
                          updates={w}
                          plans={p}
                          tasks={t}
                          icebreakers={i}
                          oneOnOnes={oo}
                          moods={ms}
                          weeklyStandups={weeklyStandups}
                          weeklyUpdates={weeklyUpdates}
                          weeklyPlans={weeklyPlans}
                          weeklyTasks={weeklyTasks}
                          weeklyIcebreakers={weeklyIcebreakers}
                          weeklyCreatedOkrs={weeklyCreatedOkrs}
                          weeklyUpdatedOkrs={weeklyUpdatedOkrs}
                          weeklyOnes={weeklyOnes}
                          weeklyMoods={weeklyMoods}
                          handleLogin={handleLogin}
                          deleteCompany={deleteCompany}
                          auth={user}
                          weeklyCustoms={weeklyCustoms}
                        />
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

Users.propTypes = {
  setAlert: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  getAllOkrs: PropTypes.func.isRequired,
  getAllStandups: PropTypes.func.isRequired,
  getAllUpdates: PropTypes.func.isRequired,
  getAllPlans: PropTypes.func.isRequired,
  getAllIcebreakers: PropTypes.func.isRequired,
  getAllTasks: PropTypes.func.isRequired,
  getAllMoods: PropTypes.func.isRequired,
  deleteCompany: PropTypes.func.isRequired,
  getAllOnes: PropTypes.func.isRequired,
  getAllCustoms: PropTypes.func.isRequired,
  getCompanies: PropTypes.func.isRequired,
  loginAsUser: PropTypes.func.isRequired,
  logoutAsUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  data: state.data,
});

export default connect(
  mapStateToProps,
  {
    getUsers, getAllOkrs, getAllStandups,
    getAllUpdates, getCompanies,
    setAlert, loginAsUser, logoutAsUser,
    getAllPlans, getAllIcebreakers,
    getAllTasks, deleteCompany, getAllOnes,
    getAllMoods, getAllCustoms,
  }
)(Users);