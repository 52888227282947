import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MentionsInput, Mention } from 'react-mentions';
import InputNew from '../elems/InputNew';

const UniversalCommentForm = ({
  mainItemId,
  index,
  onSubmit,
}) => {

  const [data, setText] = useState('');

  return (
    <form
      className="form-app form-app-comments"
      onSubmit={e => {
        onSubmit(mainItemId, index, data, e);
        setText('');
      }}
    >
      <InputNew 
        setText={setText}
        text={data}
      />
    </form>
  );
};

UniversalCommentForm.propTypes = {
  addComment: PropTypes.func
};

export default UniversalCommentForm;
