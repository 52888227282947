import React from "react";
import PropTypes from "prop-types";

const NotificationItem = ({
  checkboxName,
  checkboxValue,
  setFormData,
  formData,
  label,
  labelStyles,
  divStyles,
}) => {
  return (
    <div className="w-100 flex align-start flex-column checkbox">
      <div className={`${!divStyles ? 'w-230px' : divStyles} checkbox-item active-status active-status-new flex align-center active-status ml-3`}>
        <input
          type="checkbox"
          id={checkboxName}
          name={checkboxName}
          checked={checkboxValue}
          value={checkboxValue}
          onChange={e => {
            setFormData({
              ...formData,
              [checkboxName]: !checkboxValue
            });
          }}
        />
        <label className={`fs-13 ${labelStyles ? labelStyles : ''}`} htmlFor={checkboxName}>{label}</label>
      </div>
    </div>
  );
};

NotificationItem.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func
};

export default NotificationItem;
