import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MoodIcon from './MoodIcon';

const MoodRow = ({
  items,
  allComments,
  showAllComments,
}) => {

  items.sort(function(a, b) {
    return a.grade - b.grade;
  });

  return (
    <Fragment>
      {items  && items.length > 0 && (
        items.map((item, index) => (
          <MoodIcon
            key={`mood-${index}`}
            item={item}
            showAlComments={showAllComments}
            allComments={allComments}
          />
        ))
      )}
    </Fragment>
  );
};

MoodRow.propTypes = {
  items: PropTypes.array.isRequired,
};

export default MoodRow;
