import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

const DetailsUpdateNew = ({ item: { updates }, updateMode, onChange, okrData }) => {
  
  return (
    <Fragment>
      {updateMode && (
        <div className="form-comment flex align-center mt-3">
          <FormattedMessage id="type.comment" defaultMessage="Type your comment here...">
            {(msg) => (
              <input
                className="w-100 h-41 mr-1 brd_new__grey p-1 form_new__hover_primary_1px br-4"
                type="text"
                placeholder={msg}
                name="text"
                value={okrData.text}
                onChange={(e) => onChange(e)}
              />
            )}
          </FormattedMessage>
        </div>
      )}
    </Fragment>
  );
};

export default DetailsUpdateNew;
