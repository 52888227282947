import React, { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStandupSettings } from '../../actions/standup';
import Navbar from '../layout/Navbar';
import Spinner from '../layout/Spinner';
import { FormattedMessage } from 'react-intl';
import slackImg from '../../img/admin/slack-auth.jpg';
import slackLogo from '../../img/admin/slack-logo.png';

const Auth = ({ getStandupSettings, standup: { settings, loading } }) => {
  useEffect(() => {
    getStandupSettings();
  }, [loading, getStandupSettings]);

  return (
    <Fragment>
      <Navbar adminMenu={true} />

      <Fragment>
        {!settings || loading ? (
          <Spinner />
        ) : settings.slackIsActive ? (
          <div className="mxa main-profile-admin px-2">
            <div className="slack-admin">
              <div className="text-center">
                <img
                  src={slackLogo}
                  alt="Slack logo"
                  className="mw-100"
                  width="100"
                  height="100"
                />
                <h2 className="pt-1">
                  <FormattedMessage
                    id="admin.auth.header"
                    defaultMessage="Success!"
                  />
                </h2>
              </div>

              <div className="edit-standups-daily my-2 p-3">
                <div className="">
                  <div className="py-2">
                    <p className="large bold">
                      <FormattedMessage
                        id="admin.auth.subtitle1"
                        defaultMessage="Congratulations!"
                      />
                    </p>
                    <p className="meduim">
                      <FormattedMessage
                        id="admin.auth.text"
                        defaultMessage="Focus bot was installed. You can use the bot to build amazing culture and work better in Slack now."
                      />
                    </p>
                  </div>

                  <div className="py-2">
                    <p className="large bold">
                      <FormattedMessage
                        id="admin.auth.subtitle2"
                        defaultMessage="Important!"
                      />
                    </p>
                    <p className="meduim">
                      <FormattedMessage
                        id="admin.auth.text2"
                        defaultMessage="You and your team members should use the same email addresses on Focus that you use on Slack. Team users from Slack, who haven’t got an account in Focus yet, will receive an invitation email after the first standup or the weekly update. If a user has on Slack another email address, then he or she should change it in Focus into this email address."
                      />
                    </p>
                  </div>

                  <div className="py-2">
                    <p className="large text-center green">
                      <FormattedMessage
                        id="admin.auth.subtitle3"
                        defaultMessage="User's email address on Slack = User's email address on Focus"
                      />
                    </p>
                  </div>

                  <div className="py-2">
                    <p className="large bold">
                      <FormattedMessage
                        id="admin.auth.subtitle4"
                        defaultMessage="Next steps"
                      />
                    </p>
                    <p className="meduim">
                      <FormattedMessage
                        id="admin.auth.text4"
                        defaultMessage="The bot is working now. It will run daily standups and weekly updates according to your settings. To edit bot's settings, you should use "
                      />
                      <span className="medium">
                        <Link
                          to="/admin/edit_standup"
                          className="blue underline"
                        >
                          <FormattedMessage
                            id="admin.auth.text4.standups"
                            defaultMessage="Standups settings"
                          />
                        </Link>
                      </span>
                      <FormattedMessage
                        id="admin.auth.text4.or"
                        defaultMessage=" or "
                      />
                      <span className="medium">
                        <Link
                          to="/admin/edit_updates"
                          className="blue underline"
                        >
                          <FormattedMessage
                            id="admin.auth.text4.updates"
                            defaultMessage="Updates settings."
                          />
                        </Link>
                      </span>
                    </p>
                  </div>

                  <div className="py-2">
                    <p className="large bold">
                      <FormattedMessage
                        id="admin.auth.subtitle5"
                        defaultMessage="What can you set in Slack bot settings?"
                      />
                    </p>
                    <p className="meduim">
                      <FormattedMessage
                        id="admin.auth.text5.first"
                        defaultMessage="– Choose a channel or a user who will get team reports"
                      />
                    </p>
                    <p className="meduim">
                      <FormattedMessage
                        id="admin.auth.text5.second"
                        defaultMessage="– Choose the type of standups: common or asynchronous standups"
                      />
                    </p>
                    <p className="meduim">
                      <FormattedMessage
                        id="admin.auth.text5.third"
                        defaultMessage="– Change the time and questions"
                      />
                    </p>
                  </div>

                  <div className="py-2">
                    <img
                      src={slackImg}
                      alt="Slack bot settings"
                      className="logo_img w-100"
                    />

                    <p className="small text-center">
                      <FormattedMessage
                        id="admin.auth.text6"
                        defaultMessage="Here is the section for managing bot's settings"
                      />
                    </p>
                  </div>

                  <div className="py-2">
                    <p className="large bold">
                      <FormattedMessage
                        id="admin.auth.subtitle7"
                        defaultMessage="Some hot commands"
                      />
                    </p>

                    <ul className="medium pl-2 list">
                      <li>
                        <span className="blue">@username r++ for</span>
                        <FormattedMessage
                          id="admin.auth.text7.second"
                          defaultMessage=" - run this command to create recognition for your coworkers"
                        />
                      </li>
                    </ul>
                  </div>

                  <div className="flex align-center space-around py-2">
                    <Link
                      to="/admin/edit_standup"
                      className="btn-secondary btn-secondary-back lh-50"
                    >
                      <FormattedMessage
                        id="go.admin"
                        defaultMessage="Go to Standups settings"
                      />
                    </Link>
                    <Link
                      to="/admin/edit_updates"
                      className="btn-secondary btn-secondary-back lh-50"
                    >
                      <FormattedMessage
                        id="go.admin"
                        defaultMessage="Go to Updates settings"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="main mxa main-dashboard main-profile-admin">
            <div className="edit-standups-daily my-2 p-3">
              <div className="py-2">
                <p className="large bold">
                  <FormattedMessage
                    id="admin.auth.mistake.subtitle1"
                    defaultMessage="Something went wrong!"
                  />
                </p>
                <p className="meduim">
                  <FormattedMessage
                    id="admin.auth.mistake.text"
                    defaultMessage={`We don't have your team id in Slack. Please connect with our support team via`}
                  />{" "}
                  {process.env.REACT_APP_EMAIL}
                </p>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    </Fragment>
  );
};

Auth.propTypes = {
  getStandupSettings: PropTypes.func.isRequired,
  standup: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  standup: state.standup
});

export default connect(mapStateToProps, {
  getStandupSettings
})(withRouter(Auth));
