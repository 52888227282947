import React, { Fragment } from "react";
import PropTypes from "prop-types";

const SlackSettingsCopy = () => {
  
  return (
    <Fragment>
      <p className="medium py-1 bold">How does it work?</p>
      <p className="medium ">The Focus bot asks questions via DM when it's time for daily check-in or weekly plan according to the schedule in the campaign settings. It sends you all answers instantly after the teammate's reply. You can set to send all answers to a public channel or specific user. </p>
      <p className="medium pt-2"><b>By default,</b> you have 4 active campaigns at Focus: daily check-ins, weekly plans, weekly updates, and icebreakers.</p>
      <p className="medium pt-2"><b>Default settings:</b> the bot asks only the administrator, who installed the bot. </p>
      <p className="medium pt-2">How to set the bot up:</p>
      <p className="medium pt-1">- Specify participants in a campaign. Just choose people who will receive messages from the bot.</p>
      <p className="medium">- Specify delivery results: channel or specific user who will get all answers.</p>
      <p className="medium">- If you want to turn off the bot for a campaign, click on the Active status to turn it off.</p>
      <p className="medium pt-2 bold">Next steps</p>
      <p className="medium pt-1">1. Specify participants for every campaign below</p>
      <p className="medium">2. Choose delivery results</p>
    </Fragment>
  );
};

export default SlackSettingsCopy;
