import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleSettings } from '../../actions/standup';
import { toggleUpdateSettings, changeCampaignType } from '../../actions/update';
import { FormattedMessage } from 'react-intl';
import CampaignItem from './CampaignItemNew';
import { deleteCustom } from '../../actions/custom';
import { deleteOkrCall } from '../../actions/okrcall';
import { oldCompaniesDate } from '../../utils/oldWorkspace'
import isEnabled, { ENGAGEMENT } from '../../utils/features';
import { Link } from 'react-router-dom';

// Hide default checkins for teams that created after 21.10.2022      
// const oldCompaniesDate = new Date('2022-10-20T00:00:00');
// console.log('oldCompaniesDate', oldCompaniesDate)

const pageTitle = <FormattedMessage id="icebreakers" defaultMessage="Icebreakers" />;
const pagesubTitle = <FormattedMessage id="icebreakers.subtitle" defaultMessage="Use Icebreakers to welcome the team members and get the conversation going" />;
const okrCallTitle = <FormattedMessage id="okrcalls" defaultMessage="Call for OKR Creation" />;
const okrCallsubTitle = <FormattedMessage id="okrcalls.subtitle" defaultMessage="Send requests to your team members to create and update OKRs on a regular basis via Slack & email reminders." />;
const createOkrCallBtn = <FormattedMessage id="create.okrcall" defaultMessage="Create New OKR Call" />;

const AdminDashboardCardItemNew = ({
  company: { company },
  settings,
  toggleSettings,
  updateSettings,
  icebreakerSettings,
  toggleUpdateSettings,
  toggleIcebreakerSettings,
  companyPlan,
  planSettings,
  togglePlanSettings,
  toggleCustomSettings,
  customCampaigns,
  changeCampaignType,
  deleteCustom,
  okrCalls,
  deleteOkrCall,
  toggleOkrCall,
  profiles,
  disabled,
}) => {
  const [formData, setFormData] = useState({
    status: settings && settings.active,
    updateStatus: updateSettings && updateSettings.active,
    icebreakerStatus: icebreakerSettings && icebreakerSettings.active,
    planStatus: planSettings && planSettings.active,
    customStatus: [],
    okrCallStatus: []
  });

  const [checkins, setCheckins] = useState([]);
  
  const [updateData, setUpdateData] = useState({
    campaignType: [],
    isForm: false,
  });

  const { icebreakerStatus, } = formData;
  const { isForm } = updateData;

  useEffect(() => {
    setFormData({
      ...formData,
      status: !settings ? false : settings.active,
      updateStatus: !updateSettings ? false : updateSettings.active,
      icebreakerStatus: !icebreakerSettings ? false : icebreakerSettings.active,
      planStatus: !planSettings ? false : planSettings.active,
      customStatus: !customCampaigns ? [] : customCampaigns.map(item => item.active),
      okrCallStatus: !okrCalls ? [] : okrCalls.map(item => item.active)
    });

    setUpdateData({
      ...updateData,
      campaignType: updateSettings && updateSettings.campaignType ? updateSettings.campaignType : 1,
    });

    // Merge all checkins
    if (settings && customCampaigns && planSettings && updateSettings) {
      // for default campaigns
      settings.name = 'Default Daily Check-ins';
      updateSettings.name = 'Default Weekly Updates';
      planSettings.name = 'Default Weekly Plans';
      settings.campaignTitle = 'Default Daily Check-ins';
      updateSettings.campaignTitle = 'Default Weekly Updates';
      planSettings.campaignTitle = 'Default Weekly Plans';
      settings.url = 'edit_standup';
      updateSettings.url = 'edit_updates';
      planSettings.url = 'edit_plans';
      settings.inputName = 'standups';
      updateSettings.inputName = 'updates';
      planSettings.inputName = 'plans';
      settings.function = toggleSettings;
      updateSettings.function = toggleUpdateSettings;
      planSettings.function = togglePlanSettings;   
      
      // weekdays
      settings.scheduleDay = { weekDays: settings.scheduleDays };
      settings.interval = 'Daily';
    
      customCampaigns.forEach(custom => {
        custom.inputName = 'custom';
        custom.function = toggleCustomSettings;
        custom.url = `campaigns/${custom._id}`;
      });

      okrCalls.forEach(okrCall => {
        okrCall.inputName = 'okrcall';
        okrCall.function = toggleOkrCall;
        okrCall.url = `edit-okr-call/${okrCall._id}`;
      });

      // Sort by the date (newest are on the top)
      // let arr = customCampaigns;
      let arr = customCampaigns.sort((a,b) => new Date(b.date) - new Date(a.date));

      if (settings && new Date(settings.date) < oldCompaniesDate) {
        arr = customCampaigns.concat(settings);
        arr = arr.concat(planSettings);
        arr = arr.concat(updateSettings);
      }

      setCheckins(arr);
    }

  }, [settings, updateSettings, icebreakerSettings, planSettings, customCampaigns, okrCalls]);

  const icebreakerCampaignTitle = <FormattedMessage id="icebreakers" defaultMessage="Icebreakers" />
  const icebreakerName = <FormattedMessage id="icebreakers" defaultMessage="Icebreakers" />

  // console.log('===== profiles', profiles)
  // console.log('===== disabled', disabled)

  return (
    <Fragment>
      {checkins && checkins.length > 0 && (
        checkins.map((item, index) => (
          <CampaignItem
            key={`campaign-${item._id}`}
            campaignTitle={item.name}
            name={item.name}
            inputName={item.inputName}
            status={item.active}
            toggleStatusFunc={item.function}
            formData={formData}
            setFormData={setFormData}
            disabled={companyPlan === 1 && profiles.length > process.env.REACT_APP_FREE_PLAN_USERS ? true : false}
            isActive={item.active}
            url={item.url}
            settings={item}
            index={index}
            isArray={true}
            deleteCustom={deleteCustom}
            deleteOkrCall={deleteOkrCall}
            type="checkin"
            id={item._id}
          />
        ))  
      )}

      {isEnabled(ENGAGEMENT, { company }) && (
        <>
          <div className="section-title mb-14 mt-5">
            <div className="flex flex-column align-start">
              <h5 className='lh-35 fw-600'>
                {pageTitle}
              </h5>
              <p className="fs-12 lh-18 pr-1">{pagesubTitle}</p>
            </div>
          </div>

          {icebreakerSettings && (
            <CampaignItem
              campaignTitle={icebreakerCampaignTitle}
              name={icebreakerName}
              inputName={'icebreakerStatus'}
              status={icebreakerStatus}
              toggleStatusFunc={toggleIcebreakerSettings}
              formData={formData}
              setFormData={setFormData}
              disabled={companyPlan < 4 ? true : false}
              isActive={icebreakerSettings.active}
              url={`edit_icebreakers`}
              settings={icebreakerSettings}
              index={0}
              deleteCustom={deleteCustom}
              type="icebreaker"
            /> 
          )}
        </>
      )}

      <>
        <div className="section-title mb-14 mt-5">
          <div className="flex flex-column align-start">
            <h5 className='lh-35 fw-600'>
              {okrCallTitle}
            </h5>
            <p className="fs-12 lh-18 pr-1">{okrCallsubTitle}</p>
          </div>
          <div className="w-300px text-right">
            <Link to="/settings/create-okr-call" className="fs-14 lh-34 inline-block text-center btn__secondary_green px-25 mla ml-2-xs">
              {createOkrCallBtn}
            </Link>
          </div>
        </div>

        {okrCalls && okrCalls.length > 0 && (
          okrCalls.map((item, index) => (
            <CampaignItem
              key={`okr-call-${item._id}`}
              campaignTitle={item.title}
              name={item.title}
              inputName={item.inputName}
              status={item.active}
              toggleStatusFunc={item.function}
              formData={formData}
              setFormData={setFormData}
              disabled={false}
              isActive={item.active}
              url={item.url}
              settings={item}
              index={index}
              isArray={true}
              deleteOkrCall={deleteOkrCall}
              type="okrcall"
              id={item._id}
            />
          ))
        )}
      </>
    </Fragment>
  );
};

AdminDashboardCardItemNew.propTypes = {
  toggleSettings: PropTypes.func.isRequired,
  toggleUpdateSettings: PropTypes.func.isRequired,
  deleteCustom: PropTypes.func,
  changeCampaignType: PropTypes.func,
  settings: PropTypes.object,
  updateSettings: PropTypes.object,
};

const mapStateToProps = (state) => ({
  company: state.company
});

export default connect(mapStateToProps, { 
  toggleSettings, 
  toggleUpdateSettings, 
  changeCampaignType,
  deleteCustom,
  deleteOkrCall,
})(
  AdminDashboardCardItemNew
);
