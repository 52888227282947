import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { CategoryScale, LinearScale, PointElement, LineElement, Chart as CharJs } from "chart.js";

CharJs.register(CategoryScale, LinearScale, PointElement, LineElement);

const Chart = ({ updateAnswers }) => {
  let answers = [];
  let days = [];

  {
    updateAnswers &&
      updateAnswers.forEach(updateAnswer => {
        if (updateAnswer.answers[0].isChart === undefined) {
        } else if (updateAnswer.answers[0].isChart) {
          answers.push(updateAnswer.answers[0].text);
          days.push(updateAnswer.date);
        }
      });
  }

  const data = {
    labels: days,
    datasets: [
      {
        data: answers,
        backgroundColor: ['rgba(108, 87, 251, 100)'],
        pointBorderColor: 'rgba(108, 87, 251, 100)',
        pointBackgroundColor: 'rgba(108, 87, 251, 100)',
        borderColor: 'rgba(108, 87, 251, 100)'
      }
    ]
  };

  return (
    <div className="chart chart-updates">
      <Line
        data={data}
        options={{
          title: {
            display: false
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  min: 1,
                  max: 5,
                  stepSize: 1
                }
              }
            ],
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'day'
                }
              }
            ]
          }
        }}
      />
    </div>
  );
};

Chart.propTypes = {
  updateAnswers: PropTypes.array.isRequired
};

export default Chart;
