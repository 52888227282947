import React from 'react';
import PropTypes from 'prop-types';
import {
  LineChart, Line, XAxis, YAxis,
  CartesianGrid, Tooltip, Legend,
  ResponsiveContainer,
  Area,
} from 'recharts';
import moment from 'moment';

const ChartLineOkrDetails = ({ items }) => {
  
  let series = [];

  // Get unique objectives from all metrics
  const uniqueArr =  [...new Set(items.map(item => item.okr))];
  const colors = ['#404ff5', '#9740e5', '#e9ad25', '#25e92b', '#c6c6ff', '#7025e9', '#deaadf', '#aea90d', '#7f0dae', '#6ce7f4'];

  // Prepare data to chart for each unique
  uniqueArr.forEach((u, index) => {

    let okrs = [];
    let name = '';

    // Gather all metrics by each objective
    // Отправляем в формате unix, чтобы она отображалась корректно на оси Х 
    items.forEach(i => {
      if (i.okr === u) {
        let time = new Date(i.date).getTime();

        // Data for chart
        let obj = { date: time, value: i.progress };
        okrs.unshift(obj);

        name = i.text;
      }
    })

    // Format data for chart if we have metrics
    if (okrs.length > 0) {

      let item = { name: name, data: okrs, color: index <= 9 ? colors[index] : colors[index - 10] };

      series.push(item);    
    }
  })
  
  const data = [{ name: "test", okr: 0 }, { name: "test", okr: 100 } ]

  const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="custom-tooltip bg-white p-2 br-6">
        <p className="label bold">{formatXAxis(label)}</p>
        {payload && payload.length > 0 && (
          payload.map(p => (
            <p key={`${p.name}-${p.stroke}`} className="label medium" style={{ color: p.stroke }}>{`${p.name}: ${p.value}`}
          </p> 
        )))}
      </div>
    );
  }

  return null;
};

// format Date
const formatXAxis = tickItem => {
  return moment(tickItem).format('D MMM');
}
  
  return (
    <div className="chart chart-updates">
      <ResponsiveContainer width = '100%' height = {250} >
        <LineChart width={500} height={250}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" type="number" domain={['dataMin', 'dataMax']} tickFormatter={formatXAxis} />
          <YAxis dataKey="value" />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {series.map(s => (
            <Line dataKey="value" data={s.data} name={s.name} key={s.name} stroke={s.color} activeDot={{ r: 8 }} type="monotone" />
          ))}
          <Area type="monotone" data={data} dataKey="value" stroke="#82ca9d" fill="#82ca9d" />
        </LineChart>
       </ResponsiveContainer>
    </div>
  );
};

ChartLineOkrDetails.propTypes = {
  items: PropTypes.array
};

export default ChartLineOkrDetails;
