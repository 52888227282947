import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Menu from '../layout/Menu';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import EmailCardItem from './EmailCardItem';
import { getReachEmailData, deleteCampaign, deleteEmail } from '../../actions/outreachEmail';
import { setAlert } from '../../actions/alert';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/1.jpg';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    backgroundColor: '#fcfcfd',
    fontSize: '14px',
  }),
  control: styles => ({ ...styles, backgroundColor: '#fcfcfd', padding: '4px 8px' }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
}

const OutreachEmail = ({
  getReachEmailData,
  deleteCampaign,
  deleteEmail,
  auth: { user },
  outreachEmail: { campaigns, loading },
  locale,
}) => {
  const [formData, setFormData] = useState({
    team: '',
    state: []
  });

  let { team, state } = formData;
  let campaignFilterOptions = [];

  // Перебор campaigns и приведение данных к формату Селекта
  {
    campaigns && campaigns.length > 0 &&
      campaigns.forEach((campaign, index) => {
      
        let obj = {
          value: index,
          label: `${campaign.name}`,
          name: `${campaign.name}`,
          id: campaign._id
        };

        campaignFilterOptions.push(obj);
      });
  }

  let choosedCampaign = campaigns.length > 0 ? campaignFilterOptions[0] : [];

  useEffect(() => {
    getReachEmailData();

    setFormData({
      team: !campaigns ? '' : choosedCampaign,
      state: campaigns.length === 0 ? [] : campaigns.filter(item => item._id.toString() === choosedCampaign.id.toString()),
    });

  }, [getReachEmailData, deleteCampaign, loading, deleteEmail, user && choosedCampaign.length > 0 && choosedCampaign.value.length]);
  
  const handleTeam = selectedOption => {
    let newItems;

    newItems = campaigns.filter(item => item._id.toString() === selectedOption.id.toString());

    setFormData({ 
      ...formData, 
      team: selectedOption, 
      state: newItems
    });
  };

  const handleDelete = async (campaignId) => {
    try {
      const res = await deleteCampaign(campaignId);

      setFormData({ 
        ...formData, 
        state: formData.state.filter(item => item._id !== res.campaignId)
      }); 
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in handleDelete', err);
    }
  };

  const createSequenceMsg = {
    title: (
      <FormattedMessage
        id="outreach.createSequence.title"
        defaultMessage="No Campaigns"
      />
    ),
    msg: (
      <FormattedMessage
        id="outreach.createSequence.msg"
        defaultMessage={`Conquer the world! Create the first campaign.`}
      />
    )
  };

  return (
    <Fragment>
      <Fragment>
        <div className="css-main bg-primary">
          <div className="wrapper">

          {user && user.department ? (
            <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user && user.isAdmin && true} outreach={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={100} />
          ) : (
            <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} outreach={user && user.isAdmin && user.role === 'Superadmin' && true} activeIndex={100} />
          )}
          <UserNav />
            <Fragment>
              {loading || !user || user && user.role !== 'Superadmin' ? (
                <Spinner />
              ) : (
                <Fragment>
                  <div className="main main-dashboard">
                    
                    <div className="okrs">

                      <h5 className="pl-0-xs">
                        <FormattedMessage id="you.header.outreach.email" defaultMessage="Outreacher: Email Campaigns" />
                      </h5>

                      <div className="flex space-between align-center my-3">
                        <FormattedMessage
                          id="checkins.sub_menu.teamSelect"
                          defaultMessage="Choose the campaign"
                        >
                          {msg => (
                            <Select
                              className="small-input w-30-l-50-xs"
                              defaultValue={choosedCampaign}
                              value={team}
                              onChange={selectedOption =>
                                handleTeam(selectedOption)
                              }
                              options={campaignFilterOptions}
                              isSearchable
                              placeholder={msg}
                              required
                              name="campaign"
                              styles={customStyles}
                            />
                          )}
                        </FormattedMessage>

                          <p
                            className="small mr-2 cursor mla"
                            onClick={() => { if (window.confirm('Are you sure you wish to delete this email?')) handleDelete(state[0]._id) } }
                          >
                            <FormattedMessage
                              id="outreach.delete.campaign"
                              defaultMessage="Delete Campaign"
                            />
                          </p>

                        <Link to={`/outreacher/add/${state.length > 0 && state[0]._id}`} className="btn btn-light">
                          <FormattedMessage
                            id="outreach.create.email"
                            defaultMessage="+ Add Email"
                          />
                        </Link>

                       <Link to="/outreacher/campaigns/new" className="btn btn-light">
                          <FormattedMessage
                            id="outreach.create.campaign"
                            defaultMessage="Create New Campaign"
                          />
                        </Link>
                      </div>
                               
                      {campaigns.length === 0 ? (
                        <NoContent 
                          message={createSequenceMsg}
                          picture={updateImg}
                          alt={'Create a campaign'}
                        />
                      ) : (
                        <Fragment>
                          {state.length > 0 && state[0].emails.map((email, index) => (
                              <div className="component my-2 py-3 px-1 light-shadow" key={`email-item-${email._id}`}>
                                <div className="cards py-2">
                                  <EmailCardItem
                                    key={email._id}
                                    email={email}
                                    campaignId={state && state[0]._id}
                                    user={user}
                                    deleteEmail={deleteEmail}
                                    owner={state && state[0].user}
                                    formDataParent={formData}
                                    setFormDataParent={setFormData}
                                    index={index + 1}
                                />
                              </div>
                            </div>
                            ))}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </Fragment>
              )}
            </Fragment>
          </div>
        </div>
      </Fragment>
    </Fragment>
  );
};

OutreachEmail.propTypes = {
  getReachEmailData: PropTypes.func.isRequired,
  deleteCampaign: PropTypes.func.isRequired,
  deleteEmail: PropTypes.func.isRequired,
  outreachEmail: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  locale: state.locale,
  outreachEmail: state.outreachEmail,
});

export default connect(
  mapStateToProps,
  { getReachEmailData, deleteCampaign, deleteEmail }
)(OutreachEmail);
