import React, { Fragment } from "react"
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import MainMenu from '../layout/Sidebar';
import UserNav from "../layout/UserNav";

import ShortlistImg from '../../img/sister_companies/shortlist.png';
import CaptainCodeImg from '../../img/sister_companies/captaincode.png';
import InspirarImg from '../../img/sister_companies/inspirar.png';
import BackgroundImg from '../../img/sister_companies/background.png';

const SisterCompanies = ({
  auth: { user, sidebar },
}) => {
  return (
    <Fragment>
      <div className="css-main bg-sister css-main__sister">
        <div className="wrapper">
          {user && <MainMenu activeIndex={15} />}
          <UserNav background="bg-transparent" />
          <Fragment>
            <div className={`${sidebar === 1 ? 'ml-280 main-dashboard' : 'short_menu__ml__okrs'} mt-3`} style={{ backgroundImage: BackgroundImg}}>
              <h5 className="pl-0-xs page-title text-center">
                <FormattedMessage id="sisters.header" defaultMessage="Focus Sister Companies" />
              </h5>
              <div className="subtitle text-center">
                <FormattedMessage
                  id="sisters.subheader"
                  defaultMessage="We've partnered with these lovely companies, whose services we trust, recommend, and in some cases have negotiated deals."
                />
              </div>
              <div className="flex justify-center wrap align-start">
                <div className="sister-card">
                  <img src={ShortlistImg} alt="Shortlist" />
                  <div className="title">
                    <FormattedMessage id="sisters.shorlist.title" defaultMessage="Shortlist.io - Link Building & SEO" />
                  </div>
                  <div className="description">
                    <FormattedMessage id="sisters.shorlist.description" defaultMessage="Shortlist is a talented, international team of digital marketers, developers, and designers. Shortlist team can help you develop a reputable backlink profile or build a SEO-Friendly website." />
                  </div>
                  <a href="https://shortlist.io" className="btn__secondary_green s" target="_blank" rel="noreferrer">
                    <FormattedMessage id="sisters.shorlist.button" defaultMessage="Get 10% Off" />
                  </a>
                </div>
                <div className="sister-card">
                  <img src={InspirarImg} alt="Inspirar" />
                  <div className="title">
                    <FormattedMessage id="sisters.inspirar.title" defaultMessage="Inspirar.io - Employee Engagement" />
                  </div>
                  <div className="description">
                    <FormattedMessage id="sisters.inspirar.description" defaultMessage="Inspirar bot is your 24/7 HR assistant, offering employees an outlet to give anonymous suggestions, exchange recognitions, celebrate birthdays & work anniversaries, engage in watercooler chat and respond to pulse surveys. Complete with admin dashboard and analytics, let Inspirar bot improve retention and foster positivity!" />
                  </div>
                  <a href="https://inspirar.io" className="btn__secondary_green s" target="_blank" rel="noreferrer">
                    <FormattedMessage id="sisters.inspirar.button" defaultMessage="Get 50% off!" />
                  </a>
                </div>
                <div className="sister-card">
                  <img src={CaptainCodeImg} alt="CaptainCode" />
                  <div className="title">
                    <FormattedMessage id="sisters.captaincode.title" defaultMessage="CaptainCode.io - Outsourced Development" />
                  </div>
                  <div className="description">
                    <FormattedMessage id="sisters.captaincode.description" defaultMessage="CaptainCode is your one-stop shop for all things development, including Frontend, Backend, and UIUX design. They have also assisted in developing Focus." />
                  </div>
                  <a href="https://www.captaincode.io" className="btn__secondary_green s" target="_blank" rel="noreferrer">
                    <FormattedMessage id="sisters.captaincode.button" defaultMessage="FIND OUT MORE" />
                  </a>
                </div>
              </div>
            </div>
            </Fragment>
        </div>
      </div>
    </Fragment>
  );
}

SisterCompanies.propTypes = {
    auth: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (state) => ({
    auth: state.auth,
  });
  
  export default connect(mapStateToProps)(SisterCompanies);
