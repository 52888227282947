import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const UniversalWhiteBtn = ({ toggleUpdateMode, update, }) => {
  return (
    <Fragment>
      <button 
        type="button" 
        className="btn btn-small btn-small-grey mr"
        onClick={() => toggleUpdateMode(!update)}
      >
        <FormattedMessage
          id="okr.form.cancel"
          defaultMessage="Cancel"
        />
      </button>
    </Fragment>
  );
};

UniversalWhiteBtn.propTypes = {
  toggleUpdateMode: PropTypes.func.isRequired,
  update: PropTypes.bool,
};

export default UniversalWhiteBtn;
