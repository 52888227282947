import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import UserNav from '../layout/UserNav';
import Logo from '../elems/Logo';
import { addPlanAnswer, getUserPlanAnswer } from '../../actions/planAnswer';
import { getPlanSettings } from '../../actions/plan';
import { getCompanyProfiles } from '../../actions/profile';
import { addRecognition } from '../../actions/recognition';
import { getAllUserOkrs, updateManyOkrs } from '../../actions/okr';
import { setAlert } from '../../actions/alert';
import Spinner from '../layout/Spinner';
import OneSelect from '../oneOnOne/elems/OneSelect';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';
import TextQuestionBody from '../customAnswers/CustomFormTextQuestionBody';

let iteration = 0;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    fontSize: '14px',
  }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
}

const PlanForm = ({
  setAlert,
  getCompanyProfiles,
  profile: { profiles },
  addPlanAnswer,
  getPlanSettings,
  plan: { settings, loading },
  planAnswer: { planAnswer },
  okr: { okrs },
  match,
  history,
  locale,
  getAllUserOkrs,
  getUserPlanAnswer,
  updateManyOkrs,
}) => {
  const [formData, setFormData] = useState({
    answers: [],
    text: '',
    value: '',
    selectedOption: [],
    focus: '',
    previousItemAnswers: [],
    notes: '',
    okrQuestion: {},
    weeklyOkrs: [],
  });

  let { 
    answers, 
    focus, previousItemAnswers, notes, okrQuestion,
    weeklyOkrs,
   } = formData;

  const [isLoader, toggleLoader] = useState(false);
  const [errorIndex, setErrorIndex] = useState(null); 

  let previousItemAnswersArr = [];
  const today = new Date();

  // By default we have plan in the first question (0 index), but Inspectrum asks it in the third questions (index 2) => 
  // So, we change index for the Inspectrum to take plans for advice in the form
  let questionIndex = 0;

  // Инспектрум 
  // Inspectrum id === 60dd2dedb3bee50109e45fa2
  if (planAnswer && planAnswer.answers.length > 0 && planAnswer.company === "60dd2dedb3bee50109e45fa2") {
    questionIndex = 2;
  } 

  // Get right answers for different types of campaign: answers.text or answers.okrAnswers
  let answersArrRaw = settings && planAnswer && planAnswer.answers.length > 0 ? (settings.campaignType === 1 || settings.campaignType === undefined ? (planAnswer.answers[questionIndex].text) : (planAnswer.answers[questionIndex].okrAnswers)) : [];
  
  {
    answersArrRaw.forEach(answer => {
      let obj = {};

      if (settings.campaignType === 1 || settings.campaignType === undefined) {
        // Classic type
        obj = {
          text: answer,
          completeOne: false,
          completeTwo: false
        }
        
        previousItemAnswersArr.push(obj);
      } else {
        // OKR-based type

        for (let i = 0; i < answer.text.length; i ++) {
          obj = {
            text: answer.text[i],
            isCompleted: answer.isCompleted,
            okrId: answer.okrValue,
            completeOne: false,
            completeTwo: false
          }

          previousItemAnswersArr.push(obj);
        }
      }
    })
  }
  
  let answerArr = [];
  // Formatting answers if it's edit form
  if (settings && planAnswer && settings.planID === planAnswer.planID && planAnswer.answers.length > 0) {
    planAnswer.answers.forEach((answer, index) => {

      let arr = [];

      if (settings.campaignType === 1 || settings.campaignType === undefined) {
        // Classic campaign
        answerArr.push(answer.text);
      } else {
        // OKR-based
        for (let i = 0; i < answer.okrAnswers.length; i ++) {
          let item = answer.okrAnswers[i].text;
         
          arr.push(item);
        }

        answerArr.push(arr);
      }
    })
  }

  const getAnswers = () => {
    try {
      let answers = (settings.planID === planAnswer.planID && planAnswer.answers) ? answerArr : [];
    
      if (settings.questions.length !== 0 && settings.questions.length !== answers.length) {
        settings.questions.forEach((q, i) => {

          let text = [""];
        
          // If it's OKR-based campaign or not
          if (settings.campaignType === 1 || settings.campaignType === undefined) {
            // Classic
            answers.push(text);
          } else {
            // OKR-based
            if (i === 0) answers.push([text]);
            if (i > 0) answers.push(text);
          }
        });
      }

      return answers;
    } catch (err) {
      console.log('Error in get answers', err);
    }
  };

  // Вот исправляет баг первого плана, когда нет еще ответов и он null
  // в этом случае не появляются инпуты, так как useEffect работает только 1 раз и не строится правильный answers
  if (planAnswer === null || planAnswer === undefined) { planAnswer = [] };

  useEffect(() => {
    // console.log('======= USEEFFECT 1 WORKS')

    if (iteration === 0) {
      getPlanSettings();
      getUserPlanAnswer();
      getCompanyProfiles();
      getAllUserOkrs();
    }

    setFormData({
      ...formData,
      previousItemAnswers: previousItemAnswers.length === 0 ? previousItemAnswersArr : previousItemAnswers,
      okrQuestion: !settings ? { isActive: false } : settings.okrQuestion,  
      answers: settings && planAnswer && answers.length === 0 ? getAnswers() : answers,
      focus: settings && planAnswer && settings.planID === planAnswer.planID && planAnswer.focus ? planAnswer.focus : '',
      notes: settings && planAnswer && settings.planID === planAnswer.planID && planAnswer.notes ? planAnswer.notes : '',
      weeklyOkrs: settings && planAnswer && settings.planID === planAnswer.planID && planAnswer.weeklyOkrs && planAnswer.weeklyOkrs.length > 0 ? planAnswer.weeklyOkrs : [],
    });

    iteration += 1;

  }, [
    settings && settings.questions.length,
    getPlanSettings, getUserPlanAnswer, getCompanyProfiles,
    match.params.id, getAllUserOkrs,
    previousItemAnswersArr.length, okrs.length
  ]);

  let options = [];

  {
    profiles &&
      profiles.forEach(profile => {
        let coworker = {
          value: profile._id,
          label: `${profile.firstName} ${profile.lastName}`
        };

        options.push(coworker);
      });
  }

  let okrOptions = [];

  let noOkrMsg = locale && locale.lang !== 'ru' ? `Not connected to OKR` : `Не связанно с OKR`; 
  {
    okrs && okrs.length > 0 &&
      okrs.forEach((okr, i) => {

        if (i === 0) {
          let noOkr = { value: 0, okr: 0, label: noOkrMsg, isFocus: false }
          okrOptions.push(noOkr);
        }

        if (!okr.isArchive) {
          let objective = { 
            value: okr._id, 
            label: `🎯 ${okr.objective}`, 
            okr: okr._id, 
            objective: okr.objective, 
            isFocus: true, 
            text: okr.objective, 
            focusIndex: 99, 
            status: okr.status, 
            progress: okr.progress, 
            goal: 100,
            name: 'weeklyOkrs',
           };
          
          okrOptions.push(objective);

          let krs = okr.keyResults;

          if (krs.length > 0) {
            krs.forEach((kr, index) => {
              let item = { 
                value: kr._id, label: ` - ${kr.title}`, 
                okr: okr._id, 
                objective: okr.objective, 
                isFocus: true, text: kr.title, 
                focusIndex: index, 
                status: okr.status, 
                progress: kr.progress, 
                goal: kr.goal, 
                current: kr.current, 
                krId: kr._id, 
                isKr: true, 
                name: 'weeklyOkrs',
              };
          
              okrOptions.push(item);
            })
          }
        }
      });
  } 

  // const handleChange = selectedOption => {
  //   setFormData({ ...formData, selectedOption: selectedOption });
  // };

  const onChangeAnswer = (e, index, i, y) => {
   
    if (settings.campaignType === 1 || settings.campaignType === undefined) {
      // Classic campaign
      // Add new input
      if (answers[index][i].length === 0 && answers[index].length === i + 1) {

        answers[index].push("");
      }

      answers[index][i] = e.target.value;
    } else if (settings.campaignType === 2 && index === 0) {
      // OKR-based: okr question
      if (answers[index][i][y].length === 0 && answers[index][i].length === y + 1) {

        answers[index][i].push("");
      }

      answers[index][i][y] = e.target.value;
    } else if (settings.campaignType === 2 && index >= 1) {
      // OKR-based: #1 thing or another question
      // only 1 input
      if (answers[index][i].length === 0 && answers[index].length === i + 1) {

        answers[index].push("");
      }
      
      answers[index][i] = e.target.value;
    }

    setFormData({ ...formData, answers: answers });
  };

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFocus = selectedOption => {

    setFormData({ ...formData, focus: selectedOption });
  };

  const handleAdd = (index) => {
    
    answers[index].push("");

    setFormData({ ...formData, answers: answers });
  };


  const handleOkrAdd = () => {    
    weeklyOkrs.push({});
   
    answers[0].push(['']);

    setFormData({ ...formData, weeklyOkrs: weeklyOkrs, answers });
  };

  const handleOkrSelect = (selectedOption, index) => {

    weeklyOkrs[index] = selectedOption;
    
    setFormData({ ...formData, weeklyOkrs, 
    });
  };

  const onSubmit2 = async e => {
    try {
      e.preventDefault();
      let arr = [];
      let arr2 = [];
    
      // Show Loader
      toggleLoader(isLoader);
  
      for (let i = 0; i < answers.length; i ++) {
  
        // Check if it's empty and only 1 input
        if (answers[i].length === 1 && answers[i][0].length === 0) {
  
          // Hide Loader
          toggleLoader(!isLoader);
  
          return setAlert(
            <FormattedMessage
              id="form.input.empty.plan"
              defaultMessage="Please fill out the field of Weekly Plan"
            />,
            'danger'
          );
        } else {
          // Remove empty element from answers
          // Возвращаем в новые массивы arr значения ответы БЕЗ пустых ячеек
          // Специально не меняем answers для этого. Иначе если с бекенда придет ошибка, то эти пустые поля останутся и при повторном вводе в них будет ошибка, тк в стейте answers они будут удалены. 
          // Альтернатива - скрыть их на клиенте, но тогда не будет возможности ввести при повторе новое поле. Поэтому добавляем новый arr2, куда сохраняем ответы без пустых строк и отправляем на сервер.
          // answers[0] - добавил, чтобы оценка для графика заходила сюда и записывалась в arr2 (у нее длина всегда 1)
          if (answers[0] || answers[i][answers[i].length - 1].length === 0) {
  
            if (settings.campaignType === 1 || settings.campaignType === undefined) {
              arr = answers[i].filter(item => item.length > 0);
              
              arr2.push(arr);
  
            } else {
              // OKR-based
              if (i === 0) {
                for (let z = 0; z < answers[i].length; z++) {
                  arr = answers[i][z].filter(item => item.length > 0);
                  
                  if (i === 0 && z === 0) {
                    arr2.push([arr]);
                  } else if (i === 0 && z !== 0) {
                    arr2[0].push(arr);
                  }
                }
              } else {
                 arr = answers[i].filter(item => item.length > 0);
                 
                 arr2.push(arr);
              }
            }
  
            if (settings.campaignType === 1 && arr.length === 0 || settings.campaignType === undefined && arr.length === 0) {
              // Hide Loader
              toggleLoader(!isLoader);
  
              return setAlert(
                <FormattedMessage
                  id="form.input.empty"
                  defaultMessage="Please fill out the field"
                />,
                'danger'
              );
            }
          }
        }
      }
  
      if (okrs && okrs.length > 0) {
        updateManyOkrs(okrs)
      }
  
      let planID = null;
  
      // Create new answer or update 
      // Check the last weekly plan
      if (planAnswer && settings.planID === planAnswer.planID) {
        planID = planAnswer.planID;
      }
      
      addPlanAnswer(arr2, focus, planID, okrQuestion, notes, planAnswer, weeklyOkrs, history);
  
      // Hide Loader
      toggleLoader(!isLoader);
    } catch (err) {

      // Hide Loader
      toggleLoader(!isLoader);
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    let arr = [];
    let arr2 = [];

    // Show Loader
    toggleLoader(isLoader);

    for (let i = 0; i < answers.length; i ++) {      
      // Check if it's empty and only 1 input
      if (answers[i].length === 1 && answers[i][0].length === 0) {
      
        // Hide Loader
        toggleLoader(isLoader);
        setErrorIndex(i);
      
        return setAlert(
          <FormattedMessage
            id="form.input.empty.plan"
            defaultMessage="Please fill out the field of Weekly Plan"
          />,
          'danger'
        );
      } else {
        // Remove empty element from answers
        // Возвращаем в новые массивы arr значения ответы БЕЗ пустых ячеек
        // Специально не меняем answers для этого. Иначе если с бекенда придет ошибка, то эти пустые поля останутся и при повторном вводе в них будет ошибка, тк в стейте answers они будут удалены. 
        // Альтернатива - скрыть их на клиенте, но тогда не будет возможности ввести при повторе новое поле. Поэтому добавляем новый arr2, куда сохраняем ответы без пустых строк и отправляем на сервер.
        // answers[0] - добавил, чтобы оценка для графика заходила сюда и записывалась в arr2 (у нее длина всегда 1)
        if (answers[0] || answers[i][answers[i].length - 1].length === 0) {

          if (settings.campaignType === 1 || settings.campaignType === undefined) {
            arr = answers[i].filter(item => item.length > 0);
            
            arr2.push(arr);
          } else {
            // OKR-based
            if (i === 0) {

              for (let z = 0; z < answers[i].length; z++) {
                arr = answers[i][z].filter(item => item.length > 0);
                
                if (i === 0 && z === 0) {
                  arr2.push([arr]);
                } else if (i === 0 && z !== 0) {
                  arr2[0].push(arr);
                }
              }
            } else {
               arr = answers[i].filter(item => item.length > 0);
               
               arr2.push(arr);
            }
          }

          if (settings.campaignType === 1 && arr.length === 0 || settings.campaignType === undefined && arr.length === 0) {
            // Hide Loader
            toggleLoader(!isLoader);
            setErrorIndex(i);

            return setAlert(
              <FormattedMessage
                id="form.input.empty"
                defaultMessage="Please fill out the field"
              />,
              'danger'
            );
          }
        }
      }
    }

    if (okrs && okrs.length > 0) {
      updateManyOkrs(okrs)
    }

    let planID = null;

    // Create new answer or update 
    // Check the last weekly plan
    if (planAnswer && settings.planID === planAnswer.planID) {
      planID = planAnswer.planID;
    }
    
    addPlanAnswer(arr2, focus, planID, okrQuestion, notes, planAnswer, weeklyOkrs, history);

    // Hide Loader
    toggleLoader(!isLoader);
  };

  const handleAnswer = (e, answer, index, i, y) => {
    e.preventDefault();

    // Toggle - complete or not 
    if (index === 0) {
      
      if (settings.campaignType === 1 || settings.campaignType === undefined) {
        // Classic campaign
        // Add or remove from the form
        if (answer.completeOne === false) {
          // Add answer to the form
          addAnswerToForm(answer, index, i);
        } else {

          // Remove from answers arr
          let removeIndex = answers[index].indexOf(answer.text);
         
          if (removeIndex > -1) {
            answers[index].splice(removeIndex, 1); 
          }
        }

        // Toggle
        answer.completeOne = !answer.completeOne;
      } else {
        // OKR-based campaign

        // Add or remove from the form
        if (answer.completeOne === false) {
          // Add answer to the form
          addAnswerToOkrForm(answer, index, i, y);
        } else {

          // Remove from answers arr
          let removeIndex = answers[index][i].indexOf(answer.text);

          if (removeIndex > -1) {
            answers[index][i].splice(removeIndex, 1); 
          }
        }

        // Toggle
        answer.completeOne = !answer.completeOne;
      }
    } else if (index === 1) {
      // Second question

      // Add or remove answer from the form
      if (answer.completeTwo === false) {
        // Add answer to the form
        addAnswerToForm(answer, index, i);
      } else {

        // Remove from answers arr
        let removeIndex = answers[index].indexOf(answer.text);

        if (removeIndex > -1) {
          answers[index].splice(removeIndex, 1); 
        }
      }

      // Toggle
      answer.completeTwo = !answer.completeTwo;
    }

    setFormData({ ...formData, answers: answers });
  };

  const addAnswerToForm = (answer, index, i) => {

    // Add new input
    if (answers[index][i] && answers[index][i].length === 0 && answers[index].length === i + 1) {

      answers[index].push("");
    }

    // если первое значение уже записано, то записываем в новый инпут
    if (answers[index][i] && answers[index][i].length === 0) {

      answers[index][i] = answer.text;
    } else {
      // если в текущий i уже записали руками ответ, то помещаем ответ в последний элемент
      // и создаем новый инпут
      answers[index][answers[index].length - 1] = answer.text;

      answers[index].push("");
    } 
  };

  // click in OKR-based campaign
  const addAnswerToOkrForm = (answer, index, i, y) => {
    // Add new input
    if (answers[index][i][y] && answers[index][i][y].length === 0 && answers[index][i].length === y + 1) {

      answers[index][i].push("");
    }

    // если первое значение уже записано, то записываем в новый инпут
    if (answers[index][i][y] && answers[index][i][y].length === 0) {
      answers[index][i][y].push(answer.text);
    } else {
      // если в текущий i уже записали руками ответ, то помещаем ответ в последний элемент
      // и создаем новый инпут
      if (answers[index][i][answers[index][i].length - 1].length === 0 ) {
        // Add answer to the empty existed input ('')
        answers[index][i][answers[index][i].length - 1] = answer.text;
      } else {
        // Add answer to the new input because the last element in array is already busy
        answers[index][i][answers[index][i].length] = answer.text;
      }

      answers[index][i].push("");
    } 
  };

  // Check the object length is not 0
  let okrQuestionLength = 0;
  if (okrQuestion !== undefined && okrQuestion !== null) {
    okrQuestionLength = Object.keys(okrQuestion).length;
  }

  const goBack = () => {
    history.goBack();
  }

  let addAnswer = <FormattedMessage id="add.answer.plus" defaultMessage="+ Add an answer" />;
  let addOkr = <FormattedMessage id="add.okr" defaultMessage="+ Add an OKR" />;

  // Loader for button
  let loadingIcon = (
    <span
      style={{
        width: '60px',
        height: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader type='Circles' color='#DAE3E5' height='18px' width='100' />
    </span>
  );

  let btnText = <FormattedMessage id='standup.form.submit' defaultMessage='Submit' />;

  if (isLoader) {
    btnText = loadingIcon;
  }

  const handleAnswerChange = (answerIndex, answerValue) => {
    setFormData((prev) => {
      const newFormData = { ...prev };
      newFormData.answers[answerIndex] = answerValue;
      return newFormData;
    });
  };

  // console.log('==== formData', formData)

  return (
    <Fragment>
      <div className="css-main bg-primary">
          <div className="wrapper">
            <div className="main_menu absolute"><Logo /></div>
            <UserNav />

            {loading ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="main mxa user-standups pl-0 pt-0-xs">
                  <div className="standups mt-3">
                    <div className="section-title">
                      <h5>
                        <FormattedMessage
                          id="plan.form.header"
                          defaultMessage="Complete Weekly Plan"
                        />
                      </h5>
                      <p
                        onClick={e => goBack()}
                        className="btn-secondary btn-secondary-back"
                      >
                        <FormattedMessage id="go.back" defaultMessage="Go Back" />
                      </p>
                    </div>

                    <div className="form-full form-updates mb-3">
                      <form
                        className="form-app form-app-updates"
                        onSubmit={e => onSubmit(e)}
                      >
                        <div className="component component-update standups-daily mt-2 p-3 light-shadow">
                          {settings ? (
                            <p className="sub-title focus-blue2 focus">
                              <FormattedMessage
                                id="updates.week.head"
                                defaultMessage="Week:"
                              />{' '}
                              <Moment format="D MMM">{today}</Moment>
                            </p>
                          ) : (
                            <p className="sub-title focus-blue2 focus">
                              <FormattedMessage
                                id="update.form.week"
                                defaultMessage="Week"
                              />
                            </p>
                          )}

                          <div className="py-2">
                            <p className="meduim">
                              <FormattedMessage
                                id="plan.form.subHeader"
                                defaultMessage="Let's answer some questions to create the weekly plan"
                              />
                            </p>
                            <div className="line" />
                          </div>

                          {settings ? (
                            <Fragment>
                              {settings.questions.map((question, index) => (
                                <Fragment key={`fragment-${index}`}>
                                <div className="form-main mb-0 mt-3" key={index}>
                                  <p className="bold medium block">{question}</p>
                                   <Fragment>
                                      {index === 0 || index === 1 ? (
                                        settings.campaignType === 1 || settings.campaignType === 2 && index === 1 || settings.campaignType === undefined ? (
                                          <Fragment>
                                            {previousItemAnswers !== undefined && previousItemAnswers.length > 0 && previousItemAnswers.map((answer, i) => (
                                              <div className="step-item mr-2 mb-2-xs cards__onboarding_body ml-4" key={`${answer.text}-${i}-${index}`}>
                                                <p
                                                  onClick={e => handleAnswer(e, answer, index, i)}
                                                  className={`inline medium card-subtgit itle todo__item cursor lh-30  ${index === 0 ? (!answer.completeOne ? '' : "todo__item-blue") : (index === 1 ? (!answer.completeTwo ? '' : "todo__item-blue") : '')}`}
                                                >
                                                  {answer.text}
                                                </p>
                                              </div>
                                            ))}
                                          </Fragment>
                                        ) : (
                                          <Fragment>
                                            {weeklyOkrs && weeklyOkrs.length > 0 && weeklyOkrs.map((okr, i) => (
                                              <Fragment>
                                                <OneSelect
                                                  defaultValue={okr}
                                                  onChange={handleOkrSelect}
                                                  options={okrOptions}
                                                  isMulti={false}
                                                  isSearchable={true}
                                                  styles={1}
                                                  selectType={3}
                                                  divStyles='w-350px fs1_4'
                                                  index={i}
                                                  containerStyles={'flex align-center mb-2'}
                                                  titleStyles={'mt-1 mr-2'}
                                                />
                                                {previousItemAnswers && previousItemAnswers.length > 0 && previousItemAnswers.map((answer, z) => (
                                                  <div className="step-item mr-2 mb-2-xs cards__onboarding_body ml-4" key={`${answer.text}-${z}-${i}`}>
                                                    <p
                                                      onClick={e => handleAnswer(e, answer, index, i, z)}
                                                      className={`inline medium card-subtitle todo__item cursor lh-30 ${index === 0 ? (!answer.completeOne ? '' : "todo__item-blue") : (index === 1 ? (!answer.completeTwo ? '' : "todo__item-blue") : '')}`}
                                                    >
                                                      {answer.text}
                                                    </p>
                                                  </div>
                                                ))}
                                              
                                                {answers !== undefined && answers.length > 0 && answers[0][i].map((answer, y) => (
                                                  <FormattedMessage
                                                    key={`answer-${y}`}
                                                    id="standup.form.comment"
                                                    defaultMessage="Add a comment"
                                                  >
                                                    {msg => (
                                                      <input
                                                        className="my-1"
                                                        type="text"
                                                        placeholder={msg}
                                                        name={y}
                                                        value={answers[0][i][y] || ''}
                                                        onChange={e => onChangeAnswer(e, 0, i, y)}
                                                      />
                                                    )}
                                                  </FormattedMessage>
                                                ))}
                                              </Fragment>
                                            ))}
                                          </Fragment>
                                        )
                                      ) : ("")}

                                    {settings.campaignType === 1 && answers[index] !== undefined  || settings.campaignType === undefined && answers[index] !== undefined  || 
                                    settings.campaignType === 2 && index >= 1 && index < answers.length && answers[index] !== undefined ? 
                                    (
                                      <div className="mt-2">
                                        <TextQuestionBody
                                          value={answers[index]}
                                          data={question}
                                          handleChange={(newValue) => {
                                            handleAnswerChange(index, newValue);
                                          }}
                                          tasks={[]}
                                          index={index}
                                          isHideTasks={true}
                                          iconStyles={'right-10'}
                                          errorIndex={errorIndex}
                                        />
                                      </div>
                                      
                                    ) : ("")}
                                    </Fragment>
                                </div>

                                  {
                                    answers.length > 0 && answerArr.length > 0 && answerArr[index] !== undefined && answers[index].length === answerArr[index].length && (
                                      <p
                                        key={`add-question-${index}`}
                                        className="grey medium grey cursor"
                                        onClick={() => handleAdd(index)}
                                      >
                                        {addAnswer}
                                    </p>
                                  )}

                                  {settings.campaignType === 2 && index === 0 ? (
                                    <p
                                      key={`add-okr-${index}`}
                                      className="w-200px btn-secondary btn-secondary-back my-2 block-wp"
                                      onClick={() => handleOkrAdd()}
                                    >
                                      {addOkr}
                                    </p>
                                  ) : ("")}
                                </Fragment>
                              ))}

                              {okrQuestionLength > 0 && okrQuestion.isActive && (
                                <div className="form-main mb-5 mt-3">
                                  <p className="bold medium block no-w label-pr3">
                                      {okrQuestion.text}
                                  </p>
                                  <FormattedMessage
                                    id="update.form.focus.question"
                                    defaultMessage="Choose the OKR you are working on next week"
                                  >
                                    {msg => (
                                      <Select
                                        className="small-input w-70-xs mb-2-xs"
                                        value={focus}
                                        onChange={selectedOption =>
                                          handleFocus(selectedOption)
                                        }
                                        options={okrOptions}
                                        isSearchable
                                        placeholder={msg}
                                        styles={customStyles}
                                      />
                                    )}
                                  </FormattedMessage>
                                </div>
                              )}

                              <div>
                                <div>
                                  <p className="bold medium inline mr-1">
                                    <FormattedMessage
                                      id="notes"
                                      defaultMessage="Notes"
                                    />
                                  </p>
                                  <span className="small grey">
                                    <FormattedMessage
                                      id="optional"
                                      defaultMessage="Optional"
                                    />
                                  </span>
                                </div>
                                <FormattedMessage
                                  id="standup.form.add.notes"
                                  defaultMessage="Add notes"
                                >
                                  {msg => (
                                    <textarea
                                      className="my-1 w-100"
                                      rows="3"
                                      type="text"
                                      placeholder={msg}
                                      name="notes"
                                      value={notes}
                                      onChange={e => onChange(e)}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>
                              
                            </Fragment>
                          ) : (
                            <Spinner />
                          )}
                        </div>

                        <button 
                          type="submit" 
                          className="btn btn-submit"
                          disabled={!isLoader ? false : true}
                        >
                          {btnText}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
        </div>
      </div>
    </Fragment>
  );
};

PlanForm.propTypes = {
  setAlert: PropTypes.func.isRequired,
  addRecognition: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  addPlanAnswer: PropTypes.func.isRequired,
  getPlanSettings: PropTypes.func.isRequired,
  getAllUserOkrs: PropTypes.func.isRequired,
  updateManyOkrs: PropTypes.func.isRequired,
  getUserPlanAnswer: PropTypes.func.isRequired,
  plan: PropTypes.object.isRequired,
  planAnswer: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  okr: PropTypes.object
};

const mapStateToProps = state => ({
  plan: state.plan,
  planAnswer: state.planAnswer,
  profile: state.profile,
  locale: state.locale,
  okr: state.okr
});

export default connect(
  mapStateToProps,
  {
    addPlanAnswer,
    getPlanSettings,
    getCompanyProfiles,
    addRecognition,
    setAlert,
    getAllUserOkrs,
    updateManyOkrs,
    getUserPlanAnswer,
  }
)(withRouter(PlanForm));
