import React from 'react';
import { FormattedMessage } from 'react-intl';

export const modal = {
  title: <FormattedMessage id="checkin.modal.title" defaultMessage="Create your first Check-in" />,
  text: <FormattedMessage id="checkin.modal.text" defaultMessage="Would you like to create your first Check-in?" />,
  btnTxt: <FormattedMessage id="checkin.modal.cta" defaultMessage="Create Check-in" />,
  cancelTxt: <FormattedMessage id="checkin.modal.cancel" defaultMessage="Not Now" />,
  btnLink: '/settings/campaign/new'
}

export const copy = {
  updatePlanMsg: {
    title: (
      <FormattedMessage
        id="company.updateplan.title.customs"
        defaultMessage="Upgrade plan and start running Check-ins."
      />
    ),
    msg: (
      <FormattedMessage
        id="custom.plans.planplan.msg.customs"
        defaultMessage={`Replace lengthy meetings with efficient online check-ins. Upgrade plan to create your first check-in.`}
      />
    ),
    btnTxt: (
      <FormattedMessage
          id="upgrade.plan"
          defaultMessage="Upgrade plan"
        />
    ),
  }, 
  liveMsg1: (
    <FormattedMessage
        id="campaign.live1"
        defaultMessage="Campaign is going on."
      />
  ),
  liveMsg2: (
    <FormattedMessage
        id="campaign.live2"
        defaultMessage="Click the button below to share your answer."
      />
  ),
  liveCta: (
    <FormattedMessage
        id="campaign.completeCta"
        defaultMessage="Share an answer"
      />
  ),
  checkinLiveNew: (
    <FormattedMessage
        id="custom.cta.complete.new"
        defaultMessage="Going on ! Show your team what you are going to work."
      />
  ),
}

export const copyAdmin = [
  {
    msg: <FormattedMessage id="custom1"  defaultMessage="You can create your custom check-ins campaigns here. After creating a campaign, users will get notifications according to the time you set. You will see all answers in the Check-ins & Plans menu. All statistics about this campaign will be in the Dashboard menu." />
  },
  {
    msg: <FormattedMessage id="custom2"  defaultMessage="Few things to know about custom campaigns:" />
  },
  {
    msg: <FormattedMessage
      id="custom3"
      defaultMessage="- <b>Name</b> - the name of the campaign. It's displayed in the sub-menu at the Check-ins & Plans menu. Also, people will receive a notification with the name of the campaign."
      values={{ b: (...chunks) => <b>{chunks}</b> }}
    />
  },
  {
    msg: <FormattedMessage id="custom4"  defaultMessage="- <b>Questions</b> - it's the questions you want to ask your team." values={{ b: (...chunks) => <b>{chunks}</b> }} />
  },
  {
    msg: <FormattedMessage id="custom5"  defaultMessage="- <b>Interval</b> - it shows how often you want to run this campaign. You can choose daily, weekly, biweekly, or monthly frequency." values={{ b: (...chunks) => <b>{chunks}</b> }} />
  },
  {
    msg: <FormattedMessage id="custom6"  defaultMessage="Let us know if you have other questions or suggestions via email."/>
  },
]

export const copyTitle = <FormattedMessage
        id="about.campaigns"
        defaultMessage="About Campaigns"
      />
  