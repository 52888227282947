import React, { Fragment, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkEmail } from '../../actions/auth';
import { setAlert } from '../../actions/alert';
import Navbar from '../layout/Navbar';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { FormattedMessage } from 'react-intl';

const Confirmation = ({ checkEmail, isAuthenticated, location, history, token, locale: { lang } }) => {

  const [confirmForm, toggleForm] = useState(false);

  useEffect(() => {
    const url = qs.parse(location.search);
    const confirmationCode = url.token;

    checkEmail(confirmationCode, history);

  }, [checkEmail]);

  useEffect(() => {
    if (token === 'confirm') {
      toggleForm(!confirmForm);
    }
  }, [token]);

  // Redirect if signed up
  if (isAuthenticated) {
    return <Redirect to="/objectives" />;
  }

  return (
    <Fragment>
      <section id="signup">
        <div className="container pt-5">
          <div className="section_header_new mb-2">
            <Navbar guest={true} />
            {!confirmForm ? (
              <h2 className="h2_new">
                <FormattedMessage
                  id="auth.confirmation.text1"
                  defaultMessage="Validating your account..."
                />
              </h2>
            ) : (
              <div>
                <h2 className="h2_new">
                  <FormattedMessage
                    id="auth.welcome.title"
                    defaultMessage="Welcome to Focus!"
                  />
                </h2>
                <p className="lead lead_new lh-22 mb-2">
                  <FormattedMessage
                    id="auth.welcome.text1"
                    defaultMessage="You're signing successfully."
                  />
                </p>
                <p className="lead lead_new lh-22">
                  <FormattedMessage
                    id="auth.welcome.text2"
                    defaultMessage="Please log into your account using your email and password."
                  />
                </p>

                <div className="block text-center">
                  <Link to="/login" className="btn btn_new btn-primary my-2">
                    <FormattedMessage id="auth.login.btn" defaultMessage="Login" />
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

Confirmation.propTypes = {
  setAlert: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired,
  location: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  token: PropTypes.string
};

const mapStateToProps = state => ({
  locale: state.locale,
  checkEmail: PropTypes.func.isRequired,
  isAuthenticated: state.auth.isAuthenticated,
  token: state.auth.token
});

export default connect(
  mapStateToProps,
  { setAlert, checkEmail }
)(Confirmation);
