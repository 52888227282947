import React from 'react';
import { FormattedMessage } from 'react-intl';

const UsersTableTitle = () => {
  return (
    <thead>
      <tr>
        <th className="small text-left fs-12 black bold pb-2 opacity-04">
          <FormattedMessage id="team.table.name" defaultMessage="Name" />
          {/* Name */}
        </th>
        <th className="small text-left fs-12 black bold pb-2 opacity-04">
          <FormattedMessage id="team.table.position" defaultMessage="Position" />
          {/* Position */}
        </th>
        <th className="small text-left fs-12 black bold pb-2 opacity-04">
          <FormattedMessage id="team.table.team" defaultMessage="Team" />
        </th>
        <th className="small text-left fs-12 black bold pb-2 opacity-04">
          <FormattedMessage id="role" defaultMessage="Role" />
        </th>
        <th className="small text-right fs-12 black bold pb-2 opacity-04">
          <FormattedMessage id="team.table.checkins" defaultMessage="Check-ins" />
          {/* Check-ins */}
        </th>
        <th className="small text-right fs-12 black bold pb-2 opacity-04">
          <FormattedMessage id="team.table.email" defaultMessage="Email" />
          {/* Email */}
        </th>

        <th className='pb-2'></th>
      </tr>
    </thead>
  );
};

export default UsersTableTitle;
