import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { editReachUser, deleteReachUser, toggleReachStatus } from '../../actions/reach';
import { icons } from '../../img/elements/icons';
import { FormattedMessage } from 'react-intl';

const OutreachRow = ({
  editReachUser,
  deleteReachUser,
  toggleReachStatus,
  auth,
  user: {
    _id,
    fullName,
    email,
    company,
    position,
    template,
    schedule,
    nextEmail,
    comment,
    isActive,
    source,
    website,
    article,
    jobPositions,
    vcRound,
    vcTime,
  }
}) => {

  const [displayUserAnswer, toggleAnswerButton] = useState(false);

  const additionalInfo = `Website: ${website ? website : '-'}, Article: ${article ? article.url : "-"}, Source: ${source ? source : "-"}`; 
  let status = isActive ? 'Active' : 'Disabled';  
  
  return (
    <Fragment>
      <tr>
        <td className="fs1_4">{fullName}</td>
        <td className="fs1_4 px-1">{email}</td>
        <td className="fs1_4">{company ? company : ""}</td>
        <td className="fs1_4">{position ? position : ""}</td>
        <td className="fs1_4">{template && template.name}</td>
        <td className="fs1_4">{nextEmail !== 5 ? nextEmail - 1 : nextEmail}</td>
        <td className="fs1_4"><Moment format="D MMM">{schedule && schedule.date}</Moment>,{" "}{schedule && schedule.time}</td>
        <td className="fs1_4">{comment ? comment : ""}</td>
        <td className="fs1_4">{additionalInfo}</td>
        <td className="fs1_4">{jobPositions}</td>
        <td className="fs1_4">{vcRound}</td>
        <td className="fs1_4">{vcTime}</td>
        <td className="fs1_4">{status}</td>
        <td className="fs1_4">
          <div className="card-icons card-icons__okr">
          <div className="btn-action no_btn ml-xs relative" type="button">
              {icons.more}

              <div className="dropdown p-1 light-shadow">
                <Link to={`/home`} className="small secondary-font">
                  <i className="fas fa-pencil-alt" />{' '} 
                  <FormattedMessage
                    id="user.okr.editContact"
                    defaultMessage="Edit Contact"
                  />
                </Link>
                  
                {auth.user && auth.user.role === "Superadmin" ? (
                  <Fragment>
                    <button 
                      type="button"
                      onClick={() => toggleReachStatus(_id)}
                      className="small secondary-font no_btn"
                    >
                      <i className="fas fa-pencil-alt" />{' '}
                      <FormattedMessage
                        id="switch.status"
                        defaultMessage="Switch active status"
                      />
                    </button>
                    <button 
                      type="button"
                      onClick={() => { if (window.confirm('Are you sure you wish to delete this contact?')) deleteReachUser(_id) } }
                      className="small secondary-font no_btn"
                    >
                      <i className="fas fa-times" />{' '}
                      <FormattedMessage
                        id="user.okr.deleteContact"
                        defaultMessage="Delete Contact"
                      />
                    </button>
                  </Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </td>
      </tr>
    </Fragment>
  );
};

OutreachRow.propTypes = {
  editReachUser: PropTypes.func.isRequired,
  deleteReachUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { deleteReachUser, editReachUser, toggleReachStatus })(OutreachRow);
