import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { addFeedback, getCompanyFeedback, clearFeedback} from '../../actions/feedback';
import { getCompanyProfiles } from '../../actions/profile';
import Spinner from '../layout/Spinner';
import UserNav from '../layout/UserNav';
import Logo from '../elems/Logo';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';

const FeedbackForm = ({
  addFeedback,
  clearFeedback,
  getCompanyFeedback,
  getCompanyProfiles,
  auth,
  profile: { profiles, loading },
  feedback,
  history,
  match,
}) => {
  const [formData, setFormData] = useState({
    text: '',
    value: '',
    currentUser: '',
    isPrivate: false,
  });

  const { text, value, currentUser, isPrivate } = formData;

  let options = [];

  {
    profiles &&
      profiles.forEach(profile => {
        let coworker = {
          value: profile._id,
          label: `${profile.firstName} ${profile.lastName}`,
          firstName: profile.firstName,
          name: 'currentUser',
        };

        options.push(coworker);
      });
  }

  useEffect(() => {
    getCompanyProfiles();
    getCompanyFeedback();

    setFormData({
      currentUser: !profiles && options.length === 0 ? {} : options.filter(item => item.value.toString() === match.params.id)[0],
    });
  }, [
     getCompanyFeedback, 
     JSON.stringify(profiles)
    ]);

  useEffect(() => {
    // Clear state
    return () => {
      clearFeedback();
    }
  }, []);

  const handleChange = selectedOption => {
    setFormData({ ...formData, [selectedOption.name]: selectedOption });
  };

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = e => {
    e.preventDefault();

    addFeedback(text, currentUser.value, isPrivate, history);
  };

  const goBack = () => {
    history.push(`/user/${match.params.id}/feedback`)
  };

  let subHeader = <FormattedMessage id="feedback.page.subHeader" defaultMessage="Give quality feedback that sparks creativity, puts out potential fires, and helps teammates show up as their best selves."/>
  let privateFeedbackTxt = <FormattedMessage id="private.feedback" defaultMessage="Private feedback"/>;
  let publicTitle = <FormattedMessage
    id="public.feedback.explanation"
    defaultMessage="<b>Public feedback:</b> everyone sees it"
    values={{ b: (...chunks) => <b>{chunks}</b> }}
  />;
  let privateTitle = <FormattedMessage
    id="private.feedback.explanation"
    defaultMessage="<b>Private feedback:</b> only you, the recipient, and the admin can see private feedback"
    values={{ b: (...chunks) => <b>{chunks}</b> }}
  />;
  let questionsToHelp1 = <FormattedMessage
    id="feedback.form.questions1"
    defaultMessage="You can write anything you want or share your thoughts on:"
  />;
  let questionsToHelp2 = <FormattedMessage
    id="feedback.form.questions2"
    defaultMessage={`One thing that ${currentUser && currentUser.label} does well`}
  />;
  let questionsToHelp3 = <FormattedMessage
    id="feedback.form.questions3"
    defaultMessage={`One thing that ${currentUser && currentUser.label} could continue to improve (and how)`}
  />;
  let questionsToHelp4= <FormattedMessage
    id="feedback.form.questions4"
    defaultMessage={`One thing that ${currentUser && currentUser.label} doesn't do, which you really wish ${currentUser && currentUser.firstName} would`}
  />;

  return (
    <Fragment>
      <div className="css-main bg-primary">
          <div className="wrapper">
            <div className="main_menu"><Logo /></div>
            <UserNav />

            {loading ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="main main-dashboard mxa user-standups pt-5 pt-0-xs">
                  <div className="standups mt-3">
                    <div className="section-title">
                      <h5>
                        <FormattedMessage
                          id="give.feedback.to"
                          defaultMessage="Give feedback to"
                        />{" "}{currentUser && currentUser.label}
                      </h5>
                      <p
                        onClick={e => goBack()}
                        className="btn-secondary btn-secondary-back"
                      >
                        <FormattedMessage id="go.back" defaultMessage="Go Back" />
                      </p>
                    </div>

                    <div className="form-full form-feedback mb-3">
                      <form
                        className="form-app form-app-feedback"
                        onSubmit={e => onSubmit(e)}
                      >
                        <div className="component standups-daily mt-2 p-3 light-shadow">
                          {!profiles ? (
                            <Spinner />
                          ) : (
                            <Fragment>
                              <div className="py-2">
                                <p className="meduim">
                                  {subHeader}
                                </p>
                                <div className="line" />
                                <p className="grey small pb-2">{questionsToHelp1}</p>
                                <p className="grey small">- {questionsToHelp2}</p>
                                <p className="grey small">- {questionsToHelp3}</p>
                                <p className="grey small">- {questionsToHelp4}</p>
                              </div>

                              <div className="form-main">
                                <p className="bold medium block">
                                  <FormattedMessage
                                    id="user.feedback.create.coworker"
                                    defaultMessage="Co-worker"
                                  />
                                </p>
                                <FormattedMessage
                                  id="user.feedback.create.coworkerSelect"
                                  defaultMessage="Choose the co-worker"
                                >
                                  {msg => (
                                    <Select
                                      value={currentUser}
                                      onChange={selectedOption =>
                                        handleChange(selectedOption)
                                      }
                                      options={options}
                                      isSearchable
                                      placeholder={msg}
                                      required
                                    />
                                  )}
                                </FormattedMessage>
                              </div>

                              <div className="form-main mb-1">
                                <p className="bold medium block">
                                  <FormattedMessage
                                    id="feedback"
                                    defaultMessage="Feedback"
                                  />
                                </p>

                                <FormattedMessage
                                  id="feedback.textInput"
                                  defaultMessage="Be specific and start your feedback"
                                >
                                  {msg => (
                                    <textarea
                                      rows="10"
                                      type="text"
                                      required
                                      placeholder={msg}
                                      name="text"
                                      value={text}
                                      onChange={e => onChange(e)}
                                    />
                                  )}
                                </FormattedMessage>
                              </div>

                              <div className="private-feeback">
                                <div className="form-main form-main-inline standups-time mt-3 flex align-center">
                                  <p className="bold medium question pb-0 mb-0">
                                    {privateFeedbackTxt}
                                  </p>

                                  <div className="checkbox status pl-3 w-30">
                                    <div className="checkbox-item active-status">
                                      <input
                                        type="checkbox"
                                        id="isPrivate"
                                        name="isPrivate"
                                        checked={isPrivate}
                                        value={isPrivate}
                                        onChange={e => {
                                          setFormData({
                                            ...formData,
                                            isPrivate: !isPrivate
                                          });
                                        }}
                                      />
                                      <label htmlFor="isPrivate">
                                        {isPrivate ? (
                                          <FormattedMessage
                                            id="private"
                                            defaultMessage="Status: Private"
                                          />
                                        ) : (
                                          <FormattedMessage
                                            id="public"
                                            defaultMessage="Status: Public"
                                          />
                                        )}
                                      </label>
                                    </div>
                                  </div>

                                    <div className="private-feedback__notice w-50">
                                      <p className="w-100 small grey">{publicTitle}.</p>
                                      <p className="w-100 small grey">{privateTitle}.</p>  
                                    </div>
                                    
                                </div>
                              </div>
                            </Fragment>
                          )}
                        </div>

                        <button type="submit" className="btn btn-submit">
                          <FormattedMessage
                            id="standup.form.submit"
                            defaultMessage="Submit"
                          />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
        </div>
      </div>
    </Fragment>
  );
};

FeedbackForm.propTypes = {
  getCompanyFeedback: PropTypes.func.isRequired,
  addFeedback: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  clearFeedback: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  feedback: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  feedback: state.feedback,
  profile: state.profile
});

export default connect(
  mapStateToProps,
  { getCompanyProfiles, addFeedback, getCompanyFeedback, clearFeedback }
)(withRouter(FeedbackForm));
