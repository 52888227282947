import React from "react";
import PropTypes from "prop-types";

const AddTodoBtn = ({ handleAdd, name, addMsg }) => {
  
  return (
   <div className="step-item mr-2 pt-1 px-1">
      <p
        className="medium grey cursor"
        onClick={e => handleAdd(e, name)}
      >
        +Add{" "}{addMsg}
      </p>
    </div>
  );
};

AddTodoBtn.propTypes = {
  addMsg: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleAdd: PropTypes.func,
};

export default AddTodoBtn;
