import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { FormattedMessage } from 'react-intl';
import RecognitionCardItem from './RecognitionCardItem';

const RecognitionItem = ({
  recognition: {
    text,
    value,
    date,
    likes,
    comments,
    avatar,
    giver,
    giverFirstName,
    giverLastName,
    recipientFirstName,
    recipientLastName,
    _id
  },
  formData,
  setFormData,
  home
}) => {
  return (
    <Fragment>
      <div className="component component-recognition my-2 p-3 light-shadow">
        <p className="sub-title focus-green focus">
          {!home ? (<Moment format="D MMM">{date}</Moment>) : (<FormattedMessage id="menu.kudos" defaultMessage="Kudos" />)}
        </p>

        <div className="cards py-2">
          <RecognitionCardItem
            key={_id}
            recognitionID={_id}
            text={text}
            value={value}
            giver={giver}
            giverAvatar={avatar}
            giverFirstName={giverFirstName}
            giverLastName={giverLastName}
            recipientFirstName={recipientFirstName}
            recipientLastName={recipientLastName}
            comments={comments}
            likes={likes}
            formData={formData}
            setFormData={setFormData}
          />
        </div>
      </div>
    </Fragment>
  );
};

RecognitionItem.propTypes = {
  recognition: PropTypes.object.isRequired
};

export default RecognitionItem;
