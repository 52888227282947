import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ExternalSecondBtn from '../elems/ExternalSecondBtn';
import SecondBtn from '../elems/SecondBtn';
import waiting from '../../img/empty/waiting.png';

const NoContent = ({
  message: { title, msg, msg2 },
  auth: { user, loading },
  picture,
  alt,
  admin,
  button,
  btnUrl,
  btnCta,
  externalLink,
  imgWidth,
}) => {

  let image;
  picture ? image = picture : image = waiting;

  let desc;
  alt ? desc = alt : desc = 'Gathering data';

  const updatePlan = <FormattedMessage id="plan.update.cta" defaultMessage="Update Plan" />;

  return (
    <div className="component my-2 p-3 light-shadow gather_data">
      {!loading && user ? (
        <Fragment>
          <p className="sub-title focus-yellow focus">{title}</p>

          <img src={image} className={`block mxa my-2 ${imgWidth && imgWidth}`} alt={desc} />

          <div className="py-2">
            <p className="meduim w-90">{msg}</p>
            {msg2 &&  (<p className="meduim w-90 mt-2">{msg2}</p>)}
            {admin === 1 && (
              <div className="text-center py-2">
                <SecondBtn 
                  link={'/settings/pricing'}
                  cta={updatePlan}
                />
              </div>
            )}
            {button === 1 && (
              <div className="text-center py-2">
                {externalLink !== 1 ? (
                  <SecondBtn link={btnUrl} cta={btnCta} />
                ) : (
                  <ExternalSecondBtn url={btnUrl} cta={btnCta} />
                )}
              </div>
            )}
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};

NoContent.propTypes = {
  auth: PropTypes.object.isRequired,
  alt: PropTypes.string
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(NoContent);
