import React from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ButtonWhite200 = ({ cta, url, isComplete }) => {

  return (
    <Link to={url} className={`btn-secondary btn-secondary-mix-white fs-14 mw-200 mr-0 pr-0 pl-0 ${isComplete && "grey"}`}>{cta}</Link>
  );
};

ButtonWhite200.propTypes = {
  cta: PropTypes.object,
  url: PropTypes.string,
  isComplete: PropTypes.bool
};

export default ButtonWhite200;
