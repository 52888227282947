import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '../layout/Menu';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import Widgets from '../widgets/Widgets';
import SubMenu from './SubMenu';
import RecognitionItem from '../recognitions/noState/RecognitionItem';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/1.jpg';
import {
  getUserRecognitionsById,
  getGivedUserRecognitionsById,
  clearKudos
} from '../../actions/recognition';
import { getCompanyProfiles, getProfileById } from '../../actions/profile';
import { getUserCompany } from '../../actions/company';
import { FormattedMessage } from 'react-intl';
import { kudosCopy } from '../../utils/wording/kudos';

const UserKudos = ({
  clearKudos,
  getProfileById,
  getUserRecognitionsById,
  getGivedUserRecognitionsById,
  getCompanyProfiles,
  getUserCompany,
  company: { company },
  auth: { user },
  profile: { profile, profiles },
  recognition: { recognitions, givedRecognitions, loading },
  match,
}) => {
  useEffect(() => {
    getUserCompany();
    getCompanyProfiles();
    getUserRecognitionsById(match.params.id);
    getGivedUserRecognitionsById(match.params.id);
    getProfileById(match.params.id);

    // Clear state
    return () => {
      clearKudos();
    }
  }, [getUserCompany, getCompanyProfiles, getUserRecognitionsById, getGivedUserRecognitionsById, getProfileById]);
  
  const [received, handleReceived] = useState(true);
  const [given, handleGiven] = useState(true);

  let titleLeft = <FormattedMessage id="widget.recognition.left.header" defaultMessage="Kudos received"/>
  let titleRight = <FormattedMessage id="widget.recognition.right.header" defaultMessage="Kudos given"/>

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {user && user.department ? (
          <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user && user.isAdmin && true} activeIndex={8} />
        ) : (
          <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} activeIndex={8} />
        )}
        <UserNav />
          <Fragment>
            {loading || !recognitions || !user || !company || !profile ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="main main-dashboard">
                     <h5>
                      <FormattedMessage id="company.header.kudos" defaultMessage={`${profile && profile.firstName}'s Kudos`} />
                    </h5>
                  <SubMenu userId={profile._id} companyIndex={4} />
                  <div className="kudos">
                    <div className="text-right pb-1">
                      <Link to="/kudos/new" className="btn btn-light">
                        <FormattedMessage
                          id="user.recognition.createBtn"
                          defaultMessage="+ Create Recognition"
                        />
                      </Link>
                    </div>

                    <div className="mt-2 mb-4">
                        <Widgets
                          leftColor={'green'}
                          rightColor={'red'}
                          receivedRecognitions={recognitions}
                          givedRecognitions={givedRecognitions}
                          received={received}
                          handleReceived={handleReceived}
                          given={given}
                          handleGiven={handleGiven}
                          titleLeft={titleLeft}
                          titleRight={titleRight}
                        />
                    </div>

                      {company.plan !== 1 ? (
                        <Fragment>
                          {recognitions.length === 0 && givedRecognitions ? (
                            <div className="mt-5">
                              <NoContent 
                                message={kudosCopy.noRecognitionMessage}
                                picture={updateImg}
                                alt={'No kudos'}
                              />
                            </div>
                          ) : (
                            <Fragment>
                              {recognitions.map(recognition => (
                                <RecognitionItem
                                  key={recognition._id}
                                  recognition={recognition}
                                />
                              ))}
                            </Fragment>
                          )}
                        </Fragment>
                      ) : (
                        <NoContent 
                          message={kudosCopy.updatePlanMsg}
                          picture={updateImg}
                          alt={'Update plan'}
                          admin={user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0}
                        />
                      )}
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

UserKudos.propTypes = {
  getCompanyProfiles: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getProfileById: PropTypes.func.isRequired,
  getUserRecognitionsById: PropTypes.func.isRequired,
  getGivedUserRecognitionsById: PropTypes.func.isRequired,
  clearKudos: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  recognition: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  recognition: state.recognition
});

export default connect(
  mapStateToProps,
  { getUserCompany, getCompanyProfiles, getUserRecognitionsById, getGivedUserRecognitionsById, getProfileById, clearKudos }
)(UserKudos);
