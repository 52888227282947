import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: "3px",
    marginBottom: "5px",
    borderBottomColor: '#bff0fd',
    backgroundColor: '#bff0fd',
    borderBottomWidth: 1,
    // height: 24,
    // textAlign: 'center',
    fontStyle: 'bold',
  },
  obj: {
    width: "150px",
  },
  w30: {
    width: "30px",
  },
  w50: {
    width: "50px",
  },
  w80: {
    width: "80px",
  },
  kr: {
    width: "200px",
  },
});

const TableHeader = ({ }) => {
  return (
    <View style={styles.row}>
      <Text style={styles.obj}>Objective</Text>
      <Text style={styles.w80}>Owner</Text>
      <Text style={styles.w80}>OKR's Team</Text>
      <Text style={styles.w50}>Status</Text>
      <Text style={styles.w50}>Progress</Text>
      <Text style={styles.kr}>Key Results</Text>
      <Text style={styles.w80}>Due Date</Text>
      <Text style={styles.w80}>Last update</Text>
    </View>
  );
};

export default TableHeader;