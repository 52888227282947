import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import Avatar from "../elems/Avatar";
import Moment from 'react-moment';

// function will determine average time given a set of 
// times in a 24 hr. period, i.e. 12am - 12pm
// it does NOT work for a 24 hr. period from 12pm - 12am
// var times = ['11:59:00 AM', '12:00:00 AM'];
// ≈.009Z

// function accepts an array of times as the argument
// requires time to be structured as above
function getAverageTime(times) {
    var count = times.length
    var timesInSeconds = [];
    let date = ''
    // loop through times
    for (var i = 0; i < count; i++) {
      date = times[i];
      let parsedTime = `${times[i].slice(11, 19)}`;
      // parse
      var pieces = parsedTime.split(':');
      var ampm = pieces[2].split(' ');
      var hrs = Number(pieces[0]);
      var mins = Number(pieces[1]);
      var secs = Number(ampm[0]);
      var ampm = ampm[1];
      // convert to 24 hr format (military time)
      if (ampm == 'PM') hrs = hrs + 12;   
      // find value in seconds of time
      var totalSecs = hrs * 60 * 60;
      totalSecs += mins * 60;
      totalSecs += secs;
      // add to array
      timesInSeconds[i] = totalSecs;
    }
    // find average timesInSeconds
    var total = 0;
    for (var j = 0; j < count; j++) {
        total = total + Number(timesInSeconds[j]);
    }
    var avg = Math.round(total / count);
    // turn seconds back into a time
    var avgMins = Math.floor(avg/60);
    var avgSecs = avg - (60 * avgMins);
    var avgHrs = Math.floor(avgMins/60);
    avgMins = avgMins - (60 * avgHrs);
    // convert back to 12 hr. format
    // var avgAmpm = 'AM';
    // if (avgHrs > 12) {
    //     avgAmpm = 'PM';
    //     avgHrs = avgHrs - 12;
    // }
    // add leading zeros for seconds, minutes
    avgHrs = avgHrs < 10 ? `0${avgHrs}` : avgHrs;
    avgSecs = ('0' + avgSecs).slice(-2);
    avgMins = ('0' + avgMins).slice(-2);

    return '2021-01-01T'+avgHrs+':'+avgMins+':'+avgSecs+'+0000'; // 2021-01-01T - привод к любой дате // +0000 - server's time
}

const TableLine = ({
  item: {
    _id,
    user,
    firstName,
    lastName,
    avatar,
  },
  getUserAnswersAmount,
  isUnfinishedUsers,
  campaigns,
  profiles, 
  linkName,
}) => {

  // Если передали answer сюда, то записываем user (так как там хранится)
  // а если передали юзера (приходит из тех, кто не ответил), то записываем _id
  let userId = !isUnfinishedUsers && user !== null ? user : _id;
  const answersAmount = getUserAnswersAmount(userId);
  const amount = answersAmount.length;
  const timeArr = answersAmount.map(i => i.date);
  let averageTime = getAverageTime(timeArr);
  let momentTime = <Moment local format="HH:mm:ss">{averageTime}</Moment>
  
  let profile = {};
  let notAnswered = 0;
  let allUserCampaigns = 0;
  let responseRate = 0;

  if (!isUnfinishedUsers && profiles && user !== null) {
    profile = profiles.filter(p => p._id.toString() === user.toString());
    profile = profile.length > 0 ? profile[0] : {};
    
    // Если юзер удален, то подставляем дату самого первого юзера (админа)
    // Неправильное, но быстрое решение
    // ToDo: находить в логах регистрацию юзера и брать эту дату
    if (isNaN(profile.date)) {
      profile.date = profiles[0].date && profiles[0].date;
    } 

    allUserCampaigns = campaigns && campaigns.filter(c => Date.parse(c.date) > Date.parse(profile.date))

    notAnswered = allUserCampaigns && allUserCampaigns.length > 0 ? allUserCampaigns.length - amount : 0;
    responseRate = Math.round(amount / allUserCampaigns.length * 100);

    if (isNaN(responseRate)) {
      responseRate = `0`;
    } else if (responseRate > 100) {
      responseRate = 100
    }

    if (notAnswered < 0) { notAnswered = 0; };
  }

  if (user === undefined) { user = _id };

  return (

    <Fragment>
      <div className="cards card-people">
        <div className="card-team">
            <div className="flex align-center">
              <Link to={`/user/${user}/${linkName}`} className={`w-220px flex align-center`}>
                <Avatar
                  id={user}
                  name={firstName}
                  lastName={lastName}
                  avatar={avatar}
                  noLink={true}
                  width={"26"}
                />

                <p className="comment-user medium">{firstName}{' '}{lastName}</p>
              </Link>

              <div className={`mla flex align-center`}>
              {
                  !isUnfinishedUsers && (
                    <Fragment>
                      <p className="w-80pxa mla fs-14 bold card-table card-table-employees text-right table_new__column">{allUserCampaigns.length}</p>
                    </Fragment>
                )}
                <p className={`${!isUnfinishedUsers ? 'w-80pxa' : 'w-150px'} mla fs-14 bold card-table card-table-employees text-right table_new__column`}>{amount}</p>
                {
                  !isUnfinishedUsers && (
                    <Fragment>
                      <p className="w-80pxa mla fs-14 bold card-table card-table-employees text-right table_new__column">{notAnswered}</p>
                      <p className="w-80pxa mla fs-14 bold card-table card-table-employees text-right table_new__column_130px">{responseRate}%</p>
                      <p className="w-80pxa mla fs-14 bold card-table card-table-employees text-right table_new__column_170px">{momentTime}
                      </p>
                    </Fragment>
                )}
              </div>
            </div>
          </div>
      </div>
    </Fragment>
  );
};

TableLine.propTypes = {
  item: PropTypes.object.isRequired,
  getUserAnswersAmount: PropTypes.func,
};

export default TableLine;
