import { combineReducers } from "redux";

import alert from "./alert";
import auth from "./auth";
import profile from "./profile";
import company from "./company";
import standup from "./standup";
import standupAnswer from "./standupAnswer";
import referral from "./referral";
import locale from "./locale";
import update from "./update";
import updateAnswer from "./updateAnswer";
import recognition from "./recognition";
import slack from "./slack";
import okr from "./okr";
import okrCall from "./okrcall";
import todo from "./todo";
import home from "./home";
import reach from "./reach";
import outreachEmail from "./outreachEmail";
import admin from "./admin";
import icebreaker from "./icebreaker";
import planAnswer from "./planAnswers"; 
import plan from "./plan";
import custom from "./custom"; 
import customAnswer from "./customAnswer"; 
import oneOnOne from "./oneOnOne"; 
import feedback from "./feedback"; 
import mood from "./mood"; 
import discord from "./discord"; 

export default combineReducers({
  alert,
  auth,
  profile,
  company,
  standup,
  standupAnswer,
  update,
  updateAnswer,
  plan,
  planAnswer,
  custom,
  customAnswer,
  okr,
  okrCall,
  todo,
  oneOnOne,
  recognition,
  slack,
  discord,
  home,
  icebreaker,
  feedback,
  ms: mood,
  locale,
  referral,
  reach,
  outreachEmail,
  data: admin,
});
