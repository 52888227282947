import React, { Fragment, useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MainMenu from '../../layout/Sidebar';
import UserNav from '../../layout/UserNav';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';
import { getCompanyAnswers, clearStandups } from '../../../actions/standupAnswer';
import { getCompanyCustomAndCheckinAnswers } from '../../../actions/customAnswer';
import { clearUpdates } from '../../../actions/updateAnswer';
import { clearOkrs } from '../../../actions/okr';
import { clearKudos } from '../../../actions/recognition';
import { getCompanyProfiles, deleteUser, switchTeamHead } from '../../../actions/profile';
import UsersTableTitle from './UsersTableTitle';
import UsersCard from './UsersCard';
import UsersCardEdit from './UsersCardEdit';
import { FormattedMessage } from 'react-intl';
import { createInvite } from '../../../actions/auth';
import useInvite from '../../invite/useInvite';
import { getUserCompany } from '../../../actions/company';
import { editCompanyMemberProfile } from '../../../actions/profile';
import NoContent from '../../utils/NoContentNew';
import updateImg from '../../../img/empty_states/invite.png';

const menuTitle = <FormattedMessage id="menu.users" defaultMessage="Teams & Members" />;
const subTitle = (
  <FormattedMessage id="company.teamsmembers.subtitle" defaultMessage="Manage your teams & team members." />
);

const Users = ({
  clearStandups,
  clearUpdates,
  clearOkrs,
  clearKudos,
  getCompanyProfiles,
  standupAnswer: { standupAnswers },
  getCompanyAnswers,
  auth: { user, sidebar },
  profile: { loading, profiles },
  company: { company },
  createInvite,
  locale: { lang },
  deleteUser,
  getUserCompany,
  editCompanyMemberProfile,
  switchTeamHead,
  getCompanyCustomAndCheckinAnswers,
  customAnswer
}) => {
  const [checkinsLoaded, setCheckinsLoaded] = useState(false);

  let customAnswers = customAnswer ? customAnswer.customAnswers : null;
  let customLoading = customAnswer ? customAnswer.loading : null;

  useEffect(() => {
    getCompanyProfiles();
    getCompanyAnswers({limit: 500});
    getCompanyCustomAndCheckinAnswers()

    // console.log('==== USEEFFECT 1 WORKS')

    // Clear states
    return () => {
      clearStandups();
      clearUpdates();
      clearOkrs();
      clearKudos();
    };
  }, []);

  useEffect(() => {
    // console.log('==== USEEFFECT 2 WORKS')
    if (!customLoading) {
      setCheckinsLoaded(true);
    }
   
  }, [customLoading]);

  const getUpdatedMembers = useCallback(() => {
    clearStandups();
    clearUpdates();
    clearOkrs();
    clearKudos();
    getCompanyProfiles();
    getCompanyAnswers();
  }, [getCompanyProfiles, getCompanyAnswers]);

  const [openInvite, InviteElem] = useInvite({ onSuccess: getUpdatedMembers });
  const [search, setSearch] = useState('');
  const [isEditMode, setEditMode] = useState(false);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const filteredProfiles = useMemo(() => {
    return profiles
      .filter((item) =>
        `${item.firstName} ${item.lastName} ${item.departmentName} ${item.email} ${item.role}`
          .toLowerCase()
          .includes(search.toLowerCase())
      )
      .sort((a, b) => {
        const aName = `${a.firstName} ${a.lastName}`;
        const bName = `${b.firstName} ${b.lastName}`;
        if (aName > bName) return 1;
        if (aName < bName) return -1;
        return 0;
      });
  }, [profiles, search]);

  const [membersChanges, setMembersChanges] = useState({});

  useEffect(() => {
    const initialState = filteredProfiles.reduce(
      (accum, item) => ({
        ...accum,
        [item._id]: { department: item.department, jobTitle: item.jobTitle, isChanged: false, role: item.role }
      }),
      {}
    );
    setMembersChanges(initialState);
  }, [filteredProfiles]);

  const openEditMode = () => {
    getUserCompany();
    setEditMode(true);
  };

  const cancelEditMode = () => {
    setEditMode(false);
    const initialState = filteredProfiles.reduce(
      (accum, item) => ({
        ...accum,
        [item._id]: { department: item.department, jobTitle: item.jobTitle, isChanged: false, role: item.role }
      }),
      {}
    );
    setMembersChanges(initialState);
  };

  const onMemberChange = useCallback(
    (memberId, fieldName, value) =>
      setMembersChanges((prev) => ({
        ...prev,
        [memberId]: { ...prev[memberId], [fieldName]: value, isChanged: true }
      })),
    [setMembersChanges]
  );

  const onMembersChangesSave = () => {
    for (let key in membersChanges) {
      if (membersChanges[key].isChanged)
        editCompanyMemberProfile(key, {
          department: membersChanges[key].department,
          jobTitle: membersChanges[key].jobTitle,
          role: membersChanges[key].role
        });
    }

    setEditMode(false);
  };

  const noTeams = {
    title: <FormattedMessage id="company.people.noteams.title" defaultMessage="Invite Team Members" />,
    msg: <FormattedMessage id="company.people.noteams.msg" defaultMessage="Invite Your Team Members and work together on reaching your OKRs."/>,
    onClick: openInvite,
    btnTxt: <FormattedMessage id="invite.team.members" defaultMessage="Invite Team Members" />,
  };

  // console.log('==== standupAnswers', standupAnswers)

  // For companies with standups answers, we count them as checkins. Else we count custom answers as checkins.
  let checkinAnswers = standupAnswers && standupAnswers.length < 50 ? customAnswers : standupAnswers;

  return (
    <Fragment>
      {InviteElem}
      <div className="css-main bg-primary css-main__dashboard overflow-x-auto">
        <div className="wrapper">
           {user && !loading && (
            <MainMenu activeIndex={8} />
          )}

          <UserNav />

          <Fragment>
            {loading || !filteredProfiles || !user ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className={`${sidebar === 1 ? 'ml-280 main main-dashboard main_new' : 'short_menu__ml__okrs ma-w90'} mt-3`}>
                  <div className="dashboard-admin">
                    <h5 className="pl-0-xs">{menuTitle}</h5>
                    <div className="flex space-between align-center mb-4">
                      <p className="medium block pb-0 mra">{subTitle}</p>
                      <FormattedMessage id="search.bar" defaultMessage="Search...">
                        {(msg) => (
                          <input
                            className="lh-34 question-input pl-2 br-4 brd brd-grey bg__white outline mr-1"
                            type="text"
                            placeholder={msg}
                            name="search"
                            value={search}
                            onChange={handleSearch}
                          />
                        )}
                      </FormattedMessage>

                      <div className="section-title justify-end">
                        <Link onClick={openInvite} className="btn fs-14 mr-0 py-1 px-15 ">
                          <FormattedMessage id="admin.people.invite" defaultMessage="Invite Team Member" />
                        </Link>
                      </div>
                    </div>

                    <div className="insights mb-3_5">
                      <div className="sub-menu__page mt-2">
                        <Link to={'/teams'} className={`medium sub-menu__item inline-block mr-2 cursor`}>
                          <FormattedMessage id="settings.workspace" defaultMessage={`Teams`} />
                        </Link>

                        <Link
                          to={'/people'}
                          className={`medium sub-menu__item inline-block mr-2 cursor sub-menu__item_active`}
                        >
                          <FormattedMessage id="settings.workspace" defaultMessage={`Members`} />
                        </Link>
                      </div>
                    </div>

                    {filteredProfiles.length === 0 || profiles.length === 1 ? (
                      <div className="mt-2">
                        <NoContent
                          message={noTeams}
                          picture={updateImg}
                          alt={'Create first team'}
                          imgWidth={'w-100 z-1000 -mt-okr__empty'}
                          btnUrl={noTeams.btnUrl}
                          btnCta={noTeams.btnCta}
                          divStyles="flex-column-reverse pt-3"
                          containerStyles="p-0"
                          isOnClick={true}
                        />
                      </div>
                    ) : (
                      <Fragment>
                        <div className="component border-gray br-4 my-3 p-4 pt-2">
                          {user && user.isAdmin && (
                            <div className="flex justify-end mb-2">
                              {isEditMode ? (
                                <>
                                  <button
                                    className=" btn btn-small medium mr-1 cursor ml-1 ml-0-xs btn__secondary_blue px-2 lh-34 fw-600 fs-14"
                                    onClick={onMembersChangesSave}
                                  >
                                    <FormattedMessage id="save" defaultMessage={'Save'} />
                                  </button>
                                  <button
                                    className=" btn btn-small medium mr-1 cursor ml-1 ml-0-xs btn__secondary_blue px-2 lh-34 fw-600 fs-14"
                                    onClick={cancelEditMode}
                                  >
                                    <FormattedMessage id="cancel" defaultMessage={'Cancel'} />
                                  </button>
                                </>
                              ) : (
                                <button
                                  className=" btn btn-small medium mr-1 cursor ml-1 ml-0-xs btn__secondary_blue px-2 lh-34 fw-600 fs-14"
                                  onClick={openEditMode}
                                >
                                  <FormattedMessage id="manage.members" defaultMessage={'Manage members'} />
                                </button>
                              )}
                            </div>
                          )}
                          <table>
                            <UsersTableTitle />

                            {filteredProfiles.map((profile, index) => (
                              <tbody key={`teamUser-${profile._id}-${index}`}>
                                {isEditMode ? (
                                  <UsersCardEdit
                                    user={profile}
                                    departments={company && company.departments ? company.departments : []}
                                    value={membersChanges[profile._id]}
                                    onChange={onMemberChange}
                                    standupAnswers={
                                      customAnswers &&
                                      customAnswers.filter(
                                        (answer) =>
                                          answer.user !== null &&
                                          answer.user.toString() === profile._id.toString()
                                      )
                                    }
                                    checkinsLoaded={checkinsLoaded}
                                    lang={lang}
                                    createInvite={createInvite}
                                    deleteUser={deleteUser}
                                    auth={user}
                                  />
                                ) : (
                                  <UsersCard
                                    user={profile}
                                    standupAnswers={
                                      checkinAnswers &&
                                      checkinAnswers.filter(
                                        (standupAnswer) =>
                                          standupAnswer.user !== null &&
                                          standupAnswer.user.toString() === profile._id.toString()
                                      )
                                    }
                                    // standupAnswers={
                                    //   standupAnswers && standupAnswers.length < 50 ?
                                    //   customAnswers &&
                                    //   customAnswers.filter(
                                    //     (standupAnswer) =>
                                    //       standupAnswer.user !== null &&
                                    //       standupAnswer.user.toString() === profile._id.toString()
                                    //   ) : standupAnswers &&
                                    //   standupAnswers.filter(
                                    //     (standupAnswer) =>
                                    //       standupAnswer.user !== null &&
                                    //       standupAnswer.user.toString() === profile._id.toString()
                                    //   )
                                    // }
                                    checkinsLoaded={checkinsLoaded}
                                    searchKeyword={search}
                                    lang={lang}
                                    createInvite={createInvite}
                                    deleteUser={deleteUser}
                                    auth={user}
                                    switchTeamHead={switchTeamHead}
                                  />
                                )}
                              </tbody>
                            ))}
                          </table>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

Users.propTypes = {
  clearStandups: PropTypes.func.isRequired,
  clearUpdates: PropTypes.func.isRequired,
  clearOkrs: PropTypes.func.isRequired,
  clearKudos: PropTypes.func.isRequired,
  getCompanyAnswers: PropTypes.func.isRequired,
  getCompanyCustomAndCheckinAnswers: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  standupAnswer: PropTypes.object.isRequired,
  createInvite: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  standupAnswer: state.standupAnswer,
  customAnswer: state.customAnswer,
  locale: state.locale,
  company: state.company
});

export default connect(mapStateToProps, {
  getCompanyAnswers,
  getCompanyProfiles,
  clearStandups,
  clearUpdates,
  clearOkrs,
  clearKudos,
  createInvite,
  deleteUser,
  getUserCompany,
  editCompanyMemberProfile,
  switchTeamHead,
  getCompanyCustomAndCheckinAnswers,
})(Users);
