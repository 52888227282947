import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '../layout/Menu';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import LogsTable from './LogsTable';
import Select from 'react-select';
import { getLogs } from '../../actions/admin';
import { FormattedMessage } from 'react-intl';

// Не работает отрисовка object (object[key].name) в LogsRow

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '16px',
  }),
  input: () => ({
    backgroundColor: '#fcfcfd',
    fontSize: '14px',
  }),
  control: styles => ({ ...styles, backgroundColor: '#fcfcfd', padding: '4px 8px' }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize };
  }
}

const Logs = ({
  getLogs,
  auth,
  data: { l, loading, },
}) => {
  const [formData, setFormData] = useState({
    team: '',
    user: [],
    logs: [],
  });

  let { team, user, logs, } = formData;

  let teamOptions = [];
  let usersOptions = [];

  // Unique companies
  let uniqueCompanies = l ? (l.reduce((acc, current) => {
    const x = acc.find(item => item.company && current.company && item.company.toString() === current.company.toString());
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, [])) : [];

  // Unique users
  let uniqueUsers = l ? (l.reduce((acc, current) => {
    const x = acc.find(item => item.user.toString() === current.user.toString());
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, [])) : [];

  uniqueUsers.length > 0 && uniqueUsers.forEach((item, index) => {
    let userId = item && item.user ? item.user.toString() : '';
    let coworker = {
      value: userId,
      label: `${item.firstName} ${item.lastName}`,
      company: item.company,
    };

    usersOptions.push(coworker);

    if (index === uniqueUsers.length - 1) {
      let team = { value: 1, label: `All Users`, } ;
      usersOptions.unshift(team);
    }
  });

  uniqueCompanies.length > 0 && uniqueCompanies.forEach((item, index) => {
    if (item) {
      let team = {
        value: item.company,
        label: item.companyName,
      };
  
      teamOptions.push(team);
    }

    if (index === uniqueCompanies.length - 1) {
      let team = { value: 1, label: `All Companies`, } ;
      teamOptions.unshift(team);
    }
  });

  let choosedTeam = l ? { value: 1, label: `All Companies`, } : {};
  let choosedUser = l ? { value: 1, label: `All Users`, } : {};
  
  useEffect(() => {
    getLogs();

    setFormData({
      team: !l ? '' : choosedTeam,
      user: !l ? '' : choosedUser,
      logs: !l ? [] : l,
    });
  }, [
    getLogs, 
    loading, 
    JSON.stringify(l),
  ]);
  
  let standupsActions = l && l.filter(i => i.type === 'standup_answer');
  let okrsActions = l && l.filter(i => i.type === 'okr');
  let tasksActions = l && l.filter(i => i.type === 'todo');

  const handleTeam = async selectedOption => {
    let newItems = [];

    if (user.value === 1) {
      // for everyone
      newItems = l.filter(item => item.company === selectedOption.company);
    } else {
      // for specific user in the team
      newItems = l.filter(item => item.company === selectedOption.company && item.user === user.value);
    }
    
    setFormData({ 
      ...formData, 
      team: selectedOption, 
      logs: newItems, 
    });
  };

  const handleUser = async selectedOption => {
    let newItems = [];

    if (team.value === 1) {
      // for specific user
      newItems = l.filter((item, index) => {
        
        if (item.user.toString() === selectedOption.value) {
          return item
        }

        return null;
      });
    } else {
      // for specific user in the specific company
      newItems = l.filter(item => item.company === team.company && item.user === selectedOption.value);
    }
    
    setFormData({ 
      ...formData, 
      user: selectedOption, 
      logs: newItems, 
    });
  };

  console.log('==== logs.length AFTER useState', logs.length);

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {auth.user && auth.user.department ? (
          <Menu team={auth.user.departmentName} teamId={auth.user.department} settings={auth.user && auth.user.isAdmin && true} focusUsers={auth.user && auth.user.isAdmin && auth.user.role === 'Superadmin' && true} activeIndex={201} />
        ) : (
          <Menu settings={auth.user && auth.user.isAdmin && true} focusUsers={auth.user && auth.user.isAdmin && auth.user.role === 'Superadmin' && true} activeIndex={201} />
        )}

        <UserNav />
          <Fragment>
            {loading || !auth.user || !l || auth.user.role !== 'Superadmin'  ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="pt-2 main-dashboard">
                     <h5>
                      <FormattedMessage id="logs" defaultMessage={`Logs`} />
                    </h5>

                  <div className="mb-5 pb-5">

                    <div className="mt-3">
                        <p className="medium bold">Total records:{" "}{l.length}</p>
                        <p className="medium bold">Current records:{" "}{logs.length}</p>
                        <p className="medium bold">Total users records:{" "}{uniqueUsers.length}</p>
                        <p className="medium bold">Total companies records:{" "}{uniqueCompanies.length}</p>
                        <div className="line w-80"></div>
                        <p className="medium bold">Total actions in check-ins:{" "}{standupsActions.length}</p>
                        <p className="medium bold">Total actions in okrs:{" "}{okrsActions.length}</p>
                        <p className="medium bold">Total actions in tasks:{" "}{tasksActions.length}</p>
                    </div>

                    <div className="flex space-between align-center mt-3">
                      <FormattedMessage
                        id="checkins.sub_menu.company"
                        defaultMessage="Choose the company"
                      >
                        {msg => (
                          <Select
                            className="small-input w-30-l-50-xs"
                            defaultValue={choosedTeam}
                            value={team}
                            onChange={selectedOption =>
                              handleTeam(selectedOption)
                            }
                            options={teamOptions}
                            isSearchable
                            placeholder={msg}
                            required
                            name="team"
                            styles={customStyles}
                          />
                        )}
                      </FormattedMessage>

                      <FormattedMessage
                        id="focus.sub_menu.user"
                        defaultMessage="Choose the user"
                      >
                        {msg => (
                          <Select
                            className="small-input w-30-l-50-xs"
                            defaultValue={choosedUser}
                            value={user}
                            onChange={selectedOption =>
                              handleUser(selectedOption)
                            }
                            options={usersOptions}
                            isSearchable
                            placeholder={msg}
                            required
                            name="user"
                            styles={customStyles}
                          />
                        )}
                      </FormattedMessage>  
                    </div>

                    <div className="my-3 pb-5 pr-2">
                        <LogsTable
                          logs={logs}
                          auth={auth.user}
                        />
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

Logs.propTypes = {
  getLogs: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  data: state.data,
});

export default connect(
  mapStateToProps,
  {
    getLogs,
  }
)(Logs);