import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import { setAlert } from '../../../actions/alert';
import TreeParent from "./TreeParent";
import Moment from "react-moment";
import { FormattedMessage } from "react-intl";

const TreeBranch = ({
  okrBranch,
  handleArchive,
  handleDelete,
  archive,
  user,
  zoom,
}) => {
  const [discussion, showComments] = useState(false);
  const [update, toggleUpdateMode] = useState(false);

  let parent = [];
  let childs = [];
  let grandchildsArr = [];
  let noParents = false;

  // Формируем отдельно родителей, детей и внуков
  // если внук без родителя или его родитель - ОКР компании, то записываем его в child
  {
    okrBranch &&
      okrBranch.forEach((item) => {
        if (item.type === 1) {
          parent.push(item);
        }

        if (item.type === 2) {
          childs.push(item);
        }

        if (item.type === 3) {
          
          if (item.isChild) {
            // If child
            
            let grandparent = okrBranch.filter(okr => okr._id.toString() === item.parentOkr.value);
          
            if (grandparent.length > 0) {
              // check who is a parent
              if (grandparent[0].type === 2) {
                // parent is team's OKR
                grandchildsArr.push(item);
              } else {
                // parent is company's OKR
                childs.push(item);
              }
            }  
          } else {
            // if no parents
            childs.push(item);  // нам нужно отрисовать child, без этого он будет пустым
            grandchildsArr.push(item);
            noParents = true;
          }
        }
      });
  }

  return (
    <Fragment>
      <div className=" mt-1" style={{ zoom: zoom, zoom: `${zoom * 100}%` }}>
        <div className="objective__branch">
          <div className="objective__parent flex align-center justify-start">
            <TreeParent
              parent={parent}
              childs={childs}
              grandchildsArr={grandchildsArr}
              noParents={noParents}
              handleArchive={handleArchive}
              handleDelete={handleDelete}
              archive={archive}
              user={user}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

TreeBranch.propTypes = {
  okrBranch: PropTypes.array.isRequired
};

export default TreeBranch;
