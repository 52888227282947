import React, { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCompanyProfiles } from '../../actions/profile';
import { createTeam } from '../../actions/company';
import Menu from '../layout/Menu';
import Spinner from '../layout/Spinner';
import UserNav from '../layout/UserNav';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';

const CreateDepartment = ({
  getCompanyProfiles,
  createTeam,
  profile: { profiles, loading },
  auth: { user },
  history
}) => {
  const [formData, setFormData] = useState({
    department: '',
    selectedOption: []
  });

  useEffect(() => {
    getCompanyProfiles();
  }, [getCompanyProfiles]);

  let options = [];

  profiles.forEach(profile => {
    let user = {
      value: profile._id,
      label: `${profile.firstName} ${profile.lastName}`
    };

    options.push(user);
  });

  const { department, selectedOption } = formData;

  const onSubmit = e => {
    e.preventDefault();
    createTeam(department, selectedOption, history);
  };

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChange = selectedOption => {
    setFormData({ ...formData, selectedOption: selectedOption });
  };

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">
          {user && user.department ? (
            <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user.isAdmin && true} activeIndex={9} />
          ) : (
            <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} activeIndex={9} />
          )}

          <UserNav />

          <Fragment>
            {!profiles || loading ? (
              <Spinner />
            ) : (
              <div className="main main-dashboard main-profile-admin">

                <div className="standups-admin mt-5 mt-0-xs">
                  <div className="section-title">
                    <h5 className="pl-0-xs">
                      <FormattedMessage id="admin.team.create.header" defaultMessage="Create team" />
                    </h5>

                    <Link
                      to="/teams"
                      className="btn-secondary btn-secondary-back"
                    >
                      <FormattedMessage id="go.back" defaultMessage="Go Back" />
                    </Link>
                  </div>

                  <div className="component create-team my-2 p-3 light-shadow">

                    <div className="form-full">
                      <div className="py-2">
                        <p className="meduim">
                        <FormattedMessage id="admin.team.create.text" defaultMessage="Enter team name and add members to the team" />
                        </p>
                      </div>

                      <form
                        className="form-app form-app-standup"
                        onSubmit={e => onSubmit(e)}
                      >
                        <div className="form-main form-main-inline">
                          <p className="bold medium block question">
                          <FormattedMessage id="admin.team.create.name" defaultMessage="Name" />
                            </p>
                          <FormattedMessage id="admin.team.create.nameInput" defaultMessage="Enter team name">
                          {msg => <input
                            className="question-input"
                            type="text"
                            placeholder={msg}
                            name="department"
                            value={department}
                            onChange={e => {
                              onChange(e);
                            }}
                          />}
                          </FormattedMessage>
                        </div>

                        <Fragment>
                          <p className="bold medium block question">
                          <FormattedMessage id="admin.team.create.invite" defaultMessage="Invite Your Team" />
                          </p>
                          <FormattedMessage id="admin.team.create.inviteSelect" defaultMessage="Add employees to the team">
                          {msg => <Select
                            value={selectedOption}
                            onChange={selectedOption =>
                              handleChange(selectedOption)
                            }
                            options={options}
                            isMulti
                            isSearchable
                            placeholder={msg}
                          />}
                          </FormattedMessage>
                        </Fragment>

                        <button type="submit" className="btn btn-form">
                        <FormattedMessage id="admin.team.create.btn" defaultMessage="Create team" />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

CreateDepartment.propTypes = {
  getCompanyProfiles: PropTypes.func.isRequired,
  createTeam: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { createTeam, getCompanyProfiles }
)(withRouter(CreateDepartment));
