import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { icons } from '../../../../img/elements/icons';

const BinaryForm = ({ 
  keyResult, index, handleKeyResults, handleAdd
 }) => {

  let milestones = keyResult.details;
  const mileLabel = <FormattedMessage id="milestone" defaultMessage="Milestone"/>

  // console.log('==== milestones', milestones)

  return (
    <div className="flex flex-column">
      
        {milestones.length > 0 && milestones.map((ml, i) => (
          <div className={`w-400px flex align-center ml-2 ${i > 0 ? 'mt-1' : ''}`}>
          <Fragment>
              <p className={`small w-80px ${i === 0 ? "mt-3" : ''} `}>{mileLabel}{" "}{i+1}</p>
              <div className="okr__kr-start labeled-wrapper w-200px kr__form__milestone__input">
                {i === 0 && (
                  <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                    {mileLabel}
                  </label>
                )}

                <FormattedMessage
                  id="enter.milestone"
                  defaultMessage="Enter a milestone"
                >
                  {(msg) => (
                    <input
                      className="w-100 input-text fs-18 kr__form__milestone__input"
                      type="text"
                      placeholder={msg}
                      name={'label'}
                      value={ml.label}
                      onChange={(e) => handleKeyResults(e, index, 'milestone', i)}
                    />
                  )}
                </FormattedMessage>
              </div>
              
              <div className="okr__kr-current labeled-wrapper w-100px ml-1">
                {i === 0 && (
                   <label className="fw-600 fs-14 no-w label-pr3 pb-0-xs mb-1">
                    <FormattedMessage
                      id="value"
                      defaultMessage="Value"
                    />
                 </label>
                )}
               
                <FormattedMessage
                  id="user.okr.create.keyResult.startInput"
                  defaultMessage="0"
                >
                  {(msg) => (
                    <input
                      className="w-100 input-text fs-18"
                      type="text"
                      placeholder={msg}
                      name={'value'}
                      value={ml.value}
                      onChange={(e) => handleKeyResults(e, index, 'milestone', i)}
                    />
                  )}
                </FormattedMessage>
              </div>
          </Fragment>
      </div>
        ))}

      <button
        type="button"
        className="w-content btn-secondary-back-upd mla fw-600 flex align-center mt-2 px-1 black"
        onClick={(e) => handleAdd(e, index)}
      >
        <span className="rounded-text-icon">{icons.circle_plus}</span>{' '}
        <FormattedMessage
          id="add.milestone"
          defaultMessage="Add Milestone"
        />
      </button>
    </div>
  );
};

BinaryForm.propTypes = {
  keyResult: PropTypes.object.isRequired,
  handleKeyResults: PropTypes.func.isRequired,
};

export default BinaryForm;
