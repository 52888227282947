import React from "react";
import PropTypes from 'prop-types';
import RoundImg from '../../elems/RoundImg';

const Profile = ({ profile, onClick, currentProfile, meetingID, isForm }) => {

  return (
     <div className="users pb-1">
      <div
        className="flex align-center justify-start mr-xs cursor"
        onClick={!isForm ? () => (onClick(profile, meetingID)) : (() => { if (window.confirm('Are you sure you wish to close the form? All data will be lost.')) onClick(profile, meetingID) } )}
      >
          <div className="user mr-1 fs1">
            <RoundImg
              src={profile && profile.avatar}
              alt={`${profile && profile.firstName} avatar`}
              w="45"
              h="45"
              styles="brd brd-primary medium-shadow"
            />
          </div>
          <div className="okr__owner">
            <p
              className={`medium-xs card-subtitle team-user ${currentProfile && profile && currentProfile._id.toString() === profile.user.toString() && 'sub-menu__item_active'}`}>
              {profile && profile.firstName}{" "}{profile && profile.lastName}
            </p>
          </div>
        </div>
      </div>
  );
};

export default Profile;
