import React, { useMemo, useState } from 'react';
import InvitationPopup from './InvitationPopup';
import { getCompanyProfiles } from '../../actions/profile';

const useInvite = ({ onSuccess = getCompanyProfiles } = {}) => {
  const [open, toggleOpen] = useState(false);
  const popup = useMemo(
    () => <InvitationPopup toggleModal={toggleOpen} onSuccess={onSuccess} />,
    [toggleOpen, onSuccess]
  );
  const openModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleOpen(true);
  };

  return [openModal, open ? popup : null];
};

export default useInvite;
