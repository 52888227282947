import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Menu from '../layout/Menu';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import PlanItem from '../plans/PlanItem';
import SubMenu from './SubMenu';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/1.jpg';
import { getCompanyProfiles, getProfileById } from '../../actions/profile';
import { getPlanUserAnswersById, clearPlans } from '../../actions/planAnswer';
import { getUserCompany } from '../../actions/company';
import { planCopy } from '../../utils/wording/plans';
import { FormattedMessage } from 'react-intl';
import Moment from 'react-moment';

const UserPlans = ({
  clearPlans,
  getPlanUserAnswersById,
  getProfileById,
  getUserCompany,
  getCompanyProfiles,
  auth: { user },
  company: { company, team },
  profile: { profile, profiles },
  planAnswer: { planAnswers, loading },
  match,
}) => {
  useEffect(() => {
    getUserCompany();
    getPlanUserAnswersById(match.params.id);
    getCompanyProfiles();
    getProfileById(match.params.id);

    // Clear state
    return () => {
      clearPlans();
    }
  }, [getUserCompany, getPlanUserAnswersById, getCompanyProfiles, getProfileById, match.params.id]); 

  const noUpdatesMessageTeam = {
    title: <FormattedMessage id="company.noplans.user.title" defaultMessage="No Plans" />,
    msg: 
    <FormattedMessage id="company.plans.user.msg" defaultMessage={`${profile && profile.firstName} has not finished weekly plan yet.`} />
  };
  
  // Removing duplicates in answers array based on the object key 'standup' - to send only unique standup items to StandupTeamItem
  // Берем данные из редакса planAnswers
  let planAnswersUnique = planAnswers ? (planAnswers.reduce((acc, current) => {
    const x = acc.find(item => item.update === current.update);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, [])) : [];

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {user && user.department ? (
          <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user && user.isAdmin && true} activeIndex={8} />
        ) : (
          <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} activeIndex={8} />
        )}
        <UserNav />
          <Fragment>
            {loading || !planAnswers || !user || !company || !profile ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="main main-dashboard">

                <h5>
                  <FormattedMessage id="team.header.plans" defaultMessage={`${profile && profile.firstName}'s Plans`} />
                </h5>
                  <SubMenu userId={profile._id} companyIndex={7} />
                  <div className="updates">
                    {company.plan !== 1 || company.plan === 1 && profiles.length > process.env.REACT_APP_FREE_PLAN_USERS ? (
                      <Fragment>
                        {planAnswers.length === 0 ? (
                          <div className="mt-5">
                            <NoContent 
                              message={noUpdatesMessageTeam}
                              picture={updateImg}
                              alt={'No plans'}
                            />
                          </div>
                        ) : (
                          <Fragment>
                            {planAnswersUnique.length > 0 && (
                              planAnswersUnique.map(
                                (answerUnique, index) => (
                                  <Fragment key={`fragment-update-${index}`}>
                                    <h3 className="fs-2 pt-2 pl-2 pb-1" key={`date-${index}`}>
                                      <Moment format="D MMM" key={`date-moment-${index}`}>{answerUnique.updateDate}</Moment>
                                    </h3>
                                    <PlanItem
                                      key={`plans-${answerUnique._id}-${index}`}
                                      planAnswers={planAnswers}
                                      updateDate={answerUnique.updateDate}
                                    />
                                  </Fragment>
                                )
                              )
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      <NoContent 
                        message={planCopy.updatePlanMsg}
                        picture={updateImg}
                        alt={'Update plan'}
                        admin={user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0}
                      />
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

UserPlans.propTypes = {
  clearPlans: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  getPlanUserAnswersById: PropTypes.func.isRequired,
  getProfileById: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  planAnswer: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  planAnswer: state.planAnswer
});

export default connect(
  mapStateToProps,
  { getUserCompany, getPlanUserAnswersById, getCompanyProfiles, getProfileById, clearPlans }
)(UserPlans);
