import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { updateLike, deleteOkr, addUserMainOkr, updateOkr } from '../../actions/okr';
import { icons } from '../../img/elements/icons';
import { setAlert } from '../../actions/alert';
import OkrStatus from './elements/OkrStatus';
import OkrAvatar from './elements/OkrAvatar';
import OkrProgress from './elements/OkrProgress';
import KeyResultItem from './kr/KeyResultItem';
import CommentItem from '../comment/okrs/CommentItem';
import CommentForm from '../comment/okrs/CommentForm';
import ErrorBoundary from '../_helpers/ErrorBoundary';
import { FormattedMessage } from 'react-intl';
import OkrMoreIcons from './elements/OkrMoreIconsClick';
import Highlighter from "react-highlight-words";
import Loader from 'react-loader-spinner';

// Сейчас кнопка для isMain (звезда) показывается так:
// доска команды (2) - видно звезду, если юзер из этой команды (okr.team === user.department)
// доска юзера (3) - видно звезду, если okr.team === user._id
// Todo - это неправильно, тк юзер может пинить свои цели на досках других юзеров и она меняется у всех!
// надо делать массив pinUsers и сохранять там юзеров, у кого запинина цель

const OkrCardItem = ({
  auth: { loading, user },
  okr: {
    creator,
    owner,
    ownerAvatar,
    ownerFirstName,
    ownerLastName,
    dueIn,
    _id,
    objective,
    team,
    teamName,
    isMain,
    isMainIndex,
    type,
    progress,
    status,
    updatedAt,
    keyResults,
    likes,
    comments,
    isArchive,
    privacy,
  },
  updateLike,
  setAlert,
  deleteOkr,
  addUserMainOkr,
  okrBoard,
  updateOkr,
  formType,
  formDataParent,
  setFormDataParent,
  archive,
  handleArchive,
  todos,
  usersOptions,
  okrOptions,
  priorityOptions,
  handleSelect,
  handleDueIn,
  handleDeleteOkr,
  handleDeleteTask,
  handleNameForm,
  handleComplete,
  listView,
  todoBoard,
  isHideUpdateBtn,
  handleDetails,
  showAllKeyResults,
  isHideDetailsModal,
  titleIsLink,
  titleLink,
  profiles,
  ownerOptions,
  locale,
  lang,
  isAvatarNoLink,
  searchKeyword,
}) => {
  const [formData, setFormData] = useState({
    keyResults: [],
    status: '',
    objective: '',
    team: '',
    // owners: '',
    dueIn: '',
    changes: [],
  });

  // Show todos or hide
  const [actions, showTasks] = useState(false);
  const [displayKeyResults, toggleKeyResultsButton] = useState(showAllKeyResults ? true : false);
  const [discussion, showComments] = useState(false);
  const [update, toggleUpdateMode] = useState(false);
  const [isLoader, toggleLoader] = useState(false);


const getChanges = (item) => {
  let newChanges = formData.changes;

  // If we didn't save this update
  if (newChanges.indexOf(item) === -1) {
    newChanges.push(item);
  }

  return newChanges;
};

const getKrChanges = (
  item,
  type,
  index,
  milestoneIdx = 100,
  milestoneName = '',
  isMilestoneAdd = false
) => {
  let newChanges = formData.changes;
  // console.log('===== type in getKrChanges', type)
  // console.log('===== index in getKrChanges', index)

  // We change owner to owners because we save many owners in db in the 'owners' array
  if (type === 'owner') {
    type = 'owners';
  }

  // If we didn't save this update
  if (newChanges.indexOf(item) === -1 && type !== 'milestone' && milestoneName !== 'milestone') {
    if (milestoneName === 'binary') {
      type = 'binary';
    } else if (type !== 'milestone') {
      // don't add it if it's binary or milestone
      newChanges.push(item);
    }
    newChanges.push(`kr-${type}-${index}`);
  } else {
    // if we've already had keyResults in the changes
    // check the name of changes then
    // we have 2 elements in changes array for 1 KR:
    // - keyResults - base
    // - kr-${type}-${index} - show index and type (owner, start, etc)
    if (type !== 'milestone') {
      // it's not milestone
      if (newChanges.indexOf(`kr-${type}-${index}`) === -1 || type === 'add' || type === 'remove') {
        // add this type of kr if we don't have it in the arr
        // we always save add and remove actions

        // We add it only if we didn't add the new KR before.
        // If we added KR before, then we don't save new title, start, goals
        if (newChanges.indexOf(`kr-add-${index}`) === -1) {
          // save new change
          newChanges.push(`kr-${type}-${index}`);
        }
      }
    } else {
      // milestone changes

      // We add it only if we didn't add the new Milestone before.
      // If we added Milestone before, then we don't save new title, start, goals
      if (
        newChanges.indexOf(`kr-milestone-${index}-${milestoneIdx}-change-${milestoneName}`) === -1 &&
        newChanges.indexOf(`kr-milestone-${index}-${milestoneIdx}-add`) === -1
      ) {
        let strMl = isMilestoneAdd ? 'add' : `change-${milestoneName}`;
        newChanges.push(`kr-${type}-${index}-${milestoneIdx}-${strMl}`);
      }
    }
  }
  // console.log('===== newChanges in getKrChanges AFTER', newChanges)

  return newChanges;
};

  let choosedOwner = {
    value: owner,
    label: `${ownerFirstName} ${ownerLastName}`
  }

  // На всякий случай округляем (иногда сохраняется число с бесконечными десятичными знаками)
  progress = Math.round(progress);

  // ToDo Ru-En translation later
  const statusOptions = [
    { value: 1, label: 'On Track', color: '#6ddeb3' },
    { value: 2, label: 'Behind', color: '#facf34' },
    { value: 3, label: 'At Risk', color: 'red' }
  ];
  let choosedStatus = {};

  status &&
    statusOptions.forEach((item) => {
      if (item.value === status) {
        choosedStatus = {
          value: item.value,
          label: item.label,
          color: item.color
        };
      }
    });

  useEffect(() => {
    setFormData({
      ...formData,
      keyResults: !keyResults ? [] : keyResults,
      status: !status ? '' : choosedStatus,
      objective: !objective ? '': objective,
      team: !team ? '': team,
      // owner: !profiles ? '' : profiles.filter(p => p.value === owner)[0],
      // owners: !owners ? '' : owners,
      dueIn: !dueIn ? '' : new Date(dueIn),
    });
  }, []);

  useEffect(() => {
    if (showAllKeyResults !== displayKeyResults) {
      toggleKeyResultsButton(!displayKeyResults);
    }
  }, [showAllKeyResults]);

  const handleStatus = (selectedOption) => {
    setFormData({ ...formData, status: selectedOption });
  };

  // const onChange = (e) => {
const onChange = (e, index) => {
    // console.log('===== index', index)
    // console.log('===== keyResults[index]', keyResults[index])

    keyResults[index][e.target.name] = e.target.value;
    keyResults[index].isNew = true; // new for identifying KR that was changed
    setFormData({ ...formData, keyResults: keyResults });
  };

  const onChangeObjective = (e) => {
    // e.preventDefault();
    let newChanges = getChanges(e.target.name);
    // console.log('===== e.target.name in onChangeObjective', e.target.name)
    // console.log('===== e.target.value in onChangeObjective', e.target.value)

    // [e.target.name] = e.target.value;
    // keyResults[index].isNew = true; // new for identifying KR that was changed
    setFormData({ ...formData, [e.target.name]: e.target.value, changes: newChanges });
  };

  const onChangeSelect = (selectedOption, name) => {
    // e.preventDefault();
    let newChanges = getChanges(name);

    // console.log('==== selectedOption in onChangeSelect', selectedOption)

    setFormData({ ...formData, [name]: selectedOption, changes: newChanges  });
  };

  const onChangeKRSelect = (selectedOption, name, index) => {
    // e.preventDefault();
    let newChanges = getChanges(name);

    keyResults[index][name] = selectedOption;
    setFormData({ ...formData, keyResults: keyResults, changes: newChanges });

  };

  const handleKeyResults = (selectedOption, index, type, detailsIdx = 0) => {
    // console.log('==selectedOption in handleKeyResults', selectedOption)
    // console.log('==index in handleKeyResults', index)

    formData.changes;

    if (type === 'details') {
      keyResults[index].details = [selectedOption];
      keyResults[index].krCurrent = selectedOption.value;
      keyResults[index].isNew = true; // new for identifying KR that was changed
    } else if (type === 'milestone') {
      keyResults[index].krCurrent = selectedOption.value;
      keyResults[index].krType.activeMilestoneIndex = selectedOption.index;
      keyResults[index].isNew = true; 

      // Save the goal
      if (selectedOption.index === keyResults[index].details.length - 1) {
        keyResults[index].goal = parseFloat(selectedOption.value);
      }
    } else if (type === 'owners') {
      // console.log('====== type === owner', type === 'owners')
      keyResults[index].owners = selectedOption;

      // newChanges = getKrChanges('krResults', type, index, detailsIdx, '');
    }
    // console.log('====== newChanges', newChanges)
    // console.log('====== keyResults[index].owners in handle', keyResults[index].owners)

    setFormData({ ...formData, keyResults: keyResults, });
  };

  const onChangeKRMetricsSelect = (selectedOption, index) => {

    // console.log('==== selectedOption in onChangeKRMetricsSelect', selectedOption)
    // console.log('==== keyResults[index].owners in onChangeKRMetricsSelect', keyResults[index].owners)
    // console.log('==== index in onChangeKRMetricsSelect', index)

    let newChanges = getKrChanges('krResults', 'owners', index, 0, '');   // по факту, не работает. Надо менять на более простую схему. 
    // console.log('==== newChanges in onChangeKRMetricsSelect', newChanges)

    // keyResults[index].owners = selectedOption; // всегда выдает undefined. Добавил krOwners из-за этого.
    keyResults[index].krOwners = selectedOption;
    keyResults[index].isNewOwner = true; // new for identifying KR that was changed
    setFormData({ ...formData, keyResults: keyResults, changes: newChanges });
  };

  // console.log('==keyResults[0].krType in handleKeyResults', keyResults[0].krType)


  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      // Show Loader
      toggleLoader(true);
      // console.log('===== onSubmit in okrCardItem update start')

      const res = await updateOkr(formData, _id);
      // console.log('===== res in okrCardItem', res)

      if (res && res.id) {
        setFormDataParent({
          ...formDataParent,
          objectives: formDataParent.objectives.map((item) => (item._id === res.id ? res.okr : item))
        });
  
        toggleUpdateMode(!update);
      } else if (res === undefined && keyResults.length > 0) {
        // If error and empty KR title
        // for (let i = 0; i < keyResults.length; i++) {
        //   let kr = keyResults[i];

        //   // If title is emtpy
        //   if (kr.title.length === 0) {
        //     // ToDo
        //     // 1. Find old meaning and paste here
        //     // === OR change onChange => 
        //     //  - if title.length = 0, 
        //     //  - then create oldTitle variable with meaning
        //     //  - and change here the meaning of title to oldTitle
        //   }
        // }
        // console.log('===== ERROR HERE: kr', keyResults)
      }
      toggleLoader(false);
    } catch (err) {
      toggleLoader(false);
      console.log('Error in onSubmit', err);
    }
  };
  // console.log('===== formDataParent', formDataParent)

  const findUserLike = (likes) => {
    if (!loading && user) {
      if (likes.filter((like) => like.user === user._id).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const findComments = (comments) => {
    if (comments.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  let btnClass = '';
  let label = '';

  if (status === 1) {
    btnClass = 'green';
    label = 'On Track';
  } else if (status === 2) {
    btnClass = 'yellow';
    label = 'Behind';
  } else if (status === 3) {
    btnClass = 'red';
    label = 'At Risk';
  }

  const handleUpdate = () => {
    toggleKeyResultsButton(true)
    toggleUpdateMode(!update)
  };

  // ЗДЕСЬ получаем коллбэк и перебираем formData.objectives и обновляем лайки
  const handleLike = async (id) => {
    try {
      const res = await updateLike(id);

      setFormDataParent({
        ...formDataParent,
        objectives: formDataParent.objectives.map((answer) => {
          if (answer._id === res.id) {
            // Update all likes
            answer.likes = res.likes;

            return answer;
          } else {
            return answer;
          }
        })
      });
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in likes', err);
    }
  };

  // ЗДЕСЬ получаем коллбэк и перебираем formData.objectives и обновляем isMainIndex
  const handleMain = async (_id, okrBoard) => {
    try {
      const res = await addUserMainOkr(_id, 99, okrBoard);

      setFormDataParent({
        ...formDataParent,
        objectives: formDataParent.objectives.map((answer) => {
          if (answer._id === res.id) {
            // Update goal
            answer.isMain = res.main.isMain;
            answer.isMainIndex = res.main.isMainIndex;

            return answer;
          } else if (res.exOkrId && answer._id === res.exOkrId) {
            answer.isMain = res.exMain.isMain;
            answer.isMainIndex = res.exMain.isMainIndex;

            return answer;
          } else {
            return answer;
          }
        })
      });
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in update main goal', err);
    }
  };

  // ЗДЕСЬ получаем коллбэк и перебираем formData.objectives и обновляем isMainIndex
  const handleDelete = async (_id) => {
    try {
      const res = await deleteOkr(_id);

      // Не меняю расположение детей, так как в новом UI они не меняют вид, НО если в будущем будет дерево OKR, то надо будет менять и у детей информацию
      setFormDataParent({
        ...formDataParent,
        objectives: formDataParent.objectives.filter((answer) => answer._id !== res.okrID)
      });
    } catch (err) {
      setAlert(
        <FormattedMessage
          id="like.alert.error"
          defaultMessage="Something went wrong. We are working on it..."
        />,
        'danger'
      );
      console.log('Error in update main goal', err);
    }
  };

  let isOkrOwner = false;
  let isKrOwner = false;

  // Shorten
  let pr = privacy;

  if (creator === user._id || owner === user._id) {
    isOkrOwner = true;
  }


  let btnTxt = <FormattedMessage id="save" defaultMessage="Save" />;

  let kr = keyResults && keyResults.length > 0 && keyResults.map((k) => k.owner);
  let krRes = kr && kr.length > 0 && kr.indexOf(user._id.toString());
  if (krRes > -1) {
    isKrOwner = true;
  }

  // Loader for button
  let loadingIcon = (
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Loader type="Circles" color="#ffffff" height="14px" width="15" />
    </span>
  );

  if (isLoader) {
    btnTxt = loadingIcon;
  }
  
  // update && console.log('=== formType', formType)
  // console.log('=== keyResults', keyResults)
  // // console.log('=== objective', objective)
  // // console.log('=== isHideDetailsModal', isHideDetailsModal)
  // update &&  console.log('=== formData.objective', formData.objective)
  // update &&  console.log('=== formData', formData)
  // // console.log('=== isAvatarNoLink', isAvatarNoLink)
  // update &&  console.log('=== profiles in OKR ITEM', profiles)

  return (
    <Fragment>
      {user.isAdmin || pr === 1 || pr === undefined || isOkrOwner || isKrOwner || pr === 2 && user && user.department && user.department.toString() === team || pr === 3 && owner === user._id || pr === 3 && creator === user._id ? (
        <div className="card-team flex relative">

        {okrBoard !== '0' && (
            <div className="card-icons card-icons__okr absolute -left-30 left-30 flex align-start pt-5">

            {okrBoard === '1' && (
              type === 1 ? (
                <button 
                className="btn-action no_btn no-minw bg-transparent absolute-xs icon__grey" 
                type="button"
                onClick={e => handleMain(_id, okrBoard)}
                >
                {/* {isMain && isMainIndex === 99 ? icons.okr_star_full : icons.okr_star} */}
                {isMain && isMainIndex === 99 ? (
                  <span className="star-box__full__yellow">{icons.star_full}</span>
                  ) : (
                    <span className="star-box">
                      <Fragment> 
                        <span className="star-box__empty">{icons.star_empty}</span>
                        <span className="star-box__full">{icons.star_full}</span>
                      </Fragment>
                    </span>
                  )}
              </button>) : null
            )}

            {okrBoard === '2' ? (
              user && user.department && team === user.department.toString() && (
                <button
                  className="btn-action no_btn no-minw mr-1 ml-1" 
                  type="button"
                  onClick={e => handleMain(_id, okrBoard)}
                  >
                  {isMain && isMainIndex === 99 ? (
                    <span className="star-box__full__yellow">{icons.star_full}</span>
                  ) : (
                    <span className="star-box">
                      <Fragment> 
                        <span className="star-box__empty">{icons.star_empty}</span>
                        <span className="star-box__full">{icons.star_full}</span>
                      </Fragment>
                    </span>
                  )}
                  </button>
              )
            ): (
                okrBoard === '3' ? (
                  team === user._id.toString() && (
                    <button
                      className="btn-action no_btn no-minw mr-1 ml-1" 
                      type="button"
                      onClick={e => handleMain(_id, okrBoard)}
                      >
                      {isMain && isMainIndex === 99 ? (
                        <span className="star-box__full__yellow">{icons.star_full}</span>
                      ) : (
                        <span className="star-box">
                        <Fragment> 
                          <span className="star-box__empty">{icons.star_empty}</span>
                          <span className="star-box__full">{icons.star_full}</span>
                        </Fragment>
                      </span>
                      )}
                    </button>
                  )
                  ) : null
            )}
            </div>
        )}

        <div className="w-100 okr__card">
          <form
            className="form__okr-update"
            onSubmit={e => onSubmit(e)}
          >
          <div className={`-${btnClass} relative ${!displayKeyResults ? 'br-4' : 'okr__card__expanded'} brd brd-grey__light bg__white`}>
            <div className="flex w-40-l-70-xsX">
              <div className="okr__left flex flex-column flex-start px-3 py-25">
              {type === 2 && okrBoard === '1' ? (
                <Link to={`/company/team/${team}`} className="-mt-1">
                  <span className={`fs-10 ${btnClass}`}>{teamName}</span>
                </Link>
                ) :
              (
                <span className={`fs-12 dark-grey`}>{teamName}</span>
              )}
                {!isHideDetailsModal ? (
                  <Fragment>
                    {!update ? (
                      <p
                        className="fs-2 fw-600 w-100 cursor"
                        onClick={() => handleDetails(_id, _id, 99)}
                      >
                        <Highlighter
                          highlightClassName="bg-yellow_pure"
                          searchWords={[searchKeyword]}
                          autoEscape={true}
                          textToHighlight={objective}
                        />
                     </p>
                    ) : (
                      <p
                        className="fs-2 fw-600 w-100 cursor"
                      >
                        <FormattedMessage
                          id="user.okr.create.objectiveInput"
                          defaultMessage="ex Achieve record revenue"
                        >
                          {(msg) => (
                            <input
                              className="w-100 h-30 p-xs outline border__input_main fs-14"
                              type="text"
                              placeholder={msg}
                              name="objective"
                              value={formData.objective || ''}
                              onChange={(e) => onChangeObjective(e)}
                            />
                          )}
                        </FormattedMessage>
                      </p>
                    )
                  }
                  </Fragment>
                  ) : (
                  !titleIsLink ? (
                    <p className="fs-2 w-100 fw-600">
                      {/* {objective} */}
                      <Highlighter
                        highlightClassName="bg-yellow_pure"
                        searchWords={[searchKeyword]}
                        autoEscape={true}
                        textToHighlight={objective}
                      />
                    </p>
                  ) : (
                    <Link 
                      to={titleLink} 
                      className="fs-2 w-100 fw-600 cursor black"
                    >
                      {/* {objective} */}
                      <Highlighter
                        highlightClassName="bg-yellow_pure"
                        searchWords={[searchKeyword]}
                        autoEscape={true}
                        textToHighlight={objective}
                      />
                    </Link>
                    )
                  )
                }
                <div className="okr__title__status flex align-center1 mt-1">
                  <div className="mr-1 mb-1-xs">
                    <OkrStatus
                      btnClass={btnClass !== undefined && btnClass}
                      label={label}
                      updateMode={update}
                      handleStatus={handleStatus}
                      statusOptions={statusOptions}
                      choosedStatus={choosedStatus}
                    />
                  </div>
                  <OkrProgress 
                    btnClass={btnClass !== undefined && btnClass} 
                    progress={progress}
                    keyResults={keyResults}
                  />
                </div>
              </div>
              <div className="line-new__vertical"></div>
              <div 
                className="okr__right px-3 py-25 flex align-center"
              >
                <div 
                  className="okr__right__avatar cursor" 
                  onClick={!update ? () => toggleKeyResultsButton(!displayKeyResults) : null}
                >
                  <OkrAvatar
                    owner={formData.owner} 
                    ownerAvatar={ownerAvatar}
                    ownerFirstName={ownerFirstName}
                    ownerLastName={ownerLastName}
                    dueIn={formData.dueIn}
                    noLink={formType === 2 && true}
                    updatedAt={updatedAt}
                    isAvatarNoLink={isAvatarNoLink}
                    updateMode={update}
                    // ownerOptions={profiles}
                    ownerOptions={ownerOptions}
                    choosedOwner={choosedOwner}
                    onChangeSelect={onChangeSelect}
                  />
                </div>
                <div className="mla">
                  {formType !== 2 && (
                    <OkrMoreIcons
                      _id={_id}
                      handleArchive={handleArchive}
                      handleDelete={handleDeleteOkr}
                      isArchive={isArchive}
                      archive={archive}
                      user={user}
                      owner={owner}
                      creator={creator}
                      locale={locale}
                      lang={lang}
                      toggleUpdateMode={toggleUpdateMode}
                      update={update}
                      handleUpdate={handleUpdate}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {displayKeyResults && (
            <div className="kr__card bg__white brd brd-grey__light card-standups-team card-body-hidden pt-4 pb-2 pl- active">
              <div className="card-goal-item relative ml-1-xs">
                <div className="mb-2">
                  <ErrorBoundary>
                  {keyResults.map((item, i) => (
                    <KeyResultItem
                      key={item._id}
                      keyResult={item}
                      progress={item.progress}
                      isMain={isMainIndex === i ? isMain : false}
                      okrId={_id}
                      index={i}
                      okrBoard={okrBoard}
                      okrType={type}
                      updateMode={update}
                      onChange={onChange}
                      formData={formData}
                      formType={formType && formType}
                      formDataParent={formDataParent}
                      setFormDataParent={setFormDataParent}
                      todos={todos && todos.filter(todo => todo.OKR.krId === item._id)}
                      usersOptions={usersOptions}
                      okrOptions={okrOptions}
                      priorityOptions={priorityOptions}
                      handleSelect={handleSelect}
                      handleDueIn={handleDueIn}
                      handleComplete={handleComplete}
                      i={i}
                      user={user}
                      handleDelete={handleDeleteTask}
                      handleNameForm={handleNameForm}
                      todoBoard={todoBoard}
                      listView={listView}
                      actions={actions}
                      showTasks={showTasks}
                      handleDetails={handleDetails}
                      searchKeyword={searchKeyword}
                      locale={locale}
                      handleKeyResults={handleKeyResults}
                      onChangeKRSelect={onChangeKRSelect}
                      // ownerOptions={profiles}
                      ownerOptions={ownerOptions}
                      onChangeKRMetricsSelect={onChangeKRMetricsSelect}
                    />
                  ))}
                  </ErrorBoundary>
                </div>

                <div className="px-3">
                  <div className="line__grey my-3"></div>
                </div>

                <div className="px-3">
                  <div className="1">
                    <div className="flex align-center">
                    {formType !== 2 && (
                        <div className="flex align-center">
                          <button
                            type="button"
                            className={classnames('btn-icons btn-like flex align-center mr-1', {
                              'btn-active-icon btn-like__active': findUserLike(likes)
                            })}
                            onClick={e => handleLike(_id)}
                          >
                            <span className="mr-xs flex align-center">{icons.heart}{icons.heart_full}</span>
                            <span className='fs-10 bold mb-0'>{likes.length > 0 ? likes.length : '0'}</span>
                          </button>

                          <button
                            type="button"
                            onClick={() => showComments(!discussion)}
                            className={classnames('btn-icons btn-comment flex align-center icon__message__badge', {
                              'btn-active-icon': findComments(comments)
                            })}
                          >
                            <span className="mr-xs flex align-center">{icons.message}</span>
                            <span className="fs-10 bold mb-0">{comments.length > 0 ? comments.length : '0'}</span>
                          </button>
                        </div>
                      )}

                      {!isHideUpdateBtn && (
                        !update ? (
                        <Fragment>
                          <div className="block mla w-content pb-0">
                            <button 
                              type="button" 
                              className="btn_new__grey br-4 cursor overflow-hidden"
                              onClick={() => toggleUpdateMode(!update)}
                            >
                              <FormattedMessage
                                id="okr.form.update"
                                defaultMessage="Update OKR"
                              />
                            </button>
                          </div>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <div className="flex align-center block mla w-content pb-0">
                            <button 
                              type="button" 
                              className="btn_new__grey br-4 cursor"
                              onClick={() => toggleUpdateMode(!update)}
                            >
                              <FormattedMessage
                                id="okr.form.cancel"
                                defaultMessage="Cancel"
                              />
                            </button>

                            <button 
                              type="submit" 
                              className="btn btn-small btn-small-blue ml-1 br-4 mr-0"
                              disabled={!isLoader ? false : true}
                            >
                              {btnTxt}
                            </button>
                          </div>
                        </Fragment>
                      )
                  )}
                    </div>
                  </div>
                </div>
              </div>

              {discussion && (
                <Fragment>
                  <div className="card-feedback mt-24 px-3">
                    <div className="comment">

                      {comments.map(comment => (
                        <CommentItem
                          key={comment._id}
                          comment={comment}
                          okrID={_id}
                          formDataParent={formDataParent}
                          setFormDataParent={setFormDataParent}
                          isEdit={false}
                        />
                      ))}

                      <CommentForm
                        okrID={_id}
                        user={user}
                        avatar={user && user.avatar}
                        formDataParent={formDataParent}
                        setFormDataParent={setFormDataParent}
                        mentions={profiles}
                      />
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          )}
          </form>
        </div>

      </div>
      ) : ("")}
    </Fragment>
  );
};


OkrCardItem.propTypes = {
  auth: PropTypes.object.isRequired,
  updateLike: PropTypes.func,
  deleteOkr: PropTypes.func,
  addUserMainOkr: PropTypes.func,
  handleArchive: PropTypes.func,
  archiveOKR: PropTypes.func,
  archive: PropTypes.bool,
  setAlert: PropTypes.func.isRequired,
  updateOkr: PropTypes.func.isRequired,
  usersOptions: PropTypes.array,
  okrOptions: PropTypes.array,
  priorityOptions: PropTypes.array,
  handleSelect: PropTypes.func,
  handleDueIn: PropTypes.func,
  clearTodo: PropTypes.func,
  handleDeleteOkr: PropTypes.func,
  handleDeleteTask: PropTypes.func,
  handleNameForm: PropTypes.func,
  handleComplete: PropTypes.func,
  listView: PropTypes.bool,
  isHideUpdateBtn: PropTypes.bool,
  todoBoard: PropTypes.number
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  locale: PropTypes.object.isRequired
});

export default connect(mapStateToProps, {
  updateLike,
  deleteOkr,
  addUserMainOkr,
  updateOkr,
  setAlert
})(OkrCardItem);
