import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TaskItemCard from './TaskItemCard';
import TaskItemCardForm from './TaskItemCardForm';

const ListItem = ({
  todos,
  formData,
  setFormData,
  searchKeyword,
  todoBoard,
  usersOptions,
  okrOptions,
  priorityOptions,
  handleSelect,
  handleDueIn,
  onSubmit,
  handleChangeForm,
  clearTodo,
  handleDelete,
  user,
  handleAdd,
  handleComplete,
  handleNameForm,
}) => {

  let title = '';
  let color = '';
  
  if (todoBoard === 1) {
    title = <FormattedMessage id="tasks" defaultMessage="Tasks" />;
    color = 'green';
  } else if (todoBoard === 99) {
    title = <FormattedMessage id="completed" defaultMessage="Completed" />;
    color = 'yellow';
  }

  return (
    <Fragment>
      <div className="my-2">
        <div className="">
          {todos.map((item, index) => (
            <Fragment 
              key={`todo-${item._id}-${index}`} 
            >
              <div>
              {!item.isNew ? (
                <TaskItemCard
                  todo={item}
                  formData={formData}
                  setFormData={setFormData}
                  searchKeyword={searchKeyword}
                  usersOptions={usersOptions}
                  okrOptions={okrOptions}
                  priorityOptions={priorityOptions}
                  handleSelect={handleSelect}
                  handleDueIn={handleDueIn}
                  handleComplete={handleComplete}
                  index={index}
                  user={user}
                  handleDelete={handleDelete}
                  handleNameForm={handleNameForm}
                  todoBoard={todoBoard}
                />
              ) : (
                <TaskItemCardForm
                  todo={item}
                  formData={formData}
                  setFormData={setFormData}
                  searchKeyword={searchKeyword}
                  usersOptions={usersOptions}
                  okrOptions={okrOptions}
                  priorityOptions={priorityOptions}
                  handleChangeForm={handleChangeForm}
                  clearTodo={clearTodo}
                  handleDueIn={handleDueIn}
                  onSubmit={onSubmit}
                  index={index}
                  krId={0}
                />
              )}
              </div>
            </Fragment>
          ))}

          {/* {todoBoard === 1 && (
            <div className="step-item mr-2 pt-1 px-1 ml-3">
              <p
                onClick={e => handleAdd(e)}
                className="medium grey cursor">
                <FormattedMessage
                    id="add.task"
                    defaultMessage="Add task..."
                  />
              </p>
            </div>
          )} */}
        </div>
      </div>
    </Fragment>
  );
};

ListItem.propTypes = {
  todos: PropTypes.array.isRequired
};

export default ListItem;
  