import React, { Fragment, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { icons } from "../../../img/elements/icons";
import { FormattedMessage } from "react-intl";

const OkrMoreIconsClick = ({
  _id,
  handleArchive,
  handleDelete,
  isArchive,
  archive,
  user,
  owner,
  creator,
  isTree,
  locale,
  lang,
  isHideArchive,
  update,
  toggleUpdateMode,
  handleUpdate,
}) => {

  const [showIcons, toggleShowIcons] = useState(false);

  let deleteCopy = 'Are you sure you wish to delete this OKR? Data will be lost forever.'

  if (lang === 'ru') {
    deleteCopy = 'Вы уверены, что хотите удалить этот OKR? Все данные будут безвозвратно потеряны.';
  }

  /** Hook that alerts clicks outside of the passed ref */
  // Hide Okr Detail if clicks outside
  function useOutsideAlerter(ref) {
    useEffect(() => {
    // console.log('========== USEEFFECT 5 WORKS')

      /** Alert if clicked on outside of element */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          toggleShowIcons(false)
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);


  return (
    <Fragment>
      {user && owner === user._id || user && creator === user._id || user && user.role === "Admin" || user && user.role === "Owner" || user && user.role === "Superadmin" ? (
        <div ref={wrapperRef} className="flex align-center br-4 brd brd-grey__light bg__white relative">
          <span className="w-100px hide-xs no_btn icon__box h-34 lh-34">
            {/* <Link to={`/okr/${_id}`} className="fs-14 icon__grey fw-600 flex align-center">
              {icons.edit_small}
              {' '} 
              <FormattedMessage
                id="edit"
                defaultMessage="Edit"
              />
            </Link> */}
             <button 
                type="button" 
                className="w-100 no_btn flex align-center justify-center icon__box icon__grey h-34 lh-34 px-1 overflow-hidden"
                onClick={handleUpdate}
              >
                {icons.update_small}
                <FormattedMessage
                  id="update"
                  defaultMessage="Update"
                />
              </button>
          </span>
          <div className="line-new__vertical h-34"></div>
          <div className={`no_btn relative h-34 px-1 icon__box ${showIcons ? 'icon__box_active' : ''}`} onClick={() => toggleShowIcons(!showIcons)}>
            <span className="w-50px lh-28">
              {icons.dots}
            </span>
          </div>
          {showIcons && (
            <div className={`${lang !== 'ru' ? 'w-100px': 'w-150px'} light-shadow absolute brd brd-grey__light br-4 dropdown-40 right bg__white z-10000`}>
              <button 
                type="button"
                className="w-100 icon__box fs-14 icon__grey no_btn px-1 lh-30 "
              >
                <Link 
                  to={`/okr/${_id}`} 
                  className='fs-14 icon__grey flex align-center  '
                >
                  <span className="w-23px inline-block">{icons.edit_small}</span>
                  
                  {' '}
                  <span className="">
                    <FormattedMessage
                      id="edit"
                      defaultMessage="Edit"
                    />
                  </span>
                </Link>
              </button>
              <button 
                type="button"
                className="w-100 icon__box fs-14 icon__grey no_btn px-1 lh-30 "
              >
                <Link 
                  to={`/objective/new/${_id}`}
                  className='fs-14 flex icon__grey flex align-center  '
                >
                  <span className="w-23px inline-block">{icons.copy_small}</span>
                  
                  {' '}
                  <span className="">
                    <FormattedMessage
                      id="copy.okr"
                      defaultMessage="Copy"
                    />
                  </span>
                </Link>
              </button>
              {!isHideArchive && (
                <button 
                  type="button"
                  onClick={(e) => handleArchive(e, _id, isArchive, isTree)}
                  className="w-100 flex icon__box fs-14 icon__grey no_btn  px-1 pt-xs lh-25 text-left "
                  >
                  <span className="w-23px inline-block">
                    {icons.archive_small}
                  </span>
                  {' '}
                  {!archive ? (
                    <FormattedMessage
                      id="okr.archive"
                      defaultMessage="Archive"
                    />
                  ) : (
                    <FormattedMessage
                      id="okr.unarchive"
                      defaultMessage="Unarchive"
                    />
                  )}
                </button>
              )}
              <button 
                type="button"
                onClick={() => { if (window.confirm(deleteCopy)) handleDelete(_id, isTree) } }
                className="w-100 flex icon__box fs-14 icon__grey no_btn px-1 lh-30 text-left"
              >
                <span className="w-23px inline-block">
                  {icons.trash_small}
                </span>
                {' '}
                <FormattedMessage
                  id="delete"
                  defaultMessage="Delete"
                />
              </button>
            </div>
            )}
        </div>
        ) : null}
    </Fragment>
  );
};

OkrMoreIconsClick.propTypes = {
  _id: PropTypes.string.isRequired,
  handleArchive: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isArchive: PropTypes.bool.isRequired,
  archive: PropTypes.bool.isRequired,
  isTree: PropTypes.bool,
  user: PropTypes.object.isRequired,
  owner: PropTypes.string.isRequired,
  creator: PropTypes.string.isRequired,
};

export default OkrMoreIconsClick;
