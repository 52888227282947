import React from "react";
import PropTypes from "prop-types";
import TableLine from "./TableLine";
import TableTitleNew from "./TableTitleNew";

const Table = ({
  answers,
  getUserAnswersAmount,
  tableNameTitle,
  tableTitleItems,
  noContentMsg,
  isUnfinishedUsers,
  campaigns,
  profiles,
  linkName,
}) => {

  return (
     <div className="table">
        <div className="br-4 my-2 p-4 brd brd-grey__light bg__white">
          <TableTitleNew 
              name={tableNameTitle}
              items={!isUnfinishedUsers ? tableTitleItems : [tableTitleItems[1]]}
            />
          
          {answers.length > 0 ? (
            answers.map((item, index) => (
              <div key={`table-item-${index}`}>
                <TableLine
                    item={item}
                    getUserAnswersAmount={getUserAnswersAmount}
                    isUnfinishedUsers={isUnfinishedUsers}
                    campaigns={campaigns}
                    profiles={profiles}
                    linkName={linkName}
                  />
              </div>
            ))
          ) : (
            <div className="mt-5">
              <p className="medium">{noContentMsg}</p>
            </div>
          )}
        </div>
    </div>
  );
};

Table.propTypes = {
  answers: PropTypes.array.isRequired,
  getUserAnswersAmount: PropTypes.func,
  tableNameTitle: PropTypes.object,
};

export default Table;
