import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Menu from '../layout/Menu';
import { Link } from 'react-router-dom';
import MainMenu from '../layout/Sidebar';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import { getCompanyProfiles } from '../../actions/profile';
import { getUserCompany, getTeamById } from '../../actions/company';
import MainWidget from '../utils/MainWidgetNew';
import OkrCardItem from '../okrs/noState/OkrCardItem';
import TeamMenu from './TeamMenu';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/1.jpg';
import { getTeamOkrsById, clearOkrs } from '../../actions/okr';
import { FormattedMessage } from 'react-intl';

const TeamOkrs = ({
  clearOkrs,
  getTeamOkrsById,
  getTeamById,
  getUserCompany,
  getCompanyProfiles,
  okr: { okrs, okr, loading },
  auth: { user, sidebar },
  company: { company, team },
  profile: { profiles },
  match,
  locale
}) => {
  useEffect(() => {
    getUserCompany();
    getCompanyProfiles();
    getTeamById(match.params.id);
    getTeamOkrsById(match.params.id);

    // Clear state
    return () => {
      clearOkrs();
    }
  }, [getUserCompany, getCompanyProfiles, getTeamOkrsById, getTeamById]);


  const updatePlanMsg = {
    title: (
      <FormattedMessage
        id="company.updateplan.title"
        defaultMessage="Update Plan"
      />
    ),
    msg: (
      <FormattedMessage
        id="company.okrs.updateplan.msg"
        defaultMessage={`The Free plan is limited by 4 users in the company. You have ${profiles && profiles.length} active users today. Please upgrade the plan to use Focus for the team with 5 and more users.`}
      />
    )
  };

  const noOkrMessageTeam = {
    title: <FormattedMessage id="nookrs.title" defaultMessage="No Objectives" />,
    msg: 
    <FormattedMessage id="nookrs.msg" defaultMessage={`${team && team.name} has not created any objective yet.`} />
  };

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {user && !loading && (
          <MainMenu activeIndex={7} />
        )}

        <UserNav />
        
          <Fragment>
            {loading || !okrs || !user || !company || !team ? (
              <Spinner />
            ) : (
              <Fragment>
                 <div className={`${sidebar === 1 ? 'main main-dashboard ' : 'short_menu__ml__okrs ma-w90 pt-2'} mt-3`}>
                     <h5>
                      <FormattedMessage id="team.header.okrs" defaultMessage={`${team.name} Objectives`} />
                    </h5>
                  <TeamMenu teamId={team._id} companyIndex={3} />
                  <div className="okrs">
                    <div className="mb-4">
                        {okrs.length > 0 && (
                          okrs.map(okr => (
                            okr.isMain && okr.type === 2 && <MainWidget key={`main-${okr._id}`} okr={okr} />
                            )
                          ))} 
                      </div>

                      <div className="text-right pb-1">
                        <Link to="/objective/new" className="btn btn-light">
                          <FormattedMessage
                            id="user.okr.sub_btn"
                            defaultMessage="Create Objective"
                          />
                        </Link>
                      </div>

                      {company.plan === 1 && profiles.length > 4 ? (
                        <NoContent 
                          message={updatePlanMsg}
                          picture={updateImg}
                          alt={'Update plan'}
                          admin={user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0}
                        />
                      ) : (
                        <Fragment>
                          {okrs.length === 0 ? (
                            <div className="mt-5">
                              <NoContent 
                                message={noOkrMessageTeam}
                                picture={updateImg}
                                alt={'No OKRs'}
                              />
                            </div>
                          ) : (
                            <Fragment> 
                                  {okrs.length > 0 && (
                                    okrs.map(okr => (
                                    <div className="" key={`okr-item-${okr._id}`}>
                                      <div className="cards py-2">
                                        <OkrCardItem
                                        key={okr._id}
                                        okr={okr}
                                        okrBoard={'1'}
                                      />
                                    </div>
                                  </div>
                                  )))}
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>

        </div>
      </div>
    </Fragment>
  );
};

TeamOkrs.propTypes = {
  clearOkrs: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getTeamOkrsById: PropTypes.func.isRequired,
  getTeamById: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  okr: state.okr
});

export default connect(
  mapStateToProps,
  { getUserCompany, getCompanyProfiles, getTeamOkrsById, getTeamById, clearOkrs }
)(TeamOkrs);
