import {
  GET_USER_RECOGNITIONS,
  GET_GIVED_USER_RECOGNITIONS,
  GET_TEAM_RECOGNITIONS,
  GET_GIVED_TEAM_RECOGNITIONS,
  GET_RECOGNITIONS,
  RECOGNITION_ERROR,
  RECOGNITIONS_ERROR,
  GIVED_USER_RECOGNITIONS_ERROR,
  GIVED_TEAM_RECOGNITIONS_ERROR,
  GIVED_COMPANY_RECOGNITIONS_ERROR,
  ADD_RECOGNITION,
  GET_RECOGNITION,
  UPDATE_RECOGNITION,
  DELETE_RECOGNITION,
  UPDATE_LIKES,
  ADD_COMMENT,
  UPDATE_COMMENT,
  CLEAR_RECOGNITIONS,
  LOCATION_CHANGE_ERROR,
  GET_COMPANY_RECOGNITIONS,
  GET_GIVED_COMPANY_RECOGNITIONS,
  COMPANY_RECOGNITIONS_ERROR,
  TEAM_RECOGNITIONS_ERROR,
  REMOVE_COMMENT
} from '../actions/types';

const initialState = {
  recognitions: [],
  givedRecognitions: [],
  recognition: null,
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_RECOGNITIONS:
    case LOCATION_CHANGE_ERROR: 
      return {
        ...state,
        recognitions: [],
        givedRecognitions: [],
        recognition: null,
        loading: true
      };
    case GET_RECOGNITIONS:
    case GET_USER_RECOGNITIONS:
    case GET_TEAM_RECOGNITIONS:
      return {
        ...state,
        recognitions: payload,
        loading: false
      };
    case GET_COMPANY_RECOGNITIONS:
      return {
        ...state,
        recognitions: payload,
        givedRecognitions: payload,
        loading: false
      };
    case GET_GIVED_USER_RECOGNITIONS:
    case GET_GIVED_TEAM_RECOGNITIONS:
    case GET_GIVED_COMPANY_RECOGNITIONS:
      return {
        ...state,
        givedRecognitions: payload,
        loading: false
      };
    case GET_RECOGNITION:
      return {
        ...state,
        recognition: payload,
        loading: false
      };
    case ADD_RECOGNITION:
      return {
        ...state,
        recognition: [payload, ...state.recognitions],
        loading: true
      };
    case UPDATE_RECOGNITION:
      return {
        ...state,
        recognition: [payload, ...state.recognitions],
        loading: false
      };
    case DELETE_RECOGNITION:
      return {
        ...state,
        recognitions: state.recognitions.filter(
          recognition => recognition._id !== payload
        ),
        loading: false
      };
    case UPDATE_LIKES:
      return {
        ...state,
        recognitions: state.recognitions.map(recognition =>
          recognition._id === payload.id
            ? { ...recognition, likes: payload.likes }
            : recognition
        ),
        loading: false
      };
    case ADD_COMMENT:
      return {
        ...state,
        recognitions: state.recognitions.map(recognition =>
          recognition._id === payload.id
            ? {
                ...recognition,
                comments: payload.comments
              }
            : recognition
        ),
        loading: false
      };
    case UPDATE_COMMENT:
      return {
        ...state,
        recognitions: state.recognitions.map(recognition =>
          recognition._id === payload.id
            ? {
                ...recognition,
                comments: payload.comments
              }
            : recognition
        ),
        loading: false
      };
    case REMOVE_COMMENT:
      return {
        ...state,
        recognitions: state.recognitions.map(recognition =>
          recognition._id === payload.recognitionID
            ? {
                ...recognition,
                comments: recognition.comments.filter(
                  comment => comment._id !== payload.commentID
                )
              }
            : recognition
        ),
        loading: false
      };
    case RECOGNITION_ERROR:
      return {
        ...state,
        error: payload,
        recognitions: [],
        recognition: null,
        loading: false
      };
    case RECOGNITIONS_ERROR:
    case TEAM_RECOGNITIONS_ERROR:
    case COMPANY_RECOGNITIONS_ERROR:
      return {
        ...state,
        error: payload,
        recognitions: [],
        loading: false
      };
    case GIVED_USER_RECOGNITIONS_ERROR:
    case GIVED_TEAM_RECOGNITIONS_ERROR:
    case GIVED_COMPANY_RECOGNITIONS_ERROR:
      return {
        ...state,
        error: payload,
        givedRecognitions: [],
        loading: false
      };
    case CLEAR_RECOGNITIONS:
      return {
        ...state,
        recognitions: [],
        givedRecognitions: [],
        recognition: null,
        loading: true
      };
    default:
      return state;
  }
}
