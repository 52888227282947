import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';

const BtnTodoAdd = ({ msgId, msg, onSubmit, index, name }) => {
  return (
    <Fragment>
     <button
        onClick={e => onSubmit(e, index, name)}
        className="inline-block bg-lead pointer medium outline brd br-20 cursor px-1 lh-30 mr-2">
        <FormattedMessage id={msgId} defaultMessage={msg} />
      </button>
    </Fragment>
  );
};

BtnTodoAdd.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
};

export default BtnTodoAdd;
