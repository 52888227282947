import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import QuestionItemAnswer from "./UniQuestionItemAnswer";

const getLabel = status => {
  let label = '';
  if (status === 1) {
    label =  <p className="text-left fs-8 uppercase white br-2 brd bg-green px-xs lh-16">On Track</p>
  } else if (status === 2) {
    label =  <p className="text-left fs-8 uppercase white br-2 brd bg-yellow px-xs lh-16">Behind</p>
  } else if (status === 3) {
    label =  <p className="text-left fs-8 uppercase white br-2 brd bg-red px-xs lh-16">At risk</p>
  }

  return label;
};

const UniQuestionItemOkr = ({ 
  question, 
  answer, 
  index, 
  searchKeyword, 
  weeklyOkrs 
}) => {

  return (
    <Fragment>
      <p className={`fs-16 bold ${index === 0 ? "mb-" : "mb-1"}`}>
        <strong>{question}</strong>
      </p>
      <div className="card-answer">
        <ul className="answer-item">
          {answer.map((answer, i) => (
            <Fragment key={i}>
              {index === 0 && (
                <Fragment>
                  <p className="mt-2 fw-600">{answer.okrValue !== "0" ? (!answer.isKr ? 'Objective: ' : 'Key Result: ') : ""}{' '}{answer.okrLabel}</p>
                  {answer.okrValue !== "0" && (
                    <Fragment> 
                      <div className="flex align-center">
                        <p className="text-left fs-16 bold mb-0">{Math.round(answer.progress)}{weeklyOkrs !== undefined && weeklyOkrs[i].focusIndex === 99 ? '%' : ''}</p>
                        <span className="fs-16 mx-xs">/</span>
                        <p className="text-left fs-12 mr-1">{answer.goal}{weeklyOkrs !== undefined && weeklyOkrs[i].focusIndex === 99 ? '%' : ''}</p>
                        {getLabel(answer.status)}
                      </div>
                    </Fragment>
                  )}
                </Fragment>
              )}
             
              <Fragment>
                {answer.text.map((text, i) => (
                  <QuestionItemAnswer
                    key={`${text._id}-${i}`}
                    index={index}
                    text={text}
                    searchKeyword={searchKeyword}
                  />
                ))}
              </Fragment>
            </Fragment>
          ))}
        </ul>
      </div>
    </Fragment>
  );
};

UniQuestionItemOkr.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.object.isRequired,
  avatar: PropTypes.string.isRequired
};

export default UniQuestionItemOkr;
