import React from 'react';
import { FormattedMessage } from 'react-intl';

export const checkinCopy = {
  msgOnboarding: (
    <FormattedMessage
      id="check-in.onboarding.company.notification.msg1"
      defaultMessage="You're using the sample data below. Run your first check-in and you'll see your data there. Click the button below to answer your first daily check-in."
    />
  ),
  ctaOnboarding: (
    <FormattedMessage
      id="check-in.onboarding.company.notification.cta"
      defaultMessage="Complete the check-in"
    />
  ),
  updatePlanMsg: {
    title: (
      <FormattedMessage
        id="company.updateplan.title"
        defaultMessage="Update Plan"
      />
    ),
    msg: (
      <FormattedMessage
        id="company.check-ins.updateplan.msg"
        defaultMessage={`Check-ins aren't available on the Free plan. Please update the plan to unlock this feature.`}
      />
    )
  },
  standupLive1: (
    <FormattedMessage
        id="checkin.cta.complete1"
        defaultMessage="Check-in is going on."
      />
  ),
  standupLive2: (
    <FormattedMessage
        id="checkin.cta.complete2"
        defaultMessage="Click the button below to participate into your daily check-in."
      />
  ),
  standupLiveCta: (
    <FormattedMessage
        id="checkin.cta.completeCta"
        defaultMessage="Check in"
      />
  ),
  standupLiveNew: (
    <FormattedMessage
        id="checkin.cta.complete.new"
        defaultMessage="Daily Check-ins are Going on !"
      />
  ),
  standupLiveCtaNew: (
    <FormattedMessage
        id="checkin.cta.completeCta.new"
        defaultMessage="Share a Daily Check-in"
      />
  )
}

export default {
  checkinCopy
}