import React, { Fragment } from 'react';
import spinnerBtn from './spinner_button.gif';

const SpinnerButton = () => {
  return (
    <Fragment>
      <img
        src={spinnerBtn}
        style={{ 
          width: '30px', 
          margin: 'auto', 
          display: 'block' 
        }}
        alt="Loading..."
      />
  </Fragment>
  );
};

export default SpinnerButton;
