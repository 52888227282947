import React, { Fragment, useState } from 'react';
import { icons } from '../../../img/elements/icons';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const OkrTips = ({ locale, displayTips = false }) => {
  let howUrl = locale && locale.lang !== 'ru' ? `https://usefocus.co/blog/how-to-set-okrs/` : `https://usefocus.co/blog/how-to-set-okrs/`;
  let whatUrl = locale && locale.lang !== 'ru' ? `https://usefocus.co/blog/what-is-okr/` : `https://usefocus.co/blog/what-is-okr/`;
  let mistakesUrl = locale && locale.lang !== 'ru' ? `https://usefocus.co/blog/5-okr-mistakes/` : `https://usefocus.co/blog/5-okr-mistakes/`;
  let marketingUrl = locale && locale.lang !== 'ru' ? `https://usefocus.co/blog/marketing-okr-examples/` : `https://usefocus.co/blog/marketing-okr-examples/`;
  let productUrl =
    locale && locale.lang !== 'ru' ? `https://usefocus.co/blog/23-okr-examples-for-product-managers/` : `https://usefocus.co/blog/23-okr-examples-for-product-managers/`;
  let hrUrl = locale && locale.lang !== 'ru' ? `https://usefocus.co/blog/20-hr-okr-examples/` : `https://usefocus.co/blog/20-hr-okr-examples/`;

  const howArticle = <FormattedMessage id="okr.tips.howToSet" defaultMessage="How to Set Powerful OKRs" />;
  const whatArticle = <FormattedMessage id="okr.tips.whatIsOkr" defaultMessage="What is OKR?" />;
  const mistakesArticle = <FormattedMessage id="okr.tips.mistakesArticle" defaultMessage="5 OKR Mistakes and How to Avoid Them" />;
  const marketingExamples = <FormattedMessage id="okr.tips.marketingExamples" defaultMessage="Marketing OKR Examples" />;
  const productExamples = <FormattedMessage id="okr.tips.productExamples" defaultMessage="OKR Examples for Product Managers" />;
  const hrExamples = <FormattedMessage id="okr.tips.hrExamples" defaultMessage="Human Resources (HR) OKR Examples" />;

  const title = <FormattedMessage id="okr.tips.title" defaultMessage={`Tips on how to set OKRs`} />;
  const text1 = (
    <FormattedMessage
      id="okr.tips.text1"
      defaultMessage={`<b>OKRs (Objectives and Key Results)</b> is the methodology for setting the goals, which is used in companies like Google, LinkedIn, Uber, etc. It aligns teams and people inside an organization and allows employees to focus on the most important goals.`}
      values={{
        b: (...chunks) => <b>{chunks}</b>,
      }}
    />
  );
  const text2 = (
    <FormattedMessage
      id="okr.tips.text2"
      defaultMessage={`An objective is <b>WHAT</b> should be achieved. It’s a big, significant, action-oriented, and ideally an inspirational goal.`}
      values={{
        b: (...chunks) => <b>{chunks}</b>,
      }}
    />
  );
  const text3 = (
    <FormattedMessage
      id="okr.tips.text3"
      defaultMessage={`Key results track <b>HOW</b> we achieve the objective. They are specific, measurable, time-bound, and aggressive.`}
      values={{
        b: (...chunks) => <b>{chunks}</b>,
      }}
    />
  );
  const text4 = (
    <FormattedMessage
      id="okr.tips.text4"
      defaultMessage={`Each objective has key results. 2-5 key results for an objective. Also, don’t create more than 5 objectives for a level in a quarter. Less is more.`}
    />
  );
  const example = <FormattedMessage id="okr.tips.example" defaultMessage={`OKR example:`} />;
  const okr = (
    <FormattedMessage
      id="okr.tips.okr"
      defaultMessage={`<b>Objective:</b> Achieve user acquisition record`}
      values={{
        b: (...chunks) => <b>{chunks}</b>,
      }}
    />
  );
  const kr1 = (
    <FormattedMessage
      id="okr.tips.kr1"
      defaultMessage={`<b>Key Results 1:</b> 50,000 visitors by the end of Q3 2020`}
      values={{
        b: (...chunks) => <b>{chunks}</b>,
      }}
    />
  );
  const kr2 = (
    <FormattedMessage
      id="okr.tips.kr2"
      defaultMessage={`<b>Key Results 2:</b> Get 5,000 signups`}
      values={{
        b: (...chunks) => <b>{chunks}</b>,
      }}
    />
  );
  const kr3 = (
    <FormattedMessage
      id="okr.tips.kr3"
      defaultMessage={`<b>Key Results 3:</b> Achieve 10% visitor to trial conversion rate `}
      values={{
        b: (...chunks) => <b>{chunks}</b>,
      }}
    />
  );
  const articles = <FormattedMessage id="okr.tips.articles" defaultMessage={`More information about OKRs`} />;
  const examples = <FormattedMessage id="okr.tips.examples" defaultMessage={`OKR Examples`} />;

  return (
    <div className="tips mt-2 mb-3">
      {displayTips && (
        <div className="tips__body mt-2">
          <div className="medium">{text1}</div>
          <div className="medium mt-1">{text2}</div>
          <div className="medium my-1">{text3}</div>
          <div className="medium mt-1 mb-2">{text4}</div>
          <div className="medium my-2 bold">{example}</div>
          <div className="medium mt-0">{okr}</div>
          <ul>
            <li className="medium inside">{kr1}</li>
            <li className="medium inside">{kr2}</li>
            <li className="medium inside">{kr3}</li>
          </ul>
          <div className="medium bold mt-2 mb-1">{articles}</div>
          <div className="medium">
            <a href={whatUrl} className="small blue block-wp pb-xs" rel="noopener noreferrer" target="_blank">
              {whatArticle}
            </a>
            <a href={howUrl} className="small blue block-wp pb-xs" rel="noopener noreferrer" target="_blank">
              {howArticle}
            </a>
            <a href={mistakesUrl} className="small blue block-wp pb-xs" rel="noopener noreferrer" target="_blank">
              {mistakesArticle}
            </a>
          </div>
          <div className="medium bold mt-2 mb-1">{examples}</div>
          <div className="medium">
            <a href={marketingUrl} className="small blue block-wp pb-xs" rel="noopener noreferrer" target="_blank">
              {marketingExamples}
            </a>
            <a href={productUrl} className="small blue block-wp pb-xs" rel="noopener noreferrer" target="_blank">
              {productExamples}
            </a>
            <a href={hrUrl} className="small blue block-wp pb-xs" rel="noopener noreferrer" target="_blank">
              {hrExamples}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

OkrTips.propTypes = {
  locale: PropTypes.object.isRequired,
};

export default OkrTips;
