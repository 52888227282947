import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";
import { getUserCompany } from '../../actions/company';
import CreateEmailInvitation from "./CreateEmailInvitationPopup";
import CreateSlackInvitation from "./CreateSlackInvitationPopup";
import Spinner from "../layout/Spinner";
import emailImg from '../../img/icons/inviteemail.svg';
import slackImg from '../../img/icons/slack.svg';

const InvitationPopup = ({
  company: { company, loading },
  getUserCompany,
  toggleModal,
  onSuccess
 }) => {

  const [type, setType] = useState(null);

  useEffect(() => {
    getUserCompany();
  }, [getUserCompany]);

  useEffect(() => {
    if (company && !company.isSlack) {
      setType('email');
    }
  }, [company]);

  const goBack = () => setType(null);

  if (type === 'email') {
    return <CreateEmailInvitation toggleModal={toggleModal} onSuccess={onSuccess} goBack={goBack} />
  }

  if (type === 'slack') {
    return <CreateSlackInvitation toggleModal={toggleModal} onSuccess={onSuccess} goBack={goBack} />
  }

  const close = () => toggleModal(false);

  return (
    <Fragment>
      <div className="modal" style={{ zIndex: 50001 }} onClick={close}>
        <div
          onClick={(e) => e.stopPropagation()}
          className="modal-content br-4"
          style={{
            marginTop: '10%',
            marginBottom: 0
          }}
        >
          <Fragment>
            {loading ? (
              <Spinner />
            ) : (
              <div className="p-5">
                <div className="modal-header">
                  <h5 className="pl-0-xs fw-600">
                    <FormattedMessage id="admin.createInvite.header" defaultMessage="Invite Your Team" />
                  </h5>
                </div>
                <Fragment>
                  <div className="relative br-4 invite-card" onClick={() => setType('email')}>
                    <div className="flex">
                      <img src={emailImg} alt="Invite by Email icon" width="48" height="48"/>
                      <div className="flex-column ml-15">
                        <h3 className="fs-2"><FormattedMessage id="admin.createInvite.email.title" defaultMessage="Invite by Email" /></h3>
                        <p><FormattedMessage id="admin.createInvite.email.description" defaultMessage="Each member will get a link to set up their account." /></p>
                      </div>
                    </div>
                  </div>
                </Fragment>
                {company.isSlack && <Fragment>
                  <div className="relative br-4 invite-card" onClick={() => setType('slack')}>
                    <div className="flex">
                      <img src={slackImg} alt="Invite by Email icon" width="48" height="48"/>
                      <div class="flex-column ml-15">
                        <h3 className="fs-2"><FormattedMessage id="admin.createInvite.slack.title" defaultMessage="Invite from Slack" /></h3>
                        <p><FormattedMessage id="admin.createInvite.slack.description" defaultMessage="Import members from your Slack channels." /></p>
                      </div>
                    </div>
                  </div>
                </Fragment>}
              </div>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

InvitationPopup.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func
};

const mapStateToProps = (state) => ({
  company: state.company
});

export default connect(mapStateToProps, { getUserCompany })(InvitationPopup);