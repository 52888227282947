import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import UserNav from '../layout/UserNav';
import MainMenu from '../layout/Sidebar';
import SubMenu from './SubMenu';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { getUserCompany } from '../../actions/company';
import AdminDashboardItem from './AdminDashboardItem';
import Notification from '../utils/Notification';
import { adminCopy } from '../../utils/wording/admin';
import { FormattedMessage } from 'react-intl';

// For plans check-ins
import { getCompanyProfiles } from '../../actions/profile';
import updateImg from '../../img/feature/1.jpg';

const AdminIntegrations = ({
  getUserCompany,
  getCompanyProfiles,
  profile: { profiles },
  company: { company },
  auth: { loading, user, sidebar }
}) => {
  useEffect(() => {
    getUserCompany();
    getCompanyProfiles();
  }, [
    getUserCompany,
    getCompanyProfiles
  ]);

  const updatePlan2 = (
    <FormattedMessage
      id="admin.updatePlan.msg2"
      defaultMessage={`The Free plan is limited by 4 users in the company. You have ${profiles && profiles.length} active users today. Please upgrade the plan to use Focus for the team with 5 and more users.`}
    />
  );

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">
          
          {/* {user && user.department ? (
            <Menu companyName={user && user.companyName} team={user.departmentName} teamId={user.department} settings={user.isAdmin && true} activeIndex={9} />
          ) : (
            <Menu companyName={user && user.companyName} settings={user && user.isAdmin && true} activeIndex={9} />
          )} */}
          {user && !loading && (
            <MainMenu activeIndex={9} />
          )}

          <UserNav />

          <Fragment>
            {loading || !company || !profiles || user && !user.isAdmin ? (
              <Spinner />
            ) : (
              <Fragment>
                {/* <div className="main main-dashboard main-profile-admin"> */}
                <div className={`${sidebar === 1 ? 'main main-dashboard main-profile-admin ' : 'short_menu__ml__okrs ma-w90 pt-2'} mt-3`}>

                  <h5 className="pl-0-xs">
                    <FormattedMessage
                      id="admin.dashboard.integrations"
                      defaultMessage="Integrations"
                    />
                  </h5>

                  <SubMenu activeIndex={4} />

                  <div className="dashboard-admin">
                    {company.plan === 1 && profiles.length > process.env.REACT_APP_FREE_PLAN_USERS && (
                      <Notification
                        msg={adminCopy.updatePlan}
                        msg2={updatePlan2}
                        cta={adminCopy.ctaPlan}
                        btnColor={'red'}
                        color={`red`}
                        url={`/settings/pricing`}
                      />
                    )}

                    {company.plan === 1 && profiles && profiles.length > process.env.REACT_APP_FREE_PLAN_USERS && (
                      <Notification
                        msg={adminCopy.freePlan}
                        msg2={adminCopy.freePlan2}
                        cta={adminCopy.ctaPlan}
                        btnColor={'orange'}
                        color={`orange`}
                        url={`/settings/pricing`}
                      />
                    )}

                    <Fragment>
                      <AdminDashboardItem
                        title={
                          <FormattedMessage
                            id="admin.dashboard.integrations.sub"
                            defaultMessage="Integrations"
                          />
                        }
                        company={company && company}
                        slack={1}
                        discordObj={1}
                      />
                    </Fragment>
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

AdminIntegrations.propTypes = {
  getUserCompany: PropTypes.func.isRequired,
  getCompanyProfiles: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  company: state.company
});

export default connect(mapStateToProps, {
  getUserCompany,
  getCompanyProfiles
})(AdminIntegrations);
