import React, { useState, Fragment, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import MainMenu from '../layout/Sidebar';
import Menu from '../layout/Menu';
import { connect } from 'react-redux';
import UserNav from '../layout/UserNav';
import Spinner from '../layout/Spinner';
import { getCompanyAnswers } from '../../actions/standupAnswer';
import { getCompanySortedOkrs } from '../../actions/okr';
import { getCompanyProfiles } from '../../actions/profile';
import { getUserCompany } from '../../actions/company';
import MainWidget from '../utils/MainWidget';
import CheckinItem from '../checkins/noState/CheckinItem';
import CompanyMenu from './CompanyMenu';
import NoContent from '../utils/NoContent';
import updateImg from '../../img/feature/1.jpg';
import Notification from '../utils/Notification';
import { onboardingStandups } from '../../utils/onboarding/standups';
import { checkinCopy } from '../../utils/wording/checkins';
import { getStandups } from '../../actions/standup';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import Moment from 'react-moment';

const CompanyCheckins = ({
  getCompanySortedOkrs,
  getUserCompany,
  getCompanyProfiles,
  okr: { okrs },
  auth: { user, sidebar },
  company: { company },
  standupAnswer: { standupAnswers, loading },
  getCompanyAnswers,
  profile: { profiles },
  getStandups,
  standup: { standups }
}) => {
  useEffect(() => {
    getUserCompany();
    getCompanyAnswers();
    getCompanyProfiles();
    getStandups();
    getCompanySortedOkrs();

  }, [getUserCompany, getCompanyAnswers, getCompanyProfiles, loading, getCompanySortedOkrs, getStandups]);


  const noCheckinsMessageTeam = {
    title: <FormattedMessage id="company.nocheckins.user.title" defaultMessage="No Check-ins" />,
    msg: 
    <FormattedMessage id="company.checkins.user.msg" defaultMessage={`${company && company.name} has not finished check-ins yet.`} />
  };

  const standupLink =
    standups.length > 0 ? `/checkins/${standups[0]._id}` : '/checkins';

  // Removing duplicates in answers array based on the object key 'standup' - to send only unique standup items to StandupTeamItem
  // Берем данные из не редакса standupAnswers
  let standupAnswersUnique = standupAnswers ? (standupAnswers.reduce((acc, current) => {
    const x = acc.find(item => item.standup === current.standup);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, [])) : [];

  return (
    <Fragment>
      <div className="css-main bg-primary">
        <div className="wrapper">

        {/* {user && user.department ? (
          <Menu companyName={user && user.companyName}   team={user.departmentName} teamId={user.department} settings={user.isAdmin && true} activeIndex={6} />
        ) : (
          <Menu companyName={user && user.companyName}   settings={user && user.isAdmin && true} activeIndex={6} />
        )} */}
        {user && !loading && (
          <MainMenu activeIndex={6} />
        )}

        <UserNav />
        
          <Fragment>
            {loading || !standupAnswers || !user || !company || !okrs ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className={`${sidebar === 1 ? 'main main-dashboard ' : 'short_menu__ml__okrs ma-w90 pt-2'} mt-3`}>
                    <h5 className="pl-0-xs">
                      <FormattedMessage id="company.header.checkins" defaultMessage="Company Check-ins" />
                    </h5>

                  <CompanyMenu type={1} companyIndex={1} />

                  <div className="checkins">

                    <div className="mb-4">
                        {okrs.length > 0 && (
                          okrs.map(okr => (
                            okr.isMain && okr.type === 1 && <MainWidget key={`main-${okr._id}`} okr={okr} />
                            )
                          ))} 
                      </div>

                    {company.plan !== 1 ? (
                      <Fragment>
                        {standupAnswers.length === 0 ? (
                          <Fragment>
                            <Notification
                              msg={checkinCopy.msgOnboarding}
                              cta={checkinCopy.ctaOnboarding}
                              btnColor={'orange'}
                              color={`orange`}
                              url={standupLink}
                            />
                            <Fragment>
                              {onboardingStandups.map(standup => (
                                <CheckinItem
                                  key={`teamStandups-${standup._id}`}
                                  standupAnswers={onboardingStandups}
                                  standupDate={standup.standupDate}
                              />
                              ))}
                            </Fragment>
                          </Fragment>
                        ) : (
                          <Fragment>
                            {standupAnswersUnique.length > 0 ? (
                              standupAnswersUnique.map(
                                (standupAnswerUnique, index) => (
                                  <Fragment key={`fragment-checkin-${index}`}>
                                    <h3 className="fs-2 pt-2 pl-2 pb-1" key={`date-${index}`}>
                                      <Moment format="D MMM" key={`date-moment-${index}`}>{standupAnswerUnique.standupDate}</Moment>
                                    </h3>

                                    <CheckinItem
                                      key={`checkins-${standupAnswerUnique._id}-${index}`}
                                      standupAnswers={standupAnswers.filter(
                                        standupAnswer =>
                                          standupAnswer.standup.toString() ===
                                          standupAnswerUnique.standup.toString()
                                      )}
                                      standupDate={standupAnswerUnique.standupDate}
                                    />
                                  </Fragment>
                                )
                              )
                            ) : (
                              <div className="mt-5">
                                <NoContent 
                                  message={noCheckinsMessageTeam}
                                  picture={updateImg}
                                  alt={'No checkins'}
                                />
                              </div>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      <NoContent 
                        message={checkinCopy.updatePlanMsg}
                        picture={updateImg}
                        alt={'Update plan'}
                        admin={user.role === 'Owner' || user.role === 'Admin' || user.role === 'Superadmin' ? 1 : 0}
                      />
                    )}
                    
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

CompanyCheckins.propTypes = {
  getCompanyProfiles: PropTypes.func.isRequired,
  getCompanyAnswers: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getStandups: PropTypes.func.isRequired,
  getCompanySortedOkrs: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  standupAnswer: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired,
  standup: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company,
  profile: state.profile,
  standupAnswer: state.standupAnswer,
  standup: state.standup,
  okr: state.okr
});

export default connect(
  mapStateToProps,
  { getCompanyAnswers, getUserCompany, getCompanyProfiles, getCompanySortedOkrs, getStandups }
)(CompanyCheckins);
