import axios from "axios";

import {
  GET_USER_HOME,
  HOME_ERROR,
  ADD_HOME_COMMENT,
  UPDATE_HOME_LIKES,
  REMOVE_HOME_COMMENT_KUDOS,
  UPDATE_HOME_LIKES_ANSWER,
  ADD_HOME_COMMENT_ANSWER,
  REMOVE_HOME_COMMENT_ANSWER
} from "./types";

// Как работает:
// Мы из других actions (recognition, standupanswer, updateanswer глобально store. If store.home не пустой, то делаем оттуда диспатч сюда)

// Get all data for Home page 
export const getHome = () => async dispatch => {
  try {
    const res = await axios.get("/api/home");
    
    dispatch({
      type: GET_USER_HOME,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: HOME_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update likes for Kudos
export const updateHomeLike = (payload) => async dispatch => {
  try {
    
    dispatch({
      type: UPDATE_HOME_LIKES,
      payload
    });
  } catch (err) {
    dispatch({
      type: HOME_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update likes for Answers
export const updateHomeLikeAnswer = (payload) => async dispatch => {
  try {

    dispatch({
      type: UPDATE_HOME_LIKES_ANSWER,
      payload
    });
  } catch (err) {
    dispatch({
      type: HOME_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};



// Add comments for Kudos
export const addHomeComment = (payload) => async dispatch => {
  try {
    
    dispatch({
      type: ADD_HOME_COMMENT,
      payload
    });
  } catch (err) {
    dispatch({
      type: HOME_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Add comments for Kudos
export const addHomeCommentAnswer = (payload) => async dispatch => {
  try {
    
    dispatch({
      type: ADD_HOME_COMMENT_ANSWER,
      payload
    });
  } catch (err) {
    dispatch({
      type: HOME_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete Kudos comments
export const deleteHomeComment = (payload) => async dispatch => {
  try {
    
    dispatch({
      type: REMOVE_HOME_COMMENT_KUDOS,
      payload: { id: payload.recognitionID, commentID: payload.commentID }
    });
  } catch (err) {
    dispatch({
      type: HOME_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete Answers comments
export const deleteHomeCommentAnswer = (payload) => async dispatch => {
  try {
    
    dispatch({
      type: REMOVE_HOME_COMMENT_ANSWER,
      payload
    });
  } catch (err) {
    dispatch({
      type: HOME_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

