import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createInvite } from '../../actions/auth';
import Spinner from '../layout/Spinner';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { icons } from '../../img/elements/icons';
import useAnalyticsEventTracker from '../../utils/analytics/useAnalyticsEventTracker';

const CreateEmailInvitation = ({
  createInvite,
  locale: { lang },
  auth: { user, loading },
  history,
  toggleModal = () => {},
  goBack,
  onSuccess
}) => {
  const [formData, setFormData] = useState({
    email: ''
  });

  const [message, showMessage] = useState(false);
  const { email } = formData;
  const onSubmit = (e) => {
    e.preventDefault();
    useAnalyticsEventTracker('invite', 'invite sent', 'Creating a team invite');

    createInvite(email, lang, history).then((res) => {
      if (res) {
        close();
        onSuccess();
      }
    });
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const close = () => toggleModal(false);

  return (
    <Fragment>
      <div className="modal" style={{ zIndex: 50001 }} onClick={close}>
        <div
          onClick={(e) => e.stopPropagation()}
          className={classNames('modal-content br-4', { 'pt-4': message })}
          style={{
            marginTop: message ? 'calc(10% - 4rem)' : '10%',
            marginBottom: 0
          }}
        >
          <Fragment>
            {!user || loading ? (
              <Spinner />
            ) : (
              <div className="people_invitation p-5">
                <div className="modal-header">
                  <h5 className="pl-0-xs fw-600">
                    <FormattedMessage id="admin.createInvite.header" defaultMessage="Invite Your Team" />
                  </h5>
                </div>

                <div className="form-full">
                  <p className="meduim">
                    <FormattedMessage
                      id="admin.createInvite.text1"
                      defaultMessage="Enter the email addresses of those you want to invite."
                    />
                    <FormattedMessage
                      id="admin.createInvite.text2"
                      defaultMessage="To invite several people - separate the email with a comma."
                    />
                  </p>

                  <form
                    className="form-app 
                  form-app_new form-app-invite"
                  >
                    <div className="form-main  mt-3">
                      <FormattedMessage
                        id="admin.createInvite.emailInput"
                        defaultMessage="Enter an email, or multiple emails separated by a comma"
                      >
                        {(msg) => (
                          <textarea
                            rows="5"
                            type="text"
                            placeholder={msg}
                            name="email"
                            value={email}
                            onChange={(e) => onChange(e)}
                          />
                        )}
                      </FormattedMessage>
                    </div>
                    <div className="flex">
                      <button
                        type="submit"
                        onClick={onSubmit}
                        className="btn btn-form btn_primary_new mt-0 flex align-center"
                      >
                        <span className="rounded-text-icon white">{icons.circle_plus}</span>{' '}
                        <FormattedMessage id="admin.createInvite.mainBtn" defaultMessage="Send Invitation" />
                      </button>
                      <button className="btn-secondary btn-secondary-back" onClick={goBack}>
                        <FormattedMessage id="go.back" defaultMessage="Go Back" />
                      </button>
                      <button
                        type="button"
                        className={classNames({ 'btn-msg_new': true, active: message, 'ml-3': true })}
                        onClick={(e) => {
                          showMessage(!message);
                        }}
                      >
                        <FormattedMessage
                          id="admin.createInvite.secondBtn"
                          defaultMessage="What the invite will say"
                        />
                      </button>
                    </div>
                  </form>

                  <div className="invitation_msg">
                    {message && (
                      <div className="card-body mt-25 light br-4">
                        <p className="list-item medium">
                          <FormattedMessage id="admin.createInvite.letter1" defaultMessage="Hello," />
                          <br />
                          {user.firstName}{' '}
                          <FormattedMessage
                            id="admin.createInvite.letter2"
                            defaultMessage="has invited you to Focus."
                          />
                          <br />
                          <FormattedMessage
                            id="admin.createInvite.letter3"
                            defaultMessage="Please accept this invitation to start using Focus by clicking the link bellow."
                          />
                          <br />
                          <br />
                          <FormattedMessage
                            id="admin.createInvite.letter4"
                            defaultMessage="Focus helps your team stay in sync without
                                meetings and work together better."
                          />
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

CreateEmailInvitation.propTypes = {
  createInvite: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  locale: state.locale,
  auth: state.auth
});

export default connect(mapStateToProps, { createInvite })(withRouter(CreateEmailInvitation));
