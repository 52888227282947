import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { icons } from '../../../img/elements/icons';

const Skip = ({ page }) => {
  let skip = <FormattedMessage id="skip.onboarding" defaultMessage="Skip Onboarding" />
  
  return (
    <div className={`mta pt-2 ${page !== 2 ? '' : 'pb-2'}`}>
      <Link to="/dashboard/objectives">
        <span className="fs-14 flex align-center cursor icon__grey">
          {icons.circle_close_small}
          <span className="ml-xs">{skip}</span>
        </span>
      </Link>
    </div>
  );
};

export default Skip;
