
import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Logo from '../../../elems/Logo';
import PropTypes from 'prop-types';
import { login } from '../../../../actions/auth';
import { prepareCustomOnboarding } from '../../../../actions/custom';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../../layout/Spinner';
import Text from '../../elems/Text';
import Skip from '../../elems/Skip';
import SelectItem from "../../../elems/Select";
import BlueBtn from '../../elems/BlueBtn';
import { getSlackUsers, getSlackChannels } from '../../../../actions/slack';
import { getUserCompany } from '../../../../actions/company';


const scheduleObj = {
  index: '1',
  day: 'Monday',
  weekDays: [
    {index: 1, day: "Mo", isActive: false},
    {index: 2, day: "Tu", isActive: false},
    {index: 3, day: "We", isActive: false},
    {index: 4, day: "Th", isActive: false},
    {index: 5, day: "Fr", isActive: false},
    {index: 6, day: "Sa", isActive: false},
    {index: 0, day: "Su", isActive: false},
  ]
}
let intervalOptions = [
  { label: `Daily`, value: 'Daily', name: 'interval' },
  { label: `Weekly`, value: 'Weekly', name: 'interval' },
  { label: `Bi-weekly`, value: 'Biweekly', name: 'interval' },
  { label: `Monthly`, value: 'Monthly', name: 'interval' },
];

let daysOptions = [
  { label: `Monday`, value: '1', name: 'weekday' },
  { label: `Tuesday`, value: '2', name: 'weekday' },
  { label: `Wednesday`, value: '3', name: 'weekday' },
  { label: `Thursday`, value: '4', name: 'weekday' },
  { label: `Friday`, value: '5', name: 'weekday' },
  { label: `Saturday`, value: '6', name: 'weekday' },
  { label: `Sunday`, value: '7', name: 'weekday' },
];

const wordingParticipants = {
  placeholderId: "choose.participants",
  placeholderMsg: "Choose participants",
}

const wordingResults = {
  placeholderId: "select.channel",
  placeholderMsg: "Select Channel",
}

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: state.isFocused ? '#404ff5' : '#e9eaf5',
    boxShadow: state.isFocused ? '0px 0px 20px #404FF54D' : 'none',
    border: state.isFocused ? '1px solid #404ff5' : styles.border,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  input: () => ({
    fontSize: '14px',
    height: '38px',
  }),
  placeholder: styles => ({ ...styles, fontSize: '14px' }),
  singleValue: (provided, state) => {
    const fontSize = '14px';

    return { ...provided, fontSize, };
  }
}

const SlackSetup = ({ 
  user, 
  prepareCustomOnboarding, 
  history,
  slack,
  getSlackUsers,
  getSlackChannels,
  getUserCompany,
  company: { company },
 }) => {
  const [formData, setFormData] = useState({
    slackIsActive: true,
    selectedSlackUsers: [],
    selectedOption: []
  });

  // const { interval, scheduleDay, scheduleTime } = formData;
  
  let options = [];
  let choosedUsers = [];
  let channelOptions = [];
  let choosedChannels = [];
  let choosedSlackUsers = [];
  let slackUserAndChannelOptions = []  

  // Slack options (users + channels in one arr)
  slack.users &&
    slack.users.forEach((user) => {
      let u = {
        value: user.user_id,
        label: user.name,
        team: 3,
        name: 'selectedSlackUsers'
      };

      slackUserAndChannelOptions.push(u);
    });

  slack.channels &&
    slack.channels.forEach((channel, index) => {
      let u = {
        value: channel.channel_id,
        label: `#${channel.name}`,
        team: 2,
        name: 'selectedSlackUsers'
      };

      slackUserAndChannelOptions.unshift(u);

      if (slack && index === slack.channels.length - 1) {
         // First option is the company
          let name = { value: 'Company', label: `Everyone`, team: 1, name: 'selectedSlackUsers' };
          slackUserAndChannelOptions.unshift(name);
       }
    });

  // Users Options for Select format (for delivery report)
  slack.users &&
    slack.users.forEach(user => {
      let u = {
        value: user.user_id,
        label: user.name,
        avatar: user.avatar
      };

      options.push(u);
    });

  // Channels Options for Select format
  slack.channels &&
    slack.channels.forEach(channel => {
      let u = {
        value: channel.channel_id,
        label: channel.name,
        name: 'selectedOption',
      };

      channelOptions.push(u);
    });
  
  useEffect(() => {
    getUserCompany();

    if (company && company.isSlack) {
      getSlackUsers(company._id, company.slackTeamId);
      getSlackChannels(company._id, company.slackTeamId);
    }
  }, [getUserCompany, getSlackUsers, getSlackChannels, company && company.isSlack]);

  const onSubmit = e => {
    e.preventDefault();

    prepareCustomOnboarding(formData, history, 2);
  };

  const handleSelect = (selectedOption) => {
    
    // Multi select gives array, not multi gives object
    let name = Array.isArray(selectedOption) ? selectedOption[0].name : selectedOption.name;
    let value = Array.isArray(selectedOption) ? selectedOption: [selectedOption];
    
    setFormData({ ...formData, [name]: value });
  };

  let title = <FormattedMessage id="slack.setup" defaultMessage="Slack Setup" />
  let message = <FormattedMessage id="onboarding.checkin.slack" defaultMessage="Select channels/members you wish to participate in the check-in and where to deliver the results." />
  let next = <FormattedMessage id="next.step" defaultMessage="Next Step" />

  // console.log('===== slack', slack)
  // console.log('===== formData', formData)
  
  return (
    <Fragment>
      {!user ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="css-main onboarding bg__white">
            <div className="wrapper">
              <div className="flex">
                <div className="onboarding__content relative">
                  <div className="">
                    <div className="pb-8">
                      <Logo isNoLink={true} />
                    </div>
                    <Text title={title} text={message} page={4} />

                    <div className="pt-3 form-app form-app-okr">
                      <div className="form-main form-main-inline standups-time flex-column-xs mb-0">
                        <div className="w-100 mb-3-xs labeled-wrapper pt-1">
                          <p className="fs-12 w-100 mb-xs bold-pure">
                            <FormattedMessage id="checkin.members" defaultMessage="Check-in members" />
                          </p>
                          <SelectItem 
                            onChange={handleSelect}
                            options={slackUserAndChannelOptions}
                            customStyles={customStyles}
                            isClearable={false}
                            key={`checkin_users-onboarding`}
                            wording={wordingParticipants}
                            isMulti={true}
                          />
                        </div>
                      </div>

                      <div className="w-100 mb-3-xs labeled-wrapper pt-1">
                        <p className="fs-12 w-100 mb-xs bold-pure">
                          <FormattedMessage id="deliver.results" defaultMessage="Deliver Results" />
                        </p>
                        <SelectItem 
                          onChange={handleSelect}
                          options={channelOptions}
                          customStyles={customStyles}
                          isClearable={false}
                          key={`deliver_results-onboarding`}
                          wording={wordingResults}
                          isMulti={false}
                        />
                      </div>

                      <div className="w-100 buttons pt-3">
                        <BlueBtn onClick={onSubmit} text={next} isLoader={false} />
                      </div>
                    </div>
                  </div>
                  
                  <Skip/>
                </div>
                <div className="onboarding__image onboarding__image__checkin__slack"></div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

SlackSetup.propTypes = {
  login: PropTypes.func.isRequired,
  prepareCustomOnboarding: PropTypes.func.isRequired,
  getUserCompany: PropTypes.func.isRequired,
  getSlackUsers: PropTypes.func.isRequired,
  getSlackChannels: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  slack: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.user,
  slack: state.slack,
  company: state.company,
});

export default connect(
  mapStateToProps,
  { login, prepareCustomOnboarding, getSlackUsers, getSlackChannels, getUserCompany }
)(SlackSetup);
