import {
  GET_USER_OKRS,
  GET_ALL_USER_OKRS,
  GET_USER_OKR,
  GET_TEAM_OKRS,
  GET_COMPANY_OKRS_FOR_EDIT,
  GET_OKR,
  GET_USER_METRICS,
  USER_METRICS_ERROR,
  GET_COMPANY_METRICS,
  COMPANY_METRICS_ERROR,
  GET_OKR_FOR_EDIT,
  GET_OKRS,
  OKR_ERROR,
  USER_OKRS_ERROR,
  USER_OKR_ERROR,
  OKRS_ERROR,
  TEAM_OKRS_ERROR,
  ADD_OKR,
  EDIT_OKR,
  UPDATE_OKR,
  UPDATE_OKRS,
  ADD_MAIN_OKR,
  REMOVE_MAIN_OKR,
  UPDATE_OKR_LIKES,
  OKR_LIKES_ERROR,
  OKR_COMMENT_ERROR,
  ADD_OKR_COMMENT,
  UPDATE_OKR_COMMENT,
  GET_COMPANY_OKRS,
  GET_COMPANY_OKR,
  COMPANY_OKR_ERROR,
  COMPANY_OKRS_ERROR,
  DELETE_OKR,
  CLEAR_OKRS,
  CLEAR_OKR,
  LOCATION_CHANGE_ERROR,
  MAIN_OKR_ERROR,
  REMOVE_OKR_COMMENT,
  UPDATE_CHILDS,
  CLEAN_DATA_OKR,
  ARCHIVE_OKR,
  UNARCHIVE_OKR,
  GET_TREE_OKRS,
  TREE_OKRS_ERROR,
  GET_ACTIVE_OKRS,
  ACTIVE_OKRS_ERROR,
  REMOVE_OKR_UPDATE,
  OKR_UPDATE_ERROR,
  GET_OKR_FOR_URL,
  CHANGE_OKR_FILTERS,
  PREPARE_FIRST_OKR,
  GET_ONBOARDING_OKR,
  REMOVE_ONBOARDING_OKR,
  CHECK_OKR_FILTERS,
} from "../actions/types";

const initialState = {
  okrs: [],
  okr: null,
  data: [],
  tree: [],
  loading: true,
  isHomeLoadingOkr: true,
  isEditLoading: true,
  isMetricsLoading: true,
  filters: localStorage && localStorage.okr_filter ? JSON.parse(localStorage.okr_filter) : {team:null,time:null,company:null},
  error: {}
};

// console.log('====== initialState.filters', initialState.filters)


export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_OKRS:
    case LOCATION_CHANGE_ERROR: 
      return {
        ...state,
        okrs: [],
        okr: null,
        data: [],
        loading: true,
        isHomeLoadingOkr: true,
        isEditLoading: true,
        isMetricsLoading: true,
      };
    case CLEAR_OKR:
      return {
        ...state,
        okr: null,
        loading: true,
      };
    case CLEAN_DATA_OKR:
      return {
        ...state,
        data: [],
        isEditLoading: true,
        };
    case PREPARE_FIRST_OKR:
      localStorage.setItem('custom_okr', payload);
      return {
        ...state,
        okr: payload,
        loading: false
      };
    case GET_ONBOARDING_OKR:
      return {
        ...state,
        okr: localStorage.getItem('custom_okr'), 
        loading: false
      };
    case REMOVE_ONBOARDING_OKR:
      localStorage.removeItem('custom_okr');
      return {
        ...state
      };
    case GET_OKRS:
    case GET_USER_OKRS:
    case GET_ALL_USER_OKRS:
    case GET_TEAM_OKRS:
    case GET_ACTIVE_OKRS:
      return {
        ...state,
        okrs: payload,
        loading: false
      };
    case GET_USER_METRICS:
      return {
        ...state,
        data: payload.userMetrics,
        isMetricsLoading: false
      };
    case GET_COMPANY_METRICS:
      return {
        ...state,
        data: payload.metrics,
        isMetricsLoading: false
      };
    case GET_COMPANY_OKRS:
      return {
        ...state,
        okrs: payload,
        loading: false,
        isHomeLoadingOkr: false,
        // filters: localStorage && localStorage.okr_filter ? JSON.parse(localStorage.okr_filter) : {team:null,time:null}
      };
    case GET_COMPANY_OKRS_FOR_EDIT:
      return {
        ...state,
        okrs: payload,
        loading: false
      };
    case GET_OKR:
    case GET_USER_OKR:
    case GET_COMPANY_OKR:
      return {
        ...state,
        okr: payload,
        loading: false
      };
    case GET_OKR_FOR_EDIT:
      return {
        ...state,
        data: payload,
        isEditLoading: false
      };
    case GET_OKR_FOR_URL:
      return {
        ...state,
        okr: payload,
        isEditLoading: false
      };
    case GET_TREE_OKRS:
      return {
        ...state,
        tree: payload,
        // data: [],   // НЕ ДЕЛАТЬ ЭТОГО - иначе в OkrNew рекурсивный цикл в useEffect => делаем сброс данных, чтобы при редактировании ОКР из дерева подставлялся правильный ОКР
        loading: false, // if tree in OkrNew, then we should turn off it
      };
    case ADD_OKR:
      return {
        ...state,
        okrs: [payload, ...state.okrs],
        loading: true
      };
    case EDIT_OKR:
      return {
        ...state,
        okrs: state.okrs.map(okr =>
          okr._id === payload.id ? payload.data : okr
        ),
        okr: state.okr && state.okr._id === payload.id ? payload.data : state.okr,
        tree: state.tree && state.tree.length > 0 && state.tree.map(okr =>
          okr._id === payload.id ? payload.data : okr
        ),
        tree: [],   // делаем сброс, чтобы дерево всегда обновлялось (без него useEffect не срабатывает в дерево)
        loading: true // делаем, чтобы на следующей странице был Спиннер
      }
    case ARCHIVE_OKR:
    case UNARCHIVE_OKR:
      return {
        ...state,
        okrs: state.okrs.map(okr =>
          okr._id === payload.id ? payload.data : okr
        ),
        okr: state.okr && state.okr._id === payload.id ? payload.data : state.okr,
        tree: payload.isTree !== undefined && state.tree.length > 0 ? (
          state.tree.map(okr => 
            okr._id === payload.id ? payload.data : okr
        )) : (state.tree),
      }
    case UPDATE_OKR:
      return {
        ...state,
        okrs: state.okrs.map(okr =>
          okr._id === payload.id ? payload.data : okr
        ),
        okr: state.okr && state.okr._id === payload.id ? payload.data : state.okr,
      }
    case UPDATE_OKRS:
      return {
        ...state,
        okrs: state.okrs.map(okr =>
          payload.data.find(item => item._id === okr._id) || okr),
        loading: false
      }
    case ADD_MAIN_OKR:
    case REMOVE_MAIN_OKR:
      return {
        ...state,
        okrs: state.okrs.map(okr =>
          okr._id === payload.id ? { ...okr, isMain: payload.main.isMain, isMainIndex: payload.main.isMainIndex } : okr,
        ),
        okr: state.okr && state.okr._id === payload.id ? { ...state.okr, isMain: payload.main.isMain, isMainIndex: payload.main.isMainIndex } : state.okr,
        loading: false
      };
    case UPDATE_OKR_LIKES:
      return {
        ...state,
        okrs: state.okrs.map(okr =>
          okr._id === payload.id ? { ...okr, likes: payload.likes } : okr
        ),
        okr: state.okr && state.okr._id === payload.id ? { ...state.okr, likes: payload.likes } : state.okr,
        loading: false
      };
    case ADD_OKR_COMMENT:
      return {
        ...state,
        okrs: state.okrs.map(okr =>
          okr._id === payload.id
            ? {
                ...okr,
                comments: payload.comments
              }
            : okr
        ),
        okr: state.okr && state.okr._id === payload.id ? {
          ...state.okr,
          comments: payload.comments
        } : state.okr,
        loading: false
      };
    case UPDATE_OKR_COMMENT:
      return {
        ...state,
        okrs: state.okrs.map(okr =>
          okr._id === payload.id
            ? {
                ...okr,
                comments: payload.comments
              }
            : okr
        ),
        okr: state.okr && state.okr._id === payload.id ? {
          ...state.okr,
          comments: payload.comments
        } : state.okr,
        loading: false
      };
    case REMOVE_OKR_COMMENT:
      return {
        ...state,
        okrs: state.okrs.map(okr =>
          okr._id === payload.okrID
            ? {
                ...okr,
                comments: okr.comments.filter(
                  comment => comment._id !== payload.commentID
                )
              }
            : okr
        ),
        okr: state.okr && state.okr._id === payload.okrID ? {
          ...state.okr,
          comments: state.okr.comments.filter(
            comment => comment._id !== payload.commentID
          )
        } : state.okr,
        loading: false
      };
    case REMOVE_OKR_UPDATE:
      return {
        ...state,
        okrs: state.okrs.map(okr =>
          okr._id === payload.okrID
            ? {
                ...okr,
                updates: okr.updates.filter(
                  update => update._id !== payload.updateId
                )
              }
            : okr
        ),
        loading: false
      };
    case DELETE_OKR:
      return {
        ...state,
        okrs: state.okrs.filter(okr => okr._id !== payload),
        okr: state.okr && state.okr._id === payload && state.okr, 
        tree: state.tree && state.tree.length > 0 && state.tree.filter(okr => okr._id !== payload), 
        loading: false
      };
    case UPDATE_CHILDS:
        return {
          ...state,
          okrs: state.okrs.map(okr =>
            payload.data.find(item => item._id === okr._id) || okr),
          loading: false
        };  
    case OKRS_ERROR:
    case USER_OKRS_ERROR:
    case TEAM_OKRS_ERROR:
    case COMPANY_OKRS_ERROR:
    case TREE_OKRS_ERROR: 
    case ACTIVE_OKRS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case USER_OKR_ERROR:
    case COMPANY_OKR_ERROR:
    case MAIN_OKR_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case OKR_COMMENT_ERROR:
    case OKR_ERROR:
    case USER_METRICS_ERROR:
    case COMPANY_METRICS_ERROR:
    case OKR_UPDATE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    // case CLEAR_OKRS:
    //   return {
    //     ...state,
    //     okrs: [],
    //     okr: null,
    //     loading: true,
    //     isHomeLoadingOkr: true
    //   };
    case CHANGE_OKR_FILTERS:
      localStorage.setItem('okr_filter', JSON.stringify({ team: payload.team, time: payload.time, company: payload.company }));
      return {
        ...state,
        filters:{team:payload.team,time:payload.time,company:payload.company}
      }
    case CHECK_OKR_FILTERS:
      // console.log('=== payload.company in CHECK_OKR_FILTERS ===', payload.company)
      // console.log('=== state.filters.company in CHECK_OKR_FILTERS ===', state.filters.company)
      if (state.filters && state.filters.company && payload.company && state.filters && state.filters.company !== payload.company || !state.filters.company) {
        localStorage.setItem('okr_filter', JSON.stringify({ team: null, time: null, company: null }));
      }
      return {
        ...state,
        filters: state.filters && state.filters.company && state.filters.company === payload.company ? state.filters : {team:null,time:null,company:null}
      }
    default:
      return state;
  }
}
