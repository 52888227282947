import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { icons } from '../../img/elements/icons';
import { hideCheckinModal } from '../../actions/custom';
import { connect } from 'react-redux';

const today = new Date();
const lastWeek = new Date(new Date() - (7 * 60 * 60 * 24 + 8) * 1000);
const lastCentury = new Date('1999-10-20T00:00:00');

// Show modal if the click on the button was a week ago or more
const BottomModal = ({ modalBox, title, text, btnTxt, btnLink, cancelTxt, hideCheckinModal, }) => {
  const [isModalVisible, setModalVisible] = useState(true);

  const hideModal = async () => {
    try {
      setModalVisible(!isModalVisible)
      await hideCheckinModal(today)
    } catch (err) {
      console.log('Error in hideModal ', err);
    }
  };

  let pastingDate = JSON.parse(modalBox)
  let lastClickDate = new Date(pastingDate)

  // console.log('======== lastClickDate', lastClickDate);
  // console.log('======== lastWeek', lastWeek);

  return (
    <Fragment>
      {!modalBox || modalBox !== null && lastClickDate < lastCentury ? (
        <div className={`modal__wrapper ${!isModalVisible ? 'modal__wrapper__close' : ''} bg-blue z-30000 br-4`}>
          <div className="flex h-15 pt-1 pr-1">
              <span className="mla cursor" onClick={(e) => hideModal()}>{icons.x}</span>
            </div>
          <div className="modal__corner__content">
            <div className="modal__header pb-1">
              <p className="white fs-22 fw-600">{title}</p>
            </div>
            <div className="modal__text pb-2">
              <p className="white fs-16">{text}</p>
            </div>
            <div className="flex">
              <div className="modal__btn__cta" onClick={(e) => hideModal()}>
                <Link to={btnLink} className="modal__corner__btn btn-secondary h-44 fw-600 btn-secondary-mix-white btn-secondary__blue-hover blue mr-1 py-0 px-3 flex align-center">
                  {btnTxt}
                </Link>
              </div>
              <div 
                className="modal__corner__btn modal__btn__cancel cursor flex align-center h-44 br-4 brd brd-white px-3 fw-600"
                // onClick={(e) => setModalVisible(!isModalVisible)}
                onClick={(e) => hideModal()}
              >
                {cancelTxt}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default connect(null, {
  hideCheckinModal
})(BottomModal);
