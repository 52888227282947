import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { icons } from '../../../img/elements/icons';
import { FormattedMessage } from 'react-intl';
import RoundImg from '../../elems/RoundImg';

const OneNotes = ({
  item,
  handleSelect,
  index,
  user,
  handleDelete,
  handleNameForm,
  name,
  inputName,
  isTodo,
  clearTodo,
  isForm,
}) => {

  let firstName = item.firstName;
  let lastName = item.lastName;
  let avatar = item.avatar;
  let date = item.date;
  let updatedAt = item.updatedAt;
  let text = item.text;
  let _id = item._id;
  let isNew = item.isNew;

  let time = (updatedAt !== undefined) ? updatedAt : date;

  let inputValue = text;
  if (isTodo) { inputValue = item.name };
  
  const [isNameUpdate, setNameUpdate] = useState(false);
  const [inHover, setHover] = useState(false);

  const handleMouseLeave = (e, _id) => {
    handleSelect(_id, e.target.value, name, index, inputName);

    setNameUpdate(!isNameUpdate);
  };

  const handleKeypress = (e, id) => {
    // it triggers by pressing the enter key
    if (e.key === 'Enter') {
      handleSelect(id, e.target.value, name, index, inputName);

      setNameUpdate(!isNameUpdate);
    }
  };

   return (
    <div className="flex mb-xs">
        <div className="user mr-1 fs1">
          <RoundImg
            src={avatar}
            alt={`${firstName} avatar`}
            w="45"
            h="45"
            styles="brd brd-primary medium-shadow"
          />
        </div>

       <div className="flex flex-column w-100">
         <div className="flex align-center">
           <p className="medium pure-bold pr-3">
              <strong>
                {firstName}{" "}{lastName}
              </strong>
            </p>

           <time className="js-local-time comment-date">
              <Moment format="ddd LLL">{time}</Moment>
            </time>
         </div>

          <Fragment>
            <div
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              className={`card-item card-team pb-xs flex align-center`}>
            <div
              className="flex space-between align-center pr-2 relative w-95"
              >
              <div className={`w-90 mr-2 class flex align-center`}>
                  {!isNameUpdate ? (
                    <p
                      onClick={() => setNameUpdate(!isNameUpdate)}
                      className={`medium card-subtitle cursor inline-block lh-30`}
                    >
                      {inputValue}
                    </p>
                  ) : (
                      <FormattedMessage
                        id="select.task"
                        defaultMessage="Add task"
                      >
                        {msg => (
                          <textarea
                            className={`w-100 medium card-subtitle brd-transparent brd_bottom_grey outline`}
                            type="text"
                            rows={inputValue.length < 40 ? "1" : "2"}
                            required
                            value={inputValue || ''}
                            onChange={e =>
                              handleNameForm(e, index, _id, name, inputName)
                            }
                            onKeyPress={e => handleKeypress(e, _id)}
                            onMouseLeave={e => handleMouseLeave(e, _id)}
                            placeholder={msg}
                            name={inputValue}
                          />
                        )}
                      </FormattedMessage>
                  )}
                </div>
            </div>

              <div className="w-30px">
                {inHover && (
                  user && item.user === user._id || user && user.role === "Admin" || user && user.role === "Owner" || user && user.role === "Superadmin" ? (
                    <div className="btn-action no_btn ml-xs relative bg-transparent" type="button">
                      {icons.more}

                      <div className="dropdown p-1 light-shadow">
                        <button 
                            type="button"
                            onClick={isForm ? (() => clearTodo(index, name)) : (() => handleDelete(_id, index, name))}
                            className="small secondary-font no_btn"
                          >
                            <i className="fas fa-times" />{' '}
                            <FormattedMessage
                              id="delete"
                              defaultMessage="Delete"
                            />
                          </button>
                      </div>
                  </div>
                  ) : null
                    )}
              </div>
            </div>
          </Fragment>

        </div>
    </div>
  );
};

OneNotes.propTypes = {
  item: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(OneNotes);
