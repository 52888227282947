import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Select from 'react-select';

// type: 1 - checkin, 2 - update, 3 plan, 4 - custom, 5 - icebreaker
// type: 10 - slack settings: checkins
// type: 11 - slack settings: weekly plans
// type: 12 - slack settings: weekly updates
// type: 13 - slack settings: customs
// type: 14 - slack settings: icebreakers

const SlackItem = ({
  formData,
  setFormData,
  slackIsActive,
  isAsync,
  choosedSlackUsers,
  handleSlackUsersForm,
  emailUsersForm,
  slackUsersForm,
  profiles,
  handleSlackUsers,
  slackUserAndChannelOptions,
  slackReportChannels,
  handleReport,
  reportChannels,
  slack,
  choosedUsers,
  handleChangeReport,
  options,
  choosedChannels,
  handleChangeChannelReport,
  channelOptions,
  isHideReports,
  isNotMulti,
  type,
  sectionTitle,
  handleCheckbox,
  refreshSlackUsers,
 }) => {

  let multi = true;

  if (isNotMulti) { multi = false };

  let participants = <FormattedMessage id="participants.slack" defaultMessage="Participants in Slack" />;
  let chooseUsersText = <FormattedMessage
      id="admin.dashboard.edit.users.slack.description"
      defaultMessage="Choose <b>a public channel</b> or team members who will participate in the campaign. If you choose a public channel, then all members of this channel will receive notifications. <b>NOTE:</b> If you want to run campaign in a channel, make sure that you <b>added the Focus bot to this channel</b>."
      values={{ b: (...chunks) => <b>{chunks}</b> }}
  />
  let teamMembers = <FormattedMessage id="admin.dashboard.edit.users.users" defaultMessage="Team members and Channels" />;

  // Check if it's Icebreaker
  if (type === 5 || type == 14) {
    teamMembers = <FormattedMessage id="public.channel" defaultMessage="Public Channel" />;
    participants = <FormattedMessage id="channels" defaultMessage="Channel" />;
    chooseUsersText = <FormattedMessage
      id="admin.dashboard.edit.users.slack.description"
      defaultMessage=" Choose <b>a public channel</b> for icebreakers. The Focus bot publishes icebreakers in the public channel. <b>NOTE:</b> Please add the bot to this channel</b>."
      values={{ b: (...chunks) => <b>{chunks}</b> }}
    />;
  }

  let title = sectionTitle;

  if (!sectionTitle) {
    title = <FormattedMessage id="admin.dashboard.edit.slack" defaultMessage="Slack" />
  }

  let checkboxNameOne = 'status';
  let checkboxNameTwo = 'isAsync';

  if (type === 11) {
    // Weekly plans in slack settings
    checkboxNameOne = 'status_plan'
    checkboxNameTwo = 'isAsync_plan'
  } else if (type === 12) {
    // Weekly updates in slack settings
    checkboxNameOne = 'status_update'
    checkboxNameTwo = 'isAsync_update'
  } else if (type === 14) {
    // Icebreakers in slack settings
    checkboxNameOne = 'status_icebreaker'
    checkboxNameTwo = 'isAsync_icebreaker'
  }

  return (
    <Fragment>
      <p className="sub-title focus-yellow focus my-2">
        {title}
      </p>

      <div className="form-main form-main-inline standups-time mt-2">
        <p className="bold medium block question">
          <FormattedMessage
            id="admin.dashboard.edit.status"
            defaultMessage="Status"
          />
        </p>

        <div className="checkbox status pl-3 w-30">
          <div className="checkbox-item active-status">
            <input
              type="checkbox"
              id={checkboxNameOne}
              name={checkboxNameOne}
              checked={slackIsActive}
              value={slackIsActive}
              onChange={e => handleCheckbox('slackIsActive', !slackIsActive)}
            />
            <label htmlFor={checkboxNameOne}>
              {slackIsActive ? (
                <FormattedMessage
                  id="admin.dashboard.edit.slack.active"
                  defaultMessage="Active"
                />
              ) : (
                  <FormattedMessage
                    id="admin.dashboard.edit.slack.disable"
                    defaultMessage="Disabled"
                  />
                )}
            </label>
          </div>
        </div>

        {type !== 14 && (
          <p className="bold medium block question mla w-100-xs">
            <FormattedMessage
              id="admin.dashboard.edit.isasync"
              defaultMessage="Asynchronous Standups"
            />
            </p>
        )}

        {type !== 14 && (
          <div className="w-120px checkbox status pl-3 mra checkbox__async">
            <div className="checkbox-item active-status">
              <input
                type="checkbox"
                id={checkboxNameTwo}
                name={checkboxNameTwo}
                checked={isAsync}
                value={isAsync}
                onChange={e => handleCheckbox('isAsync', !isAsync)}
              />
              <label htmlFor={checkboxNameTwo}>
                {isAsync ? (
                  <FormattedMessage
                    id="admin.dashboard.edit.isasync.active"
                    defaultMessage="Active"
                  />
                ) : (
                    <FormattedMessage
                      id="admin.dashboard.edit.isasync.disable"
                      defaultMessage="Disabled"
                    />
                  )}
              </label>
            </div>
          </div>
          )}
      </div>

      <div className="form-main form-main-inline mt-2">
        <p className="bold medium block question w-30 w-100-xs">
          {participants}
        </p>

        <div className="w-unset pl-3 w-30 pt-">
          {choosedSlackUsers &&
          choosedSlackUsers.length > 0 && (
            choosedSlackUsers.map((item, i) => (
              <p
                className="small w-100 inline"
                key={`${item.value}-${i}`}
              >
                {' '}
                {item.label}
              </p>
            ))
          )}
        </div>

        <span
          className="btn-secondary btn-secondary-back"
          onClick={e => handleSlackUsersForm()}
        >
          {slackUsersForm ? (
            <FormattedMessage
              id="admin.dashboard.edit.users.btn-hide"
              defaultMessage="Hide participants"
            />
          ) : (
                <FormattedMessage
                  id="admin.dashboard.edit.users.btn"
                  defaultMessage="Choose participants"
                />
            )}
        </span>
      </div>

    {slackUsersForm && (
      <Fragment>
        <p className="medium block">
            {chooseUsersText}
        </p>
        <div className={isHideReports && 'mb-3'} >
          <div className="flex align-center mb-2">
              <p className="bold medium block question pb-0 mb-0">
                {teamMembers}
              </p>

              <span
                className="btn-secondary btn-secondary-back mla"
                onClick={e => refreshSlackUsers(e)}
              >
                <FormattedMessage
                  id="refresh.users"
                  defaultMessage="Refresh users"
                />
              </span>
          </div>
          <FormattedMessage
              id="admin.dashboard.edit.users.slack.userPlaceholder"
            defaultMessage="Add users"
          >
            {msg => (
              <Select
                key={profiles && `${profiles._id}-U`}
                defaultValue={choosedSlackUsers}
                onChange={selectedOption =>
                  handleSlackUsers(selectedOption)
                }
                options={slackUserAndChannelOptions}
                isMulti={multi}
                isSearchable
                placeholder={msg}
              />
            )}
            </FormattedMessage>
          </div>
      </Fragment>
    )}
    

      {!isHideReports && (<div className="form-main form-main-inline mt-2">
        <p className="bold medium block question w-100-xs">
          <FormattedMessage
            id="admin.dashboard.edit.reports"
            defaultMessage="Delivery results"
          />
        </p>

        <div className="w-unset pt-">
          {slackReportChannels &&
            slackReportChannels.length > 0 ? (
              slackReportChannels.map(item => (
                <p
                  className="small mr-2 w-unset"
                  key={`${item._id}-updates`}
                >
                  {' '}
                  {item.name}
                </p>
              ))
            ) : (
              <p className="small mr-2 w-unset">
                <FormattedMessage
                  id="admin.dashboard.edit.reports.nocontent"
                  defaultMessage="No reports"
                />
                </p>
            )}
        </div>

        <span
          className="btn-secondary btn-secondary-back"
          onClick={e => handleReport(e)}
        >
          {reportChannels ? (<FormattedMessage
            id="admin.dashboard.edit.reports.btn-hide"
            defaultMessage="Hide channels"
          />) : (
              <FormattedMessage
                id="admin.dashboard.edit.reports.btn"
                defaultMessage="Choose channels"
              />
            )}
        </span>
      </div>)}

      {reportChannels && type !== 14 && (
        <Fragment>
          <p className="medium block">
            <FormattedMessage
              id="admin.dashboard.edit.reports.description"
              defaultMessage="Choose channels or users who will receive reports. If you choose public channel for reports, please add Focus bot to this channel."
            />
          </p>
          <div>
            <p className="bold medium block question">
              <FormattedMessage
                id="admin.dashboard.edit.reports.users"
                defaultMessage="Users (via direct messages)"
              />
            </p>
            <FormattedMessage
              id="admin.dashboard.edit.reports.userPlaceholder"
              defaultMessage="Add users from the workspace"
            >
              {msg => (
                <Select
                  key={slack.users && `${slack.users._id}_Upd-Us`}
                  defaultValue={choosedUsers}
                  onChange={selectedOption =>
                    handleChangeReport(selectedOption)
                  }
                  options={options}
                  isMulti
                  isSearchable
                  placeholder={msg}
                />
              )}
            </FormattedMessage>
          </div>

          <div className="my-2">
            <p className="bold medium block question">
              <FormattedMessage
                id="admin.dashboard.edit.reports.channels"
                defaultMessage="Channels"
              />
            </p>
            <FormattedMessage
              id="admin.dashboard.edit.reports.channelPlaceholder"
              defaultMessage="Add channels"
            >
              {msg => (
                <Select
                  key={slack.channels && `${slack.channels._id}_Upd-Ch`}
                  defaultValue={choosedChannels}
                  onChange={selectedChannelOption =>
                    handleChangeChannelReport(selectedChannelOption)
                  }
                  options={channelOptions}
                  isMulti
                  isSearchable
                  placeholder={msg}
                />
              )}
            </FormattedMessage>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

SlackItem.propTypes = {
  formdata: PropTypes.object.isRequired,
  setFormdata: PropTypes.func.isRequired,
};

export default SlackItem;
