import React from "react";
import PropTypes from 'prop-types';

const PeopleIcon = ({ activeIndex }) => {
  return (
  <svg id="team" className="w-20px" xmlns="http://www.w3.org/2000/svg" width="19.999" height="20" viewBox="0 0 19.999 20">
    <path id="Path_34" fill={`${activeIndex === 8 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 34" d="M862.749,590q-3.111,0-6.222,0a2.717,2.717,0,0,1-.476-.031,1.225,1.225,0,0,1-1.006-1.318,6.953,6.953,0,0,1,.518-2.749,6.786,6.786,0,0,1,2.526-3.171,8.11,8.11,0,0,1,5.282-.737,5.536,5.536,0,0,1,3.517,2.131,6.774,6.774,0,0,1,1.294,2.641,8.141,8.141,0,0,1,.239,1.808,1.245,1.245,0,0,1-1.085,1.408,3.094,3.094,0,0,1-.441.019Zm4.552-1.241a.833.833,0,0,0,.043-.165,6.043,6.043,0,0,0-1.378-3.841,4.7,4.7,0,0,0-1.518-1.169,7.029,7.029,0,0,0-6.432.659,5.617,5.617,0,0,0-1.833,3.5,6.662,6.662,0,0,0-.062.773,1.159,1.159,0,0,0,.049.245Z" transform="translate(-855.045 -570.002)"/>
    <path id="Path_35" fill={`${activeIndex === 8 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 35" d="M866.716,574.972a4.979,4.979,0,1,1-9.958.005,4.979,4.979,0,0,1,9.958-.005Zm-1.22-.111a3.761,3.761,0,1,0-3.593,3.883A3.741,3.741,0,0,0,865.5,574.861Z" transform="translate(-855.045 -570.002)"/>
    <path id="Path_36" fill={`${activeIndex === 8 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 36" d="M871.872,590c-.5,0-1.007,0-1.51,0a1.489,1.489,0,0,1-.4-.05.541.541,0,0,1-.376-.623.525.525,0,0,1,.438-.53,2.357,2.357,0,0,1,.367-.016q1.435,0,2.871,0c.086,0,.173,0,.258,0,.325-.029.465-.2.455-.563a4.861,4.861,0,0,0-.86-2.738,3.783,3.783,0,0,0-2.948-1.718c-.49-.03-.982-.01-1.472-.02a1.5,1.5,0,0,1-.4-.052.555.555,0,0,1-.379-.587.571.571,0,0,1,.419-.56.308.308,0,0,1,.073-.011,8.5,8.5,0,0,1,2.995.251,5.4,5.4,0,0,1,3.557,4.548,6.152,6.152,0,0,1,.083,1.077,1.47,1.47,0,0,1-1.187,1.552c-.157.029-.317.03-.476.045-.012,0-.025,0-.037,0Z" transform="translate(-855.045 -570.002)"/>
    <path id="Path_37" fill={`${activeIndex === 8 ? 'rgb(0 0 0 / 100%)' : 'rgb(0 0 0 / 40%)'}`} data-name="Path 37" d="M874.226,575.6a4.345,4.345,0,0,1-3.958,4.342,4.55,4.55,0,0,1-2.494-.5.6.6,0,0,1-.233-.969.638.638,0,0,1,.8-.129,6.038,6.038,0,0,0,1.019.349,3.133,3.133,0,1,0-.279-6.119,2.344,2.344,0,0,1-.364.085.584.584,0,0,1-.654-.4.561.561,0,0,1,.313-.731,3.957,3.957,0,0,1,1-.254,4.361,4.361,0,0,1,4.439,2.468A4.3,4.3,0,0,1,874.226,575.6Z" transform="translate(-855.045 -570.002)"/>
  </svg>

  );
};

PeopleIcon.propTypes = {
  activeIndex: PropTypes.number
};

export default PeopleIcon;
