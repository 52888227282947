import React from 'react';
import { FormattedMessage } from 'react-intl';

export const onboardingMood = [
  { 
    grade: 5,
    text: "Super excited about our last launch",
    day: 'Tue Jan 25 2022'
  },
  { 
    grade: 2,
    text: "Have problems with my health. It kills all my productivity.",
    day: 'Tue Jan 25 2022'
  },
  { 
    grade: 3,
    text: "I have challenges with the current workspace. I don't know how to set the new environment.",
    day: 'Tue Jan 25 2022'
  },
  { 
    grade: 4,
    text: "Amazing day",
    day: 'Tue Jan 25 2022'
  },
  { 
    grade: 3,
    text: "I think we should improve our communication skills. It's better to over-communicate because remote work requires that everyone is aligned in the team.",
    day: 'Tue Jan 25 2022'
  },
  { 
    grade: 4,
    day: 'Tue Jan 25 2022'
  },
  { 
    grade: 4,
    day: 'Tue Jan 25 2022'
  },
  { 
    grade: 3,
    day: 'Mon Jan 24 2022'
  },
  { 
    grade: 2,
    day: 'Mon Jan 24 2022'
  },
  { 
    grade: 3,
    day: 'Mon Jan 24 2022'
  },

  { 
    grade: 3,
    day: 'Mon Jan 24 2022',
    text: "Hard to focus on work when you have so many meetings",
  },
  { 
    grade: 5,
    day: 'Mon Jan 24 2022'
  },
  { 
    grade: 5,
    day: 'Wed Jan 26 2022',
    text: "Love our accomplishment last month with the new product",
  },
  { 
    grade: 4,
    day: 'Wed Jan 26 2022',
    text: "Love our new office. It's brilliant!",
  },
  { 
    grade: 5,
    day: 'Wed Jan 26 2022'
  },
  { 
    grade: 3,
    day: 'Wed Jan 26 2022'
  },
  { 
    grade: 2,
    day: 'Wed Jan 26 2022'
  },
  { 
    grade: 4,
    day: 'Wed Jan 26 2022'
  },
  { 
    grade: 5,
    day: 'Wed Jan 26 2022'
  },
]


export const onboardingMoodTitle = [
  { 
    day: 'Wed Jan 26 2022'
  },
  { 
    day: 'Tue Jan 25 2022'
  },
  { 
    day: 'Mon Jan 24 2022'
  },
]

export default {
  onboardingMood,
  onboardingMoodTitle,
}