
import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  titleContainer:{
    flexDirection: 'row',
    marginTop: 16,
    justifyContent: "space-between"
  },
  reportTitle:{
      color: '#000000',
      letterSpacing: 3,
      fontSize: 14,
      textAlign: 'center',
  },
  subTitle:{
    color: 'grey',
    fontSize: 12,
    textAlign: 'right',
    marginLeft: "auto"
}
});


const PageHeader = ({ title }) => (
  <View style={styles.titleContainer}>
      <Text style={styles.reportTitle}>{title}</Text>
      <Text style={styles.subTitle}>Powered by <a href="https://usefocus.co/" className="blue cursor">Focus</a></Text>
  </View>
);

export default PageHeader;