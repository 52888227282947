import {
  GET_USER_STANDUP_ANSWERS,
  GET_PROFILE_STANDUP_ANSWERS,
  GET_TEAM_STANDUP_ANSWERS,
  GET_STANDUP_ANSWERS,
  STANDUP_ANSWER_ERROR,
  STANDUP_ANSWERS_ERROR,
  ADD_STANDUP_ANSWER,
  GET_STANDUP_ANSWER,
  UPDATE_STANDUP_LIKES,
  ADD_COMMENT,
  UPDATE_COMMENT,
  CLEAR_STANDUP_ANSWERS,
  GET_COMPANY_STANDUP_ANSWERS,
  COMPANY_STANDUP_ANSWER_ERROR,
  REMOVE_COMMENT,
  LOCATION_CHANGE_ERROR,
  GET_COMPANY_STANDUP_ANSWERS_SETTINGS,
  GET_STANDUP_ANSWER_BY_ID
} from '../actions/types';

const initialState = {
  standupAnswers: [],
  standupAnswer: null,
  loading: true,
  isHomeLoading: true,
  isSettingsLoading: true,
  error: {}
};


export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
     case CLEAR_STANDUP_ANSWERS:
     case LOCATION_CHANGE_ERROR: 
      return {
        ...state,
        standupAnswers: [],
        standupAnswer: null,
        loading: true
      };
    case GET_STANDUP_ANSWERS:
    case GET_PROFILE_STANDUP_ANSWERS:
    case GET_TEAM_STANDUP_ANSWERS:
      return {
        ...state,
        standupAnswers: payload,
        loading: false
      };
    case GET_COMPANY_STANDUP_ANSWERS:
      return {
        ...state,
        standupAnswers: payload.company,
        standupAnswer: payload.user,
        loading: false
      };
    case GET_COMPANY_STANDUP_ANSWERS_SETTINGS:
      return {
        ...state,
        standupAnswers: payload.company,
        standupAnswer: payload.user,
        loading: false,
        isSettingsLoading: false
      };
    case GET_USER_STANDUP_ANSWERS:
      return {
        ...state,
        standupAnswers: payload.answers,
        standupAnswer: payload.last,
        loading: false,
        isHomeLoading: false
      };
    case GET_STANDUP_ANSWER:
    case GET_STANDUP_ANSWER_BY_ID:
      return {
        ...state,
        standupAnswer: payload,
        loading: false
      };
    case ADD_STANDUP_ANSWER:
      return {
        ...state,
        standupAnswers: [payload, ...state.standupAnswers],
        loading: false
      };
    case UPDATE_STANDUP_LIKES:
      return {
        ...state,
        standupAnswers: state.standupAnswers.map(standupAnswer =>
          standupAnswer._id === payload.standupID
            ? {
                ...standupAnswer,
                likes: payload.likes,
                answers: payload.answers
              }
            : standupAnswer
        ),
        standupAnswer: state.standupAnswer && state.standupAnswer._id === payload.standupID ? { 
          ...state.standupAnswer, 
          likes: payload.likes,
          answers: payload.answers 
        } 
        : state.standupAnswer,
        loading: false
      };
    case ADD_COMMENT:
      return {
        ...state,
        standupAnswers: state.standupAnswers.map(standupAnswer =>
          standupAnswer._id === payload.standupID
            ? {
                ...standupAnswer,
                comments: payload.comments,
                answers: payload.answers
              }
            : standupAnswer
        ),
        standupAnswer: state.standupAnswer && state.standupAnswer._id === payload.standupID ? {
          ...state.standupAnswer,
          comments: payload.comments,
          answers: payload.answers
        } 
        : state.standupAnswer,
        loading: false
      };
    case UPDATE_COMMENT:
      return {
        ...state,
        standupAnswers: state.standupAnswers.map(standupAnswer =>
          standupAnswer._id === payload.standupID
            ? {
                ...standupAnswer,
                comments: payload.comments
              }
            : standupAnswer
        ),
        standupAnswer: state.standupAnswer && state.standupAnswer._id === payload.id ? {
          ...state.standupAnswer,
          comments: payload.comments
        } 
        : state.standupAnswer,
        loading: false
      };
    case REMOVE_COMMENT:
      return {
        ...state,
        standupAnswers: state.standupAnswers.map(standupAnswer =>
          standupAnswer._id === payload.standupID
            ? {
                ...standupAnswer,
                answers: payload.answers,
                comments: standupAnswer.comments.filter(
                  comment => comment._id !== payload.commentID
                )
              }
            : standupAnswer
        ),
        standupAnswer: state.standupAnswer && state.standupAnswer._id === payload.standupID ? {
          ...state.standupAnswer,
          answers: payload.answers,
          comments: state.standupAnswer.comments.filter(
            comment => comment._id !== payload.commentID
          )
        } 
        : state.standupAnswer,
        loading: false
      };
    case STANDUP_ANSWER_ERROR:
    case COMPANY_STANDUP_ANSWER_ERROR:
      return {
        ...state,
        error: payload,
        standupAnswers: [],
        standupAnswer: null,
        loading: false
      };
    case STANDUP_ANSWERS_ERROR:
      return {
        ...state,
        error: payload,
        standupAnswers: [],
        loading: false
      };
    default:
      return state;
  }
}
