import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const IntervalItem = ({
  interval,
  onChange,
  isCustom,
  styles,
  isDaily,
  isMonthly,
}) => {

  if (isCustom) {
    isDaily = true;
    isMonthly = true;
  }
  
  return (
   <Fragment>
      <p className="bold medium block question">
        <FormattedMessage id="admin.dashboard.edit.update.interval" defaultMessage="Interval" />
      </p>
      <select className={styles} name='interval' value={interval} onChange={e => onChange(e)}>
        <option value='' disabled selected>Choose your interval</option>
        {isDaily && <option value='Daily'>Daily</option>}
        <option value='Weekly'>Weekly</option>
        <option value='Biweekly'>Biweekly</option>
        {isMonthly && <option value='Monthly'>Monthly</option>}
      </select>
    </Fragment>
  );
};

IntervalItem.propTypes = {
  interval: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isCustom: PropTypes.bool
};

export default IntervalItem;
